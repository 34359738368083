import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import RepricingStrategies from "../../modules/pages/repricer_strategies/index";

import {
  GetRepricerStrategies,
  fakeActionRepricerListing,
} from "../../redux/modules/repricer/product-listing/product-listing.action";

const mapStateToProps = (state) => ({
  GetRepricingStrategiesResponse:
    state.RepricerProductListing.GetRepricingStrategiesResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      GetRepricerStrategies,
      fakeActionRepricerListing,
    },
    dispatch
  );

const RepricingStrategiesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(RepricingStrategies);

export default RepricingStrategiesContainer;
