import React, { useEffect } from "react";

import { ConfigProvider, Progress } from "antd";
import { createHashHistory } from "history";
import { Provider } from "react-redux";
import AppRoute from "./AppRouter";
import { AppStyleWrapper } from "./AppStyle";
import GlobalCommonContextProvider from "./commonContext";
import { GlobalDebug } from "./core/remove-console";
import { store } from "./redux/configurations/store/configure-store.js";

function App() {
  useEffect(() => {
    (process.env.NODE_ENV === "production" ||
      process.env.REACT_APP_ENV === "STAGING") &&
      GlobalDebug(false);
  }, []);
  console.log("init");
  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            fontFamily: "Inter, Helvetica, sans-serif",
            colorPrimary: "#4318ff",
            colorPrimaryHover: "#4318ff",
          },
        }}
      >
        <GlobalCommonContextProvider>
          <Provider store={store}>
            <AppStyleWrapper />
            <AppRoute history={createHashHistory()} />
          </Provider>
        </GlobalCommonContextProvider>
      </ConfigProvider>
    </>
  );
}
export default App;
