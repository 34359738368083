import React, { useEffect, useRef, useState } from "react";
import { NumberWithCommas } from "../../../../../../config";
import Highcharts from "highcharts";
import NoData from "../../../../../../component/no-data";
import { savePDF } from "@progress/kendo-react-pdf";
import {
  CloudDownloadOutlined,
  FilePdfOutlined,
  FileExcelOutlined,
} from "@ant-design/icons";
import { Skeleton, Tooltip } from "antd";

export default function (props) {
  const { chartDate, chartSeries, loading } = props;
  const chartRef = useRef(null);

  // const [chartDate, setchartDate] = useState([
  //   "2024-01-16",
  //   "2024-01-21",
  //   "2024-01-28",
  //   "2024-02-04",
  //   "2024-01-13",
  //   "2024-02-11",
  // ]);
  // const [chartSeries, setchartSeries] = useState([
  //   {
  //     name: "Total Sales",
  //     data: [103713.58, 100771.61, 95611.67, 110279, 8630.69, 14333.61],
  //     type: "column",
  //   },
  //   {
  //     name: "Ad Sales",
  //     data: [20907.91, 13045.58, 47086.33, 60607.83, 10075.63, 88001.71],
  //     type: "column",
  //   },

  //   {
  //     name: "Ad Spend",
  //     data: [7907.91, 9345.58, 10786.33, 12607.83, 1175.63, 1481.71],
  //     type: "column",
  //   },
  //   {
  //     name: "TACoS",
  //     data: [18.7, 16.45, 13.83, 13.08, 11.46, 12.76],
  //     type: "line",
  //   },
  //   {
  //     name: "ACOS",
  //     data: [430, 420, 422, 493, 33, 58],
  //     type: "line",
  //   },
  // ]);

  const sign = {
    tacos: {
      valueSuffix: "%",
      valuePrefix: "",
    },
    acos: {
      valueSuffix: "",
      valuePrefix: "",
    },
    sales: {
      valueSuffix: "",
      valuePrefix: "$",
    },
    spend: {
      valueSuffix: "",
      valuePrefix: "$",
    },
  };

  // const colors = [
  //   "#70ad47", //green
  //   "#e76f03", //orange
  //   "#6048d3", //blue
  //   "#fec30d", //yellow
  //   "#1d0d6a", //yellow
  // ];
  const colors = ["#8992d4", "#373fb5", "#98cabb", "#097980"];

  useEffect(() => {
    if (chartDate?.length === 0 || chartSeries?.length === 0) return;
    Highcharts.chart("line-chart", {
      chart: {
        renderTo: "sales-by-week-graph",
        height: "450px",
        style: {
          fontFamily: "Oswald,sans-serif",
        },
      },
      type: "column",
      title: "",
      xAxis: {
        title: {
          text: "Date",
        },
        categories: chartDate,
      },
      yAxis: [
        {
          title: {
            text: "",
          },
          labels: {
            formatter: function () {
              return this.value?.toLocaleString();
            },
          },
        },
        {
          title: {
            text: `<label class="fw-bolder">ACOS</label>`,
            // text: "",
          },
          opposite: true,
          labels: {
            formatter: function () {
              return this.value?.toLocaleString() + "%";
            },
          },
        },
        {
          title: {
            text: `<label class="fw-bolder">TACoS</label>`,
            // text: "",
          },
          opposite: true,
          labels: {
            formatter: function () {
              return this.value?.toLocaleString() + "%";
            },
          },
        },
      ],
      series: chartSeries?.map((d, i) => ({
        name: d?.name,
        type: d?.type,
        // tooltip: sign?.[d?.name],
        color: colors?.[i] || "#A52A2A",
        data: d?.data,
        // yAxis: 0,
        yAxis:
          d?.name === "sales"
            ? 0
            : d?.name === "spend"
            ? 0
            : d?.name === "acos"
            ? 1
            : 2,
        // yAxis: i == 1 ? 1 : 2,
      })),
      tooltip: {
        useHTML: true,
        shared: true,
        style: {
          fontSize: "14px", // Change font size
          padding: "10px", // Change padding
          // You can add more properties to modify the tooltip style
        },
        pointFormatter: function () {
          return (
            '<span style="color:' +
            this.color +
            '">\u25CF</span> ' +
            '<span style="text-transform: capitalize">' +
            this.series.name +
            "</span>" +
            ": <b>" +
            sign?.[this.series.name]?.valuePrefix +
            this.y?.toLocaleString() +
            sign?.[this.series.name]?.valueSuffix +
            "</b><br/>"
          );
        },
      },
    });
  }, [chartSeries]);

  const handleExportPDF = () => {
    savePDF(chartRef.current, { paperSize: "auto", fileName: "chart.pdf" });
  };

  return (
    <div className="col-xxl-12">
      {/*begin::Tables Widget 9*/}

      <div className="card d-flex">
        {/*begin::Header*/}
        <div className="card-header border-bottom-dashed">
          <div className="d-flex flex-stack flex-wrap gap-4">
            <div className="position-relative me-3 mb-4 mb-md-0"></div>
          </div>
          <div className="card-toolbar">
            {" "}
            <span
              className="d-flex align-items-center cursor-pointer me-5"
              onClick={() => {
                handleExportPDF();
              }}
            >
              <Tooltip title="Download PDF" placement="bottom">
                <FilePdfOutlined className="fs-1" style={{ color: "red" }} />
              </Tooltip>
            </span>
          </div>
        </div>
        {/*end::Header*/}
        {/*begin::Body*/}
        <div className="card-body py-2">
          {/*begin::Table container*/}
          <div className="w-100" style={{ minHeight: "450px" }} id="chartZoom">
            {loading ? (
              <div className="d-flex justify-content-center">
                <Skeleton.Node block size="large" active />
              </div>
            ) : chartDate?.length != 0 ? (
              <div id="line-chart" ref={chartRef}></div>
            ) : (
              <NoData height="400px" />
            )}
          </div>
        </div>
        {/*begin::Body*/}
      </div>
      {/*end::Tables Widget 9*/}
    </div>
  );
}
