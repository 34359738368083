import axiosCall from "../../configurations/network-services/axiosCall";

const prefix = "dashboard";

export const GetListingOptimizerProducts = (data) => {
  const path = `catalog-items?page=${data?.page || 1}&per-page=${
    data?.perPage || 10
  }&asin1=${data?.search || ""}&marketplace_id=${
    data?.marketplace_id || ""
  }&status=${data?.status || ""}`;
  const responseType = "GET_LISTING_OPTIMIZER_PRODUCTS";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetListingOptimizerProductDetails = (id) => {
  const path = `catalog-item/get-details/${id}`;
  const responseType = "GET_LISTING_OPTIMIZER_PRODUCTS_DETAILS";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetCriteriaAnalysisTitle = (data) => {
  const path = `criteria/analysis-title`;
  const responseType = "GET_CRITERIA_ANALYSIS_TITLE";
  return axiosCall("post", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetCriteriaAnalysisBulletPoints = (data) => {
  const path = `criteria/analysis-bullet`;
  const responseType = "GET_CRITERIA_ANALYSIS_BULLET_POINTS";
  return axiosCall("post", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetCriteriaAnalysisProductDescription = (data) => {
  const path = `criteria/analysis-description`;
  const responseType = "GET_CRITERIA_ANALYSIS_PRODUCT_DESCRIPTION";
  return axiosCall("post", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetRecommendationTitle = (data) => {
  const path = `criteria/recommendation-title`;
  const responseType = "GET_RECOMMENDATION_TITLE";
  return axiosCall("post", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const GetRecommendationDescription = (data) => {
  const path = `criteria/recommendation-description`;
  const responseType = "GET_RECOMMENDATION_DESCRIPTION";
  return axiosCall("post", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const GetRecommendationBullets = (data) => {
  const path = `criteria/recommendation-bullet`;
  const responseType = "GET_RECOMMENDATION_BULLETS";
  return axiosCall("post", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const StartProductAnalysis = (data) => {
  const path = `catalog-item/product-analysis`;
  const responseType = "START_PRODUCT_ANALYSIS";
  return axiosCall("post", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const fakeActionListingOptimizer = (data) => async (dispatch) => {
  dispatch({ type: "FAKE_ACTION_LISTING_OPTIMIZER", state: data });
};
