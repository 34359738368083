import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import KeywordManagement from "../../../modules/pages/advertisement/keyword-management";
import {
  GetSalesChannelList,
  fakeActionSalesAnalytics,
} from "../../../redux/modules/sales-analytics/sales-analytics.action";
import {
  GetWMKeywordSummeryData,
  GetWMKeywordChartData,
  GetWMKeywordListData,
  GetWmSearchTrendsData,
  GetWmItemRecommendation,
  GetWmKeywordRecommendation,
  fakeActionWalmartKeyword,
} from "../../../redux/modules/advertisement/keyword-management/walmart/index.action";
import {
  GetWalmartBidRuleAdGroupList,
  GetWalmartBidRuleCampaignList,
  fakeActionWalmartBidManagement,
} from "../../../redux/modules/advertisement/rule-management/walmart-bid/walmart-rule-action";

const mapStateToProps = (state) => ({
  GetSalesChannelListResponse: state.SalesAnalytics.GetSalesChannelListResponse,

  GetWmKeywordSummeryDataResponse:
    state.WalmartKeywordManagement.GetWmKeywordSummeryDataResponse,
  GetWmKeywordChartDataResponse:
    state.WalmartKeywordManagement.GetWmKeywordChartDataResponse,
  GetWmKeywordListDataResponse:
    state.WalmartKeywordManagement.GetWmKeywordListDataResponse,
  GetWmSearchTrendsDataResponse:
    state.WalmartKeywordManagement.GetWmSearchTrendsDataResponse,
  GetWmItemRecommendationResponse:
    state.WalmartKeywordManagement.GetWmItemRecommendationResponse,
  GetWmKeywordRecommendationResponse:
    state.WalmartKeywordManagement.GetWmKeywordRecommendationResponse,

  GetWalmartBidRuleCampaignListResponse:
    state.WalmartBidManagement.GetWalmartBidRuleCampaignListResponse,
  GetWalmartBidRuleAgGroupListResponse:
    state.WalmartBidManagement.GetWalmartBidRuleAgGroupListResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      GetSalesChannelList,
      fakeActionSalesAnalytics,

      GetWMKeywordSummeryData,
      GetWMKeywordChartData,
      GetWMKeywordListData,
      GetWmSearchTrendsData,
      GetWmItemRecommendation,
      GetWmKeywordRecommendation,
      fakeActionWalmartKeyword,

      GetWalmartBidRuleAdGroupList,
      GetWalmartBidRuleCampaignList,
      fakeActionWalmartBidManagement,
    },
    dispatch
  );

const KeywordManagementContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(KeywordManagement);

export default KeywordManagementContainer;
