import {
  Alert,
  Checkbox,
  Col,
  Input,
  Row,
  Segmented,
  Select,
  Tag,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { EditReplenishmentWrapper } from "./style";
import SupplierOrderTab from "./supplier-order-tab";
import ManufactureLogisticTab from "./manufacture-logistic-tab";
import ForecastTab from "./forecast-tab";
import ProductDimentionTab from "./product-dimention-tab";
import { useSelector } from "react-redux";
import WarehouseStock from "./warehouse-stock";

export default function (props) {
  const {
    show,
    onHide,
    selectedRowAsinSku,
    data,
    marketplace_id,
    SaveReplenishmentModalData,
  } = props;

  const [selectedTab, setSelectedTab] = useState("Warehouse Stock");

  const [replenishmentModalData, setReplenishmentModalData] = useState({
    sku: "",
    asin: "",
    target_stock_days: 0,
    fba_buffer_days: 0,
    manufacturing_time_days: 0,
    i_use_prep_center: 0,
    shipping_prep_center_days: 0,
    shipping_fba_days: 0,
    days_of_stock: 0,
    vendor_lead_time: 0,
    marketplace_id: "",
    supplier_id: null,
  });

  useEffect(() => {
    if (data) {
      setReplenishmentModalData(data);
    } else {
      const Obj = { ...replenishmentModalData };
      Obj.sku = selectedRowAsinSku?.sku;
      Obj.asin = selectedRowAsinSku?.asin;
      Obj.marketplace_id = marketplace_id;
      setReplenishmentModalData(Obj);
    }
  }, [data && show]);

  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          onHide();
        }}
        size="lg"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Replenishment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <EditReplenishmentWrapper>
              <div className="d-flex mb-5">
                <div className="me-5">
                  <Tag color="green">ASIN : {selectedRowAsinSku?.asin}</Tag>
                </div>
                <div>
                  <Tag color="geekblue">SKU : {selectedRowAsinSku?.sku}</Tag>
                </div>
              </div>
              <div>
                <Segmented
                  options={[
                    "Warehouse Stock",
                    "Supplier order",
                    "Manufacturing & Logistics",
                    // "Forecast",
                    // "Product dimensions",
                  ]}
                  value={selectedTab}
                  onChange={(e) => {
                    setSelectedTab(e);
                  }}
                />
              </div>
              <div className="separator my-5" />

              {selectedTab === "Warehouse Stock" && (
                <WarehouseStock
                  replenishmentModalData={replenishmentModalData}
                  setReplenishmentModalData={setReplenishmentModalData}
                  {...props}
                />
              )}
              {selectedTab === "Supplier order" && (
                <SupplierOrderTab
                  replenishmentModalData={replenishmentModalData}
                  setReplenishmentModalData={setReplenishmentModalData}
                  {...props}
                />
              )}
              {selectedTab === "Manufacturing & Logistics" && (
                <ManufactureLogisticTab
                  replenishmentModalData={replenishmentModalData}
                  setReplenishmentModalData={setReplenishmentModalData}
                />
              )}
              {selectedTab === "Forecast" && (
                <ForecastTab
                  replenishmentModalData={replenishmentModalData}
                  setReplenishmentModalData={setReplenishmentModalData}
                  {...props}
                />
              )}
              {selectedTab === "Product dimensions" && <ProductDimentionTab />}
            </EditReplenishmentWrapper>
          </>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <button
              className="btn btn-light-danger me-2"
              onClick={() => {
                onHide();
              }}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary"
              onClick={() => {
                console.log(replenishmentModalData, "replenishmentModalData");
                SaveReplenishmentModalData(replenishmentModalData);
              }}
            >
              Save
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
