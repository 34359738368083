import { Input, Switch } from "antd";
import React from "react";

const ManageAddGroup = (props) => {
  const {
    data,
    setAddGroupName,
    addGroupName,
    addGroupList,
    setAddGroupList,
    bid_placement_obj,
    set_bid_placement_obj,
    bid_platform_obj,
    set_bid_platform_obj,
    placement_inclusion_obj,
    set_placement_inclusion_obj,
  } = props;
  const setAllFiledValue = (key, value, defaultVal, setDefaultVal) => {
    const newObj = { ...defaultVal };
    newObj[key] = value;
    setDefaultVal(newObj);
  };
  console.log(placement_inclusion_obj, "placement_inclusion_obj");
  return (
    <div className="fadeOutBottom">
      {data?.targetingType === "manual" && (
        <div className="row mt-5">
          <div className="col-xl-12">
            <div className="card card-flush h-lg-100 mb-5 mb-xl-5">
              <div className="card-header border-0 cursor-pointer">
                <div className="card-title m-0">
                  <h3 className="fw-bold m-0">Placement Inclusion</h3>
                </div>

                <div className="card-toolbar"></div>
              </div>

              <div className="card-body border-top border-top-dashed pb-2 pt-6">
                <div className="row mb-0 g-5">
                  <div className="col-md-12">
                    <label className="fs-6 m-0 fw-semibold">
                      Select the placements where you would like to serve ads
                    </label>
                  </div>
                  <div className="col-12 col-md-6 d-flex">
                    <Switch
                      checkedChildren="ON"
                      unCheckedChildren="OFF"
                      value={true}
                      disabled
                      // onChange={(e) => {
                      //   setAllFiledValue(
                      //     "search_ingrid",
                      //     e ? "included" : "excluded",
                      //     placement_inclusion_obj,
                      //     set_placement_inclusion_obj
                      //   );
                      // }}
                    />
                    <label className="fw-bold fs-6 ms-2">Search Ingrid</label>
                  </div>
                  <div className="col-12 col-md-6 d-flex">
                    <Switch
                      checkedChildren="ON"
                      unCheckedChildren="OFF"
                      value={
                        placement_inclusion_obj?.["Search Carousel"] ===
                        "included"
                          ? true
                          : false
                      }
                      onChange={(e) => {
                        setAllFiledValue(
                          "Search Carousel",
                          e ? "included" : "excluded",
                          placement_inclusion_obj,
                          set_placement_inclusion_obj
                        );
                      }}
                    />
                    <label className="fw-bold fs-6 ms-2">Search Carousel</label>
                  </div>
                  <div className="col-12 col-md-6 d-flex">
                    <Switch
                      checkedChildren="ON"
                      unCheckedChildren="OFF"
                      value={
                        placement_inclusion_obj?.["Item Buybox"] === "included"
                          ? true
                          : false
                      }
                      onChange={(e) => {
                        setAllFiledValue(
                          "Item Buybox",
                          e ? "included" : "excluded",
                          placement_inclusion_obj,
                          set_placement_inclusion_obj
                        );
                      }}
                    />
                    <label className="fw-bold fs-6 ms-2">Item Buybox</label>
                  </div>
                  <div className="col-12 col-md-6 d-flex">
                    <Switch
                      checkedChildren="ON"
                      unCheckedChildren="OFF"
                      value={
                        placement_inclusion_obj?.["Item Carousel"] ===
                        "included"
                          ? true
                          : false
                      }
                      onChange={(e) => {
                        setAllFiledValue(
                          "Item Carousel",
                          e ? "included" : "excluded",
                          placement_inclusion_obj,
                          set_placement_inclusion_obj
                        );
                      }}
                      defaultChecked={false}
                    />
                    <label className="fw-bold fs-6 ms-2">Item Carousel</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="row mt-5">
        <div className="col-xl-12">
          <div className="card card-flush h-lg-100 mb-5 mb-xl-5">
            <div className="card-header border-0 cursor-pointer">
              <div className="card-title m-0">
                <h3 className="fw-bold m-0">Bid Multiplier</h3>
              </div>
            </div>

            <div className="card-body border-top border-top-dashed pb-2 pt-6">
              <div className=" mb-5 text-gray-600">
                <div className="mb-1">
                  Bid Multiplier increase your bid by a preset percentage when
                  your ads are eligible to serve in premium placements
                </div>
                <div>
                  They help you compete for search ingrid, Buy Box on Desktop,
                  App and Mobile web
                </div>
              </div>

              <div className="row mb-0 ">
                <div className="col-md-12">
                  <h4 className="mb-0">Placement</h4>
                  <div className="separator separator-dashed my-4" />
                </div>
                <div className="col-6 col-md-3">
                  <div className="form-group">
                    <label htmlFor className="fw-bolder fs-6 mb-1">
                      Search Ingrid
                    </label>

                    <div className="input-group mb-5">
                      <Input
                        type="text"
                        className="ant_common_input fs-6"
                        placeholder="Enter Search Ingrid"
                        defaultValue={0}
                        value={bid_placement_obj?.["Search Ingrid"]}
                        onBlur={(e) => {
                          if (!e.target.value) {
                            setAllFiledValue(
                              "Search Ingrid",
                              0,
                              bid_placement_obj,
                              set_bid_placement_obj
                            );
                          }
                        }}
                        onChange={(e) => {
                          let value = e.target.value;
                          value = value.replace(/^0+/, "");
                          if (
                            bid_placement_obj?.["Search Ingrid"] == 0 &&
                            value != "0"
                          ) {
                            value = value.replace("0", "");
                          }
                          const isNumeric = /^[0-9]*$/.test(value);
                          if (isNumeric || value === "") {
                            setAllFiledValue(
                              "Search Ingrid",
                              value,
                              bid_placement_obj,
                              set_bid_placement_obj
                            );
                          }
                        }}
                        suffix={<label className="fw-bolder fs-4">%</label>}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-3">
                  <div className="form-group">
                    <label htmlFor className="fw-bolder fs-6 mb-1">
                      Buy Box
                    </label>

                    <div className="input-group mb-5">
                      <Input
                        type="text"
                        className="ant_common_input fs-6"
                        placeholder="Enter Buy Box"
                        defaultValue={0}
                        value={bid_placement_obj?.["Buy-Box"]}
                        onBlur={(e) => {
                          if (!e.target.value) {
                            setAllFiledValue(
                              "Buy-Box",
                              0,
                              bid_placement_obj,
                              set_bid_placement_obj
                            );
                          }
                        }}
                        onChange={(e) => {
                          let value = e.target.value;
                          value = value.replace(/^0+/, "");
                          if (
                            bid_placement_obj?.["Buy-Box"] == 0 &&
                            value != "0"
                          ) {
                            value = value.replace("0", "");
                          }
                          const isNumeric = /^[0-9]*$/.test(value);
                          if (isNumeric || value === "") {
                            setAllFiledValue(
                              "Buy-Box",
                              value,
                              bid_placement_obj,
                              set_bid_placement_obj
                            );
                          }
                        }}
                        suffix={<label className="fw-bolder fs-4">%</label>}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mt-3">
                  <h4 className="mb-0">Platform</h4>
                  <div className="separator separator-dashed my-4" />
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor className="fw-bolder fs-6 mb-1">
                      Desktop
                    </label>

                    <div className="input-group mb-5">
                      <Input
                        type="text"
                        className="ant_common_input fs-6"
                        placeholder="Enter Desktop Value"
                        suffix={<label className="fw-bolder fs-4">%</label>}
                        defaultValue={0}
                        value={bid_platform_obj?.Desktop}
                        onBlur={(e) => {
                          if (!e.target.value) {
                            setAllFiledValue(
                              "Desktop",
                              0,
                              bid_platform_obj,
                              set_bid_platform_obj
                            );
                          }
                        }}
                        onChange={(e) => {
                          let value = e.target.value;
                          value = value.replace(/^0+/, "");
                          if (bid_platform_obj?.Desktop == 0 && value != "0") {
                            value = value.replace("0", "");
                          }
                          const isNumeric = /^[0-9]*$/.test(value);
                          if (isNumeric || value === "") {
                            setAllFiledValue(
                              "Desktop",
                              value,
                              bid_platform_obj,
                              set_bid_platform_obj
                            );
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor className="fw-bolder fs-6 mb-1">
                      App
                    </label>

                    <div className="input-group mb-5">
                      <Input
                        type="text"
                        className="ant_common_input fs-6"
                        placeholder="Enter App Value"
                        suffix={<label className="fw-bolder fs-4">%</label>}
                        defaultValue={0}
                        value={bid_platform_obj?.App}
                        onBlur={(e) => {
                          if (!e.target.value) {
                            setAllFiledValue(
                              "App",
                              0,
                              bid_platform_obj,
                              set_bid_platform_obj
                            );
                          }
                        }}
                        onChange={(e) => {
                          let value = e.target.value;
                          value = value.replace(/^0+/, "");
                          if (bid_platform_obj?.App == 0 && value != "0") {
                            value = value.replace("0", "");
                          }
                          const isNumeric = /^[0-9]*$/.test(value);
                          if (isNumeric || value === "") {
                            setAllFiledValue(
                              "App",
                              value,
                              bid_platform_obj,
                              set_bid_platform_obj
                            );
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor className="fw-bolder fs-6 mb-1">
                      Mobile
                    </label>

                    <div className="input-group mb-5">
                      <Input
                        type="text"
                        className="ant_common_input fs-6"
                        placeholder="Enter Mobile Value"
                        suffix={<label className="fw-bolder fs-4">%</label>}
                        defaultValue={0}
                        value={bid_platform_obj?.Mobile}
                        onBlur={(e) => {
                          if (!e.target.value) {
                            setAllFiledValue(
                              "Mobile",
                              0,
                              bid_platform_obj,
                              set_bid_platform_obj
                            );
                          }
                        }}
                        onChange={(e) => {
                          let value = e.target.value;
                          value = value.replace(/^0+/, "");
                          if (bid_platform_obj?.Mobile == 0 && value != "0") {
                            value = value.replace("0", "");
                          }
                          const isNumeric = /^[0-9]*$/.test(value);
                          if (isNumeric || value === "") {
                            setAllFiledValue(
                              "Mobile",
                              value,
                              bid_platform_obj,
                              set_bid_platform_obj
                            );
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-5">
        <div className="col-xl-12">
          <div className="card card-flush h-lg-100 mb-5 mb-xl-5">
            <div className="card-header border-0 cursor-pointer">
              <div className="card-title m-0">
                <h3 className="fw-bold m-0">Create and Manage Ad Groups</h3>
              </div>

              <div className="card-toolbar"></div>
            </div>

            <div className="card-body border-top border-top-dashed pb-2 pt-6">
              <div className="row mb-0 g-5">
                <div className="col-md-12">
                  <h4 className="mb-0">Ad Group Details</h4>
                  <div className="separator separator-dashed my-4" />
                </div>
                <div className="col-12 mt-0">
                  {console.log(
                    addGroupList,
                    "addGroupListaddGroupListaddGroupList"
                  )}
                  {/* {addGroupList?.map((d) => {
                    return (
                      <>
                        <div className="my-1 d-flex justify-content-between">
                          <label className="fw-normal fs-5">{d?.name}</label>
                          <Switch
                            checkedChildren="On"
                            unCheckedChildren="Off"
                            value={d?.status === "Enabled" ? true : false}
                            disabled={addGroupList?.length == 1}
                            onChange={(e) => {
                              setAddGroupList((prev) => {
                                return prev?.map((a) => {
                                  if (a?.adGroupId === d?.adGroupId) {
                                    return {
                                      ...a,
                                      status: e ? "Enabled" : "Disabled",
                                    };
                                  } else {
                                    return { ...a };
                                  }
                                });
                              });
                            }}
                            defaultChecked={false}
                          />
                        </div>
                        <div className="separator separator-dashed my-2" />
                      </>
                    );
                  })} */}
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label htmlFor className="fw-bolder fs-6 mb-1 required">
                      Ad Group Name
                    </label>

                    <div className>
                      <Input
                        type="text"
                        className="ant_common_input fs-7 form-control"
                        placeholder="Name your ad group"
                        value={addGroupName}
                        onChange={(e) => {
                          setAddGroupName(e.target.value);
                        }}
                        size="large"
                      />
                    </div>
                    <div className="text-end"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageAddGroup;
