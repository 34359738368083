import { Button, Input, Upload, message } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Error from "../../../../component/error";
import { PlusOutlined } from "@ant-design/icons";
import { ENDPOINT, getUserId } from "../../../../config";
import { GlobalContext } from "../../../../commonContext";
import { ProfileWrapper } from "./style";

export default function (props) {
  const { SaveUserProfile, fakeActionuserProfile } = props;

  const GetUserProfileSaveRes = useSelector(
    (state) => state.UserProfile.GetUserProfileSaveResponse || {}
  );
  const contextVar = useContext(GlobalContext);
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    contact_no: "",
  });

  useEffect(() => {
    const localUserData = JSON.parse(localStorage.getItem("user"));
    const userObj = { ...userData };
    userObj.name = localUserData?.name;
    userObj.email = localUserData?.email;
    userObj.contact_no = localUserData?.contact_no;
    setUserData(userObj);
  }, []);

  useEffect(() => {
    if (GetUserProfileSaveRes?.status === true) {
      // console.log(GetUserProfileSaveRes, "--------GetUserProfileSaveRes");
      localStorage.setItem("user", JSON.stringify(GetUserProfileSaveRes?.data));
      message.destroy();
      message.success(GetUserProfileSaveRes?.message);
      const userObj = { ...userData };
      userObj.name = GetUserProfileSaveRes?.data?.name;
      userObj.email = GetUserProfileSaveRes?.data?.email;
      userObj.contact_no = GetUserProfileSaveRes?.data?.contact_no;
      contextVar?.updateCommonGlobalVal({
        user_data: GetUserProfileSaveRes?.data,
      });
      setUserData(userObj);
      fakeActionuserProfile("GetUserProfileSaveResponse");
    } else if (GetUserProfileSaveRes?.status === false) {
      message.destroy();
      message.error(GetUserProfileSaveRes?.message);
      fakeActionuserProfile("GetUserProfileSaveResponse");
    }
  }, [GetUserProfileSaveRes]);

  const [profileData, setProfileData] = useState(
    JSON.parse(localStorage.getItem("user"))
  );

  const [fileList, setFileList] = useState(
    contextVar?.data?.user_photo
      ? [
          {
            uid: "-1",
            name: "image.png",
            status: "done",
            url: `${contextVar?.data?.user_photo}`,
          },
        ]
      : []
  );

  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);

  const handleRemove = async () => {
    message.destroy();
    message.loading("Loading...");
    // Call backend API to remove the image from the database
    const endpoint = `${ENDPOINT}/api/v1/user/remove-image/${getUserId()}`;
    try {
      const response = await fetch(endpoint, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (response.ok) {
        // Update state and context after successful deletion
        setFileList([]);
        contextVar?.updateCommonGlobalVal({
          user_photo: null,
        });
        const userData = JSON.parse(localStorage.getItem("user"));
        if (userData) {
          userData.photo = null;
          localStorage.setItem("user", JSON.stringify(userData));
        }
        message.destroy();
        message.success("Profile picture removed successfully");
      } else {
        message.destroy();
        message.error("Failed to remove profile picture");
      }
    } catch (error) {
      message.destroy();
      console.error("Error removing profile picture:", error);
      message.error("An error occurred while removing the profile picture");
    }
  };
  return (
    <ProfileWrapper>
      <div className="d-flex flex-column flex-column-fluid fadeInRight">
        <div id="kt_app_content" className="app-content flex-column-fluid">
          <div
            id="kt_app_content_container"
            className="app-container container-fluid"
          >
            <div className="d-flex flex-column flex-lg-row">
              <div className="flex-column flex-lg-row-auto w-lg-250px w-xl-350px mb-10">
                <div
                  className="card mb-5 mb-xl-8"
                  style={{
                    borderTop: "2px solid #4318ff",
                    position: "sticky",
                    top: 12,
                  }}
                >
                  <div className="card-body">
                    <div className="d-flex flex-center flex-column pb-5">
                      <div
                        className="cursor-pointer symbol symbol-circle symbol-45px symbol-md-75px"
                        style={{ border: "1px solid #5129ff", padding: 5 }}
                      >
                        {contextVar?.data?.user_photo ? (
                          <img src={contextVar?.data?.user_photo} />
                        ) : (
                          <div
                            style={{
                              height: "60px",
                              width: "60px",
                              background: "#5058ce",
                              borderRadius: "30px",
                              color: "rgb(255, 255, 255)",
                              fontWeight: "400",
                              fontSize: "38px",
                              boxShadow:
                                "rgb(137 146 212) 0px -50px 36px -28px inset",
                            }}
                            className="d-flex justify-content-center align-items-center"
                          >
                            <span
                              style={{
                                textTransform: "uppercase",
                              }}
                            >
                              {JSON.parse(
                                localStorage.getItem("user")
                              )?.name?.charAt(0)}
                            </span>
                          </div>
                        )}
                      </div>
                      <span className="fs-3 text-gray-800 text-hover-primary fw-bold mb-3 mt-4">
                        {contextVar?.data?.user_data?.name}
                      </span>
                      {/* <div className="mb-2">
                      <div className="badge badge-lg badge-light-info d-inline">
                        Administrator
                      </div>
                    </div> */}
                    </div>

                    <div className="separator separator-dashed mb-4" />
                    <div className="pb-0 fs-6">
                      <div className="fw-bold ">Name</div>
                      <div className="text-gray-600">
                        {contextVar?.data?.user_data?.name}
                      </div>

                      <div className="separator separator-dashed my-4" />

                      <div className="fw-bold ">Email</div>
                      <div className="text-gray-600">
                        <span className="text-gray-600 text-hover-primary">
                          {contextVar?.data?.user_data?.email}
                        </span>
                      </div>

                      <div className="separator separator-dashed my-4" />

                      <div className="fw-bold ">Address</div>
                      <div className="text-gray-600">
                        {contextVar?.data?.user_data?.address || "-"}
                      </div>

                      <div className="separator separator-dashed my-4" />

                      <div className="fw-bold ">Contact Number</div>
                      <div className="text-gray-600">
                        {contextVar?.data?.user_data?.contact_no || "-"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex-lg-row-fluid ms-lg-10 ">
                <div className="row gy-5 g-xl-5">
                  <div className="col-xl-12">
                    <div className="card card-flush h-lg-100 mb-5 mb-xl-5">
                      <div
                        className="card-header border-0 cursor-pointer"
                        role="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#kt_account_profile_details"
                        aria-expanded="true"
                        aria-controls="kt_account_profile_details"
                      >
                        <div className="card-title m-0">
                          <h3 className="fw-bold m-0">Profile Details</h3>
                        </div>
                      </div>

                      <div
                        id="kt_account_settings_profile_details"
                        className="collapse show"
                      >
                        <div
                          id="kt_account_profile_details_form"
                          className="form"
                        >
                          <div className="card-body border-top border-top-dashed p-9">
                            <div className="row mb-6">
                              <label className="col-lg-4 col-form-label fw-semibold fs-6">
                                Avatar
                              </label>

                              <div className="col-lg-8">
                                <div
                                  className="image-input image-input-outline"
                                  data-kt-image-input="true"
                                  style={{ position: "relative" }}
                                >
                                  <Upload
                                    listType="picture-card"
                                    fileList={fileList}
                                    name="User[photo]"
                                    action={
                                      ENDPOINT +
                                      `/api/v1/user/${getUserId()}/update-image`
                                    }
                                    headers={{
                                      Authorization: `Bearer ${localStorage.getItem(
                                        "token"
                                      )}`,
                                    }}
                                    // customRequest={(e) => {
                                    //   console.log(e, "e");
                                    // }}
                                    maxCount="1"
                                    accept=".png, .jpg, .jpeg"
                                    onChange={(e) => {
                                      // console.log(e);
                                      if (e?.file?.status === "done") {
                                        try {
                                          const userData = JSON.parse(
                                            e.file.xhr?.response
                                          )?.data;
                                          contextVar?.updateCommonGlobalVal({
                                            user_photo: JSON.parse(
                                              e.file.xhr?.response
                                            )?.data?.photo,
                                            user_data: JSON.parse(
                                              e.file.xhr?.response
                                            )?.data,
                                          });
                                          localStorage.setItem(
                                            "user",
                                            JSON.stringify(userData)
                                          );
                                          message.success(
                                            "Profile picture updated successfully"
                                          );
                                          console.log(
                                            "afterSuccess",
                                            JSON.parse(e.file.xhr?.response)
                                              ?.data?.photo
                                          );
                                        } catch (error) {}
                                      }
                                      handleChange(e);
                                    }}
                                    // onPreview={handlePreview}
                                  >
                                    {fileList.length == 1 ? null : (
                                      <>
                                        {contextVar?.data?.user_photo ? (
                                          <div>
                                            <img
                                              src={contextVar?.data?.user_photo}
                                            />
                                          </div>
                                        ) : (
                                          <div style={{ position: "relative" }}>
                                            <button
                                              className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2"
                                              style={{
                                                position: "absolute",
                                                top: "-35px",
                                                right: "-38px",
                                              }}
                                            >
                                              <i className="ki-outline ki-pencil fs-2 text-info" />
                                            </button>
                                            <div
                                              style={{
                                                height: "60px",
                                                width: "60px",
                                                background: "#5058ce",
                                                borderRadius: "30px",
                                                color: "rgb(255, 255, 255)",
                                                fontWeight: "400",
                                                fontSize: "38px",
                                                boxShadow:
                                                  "rgb(137 146 212) 0px -50px 36px -28px inset",
                                              }}
                                              className="d-flex justify-content-center align-items-center"
                                            >
                                              <span
                                                style={{
                                                  textTransform: "uppercase",
                                                }}
                                              >
                                                {JSON.parse(
                                                  localStorage.getItem("user")
                                                )?.name?.charAt(0)}
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                      </>
                                    )}
                                  </Upload>
                                  {fileList.length == 1 && (
                                    <button
                                      className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
                                      style={{
                                        position: "absolute",
                                        right: "-7px",
                                        bottom: "-5px",
                                      }}
                                      onClick={() => {
                                        handleRemove();
                                      }}
                                    >
                                      <i className="ki-outline ki-trash fs-2 text-danger" />
                                    </button>
                                  )}
                                </div>
                                <div className="form-text mt-5">
                                  Allowed file types: png, jpg, jpeg.
                                </div>
                                {/* <div
                                className="image-input image-input-outline"
                                data-kt-image-input="true"
                              
                              >
                                <div
                                  className="image-input-wrapper w-125px h-125px"
                                  style={{
                                    backgroundImage:
                                      "url(/assets/media/avatars/300-17.jpg)",
                                  }}
                                ></div>

                                <label
                                  className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                  data-kt-image-input-action="change"
                                  data-bs-toggle="tooltip"
                                  title="Change avatar"
                                >
                                  <i className="bi bi-pencil-fill fs-7" />

                                  <input
                                    type="file"
                                    name="avatar"
                                    accept=".png, .jpg, .jpeg"
                                  />
                                  <input type="hidden" name="avatar_remove" />
                                </label>

                                <span
                                  className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                  data-kt-image-input-action="remove"
                                  data-bs-toggle="tooltip"
                                  title="Remove avatar"
                                >
                                  <i className="bi bi-x fs-2" />
                                </span>
                              </div> */}
                              </div>
                            </div>

                            <div className="row mb-6">
                              <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                                Full Name
                              </label>

                              <div className="col-lg-8">
                                <div className="row">
                                  <div className="col-12 fv-row">
                                    <Input
                                      type="text"
                                      className="form-control  mb-3 mb-lg-0"
                                      placeholder="First name"
                                      value={userData?.name}
                                      onChange={(e) => {
                                        const userObj = { ...userData };
                                        userObj.name = e.target.value;
                                        setUserData(userObj);
                                      }}
                                    />
                                  </div>

                                  <div className="col-lg-6 fv-row">
                                    {/* <Input
                                    type="text"
                                    className="form-control "
                                    placeholder="Last name"
                                    value={userData?.lname}
                                  /> */}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row mb-6">
                              <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                                Email Address
                              </label>

                              <div className="col-lg-8 fv-row">
                                <Input
                                  type="text"
                                  className="form-control "
                                  placeholder="Email"
                                  value={userData?.email}
                                  onChange={(e) => {
                                    const userObj = { ...userData };
                                    userObj.email = e.target.value;
                                    setUserData(userObj);
                                  }}
                                />
                              </div>
                            </div>

                            <div className="row mb-6">
                              <label className="col-lg-4 col-form-label fw-semibold fs-6">
                                <span className="required">Contact Phone</span>
                                <i
                                  className="fas fa-exclamation-circle ms-1 fs-7"
                                  data-bs-toggle="tooltip"
                                  title="Phone number must be active"
                                />
                              </label>

                              <div className="col-lg-8 fv-row">
                                <Input
                                  type="tel"
                                  className="form-control "
                                  placeholder="Phone number"
                                  value={userData?.contact_no}
                                  onChange={(e) => {
                                    const userObj = { ...userData };
                                    userObj.contact_no = e.target.value;
                                    setUserData(userObj);
                                  }}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="card-footer d-flex justify-content-end py-6 px-9 border-top border-top-dashed">
                            <button
                              className="btn btn-light btn-light-danger me-2 fs-7"
                              onClick={() => {
                                const localUserData = JSON.parse(
                                  localStorage.getItem("user")
                                );
                                const userObj = { ...userData };
                                userObj.name = localUserData?.name;
                                userObj.email = localUserData?.email;
                                userObj.contact_no = localUserData?.contact_no;
                                setUserData(userObj);
                              }}
                            >
                              Discard
                            </button>
                            <button
                              className="btn btn-primary  fs-7"
                              onClick={() => {
                                message.destroy();
                                message.loading("Loading...", 0);
                                // console.log(userData, "---------------");
                                SaveUserProfile(
                                  userData,
                                  JSON.parse(localStorage.getItem("user"))?.id
                                );
                              }}
                            >
                              Save Changes
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-12">
                    <div className="card card-flush h-lg-100 mb-5 mb-xl-5">
                      <div
                        className="card-header border-0 cursor-pointer"
                        role="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#kt_account_signin_method"
                      >
                        <div className="card-title m-0">
                          <h3 className="fw-bold m-0">Sign-in Method</h3>
                        </div>
                      </div>

                      <div
                        id="kt_account_settings_signin_method"
                        className="collapse show"
                      >
                        <div className="card-body border-top border-top-dashed p-9">
                          <div className="d-flex flex-wrap align-items-center">
                            <div id="kt_signin_email">
                              <div className="fs-6 fw-bold mb-1">
                                Email Address
                              </div>
                              <div className="fw-semibold text-gray-600">
                                admin@ecometrics.com
                              </div>
                            </div>

                            <div
                              id="kt_signin_email_edit"
                              className="flex-row-fluid d-none"
                            >
                              <form
                                id="kt_signin_change_email"
                                className="form"
                                noValidate="novalidate"
                              >
                                <div className="row mb-6">
                                  <div className="col-lg-6 mb-4 mb-lg-0">
                                    <div className="fv-row mb-0">
                                      <label
                                        htmlFor="emailaddress"
                                        className="form-label fs-6 fw-bold mb-3"
                                      >
                                        Enter New Email Address
                                      </label>
                                      <input
                                        type="email"
                                        className="form-control form-control-lg fs-7"
                                        id="emailaddress"
                                        placeholder="Email Address"
                                        name="emailaddress"
                                        defaultValue="admin@ecometrics.com"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="fv-row mb-0">
                                      <label
                                        htmlFor="confirmemailpassword"
                                        className="form-label fs-6 fw-bold mb-3"
                                      >
                                        Confirm Password
                                      </label>
                                      <input
                                        type="password"
                                        className="form-control form-control-lg fs-7"
                                        name="confirmemailpassword"
                                        id="confirmemailpassword"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="d-flex">
                                  <button
                                    id="kt_signin_submit"
                                    type="button"
                                    className="btn btn-primary  me-2 px-6 fs-7"
                                  >
                                    Update Email
                                  </button>
                                  <button
                                    id="kt_signin_cancel"
                                    type="button"
                                    className="btn btn-light btn-active-light-info px-6 fs-7"
                                  >
                                    Cancel
                                  </button>
                                </div>
                              </form>
                            </div>

                            <div
                              id="kt_signin_email_button"
                              className="ms-auto"
                            >
                              {/* <button className="btn btn-light btn-active-light-info fs-7">
                              Change Email
                            </button> */}
                            </div>
                          </div>

                          <div className="separator separator-dashed my-6" />

                          <div className="d-flex flex-wrap align-items-center mb-10">
                            <div id="kt_signin_password">
                              <div className="fs-6 fw-bold mb-1">Password</div>
                              <div className="fw-semibold text-gray-600">
                                ************
                              </div>
                            </div>

                            <div
                              id="kt_signin_password_edit"
                              className="flex-row-fluid d-none"
                            >
                              <form
                                id="kt_signin_change_password"
                                className="form"
                                noValidate="novalidate"
                              >
                                <div className="row mb-1">
                                  <div className="col-lg-4">
                                    <div className="fv-row mb-0">
                                      <label
                                        htmlFor="currentpassword"
                                        className="form-label fs-6 fw-bold mb-3"
                                      >
                                        Current Password
                                      </label>
                                      <input
                                        type="password"
                                        className="form-control form-control-lg "
                                        name="currentpassword"
                                        id="currentpassword"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-4">
                                    <div className="fv-row mb-0">
                                      <label
                                        htmlFor="newpassword"
                                        className="form-label fs-6 fw-bold mb-3"
                                      >
                                        New Password
                                      </label>
                                      <input
                                        type="password"
                                        className="form-control form-control-lg "
                                        name="newpassword"
                                        id="newpassword"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-4">
                                    <div className="fv-row mb-0">
                                      <label
                                        htmlFor="confirmpassword"
                                        className="form-label fs-6 fw-bold mb-3"
                                      >
                                        Confirm New Password
                                      </label>
                                      <input
                                        type="password"
                                        className="form-control form-control-lg "
                                        name="confirmpassword"
                                        id="confirmpassword"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="form-text mb-5">
                                  Password must be at least 8 character and
                                  contain symbols
                                </div>
                                <div className="d-flex">
                                  <button
                                    id="kt_password_submit"
                                    type="button"
                                    className="btn btn-primary me-2 px-6 fs-7"
                                  >
                                    Update Password
                                  </button>
                                  <button
                                    id="kt_password_cancel"
                                    type="button"
                                    className="btn btn-light btn-active-light-info px-6 fs-7"
                                  >
                                    Cancel
                                  </button>
                                </div>
                              </form>
                            </div>

                            <div
                              id="kt_signin_password_button"
                              className="ms-auto"
                            >
                              {/* <button className="btn btn-light btn-active-light-info fs-7">
                              Reset Password
                            </button> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ProfileWrapper>
  );
}
