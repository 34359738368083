import {
  Input,
  Popconfirm,
  Popover,
  Select,
  Tag,
  Tooltip,
  message,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import IvcsrTable from "../../../../component/ivcsr-table";
import Loading from "../../../../component/loading";
import NoData from "../../../../component/no-data";
import Pagination from "../../../../component/pagination";
import { DefaultPerPage, getUserId, timeSince } from "../../../../config";
import { SearchOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import ResponseMessageModal from "./walmart-lib/response-message-modal";
export default function (props) {
  const {
    GetReturnsAndRefundsOrder,
    WalmartReturnRefundOrder,
    GetDirectWalmartRefundAction,
    fakeActionReturnsRefunds,
  } = props;

  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageSize, setPageSize] = useState(DefaultPerPage);
  const [searchTerm, setSearchTerm] = useState("");
  const [returnStatus, setReturnStatus] = useState(null);
  const [refundStatus, setRefundStatus] = useState(null);
  const [isOpenResponseModal, setIsOpenResponseModal] = useState(false);
  const [reponseModalData, setResponseModalData] = useState([]);

  const GetReturnsAndRefundsOrderRes = useSelector(
    (state) =>
      state.WalmartReturnsRefunds.GetReturnsAndRefundsOrderResponse || {}
  );
  const GetDirectWalmartRefundsRes = useSelector(
    (state) => state.WalmartReturnsRefunds.GetDirectWalmartRefundsResponse || {}
  );
  const WalmartReturnsAndRefundsOrderRes = useSelector(
    (state) =>
      state.WalmartReturnsRefunds.WalmartReturnsAndRefundsOrderResponse || {}
  );
  useEffect(() => {
    const obj = {
      page: 1,
      perPage: pageSize,
      search: searchTerm,
      returnStatus: returnStatus,
      refundStatus: refundStatus,
    };
    GetReturnsAndRefundsOrder(obj);
    return () => {};
  }, []);

  useEffect(() => {
    if (GetReturnsAndRefundsOrderRes.status === true) {
      setList(GetReturnsAndRefundsOrderRes?.data?.records || []);
      console.log(GetReturnsAndRefundsOrderRes, "GetReturnsAndRefundsOrderRes");
      setLoading(false);
      setTotalPage(GetReturnsAndRefundsOrderRes?.data?.pagination?.totalCount);
      fakeActionReturnsRefunds("GetReturnsAndRefundsOrderResponse");
    } else if (GetReturnsAndRefundsOrderRes.status === false) {
      setLoading(false);
      fakeActionReturnsRefunds("GetReturnsAndRefundsOrderResponse");
    }
  }, [GetReturnsAndRefundsOrderRes]);

  useEffect(() => {
    if (GetDirectWalmartRefundsRes.status === true) {
      setLoading(true);
      setPage(1);
      const obj = {
        page: 1,
        perPage: pageSize,
        search: searchTerm,
        returnStatus: returnStatus,
        refundStatus: refundStatus,
      };
      GetReturnsAndRefundsOrder(obj);
      fakeActionReturnsRefunds("GetDirectWalmartRefundsResponse");
    } else if (GetDirectWalmartRefundsRes.status === false) {
      setLoading(false);
      setPage(1);
      const obj = {
        page: 1,
        perPage: pageSize,
        search: searchTerm,
        returnStatus: returnStatus,
        refundStatus: refundStatus,
      };
      GetReturnsAndRefundsOrder(obj);
      fakeActionReturnsRefunds("GetDirectWalmartRefundsResponse");
    }
  }, [GetDirectWalmartRefundsRes]);

  useEffect(() => {
    if (WalmartReturnsAndRefundsOrderRes.status === true) {
      message.destroy();
      message.success(
        WalmartReturnsAndRefundsOrderRes?.message ||
          "Request submitted to walmart for issue refund."
      );
      setPage(1);
      setLoading(true);
      const obj = {
        page: 1,
        perPage: pageSize,
        search: searchTerm,
        returnStatus: returnStatus,
        refundStatus: refundStatus,
      };
      GetReturnsAndRefundsOrder(obj);
      setResponseModalData(WalmartReturnsAndRefundsOrderRes);
      setIsOpenResponseModal(true);
      fakeActionReturnsRefunds("WalmartReturnsAndRefundsOrderResponse");
    } else if (WalmartReturnsAndRefundsOrderRes.status === false) {
      message.destroy();
      message.error(
        WalmartReturnsAndRefundsOrderRes?.message || "Request Failed"
      );
      // setResponseModalData(WalmartReturnsAndRefundsOrderRes);
      // setIsOpenResponseModal(true);
      fakeActionReturnsRefunds("WalmartReturnsAndRefundsOrderResponse");
    }
  }, [WalmartReturnsAndRefundsOrderRes]);

  const columns = [
    {
      title: "Sr. No.",
      ellipsis: true,
      align: "left",
      render: (text) => {
        return <span>{(page - 1) * pageSize + 1 + text.key}</span>;
      },
    },
    {
      title: "Action",
      align: "center",
      render: (text) => {
        // const newStr = text?.order_status;
        // const isAcknowledge = newStr.includes("Acknowledged");
        return (
          <div className="">
            <div className="d-flex align-items-center justify-content-between">
              <div className="p-1">
                <Tooltip title="View Details" placement="bottom">
                  <Link
                    id={Math.random()}
                    to={{
                      pathname: "returns-refunds/details",
                      search: `?return_order_id=${text?.return_order_id}&purchase_order_line_number=${text?.purchase_order_line_number}`,
                    }}
                    target="_blank"
                  >
                    <button className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                      <img
                        src="/assets/media/visible.png"
                        style={{ width: "23px" }}
                      />
                    </button>
                  </Link>
                </Tooltip>
              </div>

              {text?.return_status === "DELIVERED" ||
              text?.return_status === "INITIATED" ? (
                <div className="p-1">
                  <Tooltip title="Issue Refund" placement="bottom">
                    <Popconfirm
                      title="Issue Refund"
                      description="Are you sure to return this order?"
                      onConfirm={() => {
                        const Obj = {
                          customerOrderId: text?.customer_order_id,
                          refundLines: [
                            {
                              returnOrderLineNumber: text?.return_order_line_no,
                              quantity: {
                                unitOfMeasure: text?.quantity_unit_of_measure,
                                measurementValue:
                                  text?.quantity_measurement_value,
                              },
                            },
                          ],
                        };
                        console.log(Obj, "Obj");
                        message.destroy();
                        message.loading("Loading...", 0);
                        WalmartReturnRefundOrder(text?.return_order_id, Obj);
                      }}
                      placement="right"
                    >
                      <button className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                        <img
                          src="/assets/media/refund-policy.png"
                          style={{ width: "25px" }}
                        />
                      </button>
                    </Popconfirm>
                  </Tooltip>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        );
      },
    },
    {
      title: "Product",
      width: 300,
      render: (text) => {
        return (
          <div className="d-flex align-items-center border-start border-2 border-info p-3 rounded">
            <div className="symbol symbol-50px  me-3">
              <img src={`${text?.image_url}`} alt="" />
            </div>
            {/*end::Avatar*/}
            {/*begin::User details*/}
            <div className="d-flex flex-column">
              <Popover
                content={
                  <div
                    style={{
                      height: "max-content",
                      maxWidth: "400px",
                    }}
                  >
                    {text?.product_name}
                  </div>
                }
                placement="bottom"
              >
                <div
                  className="text-primary mb-1 fw-bolder text-one fs-5 popoverActionIconSingleLine"
                  style={{ minWidth: "200px" }}
                >
                  {text?.product_name}
                </div>
              </Popover>

              <div>
                <span className="fs-6">
                  <Tag color="orange">
                    <b>SKU:</b> {text?.sku}
                  </Tag>
                </span>
                <br />
                <span className="fs-6">
                  <Tag color="green" className="mt-2 fw-bold">
                    <b className="me-2">Quantity:</b>
                    {text?.refund_quantity}
                  </Tag>
                </span>
                {/* <span className="fs-6 ">
                  <Tag color="purple" className="mt-1">
                    <b>Order:</b> {text?.customer_order_id}
                  </Tag>
                </span> */}
              </div>
            </div>
          </div>
        );
      },
    },

    {
      title: "Purchase Order",
      dataIndex: "purchase_order_id",
      key: "purchase_order_id",
      render: (text) => {
        return <span className="">{text || "-"}</span>;
      },
    },
    {
      title: "Return Order Id",
      dataIndex: "return_order_id",
      key: "return_order_id",
      render: (text) => {
        return <span className="">{text || "-"}</span>;
      },
    },
    {
      title: "Customer Order Id",
      dataIndex: "customer_order_id",
      key: "customer_order_id",
      render: (text) => {
        return <span className="">{text || "-"}</span>;
      },
    },
    {
      title: "Total Refund",
      dataIndex: "total_refund",
      key: "total_refund",
      render: (text) => <span>${text || "-"}</span>,
    },
    {
      title: "Return Status",
      dataIndex: "return_status",
      key: "return_status",
      render: (text) => {
        return (
          <Tag
            color={
              text === "COMPLETED"
                ? "blue"
                : text === "DELIVERED"
                ? "success"
                : "magenta"
            }
          >
            {text}
          </Tag>
        );
      },
    },
    {
      title: "Refund Status",
      dataIndex: "current_refund_status",
      key: "current_refund_status",
      render: (text) => {
        return (
          <Tag
            color={
              text === "REFUND_COMPLETED"
                ? "success"
                : text === "NOT_REFUNDED"
                ? "orange"
                : text === "CANCELLED"
                ? "red"
                : "purple"
            }
          >
            {text}
          </Tag>
        );
      },
    },
    {
      title: "Return Order Date",
      dataIndex: "return_order_date",
      key: "return_order_date",
      render: (text) => {
        return <span>{moment(text).format("MMM DD, YYYY")}</span>;
      },
    },
    {
      title: "Return Reason",
      dataIndex: "return_reason",
      key: "return_reason",
      render: (text) => {
        const reason = text?.split("_")?.join(" ")?.toLowerCase();
        return (
          <span className="fw-bold" style={{ textTransform: "capitalize" }}>
            {reason || "-"}
          </span>
        );
      },
    },
    {
      title: "Customer",
      dataIndex: "customer_name",
      key: "customer_name",
      render: (text) => {
        return <span className="fw-bold">{text || "-"}</span>;
      },
    },

    // {
    //   title: "Quantity",
    //   dataIndex: "refund_quantity",
    //   key: "refund_quantity",
    //   render: (text) => <span>{text || "-"}</span>,
    // },
    // {
    //   title: "Created At",
    //   dataIndex: "created_at",
    //   key: "created_at",
    //   render: (text) => (
    //     <span>
    //       {moment(new Date(text * 1000)).format("MMM DD, YYYY hh:mm A")}
    //     </span>
    //   ),
    // },
  ];

  const onPageNo = (e) => {
    setLoading(true);
    const apiObj = {
      page: e,
      perPage: pageSize,
      search: searchTerm,
      returnStatus: returnStatus,
      refundStatus: refundStatus,
    };
    GetReturnsAndRefundsOrder(apiObj);
    setPage(e);
  };

  const onPerPage = (e) => {
    setPage(1);
    setPageSize(e);
    setLoading(true);
    const apiObj = {
      page: 1,
      perPage: e,
      search: searchTerm,
      returnStatus: returnStatus,
      refundStatus: refundStatus,
    };
    GetReturnsAndRefundsOrder(apiObj);
  };

  return (
    <div className="fadeInRight">
      <>
        <div className="d-flex flex-column flex-column-fluid">
          <div id="kt_app_content" className="app-content flex-column-fluid">
            <div
              id="kt_app_content_container"
              className="app-container container-fluid"
            >
              <div className="row gy-5 g-xl-5">
                <div className="col-xxl-12">
                  <div className="card card-xxl-stretch mb-5 mb-xl-8">
                    <div className="card-header border-bottom-dashed">
                      <h3 className="card-title ">
                        {/* <span className="card-label fw-bold text-gray-900">
                          Returns & Refunds
                        </span> */}
                      </h3>

                      <div className="card-toolbar">
                        <div className="position-relative me-2">
                          <Input
                            type="text"
                            className="text-dark w-300px fs-7 fw-bold ant_common_input"
                            name="all_search"
                            placeholder="by PO, Return Order,Customer Order Id"
                            value={searchTerm}
                            size="large"
                            onChange={(e) => {
                              setSearchTerm(e.target.value);
                            }}
                            onPressEnter={(e) => {
                              setPage(1);
                              const obj = {
                                page: 1,
                                perPage: pageSize,
                                search: e.target.value,
                                returnStatus: returnStatus,
                                refundStatus: refundStatus,
                              };
                              setLoading(true);
                              GetReturnsAndRefundsOrder(obj);
                            }}
                            prefix={
                              <SearchOutlined
                                style={{ fontSize: "19px", color: "grey" }}
                              />
                            }
                          />
                        </div>
                        <div className="position-relative me-2">
                          <Select
                            options={[
                              { label: "COMPLETED", value: "COMPLETED" },
                              { label: "DELIVERED", value: "DELIVERED" },
                              { label: "INITIATED", value: "INITIATED" },
                            ]}
                            placeholder="Select Return Status"
                            className="w-200px"
                            size="large"
                            allowClear
                            onChange={(e) => {
                              setReturnStatus(e);
                              setPage(1);
                              const obj = {
                                page: 1,
                                perPage: pageSize,
                                search: searchTerm,
                                returnStatus: e,
                                refundStatus: refundStatus,
                              };
                              setLoading(true);
                              GetReturnsAndRefundsOrder(obj);
                            }}
                          />
                        </div>
                        <div className="position-relative me-2">
                          <Select
                            options={[
                              {
                                label: "REFUND INITIATED",
                                value: "REFUND_INITIATED",
                              },
                              {
                                label: "REFUND COMPLETED",
                                value: "REFUND_COMPLETED",
                              },
                              { label: "NOT REFUNDED", value: "NOT_REFUNDED" },
                              { label: "CANCELLED", value: "CANCELLED" },
                            ]}
                            placeholder="Select Refund Status"
                            className="w-200px"
                            size="large"
                            allowClear
                            onChange={(e) => {
                              setRefundStatus(e);
                              setPage(1);
                              const obj = {
                                page: 1,
                                perPage: pageSize,
                                search: searchTerm,
                                returnStatus: returnStatus,
                                refundStatus: e,
                              };
                              setLoading(true);
                              GetReturnsAndRefundsOrder(obj);
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="card-body pb-5 pt-0">
                      <div className="mt-2">
                        <div className="position-relative">
                          <Tooltip
                            title="Refresh Return & Refunds"
                            placement="right"
                          >
                            <button
                              className="btn btn-icon btn-info"
                              onClick={() => {
                                setLoading(true);
                                GetDirectWalmartRefundAction();
                              }}
                              disabled={loading}
                            >
                              <i className="ki-outline ki-arrows-circle fs-1 customIcon" />
                              {/* <img
                                src="/assets/media/refresh_.png"
                                style={{ width: "24px", objectFit: "contain" }}
                              /> */}
                            </button>
                          </Tooltip>
                        </div>
                      </div>
                      <div className="table-responsive mt-5">
                        {loading ? (
                          <Loading />
                        ) : list?.length !== 0 ? (
                          <>
                            <IvcsrTable
                              columns={columns?.map((d) => ({
                                ...d,
                                width:
                                  d?.title === "Product"
                                    ? 400
                                    : d?.title?.length * 13,
                              }))}
                              fixed={"top"}
                              sticky={{
                                offsetHeader: "10px",
                              }}
                              scroll={{
                                x: "max-content",
                                y: "calc(100vh - 250px)",
                              }}
                              dataSource={list}
                              rowKey="key"
                              loading={loading}
                              pagination={false}
                            />
                          </>
                        ) : (
                          <NoData type="new" />
                        )}
                      </div>
                      <Pagination
                        loading={loading || list?.length === 0}
                        pageSize={pageSize}
                        page={page}
                        totalPage={totalPage}
                        onPerPage={onPerPage}
                        onPageNo={onPageNo}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
      {isOpenResponseModal && (
        <ResponseMessageModal
          show={isOpenResponseModal}
          onHide={() => {
            setResponseModalData([]);
            setIsOpenResponseModal(false);
          }}
          data={reponseModalData?.data}
        />
      )}
    </div>
  );
}
