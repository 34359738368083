import axiosCall from "../../configurations/network-services/axiosCall";

export const loginAction = (payload) => {
  const path = `login`;
  const responseType = "LOGIN";
  const body = payload;
  return axiosCall("post", path, responseType, body);
};

export const SignUpAction = (payload) => {
  const path = `signup`;
  const responseType = "SIGN_UP";
  const body = payload;
  return axiosCall("post", path, responseType, body);
};
export const UserActiveAction = (payload) => {
  const path = `user-activate`;
  const responseType = "USER_ACTIVATION";
  const body = payload;
  return axiosCall("post", path, responseType, body);
};

export const fakeActionAuth = (data) => async (dispatch) => {
  dispatch({ type: "FAKE_ACTION_AUTH", state: data });
};
