import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ManageUser from "../../../modules/pages/admin/manage-user";
import {
  GetUserList,
  SwitchUserAction,
  AddUserAction,
  DeleteUserAction,
  fakeActionManageUser,
} from "../../../redux/modules/admin/manage-user/manage-user.action";

const mapStateToProps = (state) => ({
  GetUserListResponse: state.Auth.GetUserListResponse,
  SwitchUserResponse: state.Auth.SwitchUserResponse,
  AddUserResponse: state.Auth.AddUserResponse,
  DeleteUserResponse: state.Auth.DeleteUserResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      GetUserList,
      SwitchUserAction,
      AddUserAction,
      DeleteUserAction,
      fakeActionManageUser,
    },
    dispatch
  );

const ManageUserZonFlip = connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageUser);

export default ManageUserZonFlip;
