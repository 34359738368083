import styled from "styled-components";

export const ProductDetailsWrapper = styled.div`
  .note {
    height: 191px;
    overflow: auto;
    border: 1px solid rgba(136, 205, 255, 0.28);
    border-radius: 2px;
    margin: 10px 0px;
    width: 100%;
  }
  .note .TextEditor {
    height: 181px;
    min-height: 181px;
    width: 100%;
    resize: none;
    color: rgb(0, 0, 0);
    /* background-color: rgb(226, 229, 239); */
    border: none;
    background-repeat: repeat-y, repeat;
    background-size: 100% 40px;
    /* font-size: 12px; */
    font-weight: 500;
    line-height: 35px;
    padding: 10px 15px 19px;
    background-image: url(../../assets/media/Line-01.png);
    overflow: hidden !important;
  }
  .tox .tox-menubar,
  .tox-toolbar-overlord,
  .tox .tox-statusbar {
    display: none !important;
  }

  .card-rec {
    background: #4318ff12;
    border: 1px dashed #ddd6fb;
    box-shadow: none;
  }
  .bg-icon-m {
    padding: 3px;
    border-radius: 7px;
    background: #f3567c;
    color: #fff !important;
    margin-left: -20px;
    font-size: 20px !important;
  }
  .fs-13 {
    font-size: 12px !important;
  }

  .cus-prog .progress {
    width: 40px;
    height: 40px;
    background: none;
    position: relative;
  }

  .progress::after {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 4px solid #eee;
    position: absolute;
    top: 0;
    left: 0;
  }

  .progress > span {
    width: 50%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    z-index: 1;
  }

  .cus-prog .progress .progress-left {
    left: 0;
  }

  .cus-prog .progress .progress-bar {
    width: 100%;
    height: 100%;
    background: none;
    border-width: 4px;
    border-style: solid;
    position: absolute;
    top: 0;
  }

  .cus-prog .progress .progress-left .progress-bar {
    left: 100%;
    border-top-right-radius: 80px;
    border-bottom-right-radius: 80px;
    border-left: 0;
    -webkit-transform-origin: center left;
    transform-origin: center left;
  }

  .cus-prog .progress .progress-right {
    right: 0;
  }

  .cus-prog .progress .progress-right .progress-bar {
    left: -100%;
    border-top-left-radius: 80px;
    border-bottom-left-radius: 80px;
    border-right: 0;
    -webkit-transform-origin: center right;
    transform-origin: center right;
  }

  .cus-prog .progress .progress-value div {
    font-size: 11px;
    margin-bottom: 0;
    font-weight: bolder;
  }
  .cus-prog .progress .progress-value {
    position: absolute;
    top: 0;
    left: 0;
  }
`;
export const ProductTableWrapper = styled.div`
  .ant-checkbox-inner {
    height: 20px !important;
    width: 20px !important;
    border: 1px solid blue !important;
  }
`;
