import { Select, Tooltip, Input } from "antd";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

export default function (props) {
  const { supplierList, replenishmentModalData, setReplenishmentModalData } =
    props;
  // const [supplierData, setSupplierData] = useState({
  //   supplier_id: 1,
  //   target_stock_days: 5,
  //   fba_buffer_days: 1,
  // });

  // const [supplierList, setSupplierList] = useState([
  //   { supplier_name: "Test Supplier", id: 1 },
  //   { supplier_name: "New Supplier", id: 2 },
  // ]);
  const onChangeField = (key, value, rest) => {
    setReplenishmentModalData({
      ...replenishmentModalData,
      [key]: value,
      ...rest,
    });
  };
  return (
    <>
      {" "}
      <div className="row">
        <div className="col-12 d-flex justify-content-between align-items-center">
          <div>
            <label className="fw-bold fs-6">Supplier Name</label>
          </div>
          <div className="d-flex align-items-center">
            <Select
              className="me-5"
              size="large"
              style={{
                width: "230px",
              }}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              placeholder="Select Supplier"
              value={parseInt(replenishmentModalData?.supplier_id) || null}
              onChange={(e) => {
                onChangeField("supplier_id", e);
              }}
              options={supplierList?.map((d) => {
                return {
                  label: d?.supplier_name,
                  value: d?.id,
                };
              })}
            />
            <Tooltip
              placement="left"
              title="Supplier Name"
              trigger="hover"
              className="me-4"
            >
              <i
                style={{ fontSize: "17px", color: "#827dcb" }}
                className="bi bi-info-circle mx-2 cursor-pointer"
              ></i>
            </Tooltip>
          </div>
        </div>
        <div className="separator my-5" />
        <div className="col-12 d-flex justify-content-between align-items-center">
          <div>
            <label className="fw-bold fs-6">
              Target stock range after new order (days)
            </label>
          </div>
          <div className="d-flex align-items-center">
            <Input
              className="ant_common_input me-5"
              size="large"
              style={{
                width: "230px",
              }}
              value={replenishmentModalData?.target_stock_days}
              // value={0}

              defaultValue={0}
              // onPressEnter={() => {
              //   onPressEnterApi();
              // }}
              onBlur={(e) => {
                if (!e.target.value) {
                  onChangeField("target_stock_days", 0);
                }
              }}
              onChange={(e) => {
                if (!e.target.value) {
                  onChangeField("target_stock_days", e.target.value);
                }
                const newVal = e.target.value.split(".").join("");
                const validation = /^[0-9]+$/.test(newVal);
                if (validation) {
                  onChangeField("target_stock_days", e.target.value);
                }
              }}
            />
            <Tooltip
              placement="left"
              title="When placing a new order, for how many days of selling should the new inventory be sufficient? In other words, this parameter describes how often you'd like to reorder."
              trigger="hover"
              className="me-4"
            >
              <i
                style={{ fontSize: "17px", color: "#827dcb" }}
                className="bi bi-info-circle mx-2 cursor-pointer"
              ></i>
            </Tooltip>
          </div>
        </div>
        <div className="separator my-5" />
        <div className="col-12 d-flex justify-content-between align-items-center">
          <div>
            <label className="fw-bold fs-6">FBA buffer (days)</label>
          </div>
          <div className="d-flex align-items-center">
            <Input
              value={replenishmentModalData?.fba_buffer_days}
              defaultValue={0}
              // onPressEnter={() => {
              //   onPressEnterApi();
              // }}
              onBlur={(e) => {
                if (!e.target.value) {
                  onChangeField("fba_buffer_days", 0);
                }
              }}
              onChange={(e) => {
                if (!e.target.value) {
                  onChangeField("fba_buffer_days", e.target.value);
                }
                const newVal = e.target.value.split(".").join("");
                const validation = /^[0-9]+$/.test(newVal);
                if (validation) {
                  onChangeField("fba_buffer_days", e.target.value);
                }
              }}
              className="ant_common_input me-5"
              size="large"
              style={{
                width: "230px",
              }}
            />
            <Tooltip
              placement="left"
              title="When calculating the time to reorder and the recommended amount to reorder, sellerboard will make sure your stock doesn't fall below this buffer."
              trigger="hover"
              className="me-4"
            >
              <i
                style={{ fontSize: "17px", color: "#827dcb" }}
                className="bi bi-info-circle mx-2 cursor-pointer"
              ></i>
            </Tooltip>
          </div>
        </div>
        {/* <div className="separator my-5" /> */}
      </div>
    </>
  );
}
