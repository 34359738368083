import {
  Checkbox,
  Dropdown,
  Input,
  Popconfirm,
  Popover,
  Select,
  Tag,
  message,
  theme,
} from "antd";
import moment from "moment";
import React, { useEffect, useState, useContext } from "react";
import { useSelector } from "react-redux";
import IvcsrTable from "../../../../../component/ivcsr-table";
import Loading from "../../../../../component/loading";
import NoData from "../../../../../component/no-data";
import Pagination from "../../../../../component/pagination";
import {
  DefaultPerPage,
  SalesChannelFlag,
  getUserId,
  timeSince,
} from "../../../../../config";
import { SearchOutlined } from "@ant-design/icons";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { skuDataSelectOption } from "../../../../../config/static-select-option";
import { GlobalContext } from "../../../../../commonContext";
import { CheckboxWrapper } from "../../../sales-analytics/style";
import WalmartAddItemModal from "./walmart-add-item-modal";
import writeXlsxFile from "write-excel-file";
import GlobalSearchModal from "./global-search-modal";
import { Link } from "react-router-dom";
const { useToken } = theme;
export default function (props) {
  const {
    GetWalmartProductList,
    fakeActionSkuData,
    GetSalesChannelList,
    GetWalmartProductsSearch,
    fakeActionSalesAnalytics,
  } = props;

  const { token } = useToken();
  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
    minWidth: "100px",
  };

  const GetWalmartProductListRes = useSelector(
    (state) => state.SKUData.GetWalmartProductListResponse || {}
  );

  const GetSalesChannelListRes = useSelector(
    (state) => state.SalesAnalytics.GetSalesChannelListResponse || {}
  );

  const GetWalmartProductSearchListRes = useSelector(
    (state) => state.SKUData.GetWalmartProductSearchListResponse || {}
  );

  const [filter, setFilter] = useState(null);
  const [salesChannelList, setsalesChannelList] = useState([]);
  const [selectedSalesChannel, setSelectedSalesChannel] = useState([]);
  const [selectedSalesChannelId, setSelectedSalesChannelId] = useState([]);
  const [salesChannelLoading, setSalesChannelLoading] = useState(true);
  const [selectedCurrencySign, setSelectedCurrencySign] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [publish_status, setPublish_status] = useState(null);
  const [lifecycle_status, setLifecycle_status] = useState(null);

  const [walmartList, setWalmartList] = useState([]);
  const [walmartLoading, setWalmartLoading] = useState(true);
  const [walmartPage, setWalmartPage] = useState(1);
  const [walmartTotalPage, setWalmartTotalPage] = useState(1);
  const [walmartPageSize, setWalmartPageSize] = useState(DefaultPerPage);
  const [isOpenCreateModal, setIsOpenCreateModal] = useState(false);
  const [isExportFlag, setIsExportFlag] = useState(false);
  const [openGlobalSearchModal, setOpenGlobalSearchModal] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState({});
  // const [searchByType, setSearchByType] = useState("SKU");
  console.log(walmartList, "walmartListwalmartListwalmartListwalmartList");
  useEffect(() => {
    GetSalesChannelList();
    return () => {};
  }, []);

  useEffect(() => {
    if (GetSalesChannelListRes?.status === true) {
      setSalesChannelLoading(false);
      setsalesChannelList(GetSalesChannelListRes?.data?.sales_channel);
      setSelectedSalesChannel("Amazon.com");
      setSelectedSalesChannelId("ATVPDKIKX0DER");
      setSelectedCurrencySign("$");

      const apiObj = {
        page: 1,
        perPage: walmartPageSize,
        sales_channel: "Amazon.com",
        search_key: searchTerm,
        isExport: 0,
        publish_status: publish_status,
        lifecycle_status: lifecycle_status,
      };
      setWalmartLoading(true);
      GetWalmartProductList(apiObj);

      fakeActionSalesAnalytics("GetSalesChannelListResponse");
    } else if (GetSalesChannelListRes?.status === false) {
      fakeActionSalesAnalytics("GetSalesChannelListResponse");
    }
  }, [GetSalesChannelListRes]);

  useEffect(() => {
    if (GetWalmartProductListRes.status === true) {
      message.destroy();
      if (GetWalmartProductListRes?.data?.records?.length > 0) {
        if (isExportFlag) {
          downloadXl(GetWalmartProductListRes?.data?.records);
          setIsExportFlag(false);
        } else {
          setWalmartList(GetWalmartProductListRes?.data?.records);
          console.log(GetWalmartProductListRes, "GetWalmartProductListRes");
          setWalmartLoading(false);
          setWalmartTotalPage(
            GetWalmartProductListRes?.data?.pagination?.totalCount
          );
        }
      } else {
        // GetWalmartProductsSearch({ search: searchTerm, type: searchByType });
        setWalmartLoading(false);
        setWalmartList([]);
      }
      fakeActionSkuData("GetWalmartProductListResponse");
    } else if (GetWalmartProductListRes.status === false) {
      setWalmartLoading(false);
      fakeActionSkuData("GetWalmartProductListResponse");
    }
  }, [GetWalmartProductListRes]);

  // useEffect(() => {
  //   if (GetWalmartProductSearchListRes?.status === true) {
  //     console.log(
  //       GetWalmartProductSearchListRes,
  //       "GetWalmartProductSearchListRes"
  //     );
  //     setWalmartLoading(false);
  //     setWalmartList(GetWalmartProductSearchListRes?.data?.ItemResponse);
  //     fakeActionSkuData("GetWalmartProductSearchListResponse");
  //   } else if (GetWalmartProductSearchListRes?.status === false) {
  //     setWalmartLoading(false);
  //     fakeActionSkuData("GetWalmartProductSearchListResponse");
  //   }
  // }, [GetWalmartProductSearchListRes]);

  const DeleteProductApi = async (sku) => {
    console.log(props, "props------");
    const obj = {
      sku: sku,
    };
    try {
      message.destroy();
      message.info("Deleting...", 0);

      const response = await fetch("https://service.ivcsr.com/api/v1/items", {
        method: "delete",
        headers: {
          AUTHORIZATION: `Bearer ${localStorage.getItem("token")}`,
          "MARKETPLACE-ID": "ATVPDKIKX0DER",
          // user_id: 8,
          // user_id: JSON.parse(localStorage.getItem("user"))?.id,
          "USER-ID": getUserId(),
          MODE: "API",
          "CONTENT-TYPE": "application/json",
        },
        body: JSON.stringify({ sku: sku }),
      });

      if (response.ok) {
        // Handle successful upload
        message.destroy();

        const responseData = await response.json();
        const finalJson = JSON.stringify(responseData);
        console.log(finalJson, "finalJson");
        setWalmartPage(1);
        const apiObj = {
          page: 1,
          perPage: walmartPageSize,
          sales_channel: selectedSalesChannel,
          search_key: searchTerm,
          isExport: 0,
          publish_status: publish_status,
          lifecycle_status: lifecycle_status,
        };
        setWalmartLoading(true);
        GetWalmartProductList(apiObj);
      } else {
        // Handle upload error
        const errorData = await response.json();
        const finalJson = JSON.stringify(errorData);
        // Display error message
        message.destroy();
        message.error(finalJson);
      }
    } catch (error) {
      message.destroy();
      message.error("Error");
    }
  };

  const defaultColumns = [
    {
      title: "#",
      ellipsis: true,
      align: "left",
      render: (text) => {
        return (
          <span>{(walmartPage - 1) * walmartPageSize + 1 + text.key}</span>
        );
      },
    },
    {
      title: "Product",
      render: (text) => {
        return (
          <div className="d-flex align-items-center border-start border-2 border-info p-3 rounded">
            <div className="symbol symbol-50px  me-3">
              <img src={`${text?.primary_image_url}`} alt="" />
            </div>
            {/*end::Avatar*/}
            {/*begin::User details*/}
            <div className="d-flex flex-column">
              <Popover
                content={
                  <div
                    style={{
                      height: "max-content",
                      maxWidth: "380px",
                    }}
                  >
                    {text?.product_name}
                  </div>
                }
                placement="bottomLeft"
              >
                <div
                  className="text-primary mb-1 fw-bolder text-one fs-6 popoverActionIconSingleLine"
                  style={{ minWidth: "100px" }}
                >
                  {text?.product_name}
                </div>
              </Popover>
              <div>
                <span className="fs-6">
                  <Link target="blank" to={{ pathname: text?.item_page_url }}>
                    {" "}
                    <Tag color="orange" style={{ textDecoration: "underline" }}>
                      <b>SKU:</b> {text?.sku}
                    </Tag>
                  </Link>
                </span>
                <br />
                <span className="fs-6 ">
                  <Tag color="purple" className="mt-1">
                    <b>Brand:</b> {text?.brand}
                  </Tag>
                </span>
              </div>
            </div>
          </div>
        );
      },
    },
  ];

  const actionColumns = [
    {
      title: "Marketplace Id",
      dataIndex: "marketplace_id",
      key: "marketplace_id",
      render: (text) => <span>{text || "-"}</span>,
    },
    {
      title: "Action",
      render: (text) => {
        const userId = JSON.parse(localStorage?.getItem("user")).id;
        return (
          <div className="d-flex">
            {/* <button
              onClick={() => {
                setSelectedRowData(text);
                setIsOpenCreateModal(true);
              }}
              className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2"
            >
              <i className="ki-outline ki-pencil fs-2 text-info" />
            </button> */}
            {text?.is_deleted != 1 && (
              <Popconfirm
                title="Are you srte to delete this product ?"
                onConfirm={() => {
                  DeleteProductApi(text?.sku);
                }}
                onCancel={() => {}}
                okText="Yes"
                cancelText="No"
              >
                <div>
                  <button class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                    <i class="ki-outline ki-trash fs-2 text-danger"></i>
                  </button>
                </div>
              </Popconfirm>
            )}
          </div>
        );
      },
    },
  ];
  const walmartColumns = [
    {
      title: "Category",
      dataIndex: "product_category",
      key: "product_category",
      render: (text) => <span>{text || "-"}</span>,
    },
    {
      title: "Buy Box Price",
      dataIndex: "buy_box_item_price",
      key: "buy_box_item_price",
      sorter: (a, b) => a?.buy_box_item_price - b?.buy_box_item_price,
      render: (text) => <span>$&nbsp;{text || "0"}</span>,
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      sorter: (a, b) => a?.price - b?.price,
      render: (text) => <span>$&nbsp;{text || "0"}</span>,
    },
    {
      title: "Item Id",
      dataIndex: "item_id",
      key: "item_id",
      render: (text) => <span>{text || "-"}</span>,
    },
    {
      title: "GTIN",
      dataIndex: "gtin",
      key: "gtin",
      render: (text) => <span>{text || "-"}</span>,
    },
    {
      title: "UPC",
      dataIndex: "upc",
      key: "upc",
      render: (text) => <span>{text || "-"}</span>,
    },
    {
      title: "Publish Status",
      dataIndex: "publish_status",
      key: "publish_status",
      render: (text) => (
        <Tag
          color={
            text === "PUBLISHED"
              ? "success"
              : text === "UNPUBLISHED"
              ? "blue"
              : "error"
          }
        >
          {text || "-"}
        </Tag>
      ),
    },
    {
      title: "Lifecycle Status",
      dataIndex: "lifecycle_status",
      key: "lifecycle_status",
      render: (text) => (
        <Tag
          color={
            text === "RETIRED"
              ? "error"
              : text === "ACTIVE"
              ? "green"
              : "purple"
          }
        >
          {text || "-"}
        </Tag>
      ),
    },
    {
      title: "Fulfillment Type",
      dataIndex: "fulfillment_type",
      key: "fulfillment_type",
      render: (text) => <span>{text || "-"}</span>,
    },
    {
      title: "Fulfillment Lag Time",
      dataIndex: "fulfillment_lag_time",
      key: "fulfillment_lag_time",
      render: (text) => <span>{text || "-"}</span>,
    },
  ];

  const onWalmartPageNo = (e) => {
    setWalmartLoading(true);
    const apiObj = {
      page: e,
      perPage: walmartPageSize,
      sales_channel: selectedSalesChannel,
      search_key: searchTerm,
      isExport: 0,
      publish_status: publish_status,
      lifecycle_status: lifecycle_status,
    };
    GetWalmartProductList(apiObj);
    setWalmartPage(e);
  };

  const onWalmartPerPage = (e) => {
    setWalmartPage(1);
    setWalmartPageSize(e);
    setWalmartLoading(true);
    const apiObj = {
      page: 1,
      perPage: e,
      sales_channel: selectedSalesChannel,
      search_key: searchTerm,
      isExport: 0,
      lifecycle_status: lifecycle_status,
      publish_status: publish_status,
    };
    GetWalmartProductList(apiObj);
  };

  const [selectedFilterCheckbox, setSelectedFilterCheckbox] = useState([
    "product_category",
    "buy_box_item_price",
    "price",
    "item_id",
    "gtin",
    "upc",
    "publish_status",
    "fulfillment_type",
    "lifecycle_status",
    "fulfillment_lag_time",
    "marketplace_id",
  ]);

  const checkoxOptions = walmartColumns?.map((d) => ({
    label: d?.title,
    value: d?.key,
  }));

  const exportHeader = [
    { value: "Product", type: String },
    { value: "Brand", type: String },
    { value: "SKU", type: String },
    { value: "Category", type: String },
    { value: "Buy Box Price", type: String },
    { value: "Price", type: String },
    { value: "Item Id", type: String },
    { value: "GTIN", type: String },
    { value: "UPC", type: String },
    { value: "Marketplace Id", type: String },
  ];

  const downloadXl = async (exportData) => {
    let dataRow = [];
    await exportData.map((d) => {
      const newList = [
        d?.product_name,
        d?.brand,
        d?.sku,
        d?.product_category,
        d?.buy_box_item_price,
        d?.price,
        d?.item_id,
        d?.gtin,
        d?.upc,
        d?.marketplace_id,
      ];
      dataRow = [
        ...dataRow,
        newList.map((d) => {
          return { value: d };
        }),
      ];
    });
    const data = [exportHeader, ...dataRow];
    await writeXlsxFile(data, {
      fileName: "Walmart Product Details.xlsx",
    });
    message.destroy();
  };

  return (
    <div className="fadeInRight">
      <>
        <div className="d-flex flex-column flex-column-fluid">
          <div id="kt_app_content" className="app-content flex-column-fluid">
            <div
              id="kt_app_content_container"
              className="app-container container-fluid"
            >
              {/*begin::Row*/}

              <div className="row gy-5 g-xl-5">
                {/*begin::Col*/}
                <div className="col-xxl-12">
                  {/*begin::Tables Widget 9*/}
                  <div className="card card-xxl-stretch mb-5 mb-xl-8">
                    {/*begin::Header*/}
                    <div className="card-header border-bottom-dashed">
                      {/*end::Title*/}
                      {/*begin::Title*/}
                      <h3 className="card-title ">
                        <span className="card-label fw-bold text-gray-900 d-flex gap-2">
                          {/* <div className="position-relative">
                            <Select
                              options={[
                                { label: "GTIN", value: "GTIN" },
                                { label: "UPC", value: "UPC" },
                                { label: "ISBN", value: "ISBN" },
                                { label: "EAN", value: "EAN" },
                                { label: "SKU", value: "SKU" },
                                { label: "ITEM_ID", value: "ITEM_ID" },
                              ]}
                              size="large"
                              value={searchByType}
                              onChange={(e) => {
                                setSearchByType(e);
                                setSearchTerm("");
                              }}
                              className="w-100px"
                            />
                          </div> */}

                          <div className="position-relative">
                            <Input
                              type="text"
                              className="text-dark w-250px fs-7 fw-bold ant_common_input"
                              name="all_search"
                              placeholder="Search by Brand or SKU..."
                              value={searchTerm}
                              onChange={(e) => {
                                setSearchTerm(e.target.value);
                              }}
                              onPressEnter={(e) => {
                                setWalmartLoading(true);
                                const apiObj = {
                                  page: 1,
                                  perPage: walmartPageSize,
                                  filter: filter,
                                  sales_channel: selectedSalesChannel,
                                  search_key: e.target.value,
                                  isExport: 0,
                                  publish_status: publish_status,
                                  lifecycle_status: lifecycle_status,
                                };
                                GetWalmartProductList(apiObj);
                              }}
                              prefix={
                                <SearchOutlined
                                  style={{ fontSize: "19px", color: "grey" }}
                                />
                              }
                              size="large"
                            />
                          </div>
                        </span>
                      </h3>
                      {/*end::Title*/}
                      <div className="card-toolbar gap-2">
                        <div className="position-relative">
                          <Select
                            options={[
                              { label: "PUBLISHED", value: "PUBLISHED" },
                              { label: "UNPUBLISHED", value: "UNPUBLISHED" },
                              {
                                label: "READY_TO_PUBLISH",
                                value: "READY_TO_PUBLISH",
                              },
                              { label: "IN_PROGRESS", value: "IN_PROGRESS" },
                              { label: "STAGE", value: "STAGE" },
                              {
                                label: "SYSTEM_PROBLEM",
                                value: "SYSTEM_PROBLEM",
                              },
                            ]}
                            placeholder="Publish Status"
                            size="large"
                            className="w-200px"
                            value={publish_status}
                            allowClear
                            onChange={(e) => {
                              setPublish_status(e);
                              setWalmartLoading(true);
                              const apiObj = {
                                page: 1,
                                perPage: walmartPageSize,
                                filter: filter,
                                sales_channel: selectedSalesChannel,
                                search_key: searchTerm,
                                publish_status: e,
                                lifecycle_status: lifecycle_status,
                                isExport: 0,
                              };
                              GetWalmartProductList(apiObj);
                            }}
                          />
                        </div>{" "}
                        <div className="position-relative">
                          <Select
                            options={[
                              { label: "ACTIVE", value: "ACTIVE" },
                              { label: "RETIRED", value: "RETIRED" },
                              { label: "ARCHIVED", value: "ARCHIVED" },
                            ]}
                            placeholder="Lifecycle Status"
                            size="large"
                            className="w-200px"
                            value={lifecycle_status}
                            allowClear
                            onChange={(e) => {
                              setLifecycle_status(e);
                              setWalmartLoading(true);
                              const apiObj = {
                                page: 1,
                                perPage: walmartPageSize,
                                filter: filter,
                                sales_channel: selectedSalesChannel,
                                search_key: searchTerm,
                                publish_status: publish_status,
                                lifecycle_status: e,
                                isExport: 0,
                              };
                              GetWalmartProductList(apiObj);
                            }}
                          />
                        </div>
                        <div className="position-relative">
                          <Dropdown
                            disabled={walmartList === 0}
                            trigger={["click"]}
                            dropdownRender={() => (
                              <div style={contentStyle}>
                                <div>
                                  <div className="separator border-gray-200" />
                                  <div
                                    style={{
                                      minHeight: "160px",
                                      minWidth: "100px",
                                      border: "1px solid #4419ff8a",
                                      borderRadius: "7px",
                                      overflow: "auto",
                                    }}
                                  >
                                    <CheckboxWrapper>
                                      <Checkbox.Group
                                        value={selectedFilterCheckbox}
                                        options={checkoxOptions}
                                        onChange={(value) => {
                                          setSelectedFilterCheckbox(value);
                                        }}
                                        className="ms-3"
                                        style={{
                                          display: "grid",
                                          justifyContent: "start",
                                        }}
                                      />
                                    </CheckboxWrapper>
                                  </div>
                                </div>
                              </div>
                            )}
                          >
                            <button className="btn btn-primary">
                              Select Filter
                            </button>
                          </Dropdown>
                        </div>
                        <div className="position-relative">
                          <button
                            className="btn btn-primary"
                            onClick={() => {
                              setIsOpenCreateModal(true);
                            }}
                          >
                            Add Item
                          </button>
                        </div>
                        <div className="position-relative">
                          <button
                            className="btn btn-secondary fw-bold"
                            onClick={() => {
                              setOpenGlobalSearchModal(true);
                            }}
                          >
                            Global Product Search
                          </button>
                        </div>
                        <div className="positon-relative">
                          <button
                            className="btn btn-light-info fw-bolder fs-7"
                            onClick={() => {
                              setIsExportFlag(true);
                              message.destroy();
                              message.loading("Loading...", 0);
                              const apiObj = {
                                page: 1,
                                perPage: walmartPageSize,
                                filter: filter,
                                sales_channel: selectedSalesChannel,
                                search_key: searchTerm,
                                isExport: 1,
                              };
                              GetWalmartProductList(apiObj);
                            }}
                          >
                            Export
                          </button>
                        </div>
                        <div className="position-relative">
                          {/* <Select
                            className="w-200px"
                            size="large"
                            placeholder="Select Sales Channel"
                            loading={salesChannelLoading}
                            value={selectedSalesChannelId || null}
                            onChange={(e, _) => {
                              setSelectedSalesChannelId(e);
                              setSelectedSalesChannel(_?.sales_channel);
                              setWalmartPage(1);
                              setWalmartPageSize(DefaultPerPage);
                              setWalmartLoading(true);
                              const apiObj = {
                                page: 1,
                                perPage: walmartPageSize,
                                filter: filter,
                                sales_channel: _?.sales_channel,
                                search_key: searchTerm,
                              };
                              GetWalmartProductList(apiObj);
                            }}
                            options={SalesChannelFlag(salesChannelList)}
                          /> */}
                        </div>
                      </div>
                    </div>
                    {/*end::Header*/}
                    {/*begin::Body*/}
                    <div className="card-body pb-5 pt-0">
                      {/*begin::Table container*/}
                      <div className="table-responsive mt-5">
                        {walmartLoading ? (
                          <Loading />
                        ) : walmartList?.length !== 0 ? (
                          <>
                            <IvcsrTable
                              columns={[
                                ...defaultColumns,
                                ...walmartColumns?.filter((d) =>
                                  selectedFilterCheckbox?.includes(d?.key)
                                ),
                                ...actionColumns,
                              ]?.map((d) => ({
                                ...d,
                                width:
                                  d?.title === "Product"
                                    ? 400
                                    : d?.title?.length * 13,
                              }))}
                              sticky={{
                                offsetHeader: "10px",
                              }}
                              scroll={{
                                x: "max-content",
                                y: "calc(100vh - 200px)",
                              }}
                              fixed="top"
                              dataSource={walmartList}
                              rowKey="key"
                              loading={walmartLoading}
                              pagination={false}
                            />
                          </>
                        ) : (
                          <NoData type="new" />
                        )}
                      </div>
                      <Pagination
                        loading={walmartLoading || walmartList?.length === 0}
                        pageSize={walmartPageSize}
                        page={walmartPage}
                        totalPage={walmartTotalPage}
                        onPerPage={onWalmartPerPage}
                        onPageNo={onWalmartPageNo}
                      />
                    </div>
                    {/*begin::Body*/}
                  </div>
                  {/*end::Tables Widget 9*/}
                </div>
                {/*end::Col*/}
              </div>

              {/*end::Row*/}
            </div>
          </div>
        </div>
      </>

      {isOpenCreateModal && (
        <WalmartAddItemModal
          show={isOpenCreateModal}
          onHide={() => {
            setIsOpenCreateModal(false);
          }}
          rowData={selectedRowData}
          {...props}
        />
      )}

      {openGlobalSearchModal && (
        <GlobalSearchModal
          show={openGlobalSearchModal}
          onHide={() => {
            setOpenGlobalSearchModal(false);
          }}
          {...props}
        />
      )}
    </div>
  );
}
