import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ViewReportList from "../../../modules/pages/manage-reports/amazon-reports/lib/view-report-list";

// import {
//   GetSupplierDataList,
//   SaveSupplierAction,
//   DeleteSupplierAction,
//   fakeActionSupplier,
// } from "../../../redux/modules/master-table/supplier/supplier.action";

const mapStateToProps = (state) => ({
  //   GetSupplierDataListResponse: state.Supplier.GetSupplierDataListResponse,
  //   SaveSupplierResponse: state.Supplier.SaveSupplierResponse,
  //   DeleteSupplierResponse: state.Supplier.DeleteSupplierResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      //   GetSupplierDataList,
      //   SaveSupplierAction,
      //   DeleteSupplierAction,
      //   fakeActionSupplier,
    },
    dispatch
  );

const ViewReportListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewReportList);

export default ViewReportListContainer;
