import React from "react";
import { NumberWithCommas } from "../../../../../../config";
import IvcsrTable from "../../../../../../component/ivcsr-table";
import Loading from "../../../../../../component/loading";
import NoData from "../../../../../../component/no-data";

export default function (props) {
  const { loading, list } = props;
  console.log(list, "list");
  const performanceColumns = [
    {
      title: "Day",
      dataIndex: "day_name",
      key: "day_name",
    },
    {
      title: "Impression",
      dataIndex: "impressions",
      key: "impressions",
      render: (item) => {
        return <span>{NumberWithCommas(item) || "-"}</span>;
      },
    },
    {
      title: "Clicks",
      dataIndex: "clicks",
      key: "clicks",
      render: (item) => {
        return <span>{NumberWithCommas(item) || "-"}</span>;
      },
    },
    {
      title: "Ad Spend",
      dataIndex: "spend",
      key: "spend",
      render: (item) => {
        return <span>${NumberWithCommas(item) || "-"}</span>;
      },
    },
    // {
    //   title: "%",
    //   dataIndex: "state",
    //   key: "state",
    //  render: (item) => {
    //     return <span>{NumberWithCommas(item) || "-"}</span>;
    //   },
    // },
    {
      title: "Ad Sales",
      dataIndex: "sales",
      key: "sales",
      render: (item) => {
        return <span>${NumberWithCommas(item) || "-"}</span>;
      },
    },
    // {
    //   title: "%",
    //   dataIndex: "state",
    //   key: "state",
    //  render: (item) => {
    //     return <span>{NumberWithCommas(item) || "-"}</span>;
    //   },
    // },
    {
      title: "Ad Unit Sold",
      dataIndex: "unit_ordered",
      key: "unit_ordered",
      render: (item) => {
        return <span>{NumberWithCommas(item) || "-"}</span>;
      },
    },
    {
      title: "Ad Orders",
      dataIndex: "orders",
      key: "orders",
      render: (item) => {
        return <span>{NumberWithCommas(item) || "-"}</span>;
      },
    },
    {
      title: "CTR",
      dataIndex: "CTR",
      key: "CTR",
      render: (item) => {
        return <span>{NumberWithCommas(item) || "-"}%</span>;
      },
    },
    {
      title: "CVR",
      dataIndex: "CVR",
      key: "CVR",
      render: (item) => {
        return <span>{NumberWithCommas(item) || "-"}%</span>;
      },
    },
    // {
    //   title: "CVR-Ad Units",
    //   dataIndex: "state",
    //   key: "state",
    //  render: (item) => {
    //     return <span>{NumberWithCommas(item) || "-"}</span>;
    //   },
    // },
    {
      title: "ROAS",
      dataIndex: "ROAS",
      key: "ROAS",
      render: (item) => {
        return <span>${NumberWithCommas(item) || "-"}</span>;
      },
    },
    {
      title: "ACOS",
      dataIndex: "ACoS",
      key: "ACoS",
      render: (item) => {
        return <span>{NumberWithCommas(item) || "-"}%</span>;
      },
    },
    {
      title: "CPC",
      dataIndex: "CPC",
      key: "CPC",
      render: (item) => {
        return <span>${NumberWithCommas(item) || "-"}</span>;
      },
    },
    {
      title: "AOV",
      dataIndex: "AOV",
      key: "AOV",
      render: (item) => {
        return <span>${NumberWithCommas(item) || "-"}</span>;
      },
    },
  ];
  return (
    <div className="col-xxl-12">
      {/*begin::Tables Widget 9*/}

      <div className="card">
        {/*begin::Header*/}
        <div className="card-header border-bottom-dashed">
          <div className="d-flex flex-stack flex-wrap gap-4">
            <div className="position-relative ">
              <label className="fw-bold fs-4">Day Wise Performance</label>
              {/* <Input
              type="text"
              className="text-dark w-250px fs-7 fw-bold ant_common_input"
              name="all_search"
              placeholder="Search by Campaign Name"
              // value={searchTerm}
              size="large"
              onChange={(e) => {
                // setSearchTerm(e.target.value);
              }}
              prefix={
                <SearchOutlined
                  style={{ fontSize: "19px", color: "grey" }}
                />
              }
            /> */}
            </div>
          </div>
          <div className="card-toolbar"></div>
        </div>
        {/*end::Header*/}
        {/*begin::Body*/}
        <div className="card-body py-2">
          {/*begin::Table container*/}
          <div className="table-responsive">
            {/*begin::Table*/}
            {loading ? (
              <Loading />
            ) : list?.length !== 0 ? (
              <>
                <IvcsrTable
                  columns={performanceColumns}
                  fixed="top"
                  dataSource={list}
                  rowKey="key"
                  loading={loading}
                  pagination={false}
                  scroll={{ x: "max-content" }}
                />
              </>
            ) : (
              <>
                <NoData />
              </>
            )}

            {/*end::Table*/}
          </div>
        </div>
        {/*begin::Body*/}
      </div>
      {/*end::Tables Widget 9*/}
    </div>
  );
}
