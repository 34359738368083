import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AdvCampaignManagement from "../../../modules/pages/advertisement/campaign-management";
import {
  GetAmazonCampaignList,
  DeleteWalmartCampaignAction,
  fakeActionCampaignManagement,
  GetAmazonCampaignFilters,
  GetAmazonPerformaceMetrics,
  GetAmazonCampaignGraph,
  GetAmazonCampaignTable,
  GetAmazonDayWiseCampaignList,
  GetWalmartCampaignList,
  GetWalmartDBLogCampaignList,
  WalamrtCreateCampaign,
  WalamrtCreateAdGroupCampaign,
  WalmartCreateAdItemsCampaign,
  WalmartCreateBidPlacementCampaign,
  WalmartCreateBidPlatformCampaign,
  WalmartCreateBidPlacementInclusionCampaign,
  WalmartCreateKeywordCampaign,
  WalmartGetCampaignKeywordList,
  GetSavedCamapignDetailsByCID,
  GetDirectWalmartCampaignAction,
} from "../../../redux/modules/advertisement/campaign-management/index.action";

import {
  GetSalesChannelList,
  fakeActionSalesAnalytics,
} from "../../../redux/modules/sales-analytics/sales-analytics.action";

import {
  GetWalmartProductList,
  fakeActionSkuData,
} from "../../../redux/modules/master-table/sku-data/sku-data.action";

const mapStateToProps = (state) => ({
  //   GetDashboardTopProductsListResponse:
  //     state.Dashboard.GetDashboardTopProductsListResponse,
  //   GetDashboardUnitSoldChartResponse:
  //     state.Dashboard.GetDashboardUnitSoldChartResponse,
  //   GetDashboardSalesDataResponse: state.Dashboard.GetDashboardSalesDataResponse,

  GetWalmartCampaignListResponse:
    state.CampaignManagement.GetWalmartCampaignListResponse,
  GetWalmartCampaignDBLogListResponse:
    state.CampaignManagement.GetWalmartCampaignDBLogListResponse,
  DeleteWalmartCampaignResponse:
    state.CampaignManagement.DeleteWalmartCampaignResponse,
  CreateWalmartCampaignResponse:
    state.CampaignManagement.CreateWalmartCampaignResponse,
  CreateWalmartAdGroupResponse:
    state.CampaignManagement.CreateWalmartAdGroupResponse,
  CreateWalmartAddItemsResponse:
    state.CampaignManagement.CreateWalmartAddItemsResponse,
  CreateWalmartBidPlacementResponse:
    state.CampaignManagement.CreateWalmartBidPlacementResponse,
  CreateWalmartBidPlatformResponse:
    state.CampaignManagement.CreateWalmartBidPlatformResponse,
  CreateWalmartPlacementInclusionResponse:
    state.CampaignManagement.CreateWalmartPlacementInclusionResponse,
  GetSavedCamapignDetailsByCIDResponse:
    state.CampaignManagement.GetSavedCamapignDetailsByCIDResponse,
  GetWalmartCampaignKeywordListResponse:
    state.CampaignManagement.GetWalmartCampaignKeywordListResponse,
  CreateWalmartKeywordCampaignResponse:
    state.CampaignManagement.CreateWalmartKeywordCampaignResponse,
  GetDirectWalmartCampaignListResponse:
    state.CampaignManagement.GetDirectWalmartCampaignListResponse,

  GetCampaignListResponse: state.CampaignManagement.GetCampaignListResponse,
  GetAmazonFiltersResponse: state.CampaignManagement.GetAmazonFiltersResponse,
  GetAmazonPerformanceMetricsResponse:
    state.CampaignManagement.GetAmazonPerformanceMetricsResponse,
  GetAmazonCampaignGraphResponse:
    state.CampaignManagement.GetAmazonCampaignGraphResponse,
  GetCampaignListTableResponse:
    state.CampaignManagement.GetCampaignListTableResponse,
  GetDayWiseCampaignListTableResponse:
    state.CampaignManagement.GetDayWiseCampaignListTableResponse,

  GetSalesChannelListResponse: state.SalesAnalytics.GetSalesChannelListResponse,

  GetWalmartProductListResponse: state.SKUData.GetWalmartProductListResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      GetAmazonCampaignList,
      GetWalmartCampaignList,
      GetWalmartDBLogCampaignList,
      DeleteWalmartCampaignAction,
      WalamrtCreateCampaign,
      WalamrtCreateAdGroupCampaign,
      WalmartCreateAdItemsCampaign,
      WalmartCreateBidPlacementCampaign,
      WalmartCreateBidPlatformCampaign,
      WalmartCreateBidPlacementInclusionCampaign,
      GetSavedCamapignDetailsByCID,
      WalmartGetCampaignKeywordList,
      WalmartCreateKeywordCampaign,
      GetDirectWalmartCampaignAction,
      fakeActionCampaignManagement,

      GetAmazonCampaignFilters,
      GetAmazonPerformaceMetrics,
      GetAmazonCampaignGraph,
      GetAmazonCampaignTable,
      GetAmazonDayWiseCampaignList,

      GetSalesChannelList,
      fakeActionSalesAnalytics,

      GetWalmartProductList,
      fakeActionSkuData,
    },
    dispatch
  );

const AdvCampaignManagementContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AdvCampaignManagement);

export default AdvCampaignManagementContainer;
