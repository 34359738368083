import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Supplier from "../../../modules/pages/master-table/supplier";

import {
  GetSupplierDataList,
  SaveSupplierAction,
  DeleteSupplierAction,
  fakeActionSupplier,
} from "../../../redux/modules/master-table/supplier/supplier.action";

const mapStateToProps = (state) => ({
  GetSupplierDataListResponse: state.Supplier.GetSupplierDataListResponse,
  SaveSupplierResponse: state.Supplier.SaveSupplierResponse,
  DeleteSupplierResponse: state.Supplier.DeleteSupplierResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      GetSupplierDataList,
      SaveSupplierAction,
      DeleteSupplierAction,
      fakeActionSupplier,
    },
    dispatch
  );

const SupplierContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Supplier);

export default SupplierContainer;
