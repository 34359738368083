import { Alert, Input, Tag, message } from "antd";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
export default function ({ show, onHide, data }) {
  //   const data = {
  //     returnOrderId: "165141703617217859",
  //     customerOrderId: "200011677604846",
  //     refundLines: [
  //       {
  //         returnOrderLineNumber: 1,
  //         quantity: {
  //           unitOfMeasure: "EACH",
  //           measurementValue: 1,
  //         },
  //       },
  //       {
  //         returnOrderLineNumber: 1,
  //         quantity: {
  //           unitOfMeasure: "EACH",
  //           measurementValue: 1,
  //         },
  //       },
  //     ],
  //   };
  return (
    <>
      <Modal show={show} onHide={onHide} size="lg" centered backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Return & Refund</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <div className="card card-xxl-stretch mb-5 mb-xl-8">
              <div
                style={{
                  width: "100%",
                  height: "50px",
                  padding: "18px",
                  background: "#eae8f5",
                }}
                className="d-flex justify-content-between align-items-center"
              >
                <div>
                  {" "}
                  <label className="fs-5 me-2 fw-bold">Return Order Id :</label>
                  <label className="fw-bolder fs-5">
                    <Tag color="purple">{data?.returnOrderId}</Tag>
                  </label>
                </div>
                <div>
                  {" "}
                  <label className="fs-5 me-2 fw-bold">
                    Customer Order Id :
                  </label>
                  <label className="fw-bolder fs-5">
                    <Tag color="volcano">{data?.customerOrderId}</Tag>
                  </label>
                </div>
              </div>
              <div className="separator separator-dashed mb-3" />
              <div className="card-body pb-5 pt-0">
                <div className="row my-3">
                  {data?.refundLines?.map((d) => {
                    return (
                      <>
                        <div
                          style={{ width: "100%", height: "50px" }}
                          className="d-flex justify-content-between align-items-center"
                        >
                          <div>
                            {" "}
                            <label className="fs-5 me-2">
                              {" "}
                              Return Orderline Number :
                            </label>
                            <label className="fw-bolder fs-5">
                              {d?.quantity?.measurementValue}
                            </label>
                          </div>
                          <div>
                            {" "}
                            <label className="fs-5 me-2"> Quantity:</label>
                            <label className="fw-bolder fs-5">
                              {d?.returnOrderLineNumber}
                            </label>
                          </div>
                        </div>

                        {data?.refundLines?.length > 1 && (
                          <div className="separator separator-dashed my-3" />
                        )}
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
          </>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-light-danger"
            onClick={onHide}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
