import axiosCall from "../../configurations/network-services/axiosCall";

const prefix = "analytics";

export const GetSalesAnalyticsCardData = (data) => {
  const path = `${prefix}/get-sales-data?filter_date=${
    data?.filter_date || ""
  }&sales_channel=${data?.sales_channel || ""}&search_key=${
    data?.searchTerm || ""
  }`;
  const responseType = "GET_SALES_ANALYTICS_CARD_DATA";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetSalesOverviewChart = (data) => {
  const path = `${prefix}/sales-chart-data?filter_date=${
    data?.filter_date || ""
  }&sales_channel=${data?.sales_channel || ""}&search_key=${
    data?.searchTerm || ""
  }`;
  const responseType = "GET_SALES_OVERVIEW_CHART";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetOrderOverviewChart = (data) => {
  const path = `${prefix}/orders-chart-data?filter_date=${
    data?.filter_date || ""
  }&sales_channel=${data?.sales_channel || ""}&search_key=${
    data?.searchTerm || ""
  }&selection_type=${data?.filterKey || "custom"}&view_by=${
    data?.viewBy || ""
  }`;
  const responseType = "GET_ORDER_OVERVIEW_CHART";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetSalesAnalyticsTableData = (data) => {
  const path = data?.isExport
    ? `${prefix}/sku-wise-data?search_key=${
        data?.searchTerm || ""
      }&filter_date=${data?.filter_date || ""}&sales_channel=${
        data?.sales_channel || ""
      }&isExport=${data?.isExport || 0}`
    : `${prefix}/sku-wise-data?page=${data?.page || 1}&per-page=${
        data?.perPage || 10
      }&search_key=${data?.searchTerm || ""}&filter_date=${
        data?.filter_date || ""
      }&sales_channel=${data?.sales_channel || ""}&isExport=${
        data?.isExport || 0
      }&sort=${data?.sort || ""}`;

  // const path = `${prefix}/sku-wise-data?page=${data?.page || 1}&per-page=${
  //   data?.perPage || 10
  // }&search_key=${data?.searchTerm || ""}&filter_date=${
  //   data?.filter_date || ""
  // }&sales_channel=${data?.sales_channel || ""}&isExport=${
  //   data?.isExport || 0
  // }&sort=${data?.sort || ""}`;
  const responseType = "GET_SALES_TABLE_DATA";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetSalesChannelList = (data) => {
  const path = `sales-channel`;
  const responseType = "GET_SALES_CHANNEL_LIST_DATA";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetMarketplaceList = () => {
  const path = `marketplaces/get-marketplace-by-credential`;
  const responseType = "GET_MARKETPLACE_LIST_DATA";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

// ----------------------------------------------------------------------------------  Walmart API -----------------------

export const GetWalmartSalesAnalyticsCardData = (data) => {
  const path = `wm/${prefix}/get-sales-data?filter_date=${
    data?.filter_date || ""
  }&sales_channel=${data?.sales_channel || ""}&search_key=${
    data?.searchTerm || ""
  }`;
  const responseType = "GET_WALMART_SALES_ANALYTICS_CARD_DATA";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetWalmartSalesOverviewChart = (data) => {
  const path = `wm/${prefix}/sales-chart-data?filter_date=${
    data?.filter_date || ""
  }&sales_channel=${data?.sales_channel || ""}&search_key=${
    data?.searchTerm || ""
  }`;
  const responseType = "GET_WALMART_SALES_OVERVIEW_CHART";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetWalmartOrderOverviewChart = (data) => {
  const path = `wm/${prefix}/orders-chart-data?filter_date=${
    data?.filter_date || ""
  }&sales_channel=${data?.sales_channel || ""}&search_key=${
    data?.searchTerm || ""
  }&selection_type=${data?.filterKey || "custom"}&view_by=${
    data?.viewBy || ""
  }`;
  const responseType = "GET_WALMART_ORDER_OVERVIEW_CHART";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetWalmartSalesAnalyticsTableData = (data) => {
  const path = `wm/${prefix}/sku-wise-data?page=${data?.page || 1}&per-page=${
    data?.perPage || 10
  }&search_key=${data?.searchTerm || ""}&filter_date=${
    data?.filter_date || ""
  }&sales_channel=${data?.sales_channel || ""}&isExport=${data?.isExport || 0}`;
  const responseType = "GET_WALMART_SALES_TABLE_DATA";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const fakeActionSalesAnalytics = (data) => async (dispatch) => {
  dispatch({ type: "FAKE_ACTION_SALES_ANALYTICS", state: data });
};
