import React, { useContext, useEffect } from "react";
import { GlobalContext } from "../../../commonContext";
import AmazonRepricer from "./amazon-repricer";
import UnderConstruction from "../../../component/under-construction/under-construction";
import WalmartRepricer from "./walmart-repricer";
export default function (props) {
  const contextVar = useContext(GlobalContext);

  return contextVar?.data?.marketplaceType === "amazon" ? (
    <AmazonRepricer {...props} />
  ) : (
    <WalmartRepricer {...props} />
    // <UnderConstruction />
  );
}
