import React, { useEffect, useState } from "react";
import Loading from "../../../../../../component/loading";
import IvcsrTable from "../../../../../../component/ivcsr-table";
import NoData from "../../../../../../component/no-data";
import { useSelector } from "react-redux";
import { DefaultPerPage } from "../../../../../../config";
import { Input, Popconfirm, message } from "antd";

export default function (props) {
  const {
    SaveReturnCenterAddr,
    GetReturnCenterAddress,
    DeleteReturnCenterAddress,
    fakeActionuserConfiguration,
  } = props;
  const [isAddReturnCenter, setisAddReturnCenter] = useState(true);
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([
    // {
    //   key: "1",
    //   display_name: "Night Owl",
    //   address_line1: "addr 1",
    //   address_line2: "addr 2",
    //   city: "Ahmedabad",
    //   state: "Gujarat",
    //   postal_code: "300001",
    //   country: "Test",
    // },
  ]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageSize, setPageSize] = useState(DefaultPerPage);
  const [addrId, setAddrId] = useState("");
  const [addrJson, setAddrJson] = useState({
    display_name: "",
    address_line1: "",
    address_line2: "",
    city: "",
    state: "",
    country: "",
    postal_code: "",
  });

  const SaveReturnCenterAddrRes = useSelector(
    (state) => state.Configuration.SaveReturnCenterAddrResponse || {}
  );
  const GetReturnCenterAddrRes = useSelector(
    (state) => state.Configuration.GetReturnCenterAddrResponse || {}
  );
  const DeleteReturnCenterAddrRes = useSelector(
    (state) => state.Configuration.DeleteReturnCenterAddrResponse || {}
  );

  useEffect(() => {
    GetReturnCenterAddress();
  }, []);

  useEffect(() => {
    if (SaveReturnCenterAddrRes.status === true) {
      message.destroy();
      message.success(SaveReturnCenterAddrRes?.message);
      setAddrId("");
      setAddrJson({
        display_name: "",
        address_line1: "",
        address_line2: "",
        city: "",
        state: "",
        country: "",
        postal_code: "",
      });
      setLoading(true);
      GetReturnCenterAddress();
      fakeActionuserConfiguration("SaveReturnCenterAddrResponse");
    } else if (SaveReturnCenterAddrRes.status === false) {
      message.destroy();
      message.error(SaveReturnCenterAddrRes?.message);
      fakeActionuserConfiguration("SaveReturnCenterAddrResponse");
    }
  }, [SaveReturnCenterAddrRes]);

  useEffect(() => {
    if (DeleteReturnCenterAddrRes.status === true) {
      message.destroy();
      message.success(DeleteReturnCenterAddrRes?.message);
      setLoading(true);
      GetReturnCenterAddress();
      fakeActionuserConfiguration("DeleteReturnCenterAddrResponse");
    } else if (DeleteReturnCenterAddrRes.status === false) {
      message.destroy();
      message.error(DeleteReturnCenterAddrRes?.message);
      fakeActionuserConfiguration("DeleteReturnCenterAddrResponse");
    }
  }, [DeleteReturnCenterAddrRes]);

  useEffect(() => {
    if (GetReturnCenterAddrRes.status === true) {
      setList(GetReturnCenterAddrRes?.data || []);
      setLoading(false);
      setTotalPage(GetReturnCenterAddrRes?.data?.pagination?.totalCount);
      fakeActionuserConfiguration("GetReturnCenterAddrResponse");
    } else if (GetReturnCenterAddrRes.status === false) {
      setLoading(false);
      fakeActionuserConfiguration("GetReturnCenterAddrResponse");
    }
  }, [GetReturnCenterAddrRes]);

  const onConfirmDelete = (id) => {
    message.destroy();
    message.loading("Deleting...", 0);
    DeleteReturnCenterAddress(id);
  };

  const columns = [
    {
      title: "Sr No",
      render: (_, __, i) => {
        return <span>{(page - 1) * pageSize + 1 + i}</span>;
      },
    },
    {
      title: "Display Name",
      dataIndex: "display_name",
      key: "display_name",
      render: (text) => {
        return <span className="fw-bolder">{text}</span>;
      },
    },
    {
      title: "Address Line 1",
      dataIndex: "address_line1",
      key: "address_line1",
    },
    {
      title: "Address Line 2",
      dataIndex: "address_line2",
      key: "address_line2",
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
    },
    {
      title: "State / Province",
      dataIndex: "state",
      key: "state",
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
    },
    {
      title: "Post Code",
      dataIndex: "postal_code",
      key: "postal_code",
    },
    {
      title: "Action",

      render: (text) => (
        <div className="d-flex flex-shrink-0 ">
          <span
            className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-3"
            onClick={() => {
              setAddrId(text?.id);
              const common_data = { ...addrJson };
              common_data.display_name = text?.display_name;
              common_data.address_line1 = text?.address_line1;
              common_data.address_line2 = text?.address_line2;
              common_data.city = text?.city;
              common_data.state = text?.state;
              common_data.country = text?.country;
              common_data.postal_code = text?.postal_code;
              setAddrJson(common_data);
            }}
          >
            <i className="ki-outline ki-pencil fs-2 text-dark" />
          </span>
          <Popconfirm
            title="Delete the Address"
            description="Are you sure to delete this return center address?"
            onConfirm={() => {
              onConfirmDelete(text?.id);
            }}
            onCancel={""}
            okText="Yes"
            cancelText="No"
          >
            <span className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
              <i className="ki-outline ki-trash fs-2 text-danger" />
            </span>
          </Popconfirm>
        </div>
      ),
    },
  ];

  const UpdateAddrJson = (key, value) => {
    const common_data = {
      ...addrJson,
      [key]: value,
    };
    setAddrJson(common_data);
  };

  console.log(addrJson, "-addrJson");

  return (
    <>
      <div className="row g-5 g-xl-5 mb-5 mt-5">
        <div className="col-md-12">
          <button
            className="btn fs-7 btn-primary fw-bold rotate collapsible active"
            onClick={() => {
              setisAddReturnCenter(!isAddReturnCenter);
              setAddrJson({
                display_name: "",
                address_line1: "",
                address_line2: "",
                city: "",
                state: "",
                postal_code: "",
                country: "",
              });
            }}
          >
            Add Return Center
            <span
              className={`ms-2 ${
                isAddReturnCenter ? "rotate-180" : "rotate-0"
              } d-flex`}
            >
              <i className="ki-duotone ki-down fs-3" />
            </span>
          </button>
        </div>
      </div>
      {isAddReturnCenter && (
        <div className="row mx-0 px-0 fadeInRight">
          <div className="card mb-5 mb-xl-8">
            {/* <div className="card-header border-bottom-dashed">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bolder text-dark">
                  Returns Configuration
                </span>
              </h3>
              <div className="card-toolbar"></div>
            </div> */}
            <div className="card-body pt-5 pb-10">
              {" "}
              <div
                className={`mt-5 ${isAddReturnCenter ? "" : ""}`}
                style={
                  {
                    // border: "1px solid #d8d8d8",
                    // padding: "25px",
                    // borderRadius: "9px",
                  }
                }
              >
                <div className="row g-5 g-xl-5 mb-5 d-flex align-items-end">
                  <div className="d-flex flex-column fv-row col-sm-6 col-md-4">
                    <label className="required fs-6 fw-bold mb-2">
                      Display Name
                    </label>
                    <Input
                      placeholder="Enter Display Name"
                      size="large"
                      className="ant_common_input"
                      value={addrJson?.display_name}
                      onChange={(e) => {
                        UpdateAddrJson("display_name", e.target.value);
                      }}
                    />
                  </div>

                  <div className="d-flex flex-column fv-row col-sm-6 col-md-4">
                    <label className="required fs-6 fw-bold mb-2">
                      Address Line 1
                    </label>

                    <Input
                      placeholder="Enter Address Line 1"
                      size="large"
                      className="ant_common_input"
                      value={addrJson?.address_line1}
                      onChange={(e) => {
                        UpdateAddrJson("address_line1", e.target.value);
                      }}
                    />
                  </div>

                  <div className="d-flex flex-column fv-row col-sm-6 col-md-4">
                    <label className="fs-6 fw-bold mb-2">Address Line 2</label>

                    <Input
                      placeholder="Enter Address Line 2"
                      size="large"
                      className="ant_common_input"
                      value={addrJson?.address_line2}
                      onChange={(e) => {
                        UpdateAddrJson("address_line2", e.target.value);
                      }}
                    />
                  </div>

                  <div className="d-flex flex-column fv-row col-sm-6 col-md-4 col-lg-3">
                    <label className="required fs-6 fw-bold mb-2">City</label>
                    <Input
                      placeholder="Enter City"
                      size="large"
                      className="ant_common_input"
                      value={addrJson?.city}
                      onChange={(e) => {
                        UpdateAddrJson("city", e.target.value);
                      }}
                    />
                  </div>

                  <div className="d-flex flex-column fv-row col-sm-6 col-md-4 col-lg-3">
                    <label className="required fs-6 fw-bold mb-2">
                      State / Province
                    </label>

                    <Input
                      placeholder="Enter State / Province"
                      size="large"
                      className="ant_common_input"
                      value={addrJson?.state}
                      onChange={(e) => {
                        UpdateAddrJson("state", e.target.value);
                      }}
                    />
                  </div>
                  <div className="d-flex flex-column fv-row col-sm-6 col-md-4 col-lg-3 col-xl-2">
                    <label className="required fs-6 fw-bold mb-2">
                      Country
                    </label>

                    <Input
                      placeholder="Enter Country"
                      size="large"
                      className="ant_common_input"
                      value={addrJson?.country}
                      onChange={(e) => {
                        UpdateAddrJson("country", e.target.value);
                      }}
                    />
                  </div>
                  <div className="d-flex flex-column fv-row col-sm-6 col-md-4 col-lg-3 col-xl-2">
                    <label className="required fs-6 fw-bold mb-2">
                      Post Code
                    </label>

                    <Input
                      placeholder="Enter Post Code"
                      size="large"
                      className="ant_common_input"
                      value={addrJson?.postal_code}
                      onChange={(e) => {
                        UpdateAddrJson("postal_code", e.target.value);
                      }}
                    />
                  </div>

                  <div className="col-sm-6 col-md-4 col-lg-3 col-xl-2">
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        console.log(addrJson, "addrJson");
                        message.destroy();
                        message.loading("Loading...", 0);
                        SaveReturnCenterAddr(addrJson, addrId);
                      }}
                      disabled={
                        Object.entries(addrJson)?.filter((d) => !d?.[1])
                          ?.length > 0
                      }
                    >
                      Submit
                    </button>
                    <button
                      className="btn btn-light-danger ms-2"
                      onClick={() => {
                        setAddrJson({
                          display_name: "",
                          address_line1: "",
                          address_line2: "",
                          city: "",
                          state: "",
                          postal_code: "",
                          country: "",
                        });
                      }}
                    >
                      Clear
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="card mb-5 mb-xl-8">
        <div className="card-header border-bottom-dashed">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bolder text-dark">
              Return Centers
            </span>
          </h3>
          <div className="card-toolbar"></div>
        </div>
        <div className="card-body pt-5 pb-20">
          <div className="table-responsive tbl-sales">
            {" "}
            {loading ? (
              <Loading />
            ) : list?.length !== 0 ? (
              <IvcsrTable
                columns={columns}
                dataSource={list}
                pagination={false}
                scroll={{ x: "max-content" }}
              />
            ) : (
              <NoData height="200px" />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
