import { Skeleton } from "antd";
import React from "react";
import { NumberWithCommas } from "../../../../../config";
export default function (props) {
  const { performanceMetrics, performLoading } = props;
  const loadingArr = [1, 2, 3];
  return (
    <>
      <div className="row g-5 g-xl-5 mb-5 mb-xl-0">
        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-12  ">
          <div className="card card-xxl-stretch mb-5 mb-xl-8">
            <div className="card-header border-bottom-0 min-h-45px">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bolder text-dark">
                  Active Campaign
                </span>
                {/* <span class="text-gray-400 pt-1 fw-semibold fs-6">Sales analytics by SKU</span> */}
              </h3>
              <div className="card-toolbar"></div>
            </div>

            <div className="card-body py-2">
              <div className="m-0">
                {performLoading ? (
                  loadingArr?.map((d) => {
                    return (
                      <>
                        {" "}
                        <div
                          className="d-flex flex-stack mb-5"
                          style={{ borderLeft: "2px solid #4318ff" }}
                        >
                          <div className="symbol symbol-40px mx-1">
                            <Skeleton.Button
                              size="small"
                              shape="circle"
                              active
                            />
                          </div>
                          <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                            <div className="me-5">
                              <span className="fw-bold text-primary fs-6">
                                <Skeleton.Button
                                  size="small"
                                  style={{ width: "100px" }}
                                  active
                                />
                              </span>
                            </div>

                            <div className="d-flex align-items-center">
                              <span className="text-gray-800 fw-bolder fs-4 me-3 d-block">
                                <Skeleton.Button size="small" active />
                              </span>
                              <div className="m-0">
                                <Skeleton.Button size="small" active />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="separator separator-dashed my-3" />
                      </>
                    );
                  })
                ) : (
                  <>
                    <div
                      className="d-flex flex-stack"
                      style={{ borderLeft: "2px solid #4318ff" }}
                    >
                      <div className="symbol symbol-40px me-4">
                        <span className="symbol-label">
                          <i className="ki-duotone ki-dollar text-gray-600 fs-1">
                            <span className="path1" />
                            <span className="path2" />
                            <span className="path3" />
                          </i>
                        </span>
                      </div>
                      <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                        <div className="me-5">
                          <span className="fw-bold text-primary fs-6">
                            Spend
                          </span>
                          <span className="text-gray-600 fw-semibold fs-7 d-block text-start ps-0">
                            ${NumberWithCommas(performanceMetrics?.spend?.prev)}
                          </span>
                        </div>

                        <div className="d-flex align-items-center">
                          <span className="text-gray-800 fw-bolder fs-4 me-3 d-block">
                            $
                            {NumberWithCommas(
                              performanceMetrics?.spend?.current
                            )}
                          </span>
                          <div className="m-0">
                            {NumberWithCommas(
                              performanceMetrics?.spend?.change_percentage
                            ) < 0 ? (
                              <span className="badge badge-light-danger fs-8">
                                <i className="ki-outline ki-arrow-down fs-5 text-danger ms-n1" />
                                {performanceMetrics?.spend?.change_percentage}%
                              </span>
                            ) : (
                              <span className="badge badge-light-success fs-8">
                                <i className="ki-outline ki-arrow-up fs-5 text-green ms-n1" />
                                {performanceMetrics?.spend?.change_percentage}%
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="separator separator-dashed my-3" />
                    <div
                      className="d-flex flex-stack"
                      style={{ borderLeft: "2px solid #4318ff" }}
                    >
                      <div className="symbol symbol-40px me-4">
                        <span className="symbol-label">
                          <i className="ki-duotone ki-chart-pie-3  text-gray-600 fs-1">
                            <span className="path1" />
                            <span className="path2" />
                            <span className="path3" />
                          </i>
                        </span>
                      </div>
                      <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                        <div className="me-5">
                          <span className="fw-bold text-primary fs-6">
                            Sales
                          </span>
                          <span className="text-gray-600 fw-semibold fs-7 d-block text-start ps-0">
                            ${NumberWithCommas(performanceMetrics?.sales?.prev)}
                          </span>
                        </div>

                        <div className="d-flex align-items-center">
                          <span className="text-gray-800 fw-bolder fs-4 me-3 d-block">
                            $
                            {NumberWithCommas(
                              performanceMetrics?.sales?.current
                            )}
                          </span>
                          <div className="m-0">
                            {NumberWithCommas(
                              performanceMetrics?.sales?.change_percentage
                            ) < 0 ? (
                              <span className="badge badge-light-danger fs-8">
                                <i className="ki-outline ki-arrow-down fs-5 text-danger ms-n1" />
                                {performanceMetrics?.sales?.change_percentage}%
                              </span>
                            ) : (
                              <span className="badge badge-light-success fs-8">
                                <i className="ki-outline ki-arrow-up fs-5 text-green ms-n1" />
                                {performanceMetrics?.sales?.change_percentage}%
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="separator separator-dashed my-3" />
                    <div
                      className="d-flex flex-stack"
                      style={{ borderLeft: "2px solid #4318ff" }}
                    >
                      <div className="symbol symbol-40px me-4">
                        <span className="symbol-label">
                          <i className="ki-duotone ki-graph-up text-gray-600 fs-1">
                            <span className="path1" />
                            <span className="path2" />
                            <span className="path3" />
                            <span className="path4" />
                            <span className="path5" />
                            <span className="path6" />
                          </i>
                        </span>
                      </div>

                      <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                        <div className="me-5">
                          <span className="fw-bold text-primary fs-6">
                            ACOS
                          </span>
                          <span className="text-gray-600 fw-semibold fs-7 d-block text-start ps-0">
                            {NumberWithCommas(performanceMetrics?.acos?.prev)}%
                          </span>
                        </div>

                        <div className="d-flex align-items-center">
                          <span className="text-gray-800 fw-bolder fs-4 me-3 d-block">
                            {NumberWithCommas(
                              performanceMetrics?.acos?.current
                            )}
                            %
                          </span>
                          <div className="m-0">
                            {NumberWithCommas(
                              performanceMetrics?.acos?.change_percentage
                            ) < 0 ? (
                              <span className="badge badge-light-danger fs-8">
                                <i className="ki-outline ki-arrow-down fs-5 text-danger ms-n1" />
                                {performanceMetrics?.acos?.change_percentage}%
                              </span>
                            ) : (
                              <span className="badge badge-light-success fs-8">
                                <i className="ki-outline ki-arrow-up fs-5 text-green ms-n1" />
                                {performanceMetrics?.acos?.change_percentage}%
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-12 ">
          <div className="card card-xxl-stretch mb-5 mb-xl-8">
            <div className="card-header border-bottom-0 min-h-45px">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bolder text-dark">
                  Conversion
                </span>
                {/* <span class="text-gray-400 pt-1 fw-semibold fs-6">Sales analytics by SKU</span> */}
              </h3>
              <div className="card-toolbar"></div>
            </div>

            <div className="card-body py-2">
              <div className="m-0">
                {performLoading ? (
                  loadingArr?.map((d) => {
                    return (
                      <>
                        {" "}
                        <div
                          className="d-flex flex-stack mb-5"
                          style={{ borderLeft: "2px solid #4318ff" }}
                        >
                          <div className="symbol symbol-40px mx-1">
                            <Skeleton.Button
                              size="small"
                              shape="circle"
                              active
                            />
                          </div>
                          <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                            <div className="me-5">
                              <span className="fw-bold text-primary fs-6">
                                <Skeleton.Button
                                  size="small"
                                  style={{ width: "100px" }}
                                  active
                                />
                              </span>
                            </div>

                            <div className="d-flex align-items-center">
                              <span className="text-gray-800 fw-bolder fs-4 me-3 d-block">
                                <Skeleton.Button size="small" active />
                              </span>
                              <div className="m-0">
                                <Skeleton.Button size="small" active />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="separator separator-dashed my-3" />
                      </>
                    );
                  })
                ) : (
                  <>
                    <div
                      className="d-flex flex-stack"
                      style={{ borderLeft: "2px solid #4caf50" }}
                    >
                      <div className="symbol symbol-40px me-4">
                        <span className="symbol-label">
                          <i className="ki-duotone ki-click text-gray-600 fs-1">
                            <span className="path1" />
                            <span className="path2" />
                            <span className="path3" />
                            <span className="path4" />
                            <span className="path5" />
                          </i>
                        </span>
                      </div>

                      <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                        <div className="me-5">
                          <span className="fw-bold text-primary fs-6">
                            Clicks
                          </span>
                          <span className="text-gray-600 fw-semibold fs-7 d-block text-start ps-0">
                            {NumberWithCommas(performanceMetrics?.clicks?.prev)}
                          </span>
                        </div>

                        <div className="d-flex align-items-center">
                          <span className="text-gray-800 fw-bolder fs-4 me-3 d-block">
                            {NumberWithCommas(
                              performanceMetrics?.clicks?.current
                            )}
                          </span>
                          <div className="m-0">
                            {NumberWithCommas(
                              performanceMetrics?.clicks?.change_percentage
                            ) < 0 ? (
                              <span className="badge badge-light-danger fs-8">
                                <i className="ki-outline ki-arrow-down fs-5 text-danger ms-n1" />
                                {performanceMetrics?.clicks?.change_percentage}%
                              </span>
                            ) : (
                              <span className="badge badge-light-success fs-8">
                                <i className="ki-outline ki-arrow-up fs-5 text-green ms-n1" />
                                {performanceMetrics?.clicks?.change_percentage}%
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="separator separator-dashed my-3" />
                    <div
                      className="d-flex flex-stack"
                      style={{ borderLeft: "2px solid #4caf50" }}
                    >
                      <div className="symbol symbol-40px me-4">
                        <span className="symbol-label">
                          <i className="ki-duotone ki-handcart text-gray-600 fs-1">
                            <span className="path1" />
                            <span className="path2" />
                            <span className="path3" />
                          </i>
                        </span>
                      </div>

                      <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                        <div className="me-5">
                          <span className="fw-bold text-primary fs-6">
                            Orders
                          </span>
                          <span className="text-gray-600 fw-semibold fs-7 d-block text-start ps-0">
                            {NumberWithCommas(performanceMetrics?.orders?.prev)}
                          </span>
                        </div>

                        <div className="d-flex align-items-center">
                          <span className="text-gray-800 fw-bolder fs-4 me-3 d-block">
                            {NumberWithCommas(
                              performanceMetrics?.orders?.current
                            )}
                          </span>
                          <div className="m-0">
                            {NumberWithCommas(
                              performanceMetrics?.orders?.change_percentage
                            ) < 0 ? (
                              <span className="badge badge-light-danger fs-8">
                                <i className="ki-outline ki-arrow-down fs-5 text-danger ms-n1" />
                                {performanceMetrics?.orders?.change_percentage}%
                              </span>
                            ) : (
                              <span className="badge badge-light-success fs-8">
                                <i className="ki-outline ki-arrow-up fs-5 text-green ms-n1" />
                                {performanceMetrics?.orders?.change_percentage}%
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="separator separator-dashed my-3" />
                    <div
                      className="d-flex flex-stack"
                      style={{ borderLeft: "2px solid #4caf50" }}
                    >
                      <div className="symbol symbol-40px me-4">
                        <span className="symbol-label">
                          <i className="ki-duotone ki-percentage text-gray-600 fs-1">
                            <span className="path1" />
                            <span className="path2" />
                          </i>
                        </span>
                      </div>

                      <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                        <div className="me-5">
                          <span className="fw-bold text-primary fs-6">CVR</span>
                          <span className="text-gray-600 fw-semibold fs-7 d-block text-start ps-0">
                            {NumberWithCommas(performanceMetrics?.cvr?.prev)}0%
                          </span>
                        </div>

                        <div className="d-flex align-items-center">
                          <span className="text-gray-800 fw-bolder fs-4 me-3 d-block">
                            {NumberWithCommas(performanceMetrics?.cvr?.current)}
                            %
                          </span>
                          <div className="m-0">
                            {NumberWithCommas(
                              performanceMetrics?.cvr?.change_percentage
                            ) < 0 ? (
                              <span className="badge badge-light-danger fs-8">
                                <i className="ki-outline ki-arrow-down fs-5 text-danger ms-n1" />
                                {performanceMetrics?.cvr?.change_percentage}%
                              </span>
                            ) : (
                              <span className="badge badge-light-success fs-8">
                                <i className="ki-outline ki-arrow-up fs-5 text-green ms-n1" />
                                {performanceMetrics?.cvr?.change_percentage}%
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-12 ">
          <div className="card card-xxl-stretch mb-5 mb-xl-8">
            <div className="card-header border-bottom-0 min-h-45px">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bolder text-dark">
                  Efficiency
                </span>
                {/* <span class="text-gray-400 pt-1 fw-semibold fs-6">Sales analytics by SKU</span> */}
              </h3>
              <div className="card-toolbar"></div>
            </div>

            <div className="card-body py-2">
              <div className="m-0">
                {performLoading ? (
                  loadingArr?.map((d) => {
                    return (
                      <>
                        {" "}
                        <div
                          className="d-flex flex-stack mb-5"
                          style={{ borderLeft: "2px solid #4318ff" }}
                        >
                          <div className="symbol symbol-40px mx-1">
                            <Skeleton.Button
                              size="small"
                              shape="circle"
                              active
                            />
                          </div>
                          <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                            <div className="me-5">
                              <span className="fw-bold text-primary fs-6">
                                <Skeleton.Button
                                  size="small"
                                  style={{ width: "100px" }}
                                  active
                                />
                              </span>
                            </div>

                            <div className="d-flex align-items-center">
                              <span className="text-gray-800 fw-bolder fs-4 me-3 d-block">
                                <Skeleton.Button size="small" active />
                              </span>
                              <div className="m-0">
                                <Skeleton.Button size="small" active />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="separator separator-dashed my-3" />
                      </>
                    );
                  })
                ) : (
                  <>
                    <div
                      className="d-flex flex-stack"
                      style={{ borderLeft: "2px solid #f44336" }}
                    >
                      <div className="symbol symbol-40px me-4">
                        <span className="symbol-label">
                          <i className="ki-duotone ki-dollar text-gray-600 fs-1">
                            <span className="path1" />
                            <span className="path2" />
                            <span className="path3" />
                          </i>
                        </span>
                      </div>

                      <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                        <div className="me-5">
                          <span className="fw-bold text-primary fs-6">CPC</span>
                          <span className="text-gray-600 fw-semibold fs-7 d-block text-start ps-0">
                            ${NumberWithCommas(performanceMetrics?.cpc?.prev)}
                          </span>
                        </div>

                        <div className="d-flex align-items-center">
                          <span className="text-gray-800 fw-bolder fs-4 me-3 d-block">
                            ${" "}
                            {NumberWithCommas(performanceMetrics?.cpc?.current)}
                          </span>
                          <div className="m-0">
                            {NumberWithCommas(
                              performanceMetrics?.cpc?.change_percentage
                            ) < 0 ? (
                              <span className="badge badge-light-danger fs-8">
                                <i className="ki-outline ki-arrow-down fs-5 text-danger ms-n1" />
                                {performanceMetrics?.cpc?.change_percentage}%
                              </span>
                            ) : (
                              <span className="badge badge-light-success fs-8">
                                <i className="ki-outline ki-arrow-up fs-5 text-green ms-n1" />
                                {performanceMetrics?.cpc?.change_percentage}%
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="separator separator-dashed my-3" />
                    <div
                      className="d-flex flex-stack"
                      style={{ borderLeft: "2px solid #f44336" }}
                    >
                      <div className="symbol symbol-40px me-4">
                        <span className="symbol-label">
                          <i className="ki-duotone ki-tablet-ok text-gray-600 fs-1">
                            <span className="path1" />
                            <span className="path2" />
                            <span className="path3" />
                          </i>
                        </span>
                      </div>

                      <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                        <div className="me-5">
                          <span className="fw-bold text-primary fs-6">SPC</span>
                          <span className="text-gray-600 fw-semibold fs-7 d-block text-start ps-0">
                            ${NumberWithCommas(performanceMetrics?.spc?.prev)}
                          </span>
                        </div>

                        <div className="d-flex align-items-center">
                          <span className="text-gray-800 fw-bolder fs-4 me-3 d-block">
                            $
                            {NumberWithCommas(performanceMetrics?.spc?.current)}
                          </span>
                          <div className="m-0">
                            {NumberWithCommas(
                              performanceMetrics?.spc?.change_percentage
                            ) < 0 ? (
                              <span className="badge badge-light-danger fs-8">
                                <i className="ki-outline ki-arrow-down fs-5 text-danger ms-n1" />
                                {performanceMetrics?.spc?.change_percentage}%
                              </span>
                            ) : (
                              <span className="badge badge-light-success fs-8">
                                <i className="ki-outline ki-arrow-up fs-5 text-green ms-n1" />
                                {performanceMetrics?.spc?.change_percentage}%
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="separator separator-dashed my-3" />
                    <div
                      className="d-flex flex-stack"
                      style={{ borderLeft: "2px solid #f44336" }}
                    >
                      <div className="symbol symbol-40px me-4">
                        <span className="symbol-label">
                          <i className="ki-duotone ki-discount text-gray-600 fs-1">
                            <span className="path1" />
                            <span className="path2" />
                          </i>
                        </span>
                      </div>

                      <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                        <div className="me-5">
                          <span className="fw-bold text-primary fs-6">CTR</span>
                          <span className="text-gray-600 fw-semibold fs-7 d-block text-start ps-0">
                            {NumberWithCommas(performanceMetrics?.ctr?.prev)}%
                          </span>
                        </div>

                        <div className="d-flex align-items-center">
                          <span className="text-gray-800 fw-bolder fs-4 me-3 d-block">
                            {NumberWithCommas(performanceMetrics?.ctr?.current)}
                            %
                          </span>
                          <div className="m-0">
                            {NumberWithCommas(
                              performanceMetrics?.ctr?.change_percentage
                            ) < 0 ? (
                              <span className="badge badge-light-danger fs-8">
                                <i className="ki-outline ki-arrow-down fs-5 text-danger ms-n1" />
                                {performanceMetrics?.ctr?.change_percentage}%
                              </span>
                            ) : (
                              <span className="badge badge-light-success fs-8">
                                <i className="ki-outline ki-arrow-up fs-5 text-green ms-n1" />
                                {performanceMetrics?.ctr?.change_percentage}%
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-12 ">
          <div className="card card-xxl-stretch mb-5 mb-xl-8">
            <div className="card-header border-bottom-0 min-h-45px">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bolder text-dark">
                  Performance
                </span>
                {/* <span class="text-gray-400 pt-1 fw-semibold fs-6">Sales analytics by SKU</span> */}
              </h3>
              <div className="card-toolbar"></div>
            </div>

            <div className="card-body py-2">
              <div className="m-0">
                <div
                  className="d-flex flex-stack"
                  style={{ borderLeft: "2px solid #ffbb18" }}
                >
                  <div className="symbol symbol-40px me-4">
                    <span className="symbol-label">
                      <i className="ki-duotone ki-bill text-gray-600 fs-1">
                        <span className="path1" />
                        <span className="path2" />
                        <span className="path3" />
                        <span className="path4" />
                        <span className="path5" />
                        <span className="path6" />
                      </i>
                    </span>
                  </div>

                  <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                    <div className="me-5">
                      <span className="fw-bold text-primary fs-6">Spend</span>
                      <span className="text-gray-600 fw-semibold fs-7 d-block text-start ps-0">
                        0
                      </span>
                    </div>

                    <div className="d-flex align-items-center">
                      <span className="text-gray-800 fw-bolder fs-4 me-3 d-block">
                        0
                      </span>
                      <div className="m-0">
                        <span className="badge badge-light-success fs-8">
                          <i className="ki-outline ki-arrow-up fs-5 text-green ms-n1" />
                          0%
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="separator separator-dashed my-3" />

                <div
                  className="d-flex flex-stack"
                  style={{ borderLeft: "2px solid #ffbb18" }}
                >
                  <div className="symbol symbol-40px me-4">
                    <span className="symbol-label">
                      <i className="ki-duotone ki-chart-simple-3 text-gray-600 fs-1">
                        <span className="path1" />
                        <span className="path2" />
                        <span className="path3" />
                        <span className="path4" />
                      </i>
                    </span>
                  </div>

                  <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                    <div className="me-5">
                      <span className="fw-bold text-primary fs-6">Sales</span>
                      <span className="text-gray-600 fw-semibold fs-7 d-block text-start ps-0">
                        0
                      </span>
                    </div>

                    <div className="d-flex align-items-center">
                      <span className="text-gray-800 fw-bolder fs-4 me-3 d-block">
                        0
                      </span>
                      <div className="m-0">
                        <span className="badge badge-light-success fs-8">
                          <i className="ki-outline ki-arrow-up fs-5 text-green ms-n1" />
                          0%
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="separator separator-dashed my-3" />

                <div
                  className="d-flex flex-stack"
                  style={{ borderLeft: "2px solid #ffbb18" }}
                >
                  <div className="symbol symbol-40px me-4">
                    <span className="symbol-label">
                      <i className="ki-duotone ki-screen text-gray-600 fs-1">
                        <span className="path1" />
                        <span className="path2" />
                        <span className="path3" />
                        <span className="path4" />
                      </i>
                    </span>
                  </div>

                  <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                    <div className="me-5">
                      <span className="fw-bold text-primary fs-6">ACOS</span>
                      <span className="text-gray-600 fw-semibold fs-7 d-block text-start ps-0">
                        0%
                      </span>
                    </div>

                    <div className="d-flex align-items-center">
                      <span className="text-gray-800 fw-bolder fs-4 me-3 d-block">
                        0
                      </span>
                      <div className="m-0">
                        <span className="badge badge-light-warning fs-8">
                          <i className="ki-outline ki-arrow-up fs-5 text-warning ms-n1" />
                          0%
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="d-none row g-5 g-xl-5 mb-5 mb-xl-0">
        <div className="col-md-4 ">
          <div className="card card-xxl-stretch mb-5 mb-xl-8">
            <div className="card-header border-bottom-0 min-h-45px">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bolder text-dark">
                  Active Campaign
                </span>
                {/* <span class="text-gray-400 pt-1 fw-semibold fs-6">Sales analytics by SKU</span> */}
              </h3>
              <div className="card-toolbar"></div>
            </div>

            <div className="card-body py-2">
              <div className="m-0">
                <div
                  className="d-flex flex-stack"
                  style={{ borderLeft: "2px solid #ffbb18" }}
                >
                  <div className="symbol symbol-40px me-4">
                    <span className="symbol-label">
                      <i className="ki-duotone ki-bill text-gray-600 fs-1">
                        <span className="path1" />
                        <span className="path2" />
                        <span className="path3" />
                        <span className="path4" />
                        <span className="path5" />
                        <span className="path6" />
                      </i>
                    </span>
                  </div>

                  <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                    <div className="me-5">
                      <span className="fw-bold text-primary fs-6">Spend</span>
                      <span className="text-gray-600 fw-semibold fs-7 d-block text-start ps-0">
                        0
                      </span>
                    </div>

                    <div className="d-flex align-items-center">
                      <span className="text-gray-800 fw-bolder fs-4 me-3 d-block">
                        0
                      </span>
                      <div className="m-0">
                        <span className="badge badge-light-success fs-8">
                          <i className="ki-outline ki-arrow-up fs-5 text-green ms-n1" />
                          0%
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="separator separator-dashed my-3" />

                <div
                  className="d-flex flex-stack"
                  style={{ borderLeft: "2px solid #ffbb18" }}
                >
                  <div className="symbol symbol-40px me-4">
                    <span className="symbol-label">
                      <i className="ki-duotone ki-chart-simple-3 text-gray-600 fs-1">
                        <span className="path1" />
                        <span className="path2" />
                        <span className="path3" />
                        <span className="path4" />
                      </i>
                    </span>
                  </div>

                  <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                    <div className="me-5">
                      <span className="fw-bold text-primary fs-6">Sales</span>
                      <span className="text-gray-600 fw-semibold fs-7 d-block text-start ps-0">
                        0
                      </span>
                    </div>

                    <div className="d-flex align-items-center">
                      <span className="text-gray-800 fw-bolder fs-4 me-3 d-block">
                        0
                      </span>
                      <div className="m-0">
                        <span className="badge badge-light-success fs-8">
                          <i className="ki-outline ki-arrow-up fs-5 text-green ms-n1" />
                          0%
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="separator separator-dashed my-3" />

                <div
                  className="d-flex flex-stack"
                  style={{ borderLeft: "2px solid #ffbb18" }}
                >
                  <div className="symbol symbol-40px me-4">
                    <span className="symbol-label">
                      <i className="ki-duotone ki-screen text-gray-600 fs-1">
                        <span className="path1" />
                        <span className="path2" />
                        <span className="path3" />
                        <span className="path4" />
                      </i>
                    </span>
                  </div>

                  <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                    <div className="me-5">
                      <span className="fw-bold text-primary fs-6">ACOS</span>
                      <span className="text-gray-600 fw-semibold fs-7 d-block text-start ps-0">
                        0%
                      </span>
                    </div>

                    <div className="d-flex align-items-center">
                      <span className="text-gray-800 fw-bolder fs-4 me-3 d-block">
                        0
                      </span>
                      <div className="m-0">
                        <span className="badge badge-light-warning fs-8">
                          <i className="ki-outline ki-arrow-up fs-5 text-warning ms-n1" />
                          0%
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-8 ">
          <div className="row">
            <div className="col-md-6"></div>
            <div className="col-md-6" />
          </div>
          <div className="card card-xxl-stretch mb-5 mb-xl-8">
            <div className="card-header border-bottom-0 min-h-45px">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bolder text-dark">
                  Targeting
                </span>
                {/* <span class="text-gray-400 pt-1 fw-semibold fs-6">Sales analytics by SKU</span> */}
              </h3>
              <div className="card-toolbar"></div>
            </div>

            <div className="card-body py-2">
              <div className="row gx-10">
                <div className="col-md-6">
                  <div className="m-0">
                    <div
                      className="d-flex flex-stack"
                      style={{ borderLeft: "2px solid #383838" }}
                    >
                      <div className="symbol symbol-40px me-4">
                        <span className="symbol-label">
                          <i className="ki-duotone ki-tablet-ok text-gray-600 fs-1">
                            <span className="path1" />
                            <span className="path2" />
                            <span className="path3" />
                          </i>
                        </span>
                      </div>

                      <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                        <div className="me-5">
                          <span className="fw-bold text-primary fs-6">
                            Active Keywords
                          </span>
                          <span className="text-gray-600 fw-semibold fs-7 d-block text-start ps-0">
                            0
                          </span>
                        </div>

                        <div className="d-flex align-items-center">
                          <span className="text-gray-800 fw-bolder fs-4 me-3 d-block">
                            0
                          </span>
                          <div className="m-0">
                            <span className="badge badge-light-success fs-8">
                              <i className="ki-outline ki-arrow-up fs-5 text-green ms-n1" />
                              0%
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="separator separator-dashed my-3" />

                    <div
                      className="d-flex flex-stack"
                      style={{ borderLeft: "2px solid #383838" }}
                    >
                      <div className="symbol symbol-40px me-4">
                        <span className="symbol-label">
                          <i className="ki-duotone ki-add-files text-gray-600 fs-1">
                            <span className="path1" />
                            <span className="path2" />
                            <span className="path3" />
                          </i>
                        </span>
                      </div>

                      <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                        <div className="me-5">
                          <span className="fw-bold text-primary fs-6">
                            New Keywords
                          </span>
                          <span className="text-gray-600 fw-semibold fs-7 d-block text-start ps-0">
                            0
                          </span>
                        </div>

                        <div className="d-flex align-items-center">
                          <span className="text-gray-800 fw-bolder fs-4 me-3 d-block">
                            0
                          </span>
                          <div className="m-0">
                            <span className="badge badge-light-success fs-8">
                              <i className="ki-outline ki-arrow-up fs-5 text-green ms-n1" />
                              0%
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="separator separator-dashed my-3" />

                    <div
                      className="d-flex flex-stack"
                      style={{ borderLeft: "2px solid #383838" }}
                    >
                      <div className="symbol symbol-40px me-4">
                        <span className="symbol-label">
                          <i className="ki-duotone ki-user-tick text-gray-600 fs-1">
                            <span className="path1" />
                            <span className="path2" />
                            <span className="path3" />
                          </i>
                        </span>
                      </div>

                      <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                        <div className="me-5">
                          <span className="fw-bold text-primary fs-6">
                            Active PAT
                          </span>
                          <span className="text-gray-600 fw-semibold fs-7 d-block text-start ps-0">
                            0%
                          </span>
                        </div>

                        <div className="d-flex align-items-center">
                          <span className="text-gray-800 fw-bolder fs-4 me-3 d-block">
                            0
                          </span>
                          <div className="m-0">
                            <span className="badge badge-light-warning fs-8">
                              <i className="ki-outline ki-arrow-up fs-5 text-warning ms-n1" />
                              0%
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="m-0">
                    <div
                      className="d-flex flex-stack"
                      style={{ borderLeft: "2px solid #383838" }}
                    >
                      <div className="symbol symbol-40px me-4">
                        <span className="symbol-label">
                          <i className="ki-duotone ki-user-tick text-gray-600 fs-1">
                            <span className="path1" />
                            <span className="path2" />
                            <span className="path3" />
                          </i>
                        </span>
                      </div>

                      <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                        <div className="me-5">
                          <span className="fw-bold text-primary fs-6">
                            New PAT
                          </span>
                          <span className="text-gray-600 fw-semibold fs-7 d-block text-start ps-0">
                            0
                          </span>
                        </div>

                        <div className="d-flex align-items-center">
                          <span className="text-gray-800 fw-bolder fs-4 me-3 d-block">
                            0
                          </span>
                          <div className="m-0">
                            <span className="badge badge-light-success fs-8">
                              <i className="ki-outline ki-arrow-up fs-5 text-green ms-n1" />
                              0%
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="separator separator-dashed my-3" />

                    <div
                      className="d-flex flex-stack"
                      style={{ borderLeft: "2px solid #383838" }}
                    >
                      <div className="symbol symbol-40px me-4">
                        <span className="symbol-label">
                          <i className="ki-duotone ki-user-tick text-gray-600 fs-1">
                            <span className="path1" />
                            <span className="path2" />
                            <span className="path3" />
                          </i>
                        </span>
                      </div>

                      <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                        <div className="me-5">
                          <span className="fw-bold text-primary fs-6">
                            Active Audience
                          </span>
                          <span className="text-gray-600 fw-semibold fs-7 d-block text-start ps-0">
                            0
                          </span>
                        </div>

                        <div className="d-flex align-items-center">
                          <span className="text-gray-800 fw-bolder fs-4 me-3 d-block">
                            0
                          </span>
                          <div className="m-0">
                            <span className="badge badge-light-success fs-8">
                              <i className="ki-outline ki-arrow-up fs-5 text-green ms-n1" />
                              0%
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="separator separator-dashed my-3" />

                    <div
                      className="d-flex flex-stack"
                      style={{ borderLeft: "2px solid #383838" }}
                    >
                      <div className="symbol symbol-40px me-4">
                        <span className="symbol-label">
                          <i className="ki-duotone ki-profile-user text-gray-600 fs-1">
                            <span className="path1" />
                            <span className="path2" />
                            <span className="path3" />
                            <span className="path4" />
                          </i>
                        </span>
                      </div>

                      <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                        <div className="me-5">
                          <span className="fw-bold text-primary fs-6">
                            New Audience
                          </span>
                          <span className="text-gray-600 fw-semibold fs-7 d-block text-start ps-0">
                            0%
                          </span>
                        </div>

                        <div className="d-flex align-items-center">
                          <span className="text-gray-800 fw-bolder fs-4 me-3 d-block">
                            0
                          </span>
                          <div className="m-0">
                            <span className="badge badge-light-warning fs-8">
                              <i className="ki-outline ki-arrow-up fs-5 text-warning ms-n1" />
                              0%
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
