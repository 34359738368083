import React from "react";
import NoData from "../../../../../component/no-data";
import { Skeleton } from "antd";
export default function (props) {
  const {
    campaignByAdSpendData,
    campaignOutOfBudget,
    campaignByTableLoading,
    outOfCampaignTableLoading,
  } = props;
  const loadingArr = [1, 2, 3, 4, 5];
  return (
    <>
      <div className="row g-5 g-xl-5 ">
        {/*begin::Col*/}
        <div className="col-xxl-6 mb-5 mb-xl-5 ">
          {/*begin::Maps widget 1*/}
          <div className="card card-flush h-xl-100">
            {/*begin::Header*/}
            <div className="card-header">
              {/*begin::Title*/}
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bold text-dark">
                  Top Campaigns By Ad Spend
                </span>
                {/* <span class="text-gray-400 pt-2 fw-semibold fs-6">Monthly Unit Sold Overview</span> */}
              </h3>
              {/*end::Title*/}
              {/*begin::Toolbar*/}
              <div className="card-toolbar"></div>
              {/*end::Toolbar*/}
            </div>
            {/*end::Header*/}
            {/*begin::Body*/}
            <div className="card-body pt-2 hover-scroll h-300px pb-0">
              <div className="table-responsive tbl-sales">
                {/*begin::Table*/}
                <table
                  className="table align-middle table-row-dashed  table-row-gray-300 gy-4 gx-4 border-top-d"
                  id
                >
                  <thead>
                    <tr className="fw-bolder text-dark bg-light-info">
                      <th className="min-w-150px">Campaign Name</th>
                      <th className="min-w-100px">Ad Spend</th>
                      <th className="min-w-50px">RoAS</th>
                      <th className="min-w-75px">CTR</th>
                      <th className="min-w-175px">CR (Units Sold Based)</th>
                      <th className="min-w-100px">Avg. CPC</th>
                    </tr>
                  </thead>
                  <tbody className="text-gray-700 fw-bold fs-7">
                    {campaignByTableLoading ? (
                      loadingArr?.map((d) => {
                        return (
                          <tr>
                            <td colSpan={6}>
                              <Skeleton.Button active block />
                            </td>
                          </tr>
                        );
                      })
                    ) : campaignByAdSpendData?.length != 0 ? (
                      campaignByAdSpendData?.map((d) => {
                        return (
                          <tr className>
                            <td>
                              <a href className="fw-bold">
                                {d?.campaign_name}
                              </a>
                            </td>
                            <td>${d?.ad_spend}</td>
                            <td>${d?.roas}</td>
                            <td>{d?.ctr}%</td>
                            <td>{d?.cr_unit_sold}%</td>
                            <td>${d?.cpc}</td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={6}>
                          <NoData height={"100px"} />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                {/*end::Table*/}
              </div>
            </div>
            {/*end::Body*/}
          </div>
          {/*end::Maps widget 1*/}
        </div>
        {/*end::Col*/}
        {/*begin::Col*/}
        <div className="col-xxl-6 mb-md-5 mb-xl-5 ">
          <div className="card card-flush h-xl-100">
            <div className="card-header pt-0">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bold text-dark">
                  Out of budget Campaigns
                </span>
                {/* <span class="text-gray-400 pt-2 fw-semibold fs-6">Monthly Unit Sold Overview</span> */}
              </h3>
              {/*end::Title*/}
              {/*begin::Toolbar*/}
              <div className="card-toolbar"></div>
              {/*end::Toolbar*/}
            </div>
            {/*end::Header*/}
            {/*begin::Body*/}
            <div className="card-body pt-2 hover-scroll h-300px pb-0">
              <div className="table-responsive tbl-sales">
                {/*begin::Table*/}
                <table
                  className="table align-middle table-row-dashed  table-row-gray-300 gy-4 gx-4 border-top-d"
                  id
                >
                  <thead>
                    <tr className="fw-bolder text-dark bg-light-info">
                      <th className="min-w-150px">Campaign Name</th>
                      <th className="min-w-150px">Campaign Type</th>
                      <th className="min-w-100px">Ad Spend </th>
                      <th className="min-w-150px">Daily Budget</th>
                      <th className="min-w-150px">Total Budget</th>
                      <th className="min-w-150px">Total Remaining Budget</th>
                      <th className="min-w-150px">Daily Remaining Budget</th>
                    </tr>
                  </thead>
                  <tbody className="text-gray-700 fw-bold fs-7">
                    {outOfCampaignTableLoading ? (
                      loadingArr?.map((d) => {
                        return (
                          <tr>
                            <td colSpan={7}>
                              <Skeleton.Button active block />
                            </td>
                          </tr>
                        );
                      })
                    ) : campaignOutOfBudget?.length != 0 ? (
                      campaignOutOfBudget?.map((d) => {
                        return (
                          <tr className>
                            <td>
                              <a href className="fw-bold">
                                {d?.campaign_name || "-"}
                              </a>
                            </td>
                            <td> {d?.campaign_type || "-"}</td>
                            <td>$ {d?.ad_spend || "0"}</td>
                            <td>$ {d?.daily_budget || "0"}</td>
                            <td>$ {d?.total_budget || "0"}</td>
                            <td>$ {d?.total_remaining_budget || "0"}</td>
                            <td>$ {d?.daily_remaining_budget || "0"}</td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={6}>
                          <NoData height={"100px"} />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                {/*end::Table*/}
              </div>
            </div>
            {/*end::Body*/}
          </div>
          {/*end::Maps widget 1*/}
        </div>
        {/*end::Col*/}
      </div>
    </>
  );
}
