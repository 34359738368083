import React from "react";

export const CustomDatepickerButton = (props) => {
  const {} = props;
  return (
    <>
      <button className="btn btn-primary">LW</button>
      <button className="btn btn-primary">L4W</button>
      <button className="btn btn-primary">L13W</button>
      <button className="btn btn-primary">YTD</button>
      <button className="btn btn-primary">L52W</button>
    </>
  );
};
