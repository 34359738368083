import React, { useEffect, useState } from "react";
// import { Modal } from "react-bootstrap";
import {
  CaretRightOutlined,
  DeleteTwoTone,
  PlusCircleOutlined,
} from "@ant-design/icons";
import {
  Collapse,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
  Select,
  Switch,
  TimePicker,
  Tooltip,
  message,
} from "antd";
import lodashObj from "lodash";
import { useSelector } from "react-redux";

import moment from "moment";
import dayjs from "dayjs";
import {
  createRepricingRule,
  repricingAmtPer,
} from "../../../../../config/static-select-option";
const { Panel } = Collapse;
const { TextArea } = Input;

const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;

export default function (props) {
  const {
    show,
    onHide,

    getList,
    Type,
    selectedRule,
  } = props;

  const [competitorModal, setCompetitorModal] = useState(false);

  const [isValidSubmit, setIsValidSubmit] = useState(false);

  const [loading, setLoading] = useState(false);
  const [change, setChange] = useState(false);

  const [sleepList, setSleepList] = useState([
    {
      start: null,
      stop: null,
    },
  ]);

  const [data, setData] = useState({
    rule_name: "",
    rule_type: "",
    amount: "",
    amount_type: "",
    rule_status: 0,
    exclusions: {
      exclude_non_buy_box_eligible_sellers: "",

      exclude_out_of_bounds_sellers: "",

      exclude_amazon: "",
      exclude_specific_merchants: "",
    },
    scenarios: {
      if_buy_box_is_suppressed: "",

      if_no_competitors: "",
      if_competition_is_below_min_price: "",
      if_competition_is_match_min_price: "",

      buy_box_chaser: "",

      sleep_mode: "",
    },
    add_competitor_rule: {
      your_competitors: {
        competitor_pricing_rule: {
          competitor_pricing_rule_1: "",
          the_competition_by: "",
          the_competition_by_amt_type: "",
        },
        buy_box_winner_rule: {
          buy_box_winner_rule_1: "",
          buy_box_winner_rule_2: "",
        },
        fulfilment_rule: {
          fulfilment_rule_1: "",
          fulfilment_rule_2: "",
        },
        prime_rule: {
          prime_rule_1: "",
          prime_rule_2: "",
        },
      },

      include_and_exclude: {
        marketplaces: {
          marketplace: "",
        },
        merchants: {
          merchants_1: "",
          merchants_input: "",
        },
        competitors_between: {
          competitors_between_min: "",
          competitors_between_max: "",
        },
      },
    },
  });

  useEffect(() => {
    if (show && Type !== "add") {
      setChange(true);
      console.log(JSON.parse(selectedRule?.additional_info), "selectedRule");

      setData({
        rule_name: selectedRule?.rule_name,
        rule_type: selectedRule?.rule_type,
        amount: selectedRule?.amount,
        amount_type: selectedRule?.amount_type,
        rule_status: Type === "edit" ? selectedRule?.rule_status : 1,
        ...JSON.parse(selectedRule?.additional_info),
      });
    }
  }, [show]);

  return (
    <Modal
      title={
        Type === "add"
          ? "Add New Repricer"
          : Type === "edit"
          ? "Update Repricer"
          : "Clone Repricer"
      }
      width={900}
      centered
      maskClosable={false}
      backdrop="static"
      footer={[
        <button
          className="btn btn-light-danger me-3"
          data-bs-dismiss="modal"
          onClick={() => {
            onHide();
          }}
        >
          Close
        </button>,
        <button
          // className={`btn btn-primary
          // ${
          //   !data.rule_name ||
          //   !data.rule_type ||
          //   !data.amount ||
          //   !data.amount_type
          //     ? "btn-disable-primary"
          //     : ""
          // }
          //  ms-2 fs-7 fw-bolder`}
          className={`btn btn-primary `}
          //   onClick={() => submit()}
        >
          {loading ? (
            <span>
              Please wait...
              <span className="spinner-border spinner-border-sm align-middle ms-2" />
            </span>
          ) : (
            <span className="indicator-label">
              {/* {Type === "add" ? "Create" : Type === "edit" ? "Edit" : "Clone"} */}
              Save
            </span>
          )}
        </button>,
      ]}
      onCancel={() => onHide()}
      open={show}
    >
      <div className="modal-body fadeInRight p-0">
        <div className="row mb-5">
          <div className="col-md-12 d-flex align-items-center">
            Basic Info&nbsp;
            <Tooltip
              title="Define how you want your Default Repricer Rule to behave."
              placement="bottom"
            >
              <i
                className="bi bi-info-circle-fill px-1"
                style={{ fontSize: 16, color: "rgb(126, 120, 239)" }}
              />
            </Tooltip>
          </div>

          <div className="col-12 mt-5">
            <div className="row">
              <div className="col-12 col-md-12">
                <div>
                  <span className="text-danger fs-4">&#8727;</span>
                  <span className="fw-bolder fs-7"> Repricing Rule Name</span>
                </div>
                <Input
                  placeholder="Repricing Rule Name"
                  size="large"
                  className="ant_common_input "
                  value={data?.rule_name}
                  onChange={(e) => {
                    const data_ = { ...data };
                    data_["rule_name"] = e.target.value;
                    setData(data_);
                  }}
                />
                <div className="mt-1" style={{ visibility: "visible" }}>
                  {!data?.rule_name && isValidSubmit ? (
                    <span className="text-danger">Rule Name is required</span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-12 col-md-4">
                <div>
                  <span className="text-danger fs-4">&#8727;</span>
                  <span className="fw-bolder fs-7">Default Pricing Rule</span>
                </div>
                <Select
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  style={{ width: "100%" }}
                  size="large"
                  options={createRepricingRule.map((d) => {
                    return { label: d?.label, value: d?.value };
                  })}
                  value={data?.rule_type || null}
                  onChange={(e) => {
                    const data_ = { ...data };
                    if (e === "match") {
                      data_["amount_type"] = "";
                      data_["amount"] = "";
                    }
                    data_["rule_type"] = e;
                    setData(data_);
                  }}
                  placeholder="Rule Type"
                  className="mb-1"
                />
                <div className="mt-1" style={{ visibility: "visible" }}>
                  {!data?.rule_type && isValidSubmit ? (
                    <span className="text-danger">Rule Type is required</span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="col-12 col-md-8">
                <div>
                  <span className="text-danger fs-4">&#8727;</span>
                  <span className="fw-bolder fs-7"> the competition by</span>
                </div>
                <div className="row">
                  <div className="col-12 col-md-6">
                    <Input
                      style={{
                        width: "100%",
                      }}
                      className="ant_common_input me-5"
                      size="large"
                      step={1}
                      placeholder="the competition by"
                      value={data?.amount}
                      disabled={data?.rule_type === "match"}
                      onChange={(e) => {
                        if (!e.target.value) {
                          const data_ = { ...data };
                          data_["amount"] = e.target.value;
                          setData(data_);
                        }
                        const newVal = e.target.value.split(".").join("");
                        const validation = /^[0-9]+$/.test(newVal);
                        if (validation) {
                          const data_ = { ...data };
                          data_["amount"] = e.target.value;
                          setData(data_);
                        }
                      }}
                    />
                    <div className="mt-1" style={{ visibility: "visible" }}>
                      {data?.rule_type !== "match" &&
                        !data?.amount &&
                        isValidSubmit && (
                          <span className="text-danger">
                            Competition by is required
                          </span>
                        )}
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <Select
                      disabled={data?.rule_type === "match"}
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentNode
                      }
                      size="large"
                      options={repricingAmtPer.map((d) => {
                        return { label: d?.label, value: d?.value };
                      })}
                      placeholder="Select"
                      value={data?.amount_type || null}
                      style={{ width: "100%" }}
                      onChange={(e) => {
                        const data_ = { ...data };
                        data_["amount_type"] = e;
                        setData(data_);
                      }}
                    />

                    <div className="mt-1">
                      {data?.rule_type !== "match" &&
                        !data?.amount_type &&
                        isValidSubmit && (
                          <span className="text-danger">
                            Amount Type is required
                          </span>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div>
              {/* <div className="row mb-5 align-items-center">
                <div className="col-md-3"></div>
                <div className="col-md-9 pe-md-5"></div>
              </div>
              <div className="row mb-5 align-items-center">
                <div className="col-md-3">
                  <span className="text-danger fs-4">&#8727;</span>
                  <span className="fw-bolder fs-7">Default Pricing Rule</span>
                </div>
                <div className="col-md-9 pe-md-5"></div>
              </div>
              <div className="row mb-5 align-items-center">
                <div className="col-md-3">
                  <span className="text-danger fs-4">&#8727;</span>
                  <span className="fw-bolder fs-7"> the competition by</span>
                </div>
                <div className="col-md-9 pe-md-5">
                  <div className="col-auto">
                    <Input
                      style={{
                        width: 150,
                      }}
                      className="ant_common_input"
                      size="large"
                      step={1}
                      placeholder="the competition by"
                      value={data?.amount}
                      disabled={data?.rule_type === "match"}
                      onChange={(e) => {
                        if (!e.target.value) {
                          const data_ = { ...data };
                          data_["amount"] = e.target.value;
                          setData(data_);
                        }
                        const newVal = e.target.value.split(".").join("");
                        const validation = /^[0-9]+$/.test(newVal);
                        if (validation) {
                          const data_ = { ...data };
                          data_["amount"] = e.target.value;
                          setData(data_);
                        }
                      }}
                    />
                    <div>
                      <span className="text-danger">Rule Type is required</span>
                    </div>
                  </div>
                  <div className="col-auto">
                    <Select
                      disabled={data?.rule_type === "match"}
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentNode
                      }
                      size="large"
                      options={repricingAmtPer.map((d) => {
                        return { label: d?.label, value: d?.value };
                      })}
                      placeholder="Select"
                      value={data?.amount_type || null}
                      style={{ width: "100px" }}
                      onChange={(e) => {
                        const data_ = { ...data };
                        data_["amount_type"] = e;
                        setData(data_);
                      }}
                    />
                    <div>
                      <span className="text-danger">Rule Type is required</span>
                    </div>
                  </div>
                </div>
              </div> */}

              {/* <div className="row  align-items-center">
                <div className="col-md-3">
                  <span className="text-danger fs-4">&#8727;</span>
                  <span className="fw-bolder fs-7">Default Pricing Rule</span>
                </div>
                <div className="col-md-9 ">
                  <div className="row g-3 align-items-center">
                    <div className="col-auto">
                      <Select
                        getPopupContainer={(triggerNode) =>
                          triggerNode.parentNode
                        }
                        style={{ width: "95px" }}
                        size="large"
                        options={createRepricingRule.map((d) => {
                          return { label: d?.label, value: d?.value };
                        })}
                        value={data?.rule_type || null}
                        onChange={(e) => {
                          const data_ = { ...data };
                          if (e === "match") {
                            data_["amount_type"] = "";
                            data_["amount"] = "";
                          }
                          data_["rule_type"] = e;
                          setData(data_);
                        }}
                        placeholder="Rule"
                      />
                    </div>
                    <div className="col-auto">
                      <span id className="form-text">
                        <span className="text-danger fs-4">&#8727;</span>
                        the competition by
                      </span>
                    </div>
                    <div className="col-auto">
                      <Input
                        style={{
                          width: 150,
                        }}
                        className="ant_common_input"
                        size="large"
                        step={1}
                        placeholder="the competition by"
                        value={data?.amount}
                        disabled={data?.rule_type === "match"}
                        onChange={(e) => {
                          if (!e.target.value) {
                            const data_ = { ...data };
                            data_["amount"] = e.target.value;
                            setData(data_);
                          }
                          const newVal = e.target.value.split(".").join("");
                          const validation = /^[0-9]+$/.test(newVal);
                          if (validation) {
                            const data_ = { ...data };
                            data_["amount"] = e.target.value;
                            setData(data_);
                          }
                        }}
                      />
                    </div>
                    <div className="col-auto">
                      <Select
                        disabled={data?.rule_type === "match"}
                        getPopupContainer={(triggerNode) =>
                          triggerNode.parentNode
                        }
                        size="large"
                        options={repricingAmtPer.map((d) => {
                          return { label: d?.label, value: d?.value };
                        })}
                        placeholder="Select"
                        value={data?.amount_type || null}
                        style={{ width: "100px" }}
                        onChange={(e) => {
                          const data_ = { ...data };
                          data_["amount_type"] = e;
                          setData(data_);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
