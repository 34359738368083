import { message } from "antd";
import { getUserId } from "../../../../config";
import axiosCall from "../../../configurations/network-services/axiosCall";
import walmartAxiosCall from "../../../configurations/network-services/walmartAxoisCall";

const prefix = "dashboard";

export const GetWalmartDBLogCampaignList = (id) => {
  message.destroy();
  // message.loading("Please Wait, Campaign creation is in progress...");
  const path = `campaign?campaign_id=${id}`;
  const responseType = "GET_WALMART_CAMPAIGN_BD_LOG_LIST";
  return walmartAxiosCall("get", path, responseType, null, {
    AUTHORIZATION: `Bearer ${localStorage.getItem("token")}`,
    "USER-ID": getUserId(),
    "MARKETPLACE-ID": "ATVPDKIKX0DER",
  });
};

export const GetWalmartCampaignList = (data) => {
  const path = `wm/advertising/campaigns?page=${data?.page || 1}&per-page=${
    data?.perPage || 25
  }&campaign_type=${data?.campaign_type || ""}&targeting_type=${
    data?.targeting_type || ""
  }&status=${data?.status || ""}&start_date=${
    data?.start_date || ""
  }&end_date=${data?.end_date || ""}&search_key=${
    data?.search_key || ""
  }&sort_by=${data?.sort || ""}&field_name=${data?.field_name || ""}`;
  const responseType = "GET_WALMART_CAMPAIGN_MANAGEMENT_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const DeleteWalmartCampaignAction = (data) => {
  const path = `campaign`;
  const responseType = "DELETE_WALMART_CAMPAIGN";
  return walmartAxiosCall("DELETE", path, responseType, data, {
    AUTHORIZATION: `Bearer ${localStorage.getItem("token")}`,
    // user_id: 8,
    // user_id: JSON.parse(localStorage.getItem("user"))?.id,
    "USER-ID": getUserId(),
    "MARKETPLACE-ID": "ATVPDKIKX0DER",
  });
};

export const GetSavedCamapignDetailsByCID = (campId) => {
  const path = `campaign?campaignId=${campId}`;
  // const path = `campaign?campaignId=1833286286`;
  const responseType = "GET_SAVED_CAMPAIGN_DETAILS_BY_CID";
  return walmartAxiosCall("PATCH", path, responseType, null, {
    AUTHORIZATION: `Bearer ${localStorage.getItem("token")}`,
    // user_id: 8,
    // user_id: JSON.parse(localStorage.getItem("user"))?.id,
    "USER-ID": getUserId(),
    "MARKETPLACE-ID": "ATVPDKIKX0DER",
  });
};

export const WalamrtCreateCampaign = (data, campaignId) => {
  const path = `campaign`;
  const method = campaignId ? "PUT" : "POST";
  const responseType = "WALMART_CREATE_CAMPAIGN";
  return walmartAxiosCall(method, path, responseType, data, {
    AUTHORIZATION: `Bearer ${localStorage.getItem("token")}`,
    // user_id: 8,
    // user_id: JSON.parse(localStorage.getItem("user"))?.id,
    "USER-ID": getUserId(),
    "MARKETPLACE-ID": "ATVPDKIKX0DER",
  });
};

export const WalamrtCreateAdGroupCampaign = (data, adGroupId) => {
  const path = `adgroup`;
  const method = adGroupId ? "PUT" : "POST";
  const responseType = "WAMLART_CREATE_AD_GROUP_CAMPAIGN";
  return walmartAxiosCall(method, path, responseType, data, {
    AUTHORIZATION: `Bearer ${localStorage.getItem("token")}`,
    // user_id: 8,
    // user_id: JSON.parse(localStorage.getItem("user"))?.id,
    "USER-ID": getUserId(),
    "MARKETPLACE-ID": "ATVPDKIKX0DER",
  });
};

export const WalmartCreateAdItemsCampaign = (
  data,
  operation_flag,
  campaignId
) => {
  const path = `aditem?campaignId=${campaignId}`;
  const responseType = "WALMART_CREATE_AD_ITEMS_CAMPAIGN";
  return walmartAxiosCall(operation_flag, path, responseType, data, {
    AUTHORIZATION: `Bearer ${localStorage.getItem("token")}`,
    // user_id: 8,
    // user_id: JSON.parse(localStorage.getItem("user"))?.id,
    "USER-ID": getUserId(),
    "MARKETPLACE-ID": "ATVPDKIKX0DER",
  });
};

export const WalmartCreateBidPlacementCampaign = (data, operation_flag) => {
  const path = `bid?type=placement`;
  const method = operation_flag === "edit" ? "PUT" : "POST";
  const responseType = "WALMART_CREATE_BID_PLACEMENT_CAMPAIGN";
  return walmartAxiosCall(method, path, responseType, data, {
    AUTHORIZATION: `Bearer ${localStorage.getItem("token")}`,
    // user_id: 8,
    // user_id: JSON.parse(localStorage.getItem("user"))?.id,
    "USER-ID": getUserId(),
    "MARKETPLACE-ID": "ATVPDKIKX0DER",
  });
};

export const WalmartCreateBidPlatformCampaign = (data, operation_flag) => {
  const path = `bid?type=platform`;
  const method = operation_flag === "edit" ? "PUT" : "POST";
  const responseType = "WALMART_CREATE_BID_PLATFORM_CAMPAIGN";
  return walmartAxiosCall(method, path, responseType, data, {
    AUTHORIZATION: `Bearer ${localStorage.getItem("token")}`,
    // user_id: 8,
    // user_id: JSON.parse(localStorage.getItem("user"))?.id,
    "USER-ID": getUserId(),
    "MARKETPLACE-ID": "ATVPDKIKX0DER",
  });
};

export const WalmartCreateBidPlacementInclusionCampaign = (data) => {
  const path = `placement`;
  const responseType = "WALMART_CREATE_PLACEMENT_INCLUSION_CAMPAIGN";
  return walmartAxiosCall("PUT", path, responseType, data, {
    AUTHORIZATION: `Bearer ${localStorage.getItem("token")}`,
    // user_id: 8,
    // user_id: JSON.parse(localStorage.getItem("user"))?.id,
    "USER-ID": getUserId(),
    "MARKETPLACE-ID": "ATVPDKIKX0DER",
  });
};

export const WalmartCreateKeywordCampaign = (
  data,
  operation_flag,
  campaignId,
  adGroupId
) => {
  const path =
    operation_flag === "POST"
      ? "keyword"
      : `keyword?campaignId=${campaignId}&adGroupId=${adGroupId}`;
  const responseType = "WALMART_CREATE_KEYWORD_CAMPAIGN";
  return walmartAxiosCall(operation_flag, path, responseType, data, {
    AUTHORIZATION: `Bearer ${localStorage.getItem("token")}`,
    // user_id: 8,
    // user_id: JSON.parse(localStorage.getItem("user"))?.id,
    "USER-ID": getUserId(),
    "MARKETPLACE-ID": "ATVPDKIKX0DER",
  });
};

export const WalmartGetCampaignKeywordList = (adGroupId) => {
  const path = `keyword?adGroupId=${adGroupId}`;
  const responseType = "WALMART_GET_CAMPAIGN_KEYWORD_LIST";
  return walmartAxiosCall("get", path, responseType, null, {
    AUTHORIZATION: `Bearer ${localStorage.getItem("token")}`,
    // user_id: 8,
    // user_id: JSON.parse(localStorage.getItem("user"))?.id,
    "USER-ID": getUserId(),
    "MARKETPLACE-ID": "ATVPDKIKX0DER",
  });
};

export const GetDirectWalmartCampaignAction = () => {
  const path = `all/campaign`;
  const responseType = "GET_DIRECT_WALMART_CAMPAIGN_LIST";
  return walmartAxiosCall("get", path, responseType, null, {
    AUTHORIZATION: `Bearer ${localStorage.getItem("token")}`,
    // user_id: 8,
    // user_id: JSON.parse(localStorage.getItem("user"))?.id,
    "USER-ID": getUserId(),
    "MARKETPLACE-ID": "ATVPDKIKX0DER",
  });
};
// ------------------------------------------------------------------------------------------------------------------------------------------  amazon API

export const GetAmazonCampaignList = (data) => {
  const path = `${prefix}/get-products?filter_date=${
    data?.filter_date || ""
  }&sales_channel=${data?.sales_channel || ""}`;
  const responseType = "GET_CAMPAIGN_MANAGEMENT_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetAmazonCampaignFilters = (data) => {
  const path = `campaigns-analytics/filters`;
  const responseType = "AMAZON_CAMPAIGN_FILTERS";
  return axiosCall("get", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetAmazonPerformaceMetrics = (data) => {
  const path = `campaigns-analytics/performance-metrics?advertising_type=${
    data?.advertising_type || ""
  }&request_type=${data?.requestType || "current_count"}&start_date=${
    data?.start_date || ""
  }&end_date=${data?.end_date || ""}&marketplace_id=${
    data?.marketplace_id || ""
  }`;
  const responseType = "AMAZON_PERFORMANCE_METRICS";
  return axiosCall("get", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetAmazonCampaignGraph = (data) => {
  const path = `campaigns-analytics/daily-performance-trend?advertising_type=${
    data?.advertising_type || ""
  }&request_type=${data?.requestType || "current_count"}&start_date=${
    data?.start_date || ""
  }&end_date=${data?.end_date || ""}&marketplace_id=${
    data?.marketplace_id || ""
  }`;
  const responseType = "AMAZON_CAMPAIGN_GRAPH";
  return axiosCall("get", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetAmazonCampaignTable = (data) => {
  const path = `campaigns-analytics/all-campaigns-table-data?marketplace_id=${
    data?.marketplace_id || ""
  }&page=${data?.page || 1}&per-page=${data?.perPage || 25}&request_type=${
    data?.requestType || "current_count"
  }
  `;
  const responseType = "CAMPAIGN_LIST_TABLE";
  return axiosCall("get", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetAmazonDayWiseCampaignList = (data) => {
  const path = `campaigns-analytics/day-wise-performance?advertising_type=${
    data?.advertising_type || ""
  }&request_type=${data?.requestType || "current_count"}&start_date=${
    data?.start_date || ""
  }&end_date=${data?.end_date || ""}
  `;
  const responseType = "DAY_WISE_CAMPAIGN_LIST_TABLE";
  return axiosCall("get", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const fakeActionCampaignManagement = (data) => async (dispatch) => {
  dispatch({ type: "FAKE_ACTION_CAMPAIGN_MANAGEMENT", state: data });
};
