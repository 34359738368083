import { Select, Tooltip, Input, Table, message } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Chart from "react-apexcharts";
import { useSelector } from "react-redux";
import Loading from "../../../../../component/loading";
import NoData from "../../../../../component/no-data";

export default function (props) {
  const {
    selectedRowAsinSku,
    marketplace_id,
    GetReplenishmentForecastAction,
    fakeActionInventoryManagement,
  } = props;

  const GetReplenishmentForecastDataRes = useSelector(
    (state) =>
      state.InventoryManagement.GetReplenishmentForecastDataResponse || {}
  );
  const [forecastAPIData, setForecastAPIData] = useState([]);
  const [chartDate, setChartDate] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);

  const [graphSeries, setGraphSeries] = useState([]);

  useEffect(() => {
    const obj = {
      sku: selectedRowAsinSku?.sku,
      asin: selectedRowAsinSku?.asin,
      marketplace_id: marketplace_id,
    };
    GetReplenishmentForecastAction(obj);
  }, []);

  useEffect(() => {
    if (GetReplenishmentForecastDataRes?.status === true) {
      message.destroy();
      message.success(GetReplenishmentForecastDataRes.message);
      setTableLoading(false);
      setForecastAPIData(GetReplenishmentForecastDataRes?.data?.forecast_data);
      console.log(
        GetReplenishmentForecastDataRes,
        "GetReplenishmentForecastDataRes"
      );
      setChartDate(
        GetReplenishmentForecastDataRes?.data?.chart_data?.chartDate
      );
      const series =
        GetReplenishmentForecastDataRes?.data?.chart_data?.chartData
          ?.map((d) => {
            delete d?.type;
            return { ...d };
          })
          .filter((a) => {
            const onlyFutureForecast = a?.name?.split("(")?.[0]?.trim(" ");
            return onlyFutureForecast === "Future forecast" && { ...a };
          }) || [];

      setGraphSeries(series);
      fakeActionInventoryManagement("GetReplenishmentForecastDataResponse");
    } else if (GetReplenishmentForecastDataRes?.status === false) {
      message.destroy();
      message.error(GetReplenishmentForecastDataRes.message);
      fakeActionInventoryManagement("GetReplenishmentForecastDataResponse");
    }
  }, [GetReplenishmentForecastDataRes]);

  const options = {
    chart: {
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        columnWidth: "40%",
        borderRadius: 3,
        // dataLabels: {
        //   position: "top", // Place the data labels at the top of bars
        //   offsetX: 0,
        //   offsetY: -20,
        // },
      },
    },
    colors: ["#4318ff", "#63CE94"],
    xaxis: {
      categories:
        chartDate?.map((d) => moment(new Date(d)).format("MMM")) || [],
    },
    yaxis: {
      min: 0,
      tickAmount: 5,
      labels: {
        formatter: (value) => Math.round(value),
      },
    },
  };

  const forecastColumns = [
    {
      title: "Period (days)",
      dataIndex: "days_in_stock",
      key: "days_in_stock",
    },
    {
      title: "Days in stock",
      dataIndex: "days_in_stock",
      key: "days_in_stock",
    },
    {
      title: "Units sold",
      dataIndex: "units_sold",
      key: "units_sold",
    },
    {
      title: "Velocity",
      dataIndex: "velocity",
      key: "velocity",
    },
  ];

  return (
    <>
      <div style={{ height: "500px", overflowY: "auto" }}>
        <div>
          <div className="mb-3 d-flex justify-content-between">
            <label className="fw-bold fs-4">
              Adjusted sales velocity parameters
            </label>
            <Tooltip
              placement="left"
              title="Average number of units sold per day. This number is adjusted by the weight entered per period."
              trigger="hover"
              className="me-4"
            >
              <i
                style={{ fontSize: "17px", color: "#827dcb" }}
                className="bi bi-info-circle mx-2 cursor-pointer"
              ></i>
            </Tooltip>
          </div>
          <div>
            {tableLoading ? (
              <Loading />
            ) : forecastAPIData?.length !== 0 ? (
              <Table
                dataSource={forecastAPIData}
                columns={forecastColumns}
                pagination={false}
              />
            ) : (
              <NoData />
            )}
          </div>
        </div>
        <div className="separator separator-dashed my-5" />
        <div className=" mt-7">
          <label className="fw-bold fs-4">Seasonality</label>
        </div>

        <div>
          <Chart
            options={options}
            series={graphSeries}
            type="bar"
            height={350}
          />
        </div>
      </div>
    </>
  );
}
