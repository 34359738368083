import { Checkbox, Input, Radio, Skeleton, Tag, Tooltip, message } from "antd";
import React, { useEffect, useState } from "react";
import { NumberWithCommas, urlDecode } from "../../../../../config";
import { useSelector } from "react-redux";
import OrderInfoModal from "./order-info-modal";
import OrderCancelModal from "./order-cancel-modal";
import { OrderProcessWrapper } from "../style";
import OrderStatusModal from "../order-status-modal";

const TabItemDetails = (props) => {
  const {
    GetOrderDetails,
    fakeActionWalmartOrders,
    poUrl,
    setSelectedTab,
    GetReturnCenterAddress,
    fakeActionuserConfiguration,
  } = props;
  const loadingArr = [1, 2, 3, 4, 5, 6];
  const loadingArr2 = [1, 2, 3, 4];

  const [orderData, setOrderData] = useState([]);
  const [addressList, setAddressList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedCheckbox, setSelectedCheckbox] = useState([]);
  const [processOrderOperation, setProcessOrderOperation] = useState("");
  const [isOpenOrderInfoModal, setIsOpenOrderInfoModal] = useState(false);
  const [isOpenCancelOrderModal, setIsOpenCancelOrderModal] = useState(false);
  const [isOpenOrderStatusModal, setIsOpenOrderStatusModal] = useState(false);
  const [allStatusData, setAllStatusData] = useState([]);
  // const [entireOrderToProcess, setEntireOrderToProcess] = useState(false);

  const GetReturnCenterAddrRes = useSelector(
    (state) => state.Configuration.GetReturnCenterAddrResponse || {}
  );

  const GetWalmartOrdersDetailsRes = useSelector(
    (state) => state.WalmartOrders.GetWalmartOrdersDetailsResponse || {}
  );

  useEffect(() => {
    GetOrderDetails({ id: poUrl?.poid, is_all: 1 });
    GetReturnCenterAddress();
  }, []);

  useEffect(() => {
    if (GetReturnCenterAddrRes.status === true) {
      setAddressList(GetReturnCenterAddrRes?.data || []);
      fakeActionuserConfiguration("GetReturnCenterAddrResponse");
    } else if (GetReturnCenterAddrRes.status === false) {
      fakeActionuserConfiguration("GetReturnCenterAddrResponse");
    }
  }, [GetReturnCenterAddrRes]);

  useEffect(() => {
    if (GetWalmartOrdersDetailsRes.status === true) {
      setOrderData(GetWalmartOrdersDetailsRes?.data);
      setLoading(false);
      fakeActionWalmartOrders("GetWalmartOrdersDetailsResponse");
    } else if (GetWalmartOrdersDetailsRes.status === false) {
      setLoading(false);
      fakeActionWalmartOrders("GetWalmartOrdersDetailsResponse");
    }
  }, [GetWalmartOrdersDetailsRes]);

  const [radioOperationStatus, setRadioOperationStatus] = useState("Ship");
  const onRadioOperationStatus = (e) => {
    setRadioOperationStatus(e.target.value);
  };
  return (
    <>
      <div className="row gy-5 g-xl-5 ">
        <div className="col-xxl-12">
          <OrderProcessWrapper>
            <div className="card card-xxl-stretch mb-5 mb-xl-8">
              <div className="card-header border-bottom-dashed">
                <h3 className="card-title align-items-start flex-column">
                  <div>
                    {" "}
                    <span className="card-label fw-bolder text-dark">
                      Items Details
                    </span>
                    <Radio.Group
                      onChange={onRadioOperationStatus}
                      value={radioOperationStatus}
                      className="ms-5"
                    >
                      <Radio value={"Ship"}>Ship</Radio>
                      <Radio value={"Cancel"}>Cancel</Radio>
                    </Radio.Group>
                  </div>
                </h3>
                <div className="card-toolbar">
                  {radioOperationStatus === "Ship" && (
                    <div className="position-relative">
                      <button
                        className="btn btn-primary"
                        disabled={selectedCheckbox?.length == 0}
                        onClick={() => {
                          setProcessOrderOperation("ship");
                          setIsOpenOrderInfoModal(true);
                        }}
                      >
                        Ship Item
                      </button>
                    </div>
                  )}
                  {radioOperationStatus === "Cancel" && (
                    <div className="position-relative">
                      <button
                        className="btn btn-danger ms-2"
                        disabled={selectedCheckbox?.length == 0}
                        onClick={() => {
                          setProcessOrderOperation("cancel");
                          setIsOpenCancelOrderModal(true);
                        }}
                      >
                        Cancel Item
                      </button>
                    </div>
                  )}
                </div>
              </div>
              <div className="card-body p-9">
                <div className="row g-5 mb-0">
                  <div className="col-md-6 ">
                    {loading &&
                      loadingArr?.map((d) => {
                        return (
                          <div className="row mb-2">
                            <div className="col-lg-4 fw-normal text-gray-700 fs-6">
                              <Skeleton.Button active block size="medium" />
                            </div>
                            <div className="col-lg-8">
                              <span className="fw-bold fs-6 text-gray-800 text-two">
                                <Skeleton.Button active block size="medium" />
                              </span>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                  <div className="col-md-5 mx-auto">
                    {loading &&
                      loadingArr2?.map((d) => {
                        return (
                          <>
                            <div className=" bg-light py-2 px-7 rounded-1">
                              <div className="row mb-7">
                                <label className="col-lg-4 fw-bold text-dark fs-6">
                                  <Skeleton.Button active block size="medium" />
                                </label>
                                <div className="col-lg-8 fv-row">
                                  <span className="fw-bold text-gray-800 fs-6">
                                    <Skeleton.Button
                                      active
                                      block
                                      size="medium"
                                    />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })}
                  </div>
                </div>
                {/* {!loading && (
                  <div className="row">
                    <div className="col-12  d-flex align-items-center">
                      <label
                        className="fw-bold fs-4 me-5"
                        style={{
                          color:
                            entireOrderToProcess === "Cancel"
                              ? "red"
                              : "#4318ff",
                        }}
                      >
                        {radioOperationStatus} entire order ?
                      </label>
                      <Checkbox
                        checked={entireOrderToProcess}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setEntireOrderToProcess(true);
                            orderData?.map((a) => {
                              if (a?.order_line_status === "Acknowledged") {
                                setSelectedCheckbox((prev) => {
                                  return [
                                    ...prev,
                                    {
                                      ...a,
                                      qty_to_ship: a?.order_line_quantity,
                                    },
                                  ];
                                });
                              }
                            });
                          } else {
                            setEntireOrderToProcess(false);
                            setSelectedCheckbox([]);
                          }
                        }}
                      />
                    </div>
                  </div>
                )} */}

                <div className="separator separator-dashed my-5" />
                {!loading &&
                  Array.isArray(orderData) &&
                  orderData?.map((d) => {
                    const totalAmt =
                      parseFloat(d?.product_charge_amount || 0) +
                      parseFloat(d?.shipping_charge_amount || 0) +
                      parseFloat(d?.product_charge_tax_amount || 0);
                    const qtyToShip = selectedCheckbox.filter(
                      (a) => a.order_line_number == d.order_line_number
                    );
                    const newStatus = JSON.parse(d?.order_line_status_details);
                    const myNewStatus = newStatus?.map((ns) => {
                      return {
                        status: ns?.status,
                        quantity: ns?.statusQuantity?.amount,
                      };
                    });
                    const statusArr = newStatus?.map((ns) => {
                      return ns?.status;
                    });
                    const isAcknowledge = statusArr?.includes("Acknowledged");
                    console.log(
                      myNewStatus,
                      statusArr,
                      newStatus,
                      newStatus?.[0]?.statusQuantity?.unitOfMeasurement,
                      "-myNewStatus"
                    );

                    let statusQuantities = {};

                    // Loop through the orders array
                    myNewStatus?.forEach((order) => {
                      // Check if the status exists in the statusQuantities object
                      if (statusQuantities.hasOwnProperty(order.status)) {
                        // If it exists, add the quantity to the existing value
                        statusQuantities[order.status] += parseInt(
                          order.quantity
                        );
                      } else {
                        // If it doesn't exist, create a new property and set its value to the quantity
                        statusQuantities[order.status] = parseInt(
                          order.quantity
                        );
                      }
                    });
                    console.log(statusQuantities, "-statusQuantities");
                    return (
                      <>
                        <div className="row mb-7">
                          <div className="col-md-6">
                            <div className="row">
                              <label className="col-lg-4 fw-bold text-gray-700 fs-6">
                                {isAcknowledge && (
                                  <Checkbox
                                    checked={
                                      selectedCheckbox.filter(
                                        (a) =>
                                          a.order_line_number ==
                                          d.order_line_number
                                      )?.length == 1
                                        ? true
                                        : false
                                    }
                                    onChange={(e) => {
                                      const checkboxId = d.order_line_number;
                                      setSelectedCheckbox((prev) => {
                                        if (e?.target?.checked) {
                                          return [
                                            ...prev,
                                            {
                                              ...d,
                                              qty_to_ship: 1,
                                              new_unit_of_measurement:
                                                newStatus?.[0]?.statusQuantity
                                                  ?.unitOfMeasurement,
                                            },
                                          ];
                                        } else {
                                          return prev.filter(
                                            (b) =>
                                              b.order_line_number !== checkboxId
                                          );
                                        }
                                      });
                                    }}
                                  />
                                )}
                              </label>
                              {console.log(
                                selectedCheckbox,
                                "------ Selected CHeckbox"
                              )}
                              <div className="col-lg-8 fv-row">
                                <Tag className="fw-bold  fs-6" color="blue">
                                  Orderline {d?.order_line_number || ""}
                                </Tag>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row g-5 mb-0">
                          <div className="col-md-6 ">
                            <div className="row">
                              <div className="col-lg-4 fw-bold text-gray-700 fs-6">
                                {/* <img
                                src={d?.product_image}
                                style={{ width: "70px" }}
                              /> */}
                                Item Name
                              </div>
                              <div className="col-lg-8">
                                <span className="fw-bold fs-6 text-primary text-two">
                                  {d?.product_name || "-"}
                                </span>
                              </div>
                            </div>

                            <div className="row mb-7">
                              <label className="col-lg-4 fw-bold text-gray-700 fs-6">
                                SKU
                              </label>
                              <div className="col-lg-8 fv-row">
                                <Tag className="fw-bold  fs-6" color="magenta">
                                  {d?.sku || ""}
                                </Tag>
                              </div>
                            </div>
                            <div className="row mb-7">
                              <label className="col-lg-4 fw-bold text-gray-700 fs-6">
                                Fulfilled by
                              </label>
                              <div className="col-lg-8 fv-row">
                                <span className="fw-bold text-gray-800 fs-6">
                                  <div className="d-flex align-items-center">
                                    <span>{d?.ship_node_type || "-"}</span>
                                  </div>
                                </span>
                              </div>
                            </div>
                            <div className="row mb-7">
                              <label className="col-lg-4 fw-bold text-gray-700 fs-6">
                                Total Order Quantity
                              </label>
                              <div className="col-lg-8 fv-row d-flex align-items-center">
                                <span className="fw-bold text-gray-800 fs-6">
                                  {d?.order_line_quantity || "0"}
                                </span>
                              </div>
                            </div>
                            <div className="row mb-7">
                              <label className="col-lg-4 fw-bold text-gray-700 fs-6">
                                Remaining Order Quantity
                              </label>
                              <div className="col-lg-8 fv-row d-flex align-items-center">
                                <span
                                  className={`fw-bold ${
                                    d?.acknowledge_line_quantity == 0
                                      ? "text-danger"
                                      : "text-green"
                                  } fs-4`}
                                >
                                  {d?.acknowledge_line_quantity || "0"}
                                </span>
                              </div>
                            </div>
                            {selectedCheckbox.filter(
                              (a) => a.order_line_number == d.order_line_number
                            )?.length == 1 ? (
                              <div className="row mb-7">
                                <label className="col-lg-4 fw-bold text-gray-700 fs-6">
                                  Quantity to {radioOperationStatus}
                                </label>
                                <div className="col-lg-8 fv-row">
                                  <span className="fw-bold text-gray-800 fs-6">
                                    <div className="d-flex align-items-center">
                                      <Input
                                        style={{ width: "150px" }}
                                        size="medium"
                                        placeholder="Quantity to ship"
                                        value={qtyToShip?.[0]?.qty_to_ship}
                                        onChange={(e) => {
                                          const checkboxId =
                                            d.order_line_number;
                                          if (!e.target.value) {
                                            setSelectedCheckbox((prev) => {
                                              return prev?.map((a) => {
                                                if (
                                                  a?.order_line_number ===
                                                  checkboxId
                                                ) {
                                                  return {
                                                    ...d,
                                                    qty_to_ship: e.target.value,
                                                  };
                                                } else {
                                                  return a;
                                                }
                                              });
                                            });
                                          } else {
                                            const validation = /^[0-9]+$/.test(
                                              e.target.value
                                            );
                                            if (validation) {
                                              setSelectedCheckbox((prev) => {
                                                return prev?.map((a) => {
                                                  if (
                                                    a?.order_line_number ===
                                                    checkboxId
                                                  ) {
                                                    return {
                                                      ...d,
                                                      qty_to_ship:
                                                        e.target.value,
                                                    };
                                                  } else {
                                                    return a;
                                                  }
                                                });
                                              });
                                            }
                                          }
                                        }}
                                        onBlur={(e) => {
                                          if (
                                            !e.target.value ||
                                            e.target.value == 0
                                          ) {
                                            message.destroy();
                                            message.info(
                                              "Minimum one quantity is required"
                                            );
                                            const checkboxId =
                                              d.order_line_number;
                                            setSelectedCheckbox((prev) => {
                                              return prev?.map((a) => {
                                                if (
                                                  a?.order_line_number ===
                                                  checkboxId
                                                ) {
                                                  return {
                                                    ...d,
                                                    qty_to_ship: 1,
                                                  };
                                                } else {
                                                  return a;
                                                }
                                              });
                                            });
                                          } else if (
                                            e.target.value >
                                            d?.acknowledge_line_quantity
                                          ) {
                                            message.destroy();
                                            message.info(
                                              `Can not ${radioOperationStatus} quantity more than ordered quantity `
                                            );
                                            const checkboxId =
                                              d.order_line_number;
                                            setSelectedCheckbox((prev) => {
                                              return prev?.map((a) => {
                                                if (
                                                  a?.order_line_number ===
                                                  checkboxId
                                                ) {
                                                  return {
                                                    ...d,
                                                    qty_to_ship: 1,
                                                  };
                                                } else {
                                                  return a;
                                                }
                                              });
                                            });
                                          }
                                        }}
                                      />
                                    </div>
                                  </span>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                            <div className="row mb-7">
                              <label className="col-lg-4 fw-bold text-gray-700 fs-6">
                                Status
                              </label>
                              <div className="col-lg-8 fv-row">
                                {Object?.entries(statusQuantities)?.map(
                                  (ms) => {
                                    return (
                                      <span className="fw-bold text-gray-800 fs-6">
                                        <div className="d-flex align-items-center">
                                          <Tooltip
                                            title="View orderline status details"
                                            placement="bottom"
                                          >
                                            {" "}
                                            <Tag
                                              color={
                                                ms?.[0] === "Cancelled"
                                                  ? "error"
                                                  : ms?.[0] === "Shipped"
                                                  ? "geekblue"
                                                  : ms?.[0] === "Delivered"
                                                  ? "green"
                                                  : ms?.[0] === "Acknowledged"
                                                  ? "orange"
                                                  : "purple"
                                              }
                                              style={{
                                                textDecoration: "underline",
                                              }}
                                              className="fw-bold my-1 cursor-pointer"
                                              onClick={() => {
                                                setIsOpenOrderStatusModal(true);
                                                setAllStatusData(newStatus);
                                              }}
                                            >
                                              {ms?.[0]} : {ms?.[1]}
                                            </Tag>
                                          </Tooltip>
                                        </div>
                                      </span>
                                    );
                                  }
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-5 mx-auto">
                            <div className=" bg-light p-7 px-7 rounded-1">
                              <div className="row mb-7">
                                <label className="col-lg-6 fw-bold text-dark fs-6">
                                  Subtotal (1 Item)
                                </label>
                                <div className="col-lg-6 fv-row">
                                  <span className="fw-bold text-gray-800 fs-6">
                                    $
                                    {NumberWithCommas(
                                      d?.product_charge_amount || 0
                                    )}
                                  </span>
                                </div>
                              </div>
                              <div className="row mb-7">
                                <label className="col-lg-6 fw-normal text-gray-700 fs-6">
                                  Shipping Fee:
                                </label>
                                <div className="col-lg-6 fv-row">
                                  <span className="fw-bold text-gray-800 fs-6">
                                    $
                                    {NumberWithCommas(
                                      d?.shipping_charge_amount || 0
                                    )}
                                  </span>
                                </div>
                              </div>
                              <div className="row mb-7">
                                <label className="col-lg-6 fw-normal text-gray-700 fs-6">
                                  Taxes:
                                </label>
                                <div className="col-lg-6 fv-row">
                                  <span className="fw-bold text-gray-800 fs-6">
                                    $
                                    {NumberWithCommas(
                                      d?.product_charge_tax_amount || 0
                                    )}
                                  </span>
                                </div>
                              </div>
                              <div className="separator separator-dashed borde-gray-300 my-4 w-75" />
                              <div className="row mb-7">
                                <label className="col-lg-6 fw-bold text-dark fs-6">
                                  Total:
                                </label>
                                <div className="col-lg-6 fv-row">
                                  <span className="fw-bold text-green fs-6">
                                    ${NumberWithCommas(totalAmt)}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="separator separator-dashed my-5" />
                      </>
                    );
                  })}
              </div>
            </div>
          </OrderProcessWrapper>
        </div>
      </div>

      {isOpenOrderInfoModal && (
        <OrderInfoModal
          show={isOpenOrderInfoModal}
          onHide={() => {
            setIsOpenOrderInfoModal(false);
          }}
          processOrderOperation={processOrderOperation}
          selectedPoId={poUrl?.poid}
          selectedCheckbox={selectedCheckbox}
          onSuccess={() => {
            setSelectedTab(1);
          }}
          addressList={addressList}
          {...props}
        />
      )}
      {isOpenOrderStatusModal && (
        <OrderStatusModal
          show={isOpenOrderStatusModal}
          onHide={() => {
            setIsOpenOrderStatusModal(false);
            setAllStatusData([]);
          }}
          data={allStatusData}
        />
      )}
      {isOpenCancelOrderModal && (
        <OrderCancelModal
          show={isOpenCancelOrderModal}
          onHide={() => {
            setIsOpenCancelOrderModal(false);
          }}
          processOrderOperation={processOrderOperation}
          selectedPoId={poUrl?.poid}
          selectedCheckbox={selectedCheckbox}
          setSelectedCheckbox={setSelectedCheckbox}
          onSuccess={() => {
            setLoading(true);
            setSelectedCheckbox([]);
            GetOrderDetails({ id: poUrl?.poid, is_all: 1 });
          }}
          {...props}
        />
      )}
    </>
  );
};

export default TabItemDetails;
