export default (state, action) => {
  if (typeof state === "undefined") {
    return [];
  }
  switch (action.type) {
    case "GET_LISTING_OPTIMIZER_PRODUCTS_SUCCESS":
    case "GET_LISTING_OPTIMIZER_PRODUCTS_ERROR":
      return {
        ...state,
        GetListingOptimizerProductsResponse: action.updatePayload,
      };

    case "GET_LISTING_OPTIMIZER_PRODUCTS_DETAILS_SUCCESS":
    case "GET_LISTING_OPTIMIZER_PRODUCTS_DETAILS_ERROR":
      return {
        ...state,
        GetListingOptimizerProductsDetailsResponse: action.updatePayload,
      };

    case "GET_CRITERIA_ANALYSIS_TITLE_SUCCESS":
    case "GET_CRITERIA_ANALYSIS_TITLE_ERROR":
      return {
        ...state,
        GetCriteriaAnalysisTitleResponse: action.updatePayload,
      };
    case "GET_CRITERIA_ANALYSIS_BULLET_POINTS_SUCCESS":
    case "GET_CRITERIA_ANALYSIS_BULLET_POINTS_ERROR":
      return {
        ...state,
        GetCriteriaAnalysisBulletPointsResponse: action.updatePayload,
      };
    case "GET_CRITERIA_ANALYSIS_PRODUCT_DESCRIPTION_SUCCESS":
    case "GET_CRITERIA_ANALYSIS_PRODUCT_DESCRIPTION_ERROR":
      return {
        ...state,
        GetCriteriaAnalysisProductDescResponse: action.updatePayload,
      };
    case "GET_RECOMMENDATION_TITLE_SUCCESS":
    case "GET_RECOMMENDATION_TITLE_ERROR":
      return {
        ...state,
        GetRecommendationTitleResponse: action.updatePayload,
      };
    case "GET_RECOMMENDATION_DESCRIPTION_SUCCESS":
    case "GET_RECOMMENDATION_DESCRIPTION_ERROR":
      return {
        ...state,
        GetRecommendationDescriptionResponse: action.updatePayload,
      };
    case "GET_RECOMMENDATION_BULLETS_SUCCESS":
    case "GET_RECOMMENDATION_BULLETS_ERROR":
      return {
        ...state,
        GetRecommendationBulletsResponse: action.updatePayload,
      };
    case "START_PRODUCT_ANALYSIS_SUCCESS":
    case "START_PRODUCT_ANALYSIS_ERROR":
      return {
        ...state,
        StartProductAnalysisResponse: action.updatePayload,
      };

    case "FAKE_ACTION_LISTING_OPTIMIZER":
      return { ...state, [action?.state]: {} };
    default:
      return state;
  }
};
