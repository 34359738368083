import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { DefaultPerPage, timeSince } from "../../../../config";
import IvcsrTable from "../../../../component/ivcsr-table";
import { Input, Popconfirm, Segmented, Tooltip, message } from "antd";
import moment from "moment/moment";
import Loading from "../../../../component/loading";
import NoData from "../../../../component/no-data";
import Pagination from "../../../../component/pagination";
import UserModal from "./lib/user-modal";
import { Wrapper } from "./style";
import { SearchOutlined } from "@ant-design/icons";
import { GlobalContext } from "../../../../commonContext";

export default function (props) {
  const {
    GetUserList,
    SwitchUserAction,
    DeleteUserAction,
    fakeActionManageUser,
  } = props;
  const contextVar = useContext(GlobalContext);

  const GetUserListRes = useSelector(
    (state) => state.ManageUser.GetUserListResponse || {}
  );
  const SwitchUserRes = useSelector(
    (state) => state.ManageUser.SwitchUserResponse || {}
  );
  const DeleteUserRes = useSelector(
    (state) => state.ManageUser.DeleteUserResponse || {}
  );

  const history = useHistory();

  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageSize, setPageSize] = useState(DefaultPerPage);
  const [openModal, setOpenModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);
  const [selectedSegment, setSelectedSegment] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [isSwitching, setIsSwitching] = useState(false);

  useEffect(() => {
    GetUserList({ page: page, perPage: pageSize, search_key: searchTerm });
  }, []);

  useEffect(() => {
    if (GetUserListRes.status === true) {
      setList(GetUserListRes?.data?.records);
      // console.log(GetUserListRes, "GetUserListRes");
      setLoading(false);
      setTotalPage(GetUserListRes?.data?.pagination?.totalCount);
      fakeActionManageUser("GetUserListResponse");
    } else if (GetUserListRes.status === false) {
      setLoading(false);
      // message.error(SwitchUserRes?.message);
      fakeActionManageUser("GetUserListResponse");
    }
  }, [GetUserListRes]);

  useEffect(() => {
    if (SwitchUserRes.status === true) {
      message.destroy();
      message.success(SwitchUserRes?.message);
      setTimeout(() => {
        let adminToken = localStorage.getItem("token");
        let adminData = localStorage.getItem("user");
        const data = {
          token: adminToken,
          data: adminData,
        };

        localStorage.setItem("adminData", JSON.stringify(data));
        localStorage.setItem(
          "platformType",
          SwitchUserRes?.data?.user_data?.connected_by === "both"
            ? "amazon"
            : SwitchUserRes?.data?.user_data?.connected_by
        );
        localStorage.setItem(
          "connected_by",
          SwitchUserRes?.data?.user_data?.connected_by
        );
        contextVar?.updateCommonGlobalVal({
          marketplaceType:
            SwitchUserRes?.data?.user_data?.connected_by === "both"
              ? "amazon"
              : SwitchUserRes?.data?.user_data?.connected_by,
          user_photo: SwitchUserRes?.data?.user_data?.photo,
          user_data: SwitchUserRes?.data?.user_data,
        });

        localStorage.setItem(
          "user",
          JSON.stringify(SwitchUserRes?.data?.user_data)
        );
        localStorage.setItem(
          "userType",
          SwitchUserRes?.data?.user_data?.user_type
        );
        localStorage.setItem(
          "userCredential",
          JSON.stringify(SwitchUserRes?.data?.credentials)
        );
        localStorage.setItem("token", SwitchUserRes?.data?.auth_token);
        setIsSwitching(false);
        history.replace("/dashboard");
        fakeActionManageUser("SwitchUserResponse");
      }, 500);
    } else if (SwitchUserRes.status === false) {
      setIsSwitching(false);
      message.destroy();
      message.error(SwitchUserRes?.message);
      fakeActionManageUser("SwitchUserResponse");
    }
  }, [SwitchUserRes]);

  useEffect(() => {
    if (DeleteUserRes.status === true) {
      message.destroy();
      message.success(DeleteUserRes?.message);
      setPage(1);
      setPageSize(DefaultPerPage);
      setLoading(true);
      GetUserList({
        page: 1,
        perPage: DefaultPerPage,
        search_key: searchTerm,
      });
      fakeActionManageUser("DeleteUserResponse");
    } else if (DeleteUserRes.status === false) {
      message.destroy();
      message.error(DeleteUserRes?.message);
      fakeActionManageUser("DeleteUserResponse");
    }
  }, [DeleteUserRes]);

  // const columns = [
  //   {
  //     title: "Sr. No.",
  //     ellipsis: true,
  //     width: 100,
  //     align: "left",
  //     render: (_, __, i) => {
  //       return <span>{(page - 1) * pageSize + 1 + i}</span>;
  //     },
  //   },
  //   {
  //     title: "Name",
  //     width: 150,
  //     align: "left",
  //     render: (text) => {
  //       return <span className="fw-bolder">{text?.name || "-"}</span>;
  //     },
  //   },
  //   {
  //     title: "Email",
  //     width: 200,
  //     align: "left",
  //     render: (text) => {
  //       return <span>{text?.email || "-"}</span>;
  //     },
  //   },
  //   {
  //     title: "Contact No",
  //     width: 150,
  //     align: "left",
  //     render: (text) => {
  //       return <span>{text?.contact_no || "-"}</span>;
  //     },
  //   },

  //   {
  //     title: "Switch User",
  //     width: 150,
  //     align: "center",
  //     render: (text) => {
  //       return (
  //         <Tooltip title="Switch user">
  //           <span
  //             onClick={() => {
  //               message.destroy();
  //               message.loading("Switching...", 0);
  //               // SwitchUserAction(text?.id);
  //             }}
  //             style={{ cursor: "pointer" }}
  //           >
  //             <i class="ki-outline ki-arrow-right-left fs-1 text-danger me-3" />
  //           </span>
  //         </Tooltip>
  //       );
  //     },
  //   },
  //   {
  //     title: "Created At",
  //     width: 200,
  //     align: "left",
  //     render: (text) => {
  //       return (
  //         <div>
  //           <span>
  //             {moment(new Date(text.created_at * 1000)).format(
  //               "MMM DD, YYYY hh:mm A"
  //             )}
  //           </span>
  //           <br />
  //           <span className="timeStampColor">
  //             ({timeSince(text.created_at)})
  //           </span>
  //         </div>
  //       );
  //     },
  //   },

  //   {
  //     title: "Updated At",
  //     width: 200,
  //     align: "left",
  //     render: (text) => {
  //       return (
  //         <div>
  //           <span>
  //             {text?.updated_at !== 1
  //               ? moment(new Date(text.updated_at * 1000)).format(
  //                   "MMM DD, YYYY hh:mm A"
  //                 )
  //               : moment(new Date()).format("MMM DD, YYYY hh:mm A")}
  //           </span>
  //           <br />
  //           <span className="timeStampColor">
  //             {text?.updated_at !== 1
  //               ? timeSince(text?.updated_at)
  //               : timeSince(Date.parse(new Date()) / 1000)}
  //           </span>
  //         </div>
  //       );
  //     },
  //   },
  //   {
  //     title: "Action",
  //     width: 100,
  //     align: "center",
  //     render: (text) => {
  //       return (
  //         <div className="d-flex flex-shrink-0">
  //           <span
  //             className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2"
  //             onClick={() => {
  //               setSelectedRow(text);
  //               setOpenModal(true);
  //             }}
  //           >
  //             <i className="ki-outline ki-pencil fs-2 text-primary" />
  //           </span>

  //           <Popconfirm
  //             placement="left"
  //             title="Delete"
  //             description="Are you sure to delete this User ?"
  //             onConfirm={() => {
  //               message.destroy();
  //               message.loading("Loading", 0);
  //               DeleteUserAction(text?.id);
  //             }}
  //             onCancel={() => {}}
  //             okText="Yes"
  //             cancelText="No"
  //             okButtonProps={{
  //               style: { backgroundColor: "green" },
  //             }}
  //           >
  //             <span className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
  //               <i className="ki-outline ki-trash fs-2 text-danger" />
  //             </span>
  //           </Popconfirm>
  //         </div>
  //       );
  //     },
  //   },
  // ];

  const onPageNo = (e) => {
    setLoading(true);
    const apiObj = {
      page: e,
      perPage: pageSize,
      search_key: searchTerm,
    };
    GetUserList(apiObj);
    setPage(e);
  };

  const onPerPage = (e) => {
    setPage(1);
    setPageSize(e);
    setLoading(true);
    const apiObj = {
      page: 1,
      perPage: e,
      search_key: searchTerm,
    };
    GetUserList(apiObj);
  };

  return (
    <Wrapper>
      <div className="d-flex flex-column flex-column-fluid fadeInRight">
        {/*begin::Content*/}
        <div id="kt_app_content" className="app-content flex-column-fluid">
          {/*begin::Content container*/}
          <div
            id="kt_app_content_container"
            className="app-container container-fluid"
          >
            {/*begin::Row*/}
            <div className="row g-5 mb-7">
              <div className="col-md-6">
                <div className="position-relative">
                  <Input
                    type="text"
                    className="text-dark w-250px fs-7 fw-bold ant_common_input "
                    name="all_search"
                    placeholder="Search by Name, Email..."
                    value={searchTerm}
                    size="large"
                    onChange={(e) => {
                      setSearchTerm(e.target.value);
                    }}
                    onPressEnter={(e) => {
                      setLoading(true);
                      GetUserList({
                        page: page,
                        perPage: pageSize,
                        search_key: e.target.value,
                      });
                    }}
                    prefix={
                      <SearchOutlined
                        style={{ fontSize: "19px", color: "grey" }}
                      />
                    }
                  />
                </div>
              </div>
              <div className="col-md-6 text-md-end">
                <div className="d-flex justify-content-end">
                  <div className="me-2">
                    <Segmented
                      onChange={(e) => {
                        setSelectedSegment(e);
                      }}
                      options={[
                        {
                          label: (
                            <div
                              style={{
                                padding: 4,
                              }}
                            >
                              <span
                                className={`nav-link btn btn-active-light-info btn-active-color-info btn-color-gray-500 py-2 px-3  fs-6 fw-semibold ${
                                  selectedSegment == 1 && "active"
                                }`}
                              >
                                <i className="ki-duotone ki-questionnaire-tablet fs-2 pe-0">
                                  <span className="path1" />
                                  <span className="path2" />
                                  <span className="path3" />
                                  <span className="path4" />
                                </i>
                              </span>
                            </div>
                          ),
                          value: 1,
                        },
                        {
                          label: (
                            <div
                              style={{
                                padding: 4,
                              }}
                            >
                              <span
                                className={`nav-link btn btn-active-light-info btn-active-color-info btn-color-gray-500 py-2 px-3  fs-6 fw-semibold ${
                                  selectedSegment == 2 && "active"
                                }`}
                              >
                                <i className="ki-duotone ki-element-11 fs-3 pe-0">
                                  <span className="path1" />
                                  <span className="path2" />
                                  <span className="path3" />
                                  <span className="path4" />
                                </i>
                              </span>
                            </div>
                          ),
                          value: 2,
                        },
                      ]}
                    />
                  </div>

                  <button
                    onClick={() => {
                      setOpenModal(true);
                    }}
                    className="btn  fs-6 btn-primary fw-bold"
                  >
                    Add New User
                  </button>
                </div>
              </div>
            </div>
            <div className="tab-content px-0">
              {loading ? (
                <Loading />
              ) : (
                selectedSegment == 1 && (
                  <>
                    {/*begin::Tab pane*/}
                    <div>
                      <div
                        className="overflow-auto pb-5 users"
                        style={{ height: "calc(100vh - 110px)" }}
                      >
                        {/*begin::Record*/}
                        {list?.map((d, i) => {
                          return (
                            <div
                              className="d-flex align-items-center border border-dashed border-gray-300 rounded  px-7 py-4 mb-5 bg-white justify-content-between"
                              key={i}
                              style={{ minWidth: "1700px" }}
                            >
                              {/*begin::Ribbon*/}
                              <div className="ribbon ribbon-triangle ribbon-top-start border-info">
                                <div className="ribbon-label ">
                                  {(page - 1) * pageSize + 1 + i}
                                </div>
                              </div>
                              {/*end::Ribbon*/}
                              {/*begin::Users*/}
                              <div className="min-w-300px  min-w-xl-300px pe-2 ps-5">
                                <div className="d-flex align-items-center">
                                  <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                                    {d?.photo ? (
                                      <img src={`${d?.photo}`} alt />
                                    ) : (
                                      <div
                                        style={{
                                          height: "50px",
                                          width: "50px",
                                          background: "#5058ce",
                                          borderRadius: "30px",
                                          color: "rgb(255, 255, 255)",
                                          fontWeight: "400",
                                          fontSize: "20px",
                                          boxShadow:
                                            "rgb(137 146 212) 0px -50px 36px -28px inset",
                                        }}
                                        className="d-flex justify-content-center align-items-center"
                                      >
                                        <span
                                          style={{
                                            textTransform: "uppercase",
                                          }}
                                        >
                                          {d?.name?.charAt(0)}
                                        </span>
                                      </div>
                                    )}
                                  </div>
                                  {/* <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                                    <img
                                      src="/assets/media/avatars/300-17.jpg"
                                      alt=""
                                    />
                                  </div> */}
                                  {/*end::Avatar*/}
                                  {/*begin::User details*/}
                                  <div className="d-flex flex-column">
                                    <a
                                      href
                                      className="text-gray-800 text-hover-info mb-1 fw-bolder"
                                    >
                                      {d?.name}
                                    </a>
                                    <span>{d?.email}</span>
                                  </div>
                                </div>
                              </div>
                              {/*end::Users*/}
                              <div className="min-w-175px min-w-xl-175px pe-2 ">
                                <span className="fs-7 text-info">
                                  Contact No
                                </span>
                                <div className="fw-bolder fs-6">
                                  {d?.contact_no}
                                </div>
                              </div>
                              <div className="min-w-175px min-w-xl-175px pe-2 ">
                                <span className="fs-7 text-info">
                                  Amazon Pending Reports
                                </span>
                                <div className="fw-bolder fs-6">
                                  {d?.amz_pending_reports}
                                </div>
                              </div>
                              <div className="min-w-175px min-w-xl-175px pe-2 ">
                                <span className="fs-7 text-info">
                                  Walmart Pending Reports
                                </span>
                                <div className="fw-bolder fs-6">
                                  {d?.walmart_pending_reports}
                                </div>
                              </div>
                              <div className="min-w-175px min-w-xl-175px pe-2 ">
                                <span className="fs-7 text-info">
                                  Connected by
                                </span>
                                <div className="fw-bolder fs-6">
                                  {d?.connected_by == "both" ? (
                                    ""
                                  ) : d?.connected_by === "amazon" ? (
                                    <>
                                      <label style={{ color: "#ff9b01" }}>
                                        Amazon
                                      </label>
                                    </>
                                  ) : d?.connected_by === "walmart" ? (
                                    <>
                                      <label style={{ color: "#0071dc" }}>
                                        Walmart
                                      </label>
                                    </>
                                  ) : (
                                    "-"
                                  )}
                                  {d?.connected_by === "both" && (
                                    <>
                                      <label style={{ color: "#ff9b01" }}>
                                        Amazon
                                      </label>
                                      {","}&nbsp;
                                      <label style={{ color: "#0071dc" }}>
                                        Walmart
                                      </label>
                                    </>
                                  )}
                                </div>
                              </div>
                              <div className="min-w-175px min-w-xl-175px pe-2 ">
                                <span className="fs-7 text-info">
                                  Created at
                                </span>
                                <div className="fw-bolder fs-6">
                                  {moment(
                                    new Date(d?.created_at * 1000)
                                  ).format("MMM DD, YYYY hh:mm A")}
                                </div>
                              </div>
                              <div className="min-w-175px min-w-xl-175px pe-2 ">
                                <span className="fs-7 text-info">
                                  Updated at
                                </span>
                                <div className="fw-bolder fs-6">
                                  {d?.updated_at != 1
                                    ? moment(
                                        new Date(d?.updated_at * 1000)
                                      ).format("MMM DD, YYYY hh:mm A")
                                    : "-"}
                                </div>
                              </div>
                              <div className="min-w-175px min-w-xl-175px pe-2 ">
                                <button
                                  className="btn btn-sm btn-light-info fs-6 fw-bolder"
                                  onClick={() => {
                                    setIsSwitching(true);
                                    message.loading("Switching...", 0);
                                    SwitchUserAction(d?.id);
                                  }}
                                  disabled={isSwitching}
                                >
                                  <i className="ki-outline ki-arrow-right-left fs-3  me-2" />
                                  Switch User
                                </button>
                              </div>
                              {/*begin::Action*/}
                              <div className="d-flex flex-shrink-0 ">
                                <button
                                  onClick={() => {
                                    setSelectedRow(d);
                                    setOpenModal(true);
                                  }}
                                  className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2"
                                >
                                  <i className="ki-outline ki-pencil fs-2 text-info" />
                                </button>
                                <Popconfirm
                                  title="Are you sure to delete this user?"
                                  placement="left"
                                  cancelText="No"
                                  okText="Yes"
                                  onConfirm={() => {
                                    message.loading("Deleting...", 0);
                                    // console.log(d?.id);
                                    DeleteUserAction(d?.id);
                                  }}
                                >
                                  <button className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                                    <i className="ki-outline ki-trash fs-2 text-danger" />
                                  </button>
                                </Popconfirm>
                              </div>
                              {/*end::Action*/}
                            </div>
                          );
                        })}

                        {/*end::Record*/}
                      </div>
                    </div>
                    {/*end::Tab pane*/}
                  </>
                )
              )}
              {selectedSegment == 2 && (
                <>
                  <div
                    className="row gy-5 g-xl-5 users overflow-auto"
                    style={{ height: "calc(100vh - 110px)" }}
                  >
                    {list?.map((d, i) => {
                      return (
                        <>
                          {/*begin::Col*/}
                          <div className="col-12 col-md-6 col-lg-4">
                            {/*begin::Tables Widget 9*/}
                            <div className="card card-xxl-stretch mb-5 mb-xl-8">
                              <div className="ribbon ribbon-triangle ribbon-top-start border-info">
                                <div className="ribbon-label ">
                                  {(page - 1) * pageSize + 1 + i}
                                </div>
                              </div>
                              {/*begin::Body*/}
                              <div className="card-body text-center pb-2">
                                <div className="d-flex align-items-center ">
                                  <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                                    {d?.photo ? (
                                      <img src={`${d?.photo}`} alt />
                                    ) : (
                                      <div
                                        style={{
                                          height: "50px",
                                          width: "50px",
                                          background: "#5058ce",
                                          borderRadius: "30px",
                                          color: "rgb(255, 255, 255)",
                                          fontWeight: "400",
                                          fontSize: "20px",
                                          boxShadow:
                                            "rgb(137 146 212) 0px -50px 36px -28px inset",
                                        }}
                                        className="d-flex justify-content-center align-items-center"
                                      >
                                        <span
                                          style={{
                                            textTransform: "uppercase",
                                          }}
                                        >
                                          {d?.name?.charAt(0)}
                                        </span>
                                      </div>
                                    )}
                                  </div>
                                  {/*end::Avatar*/}
                                  {/*begin::User details*/}
                                  <div className="d-flex flex-column text-start mt-0">
                                    <a
                                      href
                                      className="text-gray-800 text-hover-info mb-1 fw-bolder"
                                    >
                                      {d?.name}
                                    </a>
                                    <span>{d?.email}</span>
                                  </div>
                                </div>
                                <div className="separator separator-dashed borde-gray-300 my-4" />
                                <div className="mt-3">
                                  <div className="d-flex justify-content-between align-items-center mb-2">
                                    <span className="fs-7 text-info">
                                      Contact No
                                    </span>
                                    <div className="fw-bolder text-gray-800 fs-6">
                                      {d?.contact_no}
                                    </div>
                                  </div>
                                  <div className="d-flex justify-content-between align-items-center mb-2">
                                    <span className="fs-7 text-info">
                                      Pending Reports
                                    </span>
                                    <div className="fw-bolder text-gray-800 fs-6">
                                      {d?.pending_reports}
                                    </div>
                                  </div>
                                  <div className="d-flex justify-content-between align-items-center mb-2">
                                    <span className="fs-7 text-info">
                                      Connected by
                                    </span>
                                    <div className="fw-bolder text-gray-800 fs-6">
                                      {d?.connected_by == "both" ? (
                                        ""
                                      ) : d?.connected_by === "amazon" ? (
                                        <>
                                          <label style={{ color: "#ff9b01" }}>
                                            Amazon
                                          </label>
                                        </>
                                      ) : d?.connected_by === "walmart" ? (
                                        <>
                                          <label style={{ color: "#0071dc" }}>
                                            Walmart
                                          </label>
                                        </>
                                      ) : (
                                        "-"
                                      )}
                                      {d?.connected_by === "both" && (
                                        <>
                                          {"("}
                                          <label style={{ color: "#ff9b01" }}>
                                            Amazon
                                          </label>
                                          {","}
                                          <label style={{ color: "#0071dc" }}>
                                            Walmart
                                          </label>
                                          {")"}
                                        </>
                                      )}
                                    </div>
                                  </div>
                                  <div className="d-flex justify-content-between align-items-center mb-2">
                                    <span className="fs-7 text-info">
                                      Created at
                                    </span>
                                    <div className="fw-bolder text-gray-800 fs-6">
                                      {moment(
                                        new Date(d?.created_at * 1000)
                                      ).format("MMM DD, YYYY hh:mm A")}
                                    </div>
                                  </div>
                                  <div className="d-flex justify-content-between align-items-center">
                                    <span className="fs-7 text-info">
                                      Updated at
                                    </span>
                                    <div className="fw-bolder text-gray-800 fs-6">
                                      {d?.updated_at != 1
                                        ? moment(
                                            new Date(d?.updated_at * 1000)
                                          ).format("MMM DD, YYYY hh:mm A")
                                        : "-"}
                                    </div>
                                  </div>
                                </div>
                                <div className="separator separator-dashed borde-gray-300 my-4" />
                                <div className="mt-3">
                                  <div className="d-flex justify-content-between">
                                    <div>
                                      <div className="d-flex flex-shrink-0 ">
                                        <button
                                          onClick={() => {
                                            setSelectedRow(d);
                                            setOpenModal(true);
                                          }}
                                          className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2"
                                        >
                                          <i className="ki-outline ki-pencil fs-2 text-info" />
                                        </button>
                                        <Popconfirm
                                          title="Are you sure to delete this user?"
                                          placement="left"
                                          cancelText="No"
                                          okText="Yes"
                                          onConfirm={() => {
                                            message.loading("Deleting...", 0);
                                            // console.log(d?.id);
                                            DeleteUserAction(d?.id);
                                          }}
                                        >
                                          <button className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2">
                                            <i className="ki-outline ki-trash fs-2 text-danger" />
                                          </button>
                                        </Popconfirm>
                                      </div>
                                    </div>
                                    <div>
                                      <button
                                        className="btn btn-sm btn-light-info fs-6 fw-bolder"
                                        onClick={() => {
                                          message.loading("Switching...", 0);
                                          SwitchUserAction(d?.id);
                                        }}
                                      >
                                        <i className="ki-outline ki-arrow-right-left fs-3  me-2" />
                                        Switch User
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/*begin::Body*/}
                            </div>
                            {/*end::Tables Widget 9*/}
                          </div>
                          {/*end::Col*/}
                        </>
                      );
                    })}
                  </div>
                </>
              )}

              <Pagination
                loading={loading || list?.length === 0}
                pageSize={pageSize}
                page={page}
                totalPage={totalPage}
                onPerPage={onPerPage}
                onPageNo={onPageNo}
              />
            </div>
          </div>
          {/*end::Content container*/}
        </div>
        {/*end::Content*/}
      </div>

      {openModal && (
        <UserModal
          show={openModal}
          onHide={() => {
            setOpenModal(false);
            setSelectedRow([]);
          }}
          data={selectedRow}
          onSuccess={() => {
            setOpenModal(false);
            setList([]);
            setSelectedRow([]);
            setPage(1);
            setPageSize(DefaultPerPage);
            setLoading(true);
            GetUserList({
              page: 1,
              perPage: DefaultPerPage,
              search_key: searchTerm,
            });
          }}
          {...props}
        />
      )}
    </Wrapper>
  );
}
