import axiosCall from "../../../configurations/network-services/axiosCall";

// const prefix = "dashboard";

export const SaveUserProfile = (data, id) => {
  const path = `update-profile/${id}`;
  const responseType = "SAVE_USER_PROFILE";
  return axiosCall("PUT", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const fakeActionuserProfile = (data) => async (dispatch) => {
  dispatch({ type: "FAKE_ACTION_USER_PROFILE", state: data });
};
