import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CentralLog from "../../../modules/pages/application-log/central-log-system";
import {
  GetCentralLogList,
  GetEventNameList,
  GetEventTypeList,
  fakeActionCentralLog,
} from "../../../redux/modules/application-log/central-log/central-log.action";

import {
  GetMarketplaceList,
  fakeActionSalesAnalytics,
} from "../../../redux/modules/sales-analytics/sales-analytics.action";

const mapStateToProps = (state) => ({
  GetCentralLogListResponse: state.CentralLogSystem.GetCentralLogListResponse,
  GetEventNameListResponse: state.CentralLogSystem.GetEventNameListResponse,
  GetEventTypeListResponse: state.CentralLogSystem.GetEventTypeListResponse,

  GetMarketplaceListResponse: state.SalesAnalytics.GetMarketplaceListResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      GetCentralLogList,
      GetEventNameList,
      GetEventTypeList,
      fakeActionCentralLog,

      GetMarketplaceList,
      fakeActionSalesAnalytics,
    },
    dispatch
  );

const CentralLogContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CentralLog);

export default CentralLogContainer;
