import React, { useEffect, useState } from "react";
import ViewReportModal from "./lib/view-report-modal";

export default function (props) {
  const {} = props;
  const [openReportViewModal, setOpenReportViewModal] = useState(false);
  const [selectedReport, setSelectedReport] = useState("");
  return (
    <>
      <div className="d-flex flex-column flex-column-fluid fadeInRight">
        <div id="kt_app_content" className="app-content flex-column-fluid">
          <div
            id="kt_app_content_container"
            className="app-container container-fluid"
          >
            <div className="card card-xxl-stretch mb-5 mb-xl-5 mt-5">
              <div className="card-header pt-5 border-bottom-0">
                <label className="card-title align-items-start flex-column">
                  <span className="fw-bold text-dark fs-2">Reports List</span>
                </label>

                <div className="card-toolbar"></div>
              </div>
              <div className="separator separator-dashed mt-1 mb-3" />

              <div className="card-body py-3">
                <div className="row mb-5">
                  <div className="row ">
                    <div className="col-md-6 col-xl-4 col-xxl-3">
                      <div
                        className="card card-xxl-stretch mb-5 mb-xl-5"
                        style={{ border: "1px solid #ddd8fe" }}
                      >
                        <div className="card-body p-4 py-3 d-flex align-items-center">
                          <div className="d-flex flex-stack w-100">
                            <div className="symbol symbol-50px me-4">
                              <div className="symbol-label fs-2 fw-semibold bg-light-info text-inverse-danger">
                                <i className="ki-duotone ki-document fs-2x text-primary">
                                  <span className="path1" />
                                  <span className="path2" />
                                </i>
                              </div>
                            </div>
                            <div className="d-flex align-items-center flex-row-fluid flex-wrap">
                              <div className="flex-grow-1 me-2">
                                <span className="text-gray-800 text-hover-primary fs-6 fw-bold">
                                  Finance Reports
                                </span>
                              </div>
                              <span
                                className="btn btn-sm btn-icon btn-light-info w-30px h-30px"
                                onClick={() => {
                                  setOpenReportViewModal(true);
                                  setSelectedReport("Finance Report");
                                }}
                              >
                                <i className="ki-outline ki-arrow-right fs-2" />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row ">
                    <div className="col-md-12 mb-5">
                      <h3 className="fw-bold">Business Reports </h3>
                    </div>

                    <div className="col-md-6 col-xl-4 col-xxl-3">
                      <div
                        className="card card-xxl-stretch mb-5 mb-xl-5"
                        style={{ border: "1px solid #ddd8fe" }}
                      >
                        <div className="card-body p-4 py-3 d-flex align-items-center">
                          <div className="d-flex flex-stack w-100">
                            <div className="symbol symbol-50px me-4">
                              <div className="symbol-label fs-2 fw-semibold bg-light-info text-inverse-danger">
                                <i className="ki-duotone ki-document fs-2x text-primary">
                                  <span className="path1" />
                                  <span className="path2" />
                                </i>
                              </div>
                            </div>
                            <div className="d-flex align-items-center flex-row-fluid flex-wrap">
                              <div className="flex-grow-1 me-2">
                                <span className="text-gray-800 text-hover-primary fs-6 fw-bold">
                                  Sales Analytics Report
                                </span>
                              </div>
                              <span
                                className="btn btn-sm btn-icon btn-light-info w-30px h-30px"
                                onClick={() => {
                                  setOpenReportViewModal(true);
                                  setSelectedReport("Sales Analytics Report");
                                }}
                              >
                                <i className="ki-outline ki-arrow-right fs-2" />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 col-xl-4 col-xxl-3">
                      <div
                        className="card card-xxl-stretch mb-5 mb-xl-5"
                        style={{ border: "1px solid #ddd8fe" }}
                      >
                        <div className="card-body p-4 py-3 d-flex align-items-center">
                          <div className="d-flex flex-stack w-100">
                            <div className="symbol symbol-50px me-4">
                              <div className="symbol-label fs-2 fw-semibold bg-light-info text-inverse-danger">
                                <i className="ki-duotone ki-document fs-2x text-primary">
                                  <span className="path1" />
                                  <span className="path2" />
                                </i>
                              </div>
                            </div>
                            <div className="d-flex align-items-center flex-row-fluid flex-wrap">
                              <div className="flex-grow-1 me-2">
                                <span className="text-gray-800 text-hover-primary fs-6 fw-bold">
                                  Sales Analytics
                                  <small className="fs-8 text-gray-600">
                                    (By ASIN)
                                  </small>
                                </span>
                              </div>
                              <span
                                className="btn btn-sm btn-icon btn-light-info w-30px h-30px"
                                onClick={() => {
                                  setOpenReportViewModal(true);
                                  setSelectedReport(
                                    "Sales Analytics (By ASIN)"
                                  );
                                }}
                              >
                                <i className="ki-outline ki-arrow-right fs-2" />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 col-xl-4 col-xxl-3">
                      <div
                        className="card card-xxl-stretch mb-5 mb-xl-5"
                        style={{ border: "1px solid #ddd8fe" }}
                      >
                        <div className="card-body p-4 py-3 d-flex align-items-center">
                          <div className="d-flex flex-stack w-100">
                            <div className="symbol symbol-50px me-4">
                              <div className="symbol-label fs-2 fw-semibold bg-light-info text-inverse-danger">
                                <i className="ki-duotone ki-document fs-2x text-primary">
                                  <span className="path1" />
                                  <span className="path2" />
                                </i>
                              </div>
                            </div>
                            <div className="d-flex align-items-center flex-row-fluid flex-wrap">
                              <div className="flex-grow-1 me-2">
                                <span className="text-gray-800 text-hover-primary fs-6 fw-bold">
                                  Sales and Order
                                </span>
                              </div>
                              <span
                                className="btn btn-sm btn-icon btn-light-info w-30px h-30px"
                                onClick={() => {
                                  setOpenReportViewModal(true);
                                  setSelectedReport("Sales and Order Report");
                                }}
                              >
                                <i className="ki-outline ki-arrow-right fs-2" />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 col-xl-4 col-xxl-3">
                      <div
                        className="card card-xxl-stretch mb-5 mb-xl-5"
                        style={{ border: "1px solid #ddd8fe" }}
                      >
                        <div className="card-body p-4 py-3 d-flex align-items-center">
                          <div className="d-flex flex-stack w-100">
                            <div className="symbol symbol-50px me-4">
                              <div className="symbol-label fs-2 fw-semibold bg-light-info text-inverse-danger">
                                <i className="ki-duotone ki-document fs-2x text-primary">
                                  <span className="path1" />
                                  <span className="path2" />
                                </i>
                              </div>
                            </div>
                            <div className="d-flex align-items-center flex-row-fluid flex-wrap">
                              <div className="flex-grow-1 me-2">
                                <span className="text-gray-800 text-hover-primary fs-6 fw-bold">
                                  Sales and Order
                                  <small className="fs-8 text-gray-600">
                                    (By ASIN)
                                  </small>
                                </span>
                              </div>
                              <span
                                className="btn btn-sm btn-icon btn-light-info w-30px h-30px"
                                onClick={() => {
                                  setOpenReportViewModal(true);
                                  setSelectedReport(
                                    "Sales and Order (By ASIN) Report"
                                  );
                                }}
                              >
                                <i className="ki-outline ki-arrow-right fs-2" />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row ">
                    <div className="col-md-12 mb-5">
                      <h3 className="fw-bold">Fulfillment Report</h3>
                    </div>

                    <div className="col-md-6 col-xl-4 col-xxl-3">
                      <div
                        className="card card-xxl-stretch mb-5 mb-xl-5"
                        style={{ border: "1px solid #ddd8fe" }}
                      >
                        <div className="card-body p-4 py-3 d-flex align-items-center">
                          <div className="d-flex flex-stack w-100">
                            <div className="symbol symbol-50px me-4">
                              <div className="symbol-label fs-2 fw-semibold bg-light-info text-inverse-danger">
                                <i className="ki-duotone ki-document fs-2x text-primary">
                                  <span className="path1" />
                                  <span className="path2" />
                                </i>
                              </div>
                            </div>
                            <div className="d-flex align-items-center flex-row-fluid flex-wrap">
                              <div className="flex-grow-1 me-2">
                                <span className="text-gray-800 text-hover-primary fs-6 fw-bold">
                                  All Order Report
                                </span>
                              </div>
                              <span
                                className="btn btn-sm btn-icon btn-light-info w-30px h-30px"
                                onClick={() => {
                                  setOpenReportViewModal(true);
                                  setSelectedReport("All Order Report");
                                }}
                              >
                                <i className="ki-outline ki-arrow-right fs-2" />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 col-xl-4 col-xxl-3">
                      <div
                        className="card card-xxl-stretch mb-5 mb-xl-5"
                        style={{ border: "1px solid #ddd8fe" }}
                      >
                        <div className="card-body p-4 py-3 d-flex align-items-center">
                          <div className="d-flex flex-stack w-100">
                            <div className="symbol symbol-50px me-4">
                              <div className="symbol-label fs-2 fw-semibold bg-light-info text-inverse-danger">
                                <i className="ki-duotone ki-document fs-2x text-primary">
                                  <span className="path1" />
                                  <span className="path2" />
                                </i>
                              </div>
                            </div>
                            <div className="d-flex align-items-center flex-row-fluid flex-wrap">
                              <div className="flex-grow-1 me-2">
                                <span className="text-gray-800 text-hover-primary fs-6 fw-bold">
                                  Return Report
                                </span>
                              </div>
                              <span
                                className="btn btn-sm btn-icon btn-light-info w-30px h-30px"
                                onClick={() => {
                                  setOpenReportViewModal(true);
                                  setSelectedReport("Return Report");
                                }}
                              >
                                <i className="ki-outline ki-arrow-right fs-2" />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 col-xl-4 col-xxl-3">
                      <div
                        className="card card-xxl-stretch mb-5 mb-xl-5"
                        style={{ border: "1px solid #ddd8fe" }}
                      >
                        <div className="card-body p-4 py-3 d-flex align-items-center">
                          <div className="d-flex flex-stack w-100">
                            <div className="symbol symbol-50px me-4">
                              <div className="symbol-label fs-2 fw-semibold bg-light-info text-inverse-danger">
                                <i className="ki-duotone ki-document fs-2x text-primary">
                                  <span className="path1" />
                                  <span className="path2" />
                                </i>
                              </div>
                            </div>
                            <div className="d-flex align-items-center flex-row-fluid flex-wrap">
                              <div className="flex-grow-1 me-2">
                                <span className="text-gray-800 text-hover-primary fs-6 fw-bold">
                                  FBA Inventory Report
                                </span>
                              </div>
                              <span
                                className="btn btn-sm btn-icon btn-light-info w-30px h-30px"
                                onClick={() => {
                                  setOpenReportViewModal(true);
                                  setSelectedReport("FBA Inventory Report");
                                }}
                              >
                                <i className="ki-outline ki-arrow-right fs-2" />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 col-xl-4 col-xxl-3">
                      <div
                        className="card card-xxl-stretch mb-5 mb-xl-5"
                        style={{ border: "1px solid #ddd8fe" }}
                      >
                        <div className="card-body p-4 py-3 d-flex align-items-center">
                          <div className="d-flex flex-stack w-100">
                            <div className="symbol symbol-50px me-4">
                              <div className="symbol-label fs-2 fw-semibold bg-light-info text-inverse-danger">
                                <i className="ki-duotone ki-document fs-2x text-primary">
                                  <span className="path1" />
                                  <span className="path2" />
                                </i>
                              </div>
                            </div>
                            <div className="d-flex align-items-center flex-row-fluid flex-wrap">
                              <div className="flex-grow-1 me-2">
                                <span className="text-gray-800 text-hover-primary fs-6 fw-bold">
                                  Inventory Ledger Report
                                </span>
                              </div>
                              <span
                                className="btn btn-sm btn-icon btn-light-info w-30px h-30px"
                                onClick={() => {
                                  setOpenReportViewModal(true);
                                  setSelectedReport("Inventory Ledger Report");
                                }}
                              >
                                <i className="ki-outline ki-arrow-right fs-2" />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 col-xl-4 col-xxl-3">
                      <div
                        className="card card-xxl-stretch mb-5 mb-xl-5"
                        style={{ border: "1px solid #ddd8fe" }}
                      >
                        <div className="card-body p-4 py-3 d-flex align-items-center">
                          <div className="d-flex flex-stack w-100">
                            <div className="symbol symbol-50px me-4">
                              <div className="symbol-label fs-2 fw-semibold bg-light-info text-inverse-danger">
                                <i className="ki-duotone ki-document fs-2x text-primary">
                                  <span className="path1" />
                                  <span className="path2" />
                                </i>
                              </div>
                            </div>
                            <div className="d-flex align-items-center flex-row-fluid flex-wrap">
                              <div className="flex-grow-1 me-2">
                                <span className="text-gray-800 text-hover-primary fs-6 fw-bold">
                                  Inventory Ledger Report
                                </span>
                              </div>
                              <span
                                className="btn btn-sm btn-icon btn-light-info w-30px h-30px"
                                onClick={() => {
                                  setOpenReportViewModal(true);
                                  setSelectedReport("Inventory Ledger Report");
                                }}
                              >
                                <i className="ki-outline ki-arrow-right fs-2" />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row ">
                    <div className="col-md-12 mb-5">
                      <h3 className="fw-bold">Advertisement Report</h3>
                    </div>

                    <div className="col-md-6 col-xl-4 col-xxl-3">
                      <div
                        className="card card-xxl-stretch mb-5 mb-xl-5"
                        style={{ border: "1px solid #ddd8fe" }}
                      >
                        <div className="card-body p-4 py-3 d-flex align-items-center">
                          <div className="d-flex flex-stack w-100">
                            <div className="symbol symbol-50px me-4">
                              <div className="symbol-label fs-2 fw-semibold bg-light-info text-inverse-danger">
                                <i className="ki-duotone ki-document fs-2x text-primary">
                                  <span className="path1" />
                                  <span className="path2" />
                                </i>
                              </div>
                            </div>
                            <div className="d-flex align-items-center flex-row-fluid flex-wrap">
                              <div className="flex-grow-1 me-2">
                                <span className="text-gray-800 text-hover-primary fs-6 fw-bold">
                                  Lifetime Perfom. Report
                                </span>
                              </div>
                              <span
                                className="btn btn-sm btn-icon btn-light-info w-30px h-30px"
                                onClick={() => {
                                  setOpenReportViewModal(true);
                                  setSelectedReport("Lifetime Perfom. Report");
                                }}
                              >
                                <i className="ki-outline ki-arrow-right fs-2" />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 col-xl-4 col-xxl-3">
                      <div
                        className="card card-xxl-stretch mb-5 mb-xl-5"
                        style={{ border: "1px solid #ddd8fe" }}
                      >
                        <div className="card-body p-4 py-3 d-flex align-items-center">
                          <div className="d-flex flex-stack w-100">
                            <div className="symbol symbol-50px me-4">
                              <div className="symbol-label fs-2 fw-semibold bg-light-info text-inverse-danger">
                                <i className="ki-duotone ki-document fs-2x text-primary">
                                  <span className="path1" />
                                  <span className="path2" />
                                </i>
                              </div>
                            </div>
                            <div className="d-flex align-items-center flex-row-fluid flex-wrap">
                              <div className="flex-grow-1 me-2">
                                <span className="text-gray-800 text-hover-primary fs-6 fw-bold">
                                  Sponsored Product Report
                                </span>
                              </div>
                              <span
                                className="btn btn-sm btn-icon btn-light-info w-30px h-30px"
                                onClick={() => {
                                  setOpenReportViewModal(true);
                                  setSelectedReport("Sponsored Product Report");
                                }}
                              >
                                <i className="ki-outline ki-arrow-right fs-2" />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 col-xl-4 col-xxl-3">
                      <div
                        className="card card-xxl-stretch mb-5 mb-xl-5"
                        style={{ border: "1px solid #ddd8fe" }}
                      >
                        <div className="card-body p-4 py-3 d-flex align-items-center">
                          <div className="d-flex flex-stack w-100">
                            <div className="symbol symbol-50px me-4">
                              <div className="symbol-label fs-2 fw-semibold bg-light-info text-inverse-danger">
                                <i className="ki-duotone ki-document fs-2x text-primary">
                                  <span className="path1" />
                                  <span className="path2" />
                                </i>
                              </div>
                            </div>
                            <div className="d-flex align-items-center flex-row-fluid flex-wrap">
                              <div className="flex-grow-1 me-2">
                                <span className="text-gray-800 text-hover-primary fs-6 fw-bold">
                                  Sponsored Brand Report
                                </span>
                              </div>
                              <span
                                className="btn btn-sm btn-icon btn-light-info w-30px h-30px"
                                onClick={() => {
                                  setOpenReportViewModal(true);
                                  setSelectedReport("Sponsored Brand Report");
                                }}
                              >
                                <i className="ki-outline ki-arrow-right fs-2" />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 col-xl-4 col-xxl-3">
                      <div
                        className="card card-xxl-stretch mb-5 mb-xl-5"
                        style={{ border: "1px solid #ddd8fe" }}
                      >
                        <div className="card-body p-4 py-3 d-flex align-items-center">
                          <div className="d-flex flex-stack w-100">
                            <div className="symbol symbol-50px me-4">
                              <div className="symbol-label fs-2 fw-semibold bg-light-info text-inverse-danger">
                                <i className="ki-duotone ki-document fs-2x text-primary">
                                  <span className="path1" />
                                  <span className="path2" />
                                </i>
                              </div>
                            </div>
                            <div className="d-flex align-items-center flex-row-fluid flex-wrap">
                              <div className="flex-grow-1 me-2">
                                <span className="text-gray-800 text-hover-primary fs-6 fw-bold">
                                  Sponsored Video Report
                                </span>
                              </div>
                              <span
                                className="btn btn-sm btn-icon btn-light-info w-30px h-30px"
                                onClick={() => {
                                  setOpenReportViewModal(true);
                                  setSelectedReport("Sponsored Video Report");
                                }}
                              >
                                <i className="ki-outline ki-arrow-right fs-2" />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 col-xl-4 col-xxl-3">
                      <div
                        className="card card-xxl-stretch mb-5 mb-xl-5"
                        style={{ border: "1px solid #ddd8fe" }}
                      >
                        <div className="card-body p-4 py-3 d-flex align-items-center">
                          <div className="d-flex flex-stack w-100">
                            <div className="symbol symbol-50px me-4">
                              <div className="symbol-label fs-2 fw-semibold bg-light-info text-inverse-danger">
                                <i className="ki-duotone ki-document fs-2x text-primary">
                                  <span className="path1" />
                                  <span className="path2" />
                                </i>
                              </div>
                            </div>
                            <div className="d-flex align-items-center flex-row-fluid flex-wrap">
                              <div className="flex-grow-1 me-2">
                                <span className="text-gray-800 text-hover-primary fs-6 fw-bold">
                                  Targeting Report
                                </span>
                              </div>
                              <span
                                className="btn btn-sm btn-icon btn-light-info w-30px h-30px"
                                onClick={() => {
                                  setOpenReportViewModal(true);
                                  setSelectedReport("Targeting Report");
                                }}
                              >
                                <i className="ki-outline ki-arrow-right fs-2" />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 col-xl-4 col-xxl-3">
                      <div
                        className="card card-xxl-stretch mb-5 mb-xl-5"
                        style={{ border: "1px solid #ddd8fe" }}
                      >
                        <div className="card-body p-4 py-3 d-flex align-items-center">
                          <div className="d-flex flex-stack w-100">
                            <div className="symbol symbol-50px me-4">
                              <div className="symbol-label fs-2 fw-semibold bg-light-info text-inverse-danger">
                                <i className="ki-duotone ki-document fs-2x text-primary">
                                  <span className="path1" />
                                  <span className="path2" />
                                </i>
                              </div>
                            </div>
                            <div className="d-flex align-items-center flex-row-fluid flex-wrap">
                              <div className="flex-grow-1 me-2">
                                <span className="text-gray-800 text-hover-primary fs-6 fw-bold">
                                  Performance Report
                                </span>
                              </div>
                              <span
                                className="btn btn-sm btn-icon btn-light-info w-30px h-30px"
                                onClick={() => {
                                  setOpenReportViewModal(true);
                                  setSelectedReport("Performance Report");
                                }}
                              >
                                <i className="ki-outline ki-arrow-right fs-2" />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 col-xl-4 col-xxl-3">
                      <div
                        className="card card-xxl-stretch mb-5 mb-xl-5"
                        style={{ border: "1px solid #ddd8fe" }}
                      >
                        <div className="card-body p-4 py-3 d-flex align-items-center">
                          <div className="d-flex flex-stack w-100">
                            <div className="symbol symbol-50px me-4">
                              <div className="symbol-label fs-2 fw-semibold bg-light-info text-inverse-danger">
                                <i className="ki-duotone ki-document fs-2x text-primary">
                                  <span className="path1" />
                                  <span className="path2" />
                                </i>
                              </div>
                            </div>
                            <div className="d-flex align-items-center flex-row-fluid flex-wrap">
                              <div className="flex-grow-1 me-2">
                                <span className="text-gray-800 text-hover-primary fs-6 fw-bold">
                                  Platform Report
                                </span>
                              </div>
                              <span
                                className="btn btn-sm btn-icon btn-light-info w-30px h-30px"
                                onClick={() => {
                                  setOpenReportViewModal(true);
                                  setSelectedReport("Platform Report");
                                }}
                              >
                                <i className="ki-outline ki-arrow-right fs-2" />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 col-xl-4 col-xxl-3">
                      <div
                        className="card card-xxl-stretch mb-5 mb-xl-5"
                        style={{ border: "1px solid #ddd8fe" }}
                      >
                        <div className="card-body p-4 py-3 d-flex align-items-center">
                          <div className="d-flex flex-stack w-100">
                            <div className="symbol symbol-50px me-4">
                              <div className="symbol-label fs-2 fw-semibold bg-light-info text-inverse-danger">
                                <i className="ki-duotone ki-document fs-2x text-primary">
                                  <span className="path1" />
                                  <span className="path2" />
                                </i>
                              </div>
                            </div>
                            <div className="d-flex align-items-center flex-row-fluid flex-wrap">
                              <div className="flex-grow-1 me-2">
                                <span className="text-gray-800 text-hover-primary fs-6 fw-bold">
                                  Item Performance Report
                                </span>
                              </div>
                              <span
                                className="btn btn-sm btn-icon btn-light-info w-30px h-30px"
                                onClick={() => {
                                  setOpenReportViewModal(true);
                                  setSelectedReport("Item Performance Report");
                                }}
                              >
                                <i className="ki-outline ki-arrow-right fs-2" />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 col-xl-4 col-xxl-3">
                      <div
                        className="card card-xxl-stretch mb-5 mb-xl-5"
                        style={{ border: "1px solid #ddd8fe" }}
                      >
                        <div className="card-body p-4 py-3 d-flex align-items-center">
                          <div className="d-flex flex-stack w-100">
                            <div className="symbol symbol-50px me-4">
                              <div className="symbol-label fs-2 fw-semibold bg-light-info text-inverse-danger">
                                <i className="ki-duotone ki-document fs-2x text-primary">
                                  <span className="path1" />
                                  <span className="path2" />
                                </i>
                              </div>
                            </div>
                            <div className="d-flex align-items-center flex-row-fluid flex-wrap">
                              <div className="flex-grow-1 me-2">
                                <span className="text-gray-800 text-hover-primary fs-6 fw-bold">
                                  Keyword Perform. Report
                                </span>
                              </div>
                              <span
                                className="btn btn-sm btn-icon btn-light-info w-30px h-30px"
                                onClick={() => {
                                  setOpenReportViewModal(true);
                                  setSelectedReport("Keyword Perform. Report");
                                }}
                              >
                                <i className="ki-outline ki-arrow-right fs-2" />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {openReportViewModal && (
        <ViewReportModal
          show={openReportViewModal}
          onHide={() => {
            setOpenReportViewModal(false);
          }}
          selectedReport={selectedReport}
        />
      )}
    </>
  );
}
