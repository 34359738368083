import { Popover, Table, Tooltip } from "antd";
import React, { useState } from "react";
import IvcsrTable from "../../../../component/ivcsr-table";
import { NumberWithCommas } from "../../../../config";

export default function (props) {
  const { data, page, pageSize, selectedCurrencySign, columns } = props;

  return (
    <>
      <IvcsrTable
        dataSource={data}
        columns={columns?.map((d) => ({
          ...d,
          width: d?.title === "Product" ? 300 : d?.title?.length * 13,
        }))}
        pagination={false}
        sticky={{
          offsetHeader: "10px",
        }}
        scroll={{
          x: "max-content",
          y: "calc(100vh - 200px)",
        }}
        fixed="top"
      />
    </>
  );
}
