import React, { useEffect } from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import Loading from "../../../../../component/loading";
import NoData from "../../../../../component/no-data";
import IvcsrTable from "../../../../../component/ivcsr-table";
import moment from "moment";
import Pagination from "../../../../../component/pagination";
import { Popover, Tag } from "antd";
export default function ({
  show,
  onHide,
  GetWmKeywordRecommendation,
  keyword_recomm_filter,
  set_keyword_recomm_filter,
  keyword_recommendation_list,
  keyword_recommendation_total_record,
}) {
  useEffect(() => {
    setLoading(false);
  }, [keyword_recommendation_list]);

  const [loading, setLoading] = useState(false);
  const onPageNo = (e) => {
    setLoading(true);
    const apiObj = {
      page: e,
      perPage: keyword_recomm_filter?.perPage,
    };
    GetWmKeywordRecommendation(apiObj);
    set_keyword_recomm_filter({ ...keyword_recomm_filter, page: e });
  };

  const onPerPage = (e) => {
    set_keyword_recomm_filter({
      ...keyword_recomm_filter,
      page: 1,
      perPage: e,
    });
    setLoading(true);
    const apiObj = {
      page: 1,
      perPage: e,
    };
    GetWmKeywordRecommendation(apiObj);
  };

  const columns = [
    {
      title: "Sr. No",
      render: (text) => {
        return (
          <span>
            {(keyword_recomm_filter?.page - 1) *
              keyword_recomm_filter?.perPage +
              1 +
              text.key}
          </span>
        );
      },
    },
    {
      title: "Keyword Text",
      dataIndex: "keyword_text",
      key: "keyword_text",
      render: (text) => {
        return (
          <Popover
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
            content={
              <div
                style={{
                  height: "auto",
                  width: "300px",
                }}
              >
                {text}
              </div>
            }
            placement="bottom"
          >
            <span className="text-gray-800 fs-6 mb-1 text-two fw-bold">
              {text}
            </span>
          </Popover>
        );
      },
    },
    {
      title: "Advertiser ID",
      dataIndex: "advertiser_id",
      key: "advertiser_id",
    },
    {
      title: "Suggested Bid",
      dataIndex: "suggested_bid",
      key: "suggested_bid",
    },
    {
      title: "Match Type",
      dataIndex: "match_type",
      key: "match_type",
      render: (text) => {
        return (
          <Tag
            color={
              text === "exact"
                ? "green"
                : text === "broad"
                ? "orange"
                : "geekblue"
            }
          >
            {text}
          </Tag>
        );
      },
    },
    // {
    //   title: "Campaign ID",
    //   dataIndex: "campaign_id",
    //   key: "campaign_id",
    // },
    {
      title: "Campaign Name",
      dataIndex: "campaign_name",
      key: "campaign_name",
    },
    // {
    //   title: "Ad Group ID",
    //   dataIndex: "ad_group_id",
    //   key: "ad_group_id",
    // },

    {
      title: "Ad Group Name",
      dataIndex: "ad_group_name",
      key: "ad_group_name",
      render: (text) => {
        return (
          <Popover
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
            content={
              <div
                style={{
                  height: "auto",
                  width: "300px",
                }}
              >
                {text}
              </div>
            }
            placement="bottom"
          >
            <span className="text-gray-800 fs-6 mb-1 text-two ">{text}</span>
          </Popover>
        );
      },
    },
    // {
    //   title: "Marketplace ID",
    //   dataIndex: "marketplace_id",
    //   key: "marketplace_id",
    // },
    // {
    //   title: "Marketplace",
    //   dataIndex: "marketplace",
    //   key: "marketplace",
    // },

    // {
    //   title: "Created At",
    //   dataIndex: "created_at",
    //   key: "created_at",
    //   render: (timestamp) =>
    //     moment(new Date(timestamp * 1000)).format("MMM DD, YYYY"), // Convert timestamp to date
    // },
    // {
    //   title: "Updated At",
    //   dataIndex: "updated_at",
    //   key: "updated_at",
    //   render: (timestamp) =>
    //     moment(new Date(timestamp * 1000)).format("MMM DD, YYYY"), // Convert timestamp to date
    // },
  ];

  return (
    <>
      <Modal show={show} onHide={onHide} size="xl" centered backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>
            <label className="fs-2">eyword Recommendation</label>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <div className="row">
              <div className="col-12 mb-10 d-flex justify-content-end">
                <div className="fw-bold fs-4">
                  Total keyword Recommendation :
                  <Tag color="success" className="fs-2 ms-2">
                    {keyword_recommendation_total_record}
                  </Tag>
                </div>
              </div>
              <div className="col-12">
                <div className="table-responsive">
                  {loading ? (
                    <Loading style={{ height: "500px" }} />
                  ) : keyword_recommendation_list?.length > 0 ? (
                    <IvcsrTable
                      columns={columns?.map((d) => ({
                        ...d,
                        width:
                          d?.title === "Sr. No"
                            ? 80
                            : d?.title === "Keyword Text"
                            ? 250
                            : d?.title?.length * 10,
                      }))}
                      scroll={{
                        x: "max-content",
                        y: 500,
                      }}
                      fixed={true}
                      dataSource={keyword_recommendation_list}
                      rowKey="key"
                      loading={loading}
                      pagination={false}
                    />
                  ) : (
                    <NoData type="new" />
                  )}
                </div>
                <Pagination
                  loading={loading || keyword_recommendation_list?.length === 0}
                  pageSize={keyword_recomm_filter?.perPage}
                  page={keyword_recomm_filter?.page}
                  totalPage={keyword_recommendation_total_record}
                  onPerPage={onPerPage}
                  onPageNo={onPageNo}
                />
              </div>
            </div>
          </>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-light-danger"
            onClick={onHide}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
