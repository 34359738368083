import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ListingOptimizerProductDetails from "../../../modules/pages/listing-optimizer/lib/product-details";

import {
  GetListingOptimizerProductDetails,
  GetCriteriaAnalysisTitle,
  GetCriteriaAnalysisBulletPoints,
  GetCriteriaAnalysisProductDescription,
  GetRecommendationTitle,
  GetRecommendationDescription,
  GetRecommendationBullets,
  fakeActionListingOptimizer,
} from "../../../redux/modules/listing-optimizer/listing-optimizer.action";

const mapStateToProps = (state) => ({
  GetListingOptimizerProductsResponse:
    state.ListingOptimizer.GetListingOptimizerProductsResponse,
  GetCriteriaAnalysisTitleResponse:
    state.ListingOptimizer.GetCriteriaAnalysisTitleResponse,
  GetCriteriaAnalysisBulletPointsResponse:
    state.ListingOptimizer.GetCriteriaAnalysisBulletPointsResponse,
  GetCriteriaAnalysisProductDescResponse:
    state.ListingOptimizer.GetCriteriaAnalysisProductDescResponse,
  GetRecommendationTitleResponse:
    state.ListingOptimizer.GetRecommendationTitleResponse,
  GetRecommendationDescriptionResponse:
    state.ListingOptimizer.GetRecommendationDescriptionResponse,
  GetRecommendationBulletsResponse:
    state.ListingOptimizer.GetRecommendationBulletsResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      GetListingOptimizerProductDetails,
      GetCriteriaAnalysisTitle,
      GetCriteriaAnalysisBulletPoints,
      GetCriteriaAnalysisProductDescription,
      GetRecommendationTitle,
      GetRecommendationDescription,
      GetRecommendationBullets,
      fakeActionListingOptimizer,
    },
    dispatch
  );

const ListingOptimizerProductDetailsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ListingOptimizerProductDetails);

export default ListingOptimizerProductDetailsContainer;
