import React, { useContext, useEffect } from "react";
import { GlobalContext } from "../../../../commonContext";
// import WalmartIndex from "./lib/walmart-index";
import { useHistory } from "react-router-dom";
import UnderConstruction from "../../../../component/under-construction/under-construction";
import AmazonAdvRuleManagement from "./amazon-adv-rule-management";
import WalmartRuleManagement from "./walmart-rule-management";

export default function (props) {
  const history = useHistory();
  const contextVar = useContext(GlobalContext);

  return contextVar?.data?.marketplaceType === "amazon" ? (
    <AmazonAdvRuleManagement {...props} />
  ) : (
    <WalmartRuleManagement {...props} />
  );
}
