import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { urlDecode } from "../../../../../config";
import { useSelector } from "react-redux";
import TabOrderDetails from "./tab-order-details";
import TabItemDetails from "./tab-item-details";
import TabCustomerDetails from "./tab-customer-details";
import TabTimeline from "./tab-timeline";

const OrderDetails = (props) => {
  const { GetOrderDetails, fakeActionWalmartOrders } = props;
  const [selectedTab, setSelectedTab] = useState(1);
  const [orderData, setOrderData] = useState([]);
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const newUrl = urlDecode(location);
    GetOrderDetails({ id: newUrl?.poid, is_all: 0 });
  }, [location]);

  const GetWalmartOrdersDetailsRes = useSelector(
    (state) => state.WalmartOrders.GetWalmartOrdersDetailsResponse || {}
  );

  useEffect(() => {
    if (GetWalmartOrdersDetailsRes.status === true) {
      setOrderData(GetWalmartOrdersDetailsRes?.data);
      setLoading(false);
      fakeActionWalmartOrders("GetWalmartOrdersDetailsResponse");
    } else if (GetWalmartOrdersDetailsRes.status === false) {
      setLoading(false);
      fakeActionWalmartOrders("GetWalmartOrdersDetailsResponse");
    }
  }, [GetWalmartOrdersDetailsRes]);

  return (
    <div className="fadeInRight">
      <>
        <div className="d-flex flex-column flex-column-fluid">
          <div id="kt_app_content" className="app-content flex-column-fluid">
            <div
              id="kt_app_content_container"
              className="app-container container-fluid"
            >
              <div className="row ">
                <>
                  <div className="row">
                    <div className="col-md-12 cus-tab">
                      <ul className="nav nav-pills mb-5 fs-6">
                        <li className="nav-item">
                          <span
                            onClick={() => {
                              setSelectedTab(1);
                            }}
                            className={`nav-link  ${
                              selectedTab == 1 && "active "
                            } fs-6 fw-bold py-3 px-7 me-2 cursor-pointer`}
                            data-bs-toggle="tab"
                            style={{ backgroundColor: "#edeeff" }}
                          >
                            Order Details
                          </span>
                        </li>
                        <li className="nav-item">
                          <span
                            className={`nav-link ${
                              selectedTab == 2 && "active "
                            } fs-6 fw-bold py-3 px-7 me-2 cursor-pointer`}
                            data-bs-toggle="tab"
                            style={{ backgroundColor: "#edeeff" }}
                            onClick={() => {
                              setSelectedTab(2);
                            }}
                          >
                            Items Details
                          </span>
                        </li>
                        {/* <li className="nav-item">
                          <span
                            className={`nav-link ${
                              selectedTab == 3 && "active  bg-white"
                            } fs-6 fw-bold py-3 px-7 me-2 cursor-pointer`}
                            data-bs-toggle="tab"
                            onClick={() => {
                              setSelectedTab(3);
                            }}
                          >
                            Customer Details
                          </span>
                        </li> */}
                        {/* <li className="nav-item">
                          <span
                            className={`nav-link ${
                              selectedTab == 4 && "active  bg-white"
                            } fs-6 fw-bold py-3 px-7 me-2 cursor-pointer`}
                            data-bs-toggle="tab"
                            onClick={() => {
                              setSelectedTab(4);
                            }}
                          >
                            Timeline
                          </span>
                        </li> */}
                      </ul>
                    </div>
                  </div>
                  <div className="tab-content" id="myTabContent">
                    {selectedTab == 1 && (
                      <div
                        className="tab-pane fade show active"
                        id="kt_tab_pane_6"
                        role="tabpanel"
                      >
                        <TabOrderDetails
                          orderDetails={orderData?.orderDetails}
                          customer={orderData?.shippingInfo}
                          loading={loading}
                        />
                      </div>
                    )}

                    {selectedTab == 2 && (
                      <div
                        className="tab-pane fade  show active"
                        id="kt_tab_pane_7"
                        role="tabpanel"
                      >
                        <TabItemDetails
                          itemDetails={orderData?.itemDetails}
                          loading={loading}
                        />
                      </div>
                    )}
                    {/* {selectedTab == 3 && (
                      <div
                        className="tab-pane fade show active "
                        id="kt_tab_pane_8"
                        role="tabpanel"
                      >
                        <TabCustomerDetails data={orderData} />
                      </div>
                    )} */}

                    {/* {selectedTab == 4 && (
                      <div
                        className="tab-pane fade show active"
                        id="kt_tab_pane_9"
                        role="tabpanel"
                      >
                        <TabTimeline data={orderData} />
                      </div>
                    )} */}
                  </div>
                </>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default OrderDetails;
