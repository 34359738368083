import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ListingOptimizer from "../../modules/pages/listing-optimizer";
import {
  GetListingOptimizerProducts,
  StartProductAnalysis,
  fakeActionListingOptimizer,
} from "../../redux/modules/listing-optimizer/listing-optimizer.action";

import {
  GetSalesChannelList,
  fakeActionSalesAnalytics,
} from "../../redux/modules/sales-analytics/sales-analytics.action";

const mapStateToProps = (state) => ({
  GetListingOptimizerProductsResponse:
    state.ListingOptimizer.GetListingOptimizerProductsResponse,
  StartProductAnalysisResponse:
    state.ListingOptimizer.StartProductAnalysisResponse,
  GetSalesChannelListResponse: state.SalesAnalytics.GetSalesChannelListResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      GetSalesChannelList,
      fakeActionSalesAnalytics,
      GetListingOptimizerProducts,
      StartProductAnalysis,
      fakeActionListingOptimizer,
    },
    dispatch
  );

const ListingOptimizerContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ListingOptimizer);

export default ListingOptimizerContainer;
