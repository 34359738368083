import React from "react";
import { NumberWithCommas } from "../../../../../config";
import { Skeleton } from "antd";

export default function (props) {
  const {
    cardData,
    activeCampaignSubCardData,
    targetingSubCardData,
    dashboardSubCardLoading,
    setDashboardSubCardLoading,
    activeCampaignCardLoading,
    targetingCardLoading,
  } = props;
  console.log("cardData", cardData);
  return (
    <>
      <div className="row">
        <div className="col-12">
          {/*begin::Mixed Widget 14*/}
          <div
            className="card card-xxl-stretch mb-5 mb-xl-5 theme-dark-bg-body"
            style={{}}
          >
            {/*begin::Body*/}
            <div className="card-body d-flex flex-column">
              {/*begin::Wrapper*/}
              <div className="d-flex flex-column mb-7">
                {/*begin::Title*/}
                <span className="text-gray-900 text-hover-primary fw-bold fs-3">
                  Active Campaign
                </span>
                {/*end::Title*/}
              </div>
              {/*end::Wrapper*/}
              {/*begin::Row*/}
              {dashboardSubCardLoading && (
                <div className="row g-5 gy-7">
                  {/*begin::Col*/}
                  <div className="col-6 col-md-6 col-lg-3">
                    <Skeleton.Button active block />
                  </div>
                  <div className="col-6 col-md-6 col-lg-3">
                    <Skeleton.Button active block />
                  </div>
                  <div className="col-6 col-md-6 col-lg-3">
                    <Skeleton.Button active block />
                  </div>
                  <div className="col-6 col-md-6 col-lg-3">
                    <Skeleton.Button active block />
                  </div>
                </div>
              )}
              {!dashboardSubCardLoading && (
                <div className="row g-5 gy-7">
                  {/*begin::Col*/}
                  <div className="col-6 col-md-6 col-lg-3">
                    <div
                      className="d-flex flex-stack"
                      style={{ borderLeft: "2px solid #ffbb18" }}
                    >
                      <div className="symbol symbol-40px me-4">
                        <span className="symbol-label">
                          <i className="ki-duotone ki-bill text-gray-600 fs-1">
                            <span className="path1" />
                            <span className="path2" />
                            <span className="path3" />
                            <span className="path4" />
                            <span className="path5" />
                            <span className="path6" />
                          </i>
                        </span>
                      </div>
                      {/*begin::Section*/}
                      <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                        <div className="me-5">
                          <span className="fw-bold text-primary fs-3">SP</span>
                          {/* <span className="text-gray-600 fw-semibold fs-7 d-block text-start ps-0">
                          559.5K
                        </span> */}
                        </div>
                        {/*end::Content*/}
                        {/*begin::Info*/}
                        <div className="d-flex align-items-center">
                          <span className="text-gray-800 fw-bolder fs-4 me-3 d-block">
                            {NumberWithCommas(cardData?.SP?.current_count || 0)}
                          </span>
                          <div className="m-0">
                            {cardData?.SP?.change_percentage > 0 ? (
                              <span className="badge badge-light-success fs-8">
                                <i className="ki-outline ki-arrow-up fs-5 text-green ms-n1" />
                                {NumberWithCommas(
                                  cardData?.SP?.change_percentage || 0
                                )}
                                %
                              </span>
                            ) : (
                              <span className="badge badge-light-danger fs-8">
                                <i className="ki-outline ki-arrow-down fs-5 text-danger ms-n1" />
                                {NumberWithCommas(
                                  cardData?.SP?.change_percentage || 0
                                )}
                                %
                              </span>
                            )}
                          </div>
                        </div>
                        {/*end::Info*/}
                      </div>
                      {/*end::Section*/}
                    </div>
                  </div>
                  {/*end::Col*/}
                  {/*begin::Col*/}
                  <div className="col-6 col-md-6 col-lg-3">
                    <div
                      className="d-flex flex-stack"
                      style={{ borderLeft: "2px solid #ffbb18" }}
                    >
                      <div className="symbol symbol-40px me-4">
                        <span className="symbol-label">
                          <i className="ki-duotone ki-chart-simple-3 text-gray-600 fs-1">
                            <span className="path1" />
                            <span className="path2" />
                            <span className="path3" />
                            <span className="path4" />
                          </i>
                        </span>
                      </div>
                      {/*begin::Section*/}
                      <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                        <div className="me-5">
                          <span className="fw-bold text-primary fs-3">SB</span>
                          {/* <span className="text-gray-600 fw-semibold fs-7 d-block text-start ps-0">
                          4.5M
                        </span> */}
                        </div>
                        {/*end::Content*/}
                        {/*begin::Info*/}
                        <div className="d-flex align-items-center">
                          <span className="text-gray-800 fw-bolder fs-4 me-3 d-block">
                            {NumberWithCommas(
                              activeCampaignSubCardData?.SB?.current_count || 0
                            )}
                          </span>
                          <div className="m-0">
                            {cardData?.SB?.change_percentage > 0 ? (
                              <span className="badge badge-light-success fs-8">
                                <i className="ki-outline ki-arrow-up fs-5 text-green ms-n1" />
                                {NumberWithCommas(
                                  cardData?.SB?.change_percentage || 0
                                )}
                                %
                              </span>
                            ) : (
                              <span className="badge badge-light-danger fs-8">
                                <i className="ki-outline ki-arrow-down fs-5 text-danger ms-n1" />
                                {NumberWithCommas(
                                  cardData?.SB?.change_percentage || 0
                                )}
                                %
                              </span>
                            )}
                          </div>
                        </div>
                        {/*end::Info*/}
                      </div>
                      {/*end::Section*/}
                    </div>
                  </div>
                  {/*end::Col*/}
                  {/*begin::Col*/}
                  <div className="col-6 col-md-6 col-lg-3">
                    <div
                      className="d-flex flex-stack"
                      style={{ borderLeft: "2px solid #ffbb18" }}
                    >
                      <div className="symbol symbol-40px me-4">
                        <span className="symbol-label">
                          <i className="ki-duotone ki-screen text-gray-600 fs-1">
                            <span className="path1" />
                            <span className="path2" />
                            <span className="path3" />
                            <span className="path4" />
                          </i>
                        </span>
                      </div>
                      {/*begin::Section*/}
                      <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                        <div className="me-5">
                          <span className="fw-bold text-primary fs-3">SD</span>
                          {/* <span className="text-gray-600 fw-semibold fs-7 d-block text-start ps-0">
                          12.40%
                        </span> */}
                        </div>
                        {/*end::Content*/}
                        {/*begin::Info*/}
                        <div className="d-flex align-items-center">
                          <span className="text-gray-800 fw-bolder fs-4 me-3 d-block">
                            {NumberWithCommas(
                              activeCampaignSubCardData?.SD?.current_count || 0
                            )}
                          </span>
                          <div className="m-0">
                            {cardData?.SD?.change_percentage > 0 ? (
                              <span className="badge badge-light-success fs-8">
                                <i className="ki-outline ki-arrow-up fs-5 text-green ms-n1" />
                                {NumberWithCommas(
                                  cardData?.SD?.change_percentage || 0
                                )}
                                %
                              </span>
                            ) : (
                              <span className="badge badge-light-danger fs-8">
                                <i className="ki-outline ki-arrow-down fs-5 text-danger ms-n1" />
                                {NumberWithCommas(
                                  cardData?.SD?.change_percentage || 0
                                )}
                                %
                              </span>
                            )}
                          </div>
                        </div>
                        {/*end::Info*/}
                      </div>
                      {/*end::Section*/}
                    </div>
                  </div>
                  {/*end::Col*/}
                  {/*begin::Col*/}
                  <div className="col-6 col-md-6 col-lg-3">
                    <div
                      className="d-flex flex-stack"
                      style={{ borderLeft: "2px solid #ffbb18" }}
                    >
                      <div className="symbol symbol-40px me-4">
                        <span className="symbol-label">
                          <i className="ki-duotone ki-screen text-gray-600 fs-1">
                            <span className="path1" />
                            <span className="path2" />
                            <span className="path3" />
                            <span className="path4" />
                          </i>
                        </span>
                      </div>
                      {/*begin::Section*/}
                      <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                        <div className="me-5">
                          <span className="fw-bold text-primary fs-3">SBV</span>
                          {/* <span className="text-gray-600 fw-semibold fs-7 d-block text-start ps-0">
                          12.40%
                        </span> */}
                        </div>
                        {/*end::Content*/}
                        {/*begin::Info*/}
                        <div className="d-flex align-items-center">
                          <span className="text-gray-800 fw-bolder fs-4 me-3 d-block">
                            {NumberWithCommas(
                              activeCampaignSubCardData?.SBV?.current_count || 0
                            )}
                          </span>
                          <div className="m-0">
                            {cardData?.SBV?.change_percentage > 0 ? (
                              <span className="badge badge-light-success fs-8">
                                <i className="ki-outline ki-arrow-up fs-5 text-green ms-n1" />
                                {NumberWithCommas(
                                  cardData?.SBV?.change_percentage || 0
                                )}
                                %
                              </span>
                            ) : (
                              <span className="badge badge-light-danger fs-8">
                                <i className="ki-outline ki-arrow-down fs-5 text-danger ms-n1" />
                                {NumberWithCommas(
                                  cardData?.SBV?.change_percentage || 0
                                )}
                                %
                              </span>
                            )}
                          </div>
                        </div>
                        {/*end::Info*/}
                      </div>
                      {/*end::Section*/}
                    </div>
                  </div>
                  {/*end::Col*/}
                </div>
              )}

              {/*end::Row*/}
            </div>
          </div>
          {/*end::Mixed Widget 14*/}
        </div>
      </div>
      {/* ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */}

      <div className="row ">
        <div className="col-12">
          {/*begin::Mixed Widget 14*/}
          <div className="card card-xxl-stretch mb-5 mb-xl-5 theme-dark-bg-body">
            {/*begin::Body*/}
            <div className="card-body d-flex flex-column">
              {/*begin::Wrapper*/}
              <div className="d-flex flex-column mb-7">
                {/*begin::Title*/}
                <span className="text-gray-900 text-hover-primary fw-bold fs-3">
                  Performance
                </span>
                {/*end::Title*/}
              </div>
              {/*end::Wrapper*/}
              {/*begin::Row*/}
              {dashboardSubCardLoading && (
                <div className="row g-5 gy-7">
                  {/*begin::Col*/}
                  <div className="col-6 col-md-6 col-lg-3">
                    <Skeleton.Button active block style={{ height: "100px" }} />
                  </div>
                  <div className="col-6 col-md-6 col-lg-3">
                    <Skeleton.Button active block style={{ height: "100px" }} />
                  </div>
                  <div className="col-6 col-md-6 col-lg-3">
                    <Skeleton.Button active block style={{ height: "100px" }} />
                  </div>
                  <div className="col-6 col-md-6 col-lg-3">
                    <Skeleton.Button active block style={{ height: "100px" }} />
                  </div>
                </div>
              )}
              {!dashboardSubCardLoading && (
                <div className="row g-5 gy-7 d-flex justify-content-between">
                  {/*begin::Col*/}
                  <div className="" style={{ width: "20%" }}>
                    {/*begin::Item*/}
                    <div
                      className="d-flex flex-stack "
                      style={{ borderLeft: "2px solid #4318ff" }}
                    >
                      <div className="symbol symbol-40px me-4">
                        <span className="symbol-label">
                          <i className="ki-duotone ki-dollar text-gray-600 fs-1">
                            <span className="path1" />
                            <span className="path2" />
                            <span className="path3" />
                          </i>
                        </span>
                      </div>
                      {/*begin::Section*/}
                      <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                        <div className="me-5">
                          <span className="text-gray-800 fw-bolder fs-4 me-3 d-block">
                            ${NumberWithCommas(cardData?.spend?.current || 0)}
                          </span>
                          <span className="fw-bold text-primary fs-3">
                            Ad Spend
                          </span>
                          <div className="d-flex align-items-center">
                            {/* <span className="text-gray-600 fw-semibold fs-7 d-block text-start ps-0">
                            559.5K
                          </span> */}

                            {cardData?.spend?.change_percentage > 0 ? (
                              <span className="badge badge-light-success fs-8">
                                <i className="ki-outline ki-arrow-up fs-5 text-green ms-n1" />
                                {NumberWithCommas(
                                  cardData?.spend?.change_percentage || 0
                                )}
                                %
                              </span>
                            ) : (
                              <span className="badge badge-light-danger fs-8">
                                <i className="ki-outline ki-arrow-down fs-5 text-danger ms-n1" />
                                {NumberWithCommas(
                                  cardData?.spend?.change_percentage || 0
                                )}
                                %
                              </span>
                            )}
                          </div>
                        </div>
                        {/*end::Content*/}
                      </div>
                      {/*end::Section*/}
                    </div>
                    {/*end::Item*/}
                  </div>
                  {/*begin::Col*/}

                  <div className="" style={{ width: "20%" }}>
                    {/*begin::Item*/}
                    <div
                      className="d-flex flex-stack "
                      style={{ borderLeft: "2px solid #4318ff" }}
                    >
                      <div className="symbol symbol-40px me-4">
                        <span className="symbol-label">
                          <i className="ki-duotone ki-chart-pie-3  text-gray-600 fs-1">
                            <span className="path1" />
                            <span className="path2" />
                            <span className="path3" />
                          </i>
                        </span>
                      </div>
                      {/*begin::Section*/}
                      <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                        <div className="me-5">
                          <span className="text-gray-800 fw-bolder fs-4 me-3 d-block">
                            ${NumberWithCommas(cardData?.sales?.current || 0)}
                          </span>
                          <span className="fw-bold text-primary fs-3">
                            Ad Sales
                          </span>
                          <div className="d-flex align-items-center">
                            {/* <span className="text-gray-600 fw-semibold fs-7 d-block text-start ps-0">
                            559.5K
                          </span> */}

                            {cardData?.sales?.change_percentage > 0 ? (
                              <span className="badge badge-light-success fs-8">
                                <i className="ki-outline ki-arrow-up fs-5 text-green ms-n1" />
                                {NumberWithCommas(
                                  cardData?.sales?.change_percentage || 0
                                )}
                                %
                              </span>
                            ) : (
                              <span className="badge badge-light-danger fs-8">
                                <i className="ki-outline ki-arrow-down fs-5 text-danger ms-n1" />
                                {NumberWithCommas(
                                  cardData?.sales?.change_percentage || 0
                                )}
                                %
                              </span>
                            )}
                          </div>
                        </div>
                        {/*end::Content*/}
                      </div>
                      {/*end::Section*/}
                    </div>
                    {/*end::Item*/}
                  </div>

                  {/*end::Col*/}
                  {/*begin::Col*/}
                  <div className="" style={{ width: "20%" }}>
                    {/*begin::Item*/}
                    <div
                      className="d-flex flex-stack "
                      style={{ borderLeft: "2px solid #4318ff" }}
                    >
                      <div className="symbol symbol-40px me-4">
                        <span className="symbol-label">
                          <i className="ki-duotone ki-chart-pie-3  text-gray-600 fs-1">
                            <span className="path1" />
                            <span className="path2" />
                            <span className="path3" />
                          </i>
                        </span>
                      </div>
                      {/*begin::Section*/}
                      <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                        <div className="me-5">
                          <span className="text-gray-800 fw-bolder fs-4 me-3 d-block">
                            $
                            {NumberWithCommas(
                              cardData?.total_sales?.current || 0
                            )}
                          </span>
                          <span className="fw-bold text-primary fs-3">
                            Total Sales
                          </span>
                          <div className="d-flex align-items-center">
                            {/* <span className="text-gray-600 fw-semibold fs-7 d-block text-start ps-0">
                            559.5K
                          </span> */}
                            {cardData?.total_sales?.change_percentage > 0 ? (
                              <span className="badge badge-light-success fs-8">
                                <i className="ki-outline ki-arrow-up fs-5 text-green ms-n1" />
                                {NumberWithCommas(
                                  cardData?.total_sales?.change_percentage || 0
                                )}
                                %
                              </span>
                            ) : (
                              <span className="badge badge-light-danger fs-8">
                                <i className="ki-outline ki-arrow-down fs-5 text-danger ms-n1" />
                                {NumberWithCommas(
                                  cardData?.total_sales?.change_percentage || 0
                                )}
                                %
                              </span>
                            )}
                          </div>
                        </div>
                        {/*end::Content*/}
                      </div>
                      {/*end::Section*/}
                    </div>
                    {/*end::Item*/}
                  </div>

                  {/*end::Col*/}
                  {/*begin::Col*/}

                  <div className="" style={{ width: "20%" }}>
                    {/*begin::Item*/}
                    <div
                      className="d-flex flex-stack "
                      style={{ borderLeft: "2px solid #4318ff" }}
                    >
                      <div className="symbol symbol-40px me-4">
                        <span className="symbol-label">
                          <i className="ki-duotone ki-graph-up text-gray-600 fs-1">
                            <span className="path1" />
                            <span className="path2" />
                            <span className="path3" />
                            <span className="path4" />
                            <span className="path5" />
                            <span className="path6" />
                          </i>
                        </span>
                      </div>
                      {/*begin::Section*/}
                      <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                        <div className="me-5">
                          <span className="text-gray-800 fw-bolder fs-4 me-3 d-block">
                            {NumberWithCommas(cardData?.acos?.current || 0)}%
                          </span>
                          <span className="fw-bold text-primary fs-3">
                            ACOS
                          </span>
                          <div className="d-flex align-items-center">
                            {/* <span className="text-gray-600 fw-semibold fs-7 d-block text-start ps-0">
                            559.5K
                          </span> */}
                            {cardData?.acos?.change_percentage > 0 ? (
                              <span className="badge badge-light-success fs-8">
                                <i className="ki-outline ki-arrow-up fs-5 text-green ms-n1" />
                                {NumberWithCommas(
                                  cardData?.acos?.change_percentage || 0
                                )}
                                %
                              </span>
                            ) : (
                              <span className="badge badge-light-danger fs-8">
                                <i className="ki-outline ki-arrow-down fs-5 text-danger ms-n1" />
                                {NumberWithCommas(
                                  cardData?.acos?.change_percentage || 0
                                )}
                                %
                              </span>
                            )}
                          </div>
                        </div>
                        {/*end::Content*/}
                      </div>
                      {/*end::Section*/}
                    </div>
                    {/*end::Item*/}
                  </div>

                  {/*end::Col*/}
                  {/*begin::Col*/}

                  <div className="" style={{ width: "20%" }}>
                    {/*begin::Item*/}
                    <div
                      className="d-flex flex-stack "
                      style={{ borderLeft: "2px solid #4318ff" }}
                    >
                      <div className="symbol symbol-40px me-4">
                        <span className="symbol-label">
                          <i className="ki-duotone ki-graph-up text-gray-600 fs-1">
                            <span className="path1" />
                            <span className="path2" />
                            <span className="path3" />
                            <span className="path4" />
                            <span className="path5" />
                            <span className="path6" />
                          </i>
                        </span>
                      </div>
                      {/*begin::Section*/}
                      <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                        <div className="me-5">
                          <span className="text-gray-800 fw-bolder fs-4 me-3 d-block">
                            {NumberWithCommas(cardData?.tacos?.current || 0)}%
                          </span>
                          <span className="fw-bold text-primary fs-3">
                            TACoS
                          </span>
                          <div className="d-flex align-items-center">
                            {/* <span className="text-gray-600 fw-semibold fs-7 d-block text-start ps-0">
                            559.5K
                          </span> */}
                            {cardData?.tacos?.change_percentage > 0 ? (
                              <span className="badge badge-light-success fs-8">
                                <i className="ki-outline ki-arrow-up fs-5 text-green ms-n1" />
                                {NumberWithCommas(
                                  cardData?.tacos?.change_percentage || 0
                                )}
                                %
                              </span>
                            ) : (
                              <span className="badge badge-light-danger fs-8">
                                <i className="ki-outline ki-arrow-down fs-5 text-danger ms-n1" />
                                {NumberWithCommas(
                                  cardData?.tacos?.change_percentage || 0
                                )}
                                %
                              </span>
                            )}
                          </div>
                        </div>
                        {/*end::Content*/}
                      </div>
                      {/*end::Section*/}
                    </div>
                    {/*end::Item*/}
                  </div>

                  {/*end::Col*/}
                </div>
              )}
              {/*end::Row*/}
            </div>
          </div>
          {/*end::Mixed Widget 14*/}
        </div>
      </div>

      {/* -------------------------------------------------------------- */}

      <div className="row ">
        <div className="col-sm-6 col-md-6 col-xxl-6">
          <div className="card card-xxl-stretch mb-5 mb-xl-8">
            {/*begin::Header*/}
            <div className="card-header border-bottom-0 min-h-45px">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bolder text-dark">
                  Conversion
                </span>
                {/* <span class="text-gray-400 pt-1 fw-semibold fs-6">Sales analytics by SKU</span> */}
              </h3>
              <div className="card-toolbar"></div>
            </div>
            {/*end::Header*/}
            {/*begin::Body*/}
            <div className="card-body py-2">
              {dashboardSubCardLoading && (
                <div className="row g-5 gy-7">
                  {/*begin::Col*/}
                  <div className="col-6 col-md-6 col-lg-4">
                    <Skeleton.Button active block style={{ height: "100px" }} />
                  </div>
                  <div className="col-6 col-md-6 col-lg-4">
                    <Skeleton.Button active block style={{ height: "100px" }} />
                  </div>
                  <div className="col-6 col-md-6 col-lg-4">
                    <Skeleton.Button active block style={{ height: "100px" }} />
                  </div>
                </div>
              )}
              {!dashboardSubCardLoading && (
                <div className="row g-4 mb-5">
                  <div className="col-md-4">
                    {/*begin::Item*/}
                    <div
                      className="d-flex flex-stack "
                      style={{ borderLeft: "2px solid #4caf50" }}
                    >
                      <div className="symbol symbol-40px me-4">
                        <span className="symbol-label">
                          <i className="ki-duotone ki-click text-gray-600 fs-1">
                            <span className="path1" />
                            <span className="path2" />
                            <span className="path3" />
                            <span className="path4" />
                            <span className="path5" />
                          </i>
                        </span>
                      </div>
                      {/*begin::Section*/}
                      <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                        <div className="me-5">
                          <span className="text-gray-800 fw-bolder fs-4 me-3 d-block">
                            {NumberWithCommas(cardData?.clicks?.current || 0)}
                          </span>
                          <span className="fw-bold text-primary fs-3">
                            Clicks
                          </span>
                          <div className="d-flex align-items-center">
                            {/* <span className="text-gray-600 fw-semibold fs-7 d-block text-start ps-0">
                            559.5K
                          </span> */}

                            {cardData?.clicks?.change_percentage > 0 ? (
                              <span className="badge badge-light-success fs-8">
                                <i className="ki-outline ki-arrow-up fs-5 text-green ms-n1" />
                                {NumberWithCommas(
                                  cardData?.clicks?.change_percentage || 0
                                )}
                                %
                              </span>
                            ) : (
                              <span className="badge badge-light-danger fs-8">
                                <i className="ki-outline ki-arrow-down fs-5 text-danger ms-n1" />
                                {NumberWithCommas(
                                  cardData?.clicks?.change_percentage || 0
                                )}
                                %
                              </span>
                            )}
                          </div>
                        </div>
                        {/*end::Content*/}
                      </div>
                      {/*end::Section*/}
                    </div>
                    {/*end::Item*/}
                  </div>
                  <div className="col-md-4">
                    {/*begin::Item*/}
                    <div
                      className="d-flex flex-stack "
                      style={{ borderLeft: "2px solid #4caf50" }}
                    >
                      <div className="symbol symbol-40px me-4">
                        <span className="symbol-label">
                          <i className="ki-duotone ki-click text-gray-600 fs-1">
                            <span className="path1" />
                            <span className="path2" />
                            <span className="path3" />
                            <span className="path4" />
                            <span className="path5" />
                          </i>
                        </span>
                      </div>
                      {/*begin::Section*/}
                      <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                        <div className="me-5">
                          <span className="text-gray-800 fw-bolder fs-4 me-3 d-block">
                            {NumberWithCommas(cardData?.orders?.current || 0)}
                          </span>
                          <span className="fw-bold text-primary fs-3">
                            Orders
                          </span>
                          <div className="d-flex align-items-center">
                            {/* <span className="text-gray-600 fw-semibold fs-7 d-block text-start ps-0">
                            559.5K
                          </span> */}
                            {cardData?.orders?.change_percentage > 0 ? (
                              <span className="badge badge-light-success fs-8">
                                <i className="ki-outline ki-arrow-up fs-5 text-green ms-n1" />
                                {NumberWithCommas(
                                  cardData?.orders?.change_percentage || 0
                                )}
                                %
                              </span>
                            ) : (
                              <span className="badge badge-light-danger fs-8">
                                <i className="ki-outline ki-arrow-down fs-5 text-danger ms-n1" />
                                {NumberWithCommas(
                                  cardData?.orders?.change_percentage || 0
                                )}
                                %
                              </span>
                            )}
                          </div>
                        </div>
                        {/*end::Content*/}
                      </div>
                      {/*end::Section*/}
                    </div>
                    {/*end::Item*/}
                  </div>
                  <div className="col-md-4">
                    {/*begin::Item*/}
                    <div
                      className="d-flex flex-stack "
                      style={{ borderLeft: "2px solid #4caf50" }}
                    >
                      <div className="symbol symbol-40px me-4">
                        <span className="symbol-label">
                          <i className="ki-duotone ki-click text-gray-600 fs-1">
                            <span className="path1" />
                            <span className="path2" />
                            <span className="path3" />
                            <span className="path4" />
                            <span className="path5" />
                          </i>
                        </span>
                      </div>
                      {/*begin::Section*/}
                      <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                        <div className="me-5">
                          <span className="text-gray-800 fw-bolder fs-4 me-3 d-block">
                            {NumberWithCommas(cardData?.cvr?.current || 0)}%
                          </span>
                          <span className="fw-bold text-primary fs-3">CVR</span>
                          <div className="d-flex align-items-center">
                            {/* <span className="text-gray-600 fw-semibold fs-7 d-block text-start ps-0">
                            559.5K
                          </span> */}
                            {cardData?.cvr?.change_percentage > 0 ? (
                              <span className="badge badge-light-success fs-8">
                                <i className="ki-outline ki-arrow-up fs-5 text-green ms-n1" />
                                {NumberWithCommas(
                                  cardData?.cvr?.change_percentage || 0
                                )}
                                %
                              </span>
                            ) : (
                              <span className="badge badge-light-danger fs-8">
                                <i className="ki-outline ki-arrow-down fs-5 text-danger ms-n1" />
                                {NumberWithCommas(
                                  cardData?.cvr?.change_percentage || 0
                                )}
                                %
                              </span>
                            )}
                          </div>
                        </div>
                        {/*end::Content*/}
                      </div>
                      {/*end::Section*/}
                    </div>
                    {/*end::Item*/}
                  </div>
                </div>
              )}
            </div>
            {/*begin::Body*/}
          </div>
        </div>
        <div className="col-sm-6 col-md-6 col-xxl-6">
          <div className="card card-xxl-stretch mb-5 mb-xl-8">
            {/*begin::Header*/}
            <div className="card-header border-bottom-0 min-h-45px">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bolder text-dark">
                  Efficiency
                </span>
                {/* <span class="text-gray-400 pt-1 fw-semibold fs-6">Sales analytics by SKU</span> */}
              </h3>
              <div className="card-toolbar"></div>
            </div>
            {/*end::Header*/}
            {/*begin::Body*/}
            <div className="card-body py-2">
              {dashboardSubCardLoading && (
                <div className="row g-5 gy-7">
                  {/*begin::Col*/}
                  <div className="col-6 col-md-6 col-lg-4">
                    <Skeleton.Button active block style={{ height: "100px" }} />
                  </div>
                  <div className="col-6 col-md-6 col-lg-4">
                    <Skeleton.Button active block style={{ height: "100px" }} />
                  </div>
                  <div className="col-6 col-md-6 col-lg-4">
                    <Skeleton.Button active block style={{ height: "100px" }} />
                  </div>
                </div>
              )}
              {!dashboardSubCardLoading && (
                <div className="row g-4 mb-5">
                  <div className="col-md-4">
                    {/*begin::Item*/}
                    <div
                      className="d-flex flex-stack "
                      style={{ borderLeft: "2px solid #f44336" }}
                    >
                      <div className="symbol symbol-40px me-4">
                        <span className="symbol-label">
                          <i className="ki-duotone ki-click text-gray-600 fs-1">
                            <span className="path1" />
                            <span className="path2" />
                            <span className="path3" />
                            <span className="path4" />
                            <span className="path5" />
                          </i>
                        </span>
                      </div>
                      {/*begin::Section*/}
                      <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                        <div className="me-5">
                          <span className="text-gray-800 fw-bolder fs-4 me-3 d-block">
                            ${NumberWithCommas(cardData?.cpc?.current || 0)}
                          </span>
                          <span className="fw-bold text-primary fs-3">CPC</span>
                          <div className="d-flex align-items-center">
                            {cardData?.cpc?.change_percentage > 0 ? (
                              <span className="badge badge-light-success fs-8">
                                <i className="ki-outline ki-arrow-up fs-5 text-green ms-n1" />
                                {NumberWithCommas(
                                  cardData?.cpc?.change_percentage || 0
                                )}
                                %
                              </span>
                            ) : (
                              <span className="badge badge-light-danger fs-8">
                                <i className="ki-outline ki-arrow-down fs-5 text-danger ms-n1" />
                                {NumberWithCommas(
                                  cardData?.cpc?.change_percentage || 0
                                )}
                                %
                              </span>
                            )}
                          </div>
                        </div>
                        {/*end::Content*/}
                      </div>
                      {/*end::Section*/}
                    </div>
                    {/*end::Item*/}
                  </div>
                  <div className="col-md-4">
                    {/*begin::Item*/}
                    <div
                      className="d-flex flex-stack "
                      style={{ borderLeft: "2px solid #f44336" }}
                    >
                      <div className="symbol symbol-40px me-4">
                        <span className="symbol-label">
                          <i className="ki-duotone ki-click text-gray-600 fs-1">
                            <span className="path1" />
                            <span className="path2" />
                            <span className="path3" />
                            <span className="path4" />
                            <span className="path5" />
                          </i>
                        </span>
                      </div>
                      {/*begin::Section*/}
                      <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                        <div className="me-5">
                          <span className="text-gray-800 fw-bolder fs-4 me-3 d-block">
                            ${NumberWithCommas(cardData?.spc?.current || 0)}
                          </span>
                          <span className="fw-bold text-primary fs-3">SPC</span>
                          <div className="d-flex align-items-center">
                            {cardData?.spc?.change_percentage > 0 ? (
                              <span className="badge badge-light-success fs-8">
                                <i className="ki-outline ki-arrow-up fs-5 text-green ms-n1" />
                                {NumberWithCommas(
                                  cardData?.spc?.change_percentage || 0
                                )}
                                %
                              </span>
                            ) : (
                              <span className="badge badge-light-danger fs-8">
                                <i className="ki-outline ki-arrow-down fs-5 text-danger ms-n1" />
                                {NumberWithCommas(
                                  cardData?.spc?.change_percentage || 0
                                )}
                                %
                              </span>
                            )}
                          </div>
                        </div>
                        {/*end::Content*/}
                      </div>
                      {/*end::Section*/}
                    </div>
                    {/*end::Item*/}
                  </div>
                  <div className="col-md-4">
                    {/*begin::Item*/}
                    <div
                      className="d-flex flex-stack "
                      style={{ borderLeft: "2px solid #f44336" }}
                    >
                      <div className="symbol symbol-40px me-4">
                        <span className="symbol-label">
                          <i className="ki-duotone ki-click text-gray-600 fs-1">
                            <span className="path1" />
                            <span className="path2" />
                            <span className="path3" />
                            <span className="path4" />
                            <span className="path5" />
                          </i>
                        </span>
                      </div>
                      {/*begin::Section*/}
                      <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                        <div className="me-5">
                          <span className="text-gray-800 fw-bolder fs-4 me-3 d-block">
                            {NumberWithCommas(cardData?.ctr?.current || 0)}%
                          </span>
                          <span className="fw-bold text-primary fs-3">CTR</span>
                          <div className="d-flex align-items-center">
                            {cardData?.ctr?.change_percentage > 0 ? (
                              <span className="badge badge-light-success fs-8">
                                <i className="ki-outline ki-arrow-up fs-5 text-green ms-n1" />
                                {NumberWithCommas(
                                  cardData?.ctr?.change_percentage || 0
                                )}
                                %
                              </span>
                            ) : (
                              <span className="badge badge-light-danger fs-8">
                                <i className="ki-outline ki-arrow-down fs-5 text-danger ms-n1" />
                                {NumberWithCommas(
                                  cardData?.ctr?.change_percentage || 0
                                )}
                                %
                              </span>
                            )}
                          </div>
                        </div>
                        {/*end::Content*/}
                      </div>
                      {/*end::Section*/}
                    </div>
                    {/*end::Item*/}
                  </div>
                </div>
              )}
            </div>
            {/*begin::Body*/}
          </div>
        </div>
      </div>
    </>
  );
}
