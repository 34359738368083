import axiosCall from "../../../configurations/network-services/axiosCall";

// const prefix = "dashboard";

export const GetWalmartApiLogList = (data) => {
  const path = `wm/log?page=${data?.page || 1}&per-page=${
    data?.perPage || 10
  }&id=${data?.searchTerm || ""}`;
  const responseType = "GET_WALMART_API_LOG_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const fakeActionWalmartApiLog = (data) => async (dispatch) => {
  dispatch({ type: "FAKE_ACTION_WALAMRT_API_LOG", state: data });
};
