import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Profile from "../../../modules/pages/setting/profile";

import {
  SaveUserProfile,
  fakeActionuserProfile,
} from "../../../redux/modules/setting/profile/profile.action";

const mapStateToProps = (state) => ({
  GetUserProfileSaveResponse: state.UserProfile.GetUserProfileSaveResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      SaveUserProfile,
      fakeActionuserProfile,
    },
    dispatch
  );

const ProfileContainer = connect(mapStateToProps, mapDispatchToProps)(Profile);

export default ProfileContainer;
