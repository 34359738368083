import { Alert, Input, message } from "antd";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import ReactJson from "react-json-view";
import { useSelector } from "react-redux";
export default function ({ show, onHide, requestedJson, responseJson }) {
  console.log(requestedJson, responseJson, "requestedJson, responseJson");
  return (
    <>
      <Modal show={show} onHide={onHide} size="xl" centered backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Erorr Log</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <div className="d-flex justify-content-center align-items-center">
              <div style={{ height: "900px", width: "48%" }}>
                <div>
                  <h2>Requested Payload</h2>
                </div>
                <ReactJson
                  src={requestedJson}
                  theme="rjv-default"
                  displayDataTypes={false}
                  collapsed={false}
                  style={{
                    overflow: "auto",
                    height: "100%",
                    border: "1px solid #5142a0b0",
                    borderRadius: "4px",
                    padding: "10px",
                  }}
                />
              </div>
              <div style={{ width: "4%" }}>
                <span style={{ fontSize: "40px" }}>{"=>"}</span>
              </div>
              <div style={{ height: "900px", width: "48%" }}>
                <div className="d-flex justify-content-center">
                  <h2>Response Payload</h2>
                </div>
                <ReactJson
                  src={responseJson}
                  theme="rjv-default"
                  displayDataTypes={false}
                  collapsed={false}
                  style={{
                    overflow: "auto",
                    height: "100%",
                    border: "1px solid #5142a0b0",
                    borderRadius: "4px",
                    padding: "10px",
                  }}
                />
              </div>
            </div>
          </>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-light-danger"
            onClick={onHide}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
