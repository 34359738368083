import React, { useContext, useEffect } from "react";
import { GlobalContext } from "../../../../commonContext";
import { useHistory } from "react-router-dom";
import WalmartOrders from "./walmart-orders_old";
import WalmartOrders_new from "./walmart-orders_new";

export default function (props) {
  const history = useHistory();
  const contextVar = useContext(GlobalContext);

  return contextVar?.data?.marketplaceType === "amazon" ? (
    history.replace("/dashboard")
  ) : (
    // <WalmartOrders {...props} />
    <WalmartOrders_new {...props} />
  );
}
