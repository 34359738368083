import styled from "styled-components";

export const ReportScheduledWrapper = styled.div`
  // .ant-radio-button-wrapper {
  //   width: 100% !important;
  //   height: fit-content !important;
  //   padding: 0px !important;
  //   border: none !important;
  //   border-inline-start: none !important;
  //   margin: 10px 0px 0px 0px !important;
  // }
  // .ant-radio-button-wrapper:not(:first-child)::before {
  //   background-color: #d9d9d900 !important;
  // }

  // .ant-radio-group {
  //   width: 100% !important;
  // }
  // .ant-radio-wrapper {
  //   width: 100% !important;
  // }
  // .ant-radio-wrapper span:nth-child(1) {
  //   width: 5% !important;
  // }
  // .ant-radio-wrapper span:nth-child(2) {
  //   width: 94% !important;
  // }

  .ant-checkbox-inner {
    width: 23px !important;
    height: 23px !important;
  }
`;
