import { Input, Popover, Select, Switch, message } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import IvcsrTable from "../../../../../../component/ivcsr-table";
import { staticKeyword } from "../../../../../../config/static-select-option";
import { SearchOutlined, LoadingOutlined } from "@ant-design/icons";
const CreateKeyword = (props) => {
  const {
    WalmartGetCampaignKeywordList,
    fakeActionCampaignManagement,
    selectedKeyword,
    setSelectedKeyword,
    campaignId,
    adGroupId,
    selectedKeywordListView,
    setSelectedKeywordListView,
    WalmartCreateKeywordCampaign,
    keywordOperationLoading,
    setKeywordOperationLoading,
    keywordId,
    setKeywordId,
  } = props;
  const [selectedTab, setSelectedTab] = useState("suggested-keyword");
  const [walmartLoading, setWalmartLoading] = useState(true);
  const [KeywordList, setKeywordList] = useState([]);
  const [keywordName, setKeywordName] = useState("");
  const [keyworBid, setKeywordBid] = useState(0.75);
  const [keywordType, setKeywordType] = useState(null);

  const GetWalmartCampaignKeywordListRes = useSelector(
    (state) =>
      state.CampaignManagement.GetWalmartCampaignKeywordListResponse || {}
  );

  useEffect(() => {
    WalmartGetCampaignKeywordList(adGroupId);
  }, []);

  useEffect(() => {
    if (GetWalmartCampaignKeywordListRes.status === true) {
      const newList = GetWalmartCampaignKeywordListRes?.data?.map((d) => {
        const keyword_name = d?.query;
        const score = d?.score;

        const exact = {
          name: "Exact",
          sugg_bid: d?.suggestedBid,
          bid: d?.suggestedBidExact,
          state: "enabled",
          keywordId: d?.keywordId,
        };
        const phrase = {
          name: "Phrase",
          sugg_bid: d?.suggestedBid,
          bid: d?.suggestedBidPhrase,
          state: "enabled",
          keywordId: d?.keywordId,
        };
        const broad = {
          name: "Broad",
          sugg_bid: d?.suggestedBid,
          bid: d?.suggestedBidBroad,
          state: "enabled",
          keywordId: d?.keywordId,
        };
        return {
          keyword_name,
          score,
          exact,
          phrase,
          broad,
        };
      });
      //   setKeywordList(newList);
      setKeywordList(newList || []);
      setWalmartLoading(false);
      fakeActionCampaignManagement("GetWalmartCampaignKeywordListResponse");
    } else if (GetWalmartCampaignKeywordListRes.status === false) {
      message.destroy();
      message.error(
        GetWalmartCampaignKeywordListRes?.message || "Error in Get Keyword"
      );
      setWalmartLoading(false);
      fakeActionCampaignManagement("GetWalmartCampaignKeywordListResponse");
    }
  }, [GetWalmartCampaignKeywordListRes]);

  const updateKeywordBid = (key, value, newObj, text) => {
    try {
      setSelectedKeywordListView((prev) => {
        if (!prev || prev.length === 0) {
          // If previous state is empty, simply return a new array with newObj
          return [newObj];
        } else {
          // Check if the score already exists, if so, update suggestedBidBroad
          const updatedList = prev.map((d) => {
            if (d.score === text.score) {
              return {
                ...d,
                [key]: value,
              };
            } else {
              return d;
            }
          });

          // If newObj's score doesn't exist in the list, add it
          const existingScore = updatedList.find((d) => d.score === text.score);
          if (!existingScore) {
            updatedList.push(newObj);
          }

          return updatedList;
        }
      });
    } catch (error) {
      console.log(error, "error");
    }
  };

  {
    console.log(selectedKeywordListView, "selectedKeyword-------------");
  }
  const suggestedKeywordColumns = [
    {
      title: "Keywords",
      dataIndex: "keyword_name",
      key: "keyword_name",
      width: 200,
      render: (text) => {
        return (
          <Popover
            content={
              <div
                style={{
                  height: "max-content",
                  maxWidth: "400px",
                }}
              >
                {text}
              </div>
            }
            placement="bottom"
          >
            <span
              href=""
              className="text-primary text-hover-info mb-1 fw-bolder text-one fs-6  w-200px"
            >
              {text}
            </span>
          </Popover>
        );
      },
    },
    {
      title: "Match Type",
      width: 120,
      render: (text) => {
        return (
          <div>
            <div>{text?.exact?.name}</div>
            <div>{text?.broad?.name}</div>
            <div>{text?.phrase?.name}</div>
          </div>
        );
      },
    },
    {
      title: "Sugg. bid",
      width: 100,
      render: (text) => {
        return (
          <div>
            <div>
              <label className="fw-bold">${text?.exact?.sugg_bid || 0}</label>
            </div>
            <div>
              <label className="fw-bold">${text?.broad?.sugg_bid || 0}</label>
            </div>
            <div>
              <label className="fw-bold">${text?.phrase?.sugg_bid || 0}</label>
            </div>
          </div>
        );
      },
    },
    {
      title: "Bid",
      width: 80,
      render: (text) => {
        return (
          <div>
            <div>
              <label>${text?.exact?.bid}</label>
            </div>
            <div>
              <label>${text?.broad?.bid}</label>
            </div>
            <div>
              <label>${text?.phrase?.bid}</label>
            </div>
          </div>
        );
      },
    },
    {
      title: "Action",
      width: 80,
      render: (text) => {
        const isAdd = selectedKeywordListView?.filter(
          (d) => d.keyword_name === text.keyword_name
        );
        console.log(isAdd, "isAdd");
        return (
          <div>
            <div>
              <label
                className="text-primary"
                style={{
                  textDecoration: "underline",
                }}
                onClick={() => {
                  if (!isAdd?.[0]?.exact?.name) {
                    const newObj = {
                      keyword_name: text?.keyword_name,
                      score: text?.score,
                      keywordId: text?.keywordId,
                      exact: {
                        name: "Exact",
                        sugg_bid: text?.exact?.sugg_bid,
                        bid: text?.exact?.bid,
                        state: text?.exact?.state,
                      },
                      phrase: {},
                      broad: {},
                    };
                    message.destroy();
                    message.loading("Loading...", 0);
                    WalmartCreateKeywordCampaign(
                      {
                        keywords: [newObj],
                        adGroupId: adGroupId,
                        campaignId: campaignId,
                      },
                      "POST"
                    );
                  } else {
                    message.destroy("Keyword already added");
                  }
                }}
              >
                {!isAdd?.[0]?.exact?.name && "Add"}
              </label>
            </div>

            <div>
              <label
                className="text-primary"
                style={{
                  textDecoration: "underline",
                }}
                onClick={() => {
                  if (!isAdd?.[0]?.broad?.name) {
                    const newObj = {
                      keyword_name: text?.keyword_name,
                      score: text?.score,
                      keywordId: text?.keywordId,
                      exact: {},
                      phrase: {},
                      broad: {
                        name: "Broad",
                        sugg_bid: text?.broad?.sugg_bid,
                        bid: text?.broad?.bid,
                        state: text?.broad?.state,
                      },
                    };
                    message.destroy();
                    message.loading("Loading...", 0);
                    WalmartCreateKeywordCampaign(
                      {
                        keywords: [newObj],
                        adGroupId: adGroupId,
                        campaignId: campaignId,
                      },
                      "POST"
                    );
                  } else {
                    message.destroy("Keyword already added");
                  }
                }}
              >
                {!isAdd?.[0]?.broad?.name && "Add"}
              </label>
            </div>
            <div>
              <label
                className="text-primary"
                style={{
                  textDecoration: "underline",
                }}
                onClick={() => {
                  if (!isAdd?.[0]?.phrase?.name) {
                    const newObj = {
                      keyword_name: text?.keyword_name,
                      score: text?.score,
                      keywordId: text?.keywordId,
                      exact: {},
                      phrase: {
                        name: "Phrase",
                        sugg_bid: text?.phrase?.sugg_bid,
                        bid: text?.phrase?.bid,
                        state: text?.phrase?.state,
                      },
                      broad: {},
                    };
                    message.destroy();
                    message.loading("Loading...", 0);
                    WalmartCreateKeywordCampaign(
                      {
                        keywords: [newObj],
                        adGroupId: adGroupId,
                        campaignId: campaignId,
                      },
                      "POST"
                    );
                  } else {
                    message.destroy("Keyword already added");
                  }
                }}
              >
                {!isAdd?.[0]?.phrase?.name && "Add"}
              </label>
            </div>
          </div>
        );
      },
    },
  ];

  //  --------------------------------------------------------------------------------------------------------------------selected keyword columns
  const selectedKeywordColumns = [
    {
      title: "Keywords",
      dataIndex: "keyword_name",
      key: "keyword_name",
      width: 200,
      render: (text) => {
        return (
          <Popover
            content={
              <div
                style={{
                  height: "max-content",
                  maxWidth: "400px",
                }}
              >
                {text}
              </div>
            }
            placement="bottom"
          >
            <span
              href=""
              className="text-primary text-hover-info mb-1 fw-bolder text-one fs-6  w-200px"
            >
              {text}
            </span>
          </Popover>
        );
      },
    },
    {
      title: "Match Type",
      width: 120,
      render: (text) => {
        return (
          <div>
            {text?.exact?.name && (
              <div className="my-1">{text?.exact?.name}</div>
            )}
            {text?.broad?.name && (
              <div className="my-1">{text?.broad?.name}</div>
            )}
            {text?.phrase?.name && (
              <div className="my-1">{text?.phrase?.name}</div>
            )}
          </div>
        );
      },
    },
    {
      title: "Sugg. bid",
      width: 100,
      render: (text) => {
        return (
          <div>
            {text?.exact?.sugg_bid && (
              <div className="my-1">
                <label className="fw-bold">${text?.exact?.sugg_bid}</label>
              </div>
            )}
            {text?.broad?.sugg_bid && (
              <div className="my-1">
                <label className="fw-bold">${text?.broad?.sugg_bid}</label>
              </div>
            )}
            {text?.phrase?.sugg_bid && (
              <div className="my-1">
                <label className="fw-bold">${text?.phrase?.sugg_bid}</label>
              </div>
            )}
          </div>
        );
      },
    },
    {
      title: "Bid",
      width: 80,
      render: (text) => {
        return (
          <div>
            {text?.exact?.keywordId && (
              <div className="my-1 d-flex">
                {text?.exact?.keywordId === keywordId &&
                keywordOperationLoading === "exactBid" ? (
                  <LoadingOutlined style={{ fontSize: "23px" }} />
                ) : (
                  <>
                    $
                    <Input
                      size="small"
                      className="ms-1"
                      value={text?.exact?.bid}
                      onChange={(e) => {
                        setSelectedKeywordListView((prev) => {
                          return prev?.map((d) => {
                            if (
                              text?.exact?.keywordId === d?.exact?.keywordId
                            ) {
                              return {
                                ...d,
                                exact: {
                                  ...d?.exact,
                                  bid: e.target.value,
                                },
                              };
                            } else {
                              return d;
                            }
                          });
                        });
                      }}
                      onPressEnter={(e) => {
                        if (keywordOperationLoading !== "exactBid") {
                          setKeywordId(text?.exact?.keywordId);
                          setKeywordOperationLoading("exactBid");
                          const newObj = {
                            keywordId: text?.exact?.keywordId,
                            bid: e.target.value,
                            state: text?.exact?.state,
                          };
                          WalmartCreateKeywordCampaign(
                            [newObj],
                            "PUT",
                            campaignId,
                            adGroupId
                          );
                        }
                      }}
                    />
                  </>
                )}
              </div>
            )}
            {text?.broad?.keywordId && (
              <div className="my-1 d-flex">
                {text?.broad?.keywordId === keywordId &&
                keywordOperationLoading === "broadBid" ? (
                  <LoadingOutlined style={{ fontSize: "23px" }} />
                ) : (
                  <>
                    $
                    <Input
                      size="small"
                      className="ms-1"
                      value={text?.broad?.bid}
                      onChange={(e) => {
                        setSelectedKeywordListView((prev) => {
                          return prev?.map((d) => {
                            if (
                              text?.broad?.keywordId === d?.broad?.keywordId
                            ) {
                              return {
                                ...d,
                                broad: {
                                  ...d?.broad,
                                  bid: e.target.value,
                                },
                              };
                            } else {
                              return d;
                            }
                          });
                        });
                      }}
                      onPressEnter={(e) => {
                        if (keywordOperationLoading !== "broadBid") {
                          setKeywordId(text?.broad?.keywordId);
                          setKeywordOperationLoading("broadBid");
                          const newObj = {
                            keywordId: text?.broad?.keywordId,
                            bid: e.target.value,
                            state: text?.broad?.state,
                          };
                          WalmartCreateKeywordCampaign(
                            [newObj],
                            "PUT",
                            campaignId,
                            adGroupId
                          );
                        }
                      }}
                    />
                  </>
                )}
              </div>
            )}
            {text?.phrase?.keywordId && (
              <div className="my-1 d-flex">
                {text?.phrase?.keywordId === keywordId &&
                keywordOperationLoading === "phraseBid" ? (
                  <LoadingOutlined style={{ fontSize: "23px" }} />
                ) : (
                  <>
                    {" "}
                    $
                    <Input
                      size="small"
                      className="ms-1"
                      value={text?.phrase?.bid}
                      onChange={(e) => {
                        setSelectedKeywordListView((prev) => {
                          return prev?.map((d) => {
                            if (
                              text?.phrase?.keywordId === d?.phrase?.keywordId
                            ) {
                              return {
                                ...d,
                                phrase: {
                                  ...d?.phrase,
                                  bid: e.target.value,
                                },
                              };
                            } else {
                              return d;
                            }
                          });
                        });
                      }}
                      onPressEnter={(e) => {
                        if (keywordOperationLoading !== "phraseBid") {
                          setKeywordId(text?.phrase?.keywordId);
                          setKeywordOperationLoading("phraseBid");
                          const newObj = {
                            keywordId: text?.phrase?.keywordId,
                            bid: e.target.value,
                            state: text?.phrase?.state,
                          };
                          WalmartCreateKeywordCampaign(
                            [newObj],
                            "PUT",
                            campaignId,
                            adGroupId
                          );
                        }
                      }}
                    />
                  </>
                )}
              </div>
            )}
          </div>
        );
      },
    },
    {
      title: "Action",
      width: 80,
      render: (text) => {
        return (
          <div>
            {text?.exact?.name &&
              (text?.exact?.keywordId === keywordId &&
              keywordOperationLoading === "exact" ? (
                <LoadingOutlined style={{ fontSize: "23px" }} />
              ) : (
                <div className="my-1">
                  <Switch
                    checked={text?.exact?.state === "enabled" ? true : false}
                    onChange={(e) => {
                      setKeywordId(text?.exact?.keywordId);
                      setKeywordOperationLoading("exact");
                      const newObj = {
                        keywordId: text?.exact?.keywordId,
                        bid: text?.exact?.bid,
                        state: e ? "enabled" : "paused",
                      };
                      WalmartCreateKeywordCampaign(
                        [newObj],
                        "PUT",
                        campaignId,
                        adGroupId
                      );
                    }}
                  />
                </div>
              ))}
            {text?.broad?.name &&
              (text?.broad?.keywordId === keywordId &&
              keywordOperationLoading === "broad" ? (
                <LoadingOutlined style={{ fontSize: "23px" }} />
              ) : (
                <div className="my-1">
                  <Switch
                    checked={text?.broad?.state === "enabled" ? true : false}
                    onChange={(e) => {
                      setKeywordId(text?.broad?.keywordId);
                      setKeywordOperationLoading("broad");
                      const newObj = {
                        keywordId: text?.broad?.keywordId,
                        bid: text?.broad?.bid,
                        state: e ? "enabled" : "paused",
                      };
                      WalmartCreateKeywordCampaign(
                        [newObj],
                        "PUT",
                        campaignId,
                        adGroupId
                      );
                    }}
                  />
                </div>
              ))}
            {text?.phrase?.name &&
              (text?.phrase?.keywordId === keywordId &&
              keywordOperationLoading === "phrase" ? (
                <LoadingOutlined style={{ fontSize: "23px" }} />
              ) : (
                <div className="my-1">
                  <Switch
                    checked={text?.phrase?.state === "enabled" ? true : false}
                    onChange={(e) => {
                      setKeywordId(text?.phrase?.keywordId);
                      setKeywordOperationLoading("phrase");
                      const newObj = {
                        keywordId: text?.phrase?.keywordId,
                        bid: text?.phrase?.bid,
                        state: e ? "enabled" : "paused",
                      };
                      WalmartCreateKeywordCampaign(
                        [newObj],
                        "PUT",
                        campaignId,
                        adGroupId
                      );
                    }}
                  />
                </div>
              ))}
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className="row ">
        <div className="col-xl-12">
          <div className="card card-flush h-lg-100 mb-5 mb-xl-5">
            <div className="card-header border-0 cursor-pointer">
              <div className="card-title m-0">
                <h3 className="fw-bold m-0">Keywords</h3>
              </div>

              <div className="card-toolbar">
                {/* Ad Group Name: <b>-</b> */}
              </div>
            </div>

            <div className="card-body border-top border-top-dashed pb-2 pt-6">
              <div className="row mb-0 g-5">
                <div className="col-md-12">
                  <div className="d-flex justify-content-between align-items-center mb-5">
                    <div className="mb-0 fw-bold fs-5">
                      <ul className="nav nav-tabs nav-line-tabs mb-5 fs-6">
                        <li
                          className="nav-item"
                          onClick={() => {
                            setSelectedTab("suggested-keyword");
                          }}
                        >
                          <span
                            className={`nav-link fw-bold text-dark px-4 ${
                              selectedTab === "suggested-keyword" && "active"
                            }`}
                            data-bs-toggle="tab"
                          >
                            Suggested Keywords
                          </span>
                        </li>
                        <li
                          className="nav-item"
                          onClick={() => {
                            setSelectedTab("additional-keyword");
                          }}
                        >
                          <span
                            className={`nav-link fw-bold text-dark px-4 ${
                              selectedTab === "additional-keyword" && "active"
                            }`}
                            data-bs-toggle="tab"
                          >
                            Additional Keywords
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div className=" d-flex flex-wrap align-items-center">
                      <div className="position-relative ">
                        <i className="ki-outline ki-magnifier text-gray-800 fs-2 position-absolute top-50 translate-middle-y ms-4" />
                        <input
                          type="text"
                          data-kt-table-widget-4="search"
                          className="form-control form-control-solid text-dark border-info  px fs-7 ps-12 all-search"
                          name="all_search"
                          placeholder="Search by Keyword"
                        />
                      </div>

                      <button className="btn btn-icon btn-color-gray-500 btn-active-color-primary justify-content-end">
                        <i className="bi bi-file-earmark-arrow-down fs-1 text-gray-700 ms-3" />
                      </button>
                    </div>
                  </div>
                  <div className="row gy-5 g-xl-5">
                    <div className="col-xxl-6">
                      <div
                        className="card card-xxl-stretch mb-5 mb-xl-8"
                        style={{ boxShadow: "none" }}
                      >
                        <div className="card-header border-bottom-dashed">
                          <h3 className="card-title fw-bold">
                            {selectedTab === "suggested-keyword"
                              ? "List of Keywords"
                              : "Enter Keyword"}
                          </h3>
                          <div className="card-toolbar"></div>
                        </div>

                        <div className="card-body py-2 px-5">
                          {selectedTab === "suggested-keyword" && (
                            <div className="table-responsive">
                              <IvcsrTable
                                columns={suggestedKeywordColumns}
                                fixed={true}
                                sticky={{
                                  offsetHeader: "10px",
                                }}
                                dataSource={KeywordList}
                                rowKey="key"
                                loading={walmartLoading}
                                pagination={false}
                                scroll={{ x: "max-content", y: 500 }}
                              />
                              {/* <table className="table table-row-dashed table-row-gray-300 align-middle gs-4 gy-4">
                                <thead>
                                  <tr className="fw-bolder text-dark bg-light-info">
                                    <th className>Keywords</th>
                                    <th className=" ">Match Type</th>
                                    <th className=" ">Sugg. bid</th>
                                  </tr>
                                </thead>

                                <tbody>
                                  <tr>
                                    <td>
                                      <div className="d-flex flex-column">
                                        <span className="fs-7 fw-bolder text-dark">
                                          Lorem Ipsum
                                        </span>
                                      </div>
                                    </td>
                                    <td>Broad</td>
                                    <td>
                                      <div className="input-group input-group-sm w-75px">
                                        <span
                                          className="input-group-text bg-white"
                                          id="basic-addon1"
                                        >
                                          $
                                        </span>
                                        <input
                                          type="text"
                                          className="form-control fs-7  border-start-0"
                                          placeholder
                                          defaultValue="0.75"
                                          aria-label
                                          aria-describedby="basic-addon1"
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table> */}
                            </div>
                          )}

                          {selectedTab === "additional-keyword" && (
                            <div className="row mt-5">
                              <div className="col-4">
                                <Input
                                  size="large"
                                  onChange={(e) => {
                                    setKeywordName(e.target.value);
                                  }}
                                  value={keywordName}
                                  className="ant_common_input"
                                  placeholder="Enter Keyword Name"
                                />
                              </div>
                              <div className="col-4">
                                <Select
                                  style={{ width: "100%" }}
                                  size="large"
                                  options={[
                                    { label: "Broad", value: "broad" },
                                    { label: "Exact", value: "exact" },
                                    { label: "Phrase", value: "phrase" },
                                  ]}
                                  onChange={(e) => {
                                    setKeywordType(e);
                                  }}
                                  placeholder="Enter Keyword Type"
                                  value={keywordType}
                                  allowClear
                                />
                              </div>
                              <div className="col-2">
                                <Input
                                  size="large"
                                  className="ant_common_input"
                                  placeholder="Bid"
                                  value={keyworBid}
                                  onBlur={(e) => {
                                    if (!e.target.value) {
                                      setKeywordBid(e.target.value);
                                    }
                                  }}
                                  onChange={(e) => {
                                    let value = e.target.value;
                                    const isNumeric =
                                      /^[0-9]*(\.[0-9]*)?$/.test(value);
                                    if (isNumeric || value === "") {
                                      setKeywordBid(value);
                                    }
                                  }}
                                />
                              </div>
                              <div className="col-2">
                                <button
                                  className="btn btn-primary"
                                  onClick={() => {
                                    const checkDuplicateKeyword =
                                      selectedKeywordListView?.filter(
                                        (d) =>
                                          d?.keyword_name === keywordName &&
                                          Object.entries(d?.[keywordType])
                                            ?.length > 0
                                      );
                                    if (checkDuplicateKeyword?.length > 0) {
                                      message.destroy();
                                      message.info("Keyword must be unique...");
                                      return;
                                    }

                                    const newObj = {
                                      keyword_name: keywordName,
                                      keywordId: "",
                                      exact:
                                        keywordType === "exact"
                                          ? {
                                              name: "Exact",
                                              sugg_bid: "",
                                              bid: keyworBid,
                                              state: "enabled",
                                            }
                                          : {},
                                      phrase:
                                        keywordType === "phrase"
                                          ? {
                                              name: "Phrase",
                                              sugg_bid: "",
                                              bid: keyworBid,
                                              state: "enabled",
                                            }
                                          : {},
                                      broad:
                                        keywordType === "broad"
                                          ? {
                                              name: "Broad",
                                              sugg_bid: "",
                                              bid: keyworBid,
                                              state: "enabled",
                                            }
                                          : {},
                                    };
                                    message.destroy();
                                    message.loading("Loading...", 0);
                                    WalmartCreateKeywordCampaign(
                                      {
                                        keywords: [newObj],
                                        adGroupId: adGroupId,
                                        campaignId: campaignId,
                                      },
                                      "POST"
                                    );
                                  }}
                                >
                                  Add
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="col-xxl-6">
                      <div
                        className="card card-xxl-stretch mb-5 mb-xl-8"
                        style={{ boxShadow: "none" }}
                      >
                        <div className="card-header border-bottom-dashed">
                          <h3 className="card-title fw-bold">
                            Selected Keywords
                          </h3>
                          <div className="card-toolbar"></div>
                        </div>

                        <div className="card-body py-2 px-5">
                          <div className="table-responsive">
                            <IvcsrTable
                              columns={selectedKeywordColumns}
                              fixed={true}
                              sticky={{
                                offsetHeader: "10px",
                              }}
                              dataSource={selectedKeywordListView}
                              rowKey="key"
                              loading={false}
                              pagination={false}
                              scroll={{ x: "max-content", y: 500 }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateKeyword;
