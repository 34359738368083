import React from "react";
import { NumberWithCommas } from "../../../../config";
import { Skeleton } from "antd";

const DashboardCard = (props) => {
  const { salesData, salesLoading, selectedCurrencySign } = props;
  return (
    <>
      <div
        className="col-md-6 col-xl-3 mb-xxl-1"
        // style={{ filter: salesLoading ? "blur(6px)" : "blur(0px)" }}
      >
        {/*begin::Card widget 8*/}
        {salesLoading ? (
          <Skeleton.Button
            block
            active
            style={{ height: "71px" }}
            className="mb-5"
          />
        ) : (
          <div
            className="card overflow-hidden  mb-5 mb-xl-5"
            style={{
              border: "2px solid #4318ff",
              // backgroundImage: "linear-gradient(269deg, #c7bdff, transparent)",
            }}
          >
            {/*begin::Card body*/}
            <div className="card-body d-flex justify-content-between flex-column px-0 pb-0 pt-4">
              <div className="d-flex px-10 pb-4">
                {/*begin::Statistics*/}
                <div className="symbol symbol-35px me-5 bd3 d-flex align-items-center">
                  <span className="symbol-label bg-primary">
                    <i className="ki-outline ki-dollar fs-2x text-gray-100" />
                  </span>
                </div>
                <div className="mb-0 ">
                  {/*begin::Info*/}
                  <div className="d-flex align-items-center mb-2 ">
                    {/*begin::Currency*/}
                    <span className="fs-4 fw-semibold text-gray-400 align-self-start me-1 lh-1">
                      {selectedCurrencySign}
                    </span>
                    {/*end::Currency*/}
                    {/*begin::Value*/}
                    <span className="fs-4 fw-bolder text-gray-800 me-2 lh-1">
                      {NumberWithCommas(salesData?.total_sales || 0)}
                    </span>
                    {/*end::Value*/}
                  </div>
                  {/*end::Info*/}
                  {/*begin::Description*/}
                  <span className="fs-6 fw-semibold text-gray-600 d-flex align-items-center">
                    Total Sales
                    {/*begin::Label*/}
                    {/* <span class="badge badge-light text-info ms-2 fs-base bdr">
                              <i class="ki-outline ki-arrow-up fs-7 text-primary ms-n1"></i>2.2%</span> */}
                    {/*end::Label*/}
                  </span>
                  {/*end::Description*/}
                </div>
              </div>
              {/*end::Statistics*/}
              {/*begin::Chart*/}

              {/*end::Chart*/}
            </div>
            {/*end::Card body*/}
          </div>
        )}

        {/*end::Card widget 8*/}
      </div>
      <div
        className="col-md-6 col-xl-3 mb-xxl-1"
        // style={{ filter: salesLoading ? "blur(6px)" : "blur(0px)" }}
      >
        {" "}
        {salesLoading ? (
          <Skeleton.Button
            block
            active
            style={{ height: "71px" }}
            className="mb-5"
          />
        ) : (
          <div
            className="card overflow-hidden  mb-5 mb-xl-5"
            style={{
              border: "2px solid #171717",
              // backgroundImage: "linear-gradient(269deg, #c6c8cf, transparent)",
            }}
          >
            {/*begin::Card body*/}
            <div className="card-body d-flex justify-content-between flex-column px-0 pb-0 pt-4">
              <div className="d-flex px-10 pb-4">
                {/*begin::Statistics*/}
                <div className="symbol symbol-35px me-3  d-flex align-items-center">
                  <span className="symbol-label bg-dark">
                    <i className="ki-outline ki-delivery-2 fs-2x text-gray-100" />{" "}
                  </span>
                </div>
                <div className="mb-0 ">
                  {/*begin::Info*/}
                  <div className="d-flex align-items-center mb-2">
                    {/*begin::Currency*/}
                    {/* <span class="fs-4 fw-semibold text-gray-400 align-self-start me-1&gt;">{selectedCurrencySign}</span> */}
                    {/*end::Currency*/}
                    {/*begin::Value*/}
                    <span className="fs-4 fw-bolder text-gray-800 me-2 lh-1">
                      {NumberWithCommas(salesData?.order_units || 0)}
                    </span>
                    {/*end::Value*/}
                    {/*begin::Label*/}
                    {/* <span class="badge badge-light-info fs-base">
                            <i class="ki-outline ki-arrow-up fs-5 text-info ms-n1"></i>2.2%</span> */}
                    {/*end::Label*/}
                  </div>
                  {/*end::Info*/}
                  {/*begin::Description*/}
                  <span className="fs-6 fw-semibold text-gray-600">
                    Total Units Sold
                  </span>
                  {/*end::Description*/}
                </div>
              </div>
              {/*end::Statistics*/}
              {/*begin::Chart*/}

              {/*end::Chart*/}
            </div>
            {/*end::Card body*/}
          </div>
        )}
      </div>
      <div
        className="col-md-6 col-xl-3 mb-xxl-1"
        // style={{ filter: salesLoading ? "blur(6px)" : "blur(0px)" }}
      >
        {" "}
        {salesLoading ? (
          <Skeleton.Button
            block
            active
            style={{ height: "71px" }}
            className="mb-5"
          />
        ) : (
          <div
            className="card overflow-hidden mb-5 mb-xl-5"
            style={{
              border: "2px solid #47a672",
              // backgroundImage: "linear-gradient(269deg, #aee3ca, transparent)",
            }}
          >
            {/*begin::Card body*/}
            <div className="card-body d-flex justify-content-between flex-column px-0 pb-0 pt-4">
              <div className="d-flex px-10 pb-4">
                {/*begin::Statistics*/}
                <div className="symbol symbol-35px me-3  d-flex align-items-center">
                  <span className="symbol-label bg-gren">
                    <i className="ki-outline ki-tag fs-2x text-gray-100" />{" "}
                  </span>
                </div>
                <div className="mb-0 ">
                  {/*begin::Statistics*/}
                  <div className="d-flex align-items-center mb-2">
                    <span className="fs-4 fw-semibold text-gray-400 align-self-start me-1 lh-1">
                      {selectedCurrencySign}
                    </span>
                    {/*begin::Value*/}
                    <span className="fs-4 fw-bolder text-gray-800 me-2 lh-1">
                      {NumberWithCommas(salesData?.net_profit || 0)}
                    </span>
                    {/*end::Value*/}
                    {/*begin::Label*/}
                    {/* <span class="badge badge-light-info fs-base">
                            <i class="ki-outline ki-arrow-up fs-5 text-info ms-n1"></i>2.6%</span> */}
                    {/*end::Label*/}
                  </div>
                  {/*end::Statistics*/}
                  {/*begin::Description*/}
                  <span className="fs-6 fw-semibold text-gray-600">
                    Total Net Profit
                  </span>
                  {/*end::Description*/}
                </div>
              </div>
              {/*end::Statistics*/}
              {/*begin::Chart*/}

              {/*end::Chart*/}
            </div>
            {/*end::Card body*/}
          </div>
        )}
      </div>
      <div
        className="col-md-6 col-xl-3 mb-xxl-1"
        // style={{ filter: salesLoading ? "blur(6px)" : "blur(0px)" }}
      >
        {" "}
        {salesLoading ? (
          <Skeleton.Button
            block
            active
            style={{ height: "71px" }}
            className="mb-5"
          />
        ) : (
          <div
            className="card overflow-hidden mb-5 mb-xl-5"
            style={{
              border: "2px solid #f1416c",
              // backgroundImage: "linear-gradient(269deg, #f8acbd, transparent)",
            }}
          >
            {/*begin::Card body*/}
            <div className="card-body d-flex justify-content-between flex-column px-0 pb-0 pt-4">
              <div className="d-flex px-10 pb-4">
                {/*begin::Statistics*/}
                <div className="symbol symbol-35px me-3  d-flex align-items-center">
                  <span className="symbol-label bg-danger">
                    <i className="ki-outline ki-update-file fs-2x text-gray-100" />{" "}
                  </span>
                </div>
                <div className="mb-0 ">
                  {/*begin::Statistics*/}
                  <div className="d-flex align-items-center mb-2">
                    <span className="fs-4 fw-semibold text-gray-400 align-self-start me-1 lh-1">
                      {selectedCurrencySign}
                    </span>
                    {/*begin::Value*/}
                    <span className="fs-4 fw-bolder text-gray-800 me-2 lh-1">
                      {NumberWithCommas("-" + salesData?.refund_revenue || 0)}
                    </span>
                    {/*end::Value*/}
                    {/*begin::Label*/}
                    {/* <span class="badge badge-light-info fs-base">
                            <i class="ki-outline ki-arrow-up fs-5 text-info ms-n1"></i>2.6%</span> */}
                    {/*end::Label*/}
                  </div>
                  {/*end::Statistics*/}
                  {/*begin::Description*/}
                  <span className="fs-6 fw-semibold text-gray-600">
                    Total Refunds
                  </span>
                  {/*end::Description*/}
                </div>
                {/*end::Statistics*/}
              </div>
              {/*begin::Chart*/}

              {/*end::Chart*/}
            </div>
            {/*end::Card body*/}
          </div>
        )}
      </div>
    </>
  );
};

export default DashboardCard;
