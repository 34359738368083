import axiosCall from "../../configurations/network-services/axiosCall";

// const prefix = "dashboard";

export const GetWalmartInsightsReportList = (data) => {
  const path = `wm/snapshot/insights`;
  const responseType = "GET_WALMART_INSIGHTS_REPORT";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetWalmartPerformanceData = (data) => {
  const path = `wm/snapshot/performance-data?start_date=${
    data?.start_date || ""
  }&end_date=${data?.end_date || ""}&performance_type=${
    data?.performance_type || ""
  }&attributed_days=${data?.attributed_days || ""}&page=1&per-page=25`;
  const responseType = "GET_WALMART_PERFORMANCE_DATA";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetWalmartAdvanceReportSummaryData = (data) => {
  const path = `wm/snapshot/summary-data?start_date=${
    data?.start_date || ""
  }&end_date=${data?.end_date || ""}&performance_type=${
    data?.performance_type || ""
  }&attributed_days=${data?.attributed_days || ""}`;
  const responseType = "GET_WALMART_ADVANCE_REPORT_SUMMARY_DATA";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetWalmartAdvanceReportChartData = (data) => {
  const path = `wm/snapshot/chart-data?start_date=${
    data?.start_date || ""
  }&end_date=${data?.end_date || ""}&performance_type=${
    data?.performance_type || ""
  }&attributed_days=${data?.attributed_days || ""}`;
  const responseType = "GET_WALMART_ADVANCE_REPORT_CHART_DATA";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const fakeActionWalmartAdvanceReports = (data) => async (dispatch) => {
  dispatch({ type: "FAKE_ACTION_WALMART_ADVANCE_REPORT", state: data });
};
