import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CriteriaAnalytics from "../../modules/pages/criteria-analysis_new";

import {
  CriteriaAnalysisAdminAction,
  EditCriteriaAnalysisAdminAction,
  fakeActionCriteriaAnalytics,
} from "../../redux/modules/criteria-analytics/index.action";

const mapStateToProps = (state) => ({
  GetCriteriaAnalyticsListResponse:
    state.CriteriaAnalytics.GetCriteriaAnalyticsListResponse,
  EditCriteriaAnalysisResponse:
    state.CriteriaAnalytics.EditCriteriaAnalysisResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      CriteriaAnalysisAdminAction,
      EditCriteriaAnalysisAdminAction,
      fakeActionCriteriaAnalytics,
    },
    dispatch
  );

const CriteriaAnalyticsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CriteriaAnalytics);

export default CriteriaAnalyticsContainer;
