import React, { useContext, useEffect } from "react";
import { GlobalContext } from "../../../../commonContext";
import { useHistory } from "react-router-dom";
import WalmartFeedItems from "./walmart-feed-items";

export default function (props) {
  const history = useHistory();
  const contextVar = useContext(GlobalContext);
  return contextVar?.data?.marketplaceType === "amazon" ? (
    history.replace("/dashboard")
  ) : (
    <WalmartFeedItems {...props} />
  );
}
