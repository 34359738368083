import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  DateFormat,
  bidManagementValidKeyName,
  returnBlankKeyFromObj,
  urlDecode,
} from "../../../../../config";
import {
  Checkbox,
  DatePicker,
  Input,
  Radio,
  Select,
  Skeleton,
  Tag,
  TimePicker,
  Tooltip,
  message,
} from "antd";
import { MailOutlined } from "@ant-design/icons";
import {
  daysList,
  monthsList,
  weekList,
} from "../../../../../config/static-select-option";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import moment from "moment";
import { Redirect } from "react-router-dom";
const BidRuleDetails = (props) => {
  const {
    GetWalmartBidRuleDetails,
    GetWalmartCreateRuleAttribute,
    GetWalmartBidRuleCampaignList,
    GetWalmartBidRuleAdGroupList,
    CreateWalmartRuleAction,
    fakeActionWalmartBidManagement,
  } = props;

  const [bidLoading, setBidLoading] = useState(true);
  const [error_data, set_error_data] = useState({});
  const [rule_id, set_rule_id] = useState("");
  const [actionRadioValue, setActionRadioValue] = useState("notification");
  const [actionTypeDropDown, setActionTypeDropDown] = useState([]);
  const [rule_details, set_rule_details] = useState({});
  const [isRedirect, setIsRedirect] = useState(false);
  const [bidSaving, setBidSaving] = useState(false);
  const [isIndefinite, setIsIndefinite] = useState(false);
  const [dropDownLoading, setDropDownLoading] = useState(true);
  const [dropDownData, setDropDownData] = useState({});
  const [campaign_list, set_campaign_list] = useState([]);
  const [selected_campaign_list_for_api, set_selected_campaign_list_for_api] =
    useState([]);
  const [selected_campaign_for_dd, set_selected_campaign_for_dd] = useState([]);
  const [selected_ad_group_list_for_api, set_selected_ad_group_list_for_api] =
    useState([]);
  const [selected_ad_group_for_dd, set_selected_ad_group_for_dd] = useState([]);
  const [ad_group_list, set_ad_group_list] = useState([]);
  const [singleNotificationEmail, setSingleNotificationEmail] = useState("");

  const [compare_value, set_compare_value] = useState([
    {
      attribute: null,
      operator: null,
      value: 0,
    },
  ]);
  const [rule_value, set_rule_value] = useState({
    rule_name: "",
    action_type: null,
    notification_type: null,
    budget_type: null,
    rule_status: "INACTIVE",
    start_date: dayjs(),
    end_date: dayjs().add(30, "d"),
    apply_rule_type: null,
    apply_rule_attribute: null,
    notifications_email: [],
    measurement_type: null,
    measurement_value: "",
    budget_measurement: [
      {
        budget_type: "total",
        measurement_type: "percentage",
        measurement_value: "",
        max_budget: "",
      },
      {
        budget_type: "daily",
        measurement_type: "percentage",
        measurement_value: "",
        max_budget: "",
      },
    ],
    min_max_measurement_value: "",
    look_back_period: null,
    type: null,
    day: [],
    weekly: [],
    frequency_hours: null,
    start_time: "12:00",
    end_time: "12:00",
  });
  const [weekObj, setWeekObj] = useState([]);
  const location = useLocation();
  const GetWalmartBidRuleDetailsRes = useSelector(
    (state) => state.WalmartBidManagement.GetWalmartBidRuleDetailsResponse || {}
  );
  const GetWalmartCreateRuleAttributeRes = useSelector(
    (state) =>
      state.WalmartBidManagement.GetWalmartCreateRuleAttributeResponse || {}
  );
  const GetWalmartBidRuleCampaignListRes = useSelector(
    (state) =>
      state.WalmartBidManagement.GetWalmartBidRuleCampaignListResponse || {}
  );
  const GetWalmartBidRuleAgGroupListRes = useSelector(
    (state) =>
      state.WalmartBidManagement.GetWalmartBidRuleAgGroupListResponse || {}
  );
  const CreateWalmartRuleActionRes = useSelector(
    (state) => state.WalmartBidManagement.CreateWalmartRuleActionResponse || {}
  );

  const selectRef = useRef(null);
  useEffect(() => {
    const handleScroll = () => {
      selectRef.current.blur();
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const newUrl = urlDecode(location);
    console.log(newUrl, "newUrl");
    if (newUrl?.ruleId) {
      set_rule_id(newUrl?.ruleId);
      GetWalmartBidRuleDetails(newUrl?.ruleId);
    }
  }, [location]);

  useEffect(() => {
    const newUrl = urlDecode(location);
    GetWalmartCreateRuleAttribute();
    GetWalmartBidRuleCampaignList();
    GetWalmartBidRuleAdGroupList();
    // GetWalmartCreateRuleAttribute({ id: newUrl?.poid, is_all: 0 });
  }, []);

  useEffect(() => {
    updateCommonRuleDetails(
      "end_time",
      dayjs(rule_value.start_time, "HH:mm").add(1, "hour").format("HH:mm")
    );
  }, [rule_value?.start_time]);

  useEffect(() => {
    if (GetWalmartBidRuleDetailsRes?.status === true) {
      try {
        set_rule_details(GetWalmartBidRuleDetailsRes?.data);
        set_rule_id(GetWalmartBidRuleDetailsRes?.data?.id);

        const newData = JSON.parse(
          GetWalmartBidRuleDetailsRes?.data?.campaign_list
        )?.map((d) => Object.keys(d)?.[0]);
        set_selected_campaign_for_dd(newData);
        set_selected_campaign_list_for_api(
          JSON.parse(GetWalmartBidRuleDetailsRes?.data?.campaign_list)?.map(
            (d) => {
              console.log(d, "-d");
              return {
                label: Object.values(d)?.[0],
                value: Object.keys(d)?.[0],
              };
            }
          )
        );

        const newAdGroup = JSON.parse(
          GetWalmartBidRuleDetailsRes?.data?.ad_group_list
        )?.map((d) => Object.keys(d)?.[0]);
        set_selected_ad_group_for_dd(newAdGroup);
        set_selected_ad_group_list_for_api(
          JSON.parse(GetWalmartBidRuleDetailsRes?.data?.ad_group_list)?.map(
            (d) => {
              console.log(d, "-d");
              return {
                label: Object.values(d)?.[0],
                value: Object.keys(d)?.[0],
              };
            }
          )
        );
        if (GetWalmartBidRuleDetailsRes?.data?.criteria) {
          set_compare_value(
            JSON.parse(GetWalmartBidRuleDetailsRes?.data?.criteria)
          );
        } else {
          set_compare_value([
            {
              attribute: null,
              operator: null,
              value: 0,
            },
          ]);
        }
        setActionRadioValue(GetWalmartBidRuleDetailsRes?.data?.rule_type);
        const newFreq = JSON.parse(
          GetWalmartBidRuleDetailsRes?.data?.time_periods
        )?.frequency;
        setWeekObj(newFreq?.day_name);
        const budget_type = GetWalmartBidRuleDetailsRes?.data?.budget_type;
        const bid_measurement_type =
          GetWalmartBidRuleDetailsRes?.data?.bid_measurement_type ||
          JSON.parse(GetWalmartBidRuleDetailsRes?.data?.budget_measurement)?.[0]
            ?.measurement_type;
        const measurement_value =
          GetWalmartBidRuleDetailsRes?.data?.bid_measurement_value ||
          JSON.parse(GetWalmartBidRuleDetailsRes?.data?.budget_measurement)?.[0]
            ?.measurement_value;
        const min_max_measurement_value =
          GetWalmartBidRuleDetailsRes?.data?.max_bid ||
          GetWalmartBidRuleDetailsRes?.data?.min_bid ||
          JSON.parse(GetWalmartBidRuleDetailsRes?.data?.budget_measurement)?.[0]
            ?.max_budget ||
          JSON.parse(GetWalmartBidRuleDetailsRes?.data?.budget_measurement)?.[0]
            ?.min_budget;

        JSON.parse(GetWalmartBidRuleDetailsRes?.data?.time_periods)?.end_date
          ? setIsIndefinite(false)
          : setIsIndefinite(true);

        set_rule_value({
          ...rule_value,
          ...GetWalmartBidRuleDetailsRes?.data,
          budget_type: budget_type,
          measurement_type: bid_measurement_type,
          measurement_value: measurement_value,
          min_max_measurement_value: min_max_measurement_value,
          type: newFreq?.type,
          day: newFreq?.type === "once" ? newFreq?.day : newFreq?.days,
          weekly: newFreq?.day_name?.map((d) => {
            return d?.d_name;
          }),
          frequency_hours: newFreq?.frequency_hours || null,
          start_time: newFreq?.start_time || "12:00",
          end_time: newFreq?.end_time || "12:00",
          look_back_period: JSON.parse(
            GetWalmartBidRuleDetailsRes?.data?.time_periods
          )?.look_back_period,
          start_date: dayjs(
            JSON.parse(GetWalmartBidRuleDetailsRes?.data?.time_periods)
              ?.start_date
          ),
          end_date: dayjs(
            JSON.parse(GetWalmartBidRuleDetailsRes?.data?.time_periods)
              ?.end_date
          ),
          budget_measurement: JSON.parse(
            GetWalmartBidRuleDetailsRes?.data?.budget_measurement
          ),
          notifications_email:
            GetWalmartBidRuleDetailsRes?.data?.notifications_email?.split(","),
        });
      } catch (error) {
        console.log(error, "try_error");
      }
      fakeActionWalmartBidManagement("GetWalmartBidRuleDetailsResponse");
    } else if (GetWalmartBidRuleDetailsRes?.status === false) {
      fakeActionWalmartBidManagement("GetWalmartBidRuleDetailsResponse");
    }
  }, [GetWalmartBidRuleDetailsRes]);

  useEffect(() => {
    if (GetWalmartCreateRuleAttributeRes?.status === true) {
      setDropDownLoading(false);
      setDropDownData(GetWalmartCreateRuleAttributeRes?.data || {});
      fakeActionWalmartBidManagement("GetWalmartCreateRuleAttributeResponse");
    } else if (GetWalmartCreateRuleAttributeRes?.status === false) {
      setDropDownLoading(false);
      fakeActionWalmartBidManagement("GetWalmartCreateRuleAttributeResponse");
    }
  }, [GetWalmartCreateRuleAttributeRes]);

  useEffect(() => {
    if (GetWalmartBidRuleCampaignListRes?.status === true) {
      set_campaign_list(GetWalmartBidRuleCampaignListRes?.data?.records || {});
      fakeActionWalmartBidManagement("GetWalmartBidRuleCampaignListResponse");
    } else if (GetWalmartBidRuleCampaignListRes?.status === false) {
      fakeActionWalmartBidManagement("GetWalmartBidRuleCampaignListResponse");
    }
  }, [GetWalmartBidRuleCampaignListRes]);

  useEffect(() => {
    if (GetWalmartBidRuleAgGroupListRes?.status === true) {
      set_ad_group_list(GetWalmartBidRuleAgGroupListRes?.data?.records || {});
      fakeActionWalmartBidManagement("GetWalmartBidRuleAgGroupListResponse");
    } else if (GetWalmartBidRuleAgGroupListRes?.status === false) {
      fakeActionWalmartBidManagement("GetWalmartBidRuleAgGroupListResponse");
    }
  }, [GetWalmartBidRuleAgGroupListRes]);

  useEffect(() => {
    if (CreateWalmartRuleActionRes?.status === true) {
      message.destroy();
      message.success(CreateWalmartRuleActionRes?.message);
      setBidSaving(false);
      fakeActionWalmartBidManagement("CreateWalmartRuleActionResponse");
      setIsRedirect(true);
    } else if (CreateWalmartRuleActionRes?.status === false) {
      const newErr = Object.entries(CreateWalmartRuleActionRes?.data)?.map(
        (d) => {
          return d?.[1]?.[0];
        }
      );
      message.destroy();
      message.error(
        newErr?.map((d) => {
          return <div>{d}</div>;
        })
      );
      setBidSaving(false);
      set_error_data(CreateWalmartRuleActionRes?.data);
      fakeActionWalmartBidManagement("CreateWalmartRuleActionResponse");
    }
  }, [CreateWalmartRuleActionRes]);

  useEffect(() => {
    if (rule_id) {
      if (
        Object.entries(rule_details)?.length > 0 &&
        Object.entries(dropDownData)?.length > 0
      ) {
        const result = Object.entries(dropDownData?.action_type)
          .filter(([key, value]) => key.includes(rule_details?.rule_type))
          .map(([key, value]) => ({ key, value }));
        setActionTypeDropDown(result);
        setBidLoading(false);
      }
    } else if (Object.entries(dropDownData)?.length > 0) {
      const data = [
        {
          key: "notification_only",
          value: "Notification Only(Default)",
        },
      ];
      setActionTypeDropDown(data);
      setBidLoading(false);
    }
  }, [rule_details, dropDownData]);

  const addCompareValueRow = () => {
    const newData = {
      attribute: null,
      operator: null,
      value: 0,
    };

    const updatedValue = [...compare_value];
    updatedValue.push(newData);
    set_compare_value(updatedValue);
  };

  const updateCompareValue = (index, key, value) => {
    const updatedValue = [...compare_value];
    updatedValue[index][key] = value;
    set_compare_value(updatedValue);
  };

  const removeCompareValueRow = (index) => {
    const updatedValue = [...compare_value];
    updatedValue.splice(index, 1);
    set_compare_value(updatedValue);
  };

  const updateCommonRuleDetails = (key, value) => {
    // if (key === "action_type") {
    //   const {
    //     notification_type,
    //     budget_type,
    //     apply_rule_type,
    //     apply_rule_attribute,
    //     ...rest
    //   } = rule_value;
    //   const common_data = {
    //     ...rest,
    //     notification_type: null,
    //     budget_type: null,
    //     apply_rule_type: null,
    //     apply_rule_attribute: null,
    //     [key]: value,
    //   };
    //   set_selected_ad_group_for_dd([]);
    //   set_selected_ad_group_list_for_api([]);
    //   set_selected_campaign_for_dd([]);
    //   set_selected_campaign_list_for_api([]);
    //   set_rule_value(common_data);
    // } else {
    const common_data = {
      ...rule_value,
      [key]: value,
    };
    set_rule_value(common_data);
    // }
  };

  const SaveBidRule = () => {
    if (
      rule_value?.action_type === "notification_only" &&
      rule_value?.notification_type === "out_of_budget"
    ) {
      Save_Out_Of_Budget_NotificationRule();
    } else if (
      rule_value?.action_type === "notification_only" &&
      rule_value?.notification_type === "custom"
    ) {
      Save_Custom_NotificationRule();
    } else if (
      rule_value?.action_type === "increase_budget" &&
      rule_value?.budget_type === "daily"
    ) {
      Save_Increase_Budget_With_Daily();
    } else if (
      rule_value?.action_type === "increase_budget" &&
      rule_value?.budget_type === "total"
    ) {
      Save_Increase_Budget_With_Total();
    } else if (
      rule_value?.action_type === "decrease_budget" &&
      rule_value?.budget_type === "daily"
    ) {
      Save_Decrease_Budget_With_Daily();
    } else if (
      rule_value?.action_type === "decrease_budget" &&
      rule_value?.budget_type === "total"
    ) {
      Save_Decrease_Budget_With_Total();
    } else if (
      rule_value?.action_type === "increase_budget" &&
      rule_value?.budget_type === "daily_and_total"
    ) {
      Save_Increase_Budget_With_Daily_Total();
    } else if (
      rule_value?.action_type === "decrease_budget" &&
      rule_value?.budget_type === "daily_and_total"
    ) {
      Save_Decrease_Budget_With_Daily_Total();
    } else if (rule_value?.action_type === "increase_bid") {
      Save_Increase_Bid();
    } else if (rule_value?.action_type === "decrease_bid") {
      Save_Decrease_Bid();
    } else {
      setBidSaving(false);
    }
  };

  const Save_Out_Of_Budget_NotificationRule = () => {
    const newJson = {
      marketplace_id: "ATVPDKIKX0DER",
      rule_name: rule_value?.rule_name,
      rule_type: actionRadioValue,
      action_type: rule_value?.action_type,
      notification_type: rule_value?.notification_type,
      rule_status: rule_value?.rule_status,
      apply_rule_type: rule_value?.apply_rule_type,
      apply_rule_attribute: rule_value?.apply_rule_attribute,
      campaign_list: selected_campaign_list_for_api?.map((d) => {
        return { [d?.value]: d?.label };
      }),
      time_periods: {
        start_date: moment(rule_value?.start_date?.$d)?.format("YYYY-MM-DD"),
        end_date: isIndefinite
          ? ""
          : moment(rule_value?.end_date?.$d)?.format("YYYY-MM-DD"),
        frequency: {
          type: "daily",
          frequency_hours: "4",
          start_time: "00:00",
          end_time: "23:59",
        },
      },
      notifications_email: rule_value?.notifications_email?.join(", "),
    };
    const blankKey = returnBlankKeyFromObj(newJson);
    if (blankKey) {
      message.destroy();
      message.info(`Please Enter ${bidManagementValidKeyName(blankKey?.[0])}`);
      setBidSaving(false);
    } else {
      message.destroy();
      message.loading("Loading...", 0);
      CreateWalmartRuleAction(newJson, rule_id);
    }
  };

  const Save_Custom_NotificationRule = () => {
    const newJson = {
      marketplace_id: "ATVPDKIKX0DER",
      rule_name: rule_value?.rule_name,
      rule_type: actionRadioValue,
      action_type: rule_value?.action_type,
      notification_type: rule_value?.notification_type,
      rule_status: rule_value?.rule_status,
      apply_rule_type: rule_value?.apply_rule_type,
      apply_rule_attribute: rule_value?.apply_rule_attribute,
      campaign_list: selected_campaign_list_for_api?.map((d) => {
        return { [d?.value]: d?.label };
      }),
      criteria: compare_value?.map((d) => {
        return {
          attribute: d?.attribute,
          operator: d?.operator,
          value: d?.value,
        };
      }),
      time_periods: {
        start_date: moment(rule_value?.start_date?.$d)?.format("YYYY-MM-DD"),
        end_date: isIndefinite
          ? ""
          : moment(rule_value?.end_date?.$d)?.format("YYYY-MM-DD"),
        frequency: {
          type: rule_value?.type,
          ...(rule_value?.type === "daily"
            ? ""
            : {
                [rule_value?.type === "weekly"
                  ? "day_name"
                  : rule_value?.type === "monthly"
                  ? "days"
                  : "day"]:
                  rule_value?.type === "weekly"
                    ? weekObj
                    : rule_value?.type === "once"
                    ? rule_value?.day
                    : rule_value?.day,
              }),
          frequency_hours: rule_value?.frequency_hours,
          start_time: rule_value?.start_time,
          end_time: rule_value?.end_time,
        },
        look_back_period: rule_value?.look_back_period,
      },
      notifications_email: rule_value?.notifications_email?.join(", "),
    };
    const blankKey = returnBlankKeyFromObj(newJson);
    if (blankKey) {
      message.destroy();
      message.info(`Please Enter ${bidManagementValidKeyName(blankKey?.[0])}`);
      setBidSaving(false);
    } else {
      message.destroy();
      message.loading("Loading...", 0);
      CreateWalmartRuleAction(newJson, rule_id);
    }
  };

  const Save_Increase_Budget_With_Daily = () => {
    const newJson = {
      marketplace_id: "ATVPDKIKX0DER",
      rule_name: rule_value?.rule_name,
      rule_type: actionRadioValue,
      action_type: "increase_budget",
      budget_type: "daily",
      budget_measurement: [
        {
          budget_type: "daily",
          measurement_type: rule_value?.measurement_type,
          measurement_value: rule_value?.measurement_value,
          max_budget: rule_value?.min_max_measurement_value,
        },
      ],
      rule_status: rule_value?.rule_status,
      apply_rule_type: rule_value?.apply_rule_type,
      apply_rule_attribute: rule_value?.apply_rule_attribute,
      campaign_list: selected_campaign_list_for_api?.map((d) => {
        return { [d?.value]: d?.label };
      }),
      criteria: compare_value?.map((d) => {
        return {
          attribute: d?.attribute,
          operator: d?.operator,
          value: d?.value,
        };
      }),
      time_periods: {
        start_date: moment(rule_value?.start_date?.$d)?.format("YYYY-MM-DD"),
        end_date: isIndefinite
          ? ""
          : moment(rule_value?.end_date?.$d)?.format("YYYY-MM-DD"),
        frequency: {
          type: rule_value?.type,
          ...(rule_value?.type === "daily"
            ? ""
            : {
                [rule_value?.type === "weekly"
                  ? "day_name"
                  : rule_value?.type === "monthly"
                  ? "days"
                  : "day"]:
                  rule_value?.type === "weekly"
                    ? weekObj
                    : rule_value?.type === "once"
                    ? rule_value?.day
                    : rule_value?.day,
              }),
          frequency_hours: rule_value?.frequency_hours,
          start_time: rule_value?.start_time,
          end_time: rule_value?.end_time,
        },
        look_back_period: rule_value?.look_back_period,
      },
      notifications_email: rule_value?.notifications_email?.join(", "),
    };

    console.log(newJson, "-New_Json");
    const blankKey = returnBlankKeyFromObj(newJson);
    if (blankKey) {
      message.destroy();
      message.info(`Please Enter ${bidManagementValidKeyName(blankKey?.[0])}`);
      setBidSaving(false);
    } else {
      message.destroy();
      message.loading("Loading...", 0);
      CreateWalmartRuleAction(newJson, rule_id);
    }
  };

  const Save_Increase_Budget_With_Total = () => {
    const newJson = {
      marketplace_id: "ATVPDKIKX0DER",
      rule_name: rule_value?.rule_name,
      rule_type: actionRadioValue,
      action_type: "increase_budget",
      budget_type: "total",
      budget_measurement: [
        {
          budget_type: "total",
          measurement_type: rule_value?.measurement_type,
          measurement_value: rule_value?.measurement_value,
          max_budget: rule_value?.min_max_measurement_value,
        },
      ],
      rule_status: rule_value?.rule_status,
      apply_rule_type: rule_value?.apply_rule_type,
      apply_rule_attribute: rule_value?.apply_rule_attribute,
      campaign_list: selected_campaign_list_for_api?.map((d) => {
        return { [d?.value]: d?.label };
      }),
      criteria: compare_value?.map((d) => {
        return {
          attribute: d?.attribute,
          operator: d?.operator,
          value: d?.value,
        };
      }),
      time_periods: {
        start_date: moment(rule_value?.start_date?.$d)?.format("YYYY-MM-DD"),
        end_date: isIndefinite
          ? ""
          : moment(rule_value?.end_date?.$d)?.format("YYYY-MM-DD"),
        frequency: {
          type: rule_value?.type,
          ...(rule_value?.type === "daily"
            ? ""
            : {
                [rule_value?.type === "weekly"
                  ? "day_name"
                  : rule_value?.type === "monthly"
                  ? "days"
                  : "day"]:
                  rule_value?.type === "weekly"
                    ? weekObj
                    : rule_value?.type === "once"
                    ? rule_value?.day
                    : rule_value?.day,
              }),
          frequency_hours: rule_value?.frequency_hours,
          start_time: rule_value?.start_time,
          end_time: rule_value?.end_time,
        },
        look_back_period: rule_value?.look_back_period,
      },
      notifications_email: rule_value?.notifications_email?.join(", "),
    };

    console.log(newJson, "-New_Json");
    const blankKey = returnBlankKeyFromObj(newJson);
    if (blankKey) {
      message.destroy();
      message.info(`Please Enter ${bidManagementValidKeyName(blankKey?.[0])}`);
      setBidSaving(false);
    } else {
      message.destroy();
      message.loading("Loading...", 0);
      CreateWalmartRuleAction(newJson, rule_id);
    }
  };

  const Save_Decrease_Budget_With_Daily = () => {
    const newJson = {
      marketplace_id: "ATVPDKIKX0DER",
      rule_name: rule_value?.rule_name,
      rule_type: actionRadioValue,
      action_type: "decrease_budget",
      budget_type: "daily",
      budget_measurement: [
        {
          budget_type: "daily",
          measurement_type: rule_value?.measurement_type,
          measurement_value: rule_value?.measurement_value,
          min_budget: rule_value?.min_max_measurement_value,
        },
      ],
      rule_status: rule_value?.rule_status,
      apply_rule_type: rule_value?.apply_rule_type,
      apply_rule_attribute: rule_value?.apply_rule_attribute,
      campaign_list: selected_campaign_list_for_api?.map((d) => {
        return { [d?.value]: d?.label };
      }),
      criteria: compare_value?.map((d) => {
        return {
          attribute: d?.attribute,
          operator: d?.operator,
          value: d?.value,
        };
      }),
      time_periods: {
        start_date: moment(rule_value?.start_date?.$d)?.format("YYYY-MM-DD"),
        end_date: isIndefinite
          ? ""
          : moment(rule_value?.end_date?.$d)?.format("YYYY-MM-DD"),
        frequency: {
          type: rule_value?.type,
          ...(rule_value?.type === "daily"
            ? ""
            : {
                [rule_value?.type === "weekly"
                  ? "day_name"
                  : rule_value?.type === "monthly"
                  ? "days"
                  : "day"]:
                  rule_value?.type === "weekly"
                    ? weekObj
                    : rule_value?.type === "once"
                    ? rule_value?.day
                    : rule_value?.day,
              }),
          frequency_hours: rule_value?.frequency_hours,
          start_time: rule_value?.start_time,
          end_time: rule_value?.end_time,
        },
        look_back_period: rule_value?.look_back_period,
      },
      notifications_email: rule_value?.notifications_email?.join(", "),
    };

    console.log(newJson, "-New_Json");
    const blankKey = returnBlankKeyFromObj(newJson);
    if (blankKey) {
      message.destroy();
      message.info(`Please Enter ${bidManagementValidKeyName(blankKey?.[0])}`);
      setBidSaving(false);
    } else {
      message.destroy();
      message.loading("Loading...", 0);
      CreateWalmartRuleAction(newJson, rule_id);
    }
  };

  const Save_Decrease_Budget_With_Total = () => {
    const newJson = {
      marketplace_id: "ATVPDKIKX0DER",
      rule_name: rule_value?.rule_name,
      rule_type: actionRadioValue,
      action_type: "decrease_budget",
      budget_type: "total",
      budget_measurement: [
        {
          budget_type: "total",
          measurement_type: rule_value?.measurement_type,
          measurement_value: rule_value?.measurement_value,
          min_budget: rule_value?.min_max_measurement_value,
        },
      ],
      rule_status: rule_value?.rule_status,
      apply_rule_type: rule_value?.apply_rule_type,
      apply_rule_attribute: rule_value?.apply_rule_attribute,
      campaign_list: selected_campaign_list_for_api?.map((d) => {
        return { [d?.value]: d?.label };
      }),
      criteria: compare_value?.map((d) => {
        return {
          attribute: d?.attribute,
          operator: d?.operator,
          value: d?.value,
        };
      }),
      time_periods: {
        start_date: moment(rule_value?.start_date?.$d)?.format("YYYY-MM-DD"),
        end_date: isIndefinite
          ? ""
          : moment(rule_value?.end_date?.$d)?.format("YYYY-MM-DD"),
        frequency: {
          type: rule_value?.type,
          ...(rule_value?.type === "daily"
            ? ""
            : {
                [rule_value?.type === "weekly"
                  ? "day_name"
                  : rule_value?.type === "monthly"
                  ? "days"
                  : "day"]:
                  rule_value?.type === "weekly"
                    ? weekObj
                    : rule_value?.type === "once"
                    ? rule_value?.day
                    : rule_value?.day,
              }),
          frequency_hours: rule_value?.frequency_hours,
          start_time: rule_value?.start_time,
          end_time: rule_value?.end_time,
        },
        look_back_period: rule_value?.look_back_period,
      },
      notifications_email: rule_value?.notifications_email?.join(", "),
    };

    const blankKey = returnBlankKeyFromObj(newJson);
    if (blankKey) {
      message.destroy();
      message.info(`Please enter ${bidManagementValidKeyName(blankKey?.[0])}`);
      setBidSaving(false);
    } else {
      message.destroy();
      message.loading("Loading...", 0);
      CreateWalmartRuleAction(newJson, rule_id);
    }
  };

  const Save_Increase_Budget_With_Daily_Total = () => {
    const newJson = {
      marketplace_id: "ATVPDKIKX0DER",
      rule_name: rule_value?.rule_name,
      rule_type: actionRadioValue,
      action_type: "increase_budget",
      budget_type: "daily_and_total",
      budget_measurement: rule_value?.budget_measurement,
      rule_status: rule_value?.rule_status,
      apply_rule_type: rule_value?.apply_rule_type,
      apply_rule_attribute: rule_value?.apply_rule_attribute,
      campaign_list: selected_campaign_list_for_api?.map((d) => {
        return { [d?.value]: d?.label };
      }),
      criteria: compare_value?.map((d) => {
        return {
          attribute: d?.attribute,
          operator: d?.operator,
          value: d?.value,
        };
      }),
      time_periods: {
        start_date: moment(rule_value?.start_date?.$d)?.format("YYYY-MM-DD"),
        end_date: isIndefinite
          ? ""
          : moment(rule_value?.end_date?.$d)?.format("YYYY-MM-DD"),
        frequency: {
          type: rule_value?.type,
          ...(rule_value?.type === "daily"
            ? ""
            : {
                [rule_value?.type === "weekly"
                  ? "day_name"
                  : rule_value?.type === "monthly"
                  ? "days"
                  : "day"]:
                  rule_value?.type === "weekly"
                    ? weekObj
                    : rule_value?.type === "once"
                    ? rule_value?.day
                    : rule_value?.day,
              }),
          frequency_hours: rule_value?.frequency_hours,
          start_time: rule_value?.start_time,
          end_time: rule_value?.end_time,
        },
        look_back_period: rule_value?.look_back_period,
      },
      notifications_email: rule_value?.notifications_email?.join(", "),
    };

    console.log(newJson, "-New_Json");
    const blankKey = returnBlankKeyFromObj(newJson);
    if (blankKey) {
      message.destroy();
      message.info(`Please Enter ${bidManagementValidKeyName(blankKey?.[0])}`);
      setBidSaving(false);
    } else {
      message.destroy();
      message.loading("Loading...", 0);
      CreateWalmartRuleAction(newJson, rule_id);
    }
  };

  const Save_Decrease_Budget_With_Daily_Total = () => {
    const newJson = {
      marketplace_id: "ATVPDKIKX0DER",
      rule_name: rule_value?.rule_name,
      rule_type: actionRadioValue,
      action_type: "decrease_budget",
      budget_type: "daily_and_total",
      budget_measurement: rule_value?.budget_measurement,
      rule_status: rule_value?.rule_status,
      apply_rule_type: rule_value?.apply_rule_type,
      apply_rule_attribute: rule_value?.apply_rule_attribute,
      campaign_list: selected_campaign_list_for_api?.map((d) => {
        return { [d?.value]: d?.label };
      }),
      criteria: compare_value?.map((d) => {
        return {
          attribute: d?.attribute,
          operator: d?.operator,
          value: d?.value,
        };
      }),
      time_periods: {
        start_date: moment(rule_value?.start_date?.$d)?.format("YYYY-MM-DD"),
        end_date: isIndefinite
          ? ""
          : moment(rule_value?.end_date?.$d)?.format("YYYY-MM-DD"),
        frequency: {
          type: rule_value?.type,
          ...(rule_value?.type === "daily"
            ? ""
            : {
                [rule_value?.type === "weekly"
                  ? "day_name"
                  : rule_value?.type === "monthly"
                  ? "days"
                  : "day"]:
                  rule_value?.type === "weekly"
                    ? weekObj
                    : rule_value?.type === "once"
                    ? rule_value?.day
                    : rule_value?.day,
              }),
          frequency_hours: rule_value?.frequency_hours,
          start_time: rule_value?.start_time,
          end_time: rule_value?.end_time,
        },
        look_back_period: rule_value?.look_back_period,
      },
      notifications_email: rule_value?.notifications_email?.join(", "),
    };

    console.log(newJson, "-New_Json");
    const blankKey = returnBlankKeyFromObj(newJson);
    if (blankKey) {
      message.destroy();
      message.info(`Please Enter ${bidManagementValidKeyName(blankKey?.[0])}`);
      setBidSaving(false);
    } else {
      message.destroy();
      message.loading("Loading...", 0);
      CreateWalmartRuleAction(newJson, rule_id);
    }
  };

  const Save_Increase_Bid = () => {
    const newJson = {
      marketplace_id: "ATVPDKIKX0DER",
      rule_name: rule_value?.rule_name,
      rule_type: actionRadioValue,
      action_type: rule_value?.action_type,
      bid_measurement_type: rule_value?.measurement_type,
      bid_measurement_value: rule_value?.measurement_value,
      max_bid: rule_value?.min_max_measurement_value,
      rule_status: rule_value?.rule_status,
      apply_rule_type: rule_value?.apply_rule_type,
      apply_rule_attribute: rule_value?.apply_rule_attribute,
      ad_group_list: selected_ad_group_list_for_api?.map((d) => {
        return { [d?.value]: d?.label };
      }),
      criteria: compare_value?.map((d) => {
        return {
          attribute: d?.attribute,
          operator: d?.operator,
          value: d?.value,
        };
      }),
      time_periods: {
        start_date: moment(rule_value?.start_date?.$d)?.format("YYYY-MM-DD"),
        end_date: isIndefinite
          ? ""
          : moment(rule_value?.end_date?.$d)?.format("YYYY-MM-DD"),
        frequency: {
          type: rule_value?.type,
          ...(rule_value?.type === "daily"
            ? ""
            : {
                [rule_value?.type === "weekly"
                  ? "day_name"
                  : rule_value?.type === "monthly"
                  ? "days"
                  : "day"]:
                  rule_value?.type === "weekly"
                    ? weekObj
                    : rule_value?.type === "once"
                    ? rule_value?.day
                    : rule_value?.day,
              }),
          frequency_hours: rule_value?.frequency_hours,
          start_time: rule_value?.start_time,
          end_time: rule_value?.end_time,
        },
        look_back_period: rule_value?.look_back_period,
      },
      notifications_email: rule_value?.notifications_email?.join(", "),
    };

    const blankKey = returnBlankKeyFromObj(newJson);
    if (blankKey) {
      message.destroy();
      message.info(`Please Enter ${bidManagementValidKeyName(blankKey?.[0])}`);
      setBidSaving(false);
    } else {
      message.destroy();
      message.loading("Loading...", 0);
      CreateWalmartRuleAction(newJson, rule_id);
    }
  };

  const Save_Decrease_Bid = () => {
    const newJson = {
      marketplace_id: "ATVPDKIKX0DER",
      rule_name: rule_value?.rule_name,
      rule_type: actionRadioValue,
      action_type: rule_value?.action_type,
      bid_measurement_type: rule_value?.measurement_type,
      bid_measurement_value: rule_value?.measurement_value,
      min_bid: rule_value?.min_max_measurement_value,
      rule_status: rule_value?.rule_status,
      apply_rule_type: rule_value?.apply_rule_type,
      apply_rule_attribute: rule_value?.apply_rule_attribute,
      ad_group_list: selected_ad_group_list_for_api?.map((d) => {
        return { [d?.value]: d?.label };
      }),
      criteria: compare_value?.map((d) => {
        return {
          attribute: d?.attribute,
          operator: d?.operator,
          value: d?.value,
        };
      }),
      time_periods: {
        start_date: moment(rule_value?.start_date?.$d)?.format("YYYY-MM-DD"),
        end_date: isIndefinite
          ? ""
          : moment(rule_value?.end_date?.$d)?.format("YYYY-MM-DD"),
        frequency: {
          type: rule_value?.type,
          ...(rule_value?.type === "daily"
            ? ""
            : {
                [rule_value?.type === "weekly"
                  ? "day_name"
                  : rule_value?.type === "monthly"
                  ? "days"
                  : "day"]:
                  rule_value?.type === "weekly"
                    ? weekObj
                    : rule_value?.type === "once"
                    ? rule_value?.day
                    : rule_value?.day,
              }),
          frequency_hours: rule_value?.frequency_hours,
          start_time: rule_value?.start_time,
          end_time: rule_value?.end_time,
        },
        look_back_period: rule_value?.look_back_period,
      },
      notifications_email: rule_value?.notifications_email?.join(", "),
    };

    const blankKey = returnBlankKeyFromObj(newJson);
    if (blankKey) {
      message.destroy();
      message.info(`Please Enter ${bidManagementValidKeyName(blankKey?.[0])}`);
      setBidSaving(false);
    } else {
      message.destroy();
      message.loading("Loading...", 0);
      CreateWalmartRuleAction(newJson, rule_id);
    }
  };

  if (isRedirect) {
    return <Redirect to="/advertisement/rule-management" />;
  }

  const disableEndDate = (current) => {
    // Disable dates before startDate and dates before today
    return (
      (rule_value?.start_date &&
        current &&
        current.isBefore(rule_value?.start_date, "day")) ||
      (current && current.isBefore(moment().startOf("day"))) // disabled all date before current date
    );
  };

  const disableStartDate = (current) => {
    // Disable dates after endDate and dates before today
    return (
      (rule_value?.end_date &&
        current &&
        current.isAfter(rule_value?.end_date, "day")) ||
      (current && current.isBefore(moment().startOf("day"))) // disabled all date before current date
    );
  };

  return (
    <>
      <div className="d-flex flex-column flex-column-fluid fadeOutBottom">
        <div id="kt_app_content" className="app-content flex-column-fluid">
          <div
            id="kt_app_content_container"
            className="app-container container-fluid"
          >
            {bidLoading ? (
              <div className="row">
                {" "}
                <div className="col-xxl-12">
                  <div className="card card-xxl-stretch mb-5 mb-xl-8">
                    <div className="card-header border-bottom-dashed">
                      <div className="d-flex flex-stack flex-wrap">
                        <div className="position-relative">
                          <h3 className="card-title ">
                            <span className="card-label fw-bold text-gray-900">
                              Rule
                            </span>
                          </h3>
                        </div>
                      </div>
                      <div className="card-toolbar"></div>
                    </div>

                    <div className="card-body">
                      <div className="row">
                        <div className="col-3">
                          <Skeleton.Button active size="large" block />
                        </div>
                      </div>
                      <div className="row my-10">
                        <div className="col-3">
                          <Skeleton.Button active size="large" block />
                        </div>
                        <div className="col-3">
                          <Skeleton.Button active size="large" block />
                        </div>
                        <div className="col-3">
                          <Skeleton.Button active size="large" block />
                        </div>
                        <div className="col-3">
                          <Skeleton.Button active size="large" block />
                        </div>
                      </div>
                      <div className="row my-10">
                        <div className="col-3">
                          <Skeleton.Button active size="large" block />
                        </div>{" "}
                        <div className="col-3">
                          <Skeleton.Button active size="large" block />
                        </div>
                      </div>

                      <div className="row my-10">
                        <div className="col-3">
                          <Skeleton.Button active size="large" block />
                        </div>
                        <div className="col-3">
                          <Skeleton.Button active size="large" block />
                        </div>{" "}
                        <div className="col-3">
                          <Skeleton.Button active size="large" block />
                        </div>
                      </div>
                      <div className="row my-10">
                        <div className="col-3">
                          <Skeleton.Button active size="large" block />
                        </div>
                        <div className="col-3">
                          <Skeleton.Button active size="large" block />
                        </div>{" "}
                        <div className="col-3">
                          <Skeleton.Button active size="large" block />
                        </div>
                      </div>
                      <div className="row my-10">
                        <div className="col-3">
                          <Skeleton.Button active size="large" block />
                        </div>{" "}
                        <div className="col-3">
                          <Skeleton.Button active size="large" block />
                        </div>
                      </div>
                      <div className="row my-10">
                        <div className="col-12">
                          <Skeleton.Button active size="large" block />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="row gy-5 g-xl-5">
                <div className="col-xxl-12">
                  <div className="card card-xxl-stretch mb-5 mb-xl-8">
                    <div className="card-header border-bottom-dashed">
                      <div className="d-flex flex-stack flex-wrap">
                        <div className="position-relative">
                          <h3 className="card-title ">
                            <span className="card-label fw-bold text-gray-900">
                              Rule
                            </span>
                          </h3>
                        </div>
                      </div>
                      <div className="card-toolbar "></div>
                    </div>

                    <div className="card-body">
                      <div
                        className="box-rule p-5 rounded border border-dashed border-gray-300"
                        style={{
                          background: "#fcfbff",
                          boxShadow: "0px 0px 6px -1px #4318ff5c",
                        }}
                      >
                        <div className="p-4 px-0 mb-4">
                          <h5 className="mb-0 required">Rule Details</h5>
                        </div>
                        <div className="">
                          <div className="form-group">
                            <label htmlFor="" className="fw-bolder mb-1">
                              Rule Name
                            </label>
                            <Input
                              placeholder="Enter rule name"
                              size="large"
                              className="ant_common_input"
                              value={rule_value?.rule_name}
                              onChange={(e) => {
                                updateCommonRuleDetails(
                                  "rule_name",
                                  e.target.value
                                );
                              }}
                            />
                            {/* {error_data?.rule_name?.length > 0 && (
                            <label className="fw-bold mb-1 text-danger">
                              {error_data?.rule_name?.[0]}
                            </label>
                          )} */}
                          </div>
                        </div>
                      </div>
                      {/* <div
                      className="my-5"
                      style={{ borderBottom: "1px dashed #000000" }}
                    ></div> */}
                      <div
                        className="box-rule p-5 rounded border border-dashed border-gray-300 mt-10"
                        style={{
                          background: "#fcfbff",
                          boxShadow: "0px 0px 10px -1px #4318ff5c",
                        }}
                      >
                        {/* ---------------------------------------------------------------- Choosen Action  Start */}
                        <div className="p-4 px-0 mb-4">
                          <h5 className="mb-0 required">Choose Action</h5>
                        </div>
                        <div className="row g-5">
                          <div className="col-12">
                            <Radio.Group
                              onChange={(e) => {
                                const selectedVal = e.target.value;
                                setActionRadioValue(e.target.value);

                                const result = Object.entries(
                                  dropDownData?.action_type
                                )
                                  .filter(([key, value]) =>
                                    key.includes(selectedVal)
                                  )
                                  .map(([key, value]) => ({ key, value }));
                                setActionTypeDropDown(result);
                                const {
                                  notification_type,
                                  budget_type,
                                  apply_rule_type,
                                  apply_rule_attribute,
                                  ...rest
                                } = rule_value;
                                const common_data = {
                                  ...rest,
                                  notification_type: null,
                                  budget_type: null,
                                  apply_rule_type: null,
                                  apply_rule_attribute: null,
                                  action_type: null,
                                };
                                set_selected_ad_group_for_dd([]);
                                set_selected_ad_group_list_for_api([]);
                                set_selected_campaign_for_dd([]);
                                set_selected_campaign_list_for_api([]);
                                set_rule_value(common_data);
                              }}
                              value={actionRadioValue}
                              disabled={rule_id}
                            >
                              <Radio value={"bid"}>Bid</Radio>
                              <Radio value={"budget"}>Budget</Radio>
                              <Radio value={"notification"}>Notification</Radio>
                            </Radio.Group>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <label htmlFor="" className="fw-bolder mb-1">
                                Action Type
                              </label>
                              <Select
                                ref={selectRef}
                                loading={dropDownLoading}
                                options={actionTypeDropDown?.map((d) => {
                                  return { label: d?.value, value: d?.key };
                                })}
                                size="large"
                                placeholder="Select"
                                value={rule_value?.action_type}
                                onChange={(e) => {
                                  updateCommonRuleDetails("action_type", e);
                                }}
                                // onChange={(e) => {
                                //   setActionBudgetType(null);
                                //   set_notification_type(null);
                                //   setActionType(e);
                                // }}
                                style={{ width: "100%" }}
                              />
                            </div>
                          </div>

                          {console.log(rule_value, "-----rule_value")}
                          {rule_value?.action_type === "notification_only" && (
                            <div className="col-md-3">
                              <div className="form-group">
                                <label htmlFor="" className="fw-bolder mb-1">
                                  Notification Type
                                </label>
                                <Select
                                  ref={selectRef}
                                  options={[
                                    {
                                      label: "Out of Budget",
                                      value: "out_of_budget",
                                    },
                                    {
                                      label: "Custom",
                                      value: "custom",
                                    },
                                  ]}
                                  size="large"
                                  placeholder="Select"
                                  value={rule_value?.notification_type}
                                  onChange={(e) => {
                                    updateCommonRuleDetails(
                                      "notification_type",
                                      e
                                    );
                                  }}
                                  style={{ width: "100%" }}
                                />
                              </div>
                            </div>
                          )}
                          {rule_value?.action_type === "increase_budget" ||
                          rule_value?.action_type === "decrease_budget" ? (
                            <div className="col-md-3">
                              <div className="form-group">
                                <label htmlFor="" className="fw-bolder mb-1">
                                  Budget Type
                                </label>
                                <Select
                                  ref={selectRef}
                                  options={[
                                    {
                                      label: "Daily Budget",
                                      value: "daily",
                                    },
                                    {
                                      label: "Total Budget",
                                      value: "total",
                                    },
                                    {
                                      label: "Daily & Total Budget",
                                      value: "daily_and_total",
                                    },
                                  ]}
                                  size="large"
                                  placeholder="Select"
                                  value={rule_value?.budget_type}
                                  style={{ width: "100%" }}
                                  onChange={(e) => {
                                    updateCommonRuleDetails("budget_type", e);
                                  }}
                                />{" "}
                              </div>
                            </div>
                          ) : (
                            ""
                          )}

                          {rule_value?.action_type === "increase_bid" ||
                          rule_value?.action_type === "decrease_bid" ||
                          rule_value?.budget_type === "daily" ||
                          rule_value?.budget_type === "total" ? (
                            <>
                              <div className="col-2">
                                <div className="form-group">
                                  <label htmlFor="" className="fw-bolder mb-1">
                                    Measurement
                                  </label>
                                  <Select
                                    ref={selectRef}
                                    options={[
                                      {
                                        label: "$",
                                        value: "amount",
                                      },
                                      {
                                        label: "%",
                                        value: "percentage",
                                      },
                                    ]}
                                    size="large"
                                    placeholder="Select"
                                    value={rule_value?.measurement_type}
                                    onChange={(e) => {
                                      updateCommonRuleDetails(
                                        "measurement_type",
                                        e
                                      );
                                    }}
                                    style={{ width: "100%" }}
                                  />
                                </div>
                              </div>
                              <div className="col-2">
                                <div className="form-group">
                                  <label htmlFor="" className="fw-bolder mb-1">
                                    Value
                                  </label>
                                  <Input
                                    placeholder="Enter Value"
                                    size="large"
                                    className="ant_common_input"
                                    value={rule_value?.measurement_value}
                                    onChange={(e) => {
                                      updateCommonRuleDetails(
                                        "measurement_value",
                                        e.target.value
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-2">
                                <div className="form-group">
                                  <label htmlFor="" className="fw-bolder mb-1">
                                    {rule_value?.action_type.includes(
                                      "increase"
                                    )
                                      ? "Max"
                                      : "Min"}
                                    &nbsp;
                                    {rule_value?.action_type.includes("bid")
                                      ? "Bid"
                                      : "Budget"}
                                  </label>

                                  <div className="input-group input-group-solid mb-0">
                                    <Input
                                      placeholder="Enter Value"
                                      size="large"
                                      className="ant_common_input"
                                      value={
                                        rule_value?.min_max_measurement_value
                                      }
                                      onChange={(e) => {
                                        updateCommonRuleDetails(
                                          "min_max_measurement_value",
                                          e.target.value
                                        );
                                      }}
                                      prefix={
                                        <label
                                          style={{ fontSize: "15px" }}
                                          className="me-2"
                                        >
                                          $
                                        </label>
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                        {/* ---------------------------------------------------------------- Choosen Action  End */}
                        {/* ---------------------------------------------------------------- Budget Div Start */}
                        {(rule_value?.action_type === "increase_budget" ||
                          rule_value?.action_type === "decrease_budget") &&
                        rule_value?.budget_type === "daily_and_total" ? (
                          <>
                            <div className="separator separator-dashed borde-gray-300 mt-4 mb-4"></div>
                            {/* budget_type: "total",
                              measurement_type: "percentage",
                              measurement_value: "",
                              max_budget: "", */}
                            {rule_value?.budget_measurement?.map((d, i) => {
                              return (
                                <div className="row g-5 mb-5 align-items-center ">
                                  <div className="col-md-3">
                                    <label
                                      htmlFor=""
                                      className="fw-bolder mt-9 fs-6"
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      {d?.budget_type} Budget
                                    </label>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="form-group">
                                      <label
                                        htmlFor=""
                                        className="fw-bolder mb-1"
                                      >
                                        Measurement
                                      </label>
                                      <Select
                                        ref={selectRef}
                                        options={[
                                          {
                                            label: "$",
                                            value: "amount",
                                          },
                                          {
                                            label: "%",
                                            value: "percentage",
                                          },
                                        ]}
                                        size="large"
                                        placeholder="Select"
                                        value={d?.measurement_type}
                                        style={{ width: "100%" }}
                                        onChange={(e) => {
                                          const updatedValue = [
                                            ...rule_value?.budget_measurement,
                                          ];
                                          updatedValue[i]["measurement_type"] =
                                            e;
                                          set_rule_value({
                                            ...rule_value,
                                            budget_measurement: updatedValue,
                                          });
                                        }}
                                      />
                                      {console.log(
                                        rule_value,
                                        "---rule_value Test"
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="form-group">
                                      <label
                                        htmlFor=""
                                        className="fw-bolder mb-1"
                                      >
                                        Value
                                      </label>
                                      <Input
                                        placeholder="Enter Value"
                                        size="large"
                                        className="ant_common_input"
                                        value={d?.measurement_value}
                                        onChange={(e) => {
                                          const updatedValue = [
                                            ...rule_value?.budget_measurement,
                                          ];
                                          updatedValue[i]["measurement_value"] =
                                            e.target.value;
                                          set_rule_value({
                                            ...rule_value,
                                            budget_measurement: updatedValue,
                                          });
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="form-group">
                                      <label
                                        htmlFor=""
                                        className="fw-bolder mb-1"
                                      >
                                        {rule_value?.action_type ===
                                        "increase_budget"
                                          ? "Max"
                                          : "Min"}
                                        &nbsp; Budget
                                      </label>

                                      <div className="input-group input-group-solid mb-0">
                                        <Input
                                          placeholder="Enter Value"
                                          size="large"
                                          className="ant_common_input"
                                          value={
                                            rule_value?.action_type ===
                                            "increase_budget"
                                              ? d?.max_budget
                                              : d?.min_budget
                                          }
                                          onChange={(e) => {
                                            const key =
                                              rule_value?.action_type ===
                                              "increase_budget"
                                                ? "max_budget"
                                                : "min_budget";
                                            const updatedValue = [
                                              ...rule_value?.budget_measurement,
                                            ];
                                            updatedValue[i][key] =
                                              e.target.value;
                                            set_rule_value({
                                              ...rule_value,
                                              budget_measurement: updatedValue,
                                            });
                                          }}
                                          prefix={
                                            <label
                                              style={{ fontSize: "15px" }}
                                              className="me-2"
                                            >
                                              $
                                            </label>
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </>
                        ) : (
                          ""
                        )}
                        {/* {console.log(compare_value, "-compare_value")} */}
                        {/* ---------------------------------------------------------------- Budget Div End */}
                        {/* ---------------------------------------------------------------- Compare Values  Start */}
                        {rule_value?.notification_type !== "out_of_budget" && (
                          <>
                            <div className="separator separator-dashed borde-gray-300 mt-4 mb-4"></div>
                            <h6 className="mb-0 required mb-5">
                              Compare Values
                            </h6>
                            {compare_value?.map((d, i) => {
                              return (
                                <div className="row g-5 mb-5 align-items-center ">
                                  <div className="col-md-3 ">
                                    <Select
                                      ref={selectRef}
                                      loading={dropDownLoading}
                                      options={Object.entries(
                                        dropDownData?.attributes_dd_data || {}
                                      )?.map((d) => {
                                        return { label: d?.[1], value: d?.[0] };
                                      })}
                                      onChange={(e) => {
                                        updateCompareValue(i, "attribute", e);
                                      }}
                                      size="large"
                                      placeholder="Select"
                                      value={d?.attribute}
                                      style={{ width: "100%" }}
                                    />
                                  </div>
                                  <div className="col-md-3 ">
                                    <Select
                                      ref={selectRef}
                                      loading={dropDownLoading}
                                      options={Object?.entries(
                                        dropDownData?.operators_dd_data || {}
                                      )?.map((d) => {
                                        return { label: d?.[1], value: d?.[0] };
                                      })}
                                      onChange={(e) => {
                                        updateCompareValue(i, "operator", e);
                                      }}
                                      size="large"
                                      placeholder="Select"
                                      value={d?.operator}
                                      style={{ width: "100%" }}
                                    />
                                  </div>
                                  <div className="col-md-3 ">
                                    <Input
                                      placeholder="Enter Value"
                                      size="large"
                                      className="ant_common_input"
                                      value={d?.value}
                                      onBlur={(e) => {
                                        if (!e.target.value) {
                                          updateCompareValue(i, "value", 0);
                                        }
                                        const newVal = e.target.value
                                          .split(".")
                                          .join("");
                                        const validation = /^[0-9]+$/.test(
                                          newVal
                                        );
                                        if (validation) {
                                          updateCompareValue(
                                            i,
                                            "value",
                                            e.target.value
                                          );
                                        }
                                      }}
                                      onChange={(e) => {
                                        const newVal = e.target.value
                                          .split(".")
                                          .join("");
                                        const validation = /^[0-9]+$/.test(
                                          newVal
                                        );
                                        if (validation) {
                                          updateCompareValue(
                                            i,
                                            "value",
                                            e.target.value
                                          );
                                        }
                                      }}
                                      suffix={
                                        <label
                                          style={{ fontSize: "15px" }}
                                          className="me-2"
                                        >
                                          $
                                        </label>
                                      }
                                    />
                                  </div>
                                  <div className="col-md-2 ">
                                    <div className="d-flex">
                                      <span
                                        className="btn btn-light-info btn-icon me-3"
                                        onClick={() => {
                                          var isBlank = compare_value
                                            .map(function (item) {
                                              return Object.values(item).reduce(
                                                function (count, value) {
                                                  return (
                                                    count + (!value ? 1 : 0)
                                                  );
                                                },
                                                0
                                              );
                                            })
                                            .reduce(function (total, count) {
                                              return total + count;
                                            }, 0);
                                          if (isBlank == 0) {
                                            addCompareValueRow();
                                          } else {
                                            message.destroy();
                                            message.info("Please enter value");
                                          }
                                        }}
                                      >
                                        <i className="fa fa-plus" />
                                      </span>
                                      {compare_value?.length > 1 && (
                                        <span
                                          className="btn btn-light-danger btn-icon me-3"
                                          onClick={() => {
                                            removeCompareValueRow(i);
                                          }}
                                        >
                                          <i className="fa fa-minus" />
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </>
                        )}
                        {/* ---------------------------------------------------------------- Compare Values  End */}
                      </div>
                      {/* <div
                      className="my-5"
                      style={{ borderBottom: "1px dashed #000000" }}
                    ></div> */}
                      {/* ---------------------------------------------------------------- Apply the Rule to Start */}

                      <>
                        <div
                          className="box-rule p-5 rounded border border-dashed border-gray-300 mt-10"
                          style={{
                            background: "#fcfbff",
                            boxShadow: "0px 0px 10px -1px #4318ff5c",
                          }}
                        >
                          <div className="p-4 px-0 mb-4">
                            <h5 className="mb-0 required">Apply the Rule to</h5>
                          </div>
                          <div className="row gx-5">
                            <div className="col-md-3 col-lg-2">
                              <div className="form-group">
                                <label htmlFor="" className="fw-bolder mb-1">
                                  Type
                                </label>
                                <Select
                                  ref={selectRef}
                                  options={
                                    rule_value?.action_type ===
                                      "increase_bid" ||
                                    rule_value?.action_type === "decrease_bid"
                                      ? [
                                          {
                                            label: "By Ad Group",
                                            value: "by_ad_group",
                                          },
                                        ]
                                      : [
                                          {
                                            label: "By Campaign",
                                            value: "by_campaigns",
                                          },
                                        ]
                                  }
                                  onChange={(e) => {
                                    updateCommonRuleDetails(
                                      "apply_rule_type",
                                      e
                                    );
                                    set_selected_ad_group_for_dd([]);
                                    set_selected_ad_group_list_for_api([]);
                                    set_selected_campaign_for_dd([]);
                                    set_selected_campaign_list_for_api([]);
                                  }}
                                  size="large"
                                  placeholder="Select"
                                  value={rule_value?.apply_rule_type}
                                  style={{ width: "100%" }}
                                />
                              </div>
                              {/* <span className="text-primary fw-bold">
                              Add
                              {rule_value?.action_type === "increase_bid" ||
                              rule_value?.action_type === "decrease_bid"
                                ? "Ad Group"
                                : "Campaign"}
                            </span> */}
                            </div>
                            <div className="col-md-3 col-lg-2">
                              <div className="form-group">
                                <label htmlFor="" className="fw-bolder mb-1">
                                  Attribute
                                </label>
                                <Select
                                  ref={selectRef}
                                  options={
                                    rule_value?.action_type ===
                                      "increase_bid" ||
                                    rule_value?.action_type === "decrease_bid"
                                      ? [
                                          {
                                            label: "All Ad Group",
                                            value: "all",
                                          },
                                          {
                                            label: "Selected Ad Group",
                                            value: "selected",
                                          },
                                        ]
                                      : [
                                          {
                                            label: "All Campaigns",
                                            value: "all",
                                          },
                                          {
                                            label: "Selected Campaigns",
                                            value: "selected",
                                          },
                                        ]
                                  }
                                  size="large"
                                  showSearch
                                  placeholder="Select"
                                  value={rule_value?.apply_rule_attribute}
                                  onChange={(e) => {
                                    updateCommonRuleDetails(
                                      "apply_rule_attribute",
                                      e
                                    );
                                    if (e === "all") {
                                      if (
                                        rule_value?.action_type ===
                                          "increase_bid" ||
                                        rule_value?.action_type ===
                                          "decrease_bid"
                                      ) {
                                        const allAdGroup = ad_group_list?.map(
                                          (d) => {
                                            return d?.ad_group_id;
                                          }
                                        );
                                        set_selected_ad_group_for_dd(
                                          allAdGroup
                                        );
                                        set_selected_ad_group_list_for_api(
                                          ad_group_list?.map((d) => {
                                            return {
                                              label: d?.ad_group_name,
                                              value: d?.ad_group_id,
                                            };
                                          })
                                        );
                                      } else {
                                        const allCampaign = campaign_list?.map(
                                          (d) => {
                                            return d?.campaign_id;
                                          }
                                        );
                                        set_selected_campaign_for_dd(
                                          allCampaign
                                        );
                                        set_selected_campaign_list_for_api(
                                          campaign_list?.map((d) => {
                                            return {
                                              label: d?.campaign_name,
                                              value: d?.campaign_id,
                                            };
                                          })
                                        );
                                      }
                                    } else {
                                      set_selected_ad_group_for_dd([]);
                                      set_selected_ad_group_list_for_api([]);
                                      set_selected_campaign_for_dd([]);
                                      set_selected_campaign_list_for_api([]);
                                    }
                                  }}
                                  style={{ width: "100%" }}
                                />
                              </div>
                            </div>
                            <div className="col-md-6 col-lg-8">
                              <div className="form-group">
                                <label htmlFor="" className="fw-bolder mb-1">
                                  {rule_value?.action_type === "increase_bid" ||
                                  rule_value?.action_type === "decrease_bid"
                                    ? "Ad Group"
                                    : "Campaign"}
                                  &nbsp;Name
                                </label>
                                <Select
                                  ref={selectRef}
                                  options={
                                    rule_value?.action_type ===
                                      "increase_bid" ||
                                    rule_value?.action_type === "decrease_bid"
                                      ? ad_group_list?.map((d) => {
                                          return {
                                            label: d?.ad_group_name,
                                            value: d?.ad_group_id,
                                          };
                                        })
                                      : campaign_list?.map((d) => {
                                          return {
                                            label: d?.campaign_name,
                                            value: d?.campaign_id,
                                          };
                                        })
                                  }
                                  maxTagCount={"responsive"}
                                  mode="multiple"
                                  size="large"
                                  placeholder="Select"
                                  showSearch
                                  filterOption={(input, option) =>
                                    option.label
                                      .toLowerCase()
                                      .includes(input.toLowerCase())
                                  }
                                  onChange={(e, _) => {
                                    if (
                                      rule_value?.action_type ===
                                        "increase_bid" ||
                                      rule_value?.action_type === "decrease_bid"
                                    ) {
                                      set_selected_ad_group_list_for_api(_);
                                      set_selected_ad_group_for_dd(e);
                                    } else {
                                      set_selected_campaign_list_for_api(_);
                                      set_selected_campaign_for_dd(e);
                                    }
                                  }}
                                  value={
                                    rule_value?.action_type ===
                                      "increase_bid" ||
                                    rule_value?.action_type === "decrease_bid"
                                      ? selected_ad_group_for_dd
                                      : selected_campaign_for_dd
                                  }
                                  style={{
                                    width: "100%",
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <div
                        className="my-5"
                        style={{ borderBottom: "1px dashed #000000" }}
                      ></div> */}
                      </>

                      {/* ---------------------------------------------------------------- Apply the Rule to End */}

                      {/* ---------------------------------------------------------------- Select a Date Range Start */}

                      <div
                        className="box-rule p-5 rounded border border-dashed border-gray-300 mt-10"
                        style={{
                          background: "#fcfbff",
                          boxShadow: "0px 0px 10px -1px #4318ff5c",
                        }}
                      >
                        <div className="p-4 px-0 mb-4">
                          <h5 className="mb-0 required">Select a Date Range</h5>
                        </div>
                        <div className="mb-5">
                          <div className="">
                            <Checkbox
                              onChange={(e) => {
                                setIsIndefinite(e.target.checked);
                                if (!e.target.checked) {
                                  const common_data = {
                                    ...rule_value,
                                    ["end_date"]: dayjs().add(30, "d"),
                                  };
                                  set_rule_value(common_data);
                                }
                              }}
                              checked={isIndefinite}
                            />
                            <label className="fw-bold text-primary fs-5 ms-3">
                              Indefinite
                            </label>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-2">
                            <div className="form-group">
                              <label htmlFor="" className="fw-bolder mb-1">
                                Start Date
                              </label>
                              <DatePicker
                                format={DateFormat}
                                onChange={(date, dateString) => {
                                  updateCommonRuleDetails(
                                    "start_date",
                                    dayjs(date?.$d)
                                  );
                                }}
                                style={{ width: "100%" }}
                                size="large"
                                className="ant_common_input"
                                value={rule_value?.start_date}
                                // disabledDate={disableStartDate}
                              />
                            </div>
                          </div>
                          {!isIndefinite && (
                            <div className="col-md-2">
                              <div className="form-group">
                                <label htmlFor="" className="fw-bolder mb-1">
                                  End Date
                                </label>
                                <DatePicker
                                  format={DateFormat}
                                  onChange={(date, dateString) => {
                                    updateCommonRuleDetails(
                                      "end_date",
                                      dayjs(date?.$d)
                                    );
                                  }}
                                  style={{ width: "100%" }}
                                  size="large"
                                  className="ant_common_input"
                                  value={rule_value?.end_date}
                                  // disabledDate={disableEndDate}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="row mt-7">
                          {!rule_value?.notification_type ||
                          rule_value?.notification_type !== "out_of_budget" ? (
                            <>
                              <div className="col-md-2">
                                <div className="form-group">
                                  <label htmlFor="" className="fw-bolder mb-1">
                                    Frequency
                                  </label>
                                  <Select
                                    ref={selectRef}
                                    options={[
                                      { label: "1 Hour", value: "1" },
                                      {
                                        label: "2 Hour",
                                        value: "2",
                                        disabled: true,
                                      },
                                      {
                                        label: "3 Hour",
                                        value: "3",
                                        disabled: true,
                                      },
                                      {
                                        label: "4 Hour",
                                        value: "4",
                                        disabled: true,
                                      },
                                    ]}
                                    size="large"
                                    placeholder="Select"
                                    style={{ width: "100%" }}
                                    value={rule_value?.frequency_hours}
                                    onChange={(e) => {
                                      updateCommonRuleDetails(
                                        "frequency_hours",
                                        e
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div className="form-group">
                                  <label htmlFor="" className="fw-bolder mb-1">
                                    Compare from
                                  </label>
                                  <Select
                                    ref={selectRef}
                                    loading={dropDownLoading}
                                    options={Object.entries(
                                      dropDownData?.look_back_period || {}
                                    )?.map((d) => {
                                      return { label: d?.[1], value: d?.[0] };
                                    })}
                                    size="large"
                                    placeholder="Select"
                                    value={rule_value?.look_back_period}
                                    onChange={(e) => {
                                      updateCommonRuleDetails(
                                        "look_back_period",
                                        e
                                      );
                                    }}
                                    style={{ width: "100%" }}
                                  />
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div className="form-group">
                                  <label htmlFor="" className="fw-bolder mb-1">
                                    Occurrence
                                  </label>
                                  <Select
                                    ref={selectRef}
                                    options={[
                                      // { label: "Once", value: "once" },
                                      { label: "Daily", value: "daily" },
                                      { label: "Weekly", value: "weekly" },
                                      { label: "Monthly", value: "monthly" },
                                    ]}
                                    size="large"
                                    placeholder="Select"
                                    style={{ width: "100%" }}
                                    value={rule_value?.type}
                                    onChange={(e) => {
                                      updateCommonRuleDetails("type", e);
                                    }}
                                  />
                                </div>
                              </div>
                              {rule_value?.type === "weekly" && (
                                <div className="col-md-2">
                                  <div className="form-group">
                                    <label
                                      htmlFor=""
                                      className="fw-bolder mb-1"
                                    >
                                      Day
                                    </label>

                                    <Select
                                      ref={selectRef}
                                      options={weekList}
                                      size="large"
                                      placeholder="Select"
                                      maxTagCount={"responsive"}
                                      mode="multiple"
                                      style={{ width: "100%" }}
                                      value={rule_value?.weekly}
                                      onChange={(e, _) => {
                                        const newWeekObj = _?.map((d) => {
                                          return {
                                            d_name: d?.label,
                                            day_index: d?.day_index,
                                          };
                                        });
                                        setWeekObj(newWeekObj);
                                        updateCommonRuleDetails("weekly", e);
                                      }}
                                    />
                                  </div>
                                </div>
                              )}

                              {rule_value?.type === "monthly" ||
                              rule_value?.type === "once" ? (
                                <div className="col-md-2">
                                  <div className="form-group">
                                    <label
                                      htmlFor=""
                                      className="fw-bolder mb-1"
                                    >
                                      Day Number
                                    </label>

                                    <Select
                                      ref={selectRef}
                                      options={daysList}
                                      size="large"
                                      placeholder="Select"
                                      maxTagCount={"responsive"}
                                      mode={
                                        rule_value?.type !== "once" &&
                                        "multiple"
                                      }
                                      style={{ width: "100%" }}
                                      value={rule_value?.day}
                                      onChange={(e) => {
                                        updateCommonRuleDetails("day", e);
                                      }}
                                    />
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                              <div className="col-md-2">
                                <div className="form-group">
                                  <label htmlFor="" className="fw-bolder mb-1">
                                    Start Time{" "}
                                  </label>

                                  <TimePicker
                                    // defaultValue={moment(, "HH:mm")}
                                    format={"HH:mm"}
                                    placeholder="Enter Start Time"
                                    size="large"
                                    className="ant_common_input"
                                    style={{ width: "100%", height: "44px" }}
                                    value={dayjs(
                                      rule_value?.start_time,
                                      "HH:mm"
                                    )}
                                    onChange={(time, timeString) => {
                                      console.log(time, "-time");
                                      updateCommonRuleDetails(
                                        "start_time",
                                        dayjs(time)?.format("HH:mm")
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div className="form-group">
                                  <label htmlFor="" className="fw-bolder mb-1">
                                    End Time{" "}
                                  </label>
                                  <div className="d-flex align-items-center">
                                    <TimePicker
                                      // defaultValue={moment(, "HH:mm")}
                                      format={"HH:mm"}
                                      placeholder="Enter End Time"
                                      size="large"
                                      className="ant_common_input"
                                      style={{ width: "100%", height: "44px" }}
                                      value={dayjs(
                                        rule_value?.end_time,
                                        "HH:mm"
                                      )}
                                      onChange={(time, timeString) => {
                                        const start = dayjs(
                                          rule_value?.start_time,
                                          "HH:mm"
                                        );
                                        const newEndTime = dayjs(
                                          timeString,
                                          "HH:mm"
                                        )
                                          .minute(start.minute())
                                          .format("HH:mm");
                                        updateCommonRuleDetails(
                                          "end_time",
                                          newEndTime
                                        );
                                      }}
                                    />
                                    <Tooltip
                                      defaultOpen
                                      placement="bottom"
                                      title="End time is adjusted based on selected start time by considering one hour frequecy. you can edit the end time but minutes will be same for start & End time."
                                    >
                                      <i
                                        style={{
                                          fontSize: "17px",
                                          color: "#827dcb",
                                        }}
                                        className="bi bi-info-circle mx-2 cursor-pointer"
                                      ></i>
                                    </Tooltip>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      {/* ---------------------------------------------------------------- Select a Date Range End */}
                      {/* <div
                      className="my-5"
                      style={{ borderBottom: "1px dashed #000000" }}
                    ></div> */}
                      {/* ---------------------------------------------------------------- Notifications Start */}
                      <div
                        className="box-rule p-5 rounded mb-10 border border-dashed border-gray-300 mt-10"
                        style={{
                          background: "#fcfbff",
                          boxShadow: "0px 0px 10px -1px #4318ff5c",
                        }}
                      >
                        <div className="p-4 px-0 mb-4">
                          <h5 className="mb-0 required">Notifications</h5>
                        </div>
                        <div className="">
                          <div className="form-group">
                            <label htmlFor="" className="fw-bolder mb-1">
                              Email
                            </label>
                            <small className="text-muted ms-1">
                              (Please only add emails associated with this
                              account. Add multiple emails.)
                            </small>

                            <Input
                              placeholder="Enter Value"
                              size="large"
                              className="ant_common_input"
                              // value={rule_value?.notifications_email}
                              value={singleNotificationEmail}
                              onChange={(e) => {
                                setSingleNotificationEmail(e.target.value);
                                // updateCommonRuleDetails(
                                //   "notifications_email",
                                //   e.target.value
                                // );
                              }}
                              onPressEnter={(e) => {
                                const isValidEmail =
                                  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                                    e.target.value
                                  );
                                if (isValidEmail) {
                                  const common_data = {
                                    ...rule_value,
                                    notifications_email: [
                                      ...rule_value?.notifications_email,
                                      e.target.value,
                                    ],
                                  };
                                  set_rule_value(common_data);
                                  setSingleNotificationEmail("");
                                } else {
                                  message.destroy();
                                  message.info("Invalid email");
                                }
                              }}
                              // onBlur={(e) => {
                              //   const isValidEmail =
                              //     /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                              //       e.target.value
                              //     );
                              //   if (isValidEmail) {
                              //     const common_data = {
                              //       ...rule_value,
                              //       notifications_email: [
                              //         ...rule_value?.notifications_email,
                              //         e.target.value,
                              //       ],
                              //     };
                              //     set_rule_value(common_data);
                              //     setSingleNotificationEmail("");
                              //   } else {
                              //     message.destroy();
                              //     message.info("Invalid email");
                              //     setSingleNotificationEmail("");
                              //   }
                              // }}
                              prefix={
                                <MailOutlined
                                  style={{ fontSize: "19px", color: "#2c00f0" }}
                                  className="me-2"
                                />
                              }
                            />
                            {console.log(rule_value, "-TestEmail")}
                          </div>
                        </div>
                        <div className="mt-5 d-flex flex-wrap">
                          {rule_value?.notifications_email?.map((email) => {
                            return (
                              <span
                                className="d-flex align-items-center my-1 mx-1"
                                style={{
                                  border: "2px solid #adc6ff",
                                  color: "#1d39c4",
                                  padding: " 0px 7px",
                                  borderRadius: "5px",
                                  background: "#f0f5ff",
                                }}
                              >
                                <span className="me-2"> {email}</span>
                                <i
                                  className="ki-outline ki-cross-square text-danger fs-4 cursor-pointer"
                                  style={{ marginTop: "1px" }}
                                  onClick={() => {
                                    const removeEmail =
                                      rule_value?.notifications_email?.filter(
                                        (d) => d !== email
                                      );
                                    const common_data = {
                                      ...rule_value,
                                      notifications_email: removeEmail,
                                    };
                                    set_rule_value(common_data);
                                  }}
                                />
                              </span>
                            );
                          })}
                        </div>
                        {/* <div className="mt-5 d-flex flex-wrap">
                        {rule_value?.notifications_email?.map((email) => {
                          return (
                            <span className="me-3 d-flex align-items-center">
                              <Tag color="geekblue" className="my-1 me-0">
                                {email}
                              </Tag>
                              <i className="ki-outline ki-cross-square text-danger fs-4 cursor-pointer" />
                            </span>
                          );
                        })}
                      </div> */}
                      </div>
                      {/* ---------------------------------------------------------------- Notifications End */}
                      <div className="row">
                        <div className="col-12 text-end">
                          <button
                            className="btn btn-primary me-2"
                            disabled={bidSaving}
                            onClick={() => {
                              // console.log(rule_value, "-rule_value");
                              if (
                                !rule_value?.rule_name ||
                                !rule_value?.action_type
                              ) {
                                message.destroy();
                                message.info(
                                  "Please enter rule details and choose action"
                                );
                              } else {
                                setBidSaving(true);
                                SaveBidRule();
                              }
                            }}
                          >
                            {rule_id ? "Update Rule" : "Save Rule"}
                          </button>
                          <button
                            className="btn btn-danger"
                            onClick={() => {
                              setIsRedirect(true);
                            }}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default BidRuleDetails;
