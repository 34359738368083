import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import Chart from "react-apexcharts";
import { NumberWithCommas } from "../../../../config";
import { Skeleton, Tooltip, message } from "antd";
import {
  CloudDownloadOutlined,
  FilePdfOutlined,
  FileExcelOutlined,
} from "@ant-design/icons";
import { savePDF } from "@progress/kendo-react-pdf";
import NoData from "../../../../component/no-data";
import writeXlsxFile from "write-excel-file";

export default function (props) {
  const {
    unitSoldChartDate,
    unitSoldChartSeries,
    unitSoldChartLoading,
    selectedDateRangeType,
    exportGraphArray,
    selectedViewBy,
  } = props;
  const chartRef = useRef(null);
  const options = {
    chart: {
      type: "bar",
      fontFamily: 'Inter, Helvetica, "sans-serif"',
      toolbar: {
        show: false,
        offsetY: -60,
        tools: {
          download: '<img src="/assets/media/downloads.png" width="20"/>',
        },
        export: {
          // Set the filename
          filename: "Unit Sold Graph",
          // Include only PNG
          formats: ["png"],
        },
      },
      selection: {
        enabled: true,
      },
      dropShadow: {
        enabled: !0,
        top: 12,
        left: 0,
        bottom: 0,
        right: 0,
        blur: 2,
        color: "rgba(132, 145, 183, 0.4)",
        opacity: 0.45,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        // endingShape: "rounded",
        columnWidth: "50%",
        borderRadius: 3,
      },
    },
    noData: {
      text: "No Data",
      align: "center",
      verticalAlign: "middle",
      style: {
        fontSize: "20px",
      },
    },
    tooltip: {
      marker: {
        show: true,
      },
      x: {
        show: true,
      },
      y: {
        formatter: function (
          value,
          { series, seriesIndex, dataPointIndex, w }
        ) {
          const withTitle_ = w?.config?.series?.[seriesIndex];
          console.log(withTitle_, "withTitle_");
          return withTitle_?.name === "Unit Sold"
            ? value || 0
            : "$" + NumberWithCommas(value);
        },
      },
    },
    colors: ["#8992d4", "#373fb5", "#98cabb", "#097980"],
    // stroke: {
    //   curve: "smooth",
    //   lineCap: "butt",
    //   show: !0,
    //   width: 2,
    //   colors: ["#000", "#63CE94"],
    // },
    yaxis: [
      {
        seriesName: "Total Sales",
        title: {
          text: "Total Sales",
        },
        labels: {
          formatter: function (_) {
            return NumberWithCommas(_);
          },
        },
      },
      {
        seriesName: "Unit Sold",
        opposite: true,
        title: {
          text: "Unit Sold",
        },
        labels: {
          formatter: function (_) {
            return NumberWithCommas(_);
          },
        },
      },
    ],
    // yaxis: {
    //   labels: {
    //     formatter: function (_) {
    //       return NumberWithCommas(_);
    //     },
    //     offsetX: -12,
    //     offsetY: 0,
    //   },
    // },
    dataLabels: {
      enabled: false, // Set this to true if you want to display data labels initially
    },
    markers: {
      size: 5,
    },
    xaxis: {
      tickPlacement: "between",
      tickAmount: window.innerWidth >= 992 ? 15 : 5,
      categories:
        unitSoldChartDate?.map((d) =>
          selectedDateRangeType === "last52_week" ||
          selectedDateRangeType === "year_to_date"
            ? d
            : selectedViewBy === "month"
            ? moment(new Date(d)).format("MMM YYYY")
            : moment(new Date(d)).format("DD MMM YYYY")
        ) || [],
      labels: {
        rotate: -50,
      },
    },
    grid: {
      strokeDashArray: 3,
      xaxis: { lines: { show: !0 } },
      yaxis: { lines: { show: !1 } },
    },
    legend: {
      show: true,
    },

    // fill: {
    //   type: "gradient",
    //   gradient: {
    //     type: "vertical",
    //     shadeIntensity: 1,
    //     inverseColors: !1,
    //     opacityFrom: 0.05,
    //     opacityTo: 0.05,
    //     stops: [45, 100],
    //   },
    // },
  };

  const handleExportPDF = () => {
    savePDF(chartRef.current, { paperSize: "auto", fileName: "chart.pdf" });
  };

  const exportChartHeader = [
    { value: "Date", type: String },
    { value: "Total Sales", type: String },
    { value: "Units sold", type: String },
  ];

  const downloadXlChart = async () => {
    let dataRow = [];
    await exportGraphArray.map((d) => {
      const newList = [d?.date, d?.order_sales, d?.unit_sold];
      dataRow = [
        ...dataRow,
        newList.map((d) => {
          return { value: d };
        }),
      ];
    });
    const data = [exportChartHeader, ...dataRow];
    await writeXlsxFile(data, {
      fileName: "Unit Sold Graph.xlsx",
    });
    message.destroy();
  };
  return (
    <>
      <div
        className="card card-flush"
        style={{
          // filter: unitSoldChartLoading ? "blur(6px)" : "blur(0px)",
          backgroundImage: "url(/assets/media/crossword.png)",
          boxShadow: "#8f7be963 0px 0px 18px -1px",
        }}
      >
        {/*begin::Header*/}
        <div className="card-header pt-7">
          {/*begin::Title*/}
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bold text-dark">Unit Sold</span>
            <span className="text-gray-400 pt-2 fw-semibold fs-6">
              Unit Sold Overview
            </span>
          </h3>
          {/*end::Title*/}
          {/*begin::Toolbar*/}
          <div className="card-toolbar">
            {" "}
            <span
              className="d-flex align-items-center cursor-pointer me-5"
              onClick={() => {
                handleExportPDF();
              }}
            >
              <Tooltip title="Download PDF" placement="bottom">
                <FilePdfOutlined className="fs-1" style={{ color: "red" }} />
              </Tooltip>
            </span>
            <span
              className="d-flex align-items-center cursor-pointer"
              onClick={() => {
                message.loading("Loading...", 0);
                downloadXlChart();
              }}
            >
              <Tooltip title="Download Excel" placement="bottom">
                <FileExcelOutlined className="fs-1" style={{ color: "blue" }} />
              </Tooltip>
            </span>
          </div>
          {/*end::Toolbar*/}
        </div>
        {/*end::Header*/}
        {/*begin::Body*/}
        <div className="card-body pb-0">
          {/*begin::Map container*/}
          {/* <div
            id="chartZoom"
            className="w-100 position-relative"
            style={{ minHeight: "395px" }}
          >
            <Chart
              options={options}
              series={unitSoldChartSeries}
              type="area"
              height={432}
            />
          </div> */}
          <div
            id="chartZoom"
            className="w-100 "
            style={{ minHeight: "395px" }}
            ref={chartRef}
          >
            {unitSoldChartLoading ? (
              <div className="d-flex justify-content-center align-items-center">
                <Skeleton.Button block active style={{ height: "300px" }} />
              </div>
            ) : unitSoldChartDate?.length != 0 ? (
              <Chart
                options={options}
                series={unitSoldChartSeries}
                type="bar"
                height={413}
              />
            ) : (
              <NoData height="400px" />
            )}
          </div>
          {/*end::Map container*/}
        </div>
        {/*end::Body*/}
      </div>
    </>
  );
}
