import {
  Alert,
  Checkbox,
  DatePicker,
  Input,
  Popover,
  Select,
  Tag,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import lodashObj from "lodash";
import { DateFormat, getUserId } from "../../../../../config";
import dayjs from "dayjs";
import { AllCarrierName } from "../../../../../config/static-select-option";
import { OrderWrapper } from "../lib/style";
export default function (props) {
  const {
    show,
    onHide,
    processOrderOperation,
    selectedPoId,
    WalmartCancelItemAction,
    fakeActionWalmartOrders,
    selectedCheckbox,
    setSelectedCheckbox,
    onSuccess,
  } = props;

  const WalmartCancelItemsRes = useSelector(
    (state) => state.WalmartOrders.WalmartCancelItemsResponse || {}
  );

  useEffect(() => {
    if (WalmartCancelItemsRes.status === true) {
      message.destroy();
      message.success("Cancel - Request submit to walmart");
      onSuccess();
      onHide();
      fakeActionWalmartOrders("WalmartCancelItemsResponse");
    } else if (WalmartCancelItemsRes.status === false) {
      message.destroy();
      message.error("Failed");
      fakeActionWalmartOrders("WalmartCancelItemsResponse");
    }
  }, [WalmartCancelItemsRes]);

  const CancelWalmartItems = () => {
    const isValid = selectedCheckbox?.filter((d) => !d?.cancellation_reason);
    if (isValid?.length > 0) {
      message.destroy();
      message.warning("Please select reason");
      return;
    } else {
      const finalJson = selectedCheckbox?.map((d) => {
        return {
          lineNumber: d?.order_line_number,
          orderLineStatuses: {
            orderLineStatus: [
              {
                status: "Cancelled",
                cancellationReason: d?.cancellation_reason,
                statusQuantity: {
                  unitOfMeasurement: d?.order_unit_of_measurement,
                  amount: d?.qty_to_ship?.toString(),
                },
              },
            ],
          },
        };
      });
      const newJson = {
        orderCancellation: {
          orderLines: {
            orderLine: finalJson,
          },
        },
      };
      if (processOrderOperation === "cancel") {
        message.destroy();
        message.loading("Loading...", 0);
        WalmartCancelItemAction(selectedPoId, newJson);
      }
    }
  };

  return (
    <>
      <Modal show={show} onHide={onHide} size="md" centered backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Cancel Items</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <OrderWrapper>
            <div style={{ height: "400px", overflow: "auto" }}>
              {selectedCheckbox?.map((d) => {
                return (
                  <>
                    <div className="row my-4 px-0 mx-0">
                      <div className="col-11">
                        <div className="row mb-2">
                          <div className="col-12">
                            <Popover
                              getPopupContainer={(triggerNode) =>
                                triggerNode.parentNode
                              }
                              content={
                                <div
                                  style={{
                                    height: "max-content",
                                    width: "300px",
                                  }}
                                >
                                  {d?.product_name}
                                </div>
                              }
                              placement="bottomLeft"
                            >
                              <div
                                className="text-primary mb-1 fw-bold text-one fs-5 popoverActionIconSingleLine"
                                style={{ minWidth: "100px" }}
                              >
                                {d?.product_name}
                              </div>
                            </Popover>
                          </div>
                        </div>
                        <div className="d-flex align-items-center">
                          <Tag color="magenta">SKU - {d?.sku}</Tag>

                          <Tag color="geekblue">
                            Orderline - {d?.order_line_number}
                          </Tag>
                        </div>
                        <div className="row mt-5">
                          <div className="position-relative">
                            <Select
                              style={{ width: "350px" }}
                              getPopupContainer={(triggerNode) =>
                                triggerNode.parentNode
                              }
                              placeholder="Select Reason"
                              size="medium"
                              value={d?.cancellation_reason || null}
                              onChange={(e) => {
                                setSelectedCheckbox((prev) =>
                                  prev?.map((l) => {
                                    if (
                                      l?.order_line_number ===
                                      d?.order_line_number
                                    ) {
                                      return { ...l, cancellation_reason: e };
                                    }
                                    return { ...l };
                                  })
                                );
                              }}
                              options={[
                                {
                                  label: "SELLER_CANCEL_PRICING_ERROR",
                                  value: "SELLER_CANCEL_PRICING_ERROR",
                                },
                                {
                                  label: "SELLER_CANCEL_OUT_OF_STOCK",
                                  value: "SELLER_CANCEL_OUT_OF_STOCK",
                                },
                                {
                                  label: "SELLER_CANCEL_FRAUD_STOP_SHIPMENT",
                                  value: "SELLER_CANCEL_FRAUD_STOP_SHIPMENT",
                                },
                                {
                                  label:
                                    "SELLER_CANCEL_ADDRESS_NOT_SERVICEABLE",
                                  value:
                                    "SELLER_CANCEL_ADDRESS_NOT_SERVICEABLE",
                                },
                              ]}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="separator separator-dashed mb-4" />
                  </>
                );
              })}
            </div>
          </OrderWrapper>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-light-danger"
            onClick={onHide}
          >
            Close
          </button>
          <button
            type="submit"
            className="btn btn-primary"
            onClick={() => {
              CancelWalmartItems();
            }}
          >
            Cancel Items
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
