import { SearchOutlined } from "@ant-design/icons";
import { Input, Popconfirm, Select, Tag, Tooltip, message } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import IvcsrTable from "../../../../../component/ivcsr-table";
import Loading from "../../../../../component/loading";
import NoData from "../../../../../component/no-data";
import Pagination from "../../../../../component/pagination";
import { DefaultPerPage, isNot2000SeriesYear } from "../../../../../config";

export default function (props) {
  const {
    setCampaignOperation,
    setEditCampaignData,
    setSelectedTab,
    GetWalmartCampaignList,
    DeleteWalmartCampaignAction,
    GetSavedCamapignDetailsByCID,
    GetDirectWalmartCampaignAction,
    fakeActionCampaignManagement,
    setIsCampaignId,
  } = props;

  const [sortFilters, setSortFilters] = useState({
    key: "",
    type: "",
  });
  const GetWalmartCampaignListRes = useSelector(
    (state) => state.CampaignManagement.GetWalmartCampaignListResponse || {}
  );

  const DeleteWalmartCampaignRes = useSelector(
    (state) => state.CampaignManagement.DeleteWalmartCampaignResponse || {}
  );

  const GetSavedCamapignDetailsByCIDRes = useSelector(
    (state) =>
      state.CampaignManagement.GetSavedCamapignDetailsByCIDResponse || {}
  );

  const GetDirectWalmartCampaignListRes = useSelector(
    (state) =>
      state.CampaignManagement.GetDirectWalmartCampaignListResponse || {}
  );

  const [filters, setFilters] = useState({
    start_date: dayjs().add(-30, "d"),
    end_date: dayjs(),
  });
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState();
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageSize, setPageSize] = useState(DefaultPerPage);
  const [campaignType, setCampaignType] = useState(null);
  const [targetingType, setTargetingType] = useState(null);
  const [campaignStatus, setCampaignStatus] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  const columns = [
    {
      title: "Sr. No.",
      ellipsis: true,
      align: "left",
      render: (text) => {
        return <span>{(page - 1) * pageSize + 1 + text.key}</span>;
      },
    },
    {
      title: "Name",
      // dataIndex: "name",
      // key: "name",
      dataIndex: "campaign_name",
      key: "campaign_name",
      render: (text) => {
        return <span className="fw-bolder">{text}</span>;
      },
    },
    {
      title: "Campaign Type",
      // dataIndex: "campaignType",
      // key: "campaignType",
      dataIndex: "campaign_type",
      key: "campaign_type",
    },
    {
      title: "Targeting Type",
      // dataIndex: "targetingType",
      // key: "targetingType",
      dataIndex: "targeting_type",
      key: "targeting_type",
      render: (text) => {
        return (
          <Tag
            className="fw-bolder"
            color={
              text === "manual" ? "magenta" : text === "auto" ? "cyan" : ""
            }
            style={{ textTransform: "capitalize" }}
            bordered={false}
          >
            {text}
          </Tag>
        );
      },
    },
    {
      title: "Status",
      // dataIndex: "status",
      // key: "status",
      dataIndex: "status",
      key: "status",
      render: (text) => {
        return (
          <Tag
            className="fw-bolder"
            color={
              text === "Proposal"
                ? "blue"
                : text === "Live"
                ? "green"
                : text === "Paused"
                ? "orange"
                : text === "Completed"
                ? "purple"
                : "error"
            }
          >
            {text}
          </Tag>
        );
      },
    },
    {
      title: "Budget Type",
      // dataIndex: "budgetType",
      // key: "budgetType",
      dataIndex: "budget_type",
      key: "budget_type",
      render: (text) => {
        return (
          <span style={{ textTransform: "capitalize" }}>
            {text?.toLowerCase()}
          </span>
        );
      },
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      key: "start_date",
    },
    {
      title: "End Date",

      dataIndex: "end_date",
      key: "end_date",
      render: (text) => {
        const year = isNot2000SeriesYear(text);
        console.log(year, "year");
        return <label>{year ? "No End Date" : text}</label>;
      },
    },
    {
      title: "Total Budget",
      dataIndex: "total_budget",
      key: "total_budget",
      isFilterKey: "total_budget",
      render: (text) => {
        return <span className="">${text}</span>;
      },
    },
    {
      title: "Daily Budget",
      dataIndex: "daily_budget",
      key: "daily_budget",
      isFilterKey: "daily_budget",
      render: (text) => {
        return <span className="">${text}</span>;
      },
    },
    {
      title: "Total Remaining Budget",
      dataIndex: "total_remaining_budget",
      key: "total_remaining_budget",
      isFilterKey: "total_remaining_budget",
      render: (text) => {
        return <span className="">${text}</span>;
      },
    },
    {
      title: "Daily Remaining Budget",
      dataIndex: "daily_remaining_budget",
      key: "daily_remaining_budget",
      isFilterKey: "daily_remaining_budget",
      render: (text) => {
        return <span className="">${text}</span>;
      },
    },
    {
      title: "Rollover",
      dataIndex: "rollover",
      key: "rollover",
      render: (rollover) => (rollover ? "Yes" : "No"),
    },
    {
      title: "Advertiser ID",
      // dataIndex: "advertiserId",
      // key: "advertiserId",
      dataIndex: "advertiser_id",
      key: "advertiser_id",
    },
    {
      title: "Campaign ID",
      // dataIndex: "campaignId",
      // key: "campaignId",
      dataIndex: "campaign_id",
      key: "campaign_id",
    },
    {
      title: "Bidding Strategy",
      dataIndex: "bidding_strategy",
      key: "bidding_strategy",
      render: (biddingStrategy) => {
        const data = JSON.parse(biddingStrategy);
        return data?.strategy;
      },
    },
    {
      title: "Channel",
      dataIndex: "channel",
      key: "channel",
    },
    {
      title: "Action",
      render: (text) => {
        return (
          <div className="d-flex justify-content-between">
            <div className="mx-1">
              <button
                class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
                onClick={() => {
                  message.loading("Loading...", 0);
                  GetSavedCamapignDetailsByCID(text?.campaign_id);
                }}
              >
                <i class="ki-outline ki-pencil fs-2 text-green"></i>
              </button>
            </div>

            <Popconfirm
              title="Are you sure to delete this campaign ?"
              onConfirm={() => {
                message.destroy();
                message.loading("Deleting...", 0);
                DeleteWalmartCampaignAction([
                  { campaignId: text?.campaign_id },
                ]);
              }}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <div className="mx-1">
                <button class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                  <i class="ki-outline ki-trash fs-2 text-danger"></i>
                </button>
              </div>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    GetWalmartCampaignList({
      page: page,
      perPage: pageSize,
      campaign_type: campaignType,
      targeting_type: targetingType,
      status: campaignStatus,
      // start_date: moment(filters?.start_date?.$d).format("YYYY-MM-DD"),
      // start_date: "2024-03-22",
      // // end_date: moment(filters?.end_date?.$d).format("YYYY-MM-DD"),
      // end_date: "9999-12-30",
      search_key: searchTerm,
      sort:
        sortFilters?.type === "ascend"
          ? "asc"
          : sortFilters?.type === "descend"
          ? "desc"
          : "",
      field_name: sortFilters?.key,
    });
    setIsCampaignId("");
  }, []);

  useEffect(() => {
    if (GetWalmartCampaignListRes.status === true) {
      const userId = JSON.parse(localStorage.getItem("user"))?.id.toString();
      // if (userId == 8) {
      //   setList(GetWalmartCampaignListRes?.data);
      // } else {
      setList(GetWalmartCampaignListRes?.data?.records);
      // }
      console.log(GetWalmartCampaignListRes, "GetWalmartCampaignListRes");
      setLoading(false);
      setTotalPage(GetWalmartCampaignListRes?.data?.pagination?.totalCount);
      fakeActionCampaignManagement("GetWalmartCampaignListResponse");
    } else if (GetWalmartCampaignListRes.status === false) {
      setLoading(false);
      fakeActionCampaignManagement("GetWalmartCampaignListResponse");
    }
  }, [GetWalmartCampaignListRes]);

  useEffect(() => {
    if (DeleteWalmartCampaignRes.status === true) {
      console.log(DeleteWalmartCampaignRes, "DeleteWalmartCampaignRes");
      message.destroy();
      message.success("Deleted Successfully");
      fakeActionCampaignManagement("DeleteWalmartCampaignResponse");
    } else if (DeleteWalmartCampaignRes.status === false) {
      // setLoading(false);
      message.destroy();
      message.error(DeleteWalmartCampaignRes?.data?.[0]?.details);
      fakeActionCampaignManagement("DeleteWalmartCampaignResponse");
    }
  }, [DeleteWalmartCampaignRes]);

  useEffect(() => {
    if (GetSavedCamapignDetailsByCIDRes.status === true) {
      setEditCampaignData(GetSavedCamapignDetailsByCIDRes?.data || []);
      setCampaignOperation("add");
      setSelectedTab("campaign-creation");
      setIsCampaignId(
        GetSavedCamapignDetailsByCIDRes?.data?.campaigns?.[0]?.campaignId
      );
      message.destroy();
      fakeActionCampaignManagement("GetSavedCamapignDetailsByCIDResponse");
    } else if (GetSavedCamapignDetailsByCIDRes.status === false) {
      message.destroy();
      message.error(GetSavedCamapignDetailsByCIDRes?.data?.[0]?.details);
      fakeActionCampaignManagement("GetSavedCamapignDetailsByCIDResponse");
    }
  }, [GetSavedCamapignDetailsByCIDRes]);

  useEffect(() => {
    if (GetDirectWalmartCampaignListRes.status === true) {
      setPage(1);
      GetWalmartCampaignList({
        page: page,
        perPage: pageSize,
        campaign_type: campaignType,
        targeting_type: targetingType,
        status: campaignStatus,
        search_key: searchTerm,
        sort:
          sortFilters?.type === "ascend"
            ? "asc"
            : sortFilters?.type === "descend"
            ? "desc"
            : "",
        field_name: sortFilters?.key,
      });
      fakeActionCampaignManagement("GetDirectWalmartCampaignListResponse");
    } else if (GetDirectWalmartCampaignListRes.status === false) {
      setPage(1);
      GetWalmartCampaignList({
        page: page,
        perPage: pageSize,
        campaign_type: campaignType,
        targeting_type: targetingType,
        status: campaignStatus,
        search_key: searchTerm,
        sort:
          sortFilters?.type === "ascend"
            ? "asc"
            : sortFilters?.type === "descend"
            ? "desc"
            : "",
        field_name: sortFilters?.key,
      });
      fakeActionCampaignManagement("GetDirectWalmartCampaignListResponse");
    }
  }, [GetDirectWalmartCampaignListRes]);

  const onPageNo = (e) => {
    setLoading(true);
    const apiObj = {
      page: e,
      perPage: pageSize,
      campaign_type: campaignType,
      targeting_type: targetingType,
      status: campaignStatus,
      // start_date: moment(filters?.start_date?.$d).format("YYYY-MM-DD"),
      // end_date: "9999-12-30",
      search_key: searchTerm,
      sort:
        sortFilters?.type === "ascend"
          ? "asc"
          : sortFilters?.type === "descend"
          ? "desc"
          : "",
      field_name: sortFilters?.key,
    };
    GetWalmartCampaignList(apiObj);
    setPage(e);
  };

  const onPerPage = (e) => {
    setPage(1);
    setPageSize(e);
    setLoading(true);
    const apiObj = {
      page: 1,
      perPage: e,
      campaign_type: campaignType,
      targeting_type: targetingType,
      status: campaignStatus,
      // start_date: moment(filters?.start_date?.$d).format("YYYY-MM-DD"),
      // end_date: "9999-12-30",
      search_key: searchTerm,
      sort:
        sortFilters?.type === "ascend"
          ? "asc"
          : sortFilters?.type === "descend"
          ? "desc"
          : "",
      field_name: sortFilters?.key,
    };
    GetWalmartCampaignList(apiObj);
  };

  const handleOnChangeColumn = (_, __, sorter) => {
    console.log(_, __, sorter, "sorter");
    setSortFilters({
      key: sorter?.order ? sorter?.column?.isFilterKey : "",
      type: sorter?.order || "",
    });
    console.log(sorter?.order, "sorter?.order");
    setPage(1);
    setLoading(true);
    GetWalmartCampaignList({
      page: 1,
      perPage: pageSize,
      campaign_type: campaignType,
      targeting_type: targetingType,
      status: campaignStatus,
      // start_date: moment(filters?.start_date?.$d).format("YYYY-MM-DD"),
      // start_date: "2024-03-22",
      // // end_date: moment(filters?.end_date?.$d).format("YYYY-MM-DD"),
      end_date: "9999-12-30",
      search_key: searchTerm,
      sort:
        sorter?.order === "ascend"
          ? "asc"
          : sorter?.order === "descend"
          ? "desc"
          : "",
      field_name: sorter?.column?.isFilterKey,
    });
    // const Obj = {
    //   page: 1,
    //   perPage: pageSize,
    //   marketplaceId: GetSalesChannelListRes?.data?.sales_channel,
    //   type: "all",
    //   isExport: 0,
    //   sort:
    //     sorter?.order === "ascend"
    //       ? sorter?.column?.isFilterKey
    //       : `-${sorter?.column?.isFilterKey}`,
    // };
    // setLoading(true);
    // GetInventoryDetailsList(Obj);
  };

  const extraProps = (type) => {
    console.log(type, "type");
    if (type) {
      return {
        defaultSortOrder: type === sortFilters?.key ? sortFilters.type : [],
        sorter: () => {},
      };
    }
  };

  return (
    <>
      <div className="row gy-5 g-xl-5 fadeOutBottom">
        {/*begin::Col*/}
        <div className="col-xxl-12">
          {/*begin::Tables Widget 9*/}
          <div className="card card-xxl-stretch mb-5 mb-xl-8">
            {/*begin::Header*/}
            <div className="card-header border-bottom-dashed">
              <div className="d-flex flex-stack flex-wrap gap-4"></div>
              <div className="card-toolbar">
                <div className="position-relative me-2">
                  <Input
                    type="text"
                    className="text-dark w-250px fs-7 fw-bold ant_common_input"
                    name="all_search"
                    placeholder="Search by Campaign Name & Id..."
                    value={searchTerm}
                    size="large"
                    onChange={(e) => {
                      setSearchTerm(e.target.value);
                    }}
                    onPressEnter={(e) => {
                      setPage(1);
                      setLoading(true);
                      GetWalmartCampaignList({
                        page: 1,
                        perPage: pageSize,
                        campaign_type: campaignType,
                        targeting_type: targetingType,
                        status: campaignStatus,
                        // start_date: moment(filters?.start_date?.$d).format(
                        //   "YYYY-MM-DD"
                        // ),
                        // end_date: "9999-12-30",
                        search_key: e.target.value,
                        sort:
                          sortFilters?.type === "ascend"
                            ? "asc"
                            : sortFilters?.type === "descend"
                            ? "desc"
                            : "",
                        field_name: sortFilters?.key,
                      });
                    }}
                    prefix={
                      <SearchOutlined
                        style={{ fontSize: "19px", color: "grey" }}
                      />
                    }
                  />
                </div>
                <div className="position-relative">
                  {/* <div className="position-relative me-2">
                    <RangePickerIVCSR
                      className="ant_common_input"
                      id="ivcsr_date_picker"
                      size="large"
                      allowClear={false}
                      style={{ width: "290px" }}
                      format={DateFormat}
                      value={[filters?.start_date, filters?.end_date]}
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentNode
                      }
                      placement="bottomRight"
                      onChange={(e) => {
                        const filters_ = filters;
                        filters_.start_date = e?.[0];
                        filters_.end_date = e?.[1];
                        setFilters({ ...filters_ });
                        setPage(1);
                        setLoading(true);
                        GetWalmartCampaignList({
                          page: page,
                          perPage: pageSize,
                          campaign_type: campaignType,
                          targeting_type: targetingType,
                          status: campaignStatus,
                          // start_date: moment(e?.[0]?.$d).format("YYYY-MM-DD"),
                          // start_date: "2024-03-22",
                          // // end_date: moment(filters?.end_date?.$d).format("YYYY-MM-DD"),
                          // end_date: "9999-12-30",
                          search_key: searchTerm,
                          sort:
                            sortFilters?.type === "ascend"
                              ? "asc"
                              : sortFilters?.type === "descend"
                              ? "desc"
                              : "",
                          field_name: sortFilters?.key,
                        });
                      }}
                    />
                  </div> */}
                </div>
                <div className="position-relative me-2">
                  <Select
                    options={[
                      {
                        label: "Sponsored Products",
                        value: "sponsoredProducts",
                      },
                      { label: "Sponsored Brand ", value: "sponsoredBrand" },
                      { label: "Sponsored Video", value: "video" },
                    ]}
                    style={{ width: "170px" }}
                    allowClear
                    placeholder="Campaign Type"
                    value={campaignType}
                    onChange={(e) => {
                      setCampaignType(e);
                      setPage(1);
                      setLoading(true);
                      GetWalmartCampaignList({
                        page: page,
                        perPage: pageSize,
                        campaign_type: e,
                        targeting_type: targetingType,
                        status: campaignStatus,
                        // start_date: moment(filters?.start_date?.$d).format(
                        //   "YYYY-MM-DD"
                        // ),
                        // end_date: "9999-12-30",
                        search_key: searchTerm,
                        sort:
                          sortFilters?.type === "ascend"
                            ? "asc"
                            : sortFilters?.type === "descend"
                            ? "desc"
                            : "",
                        field_name: sortFilters?.key,
                      });
                    }}
                    size="large"
                  />
                </div>
                <div className="position-relative me-2">
                  <Select
                    options={[
                      {
                        label: "Auto",
                        value: "auto",
                      },
                      { label: "Manual", value: "manual" },
                    ]}
                    allowClear
                    style={{ width: "170px" }}
                    placeholder="Targeting Type"
                    value={targetingType}
                    onChange={(e) => {
                      setTargetingType(e);
                      setPage(1);
                      setLoading(true);
                      GetWalmartCampaignList({
                        page: page,
                        perPage: pageSize,
                        campaign_type: campaignType,
                        targeting_type: e,
                        status: campaignStatus,
                        // start_date: moment(filters?.start_date?.$d).format(
                        //   "YYYY-MM-DD"
                        // ),
                        // end_date: "9999-12-30",
                        search_key: searchTerm,
                        sort:
                          sortFilters?.type === "ascend"
                            ? "asc"
                            : sortFilters?.type === "descend"
                            ? "desc"
                            : "",
                        field_name: sortFilters?.key,
                      });
                    }}
                    size="large"
                  />
                </div>
                <div className="position-relative me-2">
                  <Select
                    options={[
                      {
                        label: "Paused",
                        value: "Paused",
                      },
                      { label: "Live", value: "Live" },
                      { label: "Proposal", value: "Proposal" },
                      { label: "Completed", value: "Completed" },
                      { label: "Scheduled", value: "Scheduled" },
                    ]}
                    allowClear
                    style={{ width: "170px" }}
                    placeholder="Status"
                    value={campaignStatus}
                    onChange={(e) => {
                      setCampaignStatus(e);
                      setLoading(true);
                      setPage(1);
                      GetWalmartCampaignList({
                        page: page,
                        perPage: pageSize,
                        campaign_type: campaignType,
                        targeting_type: targetingType,
                        status: e,
                        // start_date: moment(filters?.start_date?.$d).format(
                        //   "YYYY-MM-DD"
                        // ),
                        // end_date: "9999-12-30",
                        search_key: searchTerm,
                        sort:
                          sortFilters?.type === "ascend"
                            ? "asc"
                            : sortFilters?.type === "descend"
                            ? "desc"
                            : "",
                        field_name: sortFilters?.key,
                      });
                    }}
                    size="large"
                  />
                </div>
              </div>
            </div>
            {/*end::Header*/}
            {/*begin::Body*/}
            <div className="card-body py-2">
              {/*begin::Table container*/}
              <div className="mt-1">
                <div className="position-relative">
                  <Tooltip title="Refresh Campaigns" placement="right">
                    <button
                      className="btn btn-icon btn-info"
                      onClick={() => {
                        setLoading(true);
                        GetDirectWalmartCampaignAction();
                      }}
                      disabled={loading}
                    >
                      <i className="ki-outline ki-arrows-circle fs-1 customIcon" />
                      {/* <img
                        src="/assets/media/refresh_.png"
                        style={{ width: "24px", objectFit: "contain" }}
                      /> */}
                    </button>
                  </Tooltip>
                </div>
              </div>
              <div className="table-responsive mt-2">
                {/*begin::Table*/}
                {loading ? (
                  <Loading />
                ) : list?.length != 0 ? (
                  <>
                    <IvcsrTable
                      columns={columns
                        ?.map((d) => ({
                          ...d,
                          ...extraProps(d?.isFilterKey),
                        }))
                        ?.map((d) => ({
                          ...d,
                          width:
                            d?.title === "Name" ? 300 : d?.title?.length * 13,
                        }))}
                      sticky={{
                        offsetHeader: "10px",
                      }}
                      scroll={{ x: "max-content", y: "calc(100vh - 300px)" }}
                      fixed="top"
                      onChange={handleOnChangeColumn}
                      dataSource={list}
                      rowKey="key"
                      loading={loading}
                      pagination={false}
                    />
                  </>
                ) : (
                  <NoData />
                )}
                {/*end::Table*/}
              </div>
              <Pagination
                loading={loading || list?.length === 0}
                pageSize={pageSize}
                page={page}
                totalPage={totalPage}
                onPerPage={onPerPage}
                onPageNo={onPageNo}
              />
            </div>
            {/*begin::Body*/}
          </div>
          {/*end::Tables Widget 9*/}
        </div>
        {/*end::Col*/}
      </div>
    </>
  );
}
