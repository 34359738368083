import axiosCall from "../../../configurations/network-services/axiosCall";

// const prefix = "dashboard";

export const GetReturnCenterAddress = (data) => {
  const path = `wm/return-center`;
  const responseType = "GET_RETURN_CENTER_ADDRESS";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const DeleteReturnCenterAddress = (id) => {
  const path = `wm/return-center/${id}`;
  const responseType = "DELETE_RETURN_CENTER_ADDRESS";
  return axiosCall("DELETE", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const SaveReturnCenterAddr = (data, id) => {
  const path = id ? `wm/return-center/${id}` : `wm/return-center`;
  const method = id ? `PUT` : `POST`;
  const responseType = "SAVE_RETURN_CENTER_ADDR";
  return axiosCall(method, path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const fakeActionuserConfiguration = (data) => async (dispatch) => {
  dispatch({ type: "FAKE_ACTION_CONFIGURATION", state: data });
};
