import styled from "styled-components";

export const ProfileWrapper = styled.div`
  .ant-upload-list {
    display: block !important;
  }
  .ant-upload-list-item-actions {
    display: none !important;
  }
  .ant-upload-wrapper.ant-upload-picture-card-wrapper
    .ant-upload-list.ant-upload-list-picture-card
    .ant-upload-list-item:hover::before {
    opacity: 0 !important;
  }
`;
