import { Select, message } from "antd";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
export default function (props) {
  const {
    show,
    onHide,
    supplierList,
    selectedCheckbox,
    marketplace_id,
    SaveBulkInventoryAction,
    fakeActionInventoryManagement,
    onSuccess,
  } = props;
  const [selectedSupplier, setSelectedSupplier] = useState(null);

  const SaveBulkInventoryRes = useSelector(
    (state) => state.InventoryManagement.SaveBulkInventoryResponse || {}
  );
  useEffect(() => {
    if (SaveBulkInventoryRes?.status === true) {
      message.destroy();
      message.success(SaveBulkInventoryRes?.message);
      onSuccess();
      fakeActionInventoryManagement("SaveBulkInventoryResponse");
    } else if (SaveBulkInventoryRes?.status === false) {
      message.destroy();
      message.error(SaveBulkInventoryRes.message);
      fakeActionInventoryManagement("SaveBulkInventoryResponse");
    }
  }, [SaveBulkInventoryRes]);
  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          onHide();
        }}
        size="sm"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Supplier</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <Select
              className=""
              size="large"
              style={{
                width: "100%",
              }}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              placeholder="Select Supplier"
              value={selectedSupplier || null}
              onChange={(e) => {
                setSelectedSupplier(e);
              }}
              options={supplierList?.map((d) => {
                return {
                  label: d?.supplier_name,
                  value: d?.id,
                };
              })}
            />
          </>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <button
              className="btn btn-light-danger me-2"
              onClick={() => {
                onHide();
              }}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary"
              onClick={() => {
                message.loading("Loading...", 0);
                const Obj = {
                  skus: selectedCheckbox.join(","),
                  marketplace_id: marketplace_id,
                  supplier_id: selectedSupplier,
                };
                SaveBulkInventoryAction(Obj);
              }}
            >
              Save
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
