import React, { Suspense } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import Pages from "./containers";
import LoginIVCSR from "./containers/auth/index";
import SignUpIVCSR from "./containers/auth/sign-up";
import ActivateUserIVCSR from "./containers/auth/activate-user";

function AppRoute(props) {
  return (
    <BrowserRouter history={props.history}>
      <Suspense>
        <Switch>
          <Route path="/login" component={LoginIVCSR} exact />
          <Route path="/create-account" component={SignUpIVCSR} exact />
          <Route path="/user/activate" component={ActivateUserIVCSR} exact />
          <Route path="/" component={Pages} />
          <Redirect to="/" />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
}

export default AppRoute;
