export default (state, action) => {
  if (typeof state === "undefined") {
    return [];
  }
  switch (action.type) {
    case "GET_WALMART_ORDERS_SUCCESS":
    case "GET_WALMART_ORDERS_ERROR":
      return {
        ...state,
        GetWalmartOrdersResponse: action.updatePayload,
      };

    case "GET_WALMART_ORDERS_WITH_ALL_PRODUCTS_SUCCESS":
    case "GET_WALMART_ORDERS_WITH_ALL_PRODUCTS_ERROR":
      return {
        ...state,
        GetWalmartOrdersWithAllProductResponse: action.updatePayload,
      };

    case "GET_DIRECT_WALMART_ORDERS_SUCCESS":
    case "GET_DIRECT_WALMART_ORDERS_ERROR":
      return {
        ...state,
        GetDirectWalmartOrdersResponse: action.updatePayload,
      };

    case "GET_WALMART_ORDERS_DETAILS_SUCCESS":
    case "GET_WALMART_ORDERS_DETAILS_ERROR":
      return {
        ...state,
        GetWalmartOrdersDetailsResponse: action.updatePayload,
      };

    case "WALMART_SHIPMENT_ITEMS_SUCCESS":
    case "WALMART_SHIPMENT_ITEMS_ERROR":
      return {
        ...state,
        WalmartShipmentItemsResponse: action.updatePayload,
      };

    case "WALMART_CANCEL_ITEMS_SUCCESS":
    case "WALMART_CANCEL_ITEMS_ERROR":
      return {
        ...state,
        WalmartCancelItemsResponse: action.updatePayload,
      };

    case "WALMART_ACKNOWLEDGE_ORDER_SUCCESS":
    case "WALMART_ACKNOWLEDGE_ORDER_ERROR":
      return {
        ...state,
        WalmartAcknowledgeOrderResponse: action.updatePayload,
      };

    case "FAKE_ACTION_WALMART_ORDERS":
      return { ...state, [action?.state]: {} };

    default:
      return state;
  }
};
