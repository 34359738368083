import React from "react";
export default function () {
  return (
    <div className="row g-5 g-xl-5 mb-4 mb-xl-0 d-none">
      <div className="col-md-3">
        <div className="card card-xxl-stretch mb-5 mb-xl-8">
          {/*begin::Header*/}
          <div className="card-header border-bottom-0 min-h-45px ">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label fw-bolder text-dark">
                Total Ad Spend
              </span>
              {/* <span class="text-gray-400 pt-1 fw-semibold fs-6">Sales analytics by SKU</span> */}
            </h3>
            <div className="card-toolbar"></div>
          </div>
          {/*end::Header*/}
          {/*begin::Body*/}
          <div className="card-body py-0 px-2">
            <div id="chartdiv" style={{ height: 300, zoom: "118%" }} />
          </div>
          {/*begin::Body*/}
        </div>
      </div>
      <div className="col-md-3">
        <div className="card card-xxl-stretch mb-5 mb-xl-8">
          {/*begin::Header*/}
          <div className="card-header border-bottom-0 min-h-45px ">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label fw-bolder text-dark">
                Total Ad Sales
              </span>
              {/* <span class="text-gray-400 pt-1 fw-semibold fs-6">Sales analytics by SKU</span> */}
            </h3>
            <div className="card-toolbar"></div>
          </div>
          {/*end::Header*/}
          {/*begin::Body*/}
          <div className="card-body py-2 px-2">
            <div id="chartdiv2" style={{ height: 300, zoom: "118%" }} />
          </div>
          {/*begin::Body*/}
        </div>
      </div>
      <div className="col-md-3">
        <div className="card card-xxl-stretch mb-5 mb-xl-8">
          {/*begin::Header*/}
          <div className="card-header border-bottom-0 min-h-45px ">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label fw-bolder text-dark">
                Total TACoS
              </span>
              {/* <span class="text-gray-400 pt-1 fw-semibold fs-6">Sales analytics by SKU</span> */}
            </h3>
            <div className="card-toolbar"></div>
          </div>
          {/*end::Header*/}
          {/*begin::Body*/}
          <div className="card-body py-2 px-2">
            <div id="chartdiv3" style={{ height: 300, zoom: "118%" }} />
          </div>
          {/*begin::Body*/}
        </div>
      </div>
      <div className="col-md-3">
        <div className="card card-xxl-stretch mb-5 mb-xl-8">
          {/*begin::Header*/}
          <div className="card-header border-bottom-0 min-h-45px ">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label fw-bolder text-dark">Total RoAS</span>
              {/* <span class="text-gray-400 pt-1 fw-semibold fs-6">Sales analytics by SKU</span> */}
            </h3>
            <div className="card-toolbar"></div>
          </div>
          {/*end::Header*/}
          {/*begin::Body*/}
          <div className="card-body py-2 px-2">
            <div id="chartdiv4" style={{ height: 300, zoom: "118%" }} />
          </div>
          {/*begin::Body*/}
        </div>
      </div>
    </div>
  );
}
