import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ManageReports from "../../modules/pages/manage-reports";

// import {
//   GetSupplierDataList,
//   SaveSupplierAction,
//   DeleteSupplierAction,
//   fakeActionSupplier,
// } from "../../../redux/modules/master-table/supplier/supplier.action";

const mapStateToProps = (state) => ({
  //   GetSupplierDataListResponse: state.Supplier.GetSupplierDataListResponse,
  //   SaveSupplierResponse: state.Supplier.SaveSupplierResponse,
  //   DeleteSupplierResponse: state.Supplier.DeleteSupplierResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      //   GetSupplierDataList,
      //   SaveSupplierAction,
      //   DeleteSupplierAction,
      //   fakeActionSupplier,
    },
    dispatch
  );

const ManageReportsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageReports);

export default ManageReportsContainer;
