import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import InventoryDetails from "../../../modules/pages/inventory/inventory-details";

import {
  GetInventoryDetailsList,
  GetWalmartInventoryDetailsList,
  fakeActionInventoryDetails,
} from "../../../redux/modules/inventory/inventory-details/inventory-details.action";
import {
  GetSalesChannelList,
  fakeActionSalesAnalytics,
} from "../../../redux/modules/sales-analytics/sales-analytics.action";

const mapStateToProps = (state) => ({
  GetInventoryDetailsListResponse:
    state.InventoryDetails.GetInventoryDetailsListResponse,
  GetWalmartInventoryDetailsListResponse:
    state.InventoryDetails.GetWalmartInventoryDetailsListResponse,
  GetSalesChannelListResponse: state.SalesAnalytics.GetSalesChannelListResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      GetInventoryDetailsList,
      GetWalmartInventoryDetailsList,
      fakeActionInventoryDetails,
      GetSalesChannelList,
      fakeActionSalesAnalytics,
    },
    dispatch
  );

const InventoryDetailsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(InventoryDetails);

export default InventoryDetailsContainer;
