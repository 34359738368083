import { Dropdown, Skeleton, Tooltip, message } from "antd";
import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { NumberWithCommas } from "../../../../config";
import Chart from "react-apexcharts";
import {
  FilePdfOutlined,
  FileExcelOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { savePDF } from "@progress/kendo-react-pdf";
import writeXlsxFile from "write-excel-file";
const AllCampaignPerformanceSummery = (props) => {
  const {
    performanceSummary,
    chartSeries,
    chartDate,
    summaryLoading,
    chartLoading,
    exportGraphArray,
    selectedPerformance,
  } = props;
  const chartRefFirstGraph = useRef(null);
  // const [performanceSummary, setPerformanceSummary] = useState({});
  const [selectedFilters, setSelectedFilters] = useState([
    "impression",
    "ad_clicks",
    "CTR",
    "ad_spend",
    "units",
    "orders",
    "CRUnitSold",
    "otherSkuSales",
    "CROrderSold",
    "sales_revenue",
    "new_to_brand_order",
    "ROAS",
    "advertisedSkuSales",
    "in_store_advertised_sales",
    "in_store_attributed_sales",
    "in_store_orders",
    "in_store_other_sales",
    "in_store_units_sold",
  ]);
  const options = {
    chart: {
      height: 350,
      type: "area",
      toolbar: { show: false },
      zoom: {
        enabled: false,
      },
    },
    colors: ["#4318FF", "#000", "#367d19"],
    dataLabels: { enabled: !1 },
    stroke: {
      show: !0,
      curve: "smooth",
      width: [2, 2, 2],
      dashArray: [0, 0],
      lineCap: "round",
    },

    xaxis: {
      tickPlacement: "between",
      tickAmount: window.innerWidth >= 992 ? 15 : 5,
      categories:
        chartDate?.map((d) => moment(new Date(d)).format("DD MMM YYYY")) || [],
      labels: {
        rotate: -50,
      },
    },
    //    yaxis: {
    // 	 labels: {
    // 	   formatter: function (_) {
    // 		 return _ + "";
    // 	   },
    // 	   offsetX: -12,
    // 	   offsetY: 0,
    // 	 },
    //    },
    yaxis: [
      {
        axisTicks: {
          show: true,
        },

        labels: {
          formatter: function (_) {
            return _ + "";
          },
          offsetX: -12,
          offsetY: 0,
        },
      },
      {
        opposite: true,
        axisTicks: {
          show: true,
        },

        labels: {
          formatter: function (_) {
            return _ + "";
          },
          offsetX: -12,
          offsetY: 0,
        },
      },
    ],
    grid: {
      strokeDashArray: 3,
      xaxis: { lines: { show: !0 } },
      yaxis: { lines: { show: !1 } },
    },
    legend: { show: true },
    fill: {
      type: "gradient",
      gradient: {
        type: "vertical",
        shadeIntensity: 1,
        inverseColors: !1,
        opacityFrom: 0.41,
        opacityTo: 0.2,
        stops: [45, 100],
      },
    },
    tooltip: {
      marker: {
        show: true,
      },
      x: {
        show: true,
      },
      y: {
        formatter: function (
          value,
          { series, seriesIndex, dataPointIndex, w }
        ) {
          const withTitle_ = w?.config?.series?.[seriesIndex];
          console.log(withTitle_, "withTitle_");
          return withTitle_?.name !== "ad spend"
            ? value || 0
            : "$" + NumberWithCommas(value);
        },
      },
    },
  };

  const [swiper, setSwiper] = useState(null);

  const handleNext = () => {
    if (swiper !== null) {
      swiper.slideNext();
    }
  };

  const handlePrev = () => {
    if (swiper !== null) {
      swiper.slidePrev();
    }
  };

  const handleExportPDF = () => {
    savePDF(chartRefFirstGraph.current, {
      paperSize: "auto",
      fileName: "chart.pdf",
    });
  };

  const header = [
    { value: "Date", type: String },
    { value: "Ad Spend", type: String },
    { value: "Impressions", type: String },
    { value: "Ad Clicks", type: String },
  ];

  const downloadXlAmazon = async () => {
    let dataRow = [];
    await exportGraphArray.map((d) => {
      const newList = [d?.date, d?.ad_spend, d?.impression, d?.ad_clicks];
      dataRow = [
        ...dataRow,
        newList.map((d) => {
          return { value: d };
        }),
      ];
    });
    const data = [header, ...dataRow];
    await writeXlsxFile(data, {
      fileName: `${selectedPerformance}.xlsx`,
    });
    message.destroy();
  };

  return (
    <>
      <div className="mt-5">
        <div>
          {summaryLoading ? (
            <div
              className="d-flex justify-content-between"
              style={{ width: "100%" }}
            >
              <Skeleton.Button
                active
                style={{ height: "50px", width: "110px" }}
              />
              <Skeleton.Button
                active
                style={{ height: "50px", width: "110px" }}
              />
              <Skeleton.Button
                active
                style={{ height: "50px", width: "110px" }}
              />
              <Skeleton.Button
                active
                style={{ height: "50px", width: "110px" }}
              />
              <Skeleton.Button
                active
                style={{ height: "50px", width: "110px" }}
              />
              <Skeleton.Button
                active
                style={{ height: "50px", width: "110px" }}
              />
            </div>
          ) : (
            <div className="d-flex">
              <button
                onClick={handlePrev}
                className="btn btn-light-info me-4"
                style={{ padding: "5px", fontSize: "25px" }}
              >
                <LeftOutlined />
              </button>
              <Swiper
                style={{ height: "100px" }}
                spaceBetween={10}
                className="mySwiper"
                navigation={true}
                onSwiper={setSwiper}
                pagination={{
                  type: "fraction",
                }}
                slidesPerView={
                  window.innerWidth <= 992
                    ? 4
                    : window.innerWidth >= 992 && window.innerWidth <= 1100
                    ? 5
                    : window.innerWidth >= 1100 && window.innerWidth <= 1250
                    ? 6
                    : window.innerWidth >= 1250 && window.innerWidth <= 1350
                    ? 8
                    : 9
                }
              >
                {selectedFilters?.map((d) => {
                  return (
                    <SwiperSlide>
                      <span
                        className="parent-hover cursor-pointer d-flex align-items-center flex-md-row-fluid py-lg-2 px-0 px-4  me-4"
                        style={{
                          borderRadius: "5px",
                          // borderTop: "2px solid #4318FF",
                          background: "#efedff",
                          height: "100%",
                        }}
                      >
                        {/*begin::Info*/}
                        <div className="d-flex justify-content-center flex-column">
                          <span className="text-gray-700 fw-semibold d-block fs-6">
                            {d === "ad_spend"
                              ? "Ad Spend"
                              : d === "advertisedSkuSales"
                              ? "Advertised SKU Sales"
                              : d === "ad_clicks"
                              ? "Clicks"
                              : d === "CROrderSold"
                              ? "Conversion Rate (Orders Sold Based)"
                              : d === "CRUnitSold"
                              ? "Conversion Rate (Unit Sold Based)"
                              : d === "CTR"
                              ? "CTR"
                              : d === "impression"
                              ? "Impression"
                              : d === "new_to_brand_order"
                              ? "New-to-Brand Orders"
                              : d === "orders"
                              ? "Orders"
                              : d === "otherSkuSales"
                              ? "Other SKU Sales"
                              : d === "ROAS"
                              ? "RoAS"
                              : d === "sales_revenue"
                              ? "Total Attr. Sales Revenue"
                              : d === "units"
                              ? "Unit Sold"
                              : d === "in_store_advertised_sales"
                              ? "In Store Advertised Sales"
                              : d === "in_store_attributed_sales"
                              ? "In Store Attributed Sales"
                              : d === "in_store_orders"
                              ? "In Store Orders"
                              : d === "in_store_other_sales"
                              ? "In Store Other Sales"
                              : "In Store Unit Sold"}
                          </span>

                          <span className="fw-bold text-gray-800 parent-hover-primary fs-5 mt-1">
                            {d === "ad_spend"
                              ? "$"
                              : d === "advertisedSkuSales"
                              ? "$"
                              : d === "otherSkuSales"
                              ? "$"
                              : d === "ROAS"
                              ? "$"
                              : d === "sales_revenue"
                              ? "$"
                              : ""}
                            {NumberWithCommas(performanceSummary[d] || 0)}
                            {d === "CROrderSold"
                              ? "%"
                              : d === "CRUnitSold"
                              ? "%"
                              : d === "CTR"
                              ? "%"
                              : d === "new_to_brand_order"
                              ? "%"
                              : ""}
                          </span>
                        </div>
                        {/*end::Info*/}
                      </span>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
              <button
                onClick={handleNext}
                className="btn btn-light-info"
                style={{ padding: "5px", fontSize: "25px" }}
              >
                <RightOutlined />
              </button>
            </div>
          )}
        </div>
      </div>

      <div className="d-flex justify-content-end mt-5">
        {chartLoading ? (
          <Skeleton.Button active className="me-2" />
        ) : (
          <span
            className="d-flex align-items-center cursor-pointer me-5"
            onClick={() => {
              handleExportPDF();
            }}
          >
            <Tooltip title="Download PDF" placement="bottom">
              <FilePdfOutlined className="fs-1" style={{ color: "red" }} />
            </Tooltip>
          </span>
        )}

        {chartLoading ? (
          <Skeleton.Button active />
        ) : (
          <span
            className="d-flex align-items-center cursor-pointer"
            onClick={() => {
              downloadXlAmazon();
            }}
          >
            <Tooltip title="Download Excel" placement="bottom">
              <FileExcelOutlined className="fs-1" style={{ color: "blue" }} />
            </Tooltip>
          </span>
        )}
      </div>
      {chartLoading ? (
        <div className="d-flex justify-content-center my-5">
          <Skeleton.Node active />
        </div>
      ) : (
        <div id="chartZoom" className="w-100 mt-0" ref={chartRefFirstGraph}>
          <Chart
            options={options}
            series={chartSeries}
            type="area"
            height={"400px"}
          />
        </div>
      )}
    </>
  );
};

export default AllCampaignPerformanceSummery;
