import { createGlobalStyle } from "styled-components";

export const AppStyleWrapper = createGlobalStyle`
    #root {
        height: 100%;
    }
    // .app-header{
    //   z-index:1051 !important;
    // }
    .ant-table-sticky-scroll{
      display:none !important;
    }
    ::selection {
        color: #181c32;
        background: #dedbf7;
    }
    .ant-message{
      z-index: 1070 !important;
    }
    .ant-table-wrapper .ant-table-thead >tr>th {
      background: #f1effc;
    }
    .ant-table-wrapper td.ant-table-column-sort {
      background: #ffffff !important;
    }
    .ant-table-wrapper .ant-table-thead th.ant-table-column-sort {
      background: #f1effc  !important;
    }
    ::-webkit-scrollbar {
      width: 12px !important;
      height: 12px !important;
      background-color: transparent;
    }
    
    ::-webkit-scrollbar-track {
      border-radius: 8px;
      background-color: transparent !important;
      border: 2px solid transparent;
    }
    
    ::-webkit-scrollbar-thumb {
      border: 3px solid transparent !important;
      background-clip: content-box;
      background-color: transparent !important;
      border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background-color: #9b87ff !important;
    }
    &:hover {
      &::-webkit-scrollbar {
        width: 12px !important;
        background-color: transparent;
      }
    
      &::-webkit-scrollbar-track {
        border-radius: 8px;
        background-color: transparent;
        background-color: #9b87ff;
        border: 1px solid #d1d1d1;
      }
    
      &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        border: 3px solid transparent;
        background-clip: content-box;
        background-color: #9b87ff !important;
        border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background-color: #9b87ff !important;
      }
    }
    

    .ant-select-selector{
      // border-color: #ecf0f4 !important;
      // background-color: #ecf0f4 !important;
      // border-radius:4px !important;

      border-radius:4px !important;
      background-color: #f5f3ff !important;
      border-color: #d2caff !important;
    }
    .ant-select-selection-item{
        font-size: 1rem !important;
        font-weight: bold !important;
        color: #00000099 !important;
    }
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        position: relative !important; 
        box-shadow: none !important;
    }
    .ant-select-selection-placeholder{
        color: #00000075 !important;
        font-weight : bold;
        font-size: 1rem !important;
    }
    .ant-select-item-option-selected{
        background-color : #f1f1f1 !important;
        color :#4318ff !important;
    }
    .ant-select-multiple > .ant-select-selector{
        padding: 1px 4px !important;
    }
    .ant-select-lg{
      height: 42px !important;
    }

    .fs-1-5{
      font-size: 1.5rem !important;
    }

    @keyframes fadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }

    .fade-in {
      opacity: 0;
      animation: fadeIn ease-in 1;
      animation-fill-mode: forwards;
      animation-duration: 0.4s;
    }

    .authFadeInBottom {
      animation-duration: 1s;
      animation-fill-mode: both;
      animation-name: authFadeInBottom;
      z-index : 99
    }


    .fadeContentDiv {
      animation-duration: 0.7s;
      animation-fill-mode: both;
      animation-name: fadeContentDiv;
    }
    
    @keyframes fadeContentDiv {
      from {
        opacity: 0;
        transform:  translateY(-100px); 
      }
      to {
        opacity: 1;
      }
    }

    .fadeInRight {
      animation-duration: 0.7s;
      animation-fill-mode: both;
      animation-name: fadeInRight;
    }
    
    @keyframes fadeInRight {
      from {
        opacity: 0;
        transform: translate3d(100px, 100px, 100px); 
      }
      to {
        opacity: 1;
      }
    }


    .fadeInRightFast {
      animation-duration: 0.5s;
      animation-fill-mode: both;
      animation-name: fadeInRightFast;
    }
    
    @keyframes fadeInRightFast {
      from {
        opacity: 0;
        transform: translateX(100px); 
      }
      to {
        opacity: 1;
        transform: translateY(0px); 
      }
    }

    .fadeInTop {
      animation-duration: 0.7s;
      animation-fill-mode: both;
      animation-name: fadeInTop;
    }
    
    @keyframes fadeInTop {
      from {
        opacity: 0;
        transform: translateY(-200px); 
      }
      to {
        opacity: 1;
      }
    }

    .fadeInTopUser {
      animation-duration: 0.7s;
      animation-fill-mode: both;
      animation-name: fadeInTopUser;
    }
    
    @keyframes fadeInTopUser {
      from {
        opacity: 0;
        transform: translateY(-200px); 
      }
      to {
        opacity: 1;
      }
    }

    .fadeOutBottom {
      animation-duration: 0.5s;
      animation-fill-mode: both;
      animation-name: fadeOutBottom;
    }
    
    @keyframes fadeOutBottom {
      0% {
        opacity: 0;        
        transform: translateY(200px); 
      }
      100% {
        opacity: 1;        
        transform: translateY(0px); 
      }
    }

    .fadeInLeft{
      animation-duration: 0.5s;
      animation-fill-mode: both;
      animation-name: fadeInLeft;
    }
    
    @keyframes fadeInLeft {
      0% {
        opacity: 0;        
        transform: translateX(-200px); 
      }
      100% {
        opacity: 1;               
      }
    }

    .fadeOutLeft {
      animation-duration: 1s;
      animation-fill-mode: both;
      animation-name: fadeOutLeft;
    }
    
    @keyframes fadeOutLeft {
      0% {
        opacity: 0;        
        transform: translate(0px); 
      }
      100% {
        opacity: 1;        
        transform: translateX(-300px); 
      }
    }


    .fadeInLeftSlow{
      animation-duration: 1s;
      animation-fill-mode: both;
      animation-name: fadeInLeftSlow;
    }
    
    @keyframes fadeInLeftSlow {
      0% {
        opacity: 0;        
        transform: translateX(-200px); 
      }
      100% {
        opacity: 1;               
      }
    }

    
    .fadeInBottom {
      animation-duration: 5s;
      animation-fill-mode: both;
      animation-name: fadeInBottom;
      z-index : 99
    }

    @keyframes fadeInBottom {
      from {
        opacity: 0;
        transform: translateY(-25%);
      }
      to {
        opacity: 1;
      }
    }

    .shimmer {
      -webkit-mask: linear-gradient(-60deg, rgb(0, 0, 0) 30%, rgba(0, 0, 0, 0.11), rgb(0, 0, 0) 70%) right center / 300% 100%;
      background-repeat: no-repeat;
      animation: 2.5s ease 0s infinite normal none running shimmer;
    }

    @keyframes shimmer{
      100% {
        -webkit-mask-position: left center;
      }
    }

    .shinnerLoader {
      -webkit-mask: linear-gradient(-60deg, rgb(0, 0, 0) 30%, rgba(0, 0, 0, 0.11), rgb(0, 0, 0) 70%) right center / 300% 100%;
      background-repeat: no-repeat;
      animation: 1.5s ease 0s infinite normal none running shinnerLoader;
    }

    @keyframes shinnerLoader{
      100% {
        -webkit-mask-position: left center;
      }
    }

    .actionIcon {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      cursor: pointer;
    }

    .ant_common_input {      
      transition: color .2s ease !important;
      border-radius:4px !important;
      background-color: #f5f3ff !important;
      border:1px solid #d2caff !important;
      // line-height: 2 !important;
    }
    .ant_common_input::placeholder {
        color:#aeaeae !important; 
    }
    .ant-input{
        box-shadow:none !important;
        background-color: #f5f3ff;
    }
    .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
    .ant-input:focus,
    .ant-input-focused,
    .ant-input:hover {
        border-color: #ecf0f4;
        box-shadow:none;
    }
    .ant-input-disabled{
      color: #8d8d8d !important;
    }
    .ant-input-affix-wrapper:focus, 
    .ant-input-affix-wrapper-focused {
        box-shadow:none !important;
    }

    .popoverActionIcon {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;    
      word-break: break-all;
      svg {
        font-size: 24px;
        color: #f8a3a8;
        cursor: pointer;
        &:hover {
          color: #f082b0;
        }
      }
    }

    .popoverActionIconSingleLine {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;    
      word-break: break-all;
      svg {
        font-size: 24px;
        color: #f8a3a8;
        cursor: pointer;
        &:hover {
          color: #f082b0;
        }
      }
    }

    .ant-input-affix-wrapper >input.ant-input{
        background:transparent !important;
    }
    .ant-input-affix-wrapper{
      height : 42px !important;
    }
    .ant-input-affix-wrapper-lg{
      padding:0px 11px !important;
      line-height:2.5 !important;
    }
    .min-w-1270px{
      min-width : 1330px !important
    }
    .min-w-1485px{
      min-width : 1485px !important
    }
    .apexcharts-xaxistooltip.apexcharts-theme-light{
      background:#fff !important;
    }

    .import-button {
      background: #7472ee;
      color: #fff;
      padding: 0 calc(1.5rem + 1px) !important;
      transition: 0.4s;
      height: 40px;
      padding-bottom: 5px !important;
      border: 1px dashed transparent !important;
      &:hover {
        border: 1px dashed #7472ee !important;
        background: transparent !important;
        color: #7472ee;
      }
      .anticon-cloud-download {
        font-size: 20px;
        margin-right: 7px;
        position: relative;
        top: 4px;
      }
    }
    .export-button {
      background: transparent !important;
      padding: 0 calc(1.5rem + 1px) !important;
      color: green !important;
      transition: 0.4s;
      height: 40px;
      padding-bottom: 2px !important;
      border: 1px dashed lightgreen !important;
      &:hover {
        color: darkgreen !important;
        background-color: lightgreen !important;
      }
      .anticon-cloud-upload {
        font-size: 20px;
        margin-right: 7px;
        position: relative;
        top: 4px;
      }
  }
    
  .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100% !important;
    height: 100% !important;
    background-color: #000;
  }

  #chartZoom {
    zoom: 118% !important;
  }
  .ant-upload-list {
    display:none;
  }
  // .active-keyword-div:hover div div .active-keyword-hover{
  //   text-decoration: underline !important; /* Change this to the desired text color */
  //   color :blue;
  // }
  .active-keyword-div div div .active-keyword-hover{
      text-decoration: underline !important; /* Change this to the desired text color */
      color :blue;
  }
  .note {
    height: 191px;
    overflow: auto;
    border: 1px solid rgba(136, 205, 255, 0.28);
    border-radius: 2px;
    margin: 10px 0px;
    width: 100%;
  }
  .note .TextEditor {
    height: 181px;
    min-height: 181px;
    width: 100%;
    resize: none;
    color: rgb(0, 0, 0);
    /* background-color: rgb(226, 229, 239); */
    border: none;
    background-repeat: repeat-y, repeat;
    background-size: 100% 40px;
    /* font-size: 12px; */
    font-weight: 500;
    line-height: 40px;
    padding: 10px 15px 19px;
    background-image: url(../../assets/media/Line-01.png);
    overflow: hidden !important;
  }
  .ant-table-wrapper .ant-table-thead >tr>td{
    background : #f1effc !important;
  }
  .ant-tooltip-arrow-content {
    --antd-arrow-background-color: linear-gradient(
      to right bottom,
     #fff,
      #ffffff
    ) !important;
  }
  .ant-tooltip-inner {
    background-color: #fff !important;
    font-weight: 500;
    color: #000 !important;
  }
  .ant-tooltip-arrow:before {
    background: white !important;
  }
  .ant-tooltip-inner {
    color: #000 !important;
    a {
      color: #000 !important;
    }
  }
  .rotate-0{
    transform: rotateZ(0deg);
    transition: transform .3s ease;
  }
  

`;
