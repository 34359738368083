import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import WalmartApiLog from "../../../modules/pages/application-log/api-log";
import {
  GetWalmartApiLogList,
  fakeActionWalmartApiLog,
} from "../../../redux/modules/application-log/api-log/api-log.action";
const mapStateToProps = (state) => ({
  GetWalmartApiLogListResponse:
    state.WalmartApiLog.GetWalmartApiLogListResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      GetWalmartApiLogList,
      fakeActionWalmartApiLog,
    },
    dispatch
  );

const WalmartApiLogContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(WalmartApiLog);

export default WalmartApiLogContainer;
