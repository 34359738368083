export default (state, action) => {
  if (typeof state === "undefined") {
    return [];
  }
  switch (action.type) {
    case "GET_FILTERS_LIST_SUCCESS":
    case "GET_FILTERS_LIST_ERROR":
      return {
        ...state,
        GetFiltersListResponse: action.updatePayload,
      };

    case "GET_DASHBOARD_GRAPH_CARD_DATA_SUCCESS":
    case "GET_DASHBOARD_GRAPH_CARD_DATA_ERROR":
      return {
        ...state,
        GetDashboardCardGraphDataResponse: action.updatePayload,
      };

    case "GET_DASHBOARD_SUB_CARD_DATA_SUCCESS":
    case "GET_DASHBOARD_SUB_CARD_DATA_ERROR":
      return {
        ...state,
        GetDashboardSubCardDataResponse: action.updatePayload,
      };

    case "GET_DASHBOARD_ACTIVE_CAMPAIGN_SUB_CARD_DATA_SUCCESS":
    case "GET_DASHBOARD_ACTIVE_CAMPAIGN_SUB_CARD_DATA_ERROR":
      return {
        ...state,
        GetActiveCampaignSubCardDataResponse: action.updatePayload,
      };

    case "GET_DASHBOARD_TARGETING_SUB_CARD_DATA_SUCCESS":
    case "GET_DASHBOARD_TARGETING_SUB_CARD_DATA_ERROR":
      return {
        ...state,
        GetTargetingSubCardDataResponse: action.updatePayload,
      };

    case "GET_DASHBOARD_DAILY_PERFORMANCE_TREND_DATA_SUCCESS":
    case "GET_DASHBOARD_DAILY_PERFORMANCE_TREND_DATA_ERROR":
      return {
        ...state,
        GetDailyPerformanceTrendDataResponse: action.updatePayload,
      };

    case "GET_DASHBOARD_TOP_SEARCH_TERM_DATA_SUCCESS":
    case "GET_DASHBOARD_TOP_SEARCH_TERM_DATA_ERROR":
      return {
        ...state,
        GetTopSearchTermDataResponse: action.updatePayload,
      };

    case "GET_WALMART_ADV_FILTER_SUCCESS":
    case "GET_WALMART_ADV_FILTER_ERROR":
      return {
        ...state,
        GetWalmartAdvFilterResponse: action.updatePayload,
      };

    case "GET_WALMART_ADV_PERFORMANCE_CHART_SUCCESS":
    case "GET_WALMART_ADV_PERFORMANCE_CHART_ERROR":
      return {
        ...state,
        GetWalmartAdvPerformanceChartResponse: action.updatePayload,
      };

    case "GET_WALMART_ADV_PERFORMANCE_SUMMARY_SUCCESS":
    case "GET_WALMART_ADV_PERFORMANCE_SUMMARY_ERROR":
      return {
        ...state,
        GetWalmartAdvPerformanceSummaryResponse: action.updatePayload,
      };

    case "GET_WALMART_CAMPAIGN_BY_AD_SPEND_SUCCESS":
    case "GET_WALMART_CAMPAIGN_BY_AD_SPEND_ERROR":
      return {
        ...state,
        GetWalmartCampaignAdSpendResponse: action.updatePayload,
      };

    case "GET_WALMART_CAMPAIGN_OUT_OF_BUDGET_SUCCESS":
    case "GET_WALMART_CAMPAIGN_OUT_OF_BUDGET_ERROR":
      return {
        ...state,
        GetWalmartCampaignOutOfBudgetResponse: action.updatePayload,
      };

    case "GET_WALMART_DASHBOARD_PERFORMANCE_METRICS_SUCCESS":
    case "GET_WALMART_DASHBOARD_PERFORMANCE_METRICS_ERROR":
      return {
        ...state,
        GetWalmartDashboardPerformanceMetricsResponse: action.updatePayload,
      };

    case "GET_AMAZON_ACTIVE_KEYWORDS_SUCCESS":
    case "GET_AMAZON_ACTIVE_KEYWORDS_ERROR":
      return {
        ...state,
        GetAmazonActiveKeywordsResponse: action.updatePayload,
      };

    case "FAKE_ACTION_ADV_DASHBOARD":
      return { ...state, [action?.state]: {} };
    default:
      return state;
  }
};
