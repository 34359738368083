import React, { useContext, useEffect } from "react";
import { GlobalContext } from "../../../../commonContext";
// import WalmartIndex from "./lib/walmart-index";
import { useHistory } from "react-router-dom";
import WalmartKeyword from "./walmart-keyword";

export default function (props) {
  const history = useHistory();
  const contextVar = useContext(GlobalContext);

  return contextVar?.data?.marketplaceType === "amazon" ? (
    history.replace("/dashboard")
  ) : (
    <WalmartKeyword {...props} />
  );
}
