import { Skeleton, Tag } from "antd";
import NoData from "../../../../component/no-data";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { SyncOutlined } from "@ant-design/icons";
export default function (props) {
  const {
    productData,
    allLoading,
    setAllLoading,
    suggestedDescAnalysis,
    setSuggestedDescAnalysis,
    recommendedDescription,
    setRecommendedDescription,
    GetCriteriaAnalysisProductDescription,
    GetRecommendationDescription,
    fakeActionListingOptimizer,
  } = props;

  const GetCriteriaAnalysisProductDescRes = useSelector(
    (state) =>
      state.ListingOptimizer.GetCriteriaAnalysisProductDescResponse || {}
  );

  const GetRecommendationDescriptionRes = useSelector(
    (state) => state.ListingOptimizer.GetRecommendationDescriptionResponse || {}
  );

  useEffect(() => {
    const loading = { ...allLoading };
    if (GetCriteriaAnalysisProductDescRes?.status === true) {
      console.log(
        GetCriteriaAnalysisProductDescRes,
        "GetCriteriaAnalysisProductDescRes"
      );
      loading.descAnalysis = false;
      setAllLoading(loading);
      GetCriteriaAnalysisProductDescRes?.data?.length > 0 &&
        setSuggestedDescAnalysis(
          JSON.parse(GetCriteriaAnalysisProductDescRes?.data?.matchCriteria)
        );

      fakeActionListingOptimizer("GetCriteriaAnalysisProductDescResponse");
    } else if (GetCriteriaAnalysisProductDescRes?.status === false) {
      loading.descAnalysis = false;
      setAllLoading(loading);
      fakeActionListingOptimizer("GetCriteriaAnalysisProductDescResponse");
    }
  }, [GetCriteriaAnalysisProductDescRes]);

  useEffect(() => {
    const loading = { ...allLoading };
    if (GetRecommendationDescriptionRes?.status === true) {
      console.log(
        GetRecommendationDescriptionRes,
        "GetRecommendationDescriptionRes"
      );
      setRecommendedDescription(
        GetRecommendationDescriptionRes?.data?.suggested_description
      );
      loading.descRecommendation = false;
      setAllLoading(loading);
      fakeActionListingOptimizer("GetRecommendationDescriptionResponse");
    } else if (GetRecommendationDescriptionRes?.status === false) {
      loading.descRecommendation = false;
      setAllLoading(loading);
      fakeActionListingOptimizer("GetRecommendationDescriptionResponse");
    }
  }, [GetRecommendationDescriptionRes]);

  return (
    <>
      {" "}
      <div className="col-xl-12">
        <div className="card card-flush h-lg-100 mb-5 mb-xl-5">
          <div
            className="card-header border-0 cursor-pointer min-h-50px"
            role="button"
            data-bs-toggle="collapse"
            data-bs-target="#kt_account_profile_details"
            aria-expanded="true"
            aria-controls="kt_account_profile_details"
          >
            <div className="card-title m-0">
              <h3 className="fw-bold m-0">
                Product Description{" "}
                <label className="ms-2">
                  <Tag color="#108ee9" className="fw-bolder fs-5">
                    Score : 0
                  </Tag>
                </label>
              </h3>
            </div>
          </div>

          <div className="card-body border-top border-top-dashed pt-5 pb-3">
            <div className="row mb-0">
              <div className="col-lg-6 ">
                {allLoading?.mainHeaderLoading ? (
                  <>
                    <Skeleton.Input
                      active
                      block
                      small
                      className="my-1"
                      style={{ height: "18px" }}
                    />
                    <Skeleton.Input
                      active
                      block
                      small
                      className="my-1"
                      style={{ height: "18px" }}
                    />
                    <Skeleton.Input
                      active
                      block
                      small
                      className="my-1"
                      style={{ height: "18px" }}
                    />{" "}
                    <Skeleton.Input
                      active
                      block
                      small
                      className="my-1"
                      style={{ height: "18px" }}
                    />
                    <Skeleton.Input
                      active
                      block
                      small
                      className="my-1"
                      style={{ height: "18px" }}
                    />
                    <Skeleton.Input
                      active
                      small
                      style={{ height: "18px" }}
                      className="my-1"
                    />
                  </>
                ) : (
                  <div>
                    <div>
                      Current Description :{" "}
                      <div
                        className="fw-bold"
                        style={{
                          height: "200px",
                          overflow: "auto",
                        }}
                      >
                        {productData?.product_description || "-"}
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="col-lg-6 fv-row">
                <div className="card card-rec">
                  <div className="card-header bg-white py-2 px-4 min-h-50px h-50px">
                    <h3 className="card-title fs-4 fw-bold text-info">
                      <i className="ki-duotone ki-technology-4 fs-2 text-info me-3">
                        <span className="path1" />
                        <span className="path2" />
                        <span className="path3" />
                        <span className="path4" />
                        <span className="path5" />
                        <span className="path6" />
                        <span className="path7" />
                      </i>
                      Recommendation
                    </h3>
                    <div className="card-toolbar my-0">
                      <button
                        className="btn btn-light-info fw-bold btn-sm"
                        style={{ border: "1px solid #4318ff" }}
                        onClick={() => {
                          const loading = { ...allLoading };
                          loading.descRecommendation = true;
                          setAllLoading(loading);
                          const Obj = {
                            parameter_name: "Description",
                            content_text: productData?.product_description,
                            asin: productData?.asin,
                            brand: productData?.brand,
                          };
                          GetRecommendationDescription(Obj);
                        }}
                      >
                        Generate
                      </button>
                    </div>
                  </div>
                  <div className="card-body pe-2">
                    <div className="overflow-auto h-150px">
                      {allLoading?.descRecommendation ||
                      allLoading?.mainHeaderLoading ? (
                        <Skeleton active block />
                      ) : recommendedDescription ? (
                        recommendedDescription
                      ) : (
                        <NoData height="150px" />
                      )}
                      {/* {recommendedDescription || "-"} */}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12">
                <div className="separator separator-dashed my-3" />
                <div className="fs-5 fw-bold mb-4">Analysis: </div>
                <div className="d-flex flex-wrap">
                  {allLoading?.mainHeaderLoading || allLoading.descAnalysis ? (
                    <>
                      <Skeleton.Button
                        active
                        style={{
                          borderRadius: "20px",
                          height: "25px",
                          width: "200px",
                        }}
                        className="my-1 me-3"
                      />
                      <Skeleton.Button
                        active
                        style={{
                          borderRadius: "20px",
                          height: "25px",
                          width: "200px",
                        }}
                        className="my-1 me-3"
                      />
                      <Skeleton.Button
                        active
                        style={{
                          borderRadius: "20px",
                          height: "25px",
                          width: "200px",
                        }}
                        className="my-1 me-3"
                      />
                      <Skeleton.Button
                        active
                        style={{
                          borderRadius: "20px",
                          height: "25px",
                          width: "200px",
                        }}
                        className="my-1 me-3"
                      />
                    </>
                  ) : (
                    <>
                      <div className="cus-badge mb-10 g-2 ">
                        {Object.entries(suggestedDescAnalysis)?.map((d) => {
                          if (d?.[0] !== "Total Score" && d?.[0] !== "Score") {
                            return (
                              <span className="badge-c mb-3">
                                <span
                                  className={`ki-outline ${
                                    d?.[1] ? "ki-check" : "ki-cross"
                                  }   fs-3 me-2 badge__icon ${
                                    d?.[1] ? "bg-success" : "bg-danger"
                                  }`}
                                />
                                {d?.[0]}
                              </span>
                            );
                          }
                        })}
                        <span
                          className="badge-c mb-3 cursor-pointer"
                          onClick={() => {
                            const loading = { ...allLoading };
                            loading.descAnalysis = true;
                            setAllLoading(loading);
                            const Obj = {
                              parameter_name: "Description",
                              content_text: productData?.product_description,
                              asin: productData?.asin,
                              brand: productData?.brand,
                            };
                            GetCriteriaAnalysisProductDescription(Obj);
                          }}
                        >
                          <span className=" fs-3 me-2 badge__icon bg-info">
                            {allLoading.descAnalysis ? (
                              <SyncOutlined spin />
                            ) : (
                              <SyncOutlined />
                            )}
                          </span>
                          Reload
                        </span>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
