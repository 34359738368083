import { Alert, Checkbox, Col, Input, Row, Select, message } from "antd";
import React, { useEffect, useState } from "react";
import WalmartCampaignList from "./walmart-campaign-list";
import WalmartCampaignCreation from "./walmart-campaign-creation";
import WalmartCampaignEdit from "./walmart-campaign-edit__";

export default function (props) {
  const [selectedTab, setSelectedTab] = useState("campaign-list");
  const [campaignOperation, setCampaignOperation] = useState("");
  const [editCampaignData, setEditCampaignData] = useState({});
  const [isCampaignId, setIsCampaignId] = useState("");
  return (
    <>
      <div className="d-flex flex-column flex-column-fluid fadeInRight">
        {/*begin::Content*/}
        <div id="kt_app_content" className="app-content flex-column-fluid">
          {/*begin::Content container*/}
          <div
            id="kt_app_content_container"
            className="app-container container-fluid"
          >
            <div className="row mb-4">
              <div className="col-md-12 cus-tab">
                <ul className="nav nav-pills mb-5 fs-6">
                  <li
                    className="nav-item cursor-pointer"
                    onClick={() => {
                      setSelectedTab("campaign-list");
                    }}
                  >
                    <span
                      className={`${
                        selectedTab === "campaign-list" && "active"
                      }  nav-link  fs-7 fw-bold py-3 px-7 me-2`}
                      style={{ backgroundColor: "#edeeff" }}
                    >
                      Campaigns List
                    </span>
                  </li>
                  <li
                    className="nav-item cursor-pointer"
                    onClick={() => {
                      setSelectedTab("campaign-creation");
                      setCampaignOperation("add");
                      setEditCampaignData({});
                    }}
                  >
                    <span
                      className={`${
                        selectedTab === "campaign-creation" && "active"
                      }  nav-link  fs-7 fw-bold py-3 px-7 me-2`}
                      style={{ backgroundColor: "#edeeff" }}
                    >
                      {isCampaignId ? "Edit Campaign" : " Campaign Creation"}
                    </span>
                  </li>
                </ul>
              </div>
            </div>

            {selectedTab === "campaign-list" && (
              <WalmartCampaignList
                {...props}
                campaignOperation={campaignOperation}
                setCampaignOperation={setCampaignOperation}
                setEditCampaignData={setEditCampaignData}
                setSelectedTab={setSelectedTab}
                setIsCampaignId={setIsCampaignId}
              />
            )}
            {selectedTab === "campaign-creation" &&
            campaignOperation === "add" ? (
              <WalmartCampaignCreation
                {...props}
                setSelectedTab={setSelectedTab}
                editCampaignData={editCampaignData}
              />
            ) : (
              ""
            )}

            {/* {selectedTab === "campaign-creation" &&
            campaignOperation === "edit" ? (
              <>
                <WalmartCampaignEdit
                  {...props}
                  setSelectedTab={setSelectedTab}
                  editCampaignData={editCampaignData}
                />
              </>
            ) : (
              ""
            )} */}
            {/* {selectedTab === "campaign-creation" && <NoData />} */}
          </div>
        </div>
      </div>
    </>
  );
}
