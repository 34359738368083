export default (state, action) => {
  if (typeof state === "undefined") {
    return [];
  }
  switch (action.type) {
    case "GET_INVENTORY_MANAGEMENT_LIST_SUCCESS":
    case "GET_INVENTORY_MANAGEMENT_LIST_ERROR":
      return {
        ...state,
        GetInventoryManagementListResponse: action.updatePayload,
      };
    case "GET_SUPPLIER_LIST_SUCCESS":
    case "GET_SUPPLIER_LIST_ERROR":
      return {
        ...state,
        GetSupplierListResponse: action.updatePayload,
      };

    case "GET_REPLENISHMENT_MODAL_DATA_SUCCESS":
    case "GET_REPLENISHMENT_MODAL_DATA_ERROR":
      return {
        ...state,
        GetReplenishmentModalDataResponse: action.updatePayload,
      };

    case "SAVE_REPLENISHMENT_MODAL_DATA_SUCCESS":
    case "SAVE_REPLENISHMENT_MODAL_DATA_ERROR":
      return {
        ...state,
        SaveReplenishmentModalDataResponse: action.updatePayload,
      };

    case "GET_REPLENISHMENT_WAREHOUSE_LIST_SUCCESS":
    case "GET_REPLENISHMENT_WAREHOUSE_LIST_ERROR":
      return {
        ...state,
        GetReplenishmentWarehouseListResponse: action.updatePayload,
      };

    case "ADD_EDIT_REPLENISHMENT_WAREHOUSE_DETAILS_SUCCESS":
    case "ADD_EDIT_REPLENISHMENT_WAREHOUSE_DETAILS_ERROR":
      return {
        ...state,
        AddEditReplenishmentWarehouseDetailsResponse: action.updatePayload,
      };

    case "DELETE_REPLENISHMENT_WAREHOUSE_DETAILS_SUCCESS":
    case "DELETE_REPLENISHMENT_WAREHOUSE_DETAILS_ERROR":
      return {
        ...state,
        DeleteReplenishmentWarehouseResponse: action.updatePayload,
      };

    case "GET_REPLENISHMENT_FORECAST_DATA_SUCCESS":
    case "GET_REPLENISHMENT_FORECAST_DATA_ERROR":
      return {
        ...state,
        GetReplenishmentForecastDataResponse: action.updatePayload,
      };

    case "IMPORT_WAREHOUSE_STOCK_DATA_SUCCESS":
    case "IMPORT_WAREHOUSE_STOCK_DATA_ERROR":
      return {
        ...state,
        ImportWarehouseStockDataResponse: action.updatePayload,
      };

    case "SAVE_BULK_INVENTORY_SUCCESS":
    case "SAVE_BULK_INVENTORY_ERROR":
      return {
        ...state,
        SaveBulkInventoryResponse: action.updatePayload,
      };

    case "FAKE_ACTION_INVENTORY_MANAGEMENT":
      return { ...state, [action?.state]: {} };
    default:
      return state;
  }
};
