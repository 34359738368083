import { Select, Tooltip, Input } from "antd";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

export default function (props) {
  const [supplierData, setSupplierData] = useState({
    supplier_id: 1,
    target_stock_days: 5,
    fba_buffer_days: 1,
  });

  const [supplierList, setSupplierList] = useState([
    { supplier_name: "Test Supplier", id: 1 },
    { supplier_name: "New Supplier", id: 2 },
  ]);
  const onChangeField = (key, value, rest) => {
    setSupplierData({ ...supplierData, [key]: value, ...rest });
  };
  return (
    <>
      <div className="row">
        <div className="col-12 d-flex justify-content-between align-items-center">
          <div>
            <label className="fw-bold fs-6">Units per box </label>
          </div>
          <div className="d-flex align-items-center">
            <Input
              value={"50"}
              className="ant_common_input me-5"
              size="large"
              style={{
                width: "230px",
              }}
            />

            <Tooltip
              placement="left"
              title="Number of units in a box"
              trigger="hover"
              className="me-4"
            >
              <i
                style={{ fontSize: "17px", color: "#827dcb" }}
                className="bi bi-info-circle mx-2 cursor-pointer"
              ></i>
            </Tooltip>
          </div>
        </div>
        <div className="separator my-5" />
        <div className="col-12 d-flex justify-content-between align-items-center">
          <div>
            <label className="fw-bold fs-6">Box length (inches)</label>
          </div>
          <div className="d-flex align-items-center">
            <Input
              value={"0.78"}
              className="ant_common_input me-5"
              size="large"
              style={{
                width: "230px",
              }}
            />
            <Tooltip
              placement="left"
              title="The longest side of the box that has a flap"
              trigger="hover"
              className="me-4"
            >
              <i
                style={{ fontSize: "17px", color: "#827dcb" }}
                className="bi bi-info-circle mx-2 cursor-pointer"
              ></i>
            </Tooltip>
          </div>
        </div>
        <div className="separator my-5" />
        <div className="col-12 d-flex justify-content-between align-items-center">
          <div>
            <label className="fw-bold fs-6">Box width (inches)</label>
          </div>
          <div className="d-flex align-items-center">
            <Input
              value={"0.78"}
              className="ant_common_input me-5"
              size="large"
              style={{
                width: "230px",
              }}
            />
            <Tooltip
              placement="left"
              title="The shorter side of the box that has a flap"
              trigger="hover"
              className="me-4"
            >
              <i
                style={{ fontSize: "17px", color: "#827dcb" }}
                className="bi bi-info-circle mx-2 cursor-pointer"
              ></i>
            </Tooltip>
          </div>
        </div>
        <div className="separator my-5" />
        <div className="col-12 d-flex justify-content-between align-items-center">
          <div>
            <label className="fw-bold fs-6">Box height (inches)</label>
          </div>
          <div className="d-flex align-items-center">
            <Input
              value={"1.18"}
              className="ant_common_input me-5"
              size="large"
              style={{
                width: "230px",
              }}
            />
            <Tooltip
              placement="left"
              title="The standing side of the box from top to bottom"
              trigger="hover"
              className="me-4"
            >
              <i
                style={{ fontSize: "17px", color: "#827dcb" }}
                className="bi bi-info-circle mx-2 cursor-pointer"
              ></i>
            </Tooltip>
          </div>
        </div>
        <div className="separator my-5" />
        <div className="col-12 d-flex justify-content-between align-items-center">
          <div>
            <label className="fw-bold fs-6">Color</label>
          </div>
          <div className="d-flex align-items-center">
            <Input
              value={"red"}
              className="ant_common_input me-5"
              size="large"
              style={{
                width: "230px",
              }}
            />
            <Tooltip
              placement="left"
              title="The color of a product"
              trigger="hover"
              className="me-4"
            >
              <i
                style={{ fontSize: "17px", color: "#827dcb" }}
                className="bi bi-info-circle mx-2 cursor-pointer"
              ></i>
            </Tooltip>
          </div>
        </div>
        <div className="separator my-5" />
        <div className="col-12 d-flex justify-content-between align-items-center">
          <div>
            <label className="fw-bold fs-6">Size</label>
          </div>
          <div className="d-flex align-items-center">
            <Input
              value={"M"}
              className="ant_common_input me-5"
              size="large"
              style={{
                width: "230px",
              }}
            />
            <Tooltip
              placement="left"
              title="The size of a product"
              trigger="hover"
              className="me-4"
            >
              <i
                style={{ fontSize: "17px", color: "#827dcb" }}
                className="bi bi-info-circle mx-2 cursor-pointer"
              ></i>
            </Tooltip>
          </div>
        </div>
        {/* <div className="separator my-5" /> */}
      </div>
    </>
  );
}
