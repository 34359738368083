import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ActivateUser from "../../modules/pages/auth/activate-user/activate-user";

import {
  UserActiveAction,
  fakeActionAuth,
} from "../../redux/modules/auth/auth.action";

const mapStateToProps = (state) => ({
  SignUpResponse: state.Auth.SignUpResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      UserActiveAction,
      fakeActionAuth,
    },
    dispatch
  );

const ActivateUserIVCSR = connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivateUser);

export default ActivateUserIVCSR;
