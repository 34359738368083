import Auth from "../../modules/auth/auth.reducer";
import ManageUser from "../../modules/admin/manage-user/manage-user.reducer";
import CentralLogSystem from "../../modules/application-log/central-log/central-log.reducer";
import SystemEventLog from "../../modules/application-log/system-event-log/system-event-log.reducer";
import SalesAnalytics from "../../modules/sales-analytics/sales-analytics.reducer";
import DataScheduler from "../../modules/application-log/data-scheduler/data-scheduler.reducer";
import InventoryManagement from "../../modules/inventory/inventory-management/inventory-management.reducer";
import Dashboard from "../../modules/dashboard/dashboard.reducer";
import InventoryDetails from "../../modules/inventory/inventory-details/inventory-details.reducer";
import SKUData from "../../modules/master-table/sku-data/sku-data.reducer";
import Supplier from "../../modules/master-table/supplier/supplier.reducer";
import MarketplaceCredential from "../../modules/setting/marketplace-credential/marketplace-credential.reducer";
import UserProfile from "../../modules/setting/profile/profile.reducer";
import ListingOptimizer from "../../modules/listing-optimizer/listing-optimizer.reducer";
import CriteriaAnalytics from "../../modules/criteria-analytics/index.reducer";
import BidManagement from "../../modules/advertisement/rule-management/index.reducer";
import CampaignManagement from "../../modules/advertisement/campaign-management/index.reducer";
import AdvDashboard from "../../modules/advertisement/dashboard/index.reducer";
import WalmartApiLog from "../../modules/application-log/api-log/api-log.reducer";
import ItemBulkOperation from "../../modules/item-bulk-operation/item-bulk-operation.reducer";
import WalmartOrders from "../../modules/order-management/orders/orders.reducer";
import WalmartReturnsRefunds from "../../modules/order-management/returns-refunds/returns-refunds.reducer";
import RepricerProductListing from "../../modules/repricer/product-listing/product-listing.reducer";
import AdvanceReport from "../../modules/advance-report/advance-report.reducer";
import WalmartBidManagement from "../../modules/advertisement/rule-management/walmart-bid/walmart-rule-reducer";
import FeedItemsLogReducer from "../../modules/master-table/feed-items-log/feed-items-log.reducer";
import Configuration from "../../modules/setting/configuration/configuration.reducer";
import WalmartKeywordManagement from "../../modules/advertisement/keyword-management/walmart/index.reducer";
const reducers = {
  Auth,
  ManageUser,
  CentralLogSystem,
  SystemEventLog,
  SalesAnalytics,
  DataScheduler,
  InventoryManagement,
  InventoryDetails,
  Dashboard,
  SKUData,
  Supplier,
  MarketplaceCredential,
  UserProfile,
  ListingOptimizer,
  CriteriaAnalytics,
  BidManagement,
  CampaignManagement,
  AdvDashboard,
  WalmartApiLog,
  ItemBulkOperation,
  WalmartOrders,
  WalmartReturnsRefunds,
  RepricerProductListing,
  AdvanceReport,
  WalmartBidManagement,
  FeedItemsLogReducer,
  Configuration,
  WalmartKeywordManagement,
};

export default reducers;
