import React, { useEffect } from "react";
import Wrapper from "./style";
import {
  Button,
  Rate,
  Input,
  List,
  Popconfirm,
  Popover,
  Space,
  Table,
  Tag,
  message,
} from "antd";
import { EyeFilled } from "@ant-design/icons";
import { useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { DefaultPerPage, timeSince } from "../../../config";
import Pagination from "../../../component/pagination";
import AddNew from "./lib/add-new";
import Icons from "../../../component/icons";
// import { DeleteCriteriaAnalysisAdminAction } from "../../../../redux/modules/admin/criteria-analysis/index.action";
const desc = ["terrible", "bad", "normal", "good", "wonderful"];
export default function (props) {
  const {
    CriteriaAnalysisAdminAction,
    // DeleteCriteriaAnalysisAdminAction,
    EditCriteriaAnalysisAdminAction,
    fakeActionCriteriaAnalytics,
  } = props;

  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);
  const [isSaveDisabled, setIsSaveDisable] = useState(false);

  const [valueRecommend, setValueRecommend] = useState(2);
  const [valueAnalysis, setValueAnalysis] = useState(1);

  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageSize, setPageSize] = useState(DefaultPerPage);

  const [addNewModal, setAddNewModal] = useState(false);

  const [selectedRow, setSelectedRow] = useState([]);

  const CriteriaAnalysisResponse = useSelector(
    (state) => state.CriteriaAnalytics.GetCriteriaAnalyticsListResponse || {}
  );
  const EditCriteriaAnalysisResponse = useSelector(
    (state) => state.CriteriaAnalytics.EditCriteriaAnalysisResponse || {}
  );
  const DeleteCriteriaAnalysisResponse = useSelector(
    (state) => state.CriteriaAnalytics.DeleteCriteriaAnalysisResponse || {}
  );

  useEffect(() => {
    if (CriteriaAnalysisResponse?.status === true) {
      setList(CriteriaAnalysisResponse?.data?.records);
      setLoading(false);
      fakeActionCriteriaAnalytics("GetCriteriaAnalyticsListResponse");
    } else if (CriteriaAnalysisResponse?.status === false) {
      setLoading(false);
      fakeActionCriteriaAnalytics("GetCriteriaAnalyticsListResponse");
    }
  }, [CriteriaAnalysisResponse]);

  useEffect(() => {
    setLoading(true);
    CriteriaAnalysisAdminAction({
      page: 1,
      perPage: 10,
    });
    return () => {};
  }, []);

  const getTable = () => {
    setPage(1);
    setPageSize(10);
    setLoading(true);
    setList([]);
    CriteriaAnalysisAdminAction({
      page: 1,
      perPage: 10,
    });
  };

  useEffect(() => {
    if (DeleteCriteriaAnalysisResponse?.status === true) {
      message?.destroy();
      message?.success(DeleteCriteriaAnalysisResponse?.message);
      getTable();
      fakeActionCriteriaAnalytics("DeleteCriteriaAnalysisResponse");
    } else if (DeleteCriteriaAnalysisResponse?.status === false) {
      message?.destroy();
      message?.warning(DeleteCriteriaAnalysisResponse?.message);
      fakeActionCriteriaAnalytics("DeleteCriteriaAnalysisResponse");
    }
  }, [DeleteCriteriaAnalysisResponse]);

  useEffect(() => {
    if (EditCriteriaAnalysisResponse?.status === true) {
      message?.destroy();
      message?.success(EditCriteriaAnalysisResponse?.message);
      setIsSaveDisable(false);
      getTable();
      fakeActionCriteriaAnalytics("EditCriteriaAnalysisResponse");
    } else if (EditCriteriaAnalysisResponse?.status === false) {
      message?.destroy();
      message?.warning(EditCriteriaAnalysisResponse?.message);
      fakeActionCriteriaAnalytics("EditCriteriaAnalysisResponse");
    }
  }, [EditCriteriaAnalysisResponse]);

  const onPageNo = (e) => {
    setLoading(true);
    setList([]);
    CriteriaAnalysisAdminAction({
      page: e,
      perPage: pageSize,
    });
    setPage(e);
  };

  const onPerPage = (e) => {
    setPage(1);
    setPageSize(e);
    setLoading(true);
    setList([]);
    CriteriaAnalysisAdminAction({
      page: 1,
      perPage: e,
    });
  };

  const rowSelection = {
    onChange: (_, selectedRows) => {
      setSelectedRow(selectedRows);
    },
    selectedRowKeys: selectedRow?.map((d) => d?.id),
  };

  const handleRowClick = (record) => {
    setSelectedRow(
      selectedRow?.[0]?.parameter_name === record?.parameter_name
        ? []
        : [record]
    );
  };

  const columns = [
    {
      title: "Id",
      width: 80,
      align: "center",
      render: (text) => <a>{text?.id}</a>,
    },
    {
      title: "Data field",
      width: 150,
      align: "left",
      render: (text) => <a>{text?.parameter_name}</a>,
    },
    // {
    //   title: "Status",
    //   width: 120,
    //   align: "left",
    //   render: (text) => (
    //     <Tag
    //       bordered={false}
    //       color={text?.status === 1 ? "success" : "warning"}
    //     >
    //       {text?.status === 1 ? "Active" : "In Active"}
    //     </Tag>
    //   ),
    // },
    // {
    //   title: "Created At",
    //   align: "left",
    //   width: 180,
    //   render: (text) => {
    //     return (
    //       <div>
    //         <span>
    //           {moment(new Date(text.created_at * 1000)).format(
    //             "MM/DD/YYYY hh:mm A"
    //           )}
    //         </span>
    //         <br />
    //         <span className="timeStampColor">
    //           ({timeSince(text.created_at)})
    //         </span>
    //       </div>
    //     );
    //   },
    // },

    {
      title: "Updated At",
      align: "left",
      width: 180,
      render: (text) => {
        return (
          <div>
            <span>
              {moment(new Date(text.updated_at * 1000)).format(
                "MM/DD/YYYY hh:mm A"
              )}
            </span>
            <br />
            <span className="timeStampColor">
              ({timeSince(text.updated_at)})
            </span>
          </div>
        );
      },
    },
    // {
    //   title: "Action",
    //   align: "center",
    //   width: 100,
    //   render: (text) => {
    //     return (
    //       <div>
    //         <Popconfirm
    //           title="Delete the Data field"
    //           description="Are you sure to delete this Data field?"
    //           okText="Yes"
    //           cancelText="No"
    //           onConfirm={() => {
    //             message.destroy();
    //             message.loading("Loading...", 0);
    //             DeleteCriteriaAnalysisAdminAction(text?.id);
    //           }}
    //         >
    //           <Icons type="Delete" style={{ cursor: "pointer" }} />
    //         </Popconfirm>
    //       </div>
    //     );
    //   },
    // },
  ];
  return (
    <Wrapper>
      <div className="fadeInRight">
        <div className="d-flex flex-column flex-column-fluid">
          <div id="kt_app_content" className="app-content flex-column-fluid">
            <div
              id="kt_app_content_container"
              className="app-container container-fluid"
            >
              <div class="row g-5 gx-xl-5 mb-5 mb-xl-10">
                {/*begin::Col*/}
                <div className="col-12 col-lg-12 col-xl-6">
                  {/*begin::Engage widget 10*/}
                  <div className="card h-md-100">
                    <div className="card-header border-bottom-dashed border-gray-300">
                      <span className="card-title fw-bold text-gray-900">
                        Criteria List
                      </span>
                    </div>
                    {/*begin::Body*/}
                    <div className="card-body pt-2">
                      <div className="table-responsive tbl-sales">
                        {/*begin::Table*/}
                        <Table
                          columns={columns}
                          loading={loading}
                          dataSource={list}
                          pagination={false}
                          onRow={(record, rowIndex) => ({
                            onClick: () => handleRowClick(record),
                          })}
                          rowSelection={{
                            type: "radio",
                            ...rowSelection,
                          }}
                          rowKey="id"
                          scroll={{ x: "max-content" }}
                        />
                        {/*end::Table*/}
                      </div>
                    </div>
                    {/*end::Body*/}
                    <div className="card-footer py-4">
                      <div className="d-flex flex-stack flex-wrap pt-0 mt-0 justify-content-between">
                        <Pagination
                          loading={loading || list?.length === 0}
                          pageSize={pageSize}
                          page={page}
                          totalPage={totalPage}
                          onPerPage={onPerPage}
                          onPageNo={onPageNo}
                        />
                      </div>
                    </div>
                  </div>
                  {/*end::Engage widget 10*/}
                </div>
                {/*end::Col*/}
                {/*begin::Col*/}
                <div
                  className="col-12 col-lg-12 col-xl-6"
                  style={{
                    opacity: selectedRow?.length > 0 ? 1 : 0.5,
                    pointerEvents: selectedRow?.length > 0 ? "" : "none",
                  }}
                >
                  <div className="card mb-7">
                    <div className="card-header">
                      <div className="card-title">
                        {selectedRow?.[0]?.parameter_name === "Reviews"
                          ? "Recommended changes required"
                          : "Analysis Criteria"}{" "}
                      </div>
                      <div className="card-toolbar">
                        <button
                          onClick={() => {
                            const {
                              analysis_criteria,
                              id,
                              parameter_name,
                              recommend_criteria,
                            } = selectedRow?.[0];
                            setIsSaveDisable(true);
                            message?.destroy();
                            message?.loading("Loading...", 0);
                            EditCriteriaAnalysisAdminAction(id, {
                              parameter_name,
                              analysis_criteria,
                              recommend_criteria,
                            });
                          }}
                          size="small"
                          type="ghost"
                          className="btn btn-primary btn-sm"
                        >
                          save
                        </button>
                      </div>
                    </div>
                    <div className="card-body">
                      {selectedRow?.[0]?.parameter_name === "Reviews" ? (
                        <span>
                          Analysis based on {"{"}
                          <b>MR, 1, 2, 3</b>
                          {"}"} Where MR = <b>My Review</b> {"("}You can pass{" "}
                          <b>
                            1 or 1, 2 or 1, 2, 3 or 1, 2, 3, 4 or 1, 2, 3, 4, 5
                          </b>
                          {")"}
                        </span>
                      ) : (
                        <span>
                          Analysis based on{"{"}
                          <b>{selectedRow?.[0]?.parameter_name || ""}</b>
                          {"}"} and {"{"}
                          <b>brand</b>
                          {"}"}
                        </span>
                      )}

                      <div className="note">
                        <Input.TextArea
                          className="TextEditor"
                          value={
                            selectedRow?.length > 0
                              ? selectedRow?.[0]?.analysis_criteria
                              : // ?.split(",")
                                // ?.join("\n")
                                ""
                          }
                          name="textNote"
                          autoSize={{ minRows: 4 }}
                          onChange={(e) => {
                            setList((pre) => {
                              return pre?.map((d) => {
                                if (d?.id === selectedRow?.[0]?.id) {
                                  const obj = {
                                    ...d,
                                    analysis_criteria: e.target.value,
                                    // ?.split("\n")
                                    // ?.join("\n"),
                                  };
                                  setSelectedRow([obj]);
                                  return obj;
                                }
                                return { ...d };
                              });
                            });
                          }}
                          placeholder="Add Text To Create Note"
                        />
                      </div>
                      {/* <span>
                Return the response in Json format with true or false value.
              </span> */}
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title">
                        {selectedRow?.[0]?.parameter_name === "Reviews"
                          ? "Good to have"
                          : "Recommend Criteria"}{" "}
                      </div>
                      <div className="card-toolbar">
                        <button
                          onClick={() => {
                            const {
                              analysis_criteria,
                              id,
                              parameter_name,
                              recommend_criteria,
                            } = selectedRow?.[0];
                            setIsSaveDisable(true);
                            message?.destroy();
                            message?.loading("Loading...", 0);
                            EditCriteriaAnalysisAdminAction(id, {
                              parameter_name,
                              analysis_criteria,
                              recommend_criteria,
                            });
                          }}
                          size="small"
                          type="ghost"
                          className="btn btn-primary btn-sm"
                        >
                          save
                        </button>
                      </div>
                    </div>
                    <div className="card-body">
                      {selectedRow?.[0]?.parameter_name === "Reviews" ? (
                        <span>
                          Analysis based on {"{"}
                          <b>CR, 1, 2, 3</b>
                          {"}"} Where CR = <b>Competitor Review</b> {"("}You can
                          pass{" "}
                          <b>
                            1 or 1, 2 or 1, 2, 3 or 1, 2, 3, 4 or 1, 2, 3, 4, 5
                          </b>
                          {")"}
                        </span>
                      ) : (
                        <span>
                          Recommendation based on {"{"}
                          <b>{selectedRow?.[0]?.parameter_name || ""}</b>
                          {"}"}, {"{"}
                          <b>brand</b>
                          {"}"} and {"{"}
                          <b>keywords</b>
                          {"}"}
                        </span>
                      )}

                      <div className="note">
                        <Input.TextArea
                          className="TextEditor"
                          name="textNote"
                          autoSize={{ minRows: 10 }}
                          value={
                            selectedRow?.length > 0
                              ? selectedRow?.[0]?.recommend_criteria
                              : // ?.split("\n")
                                // ?.join("\n")
                                ""
                          }
                          onChange={(e) => {
                            setList((pre) => {
                              return pre?.map((d) => {
                                if (d?.id === selectedRow?.[0]?.id) {
                                  const obj = {
                                    ...d,
                                    recommend_criteria: e.target.value,
                                    // ?.split("\n")
                                    // ?.join(","),
                                  };
                                  setSelectedRow([obj]);
                                  return obj;
                                }
                                return { ...d };
                              });
                            });
                          }}
                          placeholder="Add Text To Create Note"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/*end::Col*/}
              </div>
            </div>
          </div>
        </div>
      </div>

      {addNewModal && (
        <AddNew
          onClose={() => setAddNewModal(false)}
          open={addNewModal}
          getTable={getTable}
          {...props}
        />
      )}
      {/* <Table columns={columns} dataSource={data} /> */}
    </Wrapper>
  );
}
