import { Drawer, List, Popover, Skeleton, Tag, Tooltip, message } from "antd";
import React, { useRef, useState } from "react";
import Loading from "../../../../component/loading";
import NoData from "../../../../component/no-data";
import { NumberWithCommas } from "../../../../config";
import Chart from "react-apexcharts";
import writeXlsxFile from "write-excel-file";
import { savePDF } from "@progress/kendo-react-pdf";
import { FilePdfOutlined, FileExcelOutlined } from "@ant-design/icons";

const TopProducts = (props) => {
  const {
    topProductList,
    topProductLoading,
    platformType,
    chartCategory,
    chartSeries,
  } = props;
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);
  const [selectedTab, setSelectedTab] = useState("List");

  const options = {
    chart: {
      type: "bar",
      height: 300,
      toolbar: {
        show: false,
        offsetY: -20,
        tools: {
          download: '<img src="/assets/media/downloads.png" width="20"/>',
        },
        export: {
          // Set the filename
          filename: "Unit Sold Graph",
          // Include only PNG
          formats: ["png"],
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        // endingShape: "rounded",
        columnWidth: "50%",
        borderRadius: 5,
      },
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    dataLabels: {
      enabled: false,
    },
    yaxis: {
      title: {
        // text: '$ (thousands)'
      },
    },
    xaxis: {
      tickPlacement: "between",
      tickAmount: window.innerWidth >= 992 ? 8 : 5,
      categories:
        selectedTab === "Chart"
          ? chartCategory?.map((d) => {
              return d;
            }) || []
          : [],
      labels: {
        rotate: -50,
      },
    },
    noData: {
      text: "No Data",
      align: "center",
      verticalAlign: "middle",
      style: {
        fontSize: "20px",
      },
    },
    colors: ["#8992d4", "#373fb5", "#98cabb", "#097980"],
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val;
        },
      },
    },
    yaxis: [
      {
        seriesName: "Total Sales",
        title: {
          text: "Total Sales",
        },
      },
      {
        seriesName: "Unit Sold",
        opposite: true,
        title: {
          text: "Unit Sold",
        },
      },
    ],
    tooltip: {
      marker: {
        show: true,
      },
      x: {
        show: true,
      },
      y: {
        formatter: function (
          value,
          { series, seriesIndex, dataPointIndex, w }
        ) {
          const withTitle_ = w?.config?.series?.[seriesIndex];
          console.log(withTitle_, "withTitle_");
          return withTitle_?.name === "Unit Sold"
            ? value || 0
            : "$" + NumberWithCommas(value);
        },
      },
    },
  };

  const chartRef = useRef(null);
  const handleExportPDF = () => {
    savePDF(chartRef.current, { paperSize: "auto", fileName: "chart.pdf" });
  };

  const exportHeaderAmazon = [
    { value: "Product", type: String },
    { value: "ASIN", type: String },
    { value: "SKU", type: String },
    { value: "Orders", type: String },
    { value: "Units sold", type: String },
    { value: "Total Sales", type: String },
    { value: "Net Profit", type: String },
    { value: "Profit Margin", type: String },
    { value: "ROI", type: String },
  ];

  const downloadXlAmazon = async () => {
    let dataRow = [];
    await topProductList.map((d) => {
      const newList = [
        d?.product_title,
        d?.asin,
        d?.sku,
        d?.total_orders,
        d?.order_units,
        d?.total_sales,
        d?.net_profit,
        d?.profit_margin,
        d?.roi,
      ];
      dataRow = [
        ...dataRow,
        newList.map((d) => {
          return { value: d };
        }),
      ];
    });
    const data = [exportHeaderAmazon, ...dataRow];
    await writeXlsxFile(data, {
      fileName: "Top Products.xlsx",
    });
    message.destroy();
  };

  const exportHeaderWalamrt = [
    { value: "Product", type: String },
    { value: "SKU", type: String },
    { value: "UPC", type: String },
    { value: "GTIN", type: String },
    { value: "Orders", type: String },
    { value: "Units sold", type: String },
    { value: "Total Sales", type: String },
    { value: "Net Profit", type: String },
    { value: "Profit Margin", type: String },
    { value: "ROI", type: String },
  ];

  const downloadXlWalmart = async () => {
    let dataRow = [];
    await topProductList.map((d) => {
      const newList = [
        d?.product_name,
        d?.sku,
        d?.upc,
        d?.gtin,
        d?.total_orders,
        d?.total_units,
        d?.total_sales,
        d?.net_profit,
        d?.profit_margin,
        d?.roi,
      ];
      dataRow = [
        ...dataRow,
        newList.map((d) => {
          return { value: d };
        }),
      ];
    });
    const data = [exportHeaderWalamrt, ...dataRow];
    await writeXlsxFile(data, {
      fileName: "Top Products.xlsx",
    });
    message.destroy();
  };

  const exportChartHeader = [
    { value: "SKU", type: String },
    { value: "Units sold", type: String },
    { value: "Total Sales", type: String },
  ];

  const downloadXlChart = async () => {
    let dataRow = [];
    await topProductList.map((d) => {
      const newList = [d?.sku, d?.total_units, d?.total_sales];
      dataRow = [
        ...dataRow,
        newList.map((d) => {
          return { value: d };
        }),
      ];
    });
    const data = [exportChartHeader, ...dataRow];
    await writeXlsxFile(data, {
      fileName: "Top Products.xlsx",
    });
    message.destroy();
  };
  const loadingArr = [1, 2, 3, 4];
  return (
    <>
      <div className="card card-xxl-stretch mb-5 mb-xl-8">
        {/*begin::Header*/}
        <div className="card-header pt-5 border-bottom-0">
          {/*begin::Title*/}
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bold text-dark">
              Top 10 Products
            </span>
            <span className="text-gray-400 pt-2 fw-semibold fs-6">
              Products by Units Sold
            </span>
          </h3>
          {/*end::Title*/}
          {/*begin::Toolbar*/}
          <div className="card-toolbar">
            {!topProductLoading && (
              <>
                {" "}
                <ul className="nav nav-pills nav-line-pills border rounded p-1">
                  <li
                    className="nav-item"
                    onClick={() => {
                      setSelectedTab("List");
                    }}
                  >
                    <span
                      className={`nav-link btn btn-active-light-info btn-active-color-info btn-color-gray-500 py-2 px-3 fs-6 fw-semibold ${
                        selectedTab === "List" ? "active" : ""
                      }`}
                    >
                      <i className="ki-duotone ki-element-11 fs-3 pe-0">
                        <span className="path1" />
                        <span className="path2" />
                        <span className="path3" />
                        <span className="path4" />
                      </i>
                    </span>
                  </li>
                  <li
                    className="nav-item me-2"
                    onClick={() => {
                      setSelectedTab("Chart");
                    }}
                  >
                    <span
                      className={`nav-link btn btn-active-light-info btn-active-color-info btn-color-gray-500 py-2 px-3 fs-6 fw-semibold ${
                        selectedTab === "Chart" ? "active" : ""
                      }`}
                    >
                      <i class="ki-duotone ki-chart-simple fs-2 pe-0">
                        <span class="path1"></span>
                        <span class="path2"></span>
                        <span class="path3"></span>
                        <span class="path4"></span>
                      </i>
                    </span>
                  </li>
                </ul>
                <button
                  className="btn btn-light-info fs-7 fw-bolder ms-2"
                  disabled={
                    selectedTab === "Chart" || topProductList?.length === 0
                  }
                  onClick={() => {
                    {
                      platformType === "amazon"
                        ? downloadXlAmazon()
                        : downloadXlWalmart();
                    }
                  }}
                >
                  Export
                </button>
              </>
            )}
          </div>
          {/*end::Toolbar*/}
        </div>
        {/*end::Header*/}
        {/*begin::Body*/}
        <div className="card-body py-3">
          <div className="tab-content px-0">
            {/*begin::Tab pane*/}
            <div
              className="tab-pane fade  active show"
              id="kt_tab_grid"
              role="tabpanel"
              aria-labelledby="kt_tab_grid"
            >
              <div className>
                <div className="row mb-10 mt-0 g-7 gx-7">
                  {selectedTab === "List" ? (
                    topProductLoading ? (
                      <>
                        {loadingArr?.map((d) => {
                          return (
                            <div className="col-xl-3 col-lg-6 col-md-6">
                              <div className="card  overlay overflow-hidden bdr-box">
                                <div className="card-body p-0">
                                  <div className="overlay-wrapper  min-h-225px p-5">
                                    {/*begin::Ribbon*/}
                                    {/* <div className="ribbon ribbon-triangle ribbon-top-start border-info">
                                  <div className="ribbon-label "></div>
                                </div> */}
                                    {/*end::Ribbon*/}
                                    <div className="text-center">
                                      <div className="symbol symbol-100px overflow-hidden me-3">
                                        <Skeleton.Node
                                          active
                                          className="mb-3"
                                        />
                                      </div>
                                      <div className="d-flex flex-column">
                                        <Skeleton.Input
                                          active
                                          block
                                          size="small"
                                          className="mb-1"
                                        />
                                        <Skeleton.Input
                                          active
                                          block
                                          size="small"
                                          className="mb-3"
                                        />
                                        <span>
                                          <Skeleton.Button
                                            active
                                            style={{ width: "150px" }}
                                          />
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </>
                    ) : topProductList?.length != 0 ? (
                      topProductList?.map((d, i) => {
                        return (
                          <div className="col-xl-3 col-lg-6 col-md-6" key={i}>
                            <div className="card  overlay overflow-hidden bdr-box">
                              <div className="card-body p-0">
                                <div className="overlay-wrapper  min-h-225px p-5">
                                  {/*begin::Ribbon*/}
                                  <div className="ribbon ribbon-triangle ribbon-top-start border-info">
                                    <div className="ribbon-label ">{i + 1}</div>
                                  </div>
                                  {/*end::Ribbon*/}
                                  <div className="text-center">
                                    <div className="symbol symbol-100px overflow-hidden me-3">
                                      <img
                                        src={`${d?.image_url}`}
                                        className=" bg-light"
                                        alt=""
                                      />
                                    </div>
                                    <div className="d-flex flex-column">
                                      <a
                                        href
                                        className="text-gray-800 fs-6 text-hover-info mb-1 fw-bolder text-two "
                                      >
                                        {d?.product_title || d?.product_name}
                                      </a>
                                      <span>
                                        {platformType === "amazon" ? (
                                          <>
                                            <Tag
                                              color="geekblue"
                                              className="mt-1 fs-6"
                                              bordered={false}
                                            >
                                              <b>ASIN:</b> {d?.asin}
                                            </Tag>
                                          </>
                                        ) : (
                                          <>
                                            <b>SKU:</b> {d?.sku}
                                          </>
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="overlay-layer align-items-center bg-light bg-opacity-25  p-5 pe-0 d-flex"
                                  style={{ Opacity: 1, zIndex: 9 }}
                                >
                                  <button
                                    className="btn btn-primary"
                                    onClick={() => {
                                      setSelectedRow(d);
                                      setOpenDrawer(true);
                                    }}
                                  >
                                    View Details
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <NoData height={"100px"} />
                    )
                  ) : (
                    <>
                      <div className="d-flex justify-content-end">
                        <span
                          className="d-flex align-items-center cursor-pointer me-5"
                          onClick={() => {
                            handleExportPDF();
                          }}
                        >
                          <Tooltip title="Download PDF" placement="bottom">
                            <FilePdfOutlined
                              className="fs-1"
                              style={{ color: "red" }}
                            />
                          </Tooltip>
                        </span>
                        <span
                          className="d-flex align-items-center cursor-pointer"
                          onClick={() => {
                            // handleExportPDF();
                            downloadXlChart();
                          }}
                        >
                          <Tooltip title="Download Excel" placement="bottom">
                            <FileExcelOutlined
                              className="fs-1"
                              style={{ color: "blue" }}
                            />
                          </Tooltip>
                        </span>
                      </div>
                      <div ref={chartRef}>
                        <Chart
                          options={options}
                          series={chartSeries}
                          type="bar"
                          height={413}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            {/*end::Tab pane*/}
            {/*end::Tab pane*/}
          </div>
        </div>
        {/*begin::Body*/}
      </div>

      <Drawer
        title="Product Details"
        placement="right"
        onClose={() => {
          setOpenDrawer(false);
        }}
        open={openDrawer}
      >
        <>
          <div>
            <div className="d-flex  align-items-center">
              <div className="symbol symbol-100px overflow-hidden me-5">
                <img
                  src={selectedRow?.image_url}
                  className=" bg-light"
                  alt=""
                />
              </div>
              <div className="d-flex flex-column">
                <span className="text-gray-800 fs-6 text-hover-info mb-1 fw-bolder text-two min-w-200px">
                  <Popover
                    content={
                      <div
                        style={{
                          height: "150px",
                          width: "200px",
                          overflowY: "scroll",
                        }}
                      >
                        {selectedRow?.product_title ||
                          selectedRow?.product_name ||
                          "-"}
                      </div>
                    }
                    className="popoverActionIcon "
                    placement="bottom"
                  >
                    {selectedRow?.product_title ||
                      selectedRow?.product_name ||
                      "-"}
                  </Popover>
                </span>
                {/* <span className="mb-2">
                  <b>SKU:</b> {selectedRow?.sku || "-"}
                </span> */}
                {platformType === "amazon" ? (
                  <span>
                    <b>ASIN:</b> {selectedRow?.asin || "-"}
                  </span>
                ) : (
                  <span>
                    <b>SKU:</b> {selectedRow?.sku || "-"}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="separator separator-dashed my-5 border-gray-300" />
          {platformType === "amazon" && (
            <div className="d-flex justify-content-between w-100 fs-7 border-bottom border-bottom-dashed border-gray-300 pb-4 mb-5">
              <div className="fw-bolder">SKU</div>
              <div className="fw-bolder fs-6 text-info">
                {selectedRow?.sku || "-"}
              </div>
            </div>
          )}
          {platformType === "walmart" && (
            <div className="d-flex justify-content-between w-100 fs-7 border-bottom border-bottom-dashed border-gray-300 pb-4 mb-5">
              <div className="fw-bolder">UPC</div>
              <div className="fw-bolder fs-6 text-info">
                {selectedRow?.upc || "-"}
              </div>
            </div>
          )}
          {platformType === "walmart" && (
            <div className="d-flex justify-content-between w-100 fs-7 border-bottom border-bottom-dashed border-gray-300 pb-4 mb-5">
              <div className="fw-bolder">Gtin</div>
              <div className="fw-bolder fs-6 text-info">
                {selectedRow?.gtin || 0}
              </div>
            </div>
          )}
          {platformType === "amazon" && (
            <div className="d-flex justify-content-between w-100 fs-7 border-bottom border-bottom-dashed border-gray-300 pb-4 mb-5">
              <div className="fw-bolder">COGS</div>
              <div className="fw-bolder fs-6 text-info">
                $ {NumberWithCommas(selectedRow?.cogsData?.total_cogs || 0)}
              </div>
            </div>
          )}

          <div className="d-flex justify-content-between w-100 fs-7 border-bottom border-bottom-dashed border-gray-300 pb-4 mb-5">
            <div className="fw-bolder">Orders</div>
            <div className="fw-bolder fs-6 text-info">
              {NumberWithCommas(selectedRow?.total_orders || 0)}
            </div>
          </div>
          <div className="d-flex justify-content-between w-100 fs-7 border-bottom border-bottom-dashed border-gray-300 pb-4 mb-5">
            <div className="fw-bolder">Units Sold</div>
            <div className="fw-bolder fs-6 text-info">
              {NumberWithCommas(selectedRow?.order_units || 0)}
            </div>
          </div>

          <div className="d-flex justify-content-between w-100 fs-7 border-bottom border-bottom-dashed border-gray-300 pb-4 mb-5">
            <div className="fw-bolder">Total Sales</div>
            <div className="fw-bolder fs-6 text-info">
              $ {NumberWithCommas(selectedRow?.total_sales || 0)}
            </div>
          </div>
          <div className="d-flex justify-content-between w-100 fs-7 border-bottom border-bottom-dashed border-gray-300 pb-4 mb-5">
            <div className="fw-bolder">Net Profit</div>
            <div className="fw-bolder fs-6 text-info">
              $ {NumberWithCommas(selectedRow?.net_profit || "0")}
            </div>
          </div>
          <div className="d-flex justify-content-between w-100 fs-7 border-bottom border-bottom-dashed border-gray-300 pb-4 mb-5">
            <div className="fw-bolder">Profit Margin</div>
            <div className="fw-bolder fs-6 text-info">
              {selectedRow?.profit_margin || 0} %
            </div>
          </div>
          <div className="d-flex justify-content-between w-100 fs-7 border-bottom border-bottom-dashed border-gray-300 pb-4 mb-5">
            <div className="fw-bolder">ROI</div>
            <div className="fw-bolder fs-6 text-info">
              {selectedRow?.roi || 0} %
            </div>
          </div>
        </>
      </Drawer>
    </>
  );
};

export default TopProducts;
