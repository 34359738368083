import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import RepricerProductListing from "../../modules/pages/repricer-product-listing";

import {
  GetRepricerProductListing,
  fakeActionRepricerListing,
} from "../../redux/modules/repricer/product-listing/product-listing.action";

const mapStateToProps = (state) => ({
  GetRepricingProductListingResponse:
    state.RepricerProductListing.GetRepricingProductListingResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      GetRepricerProductListing,
      fakeActionRepricerListing,
    },
    dispatch
  );

const RepricerProductListingContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(RepricerProductListing);

export default RepricerProductListingContainer;
