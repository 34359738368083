import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Login from "../../modules/pages/auth/login";

import {
  loginAction,
  fakeActionAuth,
} from "../../redux/modules/auth/auth.action";

const mapStateToProps = (state) => ({
  LoginResponse: state.Auth.LoginResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      loginAction,
      fakeActionAuth,
    },
    dispatch
  );

const LoginIVCSR = connect(mapStateToProps, mapDispatchToProps)(Login);

export default LoginIVCSR;
