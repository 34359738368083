import axiosCall from "../../../configurations/network-services/axiosCall";

const prefix = "dashboard";

export const GetBidManagementList = (data) => {
  const path = `rule-management/get-rules?page=${data?.page || 1}&per-page=${
    data?.perPage || 10
  }&marketplace_id=${data?.marketplaceId || ""}&rule_name=${
    data?.ruleName || ""
  }`;
  const responseType = "GET_BID_MANAGEMENT_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetApplierRuleLogsList = (data) => {
  const path = `rule-management/get-applied-rule-logs?master_rule_id=${
    data?.master_rule_id || ""
  }&page=${data?.page || 1}&per-page=${data?.perPage || 10}&marketplace_id=${
    data?.marketplaceId || ""
  }`;
  const responseType = "GET_APPLIED_RULE_LOG_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const CreateRuleAction = (data, id) => {
  const method = id ? "put" : "post";
  const path = id ? `rule-management/${id}` : `rule-management/create-rule`;
  const responseType = "CREATE_NEW_BID_MANAGEMENT";
  return axiosCall(method, path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetRuleManagementDropdownListAction = () => {
  const path = `rule-management/get-create-rule-dd-values`;
  const responseType = "GET_RULE_MANAGEMENT_DROPDOWN";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const DeleteRuleAction = (id, type) => {
  const path = `rule-management/master-rule/${id}`;
  const responseType = "DELETE_BID_MANAGEMENT_RULE";
  return axiosCall("delete", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const CampaignsAnalyticsAllTableDataAction = (
  data,
  key,
  value,
  filter_condition
) => {
  const path = `campaigns-analytics/all-campaigns-table-data?start_date=${
    data?.start_date || ""
  }&end_date=${data?.end_date || ""}&advertising_type=${
    data?.advertising_type || ""
  }&marketplace_id=${data?.marketplace_id || ""}&${key || ""}=${
    value || ""
  }&filter_condition=${filter_condition || ""}`;
  const responseType = "ALL_CAMPAIGNS_DATA";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const fakeActionBidManagement = (data) => async (dispatch) => {
  dispatch({ type: "FAKE_ACTION_BID_MANAGEMENT", state: data });
};
