import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ItemBulkOperation from "../../modules/pages/item-bulk-operation";

import {
  GetSalesChannelList,
  fakeActionSalesAnalytics,
} from "../../redux/modules/sales-analytics/sales-analytics.action";
import {
  GetItemBulkOperation,
  DownloadItemBulkFile,
  GetUploadFileBulkError,
  ItemBulkOperationUpload,
  fakeActionItemBulkOperation,
  GetWalmartApiLogDetails,
} from "../../redux/modules/item-bulk-operation/item-bulk-operation.action";

const mapStateToProps = (state) => ({
  GetItemBulkOperationResponse:
    state.ItemBulkOperation.GetItemBulkOperationResponse,
  GetDownloadBulkOperationResponse:
    state.ItemBulkOperation.GetDownloadBulkOperationResponse,
  GetUploadFIleBulkErrorResponse:
    state.ItemBulkOperation.GetUploadFIleBulkErrorResponse,
  GetUploadItemBulkOperationFileResponse:
    state.ItemBulkOperation.GetUploadItemBulkOperationFileResponse,
  GetWalmartApiLogDetailsResponse:
    state.ItemBulkOperation.GetWalmartApiLogDetailsResponse,
  // SaveWalmartItemFeedListResponse:
  //   state.SKUData.SaveWalmartItemFeedListResponse,

  GetSalesChannelListResponse: state.SalesAnalytics.GetSalesChannelListResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      GetItemBulkOperation,
      DownloadItemBulkFile,
      GetUploadFileBulkError,
      ItemBulkOperationUpload,
      fakeActionItemBulkOperation,
      GetWalmartApiLogDetails,

      GetSalesChannelList,
      fakeActionSalesAnalytics,
    },
    dispatch
  );

const ItemBulkOperationContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ItemBulkOperation);

export default ItemBulkOperationContainer;
