import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { DefaultPerPage, urlDecode } from "../../../../../config";
import Loading from "../../../../../component/loading";
import IvcsrTable from "../../../../../component/ivcsr-table";
import Pagination from "../../../../../component/pagination";
import NoData from "../../../../../component/no-data";
import { Popover, Tag } from "antd";
import DownloadSendReportModal from "./download-send-report-modal";

export default function (props) {
  const {} = props;
  const location = useLocation();

  const [isOpenDownloadModal, setIsOpenDownloadModal] = useState(false);
  const [list, setList] = useState([
    {
      "#": 1,
      settlementId: 17699155761,
      type: "Order",
      orderId: "112-7688637-9374615",
      sku: "TP-KIT-ASADD-SMALL",
      description:
        "ArmorPoxy Garage Epoxy Floor Kit for Concrete - 14-Pc 2 Part, Industrial Grade Epoxy Floor Coating - 300 Sq Ft, Gray - for Garage, Basements, Workshop",
      quantity: 1,
      marketplace: "Amazon.com",
      accountType: "Standard Orders",
      fulfillment: "Amazon",
      orderCity: "KATY",
      orderState: "TX",
      orderPostal: "77449-5788",
      taxCollectionModel: "MarketplaceFacilitator",
      productSales: "$439",
      productSalesTax: "$36.22",
      shippingCredits: "$0",
      shippingCreditsTax: "$0",
      giftWrapCredits: "$0",
      giftwrapCreditsTax: "$0",
      regulatoryFee: "$0",
      taxOnRegulatoryFee: "$0",
      promotionalRebates: "$0",
      promotionalRebatesTax: "$-36.22",
      marketplaceWithheldTax: "$-52.68",
      sellingFees: "$-26.02",
      fbaFees: "$0",
      otherTransactionFees: "$0",
      other: "$360.3",
      total: "$360.3",
    },
  ]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageSize, setPageSize] = useState(DefaultPerPage);

  useEffect(() => {
    console.log(urlDecode(location), "location");
  }, [location]);

  const columns = [
    {
      title: "#",
      dataIndex: "#",
      key: "#",
    },
    {
      title: "Settlement id",
      dataIndex: "settlementId",
      key: "settlementId",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Order Id",
      dataIndex: "orderId",
      key: "orderId",
    },
    {
      title: "SKU",
      dataIndex: "sku",
      key: "sku",
      render: (text) => {
        return <Tag color="orange">{text}</Tag>;
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: 400,
      render: (text) => {
        return (
          <Popover
            content={
              <div
                style={{
                  height: "auto",
                  width: "400px",
                }}
              >
                {text}
              </div>
            }
            placement="bottom"
          >
            <span
              href=""
              className="text-primary text-hover-info mb-1 fw-bolder text-two fs-6 "
              style={{ width: "100%" }}
            >
              {text}
            </span>
          </Popover>
        );
      },
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Marketplace",
      dataIndex: "marketplace",
      key: "marketplace",
    },
    {
      title: "Account type",
      dataIndex: "accountType",
      key: "accountType",
    },
    {
      title: "Fulfillment",
      dataIndex: "fulfillment",
      key: "fulfillment",
    },
    {
      title: "Order City",
      dataIndex: "orderCity",
      key: "orderCity",
    },
    {
      title: "Order State",
      dataIndex: "orderState",
      key: "orderState",
    },
    {
      title: "Order Postal",
      dataIndex: "orderPostal",
      key: "orderPostal",
    },
    {
      title: "Tax Collection Model",
      dataIndex: "taxCollectionModel",
      key: "taxCollectionModel",
    },
    {
      title: "Product Sales",
      dataIndex: "productSales",
      key: "productSales",
    },
    {
      title: "Product Sales Tax",
      dataIndex: "productSalesTax",
      key: "productSalesTax",
    },
    {
      title: "Shipping Credits",
      dataIndex: "shippingCredits",
      key: "shippingCredits",
    },
    {
      title: "Shipping Credits Tax",
      dataIndex: "shippingCreditsTax",
      key: "shippingCreditsTax",
    },
    {
      title: "Gift Wrap Credits",
      dataIndex: "giftWrapCredits",
      key: "giftWrapCredits",
    },
    {
      title: "Giftwrap Credits Tax",
      dataIndex: "giftwrapCreditsTax",
      key: "giftwrapCreditsTax",
    },
    {
      title: "Regulatory Fee",
      dataIndex: "regulatoryFee",
      key: "regulatoryFee",
    },
    {
      title: "Tax On Regulatory Fee",
      dataIndex: "taxOnRegulatoryFee",
      key: "taxOnRegulatoryFee",
    },
    {
      title: "Promotional Rebates",
      dataIndex: "promotionalRebates",
      key: "promotionalRebates",
    },
    {
      title: "Promotional Rebates Tax",
      dataIndex: "promotionalRebatesTax",
      key: "promotionalRebatesTax",
    },
    {
      title: "Marketplace Withheld Tax",
      dataIndex: "marketplaceWithheldTax",
      key: "marketplaceWithheldTax",
    },
    {
      title: "Selling Fees",
      dataIndex: "sellingFees",
      key: "sellingFees",
    },
    {
      title: "FBA fees",
      dataIndex: "fbaFees",
      key: "fbaFees",
    },
    {
      title: "Other Transaction Fees",
      dataIndex: "otherTransactionFees",
      key: "otherTransactionFees",
    },
    {
      title: "Other",
      dataIndex: "other",
      key: "other",
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
    },
  ];

  const onPageNo = (e) => {
    // setLoading(true);
    const apiObj = {
      page: e,
      perPage: pageSize,
    };
    // GetItemBulkOperation(apiObj);
    setPage(e);
  };

  const onPerPage = (e) => {
    setPage(1);
    setPageSize(e);
    // setLoading(true);
    const apiObj = {
      page: 1,
      perPage: e,
    };
    // GetItemBulkOperation(apiObj);
  };
  return (
    <>
      <div className="d-flex flex-column flex-column-fluid">
        <div id="kt_app_content" className="app-content flex-column-fluid">
          <div
            id="kt_app_content_container"
            className="app-container container-fluid"
          >
            <div className="row gy-5 g-xl-5">
              <div className="col-xxl-12">
                <div className="card card-xxl-stretch mb-5 mb-xl-8">
                  <div className="card-header border-bottom-dashed">
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label fw-bolder text-dark">
                        Report View
                      </span>
                    </h3>
                    <div className="card-toolbar ">
                      <button
                        className="btn btn-light-info fw-bold"
                        onClick={() => {
                          setIsOpenDownloadModal(true);
                        }}
                      >
                        Download
                      </button>
                    </div>
                  </div>

                  <div className="card-body py-2">
                    <div className="card-body pb-5 pt-0">
                      <div className="table-responsive mt-5">
                        {loading ? (
                          <Loading />
                        ) : list?.length !== 0 ? (
                          <>
                            <IvcsrTable
                              columns={columns}
                              fixed="top"
                              dataSource={list}
                              rowKey="key"
                              loading={loading}
                              pagination={false}
                              scroll={{ x: "max-content" }}
                            />
                          </>
                        ) : (
                          <NoData type="new" />
                        )}
                      </div>
                      <Pagination
                        loading={loading || list?.length === 0}
                        pageSize={pageSize}
                        page={page}
                        totalPage={totalPage}
                        onPerPage={onPerPage}
                        onPageNo={onPageNo}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isOpenDownloadModal && (
        <DownloadSendReportModal
          show={isOpenDownloadModal}
          onHide={() => {
            setIsOpenDownloadModal(false);
          }}
        />
      )}
    </>
  );
}
