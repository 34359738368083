import { Alert, Checkbox, Col, Input, Row, Select, message } from "antd";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import Loading from "../../../../../component/loading";
import IvcsrTable from "../../../../../component/ivcsr-table";
import NoData from "../../../../../component/no-data";
import dayjs from "dayjs";
import Pagination from "../../../../../component/pagination";
import { DefaultPerPage } from "../../../../../config";

export default function (props) {
  const {
    show,
    onHide,
    selectedActionLog,
    GetApplierRuleLogsList,
    fakeActionBidManagement,
  } = props;
  console.log(selectedActionLog, "selectedActionLog");
  const [logList, setLogList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(500);
  const [pageSize, setPageSize] = useState(DefaultPerPage);

  const GetAppliedRuleLogListRes = useSelector(
    (state) => state.BidManagement.GetAppliedRuleLogListResponse || {}
  );

  useEffect(() => {
    const Obj = { master_rule_id: selectedActionLog?.id };
    GetApplierRuleLogsList(Obj);
  }, []);

  useEffect(() => {
    if (GetAppliedRuleLogListRes?.status === true) {
      setLoading(false);
      console.log(GetAppliedRuleLogListRes, "GetAppliedRuleLogListRes");
      setTotalPage(GetAppliedRuleLogListRes?.data?.pagination?.totalCount);
      setLogList(GetAppliedRuleLogListRes?.data?.records || []);
      // setLogList(GetAppliedRuleLogListRes?.data?.records);
      fakeActionBidManagement("GetAppliedRuleLogListResponse");
    } else if (GetAppliedRuleLogListRes?.status === false) {
      fakeActionBidManagement("GetAppliedRuleLogListResponse");
    }
  }, [GetAppliedRuleLogListRes]);

  const columns = [
    {
      title: "#",
      render: (text) => {
        if (text?.new_value > text?.old_value) {
          return (
            <label className="fw-bolder">
              <i
                class="ki-duotone ki-double-up fs-1"
                style={{ color: "green" }}
              >
                <span class="path1"></span>
                <span class="path2"></span>
                <span class="path3"></span>
              </i>
            </label>
          );
        } else {
          return (
            <label className="fw-bolder">
              <i
                class="ki-duotone ki-double-down fs-1"
                style={{ color: "red" }}
              >
                <span class="path1"></span>
                <span class="path2"></span>
                <span class="path3"></span>
              </i>
            </label>
          );
        }
      },
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Target Name",
      dataIndex: "targeting_text",
      key: "targeting_text",
      render: (text) => {
        return (
          <label className="fw-bolder" style={{ textTransform: "capitalize" }}>
            {text}
          </label>
        );
      },
    },
    // {
    //   title: "Target Type",
    //   dataIndex: "target_type",
    //   key: "target_type",
    // },
    {
      title: "Change",
      dataIndex: "change_type",
      key: "change_type",
    },
    {
      title: "Campaign",
      dataIndex: "campaign_name",
      key: "campaign_name",
    },
    {
      title: "AdGroup",
      dataIndex: "ad_group_name",
      key: "ad_group_name",
    },
    {
      title: "Rule",
      dataIndex: "rule_name",
      key: "rule_name",
    },
    {
      title: "Rule Applied Time",
      render: (text) => {
        if (!text?.rule_applied_time) {
          return "-";
        }
        return (
          <span>
            {dayjs(parseInt(text?.rule_applied_time) * 1000).format(
              "MMM DD, YYYY"
            )}
          </span>
        );
      },
    },
    {
      title: "Old Value",
      dataIndex: "old_value",
      key: "old_value",
    },
    {
      title: "New Value",
      dataIndex: "new_value",
      key: "new_value",
    },
    {
      title: "Remarks",
      dataIndex: "remark",
      key: "remark",
    },
  ];

  const onPageNo = (e) => {
    setLoading(true);
    const Obj = {
      page: e,
      perPage: pageSize,
      master_rule_id: selectedActionLog?.id,
    };
    // GetApplierRuleLogsList(Obj);
    setPage(e);
  };

  const onPerPage = (e) => {
    setPage(1);
    setPageSize(e);
    setLoading(true);
    const Obj = {
      page: 1,
      perPage: e,
      master_rule_id: selectedActionLog?.id,
    };
    // GetApplierRuleLogsList(Obj);
  };

  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          onHide();
        }}
        size="xl"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Action Logs - {selectedActionLog?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <div className="row">
              {loading ? (
                <Loading />
              ) : logList?.length !== 0 ? (
                <IvcsrTable
                  columns={columns}
                  fixed="top"
                  dataSource={logList}
                  rowKey="key"
                  pagination={false}
                  scroll={{ x: "max-content" }}
                />
              ) : (
                <NoData type="new" />
              )}
            </div>
            {/* <Pagination
              loading={loading || logList?.length === 0}
              pageSize={pageSize}
              page={page}
              totalPage={totalPage}
              onPerPage={onPerPage}
              onPageNo={onPageNo}
            /> */}
          </>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <button
              className="btn btn-light-danger me-2"
              onClick={() => {
                onHide();
              }}
            >
              Cancel
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
