import React, { useEffect, useState } from "react";
import RangePickerIVCSR from "../../../../component/range-picker";
import { DateFormat, SalesChannelFlag } from "../../../../config";
import dayjs from "dayjs";
import { Select } from "antd";
import { useSelector } from "react-redux";

export default function (props) {
  const { GetSalesChannelList, fakeActionSalesAnalytics } = props;

  const GetSalesChannelListRes = useSelector(
    (state) => state.SalesAnalytics.GetSalesChannelListResponse || {}
  );

  const [salesChannelList, setsalesChannelList] = useState([]);
  const [selectedSalesChannel, setSelectedSalesChannel] = useState([]);
  const [selectedSalesChannelId, setSelectedSalesChannelId] = useState();
  const [salesChannelLoading, setSalesChannelLoading] = useState(true);
  const [selectedCurrencySign, setSelectedCurrencySign] = useState("");

  const [filters, setFilters] = useState({
    start_date: dayjs().add(-7, "d"),
    end_date: dayjs(),
  });

  useEffect(() => {
    GetSalesChannelList();
  }, []);

  useEffect(() => {
    if (GetSalesChannelListRes?.status === true) {
      setSalesChannelLoading(false);
      setsalesChannelList(GetSalesChannelListRes?.data?.sales_channel);
      setSelectedSalesChannelId("ATVPDKIKX0DER");
      setSelectedSalesChannel("Amazon.com");
      setSelectedCurrencySign("$");

      fakeActionSalesAnalytics("GetSalesChannelListResponse");
    } else if (GetSalesChannelListRes?.status === false) {
      fakeActionSalesAnalytics("GetSalesChannelListResponse");
    }
  }, [GetSalesChannelListRes]);

  return (
    <div className="d-flex flex-column flex-column-fluid fadeInRight">
      <div id="kt_app_content" className="app-content flex-column-fluid">
        <div
          id="kt_app_content_container"
          className="app-container container-fluid"
        >
          <div className="row g-5 g-xl-5 mb-0 mb-5">
            <div className="col-12 my-3 d-flex">
              <div className="me-3">
                <RangePickerIVCSR
                  className="ant_common_input"
                  id="ivcsr_date_picker"
                  size="large"
                  allowClear={false}
                  style={{ width: "290px" }}
                  format={DateFormat}
                  value={[filters?.start_date, filters?.end_date]}
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  onChange={(e) => {
                    const filters_ = filters;
                    filters_.start_date = e?.[0];
                    filters_.end_date = e?.[1];
                    setFilters({ ...filters_ });
                  }}
                />
              </div>
              <div>
                <Select
                  className="w-250px"
                  size="large"
                  placeholder="Select Sales Channel"
                  loading={salesChannelLoading}
                  value={selectedSalesChannelId || null}
                  onChange={(e, _) => {
                    setSelectedSalesChannel(_?.sales_channel);
                    setSelectedSalesChannelId(e);
                  }}
                  options={SalesChannelFlag(salesChannelList)}
                />
              </div>
            </div>
          </div>

          <div>
            <div className="row gy-5 g-xl-5">
              <div className="col-sm-4 col-xl-2 mb-xl-5">
                <div
                  className="card h-lg-100"
                  style={{
                    borderTop: "2px solid #9185cd",
                    background:
                      "linear-gradient(180deg, #9488cf24, #fff, #ffff)",
                  }}
                >
                  <div className="card-body d-flex justify-content-between align-items-start flex-column p-5">
                    <div className="m-0">
                      <i
                        className="ki-duotone ki-tablet-ok fs-2hx "
                        style={{ color: "#9185cd" }}
                      >
                        <span className="path1" />
                        <span className="path2" />
                        <span className="path3" />
                      </i>
                    </div>

                    <div className="d-flex flex-column my-5">
                      <span className="fw-bold fs-2x text-gray-800 lh-1 ls-n2">
                        70706
                      </span>

                      <div className="m-0">
                        <span className="fw-semibold fs-6 text-gray-400">
                          Active Keywords{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-4 col-xl-2 mb-xl-5">
                <div
                  className="card h-lg-100"
                  style={{
                    borderTop: "2px solid #c483cc",
                    background:
                      "linear-gradient(180deg, #c483cc24, #fff, #ffff)",
                  }}
                >
                  <div className="card-body d-flex justify-content-between align-items-start flex-column p-5">
                    <div className="m-0">
                      <i
                        className="ki-duotone ki-add-files fs-2hx"
                        style={{ color: "#c483cc" }}
                      >
                        <span className="path1" />
                        <span className="path2" />
                        <span className="path3" />
                        <span className="path4" />
                      </i>
                    </div>

                    <div className="d-flex flex-column my-5">
                      <span className="fw-bold fs-2x text-gray-800 lh-1 ls-n2">
                        229
                      </span>

                      <div className="m-0">
                        <span className="fw-semibold fs-6 text-gray-400">
                          New Keywords
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-4 col-xl-2 mb-xl-5">
                <div
                  className="card h-lg-100"
                  style={{
                    borderTop: "2px solid #cc8397",
                    background:
                      "linear-gradient(180deg, #cc839724, #fff, #ffff)",
                  }}
                >
                  <div className="card-body d-flex justify-content-between align-items-start flex-column p-5">
                    <div className="m-0">
                      <i
                        className="ki-duotone ki-user-tick fs-2hx "
                        style={{ color: "#cc8397" }}
                      >
                        <span className="path1" />
                        <span className="path2" />
                        <span className="path3" />
                      </i>
                    </div>

                    <div className="d-flex flex-column my-5">
                      <span className="fw-bold fs-2x text-gray-800 lh-1 ls-n2">
                        21198
                      </span>

                      <div className="m-0">
                        <span className="fw-semibold fs-6 text-gray-400">
                          Active PAT
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-4 col-xl-2 mb-xl-5">
                <div
                  className="card h-lg-100"
                  style={{
                    borderTop: "2px solid #ccb083",
                    background:
                      "linear-gradient(180deg, #ccb08324, #fff, #ffff)",
                  }}
                >
                  <div className="card-body d-flex justify-content-between align-items-start flex-column p-5">
                    <div className="m-0">
                      <i
                        className="ki-duotone ki-user-tick fs-2hx"
                        style={{ color: "#ccb083" }}
                      >
                        <span className="path1" />
                        <span className="path2" />
                        <span className="path3" />
                      </i>
                    </div>

                    <div className="d-flex flex-column my-5">
                      <span className="fw-bold fs-2x text-gray-800 lh-1 ls-n2">
                        835
                      </span>

                      <div className="m-0">
                        <span className="fw-semibold fs-6 text-gray-400">
                          New PAT
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-4 col-xl-2 mb-5 mb-xl-5">
                <div
                  className="card h-lg-100"
                  style={{
                    borderTop: "2px solid #c4cc83",
                    background:
                      "linear-gradient(180deg, #c4cc8324, #fff, #ffff)",
                  }}
                >
                  <div className="card-body d-flex justify-content-between align-items-start flex-column p-5">
                    <div className="m-0">
                      <i
                        className="ki-duotone ki-user-tick fs-2hx"
                        style={{ color: "#c4cc83" }}
                      >
                        <span className="path1" />
                        <span className="path2" />
                        <span className="path3" />
                      </i>
                    </div>

                    <div className="d-flex flex-column my-5">
                      <span className="fw-bold fs-2x text-gray-800 lh-1 ls-n2">
                        409
                      </span>

                      <div className="m-0">
                        <span className="fw-semibold fs-6 text-gray-400">
                          Active Audience
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-4 col-xl-2 mb-5 mb-xl-5">
                <div
                  className="card h-lg-100"
                  style={{
                    borderTop: "2px solid #84cc94",
                    background:
                      "linear-gradient(180deg, #84cc9424, #fff, #ffff)",
                  }}
                >
                  <div className="card-body d-flex justify-content-between align-items-start flex-column p-5">
                    <div className="m-0">
                      <i
                        className="ki-duotone ki-profile-user fs-2hx"
                        style={{ color: "#84cc94" }}
                      >
                        <span className="path1" />
                        <span className="path2" />
                        <span className="path3" />
                        <span className="path4" />
                      </i>
                    </div>

                    <div className="d-flex flex-column my-5">
                      <span className="fw-bold fs-2x text-gray-800 lh-1 ls-n2">
                        22
                      </span>

                      <div className="m-0">
                        <span className="fw-semibold fs-6 text-gray-400">
                          New Audience
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-5 g-xl-5 mb-5 mb-xl-5">
              <div className="col-sm-6 col-md-6 col-xxl-3 ">
                <div className="card card-xxl-stretch mb-5 mb-xl-8">
                  <div className="card-header border-bottom-0 min-h-45px">
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label fw-bolder text-dark">
                        Performance
                      </span>
                    </h3>
                    <div className="card-toolbar"></div>
                  </div>

                  <div className="card-body py-2">
                    <div className="m-0">
                      <div
                        className="d-flex flex-stack"
                        style={{ borderLeft: "2px solid #4318ff" }}
                      >
                        <div className="symbol symbol-40px me-4">
                          <span className="symbol-label">
                            <i className="ki-duotone ki-dollar text-gray-600 fs-1">
                              <span className="path1" />
                              <span className="path2" />
                              <span className="path3" />
                            </i>
                          </span>
                        </div>

                        <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                          <div className="me-5">
                            <a href="#" className="fw-bold text-primary fs-6">
                              Spend
                            </a>
                            <span className="text-gray-600 fw-semibold fs-7 d-block text-start ps-0">
                              559.5K
                            </span>
                          </div>

                          <div className="d-flex align-items-center">
                            <span className="text-gray-800 fw-bolder fs-4 me-3 d-block">
                              $592.6K
                            </span>
                            <div className="m-0"></div>
                          </div>
                        </div>
                      </div>

                      <div className="separator separator-dashed my-3" />

                      <div
                        className="d-flex flex-stack"
                        style={{ borderLeft: "2px solid #4318ff" }}
                      >
                        <div className="symbol symbol-40px me-4">
                          <span className="symbol-label">
                            <i className="ki-duotone ki-chart-pie-3  text-gray-600 fs-1">
                              <span className="path1" />
                              <span className="path2" />
                              <span className="path3" />
                            </i>
                          </span>
                        </div>

                        <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                          <div className="me-5">
                            <a href="#" className="fw-bold text-primary fs-6">
                              Sales
                            </a>
                            <span className="text-gray-600 fw-semibold fs-7 d-block text-start ps-0">
                              4.5M
                            </span>
                          </div>

                          <div className="d-flex align-items-center">
                            <span className="text-gray-800 fw-bolder fs-4 me-3 d-block">
                              $4.9M
                            </span>
                            <div className="m-0"></div>
                          </div>
                        </div>
                      </div>

                      <div className="separator separator-dashed my-3" />

                      <div
                        className="d-flex flex-stack"
                        style={{ borderLeft: "2px solid #4318ff" }}
                      >
                        <div className="symbol symbol-40px me-4">
                          <span className="symbol-label">
                            <i className="ki-duotone ki-graph-up text-gray-600 fs-1">
                              <span className="path1" />
                              <span className="path2" />
                              <span className="path3" />
                              <span className="path4" />
                              <span className="path5" />
                              <span className="path6" />
                            </i>
                          </span>
                        </div>

                        <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                          <div className="me-5">
                            <a href="#" className="fw-bold text-primary fs-6">
                              TACoS
                            </a>
                            <span className="text-gray-600 fw-semibold fs-7 d-block text-start ps-0">
                              12.40%
                            </span>
                          </div>

                          <div className="d-flex align-items-center">
                            <span className="text-gray-800 fw-bolder fs-4 me-3 d-block">
                              12.42%
                            </span>
                            <div className="m-0"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-xxl-3">
                <div className="card card-xxl-stretch mb-5 mb-xl-8">
                  <div className="card-header border-bottom-0 min-h-45px">
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label fw-bolder text-dark">
                        Conversion
                      </span>
                    </h3>
                    <div className="card-toolbar"></div>
                  </div>

                  <div className="card-body py-2">
                    <div className="m-0">
                      <div
                        className="d-flex flex-stack"
                        style={{ borderLeft: "2px solid #4caf50" }}
                      >
                        <div className="symbol symbol-40px me-4">
                          <span className="symbol-label">
                            <i className="ki-duotone ki-click text-gray-600 fs-1">
                              <span className="path1" />
                              <span className="path2" />
                              <span className="path3" />
                              <span className="path4" />
                              <span className="path5" />
                            </i>
                          </span>
                        </div>

                        <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                          <div className="me-5">
                            <a href="#" className="fw-bold text-primary fs-6">
                              Clicks
                            </a>
                            <span className="text-gray-600 fw-semibold fs-7 d-block text-start ps-0">
                              559.5K
                            </span>
                          </div>

                          <div className="d-flex align-items-center">
                            <span className="text-gray-800 fw-bolder fs-4 me-3 d-block">
                              816.8K
                            </span>
                            <div className="m-0"></div>
                          </div>
                        </div>
                      </div>

                      <div className="separator separator-dashed my-3" />

                      <div
                        className="d-flex flex-stack"
                        style={{ borderLeft: "2px solid #4caf50" }}
                      >
                        <div className="symbol symbol-40px me-4">
                          <span className="symbol-label">
                            <i className="ki-duotone ki-handcart text-gray-600 fs-1">
                              <span className="path1" />
                              <span className="path2" />
                              <span className="path3" />
                            </i>
                          </span>
                        </div>

                        <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                          <div className="me-5">
                            <a href="#" className="fw-bold text-primary fs-6">
                              Orders
                            </a>
                            <span className="text-gray-600 fw-semibold fs-7 d-block text-start ps-0">
                              4.5M
                            </span>
                          </div>

                          <div className="d-flex align-items-center">
                            <span className="text-gray-800 fw-bolder fs-4 me-3 d-block">
                              96.6K
                            </span>
                            <div className="m-0"></div>
                          </div>
                        </div>
                      </div>

                      <div className="separator separator-dashed my-3" />

                      <div
                        className="d-flex flex-stack"
                        style={{ borderLeft: "2px solid #4caf50" }}
                      >
                        <div className="symbol symbol-40px me-4">
                          <span className="symbol-label">
                            <i className="ki-duotone ki-percentage text-gray-600 fs-1">
                              <span className="path1" />
                              <span className="path2" />
                            </i>
                          </span>
                        </div>

                        <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                          <div className="me-5">
                            <a href="#" className="fw-bold text-primary fs-6">
                              CVR
                            </a>
                            <span className="text-gray-600 fw-semibold fs-7 d-block text-start ps-0">
                              12.40%
                            </span>
                          </div>

                          <div className="d-flex align-items-center">
                            <span className="text-gray-800 fw-bolder fs-4 me-3 d-block">
                              11.82%
                            </span>
                            <div className="m-0"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-xxl-3">
                <div className="card card-xxl-stretch mb-5 mb-xl-8">
                  <div className="card-header border-bottom-0 min-h-45px">
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label fw-bolder text-dark">
                        Efficiency
                      </span>
                    </h3>
                    <div className="card-toolbar"></div>
                  </div>

                  <div className="card-body py-2">
                    <div className="m-0">
                      <div
                        className="d-flex flex-stack"
                        style={{ borderLeft: "2px solid #f44336" }}
                      >
                        <div className="symbol symbol-40px me-4">
                          <span className="symbol-label">
                            <i className="ki-duotone ki-dollar text-gray-600 fs-1">
                              <span className="path1" />
                              <span className="path2" />
                              <span className="path3" />
                            </i>
                          </span>
                        </div>

                        <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                          <div className="me-5">
                            <a href="#" className="fw-bold text-primary fs-6">
                              CPC
                            </a>
                            <span className="text-gray-600 fw-semibold fs-7 d-block text-start ps-0">
                              559.5K
                            </span>
                          </div>

                          <div className="d-flex align-items-center">
                            <span className="text-gray-800 fw-bolder fs-4 me-3 d-block">
                              $0.73
                            </span>
                            <div className="m-0"></div>
                          </div>
                        </div>
                      </div>

                      <div className="separator separator-dashed my-3" />

                      <div
                        className="d-flex flex-stack"
                        style={{ borderLeft: "2px solid #f44336" }}
                      >
                        <div className="symbol symbol-40px me-4">
                          <span className="symbol-label">
                            <i className="ki-duotone ki-tablet-ok text-gray-600 fs-1">
                              <span className="path1" />
                              <span className="path2" />
                              <span className="path3" />
                            </i>
                          </span>
                        </div>

                        <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                          <div className="me-5">
                            <a href="#" className="fw-bold text-primary fs-6">
                              SPC
                            </a>
                            <span className="text-gray-600 fw-semibold fs-7 d-block text-start ps-0">
                              4.5M
                            </span>
                          </div>

                          <div className="d-flex align-items-center">
                            <span className="text-gray-800 fw-bolder fs-4 me-3 d-block">
                              $5.8
                            </span>
                            <div className="m-0"></div>
                          </div>
                        </div>
                      </div>

                      <div className="separator separator-dashed my-3" />

                      <div
                        className="d-flex flex-stack"
                        style={{ borderLeft: "2px solid #f44336" }}
                      >
                        <div className="symbol symbol-40px me-4">
                          <span className="symbol-label">
                            <i className="ki-duotone ki-discount text-gray-600 fs-1">
                              <span className="path1" />
                              <span className="path2" />
                            </i>
                          </span>
                        </div>

                        <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                          <div className="me-5">
                            <a href="#" className="fw-bold text-primary fs-6">
                              CTR
                            </a>
                            <span className="text-gray-600 fw-semibold fs-7 d-block text-start ps-0">
                              12.40%
                            </span>
                          </div>

                          <div className="d-flex align-items-center">
                            <span className="text-gray-800 fw-bolder fs-4 me-3 d-block">
                              0.33%
                            </span>
                            <div className="m-0"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-xxl-3">
                <div className="card card-xxl-stretch mb-5 mb-xl-8">
                  <div className="card-header border-bottom-0 min-h-45px">
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label fw-bolder text-dark">
                        Active Campaign
                      </span>
                    </h3>
                    <div className="card-toolbar"></div>
                  </div>

                  <div className="card-body py-2">
                    <div className="m-0">
                      <div
                        className="d-flex flex-stack"
                        style={{ borderLeft: "2px solid #ffbb18" }}
                      >
                        <div className="symbol symbol-40px me-4">
                          <span className="symbol-label">
                            <i className="ki-duotone ki-bill text-gray-600 fs-1">
                              <span className="path1" />
                              <span className="path2" />
                              <span className="path3" />
                              <span className="path4" />
                              <span className="path5" />
                              <span className="path6" />
                            </i>
                          </span>
                        </div>

                        <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                          <div className="me-5">
                            <a href="#" className="fw-bold text-primary fs-6">
                              SP
                            </a>
                            <span className="text-gray-600 fw-semibold fs-7 d-block text-start ps-0">
                              559.5K
                            </span>
                          </div>

                          <div className="d-flex align-items-center">
                            <span className="text-gray-800 fw-bolder fs-4 me-3 d-block">
                              890
                            </span>
                            <div className="m-0"></div>
                          </div>
                        </div>
                      </div>

                      <div className="separator separator-dashed my-3" />

                      <div
                        className="d-flex flex-stack"
                        style={{ borderLeft: "2px solid #ffbb18" }}
                      >
                        <div className="symbol symbol-40px me-4">
                          <span className="symbol-label">
                            <i className="ki-duotone ki-chart-simple-3 text-gray-600 fs-1">
                              <span className="path1" />
                              <span className="path2" />
                              <span className="path3" />
                              <span className="path4" />
                            </i>
                          </span>
                        </div>

                        <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                          <div className="me-5">
                            <a href="#" className="fw-bold text-primary fs-6">
                              SB
                            </a>
                            <span className="text-gray-600 fw-semibold fs-7 d-block text-start ps-0">
                              4.5M
                            </span>
                          </div>

                          <div className="d-flex align-items-center">
                            <span className="text-gray-800 fw-bolder fs-4 me-3 d-block">
                              418
                            </span>
                            <div className="m-0"></div>
                          </div>
                        </div>
                      </div>

                      <div className="separator separator-dashed my-3" />

                      <div
                        className="d-flex flex-stack"
                        style={{ borderLeft: "2px solid #ffbb18" }}
                      >
                        <div className="symbol symbol-40px me-4">
                          <span className="symbol-label">
                            <i className="ki-duotone ki-screen text-gray-600 fs-1">
                              <span className="path1" />
                              <span className="path2" />
                              <span className="path3" />
                              <span className="path4" />
                            </i>
                          </span>
                        </div>

                        <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                          <div className="me-5">
                            <a href="#" className="fw-bold text-primary fs-6">
                              SD
                            </a>
                            <span className="text-gray-600 fw-semibold fs-7 d-block text-start ps-0">
                              12.40%
                            </span>
                          </div>

                          <div className="d-flex align-items-center">
                            <span className="text-gray-800 fw-bolder fs-4 me-3 d-block">
                              162
                            </span>
                            <div className="m-0"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-5 g-xl-5 mb-5 mb-xl-0"></div>
            <div className="row gy-5 g-xl-5">
              <div className="col-xxl-7">
                <div className="card card-xxl-stretch mb-5 mb-xl-0">
                  <div className="card-header border-bottom-dashed">
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label fw-bolder text-dark">
                        Sales Overview
                      </span>
                    </h3>
                    <div className="card-toolbar"></div>
                  </div>

                  <div className="card-body py-2">
                    <div
                      id="Revenu_Status"
                      className="apex-charts mt-3"
                      data-colors="#727cf5,#0acf97"
                    />
                  </div>
                </div>
              </div>

              <div className="col-xxl-5">
                <div className="card card-xxl-stretch mb-5 mb-xl-0">
                  <div className="card-header border-bottom-dashed">
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label fw-bolder text-dark">
                        Audience
                      </span>
                    </h3>
                    <div className="card-toolbar"></div>
                  </div>

                  <div className="card-body py-2">
                    <div
                      id="kt_charts_widget_38_chart"
                      style={{ height: 300 }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
