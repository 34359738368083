export default (state, action) => {
  if (typeof state === "undefined") {
    return [];
  }
  switch (action.type) {
    // case "GET_FILTERS_LIST_SUCCESS":
    // case "GET_FILTERS_LIST_ERROR":
    //   return {
    //     ...state,
    //     GetFiltersListResponse: action.updatePayload,
    //   };
    case "GET_WM_KEYWORD_SUMMERY_DATA_SUCCESS":
    case "GET_WM_KEYWORD_SUMMERY_DATA_ERROR":
      return {
        ...state,
        GetWmKeywordSummeryDataResponse: action.updatePayload,
      };

    case "GET_WM_KEYWORD_CHART_DATA_SUCCESS":
    case "GET_WM_KEYWORD_CHART_DATA_ERROR":
      return {
        ...state,
        GetWmKeywordChartDataResponse: action.updatePayload,
      };
    case "GET_WM_KEYWORD_LIST_DATA_SUCCESS":
    case "GET_WM_KEYWORD_LIST_DATA_ERROR":
      return {
        ...state,
        GetWmKeywordListDataResponse: action.updatePayload,
      };
    case "GET_WM_SEARCH_TRENDS_DATA_SUCCESS":
    case "GET_WM_SEARCH_TRENDS_DATA_ERROR":
      return {
        ...state,
        GetWmSearchTrendsDataResponse: action.updatePayload,
      };
    case "GET_WM_ITEM_RECOMMENDATION_SUCCESS":
    case "GET_WM_ITEM_RECOMMENDATION_ERROR":
      return {
        ...state,
        GetWmItemRecommendationResponse: action.updatePayload,
      };
    case "GET_WM_KEYWORD_RECOMMENDATION_SUCCESS":
    case "GET_WM_KEYWORD_RECOMMENDATION_ERROR":
      return {
        ...state,
        GetWmKeywordRecommendationResponse: action.updatePayload,
      };

    case "FAKE_ACTION_WALMART_KEYWORD":
      return { ...state, [action?.state]: {} };
    default:
      return state;
  }
};
