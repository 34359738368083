import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Configuration from "../../../modules/pages/setting/configuration";

import {
  GetReturnCenterAddress,
  SaveReturnCenterAddr,
  DeleteReturnCenterAddress,
  fakeActionuserConfiguration,
} from "../../../redux/modules/setting/configuration/configuration.action";

const mapStateToProps = (state) => ({
  SaveReturnCenterAddrResponse:
    state.Configuration.SaveReturnCenterAddrResponse,
  GetReturnCenterAddrResponse: state.Configuration.GetReturnCenterAddrResponse,
  DeleteReturnCenterAddrResponse:
    state.Configuration.DeleteReturnCenterAddrResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      GetReturnCenterAddress,
      SaveReturnCenterAddr,
      DeleteReturnCenterAddress,
      fakeActionuserConfiguration,
    },
    dispatch
  );

const ConfigurationContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Configuration);

export default ConfigurationContainer;
