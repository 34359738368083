import React, { useEffect, useState } from "react";
import Loading from "../../../../../../component/loading";
import IvcsrTable from "../../../../../../component/ivcsr-table";
import NoData from "../../../../../../component/no-data";
import { useSelector } from "react-redux";
import { DefaultPerPage } from "../../../../../../config";
import { Input, Popconfirm, Radio, Space, message } from "antd";

export default function (props) {
  const { fakeActionuserConfiguration } = props;
  const [isUniversalRefund, setIsUniversalRefund] = useState(false);
  const [isSellerDefinedRefund, setIsSellerDefinedRefund] = useState(false);
  const [refundPrice, setRefundPrice] = useState("");
  return (
    <>
      <div className="row g-5 g-xl-5 mb-5 mt-5"></div>

      <div className="card mb-5 mb-xl-8">
        <div className="card-header border-bottom-dashed">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bolder text-dark">
              Refund Details
            </span>
          </h3>
          <div className="card-toolbar"></div>
        </div>
        <div className="card-body pt-5 pb-20">
          <div className="row">
            <div className="col-12">
              <label className="text-green fs-4 fw-bold">
                Flexible Refund Policy Guidelines for Sellers and Customers
              </label>
            </div>
          </div>
          <div className="row mt-10">
            <div className="col-12 d-flex ">
              <div className="me-2 cursor-pointer">
                <i
                  class="ki-outline ki-check"
                  style={{
                    fontSize: "35px",
                    color: isUniversalRefund ? "#4318ff" : "#99a1b7",
                  }}
                  onClick={() => {
                    setIsUniversalRefund(true);
                    setIsSellerDefinedRefund(false);
                  }}
                />
              </div>
              <div>
                <div>
                  <label
                    className="fw-bold fs-4"
                    style={{ color: isUniversalRefund ? "#4318ff" : "" }}
                  >
                    Universal Refund Policy:
                  </label>
                </div>
                <p className="text-muted fs-6">
                  (Please process a refund for all orders, regardless of the
                  order amount or any other conditions. This applies universally
                  to every order, ensuring that all customers receive a full
                  refund without any exception.)
                </p>
              </div>
            </div>
            <div className="col-12 d-flex mt-5 ">
              <div className="me-2 cursor-pointer">
                <i
                  class="ki-outline ki-check"
                  style={{
                    fontSize: "35px",
                    color: isSellerDefinedRefund ? "#4318ff" : "#99a1b7",
                  }}
                  onClick={() => {
                    setIsUniversalRefund(false);
                    setIsSellerDefinedRefund(true);
                  }}
                />
              </div>
              <div>
                <div className="d-flex">
                  <label
                    className="fw-bold fs-4"
                    style={{ color: isSellerDefinedRefund ? "#4318ff" : "" }}
                  >
                    Seller-Defined Refund Threshold:
                  </label>
                </div>
                <p className="text-muted fs-6 mb-2">
                  (Refunds should be processed only for orders where the total
                  order price is below a certain threshold, which is determined
                  by each individual seller. This means that each seller has the
                  flexibility to set their own price limit for refunds.
                  Customers should be informed about the specific refund policy
                  applicable to their purchase, as the refund eligibility can
                  vary depending on the seller's defined price limit.)
                </p>
                <Input
                  placeholder="Enter Price"
                  size="medium"
                  className="ant_common_input"
                  style={{ width: "200px" }}
                  disabled={!isSellerDefinedRefund}
                  value={refundPrice}
                  onChange={(e) => {
                    setRefundPrice(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="col-12 d-flex justify-content-end mt-5">
              <button className="btn btn-primary">Save</button>
              <button
                className="btn btn-danger ms-3"
                onClick={() => {
                  setIsUniversalRefund(false);
                  setIsSellerDefinedRefund(false);
                  setRefundPrice("");
                }}
              >
                Cancel
              </button>
            </div>
          </div>
          {/* <NoData height="200px" /> */}
        </div>
      </div>
    </>
  );
}
