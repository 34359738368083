import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DataScheduler from "../../../modules/pages/application-log/data-scheduler";
import {
  GetDataSchedulerList,
  fakeActionDataScheduler,
} from "../../../redux/modules/application-log/data-scheduler/data-scheduler.action";

import {
  GetMarketplaceList,
  fakeActionSalesAnalytics,
} from "../../../redux/modules/sales-analytics/sales-analytics.action";

const mapStateToProps = (state) => ({
  GetDataSchedulerListResponse:
    state.DataScheduler.GetDataSchedulerListResponse,

  GetMarketplaceListResponse: state.SalesAnalytics.GetMarketplaceListResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      GetDataSchedulerList,
      fakeActionDataScheduler,

      GetMarketplaceList,
      fakeActionSalesAnalytics,
    },
    dispatch
  );

const DataSchedulerContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DataScheduler);

export default DataSchedulerContainer;
