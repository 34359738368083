import Chart from "react-apexcharts";
import React, { useContext, useEffect, useState } from "react";
import {
  DateFormat,
  DefaultPerPage,
  SalesChannelFlag,
  handleDateRangeChange,
} from "../../../config";
import dayjs from "dayjs";
import RangePickerIVCSR from "../../../component/range-picker";
import { Radio, Select, Skeleton, message } from "antd";
import { useSelector } from "react-redux";
import moment from "moment";
import { CustomDatepickerButton } from "./walmart-lib/custom-daterange-button";
import { GlobalContext } from "../../../commonContext";
import DashboardCard from "./walmart-lib/dashboard-card";
import TopProducts from "./walmart-lib/top-products";
import WalmartSalesBreakdown from "./walmart-lib/walmart-sales-breakdown";
import WalmartUnitSoldGraph from "./walmart-lib/walmart-unit-sold-graph";
import { WalmartMarketplaceList } from "../../../config/static-select-option";
// const DashboardCard = React.lazy(() => import("./containers/auth"));
export default function (props) {
  const {
    GetTopProductsList,
    GetDashboardUnitSoldChart,
    GetDashboardSalesData,
    GetSalesChannelList,
    fakeActionSalesAnalytics,
    fakeActionDashboard,
  } = props;

  const contextVar = useContext(GlobalContext);

  const GetDashboardTopProductsListRes = useSelector(
    (state) => state.Dashboard.GetDashboardTopProductsListResponse || {}
  );

  const GetDashboardUnitSoldChartRes = useSelector(
    (state) => state.Dashboard.GetDashboardUnitSoldChartResponse || {}
  );

  const GetDashboardSalesDataRes = useSelector(
    (state) => state.Dashboard.GetDashboardSalesDataResponse || {}
  );

  const GetSalesChannelListRes = useSelector(
    (state) => state.SalesAnalytics.GetSalesChannelListResponse || {}
  );

  const platformType = contextVar?.data?.marketplaceType;

  const [topProductList, setTopProductList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [salesChannelList, setsalesChannelList] = useState([]);
  const [selectedSalesChannel, setSelectedSalesChannel] = useState([]);
  const [selectedSalesChannelId, setSelectedSalesChannelId] = useState();
  const [salesChannelLoading, setSalesChannelLoading] = useState(true);
  const [selectedCurrencySign, setSelectedCurrencySign] = useState("");
  const [unitSoldChartDate, setUnitSoldChartDate] = useState([]);
  const [unitSoldChartSeries, setUnitSoldChartSeries] = useState([]);
  const [exportGraphArray, setExportGraphArray] = useState([]);
  const [salesData, setSalesData] = useState([]);

  const [salesLoading, setSalesLoading] = useState(true);
  const [unitSoldChartLoading, setUnitSoldChartLoading] = useState(true);
  const [topProductLoading, setTopProductLoading] = useState(true);
  const [topProductChartCategory, setTopProductChartCategory] = useState(true);
  const [topProductChartSeries, setTopProductChartSeries] = useState(true);
  const [selectedDateRangeType, setSelectedDateRangeType] = useState("custom");
  const [selectedViewBy, setSelectedViewBy] = useState("week");

  const [filter, setFilter] = useState({
    date_period: [dayjs().add(-30, "d"), dayjs()],
  });

  useEffect(() => {
    setUnitSoldChartSeries([]);
    setUnitSoldChartDate([]);
    setSalesLoading(true);
    setUnitSoldChartLoading(true);
    setTopProductLoading(true);
    GetSalesChannelList();

    return () => {};
  }, [contextVar]);

  useEffect(() => {
    if (GetSalesChannelListRes?.status === true) {
      setSalesChannelLoading(false);
      setsalesChannelList(GetSalesChannelListRes?.data?.sales_channel);
      setSelectedSalesChannelId("ATVPDKIKX0DER");
      setSelectedSalesChannel("Amazon.com");
      setSelectedCurrencySign("$");
      const obj = {
        filter_date:
          moment(filter?.date_period?.[0].$d).format("MMM DD, YYYY") +
          " - " +
          moment(filter?.date_period?.[1].$d).format("MMM DD, YYYY"),
        sales_channel: "Amazon.com",
        filterKey: selectedDateRangeType,
        viewBy: selectedViewBy,
      };
      // setSalesLoading(true);
      // setUnitSoldChartLoading(true);
      // setTopProductLoading(true);
      GetTopProductsList(obj, platformType);
      GetDashboardUnitSoldChart(obj, platformType);
      GetDashboardSalesData(obj, platformType);
      fakeActionSalesAnalytics("GetSalesChannelListResponse");
    } else if (GetSalesChannelListRes?.status === false) {
      message.destroy();
      message.error(GetSalesChannelListRes?.message);
      fakeActionSalesAnalytics("GetSalesChannelListResponse");
    }
  }, [GetSalesChannelListRes]);

  useEffect(() => {
    if (GetDashboardTopProductsListRes?.status === true) {
      setTopProductList(GetDashboardTopProductsListRes?.data);
      let unitSold = [];
      let totalSales = [];
      const category = GetDashboardTopProductsListRes?.data?.map((d) => {
        unitSold.push(d?.order_units);
        totalSales.push(d?.total_sales);
        return d?.sku;
      });
      const newArr = [
        { name: "Total Sales", data: totalSales },
        {
          name: "Unit Sold",
          data: unitSold,
        },
      ];
      setTopProductChartCategory(category);
      setTopProductChartSeries(newArr);
      setTopProductLoading(false);
      fakeActionDashboard("GetDashboardTopProductsListResponse");
    } else if (GetDashboardTopProductsListRes?.status === false) {
      setTopProductLoading(false);
      message.destroy();
      message.error(GetDashboardSalesDataRes?.message);
      setTopProductList([]);
      fakeActionDashboard("GetDashboardTopProductsListResponse");
    }
  }, [GetDashboardTopProductsListRes]);

  useEffect(() => {
    if (GetDashboardSalesDataRes?.status === true) {
      setSalesData(GetDashboardSalesDataRes?.data || []);
      setSalesLoading(false);
      fakeActionDashboard("GetDashboardSalesDataResponse");
    } else if (GetDashboardSalesDataRes?.status === false) {
      setSalesLoading(false);
      message.destroy();
      message.error(GetDashboardSalesDataRes?.message);
      setSalesData([]);
      fakeActionDashboard("GetDashboardSalesDataResponse");
    }
  }, [GetDashboardSalesDataRes]);

  useEffect(() => {
    if (GetDashboardUnitSoldChartRes?.status === true) {
      console.log(GetDashboardUnitSoldChartRes, "GetDashboardUnitSoldChartRes");
      if (platformType === "amazon") {
        const newSeries =
          Object.entries(GetDashboardUnitSoldChartRes?.data?.chartData)?.map(
            (d) => {
              console.log(d, "d----------");
              const name =
                d?.[0] === "order_sales"
                  ? "Total Sales"
                  : d?.[0] === "ad_spend"
                  ? "Ad Spend"
                  : d?.[0] === "TACoS"
                  ? "TACoS"
                  : "Unit Sold";
              const data = Object.entries(d?.[1])?.map((a) => {
                return parseFloat(a?.[1]);
              });
              return {
                name: name,
                data: data,
                type:
                  d?.[0] === "ad_spend" || d?.[0] === "TACoS"
                    ? "line"
                    : "column",
              };
            }
          ) || [];
        // console.clear();
        const finalData = Object.entries(
          GetDashboardUnitSoldChartRes?.data?.chartDate
        )?.map((d) => {
          const newdata = Object.entries(
            GetDashboardUnitSoldChartRes?.data?.chartData
          )?.map((a) => {
            console.log({ [a[0]]: a?.[1]?.[d?.[1]] }, "aa");
            return { [a[0]]: a?.[1]?.[d?.[1]] };
          });
          console.log(d, "dd");
          console.log(newdata, "newdata");
          const myArr = { date: d?.[1], other: newdata };

          const transformedData = myArr.other.reduce((result, obj) => {
            const key = Object.keys(obj)[0];
            result[key] = obj[key];
            return result;
          }, {});

          const finalResult = {
            date: myArr.date,
            ...transformedData,
          };

          console.log(finalResult, "finalResult");
          return finalResult;
        });
        setExportGraphArray(finalData);
        // console.clear();
        console.log(finalData, "finalData");

        setUnitSoldChartSeries(newSeries);
        setUnitSoldChartDate(GetDashboardUnitSoldChartRes?.data?.chartDate);
        setUnitSoldChartLoading(false);
      } else {
        const newSeries =
          Object.entries(GetDashboardUnitSoldChartRes?.data?.chartData)?.map(
            (d) => {
              console.log(d, "d----------");
              const name =
                d?.[0] === "order_sales" ? "Total Sales" : "Unit Sold";
              const data = Object.entries(d?.[1])?.map((a) => {
                return a?.[1];
              });
              return { name: name, data: data };
            }
          ) || [];
        setUnitSoldChartSeries(newSeries);
        setUnitSoldChartDate(GetDashboardUnitSoldChartRes?.data?.chartDate);
        setUnitSoldChartLoading(false);
        const finalData = Object.entries(
          GetDashboardUnitSoldChartRes?.data?.chartDate
        )?.map((d) => {
          const newdata = Object.entries(
            GetDashboardUnitSoldChartRes?.data?.chartData
          )?.map((a) => {
            return { [a[0]]: a?.[1]?.[d?.[1]] };
          });
          const myArr = { date: d?.[1], other: newdata };
          const transformedData = myArr.other.reduce((result, obj) => {
            const key = Object.keys(obj)[0];
            result[key] = obj[key];
            return result;
          }, {});

          const finalResult = {
            date: myArr.date,
            ...transformedData,
          };
          return finalResult;
        });
        setExportGraphArray(finalData);
      }
      fakeActionDashboard("GetDashboardUnitSoldChartResponse");
    } else if (GetDashboardUnitSoldChartRes?.status === false) {
      setUnitSoldChartLoading(false);
      setUnitSoldChartSeries([]);
      setUnitSoldChartDate([]);
      message.destroy();
      message.error(GetDashboardUnitSoldChartRes?.message);
      fakeActionDashboard("GetDashboardUnitSoldChartResponse");
    }
  }, [GetDashboardUnitSoldChartRes]);

  const onRangeChange = (_, dateStrings, filterKey) => {
    const filter_ = { ...filter };
    console.log(_, dateStrings);
    if (_ === null) {
      const currDate = [dayjs().add(-30, "d"), dayjs()];
      filter_.date_period = currDate;
      //   dateStrings[0] = `${currDate[0]?.format("MMM DD, YYYY")}`;
      //   dateStrings[1] = `${currDate[1]?.format("MMM DD, YYYY")}`;
    } else {
      filter_.date_period = _;
    }
    setFilter(filter_);
    const obj = {
      filter_date:
        moment(_?.[0].$d).format("MMM DD, YYYY") +
        " - " +
        moment(_?.[1].$d).format("MMM DD, YYYY"),
      sales_channel: selectedSalesChannel,
      filterKey: filterKey,
      viewBy: selectedViewBy,
    };
    setSalesLoading(true);
    setUnitSoldChartLoading(true);
    setTopProductLoading(true);
    GetTopProductsList(obj, platformType);
    GetDashboardUnitSoldChart(obj, platformType);
    GetDashboardSalesData(obj, platformType);
  };

  return (
    <>
      <div
        className="d-flex flex-column flex-column-fluid fadeInRight"
        data-select2-id="select2-data-4-9p4u"
      >
        <div
          id="kt_app_content"
          className="app-content flex-column-fluid"
          data-select2-id="select2-data-kt_app_content"
        >
          <div
            id="kt_app_content_container"
            className="app-container container-fluid"
            data-select2-id="select2-data-kt_app_content_container"
          >
            <div className="row g-5 g-xl-5 mb-5">
              <div className="col-md-12">
                <div className="d-lg-flex gap-2 justify-content-between">
                  <div className="d-flex flex-wrap gap-2 mb-1">
                    <div className="min-w-150px">
                      <div>
                        <RangePickerIVCSR
                          className="ant_common_input"
                          id="ivcsr_date_picker"
                          size="large"
                          allowClear={false}
                          style={{ width: "290px" }}
                          format={DateFormat}
                          value={filter?.date_period}
                          getPopupContainer={(triggerNode) =>
                            triggerNode.parentNode
                          }
                          onChange={(_, e) => {
                            const filterKey = handleDateRangeChange(e, _);
                            console.log(filterKey, "filterKey-----");
                            setSelectedDateRangeType(filterKey);
                            if (
                              filterKey !== "today" &&
                              filterKey !== "yesterday" &&
                              filterKey !== "last_week" &&
                              filterKey !== "custom"
                            ) {
                              setSelectedViewBy("week");
                            }
                            onRangeChange(_, e, filterKey);
                          }}
                          // presets={[
                          //   {
                          //     label: "Today",
                          //     value: [dayjs(), dayjs()],
                          //   },
                          //   {
                          //     label: "Yesterday",
                          //     value: [dayjs().add(-1, "d"), dayjs().add(-1, "d")],
                          //   },
                          //   {
                          //     label: "LW",
                          //     value: [
                          //       dayjs().subtract(1, "week").startOf("week"),
                          //       dayjs().subtract(1, "week").endOf("week"),
                          //     ],
                          //   },
                          //   {
                          //     label: "L4W",
                          //     value: [dayjs().add(-28, "d"), dayjs()],
                          //   },
                          //   {
                          //     label: "L13W",
                          //     value: [dayjs().add(-91, "d"), dayjs()],
                          //   },
                          //   {
                          //     label: "YTD",
                          //     value: [dayjs().startOf("year"), dayjs()],
                          //   },
                          //   {
                          //     label: "L52W",
                          //     value: [dayjs().add(-365, "d"), dayjs()],
                          //   },
                          //   {
                          //     label: "Custom Range",
                          //     value: [0, 0],
                          //   },
                          // ]}
                        />

                        {/* <RangePickerIVCSR
                        className="ant_common_input"
                        id="ivcsr_date_picker"
                        size="large"
                        allowClear={false}
                        style={{ width: "290px" }}
                        format={DateFormat}
                        value={filter?.date_period}
                        getPopupContainer={(triggerNode) =>
                          triggerNode.parentNode
                        }
                        // presets={[
                        //   {
                        //     label: "Today",
                        //     value: [dayjs(), dayjs()],
                        //   },
                        //   {
                        //     label: "Yesterday",
                        //     value: [dayjs().add(-1, "d"), dayjs().add(-1, "d")],
                        //   },
                        //   {
                        //     label: "LW",
                        //     value: [dayjs().add(-7, "d"), dayjs()],
                        //   },
                        //   {
                        //     label: "L4W",
                        //     value: [dayjs().add(-28, "d"), dayjs()],
                        //   },
                        //   {
                        //     label: "L13W",
                        //     value: [dayjs().add(-91, "d"), dayjs()],
                        //   },
                        //   {
                        //     label: "YTD",
                        //     value: [dayjs().startOf("year"), dayjs()],
                        //   },
                        //   {
                        //     label: "L52W",
                        //     value: [dayjs().add(-365, "d"), dayjs()],
                        //   },
                        //   {
                        //     label: "Custom Range",
                        //     value: [0, 0],
                        //   },
                        // ]}
                        onOpenChange={(e) => {
                          if (e) {
                            console.log(e, "-----------");
                            const filter_ = {
                              ...filter,
                            };
                            const currDate = [dayjs().add(-30, "d"), null];
                            filter_.date_period = currDate;
                            setFilter(filter_);
                          }
                        }}
                        onChange={(_, e) => {
                          onRangeChange(_, e);
                        }}
                      /> */}
                      </div>
                    </div>
                    <div
                      className="d-flex align-items-center ps-3"
                      style={{
                        border: "1px solid #d3cdff",
                        borderRadius: "5px",
                        background: "#f5f3ff",
                        height: "44px",
                      }}
                    >
                      <div className="d-flex align-items-center">
                        <label className="fw-bolder fs-5 me-5">
                          View By :{" "}
                        </label>{" "}
                        <Radio.Group
                          onChange={(e) => {
                            setSelectedViewBy(e.target.value);
                            const obj = {
                              filter_date:
                                moment(filter?.date_period?.[0].$d).format(
                                  "MMM DD, YYYY"
                                ) +
                                " - " +
                                moment(filter?.date_period?.[1].$d).format(
                                  "MMM DD, YYYY"
                                ),
                              sales_channel: "Amazon.com",
                              filterKey: "custom",
                              viewBy: e.target.value,
                            };
                            setUnitSoldChartLoading(true);
                            GetDashboardUnitSoldChart(obj, platformType);
                          }}
                          value={selectedViewBy}
                        >
                          <Radio
                            value={"day"}
                            disabled={
                              selectedDateRangeType !== "today" &&
                              selectedDateRangeType !== "yesterday" &&
                              selectedDateRangeType !== "last_week" &&
                              selectedDateRangeType !== "custom"
                            }
                          >
                            Day
                          </Radio>
                          <Radio value={"week"}>Week</Radio>
                          <Radio value={"month"}>Month</Radio>
                        </Radio.Group>
                      </div>
                    </div>
                    <div
                      className="d-flex align-items-center  px-3 "
                      style={
                        {
                          // border: "1px solid #d3cdff",
                          // borderRadius: "5px",
                          // background: "#f5f3ff",
                        }
                      }
                    >
                      <label className="fw-bold fs-5 me-2">Filter Type :</label>
                      <label
                        className="fw-bolder fs-4"
                        style={{
                          color: "#2e1e75",
                          textTransform: "capitalize",
                        }}
                      >
                        {selectedDateRangeType?.replace("_", " ")}
                      </label>
                    </div>
                  </div>

                  <div className="d-flex">
                    <Select
                      className="w-250px"
                      size="large"
                      placeholder="Select Sales Channel"
                      loading={salesChannelLoading}
                      value={selectedSalesChannelId || null}
                      onChange={(e, _) => {
                        console.log(_, "_");
                        setSelectedSalesChannel(_?.sales_channel);
                        setSelectedCurrencySign(_?.currency_sign);
                        setSelectedSalesChannelId(e);
                        const obj = {
                          filter_date:
                            moment(filter?.date_period?.[0].$d).format(
                              "MMM DD, YYYY"
                            ) +
                            " - " +
                            moment(filter?.date_period?.[1].$d).format(
                              "MMM DD, YYYY"
                            ),
                          sales_channel: _?.sales_channel,
                          filterKey: selectedDateRangeType,
                          viewBy: selectedViewBy,
                        };
                        setSalesLoading(true);
                        setUnitSoldChartLoading(true);
                        setTopProductLoading(true);
                        GetTopProductsList(obj, platformType);
                        GetDashboardUnitSoldChart(obj, platformType);
                        GetDashboardSalesData(obj, platformType);
                      }}
                      options={SalesChannelFlag(WalmartMarketplaceList)}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="row g-5 g-xl-5">
              <div className="col-xxl-8 mb-5 mb-xl-5">
                <div className="row">
                  <DashboardCard
                    salesData={salesData}
                    salesLoading={salesLoading}
                    selectedCurrencySign={selectedCurrencySign}
                    {...props}
                  />

                  <div className="col-12">
                    <UnitSoldGraph
                      unitSoldChartDate={unitSoldChartDate}
                      unitSoldChartSeries={unitSoldChartSeries}
                      unitSoldChartLoading={unitSoldChartLoading}
                      {...props}
                    />
                  </div>
                </div>
              </div>
              <div className="col-xxl-4 mb-md-5 mb-xl-5">
                <SalesBreakdown
                  salesData={salesData}
                  salesLoading={salesLoading}
                  selectedCurrencySign={selectedCurrencySign}
                  {...props}
                />
              </div>
            </div> */}
            <div className="row g-5 g-xl-5 ">
              <DashboardCard
                salesData={salesData}
                salesLoading={salesLoading}
                selectedCurrencySign={selectedCurrencySign}
                {...props}
              />
            </div>
            <div>
              {" "}
              <div className="row g-5 g-xl-5">
                <div className="col-xxl-7 mb-5 mb-xl-5">
                  {unitSoldChartLoading ? (
                    <div className="card card-flush">
                      <div className="card-header pt-7">
                        <h3 className="card-title align-items-start flex-column">
                          <span className="card-label fw-bold text-dark">
                            Unit Sold
                          </span>
                          <span className="text-gray-400 pt-2 fw-semibold fs-6">
                            Unit Sold Overview
                          </span>
                        </h3>
                        <div className="card-toolbar"></div>
                      </div>
                      <div className="card-body pb-0">
                        <div
                          className="w-100"
                          style={{ minHeight: "430px" }}
                          id="chartZoom"
                        >
                          <div className="d-flex justify-content-center align-items-center">
                            <Skeleton.Button
                              block
                              active
                              style={{ height: "300px" }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <WalmartUnitSoldGraph
                      unitSoldChartDate={unitSoldChartDate}
                      unitSoldChartSeries={unitSoldChartSeries}
                      unitSoldChartLoading={unitSoldChartLoading}
                      selectedCurrencySign={selectedCurrencySign}
                      selectedDateRangeType={selectedDateRangeType}
                      exportGraphArray={exportGraphArray}
                      selectedViewBy={selectedViewBy}
                      {...props}
                    />
                  )}
                </div>
                <div className="col-xxl-5 mb-md-5 mb-xl-5">
                  <WalmartSalesBreakdown
                    salesData={salesData}
                    salesLoading={salesLoading}
                    selectedCurrencySign={selectedCurrencySign}
                    {...props}
                  />
                </div>
              </div>
              <div className="row gy-5 g-xl-5">
                <div className="col-xxl-12">
                  <TopProducts
                    topProductList={topProductList}
                    topProductLoading={topProductLoading}
                    platformType={platformType}
                    chartCategory={topProductChartCategory}
                    chartSeries={topProductChartSeries}
                    {...props}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
