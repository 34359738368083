import { getUserId } from "../../../../config";
import axiosCall from "../../../configurations/network-services/axiosCall";
import walmartAxiosCall from "../../../configurations/network-services/walmartAxoisCall";

const prefix = "analytics";

export const GetRepricerProductListing = (data) => {
  const path = `repricing/products?page=${data?.page || 1}&per-page=${
    data?.perPage || 25
  }&search=${data?.searchTerm || ""}&marketplace_id=ATVPDKIKX0DER`;
  const responseType = "GET_REPRICER_LISTING";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const GetRepricerStrategies = (data) => {
  const path = `repricer`;
  const responseType = "GET_REPRICER_STRATEGIES";
  return walmartAxiosCall("get", path, responseType, data, {
    AUTHORIZATION: `Bearer ${localStorage.getItem("token")}`,
    "MARKETPLACE-ID": "ATVPDKIKX0DER",
    // user_id: 8,
    // user_id: JSON.parse(localStorage.getItem("user"))?.id,
    "USER-ID": getUserId(),
  });
};

export const fakeActionRepricerListing = (data) => async (dispatch) => {
  dispatch({ type: "FAKE_ACTION_REPRICER_LISTING", state: data });
};
