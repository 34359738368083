export default (state, action) => {
  if (typeof state === "undefined") {
    return [];
  }
  switch (action.type) {
    case "GET_SALES_ANALYTICS_CARD_DATA_SUCCESS":
    case "GET_SALES_ANALYTICS_CARD_DATA_ERROR":
      return {
        ...state,
        GetSalesAnalyticsCardDataResponse: action.updatePayload,
      };
    case "GET_SALES_OVERVIEW_CHART_SUCCESS":
    case "GET_SALES_OVERVIEW_CHART_ERROR":
      return {
        ...state,
        GetSalesAnalyticsSalesOverviewChartResponse: action.updatePayload,
      };
    case "GET_ORDER_OVERVIEW_CHART_SUCCESS":
    case "GET_ORDER_OVERVIEW_CHART_ERROR":
      return {
        ...state,
        GetSalesAnalyticsOrderOverviewChartResponse: action.updatePayload,
      };
    case "GET_SALES_TABLE_DATA_SUCCESS":
    case "GET_SALES_TABLE_DATA_ERROR":
      return {
        ...state,
        GetSalesAnalyticsTableDataResponse: action.updatePayload,
      };
    case "GET_SALES_CHANNEL_LIST_DATA_SUCCESS":
    case "GET_SALES_CHANNEL_LIST_DATA_ERROR":
      return {
        ...state,
        GetSalesChannelListResponse: action.updatePayload,
      };
    case "GET_MARKETPLACE_LIST_DATA_SUCCESS":
    case "GET_MARKETPLACE_LIST_DATA_ERROR":
      return {
        ...state,
        GetMarketplaceListResponse: action.updatePayload,
      };

    case "FAKE_ACTION_SALES_ANALYTICS":
      return { ...state, [action?.state]: {} };

    case "GET_WALMART_SALES_ANALYTICS_CARD_DATA_SUCCESS":
    case "GET_WALMART_SALES_ANALYTICS_CARD_DATA_ERROR":
      return {
        ...state,
        GetWalmartSalesAnalyticsCardDataResponse: action.updatePayload,
      };
    case "GET_WALMART_SALES_OVERVIEW_CHART_SUCCESS":
    case "GET_WALMART_SALES_OVERVIEW_CHART_ERROR":
      return {
        ...state,
        GetWalmartSalesAnalyticsSalesOverviewChartResponse:
          action.updatePayload,
      };
    case "GET_WALMART_ORDER_OVERVIEW_CHART_SUCCESS":
    case "GET_WALMART_ORDER_OVERVIEW_CHART_ERROR":
      return {
        ...state,
        GetWalmartSalesAnalyticsOrderOverviewChartResponse:
          action.updatePayload,
      };
    case "GET_WALMART_SALES_TABLE_DATA_SUCCESS":
    case "GET_WALMART_SALES_TABLE_DATA_ERROR":
      return {
        ...state,
        GetWalmartSalesAnalyticsTableDataResponse: action.updatePayload,
      };
    default:
      return state;
  }
};
