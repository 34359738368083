import React, { useContext, useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
// import Sidebar from "../modules/layouts/sidebar";
// import Header from "../modules/layouts/header";
// import Footer from "../modules/layouts/footer";
import PageRouter from "../pageRouter";
import Header from "../modules/layouts/header";
import Sidebar from "../modules/layouts/sidebar";
import Footer from "../modules/layouts/footer";
import { useLocation, useHistory, Link } from "react-router-dom";
import PageLoader from "../component/page-loader/page-loader";
import { GlobalContext } from "../commonContext";

export default function (props) {
  const [redirectPage, setRedirectPage] = useState(false);
  const [sidebarStatus, setSidebarStatus] = useState(false);
  const [mobileStatus, setMobileStatus] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isBackToAdmin, setIsBackToAdmin] = useState(0);
  const [isPageLoading, setIsPageLoading] = useState(true);

  const history = useHistory();
  const location = useLocation();

  if (redirectPage || !localStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  const checkLoginType = () => {
    return localStorage.getItem("userType");
  };
  const getUser = () => {
    return JSON.parse(localStorage.getItem("user"));
  };
  // console.log(sidebarStatus, mobileStatus, "-------");

  const backToAdmin = () => {
    let adminDataLocal = localStorage.getItem("adminData");
    if (adminDataLocal) {
      adminDataLocal = JSON.parse(adminDataLocal);
      localStorage.setItem("user", adminDataLocal.data);
      localStorage.setItem(
        "userType",
        JSON.parse(adminDataLocal?.data)?.user_type
      );
      localStorage.setItem("token", adminDataLocal.token);
      localStorage.removeItem("adminData");
    }
  };

  useEffect(() => {
    // console.log("location", location);
    if (location.pathname === "/") {
      if (localStorage.getItem("userType") == 2) {
        history.replace("/dashboard");
      } else {
        history.replace("/manage-user");
      }
    }
    // if (location.pathname === "/") {
    //   if (localStorage.getItem("adminData")) {
    //     history.replace("/dashboard");
    //   } else {
    //     history.replace("/manage-user");
    //   }
    // }
  }, []);

  const contextVar = useContext(GlobalContext);

  setTimeout(() => {
    setIsPageLoading(false);
  }, 3000);

  if (isPageLoading) {
    return <PageLoader />;
  }
  return (
    <>
      <div class="d-flex flex-column flex-root app-root" id="kt_app_root">
        <div
          className="app-page flex-column flex-column-fluid"
          id="kt_app_page"
        >
          <Header
            userType={checkLoginType()}
            setIsCollapsed={setIsCollapsed}
            isCollapsed={isCollapsed}
            setIsBackToAdmin={setIsBackToAdmin}
            isBackToAdmin={isBackToAdmin}
            backToAdmin={backToAdmin}
            setMobileStatus={setMobileStatus}
            {...props}
          />
          <div
            className="app-wrapper flex-column flex-row-fluid"
            id="kt_app_wrapper"
          >
            <Sidebar
              userType={checkLoginType()}
              setIsCollapsed={setIsCollapsed}
              isCollapsed={isCollapsed}
              setIsBackToAdmin={setIsBackToAdmin}
              isBackToAdmin={isBackToAdmin}
              backToAdmin={backToAdmin}
              mobileStatus={mobileStatus}
            />
            <div
              className="app-main flex-column flex-row-fluid "
              id="kt_app_main"
            >
              {/* <div style={{ height: `calc(100vh - (90px + 80px))` }}>  */}

              <div
              // style={{
              //   height: `calc(100vh - (90px + 80px))`,
              //   overflowY: "scroll",
              // }}
              >
                {mobileStatus && (
                  <div
                    onClick={() => setMobileStatus(false)}
                    style={{
                      background: "#00000033",
                      position: "absolute",
                      inset: 0,
                      zIndex: "100",
                    }}
                  />
                )}

                <PageRouter userType={checkLoginType()} {...props} />
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </>
  );

  // return (
  //   <>
  //     <div
  //       className="fade-in d-flex flex-column flex-root"
  //       style={{ height: "100%" }}
  //     >
  //       <div
  //         className="page d-flex flex-row flex-column-fluid"
  //         style={{ height: "100%" }}
  //       >
  //         <div className="fadeInLeft">
  //           <Sidebar
  //             setSidebarStatus={setSidebarStatus}
  //             sidebarStatus={sidebarStatus}
  //             screenStatus={screenStatus}
  //           />
  //         </div>
  //         <div className=" d-flex flex-column flex-row-fluid" id="kt_wrapper">
  //           <Header
  //             setSidebarStatus={setSidebarStatus}
  //             sidebarStatus={sidebarStatus}
  //           />

  //           <div className=" d-flex flex-column flex-row-fluid">
  //             {sidebarStatus && screenStatus && (
  //               <div
  //                 onClick={() => setSidebarStatus(false)}
  //                 style={{
  //                   background: "#00000026",
  //                   position: "absolute",
  //                   inset: 0,
  //                 }}
  //               />
  //             )}

  //             <div className=" flex-column flex-column-fluid">
  //               <div
  //                 style={{
  //                   height: "500px",
  //                   width: "500px",
  //                   backgroundColor: "red",
  //                 }}
  //               >
  //                 <span> Hiiii Hello</span>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </>
  // );
}
