import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import FeedItemsLog from "../../../modules/pages/master-table/feed-items-log";

import {
  GetFeedItemsLogList,
  fakeActionFeesItemsLog,
} from "../../../redux/modules/master-table/feed-items-log/feed-items-log.action";

const mapStateToProps = (state) => ({
  GetFeedItemsLogListResponse:
    state.FeedItemsLogReducer.GetFeedItemsLogListResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      GetFeedItemsLogList,
      fakeActionFeesItemsLog,
    },
    dispatch
  );

const FeedItemsLogContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(FeedItemsLog);

export default FeedItemsLogContainer;
