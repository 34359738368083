import { useEffect, useState } from "react";
import PreviewImage from "../../../../component/preview-image";
import { Skeleton } from "antd";

export default function (props) {
  const { productData, allLoading } = props;
  const [totalImage, setTotalImage] = useState([]);
  const [imageUrl, setImageUrl] = useState("");
  const [selectedImage, setSelectedImage] = useState(0);

  useEffect(() => {
    const imageData = JSON.parse(productData?.images || "[]");
    if (imageData?.length != 0) {
      setTotalImage(imageData);
      setSelectedImage(1);
      setImageUrl(JSON.parse(productData?.images || "[]")?.[0]?.link);
    }
  }, [productData]);
  return (
    <>
      <div className="col-xl-12">
        <div className="card card-flush h-lg-100 mb-5 mb-xl-5">
          <div
            className="card-header border-0 cursor-pointer min-h-50px"
            role="button"
            data-bs-toggle="collapse"
            data-bs-target="#kt_account_profile_details"
            aria-expanded="true"
            aria-controls="kt_account_profile_details"
          >
            <div className="card-title m-0">
              <h3 className="fw-bold m-0">Images</h3>
            </div>
          </div>

          <div className="card-body border-top border-top-dashed pt-5 pb-3">
            <div className="row mb-0">
              <div className="col-lg-6 ">
                {allLoading?.mainHeaderLoading ? (
                  <Skeleton.Button active block />
                ) : (
                  <div>
                    <div className="fs-5">
                      No. of Images :{" "}
                      <span className="fw-bold">{totalImage?.length || 0}</span>
                      , No. of Video : <span className="fw-bold">0</span>
                    </div>
                  </div>
                )}

                <div className="separator separator-dashed my-3" />
                <div
                  className="d-flex py-1"
                  style={{ width: "100%", height: "50px", overflowY: "auto" }}
                >
                  {" "}
                  {allLoading?.mainHeaderLoading ? (
                    <>
                      {" "}
                      <Skeleton.Button active className="me-2" />{" "}
                      <Skeleton.Button active className="me-2" />{" "}
                      <Skeleton.Button active className="me-2" />{" "}
                      <Skeleton.Button active className="me-2" />{" "}
                      <Skeleton.Button active className="me-2" />{" "}
                      <Skeleton.Button active className="me-2" />{" "}
                    </>
                  ) : (
                    totalImage?.map((d, i) => {
                      return (
                        <div
                          className="mx-2 cursor-pointer"
                          style={{
                            height: "30px",
                            minWidth: "30px",
                            background: selectedImage === i + 1 && "#4318ff",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: "3px",
                            fontSize: "17px",
                            color: selectedImage === i + 1 ? "#fff" : "#4318ff",
                          }}
                          onClick={() => {
                            setImageUrl(d?.link);
                            setSelectedImage(i + 1);
                          }}
                        >
                          {i + 1}
                        </div>
                      );
                    })
                  )}
                  {/* <a
                    href="#"
                    className="btn btn-icon btn-light-info btn-active-info fw-bold fs-4 me-2"
                  >
                    2
                  </a>
                  <a
                    href="#"
                    className="btn btn-icon btn-light-info btn-active-info fw-bold fs-4 me-2"
                  >
                    3
                  </a>
                  <a
                    href="#"
                    className="btn btn-icon btn-light-info  btn-active-info text-green fw-bold fs-4 me-2 position-relative"
                  >
                    4{" "}
                    <i
                      className="ki-solid ki-to-right"
                      style={{
                        position: "absolute",
                        top: 4,
                        right: 6,
                        fontSize: 15,
                      }}
                    />
                  </a>
                  <a
                    href="#"
                    className="btn btn-icon btn-light-info btn-active-info text-green fw-bold fs-4  position-relative"
                  >
                    5{" "}
                    <i
                      className="ki-solid ki-to-right"
                      style={{
                        position: "absolute",
                        top: 4,
                        right: 6,
                        fontSize: 15,
                      }}
                    />
                  </a> */}
                </div>
                <div className="separator separator-dashed my-3" />
                {/* <div className="fs-5 fw-bold mb-4">Analysis: </div>
                <div className="cus-badge mb-10 g-2 ">
                  <span className="badge-c mb-3">
                    <span className="ki-outline ki-check fs-3 me-2 badge__icon bg-success" />
                    Video relevance to SKU
                  </span>
                  <span className="badge-c mb-3">
                    <span className="ki-outline ki-check fs-3 me-2 badge__icon bg-success" />
                    Blurring Video
                  </span>
                  <span className="badge-c mb-3">
                    <span className="ki-outline ki-check fs-3 me-2 badge__icon bg-success" />
                    App view optimisation
                  </span>
                  <a href className="badge-c mb-3">
                    <span className="ki-outline ki-arrows-circle fs-3 me-2 badge__icon bg-info" />
                    Reload
                  </a>
                </div> */}
              </div>

              <div className="col-lg-6 fv-row">
                <div className="h-100px d-flex justify-content-center align-items-center">
                  {allLoading?.mainHeaderLoading ? (
                    <Skeleton.Node active />
                  ) : (
                    <PreviewImage imageUrl={imageUrl ? imageUrl : ""} />
                  )}
                </div>
                {/* <div className="card card-rec">
                  <div className="card-header bg-white py-2 px-4 min-h-50px h-50px">
                    <h3 className="card-title fs-4 fw-bold text-info">
                      <i className="ki-duotone ki-technology-4 fs-2 text-info me-3">
                        <span className="path1" />
                        <span className="path2" />
                        <span className="path3" />
                        <span className="path4" />
                        <span className="path5" />
                        <span className="path6" />
                        <span className="path7" />
                      </i>
                      Recommendation
                    </h3>
                    <div className="card-toolbar my-0">
                      <button className="btn btn-primary fw-bold btn-sm">
                        Generate
                      </button>
                    </div>
                  </div>
                  <div className="card-body min-h-150px">
                    "DUCRAY Shampoos, ABC - Gentle and Nourishing Hair Care -
                    Ideal for Daily Use - 200ml"
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
