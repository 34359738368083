import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AdvDashboard from "../../../modules/pages/advertisement/adv-dashboard/adv-dashboard";
import {
  GetSalesChannelList,
  fakeActionSalesAnalytics,
} from "../../../redux/modules/sales-analytics/sales-analytics.action";
import {
  GetAdvFilters,
  GetAdvDashboardGraphCardData,
  GetAdvDashboardSubCardData,
  GetActiveCampaignSubCardData,
  GetTargetingSubCardData,
  GetDailyPerformanceTrend,
  GetTopSearchTermData,
  fakeActionAdvDashboard,
  GetWalmartAdvFilter,
  GetWalmartAdvPerformanceChart,
  GetWalmartPerformanceSummary,
  GetWalmartCampaignByAdSpend,
  GetWalmartCampaignOutOfBudget,
  GetWalmartDashboardPerformanceMetrics,
} from "../../../redux/modules/advertisement/dashboard/index.action";

const mapStateToProps = (state) => ({
  GetFiltersListResponse: state.AdvDashboard.GetFiltersListResponse,
  GetDashboardCardGraphDataResponse:
    state.AdvDashboard.GetDashboardCardGraphDataResponse,
  GetDashboardSubCardDataResponse:
    state.AdvDashboard.GetDashboardSubCardDataResponse,
  GetActiveCampaignSubCardDataResponse:
    state.AdvDashboard.GetActiveCampaignSubCardDataResponse,
  GetTargetingSubCardDataResponse:
    state.AdvDashboard.GetTargetingSubCardDataResponse,
  GetDailyPerformanceTrendDataResponse:
    state.AdvDashboard.GetDailyPerformanceTrendDataResponse,
  GetTopSearchTermDataResponse: state.AdvDashboard.GetTopSearchTermDataResponse,

  GetWalmartAdvFilterResponse: state.AdvDashboard.GetWalmartAdvFilterResponse,
  GetWalmartAdvPerformanceChartResponse:
    state.AdvDashboard.GetWalmartAdvPerformanceChartResponse,
  GetWalmartAdvPerformanceSummaryResponse:
    state.AdvDashboard.GetWalmartAdvPerformanceSummaryResponse,
  GetWalmartCampaignAdSpendResponse:
    state.AdvDashboard.GetWalmartCampaignAdSpendResponse,
  GetWalmartCampaignOutOfBudgetResponse:
    state.AdvDashboard.GetWalmartCampaignOutOfBudgetResponse,
  GetWalmartDashboardPerformanceMetricsResponse:
    state.AdvDashboard.GetWalmartDashboardPerformanceMetricsResponse,

  GetSalesChannelListResponse: state.SalesAnalytics.GetSalesChannelListResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      GetSalesChannelList,
      fakeActionSalesAnalytics,

      GetAdvDashboardGraphCardData,
      GetAdvDashboardSubCardData,
      GetActiveCampaignSubCardData,
      GetTargetingSubCardData,
      GetDailyPerformanceTrend,
      GetAdvFilters,
      GetTopSearchTermData,
      fakeActionAdvDashboard,

      GetWalmartAdvFilter,
      GetWalmartAdvPerformanceChart,
      GetWalmartPerformanceSummary,
      GetWalmartCampaignByAdSpend,
      GetWalmartCampaignOutOfBudget,
      GetWalmartDashboardPerformanceMetrics,
    },
    dispatch
  );

const AdvDashboardContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AdvDashboard);

export default AdvDashboardContainer;
