import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import WalmartReturnRefundDetails from "../../../../modules/pages/order-management/returns-refunds/walmart-lib/return-refund-details";

import {
  GetReturnsAndRefundsOrderDetails,
  fakeActionReturnsRefunds,
} from "../../../../redux/modules/order-management/returns-refunds/returns-refunds.action";

const mapStateToProps = (state) => ({
  GetReturnsAndRefundsOrderDetailsResponse:
    state.WalmartReturnsRefunds.GetReturnsAndRefundsOrderDetailsResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { GetReturnsAndRefundsOrderDetails, fakeActionReturnsRefunds },
    dispatch
  );

const WalmartReturnRefundDetailsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(WalmartReturnRefundDetails);

export default WalmartReturnRefundDetailsContainer;
