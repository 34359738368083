import { Alert, Checkbox, Col, Input, Radio, Row, Select, message } from "antd";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { SearchOutlined } from "@ant-design/icons";
import { ReportScheduledWrapper } from "../style";
import NoData from "../../../../../component/no-data";
export default function ({
  show,
  onHide,
  onSuccess,
  data,
  AddUserAction,
  fakeActionManageUser,
}) {
  const [searchByUser, setSearchByUser] = useState("");
  const [selectedUserId, setSelectedUserId] = useState([]);
  const [userList, setUserList] = useState([
    {
      id: 12,
      name: "Merkury",
      seller_name: "Merkury",
      user_type: 2,
      email: "gbeyda@merkuryinnovations.com",
      photo: null,
      contact_no: "88666855021",
      company_name: null,
      pending_reports: 0,
      connected_by: "walmart",
      created_at: 1714732687,
      updated_at: 1716367541,
    },
    {
      id: 9,
      name: "Night Owl - Connect Account  (3PL) ",
      seller_name: "Night Owl - Connect Account  (3PL) ",
      user_type: 2,
      email: "cord4.neela1@gmail.com",
      photo: null,
      contact_no: "9898989898",
      company_name: null,
      pending_reports: 1,
      connected_by: "walmart",
      created_at: 1711718225,
      updated_at: 1,
    },
    {
      id: 8,
      name: "Neela ",
      seller_name: "Neela_test",
      user_type: 2,
      email: "cord4.neela@gmail.com",
      photo: null,
      contact_no: "8888888888",
      company_name: null,
      pending_reports: 153,
      connected_by: "walmart",
      created_at: 1701839905,
      updated_at: 1,
    },
    {
      id: 7,
      name: "Night Owl",
      seller_name: "Night Owl ",
      user_type: 2,
      email: "michael.napier@nightowlsp.com",
      photo: null,
      contact_no: "4793811131",
      company_name: null,
      pending_reports: 16,
      connected_by: "both",
      created_at: 1698181035,
      updated_at: 1,
    },
    {
      id: 2,
      name: "Abhishek",
      seller_name: "Abhishek",
      user_type: 2,
      email: "cord4.ab@gmail.com",
      photo: null,
      contact_no: "8564721359",
      company_name: null,
      pending_reports: 79,
      connected_by: "walmart",
      created_at: 1695896707,
      updated_at: 1,
    },
  ]);

  const [searchUserList, setSearchUserList] = useState([
    {
      id: 12,
      name: "Merkury",
      seller_name: "Merkury",
      user_type: 2,
      email: "gbeyda@merkuryinnovations.com",
      photo: null,
      contact_no: "88666855021",
      company_name: null,
      pending_reports: 0,
      connected_by: "walmart",
      created_at: 1714732687,
      updated_at: 1716367541,
    },
    {
      id: 9,
      name: "Night Owl - Connect Account  (3PL) ",
      seller_name: "Night Owl - Connect Account  (3PL) ",
      user_type: 2,
      email: "cord4.neela1@gmail.com",
      photo: null,
      contact_no: "9898989898",
      company_name: null,
      pending_reports: 1,
      connected_by: "walmart",
      created_at: 1711718225,
      updated_at: 1,
    },
    {
      id: 8,
      name: "Neela ",
      seller_name: "Neela_test",
      user_type: 2,
      email: "cord4.neela@gmail.com",
      photo: null,
      contact_no: "8888888888",
      company_name: null,
      pending_reports: 153,
      connected_by: "walmart",
      created_at: 1701839905,
      updated_at: 1,
    },
    {
      id: 7,
      name: "Night Owl",
      seller_name: "Night Owl ",
      user_type: 2,
      email: "michael.napier@nightowlsp.com",
      photo: null,
      contact_no: "4793811131",
      company_name: null,
      pending_reports: 16,
      connected_by: "both",
      created_at: 1698181035,
      updated_at: 1,
    },
    {
      id: 2,
      name: "Abhishek",
      seller_name: "Abhishek",
      user_type: 2,
      email: "cord4.ab@gmail.com",
      photo: null,
      contact_no: "8564721359",
      company_name: null,
      pending_reports: 79,
      connected_by: "walmart",
      created_at: 1695896707,
      updated_at: 1,
    },
  ]);
  // const AddUserRes = useSelector(
  //   (state) => state.ManageUser.AddUserResponse || {}
  // );

  return (
    <>
      <Modal show={show} onHide={onHide} size="xl" centered backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Schedule</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ReportScheduledWrapper>
            <div className="modal-body pb-7 pt-5 px-lg-10">
              <div className=" mb-0">
                <div className="row gx-10">
                  <div className="col-12 col-lg-4">
                    <div className="position-relative mb-5 me-2">
                      <Input
                        type="text"
                        className="text-dark fs-7 fw-bold ant_common_input "
                        name="all_search"
                        placeholder="Search by Name, Email..."
                        style={{ width: "100%" }}
                        value={searchByUser}
                        size="large"
                        onChange={(e) => {
                          setSearchByUser(e.target.value);
                        }}
                        onPressEnter={(e) => {
                          const filterUser = userList?.filter((d) => {
                            const searchStr = e.target.value?.toLowerCase();
                            const sellerName = d?.seller_name?.toLowerCase();
                            return sellerName.includes(searchStr);
                          });
                          console.log(filterUser, "-filterUser");
                          setSearchUserList(filterUser);
                        }}
                        prefix={
                          <SearchOutlined
                            style={{ fontSize: "19px", color: "grey" }}
                          />
                        }
                      />
                    </div>
                    {console.log(selectedUserId, "selecteduserid")}
                    <div className="scroll h-300px px-2 ps-0">
                      {searchUserList?.length > 0 ? (
                        <div className="row mx-0">
                          {searchUserList?.map((d) => {
                            return (
                              <>
                                <div
                                  className="col-12"
                                  onClick={() => {
                                    setSelectedUserId((prev) => {
                                      // Check if the item is already selected
                                      const isSelected = prev.includes(d?.id);
                                      if (isSelected) {
                                        // If selected, remove it from the array
                                        return prev.filter(
                                          (item) => item !== d?.id
                                        );
                                      } else {
                                        // If not selected, add it to the array
                                        return [...prev, d?.id];
                                      }
                                    });
                                  }}
                                >
                                  <label
                                    className={`btn btn-outline btn-outline-dashed btn-active-light-info d-flex align-items-center px-4 py-2 mb-4 ${
                                      selectedUserId.includes(d?.id)
                                        ? "active"
                                        : ""
                                    }`}
                                    htmlFor="kt_radio_buttons_2_option_2"
                                  >
                                    <div className="d-flex align-items-center">
                                      <div className="symbol symbol-circle symbol-30px overflow-hidden me-3">
                                        <img
                                          src="assets/media/avatars/300-25.jpg"
                                          alt
                                        />
                                      </div>

                                      <div className="d-flex flex-column text-start">
                                        <span className="text-gray-800 text-hover-info mb-0 fs-7 fw-bolder">
                                          {d?.seller_name}
                                        </span>
                                        <span className="fs-8 text-gray-600">
                                          {d?.email}
                                        </span>
                                      </div>
                                    </div>
                                  </label>
                                </div>
                              </>
                            );
                          })}
                        </div>
                      ) : (
                        <NoData height="250px" />
                      )}

                      {/* <Radio.Group onChange={onChangeUserRadio}>
                        {userList?.map((d) => {
                          return (
                            <Radio value={d?.id}>
                              <label className="btn btn-outline btn-outline-dashed btn-active-light-info p-0 d-flex align-items-center px-4 py-2">
                                <div className="d-flex align-items-center">
                                  <div className="symbol symbol-circle symbol-30px overflow-hidden me-3">
                                    <img
                                      src="assets/media/avatars/300-25.jpg"
                                      alt
                                    />
                                  </div>
                                  <div className="d-flex flex-column text-start">
                                    <a
                                      href
                                      className="text-gray-800 text-hover-info mb-0 fs-7 fw-bolder"
                                    >
                                      {d?.seller_name}
                                    </a>
                                    <span className="fs-8 text-gray-600">
                                      {d?.email}
                                    </span>
                                  </div>
                                </div>
                              </label>
                            </Radio>
                          );
                        })}
                      </Radio.Group> */}
                    </div>
                  </div>
                  <div className="col-12 col-lg-8 ">
                    <div className="row ">
                      <div className="col-md-12 mb-2">
                        <label htmlFor className="fw-bold mb-0 fs-6">
                          Report defination based on
                        </label>
                      </div>
                      <div className="col-md-4">
                        <Select
                          style={{ width: 200 }}
                          placeholder="Select an option"
                          size="large"
                          getPopupContainer={(triggerNode) =>
                            triggerNode.parentNode
                          }
                          options={[
                            {
                              label: "WOW",
                              value: "WOW",
                            },
                            {
                              label: "MOM",
                              value: "MOM",
                            },
                            {
                              label: "YOY",
                              value: "YOY",
                            },
                            {
                              label: "Life time performance",
                              value: "Life time performance",
                            },
                          ]}
                        ></Select>
                      </div>
                      <div className="col-md-4">
                        <Select
                          style={{ width: 200 }}
                          placeholder="Select an option"
                          size="large"
                          getPopupContainer={(triggerNode) =>
                            triggerNode.parentNode
                          }
                          options={[
                            {
                              label: "SP Reports",
                              value: "SP Reports",
                            },
                            {
                              label: "Advertisement Report",
                              value: "Advertisement Report",
                            },
                          ]}
                        ></Select>
                      </div>
                      <div className="col-md-4"></div>
                    </div>
                    <div className="separator separator-dashed borde-gray-300 my-4" />
                    <div className=" row">
                      <div className="col-md-6">
                        <label htmlFor className="fw-bold mb-0 fs-6">
                          Frequency:
                        </label>
                        <div className="mt-8">
                          <Checkbox.Group
                            style={{
                              width: "100%",
                            }}
                          >
                            <div className="row mx-0">
                              <div className="col-12 my-2">
                                <Checkbox value={"7days"}>
                                  <label className="form-check-label fw-bold text-dark fs-6">
                                    7 Days
                                  </label>
                                </Checkbox>
                              </div>{" "}
                              <div className="col-12 my-2">
                                <Checkbox value={"15days"}>
                                  <label className="form-check-label fw-bold text-dark fs-6">
                                    15 Days
                                  </label>
                                </Checkbox>
                              </div>{" "}
                              <div className="col-12 my-2">
                                <Checkbox value={"30days"}>
                                  <label className="form-check-label fw-bold text-dark fs-6">
                                    30 Days
                                  </label>
                                </Checkbox>
                              </div>{" "}
                              <div className="col-12 my-2">
                                <Checkbox value={"One Time"}>
                                  <label className="form-check-label fw-bold text-dark fs-6">
                                    One Time
                                  </label>
                                </Checkbox>
                              </div>
                            </div>
                          </Checkbox.Group>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <label htmlFor className="fw-bold mb-0 fs-6">
                          Lookback Window:
                        </label>
                        <div className="mt-8">
                          <Checkbox.Group
                            style={{
                              width: "100%",
                            }}
                          >
                            <div className="row mx-0">
                              <div className="col-12 my-2">
                                <Checkbox value={"7days"}>
                                  <label className="form-check-label fw-bold text-dark fs-6">
                                    Last 7 Days
                                  </label>
                                </Checkbox>
                              </div>{" "}
                              <div className="col-12 my-2">
                                <Checkbox value={"15days"}>
                                  <label className="form-check-label fw-bold text-dark fs-6">
                                    Last 15 Days
                                  </label>
                                </Checkbox>
                              </div>{" "}
                              <div className="col-12 my-2">
                                <Checkbox value={"30days"}>
                                  <label className="form-check-label fw-bold text-dark fs-6">
                                    Last 30 Days
                                  </label>
                                </Checkbox>
                              </div>{" "}
                              <div className="col-12 my-2">
                                <Checkbox value={"One Time"}>
                                  <label className="form-check-label fw-bold text-dark fs-6">
                                    Custom
                                  </label>
                                </Checkbox>
                              </div>
                              <div className="col-12 my-2">
                                <Input
                                  className="ant_common_input"
                                  size="large"
                                  style={{ width: "50%" }}
                                />
                              </div>
                            </div>
                          </Checkbox.Group>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ReportScheduledWrapper>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-light-danger"
            onClick={onHide}
          >
            Close
          </button>
          <button
            type="submit"
            className="btn btn-primary"
            // onClick={onSubmit}
            // disabled={
            //   name && email && sellerName && contact && !sending ? false : true
            // }
            onClick={onHide}
          >
            Schedule
            {/* {sending && (
              <span className="spinner-border spinner-border-sm align-middle ms-2" />
            )} */}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
