import axiosCall from "../../configurations/network-services/axiosCall";

const prefix = "dashboard";

export const GetTopProductsList = (data, platformType) => {
  const newPrefix = platformType === "walmart" ? `wm/${prefix}` : `${prefix}`;
  const path = `${newPrefix}/get-products?filter_date=${
    data?.filter_date || ""
  }&sales_channel=${data?.sales_channel || ""}`;
  const responseType = "GET_DASHBOARD_TOP_PRODUCTS";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetDashboardUnitSoldChart = (data, platformType) => {
  const newPrefix = platformType === "walmart" ? `wm/${prefix}` : `${prefix}`;
  const path = `${newPrefix}/unit-sold-chart?filter_date=${
    data?.filter_date || ""
  }&sales_channel=${data?.sales_channel || ""}&selection_type=${
    data?.filterKey || ""
  }&search_key=${data?.data?.searchTerm || ""}&view_by=${data?.viewBy || ""}`;
  const responseType = "GET_DASHBOARD_UNIT_SOLD_CHART";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetDashboardSalesData = (data, platformType) => {
  const newPrefix = platformType === "walmart" ? `wm/${prefix}` : `${prefix}`;
  const path = `${newPrefix}/get-sales-data?filter_date=${
    data?.filter_date || ""
  }&sales_channel=${data?.sales_channel || ""}`;
  const responseType = "GET_DASHBOARD_SALES_DATA";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

// export const GetSalesAnalyticsTableData = (data) => {
//   const path = `${prefix}/sku-wise-data?page=${data?.page || 1}&per-page=${
//     data?.perPage || 10
//   }&search_key=${data?.searchTerm || ""}&filter_date=${
//     data?.filter_date || ""
//   }&sales_channel=Amazon.com&isExport=0`;
//   const responseType = "GET_SALES_TABLE_DATA";
//   return axiosCall("get", path, responseType, null, {
//     Authorization: `Bearer ${localStorage.getItem("token")}`,
//   });
// };

// export const GetSalesChannelList = (data) => {
//   const path = `sales-channel`;
//   const responseType = "GET_SALES_CHANNEL_LIST_DATA";
//   return axiosCall("get", path, responseType, null, {
//     Authorization: `Bearer ${localStorage.getItem("token")}`,
//   });
// };

export const fakeActionDashboard = (data) => async (dispatch) => {
  dispatch({ type: "FAKE_ACTION_DASHBOARD", state: data });
};
