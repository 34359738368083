import { Skeleton, Tag } from "antd";
import { SyncOutlined } from "@ant-design/icons";
import NoData from "../../../../component/no-data";
import { useEffect } from "react";
import { useSelector } from "react-redux";

export default function (props) {
  const {
    productCriteriaData,
    productData,
    suggestedTitleAnalysis,
    setSuggestedTitleAnalysis,
    allLoading,
    setAllLoading,
    recommendedTitle,
    setRecommendedTitle,
    GetCriteriaAnalysisTitle,
    GetRecommendationTitle,
    fakeActionListingOptimizer,
  } = props;

  const GetCriteriaAnalysisTitleRes = useSelector(
    (state) => state.ListingOptimizer.GetCriteriaAnalysisTitleResponse || {}
  );
  const GetRecommendationTitleRes = useSelector(
    (state) => state.ListingOptimizer.GetRecommendationTitleResponse || {}
  );

  useEffect(() => {
    const loading = { ...allLoading };
    if (GetCriteriaAnalysisTitleRes?.status === true) {
      console.log(GetCriteriaAnalysisTitleRes, "GetCriteriaAnalysisTitleRes");
      loading.titleAnalysis = false;
      setAllLoading(loading);
      setSuggestedTitleAnalysis(
        GetCriteriaAnalysisTitleRes?.data?.matchCriteria || {}
      );
      fakeActionListingOptimizer("GetCriteriaAnalysisTitleResponse");
    } else if (GetCriteriaAnalysisTitleRes?.status === false) {
      loading.titleAnalysis = false;
      setAllLoading(loading);
      fakeActionListingOptimizer("GetCriteriaAnalysisTitleResponse");
    }
  }, [GetCriteriaAnalysisTitleRes]);

  useEffect(() => {
    const loading = { ...allLoading };
    if (GetRecommendationTitleRes?.status === true) {
      console.log(GetRecommendationTitleRes, "GetRecommendationTitleRes");
      setRecommendedTitle(GetRecommendationTitleRes?.data?.suggested_title);
      loading.titleRecommendation = false;
      setAllLoading(loading);
      fakeActionListingOptimizer("GetRecommendationTitleResponse");
    } else if (GetRecommendationTitleRes?.status === false) {
      loading.titleRecommendation = false;
      setAllLoading(loading);
      fakeActionListingOptimizer("GetRecommendationTitleResponse");
    }
  }, [GetRecommendationTitleRes]);

  return (
    <div className="col-xl-12">
      <div className="card card-flush h-lg-100 mb-5 mb-xl-5">
        <div
          className="card-header border-0 cursor-pointer min-h-50px"
          role="button"
          data-bs-toggle="collapse"
          data-bs-target="#kt_account_profile_details"
          aria-expanded="true"
          aria-controls="kt_account_profile_details"
        >
          <div className="card-title m-0">
            <h3 className="fw-bold m-0">
              Title
              <label className="ms-2">
                <Tag color="#108ee9" className="fw-bolder fs-5">
                  Score : {productCriteriaData?.title_out_of_score}
                </Tag>
              </label>
            </h3>
          </div>
        </div>

        <div className="card-body border-top border-top-dashed pt-5 pb-3">
          <div className="row mb-0">
            <div className="col-lg-6 ">
              {allLoading?.mainHeaderLoading ? (
                <>
                  {" "}
                  <Skeleton.Input
                    active
                    block
                    small
                    style={{ height: "18px" }}
                  />
                  <Skeleton.Input
                    active
                    block
                    small
                    style={{ height: "18px" }}
                  />
                  <Skeleton.Input active small style={{ height: "18px" }} />
                </>
              ) : (
                <div>
                  <div>
                    Current Title :{" "}
                    <span className="fw-bold">
                      {productData?.item_name || ""}
                    </span>
                  </div>
                </div>
              )}

              <div className="separator separator-dashed my-3" />
              <div className="fs-5 fw-bold mb-4">Analysis: </div>
              <div className="cus-badge mb-10 g-2 ">
                {allLoading?.mainHeaderLoading || allLoading?.titleAnalysis ? (
                  <>
                    <Skeleton.Button
                      active
                      style={{ borderRadius: "20px", height: "25px" }}
                      block
                      className=""
                    />
                    <Skeleton.Button
                      active
                      style={{ borderRadius: "20px", height: "25px" }}
                      block
                      className="my-1"
                    />
                    <Skeleton.Button
                      active
                      style={{ borderRadius: "20px", height: "25px" }}
                      block
                      className="mb-1"
                    />
                  </>
                ) : (
                  <>
                    {Object.entries(suggestedTitleAnalysis)?.map((d) => {
                      if (d?.[0] !== "Total Score" && d?.[0] !== "Score") {
                        return (
                          <span className="badge-c mb-3">
                            <span
                              className={`ki-outline ${
                                d?.[1] ? "ki-check" : "ki-cross"
                              }   fs-3 me-2 badge__icon ${
                                d?.[1] ? "bg-success" : "bg-danger"
                              }`}
                            />
                            {d?.[0]}
                          </span>
                        );
                      }
                    })}
                    <span
                      className="badge-c mb-3 cursor-pointer"
                      onClick={() => {
                        const loading = { ...allLoading };
                        loading.titleAnalysis = true;
                        setAllLoading(loading);
                        const body = {
                          parameter_name: "title",
                          content_text: productData?.item_name || "",
                          asin: productData?.asin,
                          brand: productData?.brand,
                        };
                        GetCriteriaAnalysisTitle(body);
                      }}
                    >
                      <span className=" fs-3 me-2 badge__icon bg-info">
                        {allLoading.titleAnalysis ? (
                          <SyncOutlined spin />
                        ) : (
                          <SyncOutlined />
                        )}
                      </span>
                      Reload
                    </span>
                  </>
                )}
              </div>
            </div>

            <div className="col-lg-6 fv-row">
              <div className="card card-rec">
                <div className="card-header bg-white py-2 px-4 min-h-50px h-50px">
                  <h3 className="card-title fs-4 fw-bold text-info">
                    <i className="ki-duotone ki-technology-4 fs-2 text-info me-3">
                      <span className="path1" />
                      <span className="path2" />
                      <span className="path3" />
                      <span className="path4" />
                      <span className="path5" />
                      <span className="path6" />
                      <span className="path7" />
                    </i>
                    Recommendation
                  </h3>
                  <div className="card-toolbar my-0">
                    <button
                      className="btn btn-light-info fw-bold btn-sm"
                      style={{ border: "1px solid #4318ff" }}
                      onClick={() => {
                        const loading = { ...allLoading };
                        loading.titleRecommendation = true;
                        setAllLoading(loading);
                        const body = {
                          parameter_name: "title",
                          content_text: productData?.item_name || "",
                          asin: productData?.asin,
                          brand: productData?.brand,
                        };
                        GetRecommendationTitle(body);
                      }}
                      disabled={
                        allLoading?.mainHeaderLoading ||
                        allLoading?.titleRecommendation
                      }
                    >
                      Generate
                    </button>
                  </div>
                </div>
                <div className="card-body min-h-150px">
                  {allLoading?.mainHeaderLoading ||
                  allLoading?.titleRecommendation ? (
                    <Skeleton active block />
                  ) : recommendedTitle ? (
                    recommendedTitle
                  ) : (
                    <NoData height="150px" />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
