import styled from "styled-components";
export const Wrapper = styled.div`
  .selectHeaderDD {
    .ant-select-selector {
      border-radius: 30px !important;
      // border: 1px solid #8f7be9 !important;
    }
  }
  .ant-select-selection-item {
    text-align: center;
  }
  .ant-select {
    // box-shadow: 0px 0px 19px -5px #8f7be9 !important;
    border-radius: 30px !important;
  }
`;
