import React, { useContext, useEffect } from "react";
import { GlobalContext } from "../../../../commonContext";
// import WalmartIndex from "./lib/walmart-index";
import { useHistory } from "react-router-dom";
import AmazonAdvDashboard from "./amazon-adv-dashboard";
import WalmartAdvDashboard from "./walmart-adv-dashboard";
import UnderConstruction from "../../../../component/under-construction/under-construction";

export default function (props) {
  const history = useHistory();
  const contextVar = useContext(GlobalContext);

  return contextVar?.data?.marketplaceType === "amazon" ? (
    // <UnderConstruction />
    <AmazonAdvDashboard {...props} />
  ) : (
    <WalmartAdvDashboard {...props} />
  );
}
