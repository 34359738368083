import {
  Checkbox,
  Dropdown,
  Input,
  Popover,
  Select,
  Tag,
  message,
  theme,
} from "antd";
import moment from "moment";
import React, { useEffect, useState, useContext } from "react";
import { useSelector } from "react-redux";
import IvcsrTable from "../../../../../component/ivcsr-table";
import Loading from "../../../../../component/loading";
import NoData from "../../../../../component/no-data";
import Pagination from "../../../../../component/pagination";
import {
  DefaultPerPage,
  NumberWithCommas,
  SalesChannelFlag,
  timeSince,
} from "../../../../../config";
import { SearchOutlined } from "@ant-design/icons";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { skuDataSelectOption } from "../../../../../config/static-select-option";
import { GlobalContext } from "../../../../../commonContext";
import AmazonCogsModal from "./amazon-cogs-modal";
import { CheckboxWrapper } from "../../../sales-analytics/style";
import writeXlsxFile from "write-excel-file";
const { useToken } = theme;
export default function (props) {
  const {
    GetSkuDataList,
    fakeActionSkuData,
    GetSalesChannelList,
    fakeActionSalesAnalytics,
  } = props;

  const contextVar = useContext(GlobalContext);

  const GetSalesChannelListRes = useSelector(
    (state) => state.SalesAnalytics.GetSalesChannelListResponse || {}
  );

  const GetSkuDataListRes = useSelector(
    (state) => state.SKUData.GetSkuDataListResponse || {}
  );

  const { token } = useToken();
  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
    minWidth: "150px",
  };

  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageSize, setPageSize] = useState(DefaultPerPage);
  const [filter, setFilter] = useState(null);
  const [salesChannelList, setsalesChannelList] = useState([]);
  const [selectedSalesChannel, setSelectedSalesChannel] = useState([]);
  const [selectedSalesChannelId, setSelectedSalesChannelId] = useState([]);
  const [salesChannelLoading, setSalesChannelLoading] = useState(true);
  const [selectedCurrencySign, setSelectedCurrencySign] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [isOpenCogsModal, setIsOpenCogsModal] = useState(false);
  const [isExportFlag, setIsExportFlag] = useState(false);

  useEffect(() => {
    GetSalesChannelList();
    return () => {};
  }, []);

  useEffect(() => {
    if (GetSalesChannelListRes?.status === true) {
      setSalesChannelLoading(false);
      setsalesChannelList(GetSalesChannelListRes?.data?.sales_channel);
      setSelectedSalesChannel("Amazon.com");
      setSelectedSalesChannelId("ATVPDKIKX0DER");
      setSelectedCurrencySign("$");
      const apiObj = {
        page: 1,
        perPage: pageSize,
        filter: filter,
        sales_channel: "Amazon.com",
        search_key: searchTerm,
        isExport: 0,
      };
      setLoading(true);
      GetSkuDataList(apiObj);
      fakeActionSalesAnalytics("GetSalesChannelListResponse");
    } else if (GetSalesChannelListRes?.status === false) {
      fakeActionSalesAnalytics("GetSalesChannelListResponse");
    }
  }, [GetSalesChannelListRes]);

  const exportHeader = [
    { value: "Product", type: String },
    { value: "SKU", type: String },
    { value: "ASIN", type: String },
    { value: "Brand", type: String },
    { value: "COGS", type: String },
    { value: "Sale Price", type: String },
    { value: "Total Fees", type: String },
    { value: "Product Weight", type: String },
    { value: "Status", type: String },
  ];
  const downloadXl = async (exportData) => {
    let dataRow = [];
    await exportData.map((d) => {
      const newList = [
        d?.product_title,
        d?.sku,
        d?.asin,
        d?.brand,
        d?.cogs,
        d?.item_price,
        d?.total_fees,
        d?.product_weight,
        d?.status === 1 ? "Active" : "In Active",
      ];
      dataRow = [
        ...dataRow,
        newList.map((d) => {
          return { value: d };
        }),
      ];
    });
    const data = [exportHeader, ...dataRow];
    await writeXlsxFile(data, {
      fileName: "Amazon Product Details.xlsx",
    });
    message.destroy();
  };
  useEffect(() => {
    if (GetSkuDataListRes.status === true) {
      if (isExportFlag) {
        downloadXl(GetSkuDataListRes?.data?.records);
        setIsExportFlag(false);
      } else {
        setList(GetSkuDataListRes?.data?.records);
        console.log(GetSkuDataListRes, "GetSkuDataListRes");
        setLoading(false);
        setTotalPage(GetSkuDataListRes?.data?.pagination?.totalCount);
      }
      fakeActionSkuData("GetSkuDataListResponse");
    } else if (GetSkuDataListRes.status === false) {
      setLoading(false);
      fakeActionSkuData("GetSkuDataListResponse");
    }
  }, [GetSkuDataListRes]);
  const defaultColumns = [
    {
      title: "#",
      ellipsis: true,
      align: "left",
      render: (text) => {
        return <span>{(page - 1) * pageSize + 1 + text.key}</span>;
      },
    },
    {
      title: "Product",
      width: 400,
      render: (text) => {
        return (
          <div className="d-flex align-items-center border-start border-2 border-info p-3 rounded">
            <div className="symbol symbol-50px  me-3">
              <img src={`${text?.image_url}`} alt="" />
            </div>
            {/*end::Avatar*/}
            {/*begin::User details*/}
            <div className="d-flex flex-column">
              <Popover
                content={
                  <div
                    style={{
                      height: "max-content",
                      width: "300px",
                    }}
                  >
                    {text?.product_title}
                  </div>
                }
                placement="bottom"
              >
                <div
                  className="text-primary mb-1 fw-bolder text-one fs-5 popoverActionIconSingleLine"
                  style={{ minWidth: "100px" }}
                >
                  {text?.product_title}
                </div>
              </Popover>

              <div>
                <span className="fs-6">
                  <Tag color="orange">
                    <b>SKU:</b> {text?.sku}
                  </Tag>
                </span>
                <br />
                <span className="fs-6 ">
                  <Tag color="purple" className="mt-1">
                    <b>ASIN:</b> {text?.asin}
                  </Tag>
                </span>
              </div>
            </div>
          </div>
          // <Popover
          //   content={
          //     <div
          //       style={{
          //         height: "50px",
          //         width: "400px",
          //         overflowY: "scroll",
          //       }}
          //     >
          //       {text}
          //     </div>
          //   }
          //   placement="bottom"
          // >
          //   <div className="popoverActionIcon" style={{ width: "200px" }}>
          //     {text}
          //   </div>
          // </Popover>
        );
      },
    },
    {
      title: "Brand",
      dataIndex: "brand",
      key: "brand",
      render: (text) => <span>{text || "-"}</span>,
    },
  ];

  const columns = [
    // {
    //   title: "Image",
    //   render: (text, record) => (
    //     <img
    //       src={record.image_url}
    //       width={75}
    //       height={75}
    //       style={{ borderRadius: "5px" }}
    //       alt="Product Image"
    //     />
    //   ),
    // },
    // {
    //   title: "SKU",
    //   dataIndex: "sku",
    //   key: "sku",
    //   render: (text) => <span>{text || "-"}</span>,
    // },
    // {
    //   title: "ASIN",
    //   dataIndex: "asin",
    //   key: "asin",
    //   render: (text) => <span>{text || "-"}</span>,
    // },

    // {
    //   title: "Category Name",
    //   dataIndex: "category_name",
    //   key: "category_name",
    //   render: (text) => <span>{text || "-"}</span>,
    // },
    {
      title: "COGS",
      dataIndex: "cogs",
      key: "cogs",
      sorter: (a, b) => a?.cogs || 0 - b?.cogs || 0,
      width: 150,
      render: (text) => (
        <div className="d-flex align-items-center ">
          {/* <Input defaultValue={text || 0} /> */}
          <label className="fw-bolder">{text}</label>
          <button
            className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm ms-2"
            onClick={() => {
              setIsOpenCogsModal(true);
            }}
          >
            <i
              className="ki-outline ki-pencil fs-2"
              style={{ color: "#389e0d" }}
            />
          </button>
        </div>
        // <span>
        //   {selectedCurrencySign}&nbsp;{text || 0}
        // </span>
      ),
    },
    {
      title: "Sale Price",
      dataIndex: "item_price",
      key: "item_price",
      sorter: (a, b) => a?.item_price - b?.item_price,
      render: (text) => (
        <span>
          {" "}
          {selectedCurrencySign}&nbsp;{text || 0}
        </span>
      ),
    },
    {
      title: "Total Fees",
      dataIndex: "total_fees",
      key: "total_fees",
      sorter: (a, b) => a?.total_fees - b?.total_fees,
      render: (text, row) => (
        <span>
          {" "}
          {selectedCurrencySign}&nbsp;{text || 0}
          <Popover
            placement="bottom"
            content={
              <>
                <div className="d-flex justify-content-between">
                  <label>Referral Fees :&nbsp;</label>
                  <label className="fw-bold">
                    {selectedCurrencySign}
                    {NumberWithCommas(row?.referral_fee || 0)}
                  </label>
                </div>
                <div className="d-flex justify-content-between">
                  <label>FBA Fees :&nbsp;</label>
                  <label className="fw-bold">
                    {selectedCurrencySign}
                    {NumberWithCommas(row?.fba_fees || 0)}
                  </label>
                </div>
                {/* <div className="d-flex justify-content-between">
                  <label>Refunds Fees :&nbsp;</label>
                  <label className="fw-bold">
                    {selectedCurrencySign}&nbsp;
                    {NumberWithCommas(text?.refund_fees || 0)}
                  </label>
                </div> */}
              </>
            }
            trigger="hover"
            className="me-4"
          >
            <i
              style={{ fontSize: "17px", color: "#827dcb" }}
              className="bi bi-info-square mx-2 cursor-pointer"
            ></i>
          </Popover>
        </span>
      ),
    },
    // {
    //   title: "Total VAT",
    //   dataIndex: "total_vat",
    //   key: "total_vat",
    //   render: (text) => (
    //     <span>
    //       {selectedCurrencySign}&nbsp;{text || 0}
    //     </span>
    //   ),
    // },
    {
      title: "Product Weight",
      dataIndex: "product_weight",
      key: "product_weight",
      sorter: (a, b) => a?.product_weight - b?.product_weight,
      render: (text) => <span>{text || "-"}</span>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => {
        return (
          <span>
            <Tag
              style={{ borderRadius: "6px" }}
              color={text === 1 ? "green" : "red"}
              icon={
                text === 1 ? <CheckCircleOutlined /> : <CloseCircleOutlined />
              }
            >
              {text === 1 ? "Active" : "In Active"}
            </Tag>
          </span>
        );
      },
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      render: (item) => {
        return (
          <div>
            <span>
              {moment(new Date(item * 1000)).format("MMM DD, YYYY hh:mm A")}
              <br />({timeSince(item)})
            </span>
          </div>
        );
      },
    },
    {
      title: "Updated At",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (item) => {
        return (
          <div>
            <span>
              {moment(new Date(item * 1000)).format("MMM DD, YYYY hh:mm A")}
              <br />({timeSince(item)})
            </span>
          </div>
        );
      },
    },
  ];

  const onPageNo = (e) => {
    setLoading(true);
    const apiObj = {
      page: e,
      perPage: pageSize,
      filter: filter,
      sales_channel: selectedSalesChannel,
      search_key: searchTerm,
      isExport: 0,
    };
    GetSkuDataList(apiObj);
    setPage(e);
  };

  const onPerPage = (e) => {
    setPage(1);
    setPageSize(e);
    setLoading(true);
    const apiObj = {
      page: 1,
      perPage: e,
      filter: filter,
      sales_channel: selectedSalesChannel,
      search_key: searchTerm,
      isExport: 0,
    };
    GetSkuDataList(apiObj);
  };

  const [selectedFilterCheckbox, setSelectedFilterCheckbox] = useState([
    "cogs",
    "item_price",
    "total_fees",
    "product_weight",
    "status",
    "created_at",
    "updated_at",
  ]);
  console.log(selectedFilterCheckbox, "selectedFilterCheckbox");
  const checkoxOptions = columns?.map((d) => ({
    label: d?.title,
    value: d?.key,
  }));

  return (
    <div className="fadeInRight">
      <>
        <div className="d-flex flex-column flex-column-fluid">
          <div id="kt_app_content" className="app-content flex-column-fluid">
            <div
              id="kt_app_content_container"
              className="app-container container-fluid"
            >
              {/*begin::Row*/}

              <div className="row gy-5 g-xl-5">
                {/*begin::Col*/}
                <div className="col-xxl-12">
                  {/*begin::Tables Widget 9*/}
                  <div className="card card-xxl-stretch mb-5 mb-xl-8">
                    {/*begin::Header*/}
                    <div className="card-header border-bottom-dashed">
                      {/*end::Title*/}
                      {/*begin::Title*/}
                      <h3 className="card-title ">
                        <div className="position-relative">
                          <Input
                            type="text"
                            className="text-dark w-250px fs-7 fw-bold ant_common_input"
                            name="all_search"
                            placeholder="Search by ASIN or SKU..."
                            value={searchTerm}
                            onChange={(e) => {
                              setSearchTerm(e.target.value);
                            }}
                            onPressEnter={(e) => {
                              setLoading(true);
                              const apiObj = {
                                page: 1,
                                perPage: pageSize,
                                filter: filter,
                                sales_channel: selectedSalesChannel,
                                search_key: e.target.value,
                                isExport: 0,
                              };
                              GetSkuDataList(apiObj);
                            }}
                            prefix={
                              <SearchOutlined
                                style={{ fontSize: "19px", color: "grey" }}
                              />
                            }
                            size="large"
                          />
                        </div>
                      </h3>
                      {/*end::Title*/}
                      <div className="card-toolbar gap-2">
                        <div className="position-relative">
                          <div>
                            <Dropdown
                              disabled={list === 0}
                              trigger={["click"]}
                              dropdownRender={() => (
                                <div style={contentStyle}>
                                  <div>
                                    <div className="separator border-gray-200" />
                                    <div
                                      style={{
                                        minHeight: "207px",
                                        minWidth: "150px",
                                        border: "1px solid #4419ff8a",
                                        borderRadius: "7px",
                                        overflow: "auto",
                                      }}
                                    >
                                      <CheckboxWrapper>
                                        <Checkbox.Group
                                          value={selectedFilterCheckbox}
                                          options={checkoxOptions}
                                          onChange={(value) => {
                                            setSelectedFilterCheckbox(value);
                                          }}
                                          style={{
                                            display: "grid",
                                            justifyContent: "center",
                                          }}
                                        />
                                      </CheckboxWrapper>
                                    </div>
                                  </div>
                                </div>
                              )}
                            >
                              <button className="btn btn-primary">
                                Select Filter
                              </button>
                            </Dropdown>
                          </div>
                        </div>
                        <div className="position-relative">
                          <Select
                            placeholder="Select options"
                            size="large"
                            style={{ width: "200px" }}
                            getPopupContainer={(triggerNode) =>
                              triggerNode.parentNode
                            }
                            onChange={(e) => {
                              setFilter(e);
                              setPage(1);
                              setPageSize(DefaultPerPage);
                              setLoading(true);
                              const apiObj = {
                                page: 1,
                                perPage: pageSize,
                                filter: e,
                                sales_channel: selectedSalesChannel,
                                search_key: searchTerm,
                                isExport: 0,
                              };
                              GetSkuDataList(apiObj);
                            }}
                            value={filter || null}
                            // className="ms-3"
                            allowClear
                            options={skuDataSelectOption.map((d) => {
                              return { label: d?.label, value: d?.value };
                            })}
                          />
                        </div>
                        <div className="position-relative">
                          <Select
                            className="w-250px"
                            size="large"
                            placeholder="Select Sales Channel"
                            loading={salesChannelLoading}
                            value={selectedSalesChannelId || null}
                            onChange={(e, _) => {
                              setSelectedSalesChannelId(e);
                              setSelectedSalesChannel(_?.sales_channel);
                              setPage(1);
                              setPageSize(DefaultPerPage);
                              setLoading(true);
                              const apiObj = {
                                page: 1,
                                perPage: pageSize,
                                filter: filter,
                                sales_channel: _?.sales_channel,
                                search_key: searchTerm,
                                isExport: 0,
                              };
                              GetSkuDataList(apiObj);
                            }}
                            options={SalesChannelFlag(salesChannelList)}
                          />
                        </div>
                        <div className="positon-relative">
                          <button
                            className="btn btn-light-info fw-bolder fs-7"
                            onClick={() => {
                              setIsExportFlag(true);
                              message.destroy();
                              message.loading("Loading...", 0);
                              const apiObj = {
                                page: 1,
                                perPage: pageSize,
                                filter: filter,
                                sales_channel: selectedSalesChannel,
                                search_key: searchTerm,
                                isExport: 1,
                              };
                              GetSkuDataList(apiObj);
                            }}
                          >
                            Export
                          </button>
                        </div>
                      </div>
                    </div>
                    {/*end::Header*/}
                    {/*begin::Body*/}
                    <div className="card-body pb-5 pt-0">
                      {/*begin::Table container*/}
                      <div className="table-responsive mt-5">
                        {loading ? (
                          <Loading />
                        ) : list?.length !== 0 ? (
                          <>
                            <IvcsrTable
                              // columns={columns}
                              columns={[
                                ...defaultColumns,
                                ...columns?.filter((d) =>
                                  selectedFilterCheckbox?.includes(d?.key)
                                ),
                              ]}
                              fixed="top"
                              dataSource={list}
                              rowKey="key"
                              loading={loading}
                              pagination={false}
                              scroll={{ x: "max-content" }}
                            />
                          </>
                        ) : (
                          <NoData type="new" />
                        )}
                      </div>
                      <Pagination
                        loading={loading || list?.length === 0}
                        pageSize={pageSize}
                        page={page}
                        totalPage={totalPage}
                        onPerPage={onPerPage}
                        onPageNo={onPageNo}
                      />
                    </div>
                    {/*begin::Body*/}
                  </div>
                  {/*end::Tables Widget 9*/}
                </div>
                {/*end::Col*/}
              </div>

              {/*end::Row*/}
            </div>
          </div>
        </div>
      </>
      {isOpenCogsModal && (
        <AmazonCogsModal
          show={isOpenCogsModal}
          onHide={() => {
            setIsOpenCogsModal(false);
          }}
        />
      )}
    </div>
  );
}
