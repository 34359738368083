import { Alert, Input, Select, Tag, message } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import IvcsrTable from "../../../../component/ivcsr-table";
import { Link } from "react-router-dom";
export default function ({ show, onHide, data }) {
  console.log(data, "-data");
  const columnsSub = [
    {
      title: "Status",
      render: (text) => (
        <>
          <Tag
            color={
              text?.status === "Cancelled"
                ? "error"
                : text?.status === "Shipped"
                ? "geekblue"
                : text?.status === "Delivered"
                ? "green"
                : text?.status === "Acknowledged"
                ? "orange"
                : "purple"
            }
            className="fw-bold my-1"
          >
            {text?.status}
          </Tag>
          <label className="fw-bold">
            {text?.status === "Shipped"
              ? `- ${moment(text?.trackingInfo?.shipDateTime).format(
                  "MMM DD, YYYY"
                )}`
              : ""}
          </label>
        </>
      ),
    },
    {
      title: "Quantity",
      render: (text) => (
        <span className=" fw-bolder text-dark">
          {text?.statusQuantity?.amount || "-"}
        </span>
      ),
    },
    {
      title: "Action",
      render: (text) => (
        <>
          {text?.status === "Shipped" ? (
            text?.trackingInfo?.trackingURL ? (
              <Link
                to={{
                  pathname: text?.trackingInfo?.trackingURL || "",
                }}
                target="_blank"
              >
                Track Order from here
              </Link>
            ) : (
              <label
                onClick={() => {
                  message.destroy();
                  message.info("Tracking url not found");
                }}
              >
                Track Order from here
              </label>
            )
          ) : (
            ""
          )}
        </>
      ),
    },
  ];
  return (
    <>
      <Modal show={show} onHide={onHide} size="lg" centered backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Status Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <IvcsrTable
              columns={columnsSub?.map((d) => ({
                ...d,
                width: d?.title?.length * 13,
              }))}
              fixed={true}
              sticky={{
                offsetHeader: "10px",
              }}
              dataSource={data}
              rowKey="key"
              loading={false}
              pagination={false}
              scroll={{ x: "max-content", y: 300 }}
            />
            {/* {data?.map((d) => {
              return d?.status;
            })} */}
          </>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-light-danger"
            onClick={onHide}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
