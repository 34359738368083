import { getUserId } from "../../../../config";
import axiosCall from "../../../configurations/network-services/axiosCall";
import walmartAxiosCall from "../../../configurations/network-services/walmartAxoisCall";

export const GetReturnsAndRefundsOrder = (data) => {
  const path = `wm/refunds?page=${data?.page || 1}&per-page=${
    data?.perPage || 20
  }&search_key=${data?.search || ""}&return_status=${
    data?.returnStatus || ""
  }&current_refund_status=${data?.refundStatus || ""}`;
  const responseType = "GET_RETURNS_AND_REFUNDS_ORDERS";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetReturnsAndRefundsOrderDetails = (
  returnOrderId,
  purchase_order_line_number
) => {
  const path = `wm/refund-details?return_order_id=${returnOrderId}&purchase_order_line_number=${purchase_order_line_number}`;
  const responseType = "GET_RETURNS_AND_REFUNDS_ORDERS_DETAILS";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetDirectWalmartRefundAction = (data) => {
  // for direct walmart api
  const path = `all/refund`;
  const responseType = "GET_DIRECT_WALMART_REFUND";
  return walmartAxiosCall("get", path, responseType, data, {
    AUTHORIZATION: `Bearer ${localStorage.getItem("token")}`,
    "MARKETPLACE-ID": "ATVPDKIKX0DER",
    "USER-ID": getUserId(),
    "EVENT-TYPE": "Walmart Report",
  });
};

export const WalmartReturnRefundOrder = (return_order_id, data) => {
  const path = `returns?return_order_id=${return_order_id || ""}`;
  const responseType = "WALMART_RETURNS_AND_REFUNDS_ORDERS";
  return walmartAxiosCall("post", path, responseType, data, {
    AUTHORIZATION: `Bearer ${localStorage.getItem("token")}`,
    // user_id: 8,
    // user_id: JSON.parse(localStorage.getItem("user"))?.id,
    "USER-ID": getUserId(),
    "MARKETPLACE-ID": "ATVPDKIKX0DER",
    "EVENT-TYPE": "Walmart Report",
  });
};

export const fakeActionReturnsRefunds = (data) => async (dispatch) => {
  dispatch({ type: "FAKE_ACTION_RETURNS_REFUNDS", state: data });
};
