export default (state, action) => {
  if (typeof state === "undefined") {
    return [];
  }
  switch (action.type) {
    case "GET_BID_MANAGEMENT_LIST_SUCCESS":
    case "GET_BID_MANAGEMENT_LIST_ERROR":
      return {
        ...state,
        GetBidManagementListResponse: action.updatePayload,
      };

    case "GET_APPLIED_RULE_LOG_LIST_SUCCESS":
    case "GET_APPLIED_RULE_LOG_LIST_ERROR":
      return {
        ...state,
        GetAppliedRuleLogListResponse: action.updatePayload,
      };

    case "CREATE_NEW_BID_MANAGEMENT_SUCCESS":
    case "CREATE_NEW_BID_MANAGEMENT_ERROR":
      return {
        ...state,
        CreateNewBidManagementResponse: action.updatePayload,
      };

    case "GET_RULE_MANAGEMENT_DROPDOWN_SUCCESS":
    case "GET_RULE_MANAGEMENT_DROPDOWN_ERROR":
      return {
        ...state,
        GetRuleManagementDropdownResponse: action.updatePayload,
      };

    case "ALL_CAMPAIGNS_DATA_SUCCESS":
    case "ALL_CAMPAIGNS_DATA_ERROR":
      return {
        ...state,
        AllCompaignsDataResponse: action.updatePayload,
      };

    case "DELETE_BID_MANAGEMENT_RULE_SUCCESS":
    case "DELETE_BID_MANAGEMENT_RULE_ERROR":
      return {
        ...state,
        DeleteBidRuleResponse: action.updatePayload,
      };

    case "FAKE_ACTION_BID_MANAGEMENT":
      return { ...state, [action?.state]: {} };
    default:
      return state;
  }
};
