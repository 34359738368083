import { Popover, Select, Table, Tag, TreeSelect, message } from "antd";
import React, { useEffect, useState, useContext } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Pagination from "../../../../../component/pagination";
import IvcsrTable from "../../../../../component/ivcsr-table";
import Loading from "../../../../../component/loading";
import NoData from "../../../../../component/no-data";
import {
  DefaultPerPage,
  NumberWithCommas,
  SalesChannelFlag,
} from "../../../../../config";
import Wrapper from "../style";
import { GlobalContext } from "../../../../../commonContext";
import writeXlsxFile from "write-excel-file";
const { SHOW_PARENT } = TreeSelect;
export default function (props) {
  const {
    GetInventoryDetailsList,
    fakeActionInventoryDetails,
    GetSalesChannelList,
    fakeActionSalesAnalytics,
  } = props;

  const contextVar = useContext(GlobalContext);

  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageSize, setPageSize] = useState(DefaultPerPage);
  const [typeList, setTypeList] = useState([]);
  const [type, setType] = useState(["all"]);

  const [salesChannelList, setsalesChannelList] = useState([]);
  const [selectedSalesChannel, setSelectedSalesChannel] = useState([]);
  const [salesChannelLoading, setSalesChannelLoading] = useState(true);
  const [selectedCurrencySign, setSelectedCurrencySign] = useState("");
  const [isExportFalg, setIsExportFlag] = useState(false);

  const GetInventoryDetailsListResponse = useSelector(
    (state) => state.InventoryDetails.GetInventoryDetailsListResponse || {}
  );

  const GetSalesChannelListRes = useSelector(
    (state) => state.SalesAnalytics.GetSalesChannelListResponse || {}
  );

  useEffect(() => {
    GetSalesChannelList();
  }, []);

  useEffect(() => {
    if (GetSalesChannelListRes?.status === true) {
      setSalesChannelLoading(false);
      setsalesChannelList(GetSalesChannelListRes?.data?.sales_channel);
      setSelectedSalesChannel("ATVPDKIKX0DER");
      setSelectedCurrencySign("$");
      const Obj = {
        page: 1,
        perPage: pageSize,
        marketplaceId: GetSalesChannelListRes?.data?.sales_channel,
        type: "all",
        isExport: 0,
      };
      GetInventoryDetailsList(Obj);
      fakeActionSalesAnalytics("GetSalesChannelListResponse");
    } else if (GetSalesChannelListRes?.status === false) {
      fakeActionSalesAnalytics("GetSalesChannelListResponse");
    }
  }, [GetSalesChannelListRes]);

  useEffect(() => {
    if (GetInventoryDetailsListResponse?.status === true) {
      if (isExportFalg) {
        downloadXl(GetInventoryDetailsListResponse?.data?.records);
        setIsExportFlag(false);
      } else {
        setTotalPage(
          GetInventoryDetailsListResponse?.data?.pagination?.totalCount
        );
        setTypeList(GetInventoryDetailsListResponse?.data?.type_data);
        setList(GetInventoryDetailsListResponse?.data?.records);
        setLoading(false);
      }
      fakeActionInventoryDetails("GetInventoryDetailsListResponseponse");
    } else if (GetInventoryDetailsListResponse?.status === false) {
      fakeActionInventoryDetails("GetInventoryDetailsListResponseponse");
    }
  }, [GetInventoryDetailsListResponse]);

  const exportHeader = [
    { value: "Product", type: String },
    { value: "ASIN", type: String },
    { value: "SKU", type: String },
    { value: "In Stock", type: String },
    { value: "Out Of Stock", type: String },
    { value: "Unfulfillable", type: String },
    { value: "Stranded", type: String },
    { value: "Ageing 91-180", type: String },
    { value: "Inbound Working", type: String },
    { value: "Inbound Received", type: String },
    { value: "Inbound Shipped", type: String },
    { value: "FC Transfer", type: String },
    { value: "FC Processing", type: String },
    { value: "FC Customer Order", type: String },
    { value: "Researching", type: String },
    { value: "All", type: String },
    { value: "Your Price", type: String },
    { value: "COGS", type: String },
    { value: "Fees", type: String },
    // { value: "VAT", type: String },
    { value: "Profit", type: String },
    { value: "Margin", type: String },
    { value: "ROI", type: String },
  ];

  const downloadXl = async (productData) => {
    let dataRow = [];
    await productData.map((d) => {
      const newList = [
        d?.title,
        d?.asin,
        d?.sku,
        d?.in_stock,
        d?.out_of_stock,
        d?.unsellable_quantity,
        d?.stranded,
        d?.ageing_91_180,
        d?.inbound_working,
        d?.inbound_receiving,
        d?.inbound_shipped,
        d?.reserved_fc_transfers,
        d?.reserved_fc_processing,
        d?.reserved_customer_orders,
        d?.researching_quantity,
        d?.all,
        d?.your_price,
        d?.cogs,
        d?.vat,
        d?.net_profit,
        d?.net_profit_margin,
        d?.roi,
      ];
      dataRow = [
        ...dataRow,
        newList.map((d) => {
          return { value: d };
        }),
      ];
    });
    const data = [exportHeader, ...dataRow];
    await writeXlsxFile(data, {
      fileName: "Amazon Products.xlsx",
    });
    message.destroy();
  };

  const columns = [
    {
      title: "Sr. No.",
      fixed: "left",
      default: true,
      render: (text) => {
        return <span>{1 + text.key}</span>;
      },
    },
    {
      title: "Product",
      fixed: "left",
      default: true,
      width: 300,
      render: (text, row) => {
        return (
          <div class="d-flex align-items-center bg-light border-start border-2 border-info p-3 rounded">
            <div class="symbol symbol-50px overflow-hidden me-3">
              <img src={row?.image} alt="" />
            </div>

            <div class="d-flex flex-column">
              <span class="text-primary mb-1 fw-bolder text-one min-w-250px fs-6">
                <Popover
                  content={
                    <div
                      style={{
                        height: "max-content",
                        maxWidth: "400px",
                      }}
                    >
                      {text?.title}
                    </div>
                  }
                  placement="bottom"
                >
                  <div
                    className="text-primary mb-1 fw-bolder text-one fs-5 popoverActionIconSingleLine"
                    style={{ minWidth: "100px" }}
                  >
                    {text?.title}
                  </div>
                </Popover>
              </span>
              <div>
                <span className="fs-6">
                  <Tag color="orange">
                    <b>SKU:</b> {text?.sku}
                  </Tag>
                </span>
                <br />
                <span className="fs-6 ">
                  <Tag color="purple" className="mt-1">
                    <b>ASIN:</b> {text?.asin}
                  </Tag>
                </span>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "In Stock",
      dataIndex: "in_stock",
      key: "in_stock",
      removeItem: "in_stock",
      isFilterKey: "in_stock",
      render: (text) => {
        return <span>{text}</span>;
      },
    },
    {
      title: "Out Of Stock",
      dataIndex: "out_of_stock",
      key: "out_of_stock",
      removeItem: "out_of_stock",
      isFilterKey: "out_of_stock",
      render: (text) => {
        return <span>{text}</span>;
      },
    },
    {
      title: "Unfulfillable",
      dataIndex: "unsellable_quantity",
      key: "unsellable_quantity",
      removeItem: "unfulfillable",
      isFilterKey: "unsellable_quantity",
      render: (text) => {
        return <span>{text}</span>;
      },
    },
    {
      title: "Stranded",
      dataIndex: "stranded",
      key: "stranded",
      removeItem: "stranded",
      isFilterKey: "stranded",
      render: (text) => {
        return <span>{text}</span>;
      },
    },
    {
      title: "Ageing 91-180",
      dataIndex: "ageing_91_180",
      key: "ageing_91_180",
      removeItem: "ageing_91_180",
      isFilterKey: "ageing_91_180",
      render: (text) => {
        return <span>{text}</span>;
      },
    },
    {
      title: "Inbound Working",
      dataIndex: "inbound_working",
      key: "inbound_working",
      removeItem: "Inbound_Working",
      isFilterKey: "inbound_working",
      render: (text) => {
        return <span>{text}</span>;
      },
    },
    {
      title: "Inbound Received",
      dataIndex: "inbound_receiving",
      key: "inbound_receiving",
      removeItem: "Inbound_Received",
      isFilterKey: "inbound_receiving",
      render: (text) => {
        return <span>{text}</span>;
      },
    },
    {
      title: "Inbound Shipped",
      dataIndex: "inbound_shipped",
      key: "inbound_shipped",
      removeItem: "Inbound_Shipped",
      isFilterKey: "inbound_shipped",
      render: (text) => {
        return <span>{text}</span>;
      },
    },
    {
      title: "FC Transfer",
      dataIndex: "reserved_fc_transfers",
      key: "reserved_fc_transfers",
      removeItem: "Reserved_Transfer",
      isFilterKey: "reserved_fc_transfers",
      render: (text) => {
        return <span>{text}</span>;
      },
    },
    {
      title: "FC Processing",
      dataIndex: "reserved_fc_processing",
      key: "reserved_fc_processing",
      removeItem: "Reserved_Processing",
      isFilterKey: "reserved_fc_processing",
      render: (text) => {
        return <span>{text}</span>;
      },
    },
    {
      title: "FC Customer Order",
      dataIndex: "reserved_customer_orders",
      key: "reserved_customer_orders",
      removeItem: "Reserved_Customer",
      isFilterKey: "reserved_customer_orders",
      render: (text) => {
        return <span>{text}</span>;
      },
    },
    {
      title: "Researching",
      dataIndex: "researching_quantity",
      key: "researching_quantity",
      removeItem: "researching",
      isFilterKey: "researching_quantity",
      render: (text) => {
        return <span>{text}</span>;
      },
    },
    {
      title: "All",
      dataIndex: "All",
      key: "all",
      removeItem: "all",
      render: (text) => {
        return <span>{text}</span>;
      },
    },
    {
      title: "Your Price",
      dataIndex: "your_price",
      key: "your_price",
      isFilterKey: "your_price",
      default: true,
      render: (text) => {
        return (
          <span>
            {selectedCurrencySign}
            {text}
          </span>
        );
      },
    },
    {
      title: "COGS",
      dataIndex: "cogs",
      key: "cogs",
      isFilterKey: "cogs",
      default: true,
      render: (text) => {
        return (
          <span>
            {selectedCurrencySign}
            {text}
          </span>
        );
      },
    },
    {
      title: "Fees",
      default: true,
      isFilterKey: "fees",
      render: (text) => {
        return (
          <div className="d-flex">
            <span>
              {selectedCurrencySign}
              {text?.fees}
            </span>
            <Popover
              placement="bottom"
              content={
                <>
                  <div className="d-flex justify-content-between">
                    <label>Referral Fees :&nbsp;</label>
                    <label className="fw-bold">
                      {selectedCurrencySign}&nbsp;
                      {NumberWithCommas(text?.referral_fee || 0)}
                    </label>
                  </div>
                  <div className="d-flex justify-content-between">
                    <label>FBA Fees :&nbsp;</label>
                    <label className="fw-bold">
                      {selectedCurrencySign}&nbsp;
                      {NumberWithCommas(text?.fba_fees || 0)}
                    </label>
                  </div>
                  {/* <div className="d-flex justify-content-between">
                    <label>Refunds Fees :&nbsp;</label>
                    <label className="fw-bold">
                      {selectedCurrencySign}&nbsp;
                      {NumberWithCommas(text?.refunds?.refunds_fees || 0)}
                    </label>
                  </div> */}
                </>
              }
              trigger="hover"
              className="me-4"
            >
              <i
                style={{ fontSize: "17px", color: "#827dcb" }}
                className="bi bi-info-square mx-2 cursor-pointer"
              ></i>
            </Popover>
          </div>
        );
      },
    },
    // {
    //   title: "VAT",
    //   dataIndex: "vat",
    //   key: "vat",
    //     isFilterKey: "inbound_shipped",
    //   default: true,
    //   render: (text) => {
    //     return (
    //       <span>
    //         {selectedCurrencySign}
    //         {text}
    //       </span>
    //     );
    //   },
    // },
    {
      title: "Profit",
      dataIndex: "net_profit",
      default: true,
      key: "net_profit",
      isFilterKey: "net_profit",
      render: (text) => {
        return (
          <span>
            {selectedCurrencySign}
            {text}
          </span>
        );
      },
    },
    {
      title: "Margin",
      dataIndex: "net_profit_margin",
      key: "net_profit_margin",
      isFilterKey: "net_profit_margin",
      default: true,
      render: (text, record) => `${text}%`,
    },
    {
      title: "ROI",
      dataIndex: "roi",
      key: "roi",
      isFilterKey: "roi",
      default: true,
      render: (text, record) => `${text}%`,
    },
  ];

  const onPageNo = (e) => {
    setLoading(true);
    const apiObj = {
      page: e,
      perPage: pageSize,
    };
    // GetDataSchedulerList(apiObj);
    setPage(e);
  };

  const onPerPage = (e) => {
    setPage(1);
    setPageSize(e);
    setLoading(true);
    const apiObj = {
      page: 1,
      perPage: e,
    };
    // GetDataSchedulerList(apiObj);
  };

  const [value, setValue] = useState(["all"]);

  const treeData = [
    {
      title: `In Stock (${typeList?.in_stock || 0})`,
      value: "in_stock",
      key: "In Stock",
    },
    {
      title: `Out Of Stock (${typeList?.out_of_stock || 0})`,
      value: "out_of_stock",
      key: "Out Of Stock",
    },
    {
      title: `Unfulfillable (${typeList?.unsellable || 0})`,
      value: "unfulfillable",
      key: "Unfulfillable",
    },
    {
      title: `Stranded (${typeList?.stranded || 0})`,
      value: "stranded",
      key: "Stranded",
    },
    {
      title: `Ageing (${typeList?.ageing_91_180 || 0})`,
      value: "ageing",
      key: "Ageing",
      children: [
        {
          title: `91-180 (${typeList?.ageing_91_180 || 0})`,
          value: "ageing_91_180",
          key: "91-180",
        },
      ],
    },
    {
      title: `Inbound (${typeList?.inbound || 0})`,
      value: "Inbound",
      key: "Inbound",
      children: [
        {
          title: `Working (${typeList?.inbound_working || 0})`,
          value: "Inbound_Working",
          key: "Inbound_Working",
        },
        {
          title: `Shipped (${typeList?.inbound_shipped || 0})`,
          value: "Inbound_Shipped",
          key: "Inbound_Shipped",
        },
        {
          title: `Received (${typeList?.inbound_receiving || 0})`,
          value: "Inbound_Received",
          key: "Inbound_Received",
        },
      ],
    },
    {
      title: `Reserved (${typeList?.reserved || 0})`,
      value: "Reserved",
      key: "Reserved",
      children: [
        {
          title: `Transfer (${typeList?.fc_transfer || 0})`,
          value: "Reserved_Transfer",
          key: "Reserved_Transfer",
        },
        {
          title: `Processing (${typeList?.fc_processing || 0})`,
          value: "Reserved_Processing",
          key: "Reserved_Processing",
        },
        {
          title: `Customer Order (${typeList?.customer_orders || 0})`,
          value: "Reserved_Customer",
          key: "Reserved_Customer",
        },
      ],
    },
    {
      title: `ReSearching  (${typeList?.researching || 0})`,
      value: "researching",
      key: "Researching",
    },
    {
      title: `All  (${typeList?.all || 0})`,
      value: "all",
      key: "All",
    },
  ];

  const inbound_ = ["Inbound_Working", "Inbound_Shipped", "Inbound_Received"];
  const ageing_ = ["ageing_91_180"];
  const reserved_ = [
    "Reserved_Processing",
    "Reserved_Customer",
    "Reserved_Transfer",
  ];

  const getGroupIndex = (newValue, type) =>
    newValue?.findIndex((r) => r === type);

  const getFilterValue = (newValue, __) => {
    const RIndex = reserved_?.findIndex((d) => d.includes(__?.triggerValue));
    const IIndex = inbound_?.findIndex((d) => d.includes(__?.triggerValue));
    const AIndex = ageing_?.findIndex((d) => d.includes(__?.triggerValue));
    const GIIndex = getGroupIndex(newValue, "Inbound");
    const GRIndex = getGroupIndex(newValue, "Reserved");
    const GAIndex = getGroupIndex(newValue, "ageing");
    if (__?.checked === false) {
      return [];
    } else if (RIndex === -1 && IIndex === -1 && AIndex === -1) {
      return [__?.triggerValue];
    } else if ((IIndex === -1 && AIndex === -1) || RIndex >= 0) {
      return GIIndex !== -1 || GAIndex !== -1
        ? __?.triggerValue === "Reserved"
          ? reserved_
          : [__?.triggerValue]
        : GRIndex !== -1
        ? reserved_
        : getGroupIndex(reserved_, newValue?.[0]) === -1
        ? [__?.triggerValue]
        : newValue;
    } else if (IIndex >= 0 || (RIndex === -1 && AIndex === -1)) {
      return GRIndex !== -1 || GAIndex !== -1
        ? __?.triggerValue === "Inbound"
          ? inbound_
          : [__?.triggerValue]
        : GIIndex !== -1
        ? inbound_
        : getGroupIndex(inbound_, newValue?.[0]) === -1
        ? [__?.triggerValue]
        : newValue;
    } else if (AIndex >= 0 || (RIndex === -1 && IIndex === -1)) {
      return GRIndex !== -1 || GIIndex !== -1
        ? __?.triggerValue === "ageing"
          ? ageing_
          : [__?.triggerValue]
        : GAIndex !== -1
        ? ageing_
        : getGroupIndex(ageing_, newValue?.[0]) === -1
        ? [__?.triggerValue]
        : newValue;
    }
  };
  const onChange = (newValue, _, __) => {
    const treeObj = getFilterValue(newValue, __);
    setPage(1);
    setPageSize(DefaultPerPage);
    setLoading(true);
    setValue(treeObj);
    const apiObj = {
      marketplaceId: selectedSalesChannel,
      perPage: DefaultPerPage,
      type: treeObj?.join(","),
      page: 1,
      perPage: DefaultPerPage,
      isExport: 0,
    };
    GetInventoryDetailsList(apiObj);
  };

  const tProps = {
    treeData,
    value,
    onChange,
    treeCheckable: true,
    maxTagCount: "responsive",
    className: "treeSelect",
    size: "large",
    showCheckedStrategy: SHOW_PARENT,
    placeholder: "Inventory Type",
    style: {
      width: "225px",
    },
  };

  const getFilterColumns = () => {
    return value?.length === 0 || value?.[0] === "all"
      ? columns
      : [
          ...columns
            ?.map((i) => {
              const index = value.findIndex((d) => d === i.removeItem);
              return { ...i, matched: i.default ? true : index !== -1 };
            })
            ?.filter((d) => d?.matched),
        ];
  };

  const [sortFilters, setSortFilters] = useState({
    key: "",
    type: "",
  });
  // console.log(sortFilters, "filters");
  const handleOnChangeColumn = (_, __, sorter) => {
    console.log(_, __, sorter, "sorter");
    setSortFilters({
      key: sorter?.order ? sorter?.column?.isFilterKey : "",
      type: sorter?.order || "",
    });
    setPage(1);
    const Obj = {
      page: 1,
      perPage: pageSize,
      marketplaceId: GetSalesChannelListRes?.data?.sales_channel,
      type: "all",
      isExport: 0,
      sort:
        sorter?.order === "ascend"
          ? sorter?.column?.isFilterKey
          : `-${sorter?.column?.isFilterKey}`,
    };
    setLoading(true);
    GetInventoryDetailsList(Obj);
  };
  const extraProps = (type) => {
    console.log(type, "type");
    if (type) {
      return {
        defaultSortOrder: type === sortFilters?.key ? sortFilters.type : [],
        sorter: () => {},
      };
    }
  };
  return (
    <Wrapper>
      <div className="fadeInRight">
        <div className="d-flex flex-column flex-column-fluid">
          <div id="kt_app_content" className="app-content flex-column-fluid">
            <div
              id="kt_app_content_container"
              className="app-container container-fluid"
            >
              <div>
                <div className="row gy-5 g-xl-5">
                  <div className="col-xxl-12">
                    <div className="card card-xxl-stretch mb-5 mb-xl-8">
                      <>
                        <div className="card-header border-bottom-dashed">
                          <h3 className="card-title ">
                            <span className="card-label fw-bold text-gray-900">
                              Inventory Details
                            </span>
                          </h3>

                          <div className="card-toolbar gap-2">
                            {contextVar?.data?.marketplaceType === "amazon" ? (
                              <div className="position-relative">
                                <TreeSelect {...tProps} />
                              </div>
                            ) : (
                              ""
                            )}

                            <div className="position-relative">
                              <Select
                                className="w-250px"
                                size="large"
                                placeholder="Select Sales Channel"
                                loading={salesChannelLoading}
                                value={selectedSalesChannel || null}
                                onChange={(e, _) => {
                                  setSelectedSalesChannel(e);
                                  setPage(1);
                                  setPageSize(DefaultPerPage);
                                }}
                                options={SalesChannelFlag(salesChannelList)}
                              />
                            </div>
                            <div className="position-relative">
                              <button
                                className="btn btn-light-info fw-bolder fs-7"
                                onClick={() => {
                                  setIsExportFlag(true);
                                  const Obj = {
                                    page: 1,
                                    perPage: pageSize,
                                    marketplaceId:
                                      GetSalesChannelListRes?.data
                                        ?.sales_channel,
                                    type: "all",
                                    isExport: 1,
                                  };
                                  GetInventoryDetailsList(Obj);
                                }}
                              >
                                Export
                              </button>
                            </div>
                          </div>
                        </div>

                        <div className="card-body pb-5 pt-0">
                          <div className="table-responsive mt-5">
                            {loading ? (
                              <Loading />
                            ) : list?.length != 0 ? (
                              <>
                                <IvcsrTable
                                  columns={getFilterColumns()
                                    ?.map((d) => ({
                                      ...d,
                                      ...extraProps(d?.isFilterKey),
                                    }))
                                    ?.map((d) => ({
                                      ...d,
                                      width:
                                        d?.title === "Product Title"
                                          ? 300
                                          : d?.title === "All"
                                          ? 50
                                          : d?.title?.length * 13,
                                    }))}
                                  sticky={{
                                    offsetHeader: "10px",
                                  }}
                                  scroll={{
                                    x: "max-content",
                                    y: "calc(100vh - 200px)",
                                  }}
                                  fixed="top"
                                  dataSource={list}
                                  rowKey="key"
                                  loading={loading}
                                  pagination={false}
                                  onChange={handleOnChangeColumn}
                                />
                              </>
                            ) : (
                              <NoData />
                            )}
                          </div>
                          <Pagination
                            loading={loading || list?.length === 0}
                            pageSize={pageSize}
                            page={page}
                            totalPage={totalPage}
                            onPerPage={onPerPage}
                            onPageNo={onPageNo}
                          />
                        </div>
                      </>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}
