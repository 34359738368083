import { Link } from "react-router-dom";

const bullet = (
  <span className="menu-bullet">
    <span className="bullet bullet-dot" />
  </span>
);

export const AdminMenus = [
  {
    title: "Manage User",
    name: "manage-user",
    path: "/manage-user",
    icon: <i className="ki-outline ki-profile-user fs-1 customIcon" />,
  },
  {
    title: "Report Schedule",
    name: "report-schedule",
    path: "/report-schedule",
    icon: <i className="ki-outline  ki-document fs-1 customIcon" />,
  },
  {
    title: "Criteria Analytics",
    name: "criteria-analytics",
    path: "/criteria-analytics",
    icon: <i className="ki-outline  ki-graph-3 fs-1 customIcon" />,
  },
];
export const UserMenus = [
  {
    title: "Dashboard",
    name: "dashboard",
    path: "/dashboard",
    icon: <i className="ki-outline  ki-home-2  fs-1 customIcon" />,
  },
  {
    title: "Sales Analytics",
    name: "sales-analytics",
    path: "/sales-analytics",
    icon: <i className="ki-outline  ki-graph-3 fs-1 customIcon" />,
  },
  {
    title: "Master Table",
    name: "master-table",
    path: "/master-table",
    icon: <i className="ki-outline  ki-element-8 fs-1 customIcon" />,
    children: [
      {
        title: "Product Details",
        name: "product-details",
        path: "/product-details",
        parent: "master-table",
        icon: bullet,
      },
      {
        title: "Supplier",
        name: "supplier",
        path: "/supplier",
        parent: "master-table",
        icon: bullet,
      },
      {
        title: "Item Bulk Operation",
        name: "item-bulk-operation",
        path: "/item-bulk-operation",
        parent: "master-table",
        icon: bullet,
      },
      {
        title: "Item Activity Feed",
        name: "item-activity-feed",
        path: "/item-activity-feed",
        parent: "master-table",
        icon: bullet,
      },
      {
        title: "Feed Items",
        name: "feed-items",
        path: "/feed-items",
        parent: "master-table",
        icon: bullet,
      },
    ],
  },
  // {
  //   title: "Product Operation",
  //   name: "product-operation",
  //   path: "/product-operation",
  //   icon: <i className="ki-outline  ki-graph-3 fs-1 customIcon" />,
  // },

  // {
  //   title: "Listing Optimizer",
  //   name: "listing-optimizer",
  //   path: "/listing-optimizer",
  //   icon: <i className="ki-outline ki-questionnaire-tablet fs-1 customIcon" />,
  // },
  // {
  //   title: "Criteria Analytics",
  //   name: "criteria-analytics",
  //   path: "/criteria-analytics",
  //   icon: <i className="ki-outline ki-chart-pie-4 fs-1 customIcon" />,
  // },
  {
    title: "Advertisement",
    name: "advertisement",
    path: "/advertisement",
    icon: <i className="ki-outline  ki-tablet-text-down  fs-1 customIcon" />,
    children: [
      {
        title: "Dashboard",
        name: "advertisement-dashboard",
        path: "/advertisement-dashboard",
        parent: "advertisement",
        icon: bullet,
      },
      {
        title: "Campaign Management",
        name: "campaign-management",
        path: "/campaign-management",
        parent: "advertisement",
        icon: bullet,
      },
      {
        title: "Rule Management",
        name: "rule-management",
        path: "/rule-management",
        parent: "advertisement",
        icon: bullet,
      },
      {
        title: "Keyword Management",
        name: "keyword-management",
        path: "/keyword-management",
        parent: "advertisement",
        icon: bullet,
      },
      {
        title: "Advance Report",
        name: "advance-report",
        path: "/advance-report",
        parent: "advertisement",
        icon: bullet,
      },

      // {
      //   title: "Walmart Campaign",
      //   name: "walmart-campaign",
      //   path: "/walmart-campaign",
      //   parent: "advertisement",
      //   icon: bullet,
      // },
      // {
      //   title: "Campaigns Analytics",
      //   name: "campaigns-analytics",
      //   path: "/campaigns-analytics",
      //   parent: "advertisement",
      //   icon: bullet,
      // },
    ],
  },
  {
    title: "Inventory",
    name: "inventory",
    path: "/inventory",
    icon: <i className="ki-outline  ki-directbox-default fs-1 customIcon" />,
    children: [
      {
        title: "Inventory Details",
        name: "inventory-details",
        path: "/inventory-details",
        parent: "inventory",
        icon: bullet,
      },
      {
        title: "Inventory Replenishment",
        name: "inventory-replenishment",
        path: "/inventory-replenishment",
        parent: "inventory",
        icon: bullet,
      },
    ],
  },
  {
    title: "Manage Reports",
    name: "manage-reports",
    path: "/manage-reports",
    icon: <i className="ki-outline  ki-document fs-1 customIcon" />,
  },
  {
    title: "Listing Optimizer",
    name: "listing-optimizer",
    path: "/listing-optimizer",
    icon: (
      <i className="ki-outline  ki-questionnaire-tablet   fs-1 customIcon" />
    ),
    // children: [
    //   {
    //     title: "Product Analytics",
    //     name: "product-analytics",
    //     path: "/product-analytics",
    //     parent: "listing-optimizer",
    //     icon: bullet,
    //   },
    // ],
  },
  {
    title: "Order Management",
    name: "order-management",
    path: "/order-management",
    icon: <i className="ki-outline  ki-handcart fs-1 customIcon" />,
    children: [
      {
        title: "Orders",
        name: "orders",
        path: "/orders",
        parent: "order-management",
        icon: bullet,
      },
      {
        title: "Returns & Refunds",
        name: "returns-refunds",
        path: "/returns-refunds",
        parent: "order-management",
        icon: bullet,
      },
    ],
  },
  {
    title: "Repricing",
    name: "repricing",
    path: "/repricing",
    icon: <i className="ki-outline  ki-graph-3 fs-1 customIcon" />,
    children: [
      {
        title: "Product Listing",
        name: "product-listing",
        path: "/product-listing",
        parent: "repricing",
        icon: bullet,
      },
      {
        title: "Repricing Strategies",
        name: "repricing-strategies",
        path: "/repricing-strategies",
        parent: "repricing",
        icon: bullet,
      },
      {
        title: "Buy Box Details",
        name: "buy-box-details",
        path: "/buy-box-details",
        parent: "repricing",
        icon: bullet,
      },
    ],
  },
  {
    title: "Setting",
    name: "setting",
    path: "/setting",
    icon: <i className="ki-outline ki-gear fs-1  customIcon" />,
    children: [
      {
        title: "My Profile",
        name: "my-profile",
        path: "/my-profile",
        parent: "setting",
        icon: bullet,
      },
      {
        title: "Marketplace Credential",
        name: "marketplace-credential",
        path: "/marketplace-credential",
        parent: "setting",
        icon: bullet,
      },
      {
        title: "Configuration",
        name: "configuration",
        path: "/configuration",
        parent: "setting",
        icon: bullet,
      },
    ],
  },
  {
    title: "Application Log",
    name: "application-log",
    path: "/application-log",
    icon: <i className="ki-outline ki-chart-simple-3 fs-1 customIcon" />,
    children: [
      {
        title: "Central Log",
        name: "central-log",
        path: "/central-log",
        parent: "application-log",
        icon: bullet,
      },
      {
        title: "System Event Log",
        name: "system-event-log",
        path: "/system-event-log",
        parent: "application-log",
        icon: bullet,
      },
      {
        title: "Data Scheduler",
        name: "data-scheduler",
        path: "/data-scheduler",
        parent: "application-log",
        icon: bullet,
      },
      {
        title: "API Log",
        name: "api-log",
        path: "/api-log",
        parent: "application-log",
        icon: bullet,
      },
    ],
  },
];

export const nameObject = {
  "/dashboard": {
    name: "Dashboard",
  },
  "/manage-user": {
    name: "Manage User",
  },
  "/criteria-analytics": {
    name: "Criteria Analytics",
  },
  // "/product-operation": {
  //   name: "Product Operation",
  // },
  "/listing-optimizer": {
    name: "Listing Optimizer",
    // isSubMenu: 1,
    // "/product-analytics": "ProductAnalytics",
  },
  "/repricing/product-listing": {
    name: "Inventory Details",
    isSubMenu: 1,
    "/product-listing": "ProductListing",
  },
  "/repricing/repricing-strategies": {
    name: "Repricing Strategies",
    isSubMenu: 1,
    "/repricing-strategies": "RepricingStrategies",
  },
  "/repricing/buy-box-details": {
    name: "BuyBoxDetails",
    isSubMenu: 1,
    "/buy-box-details": "BuyBoxDetails",
  },

  "/inventory/inventory-details": {
    name: "Inventory Details",
    isSubMenu: 1,
    "/inventory-details": "InventoryDetails",
  },
  "/inventory/inventory-replenishment": {
    name: "Inventory Replenishment",
    isSubMenu: 1,
    "/inventory-replenishment": "InventoryReplenishment",
  },
  "/setting/my-profile": {
    name: "My Profile",
    isSubMenu: 1,
    "/my-profile": "MyProfile",
  },
  "/setting/configuration": {
    name: "Configuration",
    isSubMenu: 1,
    "/configuration": "Configuration",
  },
  "/setting/marketplace-credential": {
    name: "Marketplace Credential",
    isSubMenu: 1,
    "/marketplace-credential": "MarketplaceCredential",
  },
  // "/spapi-callback": {
  //   name: "Marketplace Credential",
  //   "/spapi-callback": "MarketplaceCredential",
  // },
  // "/callbackads": {
  //   name: "Marketplace Credential",
  //   "/callbackads": "MarketplaceCredential",
  // },
  "/application-log/central-log": {
    name: "Central Log",
    isSubMenu: 1,
    "/central-log": "CentralLog",
  },
  "/application-log/system-event-log": {
    name: "System Error Log",
    isSubMenu: 1,
    "/system-event-log": "SystemEventLog",
  },
  "/application-log/data-scheduler": {
    name: "Data Scheduler",
    isSubMenu: 1,
    "/data-scheduler": "DataScheduler",
  },
  "/advertisement/advertisement-dashboard": {
    name: "Dashboard",
    isSubMenu: 1,
    "/advertisement-dashboard": "Dashboard",
  },
  "/advertisement/campaigns-analytics": {
    name: "Campaigns Analytics",
    isSubMenu: 1,
    "/campaigns-analytics": "CampaignsAnalytics",
  },
  "/advertisement/rule-management": {
    name: "Rule Management",
    isSubMenu: 1,
    "/rule-management": "RuleManagement",
  },
  "/advertisement/campaign-management": {
    name: "Campaign Management",
    isSubMenu: 1,
    "/campaign-management": "CampaignManagement",
  },
  "/advertisement/advance-report": {
    name: "Advance Report",
    isSubMenu: 1,
    "/advance-report": "AdvanceReport",
  },
  "/advertisement/keyword-management": {
    name: "Keyword Management",
    isSubMenu: 1,
    "/keyword-management": "KeywordManagement",
  },
  "/master-table/product-details": {
    name: "Product Details",
    isSubMenu: 1,
    "/product-details": "ProductDetails",
  },
  "/master-table/supplier": {
    name: "Supplier",
    isSubMenu: 1,
    "/suppliert": "Supplier",
  },
  "/master-table/item-activity-feed": {
    name: "Item Activity Feed",
    isSubMenu: 1,
    "/item-activity-feed": "ItemActivityFeed",
  },
  "/master-table/item-bulk-operation": {
    name: "Item Bulk Operation",
    isSubMenu: 1,
    "/item-bulk-operation": "ItemBulkOperation",
  },
  "/master-table/feed-items": {
    name: "Feed Items",
    isSubMenu: 1,
    "/feed-items": "FeedItems",
  },
  "/order-management/orders": {
    name: "Orders",
    isSubMenu: 1,
    "/orders": "Orders",
  },
  "/order-management/returns-refunds": {
    name: "ReturnsRefunds",
    isSubMenu: 1,
    "/returns-refunds": "ReturnsRefunds",
  },
  "/manage-reports": {
    name: "ManageReports",
    "/manage-reports": "ManageReports",
  },
  "/manage-reports/view-report": {
    name: "ManageReports",
    "/manage-reports/view-report": "ManageReports",
  },
};
const LinksMenu = (label, key, children, path) => {
  return (
    <Link
      id={Math.random()}
      to={{
        pathname: children ? `${children ? "/" + children : ""}/${key}` : path,
      }}
    >
      {label}
    </Link>
  );
};
function getItem(label, key, icon, children, type, path) {
  return {
    key,
    icon,
    children: Array.isArray(children) ? children : null,
    label:
      children && Array.isArray(children)
        ? label
        : LinksMenu(label, key, children, path),
    type,
  };
}
export const convertSidebarLinks = (type, contextVar) => {
  // console.log(type, "type-------------");
  let menuType = type === "admin" ? AdminMenus : UserMenus;
  return menuType?.map((d) => {
    if (d?.children && d?.children?.length !== 0) {
      if (
        contextVar?.data?.marketplaceType === "amazon" &&
        d?.title === "Order Management"
      ) {
      } else {
        return getItem(
          d?.title,
          d?.name,
          d?.icon,
          d?.children?.map((c) => {
            if (contextVar?.data?.marketplaceType === "amazon") {
              if (
                c?.title !== "Item Activity Feed" &&
                c?.title !== "Item Bulk Operation" &&
                c?.title !== "Walmart Campaign" &&
                // c?.title !== "Campaign Management" &&
                c?.title !== "API Log" &&
                c?.title !== "Advance Report" &&
                c?.title !== "Feed Items"
              ) {
                return getItem(c.title, c.name, c.icon, c.parent);
              }
            } else {
              if (
                c?.title !== "Supplier" &&
                c?.title !== "Inventory Replenishment" &&
                c?.title !== "Buy Box Details"
              ) {
                return getItem(c.title, c.name, c.icon, c.parent);
              }
            }
          })
        );
      }
    } else {
      if (contextVar?.data?.marketplaceType === "amazon") {
        if (d?.title !== "Product Operation") {
          return getItem(d.title, d.name, d.icon, null, null, d.path);
        }
      } else {
        if (d?.title !== "Listing Optimizer" && d?.title !== "Manage Reports") {
          return getItem(d.title, d.name, d.icon, null, null, d.path);
        }
      }
    }
  });
};
