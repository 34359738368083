import {
  Alert,
  Checkbox,
  Col,
  Input,
  Popconfirm,
  Row,
  Select,
  Tag,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import Loading from "../../../../../component/loading";
import IvcsrTable from "../../../../../component/ivcsr-table";
import NoData from "../../../../../component/no-data";
import { useSelector } from "react-redux";
import CampaignCreation from "./lib/campaign-creation";
import ManageAddGroup from "./lib/manage-add-group";
import AddList from "./lib/add-list";
import dayjs from "dayjs";
import CreateKeyword from "./lib/create-keyword";
import { getUserId } from "../../../../../config";

export default function (props) {
  const {
    GetWalmartProductList,
    fakeActionSkuData,
    setSelectedTab,
    WalamrtCreateCampaign,
    WalamrtCreateAdGroupCampaign,
    WalmartCreateAdItemsCampaign,
    WalmartCreateBidPlacementCampaign,
    WalmartCreateBidPlatformCampaign,
    WalmartCreateBidPlacementInclusionCampaign,
    GetWalmartDBLogCampaignList,
    fakeActionCampaignManagement,
    editCampaignData,
  } = props;

  const CreateWalmartCampaignRes = useSelector(
    (state) => state.CampaignManagement.CreateWalmartCampaignResponse || {}
  );
  const CreateWalmartAdGroupRes = useSelector(
    (state) => state.CampaignManagement.CreateWalmartAdGroupResponse || {}
  );
  const CreateWalmartAddItemsRes = useSelector(
    (state) => state.CampaignManagement.CreateWalmartAddItemsResponse || {}
  );
  const CreateWalmartBidPlacementRes = useSelector(
    (state) => state.CampaignManagement.CreateWalmartBidPlacementResponse || {}
  );
  const CreateWalmartBidPlatformRes = useSelector(
    (state) => state.CampaignManagement.CreateWalmartBidPlatformResponse || {}
  );
  const CreateWalmartPlacementInclusionRes = useSelector(
    (state) =>
      state.CampaignManagement.CreateWalmartPlacementInclusionResponse || {}
  );
  const CreateWalmartKeywordCampaignRes = useSelector(
    (state) =>
      state.CampaignManagement.CreateWalmartKeywordCampaignResponse || {}
  );
  const GetWalmartCampaignDBLogListRes = useSelector(
    (state) =>
      state.CampaignManagement.GetWalmartCampaignDBLogListResponse || {}
  );

  const [operation_flag, set_operation_flag] = useState("add");
  const [loading, setLoading] = useState(false);
  const [selectedStep, setSelectedStep] = useState("step1");
  const [addGroupList, setAddGroupList] = useState([]);
  const [addGroupName, setAddGroupName] = useState("");
  const [selectedItemCheckbox, setSelectedItemCheckbox] = useState([]);
  const [selectedKeywordCheckbox, setSelectedKeywordCheckbox] = useState([]);
  const [campaignId, setCampaignId] = useState("");
  const [adGroupId, setAdGroupId] = useState("");
  const [selectedKeywordListView, setSelectedKeywordListView] = useState([]);
  const [walmartProductList, setWalmartProductList] = useState([]);
  const [itemOperationLoading, setItemOperationLoading] = useState("");
  const [itemId, setItemId] = useState("");
  const [keywordOperationLoading, setKeywordOperationLoading] = useState("");
  const [keywordId, setKeywordId] = useState("");
  const [redirectToListFlag, setRedirectToListFlag] = useState("");

  const [campaignCreateObj, setCampaignCreateObj] = useState({
    name: "",
    campaignType: "sponsoredProducts",
    targetingType: "auto",
    status: "enabled",
    startDate: dayjs().format("YYYY-MM-DD"),
    endDate: dayjs("9999-12-30").format("YYYY-MM-DD"),
    totalBudget: 0,
    dailyBudget: 0,
    budgetType: "both",
    rollover: true,
    campaignId: campaignId,
  });
  const [bid_placement_obj, set_bid_placement_obj] = useState({
    "Buy-Box": 0,
    "Search Ingrid": 0,
  });
  const [bid_platform_obj, set_bid_platform_obj] = useState({
    Desktop: 0,
    App: 0,
    Mobile: 0,
  });
  const [placement_inclusion_obj, set_placement_inclusion_obj] = useState({
    "Search Carousel": "excluded",
    "Item Buybox": "excluded",
    "Item Carousel": "excluded",
  });

  useEffect(() => {
    if (selectedStep === "step3") {
      set_operation_flag("edit");
      return;
    }
  }, [selectedStep]);

  useEffect(() => {
    console.log(editCampaignData, "editCampaignData");
    if (Object.entries(editCampaignData)?.length > 0) {
      if (editCampaignData?.campaigns) {
        set_operation_flag("edit");
        setCampaignId(editCampaignData?.campaigns?.[0]?.campaignId);
        setCampaignCreateObj(editCampaignData?.campaigns?.[0]);
      }
      if (editCampaignData?.adgroups) {
        setAddGroupList(editCampaignData?.adgroups);
        setAdGroupId(editCampaignData?.adgroups?.[0]?.adGroupId);
        setAddGroupName(editCampaignData?.adgroups?.[0]?.name);
      }
      if (editCampaignData?.bids_placement) {
        set_bid_placement_obj(
          Object?.entries(editCampaignData?.bids_placement?.[0])?.length > 0
            ? editCampaignData?.bids_placement?.[0]
            : {
                "Buy-Box": 0,
                "Search Ingrid": 0,
              }
        );
      }
      if (editCampaignData?.bids_platform) {
        set_bid_platform_obj(
          Object?.entries(editCampaignData?.bids_platform?.[0])?.length > 0
            ? editCampaignData?.bids_platform?.[0]
            : {
                Desktop: 0,
                App: 0,
                Mobile: 0,
              }
        );
      }
      if (editCampaignData?.placements) {
        set_placement_inclusion_obj(
          Object?.entries(editCampaignData?.placements?.[0])?.length > 0
            ? editCampaignData?.placements?.[0]
            : {
                "Search Carousel": "excluded",
                "Item Buybox": "excluded",
                "Item Carousel": "excluded",
              }
        );
      }

      if (editCampaignData?.aditems) {
        setSelectedItemCheckbox(editCampaignData?.aditems || []);
      }
      if (editCampaignData?.keywords) {
        setSelectedKeywordListView(editCampaignData?.keywords || []);
      }
    }
  }, [editCampaignData]);

  useEffect(() => {
    if (CreateWalmartCampaignRes.status === true) {
      console.log(CreateWalmartCampaignRes, "CreateWalmartCampaignRes");
      if (CreateWalmartCampaignRes?.data?.[0]?.campaignId != 0) {
        setCampaignId(CreateWalmartCampaignRes?.data?.[0]?.campaignId);
        const newObj = { ...campaignCreateObj };
        newObj.campaignId = CreateWalmartCampaignRes?.data?.[0]?.campaignId;
        setCampaignCreateObj(newObj);
        setLoading(false);
        GetWalmartDBLogCampaignList(
          CreateWalmartCampaignRes?.data?.[0]?.campaignId
        );
        setSelectedStep("step2");
      } else {
        message.destroy();
        message.error("Error In Campaign Creation - Go to Step 1");
      }

      fakeActionCampaignManagement("CreateWalmartCampaignResponse");
    } else if (CreateWalmartCampaignRes.status === false) {
      message.destroy();
      message.error(
        CreateWalmartCampaignRes?.data?.[0]?.details ||
          "Campaign Creation Failed"
      );
      setLoading(false);
      fakeActionCampaignManagement("CreateWalmartCampaignResponse");
    }
  }, [CreateWalmartCampaignRes]);

  useEffect(() => {
    if (CreateWalmartAdGroupRes.status === true) {
      console.log(CreateWalmartAdGroupRes, "CreateWalmartAdGroupRes");
      if (CreateWalmartAdGroupRes?.data?.[0]?.adGroupId) {
        setAdGroupId(CreateWalmartAdGroupRes?.data?.[0]?.adGroupId);
        setLoading(false);
        GetWalmartDBLogCampaignList(campaignId);
        setSelectedStep("step3");
      } else {
        message.destroy();
        message.error("Error In Step 2");
      }
      fakeActionCampaignManagement("CreateWalmartAdGroupResponse");
    } else if (CreateWalmartAdGroupRes.status === false) {
      message.destroy();
      message.error(CreateWalmartCampaignRes?.message || "Error in adgroup");
      setLoading(false);
      fakeActionCampaignManagement("CreateWalmartAdGroupResponse");
    }
  }, [CreateWalmartAdGroupRes]);

  useEffect(() => {
    if (CreateWalmartAddItemsRes.status === true) {
      setSelectedItemCheckbox(CreateWalmartAddItemsRes?.data?.adItem || []);
      setItemOperationLoading("");
      setItemId("");
      fakeActionCampaignManagement("CreateWalmartAddItemsResponse");
    } else if (CreateWalmartAddItemsRes.status === false) {
      message.destroy();
      message.error(
        CreateWalmartAddItemsRes?.data?.data?.[0]?.details ||
          "Error in Add Item"
      );
      setTimeout(() => {
        GetWalmartDBLogCampaignList(campaignId);
      }, 2000);
      setLoading(false);
      setItemOperationLoading("");
      setItemId("");
      fakeActionCampaignManagement("CreateWalmartAddItemsResponse");
    }
  }, [CreateWalmartAddItemsRes]);

  useEffect(() => {
    if (CreateWalmartKeywordCampaignRes.status === true) {
      message.destroy();
      // message.success("Success");
      setSelectedKeywordListView(
        CreateWalmartKeywordCampaignRes?.data?.keywords || []
      );
      setKeywordId("");
      setKeywordOperationLoading("");
      fakeActionCampaignManagement("CreateWalmartKeywordCampaignResponse");
    } else if (CreateWalmartKeywordCampaignRes.status === false) {
      message.destroy();
      message.error(
        CreateWalmartKeywordCampaignRes?.data?.data?.error ||
          "Error in Keywords"
      );
      setSelectedKeywordListView(
        CreateWalmartKeywordCampaignRes?.data?.keywords || []
      );
      setKeywordId("");
      setKeywordOperationLoading("");
      setLoading(false);
      fakeActionCampaignManagement("CreateWalmartKeywordCampaignResponse");
    }
  }, [CreateWalmartKeywordCampaignRes]);

  useEffect(() => {
    if (CreateWalmartBidPlacementRes.status === true) {
      fakeActionCampaignManagement("CreateWalmartBidPlacementResponse");
    } else if (CreateWalmartBidPlacementRes.status === false) {
      message.destroy();
      message.error(
        CreateWalmartBidPlacementRes?.message || "Error From Step - 2"
      );
      setLoading(false);

      fakeActionCampaignManagement("CreateWalmartBidPlacementResponse");
    }
  }, [CreateWalmartBidPlacementRes]);

  useEffect(() => {
    if (CreateWalmartBidPlatformRes.status === true) {
      fakeActionCampaignManagement("CreateWalmartBidPlatformResponse");
    } else if (CreateWalmartBidPlatformRes.status === false) {
      message.destroy();
      message.error(
        CreateWalmartBidPlatformRes?.message || "Error From Step - 2"
      );
      setLoading(false);

      fakeActionCampaignManagement("CreateWalmartBidPlatformResponse");
    }
  }, [CreateWalmartBidPlatformRes]);

  useEffect(() => {
    if (CreateWalmartPlacementInclusionRes.status === true) {
      fakeActionCampaignManagement("CreateWalmartPlacementInclusionResponse");
    } else if (CreateWalmartPlacementInclusionRes.status === false) {
      message.destroy();
      message.error(
        CreateWalmartPlacementInclusionRes?.message || "Error From Step - 2"
      );
      setLoading(false);

      fakeActionCampaignManagement("CreateWalmartPlacementInclusionResponse");
    }
  }, [CreateWalmartPlacementInclusionRes]);

  useEffect(() => {
    if (GetWalmartCampaignDBLogListRes.status === true) {
      message.destroy();
      setLoading(false);
      if (
        campaignCreateObj?.targetingType === "auto" &&
        selectedStep === "step3" &&
        redirectToListFlag === "step3Auto"
      ) {
        ClearCampaignData();
        setSelectedTab("campaign-list");
      } else if (
        campaignCreateObj?.targetingType === "manual" &&
        selectedStep === "step4" &&
        redirectToListFlag === "step4Manual"
      ) {
        ClearCampaignData();
        setSelectedTab("campaign-list");
      }

      fakeActionCampaignManagement("GetWalmartCampaignDBLogListResponse");
    } else if (GetWalmartCampaignDBLogListRes.status === false) {
      message.destroy();
      fakeActionCampaignManagement("GetWalmartCampaignDBLogListResponse");
      setLoading(false);
      // ClearCampaignData();
      // setSelectedTab("campaign-list");
    }
  }, [GetWalmartCampaignDBLogListRes]);

  const ClearCampaignData = () => {
    const obj = { ...campaignCreateObj };
    obj.name = "";
    obj.campaignType = "sponsoredProducts";
    obj.targetingType = "auto";
    obj.status = "enabled";
    obj.startDate = dayjs().format("YYYY-MM-DD");
    obj.endDate = dayjs("9999-12-30").format("YYYY-MM-DD");
    obj.totalBudget = 0;
    obj.dailyBudget = 0;
    obj.budgetType = "both";
    obj.rollover = true;
    setCampaignCreateObj({ ...obj });
    setSelectedItemCheckbox([]);
    setAddGroupName("");
    setCampaignId("");
    setRedirectToListFlag("");
  };

  const ContinueWithNextStep = () => {
    if (selectedStep === "step1") {
      const { status, ...rest } = campaignCreateObj;
      const finalJson = campaignId ? [rest] : [campaignCreateObj];
      WalamrtCreateCampaign(finalJson, campaignId);
      return;
    } else if (selectedStep === "step2") {
      const adGroupJson = [
        {
          name: addGroupName,
          status: "enabled",
          adGroupId: adGroupId,
          campaignId: campaignId,
        },
      ];
      WalamrtCreateAdGroupCampaign(adGroupJson, adGroupId);
      const bidPlacementObj = [
        {
          campaignId: campaignId,
          placementType: "Buy-Box",
          multiplier: bid_placement_obj?.["Buy-Box"],
        },
        {
          campaignId: campaignId,
          placementType: "Search Ingrid",
          multiplier: bid_placement_obj?.["Search Ingrid"],
        },
      ];
      WalmartCreateBidPlacementCampaign(bidPlacementObj, operation_flag);
      const bidPlatformObj = [
        {
          campaignId: campaignId,
          platformType: "Desktop",
          multiplier: bid_platform_obj?.Desktop,
        },
        {
          campaignId: campaignId,
          platformType: "App",
          multiplier: bid_platform_obj?.App,
        },
        {
          campaignId: campaignId,
          platformType: "Mobile",
          multiplier: bid_platform_obj?.Mobile,
        },
      ];
      WalmartCreateBidPlatformCampaign(bidPlatformObj, operation_flag);
      const placInclusionObj = [
        {
          campaignId: campaignId,
          placements: [
            {
              placement: "Search Carousel",
              status: placement_inclusion_obj?.["Search Carousel"],
            },
            // {
            //   placement: "Search Ingrid",
            //   status: placement_inclusion_obj?.search_ingrid,
            // },
            {
              placement: "Item Buybox",
              status: placement_inclusion_obj?.["Item Buybox"],
            },
            {
              placement: "Item Carousel",
              status: placement_inclusion_obj?.["Item Carousel"],
            },
          ],
        },
      ];
      campaignCreateObj?.targetingType == "manual" &&
        WalmartCreateBidPlacementInclusionCampaign(placInclusionObj);
      return;
    } else if (selectedStep === "step3") {
      if (campaignCreateObj?.targetingType === "auto") {
        GetWalmartDBLogCampaignList(campaignId);
        setRedirectToListFlag("step3Auto");
        message.destroy();
        message.success("Campaign Created - Go To Campaign List");
      } else {
        GetWalmartDBLogCampaignList(campaignId);
        setLoading(false);
        setSelectedStep("step4");
      }
      // const finalItemJson = selectedItemCheckbox?.map((d) => {
      //   return {
      //     campaignId: campaignId,
      //     adGroupId: adGroupId,
      //     itemId: d?.item_id || d?.itemId,
      //     bid: 0.6,
      //     status: "enabled",
      //   };
      // });
      // WalmartCreateAdItemsCampaign(finalItemJson);
    } else if (selectedStep === "step4") {
      GetWalmartDBLogCampaignList(campaignId);
      setRedirectToListFlag("step4Manual");
      message.destroy();
      message.success("Campaign Created - Go To Campaign List");
      // const keywordJson = {
      //   keywords: selectedKeywordListView,
      //   adGroupId: adGroupId,
      //   campaignId: campaignId,
      // };
      // console.log(keywordJson, "keywordJson");
      // WalmartCreateKeywordCampaign(keywordJson, operation_flag);
      // Brefore keyword tab Change ----------------------------------------------------------------------------------------------------
      // const newJson = selectedKeywordListView?.map((d) => {
      //   const { score, name, suggestedBid, status, ...rest } = d;
      //   return Object.entries(rest)?.map((b) => {
      //     console.log(b, "-----b");
      //     if (b?.[0] === "suggestedBidBroad" && b?.[1] != "") {
      //       return {
      //         adgroup_id: adGroupId,
      //         campaignId: campaignId,
      //         state: "enabled",
      //         keyword_text: d?.name,
      //         match_type: "Broad",
      //         bid: b?.[1],
      //       };
      //     }
      //     if (b?.[0] === "suggestedBidPhrase" && b?.[1] != "") {
      //       return {
      //         adgroup_id: adGroupId,
      //         campaignId: campaignId,
      //         state: "enabled",
      //         keyword_text: d?.name,
      //         match_type: "Broad",
      //         bid: b?.[1],
      //       };
      //     }
      //     if (b?.[0] === "suggestedBidExact" && b?.[1] != "") {
      //       return {
      //         adgroup_id: adGroupId,
      //         campaignId: campaignId,
      //         state: "enabled",
      //         keyword_text: d?.name,
      //         match_type: "Broad",
      //         bid: b?.[1],
      //       };
      //     }
      //   });
      // });
      // const filteredData = newJson.map((subArray) => {
      //   console.log(subArray, "subArray");
      //   return subArray.filter((obj) => obj !== "undefined");
      // });
      // console.log(filteredData, "newJson");
      // -------------------------------------------------------------------------------------------------Code
      // WalmartCreateKeywordCampaign(keywordJson);
      // const keywordJson = selectedKeywordCheckbox?.map((d) => {
      //   return {
      //     adgroup_id: CreateWalmartAdGroupRes?.data?.[0]?.adGroupId,
      //     campaignId: campaignId,
      //     state: "enabled",
      //     keyword_text: d?.name,
      //     match_type: d?.type,
      //     bid: d?.bid,
      //   };
      // });
      // console.log(keywordJson, "keywordJson");
      //
    }
  };
  return (
    <>
      <div className="d-flex flex-column flex-lg-row fadeOutBottom">
        <div className="flex-lg-row-fluid ms-lg-5 ">
          <div className="row gy-5 g-xl-5">
            <div className="col-xl-12 ">
              {" "}
              <div className="stepper stepper-pills fadeOutBottom">
                <div
                  className=" p-4 rounded mb-5"
                  style={{
                    background: "#d6d1fe69",
                    border: "1px dashed #9c8fff",
                  }}
                  data-select2-id="select2-data-45-oa2y"
                >
                  <div
                    className="row align-items-center"
                    data-select2-id="select2-data-44-br2g"
                  >
                    <div className="col-12 d-flex justify-content-between align-items-center">
                      <div className="stepper-nav flex-wrap mb-0 ">
                        <div
                          className={`stepper-item me-5  ${
                            selectedStep === "step1" && "current"
                          }`}
                          data-kt-stepper-element="nav"
                        >
                          <div className="stepper-wrapper d-flex align-items-center">
                            <div className="stepper-icon w-40px h-40px">
                              <span className="stepper-number">1</span>
                            </div>

                            <div className="stepper-label">
                              <h3 className="stepper-title">Step 1</h3>
                              <div className="stepper-desc">
                                Create Campaign
                              </div>
                            </div>
                          </div>

                          <div className="stepper-line h-40px" />
                        </div>

                        <div
                          className={`stepper-item me-5  ${
                            selectedStep === "step2" && "current"
                          }`}
                          data-kt-stepper-element="nav"
                        >
                          <div className="stepper-wrapper d-flex align-items-center">
                            <div className="stepper-icon w-40px h-40px">
                              <span className="stepper-number">2</span>
                            </div>

                            <div className="stepper-label">
                              <h3 className="stepper-title">Step 2</h3>
                              <div className="stepper-desc">Add Group</div>
                            </div>
                          </div>

                          <div className="stepper-line h-40px" />
                        </div>

                        <div
                          className={`stepper-item me-5  ${
                            selectedStep === "step3" && "current"
                          }`}
                          data-kt-stepper-element="nav"
                        >
                          <div className="stepper-wrapper d-flex align-items-center">
                            <div className="stepper-icon w-40px h-40px">
                              <i className="stepper-check fas fa-check" />
                              <span className="stepper-number">3</span>
                            </div>

                            <div className="stepper-label">
                              <h3 className="stepper-title">Step 3</h3>
                              <div className="stepper-desc">Add Items</div>
                            </div>
                          </div>

                          <div className="stepper-line h-40px" />
                        </div>

                        {campaignCreateObj?.targetingType === "manual" && (
                          <div
                            className={`stepper-item me-5  ${
                              selectedStep === "step4" && "current"
                            }`}
                            data-kt-stepper-element="nav"
                          >
                            <div className="stepper-wrapper d-flex align-items-center">
                              <div className="stepper-icon w-40px h-40px">
                                <i className="stepper-check fas fa-check" />
                                <span className="stepper-number">4</span>
                              </div>

                              <div className="stepper-label">
                                <h3 className="stepper-title">Step 4</h3>
                                <div className="stepper-desc">Add Keyword</div>
                              </div>
                            </div>

                            <div className="stepper-line h-40px" />
                          </div>
                        )}
                      </div>
                      {/* <div>
                        <Tag color="geekblue" className="fs-5 fw-bold">
                          Add Campaign
                        </Tag>
                      </div> */}
                    </div>
                    {/* <div
                      className="col-md-4 "
                      data-select2-id="select2-data-43-wnyf"
                    ></div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{ height: "calc(100vh - 165px)", overflow: "auto" }}
            className="pe-2"
          >
            {selectedStep === "step1" && (
              <CampaignCreation
                {...props}
                data={campaignCreateObj}
                setCampaignCreateObj={setCampaignCreateObj}
              />
            )}

            {selectedStep === "step2" && (
              <ManageAddGroup
                {...props}
                data={campaignCreateObj}
                setAddGroupName={setAddGroupName}
                addGroupName={addGroupName}
                addGroupList={addGroupList}
                setAddGroupList={setAddGroupList}
                bid_placement_obj={bid_placement_obj}
                set_bid_placement_obj={set_bid_placement_obj}
                bid_platform_obj={bid_platform_obj}
                set_bid_platform_obj={set_bid_platform_obj}
                placement_inclusion_obj={placement_inclusion_obj}
                set_placement_inclusion_obj={set_placement_inclusion_obj}
              />
            )}

            {selectedStep === "step3" && (
              <AddList
                {...props}
                data={campaignCreateObj}
                setCampaignCreateObj={setCampaignCreateObj}
                selectedCheckbox={selectedItemCheckbox}
                setSelectedCheckbox={setSelectedItemCheckbox}
                campaignId={campaignId}
                adGroupId={adGroupId}
                walmartList={walmartProductList}
                setWalmartList={setWalmartProductList}
                itemOperationLoading={itemOperationLoading}
                setItemOperationLoading={setItemOperationLoading}
                itemId={itemId}
                setItemId={setItemId}
              />
            )}

            {selectedStep === "step4" && (
              <CreateKeyword
                {...props}
                selectedKeyword={selectedKeywordCheckbox}
                setSelectedKeyword={setSelectedKeywordCheckbox}
                campaignId={campaignId}
                adGroupId={adGroupId}
                selectedKeywordListView={selectedKeywordListView}
                setSelectedKeywordListView={setSelectedKeywordListView}
                keywordOperationLoading={keywordOperationLoading}
                setKeywordOperationLoading={setKeywordOperationLoading}
                keywordId={keywordId}
                setKeywordId={setKeywordId}
              />
            )}

            <div className="row gy-5 g-xl-5 my-5">
              <div className="col-xl-12">
                {" "}
                <div className="d-flex flex-stack">
                  <div className="me-2">
                    {selectedStep !== "step1" && (
                      <button
                        type="button"
                        className="btn btn-danger btn-active-danger"
                        onClick={() => {
                          const selectStep =
                            selectedStep === "step4"
                              ? "step3"
                              : selectedStep === "step3"
                              ? "step2"
                              : selectedStep === "step2"
                              ? "step1"
                              : "";

                          setSelectedStep(selectStep);
                        }}
                      >
                        Back
                      </button>
                    )}
                  </div>
                  <div>
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        setLoading(true);
                        message.destroy();
                        message.loading("Loading...", 0);
                        ContinueWithNextStep();
                      }}
                      disabled={loading}
                    >
                      {campaignCreateObj?.targetingType === "auto" &&
                      selectedStep === "step3" ? (
                        "Save"
                      ) : selectedStep === "step4" ? (
                        "Save"
                      ) : loading ? (
                        <span>Please wait...</span>
                      ) : (
                        "Save & Next"
                      )}
                    </button>
                  </div>

                  {/* <div>
                    {(campaignCreateObj?.targetingType === "manual" &&
                      selectedStep === "step4") ||
                    (campaignCreateObj?.targetingType === "auto" &&
                      selectedStep === "step3") ? (
                      <>
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => {
                            const finalItemJson = selectedItemCheckbox?.map(
                              (d) => {
                                return {
                                  campaignId: 1833225925,
                                  adgroup_id: 771483936,
                                  item_id: d?.item_id,
                                  bid: 0.6,
                                  status: "enabled",
                                };
                              }
                            );
                            console.log(campaignCreateObj, "campaignCreateObj");
                            console.log(
                              {
                                name: addGroupName,
                                status: "enabled",
                                campaignId: 1833225925,
                              },
                              "adgroup"
                            );
                            console.log(finalItemJson, "addList");
                            setLoading(true);
                            message.destroy();
                            message.loading("Loading...", 0);
                            ContinueWithNextStep();
                          }}
                          disabled={
                            !campaignCreateObj?.name || !addGroupName || loading
                          }
                        >
                          {loading ? (
                            <span>Please wait...</span>
                          ) : (
                            <span className="indicator-label">Submit</span>
                          )}
                        </button>
                      </>
                    ) : (
                      ""
                    )}
                    {(campaignCreateObj?.targetingType === "manual" &&
                      selectedStep !== "step4") ||
                    (campaignCreateObj?.targetingType === "auto" &&
                      selectedStep !== "step3") ? (
                      <>
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => {
                            const selectStep =
                              selectedStep === "step1"
                                ? "step2"
                                : selectedStep === "step2"
                                ? "step3"
                                : selectedStep === "step3"
                                ? "step4"
                                : "";

                            setSelectedStep(selectStep);
                          }}
                          disabled={
                            (selectedStep === "step1" &&
                              (!campaignCreateObj?.name ||
                                campaignCreateObj?.totalBudget === "" ||
                                !campaignCreateObj?.dailyBudget === "" ||
                                !campaignCreateObj?.startDate ||
                                !campaignCreateObj?.endDate)) ||
                            (selectedStep === "step2" && !addGroupName)
                          }
                        >
                          Continue
                        </button>
                      </>
                    ) : (
                      ""
                    )}
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
