import React, { useEffect, useState } from "react";
import Loading from "../../../../component/loading";
import IvcsrTable from "../../../../component/ivcsr-table";
import NoData from "../../../../component/no-data";
import Pagination from "../../../../component/pagination";
import { DefaultPerPage } from "../../../../config";
import { Popconfirm, Popover, Spin, Switch, Tag, Tooltip, message } from "antd";
import {
  LoadingOutlined,
  ExclamationCircleOutlined,
  CopyTwoTone,
  DeleteTwoTone,
  EditTwoTone,
} from "@ant-design/icons";
import AddNewRepricer from "./lib/add-new-repricer";
const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
    }}
    spin
  />
);
export default function (props) {
  const {} = props;
  const [list, setList] = useState([
    {
      key: "1",
      srNo: 1,
      product_title:
        "Night Owl CAM-HDA10W High-Resolution Security Dome Camera",
      sku: "WM-CAM-HDA10W-DMA",
      asin: "B01N198PY8",
      image_url: "https://m.media-amazon.com/images/I/41TMLrtKYKL._SL75_.jpg",
      last30DaysSales: 100,
      buyBoxPrice: 20.99,
      buyBoxPercentage: 50,
      lossOfBuyBox: "No",
      lostRevenue: 0,
      buyBoxWinner: "Seller X",
    },
    {
      key: "1",
      srNo: 1,
      product_title:
        "Night Owl CAM-HDA10W High-Resolution Security Dome Camera",
      sku: "WM-CAM-HDA10W-DMA",
      asin: "B01N198PY8",
      image_url: "https://m.media-amazon.com/images/I/41TMLrtKYKL._SL75_.jpg",
      last30DaysSales: 100,
      buyBoxPrice: 20.99,
      buyBoxPercentage: 50,
      lossOfBuyBox: "No",
      lostRevenue: 0,
      buyBoxWinner: "Seller X",
    },
  ]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageSize, setPageSize] = useState(DefaultPerPage);
  const [ruleWithProduct, setRuleWithProduct] = useState({});
  const [statusId, setStatusId] = useState("");
  const [modalType, setModalType] = useState("add");
  const [addNewRepricer, setAddNewRepricer] = useState(false);
  const [selectedRule, setSelectedRule] = useState({});

  const onPageNo = (e) => {
    // setLoading(true);
    // const apiObj = {
    //   page: e,
    //   perPage: pageSize,
    //   searchKey: searchTerm,
    //   marketplace_id: selectedSalesChannel,
    //   supplier_id: selectedSupplier,
    //   forecast_days: noOfDaysToReplenish,
    //   isExport: 0,
    // };
    // GetInventoryManagementList(apiObj);
    setPage(e);
  };

  const onPerPage = (e) => {
    setPage(1);
    setPageSize(e);
    // setLoading(true);
    // const apiObj = {
    //   page: 1,
    //   perPage: e,
    //   searchKey: searchTerm,
    //   marketplace_id: selectedSalesChannel,
    //   supplier_id: selectedSupplier,
    //   forecast_days: noOfDaysToReplenish,
    //   isExport: 0,
    // };
    // GetInventoryManagementList(apiObj);
  };

  const columns = [
    {
      title: "Sr. No",
      fixed: "left",
      render: (item) => {
        return (
          <div>
            <span>{(page - 1) * pageSize + 1 + item.key}</span>
          </div>
        );
      },
    },
    {
      title: "Product",
      dataIndex: "product_title",
      key: "product_title",
      fixed: "left",
      isFilterKey: "notAdd",
      width: 500,
      render: (item, row) => {
        return (
          <div className="d-flex align-items-center border-start border-2 border-info p-3 rounded">
            <div className="symbol symbol-50px  me-3">
              <img src={`${row?.image_url}`} alt="" />
            </div>
            {/*end::Avatar*/}
            {/*begin::User details*/}
            <div className="d-flex flex-column">
              <Popover
                content={
                  <div
                    style={{
                      height: "max-content",
                      width: "300px",
                    }}
                  >
                    {item}
                  </div>
                }
                placement="bottom"
              >
                <div
                  className="text-primary mb-1 fw-bolder text-one fs-5 popoverActionIconSingleLine"
                  style={{ minWidth: "100px" }}
                >
                  {item}
                </div>
              </Popover>

              <div>
                <span className="fs-6">
                  <Tag color="orange">
                    <b>SKU:</b> {row?.sku}
                  </Tag>
                </span>
                <br />
                <span className="fs-6 ">
                  <Tag color="purple" className="mt-1">
                    <b>ASIN:</b> {row?.asin}
                  </Tag>
                </span>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Last 30 days Sales",
      dataIndex: "buyBoxPrice",
      key: "buyBoxPrice",
    },
    {
      title: "Buy Box Price",
      dataIndex: "buyBoxPrice",
      key: "buyBoxPrice",
    },
    {
      title: "Buy Box %",
      dataIndex: "buyBoxPercentage",
      key: "buyBoxPercentage",
    },
    {
      title: "Loss of Buy Box",
      dataIndex: "lossOfBuyBox",
      key: "lossOfBuyBox",
    },
    {
      title: "Lost Revenue",
      dataIndex: "lossOfBuyBox",
      key: "lossOfBuyBox",
    },
    {
      title: "Buy Box Winner",
      dataIndex: "buyBoxWinner",
      key: "buyBoxWinner",
    },
  ];

  return (
    <>
      <div className="d-flex flex-column flex-column-fluid fadeInRight">
        <div id="kt_app_content" className="app-content flex-column-fluid">
          <div
            id="kt_app_content_container"
            className="app-container container-fluid"
          >
            <div className="row gy-5 g-xl-5">
              <div className="col-xxl-12">
                <div className="card card-xxl-stretch mb-5 mb-xl-8">
                  <div className="card-header border-bottom-dashed">
                    <h3 className="card-title ">
                      <span className="card-label fw-bold text-gray-900">
                        Buy Box Details
                      </span>
                    </h3>

                    {/* <div className="card-toolbar gap-2">
                      <div className="position-relative">
                        <span
                          onClick={() => {
                            setAddNewRepricer(true);
                            setModalType("add");
                          }}
                          className="btn btn-primary fw-bolder"
                        >
                          Add Rule
                        </span>
                      </div>
                    </div> */}
                  </div>

                  <div className="card-body pb-5 pt-0">
                    <div className="table-responsive mt-5">
                      {loading ? (
                        <Loading />
                      ) : list?.length !== 0 ? (
                        <>
                          <IvcsrTable
                            columns={columns}
                            fixed="top"
                            dataSource={list}
                            rowKey="key"
                            loading={loading}
                            pagination={false}
                            scroll={{ x: "max-content" }}
                          />
                        </>
                      ) : (
                        <NoData type="new" />
                      )}
                    </div>
                    <Pagination
                      loading={loading || list?.length === 0}
                      pageSize={pageSize}
                      page={page}
                      totalPage={totalPage}
                      onPerPage={onPerPage}
                      onPageNo={onPageNo}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {addNewRepricer && (
        <AddNewRepricer
          onHide={() => {
            setAddNewRepricer(false);
            setSelectedRule({});
          }}
          show={addNewRepricer}
          Type={modalType}
          selectedRule={selectedRule}
          getList={() => {
            setPage(1);
            setSelectedRule({});
            setPageSize(DefaultPerPage);
            setLoading(true);
            // GetRepricingRuleListAction({
            //   page: 1,
            //   perPage: DefaultPerPage,
            // });
          }}
          {...props}
        />
      )}
    </>
  );
}
