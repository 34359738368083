import axiosCall from "../../../configurations/network-services/axiosCall";

export const GetAdvFilters = () => {
  const path = `advertising/dashboard/filters`;
  const responseType = "GET_FILTERS_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetAdvDashboardGraphCardData = (data) => {
  const path = `advertising/dashboard/dashboard-cards-data?match_type=${
    data?.match_type || ""
  }&start_date=${data?.start_date || ""}&end_date=${
    data?.end_date || ""
  }&request_type=${data?.request_type || ""}&marketplace_id=${
    data?.marketplace_id || ""
  }`;
  const responseType = "GET_DASHBOARD_GRAPH_CARD_DATA";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetAdvDashboardSubCardData = (data) => {
  // const path = `advertising/dashboard/performance-metrics?advertising_type=&start_date=2023-10-26&end_date=2023-11-25&request_type=Previous&marketplace_id=ATVPDKIKX0DER`;
  const path = `advertising/dashboard/performance-metrics?advertising_type=${
    data?.metrics_type || ""
  }&start_date=${data?.start_date || ""}&end_date=${
    data?.end_date || ""
  }&request_type=${data?.request_type || ""}&marketplace_id=${
    data?.marketplace_id || ""
  }`;
  const responseType = "GET_DASHBOARD_SUB_CARD_DATA";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetActiveCampaignSubCardData = (data) => {
  const path = `advertising/dashboard/active-campaigns?advertising_type=${
    data?.metrics_type || ""
  }&start_date=${data?.start_date || ""}&end_date=${
    data?.end_date || ""
  }&request_type=${data?.request_type || ""}&marketplace_id=${
    data?.marketplace_id || ""
  }`;
  const responseType = "GET_DASHBOARD_ACTIVE_CAMPAIGN_SUB_CARD_DATA";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetTargetingSubCardData = (data) => {
  const path = `advertising/dashboard/targeting-card-data?advertising_type=${
    data?.metrics_type || ""
  }&start_date=${data?.start_date || ""}&end_date=${
    data?.end_date || ""
  }&request_type=${data?.request_type || ""}&marketplace_id=${
    data?.marketplace_id || ""
  }`;
  const responseType = "GET_DASHBOARD_TARGETING_SUB_CARD_DATA";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetDailyPerformanceTrend = (data) => {
  const path = `advertising/dashboard/daily-performance-trend?advertising_type=${
    data?.metrics_type || ""
  }&start_date=${data?.start_date || ""}&end_date=${
    data?.end_date || ""
  }&request_type=${data?.request_type || ""}&marketplace_id=${
    data?.marketplace_id || ""
  }&matrics=${data?.trend_type || ""}`;
  const responseType = "GET_DASHBOARD_DAILY_PERFORMANCE_TREND_DATA";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetTopSearchTermData = (data) => {
  const path = `advertising/dashboard/top-search-term?start_date=${
    data?.start_date || ""
  }&end_date=${data?.end_date || ""}&marketplace_id=${
    data?.marketplace_id || ""
  }`;
  const responseType = "GET_DASHBOARD_TOP_SEARCH_TERM_DATA";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetAmazonActiveKeywords = (data) => {
  const path = `advertising/dashboard/active-keywords?start_date=2023-10-26&end_date=2024-02-20&marketplace_id=ATVPDKIKX0DER`;
  const responseType = "GET_AMAZON_ACTIVE_KEYWORDS";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

// ----------------------------------------------------------------------------------------Walmart APIs

export const GetWalmartAdvFilter = (data) => {
  const path = `wm/advertising/metrics`;
  const responseType = "GET_WALMART_ADV_FILTER";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetWalmartAdvPerformanceChart = (data) => {
  const path = `wm/dashboard-ads/metrics-charts?start_date=${
    data?.start_date || ""
  }&end_date=${data?.end_date || ""}&metrics=${data?.metrics || ""}`;
  const responseType = "GET_WALMART_ADV_PERFORMANCE_CHART";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetWalmartPerformanceSummary = (data) => {
  const path = `wm/dashboard-ads/summary?start_date=${
    data?.start_date || ""
  }&end_date=${data?.end_date || ""}`;
  const responseType = "GET_WALMART_ADV_PERFORMANCE_SUMMARY";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetWalmartCampaignByAdSpend = (data) => {
  const path = `wm/dashboard-ads/ad-spend?start_date=${
    data?.start_date || ""
  }&end_date=${data?.end_date || ""}`;
  const responseType = "GET_WALMART_CAMPAIGN_BY_AD_SPEND";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetWalmartCampaignOutOfBudget = (data) => {
  const path = `wm/dashboard-ads/out-of-budget?start_date=${
    data?.start_date || ""
  }&end_date=${data?.end_date || ""}`;
  const responseType = "GET_WALMART_CAMPAIGN_OUT_OF_BUDGET";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetWalmartDashboardPerformanceMetrics = (data) => {
  const path = `wm/dashboard-ads/performance-metrics?start_date=${
    data?.start_date || ""
  }&end_date=${data?.end_date || ""}`;
  const responseType = "GET_WALMART_DASHBOARD_PERFORMANCE_METRICS";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const fakeActionAdvDashboard = (data) => async (dispatch) => {
  dispatch({ type: "FAKE_ACTION_ADV_DASHBOARD", state: data });
};
