export default (state, action) => {
  if (typeof state === "undefined") {
    return [];
  }
  switch (action.type) {
    case "GET_REPRICER_LISTING_SUCCESS":
    case "GET_REPRICER_LISTING_ERROR":
      return {
        ...state,
        GetRepricingProductListingResponse: action.updatePayload,
      };
    case "GET_REPRICER_STRATEGIES_SUCCESS":
    case "GET_REPRICER_STRATEGIES_ERROR":
      return {
        ...state,
        GetRepricingStrategiesResponse: action.updatePayload,
      };
    case "FAKE_ACTION_REPRICER_LISTING":
      return { ...state, [action?.state]: {} };
    default:
      return state;
  }
};
