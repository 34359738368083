import { Skeleton, Tag } from "antd";
import { SyncOutlined } from "@ant-design/icons";
import NoData from "../../../../component/no-data";
import { useEffect } from "react";
import { useSelector } from "react-redux";

export default function (props) {
  const {
    productData,
    allLoading,
    setAllLoading,
    suggestedBullets,
    GetCriteriaAnalysisBulletPoints,
    GetRecommendationBullets,
    fakeActionListingOptimizer,
    setSuggestedBullets,
    recommendedBullets,
    setRecommendedBullets,
  } = props;

  const GetCriteriaAnalysisBulletPointsRes = useSelector(
    (state) =>
      state.ListingOptimizer.GetCriteriaAnalysisBulletPointsResponse || {}
  );
  const GetRecommendationBulletsRes = useSelector(
    (state) => state.ListingOptimizer.GetRecommendationBulletsResponse || {}
  );
  console.log("bullet_point_analysis", productData?.bullet_point_analysis);
  useEffect(() => {
    const loading = { ...allLoading };
    if (GetCriteriaAnalysisBulletPointsRes?.status === true) {
      loading.bulletAnalysis = false;
      setAllLoading(loading);
      setSuggestedBullets(
        JSON.parse(
          GetCriteriaAnalysisBulletPointsRes?.data?.matchCriteria?.[0]
        ) || ""
      );
      fakeActionListingOptimizer("GetCriteriaAnalysisBulletPointsResponse");
    } else if (GetCriteriaAnalysisBulletPointsRes?.status === false) {
      loading.bulletAnalysis = false;
      setAllLoading(loading);
      fakeActionListingOptimizer("GetCriteriaAnalysisBulletPointsResponse");
    }
  }, [GetCriteriaAnalysisBulletPointsRes]);

  useEffect(() => {
    const loading = { ...allLoading };
    if (GetRecommendationBulletsRes?.status === true) {
      console.log(GetRecommendationBulletsRes, "GetRecommendationBulletsRes");
      loading.bulletRecommendation = false;
      setAllLoading(loading);
      const newPoints =
        GetRecommendationBulletsRes?.data?.suggested_bullet_point
          ?.split("- ")
          .filter(Boolean);
      console.log(newPoints, "newPoints");
      setRecommendedBullets(newPoints || []);
      fakeActionListingOptimizer("GetRecommendationBulletsResponse");
    } else if (GetRecommendationBulletsRes?.status === false) {
      loading.bulletRecommendation = false;
      setAllLoading(loading);
      fakeActionListingOptimizer("GetRecommendationBulletsResponse");
    }
  }, [GetRecommendationBulletsRes]);

  return (
    <>
      {" "}
      <div className="col-xl-12">
        <div className="card card-flush h-lg-100 mb-5 mb-xl-5">
          <div
            className="card-header border-0 cursor-pointer min-h-50px"
            role="button"
            data-bs-toggle="collapse"
            data-bs-target="#kt_account_profile_details"
            aria-expanded="true"
            aria-controls="kt_account_profile_details"
          >
            <div className="card-title m-0">
              <h3 className="fw-bold m-0">
                Bullet Points
                <label className="ms-2">
                  <Tag color="#108ee9" className="fw-bolder fs-5">
                    Score : 0
                  </Tag>
                </label>
              </h3>
            </div>
          </div>

          <div className="card-body border-top border-top-dashed pt-5 pb-3">
            <div className="row mb-0">
              <div className="col-lg-6 ">
                {allLoading?.mainHeaderLoading ? (
                  <>
                    <Skeleton.Input
                      active
                      block
                      small
                      className="my-1"
                      style={{ height: "18px" }}
                    />
                    <Skeleton.Input
                      active
                      block
                      small
                      className="my-1"
                      style={{ height: "18px" }}
                    />
                    <Skeleton.Input
                      active
                      block
                      small
                      className="my-1"
                      style={{ height: "18px" }}
                    />
                    <Skeleton.Input
                      active
                      block
                      small
                      className="my-1"
                      style={{ height: "18px" }}
                    />
                    <Skeleton.Input
                      active
                      small
                      style={{ height: "18px" }}
                      className="my-1"
                    />
                  </>
                ) : (
                  <div>
                    <div>
                      <label className="fw-bolder fs-6">
                        Current Bullet :{" "}
                      </label>
                      <div
                        style={{
                          maxHeight: "290px",
                          overflow: "auto",
                        }}
                      >
                        {console.log(
                          JSON.parse(productData?.bullet_point || "[]"),
                          "--------p"
                        )}
                        {JSON.parse(productData?.bullet_point || "[]")?.map(
                          (d) => {
                            return (
                              <div className="">
                                <label className="">
                                  {`->`}
                                  <span className="ms-2 fw-bold"> {d}</span>
                                </label>
                              </div>
                            );
                          }
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="col-lg-6 fv-row">
                <div className="card card-rec">
                  <div className="card-header bg-white py-2 px-4 min-h-50px h-50px">
                    <h3 className="card-title fs-4 fw-bold text-info">
                      <i className="ki-duotone ki-technology-4 fs-2 text-info me-3">
                        <span className="path1" />
                        <span className="path2" />
                        <span className="path3" />
                        <span className="path4" />
                        <span className="path5" />
                        <span className="path6" />
                        <span className="path7" />
                      </i>
                      Recommendation
                    </h3>
                    <div className="card-toolbar my-0">
                      <button
                        className="btn btn-light-info fw-bold btn-sm"
                        style={{ border: "1px solid #4318ff" }}
                        onClick={() => {
                          const loading = { ...allLoading };
                          loading.bulletRecommendation = true;
                          setAllLoading(loading);
                          const Obj = {
                            parameter_name: "Bullet Point",
                            // content_text:
                            //   JSON.parse(productData?.bullet_point) || "",
                            asin: productData?.asin,
                            // brand: productData?.brand,
                          };
                          // GetCriteriaAnalysisBulletPoints(Obj);
                          GetRecommendationBullets(Obj);
                        }}
                        disabled={
                          allLoading?.mainHeaderLoading ||
                          allLoading?.bulletRecommendation
                        }
                      >
                        Generate
                      </button>
                    </div>
                  </div>
                  <div className="card-body min-h-150px">
                    <ul className="ps-3">
                      {allLoading?.mainHeaderLoading ||
                      allLoading?.bulletRecommendation ? (
                        <Skeleton active block />
                      ) : recommendedBullets.length > 0 ? (
                        recommendedBullets?.map((d) => {
                          return (
                            <p>
                              <label className="me-2"> {"->"}</label>
                              {d}
                            </p>
                          );
                        })
                      ) : (
                        <NoData height="150px" />
                      )}
                      {/* <li>
                    Unique and effective formulas: Ducray
                    products are specially formulated to
                    solve specific hair and skin concerns,
                    giving you the results you want.
                  </li>
                  <li>
                    Clinically proven results: Our products
                    are backed by clinical studies,
                    providing you with confidence in their
                    effectiveness.
                  </li>
                  <li>
                    Dermatologist recommended: Ducray
                    products are trusted and recommended by
                    dermatologists, ensuring the highest
                    quality and safety for your skin and
                    hair.
                  </li>
                  <li>
                    Targeted solutions for all hair and skin
                    types: With a wide range of products for
                    different needs, Ducray has the perfect
                    solution for everyone.
                  </li>
                  <li>
                    Transform your hair and skin: Say
                    goodbye to hair and skin concerns with
                    Ducray's innovative and transformative
                    products.
                  </li> */}
                    </ul>
                  </div>
                </div>
              </div>

              <div className="co-12">
                <div className="separator separator-dashed my-3" />
                <div className="fs-5 fw-bold mb-4">Analysis: </div>
                <div className="cus-badge mb-10 g-2 ">
                  {allLoading?.mainHeaderLoading ||
                  allLoading?.bulletAnalysis ? (
                    <>
                      <Skeleton.Button
                        active
                        style={{
                          borderRadius: "20px",
                          height: "25px",
                          width: "200px",
                        }}
                        className="my-1 me-3"
                      />
                      <Skeleton.Button
                        active
                        style={{
                          borderRadius: "20px",
                          height: "25px",
                          width: "200px",
                        }}
                        className="my-1 me-3"
                      />
                      <Skeleton.Button
                        active
                        style={{
                          borderRadius: "20px",
                          height: "25px",
                          width: "200px",
                        }}
                        className="my-1 me-3"
                      />
                      <Skeleton.Button
                        active
                        style={{
                          borderRadius: "20px",
                          height: "25px",
                          width: "200px",
                        }}
                        className="my-1 me-3"
                      />
                    </>
                  ) : (
                    <>
                      {console.log(suggestedBullets, "suggestedBullets")}
                      {/* <p>{suggestedBullets || "-"}</p> */}
                      {Object.entries(suggestedBullets)?.map((d) => {
                        if (
                          d?.[0] !== "Total Score" &&
                          d?.[0] !== "Score" &&
                          d?.[0] !== "score"
                        ) {
                          return (
                            <span className="badge-c mb-3">
                              <span
                                className={`ki-outline ${
                                  d?.[1] ? "ki-check" : "ki-cross"
                                }   fs-3 me-2 badge__icon ${
                                  d?.[1] ? "bg-success" : "bg-danger"
                                }`}
                              />
                              {d?.[0]}
                            </span>
                          );
                        }
                      })}
                      <span
                        className="badge-c mb-3 cursor-pointer"
                        onClick={() => {
                          const loading = { ...allLoading };
                          loading.bulletAnalysis = true;
                          setAllLoading(loading);
                          const Obj = {
                            parameter_name: "Bullet Point",
                            // content_text:
                            //   JSON.parse(productData?.bullet_point) || "",
                            asin: productData?.asin,
                            // brand: productData?.brand,
                          };
                          GetCriteriaAnalysisBulletPoints(Obj);
                        }}
                      >
                        <span className=" fs-3 me-2 badge__icon bg-info">
                          {allLoading.bulletAnalysis ? (
                            <SyncOutlined spin />
                          ) : (
                            <SyncOutlined />
                          )}
                        </span>
                        Reload
                      </span>
                    </>
                  )}
                  {/* <span className="badge-c mb-3">
                <span className="ki-outline ki-check fs-3 me-2 badge__icon bg-success" />
                Feature Highlight
              </span>
              <span className="badge-c mb-3">
                <span className="ki-outline ki-check fs-3 me-2 badge__icon bg-success" />
                Benefit-Oriented
              </span>
              <span className="badge-c mb-3">
                <span className="ki-outline ki-check fs-3 me-2 badge__icon bg-success" />
                Concise and Clear
              </span>
              <span className="badge-c mb-3">
                <span className="ki-outline ki-check fs-3 me-2 badge__icon bg-success" />
                Specific and Quantifiable
              </span>
              <span className="badge-c mb-3">
                <span className="ki-outline ki-check fs-3 me-2 badge__icon bg-success" />
                Differentiation
              </span>
              <span className="badge-c mb-3">
                <span className="ki-outline ki-check fs-3 me-2 badge__icon bg-success" />
                Customer-Centric
              </span>
              <span className="badge-c mb-3">
                <span className="ki-outline ki-check fs-3 me-2 badge__icon bg-success" />
                Use of Keywords
              </span>
              <span className="badge-c mb-3">
                <span className="ki-outline ki-check fs-3 me-2 badge__icon bg-success" />
                Grammar and Formatting
              </span>
              <span className="badge-c mb-3">
                <span className="ki-outline ki-check fs-3 me-2 badge__icon bg-success" />
                Header Included
              </span>
              <span className="badge-c mb-3">
                <span className="ki-outline ki-check fs-3 me-2 badge__icon bg-success" />
                Capitalization of Headers
              </span>
              <span className="badge-c mb-3">
                <span className="ki-outline ki-check fs-3 me-2 badge__icon bg-success" />
                Bullet Point Formatting
              </span>
              <span className="badge-c mb-3">
                <span className="ki-outline ki-check fs-3 me-2 badge__icon bg-success" />
                Length Consistency
              </span>
              <span className="badge-c mb-3">
                <span className="ki-outline ki-check fs-3 me-2 badge__icon bg-success" />
                Scannability
              </span>
              <span className="badge-c mb-3">
                <span className="ki-outline ki-check fs-3 me-2 badge__icon bg-success" />
                Readability and Flow
              </span>
              <span className="badge-c mb-3">
                <span className="ki-outline ki-check fs-3 me-2 badge__icon bg-success" />
                Persuasiveness
              </span> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
