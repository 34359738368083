import { Input, Popconfirm, Popover, Select, Tag, message } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import IvcsrTable from "../../../../component/ivcsr-table";
import Loading from "../../../../component/loading";
import NoData from "../../../../component/no-data";
import Pagination from "../../../../component/pagination";
import { DefaultPerPage, timeSince } from "../../../../config";
import { SearchOutlined } from "@ant-design/icons";
export default function (props) {
  const { GetFeedItemsLogList, fakeActionFeesItemsLog } = props;

  const GetFeedItemsLogListRes = useSelector(
    (state) => state.FeedItemsLogReducer.GetFeedItemsLogListResponse || {}
  );

  const [list, setList] = useState();
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageSize, setPageSize] = useState(DefaultPerPage);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const obj = { page: 1, pageSize: pageSize, search_by: searchTerm };
    GetFeedItemsLogList(obj);
    return () => {};
  }, []);

  useEffect(() => {
    if (GetFeedItemsLogListRes.status === true) {
      setList(GetFeedItemsLogListRes?.data?.records);
      console.log(GetFeedItemsLogListRes, "GetFeedItemsLogListRes");
      setLoading(false);
      setTotalPage(GetFeedItemsLogListRes?.data?.pagination?.totalCount);
      fakeActionFeesItemsLog("GetFeedItemsLogListResponse");
    } else if (GetFeedItemsLogListRes.status === false) {
      setLoading(false);
      fakeActionFeesItemsLog("GetFeedItemsLogListResponse");
    }
  }, [GetFeedItemsLogListRes]);

  const onPageNo = (e) => {
    setLoading(true);
    const apiObj = {
      page: e,
      perPage: pageSize,
      search_by: searchTerm,
    };
    GetFeedItemsLogList(apiObj);
    setPage(e);
  };

  const onPerPage = (e) => {
    setPage(1);
    setPageSize(e);
    setLoading(true);
    const apiObj = {
      page: 1,
      perPage: e,
      search_by: searchTerm,
    };
    GetFeedItemsLogList(apiObj);
  };

  const columns = [
    {
      title: "#",
      ellipsis: true,
      align: "left",
      render: (text) => {
        return <span>{(page - 1) * pageSize + 1 + text.key}</span>;
      },
    },
    {
      title: "Product",
      width: 400,
      render: (text) => {
        return (
          <div className="d-flex align-items-center p-3 rounded">
            <div className="symbol symbol-50px  me-3">
              <img src={`${text?.primary_image_url}`} alt="" />
            </div>
            {/*end::Avatar*/}
            {/*begin::User details*/}
            <div className="d-flex flex-column">
              <Popover
                content={
                  <div
                    style={{
                      height: "max-content",
                      maxWidth: "400px",
                    }}
                  >
                    {text?.product_name}
                  </div>
                }
                placement="bottom"
              >
                <div
                  className="text-primary mb-1 fw-bolder text-one fs-5 popoverActionIconSingleLine"
                  style={{ minWidth: "100px" }}
                >
                  {text?.product_name}
                </div>
              </Popover>

              <div>
                <span className="fs-6">
                  <Tag color="orange">
                    <b>SKU:</b> {text?.sku}
                  </Tag>
                </span>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Category",
      dataIndex: "sub_category",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Product Id Type",
      dataIndex: "product_type",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Product ID",
      dataIndex: "product_type_id",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Price",
      dataIndex: "price",
      render: (price) => <span>${price.toFixed(2)}</span>,
    },
    // {
    //   title: "Shipping Weight",
    //   dataIndex: "shipping_weight",
    //   render: (text) => <span>{text}</span>,
    // },
    {
      title: "Brand",
      dataIndex: "brand",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Short Description",
      width: 200,
      render: (text) => (
        <Popover
          content={
            <div
              style={{
                height: "max-content",
                maxWidth: "400px",
              }}
            >
              {text?.short_description}
            </div>
          }
          placement="bottom"
        >
          <div
            className=" mb-1 text-one fs-5 popoverActionIconSingleLine"
            style={{ minWidth: "100px" }}
          >
            {text?.short_description}
          </div>
        </Popover>
      ),
    },

    // {
    //   title: "Must Ship Alone",
    //   dataIndex: "must_ship_alone",
    //   render: (mustShipAlone) => (mustShipAlone ? "Yes" : "No"),
    // },
    {
      title: "Visible To Category",
      dataIndex: "visible_to",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      render: (text) => (
        <span>{moment(text * 1000).format("MMM DD, YYYY hh:mm A")}</span>
      ),
    },
    {
      title: "Updated At",
      dataIndex: "updated_at",
      render: (text) => (
        <span>{moment(text * 1000).format("MMM DD, YYYY hh:mm A")}</span>
      ),
    },
    // {
    //   title: "Varient Swatches",
    //   dataIndex: "varient_swatches",
    //   render: (text) => <span>{text}</span>,
    // },
  ];

  return (
    <div className="fadeInRight">
      <>
        <div className="d-flex flex-column flex-column-fluid">
          <div id="kt_app_content" className="app-content flex-column-fluid">
            <div
              id="kt_app_content_container"
              className="app-container container-fluid"
            >
              {/*begin::Row*/}
              <div className="row gy-5 g-xl-5">
                {/*begin::Col*/}
                <div className="col-xxl-12">
                  {/*begin::Tables Widget 9*/}
                  <div className="card card-xxl-stretch mb-5 mb-xl-8">
                    {/*begin::Header*/}
                    <div className="card-header border-bottom-dashed">
                      {/*end::Title*/}
                      {/*begin::Title*/}
                      <h3 className="card-title ">
                        {" "}
                        <div className="position-relative mr-4">
                          {" "}
                          <Input
                            type="text"
                            className="text-dark w-250px fs-7 fw-bold ant_common_input"
                            name="all_search"
                            placeholder="Search by SKU, Title, Product Id..."
                            value={searchTerm}
                            onChange={(e) => {
                              setSearchTerm(e.target.value);
                            }}
                            onPressEnter={(e) => {
                              setLoading(true);
                              setPage(1);
                              setPageSize(DefaultPerPage);
                              const obj = {
                                page: 1,
                                pageSize: pageSize,
                                search_by: e.target.value,
                              };
                              GetFeedItemsLogList(obj);
                            }}
                            prefix={
                              <SearchOutlined
                                style={{ fontSize: "19px", color: "grey" }}
                              />
                            }
                            size="large"
                          />
                        </div>
                      </h3>
                      {/*end::Title*/}
                      <div className="card-toolbar">
                        <div className="position-relative ms-3"></div>
                      </div>
                    </div>
                    {/*end::Header*/}
                    {/*begin::Body*/}
                    <div className="card-body pb-5 pt-0">
                      {/*begin::Table container*/}
                      <div className="table-responsive mt-5">
                        {loading ? (
                          <Loading />
                        ) : list?.length !== 0 ? (
                          <>
                            <IvcsrTable
                              columns={columns?.map((d) => ({
                                ...d,
                                width:
                                  d?.title === "Product"
                                    ? 300
                                    : d?.title?.length * 13,
                              }))}
                              sticky={{
                                offsetHeader: "10px",
                              }}
                              scroll={{
                                x: "max-content",
                                y: "calc(100vh - 200px)",
                              }}
                              fixed="top"
                              dataSource={list}
                              rowKey="key"
                              loading={loading}
                              pagination={false}
                            />
                          </>
                        ) : (
                          <NoData type="new" />
                        )}
                      </div>
                      <Pagination
                        loading={loading || list?.length === 0}
                        pageSize={pageSize}
                        page={page}
                        totalPage={totalPage}
                        onPerPage={onPerPage}
                        onPageNo={onPageNo}
                      />
                    </div>
                    {/*begin::Body*/}
                  </div>
                  {/*end::Tables Widget 9*/}
                </div>
                {/*end::Col*/}
              </div>
              {/*end::Row*/}
            </div>
          </div>
        </div>
      </>
    </div>
  );
}
