import { Alert, Checkbox, Input, Select, message } from "antd";
import React, { useEffect, useState } from "react";
import Loading from "../../../../../component/loading";
import IvcsrTable from "../../../../../component/ivcsr-table";
import NoData from "../../../../../component/no-data";
import WalmartMarketplaceModal from "./walmart-marketplace-modal";
import WalmartConnectModal from "./walmart-connect-modal";
import { useSelector } from "react-redux";
import moment from "moment";

export default function (props) {
  const { GetWalmartCredentialAction, fakeActionMarketplaceCred } = props;
  const [selectedTab, setSelectedTab] = useState("marketplace");
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);
  const [walmartMarketplaceModal, setWalmartMarketplaceModal] = useState(false);
  const [walmartConnectModal, setWalmartConnectModal] = useState(false);

  const GetWalmartCredentialRes = useSelector(
    (state) => state.MarketplaceCredential.GetWalmartCredentialResponse || {}
  );
  useEffect(() => {
    setLoading(true);
    GetWalmartCredentialAction();
  }, []);

  useEffect(() => {
    if (GetWalmartCredentialRes.status === true) {
      setList(GetWalmartCredentialRes?.data);
      // console.log("hello");
      setLoading(false);
      fakeActionMarketplaceCred("GetWalmartCredentialResponse");
    } else if (GetWalmartCredentialRes.status === false) {
      message.destroy();
      message.error(GetWalmartCredentialRes?.message);
      setLoading(false);
      fakeActionMarketplaceCred("GetWalmartCredentialResponse");
    }
  }, [GetWalmartCredentialRes]);

  const walmartMarketplaceColumn = [
    {
      title: "Sr.No.",
      render: (text) => {
        return <span>{text.key + 1}</span>;
      },
    },
    {
      title: "Seller Name",
      render: (text) => {
        return <span>{JSON.parse(text?.credential_details)?.seller_name}</span>;
      },
    },
    {
      title: "App Type",
      render: (text) => {
        return <span>{text?.app_type}</span>;
      },
    },
    {
      title: "Marketplace",
      render: (text) => {
        return <span>{text?.marketplace}</span>;
      },
    },
    {
      title: "Marketplace Id",
      render: (text) => {
        return <span>{text?.marketplace_id}</span>;
      },
    },
    {
      title: "Client Id",
      render: (text) => {
        // console.log(
        //   JSON.parse(text?.credential_details),
        //   "JSON.parse(text?.credential_details)"
        // );
        const originalString = JSON.parse(text?.credential_details)?.client_id;
        const firstSevenCharacters = originalString.slice(0, 4);
        const lastSevenCharacters = originalString.slice(-4);
        const maskedString =
          firstSevenCharacters + "*************" + lastSevenCharacters;
        return <span>{maskedString}</span>;
      },
    },
    {
      title: "Client Secret",
      render: (text) => {
        const originalString = JSON.parse(
          text?.credential_details
        )?.client_secret;
        const firstSevenCharacters = originalString.slice(0, 7);
        const lastSevenCharacters = originalString.slice(-7);
        const maskedString =
          firstSevenCharacters + "*************" + lastSevenCharacters;
        return <span>{maskedString}</span>;
      },
    },

    {
      title: "Created At",
      render: (text) => {
        return (
          <span>
            {moment(new Date(text?.created_at * 1000)).format("MMM DD, YYYY")}
          </span>
        );
      },
    },
  ];

  const walmartConnectColumn = [
    {
      title: "Sr.No.",
      render: (text) => {
        return <span>{text.key + 1}</span>;
      },
    },
    {
      title: "Seller Name",
      render: (text) => {
        return <span>{JSON.parse(text?.credential_details)?.seller_name}</span>;
      },
    },
    {
      title: "App Type",
      render: (text) => {
        return <span>{text?.app_type}</span>;
      },
    },
    {
      title: "Marketplace",
      render: (text) => {
        return <span>{text?.marketplace}</span>;
      },
    },
    {
      title: "Marketplace Id",
      render: (text) => {
        return <span>{text?.marketplace_id}</span>;
      },
    },
    {
      title: "Auth Token",
      render: (text) => {
        const originalString = JSON.parse(text?.credential_details)?.authToken;
        const firstSevenCharacters = originalString.slice(0, 7);
        const lastSevenCharacters = originalString.slice(-7);
        const maskedString =
          firstSevenCharacters + "*************" + lastSevenCharacters;
        return <span>{maskedString}</span>;
      },
    },
    {
      title: "Consumer Id",
      render: (text) => {
        const originalString = JSON.parse(text?.credential_details)?.consumerId;
        const firstSevenCharacters = originalString.slice(0, 7);
        const lastSevenCharacters = originalString.slice(-7);
        const maskedString =
          firstSevenCharacters + "*************" + lastSevenCharacters;
        return <span>{maskedString}</span>;
      },
    },
    {
      title: "Advertiser Id",
      render: (text) => {
        return (
          <span>{JSON.parse(text?.credential_details)?.advertiserId}</span>
        );
      },
    },

    {
      title: "Created At",
      render: (text) => {
        return (
          <span>
            {moment(new Date(text?.created_at * 1000)).format("MMM DD, YYYY")}
          </span>
        );
      },
    },
  ];

  return (
    <>
      <div className="row">
        <div className="col-md-12 cus-tab">
          <ul className="nav nav-pills mb-5 fs-6">
            <li
              className="nav-item"
              onClick={() => {
                // setLoading(true);
                setSelectedTab("marketplace");
                // history.replace("/spapi-callback");
                // GetMarketplaceCred();
              }}
            >
              <span
                className={`nav-link ${
                  selectedTab === "marketplace" && "active"
                }  fs-7 fw-bold py-3 px-7 me-2 bg-white`}
              >
                Walmart Marketplace Credentials
              </span>
            </li>
            <li
              className="nav-item"
              onClick={() => {
                // setLoading(true);
                setSelectedTab("connect");
                // history.replace("/callbackads");
                // GetMarketplaceCred();
              }}
            >
              <span
                className={`nav-link  ${
                  selectedTab === "connect" && "active"
                }  fs-7 fw-bold py-3 px-7 me-2 bg-white`}
              >
                Walmart Connect Credentials
              </span>
            </li>
          </ul>
        </div>
      </div>

      <div className="tab-content" id="myTabContent">
        {selectedTab === "marketplace" && (
          <div
            className={`tab-pane fade ${
              selectedTab === "marketplace" && "show active"
            }`}
            role="tabpanel"
          >
            <div className="row gy-5 g-xl-5 fadeInRight">
              <div className="col-xxl-12">
                <div className="card card-xxl-stretch mb-5 mb-xl-8">
                  <div className="card-header border-bottom-dashed">
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label fw-bolder text-dark">
                        Walmart Marketplace Credentials List
                      </span>
                    </h3>
                    <div className="card-toolbar">
                      <div className="d-flex flex-stack flex-wrap gap-4">
                        <button
                          className="btn btn-primary"
                          onClick={() => {
                            setWalmartMarketplaceModal(true);
                            // setOpeningSpCredModal(true);
                            // GetRegionList();
                          }}
                        >
                          Add New
                          {/* {openingSpCredModal && (
                            <span className="spinner-border spinner-border-sm align-middle ms-2" />
                          )} */}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="card-body py-2">
                    <div className="table-responsive">
                      {loading ? (
                        <Loading />
                      ) : list?.length !== 0 ? (
                        <IvcsrTable
                          columns={walmartMarketplaceColumn}
                          dataSource={list?.filter(
                            (d) => d?.credential_type === "WM_API"
                          )}
                          pagination={false}
                          scroll={{ x: "max-content" }}
                        />
                      ) : (
                        <NoData />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {selectedTab === "connect" && (
          <div
            className={`tab-pane fade ${
              selectedTab === "connect" && "show active"
            }`}
            role="tabpanel"
          >
            <div className="row gy-5 g-xl-5 fadeInRight">
              <div className="col-xxl-12">
                <div className="card card-xxl-stretch mb-5 mb-xl-8">
                  <div className="card-header border-bottom-dashed">
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label fw-bolder text-dark">
                        Walmart Connect Credentials List
                      </span>
                    </h3>
                    <div className="card-toolbar">
                      <div className="d-flex flex-stack flex-wrap gap-4">
                        <button
                          className="btn btn-primary"
                          onClick={() => {
                            setWalmartConnectModal(true);
                            // GetRegionList();
                          }}
                        >
                          Add New
                          {/* {openingSpCredModal && (
                            <span className="spinner-border spinner-border-sm align-middle ms-2" />
                          )} */}
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="card-body py-2">
                    <div className="table-responsive">
                      {loading ? (
                        <Loading />
                      ) : list?.length !== 0 ? (
                        <IvcsrTable
                          columns={walmartConnectColumn}
                          dataSource={list?.filter(
                            (d) => d?.credential_type === "WM_ADVERTISING_API"
                          )}
                          pagination={false}
                          scroll={{ x: "max-content" }}
                        />
                      ) : (
                        <NoData />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {walmartMarketplaceModal && (
        <WalmartMarketplaceModal
          show={walmartMarketplaceModal}
          onHide={() => {
            setWalmartMarketplaceModal(false);
          }}
          onSuccess={() => {
            setWalmartMarketplaceModal(false);
            setLoading(true);
            GetWalmartCredentialAction();
          }}
          {...props}
        />
      )}

      {walmartConnectModal && (
        <WalmartConnectModal
          show={walmartConnectModal}
          onHide={() => {
            setWalmartConnectModal(false);
          }}
          onSuccess={() => {
            setWalmartConnectModal(false);
            setLoading(true);
            GetWalmartCredentialAction();
          }}
          {...props}
        />
      )}
    </>
  );
}
