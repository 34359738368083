import { CloudDownloadOutlined, LoadingOutlined } from "@ant-design/icons";
import { Select, Tag, Upload, message } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import IvcsrTable from "../../../component/ivcsr-table";
import Loading from "../../../component/loading";
import NoData from "../../../component/no-data";
import Pagination from "../../../component/pagination";
import {
  DefaultPerPage,
  ItemActivityBulkUploadSampleTemplate,
  getUserId,
} from "../../../config";
import ErrorLogModal from "./lib/error-log-modal";
import ErrorMessageModal from "./lib/error-message-modal";

const { Dragger } = Upload;
export default function (props) {
  const {
    GetItemBulkOperation,
    DownloadItemBulkFile,
    GetUploadFileBulkError,
    ItemBulkOperationUpload,
    fakeActionItemBulkOperation,
    GetWalmartApiLogDetails,
  } = props;

  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageSize, setPageSize] = useState(DefaultPerPage);
  const [selectedOperation, setSelectedOperation] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadFile, setUploadFile] = useState(null);
  const [isOpenVisibleToModal, setIsOpenVisibleToModal] = useState(false);
  const [visibleToOption, setVisibleToOption] = useState(null);
  const [isOpenErrorModal, setIsOpenErrorModal] = useState(false);
  const [errorModalMessage, setErrorModalMessage] = useState([]);
  const [wmRequestedData, setWmRequestedData] = useState([]);
  const [wmResponseData, setWmResponseData] = useState([]);
  const [typeFilter, setTypeFilter] = useState(null);
  const [isOpenErrorMsgModal, setIsOpenErrorMsgModal] = useState(false);
  const [walmartStatusError, setWalmartStatusError] = useState([]);
  const [selectedWalmartStatus, setSelectedWalmartStatus] = useState("");

  const [showChildListDiv, setshowChildListDiv] = useState(false);
  const [selectedRowForChildList, setSelectedRowForChildList] = useState(false);

  const GetItemBulkOperationRes = useSelector(
    (state) => state.ItemBulkOperation.GetItemBulkOperationResponse || {}
  );

  const GetDownloadBulkOperationRes = useSelector(
    (state) => state.ItemBulkOperation.GetDownloadBulkOperationResponse || {}
  );

  const GetUploadFIleBulkErrorRes = useSelector(
    (state) => state.ItemBulkOperation.GetUploadFIleBulkErrorResponse || {}
  );

  const GetUploadItemBulkOperationFileRes = useSelector(
    (state) =>
      state.ItemBulkOperation.GetUploadItemBulkOperationFileResponse || {}
  );
  const GetWalmartApiLogDetailsRes = useSelector(
    (state) => state.ItemBulkOperation.GetWalmartApiLogDetailsResponse || {}
  );

  useEffect(() => {
    const obj = { page: 1, perPage: pageSize, feed_type: typeFilter };
    GetItemBulkOperation(obj);
    return () => {};
  }, []);

  useEffect(() => {
    if (GetItemBulkOperationRes.status === true) {
      setList(GetItemBulkOperationRes?.data?.records || []);

      setLoading(false);
      setTotalPage(GetItemBulkOperationRes?.data?.pagination?.totalCount);
      fakeActionItemBulkOperation("GetItemBulkOperationResponse");
    } else if (GetItemBulkOperationRes.status === false) {
      setLoading(false);
      fakeActionItemBulkOperation("GetItemBulkOperationResponse");
    }
  }, [GetItemBulkOperationRes]);

  useEffect(() => {
    if (GetUploadItemBulkOperationFileRes.status === true) {
      setVisibleToOption(null);
      fakeActionItemBulkOperation("GetUploadItemBulkOperationFileResponse");
    } else if (GetUploadItemBulkOperationFileRes.status === false) {
      setLoading(false);
      setVisibleToOption(null);
      fakeActionItemBulkOperation("GetUploadItemBulkOperationFileResponse");
    }
  }, [GetUploadItemBulkOperationFileRes]);

  useEffect(() => {
    if (GetUploadFIleBulkErrorRes.status === true) {
      message.destroy();
      setIsOpenErrorMsgModal(true);
      setErrorModalMessage(GetUploadFIleBulkErrorRes?.data);
      fakeActionItemBulkOperation("GetUploadFIleBulkErrorResponse");
    } else if (GetUploadFIleBulkErrorRes.status === false) {
      message.destroy();
      message.error(GetUploadFIleBulkErrorRes?.message);
      fakeActionItemBulkOperation("GetUploadFIleBulkErrorResponse");
    }
  }, [GetUploadFIleBulkErrorRes]);

  useEffect(() => {
    if (GetWalmartApiLogDetailsRes.status === true) {
      message.destroy();
      GetWalmartApiLogDetailsRes?.data?.records?.[0]?.wm_request_payload &&
        setWmRequestedData(
          JSON.parse(
            GetWalmartApiLogDetailsRes?.data?.records?.[0]?.wm_request_payload
          )
        );
      GetWalmartApiLogDetailsRes?.data?.records?.[0]?.wm_response_payload &&
        setWmResponseData(
          JSON.parse(
            GetWalmartApiLogDetailsRes?.data?.records?.[0]?.wm_response_payload
          )
        );

      if (
        GetWalmartApiLogDetailsRes?.data?.records?.[0]?.wm_request_payload ||
        GetWalmartApiLogDetailsRes?.data?.records?.[0]?.wm_response_payload
      ) {
        const newErr = JSON.parse(
          GetWalmartApiLogDetailsRes?.data?.records?.[0]?.wm_response_payload
        );
        if (selectedWalmartStatus === "SUCCESS") {
          console.log(newErr, "-newErr");
          const finalErr = newErr?.map((d) => {
            return { feedId: d?.feedId, feedStatus: d?.feedStatus };
          });
          setWalmartStatusError(finalErr);
        } else {
          const finalErr = newErr?.map((d) => {
            const feedId = d?.feedId;
            const feedStatus = d?.feedStatus;
            const myErrData = d?.itemDetails?.itemIngestionStatus?.filter(
              (a) => a?.ingestionStatus === "DATA_ERROR"
            );
            const errorData = myErrData?.map((a) => {
              // console.log(a, "-errorData");
              const sku = a?.sku;
              const errMsg = a?.ingestionErrors?.ingestionError?.map((e) => {
                return { ...e, sku };
              });
              return errMsg;
            });
            console.log(errorData, "errorData");
            const flatErr = errorData?.flat();
            console.log(flatErr, "flatErr");
            const returnErr = flatErr?.map((e) => {
              return { ...e, feedId, feedStatus };
            });
            console.log(returnErr, "returnErr");
            return returnErr;
          });
          const displayErrMsg = finalErr?.flat();
          console.log(displayErrMsg, "displayErrMsg");
          setWalmartStatusError(displayErrMsg);
        }
        setIsOpenErrorModal(true);
      } else {
        message.destroy();
        message.info("Payload not found...");
      }
      fakeActionItemBulkOperation("GetWalmartApiLogDetailsResponse");
    } else if (GetWalmartApiLogDetailsRes.status === false) {
      message.destroy();
      message.error(GetWalmartApiLogDetailsRes?.message);
      setSelectedWalmartStatus("");
      fakeActionItemBulkOperation("GetWalmartApiLogDetailsResponse");
    }
  }, [GetWalmartApiLogDetailsRes]);

  const downloadFile = (filename, file) => {
    // Step 1: Convert binary data to a Blob

    const binaryString = atob(file);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);

    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }

    // Step 2: Convert binary data to a Blob
    const blob = new Blob([bytes], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Step 3: Create a URL for the Blob and Trigger the download
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename); // Set the file name
    document.body.appendChild(link);
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
    URL.revokeObjectURL(url);
  };

  useEffect(() => {
    if (GetDownloadBulkOperationRes.status === true) {
      downloadFile(
        GetDownloadBulkOperationRes?.filename,
        GetDownloadBulkOperationRes?.data
      );
      message.destroy();
      // downloadFile()
      fakeActionItemBulkOperation("GetDownloadBulkOperationResponse");
    } else if (GetDownloadBulkOperationRes.status === false) {
      setLoading(false);
      message.destroy();
      message.error(GetDownloadBulkOperationRes?.message);
      fakeActionItemBulkOperation("GetDownloadBulkOperationResponse");
    }
  }, [GetDownloadBulkOperationRes]);

  const columns = [
    {
      title: "Sr. No.",
      align: "left",

      render: (text) => {
        return <span>{(page - 1) * pageSize + 1 + text.key}</span>;
      },
    },
    {
      title: "API Log Id",
      dataIndex: "wm_api_log_id",
      key: "wm_api_log_id",
      render: (text) => (
        <Link
          to={{
            pathname: `/application-log/api-log`,
            search: `?logId=${text}`,
          }}
          target="blank"
          style={{ textDecoration: "underline" }}
        >
          <span>{text || "-"}</span>
        </Link>
      ),
    },
    {
      title: "File Name",
      dataIndex: "file_name",
      key: "file_name",
      render: (text, record) => (
        <span
          className="fw-bold text-primary cursor-pointer"
          style={{ textDecoration: "underline" }}
          onClick={() => {
            const obj = {
              operation: "upload",
              filename: record?.upload_filename,
            };
            DownloadItemBulkFile(obj);
          }}
        >
          {text || "-"}
        </span>
      ),
    },

    {
      title: "Operation",
      dataIndex: "feed_type",
      key: "feed_type",
      align: "center",
      render: (text) => (
        <span>
          {text === "MP_ITEM"
            ? "Create / Update Item"
            : text === "price"
            ? "Price"
            : text === "inventory"
            ? "Inventory"
            : text === "MP_ITEM_MATCH"
            ? "Item Match"
            : text === "RETURNS_OVERRIDES"
            ? "Refund Overrides"
            : ""}
        </span>
      ),
    },
    {
      title: "Upload Status",
      dataIndex: "upload_status",
      key: "upload_status",

      render: (text) =>
        text ? (
          <Tag color={text === "SUCCESS" ? "success" : "error"}>{text}</Tag>
        ) : (
          ""
        ),
    },
    {
      title: "Record Status",
      dataIndex: "records_status",
      key: "records_status",

      render: (text, _) =>
        text ? (
          <Tag
            // style={{ textDecoration: "underline" }}
            // className="cursor-pointer"
            color={text === "SUCCESS" ? "success" : "error"}
            onClick={() => {
              // message.destroy();
              // message.loading("Loading...", 0);
              // const data = {
              //   operation: "error",
              //   filename: _?.download_filename,
              // };
              // GetUploadFileBulkError(data);
            }}
          >
            {text}
          </Tag>
        ) : (
          ""
        ),
    },

    {
      title: "Walmart Status",
      dataIndex: "wm_status",
      key: "wm_status",

      render: (text, _) =>
        text ? (
          <Tag
            style={{ textDecoration: "underline" }}
            className="cursor-pointer"
            color={text === "SUCCESS" ? "success" : "error"}
            onClick={() => {
              setSelectedWalmartStatus(text);
              message.destroy();
              message.loading("Loading...", 0);
              GetWalmartApiLogDetails(_?.wm_api_log_id);
            }}
          >
            {text}
          </Tag>
        ) : (
          ""
        ),
    },
    {
      title: "Records Count",
      dataIndex: "records_count",
      key: "records_count",
      render: (text) => <span>{text || "-"}</span>,
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => (
        <span>
          {moment(new Date(text * 1000)).format("MMM DD, YYYY hh:mm A")}
        </span>
      ),
    },
    {
      title: "Action",
      align: "center",
      render: (text) => (
        <div className="d-flex">
          <button
            className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-3"
            onClick={() => {
              setshowChildListDiv(true);
              setSelectedRowForChildList(text);
            }}
          >
            <i className="ki-outline ki-eye fs-2 text-primary" />
          </button>
          <button
            className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
            onClick={() => {
              const obj = {
                operation: "download",
                filename: text?.download_filename,
              };
              message.destroy();
              message.loading("Loading...", 0);
              DownloadItemBulkFile(obj);
            }}
            disabled={text?.upload_status === "ERROR"}
          >
            <CloudDownloadOutlined className="fs-1 text-success cursor-pointer" />
          </button>
        </div>
      ),
    },
  ];

  const onPageNo = (e) => {
    setLoading(true);
    const apiObj = {
      page: e,
      perPage: pageSize,
      feed_type: typeFilter,
    };
    GetItemBulkOperation(apiObj);
    setPage(e);
  };

  const onPerPage = (e) => {
    setPage(1);
    setPageSize(e);
    setLoading(true);
    const apiObj = {
      page: 1,
      perPage: e,
      feed_type: typeFilter,
    };
    GetItemBulkOperation(apiObj);
  };

  // const propsUpload = {
  //   name: "file",
  //   multiple: false,
  //   showUploadList: false,
  //   onChange(info) {
  //     if (info?.file?.status !== "uploading") {
  //       message.destroy();
  //       setUploadFile(null);
  //       if (selectedOperation) {
  //         message.loading("File Uploading...", 0);
  //         setIsUploading(true);
  //         setUploadFile(info?.file);
  //         setTimeout(() => {
  //           ItemBulkOperationUpload({
  //             operation: selectedOperation,
  //             file: info?.file?.originFileObj,
  //           });
  //         }, 1600);
  //       } else {
  //         message.warning("Please select Marketplace");
  //       }
  //     }
  //   },
  // };

  const customUpload = async (props) => {
    const { file, onSuccess, onError } = props;
    try {
      setIsUploading(true);
      const formData = new FormData();
      formData.append("file", file);
      const response = await fetch("https://service.ivcsr.com/api/v1/bulk", {
        method: "POST",
        headers: {
          AUTHORIZATION: `Bearer ${localStorage.getItem("token")}`,
          // user_id: 8,
          // user_id: JSON.parse(localStorage.getItem("user"))?.id,
          "USER-ID": getUserId(),
          "MARKETPLACE-ID": "ATVPDKIKX0DER",
          FEEDTYPE: selectedOperation,
          "VISIBLE-TO":
            selectedOperation === "MP_ITEM" ? visibleToOption : null,
          MODE: "FILE",
          FILENAME: file.name,
        },
        body: formData,
      });
      if (response.ok) {
        onSuccess();
        message.destroy();
        const responseData = await response.json();
        const finalJson = JSON.stringify(responseData);

        setIsUploading(false);
        if (responseData?.code == 201) {
          message.destroy();
          message.success(
            "Your item has been submitted to Walmart Catalog.  Please note that it can take up to 48 hours for items to be reflect to walmart catalog."
          );
        } else {
          message.destroy();
          message.error(
            responseData?.message || "Please go to Api log to check error"
          );
        }
        setLoading(true);
        setSelectedOperation(null);
        const obj = { page: 1, pageSize: pageSize, feed_type: typeFilter };
        GetItemBulkOperation(obj);
      } else {
        const errorData = await response.json();
        onError(new Error(errorData.message));
        message.destroy();
        message.error("File upload failed");
      }
    } catch (error) {
      message.destroy();
      message.error("Error");
    }
  };

  const beforeUpload = (file) => {
    const isExcel =
      file.type === "application/vnd.ms-excel" ||
      file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

    if (!isExcel) {
      message.error(`${file.name} is not an excel file`);
    }

    return isExcel || Upload.LIST_IGNORE; // Ignore the file if it's not Excel
  };

  const allCategoryList = [
    "Animal Accessories",
    "Home Decor, Kitchen, & Other",
    "Electronics Other",
    "Animal Food",
    "Animal Health & Grooming",
    "Animal Other",
    "Art & Craft",
    "Baby Diapering, Care, & Other",
    "Baby Food",
    "Baby Furniture",
    "Baby Toys",
    "Baby Transport",
    "Beauty, Personal Care, & Hygiene",
    "Bedding",
    "Books & Magazines",
    "Building Supply",
    "Cameras & Lenses",
    "Carriers & Accessories",
    "Cases & Bags",
    "Cell Phones",
    "Ceremonial Clothing & Accessories",
    "Clothing",
    "Computer Components",
    "Computers",
    "Costumes",
    "Cycling",
    "Decorations & Favors",
    "Electrical",
    "Electronics Accessories",
    "Electronics Cables",
    "Food & Beverage",
    "Footwear",
    "Fuels & Lubricants",
    "Funeral",
    "Furniture",
    "Garden & Patio",
    "Gift Supply & Awards",
    "Grills & Outdoor Cooking",
    "Hardware",
    "Health & Beauty Electronics",
    "Household Cleaning Products & Supplies",
    "Instrument Accessories",
    "Jewelry",
    "Land Vehicles",
    "Large Appliances",
    "Medical Aids & Equipment",
    "Medicine & Supplements",
    "Movies",
    "Music Cases & Bags",
    "Music",
    "Musical Instruments",
    "Office",
    "Optical",
    "Optics",
    "Other",
    "Photo Accessories",
    "Plumbing & HVAC",
    "Printers, Scanners, & Imaging",
    "Safety & Emergency",
    "Software",
    "Sound & Recording",
    "Sport & Recreation Other",
    "Storage",
    "TV Shows",
    "TVs & Video Displays",
    "Tires",
    "Tools & Hardware Other",
    "Tools",
    "Toys",
    "Vehicle Other",
    "Vehicle Parts & Accessories",
    "Video Games",
    "Video Projectors",
    "Watches",
    "Watercraft",
    "Wheels & Wheel Components",
  ];

  const subListColumns = [
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      render: (image) => (
        <img src={image} alt="product" style={{ width: 50 }} />
      ), // rendering image
    },
    {
      title: "Product Name",
      dataIndex: "productName",
      key: "productName",
    },
    {
      title: "SKU",
      dataIndex: "sku",
      key: "sku",
    },
    {
      title: "Brand",
      dataIndex: "brand",
      key: "brand",
    },
    {
      title: "Product ID",
      dataIndex: "productId",
      key: "productId",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (text) => `$${text}`, // formatting price
    },
  ];
  const data = [
    {
      key: "1",
      productName: "Product 1",
      sku: "SKU001",
      brand: "Brand A",
      productId: "12345",
      price: "100.00",
      image: "https://via.placeholder.com/50",
    },
    {
      key: "2",
      productName: "Product 2",
      sku: "SKU002",
      brand: "Brand B",
      productId: "12346",
      price: "150.00",
      image: "https://via.placeholder.com/50",
    },
    // Add more product data as needed
  ];

  return (
    <div className="fadeInRight">
      <>
        <div className="d-flex flex-column flex-column-fluid">
          <div id="kt_app_content" className="app-content flex-column-fluid">
            <div
              id="kt_app_content_container"
              className="app-container container-fluid"
            >
              <div className="row mb-5">
                <div className="col-12 col-md-3 col-lg-2">
                  <div className="row">
                    <div className="col-12 mb-1">
                      <label className="text-primary fw-bold fs-6">
                        Step 1
                      </label>
                    </div>
                    <div className="col-12">
                      <div
                        style={{
                          height: "100%",
                          border: "1px dashed #168c1d",
                          background: "#ebf5eb",
                          borderRadius: "7px",
                        }}
                        className="p-5 d-flex align-items-center"
                      >
                        <Select
                          onDropdownVisibleChange={(e, _) => {
                            if (!e) {
                              setSelectedOperation((pre) => {
                                pre === "MP_ITEM"
                                  ? setIsOpenVisibleToModal(true)
                                  : setIsOpenVisibleToModal(false);
                                return pre;
                              });
                            }
                          }}
                          onChange={(e) => {
                            setSelectedOperation(e);
                            setVisibleToOption(null);
                          }}
                          getPopupContainer={(triggerNode) =>
                            triggerNode.parentNode
                          }
                          value={selectedOperation || null}
                          options={[
                            {
                              label: "Create / Update Item",
                              value: "MP_ITEM",
                            },
                            {
                              label: "Price",
                              value: "price",
                            },
                            { label: "Inventory", value: "inventory" },
                            // { label: "Item Variants", value: "item-variants" },
                            // { label: "Item Swatches", value: "item-swatches" },
                            // { label: "Item SKU", value: "item-sku" },
                            // { label: "Item Product ID", value: "item-iroduct-id" },
                            { label: "Item Match", value: "MP_ITEM_MATCH" },
                            {
                              label: "Refund Overrides",
                              value: "RETURNS_OVERRIDES",
                            },
                            // { label: "Secondary Image", value: "secondary-image" },
                          ]}
                          placeholder="Select Operation"
                          size="large"
                          style={{ width: "100%" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {isOpenVisibleToModal && (
                  <div className="col-12 col-md-3 col-lg-3">
                    <div className="row">
                      <div className="col-12 mb-1">
                        <label className="text-primary fw-bold fs-6">
                          Step 2
                        </label>
                      </div>
                      <div className="col-12">
                        <div
                          style={{
                            height: "100%",
                            border: "1px dashed #4318ff",
                            background: "#f2f0ff",
                            borderRadius: "7px",
                          }}
                          className="p-5 d-flex align-items-center justify-content-center"
                        >
                          <Select
                            placeholder="Select Visible to"
                            options={allCategoryList?.map((d) => {
                              return {
                                label: d,
                                value: d,
                                disabled:
                                  d === "Animal Accessories" ||
                                  d === "Home Decor, Kitchen, & Other" ||
                                  d === "Electronics Other"
                                    ? false
                                    : true,
                              };
                            })}
                            value={visibleToOption}
                            onChange={(e) => {
                              setVisibleToOption(e);
                            }}
                            size="large"
                            style={{ width: "100%" }}
                            // className="w-350px"
                            getPopupContainer={(triggerNode) =>
                              triggerNode.parentNode
                            }
                          />
                        </div>
                      </div>{" "}
                    </div>
                  </div>
                )}

                <div
                  className={`${
                    isOpenVisibleToModal
                      ? "col-12 col-md-3 col-lg-5 my-4 my-md-0"
                      : "col-12 col-md-6 col-lg-8 "
                  }`}
                >
                  {" "}
                  <div className="row">
                    <div className="col-12 mb-1">
                      <label className="text-primary fw-bold fs-6">
                        {isOpenVisibleToModal ? "Step 3" : "Step 2"}
                      </label>
                    </div>
                    <div className="col-12">
                      <div
                        style={{ height: "80px" }}
                        onClick={() => {
                          if (!selectedOperation) {
                            message.destroy();
                            message.error("Please select operation");
                          }
                        }}
                      >
                        <Dragger
                          disabled={
                            !selectedOperation ||
                            (selectedOperation === "MP_ITEM" &&
                              !visibleToOption) ||
                            isUploading
                          }
                          style={{
                            borderColor: isUploading ? "#008000" : "#f50",
                            background: isUploading ? "#ebf5eb" : "#f5ebea",
                          }}
                          customRequest={customUpload}
                          beforeUpload={beforeUpload}
                        >
                          <div className="d-flex align-items-center justify-content-between">
                            <div className="text-start">
                              <p className="ant-upload-text fs-4 fw-bolder mb-0">
                                Drop files here or click to upload xlsx.
                              </p>
                              {visibleToOption}
                            </div>
                            {isUploading ? (
                              <LoadingOutlined
                                style={{ color: "#008000", fontSize: "28px" }}
                              />
                            ) : (
                              <i className="bi bi-file-earmark-arrow-up fs-3x" />
                            )}
                          </div>
                        </Dragger>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-3 col-lg-2">
                  <div className="row">
                    <div className="col-12 mb-1">
                      <label className="text-primary fw-bold fs-6">
                        {/* {isOpenVisibleToModal ? "Step 4" : "Step 3"} */}
                      </label>
                    </div>
                    <div className="col-12 d-flex">
                      <div
                        className="me-5"
                        style={{ borderLeft: "1px solid blue" }}
                      ></div>
                      <div
                        style={{
                          height: "80px",
                          border: "1px dashed #4318ff",
                          background: "#f2f0ff",
                          borderRadius: "7px",
                          textAlign: "center",
                        }}
                        className="p-5 d-flex align-items-center justify-content-center"
                      >
                        <label
                          className="fs-5 fw-bold cursor-pointer text-primary"
                          style={{ textDecoration: "underline" }}
                          onClick={() => {
                            message.destroy();
                            message.loading("Loading...", 0);
                            if (!selectedOperation) {
                              message.destroy();
                              message.info("Please select operation");
                            } else if (
                              selectedOperation === "MP_ITEM" &&
                              !visibleToOption
                            ) {
                              message.destroy();
                              message.info("Please select visible to");
                            } else if (
                              selectedOperation === "MP_ITEM" &&
                              visibleToOption
                            ) {
                              ItemActivityBulkUploadSampleTemplate(
                                selectedOperation,
                                visibleToOption
                              );
                            } else {
                              ItemActivityBulkUploadSampleTemplate(
                                selectedOperation
                              );
                            }
                          }}
                        >
                          Download Sample Template
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mb-5">
                <div className="col-12 d-flex">
                  <label className="text-danger fw-bolder fs-4 me-2">
                    Note:
                  </label>
                  <label className="text-danger fs-4">
                    As a Next step you can download the template first and fill
                    the data or can upload the file directly.
                  </label>
                </div>
              </div>
              {/* <div className="row mb-5">
                <div className="col-12 col-md-3 col-lg-2">
                  <div
                    style={{
                      height: "100%",
                      border: "1px dashed #168c1d",
                      background: "#ebf5eb",
                      borderRadius: "7px",
                    }}
                    className="p-5 d-flex align-items-center"
                  >
                    <Select
                      onDropdownVisibleChange={(e, _) => {
                        if (!e) {
                          setSelectedOperation((pre) => {
                            pre === "MP_ITEM" && setIsOpenVisibleToModal(true);
                            return pre;
                          });
                        }
                      }}
                      onChange={(e) => {
                        setSelectedOperation(e);
                        setVisibleToOption("");
                      }}
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentNode
                      }
                      value={selectedOperation || null}
                      options={[
                        {
                          label: "Create / Update Item",
                          value: "MP_ITEM",
                        },
                        {
                          label: "Price",
                          value: "price",
                        },
                        { label: "Inventory", value: "inventory" },
                        // { label: "Item Variants", value: "item-variants" },
                        // { label: "Item Swatches", value: "item-swatches" },
                        // { label: "Item SKU", value: "item-sku" },
                        // { label: "Item Product ID", value: "item-iroduct-id" },
                        { label: "Item Match", value: "MP_ITEM_MATCH" },
                        {
                          label: "Refund Overrides",
                          value: "RETURNS_OVERRIDES",
                        },
                        // { label: "Secondary Image", value: "secondary-image" },
                      ]}
                      placeholder="Select Operation"
                      size="large"
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-8 my-4 my-md-0">
                  <div
                    style={{ height: "80px" }}
                    onClick={() => {
                      if (!selectedOperation) {
                        message.destroy();
                        message.error("Please select operation");
                      }
                    }}
                  >
                    <Dragger
                      disabled={
                        !selectedOperation ||
                        (selectedOperation === "MP_ITEM" && !visibleToOption) ||
                        isUploading
                      }
                      style={{
                        borderColor: isUploading ? "#008000" : "#f50",
                        background: isUploading ? "#ebf5eb" : "#f5ebea",
                      }}
                      customRequest={customUpload}
                      beforeUpload={beforeUpload}
                    >
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="text-start">
                          <p className="ant-upload-text fs-4 fw-bolder mb-0">
                            Drop files here or click to upload xlsx.
                          </p>{" "}
                          {visibleToOption}
                        </div>
                        {isUploading ? (
                          <LoadingOutlined
                            style={{ color: "#008000", fontSize: "28px" }}
                          />
                        ) : (
                          <i className="bi bi-file-earmark-arrow-up fs-3x" />
                        )}
                      </div>
                    </Dragger>
                  </div>
                </div>
                <div className="col-12 col-md-3 col-lg-2">
                  <div
                    style={{
                      height: "100%",
                      border: "1px dashed #4318ff",
                      background: "#f2f0ff",
                      borderRadius: "7px",
                      textAlign: "center",
                    }}
                    className="p-5 d-flex align-items-center justify-content-center"
                  >
                    <label
                      className="fs-5 fw-bold cursor-pointer text-primary"
                      style={{ textDecoration: "underline" }}
                      onClick={() => {
                        message.destroy();
                        message.loading("Loading...", 0);
                        if (!selectedOperation) {
                          message.destroy();
                          message.info("Please select operation");
                        } else if (selectedOperation === "MP_ITEM") {
                          ItemActivityBulkUploadSampleTemplate(
                            selectedOperation,
                            visibleToOption
                          );
                        } else {
                          ItemActivityBulkUploadSampleTemplate(
                            selectedOperation
                          );
                        }
                      }}
                    >
                      Download Sample Template
                    </label>
                  </div>
                </div>
              </div> */}
              {showChildListDiv ? (
                <div className="row fadeOutBottom" id="child_list_div">
                  <div className="col-xxl-12">
                    <div className="card card-xxl-stretch mb-5 mb-xl-8">
                      <div className="card-header border-bottom-dashed">
                        <h3 className="card-title ">
                          <span className="card-label fw-bold text-gray-900">
                            <lable>{selectedRowForChildList?.file_name}</lable>
                            <label className="ms-2">{`(Api log id - ${selectedRowForChildList?.wm_api_log_id})`}</label>
                          </span>
                        </h3>
                        <div className="card-toolbar">
                          <div
                            className="position-relative mr-4 cursor-pointer"
                            onClick={() => setshowChildListDiv(false)}
                          >
                            <i className="ki-outline ki-cross-square fs-1 text-danger" />
                          </div>
                        </div>
                      </div>

                      <div className="card-body pb-5 pt-5">
                        <IvcsrTable
                          columns={subListColumns}
                          fixed={true}
                          sticky={{
                            offsetHeader: "10px",
                          }}
                          dataSource={data}
                          rowKey="key"
                          loading={loading}
                          pagination={false}
                          scroll={{
                            x: "max-content",
                            y: "calc(100vh - 300px)",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className="row gy-5 g-xl-5 fadeInRightFast"
                  id="parent_list_div"
                >
                  <div className="col-xxl-12">
                    <div className="card card-xxl-stretch mb-5 mb-xl-8">
                      <div className="card-header border-bottom-dashed">
                        <h3 className="card-title ">
                          <span className="card-label fw-bold text-gray-900">
                            Item Bulk Operation
                          </span>
                        </h3>

                        <div className="card-toolbar">
                          <div className="position-relative mr-4">
                            <Select
                              value={typeFilter}
                              onChange={(e) => {
                                setTypeFilter(e);
                                setLoading(true);
                                setPage(1);
                                const obj = {
                                  page: 1,
                                  perPage: pageSize,
                                  feed_type: e,
                                };
                                GetItemBulkOperation(obj);
                              }}
                              placeholder="Select Type"
                              size="large"
                              className="w-200px"
                              allowClear
                              options={[
                                {
                                  label: "Create / Update Item",
                                  value: "MP_ITEM",
                                },
                                {
                                  label: "Price",
                                  value: "price",
                                },
                                { label: "Inventory", value: "inventory" },
                                // { label: "Item Variants", value: "item-variants" },
                                // { label: "Item Swatches", value: "item-swatches" },
                                // { label: "Item SKU", value: "item-sku" },
                                // { label: "Item Product ID", value: "item-iroduct-id" },
                                { label: "Item Match", value: "MP_ITEM_MATCH" },
                                {
                                  label: "Refund Overrides",
                                  value: "RETURNS_OVERRIDES",
                                },
                              ]}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="card-body pb-5 pt-0">
                        <div className="table-responsive mt-5">
                          {loading ? (
                            <Loading />
                          ) : list?.length !== 0 ? (
                            <>
                              <IvcsrTable
                                columns={columns?.map((d) => ({
                                  ...d,
                                  width:
                                    d?.title === "File Name"
                                      ? 400
                                      : d?.title?.length * 13,
                                }))}
                                fixed={true}
                                sticky={{
                                  offsetHeader: "10px",
                                }}
                                dataSource={list}
                                rowKey="key"
                                loading={loading}
                                pagination={false}
                                scroll={{
                                  x: "max-content",
                                  y: "calc(100vh - 360px)",
                                }}
                              />
                            </>
                          ) : (
                            <NoData type="new" />
                          )}
                        </div>
                        <Pagination
                          loading={loading || list?.length === 0}
                          pageSize={pageSize}
                          page={page}
                          totalPage={totalPage}
                          onPerPage={onPerPage}
                          onPageNo={onPageNo}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
      {/* {isOpenVisibleToModal && (
        <VisibleToModal
          show={isOpenVisibleToModal}
          onHide={() => {
            setIsOpenVisibleToModal(false);
            setSelectedOperation(null);
            setVisibleToOption("");
          }}
          setOption={(option) => {
            setVisibleToOption(option);
            setIsOpenVisibleToModal(false);
          }}
          {...props}
        />
      )} */}

      {isOpenErrorModal && (
        <ErrorLogModal
          show={isOpenErrorModal}
          onHide={() => {
            setIsOpenErrorModal(false);
            setWmRequestedData([]);
            setWmResponseData([]);
            setSelectedWalmartStatus("");
            setWalmartStatusError([]);
          }}
          requestedJson={wmRequestedData}
          responseJson={wmResponseData}
          errorList={walmartStatusError}
          walmartStatus={selectedWalmartStatus}
        />
      )}
      {isOpenErrorMsgModal && (
        <ErrorMessageModal
          show={isOpenErrorMsgModal}
          onHide={() => {
            setIsOpenErrorMsgModal(false);
            setErrorModalMessage([]);
          }}
          errorModalMessage={errorModalMessage}
        />
      )}
    </div>
  );
}
