import React from "react";
import { Skeleton } from "antd";
import { NumberWithCommas } from "../../../../config";

export default function (props) {
  const { salesAnalyticsCardData, cardLoading } = props;
  return (
    <>
      <div className="row g-5 g-xl-7 mb-5 mb-xl-10">
        <div className="col-xl-3">
          {cardLoading ? (
            <Skeleton.Button style={{ height: "100px" }} block active />
          ) : (
            <div
              className="card card-flush bgi-no-repeat bgi-size-cover bgi-position-x-end h-xl-100"
              style={{
                backgroundColor: "#f1577d",
                backgroundImage:
                  'url("assets/media/svg/shapes/wave-bg-light.svg")',
              }}
            >
              <div className="card-body py-4">
                <div className=" d-flex align-items-center justify-content-between">
                  <div
                    className="d-flex flex-center rounded-circle h-60px w-60px"
                    style={{
                      border: "1px dashed rgba(255, 255, 255, 0.5)",
                      backgroundColor: "#ffffff2e",
                    }}
                  >
                    <i className="ki-duotone ki-chart-simple-3 text-white fs-3x lh-0">
                      <span className="path1" />
                      <span className="path2" />
                      <span className="path3" />
                      <span className="path4" />
                      <span className="path5" />
                      <span className="path6" />
                      <span className="path7" />
                    </i>
                  </div>

                  <div className=" text-end">
                    <span className="fs-1-5 text-white fw-bold">
                      <small className="fs-2 me-1">$</small>
                      {NumberWithCommas(
                        salesAnalyticsCardData?.total_sales || 0
                      )}
                    </span>
                    <div className="fw-bold fs-6 text-white ">
                      <span className="d-block">Total Sales</span>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="card-footer py-2"
                style={{
                  borderTop: "1px solid rgba(255, 255, 255, 0.3)",
                  background: "rgba(255, 255, 255, 0.15)",
                }}
              ></div>
            </div>
          )}
        </div>

        <div className="col-xl-3">
          {cardLoading ? (
            <Skeleton.Button style={{ height: "100px" }} block active />
          ) : (
            <div
              className="card card-flush bgi-no-repeat bgi-size-cover bgi-position-x-end h-xl-100"
              style={{
                backgroundColor: "#7f68ff",
                backgroundImage:
                  'url("assets/media/svg/shapes/wave-bg-light.svg")',
              }}
            >
              <div className="card-body py-4">
                <div className=" d-flex align-items-center justify-content-between">
                  <div
                    className="d-flex flex-center rounded-circle h-60px w-60px"
                    style={{
                      border: "1px dashed rgba(255, 255, 255, 0.5)",
                      backgroundColor: "#ffffff2e",
                    }}
                  >
                    <i className="ki-duotone ki-tag text-white fs-3x lh-0">
                      <span className="path1" />
                      <span className="path2" />
                      <span className="path3" />
                    </i>
                  </div>

                  <div className=" text-end">
                    <span className="fs-1-5 text-white fw-bold">
                      <small className="fs-2 me-1">$</small>{" "}
                      {NumberWithCommas(
                        salesAnalyticsCardData?.net_profit || 0
                      )}
                    </span>
                    <div className="fw-bold fs-6 text-white ">
                      <span className="d-block">Net Profit</span>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="card-footer py-2"
                style={{
                  borderTop: "1px solid rgba(255, 255, 255, 0.3)",
                  background: "rgba(0, 0, 0, 0.15)",
                }}
              ></div>
            </div>
          )}
        </div>

        <div className="col-xl-3">
          {cardLoading ? (
            <Skeleton.Button style={{ height: "100px" }} block active />
          ) : (
            <div
              className="card card-flush bgi-no-repeat bgi-size-cover bgi-position-x-end h-xl-100"
              style={{
                backgroundColor: "#4caf50",
                backgroundImage:
                  'url("assets/media/svg/shapes/wave-bg-light.svg")',
              }}
            >
              <div className="card-body py-4">
                <div className=" d-flex align-items-center justify-content-between">
                  <div
                    className="d-flex flex-center rounded-circle h-60px w-60px"
                    style={{
                      border: "1px dashed rgba(255, 255, 255, 0.5)",
                      backgroundColor: "#ffffff2e",
                    }}
                  >
                    <i className="ki-duotone ki-percentage text-white fs-3x lh-0">
                      <span className="path1" />
                      <span className="path2" />
                    </i>
                  </div>

                  <div className=" text-end">
                    <span className="fs-1-5 text-white fw-bold">
                      {salesAnalyticsCardData?.profit_margin || 0}%
                    </span>
                    <div className="fw-bold fs-6 text-white ">
                      <span className="d-block">Profit Margin</span>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="card-footer py-2"
                style={{
                  borderTop: "1px solid rgba(255, 255, 255, 0.3)",
                  background: "rgba(0, 0, 0, 0.15)",
                }}
              ></div>
            </div>
          )}
        </div>

        <div className="col-xl-3">
          {cardLoading ? (
            <Skeleton.Button style={{ height: "100px" }} block active />
          ) : (
            <div
              className="card card-flush bgi-no-repeat bgi-size-cover bgi-position-x-end h-xl-100"
              style={{
                backgroundColor: "#c89851",
                backgroundImage:
                  'url("assets/media/svg/shapes/wave-bg-light.svg")',
              }}
            >
              <div className="card-body py-4">
                <div className=" d-flex align-items-center justify-content-between">
                  <div
                    className="d-flex flex-center rounded-circle h-60px w-60px"
                    style={{
                      border: "1px dashed rgba(255, 255, 255, 0.5)",
                      backgroundColor: "#ffffff2e",
                    }}
                  >
                    <i className="ki-duotone ki-delivery-3 text-white fs-3x lh-0">
                      <span className="path1" />
                      <span className="path2" />
                      <span className="path3" />
                    </i>
                  </div>

                  <div className=" text-end">
                    <span className="fs-1-5 text-white fw-bold">
                      {NumberWithCommas(
                        salesAnalyticsCardData?.total_units || 0
                      )}
                    </span>
                    <div className="fw-bold fs-6 text-white ">
                      <span className="d-block">Total Units Sold</span>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="card-footer py-2"
                style={{
                  borderTop: "1px solid rgba(255, 255, 255, 0.3)",
                  background: "rgba(0, 0, 0, 0.15)",
                }}
              ></div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
