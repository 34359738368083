import React, { useEffect, useRef, useState } from "react";
import Chart from "react-apexcharts";
import { NumberWithCommas } from "../../../../config";
import { Skeleton, Tooltip, message } from "antd";
import { FilePdfOutlined, FileExcelOutlined } from "@ant-design/icons";
import { savePDF } from "@progress/kendo-react-pdf";
import writeXlsxFile from "write-excel-file";
const PerformanceBarChart = (props) => {
  const {
    seriesLabel,
    seriesData,
    chartLoading,
    fullSeriesObj,
    selectedPerformance,
    sum,
  } = props;
  var style = getComputedStyle(document.body);
  const [barSeries, setBarSeries] = useState([]);
  const chartRef = useRef(null);
  useEffect(() => {
    if (seriesData?.length > 0 && seriesLabel?.length > 0) {
      setBarSeries([{ name: "Impression", data: seriesData }]);
    }
  }, [seriesData, seriesLabel]);

  const options = {
    chart: {
      type: "bar",
      toolbar: { show: false },
      zoom: {
        enabled: false,
      },
    },

    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        // fontSize: "12px", // Adjust font size if needed
        colors: ["#fff"], // Adjust label color if needed
      },
      formatter: function (val) {
        return val + "%";
      },
    },
    xaxis: {
      categories: seriesLabel?.map((d) => {
        return d;
      }),
      labels: {
        style: {
          colors: style.getPropertyValue("--bs-gray-700"),
          fontWeight: "bold",
          fontSize: "12px",
          screenLeft: "0px",
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: style.getPropertyValue("--bs-gray-700"),
          fontWeight: "bold",
          fontSize: "12px",
          screenLeft: "0px",
        },
      },
      max: 100,
    },
    colors: ["#555abc"],
    tooltip: {
      marker: {
        show: true,
      },
      x: {
        show: true,
      },
      y: {
        formatter: function (
          value,
          { series, seriesIndex, dataPointIndex, w }
        ) {
          console.log(
            series,
            seriesIndex,
            dataPointIndex,
            w,
            "series, seriesIndex, dataPointIndex, w "
          );
          const withTitle_ = w?.config?.series?.[seriesIndex];
          // console.log(withTitle_, "withTitle_");
          return NumberWithCommas(value) + "%";
        },
      },
    },
    noData: {
      text: "No Data",
      align: "center",
      verticalAlign: "middle",
      style: {
        fontSize: "20px",
      },
    },
  };

  const handleExportPDF = () => {
    savePDF(chartRef.current, { paperSize: "auto", fileName: "chart.pdf" });
  };

  const exportHeaderAmazon = [
    { value: "Name", type: String },
    { value: "Impression", type: String },
  ];

  const downloadXlAmazon = async () => {
    let dataRow = [];
    await fullSeriesObj.map((d) => {
      const total = (d?.impression * 100) / sum;
      const newList = [d?.name, NumberWithCommas(total)];
      dataRow = [
        ...dataRow,
        newList.map((d) => {
          return { value: d };
        }),
      ];
    });
    const data = [exportHeaderAmazon, ...dataRow];
    await writeXlsxFile(data, {
      fileName: `${selectedPerformance}-performance.xlsx`,
    });
    message.destroy();
  };

  return (
    <>
      <div className="d-flex justify-content-end mt-2">
        {chartLoading ? (
          <Skeleton.Button active className="me-2" />
        ) : (
          <span
            className="d-flex align-items-center cursor-pointer me-5"
            onClick={() => {
              handleExportPDF();
            }}
          >
            <Tooltip title="Download PDF" placement="bottom">
              <FilePdfOutlined className="fs-1" style={{ color: "red" }} />
            </Tooltip>
          </span>
        )}

        {chartLoading ? (
          <Skeleton.Button active />
        ) : (
          <span
            className="d-flex align-items-center cursor-pointer"
            onClick={() => {
              downloadXlAmazon();
            }}
          >
            <Tooltip title="Download Excel" placement="bottom">
              <FileExcelOutlined className="fs-1" style={{ color: "blue" }} />
            </Tooltip>
          </span>
        )}
      </div>
      <div>
        {chartLoading ? (
          <div className="d-flex justify-content-center my-5">
            <Skeleton.Node active />
          </div>
        ) : (
          <div id="chartZoom" className="w-100 mt-0" ref={chartRef}>
            <Chart
              options={options}
              series={barSeries}
              type="bar"
              height={350}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default PerformanceBarChart;
