import axiosCall from "../../../configurations/network-services/axiosCall";

const prefix = "inventory";

export const GetInventoryManagementList = (data) => {
  const path = `${prefix}/get-replenishment?marketplace_id=${
    data?.marketplace_id
  }&search=${data?.searchKey || ""}&days_for_the_forecast=${
    data?.forecast_days || ""
  }&supplier_id=${data?.supplier_id || ""}&page=${data?.page || 1}&per-page=${
    data?.perPage || 10
  }&isExport=${data?.isExport || 0}`;
  const responseType = "GET_INVENTORY_MANAGEMENT_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetSupplierList = () => {
  const path = `all-suppliers`;
  const responseType = "GET_SUPPLIER_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetReplenishmentWarehouseListAction = (data) => {
  const path = `inventory/warehouse-stock?sku_data_id=${
    data?.sku_data_id || ""
  }`;
  const responseType = "GET_REPLENISHMENT_WAREHOUSE_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetReplenishmentModalData = (data) => {
  const path = `inventory/replenishment-setting?sku=${data?.sku}&asin=${data?.asin}&marketplace_id=${data?.marketplace_id}`;
  const responseType = "GET_REPLENISHMENT_MODAL_DATA";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const SaveReplenishmentModalData = (data) => {
  const path = `inventory/replenishment-setting`;
  const responseType = "SAVE_REPLENISHMENT_MODAL_DATA";
  return axiosCall("post", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const AddEditWarehouseDetailsAction = (data) => {
  const path = `inventory/warehouse-stock`;
  const responseType = "ADD_EDIT_REPLENISHMENT_WAREHOUSE_DETAILS";
  return axiosCall("post", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const DeleteWarehouseAction = (id) => {
  const path = `inventory/warehouse-stock/${id}`;
  const responseType = "DELETE_REPLENISHMENT_WAREHOUSE_DETAILS";
  return axiosCall("Delete", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetReplenishmentForecastAction = (data) => {
  const path = `inventory/replenishment-forecast?sku=${data?.sku || ""}&asin=${
    data?.asin || ""
  }&marketplace_id=${data?.marketplace_id || ""}`;
  const responseType = "GET_REPLENISHMENT_FORECAST_DATA";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const SaveBulkInventoryAction = (data) => {
  const path = `inventory/replenishment-bulk-import`;
  const responseType = "SAVE_BULK_INVENTORY";
  return axiosCall("post", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const ImportWarehouseStock = (data) => {
  const formData = new FormData();
  formData.append("warehouse_stock_file", data?.warehouse_stock_file);
  formData.append("marketplace_id", data?.marketplace_id);
  formData.append("asin", data?.asin);
  formData.append("sku", data?.sku);
  const path = `inventory/warehouse-stock-import`;
  const responseType = "IMPORT_WAREHOUSE_STOCK_DATA";
  return axiosCall("post", path, responseType, formData, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const fakeActionInventoryManagement = (data) => async (dispatch) => {
  dispatch({ type: "FAKE_ACTION_INVENTORY_MANAGEMENT", state: data });
};
