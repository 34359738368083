import styled from "styled-components";

export const RuleDetailsModal = styled.div`
  .ant-collapse-content {
    background-color: #f0eff9;
  }
  .ant-collapse-item {
    border-bottom: none !important;
  }
`;
