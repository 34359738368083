import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  DefaultPerPage,
  NumberWithCommas,
  SalesChannelFlag,
} from "../../../../config";
import NoData from "../../../../component/no-data";
import Pagination from "../../../../component/pagination";
import {
  Checkbox,
  Dropdown,
  Input,
  Popover,
  Select,
  Tag,
  Tooltip,
  message,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Loading from "../../../../component/loading";
import EditReplenishment from "./lib/edit-replenishment";
import BulkSupplierModal from "./lib/bulk-supplier-modal";
import BulkDaysOfStockModal from "./lib/bulk-days-of-stock-modal";
import BulkLeadTimeModal from "./lib/bulk-lead-time-modal";
import ForecastModal from "./lib/forecast-modal";
import writeXlsxFile from "write-excel-file";

export default function (props) {
  const {
    GetInventoryManagementList,
    GetReplenishmentModalData,
    GetSupplierList,
    fakeActionInventoryManagement,
    GetSalesChannelList,
    fakeActionSalesAnalytics,
  } = props;

  const GetInventoryManagementListRes = useSelector(
    (state) =>
      state.InventoryManagement.GetInventoryManagementListResponse || {}
  );
  const GetSupplierListResponse = useSelector(
    (state) => state.InventoryManagement.GetSupplierListResponse || {}
  );
  const GetReplenishmentModalDataRes = useSelector(
    (state) => state.InventoryManagement.GetReplenishmentModalDataResponse || {}
  );
  const GetSalesChannelListRes = useSelector(
    (state) => state.SalesAnalytics.GetSalesChannelListResponse || {}
  );
  const SaveReplenishmentModalDataRes = useSelector(
    (state) =>
      state.InventoryManagement.SaveReplenishmentModalDataResponse || {}
  );

  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageSize, setPageSize] = useState(DefaultPerPage);
  const [searchTerm, setSearchTerm] = useState("");
  const [supplierList, setSupplierList] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState([]);
  const [selectedCheckbox, setSelectedCheckbox] = useState([]);

  const [salesChannelList, setsalesChannelList] = useState([]);
  const [selectedSalesChannel, setSelectedSalesChannel] = useState([]);
  const [salesChannelLoading, setSalesChannelLoading] = useState(true);
  const [selectedCurrencySign, setSelectedCurrencySign] = useState("");
  const [replenishmentData, setReplenishmentData] = useState({});
  const [selectedRowAsinSku, setSelectedRowAsinSku] = useState({
    asin: "",
    sku: "",
  });
  const [open_edit_replenishment_modal, set_open_edit_replenishment_modal] =
    useState(false);

  const [openSupplierModal, setOpenSupplierModal] = useState(false);
  const [openDaysOfStockModal, setOpenDaysOfStockModal] = useState(false);
  const [openLeadTimeModal, setOpenLeadTimeModal] = useState(false);
  const [noOfDaysToReplenish, setNoOfDaysToReplenish] = useState("");
  const [isOpenForecastModal, setIsOpenForecastModal] = useState(false);
  const [isExportFlag, setIsExportFlag] = useState(false);

  useEffect(() => {
    GetSalesChannelList();
    GetSupplierList();
  }, []);

  useEffect(() => {
    if (GetSalesChannelListRes?.status === true) {
      setSalesChannelLoading(false);
      setsalesChannelList(GetSalesChannelListRes?.data?.sales_channel);
      setSelectedSalesChannel("ATVPDKIKX0DER");
      setSelectedCurrencySign("$");
      const apiObj = {
        page: page,
        perPage: pageSize,
        searchKey: searchTerm,
        marketplace_id: "ATVPDKIKX0DER",
        supplier_id: selectedSupplier,
        forecast_days: 7,
      };
      GetInventoryManagementList(apiObj);
      fakeActionSalesAnalytics("GetSalesChannelListResponse");
    } else if (GetSalesChannelListRes?.status === false) {
      fakeActionSalesAnalytics("GetSalesChannelListResponse");
    }
  }, [GetSalesChannelListRes]);

  useEffect(() => {
    if (GetSupplierListResponse?.status === true) {
      setSupplierList(GetSupplierListResponse?.data);
      fakeActionInventoryManagement("GetSupplierListResponse");
    } else if (GetSupplierListResponse?.status === false) {
      fakeActionInventoryManagement("GetSupplierListResponse");
    }
  }, [GetSupplierListResponse]);

  console.log(selectedCheckbox, "selectedCheckbox");

  useEffect(() => {
    if (GetInventoryManagementListRes?.status === true) {
      if (isExportFlag) {
        setIsExportFlag(false);
        downloadXl(GetInventoryManagementListRes?.data?.records);
      } else {
        console.log(
          GetInventoryManagementListRes,
          "GetInventoryManagementListRes"
        );
        setTotalPage(
          GetInventoryManagementListRes?.data?.pagination?.totalCount
        );
        setLoading(false);
        setList(GetInventoryManagementListRes?.data?.records);
      }

      fakeActionInventoryManagement("GetInventoryManagementListResponse");
    } else if (GetInventoryManagementListRes?.status === false) {
      setLoading(false);
      fakeActionInventoryManagement("GetInventoryManagementListResponse");
    }
  }, [GetInventoryManagementListRes]);

  useEffect(() => {
    if (GetReplenishmentModalDataRes?.status === true) {
      setReplenishmentData(GetReplenishmentModalDataRes?.data);
      set_open_edit_replenishment_modal(true);
      fakeActionInventoryManagement("GetReplenishmentModalDataResponse");
    } else if (GetReplenishmentModalDataRes?.status === false) {
      message.destroy();
      message.error(GetReplenishmentModalDataRes?.message);
      fakeActionInventoryManagement("GetReplenishmentModalDataResponse");
    }
  }, [GetReplenishmentModalDataRes]);

  useEffect(() => {
    if (SaveReplenishmentModalDataRes?.status === true) {
      message.destroy();
      message.success(SaveReplenishmentModalDataRes?.message);
      setSelectedRowAsinSku({ asin: "", sku: "" });
      setReplenishmentData({});
      set_open_edit_replenishment_modal(false);
      setLoading(true);
      setPage(1);
      setPageSize(DefaultPerPage);
      const apiObj = {
        page: 1,
        perPage: DefaultPerPage,
        searchKey: searchTerm,
        marketplace_id: selectedSalesChannel,
        supplier_id: selectedSupplier,
        forecast_days: noOfDaysToReplenish,
        isExport: 0,
      };
      GetInventoryManagementList(apiObj);
      fakeActionInventoryManagement("SaveReplenishmentModalDataResponse");
    } else if (SaveReplenishmentModalDataRes?.status === false) {
      message.destroy();
      message.error(SaveReplenishmentModalDataRes?.message);
      fakeActionInventoryManagement("SaveReplenishmentModalDataResponse");
    }
  }, [SaveReplenishmentModalDataRes]);

  const onPageNo = (e) => {
    setLoading(true);
    const apiObj = {
      page: e,
      perPage: pageSize,
      searchKey: searchTerm,
      marketplace_id: selectedSalesChannel,
      supplier_id: selectedSupplier,
      forecast_days: noOfDaysToReplenish,
      isExport: 0,
    };
    GetInventoryManagementList(apiObj);
    setPage(e);
  };

  const onPerPage = (e) => {
    setPage(1);
    setPageSize(e);
    setLoading(true);
    const apiObj = {
      page: 1,
      perPage: e,
      searchKey: searchTerm,
      marketplace_id: selectedSalesChannel,
      supplier_id: selectedSupplier,
      forecast_days: noOfDaysToReplenish,
      isExport: 0,
    };
    GetInventoryManagementList(apiObj);
  };

  const exportHeader = [
    { value: "Product Title", type: String },
    { value: "ASIN", type: String },
    { value: "SKU", type: String },
    { value: "Forecast Period", type: String },
    { value: "Supplier Name", type: String },
    { value: "Available Inventory", type: String },
    { value: "Reserved Inventory", type: String },
    { value: "Total Inventory", type: String },
    { value: "Unit Sold", type: String },
    { value: "Total Fees", type: String },
    { value: "SKU Available Days", type: String },
    { value: "Sales Velocity", type: String },
    { value: "Total Lead Time", type: String },
    { value: "Units Based on the Lead Time", type: String },
    { value: "Days of Stock Left", type: String },
    { value: "Target Days of Stock", type: String },
    { value: "Target Units for Days of Stock", type: String },
    { value: "Recommended Quantity for Reordering", type: String },
  ];

  const downloadXl = async (productData) => {
    let dataRow = [];
    await productData.map((d) => {
      const newList = [
        d?.title,
        d?.asin,
        d?.sku,
        d?.number_of_days_to_replenish,
        d?.supplier?.supplier_name,
        d?.available_inventory,
        d?.reserved_inventory,
        d?.total_inventory,
        d?.unit_sold,
        d?.total_fees,
        d?.days,
        d?.sales_velocity,
        d?.total_lead_time,
        d?.units_based_on_the_lead_time,
        d?.days_of_stock_left,
        d?.target_days_of_stock,
        d?.target_units_for_the_target_days_of_stock,
        d?.recommended_quantity_for_reordering > 0
          ? "No Restock Needed"
          : Math.round(
              d?.recommended_quantity_for_reordering?.replace("-", "")
            ) || 0,
      ];
      dataRow = [
        ...dataRow,
        newList.map((d) => {
          return { value: d };
        }),
      ];
    });
    const data = [exportHeader, ...dataRow];
    await writeXlsxFile(data, {
      fileName: "Amzon Inventory Replenishment.xlsx",
    });
    message.destroy();
  };

  const items = [
    {
      label: (
        <span
          onClick={() => {
            setOpenSupplierModal(true);
          }}
        >
          Set Supplier
        </span>
      ),
      key: "1",
    },
    {
      label: (
        <span
          onClick={() => {
            setOpenDaysOfStockModal(true);
          }}
        >
          Add Days Of Stock
        </span>
      ),
      key: "2",
    },
    {
      label: (
        <span
          onClick={() => {
            setOpenLeadTimeModal(true);
          }}
        >
          Add Lead Time
        </span>
      ),
      key: "3",
    },
  ];

  return (
    <>
      <div className="fadeInRight">
        <div className="d-flex flex-column flex-column-fluid">
          <div id="kt_app_content" className="app-content flex-column-fluid">
            <div
              id="kt_app_content_container"
              className="app-container container-fluid"
            >
              <div className="row gy-5 g-xl-5">
                {/*begin::Col*/}
                <div className="col-xxl-12">
                  {/*begin::Tables Widget 9*/}
                  <div className="card card-xxl-stretch mb-5 mb-xl-8">
                    {/*begin::Header*/}
                    <div className="card-header border-bottom-dashed">
                      <div className="d-flex flex-stack flex-wrap gap-2">
                        <div className="position-relative">
                          <Input
                            type="text"
                            className="text-dark w-250px fs-7 fw-bold ant_common_input"
                            name="all_search"
                            placeholder="Search by ASIN or SKU..."
                            value={searchTerm}
                            onChange={(e) => {
                              setSearchTerm(e.target.value);
                            }}
                            onPressEnter={() => {
                              setLoading(true);
                              const apiObj = {
                                page: page,
                                perPage: pageSize,
                                searchKey: searchTerm,
                                marketplace_id: selectedSalesChannel,
                                supplier_id: selectedSupplier,
                                forecast_days: noOfDaysToReplenish,
                                isExport: 0,
                              };
                              GetInventoryManagementList(apiObj);
                            }}
                            prefix={
                              <SearchOutlined
                                style={{ fontSize: "19px", color: "grey" }}
                              />
                            }
                            size="large"
                          />
                        </div>
                      </div>
                      <div className="card-toolbar gap-2">
                        <div className="">
                          <Select
                            className="w-200px"
                            size="large"
                            placeholder="Select Supplier"
                            value={selectedSupplier || null}
                            allowClear
                            onChange={(e, _) => {
                              setSelectedSupplier(e);
                              setPage(1);
                              setPageSize(DefaultPerPage);
                              setLoading(true);
                              const apiObj = {
                                page: 1,
                                perPage: DefaultPerPage,
                                searchKey: searchTerm,
                                marketplace_id: selectedSalesChannel,
                                supplier_id: e,
                                forecast_days: noOfDaysToReplenish,
                                isExport: 0,
                              };
                              GetInventoryManagementList(apiObj);
                            }}
                            options={supplierList?.map((d) => {
                              return {
                                label: d?.supplier_name,
                                value: d?.id,
                              };
                            })}
                          />
                        </div>
                        <div className="position-relative ">
                          <Tooltip
                            title="Forecast Period"
                            // defaultOpen={true}
                            getPopupContainer={(triggerNode) =>
                              triggerNode.parentNode
                            }
                            placement="bottom"
                            // open={!noOfDaysToReplenish}
                          >
                            <Input
                              type="text"
                              size="large"
                              value={noOfDaysToReplenish}
                              onChange={(e) => {
                                if (e.target.value < 0) {
                                  message.destroy();
                                  message.error(
                                    "Negative Value are not allowed"
                                  );
                                }
                                if (!e.target.value) {
                                  setNoOfDaysToReplenish(e.target.value);
                                }
                                const newVal = e.target.value
                                  .split(".")
                                  .join("");
                                const validation = /^[0-9]+$/.test(newVal);
                                if (validation) {
                                  setNoOfDaysToReplenish(e.target.value);
                                }
                              }}
                              onPressEnter={(ev) => {
                                if (ev?.key === "Enter") {
                                  ev?.preventDefault();
                                  setLoading(true);
                                  setPage(1);
                                  setPageSize(DefaultPerPage);

                                  const apiObj = {
                                    page: 1,
                                    perPage: pageSize,
                                    searchKey: searchTerm,
                                    marketplace_id: selectedSalesChannel,
                                    supplier_id: selectedSupplier,
                                    forecast_days: ev.target.value,
                                    isExport: 0,
                                  };
                                  GetInventoryManagementList(apiObj);
                                }
                              }}
                              placeholder="Forecast Period"
                              style={{
                                width: "200px",
                              }}
                              className="ant_common_input"
                              suffix={
                                <img
                                  src="/assets/media/information-button.png"
                                  style={{ height: "20px", width: "20px" }}
                                  // className="fadeInScaleInfinite"
                                />
                              }
                            />
                          </Tooltip>
                        </div>
                        <div className="">
                          <Select
                            className="w-250px"
                            size="large"
                            placeholder="Select Sales Channel"
                            loading={salesChannelLoading}
                            value={selectedSalesChannel || null}
                            onChange={(e, _) => {
                              setSelectedSalesChannel(e);
                              setPage(1);
                              setPageSize(DefaultPerPage);
                              setLoading(true);
                              const apiObj = {
                                page: 1,
                                perPage: DefaultPerPage,
                                searchKey: searchTerm,
                                marketplace_id: e,
                                supplier_id: selectedSupplier,
                                forecast_days: noOfDaysToReplenish,
                                isExport: 0,
                              };
                              GetInventoryManagementList(apiObj);
                            }}
                            options={SalesChannelFlag(salesChannelList)}
                          />
                        </div>

                        <div className="">
                          <Dropdown
                            menu={{
                              items,
                            }}
                            trigger={["click"]}
                            disabled={selectedCheckbox?.length == 0}
                          >
                            <button className="btn btn-info bg-gray-1000 fs-7 btn-hover-color-primary">
                              Bulk Action
                              <i className="ki-outline ki-down fs-3" />
                            </button>
                          </Dropdown>
                        </div>
                        <div className="position-relative">
                          <button
                            className="btn btn-light-info fw-bolder fs-7"
                            onClick={() => {
                              setIsExportFlag(true);
                              message.destroy();
                              message.loading("Loading...", 0);
                              const apiObj = {
                                page: page,
                                perPage: pageSize,
                                searchKey: searchTerm,
                                marketplace_id: selectedSalesChannel,
                                supplier_id: selectedSupplier,
                                forecast_days: noOfDaysToReplenish,
                                isExport: 1,
                              };
                              GetInventoryManagementList(apiObj);
                            }}
                          >
                            Export
                          </button>
                        </div>
                      </div>
                    </div>
                    {/*end::Header*/}
                    {/*begin::Body*/}
                    <div className="card-body py-2">
                      <div
                        className="table-responsive"
                        style={{
                          height: "calc(100vh - 120px)",
                          overflow: "scroll",
                        }}
                      >
                        {loading ? (
                          <Loading />
                        ) : list?.length !== 0 ? (
                          <table
                            className="table align-start table-row-dashed table-row-gray-300 fs-7 gy-4 gx-4 border-top-d"
                            id=""
                          >
                            <thead>
                              <tr className="fw-bolder text-dark bg-gray-100 border-bottom-0">
                                <th className="w-50px p-0" />
                                <th className="p-0 w-250px" />
                                <th className="min-w-250px p-0"></th>
                                <th className="min-w-250px p-0" />
                                <th className="min-w-250px p-0" />
                              </tr>
                            </thead>
                            <tbody className="text-gray-700 fw-bold">
                              {list?.map((d, i) => {
                                return (
                                  <tr className="" key={i}>
                                    <td style={{ verticalAlign: "middle" }}>
                                      <div>
                                        <div className="d-flex justify-content-between align-items-center mb-7">
                                          <div>
                                            <Tag color="geekblue">
                                              Sr.No :{" "}
                                              {(page - 1) * pageSize + 1 + i}
                                            </Tag>
                                          </div>
                                        </div>

                                        <div className="d-flex justify-content-between align-items-center">
                                          <div>
                                            <Checkbox
                                              checked={
                                                selectedCheckbox.filter(
                                                  (a) => a == d.sku
                                                )?.length == 1
                                                  ? true
                                                  : false
                                              }
                                              onChange={(e) => {
                                                const checkboxId = d.sku;
                                                setSelectedCheckbox((prev) => {
                                                  if (e?.target?.checked) {
                                                    return [
                                                      ...prev,
                                                      checkboxId,
                                                    ];
                                                  } else {
                                                    return prev.filter(
                                                      (sku) =>
                                                        sku !== checkboxId
                                                    );
                                                  }
                                                });
                                              }}
                                            />
                                          </div>
                                          <button
                                            onClick={() => {
                                              const newObj = {
                                                ...selectedRowAsinSku,
                                              };
                                              newObj.asin = d?.asin;
                                              newObj.sku = d?.sku;
                                              newObj.rowId = d?.id;
                                              setSelectedRowAsinSku(newObj);

                                              const newApiObj = {
                                                asin: d?.asin,
                                                sku: d?.sku,
                                                marketplace_id:
                                                  selectedSalesChannel,
                                              };
                                              GetReplenishmentModalData(
                                                newApiObj
                                              );
                                            }}
                                            // className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
                                            className="btn btn-icon btn-sm"
                                          >
                                            <i className="ki-outline ki-pencil fs-2 text-info" />
                                          </button>

                                          <button
                                            className="btn btn-icon  btn-sm"
                                            onClick={() => {
                                              const newObj = {
                                                ...selectedRowAsinSku,
                                              };
                                              newObj.asin = d?.asin;
                                              newObj.sku = d?.sku;
                                              newObj.rowId = d?.id;
                                              setSelectedRowAsinSku(newObj);

                                              setIsOpenForecastModal(true);
                                            }}
                                          >
                                            {/* <i className="ki-outline ki-pencil fs-2 text-info" /> */}
                                            <i class="ki-outline ki-graph-up fs-1 text-green"></i>
                                          </button>
                                        </div>
                                      </div>
                                    </td>

                                    <td className="border-end border-dashed border-gray-300">
                                      {/*begin:: Avatar */}
                                      <div
                                        className="d-flex align-items-center bg-light rounded p-3"
                                        style={{
                                          borderRight: "2px solid #7239ea",
                                        }}
                                      >
                                        <div className="symbol symbol-90px overflow-hidden me-5">
                                          <img src={d?.image} alt="" />
                                        </div>
                                        {/*end::Avatar*/}
                                        {/*begin::User details*/}
                                        <div className="d-flex flex-column">
                                          <Popover
                                            content={
                                              <div
                                                style={{
                                                  height: "50px",
                                                  width: "400px",
                                                  overflowY: "scroll",
                                                }}
                                              >
                                                {d?.title}
                                              </div>
                                            }
                                            placement="bottom"
                                          >
                                            <span
                                              href=""
                                              className="text-primary text-hover-info mb-1 fw-bolder text-one fs-6  w-250px"
                                            >
                                              {d?.title}
                                            </span>
                                          </Popover>

                                          <span className="mb-1 fs-7">
                                            <b className="">SKU:</b>{" "}
                                            <Tag
                                              color="orange"
                                              className="fs-8"
                                            >
                                              {/* <span className="badge badge-light-info align-self-center fs-8"> */}
                                              {d?.sku}
                                              {/* </span> */}
                                            </Tag>
                                          </span>
                                          <span className="mb-1 fs-7">
                                            <b className="">ASIN:</b>{" "}
                                            <Tag
                                              color="purple"
                                              className="fs-8"
                                            >
                                              {/* <span className="badge badge-light-info align-self-center fs-8"> */}
                                              {d?.asin}
                                              {/* </span>{" "} */}
                                            </Tag>
                                          </span>
                                          <span className="mb-1 fs-7">
                                            <b className="fw-bolder">
                                              Forecast Period:
                                            </b>{" "}
                                            <Tag
                                              className=" fs-7"
                                              color="success"
                                            >
                                              {/* 0 Days */}
                                              {d?.number_of_days_to_replenish ||
                                                "-"}{" "}
                                              Days
                                            </Tag>
                                          </span>
                                          <span className="mb-1 fs-7">
                                            <b className="fw-bolder">
                                              Supplier Name:
                                            </b>{" "}
                                            <span className="badge badge-light-success align-self-center fs-7">
                                              {d?.supplier?.supplier_name ||
                                                "-"}
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                      {/*begin::User details*/}
                                    </td>
                                    <td className="border-end border-dashed border-gray-300">
                                      <div className="mb-3">
                                        <div className="d-flex justify-content-between">
                                          <div className="">
                                            Available Inventory
                                          </div>
                                          <div className="text-dark fw-bolder">
                                            {d?.available_inventory || "-"}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="mb-3">
                                        <div className="d-flex justify-content-between">
                                          <div className="">
                                            Reserved Inventory
                                          </div>
                                          <div className="text-dark fw-bolder">
                                            {d?.reserved_inventory || "-"}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="mb-3">
                                        <div className="d-flex justify-content-between">
                                          <div className="">
                                            Total Inventory
                                          </div>
                                          <div className="text-dark fw-bolder">
                                            {d?.total_inventory || "-"}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="mb-3">
                                        <div className="d-flex justify-content-between">
                                          <div className="">Unit Sold</div>
                                          <div className="text-dark fw-bolder">
                                            {d?.unit_sold || "-"}
                                          </div>
                                        </div>
                                      </div>
                                      <div>
                                        <div className="d-flex justify-content-between">
                                          <div className="">
                                            Total Fees{" "}
                                            <Popover
                                              placement="bottom"
                                              content={
                                                <>
                                                  <div className="d-flex justify-content-between">
                                                    <label>
                                                      Referral Fees :&nbsp;
                                                    </label>
                                                    <label className="fw-bold">
                                                      {selectedCurrencySign}
                                                      {NumberWithCommas(
                                                        d?.referral_fees || 0
                                                      )}
                                                    </label>
                                                  </div>
                                                  <div className="d-flex justify-content-between">
                                                    <label>
                                                      FBA Fees :&nbsp;
                                                    </label>
                                                    <label className="fw-bold">
                                                      {selectedCurrencySign}
                                                      {NumberWithCommas(
                                                        d?.refunds?.fba_fees ||
                                                          0
                                                      )}
                                                    </label>
                                                  </div>
                                                  <div className="d-flex justify-content-between">
                                                    <label>
                                                      Refunds Fees :&nbsp;
                                                    </label>
                                                    <label className="fw-bold">
                                                      {selectedCurrencySign}
                                                      {NumberWithCommas(
                                                        d?.refunds
                                                          ?.refund_fees || 0
                                                      )}
                                                    </label>
                                                  </div>
                                                </>
                                              }
                                              trigger="hover"
                                              className="me-4"
                                            >
                                              <i
                                                style={{
                                                  fontSize: "17px",
                                                  color: "#827dcb",
                                                }}
                                                className="bi bi-info-square mx-2 cursor-pointer"
                                              ></i>
                                            </Popover>
                                          </div>
                                          <div className="text-dark fw-bolder">
                                            {d?.total_fees || "0"}
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                    <td className="border-end border-dashed border-gray-300">
                                      <div className="mb-3">
                                        <div className="d-flex justify-content-between">
                                          <div className="">
                                            SKU Available Days{" "}
                                          </div>
                                          <div className="text-dark fw-bolder">
                                            {d?.days || "0"} Days
                                          </div>
                                        </div>
                                      </div>
                                      <div className="mb-3">
                                        <div className="d-flex justify-content-between">
                                          <div className="">Sales Velocity</div>
                                          <div className="text-dark fw-bolder">
                                            {d?.sales_velocity || "0"} /Day
                                          </div>
                                        </div>
                                      </div>
                                      <div className="mb-3">
                                        <div className="d-flex justify-content-between">
                                          <div className="">
                                            Total lead Time
                                          </div>
                                          <div className="text-dark fw-bolder">
                                            {d?.total_lead_time || -""}
                                          </div>
                                        </div>
                                      </div>
                                      <div>
                                        <div className="d-flex justify-content-between">
                                          <div className="">
                                            Units based on the lead time
                                          </div>
                                          <div className="text-dark fw-bolder">
                                            {d?.units_based_on_the_lead_time ||
                                              "-"}
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="mb-3">
                                        <div className="d-flex justify-content-between">
                                          <div className="">
                                            Days of Stock left
                                          </div>
                                          <div className="text-dark fw-bolder">
                                            {d?.days_of_stock_left || "-"}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="mb-3">
                                        <div className="d-flex justify-content-between">
                                          <div className="">
                                            Target Days of stock
                                          </div>
                                          <div className="text-dark fw-bolder">
                                            {d?.target_days_of_stock || "-"}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="mb-3">
                                        <div className="d-flex justify-content-between">
                                          <div className="">
                                            Target Units for days of stock
                                          </div>
                                          <div className="text-dark fw-bolder">
                                            {d?.target_units_for_the_target_days_of_stock ||
                                              "-"}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="mb-0">
                                        <div className="d-flex justify-content-between">
                                          <div className="">
                                            Recommended qty. for reordering
                                          </div>
                                          <div className="text-dark fw-bolder">
                                            <Tag
                                              className="ant-tag-custom-border mx-0"
                                              color={
                                                parseFloat(
                                                  d?.recommended_quantity_for_reordering
                                                ) > 0
                                                  ? "green"
                                                  : Math.round(
                                                      d?.recommended_quantity_for_reordering
                                                    ) == 0
                                                  ? "#d46b08"
                                                  : "#ff3333"
                                              }
                                            >
                                              <span className="fs-5">
                                                {d?.recommended_quantity_for_reordering >
                                                0
                                                  ? "No Restock Needed"
                                                  : Math.round(
                                                      d?.recommended_quantity_for_reordering?.replace(
                                                        "-",
                                                        ""
                                                      )
                                                    ) || 0}
                                                {/* {d?.recommended_quantity_for_reordering?.replace(
                                      "-",
                                      ""
                                    ) || 0} */}
                                              </span>
                                            </Tag>

                                            {/* {d?.recommended_quantity_for_reordering ||
                                              "0"} */}
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        ) : (
                          <NoData />
                        )}
                      </div>
                      <Pagination
                        loading={loading || list?.length === 0}
                        pageSize={pageSize}
                        page={page}
                        totalPage={totalPage}
                        onPerPage={onPerPage}
                        onPageNo={onPageNo}
                      />
                    </div>
                    {/*begin::Body*/}
                  </div>
                  {/*end::Tables Widget 9*/}
                </div>
                {/*end::Col*/}
              </div>
            </div>
          </div>
        </div>
      </div>

      {open_edit_replenishment_modal && (
        <EditReplenishment
          show={open_edit_replenishment_modal}
          onHide={() => {
            set_open_edit_replenishment_modal(false);
          }}
          data={replenishmentData}
          selectedRowAsinSku={selectedRowAsinSku}
          marketplace_id={selectedSalesChannel}
          supplierList={supplierList}
          {...props}
        />
      )}

      {openSupplierModal && (
        <BulkSupplierModal
          show={openSupplierModal}
          onHide={() => {
            setOpenSupplierModal(false);
          }}
          selectedCheckbox={selectedCheckbox}
          marketplace_id={selectedSalesChannel}
          supplierList={supplierList}
          onSuccess={() => {
            setSelectedCheckbox([]);
            setOpenSupplierModal(false);
            setLoading(true);
            setPage(1);
            setPageSize(DefaultPerPage);
            const apiObj = {
              page: page,
              perPage: pageSize,
              searchKey: searchTerm,
              marketplace_id: selectedSalesChannel,
              supplier_id: selectedSupplier,
              forecast_days: noOfDaysToReplenish,
              isExport: 0,
            };
            GetInventoryManagementList(apiObj);
          }}
          {...props}
        />
      )}
      {openDaysOfStockModal && (
        <BulkDaysOfStockModal
          show={openDaysOfStockModal}
          onHide={() => {
            setOpenDaysOfStockModal(false);
          }}
          selectedCheckbox={selectedCheckbox}
          marketplace_id={selectedSalesChannel}
          onSuccess={() => {
            setSelectedCheckbox([]);
            setOpenDaysOfStockModal(false);
            setLoading(true);
            setPage(1);
            setPageSize(DefaultPerPage);
            const apiObj = {
              page: page,
              perPage: pageSize,
              searchKey: searchTerm,
              marketplace_id: selectedSalesChannel,
              supplier_id: selectedSupplier,
              forecast_days: noOfDaysToReplenish,
              isExport: 0,
            };
            GetInventoryManagementList(apiObj);
          }}
          {...props}
        />
      )}
      {openLeadTimeModal && (
        <BulkLeadTimeModal
          show={openLeadTimeModal}
          onHide={() => {
            setOpenLeadTimeModal(false);
          }}
          selectedCheckbox={selectedCheckbox}
          marketplace_id={selectedSalesChannel}
          onSuccess={() => {
            setSelectedCheckbox([]);
            setOpenLeadTimeModal(false);
            setLoading(true);
            setPage(1);
            setPageSize(DefaultPerPage);
            const apiObj = {
              page: page,
              perPage: pageSize,
              searchKey: searchTerm,
              marketplace_id: selectedSalesChannel,
              supplier_id: selectedSupplier,
              forecast_days: noOfDaysToReplenish,
              isExport: 0,
            };
            GetInventoryManagementList(apiObj);
          }}
          {...props}
        />
      )}

      {isOpenForecastModal && (
        <ForecastModal
          {...props}
          show={isOpenForecastModal}
          onHide={() => {
            setIsOpenForecastModal(false);
          }}
          selectedRowAsinSku={selectedRowAsinSku}
          marketplace_id={selectedSalesChannel}
        />
      )}
    </>
  );
}
