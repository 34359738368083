import { Select, Tooltip, Input, Checkbox } from "antd";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

export default function (props) {
  const { replenishmentModalData, setReplenishmentModalData } = props;
  // const [manufacture_data, set_manufacture_data] = useState({
  //   manufacturing_time_days: 1,
  //   i_use_prep_center: 1,
  //   shipping_prep_center_days: 1,
  //   shipping_fba_days: 10,
  // });

  const onChangeField = (key, value, rest) => {
    setReplenishmentModalData({
      ...replenishmentModalData,
      [key]: value,
      ...rest,
    });
  };
  return (
    <>
      <div className="row">
        <div className="col-12 d-flex justify-content-between align-items-center">
          <div>
            <label className="fw-bold fs-6">Manufacturing time (days)</label>
          </div>
          <div className="d-flex align-items-center">
            <Input
              value={replenishmentModalData?.manufacturing_time_days}
              defaultValue={0}
              // onPressEnter={() => {
              //   onPressEnterApi();
              // }}
              onBlur={(e) => {
                if (!e.target.value) {
                  onChangeField("manufacturing_time_days", 0);
                }
              }}
              onChange={(e) => {
                if (!e.target.value) {
                  onChangeField("manufacturing_time_days", e.target.value);
                }
                const newVal = e.target.value.split(".").join("");
                const validation = /^[0-9]+$/.test(newVal);
                if (validation) {
                  onChangeField("manufacturing_time_days", e.target.value);
                }
              }}
              className="ant_common_input me-5"
              size="large"
              style={{
                width: "230px",
              }}
            />

            <Tooltip
              placement="left"
              title="How many days does it take to manufacture the product?"
              trigger="hover"
              className="me-4"
            >
              <i
                style={{ fontSize: "17px", color: "#827dcb" }}
                className="bi bi-info-circle mx-2 cursor-pointer"
              ></i>
            </Tooltip>
          </div>
        </div>
        <div className="separator my-5" />
        <div className="col-12 d-flex justify-content-between align-items-center">
          <div>
            <Checkbox
              checked={
                replenishmentModalData?.i_use_prep_center == 1 ? true : false
              }
              onChange={(e) => {
                onChangeField("i_use_prep_center", e?.target?.checked ? 1 : 0, {
                  shipping_prep_center_days: 0,
                });
                // setIsUpdate(false);

                // setreplenishmentModalData({
                //   ...replenishmentModalData,
                //   i_use_prep_center: e?.target?.checked ? 1 : 0,
                //   shipping_prep_center_days: 0,
                // });
                // if (!e?.target?.checked) {
                //   onChangeField("shipping_prep_center_days", 0);
                // }
              }}
            >
              <label className="fw-bold fs-6">I use a Prep Center</label>
            </Checkbox>
          </div>
          <div className="d-flex align-items-center">
            <Tooltip
              placement="left"
              title="Do you stock the products in a prep center, or your own warehouse before they are shipped to the FBA warehouse? Then tick this checkbox. If your supplier ships directly to the FBA warehouse, de-select the checkbox."
              trigger="hover"
              className="me-4"
            >
              <i
                style={{ fontSize: "17px", color: "#827dcb" }}
                className="bi bi-info-circle mx-2 cursor-pointer"
              ></i>
            </Tooltip>
          </div>
        </div>
        <div className="separator my-5" />
        <div className="col-12 d-flex justify-content-between align-items-center">
          <div>
            <label className="fw-bold fs-6">
              Shipping to Prep Center (days)
            </label>
          </div>
          <div className="d-flex align-items-center">
            <Input
              value={
                replenishmentModalData?.i_use_prep_center == 1
                  ? replenishmentModalData?.shipping_prep_center_days
                  : 0
              }
              defaultValue={0}
              // onPressEnter={() => {
              //   onPressEnterApi();
              // }}
              onBlur={(e) => {
                if (!e.target.value) {
                  onChangeField(
                    "shipping_prep_center_days",
                    0
                    // { i_use_prep_center: 0 }
                  );
                }
              }}
              onChange={(e) => {
                if (!e.target.value) {
                  onChangeField(
                    "shipping_prep_center_days",
                    e.target.value
                    // { i_use_prep_center: 0 }
                  );
                }
                const newVal = e.target.value.split(".").join("");
                const validation = /^[0-9]+$/.test(newVal);
                if (validation) {
                  onChangeField("shipping_prep_center_days", e.target.value);
                  // onChangeField(
                  //   "shipping_prep_center_days",
                  //   e.target.value
                  // );
                }
              }}
              className="ant_common_input me-5"
              size="large"
              style={{
                width: "230px",
              }}
              disabled={replenishmentModalData?.i_use_prep_center == 0}
            />
            <Tooltip
              placement="left"
              title="How many days does the shipping take from your supplier to your prep center"
              trigger="hover"
              className="me-4"
            >
              <i
                style={{ fontSize: "17px", color: "#827dcb" }}
                className="bi bi-info-circle mx-2 cursor-pointer"
              ></i>
            </Tooltip>
          </div>
        </div>
        <div className="separator my-5" />
        <div className="col-12 d-flex justify-content-between align-items-center">
          <div>
            <label className="fw-bold fs-6">Shipping to FBA (days)</label>
          </div>
          <div className="d-flex align-items-center">
            <Input
              value={replenishmentModalData?.shipping_fba_days}
              defaultValue={0}
              // onPressEnter={() => {
              //   onPressEnterApi();
              // }}
              onBlur={(e) => {
                if (!e.target.value) {
                  onChangeField("shipping_fba_days", 0);
                }
              }}
              onChange={(e) => {
                if (!e.target.value) {
                  onChangeField("shipping_fba_days", e.target.value);
                }
                const newVal = e.target.value.split(".").join("");
                const validation = /^[0-9]+$/.test(newVal);
                if (validation) {
                  onChangeField("shipping_fba_days", e.target.value);
                }
              }}
              className="ant_common_input me-5"
              size="large"
              style={{
                width: "230px",
              }}
            />
            <Tooltip
              placement="left"
              title="Shipping to FBA (days)"
              trigger="hover"
              className="me-4"
            >
              <i
                style={{ fontSize: "17px", color: "#827dcb" }}
                className="bi bi-info-circle mx-2 cursor-pointer"
              ></i>
            </Tooltip>
          </div>
        </div>
        {/* <div className="separator my-5" /> */}
      </div>
    </>
  );
}
