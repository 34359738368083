import { Alert, Checkbox, Col, Input, Row, Select, message } from "antd";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import CampaignList from "./campaign-list";
import CampaignCreation from "./campaign-creation";
import NoData from "../../../../component/no-data";

export default function (props) {
  const [selectedTab, setSelectedTab] = useState("campaign-list");
  return (
    <>
      <div className="d-flex flex-column flex-column-fluid fadeInRight">
        {/*begin::Content*/}
        <div id="kt_app_content" className="app-content flex-column-fluid">
          {/*begin::Content container*/}
          <div
            id="kt_app_content_container"
            className="app-container container-fluid"
          >
            <div className="row mb-4">
              <div className="col-md-12 cus-tab">
                <ul className="nav nav-pills mb-5 fs-6">
                  {/* <li
                    className="nav-item"
                    onClick={() => {
                      setSelectedTab("campaign-list");
                    }}
                  >
                    <span
                      className={`${
                        selectedTab === "campaign-list" && "active"
                      }  nav-link  fs-7 fw-bold py-3 px-7 me-2 bg-white`}
                    >
                      Campaigns List
                    </span>
                  </li> */}
                  {/* <li
                    className="nav-item"
                    onClick={() => {
                      setSelectedTab("campaign-creation");
                    }}
                  >
                    <span
                      className={`${
                        selectedTab === "campaign-creation" && "active"
                      }  nav-link  fs-7 fw-bold py-3 px-7 me-2 bg-white`}
                    >
                      Campaign Creation
                    </span>
                  </li> */}
                </ul>
              </div>
            </div>

            {selectedTab === "campaign-list" && <CampaignList {...props} />}
            {/* {selectedTab === "campaign-creation" && <CampaignCreation />} */}
            {selectedTab === "campaign-creation" && <NoData />}
          </div>
        </div>
      </div>
    </>
  );
}
