import React, { useState } from "react";
import { NumberWithCommas } from "../../../../../../config";
import IvcsrTable from "../../../../../../component/ivcsr-table";
import Loading from "../../../../../../component/loading";
import { SearchOutlined } from "@ant-design/icons";
import { Input, Select, Tag } from "antd";
import NoData from "../../../../../../component/no-data";
import Pagination from "../../../../../../component/pagination";
export default function (props) {
  const {
    loading,
    list,
    page,
    pageSize,
    totalPage,
    onPageNo,
    onPerPage,
    setList,
    originalList,
  } = props;

  const [searchTerm, setSearchTerm] = useState("");
  console.log(list, "listlistlistlist");
  const columns = [
    {
      title: "#",
      ellipsis: true,
      align: "left",
      width: 100,
      render: (text) => {
        return <span>{(page - 1) * pageSize + 1 + text.key}</span>;
      },
    },
    {
      title: "Campaign Name",
      dataIndex: "campaign_name",
      key: "campaign_name",
      render: (text) => {
        return <span className="fw-bold text-primary fs-5">{text || "-"}</span>;
      },
    },
    {
      title: "Status",
      dataIndex: "campaign_status",
      key: "campaign_status",
      render: (text) => {
        return (
          <Tag
            color={
              text === "ENABLED"
                ? "green"
                : text === "PAUSED"
                ? "orange"
                : "red"
            }
          >
            {text}
          </Tag>
        );
      },
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    // {
    //   title: "Targeting",
    //   dataIndex: "targeting",
    //   key: "targeting",
    // },
    {
      title: "Campaign Bidding Strategy",
      dataIndex: "campaign_bidding_strategy",
      key: "campaign_bidding_strategy",
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      key: "startDate",
    },
    {
      title: "End Date",
      dataIndex: "end_date",
      key: "end_date",
    },

    {
      title: "Budget",
      dataIndex: "campaign_budget_amount",
      key: "campaign_budget_amount",
      render: (text) => {
        return <span>${text || "-"}</span>;
      },
    },

    // {
    //   title: "Cost Type",
    //   dataIndex: "type",
    //   key: "type",
    //   render: (text) => {
    //     return <span>{text || "-"}</span>;
    //   },
    // },
    {
      title: "Impressions",
      dataIndex: "impressions",
      key: "impressions",
      render: (text) => {
        return <span>{text || "-"}</span>;
      },
    },
    {
      title: "Clicks",
      dataIndex: "clicks",
      key: "clicks",
      render: (text) => {
        return <span>{text || "-"}</span>;
      },
    },
    {
      title: "CTR",
      dataIndex: "CTR",
      key: "CTR",
      render: (text) => {
        return <span>{text || "-"}%</span>;
      },
    },
    {
      title: "Spend",
      dataIndex: "spend",
      key: "spend",
      render: (text) => {
        return <span>${text || "-"}</span>;
      },
    },
    {
      title: "CPC",
      dataIndex: "CPC",
      key: "CPC",
      render: (text) => {
        return <span>${text || "-"}</span>;
      },
    },
    {
      title: "Orders",
      dataIndex: "unit_ordered",
      key: "unit_ordered",
    },
    {
      title: "Sales",
      dataIndex: "sales",
      key: "sales",
      render: (text) => {
        return <span>${text || "-"}</span>;
      },
    },
    {
      title: "ACoS",
      dataIndex: "ACoS",
      key: "ACoS",
      render: (text) => {
        return <span>{text || "-"}%</span>;
      },
    },
    {
      title: "RoAS",
      dataIndex: "ROAS",
      key: "ROAS",
      render: (text) => {
        return <span>${text || "-"}</span>;
      },
    },
    {
      title: "NTB Orders",
      dataIndex: "new_to_brand_orders",
      key: "new_to_brand_orders",
    },
    {
      title: "Units Sold",
      dataIndex: "unit_ordered",
      key: "unit_ordered",
    },
    {
      title: "Portfolio",
      dataIndex: "portfolio_id",
      key: "portfolio_id",
    },
    {
      title: "Top of Search is",
      dataIndex: "top_of_search_impression_share",
      key: "top_of_search_impression_share",
    },
  ];
  return (
    <div className="col-xxl-12">
      {/*begin::Tables Widget 9*/}

      <div className="card">
        {/*begin::Header*/}
        <div className="card-header border-bottom-dashed">
          <div className="d-flex flex-stack flex-wrap gap-4">
            <div className="position-relative">
              <label className="fw-bold fs-4">Campaign List</label>
            </div>
          </div>
          <div className="card-toolbar">
            <div className="position-relative me-2">
              <Select
                placeholder="Select Option"
                size="large"
                style={{ width: "200px" }}
                // value={selectedCampaignType}
                options={[
                  { label: "Active", value: 1 },
                  { label: "Inactive", value: 2 },
                  { label: "Paused", value: 3 },
                ]}
                // onChange={(e) => {
                //   setSelectedCampaignType(e);
                // }}
              />
            </div>
            <div className="position-relative ">
              <Input
                type="text"
                className="text-dark w-250px fs-7 fw-bold ant_common_input"
                name="all_search"
                placeholder="Search by Campaign Name"
                value={searchTerm}
                size="large"
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                }}
                onPressEnter={(e) => {
                  const foundCampaign = originalList?.filter((campaign) =>
                    campaign?.campaign_name?.includes(searchTerm)
                  );
                  console.log(foundCampaign, "foundCampaign");
                  setList(foundCampaign);
                }}
                prefix={
                  <SearchOutlined style={{ fontSize: "19px", color: "grey" }} />
                }
              />
            </div>
          </div>
        </div>
        {/*end::Header*/}
        {/*begin::Body*/}
        <div className="card-body py-2">
          {/*begin::Table container*/}
          <div className="table-responsive">
            {/*begin::Table*/}
            {loading ? (
              <Loading />
            ) : originalList?.length != 0 ? (
              <>
                <IvcsrTable
                  columns={columns}
                  fixed="top"
                  dataSource={list}
                  rowKey="key"
                  loading={loading}
                  pagination={true}
                  scroll={{ x: "max-content" }}
                />
              </>
            ) : (
              <>
                <NoData />
              </>
            )}
            {/* < Pagination defaultCurrent={page} total={totalPage} /> */}
            {/*end::Table*/}
          </div>
        </div>
        {/*begin::Body*/}
      </div>
      {/*end::Tables Widget 9*/}
    </div>
  );
}
