import { Checkbox, DatePicker, Input, Radio, Space } from "antd";
import React, { useState } from "react";
import { DateFormat } from "../../../../../../config";
import dayjs from "dayjs";
import moment from "moment";

const CampaignCreation = (props) => {
  const { data, setCampaignCreateObj } = props;
  const [isEnableEndDate, setIsEnableEndDate] = useState(true);
  const [startDate, setStartDate] = useState(dayjs());
  const [endDate, setEndDate] = useState(dayjs("9999-12-30"));
  const setAllFiledValue = (key, value) => {
    const newObj = { ...data };
    newObj[key] = value;
    setCampaignCreateObj(newObj);
  };
  // console.log(data, "data");
  return (
    <>
      {/*begin::Row*/}
      <div className="row gy-5 g-xl-5 fadeOutBottom">
        {/*begin::Col*/}
        <div className="col-xl-12">
          {/*begin::Basic info*/}
          <div className="card card-flush h-lg-100 mb-5 mb-xl-5">
            {/*begin::Card header*/}
            <div className="card-header border-0 cursor-pointer">
              {/*begin::Card title*/}
              <div className="card-title m-0">
                <h3 className="fw-bold m-0">General Details</h3>
              </div>
              {/*end::Card title*/}
            </div>
            {/*begin::Card header*/}
            {/*begin::Card body*/}
            <div className="card-body border-top border-top-dashed pb-2 pt-6">
              {/*begin::Input group*/}
              <div className="row mb-0 ">
                {/*begin::Label*/}
                <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                  Campaign Name
                </label>
                {/*end::Label*/}
                {/*begin::Col*/}
                <div className="col-lg-8 fv-row">
                  <Input.TextArea
                    name
                    className="ant_common_input"
                    placeholder="Name your campaign"
                    cols={30}
                    rows={2}
                    value={data?.name || ""}
                    onChange={(e) => {
                      setAllFiledValue("name", e.target.value);
                    }}
                  />
                </div>
                {/*end::Col*/}
              </div>
              {/*end::Input group*/}
            </div>
            {/*end::Card body*/}
          </div>
          {/*end::Basic info*/}
        </div>
        {/*end::Col*/}
        <div className="col-xl-12">
          {/*begin::Targeting*/}
          <div className="card card-flush h-lg-100 mb-5 mb-xl-5">
            {/*begin::Card header*/}
            <div className="card-header border-0 ">
              <div className="card-title m-0">
                <h3 className="fw-bold m-0">Targeting</h3>
              </div>
            </div>
            {/*end::Card header*/}
            {/*begin::Card body*/}
            <div className="card-body border-top border-top-dashed p-9">
              <div className="row">
                <div className="col-md-12 mb-4">
                  <h4>Select Campaign Type:</h4>
                </div>
                <div
                  className="col-md-12"
                  onClick={() => {
                    setAllFiledValue("campaignType", "sponsoredProducts");
                  }}
                >
                  {/*begin::Option*/}

                  <label
                    className={`btn btn-outline p-4 d-flex align-items-center mb-5`}
                    style={{
                      border: `1px dashed ${
                        data?.campaignType === "sponsoredProducts"
                          ? "blue"
                          : "lightgrey"
                      } `,
                      background:
                        data?.campaignType === "sponsoredProducts"
                          ? "#f4f4ff"
                          : "#fff",
                    }}
                  >
                    <div className="symbol symbol-50px me-5">
                      <div className="symbol-label fs-4 fw-bold bg-light-info text-primary">
                        SP
                      </div>
                    </div>
                    <span className="d-block fw-semibold text-start">
                      <span className="text-primary fw-bold d-block fs-4">
                        Sponsored Products
                      </span>
                      <span className="text-muted fw-normal fs-7">
                        Get your items included in relevant results when
                        customers search Walmart's site and app
                      </span>
                    </span>
                  </label>
                  {/*end::Option*/}
                </div>
                <div
                  className="col-md-12"
                  onClick={() => {
                    setAllFiledValue("campaignType", "sponsoredBrand");
                  }}
                >
                  {/*begin::Option*/}

                  <label
                    className={`btn btn-outline p-4 d-flex align-items-center mb-5`}
                    style={{
                      border: `1px dashed ${
                        data?.campaignType === "sponsoredBrand"
                          ? "blue"
                          : "lightgrey"
                      } `,
                      background:
                        data?.campaignType === "sponsoredBrand"
                          ? "#f4f4ff"
                          : "#fff",
                    }}
                  >
                    <div className="symbol symbol-50px me-5">
                      <div className="symbol-label fs-4 fw-bold bg-light-info text-primary">
                        SB
                      </div>
                    </div>
                    <span className="d-block fw-semibold text-start">
                      <span className="text-primary fw-bold d-block fs-4">
                        Sponsored Brands
                      </span>
                      <span className="text-muted fw-normal fs-7">
                        This premium ad sends your brand and products to the top
                        of relevant results when customers search our site &amp;
                        app.
                      </span>
                    </span>
                  </label>
                  {/*end::Option*/}
                </div>
                <div
                  className="col-md-12"
                  onClick={() => {
                    setAllFiledValue("campaignType", "sponsoredVideo");
                  }}
                >
                  {/*begin::Option*/}

                  <label
                    className={`btn p-4 d-flex align-items-center mb-5`}
                    style={{
                      border: `1px dashed ${
                        data?.campaignType === "sponsoredVideo"
                          ? "blue"
                          : "lightgrey"
                      } `,
                      background:
                        data?.campaignType === "sponsoredVideo"
                          ? "#f4f4ff"
                          : "#fff",
                    }}
                  >
                    <div className="symbol symbol-50px me-5">
                      <div className="symbol-label fs-4 fw-bold bg-light-info text-primary">
                        SV
                      </div>
                    </div>
                    <span className="d-block fw-semibold text-start">
                      <span className="text-primary fw-bold d-block fs-4">
                        Sponsored Videos
                      </span>
                      <span className="text-muted fw-normal fs-7">
                        Use videos to attract customers' attention and make your
                        products stand out.
                      </span>
                    </span>
                  </label>
                  {/*end::Option*/}
                </div>
                <div className="col-md-12 mb-4 mt-7">
                  <h4>Select Targeting Tactic:</h4>
                </div>
                <div className="col-md-12">
                  {/*begin:Option*/}
                  <label className="d-flex flex-stack mb-5 cursor-pointer">
                    {/*begin:Label*/}
                    <span className="d-flex align-items-center me-2">
                      {/*begin:Input*/}
                      <span className="form-check form-check-custom form-check-solid">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="targetingType"
                          disabled={data?.campaignId}
                          checked={
                            data?.targetingType === "auto" ? true : false
                          }
                          onChange={(e) => {
                            setAllFiledValue(
                              "targetingType",
                              e.target.checked && "auto"
                            );
                          }}
                        />
                      </span>
                      {/*end:Input*/}
                      {/*begin:Info*/}
                      <span className="d-flex flex-column ms-5">
                        <span className="fw-bold fs-6">Auto</span>
                        <span className="fs-7 text-gray-600">
                          Launch a Sponsored products campaign quickly and
                          easily. Let Walmart's algorithm select relevant
                          keywords for you. Then set cost-per-click bids for
                          individual items or group of items. Ads can serve in
                          all Sponsored Products placements.
                        </span>
                      </span>
                      {/*end:Info*/}
                    </span>
                    {/*end:Label*/}
                  </label>
                  {/*end::Option*/}
                </div>
                <div className="col-md-12">
                  {/*begin:Option*/}
                  <label className="d-flex flex-stack mb-5 cursor-pointer">
                    {/*begin:Label*/}
                    <span className="d-flex align-items-center me-2">
                      {/*begin:Input*/}
                      <span className="form-check form-check-custom form-check-solid">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="targetingType"
                          disabled={data?.campaignId}
                          checked={
                            data?.targetingType === "manual" ? true : false
                          }
                          onChange={(e) => {
                            setAllFiledValue(
                              "targetingType",
                              e.target.checked && "manual"
                            );
                          }}
                        />
                      </span>
                      {/*end:Input*/}
                      {/*begin:Info*/}
                      <span className="d-flex flex-column ms-5">
                        <span className="fw-bold fs-6">Manual</span>
                        <span className="fs-7 text-gray-600">
                          Launch a Sponsored Products, Sponsored Brands or
                          Sponsored Video campaign with more control. Choose
                          your own keywords and set cost-per-click bids for
                          each. Ads can appear on search pages and item pages.
                        </span>
                      </span>
                      {/*end:Info*/}
                    </span>
                    {/*end:Label*/}
                  </label>
                  {/*end::Option*/}
                </div>
              </div>
            </div>
            {/*end::Card body*/}
          </div>
          {/*end::Targeting*/}
        </div>

        <div className="col-xl-12">
          {/*begin::Basic info*/}
          <div className="card card-flush h-lg-100 mb-5 mb-xl-5">
            {/*begin::Card header*/}
            <div className="card-header border-0 cursor-pointer">
              {/*begin::Card title*/}
              <div className="card-title m-0">
                <h3 className="fw-bold m-0">Scheduling & Budget</h3>
              </div>
              {/*end::Card title*/}
            </div>
            {/*begin::Card header*/}
            {/*begin::Card body*/}
            <div className="card-body border-top border-top-dashed pb-2 pt-6">
              {/*begin::Input group*/}
              <div className="row mb-0 ">
                {/*begin::Label*/}
                <div className="col-lg-3 col-md-4 col-12 fs-6">
                  <label className="fw-semibold fs-6 required">
                    Start Date
                  </label>
                  <DatePicker
                    onChange={(date, dateString) => {
                      // console.log(date, dateString, "date, dateString");
                      setAllFiledValue(
                        "startDate",
                        moment(date?.$d).format("YYYY-MM-DD")
                      );
                      setStartDate(date);
                    }}
                    value={startDate}
                    style={{ width: "100%" }}
                    size="large"
                    className="ant_common_input"
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    format={DateFormat}
                    needConfirm={false}
                  />
                </div>
                {!isEnableEndDate && (
                  <div className="col-lg-3 col-md-4 col-12 fs-6">
                    <label className="fw-semibold fs-6 required">
                      End Date
                    </label>
                    <DatePicker
                      onChange={(date, dateString) => {
                        // console.log(date, dateString, "date, dateString");
                        setAllFiledValue(
                          "endDate",
                          moment(date?.$d).format("YYYY-MM-DD")
                        );
                        setEndDate(date);
                      }}
                      value={endDate}
                      style={{ width: "100%" }}
                      size="large"
                      className="ant_common_input"
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentNode
                      }
                      format={DateFormat}
                    />
                  </div>
                )}

                {/*end::Label*/}
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="d-flex mt-3 align-items-center">
                    <Checkbox
                      checked={isEnableEndDate}
                      className="me-3"
                      onChange={(e) => {
                        setIsEnableEndDate(e.target.checked ? 1 : 0);
                        if (e.target.checked) {
                          setAllFiledValue(
                            "endDate",
                            dayjs("9999-12-30").format("YYYY-MM-DD")
                          );
                          setEndDate(dayjs);
                        } else {
                          setAllFiledValue(
                            "endDate",
                            dayjs("9999-12-30").format("YYYY-MM-DD")
                          );
                          setEndDate(dayjs("9999-12-30"));
                        }
                      }}
                    />
                    <label className="fw-bold fs-6">
                      Run indenfinitely from start date
                    </label>
                  </div>
                </div>
              </div>
              {/*end::Input group*/}

              <div className="row mb-0 mt-10">
                {/*begin::Label*/}
                <div className="col-12 col-lg-6  mb-3 d-flex align-items-center">
                  {/* <Radio.Group onChange={onChange} value={value}> */}
                  <label className="fs-6 me-3">Budget Type : </label>
                  <Radio.Group
                    value={data?.budgetType}
                    onChange={(e) => {
                      setAllFiledValue("budgetType", e.target.value);
                    }}
                  >
                    <Radio value={"dailytotal"} className="fw-bold">
                      Dailytotal
                    </Radio>
                    <Radio value={"both"} className="fw-bold">
                      Both
                    </Radio>
                  </Radio.Group>
                </div>
                <div className="col-lg-3 col-12 fs-6">
                  <label className="fw-semibold fs-6 required">
                    Total Budget
                  </label>
                  <Input
                    className="ant_common_input"
                    placeholder="Total Budget"
                    defaultValue={0}
                    value={data?.totalBudget}
                    onBlur={(e) => {
                      if (!e.target.value) {
                        setAllFiledValue("totalBudget", 0);
                      }
                    }}
                    onChange={(e) => {
                      let value = e.target.value;
                      value = value.replace(/^0+/, "");
                      if (data?.totalBudget == 0 && value != "0") {
                        value = value.replace("0", "");
                      }
                      const isNumeric = /^[0-9]*$/.test(value);
                      if (isNumeric || value === "") {
                        setAllFiledValue("totalBudget", value);
                      }
                    }}
                    size="large"
                  />
                </div>

                <div className="col-lg-3 col-12 fs-6">
                  <label className="fw-semibold fs-6 required">
                    Daily Budget
                  </label>
                  <Input
                    className="ant_common_input"
                    placeholder="Daily Budget"
                    defaultValue={0}
                    value={data?.dailyBudget}
                    onBlur={(e) => {
                      if (!e.target.value) {
                        setAllFiledValue("dailyBudget", 0);
                      }
                    }}
                    onChange={(e) => {
                      let value = e.target.value;
                      value = value.replace(/^0+/, "");
                      if (data?.dailyBudget == 0 && value != "0") {
                        value = value.replace("0", "");
                      }
                      const isNumeric = /^[0-9]*$/.test(value);
                      if (isNumeric || value === "") {
                        setAllFiledValue("dailyBudget", value);
                      }
                    }}
                    size="large"
                  />
                </div>

                {/*end::Label*/}
              </div>
            </div>
            {/*end::Card body*/}
          </div>
          {/*end::Basic info*/}
        </div>
      </div>
      {/* {console.log(data, "data")} */}
      {/*end::Row*/}
    </>
  );
};

export default CampaignCreation;
