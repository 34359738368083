export default (state, action) => {
  if (typeof state === "undefined") {
    return [];
  }
  switch (action.type) {
    case "GET_WALMART_CAMPAIGN_MANAGEMENT_LIST_SUCCESS":
    case "GET_WALMART_CAMPAIGN_MANAGEMENT_LIST_ERROR":
      return {
        ...state,
        GetWalmartCampaignListResponse: action.updatePayload,
      };

    case "GET_WALMART_CAMPAIGN_BD_LOG_LIST_SUCCESS":
    case "GET_WALMART_CAMPAIGN_BD_LOG_LIST_ERROR":
      return {
        ...state,
        GetWalmartCampaignDBLogListResponse: action.updatePayload,
      };

    case "DELETE_WALMART_CAMPAIGN_SUCCESS":
    case "DELETE_WALMART_CAMPAIGN_ERROR":
      return {
        ...state,
        DeleteWalmartCampaignResponse: action.updatePayload,
      };

    case "GET_SAVED_CAMPAIGN_DETAILS_BY_CID_SUCCESS":
    case "GET_SAVED_CAMPAIGN_DETAILS_BY_CID_ERROR":
      return {
        ...state,
        GetSavedCamapignDetailsByCIDResponse: action.updatePayload,
      };

    case "WALMART_CREATE_CAMPAIGN_SUCCESS":
    case "WALMART_CREATE_CAMPAIGN_ERROR":
      return {
        ...state,
        CreateWalmartCampaignResponse: action.updatePayload,
      };

    case "WAMLART_CREATE_AD_GROUP_CAMPAIGN_SUCCESS":
    case "WAMLART_CREATE_AD_GROUP_CAMPAIGN_ERROR":
      return {
        ...state,
        CreateWalmartAdGroupResponse: action.updatePayload,
      };

    case "WALMART_CREATE_AD_ITEMS_CAMPAIGN_SUCCESS":
    case "WALMART_CREATE_AD_ITEMS_CAMPAIGN_ERROR":
      return {
        ...state,
        CreateWalmartAddItemsResponse: action.updatePayload,
      };

    case "WALMART_CREATE_BID_PLACEMENT_CAMPAIGN_SUCCESS":
    case "WALMART_CREATE_BID_PLACEMENT_CAMPAIGN_ERROR":
      return {
        ...state,
        CreateWalmartBidPlacementResponse: action.updatePayload,
      };

    case "WALMART_CREATE_BID_PLATFORM_CAMPAIGN_SUCCESS":
    case "WALMART_CREATE_BID_PLATFORM_CAMPAIGN_ERROR":
      return {
        ...state,
        CreateWalmartBidPlatformResponse: action.updatePayload,
      };

    case "WALMART_CREATE_PLACEMENT_INCLUSION_CAMPAIGN_SUCCESS":
    case "WALMART_CREATE_PLACEMENT_INCLUSION_CAMPAIGN_ERROR":
      return {
        ...state,
        CreateWalmartPlacementInclusionResponse: action.updatePayload,
      };

    case "WALMART_GET_CAMPAIGN_KEYWORD_LIST_SUCCESS":
    case "WALMART_GET_CAMPAIGN_KEYWORD_LIST_ERROR":
      return {
        ...state,
        GetWalmartCampaignKeywordListResponse: action.updatePayload,
      };

    case "WALMART_CREATE_KEYWORD_CAMPAIGN_SUCCESS":
    case "WALMART_CREATE_KEYWORD_CAMPAIGN_ERROR":
      return {
        ...state,
        CreateWalmartKeywordCampaignResponse: action.updatePayload,
      };

    case "GET_DIRECT_WALMART_CAMPAIGN_LIST_SUCCESS":
    case "GET_DIRECT_WALMART_CAMPAIGN_LIST_ERROR":
      return {
        ...state,
        GetDirectWalmartCampaignListResponse: action.updatePayload,
      };

    // ------------------------------------------------------------------------------------------------------------------------------------------  amazon API

    case "GET_CAMPAIGN_MANAGEMENT_LIST_SUCCESS":
    case "GET_CAMPAIGN_MANAGEMENT_LIST_ERROR":
      return {
        ...state,
        GetCampaignListResponse: action.updatePayload,
      };

    case "AMAZON_CAMPAIGN_FILTERS_SUCCESS":
    case "AMAZON_CAMPAIGN_FILTERS_ERROR":
      return {
        ...state,
        GetAmazonFiltersResponse: action.updatePayload,
      };

    case "AMAZON_PERFORMANCE_METRICS_SUCCESS":
    case "AMAZON_PERFORMANCE_METRICS_ERROR":
      return {
        ...state,
        GetAmazonPerformanceMetricsResponse: action.updatePayload,
      };

    case "AMAZON_CAMPAIGN_GRAPH_SUCCESS":
    case "AMAZON_CAMPAIGN_GRAPH_ERROR":
      return {
        ...state,
        GetAmazonCampaignGraphResponse: action.updatePayload,
      };

    case "CAMPAIGN_LIST_TABLE_SUCCESS":
    case "CAMPAIGN_LIST_TABLE_ERROR":
      return {
        ...state,
        GetCampaignListTableResponse: action.updatePayload,
      };

    case "DAY_WISE_CAMPAIGN_LIST_TABLE_SUCCESS":
    case "DAY_WISE_CAMPAIGN_LIST_TABLE_ERROR":
      return {
        ...state,
        GetDayWiseCampaignListTableResponse: action.updatePayload,
      };

    case "FAKE_ACTION_CAMPAIGN_MANAGEMENT":
      return { ...state, [action?.state]: {} };
    default:
      return state;
  }
};
