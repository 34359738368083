import styled from "styled-components";

export const NoDataWrapper = styled.div`
  position: relative;
  // height: calc(1140px - 324px);
  .pluseIconStyle {
    position: relative;
    font-size: 20px;
    top: -4px;
    color: #ef7db2;
    cursor: pointer;
  }
`;
