import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import RepricingBuyBoxDetails from "../../modules/pages/repricer-buy-box-details/index";

import // GetCriteriaAnalyticsList,
// fakeActionCriteriaAnalytics,
"../../redux/modules/criteria-analytics/index.action";

const mapStateToProps = (state) => ({
  // GetCriteriaAnalyticsListResponse:
  //   state.CriteriaAnalytics.GetCriteriaAnalyticsListResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      // GetCriteriaAnalyticsList,
      // fakeActionCriteriaAnalytics,
    },
    dispatch
  );

const RepricingBuyBoxDetailsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(RepricingBuyBoxDetails);

export default RepricingBuyBoxDetailsContainer;
