import {
  Badge,
  Button,
  Input,
  Popconfirm,
  Select,
  Switch,
  Table,
  Tag,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import IvcsrTable from "../../../../component/ivcsr-table";
import CampaignModal from "./lib/campaign-modal";
import { DefaultPerPage, SalesChannelFlag } from "../../../../config";
import RuleDetailsModal from "./lib/rule-details-modal";
import { SearchOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import Loading from "../../../../component/loading";
import NoData from "../../../../component/no-data";
import ActionLogModal from "./lib/action-log-modal";
import Pagination from "../../../../component/pagination";
import CreateBidManagementModal from "./lib/create-bid-management-modal";

export default function (props) {
  const {
    GetBidManagementList,
    GetRuleManagementDropdownListAction,
    CampaignsAnalyticsAllTableDataAction,
    fakeActionBidManagement,
    GetSalesChannelList,
    DeleteRuleAction,
    fakeActionSalesAnalytics,
  } = props;

  const GetBidManagementListRes = useSelector(
    (state) => state.BidManagement.GetBidManagementListResponse || {}
  );
  const GetSalesChannelListRes = useSelector(
    (state) => state.SalesAnalytics.GetSalesChannelListResponse || {}
  );
  const AllCompaignsDataRes = useSelector(
    (state) => state.BidManagement.AllCompaignsDataResponse || {}
  );
  const GetRuleManagementDropdownRes = useSelector(
    (state) => state.BidManagement.GetRuleManagementDropdownResponse || {}
  );
  const DeleteBidRuleRes = useSelector(
    (state) => state.BidManagement.DeleteBidRuleResponse || {}
  );

  const [openCampaignModal, setOpenCampainModal] = useState(false);
  const [openRuleDetailsModal, setOpenRuleDetailsModal] = useState(false);

  const [searchByRuleName, setSearchByRuleName] = useState("");
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageSize, setPageSize] = useState(DefaultPerPage);
  const [ruleList, setRuleList] = useState([]);
  const [List, setList] = useState([]);
  const [salesChannelList, setsalesChannelList] = useState([]);
  const [selectedSalesChannel, setSelectedSalesChannel] = useState([]);
  const [selectedSalesChannelId, setSelectedSalesChannelId] = useState();
  const [salesChannelLoading, setSalesChannelLoading] = useState(true);
  const [selectedCurrencySign, setSelectedCurrencySign] = useState("");
  const [selectedFilterStatus, setSelectedFilterStatus] = useState(null);

  const [selectedCamp, setSelectedCamp] = useState([]);
  const [campaignView, setCampaignView] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [isCloneStatus, setIsCloneStatus] = useState(false);
  const [budgetManagementView, setBudgetManagementView] = useState(false);
  const [searchTermManagementView, setSearchTermManagementView] =
    useState(false);
  const [bidManagementView, setBidManagementView] = useState(false);
  const [selectedActionLog, setSelectedActionLog] = useState({});
  const [actionLogView, setActionLogView] = useState(false);
  const [isUpdateStatus, setIsUpdateStatus] = useState(false);

  const [allCampaignList, setAllCampaignList] = useState([]);
  const [allCampaignListLoading, setAllCampaignListLoading] = useState(true);

  const [dropdownList, setDropdownList] = useState({});
  const [dropdownLoading, setDropdownLoading] = useState(true);

  useEffect(() => {
    GetSalesChannelList();
    GetRuleManagementDropdownListAction();
    const obj = { marketplace_id: "ATVPDKIKX0DER" };
    CampaignsAnalyticsAllTableDataAction(obj);
    const Obj = {
      page: page,
      perPage: pageSize,
      marketplaceId: "ATVPDKIKX0DER",
      ruleName: searchByRuleName,
    };
    GetBidManagementList(Obj);
  }, []);

  useEffect(() => {
    if (GetSalesChannelListRes?.status === true) {
      setSalesChannelLoading(false);
      setsalesChannelList(GetSalesChannelListRes?.data?.sales_channel);
      setSelectedSalesChannelId("ATVPDKIKX0DER");
      setSelectedSalesChannel("Amazon.com");
      setSelectedCurrencySign("$");
      fakeActionSalesAnalytics("GetSalesChannelListResponse");
    } else if (GetSalesChannelListRes?.status === false) {
      message.destroy();
      message.error(GetSalesChannelListRes?.message);
      fakeActionSalesAnalytics("GetSalesChannelListResponse");
    }
  }, [GetSalesChannelListRes]);

  useEffect(() => {
    if (GetBidManagementListRes?.status === true) {
      setLoading(false);
      setRuleList(
        GetBidManagementListRes?.data?.records?.map((d) => {
          if (d?.rule_type === "budget") {
            if (d?.sub_rule_list?.length === 0) return {};
            const {
              rule_name,
              budget_date_range,
              campaign_list,
              criteria,
              recurrence,
              ...rest
            } = d?.sub_rule_list?.[0];
            return {
              name: rule_name,
              ruleId: d?.id,
              budget_date_range: JSON.parse(budget_date_range),
              campaign_list: JSON.parse(campaign_list),
              criteria: JSON.parse(criteria),
              recurrence: JSON.parse(recurrence),
              ...rest,
              sub_rule_list: d?.sub_rule_list,
            };
          }
          return { ...d };
        }) || []
      );
      setTotalPage(GetBidManagementListRes?.data?.pagination?.totalCount);
      fakeActionBidManagement("GetBidManagementListResponse");
    } else if (GetBidManagementListRes?.status === false) {
      fakeActionBidManagement("GetBidManagementListResponse");
    }
  }, [GetBidManagementListRes]);

  useEffect(() => {
    if (AllCompaignsDataRes?.status === true) {
      setAllCampaignList(AllCompaignsDataRes?.data?.data?.table_data || []);
      setAllCampaignListLoading(false);
      fakeActionBidManagement("AllCompaignsDataResponse");
    } else if (AllCompaignsDataRes?.status === false) {
      setAllCampaignList([]);
      setAllCampaignListLoading(false);
      fakeActionBidManagement("AllCompaignsDataResponse");
    }
  }, [AllCompaignsDataRes]);

  useEffect(() => {
    if (GetRuleManagementDropdownRes?.status === true) {
      setDropdownList(GetRuleManagementDropdownRes?.data);
      setDropdownLoading(false);
      fakeActionBidManagement("GetRuleManagementDropdownResponse");
    } else if (GetRuleManagementDropdownRes?.status === false) {
      setDropdownList({});
      setDropdownLoading(false);
      fakeActionBidManagement("GetRuleManagementDropdownResponse");
    }
  }, [GetRuleManagementDropdownRes]);

  useEffect(() => {
    if (DeleteBidRuleRes?.status === true) {
      message.destroy();
      message.success(DeleteBidRuleRes?.message);
      setLoading(true);
      setPage(1);
      setPageSize(DefaultPerPage);
      const Obj = {
        page: 1,
        perPage: DefaultPerPage,
        marketplaceId: "ATVPDKIKX0DER",
        ruleName: searchByRuleName,
      };
      GetBidManagementList(Obj);
      fakeActionBidManagement("DeleteBidRuleResponse");
    } else if (DeleteBidRuleRes?.status === false) {
      setDropdownList({});
      setDropdownLoading(false);
      fakeActionBidManagement("DeleteBidRuleResponse");
    }
  }, [DeleteBidRuleRes]);

  // const columns = [];
  const columns = [
    {
      title: "#",

      render: (_, __, i) => {
        return <span>{(page - 1) * pageSize + 1 + i}</span>;
      },
    },

    {
      title: "Rule Name",

      render: (text) => {
        return (
          <span className=" fw-bolder text-dark">{text?.name || "-"}</span>
        );
      },
    },

    // {
    //   title: "Rule Type",

    //   render: (text) => (
    //     <div className="d-flex align-items-center">
    //       <Tag
    //         color={`${
    //           text?.rule_type === "bid"
    //             ? "blue"
    //             : text?.rule_type === "Search_term"
    //             ? "magenta"
    //             : "cyan"
    //         }`}
    //         style={{ textTransform: "capitalize" }}
    //       >
    //         {text?.rule_type?.split("_")?.join(" ") || "-"}
    //       </Tag>
    //     </div>
    //   ),
    // },

    {
      title: "Campaigns",

      render: (text) => {
        if (text?.rule_type === "budget") {
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <div className="two-line">
                {Object.values(text?.campaign_list?.[0]) || "-"}
              </div>

              <span
                className="ms-2 "
                onClick={() => {
                  setSelectedCamp(text?.campaign_list || []);
                  setCampaignView(true);
                }}
              >
                <i className="ki-outline ki-eye fs-3 text-dark" />
              </span>
            </div>
          );
        }

        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div className="two-line">
              {Object.values(JSON.parse(text?.campaign_list || [])?.[0]) || "-"}
            </div>

            <span
              className="ms-2 cursor-pointer"
              onClick={() => {
                setSelectedCamp(JSON.parse(text?.campaign_list || []));
                setCampaignView(true);
              }}
            >
              <i className="ki-outline ki-eye fs-3 text-dark" />
            </span>
          </div>
        );
      },
    },

    // {
    //   title: "Status",

    //   render: (text) => {
    //     if (
    //       text?.rule_type === "Search Term Management" ||
    //       text?.rule_type === "Bid Management" ||
    //       text?.rule_type === "bid" ||
    //       text?.rule_type === "search_term"
    //     ) {
    //       return;
    //     }

    //     return (
    //       <>
    //         <label
    //           onChange={() => {
    //             message.destroy();

    //             message.loading("Updating...", 0);

    //             UpdateRuleStatusAction(text?.id, {
    //               rule_status:
    //                 text?.rule_status === "ACTIVE" ? "INACTIVE" : "ACTIVE",
    //             });

    //             setRuleList((prev) => {
    //               return prev.map((item) => {
    //                 if (item.id === text?.id) {
    //                   return {
    //                     ...item,

    //                     rule_status:
    //                       text?.rule_status === "ACTIVE"
    //                         ? "INACTIVE"
    //                         : "ACTIVE",
    //                   };
    //                 }

    //                 return item;
    //               });
    //             });
    //           }}
    //         >
    //           <input
    //             defaultValue="on"
    //             name="dummy"
    //             type="checkbox"
    //             className="bubble"
    //             checked={text?.rule_status === "ACTIVE"}
    //           />
    //         </label>
    //       </>
    //     );
    //   },
    // },

    {
      title: "Scheduled",

      render: (text) => {
        if (!text?.next_execution_time) {
          return "-";
        }

        return (
          <span>
            {dayjs(parseInt(text?.next_execution_time) * 1000).format(
              "MMM DD, YYYY"
            )}
          </span>
        );
      },
    },

    {
      title: "Clone",

      render: (text) => (
        <span
          style={{ cursor: "pointer" }}
          onClick={() => {
            setSelectedRow(text);

            setIsCloneStatus(true);

            switch (text?.rule_type) {
              case "Budget Management":
                setSelectedRow({
                  ...text?.sub_rule_list?.[0],

                  budget_date_range: JSON.parse(
                    text?.sub_rule_list?.[0]?.budget_date_range
                  ),

                  campaign_list: JSON.parse(
                    text?.sub_rule_list?.[0]?.campaign_list
                  ),

                  criteria: JSON.parse(text?.sub_rule_list?.[0]?.criteria),

                  recurrence: JSON.parse(text?.sub_rule_list?.[0]?.recurrence),
                });

                setBudgetManagementView(true);

                break;

              case "Search Term Management":
                setSearchTermManagementView(true);

                break;

              case "Bid Management":
                setBidManagementView(true);

                break;

              case "bid":
                setBidManagementView(true);

                break;

              case "budget":
                setSelectedRow({
                  ...text?.sub_rule_list?.[0],

                  budget_date_range: JSON.parse(
                    text?.sub_rule_list?.[0]?.budget_date_range
                  ),

                  campaign_list: JSON.parse(
                    text?.sub_rule_list?.[0]?.campaign_list
                  ),

                  criteria: JSON.parse(text?.sub_rule_list?.[0]?.criteria),

                  recurrence: JSON.parse(text?.sub_rule_list?.[0]?.recurrence),
                });

                setBudgetManagementView(true);

                break;

              case "search_term":
                setSearchTermManagementView(true);

                break;

              default:
                break;
            }
          }}
        >
          <i className="fas fa-copy text-dark fs-3" />
        </span>
      ),
    },

    {
      title: "Action Log",
      render: (text) => (
        <span
          style={{ cursor: "pointer" }}
          onClick={() => {
            setSelectedActionLog({
              ...text,
              type_action: "master",
            });
            setActionLogView(true);
          }}
        >
          <i className="fas fa-history text-dark fs-3" />
        </span>
      ),
    },

    {
      title: "Action",
      render: (text) => (
        <div className="d-flex justify-content-between">
          <button
            onClick={() => {
              setSelectedRow(text);
              switch (text?.rule_type) {
                case "Budget Management":
                  setSelectedRow({
                    ...text?.sub_rule_list?.[0],
                    budget_date_range: JSON.parse(
                      text?.sub_rule_list?.[0]?.budget_date_range
                    ),
                    campaign_list: JSON.parse(
                      text?.sub_rule_list?.[0]?.campaign_list
                    ),
                    criteria: JSON.parse(text?.sub_rule_list?.[0]?.criteria),
                    recurrence: JSON.parse(
                      text?.sub_rule_list?.[0]?.recurrence
                    ),
                  });
                  setBudgetManagementView(true);
                  break;
                case "Search Term Management":
                  setSearchTermManagementView(true);
                  break;
                case "Bid Management":
                  setBidManagementView(true);
                  break;
                case "bid":
                  setBidManagementView(true);
                  break;
                case "budget":
                  setSelectedRow({
                    ...text?.sub_rule_list?.[0],
                    budget_date_range: JSON.parse(
                      text?.sub_rule_list?.[0]?.budget_date_range
                    ),
                    campaign_list: JSON.parse(
                      text?.sub_rule_list?.[0]?.campaign_list
                    ),
                    criteria: JSON.parse(text?.sub_rule_list?.[0]?.criteria),
                    recurrence: JSON.parse(
                      text?.sub_rule_list?.[0]?.recurrence
                    ),
                  });
                  setBudgetManagementView(true);
                  break;
                case "search_term":
                  setSearchTermManagementView(true);
                  break;
                default:
                  break;
              }
            }}
            className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-3"
          >
            <i className="ki-outline ki-eye fs-2 text-primary" />
          </button>
          <a
            style={{ cursor: "pointer" }}
            onClick={() => {
              setSelectedRow(text);
              setIsUpdateStatus(true);
              switch (text?.rule_type) {
                case "Budget Management":
                  setSelectedRow({
                    ...text?.sub_rule_list?.[0],
                    budget_date_range: JSON.parse(
                      text?.sub_rule_list?.[0]?.budget_date_range
                    ),
                    campaign_list: JSON.parse(
                      text?.sub_rule_list?.[0]?.campaign_list
                    ),
                    criteria: JSON.parse(text?.sub_rule_list?.[0]?.criteria),
                    recurrence: JSON.parse(
                      text?.sub_rule_list?.[0]?.recurrence
                    ),
                  });
                  setBudgetManagementView(true);
                  break;
                case "Search Term Management":
                  setSearchTermManagementView(true);
                  break;
                case "Bid Management":
                  setBidManagementView(true);
                  break;
                case "bid":
                  setBidManagementView(true);
                  break;
                case "budget":
                  setSelectedRow({
                    ...text?.sub_rule_list?.[0],
                    budget_date_range: JSON.parse(
                      text?.sub_rule_list?.[0]?.budget_date_range
                    ),
                    campaign_list: JSON.parse(
                      text?.sub_rule_list?.[0]?.campaign_list
                    ),
                    criteria: JSON.parse(text?.sub_rule_list?.[0]?.criteria),
                    recurrence: JSON.parse(
                      text?.sub_rule_list?.[0]?.recurrence
                    ),
                  });
                  setBudgetManagementView(true);
                  break;
                case "search_term":
                  setSearchTermManagementView(true);
                  break;
                default:
                  break;
              }
            }}
            className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-3"
          >
            <i className="ki-outline ki-pencil fs-2 text-primary" />
          </a>
          <Popconfirm
            title="Delete the task"
            description="Are you sure to delete this rule?"
            onConfirm={() => {
              message.destroy();
              message.loading("Loading...", 0);
              if (text?.rule_type === "budget") {
                // DeleteRuleAction(text?.ruleId);
              } else {
                DeleteRuleAction(text?.id);
              }
              setRuleList((prev) =>
                prev?.filter((d) => parseInt(d?.id) !== parseInt(text?.id))
              );
            }}
            okText="Yes"
            cancelText="No"
            placement="left"
          >
            <span className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
              <i className="ki-outline ki-trash fs-2 text-danger" />
            </span>
          </Popconfirm>
        </div>
      ),
    },
  ];

  // const columnsSub = [
  //   {
  //     title: "#",
  //     width: 58,
  //     render: (text) => {
  //       return <span>{text?.key || 1}</span>;
  //     },
  //   },
  //   {
  //     title: "Sub Rule Name",
  //     width: 280,
  //     render: (text) => (
  //       <span className=" fw-bolder text-dark">{text?.rule_name || "-"}</span>
  //     ),
  //   },
  //   {
  //     title: "Campaigns",
  //     width: 205,
  //     render: (text) => {
  //       return (
  //         <div style={{ display: "flex", alignItems: "center" }}>
  //           <div className="two-line" style={{ wordBreak: "break-all" }}>
  //             {Object.values(JSON.parse(text?.campaign_list || [])?.[0]) || "-"}
  //           </div>
  //           <span
  //             className="ms-2 "
  //             onClick={() => {
  //               setSelectedCamp(JSON.parse(text?.campaign_list || []));
  //               setCampaignView(true);
  //             }}
  //           >
  //             <i className="ki-outline ki-eye fs-3 text-dark" />
  //           </span>
  //         </div>
  //       );
  //     },
  //   },
  //   {
  //     title: "Status",
  //     width: 70,
  //     render: (text) => {
  //       return (
  //         <>
  //           <label
  //             onChange={() => {
  //               message.destroy();
  //               message.loading("Updating...", 0);
  //               // UpdateSubRuleStatusAction(text?.id, {
  //               //   rule_status:
  //               //     text?.rule_status === "ACTIVE" ? "INACTIVE" : "ACTIVE",
  //               // });
  //               setRuleList((prev) => {
  //                 return prev.map((item) => {
  //                   if (item.id === text?.id) {
  //                     return {
  //                       ...item,
  //                       rule_status:
  //                         text?.rule_status === "ACTIVE"
  //                           ? "INACTIVE"
  //                           : "ACTIVE",
  //                     };
  //                   }
  //                   return item;
  //                 });
  //               });
  //             }}
  //           >
  //             <input
  //               defaultValue="on"
  //               name="dummy"
  //               type="checkbox"
  //               className="bubble"
  //               checked={text?.rule_status === "ACTIVE"}
  //             />
  //           </label>
  //         </>
  //       );
  //     },
  //   },
  //   {
  //     title: "Scheduled",
  //     width: 165,
  //     render: (text) => {
  //       if (!text?.next_execution_time) {
  //         return "-";
  //       }
  //       return (
  //         <span>
  //           {dayjs(parseInt(text?.next_execution_time) * 1000).format(
  //             "MMM DD, YYYY"
  //           )}
  //         </span>
  //       );
  //     },
  //   },
  //   {
  //     title: "Action Log",
  //     width: 95,
  //     render: (text) => (
  //       <span
  //         className
  //         onClick={() => {
  //           setSelectedActionLog({
  //             ...text,
  //             type_action: "",
  //           });
  //           // message.destroy();
  //           // message.loading("loading...", 0);
  //           // GetAppliedRuleAction("master", text?.id);
  //           setActionLogView(true);
  //         }}
  //       >
  //         <i className="fas fa-history text-dark fs-3" />
  //       </span>
  //     ),
  //   },
  //   {
  //     title: "Action",
  //     align: "left",
  //     width: 125,
  //     render: (text) => (
  //       <div className="d-flex flex-shrink-0 justify-content-center">
  //         <Popconfirm
  //           title="Delete the task"
  //           description="Are you sure to delete this task?"
  //           onConfirm={() => {
  //             message.destroy();
  //             message.loading("Loading...", 0);
  //             // DeleteRuleAction(text?.id, "sub");
  //             setRuleList((prev) =>
  //               prev?.map((d) => ({
  //                 ...d,
  //                 sub_rule_list: d?.sub_rule_list?.filter(
  //                   (t) => parseInt(t?.id) !== parseInt(text?.id)
  //                 ),
  //               }))
  //             );
  //           }}
  //           okText="Yes"
  //           cancelText="No"
  //           placement="left"
  //         >
  //           <a
  //             href="#"
  //             className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
  //           >
  //             <i className="ki-outline ki-trash fs-2 text-danger" />
  //           </a>
  //         </Popconfirm>
  //       </div>
  //     ),
  //   },
  // ];

  const onPageNo = (e) => {
    setLoading(true);
    const Obj = {
      page: e,
      perPage: pageSize,
      marketplaceId: "ATVPDKIKX0DER",
      ruleName: searchByRuleName,
    };
    GetBidManagementList(Obj);
    setPage(e);
  };

  const onPerPage = (e) => {
    setPage(1);
    setPageSize(e);
    setLoading(true);
    const Obj = {
      page: 1,
      perPage: e,
      marketplaceId: "ATVPDKIKX0DER",
      ruleName: searchByRuleName,
    };
    GetBidManagementList(Obj);
  };

  return (
    <>
      <div className="d-flex flex-column flex-column-fluid fadeInRight">
        {/*begin::Content*/}
        <div id="kt_app_content" className="app-content flex-column-fluid">
          {/*begin::Content container*/}
          <div
            id="kt_app_content_container"
            className="app-container container-fluid"
          >
            {/*begin::Row*/}
            <div className="row gy-5 g-xl-5">
              {/*begin::Col*/}
              <div className="col-xxl-12">
                {/*begin::Tables Widget 9*/}
                <div className="card card-xxl-stretch mb-5 mb-xl-8">
                  {/*begin::Header*/}
                  <div className="card-header border-bottom-dashed">
                    <div className="d-flex flex-stack flex-wrap">
                      <div className="position-relative">
                        <Input
                          type="text"
                          className="text-dark w-250px fs-7 fw-bold ant_common_input"
                          name="all_search"
                          placeholder="Search by Rule Name"
                          value={searchByRuleName}
                          size="large"
                          onChange={(e) => {
                            setSearchByRuleName(e.target.value);
                          }}
                          onPressEnter={(e) => {
                            setLoading(true);
                            const Obj = {
                              page: page,
                              perPage: pageSize,
                              marketplaceId: "ATVPDKIKX0DER",
                              ruleName: e.target.value,
                            };
                            GetBidManagementList(Obj);
                          }}
                          prefix={
                            <SearchOutlined
                              style={{ fontSize: "19px", color: "grey" }}
                            />
                          }
                        />
                      </div>
                      {/* <div className="ms-3 ">
                        <Select
                          options={[
                            { label: "Active", value: "Active" },
                            { label: "Disabled", value: "Disabled" },
                          ]}
                          value={selectedFilterStatus}
                          size="large"
                          onChange={(e) => {
                            setSelectedFilterStatus(e);
                          }}
                          placeholder="Select Status"
                          style={{ width: "150px" }}
                        />
                      </div> */}
                    </div>
                    <div className="card-toolbar ">
                      <div className="position-relative me-2">
                        <Select
                          className="w-250px"
                          size="large"
                          placeholder="Select Sales Channel"
                          loading={salesChannelLoading}
                          value={selectedSalesChannelId || null}
                          onChange={(e, _) => {
                            // console.log(_, "_");
                            setSelectedSalesChannel(_?.sales_channel);
                            setSelectedCurrencySign(_?.currency_sign);
                            setSelectedSalesChannelId(e);
                          }}
                          options={SalesChannelFlag(salesChannelList)}
                        />
                      </div>
                      <button
                        className="btn btn-primary fs-7"
                        onClick={() => {
                          setBidManagementView(true);
                        }}
                      >
                        Add New Rule
                      </button>
                    </div>
                  </div>
                  {/*end::Header*/}
                  {/*begin::Body*/}
                  <div className="card-body py-2">
                    <div className="table-responsive tbl-sales">
                      {/*begin::Table*/}
                      {loading ? (
                        <Loading />
                      ) : ruleList?.length != 0 ? (
                        <IvcsrTable
                          columns={columns}
                          fixed="top"
                          dataSource={ruleList}
                          rowKey="key"
                          // loading={loading}
                          pagination={false}
                          scroll={{ x: "max-content" }}
                          // expandable={{
                          //   rowExpandable: (record) =>
                          //     record.rule_type !== "budget",
                          //   expandedRowRender: (record) => {
                          //     return (
                          //       <Table
                          //         dataSource={record?.sub_rule_list?.map(
                          //           (d, i) => ({ ...d, key: i + 1 })
                          //         )}
                          //         columns={columnsSub}
                          //         rowKey="key"
                          //         pagination={false}
                          //         scroll={{ x: 900 }}
                          //       />
                          //     );
                          //   },
                          // }}
                        />
                      ) : (
                        <NoData type="new" />
                      )}

                      {/*end::Table*/}
                    </div>
                    <Pagination
                      loading={loading || ruleList?.length === 0}
                      pageSize={pageSize}
                      page={page}
                      totalPage={totalPage}
                      onPerPage={onPerPage}
                      onPageNo={onPageNo}
                    />
                    {/* <div>pagination</div> */}
                  </div>
                  {/*begin::Body*/}
                </div>
                {/*end::Tables Widget 9*/}
              </div>
              {/*end::Col*/}
            </div>
            {/*end::Row*/}
          </div>
          {/*end::Content container*/}
        </div>
        {/*end::Content*/}
      </div>

      {campaignView && (
        <CampaignModal
          selectedCamp={selectedCamp}
          show={campaignView}
          onHide={() => setCampaignView(false)}
        />
      )}

      {actionLogView && (
        <ActionLogModal
          show={actionLogView}
          selectedActionLog={selectedActionLog}
          onHide={() => {
            setActionLogView(false);
            setSelectedActionLog({});
          }}
          {...props}
        />
      )}

      {bidManagementView && (
        <CreateBidManagementModal
          visible={bidManagementView}
          allCampaignList={allCampaignList}
          dropdownList={dropdownList}
          dropdownLoading={dropdownLoading}
          selectedRow={selectedRow}
          allCampaignListLoading={allCampaignListLoading}
          isCloneStatus={isCloneStatus}
          isUpdateStatus={isUpdateStatus}
          selectedMarketplace={selectedSalesChannelId}
          handleCancel={() => {
            setSelectedRow({});
            setBidManagementView(false);
            setIsCloneStatus(false);
            setIsUpdateStatus(false);
          }}
          SIGN={selectedCurrencySign}
          getList={() => {
            setLoading(true);
            setRuleList([]);
            const Obj = {
              page: page,
              perPage: pageSize,
              marketplaceId: "ATVPDKIKX0DER",
              ruleName: searchByRuleName,
            };
            GetBidManagementList(Obj);
          }}
          {...props}
        />
      )}
      {/* {openCampaignModal && (
        <CampaignModal
          show={openCampaignModal}
          onHide={() => {
            setOpenCampainModal(false);
          }}
        />
      )}
      {openRuleDetailsModal && (
        <RuleDetailsModal
          show={openRuleDetailsModal}
          onHide={() => {
            setOpenRuleDetailsModal(false);
          }}
        />
      )} */}
    </>
  );
}
