import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import IvcsrTable from "../../../../component/ivcsr-table";
import { NumberWithCommas } from "../../../../config";

const PlatformPerformance = (props) => {
  const { list } = props;

  const columns = [
    {
      title: "Platform",
      dataIndex: "platform",
      key: "platform",
      render: (text) => {
        return (
          <label className="fw-bold" style={{ textTransform: "capitalize" }}>
            {text}
          </label>
        );
      },
    },
    {
      title: "Impressions",
      dataIndex: "impression",
      key: "impression",
      render: (text) => {
        return NumberWithCommas(text || 0);
      },
    },
    {
      title: "Clicks",
      dataIndex: "ad_clicks",
      key: "ad_clicks",
      render: (text) => {
        return NumberWithCommas(text || 0);
      },
    },
    // {
    //   title: "Units Sold",
    //   dataIndex: "units",
    //   key: "units",
    //   render: (text) => {
    //     return NumberWithCommas(text || 0);
    //   },
    // },
    // {
    //   title: "Orders",
    //   dataIndex: "orders",
    //   key: "orders",
    //   render: (text) => {
    //     return NumberWithCommas(text || 0);
    //   },
    // },
    {
      title: "CTR",
      dataIndex: "CTR",
      key: "CTR",
      render: (text) => {
        return <label>{NumberWithCommas(text || 0)}%</label>;
      },
    },
    // {
    //   title: "Conversion Rate (Units Sold)",
    //   dataIndex: "CRUnitSold",
    //   key: "CRUnitSold",
    //   render: (text) => {
    //     return <label>{NumberWithCommas(text || 0)}%</label>;
    //   },
    // },
    // {
    //   title: "Conversion Rate (Orders Sold)",
    //   dataIndex: "CROrderSold",
    //   key: "CROrderSold",
    //   render: (text) => {
    //     return <label>{NumberWithCommas(text || 0)}%</label>;
    //   },
    // },
    {
      title: "Ad Spend",
      dataIndex: "ad_spend",
      key: "ad_spend",
      render: (text) => {
        return <label>${NumberWithCommas(text || 0)}</label>;
      },
    },
    {
      title: "Total Attr. Sales Revenue",
      dataIndex: "totalAttrSalesRevenue",
      key: "totalAttrSalesRevenue",
      render: (text) => {
        return NumberWithCommas(text || 0);
      },
    },
    // {
    //   title: "Advertised SKU Sales",
    //   dataIndex: "advertisedSkuSales",
    //   key: "advertisedSkuSales",
    //   render: (text) => {
    //     return <label>${NumberWithCommas(text || 0)}</label>;
    //   },
    // },
    // {
    //   title: "Other SKU Sales",
    //   dataIndex: "otherSkuSales",
    //   key: "otherSkuSales",
    //   render: (text) => {
    //     return <label>${NumberWithCommas(text || 0)}</label>;
    //   },
    // },
    {
      title: "RoAS",
      dataIndex: "ROAS",
      key: "ROAS",
      render: (text) => {
        return NumberWithCommas(text || 0);
      },
    },
    {
      title: "In-store Attributed Sales (Beta)",
      dataIndex: "in_store",
      key: "in_store",
      render: (text) => {
        return NumberWithCommas(text || 0);
      },
    },
    // {
    //   title: "New-to-Brand Orders",
    //   dataIndex: "newToBrandOrders",
    //   key: "newToBrandOrders",
    // },
    // {
    //   title: "% of New-to-Brand Orders",
    //   dataIndex: "percentOfNewToBrandOrders",
    //   key: "percentOfNewToBrandOrders",
    // },
    // {
    //   title: "New-to-Brand Sales",
    //   dataIndex: "newToBrandSales",
    //   key: "newToBrandSales",
    // },
    // {
    //   title: "% of New-to-Brand Sales",
    //   dataIndex: "percentOfNewToBrandSales",
    //   key: "percentOfNewToBrandSales",
    // },
  ];

  return (
    <div className="mt-5">
      <IvcsrTable
        dataSource={list}
        columns={columns}
        scroll={{ x: "max-content" }}
        pagination={false}
      />
    </div>
  );
};

export default PlatformPerformance;
