import { Alert, Checkbox, Col, Input, Row, Select, message } from "antd";
import React, { useEffect, useState } from "react";
import CampaignCard from "./lib/campaign-card";
import CampaignGraph from "./lib/campaign-graph";
import CampaignList from "./lib/campaign-list";
import CampaignPerformance from "./lib/campaign-performance";
import RangePickerIVCSR from "../../../../../component/range-picker";
import dayjs from "dayjs";
import {
  DateFormat,
  DefaultPerPage,
  SalesChannelFlag,
  handleDateRangeChange,
} from "../../../../../config";
import { useSelector } from "react-redux";
import moment from "moment";

export default function (props) {
  const {
    GetSalesChannelList,
    fakeActionSalesAnalytics,
    GetAmazonCampaignFilters,
    GetAmazonPerformaceMetrics,
    GetAmazonCampaignGraph,
    GetAmazonCampaignTable,
    GetAmazonDayWiseCampaignList,
    fakeActionCampaignManagement,
  } = props;
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageSize, setPageSize] = useState(DefaultPerPage);
  const [filters, setFilters] = useState({
    start_date: dayjs().add(-7, "d"),
    end_date: dayjs(),
  });
  const [selectedDateRangeType, setSelectedDateRangeType] = useState("custom");

  const [salesChannelList, setsalesChannelList] = useState([]);
  const [selectedSalesChannel, setSelectedSalesChannel] = useState([]);
  const [selectedSalesChannelId, setSelectedSalesChannelId] = useState();
  const [salesChannelLoading, setSalesChannelLoading] = useState(true);
  const [selectedCurrencySign, setSelectedCurrencySign] = useState("");
  const [advTypeFilter, setAdvTypeFilter] = useState([]);
  const [selectedAdvType, setSelectedAdvType] = useState([]);
  const [performanceMetricsData, setPerformanceMetricsData] = useState([]);
  const [campaignGraphSeries, setCampaignGraphSeries] = useState([]);
  const [campaignGraphDate, setCampaignGraphDate] = useState([]);
  const [camapginTableList, setCamapginTableList] = useState([]);
  const [originalCamapginTableList, setOriginalCamapginTableList] = useState(
    []
  );
  const [dayWiseCamapginTableList, setDayWiseCamapginTableList] = useState([]);
  const [selectedTrendChartFilter, setSelectedTrendChartFilter] = useState([
    "sales",
    "acos",
    "tacos",
    "spend",
  ]);
  const [performanceMetricsLoading, setPerformanceMetricsLoading] =
    useState(true);
  const [graphLoading, setGraphLoading] = useState(true);
  const [campaignTableListLoading, setCampaignTableListLoading] =
    useState(true);

  const GetSalesChannelListRes = useSelector(
    (state) => state.SalesAnalytics.GetSalesChannelListResponse || {}
  );

  const GetAmazonFiltersRes = useSelector(
    (state) => state.CampaignManagement.GetAmazonFiltersResponse || {}
  );

  const GetAmazonPerformanceMetricsRes = useSelector(
    (state) =>
      state.CampaignManagement.GetAmazonPerformanceMetricsResponse || {}
  );

  const GetAmazonCampaignGraphRes = useSelector(
    (state) => state.CampaignManagement.GetAmazonCampaignGraphResponse || {}
  );

  const GetCampaignListTableRes = useSelector(
    (state) => state.CampaignManagement.GetCampaignListTableResponse || {}
  );
  const GetDayWiseCampaignListTableRes = useSelector(
    (state) =>
      state.CampaignManagement.GetDayWiseCampaignListTableResponse || {}
  );

  useEffect(() => {
    GetSalesChannelList();
  }, []);

  useEffect(() => {
    if (GetSalesChannelListRes?.status === true) {
      setSalesChannelLoading(false);
      setsalesChannelList(GetSalesChannelListRes?.data?.sales_channel);
      setSelectedSalesChannelId("ATVPDKIKX0DER");
      setSelectedSalesChannel("Amazon.com");
      setSelectedCurrencySign("$");
      GetAmazonCampaignFilters();
      fakeActionSalesAnalytics("GetSalesChannelListResponse");
    } else if (GetSalesChannelListRes?.status === false) {
      message.destroy();
      message.error(GetSalesChannelListRes?.message);
      fakeActionSalesAnalytics("GetSalesChannelListResponse");
    }
  }, [GetSalesChannelListRes]);

  useEffect(() => {
    if (GetAmazonFiltersRes?.status === true) {
      setAdvTypeFilter(
        Object.entries(GetAmazonFiltersRes?.data?.advertising_type_data)?.map(
          (d) => {
            return { label: d?.[1], value: d?.[0] };
          }
        )
      );
      setSelectedAdvType(["SB", "SP", "SBV"]);
      const Obj = {
        start_date: moment(filters?.start_date?.$d).format("YYYY-MM-DD"),
        end_date: moment(filters?.end_date?.$d).format("YYYY-MM-DD"),
        advertising_type: ["SB", "SP", "SBV"],
        marketplace_id: selectedSalesChannelId,
        page: page,
        perPage: pageSize,
      };
      GetAmazonPerformaceMetrics(Obj);
      GetAmazonCampaignGraph(Obj);
      GetAmazonCampaignTable(Obj);
      GetAmazonDayWiseCampaignList(Obj);
      fakeActionCampaignManagement("GetAmazonFiltersResponse");
    } else if (GetAmazonFiltersRes?.status === false) {
      message.destroy();
      message.error(GetAmazonFiltersRes?.message);
      fakeActionCampaignManagement("GetAmazonFiltersResponse");
    }
  }, [GetAmazonFiltersRes]);

  useEffect(() => {
    if (GetAmazonPerformanceMetricsRes?.status === true) {
      setPerformanceMetricsLoading(false);
      setPerformanceMetricsData(GetAmazonPerformanceMetricsRes?.data);
      fakeActionCampaignManagement("GetAmazonPerformanceMetricsResponse");
    } else if (GetAmazonPerformanceMetricsRes?.status === false) {
      message.destroy();
      message.error(GetAmazonPerformanceMetricsRes?.message);
      setPerformanceMetricsLoading(false);
      fakeActionCampaignManagement("GetAmazonPerformanceMetricsResponse");
    }
  }, [GetAmazonPerformanceMetricsRes]);

  useEffect(() => {
    if (GetCampaignListTableRes?.status === true) {
      setCampaignTableListLoading(false);
      setCamapginTableList(GetCampaignListTableRes?.data?.data?.table_data);
      setOriginalCamapginTableList(
        GetCampaignListTableRes?.data?.data?.table_data
      );
      fakeActionCampaignManagement("GetCampaignListTableResponse");
    } else if (GetCampaignListTableRes?.status === false) {
      message.destroy();
      message.error(GetCampaignListTableRes?.message);
      setCampaignTableListLoading(false);
      fakeActionCampaignManagement("GetCampaignListTableResponse");
    }
  }, [GetCampaignListTableRes]);

  useEffect(() => {
    if (GetDayWiseCampaignListTableRes?.status === true) {
      setCampaignTableListLoading(false);
      const finalData = Object?.entries(
        GetDayWiseCampaignListTableRes?.data?.data?.table_data
      )?.map((d) => {
        return { ...d?.[1] };
      });
      setDayWiseCamapginTableList(finalData);
      fakeActionCampaignManagement("GetDayWiseCampaignListTableResponse");
    } else if (GetDayWiseCampaignListTableRes?.status === false) {
      message.destroy();
      message.error(GetDayWiseCampaignListTableRes?.message);
      setCampaignTableListLoading(false);
      fakeActionCampaignManagement("GetDayWiseCampaignListTableResponse");
    }
  }, [GetDayWiseCampaignListTableRes]);

  useEffect(() => {
    if (GetAmazonCampaignGraphRes?.status === true) {
      setGraphLoading(false);
      console.log(GetAmazonCampaignGraphRes, "GetAmazonCampaignGraphRes");
      const OriginalData = GetAmazonCampaignGraphRes?.data?.data;
      const chartxAxis = OriginalData?.map((d, i) => {
        return d?.report_date;
      });
      const seriesData = selectedTrendChartFilter?.map((a, j) => {
        const localData = OriginalData?.map((d, i) => {
          return parseFloat(d[a]);
        });
        return {
          name: a,
          data: localData,
          type: a === "spend" || a === "tacos" ? "line" : "column",
        };
      });
      setCampaignGraphSeries(seriesData);
      setCampaignGraphDate(chartxAxis);
      fakeActionCampaignManagement("GetAmazonCampaignGraphResponse");
    } else if (GetAmazonCampaignGraphRes?.status === false) {
      message.destroy();
      message.error(GetAmazonCampaignGraphRes?.message);
      setGraphLoading(false);
      fakeActionCampaignManagement("GetAmazonCampaignGraphResponse");
    }
  }, [GetAmazonCampaignGraphRes]);

  const onPageNo = (e) => {
    const Obj = {
      marketplace_id: selectedSalesChannelId,
      page: e,
      perPage: pageSize,
    };
    setPage(e);
    GetAmazonCampaignTable(Obj);
  };

  const onPerPage = (e) => {
    setPage(1);
    setPageSize(e);
    const Obj = {
      marketplace_id: selectedSalesChannelId,
      page: 1,
      perPage: e,
    };
    GetAmazonCampaignTable(Obj);
  };

  return (
    <>
      <div className="row gy-5 g-xl-5 fadeOutBottom">
        <div className="col-12 d-flex justify-content-between">
          <div className="d-flex">
            <RangePickerIVCSR
              className="ant_common_input"
              id="ivcsr_date_picker"
              size="large"
              allowClear={false}
              style={{ width: "290px" }}
              format={DateFormat}
              value={[filters?.start_date, filters?.end_date]}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              onChange={(e, _) => {
                const filterKey = handleDateRangeChange(_, e);
                console.log(filterKey, "filterKey-----");
                setSelectedDateRangeType(filterKey);
                const filters_ = filters;
                filters_.start_date = e?.[0];
                filters_.end_date = e?.[1];
                setFilters({ ...filters_ });

                const Obj = {
                  start_date: moment(e?.[0]?.$d).format("YYYY-MM-DD"),
                  end_date: moment(e?.[1]?.$d).format("YYYY-MM-DD"),
                  advertising_type: selectedAdvType,
                  marketplace_id: selectedSalesChannelId,
                };
                GetAmazonPerformaceMetrics(Obj);
                GetAmazonCampaignGraph(Obj);
                GetAmazonCampaignTable(Obj);
                GetAmazonDayWiseCampaignList(Obj);
                // setTopSearchTermLoading(true);
                // const topSearchTerm = {
                //   marketplace_id: selectedadvSalesChannel,
                //   start_date: moment(e?.[0]?.$d).format("YYYY-MM-DD"),
                //   end_date: moment(e?.[1]?.$d).format("YYYY-MM-DD"),
                // };
                // GetTopSearchTermData(topSearchTerm);
              }}
            />
            <div
              className="d-flex align-items-center ms-2 px-3 me-2"
              style={
                {
                  // border: "1px solid #d3cdff",
                  // borderRadius: "5px",
                  // background: "#f5f3ff",
                }
              }
            >
              <label className="fw-bold fs-5 me-2">Filter Type :</label>
              <label
                className="fw-bolder fs-4"
                style={{
                  color: "#2e1e75",
                  textTransform: "capitalize",
                }}
              >
                {selectedDateRangeType?.replace("_", " ")}
              </label>
            </div>
            <div className="position-relative">
              <Select
                placeholder="Select Option"
                size="large"
                mode="multiple"
                maxTagCount="responsive"
                style={{ width: "250px" }}
                value={selectedAdvType}
                options={advTypeFilter?.map((d) => {
                  return { label: d?.label, value: d?.value };
                })}
                onChange={(e) => {
                  setSelectedAdvType(e);
                  const Obj = {
                    start_date: moment(filters?.start_date?.$d).format(
                      "YYYY-MM-DD"
                    ),
                    end_date: moment(filters?.end_date?.$d).format(
                      "YYYY-MM-DD"
                    ),
                    advertising_type: e,
                  };

                  GetAmazonPerformaceMetrics(Obj);
                  GetAmazonCampaignGraph(Obj);
                }}
              />
            </div>
          </div>
          <div className="position-relative">
            <Select
              className="w-250px"
              size="large"
              placeholder="Select Sales Channel"
              loading={salesChannelLoading}
              value={selectedSalesChannelId || null}
              onChange={(e, _) => {
                console.log(_, "_");
                setSelectedSalesChannel(_?.sales_channel);
                setSelectedCurrencySign(_?.currency_sign);
                setSelectedSalesChannelId(e);
              }}
              options={SalesChannelFlag(salesChannelList)}
            />
          </div>
        </div>

        <CampaignCard
          {...props}
          cardData={performanceMetricsData}
          loading={performanceMetricsLoading}
        />
        <CampaignGraph
          {...props}
          chartDate={campaignGraphDate}
          chartSeries={campaignGraphSeries}
          loading={graphLoading}
        />
        <CampaignList
          {...props}
          page={page}
          pageSize={pageSize}
          totalPage={totalPage}
          onPageNo={onPageNo}
          onPerPage={onPerPage}
          loading={campaignTableListLoading}
          list={camapginTableList}
          originalList={originalCamapginTableList}
          marketplace_id={selectedSalesChannelId}
          setList={(data) => {
            setCamapginTableList(data);
          }}
        />
        <CampaignPerformance {...props} list={dayWiseCamapginTableList} />
      </div>
    </>
  );
}
