import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import BidManagement from "../../../modules/pages/advertisement/rule-management";

import {
  GetBidManagementList,
  GetApplierRuleLogsList,
  CreateRuleAction,
  GetRuleManagementDropdownListAction,
  CampaignsAnalyticsAllTableDataAction,
  DeleteRuleAction,
  fakeActionBidManagement,
} from "../../../redux/modules/advertisement/rule-management/index.action";

import {
  GetWalmartBidManagementList,
  DeleteWalmartRuleAction,
  CreateWalmartRuleAction,
  UpdateWalmartRuleStatusAction,
  GetWalmartAppliedRuleLogList,
  fakeActionWalmartBidManagement,
} from "../../../redux/modules/advertisement/rule-management/walmart-bid/walmart-rule-action";

import {
  GetSalesChannelList,
  fakeActionSalesAnalytics,
} from "../../../redux/modules/sales-analytics/sales-analytics.action";

const mapStateToProps = (state) => ({
  GetBidManagementListResponse:
    state.BidManagement.GetBidManagementListResponse,
  GetAppliedRuleLogListResponse:
    state.BidManagement.GetAppliedRuleLogListResponse,
  CreateNewBidManagementResponse:
    state.BidManagement.CreateNewBidManagementResponse,
  UpdateWalmartRuleStatusResponse:
    state.BidManagement.UpdateWalmartRuleStatusResponse,
  DeleteBidRuleResponse: state.BidManagement.DeleteBidRuleResponse,

  GetWalmartBidManagementListResponse:
    state.WalmartBidManagement.GetWalmartBidManagementListResponse,
  CreateWalmartRuleActionResponse:
    state.WalmartBidManagement.CreateWalmartRuleActionResponse,
  DeleteWalmartBidRuleResponse:
    state.WalmartBidManagement.DeleteWalmartBidRuleResponse,
  GetWalmartAppliedRuleLogListResponse:
    state.WalmartBidManagement.GetWalmartAppliedRuleLogListResponse,

  GetSalesChannelListResponse: state.SalesAnalytics.GetSalesChannelListResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      GetBidManagementList,
      GetApplierRuleLogsList,
      CreateRuleAction,
      GetRuleManagementDropdownListAction,
      CampaignsAnalyticsAllTableDataAction,
      DeleteRuleAction,
      fakeActionBidManagement,

      GetWalmartBidManagementList,
      DeleteWalmartRuleAction,
      CreateWalmartRuleAction,
      UpdateWalmartRuleStatusAction,
      GetWalmartAppliedRuleLogList,
      fakeActionWalmartBidManagement,

      GetSalesChannelList,
      fakeActionSalesAnalytics,
    },
    dispatch
  );

const BidManagementContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(BidManagement);

export default BidManagementContainer;
