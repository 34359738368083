import React, { useEffect } from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import Loading from "../../../../../component/loading";
import NoData from "../../../../../component/no-data";
import IvcsrTable from "../../../../../component/ivcsr-table";
import moment from "moment";
import Pagination from "../../../../../component/pagination";
import { Popover, Tag } from "antd";
export default function ({
  show,
  onHide,
  GetWmItemRecommendation,
  item_recomm_filter,
  set_item_recomm_filter,
  item_recommendation_list,
  item_recommendation_total_record,
}) {
  useEffect(() => {
    setLoading(false);
  }, [item_recommendation_list]);

  const [loading, setLoading] = useState(false);
  const onPageNo = (e) => {
    setLoading(true);
    const apiObj = {
      page: e,
      perPage: item_recomm_filter?.perPage,
    };
    GetWmItemRecommendation(apiObj);
    set_item_recomm_filter({ ...item_recomm_filter, page: e });
  };

  const onPerPage = (e) => {
    set_item_recomm_filter({ ...item_recomm_filter, page: 1, perPage: e });
    setLoading(true);
    const apiObj = {
      page: 1,
      perPage: e,
    };
    GetWmItemRecommendation(apiObj);
  };

  const columns = [
    {
      title: "ID",
      render: (text) => {
        return (
          <span>
            {(item_recomm_filter?.page - 1) * item_recomm_filter?.perPage +
              1 +
              text.key}
          </span>
        );
      },
    },
    {
      title: "Item ID",
      dataIndex: "item_id",
      key: "item_id",
    },
    {
      title: "Item Name",
      dataIndex: "item_name",
      key: "item_name",
      render: (text) => {
        return (
          <Popover
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
            content={
              <div
                style={{
                  height: "auto",
                  width: "300px",
                }}
              >
                {text}
              </div>
            }
            placement="bottom"
          >
            <span className="text-gray-800 fs-6 text-hover-info mb-1 text-two ">
              {text}
            </span>
          </Popover>
        );
      },
    },
    {
      title: "Suggested Bid",
      dataIndex: "suggested_bid",
      key: "suggested_bid",
    },
    {
      title: "Brand Name",
      dataIndex: "brand_name",
      key: "brand_name",
    },
    {
      title: "Department Name",
      dataIndex: "department_name",
      key: "department_name",
    },
    {
      title: "Super Department Name",
      dataIndex: "super_department_name",
      key: "super_department_name",
    },

    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      render: (text) => text || "-",
    },
    {
      title: "Sub Category",
      dataIndex: "sub_category",
      key: "sub_category",
      render: (text) => text || "-",
    },
    // {
    //   title: "Marketplace ID",
    //   dataIndex: "marketplace_id",
    //   key: "marketplace_id",
    // },
    // {
    //   title: "Marketplace",
    //   dataIndex: "marketplace",
    //   key: "marketplace",
    // },
    // {
    //   title: "Advertiser ID",
    //   dataIndex: "advertiser_id",
    //   key: "advertiser_id",
    // },
    // {
    //   title: "Created At",
    //   dataIndex: "created_at",
    //   key: "created_at",
    //   render: (timestamp) =>
    //     moment(new Date(timestamp * 1000)).format("MMM DD, YYYY"), // Convert timestamp to date
    // },
    // {
    //   title: "Updated At",
    //   dataIndex: "updated_at",
    //   key: "updated_at",
    //   render: (timestamp) =>
    //     moment(new Date(timestamp * 1000)).format("MMM DD, YYYY"), // Convert timestamp to date
    // },
  ];

  return (
    <>
      <Modal show={show} onHide={onHide} size="xl" centered backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>
            <label className="fs-2">Item Recommendation</label>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <div className="row">
              <div className="col-12 mb-10 d-flex justify-content-end">
                <div className="fw-bold fs-4">
                  Total Item Recommendation :
                  <Tag color="success" className="fs-2 ms-2">
                    {item_recommendation_total_record}
                  </Tag>
                </div>
              </div>
              <div className="col-12">
                <div className="table-responsive">
                  {loading ? (
                    <Loading style={{ height: "500px" }} />
                  ) : item_recommendation_list?.length > 0 ? (
                    <IvcsrTable
                      columns={columns?.map((d) => ({
                        ...d,
                        width:
                          d?.title === "Item Name"
                            ? 300
                            : d?.title === "System Event Process ID"
                            ? 220
                            : d?.title === "Super Department Name"
                            ? 200
                            : d?.title?.length * 12,
                      }))}
                      scroll={{
                        x: "max-content",
                        y: 500,
                      }}
                      fixed={true}
                      dataSource={item_recommendation_list}
                      rowKey="key"
                      loading={loading}
                      pagination={false}
                    />
                  ) : (
                    <NoData type="new" />
                  )}
                </div>
                <Pagination
                  loading={loading || item_recommendation_list?.length === 0}
                  pageSize={item_recomm_filter?.perPage}
                  page={item_recomm_filter?.page}
                  totalPage={item_recommendation_total_record}
                  onPerPage={onPerPage}
                  onPageNo={onPageNo}
                />
              </div>
            </div>
          </>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-light-danger"
            onClick={onHide}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
