import { Alert, DatePicker, Input, Select, message } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import RangePickerIVCSR from "../../../../../component/range-picker";
import { DateFormat } from "../../../../../config";
import { Link } from "react-router-dom";
const { RangePicker } = DatePicker;
export default function ({ show, onHide, selectedReport }) {
  const [filters, setFilters] = useState({
    start_date: dayjs().add(-7, "d"),
    end_date: dayjs(),
  });

  return (
    <>
      <Modal show={show} onHide={onHide} size="lg" centered backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>View {selectedReport}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <div>
              <div className="row g-9 mb-5 align-items-center">
                <div className="col-md-5 fv-row">
                  <label className=" fs-6 fw-bold mb-2">
                    Select Date Range
                  </label>
                </div>
                <div className="col-md-7 fv-row">
                  {/*begin::Daterangepicker(defined in src/js/layout/app.js)*/}
                  <RangePicker
                    className="ant_common_input"
                    id="ivcsr_date_picker"
                    size="large"
                    allowClear={false}
                    style={{ width: "100%" }}
                    format={DateFormat}
                    value={[filters?.start_date, filters?.end_date]}
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    onChange={(e) => {
                      const filters_ = filters;
                      filters_.start_date = e?.[0];
                      filters_.end_date = e?.[1];
                      setFilters({ ...filters_ });
                    }}
                  />

                  {/*end::Daterangepicker*/}
                </div>
              </div>

              <div
                className="row g-9 mb-5 align-items-center"
                data-select2-id="select2-data-6-thps"
              >
                <div className="col-md-5 fv-row">
                  <label className=" fs-6 fw-bold mb-2">
                    Report has to be viewed{" "}
                  </label>
                </div>
                <div className="col-md-7 fv-row">
                  <Select
                    size="large"
                    style={{ width: "100%" }}
                    placeholder={"Select View Type"}
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    options={[
                      {
                        label: "Day wise view",
                        value: "day wise view",
                      },
                      {
                        label: "Week wise view",
                        value: "week wise view",
                      },
                      {
                        label: "Month wise view",
                        value: "month wise view",
                      },
                    ]}
                  />

                  {/* <div class=" mt-3">
                                  <input class="form-control form-control-solid kt_datepicker_3 fs-7" placeholder="Pick a Date & Time" id=""/>
                              </div> */}
                </div>
              </div>
            </div>
          </>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-light-danger"
            onClick={onHide}
          >
            Close
          </button>

          <Link
            target="_blank"
            to={{
              pathname: "/manage-reports/view-report",
              search: "?date=01-02-2024&type=date_wise",
            }}
            className="btn btn-primary"
          >
            View Report
          </Link>
        </Modal.Footer>
      </Modal>
    </>
  );
}
