import React, { useContext, useEffect } from "react";
import { GlobalContext } from "../../../../commonContext";
// import WalmartIndex from "./lib/walmart-index";
import { useHistory } from "react-router-dom";
import UnderConstruction from "../../../../component/under-construction/under-construction";
import AmazonCampaign from "./amazon-campaign";
import WalmartCampaign from "./walmart-campaign";

export default function (props) {
  const history = useHistory();
  const contextVar = useContext(GlobalContext);

  return contextVar?.data?.marketplaceType === "amazon" ? (
    <>
      {/* <UnderConstruction /> */}
      <AmazonCampaign {...props} />
    </>
  ) : (
    // <UnderConstruction />
    <WalmartCampaign {...props} />
  );
}
