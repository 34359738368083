import React, { useEffect, useState, useContext } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  DefaultPerPage,
  SalesChannelFlag,
  timeSince,
} from "../../../../config";
import Pagination from "../../../../component/pagination";
import Loading from "../../../../component/loading";
import moment from "moment";
import { Input, Popover, Select, Tag, message } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import {
  WalmartMarketplaceList,
  adminCentralLogEventStatus,
} from "../../../../config/static-select-option";
import NoData from "../../../../component/no-data";
import IvcsrTable from "../../../../component/ivcsr-table";
import { GlobalContext } from "../../../../commonContext";
export default function (props) {
  const {
    GetCentralLogList,
    GetEventNameList,
    GetEventTypeList,
    fakeActionCentralLog,
    GetMarketplaceList,
    fakeActionSalesAnalytics,
  } = props;

  const GetCentralLogListRes = useSelector(
    (state) => state.CentralLogSystem.GetCentralLogListResponse || {}
  );
  const GetEventNameListRes = useSelector(
    (state) => state.CentralLogSystem.GetEventNameListResponse || {}
  );
  const GetEventTypeListRes = useSelector(
    (state) => state.CentralLogSystem.GetEventTypeListResponse || {}
  );
  const GetMarketplaceListRes = useSelector(
    (state) => state.SalesAnalytics.GetMarketplaceListResponse || {}
  );

  const contextVar = useContext(GlobalContext);
  const platformType = contextVar?.data?.marketplaceType;

  const [salesChannelList, setsalesChannelList] = useState([]);
  const [selectedSalesChannel, setSelectedSalesChannel] = useState([]);
  const [selectedSalesChannelId, setSelectedSalesChannelId] = useState([]);
  const [salesChannelLoading, setSalesChannelLoading] = useState(true);
  const [selectedCurrencySign, setSelectedCurrencySign] = useState("");

  const [list, setList] = useState([]);
  const [eventNameList, setEventNameList] = useState({});
  const [eventTypeList, setEventTypeList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageSize, setPageSize] = useState(DefaultPerPage);
  const [filter, setFilter] = useState({
    event_status: "",
    event_name: "",
    event_type: "",
    priority: "",
  });

  useEffect(() => {
    setLoading(true);
    GetMarketplaceList();
    GetEventTypeList(contextVar?.data?.marketplaceType);
    GetEventNameList(contextVar?.data?.marketplaceType);
    return () => {};
  }, [contextVar?.data?.marketplaceType]);

  useEffect(() => {
    if (GetMarketplaceListRes?.status === true) {
      setSalesChannelLoading(false);
      setsalesChannelList(GetMarketplaceListRes?.data?.marketplace_list);
      setSelectedSalesChannel("Amazon.com");
      setSelectedSalesChannelId("ATVPDKIKX0DER");
      setSelectedCurrencySign("$");

      const apiObj = {
        page: 1,
        perPage: pageSize,
        marketplaceId: "ATVPDKIKX0DER",
        marketplace_type: contextVar?.data?.marketplaceType,
        ...filter,
      };
      GetCentralLogList(apiObj);

      fakeActionSalesAnalytics("GetMarketplaceListResponse");
    } else if (GetMarketplaceListRes?.status === false) {
      fakeActionSalesAnalytics("GetMarketplaceListResponse");
    }
  }, [GetMarketplaceListRes]);

  useEffect(() => {
    if (GetCentralLogListRes.status === true) {
      setList(GetCentralLogListRes?.data?.records);
      console.log(GetCentralLogListRes, "GetCentralLogListResponse");
      setLoading(false);
      setTotalPage(GetCentralLogListRes?.data?.pagination?.totalCount);
      fakeActionCentralLog("GetCentralLogListResponse");
    } else if (GetCentralLogListRes.status === false) {
      setLoading(false);
      fakeActionCentralLog("GetCentralLogListResponse");
    }
  }, [GetCentralLogListRes]);

  useEffect(() => {
    if (GetEventNameListRes.status === true) {
      setEventNameList(GetEventNameListRes?.data);
      fakeActionCentralLog("GetEventNameListResponse");
    } else if (GetEventNameListRes.status === false) {
      setLoading(false);
      message.destroy();
      message.error(GetEventNameListRes?.message);
      fakeActionCentralLog("GetEventNameListResponse");
    }
  }, [GetEventNameListRes]);

  useEffect(() => {
    if (GetEventTypeListRes.status === true) {
      setEventTypeList(GetEventTypeListRes?.data);
      fakeActionCentralLog("GetEventTypeListResponse");
    } else if (GetEventTypeListRes.status === false) {
      setLoading(false);
      message.destroy();
      message.error(GetEventTypeListRes?.message);
      fakeActionCentralLog("GetEventTypeListResponse");
    }
  }, [GetEventTypeListRes]);

  const columns = [
    {
      title: "#",
      ellipsis: true,
      align: "left",
      render: (text) => {
        return <span>{(page - 1) * pageSize + 1 + text.key}</span>;
      },
    },

    {
      title: "Log Created at",
      align: "left",
      render: (item) => {
        return (
          <div>
            <span>
              {moment(new Date(item?.created_at * 1000)).format(
                "MMM DD, YYYY hh:mm A"
              )}
            </span>
          </div>
        );
      },
    },
    {
      title: "Event Date",
      align: "left",
      render: (item) => {
        return (
          <div>
            <span>
              {moment(new Date(item?.event_date)).format("MMM DD, YYYY")}
            </span>
          </div>
        );
      },
    },
    {
      title: "Event Name",
      align: "left",
      render: (item) => {
        return (
          <div>
            <span className="fw-bolder">{item?.event_name}</span>
            {item?.event_type === "Advertising Report"
              ? item?.event_name === "sponsoredDisplay"
                ? `(${JSON.parse(item?.event_details)?.record_type || ""}) ${
                    JSON.parse(item?.event_details)?.tactic_name || ""
                  }`
                : `(${JSON.parse(item?.event_details)?.record_type || ""} )`
              : ""}
          </div>
        );
      },
    },
    {
      title: "Event Type",
      align: "left",
      render: (item) => {
        return (
          <div>
            <span>{item?.event_type || "-"}</span>
          </div>
        );
      },
    },
    {
      title: "Event Status",
      align: "left",
      render: (item) => {
        return (
          <div>
            <Tag
              color={
                item.event_status === "PENDING"
                  ? "gold"
                  : item.event_status === "IN_PROGRESS"
                  ? "blue"
                  : item.event_status === "DONE"
                  ? "green"
                  : item.event_status === "FAILED"
                  ? "red"
                  : item.event_status === "FATAL"
                  ? "magenta"
                  : item.event_status === "CANCELLED"
                  ? "volcano"
                  : item.event_status === "NOT_FOUND"
                  ? "gold"
                  : item.event_status === "NO DATA"
                  ? "purple"
                  : "error"
              }
            >
              {item?.event_status}
            </Tag>
          </div>
        );
      },
    },
    {
      title: "Priority",
      align: "left",
      render: (item) => {
        return (
          <div>
            <span>
              {item?.priority == 1
                ? "High"
                : item?.priority == 2
                ? "Medium"
                : "Low"}
            </span>
          </div>
        );
      },
    },
    {
      title: "Marketplace",
      align: "left",
      render: (item) => {
        return (
          <div>
            <span>{item?.marketplace || "-"}</span>
          </div>
        );
      },
    },
    {
      title: "Updated At",
      align: "left",
      render: (item) => {
        return (
          <div>
            <span>
              {moment(new Date(item?.updated_at * 1000)).format(
                "MMM DD, YYYY hh:mm A"
              )}
              <br />({timeSince(item?.updated_at)})
            </span>
          </div>
        );
      },
    },
    {
      title: "Report Requested",
      align: "left",
      render: (item) => {
        return (
          <div>
            <Tag color={item.status == 1 ? "green" : "gold"}>
              <span>{item?.status == 1 ? "DONE" : "PENDING"}</span>
            </Tag>
          </div>
        );
      },
    },

    {
      title: "Reason",
      align: "left",
      render: (item) => {
        return (
          <div>
            <span>{item?.note || "-"}</span>
          </div>
        );
      },
    },
  ];

  const onPageNo = (e) => {
    setLoading(true);
    const apiObj = {
      page: e,
      perPage: pageSize,
      marketplace_type: contextVar?.data?.marketplaceType,
      marketplaceId: selectedSalesChannelId,
      ...filter,
    };
    GetCentralLogList(apiObj);
    setPage(e);
  };

  const onPerPage = (e) => {
    setPage(1);
    setPageSize(e);
    setLoading(true);
    const apiObj = {
      page: 1,
      perPage: e,
      marketplace_type: contextVar?.data?.marketplaceType,
      marketplaceId: selectedSalesChannelId,
      ...filter,
    };
    GetCentralLogList(apiObj);
  };

  return (
    <div className="fadeInRight">
      <div className="d-flex flex-column flex-column-fluid">
        <div id="kt_app_content" className="app-content flex-column-fluid">
          <div
            id="kt_app_content_container"
            className="app-container container-fluid"
          >
            <div>
              {/*begin::Row*/}
              <div className="row gy-5 g-xl-5">
                {/*begin::Col*/}
                <div className="col-xxl-12">
                  {/*begin::Tables Widget 9*/}
                  <div className="card card-xxl-stretch mb-5 mb-xl-8">
                    {/*begin::Header*/}
                    <div className="card-header border-bottom-dashed">
                      {/*end::Title*/}
                      {/*begin::Title*/}
                      <h3 className="card-title ">
                        <span className="card-label fw-bold text-gray-900">
                          Central Log
                        </span>
                      </h3>
                      {/*end::Title*/}
                      <div className="card-toolbar gap-2">
                        <div className="position-relative">
                          <Select
                            className="w-200px"
                            size="large"
                            placeholder="Event Type"
                            allowClear
                            value={filter?.event_type || null}
                            onChange={(e) => {
                              const filter_ = { ...filter };
                              filter_.event_type = e || "";
                              setFilter(filter_);
                              setLoading(true);
                              setPage(1);
                              const apiObj = {
                                page: 1,
                                perPage: pageSize,
                                marketplaceId: selectedSalesChannelId,
                                marketplace_type:
                                  contextVar?.data?.marketplaceType,
                                ...filter_,
                              };
                              GetCentralLogList(apiObj);
                            }}
                            getPopupContainer={(triggerNode) =>
                              triggerNode.parentNode
                            }
                            options={eventTypeList?.map((d) => {
                              return {
                                label: d,
                                value: d,
                              };
                            })}
                          />
                        </div>
                        <div className="position-relative">
                          <Select
                            className="w-200px"
                            size="large"
                            placeholder="Event Name"
                            allowClear
                            value={filter?.event_name || null}
                            onChange={(e) => {
                              const filter_ = { ...filter };
                              filter_.event_name = e || "";
                              setFilter(filter_);
                              setLoading(true);
                              setPage(1);
                              const apiObj = {
                                page: 1,
                                perPage: pageSize,
                                marketplaceId: selectedSalesChannelId,
                                marketplace_type:
                                  contextVar?.data?.marketplaceType,
                                ...filter_,
                              };
                              GetCentralLogList(apiObj);
                            }}
                            getPopupContainer={(triggerNode) =>
                              triggerNode.parentNode
                            }
                            options={Object.keys(eventNameList)?.map((d) => {
                              return {
                                label: d,
                                value: d,
                              };
                            })}
                          />
                        </div>
                        <div className="position-relative">
                          <Select
                            className="w-200px"
                            size="large"
                            placeholder="Event Status"
                            allowClear
                            value={filter?.event_status || null}
                            onChange={(e) => {
                              const filter_ = { ...filter };
                              filter_.event_status = e || "";
                              setFilter(filter_);
                              setLoading(true);
                              setPage(1);
                              const apiObj = {
                                page: 1,
                                perPage: pageSize,
                                marketplaceId: selectedSalesChannelId,
                                marketplace_type:
                                  contextVar?.data?.marketplaceType,
                                ...filter_,
                              };
                              GetCentralLogList(apiObj);
                            }}
                            getPopupContainer={(triggerNode) =>
                              triggerNode.parentNode
                            }
                            options={adminCentralLogEventStatus.map((d) => {
                              return {
                                label: d.label,
                                value: d.value,
                              };
                            })}
                          />
                        </div>
                        <div className="position-relative">
                          <Select
                            className="w-200px"
                            size="large"
                            placeholder="Priority"
                            allowClear
                            value={filter?.priority || null}
                            onChange={(e) => {
                              const filter_ = { ...filter };
                              filter_.priority = e || "";
                              setFilter(filter_);
                              setLoading(true);
                              setPage(1);
                              const apiObj = {
                                page: 1,
                                perPage: pageSize,
                                marketplaceId: selectedSalesChannelId,
                                marketplace_type:
                                  contextVar?.data?.marketplaceType,
                                ...filter_,
                              };
                              GetCentralLogList(apiObj);
                            }}
                            getPopupContainer={(triggerNode) =>
                              triggerNode.parentNode
                            }
                            options={[
                              {
                                value: "1",
                                label: "High",
                              },
                              {
                                value: "2",
                                label: "Medium",
                              },
                              {
                                value: "3",
                                label: "Low",
                              },
                            ]}
                          />
                        </div>
                        <div className="position-relative">
                          <Select
                            className="w-250px"
                            size="large"
                            placeholder="Select Sales Channel"
                            loading={salesChannelLoading}
                            value={selectedSalesChannelId || null}
                            onChange={(e, _) => {
                              setSelectedSalesChannelId(e);
                              setSelectedSalesChannel(_?.sales_channel);
                              setPage(1);
                              setPageSize(DefaultPerPage);
                              setLoading(true);
                              const apiObj = {
                                page: 1,
                                perPage: DefaultPerPage,
                                marketplaceId: e,
                                marketplace_type:
                                  contextVar?.data?.marketplaceType,
                                ...filter,
                              };
                              GetCentralLogList(apiObj);
                            }}
                            options={
                              platformType === "amazon"
                                ? SalesChannelFlag(salesChannelList)
                                : SalesChannelFlag(WalmartMarketplaceList)
                            }
                          />
                        </div>
                      </div>
                    </div>
                    {/*end::Header*/}
                    {/*begin::Body*/}
                    <div className="card-body pb-5 pt-0">
                      <div className="table-responsive mt-5">
                        {/*begin::Table container*/}
                        {loading ? (
                          <Loading />
                        ) : list?.length != 0 ? (
                          <>
                            <IvcsrTable
                              columns={columns?.map((d) => ({
                                ...d,
                                width:
                                  d?.title === "Reason"
                                    ? 200
                                    : d?.title === "Updated At"
                                    ? 200
                                    : d?.title === "Log Created at"
                                    ? 200
                                    : d?.title?.length * 13,
                              }))}
                              fixed={"top"}
                              sticky={{
                                offsetHeader: "10px",
                              }}
                              scroll={{
                                x: "max-content",
                                y: "calc(100vh - 200px)",
                              }}
                              dataSource={list}
                              rowKey="key"
                              loading={loading}
                              pagination={false}
                            />
                          </>
                        ) : (
                          <NoData />
                        )}
                      </div>
                      <Pagination
                        loading={loading || list?.length === 0}
                        pageSize={pageSize}
                        page={page}
                        totalPage={totalPage}
                        onPerPage={onPerPage}
                        onPageNo={onPageNo}
                      />
                    </div>
                    {/*begin::Body*/}
                  </div>
                  {/*end::Tables Widget 9*/}
                </div>
                {/*end::Col*/}
              </div>
              {/*end::Row*/}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
