import { Input, Select, Switch, Tag, Popconfirm, message } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import IvcsrTable from "../../../../component/ivcsr-table";
import NoData from "../../../../component/no-data";
import Pagination from "../../../../component/pagination";
import { DefaultPerPage } from "../../../../config";
import Loading from "../../../../component/loading";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import ActionLogModal from "./walmart-rule-lib/action-log-modal";
export default function (props) {
  const {
    GetWalmartBidManagementList,
    DeleteWalmartRuleAction,
    CreateWalmartRuleAction,
    UpdateWalmartRuleStatusAction,
    fakeActionWalmartBidManagement,
  } = props;

  const [ruleList, setRuleList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageSize, setPageSize] = useState(DefaultPerPage);
  const [bidStatus, setBidStatus] = useState(null);
  const [ruleId, setRuleId] = useState("");
  const [ruleStatus, setRuleStatus] = useState("INACTIVE");
  const [ruleName, setRuleName] = useState("");
  const [statusUpdating, setStatusUpdating] = useState(false);
  const [selectedActionLog, setSelectedActionLog] = useState({});
  const [actionLogView, setActionLogView] = useState(false);

  const GetWalmartBidManagementListRes = useSelector(
    (state) =>
      state.WalmartBidManagement.GetWalmartBidManagementListResponse || {}
  );
  const DeleteWalmartBidRuleRes = useSelector(
    (state) => state.WalmartBidManagement.DeleteWalmartBidRuleResponse || {}
  );
  const CreateWalmartRuleActionRes = useSelector(
    (state) => state.WalmartBidManagement.CreateWalmartRuleActionResponse || {}
  );
  const UpdateWalmartRuleStatusRes = useSelector(
    (state) => state.WalmartBidManagement.UpdateWalmartRuleStatusResponse || {}
  );

  useEffect(() => {
    GetWalmartBidManagementList({
      page: 1,
      perPage: pageSize,
      marketplaceId: "ATVPDKIKX0DER",
      ruleName: ruleName,
      ruleStatus: bidStatus,
    });
  }, []);

  useEffect(() => {
    if (GetWalmartBidManagementListRes?.status === true) {
      setLoading(false);
      setRuleList(GetWalmartBidManagementListRes?.data?.records);
      setTotalPage(
        GetWalmartBidManagementListRes?.data?.pagination?.totalCount
      );
      fakeActionWalmartBidManagement("GetWalmartBidManagementListResponse");
    } else if (GetWalmartBidManagementListRes?.status === false) {
      fakeActionWalmartBidManagement("GetWalmartBidManagementListResponse");
    }
  }, [GetWalmartBidManagementListRes]);

  useEffect(() => {
    if (UpdateWalmartRuleStatusRes?.status === true) {
      message.destroy();
      message.success(UpdateWalmartRuleStatusRes?.message);
      setRuleId("");
      setStatusUpdating(false);
      fakeActionWalmartBidManagement("UpdateWalmartRuleStatusResponse");
    } else if (UpdateWalmartRuleStatusRes?.status === false) {
      message.destroy();
      message.error(UpdateWalmartRuleStatusRes?.message);
      setRuleId("");
      setStatusUpdating(false);
      fakeActionWalmartBidManagement("UpdateWalmartRuleStatusResponse");
    }
  }, [UpdateWalmartRuleStatusRes]);

  useEffect(() => {
    if (DeleteWalmartBidRuleRes?.status === true) {
      message.destroy();
      message.success(DeleteWalmartBidRuleRes?.message);
      setLoading(true);
      setPage(1);
      GetWalmartBidManagementList({
        page: 1,
        perPage: pageSize,
        marketplaceId: "ATVPDKIKX0DER",
        ruleName: ruleName,
        ruleStatus: bidStatus,
      });
      fakeActionWalmartBidManagement("DeleteWalmartBidRuleResponse");
    } else if (DeleteWalmartBidRuleRes?.status === false) {
      message.destroy();
      message.error(DeleteWalmartBidRuleRes?.message);
      fakeActionWalmartBidManagement("DeleteWalmartBidRuleResponse");
    }
  }, [DeleteWalmartBidRuleRes]);

  useEffect(() => {
    if (CreateWalmartRuleActionRes?.status === true) {
      message.destroy();
      message.success(CreateWalmartRuleActionRes?.message);
      setRuleId("");
      setRuleStatus("");
      setStatusUpdating(false);
      fakeActionWalmartBidManagement("CreateWalmartRuleActionResponse");
    } else if (CreateWalmartRuleActionRes?.status === false) {
      setRuleList((prev) => {
        return prev?.map((d) => {
          if (d?.id == ruleId) {
            return { ...d, rule_status: ruleStatus };
          } else {
            return { ...d };
          }
        });
      });
      setRuleId("");
      setRuleStatus("");
      setStatusUpdating(false);
      fakeActionWalmartBidManagement("CreateWalmartRuleActionResponse");
    }
  }, [CreateWalmartRuleActionRes]);

  const columns = [
    {
      title: "#",
      fixed: "left",
      render: (_, __, i) => {
        return <span>{(page - 1) * pageSize + 1 + i}</span>;
      },
    },
    {
      title: "Rule Name",
      dataIndex: "rule_name",
      key: "rule_name",
      fixed: "left",
      render: (text) => {
        return <span className="fw-bolder text-dark">{text}</span>;
      },
    },
    {
      title: "Status",
      dataIndex: "rule_status",
      key: "rule_status",
      render: (text) => {
        return (
          <Tag color={text == "ACTIVE" ? "success" : "error"}>
            {text == "ACTIVE" ? "Active" : "Inactive"}
          </Tag>
        );
      },
    },
    {
      title: "Action Type",
      dataIndex: "action_type",
      key: "action_type",
      render: (text) => {
        return (
          <span style={{ textTransform: "capitalize" }}>
            {text?.replaceAll("_", " ")}
          </span>
        );
      },
    },
    {
      title: "Notification Type",
      dataIndex: "notification_type",
      key: "notification_type",
      render: (text) => {
        return (
          <span style={{ textTransform: "capitalize" }}>
            {text?.replaceAll("_", " ")}
          </span>
        );
      },
    },
    {
      title: "Budget Type",
      dataIndex: "budget_type",
      key: "budget_type",
      render: (text) => {
        return (
          <span style={{ textTransform: "capitalize" }}>
            {text?.replaceAll("_", " ")}
          </span>
        );
      },
    },
    {
      title: "Action Log",
      render: (text) => (
        <span
          style={{ cursor: "pointer" }}
          onClick={() => {
            setSelectedActionLog({
              ...text,
              type_action: "master",
            });
            setActionLogView(true);
          }}
        >
          <i className="fas fa-history text-dark fs-3" />
        </span>
      ),
    },
    {
      title: "Marketplace",
      dataIndex: "marketplace_name",
      key: "marketplace_name",
      render: (text) => {
        return <span>{text}</span>;
      },
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => {
        return (
          <span className="">
            {moment(text * 1000)?.format("MMM DD, YYYY")}
          </span>
        );
      },
    },
    // {
    //   title: "Frequency",
    //   dataIndex: "frequency",
    //   key: "frequency",
    //   render: (text) => {
    //     return <span className="">{text}</span>;
    //   },
    // },
    // {
    //   title: "Time Frame",
    //   dataIndex: "timeFrame",
    //   key: "timeFrame",
    //   render: (text) => {
    //     return <span className="">{text}</span>;
    //   },
    // },
    // {
    //   title: "Last Run",
    //   dataIndex: "lastRun",
    //   key: "lastRun",
    //   render: (text) => {
    //     return <span className="">{text}</span>;
    //   },
    // },
    // {
    //   title: "Results",
    //   dataIndex: "results",
    //   key: "results",
    //   render: (text) => {
    //     return <span className="">{text}</span>;
    //   },
    // },
    {
      title: "Action",
      render: (text) => {
        return (
          <div className="d-flex align-items-center">
            <div className="me-2">
              <Switch
                disabled={text?.id == ruleId && statusUpdating}
                onChange={(e) => {
                  setRuleId(text?.id);
                  setStatusUpdating(true);
                  message.destroy();
                  message.loading("Loading...", 0);
                  setRuleList((prev) => {
                    return prev?.map((d) => {
                      if (d?.id === text?.id) {
                        return {
                          ...d,
                          rule_status: e ? "ACTIVE" : "INACTIVE",
                        };
                      } else {
                        return { ...d };
                      }
                    });
                  });
                  UpdateWalmartRuleStatusAction(text?.id, {
                    rule_status: e ? "ACTIVE" : 0,
                  });
                }}
                value={text?.rule_status == "ACTIVE" ? true : false}
              />
            </div>
            <div className="me-2">
              <Link
                id={Math.random()}
                to={{
                  pathname: "rule-management/rule",
                  search: `?ruleId=${text?.id}`,
                }}
                // target="_blank"
              >
                <button class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                  <i class="ki-outline ki-pencil fs-2 text-green"></i>
                </button>
              </Link>
            </div>
            <div className="me-2">
              <Popconfirm
                title="Are you sure to delete this rule?"
                placement="left"
                cancelText="No"
                okText="Yes"
                onConfirm={() => {
                  message.loading("Deleting...", 0);
                  DeleteWalmartRuleAction(text?.id);
                }}
              >
                <div>
                  <button class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                    <i class="ki-outline ki-trash fs-2 text-danger"></i>
                  </button>
                </div>
              </Popconfirm>
            </div>
          </div>
        );
      },
    },
  ];

  const onPageNo = (e) => {
    setLoading(true);
    GetWalmartBidManagementList({
      page: e,
      perPage: pageSize,
      marketplaceId: "ATVPDKIKX0DER",
      ruleName: ruleName,
      ruleStatus: bidStatus,
    });
    setPage(e);
  };

  const onPerPage = (e) => {
    setPage(1);
    setPageSize(e);
    setLoading(true);
    GetWalmartBidManagementList({
      page: 1,
      perPage: e,
      marketplaceId: "ATVPDKIKX0DER",
      ruleName: ruleName,
      ruleStatus: bidStatus,
    });
  };

  return (
    <>
      <div className="d-flex flex-column flex-column-fluid fadeInRight">
        <div id="kt_app_content" className="app-content flex-column-fluid">
          <div
            id="kt_app_content_container"
            className="app-container container-fluid"
          >
            <div className="row gy-5 g-xl-5">
              <div className="col-xxl-12">
                <div className="card card-xxl-stretch mb-5 mb-xl-8">
                  <div className="card-header border-bottom-dashed">
                    <div className="d-flex flex-stack flex-wrap">
                      <div className="position-relative">
                        <Input
                          type="text"
                          className="text-dark w-250px fs-7 fw-bold ant_common_input"
                          name="all_search"
                          placeholder="Search by Rule Name"
                          value={ruleName}
                          size="large"
                          onChange={(e) => {
                            setRuleName(e.target.value);
                          }}
                          onPressEnter={(e) => {
                            setLoading(true);
                            const Obj = {
                              page: page,
                              perPage: pageSize,
                              marketplaceId: "ATVPDKIKX0DER",
                              ruleName: e.target.value,
                              ruleStatus: bidStatus,
                            };
                            GetWalmartBidManagementList(Obj);
                          }}
                          prefix={
                            <SearchOutlined
                              style={{ fontSize: "19px", color: "grey" }}
                            />
                          }
                        />
                      </div>
                    </div>
                    <div className="card-toolbar ">
                      <div className="me-2">
                        <Select
                          options={[
                            { label: "Active", value: "1" },
                            { label: "InActive", value: "0" },
                          ]}
                          size="large"
                          placeholder="Status"
                          value={bidStatus}
                          style={{ width: "150px" }}
                          onChange={(e) => {
                            setBidStatus(e);
                            const Obj = {
                              page: page,
                              perPage: pageSize,
                              marketplaceId: "ATVPDKIKX0DER",
                              ruleName: ruleName,
                              ruleStatus: e,
                            };
                            GetWalmartBidManagementList(Obj);
                          }}
                        />
                      </div>

                      <Link
                        id={Math.random()}
                        to={{
                          pathname: "rule-management/rule",
                          // search: `?ruleId=${ruleId}`,
                        }}
                        // target="_blank"
                      >
                        <button className="btn btn-primary fs-7">
                          Add New Rule
                        </button>
                      </Link>
                    </div>
                  </div>

                  <div className="card-body py-2">
                    <div className="table-responsive tbl-sales">
                      {loading ? (
                        <Loading />
                      ) : ruleList?.length != 0 ? (
                        <IvcsrTable
                          columns={columns}
                          fixed="top"
                          dataSource={ruleList}
                          rowKey="key"
                          pagination={false}
                          scroll={{ x: "max-content" }}
                        />
                      ) : (
                        <NoData type="new" />
                      )}
                    </div>
                    <Pagination
                      loading={loading || ruleList?.length === 0}
                      pageSize={pageSize}
                      page={page}
                      totalPage={totalPage}
                      onPerPage={onPerPage}
                      onPageNo={onPageNo}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {actionLogView && (
        <ActionLogModal
          show={actionLogView}
          selectedActionLog={selectedActionLog}
          onHide={() => {
            setActionLogView(false);
            setSelectedActionLog({});
          }}
          {...props}
        />
      )}

      {/* {openCampaignModal && (
        <CampaignModal
          show={openCampaignModal}
          onHide={() => {
            setOpenCampainModal(false);
          }}
        />
      )}
      {openRuleDetailsModal && (
        <RuleDetailsModal
          show={openRuleDetailsModal}
          onHide={() => {
            setOpenRuleDetailsModal(false);
          }}
        />
      )} */}
    </>
  );
}
