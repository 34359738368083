import React from "react";
import { TableLoading } from "../table-animation";

export default function (props) {
  return (
    <TableLoading
      id="test-table"
      columns={[1, 2, 3, 4, 5]}
      checkBoxVal={true}
      actions={[1, 2]}
      {...props}
    />
  );
}
