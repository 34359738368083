import { getUserId } from "../../../../config";
import axiosCall from "../../../configurations/network-services/axiosCall";
import walmartAxiosCall from "../../../configurations/network-services/walmartAxoisCall";

// const prefix = "dashboard";

export const GetSkuDataList = (data) => {
  const path = `sku-data?page=${data?.page || 1}&per-page=${
    data?.perPage || 10
  }&filter=${data?.filter || ""}&sales_channel=${
    data?.sales_channel || ""
  }&search_key=${data?.search_key || ""}&isExport=${data?.isExport || 0}`;
  const responseType = "GET_SKU_DATA_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetWalmartProductList = (data) => {
  const path = `wm/products?page=${data?.page || 1}&per-page=${
    data?.perPage || 10
  }&filter=${data?.filter || ""}&sales_channel=${
    data?.sales_channel || ""
  }&search_key=${data?.search_key || ""}&isExport=${
    data?.isExport || 0
  }&publish_status=${data?.publish_status || ""}&lifecycle_status=${
    data?.lifecycle_status || ""
  }`;
  const responseType = "GET_WALMART_PRODUCT_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetWalmartItemFeed = (data) => {
  const path = `wm/feeds?page=${data?.page || 1}&per-page=${
    data?.perPage || 10
  }&feed_id=${data?.search || ""}&feed_type=${
    data?.feedType || ""
  }&feed_status=${data?.feedStatus || ""}`;
  const responseType = "GET_WALMART_ITEM_FEED";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const SaveItemFeedList = (data) => {
  const path = `items`;
  const responseType = "SAVE_WALMART_ITEM_FEED_LIST";
  return walmartAxiosCall("post", path, responseType, data, {
    AUTHORIZATION: `Bearer ${localStorage.getItem("token")}`,
    "EVENT-TYPE": "Walmart Report",
    MODE: "API",
    "MARKETPLACE-ID": "ATVPDKIKX0DER",
    // user_id: 8,
    // user_id: JSON.parse(localStorage.getItem("user"))?.id,
    "USER-ID": getUserId(),
    FEEDTYPE: "MP_ITEM",
    "CONTENT-TYPE": "application/json",
  });
};

export const GetGlobalSearchProducts = (data) => {
  const path = `items/global?${data?.type}=${data?.search}`;
  const responseType = "GET_GLOBALSEARCH_PRODUCT_LIST";
  return walmartAxiosCall("get", path, responseType, data, {
    AUTHORIZATION: `Bearer ${localStorage.getItem("token")}`,
    "MARKETPLACE-ID": "ATVPDKIKX0DER",
    // user_id: 8,
    "USER-ID": getUserId(),
  });
};

export const GetWalmartProductsSearch = (data) => {
  const path = `items?id=${data?.search}&product_id_type=${data?.type}`;
  const responseType = "GET_WALMART_PRODUCT_SEARCH_LIST";
  return walmartAxiosCall("get", path, responseType, data, {
    AUTHORIZATION: `Bearer ${localStorage.getItem("token")}`,
    "MARKETPLACE-ID": "ATVPDKIKX0DER",
    // user_id: 8,
    // user_id: JSON.parse(localStorage.getItem("user"))?.id,
    "USER-ID": getUserId(),
  });
};

export const fakeActionSkuData = (data) => async (dispatch) => {
  dispatch({ type: "FAKE_ACTION_SKU_DATA", state: data });
};
