import { Alert, Input, message } from "antd";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
export default function ({ show, onHide }) {
  const [sending, setSending] = useState(false);
  const [cost_price, setCostPrice] = useState(0);
  const [prep_cost, setPrepCost] = useState(0);
  const [other_cost, setOtherCost] = useState(0);

  //   const AddUserRes = useSelector(
  //     (state) => state.ManageUser.AddUserResponse || {}
  //   );

  //   const onSubmit = () => {
  //     const isValidEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
  //       email
  //     );
  //     setIsValidEmail(isValidEmail);
  //     const isValidContact = /(0|91)?[6-9][0-9]{9}/gm.test(contact);
  //     setIsValidContact(isValidContact);
  //     if (isValidEmail && isValidContact) {
  //       const body = {
  //         name: name,
  //         email: email,
  //         password: password,
  //         contact_no: contact,
  //         seller_name: sellerName,
  //         password: password,
  //       };
  //       if (data.id) {
  //         delete body.password;
  //       }
  //       setSending(true);
  //       console.log(body, "body");
  //       message.destroy();
  //       message.loading("Loading", 0);
  //       AddUserAction(data.id, body);
  //     }
  //   };

  //   useEffect(() => {
  //     if (AddUserRes.status) {
  //       message.destroy();
  //       message.success(AddUserRes?.message);
  //       onSuccess();
  //       setSending(false);
  //       onSuccess();
  //       fakeActionManageUser("AddUserResponse");
  //     } else if (AddUserRes.status === false) {
  //       setSending(false);
  //       message.destroy();
  //       message.error(AddUserRes?.message);
  //       fakeActionManageUser("AddUserResponse");
  //     }
  //   }, [AddUserRes]);

  return (
    <>
      <Modal show={show} onHide={onHide} size="md" centered backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Manage User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <div className="row">
              <div className="col-md-6 form-group mb-5">
                <label className="fw-bold fs-6">Cost Price</label>
                <Input
                  className="ant_common_input"
                  size="large"
                  value={cost_price}
                  onChange={(e) => {
                    setCostPrice(e.target.value);
                  }}
                  prefix={
                    <span
                      style={{
                        color: "#4318ff",
                        marginRight: "5px",
                        fontSize: "18px",
                      }}
                    >
                      $
                    </span>
                  }
                />
              </div>
              <div className="col-md-6 form-group mb-5">
                <label className="fw-bold fs-6">Prep Cost</label>
                <Input
                  className="ant_common_input"
                  size="large"
                  value={prep_cost}
                  onChange={(e) => {
                    setPrepCost(e.target.value);
                  }}
                  prefix={
                    <span
                      style={{
                        color: "#4318ff",
                        marginRight: "5px",
                        fontSize: "18px",
                      }}
                    >
                      $
                    </span>
                  }
                />
              </div>{" "}
              <div className="col-md-6 form-group mb-5">
                <label className="fw-bold fs-6">Other Cost</label>
                <Input
                  className="ant_common_input"
                  size="large"
                  value={other_cost}
                  onChange={(e) => {
                    setOtherCost(e.target.value);
                  }}
                  prefix={
                    <span
                      style={{
                        color: "#4318ff",
                        marginRight: "5px",
                        fontSize: "18px",
                      }}
                    >
                      $
                    </span>
                  }
                />
              </div>
            </div>
          </>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-light-danger"
            onClick={onHide}
          >
            Close
          </button>
          <button type="submit" className="btn btn-primary" disabled>
            Save
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
