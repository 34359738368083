import { Image } from "antd";
import React from "react";
const blank =
  "http://accordelectrotechnics.in/img/product/no-preview/no-preview.png";
const PreviewImage = (props) => {
  const { imageUrl, width, height, ...rest } = props;

  return (
    <>
      <Image
        src={imageUrl || blank}
        onError={(e) => {
          e.target.src = blank;
        }}
        width={width || 70}
        height={height || 70}
        {...rest}
      />
    </>
  );
};

export default PreviewImage;
