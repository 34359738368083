import React, { useEffect, useState } from "react";
import { ProductDetailsWrapper } from "./style";
import { Progress, Skeleton, Tag } from "antd";
import { useLocation } from "react-router-dom";
import { urlDecode } from "../../../../config";
import { useSelector } from "react-redux";
import PreviewImage from "../../../../component/preview-image";
import moment from "moment";
import { SyncOutlined } from "@ant-design/icons";
import NoData from "../../../../component/no-data";
import PdTitle from "./pd-title";
import PdBulletPoints from "./pd-bullet-points";
import PdDescription from "./pd-description";
import PdReview from "./pd-review";
import PdMainHeader from "./pd-main-header";
import PdImages from "./pd-images";
export default function (props) {
  const { GetListingOptimizerProductDetails, fakeActionListingOptimizer } =
    props;

  const location = useLocation();
  const [urlState, setUrlState] = useState({});
  const [productData, setProductData] = useState({});
  const [productCriteriaData, setProductCriteriaData] = useState({});
  const [suggestedTitleAnalysis, setSuggestedTitleAnalysis] = useState({});
  const [recommendedTitle, setRecommendedTitle] = useState("");
  const [suggestedBullets, setSuggestedBullets] = useState({});
  const [recommendedBullets, setRecommendedBullets] = useState([]);
  const [suggestedDescAnalysis, setSuggestedDescAnalysis] = useState("");
  const [recommendedDescription, setRecommendedDescription] = useState("");

  const [allLoading, setAllLoading] = useState({
    mainHeaderLoading: true,
    titleAnalysis: false,
    titleRecommendation: false,
    bulletAnalysis: false,
    bulletRecommendation: false,
    descAnalysis: false,
    descRecommendation: false,
  });

  const GetListingOptimizerProductsDetailsRes = useSelector(
    (state) =>
      state.ListingOptimizer.GetListingOptimizerProductsDetailsResponse || {}
  );

  useEffect(() => {
    console.log(urlDecode(location), "location");
    const newUrl = urlDecode(location);
    setUrlState(newUrl);
    GetListingOptimizerProductDetails(newUrl?.id);
  }, [location]);

  useEffect(() => {
    if (GetListingOptimizerProductsDetailsRes?.status === true) {
      const loading = { ...allLoading };
      loading.mainHeaderLoading = false;
      setAllLoading(loading);
      setProductData(
        GetListingOptimizerProductsDetailsRes?.data?.catalogItemData
      );
      setProductCriteriaData(
        GetListingOptimizerProductsDetailsRes?.data?.productCriteria
      );
      setRecommendedTitle(
        GetListingOptimizerProductsDetailsRes?.data?.productCriteria
          ?.title_recommend
      );
      if (
        GetListingOptimizerProductsDetailsRes?.data?.productCriteria
          ?.title_analysis
      ) {
        setSuggestedTitleAnalysis(
          JSON.parse(
            GetListingOptimizerProductsDetailsRes?.data?.productCriteria
              ?.title_analysis
          )
        );
      }
      if (
        GetListingOptimizerProductsDetailsRes?.data?.productCriteria
          ?.bullet_point_analysis
      ) {
        setSuggestedBullets(
          JSON.parse(
            GetListingOptimizerProductsDetailsRes?.data?.productCriteria
              ?.bullet_point_analysis || {}
          )
        );
      }
      if (
        GetListingOptimizerProductsDetailsRes?.data?.productCriteria
          ?.description_analysis
      ) {
        const newData = JSON.parse(
          GetListingOptimizerProductsDetailsRes?.data?.productCriteria
            ?.description_analysis
        );
        setSuggestedDescAnalysis(newData);
      }
      if (
        GetListingOptimizerProductsDetailsRes?.data?.productCriteria
          ?.bullet_point_recommend
      ) {
        const newPoints =
          GetListingOptimizerProductsDetailsRes?.data?.productCriteria?.bullet_point_recommend
            ?.split("- ")
            .filter(Boolean);
        console.log(newPoints, "newPoints");
        setRecommendedBullets(newPoints || []);
      }

      fakeActionListingOptimizer("GetListingOptimizerProductsDetailsResponse");
    } else if (GetListingOptimizerProductsDetailsRes?.status === false) {
      fakeActionListingOptimizer("GetListingOptimizerProductsDetailsResponse");
    }
  }, [GetListingOptimizerProductsDetailsRes]);

  return (
    <ProductDetailsWrapper>
      <div className="fadeInRight">
        <div className="d-flex flex-column flex-column-fluid">
          <div className="d-flex flex-column flex-column-fluid">
            <div id="kt_app_content" className="app-content flex-column-fluid">
              <div
                id="kt_app_content_container"
                className="app-container container-fluid"
              >
                <div className="row mb-4">
                  <div className="col-12 d-flex justify-content-end">
                    {/* <button className="btn btn-primary">Start Analysis</button> */}
                  </div>
                </div>
                <PdMainHeader
                  productData={productData}
                  allLoading={allLoading}
                  {...props}
                />

                <div className="d-flex flex-column flex-lg-row">
                  <div className="flex-column flex-lg-row-auto w-lg-250px w-xl-250px mb-10 ">
                    <div
                      className="m-0 rounded "
                      style={{ position: "sticky", top: "100px" }}
                    >
                      <ul className="nav nav-pills nav-pills-custom flex-column border-transparent fs-5 fw-bold">
                        {allLoading?.mainHeaderLoading ? (
                          <Skeleton.Button
                            className="my-1"
                            active
                            block
                            style={{ height: "55px" }}
                          />
                        ) : (
                          <li className="nav-item mt-0 border border-dashed border-info rounded py-2 px-3 me-0">
                            <div className=" d-flex align-items-center justify-content-between">
                              <span
                                className="nav-link text-muted text-active-primary ms-0 py-0 me-0 ps-5 border-0 active"
                                href
                              >
                                Title
                                <span className="bullet-custom position-absolute start-0 ms-n4 top-0 w-2px h-100 bg-primary rounded-end" />
                              </span>
                              <Progress
                                type="circle"
                                percent={20}
                                size={40}
                                strokeColor={"red"}
                              />
                            </div>{" "}
                          </li>
                        )}

                        {allLoading?.mainHeaderLoading ? (
                          <Skeleton.Button
                            className="my-1"
                            active
                            block
                            style={{ height: "55px" }}
                          />
                        ) : (
                          <li className="nav-item mt-5  border border-dashed border-gray-400 rounded py-2 px-3 me-0">
                            <div className=" d-flex align-items-center justify-content-between">
                              <span
                                className="nav-link text-gray-800 text-active-primary ms-0 py-0 me-0 ps-5 border-0 fw-bold"
                                href
                              >
                                Bullet Points
                                <span className="bullet-custom position-absolute start-0 top-0 w-3px h-100 bg-primary rounded-end" />
                              </span>
                              <Progress
                                type="circle"
                                percent={20}
                                size={40}
                                strokeColor={"#d3c11b"}
                              />
                            </div>
                          </li>
                        )}

                        {allLoading?.mainHeaderLoading ? (
                          <Skeleton.Button
                            className="my-1"
                            active
                            block
                            style={{ height: "55px" }}
                          />
                        ) : (
                          <li className="nav-item mt-5 border border-dashed border-gray-400 rounded py-2 px-3 me-0">
                            <div className=" d-flex align-items-center justify-content-between">
                              <span
                                className="nav-link text-gray-800 text-active-primary ms-0 py-0 me-0 ps-5 border-0 fw-bold"
                                href
                              >
                                Product Description
                                <span className="bullet-custom position-absolute start-0 top-0 w-3px h-100 bg-primary rounded-end" />
                              </span>
                              <Progress
                                type="circle"
                                percent={41}
                                size={40}
                                strokeColor={"orange"}
                              />
                            </div>
                          </li>
                        )}

                        {allLoading?.mainHeaderLoading ? (
                          <Skeleton.Button
                            className="my-1"
                            active
                            block
                            style={{ height: "55px" }}
                          />
                        ) : (
                          <li className="nav-item mt-5 border border-dashed border-gray-400 rounded py-2 px-3 me-0">
                            <div className=" d-flex align-items-center justify-content-between">
                              <span
                                className="nav-link text-gray-800 text-active-primary ms-0 py-0 me-0 ps-5 border-0 fw-bold"
                                href
                              >
                                Images
                                <span className="bullet-custom position-absolute start-0 top-0 w-3px h-100 bg-primary rounded-end" />
                              </span>
                              <Progress
                                type="circle"
                                percent={35}
                                size={40}
                                strokeColor={"#d3c11b"}
                              />
                            </div>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>

                  <div className="flex-lg-row-fluid ms-lg-10 ">
                    <div className="row gy-5 g-xl-5">
                      <PdTitle
                        productCriteriaData={productCriteriaData}
                        productData={productData}
                        suggestedTitleAnalysis={suggestedTitleAnalysis}
                        setSuggestedTitleAnalysis={setSuggestedTitleAnalysis}
                        allLoading={allLoading}
                        setAllLoading={setAllLoading}
                        recommendedTitle={recommendedTitle}
                        setRecommendedTitle={setRecommendedTitle}
                        {...props}
                      />

                      <PdBulletPoints
                        productData={productData}
                        allLoading={allLoading}
                        setAllLoading={setAllLoading}
                        suggestedBullets={suggestedBullets}
                        setSuggestedBullets={setSuggestedBullets}
                        recommendedBullets={recommendedBullets}
                        setRecommendedBullets={setRecommendedBullets}
                        {...props}
                      />

                      <PdDescription
                        productData={productData}
                        allLoading={allLoading}
                        setAllLoading={setAllLoading}
                        suggestedDescAnalysis={suggestedDescAnalysis}
                        setSuggestedDescAnalysis={setSuggestedDescAnalysis}
                        recommendedDescription={recommendedDescription}
                        setRecommendedDescription={setRecommendedDescription}
                        {...props}
                      />
                      <PdImages
                        productData={productData}
                        allLoading={allLoading}
                      />
                      {/* <PdReview {...props} /> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ProductDetailsWrapper>
  );
}
