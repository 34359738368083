import axiosCall from "../../../configurations/network-services/axiosCall";

// const prefix = "dashboard";

export const GetSupplierDataList = (data) => {
  const path = `supplier?page=${data?.page || 1}&per-page=${
    data?.perPage || 10
  }`;
  const responseType = "GET_SUPPLIER_DATA_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const SaveSupplierAction = (supplierId, data) => {
  const method = supplierId ? "PUT" : "post";
  const path = supplierId ? `supplier/${supplierId}` : `supplier`;
  const responseType = "SAVE_SUPPLIER_DATA";
  return axiosCall(method, path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const DeleteSupplierAction = (supplierId) => {
  const path = `supplier/${supplierId}`;
  const responseType = "DELETE_SUPPLIER";
  return axiosCall("delete", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const fakeActionSupplier = (data) => async (dispatch) => {
  dispatch({ type: "FAKE_ACTION_SUPPLIER", state: data });
};
