import React, { useContext, useEffect } from "react";
import { GlobalContext } from "../../../commonContext";
import AmazonBuyBox from "./amazon-buy-box";
import UnderConstruction from "../../../component/under-construction/under-construction";
export default function (props) {
  const contextVar = useContext(GlobalContext);

  return contextVar?.data?.marketplaceType === "amazon" ? (
    <AmazonBuyBox {...props} />
  ) : (
    <UnderConstruction />
  );
}
