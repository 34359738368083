import { Alert, Checkbox, Input, Select, message } from "antd";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { SalesChannelFlag } from "../../../../../config";
import { Link } from "react-router-dom";
export default function (props) {
  const {
    show,
    onHide,
    onSuccess,
    StoreWMConnectAction,
    fakeActionMarketplaceCred,
  } = props;

  const [seller_name, setSeller_name] = useState(
    JSON.parse(localStorage.getItem("user"))?.seller_name
  );
  const [auth_token, setAuth_token] = useState("");
  const [consumer_id, setConsumer_id] = useState("");
  const [advertiser_id, setAdvertiser_id] = useState("");
  const [account_type, set_account_type] = useState(null);
  const [marketplace_id, setMarketplace_id] = useState("ATVPDKIKX0DER");

  const StoreWalMartConnectRes = useSelector(
    (state) => state.MarketplaceCredential.StoreWalMartConnectResponse || {}
  );

  useEffect(() => {
    if (StoreWalMartConnectRes.status === true) {
      message.destroy();
      message.success(StoreWalMartConnectRes?.message);
      blankAllInput();
      onSuccess();
      fakeActionMarketplaceCred("StoreWalMartConnectResponse");
    } else if (StoreWalMartConnectRes.status === false) {
      message.destroy();
      message.error(StoreWalMartConnectRes?.message);
      fakeActionMarketplaceCred("StoreWalMartConnectResponse");
    }
  }, [StoreWalMartConnectRes]);

  const blankAllInput = () => {
    setAuth_token("");
    setConsumer_id("");
    setAdvertiser_id("");
    setMarketplace_id(null);
    set_account_type(null);
  };
  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          onHide();
        }}
        size="md"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Walmart Connect Credential</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <div className="row">
              <div className="col-12">
                <div>
                  <label className="fw-bold fs-6">Seller Name</label>
                </div>
                <div>
                  <Input
                    value={seller_name}
                    onChange={(e) => {
                      setSeller_name(e.target.value);
                    }}
                    size="large"
                    className="ant_common_input"
                  />
                </div>
              </div>

              <div className="col-12 mt-5">
                <div>
                  <label className="fw-bold fs-6">Auth Token</label>
                </div>
                <div>
                  <Input
                    value={auth_token}
                    onChange={(e) => {
                      setAuth_token(e.target.value);
                    }}
                    size="large"
                    className="ant_common_input"
                  />
                </div>
              </div>

              <div className="col-12 mt-5">
                <div>
                  <label className="fw-bold fs-6">Consumer Id</label>
                </div>
                <div>
                  <Input
                    value={consumer_id}
                    onChange={(e) => {
                      setConsumer_id(e.target.value);
                    }}
                    size="large"
                    className="ant_common_input"
                  />
                </div>
              </div>

              <div className="col-12 mt-5">
                <div>
                  <label className="fw-bold fs-6">Advertisement Id</label>
                </div>
                <div>
                  <Input
                    value={advertiser_id}
                    onChange={(e) => {
                      setAdvertiser_id(e.target.value);
                    }}
                    size="large"
                    className="ant_common_input"
                  />
                </div>
              </div>

              <div className="col-12 mt-5">
                <div>
                  <label className="fw-bold fs-6">Account Type</label>
                </div>
                <div>
                  <Select
                    size="large"
                    style={{ width: "100%" }}
                    onChange={(e) => {
                      set_account_type(e);
                    }}
                    value={account_type}
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    options={[
                      { label: "1PL", value: "1pl" },
                      { label: "3PL", value: "3pl" },
                    ]}
                  />
                </div>
              </div>
              {/* <div className="col-12 mt-5">
                <div>
                  <label className="fw-bold fs-6">Marketplace</label>
                </div>
                <div>
                  <Select
                    size="large"
                    style={{ width: "100%" }}
                    placeholder="Select Marketplace"
                    options={[{ label: "USA", value: "ATVPDKIKX0DER" }]}
                    onChange={(e) => {
                      setMarketplace_id(e);
                    }}
                    value={marketplace_id}
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  />
                </div>
              </div> */}
            </div>
          </>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-light-danger"
            onClick={() => {
              blankAllInput();
              onHide();
            }}
          >
            Close
          </button>

          <button
            type="button"
            className="btn btn-primary"
            onClick={() => {
              if (
                !seller_name ||
                !auth_token ||
                !consumer_id ||
                !advertiser_id ||
                !marketplace_id ||
                !account_type
              ) {
                message.destroy();
                message.warning("Please Enter Value");
              } else {
                const Obj = {
                  auth_token: auth_token,
                  consumer_id: consumer_id,
                  advertiser_id: advertiser_id,
                  marketplace_id: marketplace_id,
                  seller_name: seller_name,
                  account_type: account_type,
                };
                message.loading("Loading...", 0);
                StoreWMConnectAction(Obj);
              }
            }}
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
