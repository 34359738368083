import axiosCall from "../../configurations/network-services/axiosCall";

const prefix = "dashboard";

export const CriteriaAnalysisAdminAction = (data) => {
  const path = `criteria-analysis`;
  const responseType = "GET_CRITERIA_ANALYTICS_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const EditCriteriaAnalysisAdminAction = (id, data) => {
  const path = `criteria-analysis/${id}`;
  const responseType = "EDIT_CRITERIA_ANALYSIS";
  return axiosCall("put", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const fakeActionCriteriaAnalytics = (data) => async (dispatch) => {
  dispatch({ type: "FAKE_ACTION_CRITERIA_ANALYTICS", state: data });
};
