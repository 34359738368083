import React, { useContext, useEffect, useState } from "react";
import AllCampaignPerformanceSummery from "./lib/all-campaign-performance-summery";
import AllCampaignPerformance from "./lib/all-campaign-performance";
import DailyPerformance from "./lib/daily-performance";
import PlatformPerformance from "./lib/platform-performance";
import CampaignPerformance from "./lib/campaign-performance";
import PageTypePerformance from "./lib/page-type-performance";
import InshightSnapshot from "./lib/insight-snapshot";
import { Select, Skeleton, message } from "antd";
import { useSelector } from "react-redux";
import RangePickerIVCSR from "../../../component/range-picker";
import { DateFormat, NumberWithCommas } from "../../../config";
import dayjs from "dayjs";
import moment from "moment";
import Loading from "../../../component/loading";
import writeXlsxFile from "write-excel-file";
import Chart from "react-apexcharts";
import PerformanceBarChart from "./lib/performance-bar-chart";
import NoData from "../../../component/no-data";

export default function (props) {
  const {
    GetWalmartPerformanceData,
    GetWalmartAdvanceReportSummaryData,
    GetWalmartAdvanceReportChartData,
    fakeActionWalmartAdvanceReports,
  } = props;

  const [filters, setFilters] = useState({
    start_date: dayjs().add(-30, "d"),
    end_date: dayjs(),
  });
  const [selectedPerformance, setselectedPerformance] = useState("daily");
  const [selectedAttribute, setselectedAttribute] =
    useState("attributed14days");
  const [list, setList] = useState([]);
  const [totalPage, setTotalPage] = useState("");
  const [performanceSummary, setPerformanceSummary] = useState({});
  const [chartSeries, setChartSeries] = useState([]);
  const [chartDate, setChartDate] = useState([]);
  const [exportGraphArray, setExportGraphArray] = useState([]);
  const [loading, setLoading] = useState(true);
  const [summaryLoading, setSummaryLoading] = useState(true);
  const [chartLoading, setChartLoading] = useState(true);

  const [seriesLabel, setSeriesLabel] = useState([]);
  const [seriesData, setSeriesData] = useState([]);
  const [fullSeriesObj, setFullSeriesObj] = useState([]);
  const [impression_sum, set_impression_sum] = useState(0);

  const GetWalmartPerformanceDataRes = useSelector(
    (state) => state.AdvanceReport.GetWalmartPerformanceDataResponse || {}
  );
  const GetAdvanceReportSummaryDataRes = useSelector(
    (state) => state.AdvanceReport.GetAdvanceReportSummaryDataResponse || {}
  );
  const GetAdvanceReportChartDataRes = useSelector(
    (state) => state.AdvanceReport.GetAdvanceReportChartDataResponse || {}
  );

  useEffect(() => {
    const Obj = {
      start_date: moment(filters?.start_date?.$d).format("YYYY-MM-DD"),
      end_date: moment(filters?.end_date?.$d).format("YYYY-MM-DD"),
      performance_type: selectedPerformance,
      attributed_days: selectedAttribute,
    };
    GetWalmartPerformanceData(Obj);
    GetWalmartAdvanceReportSummaryData(Obj);
    GetWalmartAdvanceReportChartData(Obj);
  }, []);

  useEffect(() => {
    if (GetWalmartPerformanceDataRes.status === true) {
      setList(GetWalmartPerformanceDataRes?.data?.data);
      if (
        selectedPerformance === "platform" ||
        selectedPerformance === "page_type"
      ) {
        let sum = 0;
        const impression = GetWalmartPerformanceDataRes?.data?.data?.map(
          (d) => {
            sum = sum + parseInt(d?.impression);
            return {
              impression: parseInt(d?.impression),
              name:
                selectedPerformance === "page_type"
                  ? d?.page_type
                  : d?.platform,
            };
          }
        );

        const imprePer = impression?.map((d) => {
          const answer = (d?.impression * 100) / sum;
          return NumberWithCommas(answer);
        });
        set_impression_sum(sum);
        setFullSeriesObj(impression);
        setSeriesData(imprePer);
        setSeriesLabel(
          impression?.map((d) => {
            return d?.name;
          })
        );
        console.log(impression, sum, imprePer, "GetWalmartPerformanceDataRes");
      }
      setLoading(false);
      fakeActionWalmartAdvanceReports("GetWalmartPerformanceDataResponse");
    } else if (GetWalmartPerformanceDataRes.status === false) {
      setLoading(false);
      message.destroy();
      message.error(GetAdvanceReportSummaryDataRes?.message);
      fakeActionWalmartAdvanceReports("GetWalmartPerformanceDataResponse");
    }
  }, [GetWalmartPerformanceDataRes]);

  useEffect(() => {
    if (GetAdvanceReportSummaryDataRes.status === true) {
      setPerformanceSummary(GetAdvanceReportSummaryDataRes?.data?.data);
      setSummaryLoading(false);
      fakeActionWalmartAdvanceReports("GetAdvanceReportSummaryDataResponse");
    } else if (GetAdvanceReportSummaryDataRes.status === false) {
      setSummaryLoading(false);
      message.destroy();
      message.error(GetAdvanceReportSummaryDataRes?.message);
      fakeActionWalmartAdvanceReports("GetAdvanceReportSummaryDataResponse");
    }
  }, [GetAdvanceReportSummaryDataRes]);

  useEffect(() => {
    if (GetAdvanceReportChartDataRes.status === true) {
      const finalSeries = Object.entries(
        GetAdvanceReportChartDataRes?.data?.data?.chartData
      )?.map((d) => {
        // console.log(d, "-------cahrt d");
        return {
          name: d?.[0]?.replace("_", " "),
          data: Object?.entries(d?.[1])?.map((d) => d?.[1]),
        };
      });
      setChartSeries(finalSeries);
      setChartDate(GetAdvanceReportChartDataRes?.data?.data?.chartDate);

      const finalData = Object.entries(
        GetAdvanceReportChartDataRes?.data?.data?.chartDate
      )?.map((d) => {
        const newdata = Object.entries(
          GetAdvanceReportChartDataRes?.data?.data?.chartData
        )?.map((a) => {
          console.log({ [a[0]]: a?.[1]?.[d?.[1]] }, "aa");
          return { [a[0]]: a?.[1]?.[d?.[1]] };
        });
        console.log(d, "dd");
        console.log(newdata, "newdata");
        const myArr = { date: d?.[1], other: newdata };

        const transformedData = myArr.other.reduce((result, obj) => {
          const key = Object.keys(obj)[0];
          result[key] = obj[key];
          return result;
        }, {});

        const finalResult = {
          date: myArr.date,
          ...transformedData,
        };

        console.log(finalResult, "finalResult");
        return finalResult;
      });
      console.log(finalData, "-------------finalData");
      setExportGraphArray(finalData);
      setChartLoading(false);
      fakeActionWalmartAdvanceReports("GetAdvanceReportChartDataResponse");
    } else if (GetAdvanceReportChartDataRes.status === false) {
      message.destroy();
      message.error(GetAdvanceReportChartDataRes?.message);
      setChartLoading(false);
      fakeActionWalmartAdvanceReports("GetAdvanceReportChartDataResponse");
    }
  }, [GetAdvanceReportChartDataRes]);

  const downloadXl = async (header, dataRow, name) => {
    const data = [header, ...dataRow];
    await writeXlsxFile(data, {
      fileName: name,
    });
    message.destroy();
  };

  return (
    <div className="fadeInRight">
      <>
        <div className="d-flex flex-column flex-column-fluid">
          <div id="kt_app_content" className="app-content flex-column-fluid">
            <div
              id="kt_app_content_container"
              className="app-container container-fluid"
            >
              <div className="row mb-5">
                <div className="d-flex flex-wrap gap-2 ">
                  <div className="position-relative">
                    <RangePickerIVCSR
                      className="ant_common_input"
                      id="ivcsr_date_picker"
                      size="large"
                      allowClear={false}
                      style={{ width: "290px" }}
                      format={DateFormat}
                      value={[filters?.start_date, filters?.end_date]}
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentNode
                      }
                      onChange={(e) => {
                        const filters_ = filters;
                        filters_.start_date = e?.[0];
                        filters_.end_date = e?.[1];
                        setFilters({ ...filters_ });

                        setLoading(true);
                        setSummaryLoading(true);
                        setChartLoading(true);
                        const Obj = {
                          start_date: moment(e?.[0]?.$d).format("YYYY-MM-DD"),
                          end_date: moment(e?.[1]?.$d).format("YYYY-MM-DD"),
                          performance_type: selectedPerformance,
                          attributed_days: selectedAttribute,
                        };

                        GetWalmartPerformanceData(Obj);
                        GetWalmartAdvanceReportSummaryData(Obj);
                        GetWalmartAdvanceReportChartData(Obj);
                      }}
                    />
                  </div>
                  <div className="position-relative">
                    <Select
                      value={selectedPerformance}
                      style={{ width: 200 }}
                      size="large"
                      onChange={(e) => {
                        setselectedPerformance(e);
                        if (e !== "insight") {
                          setLoading(true);
                          setSummaryLoading(true);
                          setChartLoading(true);
                          const Obj = {
                            start_date: moment(filters?.start_date?.$d).format(
                              "YYYY-MM-DD"
                            ),
                            end_date: moment(filters?.end_date?.$d).format(
                              "YYYY-MM-DD"
                            ),
                            performance_type: e,
                            attributed_days: selectedAttribute,
                          };
                          GetWalmartPerformanceData(Obj);
                          GetWalmartAdvanceReportSummaryData(Obj);
                          GetWalmartAdvanceReportChartData(Obj);
                        }
                      }}
                      options={[
                        {
                          label: "Daily Performance",
                          value: "daily",
                        },
                        {
                          label: "Campaign Performance",
                          value: "campaign",
                        },
                        {
                          label: "Platform Performance",
                          value: "platform",
                        },
                        {
                          label: "Page Type Performance",
                          value: "page_type",
                        },
                      ]}
                    />
                  </div>
                  <div className="position-relative">
                    <Select
                      value={selectedAttribute}
                      style={{ width: 200 }}
                      size="large"
                      disabled={selectedPerformance === "insight"}
                      onChange={(e) => {
                        setselectedAttribute(e);
                        if (selectedPerformance !== "insight") {
                          setLoading(true);
                          setSummaryLoading(true);
                          setChartLoading(true);
                          const Obj = {
                            start_date: moment(filters?.start_date?.$d).format(
                              "YYYY-MM-DD"
                            ),
                            end_date: moment(filters?.end_date?.$d).format(
                              "YYYY-MM-DD"
                            ),
                            performance_type: selectedPerformance,
                            attributed_days: e,
                          };
                          GetWalmartPerformanceData(Obj);
                          GetWalmartAdvanceReportSummaryData(Obj);
                          GetWalmartAdvanceReportChartData(Obj);
                        }
                      }}
                      options={[
                        {
                          label: "3 Days Attribution",
                          value: "attributed3days",
                        },
                        {
                          label: "14 Days Attribution",
                          value: "attributed14days",
                        },
                        {
                          label: "30 Days Attribution",
                          value: "attributed30days",
                        },
                      ]}
                    />
                  </div>
                </div>
              </div>

              <div className="row gy-5 g-xl-5">
                <div className="col-xxl-12">
                  <div className="card card-xxl-stretch mb-5 mb-xl-8">
                    <div className="card-header border-bottom-dashed">
                      <h3 className="card-title ">
                        <span className="card-label fw-bold text-gray-900">
                          All Campaigns Performance Summary
                        </span>
                      </h3>

                      <div className="card-toolbar">
                        <div className="position-relative"></div>
                      </div>
                    </div>
                    <div className="card-body pb-5 pt-0">
                      {selectedPerformance === "page_type" ||
                      selectedPerformance === "platform" ? (
                        <PerformanceBarChart
                          {...props}
                          chartLoading={chartLoading}
                          fullSeriesObj={fullSeriesObj}
                          seriesLabel={seriesLabel}
                          seriesData={seriesData}
                          selectedPerformance={selectedPerformance}
                          sum={impression_sum}
                        />
                      ) : (
                        <AllCampaignPerformanceSummery
                          performanceSummary={performanceSummary}
                          chartSeries={chartSeries}
                          chartDate={chartDate}
                          summaryLoading={summaryLoading}
                          chartLoading={chartLoading}
                          exportGraphArray={exportGraphArray}
                          selectedPerformance={selectedPerformance}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="row gy-5 g-xl-5">
                <div className="col-xxl-12">
                  <div className="card card-xxl-stretch mb-5 mb-xl-8">
                    <div className="card-header border-bottom-dashed">
                      <h3 className="card-title ">
                        <span className="card-label fw-bold text-gray-900">
                          All Campaigns Performance Summary
                        </span>
                      </h3>

                      <div className="card-toolbar">
                        <div className="position-relative"></div>
                      </div>
                    </div>
                    <div className="card-body pb-5 pt-0">
                      <AllCampaignPerformance
                        performanceSummary={performanceSummary}
                        summaryLoading={summaryLoading}
                        chartLoading={chartLoading}
                      />
                    </div>
                  </div>
                </div>
              </div> */}
              {loading ? <Loading /> : ""}
              {selectedPerformance === "daily" && !loading && (
                <div className="row gy-5 g-xl-5">
                  <div className="col-xxl-12">
                    <div className="card card-xxl-stretch mb-5 mb-xl-8">
                      <div className="card-header border-bottom-dashed">
                        <h3 className="card-title ">
                          <span className="card-label fw-bold text-gray-900">
                            Daily Performance
                          </span>
                        </h3>

                        <div className="card-toolbar">
                          <div className="position-relative">
                            <button
                              className="btn btn-light-info fw-bolder fs-6 ms-2"
                              onClick={async () => {
                                const header = [
                                  { value: "Date", type: String },
                                  { value: "Impressions", type: String },
                                  { value: "Clicks", type: String },
                                  { value: "CTR", type: String },
                                  { value: "Ad Spend", type: String },
                                  {
                                    value: "Total Attr. Sales Revenue",
                                    type: String,
                                  },
                                  { value: "RoAS", type: String },
                                ];

                                let dataRow = [];
                                await list.map((d) => {
                                  const newList = [
                                    d?.report_date,
                                    d?.impression,
                                    d?.ad_clicks,
                                    d?.CTR,
                                    d?.ad_spend,
                                    d?.totalAttrSalesRevenue,
                                    d?.ROAS,
                                  ];
                                  dataRow = [
                                    ...dataRow,
                                    newList.map((d) => {
                                      return { value: d };
                                    }),
                                  ];
                                });
                                downloadXl(
                                  header,
                                  dataRow,
                                  "daily-performance.xlsx"
                                );
                              }}
                            >
                              Export
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="card-body pb-5 pt-0">
                        {/* {list?.length > 0 ? ( */}
                        <DailyPerformance {...props} list={list} />
                        {/* ) : (
                          <NoData type="new" />
                        )} */}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {selectedPerformance === "campaign" && !loading && (
                <div className="row gy-5 g-xl-5">
                  <div className="col-xxl-12">
                    <div className="card card-xxl-stretch mb-5 mb-xl-8">
                      <div className="card-header border-bottom-dashed">
                        <h3 className="card-title ">
                          <span className="card-label fw-bold text-gray-900">
                            Campaign Performance
                          </span>
                        </h3>

                        <div className="card-toolbar">
                          <div className="position-relative">
                            <button
                              className="btn btn-light-info fw-bolder fs-6 ms-2"
                              onClick={async () => {
                                const header = [
                                  { value: "Campaign", type: String },
                                  { value: "Impressions", type: String },
                                  { value: "Clicks", type: String },
                                  { value: "CTR", type: String },
                                  { value: "Ad Spend", type: String },
                                  {
                                    value: "Total Attr. Sales Revenue",
                                    type: String,
                                  },
                                  { value: "RoAS", type: String },
                                  {
                                    value: "In-store Attributed Sales (Beta)",
                                    type: String,
                                  },
                                ];

                                let dataRow = [];
                                await list.map((d) => {
                                  const newList = [
                                    d?.campaign_name,
                                    d?.impression,
                                    d?.ad_clicks,
                                    d?.CTR,
                                    d?.ad_spend,
                                    d?.totalAttrSalesRevenue,
                                    d?.ROAS,
                                    d?.in_store,
                                  ];
                                  dataRow = [
                                    ...dataRow,
                                    newList.map((d) => {
                                      return { value: d };
                                    }),
                                  ];
                                });
                                downloadXl(
                                  header,
                                  dataRow,
                                  "campaign-performance.xlsx"
                                );
                              }}
                            >
                              Export
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="card-body pb-5 pt-0">
                        <CampaignPerformance {...props} list={list} />
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {selectedPerformance === "platform" && !loading && (
                <div className="row gy-5 g-xl-5">
                  <div className="col-xxl-12">
                    <div className="card card-xxl-stretch mb-5 mb-xl-8">
                      <div className="card-header border-bottom-dashed">
                        <h3 className="card-title ">
                          <span className="card-label fw-bold text-gray-900">
                            Platform Performance
                          </span>
                        </h3>

                        <div className="card-toolbar">
                          <div className="position-relative">
                            <button
                              className="btn btn-light-info fw-bolder fs-6 ms-2"
                              onClick={async () => {
                                const header = [
                                  { value: "Platform", type: String },
                                  { value: "Impressions", type: String },
                                  { value: "Clicks", type: String },
                                  { value: "CTR", type: String },
                                  { value: "Ad Spend", type: String },
                                  {
                                    value: "Total Attr. Sales Revenue",
                                    type: String,
                                  },
                                  { value: "RoAS", type: String },
                                  {
                                    value: "In-store Attributed Sales (Beta)",
                                    type: String,
                                  },
                                ];

                                let dataRow = [];
                                await list.map((d) => {
                                  const newList = [
                                    d?.platform,
                                    d?.impression,
                                    d?.ad_clicks,
                                    d?.CTR,
                                    d?.ad_spend,
                                    d?.totalAttrSalesRevenue,
                                    d?.ROAS,
                                    d?.in_store,
                                  ];
                                  dataRow = [
                                    ...dataRow,
                                    newList.map((d) => {
                                      return { value: d };
                                    }),
                                  ];
                                });
                                downloadXl(
                                  header,
                                  dataRow,
                                  "platform-performance.xlsx"
                                );
                              }}
                            >
                              Export
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="card-body pb-5 pt-0">
                        <PlatformPerformance {...props} list={list} />
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {selectedPerformance === "page_type" && !loading && (
                <div className="row gy-5 g-xl-5">
                  <div className="col-xxl-12">
                    <div className="card card-xxl-stretch mb-5 mb-xl-8">
                      <div className="card-header border-bottom-dashed">
                        <h3 className="card-title ">
                          <span className="card-label fw-bold text-gray-900">
                            Page Type Performance
                          </span>
                        </h3>

                        <div className="card-toolbar">
                          <div className="position-relative">
                            <button
                              className="btn btn-light-info fw-bolder fs-6 ms-2"
                              onClick={async () => {
                                const header = [
                                  { value: "Page Type", type: String },
                                  { value: "Impressions", type: String },
                                  { value: "Clicks", type: String },
                                  { value: "CTR", type: String },
                                  { value: "Ad Spend", type: String },
                                  {
                                    value: "Total Attr. Sales Revenue",
                                    type: String,
                                  },
                                  { value: "RoAS", type: String },
                                  {
                                    value: "In-store Attributed Sales (Beta)",
                                    type: String,
                                  },
                                ];

                                let dataRow = [];
                                await list.map((d) => {
                                  const newList = [
                                    d?.page_type,
                                    d?.impression,
                                    d?.ad_clicks,
                                    d?.CTR,
                                    d?.ad_spend,
                                    d?.totalAttrSalesRevenue,
                                    d?.ROAS,
                                    d?.in_store,
                                  ];
                                  dataRow = [
                                    ...dataRow,
                                    newList.map((d) => {
                                      return { value: d };
                                    }),
                                  ];
                                });
                                downloadXl(
                                  header,
                                  dataRow,
                                  "page-type-performance.xlsx"
                                );
                              }}
                            >
                              Export
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="card-body pb-5 pt-0">
                        <PageTypePerformance {...props} list={list} />
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* {selectedPerformance === "insight" && ( */}
              <div className="row gy-5 g-xl-5">
                <div className="col-xxl-12">
                  <div className="card card-xxl-stretch mb-5 mb-xl-8">
                    <InshightSnapshot {...props} />
                  </div>
                </div>
              </div>
              {/* )} */}
            </div>
          </div>
        </div>
      </>
    </div>
  );
}
