import axiosCall from "../../../../configurations/network-services/axiosCall";

const prefix = "dashboard";

export const GetWalmartBidManagementList = (data) => {
  const path = `wm/rule-management/get-rules?page=${data?.page || 1}&per-page=${
    data?.perPage || 10
  }&marketplace_id=${data?.marketplaceId || ""}&rule_name=${
    data?.ruleName || ""
  }&status=${data?.ruleStatus || ""}`;
  const responseType = "GET_WALMART_BID_MANAGEMENT_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetWalmartBidRuleDetails = (id) => {
  const path = `wm/rule-management/get-rule/${id}`;
  const responseType = "GET_WALMART_BID_RULE_DETAILS";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const CreateWalmartRuleAction = (data, id) => {
  const method = id ? "put" : "post";
  const path = id
    ? `wm/rule-management/update-rule/${id}`
    : `wm/rule-management/create-rule`;
  const responseType = "CREATE_WALMART_RULE_ACTION";
  return axiosCall(method, path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const UpdateWalmartRuleStatusAction = (id, data) => {
  const path = `wm/rule-management/update-status/${id}`;
  const responseType = "UPDATE_WALMART_RULE_STATUS_ACTION";
  return axiosCall("PUT", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

// export const UpdateWalmartRuleAction = (id, data) => {
//   const path = `wm/rule-management/update-rule/${id}`;
//   const responseType = "UPDATE_WALMART_RULE_ACTION";
//   return axiosCall("PUT", path, responseType, data, {
//     Authorization: `Bearer ${localStorage.getItem("token")}`,
//   });
// };

export const DeleteWalmartRuleAction = (id) => {
  const path = `wm/rule-management/delete-rule/${id}`;
  const responseType = "DELETE_WALMART_BID_MANAGEMENT_RULE";
  return axiosCall("delete", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetWalmartCreateRuleAttribute = () => {
  const path = `wm/rule-management/get-create-rule-attributes`;
  const responseType = "GET_WALMART_CREATE_RULE_ATTRIBUTE";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetWalmartBidRuleCampaignList = () => {
  const path = `wm/rule-management/campaign-list`;
  const responseType = "GET_WALMART_BID_RULE_CAMPAIGN_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetWalmartBidRuleAdGroupList = () => {
  const path = `wm/rule-management/ad-groups-list`;
  const responseType = "GET_WALMART_BID_RULE_AD_GROUP_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const GetWalmartAppliedRuleLogList = (data) => {
  const path = `wm/rule-management/get-applied-rule-logs?rule_id=${
    data?.rule_id || ""
  }&page=${data?.page || 1}&per-page=${data?.perPage || 10}&marketplace_id=${
    data?.marketplaceId || ""
  }`;
  const responseType = "GET_WALMART_APPLIED_RULE_LOG_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const fakeActionWalmartBidManagement = (data) => async (dispatch) => {
  dispatch({ type: "FAKE_ACTION_WALMART_BID_MANAGEMENT", state: data });
};
