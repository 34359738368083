import { Button, Drawer, Space, Form, Input, message } from "antd";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";

export default function (props) {
  const {
    onClose,
    open,
    AddCriteriaAnalysisAdminAction,
    fakeActionCriteriaAnalysis,
    getTable,
  } = props;
  const [form] = Form.useForm();

  const AddCriteriaAnalysisResponse = useSelector(
    (state) => state.CriteriaAnalysis.AddCriteriaAnalysisResponse || {}
  );

  useEffect(() => {
    if (AddCriteriaAnalysisResponse?.status === true) {
      message.destroy();
      message.success(AddCriteriaAnalysisResponse?.message);
      getTable();
      onClose();
      fakeActionCriteriaAnalysis("AddCriteriaAnalysisResponse");
    } else if (AddCriteriaAnalysisResponse?.status === false) {
      message.destroy();
      message.warning(AddCriteriaAnalysisResponse?.message);
      fakeActionCriteriaAnalysis("AddCriteriaAnalysisResponse");
    }
  }, [AddCriteriaAnalysisResponse]);

  const onReset = () => {
    form.resetFields();
  };
  return (
    <Drawer
      title="Add New"
      placement="right"
      width={500}
      onClose={() => {
        onReset();
        onClose();
      }}
      open={open}
      extra={
        <Space>
          <Button
            onClick={() => {
              onReset();
              onClose();
            }}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={() => {
              form
                .validateFields()
                .then((values) => {
                  message.destroy();
                  message.loading("Loading...", 0);
                  AddCriteriaAnalysisAdminAction(values);
                })
                .catch((info) => {});
            }}
          >
            OK
          </Button>
        </Space>
      }
    >
      <Form
        form={form}
        name="criteria-add"
        layout="vertical"
        // onFinish={onFinish}
        style={{
          width: "100%",
          padding: "0px 10px",
        }}
      >
        <Form.Item
          name="parameter_name"
          label="Parameter Name"
          hasFeedback
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input size="large" />
        </Form.Item>
        <Form.Item
          name="analysis_criteria"
          label="Analysis Criteria"
          hasFeedback
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input size="large" />
        </Form.Item>
        <Form.Item
          name="recommend_criteria"
          label="Recommend Criteria"
          hasFeedback
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input size="large" />
        </Form.Item>
      </Form>
    </Drawer>
  );
}
