import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import HeaderCard from "./walmart_lib/header-card";
import SalesOverview from "./walmart_lib/sales-overview";
import OrderOverview from "./walmart_lib/order-overview";
import SalesAnalyticsTable from "./walmart_lib/sales-analytics-table";
import writeXlsxFile from "write-excel-file";
import {
  Checkbox,
  Dropdown,
  Input,
  Popover,
  Radio,
  Select,
  Skeleton,
  Tag,
  message,
  theme,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Pagination from "../../../component/pagination";
import {
  DateFormat,
  DefaultPerPage,
  NumberWithCommas,
  SalesChannelFlag,
  handleDateRangeChange,
} from "../../../config";
import Loading from "../../../component/loading";
import NoData from "../../../component/no-data";
import RangePickerIVCSR from "../../../component/range-picker";
import dayjs from "dayjs";
import moment from "moment";
import { CheckboxWrapper } from "./style";
import { WalmartMarketplaceList } from "../../../config/static-select-option";
const { useToken } = theme;
export default function (props) {
  const {
    GetWalmartSalesAnalyticsCardData,
    GetWalmartSalesOverviewChart,
    GetDashboardUnitSoldChart,
    fakeActionDashboard,
    GetWalmartOrderOverviewChart,
    GetWalmartSalesAnalyticsTableData,
    GetSalesChannelList,
    fakeActionSalesAnalytics,
  } = props;

  const { token } = useToken();
  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
    minWidth: "150px",
  };

  const [searchTerm, setSearchTerm] = useState("");
  const [list, setList] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageSize, setPageSize] = useState(DefaultPerPage);
  const [salesChartDate, setSalesChartDate] = useState([]);
  const [salesChartSeries, setSalesChartSeries] = useState([]);
  const [orderChartDate, setOrderChartDate] = useState([]);
  const [orderChartSeries, setOrderChartSeries] = useState([]);
  const [salesAnalyticsCardData, setSalesAnalyticsCardData] = useState([]);
  const [salesChannelList, setsalesChannelList] = useState([]);
  const [selectedSalesChannel, setSelectedSalesChannel] = useState([]);
  const [selectedSalesChannelId, setSelectedSalesChannelId] = useState([]);
  const [salesChannelLoading, setSalesChannelLoading] = useState(true);
  const [selectedCurrencySign, setSelectedCurrencySign] = useState("");
  const [selectedDateRangeType, setSelectedDateRangeType] = useState("custom");
  const [filter, setFilter] = useState({
    date_period: [dayjs().add(-30, "d"), dayjs()],
  });
  const [cardLoading, setCardLoading] = useState(true);
  const [salesOverviewLoading, setSalesOverviewLoading] = useState(true);
  const [orderOverviewLoading, setOrderOverviewLoading] = useState(true);
  const [salesTableLoading, setSalesTableLoading] = useState(true);
  const [isExportFlag, setIsExportFlag] = useState(0);
  const [selectedViewBy, setSelectedViewBy] = useState("day");
  const [exportOrderGraphArray, setExportOrderGraphArray] = useState([]);
  const [exportGraphArray, setExportGraphArray] = useState([]);

  const GetSalesAnalyticsCardDataRes = useSelector(
    (state) =>
      state.SalesAnalytics.GetWalmartSalesAnalyticsCardDataResponse || {}
  );

  const GetSalesAnalyticsSalesOverviewChartRes = useSelector(
    (state) =>
      state.SalesAnalytics.GetWalmartSalesAnalyticsSalesOverviewChartResponse ||
      {}
  );

  const GetSalesAnalyticsOrderOverviewChartRes = useSelector(
    (state) =>
      state.SalesAnalytics.GetWalmartSalesAnalyticsOrderOverviewChartResponse ||
      {}
  );

  const GetSalesAnalyticsTableDataRes = useSelector(
    (state) =>
      state.SalesAnalytics.GetWalmartSalesAnalyticsTableDataResponse || {}
  );

  const GetSalesChannelListRes = useSelector(
    (state) => state.SalesAnalytics.GetSalesChannelListResponse || {}
  );

  const GetDashboardUnitSoldChartRes = useSelector(
    (state) => state.Dashboard.GetDashboardUnitSoldChartResponse || {}
  );

  useEffect(() => {
    GetSalesChannelList();
  }, []);

  useEffect(() => {
    if (GetSalesChannelListRes?.status === true) {
      setSalesChannelLoading(false);
      setsalesChannelList(GetSalesChannelListRes?.data?.sales_channel);
      setSelectedSalesChannel("Amazon.com");
      setSelectedSalesChannelId("ATVPDKIKX0DER");
      setSelectedCurrencySign("$");

      const apiObj = {
        page: 1,
        perPage: pageSize,
        searchTerm: searchTerm,
        sales_channel: "Amazon.com",
        filter_date:
          moment(filter?.date_period?.[0].$d).format("MMM DD, YYYY") +
          " - " +
          moment(filter?.date_period?.[1].$d).format("MMM DD, YYYY"),
        filterKey: selectedDateRangeType,
        isExport: 0,
        viewBy: selectedViewBy,
      };
      GetWalmartSalesAnalyticsTableData(apiObj);
      // GetWalmartSalesOverviewChart(apiObj);
      GetWalmartOrderOverviewChart(apiObj);
      GetWalmartSalesAnalyticsCardData(apiObj);
      GetDashboardUnitSoldChart(apiObj, "walmart");
      fakeActionSalesAnalytics("GetSalesChannelListResponse");
    } else if (GetSalesChannelListRes?.status === false) {
      fakeActionSalesAnalytics("GetSalesChannelListResponse");
    }
  }, [GetSalesChannelListRes]);

  useEffect(() => {
    if (GetSalesAnalyticsTableDataRes?.status === true) {
      // console.log(
      //   GetSalesAnalyticsTableDataRes,
      //   "GetSalesAnalyticsTableDataRes"
      // );
      if (isExportFlag === 0) {
        // console.log(
        //   GetSalesAnalyticsTableDataRes,
        //   "GetSalesAnalyticsTableDataRes"
        // );
        setTotalPage(
          GetSalesAnalyticsTableDataRes?.data?.pagination?.totalCount
        );

        setList(GetSalesAnalyticsTableDataRes?.data?.records);
        setSalesTableLoading(false);
      } else {
        // setIsExportList(GetSalesAnalyticsTableDataRes?.data?.records);
        downloadXl(GetSalesAnalyticsTableDataRes?.data?.records);
        setIsExportFlag(0);
      }

      fakeActionSalesAnalytics("GetSalesAnalyticsTableDataResponse");
    } else if (GetSalesAnalyticsTableDataRes?.status === false) {
      setSalesTableLoading(false);
      fakeActionSalesAnalytics("GetSalesAnalyticsTableDataResponse");
    }
  }, [GetSalesAnalyticsTableDataRes]);

  const exportHeader = [
    { value: "Product", type: String },
    { value: "Brand", type: String },
    { value: "SKU", type: String },
    { value: "Orders", type: String },
    { value: "Units Sold", type: String },
    { value: "Total Sales", type: String },
    { value: "COGS", type: String },
    { value: "Gross Revenue", type: String },
    { value: "Refund", type: String },
    { value: "Taxes", type: String },
    { value: "Net Profit", type: String },
    { value: "Profit Margin", type: String },
    { value: "ROI", type: String },
  ];

  const downloadXl = async (exportData) => {
    let dataRow = [];
    await exportData.map((d) => {
      const grossRevenue = d?.gross_revenue;
      const totalTax = NumberWithCommas(
        parseFloat(d?.shipping_tax) + parseFloat(d?.product_tax)
      );

      const newList = [
        d?.product_name,
        d?.brand,
        d?.sku,
        d?.total_orders,
        d?.total_units,
        d?.total_sales,
        d?.cogsData?.total_cogs,
        grossRevenue,
        d?.refund_revenue,
        totalTax,
        d?.net_profit,
        d?.profit_margin,
        d?.roi,
      ];
      dataRow = [
        ...dataRow,
        newList.map((d) => {
          return { value: d };
        }),
      ];
    });
    const data = [exportHeader, ...dataRow];
    await writeXlsxFile(data, {
      fileName: "Sale-Analytics.xlsx",
    });
    message.destroy();
  };

  // useEffect(() => {
  //   if (GetSalesAnalyticsSalesOverviewChartRes?.status === true) {
  //     console.log(
  //       GetSalesAnalyticsSalesOverviewChartRes,
  //       "GetSalesAnalyticsSalesOverviewChartRes"
  //     );
  //     const newSeries =
  //       GetSalesAnalyticsSalesOverviewChartRes?.data?.chartData?.map((d) => {
  //         delete d?.type;
  //         return { ...d };
  //       }) || [];
  //     console.log(newSeries, "newSeriesnewSeriesnewSeries");
  //     setSalesChartSeries(newSeries);
  //     setSalesChartDate(
  //       GetSalesAnalyticsSalesOverviewChartRes?.data?.chartDate
  //     );
  //     setSalesOverviewLoading(false);
  //     fakeActionSalesAnalytics("GetSalesAnalyticsSalesOverviewChartResponse");
  //   } else if (GetSalesAnalyticsSalesOverviewChartRes?.status === false) {
  //     setSalesOverviewLoading(false);
  //     fakeActionSalesAnalytics("GetSalesAnalyticsSalesOverviewChartResponse");
  //   }
  // }, [GetSalesAnalyticsSalesOverviewChartRes]);

  useEffect(() => {
    if (GetDashboardUnitSoldChartRes?.status === true) {
      // console.log(GetDashboardUnitSoldChartRes, "GetDashboardUnitSoldChartRes");
      const newSeries =
        Object.entries(GetDashboardUnitSoldChartRes?.data?.chartData)?.map(
          (d) => {
            // console.log(d, "d----------");
            const name = d?.[0] === "order_sales" ? "Total Sales" : "Unit Sold";
            const data = Object.entries(d?.[1])?.map((a) => {
              // console.log(a, "---------a");
              return a?.[1];
            });
            return { name: name, data: data };
          }
        ) || [];

      setSalesChartSeries(newSeries);
      setSalesChartDate(GetDashboardUnitSoldChartRes?.data?.chartDate);

      const finalData = Object.entries(
        GetDashboardUnitSoldChartRes?.data?.chartDate
      )?.map((d) => {
        const newdata = Object.entries(
          GetDashboardUnitSoldChartRes?.data?.chartData
        )?.map((a) => {
          console.log({ [a[0]]: a?.[1]?.[d?.[1]] }, "aa");
          return { [a[0]]: a?.[1]?.[d?.[1]] };
        });
        console.log(d, "dd");
        console.log(newdata, "newdata");
        const myArr = { date: d?.[1], other: newdata };

        const transformedData = myArr.other.reduce((result, obj) => {
          const key = Object.keys(obj)[0];
          result[key] = obj[key];
          return result;
        }, {});

        const finalResult = {
          date: myArr.date,
          ...transformedData,
        };

        console.log(finalResult, "finalResult");
        return finalResult;
      });
      setExportGraphArray(finalData);
      setSalesOverviewLoading(false);
      fakeActionDashboard("GetDashboardUnitSoldChartResponse");
    } else if (GetDashboardUnitSoldChartRes?.status === false) {
      setSalesOverviewLoading(false);
      setSalesChartSeries([]);
      setSalesChartDate([]);
      message.destroy();
      message.error(GetDashboardUnitSoldChartRes?.message);
      fakeActionDashboard("GetDashboardUnitSoldChartResponse");
    }
  }, [GetDashboardUnitSoldChartRes]);

  useEffect(() => {
    if (GetSalesAnalyticsOrderOverviewChartRes?.status === true) {
      // console.log(
      //   GetSalesAnalyticsOrderOverviewChartRes,
      //   "GetSalesAnalyticsOrderOverviewChartRes"
      // );
      const newSeries =
        Object.entries(
          GetSalesAnalyticsOrderOverviewChartRes?.data?.chartData
        )?.map((d) => {
          // console.log(d, "d----------");
          const name = d?.[0] === "order_count" ? "Orders" : "";
          const data = Object.entries(d?.[1])?.map((a) => {
            // console.log(a, "---------a");
            return a?.[1];
          });
          return { name: name, data: data };
        }) || [];
      // console.log(newSeries, "newSeries");
      setOrderChartSeries(newSeries);
      setOrderChartDate(
        GetSalesAnalyticsOrderOverviewChartRes?.data?.chartDate
      );

      const finalData = Object.entries(
        GetSalesAnalyticsOrderOverviewChartRes?.data?.chartDate
      )?.map((d) => {
        const newdata = Object.entries(
          GetSalesAnalyticsOrderOverviewChartRes?.data?.chartData
        )?.map((a) => {
          console.log({ [a[0]]: a?.[1]?.[d?.[1]] }, "aa");
          return { [a[0]]: a?.[1]?.[d?.[1]] };
        });
        console.log(d, "dd");
        console.log(newdata, "newdata");
        const myArr = { date: d?.[1], other: newdata };

        const transformedData = myArr.other.reduce((result, obj) => {
          const key = Object.keys(obj)[0];
          result[key] = obj[key];
          return result;
        }, {});

        const finalResult = {
          date: myArr.date,
          ...transformedData,
        };

        return finalResult;
      });
      // setExportGraphArray(finalData);
      console.log(finalData, "finalOrderResult");
      setExportOrderGraphArray(finalData);

      setOrderOverviewLoading(false);
      fakeActionSalesAnalytics("GetSalesAnalyticsOrderOverviewChartResponse");
    } else if (GetSalesAnalyticsOrderOverviewChartRes?.status === false) {
      setOrderOverviewLoading(false);
      fakeActionSalesAnalytics("GetSalesAnalyticsOrderOverviewChartResponse");
    }
  }, [GetSalesAnalyticsOrderOverviewChartRes]);

  useEffect(() => {
    if (GetSalesAnalyticsCardDataRes?.status === true) {
      // console.log(GetSalesAnalyticsCardDataRes, "GetSalesAnalyticsCardDataRes");
      setSalesAnalyticsCardData(GetSalesAnalyticsCardDataRes?.data || []);
      setCardLoading(false);
      fakeActionSalesAnalytics("GetSalesAnalyticsCardDataResponse");
    } else if (GetSalesAnalyticsCardDataRes?.status === false) {
      setCardLoading(false);
      fakeActionSalesAnalytics("GetSalesAnalyticsCardDataResponse");
    }
  }, [GetSalesAnalyticsCardDataRes]);

  const onPageNo = (e) => {
    setSalesTableLoading(true);
    const apiObj = {
      page: e,
      perPage: pageSize,
      searchTerm: searchTerm,
      sales_channel: selectedSalesChannel,
      filter_date:
        moment(filter?.date_period?.[0].$d).format("MMM DD, YYYY") +
        " - " +
        moment(filter?.date_period?.[1].$d).format("MMM DD, YYYY"),
      isExport: 0,
    };
    GetWalmartSalesAnalyticsTableData(apiObj);
    setPage(e);
  };

  const onPerPage = (e) => {
    setPage(1);
    setPageSize(e);
    setSalesTableLoading(true);
    const apiObj = {
      page: 1,
      perPage: e,
      searchTerm: searchTerm,
      sales_channel: selectedSalesChannel,
      filter_date:
        moment(filter?.date_period?.[0].$d).format("MMM DD, YYYY") +
        " - " +
        moment(filter?.date_period?.[1].$d).format("MMM DD, YYYY"),
      isExport: 0,
    };
    GetWalmartSalesAnalyticsTableData(apiObj);
  };

  const onRangeChange = (_, dateStrings, filterKey) => {
    const filter_ = { ...filter };
    if (_ === null) {
      const currDate = [dayjs().add(-30, "d"), dayjs()];
      filter_.date_period = currDate;
      //   dateStrings[0] = `${currDate[0]?.format("MMM DD, YYYY")}`;
      //   dateStrings[1] = `${currDate[1]?.format("MMM DD, YYYY")}`;
    } else {
      filter_.date_period = _;
    }
    setFilter(filter_);

    const apiObj = {
      page: 1,
      perPage: pageSize,
      searchTerm: searchTerm,
      sales_channel: selectedSalesChannel,
      filter_date:
        moment(_[0].$d).format("MMM DD, YYYY") +
        " - " +
        moment(_[1].$d).format("MMM DD, YYYY"),
      filterKey: filterKey,
      isExport: 0,
      viewBy: selectedViewBy,
    };

    setCardLoading(true);
    setSalesOverviewLoading(true);
    setOrderOverviewLoading(true);
    setSalesTableLoading(true);
    GetWalmartSalesAnalyticsTableData(apiObj);
    // GetWalmartSalesOverviewChart(apiObj);
    GetDashboardUnitSoldChart(apiObj, "walmart");
    GetWalmartOrderOverviewChart(apiObj);
    GetWalmartSalesAnalyticsCardData(apiObj);
  };

  const defaultColumns = [
    {
      title: "Sr. No",
      fixed: window.innerWidth > 992 ? "left" : false,
      fixed: "left",
      width: 30,
      render: (item) => {
        return (
          <div>
            <span>{(page - 1) * pageSize + 1 + item.key}</span>
          </div>
        );
      },
    },
    {
      title: "Product",
      dataIndex: "product_name",
      key: "product_name",
      fixed: "left",
      render: (item, row) => {
        return (
          <div className="d-flex align-items-center border-start border-2 border-info p-3 rounded">
            <div className="symbol symbol-50px  me-3">
              <img src={`${row?.image_url}`} alt="" />
            </div>
            {/*end::Avatar*/}
            {/*begin::User details*/}
            <div className="d-flex flex-column">
              <Popover
                content={
                  <div
                    style={{
                      height: "max-content",
                      width: "300px",
                    }}
                  >
                    {item}
                  </div>
                }
                placement="bottom"
              >
                <div
                  className="text-primary mb-1 fw-bolder text-one fs-6 popoverActionIconSingleLine"
                  style={{ minWidth: "100px" }}
                >
                  {item}
                </div>
              </Popover>
              <div>
                <span className="fs-6">
                  <Tag color="orange">
                    <b>SKU:</b> {row?.sku}
                  </Tag>
                </span>
                <br />
                <span className="fs-6 ">
                  <Tag color="purple" className="mt-1">
                    <b>Brand:</b> {row?.brand}
                  </Tag>
                </span>
              </div>
            </div>
          </div>
        );
      },
    },
  ];

  const WalmartSalesAnalyticsColumns = [
    {
      title: "Orders",
      dataIndex: "total_orders",
      key: "total_orders",
      isFilterKey: "total_orders",
      sorter: (a, b) => a?.total_orders - b?.total_orders,
      render: (item) => {
        return (
          <div>
            <span>{NumberWithCommas(item)}</span>
          </div>
        );
      },
    },
    {
      title: "Units Sold",
      isFilterKey: "unit_sold",
      sorter: (a, b) => a?.total_units - b?.total_units,
      render: (item) => {
        return (
          <div className="d-flex align-items-center">
            <span>{NumberWithCommas(item?.total_units)}</span>
            {/* <Popover
              placement="bottom"
              content={
                <>
                  <div className="d-flex justify-content-between">
                    <label>Orders :&nbsp;</label>
                    <label className="fw-bold">
                      {NumberWithCommas(item?.order_units)}
                    </label>
                  </div>
                  <div className="d-flex justify-content-between">
                    <label>Refunds :&nbsp;</label>
                    <label className="fw-bold">
                      {NumberWithCommas(item?.refund_units)}
                    </label>
                  </div>
                </>
              }
              trigger="hover"
              className="me-4"
            >
              <i
                style={{ fontSize: "17px", color: "#827dcb" }}
                className="bi bi-info-square mx-2 cursor-pointer"
              ></i>
            </Popover> */}
          </div>
        );
      },
    },
    {
      title: "Total Sales",
      dataIndex: "total_sales",
      key: "total_sales",
      isFilterKey: "total_sales",
      sorter: (a, b) => a?.total_sales - b?.total_sales,
      render: (item) => {
        return (
          <div>
            <span>
              {selectedCurrencySign}
              {NumberWithCommas(item) || 0}
            </span>
          </div>
        );
      },
    },
    // {
    //   title: "Total Sales",
    //   dataIndex: "total_sales",
    //   key: "total_sales",
    //   width: 90,
    //   render: (item) => {
    //     return (
    //       <div>
    //         <span>
    //           {selectedCurrencySign} {NumberWithCommas(item)}
    //         </span>
    //       </div>
    //     );
    //   },
    // },

    {
      title: "COGS   ",
      isFilterKey: "total_cogs",
      sorter: (a, b) => a?.cogsData?.total_cogs - b?.cogsData?.total_cogs,
      render: (item) => {
        return (
          <div>
            <span>
              {selectedCurrencySign}{" "}
              {NumberWithCommas(item?.cogsData?.total_cogs)}
            </span>
          </div>
        );
      },
    },
    {
      title: "Gross Revenue",
      isFilterKey: "gross_revenue",
      sorter: (a, b) => a?.gross_revenue - b?.gross_revenue,
      render: (item) => {
        return (
          <div className="d-flex align-items-center">
            <span>
              {selectedCurrencySign}&nbsp;
              {NumberWithCommas(item?.gross_revenue)}
            </span>
            <Popover
              placement="bottom"
              content={
                <>
                  <div className="d-flex justify-content-between">
                    <label>Total Sales :&nbsp;</label>
                    <label className="fw-bold">
                      {selectedCurrencySign}&nbsp;
                      {NumberWithCommas(item?.total_sales)}
                    </label>
                  </div>
                  <div className="d-flex justify-content-between">
                    <label>Shipping :&nbsp;</label>
                    <label className="fw-bold">
                      {selectedCurrencySign}&nbsp;
                      {NumberWithCommas(item?.shipping) || 0}
                    </label>
                  </div>
                </>
              }
              trigger="hover"
              className="me-4"
            >
              <i
                style={{ fontSize: "17px", color: "#827dcb" }}
                className="bi bi-info-square mx-2 cursor-pointer"
              ></i>
            </Popover>
          </div>
        );
      },
    },
    {
      title: "Refund   ",
      dataIndex: "refund_revenue",
      key: "refund_revenue",
      isFilterKey: "refund_revenue",
      render: (item, _) => {
        return (
          <div className="d-flex align-items-center">
            <span>
              {selectedCurrencySign}&nbsp;
              {NumberWithCommas(item)}
            </span>
            <Popover
              placement="bottom"
              content={
                <>
                  <div className="d-flex justify-content-between">
                    <label>Total Units :&nbsp;</label>
                    <label className="fw-bold">
                      {selectedCurrencySign}&nbsp;
                      {NumberWithCommas(_?.refunds?.refund_units)}
                    </label>
                  </div>
                  <div className="d-flex justify-content-between">
                    <label>Unit Price :&nbsp;</label>
                    <label className="fw-bold">
                      {selectedCurrencySign}&nbsp;
                      {NumberWithCommas(_?.refunds?.refund_unit_price) || 0}
                    </label>
                  </div>
                  <div className="d-flex justify-content-between">
                    <label>Shipping :&nbsp;</label>
                    <label className="fw-bold">
                      {selectedCurrencySign}&nbsp;
                      {NumberWithCommas(_?.refunds?.refund_shipping_charge) ||
                        0}
                    </label>
                  </div>
                  <div className="d-flex justify-content-between">
                    <label>Total Tax :&nbsp;</label>
                    <label className="fw-bold">
                      {selectedCurrencySign}&nbsp;
                      {NumberWithCommas(_?.refunds?.total_refund_tax) || 0}
                    </label>
                  </div>
                </>
              }
              trigger="hover"
              className="me-4"
            >
              <i
                style={{ fontSize: "17px", color: "#827dcb" }}
                className="bi bi-info-square mx-2 cursor-pointer"
              ></i>
            </Popover>
          </div>
        );
      },
    },
    {
      title: "Taxes",
      isFilterKey: "taxes",
      render: (item) => {
        return (
          <div className="d-flex align-items-center">
            <span>
              {selectedCurrencySign}
              {NumberWithCommas(
                parseFloat(item?.shipping_tax) + parseFloat(item?.product_tax)
              )}
            </span>
            <Popover
              placement="bottom"
              content={
                <>
                  <div className="d-flex justify-content-between">
                    <label>Shipping Tax :&nbsp;</label>
                    <label className="fw-bold">
                      {selectedCurrencySign}
                      {item?.shipping_tax}
                    </label>
                  </div>
                  <div className="d-flex justify-content-between">
                    <label>Product Tax :&nbsp;</label>
                    <label className="fw-bold">
                      {selectedCurrencySign}
                      {NumberWithCommas(item?.product_tax)}
                    </label>
                  </div>
                </>
              }
              trigger="hover"
              className="me-4"
            >
              <i
                style={{ fontSize: "17px", color: "#827dcb" }}
                className="bi bi-info-square mx-2 cursor-pointer"
              ></i>
            </Popover>
          </div>
        );
      },
    },
    // {
    //   title: "Refund Gross Revenue",
    //   width: 80,
    //   render: (item) => {
    //     return (
    //       <div>
    //         <span>
    //           {selectedCurrencySign}{" "}
    //           {NumberWithCommas(item?.refund_gross_revenue)}
    //         </span>
    //       </div>
    //     );
    //   },
    // },
    // {
    //   title: "Total Fees",

    //   render: (item) => {
    //     return (
    //       <div className="d-flex align-items-center">
    //         <span>
    //           {selectedCurrencySign}&nbsp;
    //           {NumberWithCommas(
    //             item?.total_amazon_fees -
    //               item?.refunds?.total_refund_amazon_fees
    //           ) != 0
    //             ? "-"
    //             : ""}
    //           {NumberWithCommas(
    //             item?.total_amazon_fees -
    //               item?.refunds?.total_refund_amazon_fees
    //           )}
    //         </span>
    //         <Popover
    //           placement="bottom"
    //           content={
    //             <>
    //               <div className="d-flex justify-content-between">
    //                 <label>Orders :&nbsp;</label>
    //                 <label className="fw-bold">
    //                   {selectedCurrencySign}&nbsp;
    //                   {NumberWithCommas("-" + item?.total_amazon_fees)}
    //                 </label>
    //               </div>
    //               <div className="d-flex justify-content-between">
    //                 <label>Refunds :&nbsp;</label>
    //                 <label className="fw-bold">
    //                   {selectedCurrencySign}&nbsp;
    //                   {NumberWithCommas(
    //                     item?.refunds?.total_refund_amazon_fees
    //                   )}
    //                 </label>
    //               </div>
    //             </>
    //           }
    //           trigger="hover"
    //           className="me-4"
    //         >
    //           <i
    //             style={{ fontSize: "17px", color: "#827dcb" }}
    //             className="bi bi-info-square mx-2 cursor-pointer"
    //           ></i>
    //         </Popover>
    //       </div>
    //     );
    //   },
    // },
    // {
    //   title: "Refund Fees",

    //   render: (item) => {
    //     return (
    //       <div>
    //         <span>
    //           {selectedCurrencySign}{" "}
    //           {NumberWithCommas(item?.refunds?.total_refund_amazon_fees)}
    //         </span>
    //       </div>
    //     );
    //   },
    // },

    // {
    //   title: "Refund Tax",

    //   render: (item) => {
    //     return (
    //       <div>
    //         <span>
    //           {selectedCurrencySign}{" "}
    //           {NumberWithCommas(item?.refunds?.refund_tax)}
    //         </span>
    //       </div>
    //     );
    //   },
    // },

    {
      title: "Net Profit",
      dataIndex: "net_profit",
      key: "net_profit",
      isFilterKey: "net_profit",
      render: (item) => {
        return (
          <div>
            <span>
              {selectedCurrencySign} {NumberWithCommas(item)}
            </span>
          </div>
        );
      },
    },
    {
      title: "Profit Margin",
      dataIndex: "profit_margin",
      key: "profit_margin",
      isFilterKey: "profit_margin",
      sorter: (a, b) => a?.profit_margin - b?.profit_margin,
      render: (item) => {
        return (
          <div>
            <span>{item}%</span>
          </div>
        );
      },
    },
    {
      title: "ROI   ",
      dataIndex: "roi",
      key: "roi",
      isFilterKey: "roi",
      sorter: (a, b) => a?.roi - b?.roi,
      render: (item) => {
        return (
          <div>
            <span>{item || 0}%</span>
          </div>
        );
      },
    },
  ];

  const [selectedFilterCheckbox, setSelectedFilterCheckbox] = useState([
    "total_orders",
    "unit_sold",
    "total_cogs",
    "gross_revenue",
    "refund_revenue",
    "total_sales",
    "taxes",
    "net_profit",
    "profit_margin",
    "roi",
  ]);

  const checkoxOptions = WalmartSalesAnalyticsColumns?.map((d) => ({
    label: d?.title,
    value: d?.isFilterKey,
  }));

  return (
    <div className="d-flex flex-column flex-column-fluid fadeInRight">
      <div id="kt_app_content" className="app-content flex-column-fluid">
        <div
          id="kt_app_content_container"
          className="app-container container-fluid"
        >
          <div className="row g-5 g-xl-5 mb-5">
            <div className="col-md-12 my-3 d-flex justify-content-between">
              <div className="d-md-flex flex-wrap gap-3">
                <div className="position-relative mb-4 mb-md-0">
                  <Input
                    type="text"
                    className="text-dark w-250px fs-7 fw-bold ant_common_input"
                    name="all_search"
                    placeholder="Search by ASIN or SKU..."
                    value={searchTerm}
                    size="large"
                    onChange={(e) => {
                      setSearchTerm(e.target.value);
                    }}
                    onPressEnter={(e) => {
                      setPage(1);
                      const apiObj = {
                        page: 1,
                        perPage: DefaultPerPage,
                        searchTerm: searchTerm,
                        sales_channel: selectedSalesChannel,
                        filter_date:
                          moment(filter?.date_period?.[0].$d).format(
                            "MMM DD, YYYY"
                          ) +
                          " - " +
                          moment(filter?.date_period?.[1].$d).format(
                            "MMM DD, YYYY"
                          ),
                        filterKey: selectedDateRangeType,
                        isExport: 0,
                        viewBy: selectedViewBy,
                      };
                      setCardLoading(true);
                      setSalesOverviewLoading(true);
                      setOrderOverviewLoading(true);
                      setSalesTableLoading(true);
                      GetWalmartSalesAnalyticsTableData(apiObj);
                      // GetWalmartSalesOverviewChart(apiObj);
                      GetDashboardUnitSoldChart(apiObj, "walmart");
                      GetWalmartOrderOverviewChart(apiObj);
                      GetWalmartSalesAnalyticsCardData(apiObj);
                    }}
                    prefix={
                      <SearchOutlined
                        style={{ fontSize: "19px", color: "grey" }}
                      />
                    }
                  />
                </div>
                <div className="min-w-175px mb-4 mb-md-0">
                  <div>
                    <RangePickerIVCSR
                      className="ant_common_input"
                      id="ivcsr_date_picker"
                      size="large"
                      allowClear={false}
                      style={{ width: "290px" }}
                      format={DateFormat}
                      value={filter?.date_period}
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentNode
                      }
                      // disabled={chartLoading}
                      onChange={(_, e) => {
                        const filterKey = handleDateRangeChange(e, _);
                        setSelectedDateRangeType(filterKey);
                        // console.log(filterKey, "filterKey");
                        onRangeChange(_, e, filterKey);
                        // onRangeChange(_, e);
                      }}
                    />
                  </div>
                </div>

                <div
                  className="d-flex align-items-center  ps-3"
                  style={{
                    border: "1px solid #d3cdff",
                    borderRadius: "4px",
                    background: "#f5f3ff",
                    height: "44px",
                  }}
                >
                  <div className="d-flex align-items-center">
                    <label className="fw-bolder fs-5 me-5">View By : </label>{" "}
                    <Radio.Group
                      onChange={(e) => {
                        setSelectedViewBy(e.target.value);
                        const obj = {
                          filter_date:
                            moment(filter?.date_period?.[0].$d).format(
                              "MMM DD, YYYY"
                            ) +
                            " - " +
                            moment(filter?.date_period?.[1].$d).format(
                              "MMM DD, YYYY"
                            ),
                          filterKey: selectedDateRangeType,
                          sales_channel: "Amazon.com",
                          viewBy: e.target.value,
                        };
                        setSalesOverviewLoading(true);
                        setOrderOverviewLoading(true);
                        GetDashboardUnitSoldChart(obj, "walmart");
                        GetWalmartOrderOverviewChart(obj);
                      }}
                      value={selectedViewBy}
                    >
                      <Radio value={"day"}>Day</Radio>
                      <Radio value={"week"}>Week</Radio>
                      <Radio value={"month"}>Month</Radio>
                    </Radio.Group>
                  </div>
                </div>
              </div>
              <div className="">
                <Select
                  className="w-250px"
                  size="large"
                  placeholder="Select Sales Channel"
                  loading={salesChannelLoading}
                  value={selectedSalesChannelId || null}
                  onChange={(e, _) => {
                    setSelectedSalesChannelId(e);
                    setSelectedSalesChannel(_?.sales_channel);
                    setPage(1);
                    setPageSize(DefaultPerPage);
                    setCardLoading(true);
                    setSalesOverviewLoading(true);
                    setOrderOverviewLoading(true);
                    setSalesTableLoading(true);
                    const apiObj = {
                      page: 1,
                      perPage: DefaultPerPage,
                      searchTerm: searchTerm,
                      sales_channel: _?.sales_channel,
                      filter_date:
                        moment(filter?.date_period?.[0].$d).format(
                          "MMM DD, YYYY"
                        ) +
                        " - " +
                        moment(filter?.date_period?.[1].$d).format(
                          "MMM DD, YYYY"
                        ),
                      filterKey: selectedDateRangeType,
                      isExport: 0,
                      viewBy: selectedViewBy,
                    };
                    GetWalmartSalesAnalyticsTableData(apiObj);
                    // GetWalmartSalesOverviewChart(apiObj);
                    GetDashboardUnitSoldChart(apiObj, "walmart");
                    GetWalmartOrderOverviewChart(apiObj);
                    GetWalmartSalesAnalyticsCardData(apiObj);
                  }}
                  // options={SalesChannelFlag(salesChannelList)}
                  options={
                    salesChannelList?.length > 0
                      ? SalesChannelFlag(salesChannelList)
                      : SalesChannelFlag(WalmartMarketplaceList)
                  }
                />
              </div>
            </div>
          </div>

          {/* ------------------------------------------------------  Filter End  -----------------------------------------*/}
          {/* ------------------------------------------------------  Header Start  -----------------------------------------*/}

          <HeaderCard
            salesAnalyticsCardData={salesAnalyticsCardData}
            cardLoading={cardLoading}
          />

          {/* ------------------------------------------------------  Header End  -----------------------------------------*/}
          {/* ------------------------------------------------------  Graph Start  -----------------------------------------*/}
          <div className="row gy-5 g-xl-5 mb-5">
            <div className="col-xxl-7">
              <SalesOverview
                salesChartDate={salesChartDate}
                salesChartSeries={salesChartSeries}
                salesOverviewLoading={salesOverviewLoading}
                exportGraphArray={exportGraphArray}
                {...props}
              />
            </div>
            <div className="col-xxl-5">
              <OrderOverview
                orderChartDate={orderChartDate}
                orderChartSeries={orderChartSeries}
                orderOverviewLoading={orderOverviewLoading}
                exportOrderGraphArray={exportOrderGraphArray}
                {...props}
              />
            </div>
          </div>

          {/* ------------------------------------------------------  Graph End  -----------------------------------------*/}
          {/* ------------------------------------------------------  Table Start  -----------------------------------------*/}

          <div className="row gy-5 g-xl-5">
            <div className="col-xxl-12">
              <div className="card card-xxl-stretch mb-5 mb-xl-8">
                <div className="card-header border-bottom-dashed">
                  <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bolder text-dark">
                      Sales Overview
                    </span>
                    <span className="text-gray-500 pt-1 fw-normal fs-6">
                      Sales analytics by SKU
                    </span>
                  </h3>
                  <div className="card-toolbar ">
                    <div className="d-flex">
                      {" "}
                      {salesTableLoading ? (
                        <Skeleton.Button block active className="me-2" />
                      ) : (
                        <Dropdown
                          disabled={list === 0}
                          trigger={["click"]}
                          dropdownRender={() => (
                            <div style={contentStyle}>
                              <div>
                                <div className="separator border-gray-200" />
                                <div
                                  style={{
                                    minHeight: "206px",
                                    minWidth: "150px",
                                    border: "1px solid #4419ff8a",
                                    borderRadius: "7px",
                                    overflow: "auto",
                                  }}
                                >
                                  <CheckboxWrapper>
                                    <Checkbox.Group
                                      value={selectedFilterCheckbox}
                                      options={checkoxOptions}
                                      onChange={(value) => {
                                        setSelectedFilterCheckbox(value);
                                      }}
                                      style={{
                                        display: "grid",
                                        justifyContent: "center",
                                      }}
                                    />
                                  </CheckboxWrapper>
                                </div>
                              </div>
                            </div>
                          )}
                        >
                          <button className="btn btn-primary">
                            Select Filter
                          </button>
                        </Dropdown>
                      )}
                      {salesTableLoading ? (
                        <Skeleton.Button block active />
                      ) : (
                        <button
                          className="btn btn-light-info fw-bolder fs-6 ms-2"
                          onClick={() => {
                            setIsExportFlag(1);
                            message.destroy();
                            message.loading("Loading...", 0);
                            const apiObj = {
                              page: 1,
                              perPage: pageSize,
                              searchTerm: searchTerm,
                              sales_channel: selectedSalesChannel,
                              filter_date:
                                moment(filter?.date_period?.[0].$d).format(
                                  "MMM DD, YYYY"
                                ) +
                                " - " +
                                moment(filter?.date_period?.[1].$d).format(
                                  "MMM DD, YYYY"
                                ),
                              isExport: 1,
                            };
                            GetWalmartSalesAnalyticsTableData(apiObj);
                          }}
                        >
                          Export
                        </button>
                      )}
                    </div>
                  </div>
                </div>

                <div className="card-body py-2">
                  <div className="table-responsive tbl-sales">
                    {salesTableLoading ? (
                      <Loading style={{ height: "300px" }} />
                    ) : list?.length != 0 ? (
                      <>
                        <SalesAnalyticsTable
                          data={list}
                          page={page}
                          pageSize={pageSize}
                          selectedCurrencySign={selectedCurrencySign}
                          columns={[
                            ...defaultColumns,
                            ...WalmartSalesAnalyticsColumns?.filter((d) =>
                              selectedFilterCheckbox?.includes(d?.isFilterKey)
                            ),
                          ]}
                        />
                      </>
                    ) : (
                      <NoData height="300px" />
                    )}
                  </div>
                  <Pagination
                    loading={salesTableLoading || list?.length === 0}
                    pageSize={pageSize}
                    page={page}
                    totalPage={totalPage}
                    onPerPage={onPerPage}
                    onPageNo={onPageNo}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* ------------------------------------------------------  Table End  -----------------------------------------*/}
        </div>
      </div>
    </div>
  );
}
