export default (state, action) => {
  if (typeof state === "undefined") {
    return [];
  }
  switch (action.type) {
    case "GET_WALMART_BID_MANAGEMENT_LIST_SUCCESS":
    case "GET_WALMART_BID_MANAGEMENT_LIST_ERROR":
      return {
        ...state,
        GetWalmartBidManagementListResponse: action.updatePayload,
      };

    case "GET_WALMART_BID_RULE_DETAILS_SUCCESS":
    case "GET_WALMART_BID_RULE_DETAILS_ERROR":
      return {
        ...state,
        GetWalmartBidRuleDetailsResponse: action.updatePayload,
      };

    case "CREATE_WALMART_RULE_ACTION_SUCCESS":
    case "CREATE_WALMART_RULE_ACTION_ERROR":
      return {
        ...state,
        CreateWalmartRuleActionResponse: action.updatePayload,
      };

    case "UPDATE_WALMART_RULE_STATUS_ACTION_SUCCESS":
    case "UPDATE_WALMART_RULE_STATUS_ACTION_ERROR":
      return {
        ...state,
        UpdateWalmartRuleStatusResponse: action.updatePayload,
      };

    case "DELETE_WALMART_BID_MANAGEMENT_RULE_SUCCESS":
    case "DELETE_WALMART_BID_MANAGEMENT_RULE_ERROR":
      return {
        ...state,
        DeleteWalmartBidRuleResponse: action.updatePayload,
      };

    case "GET_WALMART_CREATE_RULE_ATTRIBUTE_SUCCESS":
    case "GET_WALMART_CREATE_RULE_ATTRIBUTE_ERROR":
      return {
        ...state,
        GetWalmartCreateRuleAttributeResponse: action.updatePayload,
      };

    case "GET_WALMART_BID_RULE_CAMPAIGN_LIST_SUCCESS":
    case "GET_WALMART_BID_RULE_CAMPAIGN_LIST_ERROR":
      return {
        ...state,
        GetWalmartBidRuleCampaignListResponse: action.updatePayload,
      };

    case "GET_WALMART_BID_RULE_AD_GROUP_LIST_SUCCESS":
    case "GET_WALMART_BID_RULE_AD_GROUP_LIST_ERROR":
      return {
        ...state,
        GetWalmartBidRuleAgGroupListResponse: action.updatePayload,
      };
    case "GET_WALMART_APPLIED_RULE_LOG_LIST_SUCCESS":
    case "GET_WALMART_APPLIED_RULE_LOG_LIST_ERROR":
      return {
        ...state,
        GetWalmartAppliedRuleLogListResponse: action.updatePayload,
      };

    case "FAKE_ACTION_WALMART_BID_MANAGEMENT":
      return { ...state, [action?.state]: {} };
    default:
      return state;
  }
};
