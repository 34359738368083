import React from "react";
import { Table } from "antd";

const IvcsrTable = (props) => {
  const {
    columns,
    fixed,
    dataSource,
    rowKey,
    loading,
    pagination,
    scrollX,
    scrollY,
    isCheckBoxRow,
    rowSelection,

    ...rest
  } = props;
  const ellipsis = false;
  return (
    <Table
      columns={columns?.map((d) => {
        return {
          ...d,
          ellipsis:
            d?.ellipsis !== undefined
              ? d?.ellipsis === false
                ? false
                : true
              : ellipsis,
        };
      })}
      fixed={fixed}
      dataSource={dataSource?.map((d, i) => {
        return { ...d, key: i };
      })}
      rowKey={rowKey}
      loading={loading}
      pagination={pagination}
      ellipsis={false}
      scrollableTarget="tableContainer"
      rowSelection={
        isCheckBoxRow
          ? {
              type: "checkbox",
              ...rowSelection,
            }
          : ""
      }
      {...rest}
    />
  );
};

export default IvcsrTable;
