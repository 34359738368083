import { Alert, Checkbox, Col, Input, Row, Select, Tabs, message } from "antd";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import Loading from "../../../../../component/loading";
import IvcsrTable from "../../../../../component/ivcsr-table";
import NoData from "../../../../../component/no-data";
import dayjs from "dayjs";
import Pagination from "../../../../../component/pagination";
import { DefaultPerPage } from "../../../../../config";

export default function (props) {
  const {
    show,
    onHide,
    selectedActionLog,
    GetWalmartAppliedRuleLogList,
    fakeActionWalmartBidManagement,
  } = props;

  const [logList, setLogList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(500);
  const [pageSize, setPageSize] = useState(DefaultPerPage);
  const [selectedTab, setSelectedTab] = useState("criteria_passed");

  const GetWalmartAppliedRuleLogListRes = useSelector(
    (state) =>
      state.WalmartBidManagement.GetWalmartAppliedRuleLogListResponse || {}
  );

  useEffect(() => {
    const Obj = {
      page: page,
      perPage: pageSize,
      rule_id: selectedActionLog?.id,
    };
    GetWalmartAppliedRuleLogList(Obj);
  }, []);

  useEffect(() => {
    if (GetWalmartAppliedRuleLogListRes?.status === true) {
      setLoading(false);
      setTotalPage(
        GetWalmartAppliedRuleLogListRes?.data?.pagination?.totalCount
      );
      setLogList(GetWalmartAppliedRuleLogListRes?.data?.records || []);
      fakeActionWalmartBidManagement("GetWalmartAppliedRuleLogListResponse");
    } else if (GetWalmartAppliedRuleLogListRes?.status === false) {
      fakeActionWalmartBidManagement("GetWalmartAppliedRuleLogListResponse");
    }
  }, [GetWalmartAppliedRuleLogListRes]);

  const columns = [
    {
      title: "#",
      render: (text) => {
        if (
          text?.new_bid > text?.old_bid ||
          text?.new_budget > text?.old_budget
        ) {
          return (
            <label className="fw-bolder">
              <i
                class="ki-duotone ki-double-up fs-1"
                style={{ color: "green" }}
              >
                <span class="path1"></span>
                <span class="path2"></span>
                <span class="path3"></span>
              </i>
            </label>
          );
        } else {
          return (
            <label className="fw-bolder">
              <i
                class="ki-duotone ki-double-down fs-1"
                style={{ color: "red" }}
              >
                <span class="path1"></span>
                <span class="path2"></span>
                <span class="path3"></span>
              </i>
            </label>
          );
        }
      },
    },
    // {
    //   title: "Date",
    //   dataIndex: "date",
    //   key: "date",
    //   render: (text) => {
    //     return <span>{dayjs(text).format("MMM DD, YYYY")}</span>;
    //   },
    // },
    // {
    //   title: "Target Name",
    //   dataIndex: "targeting_text",
    //   key: "targeting_text",
    //   render: (text) => {
    //     return (
    //       <label className="fw-bolder" style={{ textTransform: "capitalize" }}>
    //         {text}
    //       </label>
    //     );
    //   },
    // },
    {
      title: "Passed Criteria",
      dataIndex: "passed_criteria",
      key: "passed_criteria",
      width: 150,
    },
    // {
    //   title: "Change",
    //   dataIndex: "change_type",
    //   key: "change_type",
    // },
    {
      title: "Campaign",
      dataIndex: "campaign_name",
      key: "campaign_name",
      width: 200,
    },
    // {
    //   title: "AdGroup",
    //   dataIndex: "ad_group_name",
    //   key: "ad_group_name",
    // },
    // {
    //   title: "Rule",
    //   dataIndex: "rule_name",
    //   key: "rule_name",
    //   width: 150,
    // },
    {
      title: "Update value",
      dataIndex: "update_value",
      key: "update_value",
      width: 150,
    },
    // {
    //   title: "Rule Applied Time",
    //   width: 200,
    //   render: (text) => {
    //     if (!text?.date) {
    //       return "-";
    //     }
    //     return <span>{dayjs(text?.date).format("MMM DD, YYYY")}</span>;
    //   },
    // },
    {
      title: "Old Value",
      width: 150,
      render: (text) => {
        return <span>{text?.old_bid || text?.old_budget}</span>;
      },
    },
    {
      title: "New Value",
      width: 150,
      render: (text) => {
        return <span>{text?.new_bid || text?.new_budget}</span>;
      },
    },
    {
      title: "Record Type",
      width: 150,
      render: (text) => {
        return (
          <span style={{ textTransform: "capitalize" }}>
            {text?.rule_data?.rule_type}
          </span>
        );
      },
    },
    {
      title: "Updated at",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (text) => {
        return <span>{dayjs(text * 1000).format("MMM DD, YYYY")}</span>;
      },
    },
    // {
    //   title: "Remarks",
    //   dataIndex: "remark",
    //   key: "remark",
    // },
  ];

  const onPageNo = (e) => {
    setLoading(true);
    const Obj = {
      page: e,
      perPage: pageSize,
      rule_id: selectedActionLog?.id,
    };
    GetWalmartAppliedRuleLogList(Obj);
    setPage(e);
  };

  const onPerPage = (e) => {
    setPage(1);
    setPageSize(e);
    setLoading(true);
    const Obj = {
      page: 1,
      perPage: e,
      rule_id: selectedActionLog?.id,
    };
    GetWalmartAppliedRuleLogList(Obj);
  };
  const items = [
    {
      key: "criteria_passed",
      label: "Criteria Passed",
    },
    {
      key: "criteria_not_passed",
      label: "Criteria Not Passed",
    },
  ];

  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          onHide();
        }}
        size="xl"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Action Logs - {selectedActionLog?.rule_name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <div className="row mb-5">
              <div className="col-12">
                {items?.map((d) => {
                  return (
                    <label
                      onClick={() => {
                        setSelectedTab(d?.key);
                      }}
                      className={`me-10 fs-3 cursor-pointer ${
                        d?.key === selectedTab ? "text-primary fw-bold " : ""
                      }`}
                      style={{
                        borderBottom:
                          d?.key === selectedTab
                            ? "1px solid #4318ff"
                            : "1px solid lightgrey",
                      }}
                    >
                      {d?.label}
                    </label>
                  );
                })}
              </div>
            </div>
            <div className="row">
              {loading ? (
                <Loading style={{ height: "500px" }} />
              ) : logList?.length !== 0 ? (
                <IvcsrTable
                  columns={columns}
                  fixed="top"
                  dataSource={logList}
                  rowKey="key"
                  pagination={false}
                  scroll={{ x: "max-content", y: 500 }}
                />
              ) : (
                <NoData type="new" />
              )}
            </div>
            <Pagination
              loading={loading || logList?.length === 0}
              pageSize={pageSize}
              page={page}
              totalPage={totalPage}
              onPerPage={onPerPage}
              onPageNo={onPageNo}
            />
          </>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <button
              className="btn btn-light-danger me-2"
              onClick={() => {
                onHide();
              }}
            >
              Cancel
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
