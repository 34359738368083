import React, { useEffect, useState } from "react";
import { DatePicker } from "antd";
import moment from "moment";
import dayjs from "dayjs";
import { Wrapper } from "./style";

const { RangePicker } = DatePicker;
const rangePresets = [
  {
    label: "Today",
    value: [dayjs(), dayjs()],
    filterKey: "today",
  },
  {
    label: "Yesterday",
    value: [dayjs().add(-1, "d"), dayjs().add(-1, "d")],
    filterKey: "yesterday",
  },
  {
    label: "LW",
    value: [
      dayjs().subtract(1, "week").startOf("week"),
      dayjs().subtract(1, "week").endOf("week"),
    ],
    filterKey: "last_week",
  },
  {
    label: "L4W",
    value: [dayjs().add(-28, "d"), dayjs()],
    filterKey: "last4_week",
  },
  {
    label: "L13W",
    value: [dayjs().add(-91, "d"), dayjs()],
    filterKey: "last13_week",
  },
  {
    label: "YTD",
    value: [dayjs().startOf("year"), dayjs()],
    filterKey: "year_to_date",
  },
  {
    label: "L52W",
    value: [dayjs().add(-365, "d"), dayjs()],
    filterKey: "last52_week",
  },
  {
    label: "YOY L4W",
    value: [dayjs().add(-393, "d"), dayjs().add(-365, "d")],
    filterKey: "yoy_last4_week",
  },
  {
    label: "Custom Range",
    value: [0, 0],
    filterKey: "custom",
  },
];

// [
//   {
//     label: "Today",
//     value: [dayjs(), dayjs()],
//     filterKey: "totday",
//   },
//   {
//     label: "Yesterday",
//     value: [dayjs().add(-1, "d"), dayjs().add(-1, "d")],
//     filterKey: "yeaterday",
//   },
//   {
//     label: "Last 7 Days",
//     value: [dayjs().add(-7, "d"), dayjs()],
//     filterKey: "last7days",
//   },
//   {
//     label: "Last 14 Days",
//     value: [dayjs().add(-14, "d"), dayjs()],
//     filterKey: "last14days",
//   },
//   {
//     label: "Last 30 Days",
//     value: [dayjs().add(-30, "d"), dayjs()],
//     filterKey: "last30days",
//   },
//   {
//     label: "Last 90 Days",
//     value: [dayjs().add(-90, "d"), dayjs()],
//     filterKey: "last90days",
//   },
//   {
//     label: "Custom Range",
//     value: [0, 0],
//     filterKey: "customrange",
//   },
// ];

const RangePickerIVCSR = (props) => {
  const { presets, id, value, ...rest } = props;
  const [isOpen, setIsOpen] = useState(false);

  const getformat = (value) => {
    return moment(value).format("YYYY-MM-DD");
  };

  useEffect(() => {
    const element = document
      .getElementsByClassName(id)?.[0]
      ?.getElementsByClassName("ant-picker-presets");

    if (element && isOpen) {
      const isActive = (presets || rangePresets || [])
        ?.map((d) => {
          const presetsDates = d?.value;
          return {
            ...d,
            selected:
              getformat(presetsDates?.[0]?.$d) === getformat(value?.[0]?.$d) &&
              getformat(presetsDates?.[1]?.$d) === getformat(value?.[1]?.$d),
          };
        })
        .findIndex((d) => d?.selected === true);
      const nodes = element?.[0]?.childNodes?.[0]?.childNodes;
      let rangeList =
        isActive === -1 ? nodes?.[nodes?.length - 1] : nodes?.[isActive];
      const pastSelected = document
        .getElementsByClassName(id)?.[0]
        ?.querySelectorAll(".antd_selected_presets_date");

      console.log(pastSelected, "pastSelected");
      if (pastSelected?.length !== 0) {
        pastSelected?.[0]?.classList.remove("antd_selected_presets_date");
      }
      rangeList?.classList.add("antd_selected_presets_date");
    }
  }, [isOpen]);

  return (
    <Wrapper>
      <RangePicker
        presets={presets || rangePresets || []}
        value={value}
        id={id}
        popupClassName={id}
        onOpenChange={() => setIsOpen(!isOpen)}
        size="large"
        {...rest}
      />
    </Wrapper>
  );
};

export default RangePickerIVCSR;
