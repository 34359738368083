import React from "react";
import PreviewImage from "../../../../component/preview-image";
import { Link } from "react-router-dom";
import { Checkbox, Popover, Progress, Tag } from "antd";
import moment from "moment";
import { ProductTableWrapper } from "./style";
import { NumberWithCommas } from "../../../../config";
import CustomProgress from "../../../../component/custom-progress/custom-progress";

export default function (props) {
  const {
    list,
    allProductStatus,
    lastAnalysed,
    page,
    pageSize,
    selectedCheckbox,
    setSelectedCheckbox,
    allProductAnalysisScore,
  } = props;
  return (
    <ProductTableWrapper>
      {list?.map((text, i) => {
        const newStatus = Object.keys(allProductStatus || {})?.filter(
          (d) => d === text?.asin1
        );
        const getScoreAsin = Object.keys(allProductAnalysisScore || {})?.filter(
          (d) => d === text?.asin1
        );
        const otherData = allProductStatus?.[newStatus?.[0]];
        const lastDate = Object.keys(lastAnalysed)?.filter(
          (d) => d === text?.asin1
        );

        const getScore = allProductAnalysisScore?.[getScoreAsin?.[0]];
        console.log(getScore, "getScore");

        // const newScore = [];
        // if (getScore) {
        //   const localScore = Object?.entries(getScore)?.map((d) => {
        //     const main_name = d?.[0];
        //     const finalArr = Object?.entries(d?.[1])?.map((a) => {
        //       const myKey = `${main_name}_${a?.[0]}`;
        //       return { [myKey]: a?.[1] };
        //     });
        //     return finalArr;
        //   });
        //   newScore.push(localScore.flat());
        // }
        return (
          <>
            <div className="row  mx-0 px-0 my-2">
              <div className="col-2 col-md-1">
                <div className="d-flex align-items-center flex-column my-2 gap-2">
                  <div>
                    {" "}
                    <Checkbox
                      checked={
                        selectedCheckbox.filter((d) => d == text?.asin1)
                          ?.length == 1
                          ? true
                          : false
                      }
                      onChange={(e) => {
                        const checkboxId = text?.asin1;
                        setSelectedCheckbox((prev) => {
                          if (e?.target?.checked) {
                            return [...prev, checkboxId];
                          } else {
                            return prev.filter((asin) => asin !== checkboxId);
                          }
                        });
                      }}
                    />
                  </div>
                  <div className="symbol symbol-50px">
                    <PreviewImage
                      imageUrl={JSON.parse(otherData?.images)?.[0]?.link}
                    />
                  </div>
                  <div className="d-flex text-center">
                    <Link
                      id={Math.random()}
                      to={{
                        pathname: "/listing-optimizer/details",
                        search: `?id=${text?.id}`,
                      }}
                      target="_blank"
                    >
                      <label
                        className="text-primary btn-sm fw-bolder fs-6 cursor-pointer"
                        style={{ textDecoration: "underline" }}
                      >
                        View Product
                      </label>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-10 col-md-11 ">
                <div className="row">
                  <div className="col-10 ">
                    <Popover
                      content={
                        <div
                          style={{
                            height: "max-content",
                            width: "600px",
                          }}
                        >
                          {text?.item_name}
                        </div>
                      }
                      placement="bottom"
                    >
                      <div className="d-flex">
                        <Tag color="purple" className="me-3">
                          Sr.No :{(page - 1) * pageSize + 1 + i}
                        </Tag>
                        <div
                          className="text-primary mb-1 fw-bolder text-one fs-5 popoverActionIconSingleLine"
                          style={{ minWidth: "100px" }}
                        >
                          {text?.item_name}
                        </div>
                      </div>
                      <div className="d-flex align-items-center mt-2">
                        <div className="fs-6 ">
                          <Tag color="green">
                            <b>ASIN:</b> {text?.asin1}
                          </Tag>
                        </div>
                      </div>
                    </Popover>
                  </div>
                </div>
                <div
                  className="separator separator-dashed mb-2 mt-3"
                  style={{ borderColor: "1px solid #ececec" }}
                />
                <div className="row ">
                  <div className="col-12 col-md-4 ">
                    <div className="row my-1">
                      <div className="col-6 col-md-5">
                        <label className="fs-6 fw-bold">Brand :</label>
                      </div>
                      <div className="col-6 col-md-7">
                        <Tag color="orange" className=" fs-6">
                          {otherData?.brand}
                        </Tag>
                      </div>
                    </div>
                    <div className="row my-1">
                      <div className="col-6 col-md-5">
                        <label className="fs-6 fw-bold">Category :</label>
                      </div>
                      <div className="col-6 col-md-7">
                        <label className="fw-bold fs-6">
                          {otherData?.category_name}
                        </label>
                      </div>
                    </div>
                  </div>{" "}
                  <div className="col-12 col-md-4 ">
                    <div className="row my-1">
                      <div className="col-6 col-md-7 col-lg-5">
                        <label className="fs-6 fw-bold">Product Status :</label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-7">
                        {" "}
                        <Tag
                          color={text?.status === "Active" ? "green" : "red"}
                          className="fw-bold fs-6"
                        >
                          {text?.status}
                        </Tag>
                      </div>
                    </div>{" "}
                    <div className="row my-1">
                      <div className="col-6 col-md-5 col-lg-5">
                        <label className="fs-6 fw-bold">Last Analysed :</label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-7">
                        <label className="fw-bold fs-6">
                          {" "}
                          {lastAnalysed[lastDate?.[0]]
                            ? moment(
                                new Date(lastAnalysed[lastDate?.[0]] * 1000)
                              ).format("MMM DD, YYYY")
                            : "-"}
                        </label>
                      </div>
                    </div>
                  </div>{" "}
                  <div className="col-12 col-md-4 ">
                    <div className="row my-1">
                      <div className="col-6 col-md-7 col-lg-5">
                        <label className="fs-6 fw-bold">Marketplace :</label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-7">
                        <div className="d-flex align-items-center fw-bold fs-6">
                          <img
                            src={`/assets//media/domainImage/${text?.marketplace_id}.png`}
                            className="me-2"
                            style={{ height: "20px" }}
                          />

                          {text?.marketplace}
                        </div>
                      </div>
                    </div>
                    <div className="row my-1">
                      <div className="col-6 col-md-7 col-lg-5">
                        <label className="fs-6 fw-bold">Marketplace Id :</label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-7">
                        <label className="fw-bold fs-6">
                          {text?.marketplace_id}
                        </label>
                      </div>
                    </div>
                  </div>{" "}
                </div>
                {getScore && (
                  <div className="row">
                    <div className="col-12 col-md-4 ">
                      <div className="row my-1">
                        <div className="col-9 col-md-5">
                          <label className="fs-6 fw-bold">
                            Analytics Status :
                          </label>
                        </div>
                        <div className="col-3 col-md-7">
                          <CustomProgress data={getScore} />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div
              className="separator separator-dashed my-10"
              style={{
                borderColor: "#00000069",
                boxShadow: "0px 0px 10px 1px #dad9ff",
              }}
            />
          </>
        );
      })}
    </ProductTableWrapper>
  );
}
