import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import OrderDetails from "../../../../modules/pages/order-management/order/lib/order-details";
import {
  GetOrderDetails,
  fakeActionWalmartOrders,
} from "../../../../redux/modules/order-management/orders/orders.action";

const mapStateToProps = (state) => ({
  GetWalmartOrdersDetailsResponse:
    state.WalmartOrders.GetWalmartOrdersDetailsResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      GetOrderDetails,
      fakeActionWalmartOrders,
    },
    dispatch
  );

const OrderDetailsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderDetails);

export default OrderDetailsContainer;
