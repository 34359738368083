import React from "react";

const PageLoader = () => {
  return (
    <div
      style={{
        margin: "auto",
        position: "absolute",
        inset: 0,
        width: "fit-content",
        height: "fit-content",
        display: "grid",
        justifyItems: "center",
      }}
    >
      <img
        alt="Logo"
        src="/assets/media/all-images/ivcsr-logo-new.png"
        className="h-100px d-none d-sm-inline app-sidebar-logo-default theme-light-show shinnerLoader"
      />
    </div>
  );
};
export default PageLoader;
