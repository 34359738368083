import { Collapse, Skeleton } from "antd";
import React, { useState } from "react";
import SalesBreakDownWrapper from "./style";
import { NumberWithCommas } from "../../../../config";

const SalesBreakdown = (props) => {
  const { salesData, salesLoading, selectedCurrencySign } = props;
  const [collapseKey, setCollapseKey] = useState("");
  const [subCollapseKey, setSubCollapseKey] = useState("");
  const { Panel } = Collapse;
  const onCollapseChange = (key) => {
    setCollapseKey(key);
  };
  const onSubCollapseChange = (key) => {
    setSubCollapseKey(key);
  };
  return (
    <SalesBreakDownWrapper>
      <div
        className="card card-flush h-md-150"
        // style={{ filter: salesLoading ? "blur(6px)" : "blur(0px)" }}
      >
        {/*begin::Header*/}
        <div className="card-header pt-0">
          {/*begin::Title*/}
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bold text-dark">
              Sales Breakdown
            </span>
            {/* <span class="text-gray-400 pt-2 fw-semibold fs-6">Monthly Unit Sold Overview</span> */}
          </h3>
          {/*end::Title*/}
          {/*begin::Toolbar*/}
          <div className="card-toolbar"></div>
          {/*end::Toolbar*/}
        </div>
        {/*end::Header*/}
        {/*begin::Body*/}
        <div className="card-body pt-0 px-5 pb-0">
          {salesLoading ? (
            <>
              <Skeleton.Button active block className="mb-2" />
            </>
          ) : (
            <div className="border border-gray-300 bg-gray-100 border-dashed rounded p-2 px-5 mb-2">
              <div className="d-flex align-items-center">
                <div
                  className="d-flex align-items-center"
                  style={{ width: "33%" }}
                >
                  <div className="w-7px h-7px " />
                  <h5 className="mb-0  fw-semibold ms-0">Title</h5>
                </div>
                <div style={{ width: "33%", textAlign: "end" }}>
                  <h4 className="mb-0 fw-bolder">Orders</h4>
                </div>
                <div style={{ width: "33%", textAlign: "end" }}>
                  <h4 className="mb-0 fw-bolder">Refunds</h4>
                </div>
              </div>
            </div>
          )}

          <div className="mb-5 mt-3" style={{ border: "1px dashed #bbbbbb" }} />
          <div style={{ overflowY: "scroll", height: "303px" }}>
            {salesLoading ? (
              <>
                <Skeleton.Button active block className="mb-2" />
                <Skeleton.Button active block className="mb-2" />
                <Skeleton.Button active block className="mb-2" />
                <Skeleton.Button active block className="mb-2" />
                <Skeleton.Button active block className="mb-2" />
                <Skeleton.Button active block className="mb-2" />
                <Skeleton.Button active block className="mb-2" />
              </>
            ) : (
              <>
                <div className="border border-gray-300 bg-gray-100 border-dashed rounded p-2 px-5 mb-2">
                  <div className="d-flex align-items-center">
                    <div
                      className="d-flex align-items-center"
                      style={{ width: "33%" }}
                    >
                      <h5 className="mb-0  fw-semibold ms-1">Orders</h5>
                    </div>
                    <div style={{ width: "33%", textAlign: "end" }}>
                      <h4 className="mb-0 fw-bolder">
                        {NumberWithCommas(salesData?.total_orders || "0")}
                      </h4>
                    </div>
                    <div style={{ width: "33%", textAlign: "end" }}>
                      <h4 className="mb-0 fw-bolder">-</h4>
                    </div>
                  </div>
                </div>
                <div className="border border-gray-300 bg-gray-100 border-dashed rounded p-2 px-5 mb-2">
                  <div className="d-flex align-items-center">
                    <div
                      className="d-flex align-items-center"
                      style={{ width: "33%" }}
                    >
                      {/* <div className="w-7px h-7px rounded-circle bg-warning" /> */}
                      <h5 className="mb-0 text fw-semibold ms-1">
                        Total Unit Sold
                      </h5>
                    </div>
                    <div style={{ width: "33%", textAlign: "end" }}>
                      <h4 className="mb-0 fw-bolder">
                        {NumberWithCommas(salesData?.order_units || "0")}
                      </h4>
                    </div>
                    <div style={{ width: "33%", textAlign: "end" }}>
                      <h4 className="mb-0 fw-bolder">
                        {NumberWithCommas(
                          salesData?.refunds?.refund_units || "0"
                        )}
                      </h4>
                    </div>
                  </div>
                </div>
                <Collapse
                  activeKey={[subCollapseKey]}
                  accordion={true}
                  onChange={(key) => {
                    onSubCollapseChange(key);
                  }}
                  className="border border-gray-300 bg-gray-100 border-dashed rounded mb-2 commonCollapseDiv"
                >
                  <Panel
                    header={
                      <div className="d-flex align-items-center">
                        <div
                          className="d-flex align-items-center"
                          style={{ width: "32%" }}
                        >
                          {/* <div className="w-7px h-7px rounded-circle bg-warning" /> */}
                          <h5 className="mb-0 text-dark fw-semibold">
                            Gross Revenue
                          </h5>
                        </div>
                        <div style={{ width: "33%", textAlign: "end" }}>
                          <h4 className="mb-0 fw-bolder">
                            {selectedCurrencySign}&nbsp;
                            {NumberWithCommas(salesData?.gross_revenue || "0")}
                          </h4>
                        </div>
                        <div style={{ width: "33%", textAlign: "end" }}>
                          <h4 className="mb-0 fw-bolder">
                            {selectedCurrencySign}&nbsp;
                            {NumberWithCommas(
                              "-" + salesData?.refund_gross_revenue || "0"
                            )}
                          </h4>
                        </div>
                      </div>
                    }
                    key="1"
                  >
                    {/* <div className="separator separator-dashed my-3" />
                <div className="d-flex justify-content-between">
                  <label
                    className="panel_text fw-bolder"
                    style={{ width: "33%" }}
                  >
                    Gross Revenue
                  </label>
                  <h4 className="mb-0 fw-bolder">
                    {selectedCurrencySign}&nbsp;
                    {NumberWithCommas(salesData?.gross_revenue || "0")}
                  </h4>
                  <h4 className="mb-0 fw-bolder">
                    {selectedCurrencySign}&nbsp;
                    {NumberWithCommas(salesData?.refund_gross_revenue || "0")}
                  </h4>
                </div> */}

                    <div className="separator separator-dashed my-3" />
                    <div className="d-flex justify-content-between mx-5">
                      <label className="panel_text" style={{ width: "33%" }}>
                        Revenue
                      </label>
                      <h4 className="mb-0" style={{ fontWeight: "400" }}>
                        {selectedCurrencySign}&nbsp;
                        {NumberWithCommas(salesData?.total_sales || "0")}
                      </h4>
                      <h4 className="mb-0" style={{ fontWeight: "400" }}>
                        {selectedCurrencySign}&nbsp;
                        {"-" +
                          NumberWithCommas(
                            salesData?.refunds?.refund_revenue || "0"
                          )}
                      </h4>
                    </div>

                    <div className="separator separator-dashed my-3" />
                    <div className="d-flex justify-content-between mx-5">
                      <label className="panel_text" style={{ width: "33%" }}>
                        Shipping
                      </label>
                      <h4
                        className="mb-0"
                        style={{
                          width: "33%",
                          textAlign: "end",
                          fontWeight: "400",
                        }}
                      >
                        {selectedCurrencySign}&nbsp;
                        {NumberWithCommas(salesData?.shipping || "0")}
                      </h4>
                      <h4
                        className="mb-0"
                        style={{
                          width: "33%",
                          textAlign: "end",
                          fontWeight: "400",
                        }}
                      >
                        {selectedCurrencySign}&nbsp;
                        {NumberWithCommas(
                          "-" + salesData?.refunds?.refund_shipping
                        ) || "0"}
                      </h4>
                    </div>
                    <div className="separator separator-dashed my-3" />
                    <div className="d-flex justify-content-between mx-5">
                      <label className="panel_text" style={{ width: "33%" }}>
                        Promo values
                      </label>
                      <h4
                        className="mb-0"
                        style={{
                          width: "33%",
                          textAlign: "end",
                          fontWeight: "400",
                        }}
                      >
                        {selectedCurrencySign}&nbsp;
                        {"-" + NumberWithCommas(salesData?.promo_value || "0")}
                      </h4>
                      <h4
                        className="mb-0"
                        style={{
                          width: "33%",
                          textAlign: "end",
                          fontWeight: "400",
                        }}
                      >
                        {selectedCurrencySign}&nbsp;
                        {NumberWithCommas(
                          salesData?.refunds?.refund_promo_value || "0"
                        )}
                      </h4>
                    </div>
                    <div className="separator separator-dashed my-3" />
                    <div className="d-flex justify-content-between mx-5">
                      <label className="panel_text" style={{ width: "33%" }}>
                        Gift wrap
                      </label>
                      <h4
                        className="mb-0"
                        style={{
                          width: "33%",
                          textAlign: "end",
                          fontWeight: "400",
                        }}
                      >
                        {selectedCurrencySign}&nbsp;
                        {NumberWithCommas(salesData?.gift_wrap || "0")}
                      </h4>
                      <h4
                        className="mb-0"
                        style={{
                          width: "33%",
                          textAlign: "end",
                          fontWeight: "400",
                        }}
                      >
                        {selectedCurrencySign}&nbsp;
                        {NumberWithCommas(
                          "-" + salesData?.refunds?.refund_gift_wrap || "0"
                        )}
                      </h4>
                    </div>
                  </Panel>
                </Collapse>
                {/* <Collapse
            activeKey={[subCollapseKey]}
            accordion={true}
            onChange={(key) => {
              onSubCollapseChange(key);
            }}
            className="mb-3 border border-gray-300 bg-gray-100 border-dashed rounded commonCollapseDiv"
          >
            <Panel
              header={
                <>
                  <h5 className="mb-0 text-dark fw-semibold ms-3">Refunds</h5>
                </>
              }
              key="5"
            >
              <div className="separator separator-dashed my-3" />
              <div className="d-flex">
                <label
                  className="panel_text fw-bolder"
                  style={{ width: "33%" }}
                >
                  Refund sales
                </label>
                <h4
                  className="mb-0 fw-bolder"
                  style={{ width: "33%", textAlign: "end", fontWeight: "400" }}
                >
                  {selectedCurrencySign}&nbsp;
                  {NumberWithCommas(salesData?.refunds?.refund_revenue || "0")}
                </h4>
                <h4
                  className="mb-0 fw-bolder"
                  style={{ width: "33%", textAlign: "end", fontWeight: "400" }}
                >
                  {selectedCurrencySign}&nbsp;
                  {NumberWithCommas(salesData?.refunds?.refund_revenue || "0")}
                </h4>
              </div>
              <div className="separator separator-dashed my-3" />
              <div className="d-flex">
                <label className="panel_text" style={{ width: "33%" }}>
                  Refund sales
                </label>
                <h4
                  className="mb-0 "
                  style={{ width: "33%", textAlign: "end", fontWeight: "400" }}
                >
                  {selectedCurrencySign}&nbsp;
                  {NumberWithCommas(salesData?.refunds?.refund_revenue || "0")}
                </h4>
                <h4
                  className="mb-0 "
                  style={{ width: "33%", textAlign: "end", fontWeight: "400" }}
                >
                  {selectedCurrencySign}&nbsp;
                  {NumberWithCommas(salesData?.refunds?.refund_revenue || "0")}
                </h4>
              </div>
              <div className="separator separator-dashed my-3" />
              <div className="d-flex">
                <label className="panel_text" style={{ width: "33%" }}>
                  Quantity
                </label>
                <h4
                  className="mb-0 "
                  style={{ width: "33%", textAlign: "end", fontWeight: "400" }}
                >
                  {NumberWithCommas(salesData?.refunds?.refund_units || "0")}
                </h4>
                <h4
                  className="mb-0 "
                  style={{ width: "33%", textAlign: "end", fontWeight: "400" }}
                >
                  {NumberWithCommas(salesData?.refunds?.refund_units || "0")}
                </h4>
              </div>
              <div className="separator separator-dashed my-3" />
              <div className="d-flex">
                <label className="panel_text" style={{ width: "33%" }}>
                  Refund selling fees
                </label>
                <h4
                  className="mb-0 "
                  style={{ width: "33%", textAlign: "end", fontWeight: "400" }}
                >
                  {selectedCurrencySign}&nbsp;
                  {NumberWithCommas(
                    salesData?.refunds?.refund_selling_fees || "0"
                  )}
                </h4>
                <h4
                  className="mb-0 "
                  style={{ width: "33%", textAlign: "end", fontWeight: "400" }}
                >
                  {selectedCurrencySign}&nbsp;
                  {NumberWithCommas(
                    salesData?.refunds?.refund_selling_fees || "0"
                  )}
                </h4>
              </div>
            </Panel>
          </Collapse> */}
                <div className="border border-gray-300 bg-gray-100 border-dashed rounded p-2 px-5 mb-2">
                  <div className="d-flex align-items-center">
                    <div
                      className="d-flex align-items-center"
                      style={{ width: "33%" }}
                    >
                      {/* <div className="w-7px h-7px rounded-circle bg-primary" /> */}
                      <h5 className="mb-0  fw-semibold ms-2">Total Cogs</h5>
                    </div>
                    <div style={{ width: "33%", textAlign: "end" }}>
                      <h4 className="mb-0 fw-bolder">
                        {selectedCurrencySign}&nbsp;
                        {NumberWithCommas(
                          salesData?.cogsData?.order_cogs || "0"
                        )}
                      </h4>
                    </div>
                    <div style={{ width: "33%", textAlign: "end" }}>
                      <h4 className="mb-0 fw-bolder">
                        {selectedCurrencySign}&nbsp;
                        {NumberWithCommas(
                          "-" + salesData?.cogsData?.refund_cogs || "0"
                        )}
                      </h4>
                    </div>
                  </div>
                </div>
                <Collapse
                  activeKey={[subCollapseKey]}
                  accordion={true}
                  onChange={(key) => {
                    onSubCollapseChange(key);
                  }}
                  className="border border-gray-300 bg-gray-100 border-dashed rounded mb-2"
                >
                  <Panel
                    header={
                      <div className="d-flex align-items-center">
                        <div
                          className="d-flex align-items-center"
                          style={{ width: "32%" }}
                        >
                          {/* <div className="w-7px h-7px rounded-circle bg-warning" /> */}
                          <h5 className="mb-0 text-dark fw-semibold ms-1 ">
                            Total Fees
                          </h5>
                        </div>
                        <div style={{ width: "33%", textAlign: "end" }}>
                          <h4 className="mb-0 fw-bolder">
                            {selectedCurrencySign}&nbsp;
                            {salesData?.total_fees == 0 ? "" : "-"}
                            {NumberWithCommas(
                              Math.abs(salesData?.total_fees || "0")
                            )}
                          </h4>
                        </div>
                        <div style={{ width: "33%", textAlign: "end" }}>
                          <h4 className="mb-0 fw-bolder">
                            {selectedCurrencySign}&nbsp;
                            {NumberWithCommas(
                              salesData?.refunds?.total_refund_amazon_fees ||
                                "0"
                            )}
                          </h4>
                        </div>
                      </div>
                    }
                    key="2"
                    className="expenseDiv"
                  >
                    {/* <div className="d-flex justify-content-between">
                  <label
                    className="panel_text fw-bolder"
                    style={{ width: "33%" }}
                  >
                    Total Fees
                  </label>
                  <h4
                    className="mb-0 fw-bolder"
                    style={{
                      width: "33%",
                      textAlign: "end",
                      fontWeight: "400",
                    }}
                  >
                    {selectedCurrencySign}&nbsp;
                    {NumberWithCommas(salesData?.total_fees || "0")}
                  </h4>
                  <h4
                    className="mb-0 fw-bolder"
                    style={{
                      width: "33%",
                      textAlign: "end",
                      fontWeight: "400",
                    }}
                  >
                    {selectedCurrencySign}&nbsp;
                    {NumberWithCommas(
                      salesData?.refunds?.total_refund_amazon_fees || "0"
                    )}
                  </h4>
                </div>
                <div className="separator separator-dashed my-3" /> */}
                    <Collapse
                      activeKey={[collapseKey]}
                      accordion={true}
                      onChange={(key) => {
                        onCollapseChange(key);
                      }}
                      className="mb-3 border border-gray-300 bg-gray-100 border-dashed rounded"
                    >
                      <Panel
                        header={
                          <>
                            <div className="d-flex align-items-center">
                              <div
                                className="d-flex align-items-center"
                                style={{ width: "32%" }}
                              >
                                {/* <div className="w-7px h-7px rounded-circle bg-warning" /> */}
                                <h5 className="mb-0 text-dark fw-semibold ms-1 ">
                                  Amazon Fees
                                </h5>
                              </div>
                              <div style={{ width: "33.5%", textAlign: "end" }}>
                                <h4 className="mb-0 fw-bolder">
                                  {selectedCurrencySign}&nbsp;
                                  {NumberWithCommas(
                                    "-" + salesData?.total_amazon_fees || "0"
                                  )}
                                </h4>
                              </div>
                              <div style={{ width: "34%", textAlign: "end" }}>
                                <h4 className="mb-0 fw-bolder">
                                  {selectedCurrencySign}&nbsp;
                                  {NumberWithCommas(
                                    salesData?.refunds
                                      ?.total_refund_amazon_fees || "0"
                                  )}
                                </h4>
                              </div>
                            </div>
                          </>
                        }
                        key="2"
                        // extra={
                        //   <>
                        //     <h4 className="mb-0 fw-bolder">
                        //       {selectedCurrencySign}&nbsp;
                        //       {NumberWithCommas(salesData?.total_amazon_fees || "0")}
                        //     </h4>
                        //   </>
                        // }
                      >
                        {/* <div className="separator separator-dashed my-3" />
                    <div className="d-flex justify-content-between">
                      <label
                        className="panel_text  fw-bolder"
                        style={{
                          width: "33%",
                        }}
                      >
                        Amazon Fees
                      </label>
                      <h4
                        className="mb-0 fw-bolder"
                        style={{
                          width: "33%",
                          textAlign: "end",
                          fontWeight: "400",
                        }}
                      >
                        {selectedCurrencySign}&nbsp;
                        {NumberWithCommas(
                          "-" + salesData?.total_amazon_fees || "0"
                        )}
                      </h4>
                      <h4
                        className="mb-0 fw-bolder"
                        style={{
                          width: "33%",
                          textAlign: "end",
                          fontWeight: "400",
                        }}
                      >
                        {selectedCurrencySign}&nbsp;
                        {NumberWithCommas(
                          salesData?.refunds?.total_refund_amazon_fees || "0"
                        )}
                      </h4>
                    </div> */}
                        <div className="separator separator-dashed my-3" />
                        <div className="d-flex justify-content-between">
                          <label
                            className="panel_text"
                            style={{
                              width: "33%",
                            }}
                          >
                            FBA Fees
                          </label>
                          <h4
                            className="mb-0 "
                            style={{
                              width: "33%",
                              textAlign: "end",
                              fontWeight: "400",
                            }}
                          >
                            {selectedCurrencySign}&nbsp;
                            {NumberWithCommas(
                              "-" + salesData?.amazon_fees?.order_fba_fees ||
                                "0"
                            )}
                          </h4>
                          <h4
                            className="mb-0 "
                            style={{
                              width: "33%",
                              textAlign: "end",
                              fontWeight: "400",
                            }}
                          >
                            {selectedCurrencySign}&nbsp;
                            {/* {NumberWithCommas(
                        "-" + salesData?.amazon_fees?.order_fba_fees || "0"
                      )} */}
                            {selectedCurrencySign}&nbsp; 0
                          </h4>
                        </div>

                        <div className="separator separator-dashed my-3" />
                        <div className="d-flex justify-content-between">
                          <label
                            className="panel_text"
                            style={{
                              width: "33%",
                            }}
                          >
                            Referral Fees
                          </label>
                          <h4
                            className="mb-0 "
                            style={{
                              width: "33%",
                              textAlign: "end",
                              fontWeight: "400",
                            }}
                          >
                            {selectedCurrencySign}&nbsp;
                            {NumberWithCommas(
                              "-" + salesData?.amazon_fees?.order_selling_fee ||
                                "0"
                            )}
                          </h4>
                          <h4
                            className="mb-0 "
                            style={{
                              width: "33%",
                              textAlign: "end",
                              fontWeight: "400",
                            }}
                          >
                            {selectedCurrencySign}&nbsp;
                            {NumberWithCommas(
                              salesData?.refunds?.refund_selling_fees || "0"
                            )}
                          </h4>
                        </div>
                        <div className="separator separator-dashed my-3" />
                        <div className="d-flex justify-content-between">
                          <label
                            className="panel_text"
                            style={{
                              width: "33%",
                            }}
                          >
                            Shipping Charge Back
                          </label>
                          <h4
                            className="mb-0 "
                            style={{
                              width: "33%",
                              textAlign: "end",
                              fontWeight: "400",
                            }}
                          >
                            {selectedCurrencySign}&nbsp;
                            {NumberWithCommas(
                              "-" +
                                salesData?.amazon_fees?.shipping_chargeback ||
                                "0"
                            )}
                          </h4>
                          <h4
                            className="mb-0 "
                            style={{
                              width: "33%",
                              textAlign: "end",
                              fontWeight: "400",
                            }}
                          >
                            {selectedCurrencySign}&nbsp;
                            {NumberWithCommas(
                              salesData?.refunds?.refund_shipping_charge || "0"
                            )}
                          </h4>
                        </div>

                        <div className="separator separator-dashed my-3" />
                        <div className="d-flex justify-content-between">
                          <label
                            className="panel_text"
                            style={{
                              width: "33%",
                            }}
                          >
                            Gift Wrap Charge Back
                          </label>
                          <h4
                            className="mb-0"
                            style={{
                              width: "33%",
                              textAlign: "end",
                              fontWeight: "400",
                            }}
                          >
                            {selectedCurrencySign}&nbsp;
                            {NumberWithCommas(
                              "-" +
                                salesData?.amazon_fees?.gift_wrap_chargeback ||
                                "0"
                            )}
                          </h4>
                          <h4
                            className="mb-0"
                            style={{
                              width: "33%",
                              textAlign: "end",
                              fontWeight: "400",
                            }}
                          >
                            {selectedCurrencySign}&nbsp;
                            {NumberWithCommas(
                              salesData?.refunds?.refund_gift_wrap_charge || "0"
                            )}
                          </h4>
                        </div>

                        {/* <div className="separator separator-dashed my-3" />
                  <div className="d-flex justify-content-between">
                    <label className="panel_text">Fixed Closing Fee</label>
                    <h4 className="mb-0 fw-bolder">
                      {selectedCurrencySign}&nbsp;
                      {NumberWithCommas(
                        salesData?.amazon_fees?.fixed_closing_fee || "0"
                      )}
                    </h4>
                  </div>
                  <div className="separator separator-dashed my-3" />
                  <div className="d-flex justify-content-between">
                    <label className="panel_text">Variable Closing Fees</label>
                    <h4 className="mb-0 fw-bolder">
                      {selectedCurrencySign}&nbsp;
                      {NumberWithCommas(
                        salesData?.amazon_fees?.variable_closing_fee || "0"
                      )}
                    </h4>
                  </div> */}
                      </Panel>
                    </Collapse>
                    <Collapse
                      activeKey={[collapseKey]}
                      accordion={true}
                      onChange={(key) => {
                        onCollapseChange(key);
                      }}
                      className="mb-3 border border-gray-300 bg-gray-100 border-dashed rounded"
                    >
                      <Panel
                        header={
                          <>
                            <div className="d-flex align-items-center">
                              <div
                                className="d-flex align-items-center"
                                style={{ width: "32%" }}
                              >
                                {/* <div className="w-7px h-7px rounded-circle bg-warning" /> */}
                                <h5 className="mb-0 text-dark fw-semibold ms-1 ">
                                  Service Fees
                                </h5>
                              </div>
                              <div style={{ width: "33.5%", textAlign: "end" }}>
                                <h4 className="mb-0 fw-bolder">
                                  {selectedCurrencySign}&nbsp;
                                  {NumberWithCommas(
                                    salesData?.total_service_fees || "0"
                                  )}
                                </h4>
                              </div>
                              <div style={{ width: "34%", textAlign: "end" }}>
                                <h4 className="mb-0 fw-bolder">-</h4>
                              </div>
                            </div>
                          </>
                        }
                        key="3"
                        // extra={
                        //   <>
                        //     <h4 className="mb-0 fw-bolder">
                        //       {selectedCurrencySign}&nbsp;
                        //       {NumberWithCommas(salesData?.total_service_fees || "0")}
                        //     </h4>
                        //   </>
                        // }
                      >
                        <div className="separator separator-dashed my-3" />
                        <div className="d-flex justify-content-between">
                          <label
                            className="panel_text"
                            style={{
                              width: "33%",
                            }}
                          >
                            Storage Fees
                          </label>
                          <h4
                            className="mb-0"
                            style={{
                              width: "33%",
                              textAlign: "end",
                              fontWeight: "400",
                            }}
                          >
                            {selectedCurrencySign}&nbsp;
                            {NumberWithCommas(
                              salesData?.service_fees?.storage_fees || "0"
                            )}
                          </h4>
                          <h4
                            className="mb-0 "
                            style={{
                              width: "33%",
                              textAlign: "end",
                              fontWeight: "400",
                            }}
                          >
                            {/* {selectedCurrencySign}&nbsp;
                      {NumberWithCommas(
                        salesData?.service_fees?.storage_fees || "0"
                      )} */}
                            -
                          </h4>
                        </div>

                        <div className="separator separator-dashed my-3" />
                        <div className="d-flex justify-content-between">
                          <label
                            className="panel_text"
                            style={{
                              width: "33%",
                            }}
                          >
                            Long Term Storage Fees
                          </label>
                          <h4
                            className="mb-0"
                            style={{
                              width: "33%",
                              textAlign: "end",
                              fontWeight: "400",
                            }}
                          >
                            {selectedCurrencySign}&nbsp;
                            {NumberWithCommas(
                              salesData?.service_fees?.long_term_storage_fees ||
                                "0"
                            )}
                          </h4>
                          <h4
                            className="mb-0 "
                            style={{
                              width: "33%",
                              textAlign: "end",
                              fontWeight: "400",
                            }}
                          >
                            {/* {selectedCurrencySign}&nbsp;
                      {NumberWithCommas(
                        salesData?.service_fees?.long_term_storage_fees || "0"
                      )} */}
                            -
                          </h4>
                        </div>

                        <div className="separator separator-dashed my-3" />
                        <div className="d-flex justify-content-between">
                          <label
                            className="panel_text"
                            style={{
                              width: "33%",
                            }}
                          >
                            Disposal Fees
                          </label>
                          <h4
                            className="mb-0 "
                            style={{
                              width: "33%",
                              textAlign: "end",
                              fontWeight: "400",
                            }}
                          >
                            {selectedCurrencySign}&nbsp;
                            {NumberWithCommas(
                              salesData?.service_fees?.disposal_fees || "0"
                            )}
                          </h4>
                          <h4
                            className="mb-0 "
                            style={{
                              width: "33%",
                              textAlign: "end",
                              fontWeight: "400",
                            }}
                          >
                            {/* {selectedCurrencySign}&nbsp;
                      {NumberWithCommas(
                        salesData?.service_fees?.disposal_fees || "0"
                      )} */}
                            -
                          </h4>
                        </div>

                        <div className="separator separator-dashed my-3" />
                        <div className="d-flex justify-content-between">
                          <label
                            className="panel_text"
                            style={{
                              width: "33%",
                            }}
                          >
                            Removal Fees
                          </label>
                          <h4
                            className="mb-0 "
                            style={{
                              width: "33%",
                              textAlign: "end",
                              fontWeight: "400",
                            }}
                          >
                            {selectedCurrencySign}&nbsp;
                            {NumberWithCommas(
                              salesData?.service_fees?.removal_fees || "0"
                            )}
                          </h4>
                          <h4
                            className="mb-0 "
                            style={{
                              width: "33%",
                              textAlign: "end",
                              fontWeight: "400",
                            }}
                          >
                            {/* {selectedCurrencySign}&nbsp;
                      {NumberWithCommas(
                        salesData?.service_fees?.removal_fees || "0"
                      )} */}
                            -
                          </h4>
                        </div>

                        <div className="separator separator-dashed my-3" />
                        <div className="d-flex justify-content-between">
                          <label
                            className="panel_text"
                            style={{
                              width: "33%",
                            }}
                          >
                            Subscription
                          </label>
                          <h4
                            className="mb-0 "
                            style={{
                              width: "33%",
                              textAlign: "end",
                              fontWeight: "400",
                            }}
                          >
                            {selectedCurrencySign}&nbsp;
                            {NumberWithCommas(
                              salesData?.service_fees?.subscription || "0"
                            )}
                          </h4>
                          <h4
                            className="mb-0 "
                            style={{
                              width: "33%",
                              textAlign: "end",
                              fontWeight: "400",
                            }}
                          >
                            {/* {selectedCurrencySign}&nbsp;
                      {NumberWithCommas(
                        salesData?.service_fees?.subscription || "0"
                      )} */}
                            -
                          </h4>
                        </div>

                        <div className="separator separator-dashed my-3" />
                        <div className="d-flex justify-content-between">
                          <label
                            className="panel_text"
                            style={{
                              width: "33%",
                            }}
                          >
                            Inbound Transportation Fees
                          </label>
                          <h4
                            className="mb-0 "
                            style={{
                              width: "33%",
                              textAlign: "end",
                              fontWeight: "400",
                            }}
                          >
                            {selectedCurrencySign}&nbsp;
                            {NumberWithCommas(
                              salesData?.service_fees
                                ?.inbound_transportation_fees || "0"
                            )}
                          </h4>
                          <h4
                            className="mb-0 "
                            style={{
                              width: "33%",
                              textAlign: "end",
                              fontWeight: "400",
                            }}
                          >
                            {/* {selectedCurrencySign}&nbsp;
                      {NumberWithCommas(
                        salesData?.service_fees?.inbound_transportation_fees ||
                          "0"
                      )} */}
                            -
                          </h4>
                        </div>

                        <div className="separator separator-dashed my-3" />
                        <div className="d-flex justify-content-between">
                          <label
                            className="panel_text"
                            style={{
                              width: "33%",
                            }}
                          >
                            Inbound Transportation Program Fees
                          </label>
                          <h4
                            className="mb-0 "
                            style={{
                              width: "33%",
                              textAlign: "end",
                              fontWeight: "400",
                            }}
                          >
                            {selectedCurrencySign}&nbsp;
                            {NumberWithCommas(
                              salesData?.service_fees
                                ?.inbound_transportation_program_fees || "0"
                            )}
                          </h4>
                          <h4
                            className="mb-0 "
                            style={{
                              width: "33%",
                              textAlign: "end",
                              fontWeight: "400",
                            }}
                          >
                            {/* {selectedCurrencySign}&nbsp;
                      {NumberWithCommas(
                        salesData?.service_fees
                          ?.inbound_transportation_program_fees || "0"
                      )} */}
                            -
                          </h4>
                        </div>
                      </Panel>
                    </Collapse>
                    <Collapse
                      activeKey={[collapseKey]}
                      accordion={true}
                      onChange={(key) => {
                        onCollapseChange(key);
                      }}
                      className="mb-3 border border-gray-300 bg-gray-100 border-dashed rounded"
                    >
                      <Panel
                        header={
                          <>
                            <div className="d-flex align-items-center">
                              <div
                                className="d-flex align-items-center"
                                style={{ width: "32%" }}
                              >
                                {/* <div className="w-7px h-7px rounded-circle bg-warning" /> */}
                                <h5 className="mb-0 text-dark fw-semibold ms-1 ">
                                  Adjustment Fees
                                </h5>
                              </div>
                              <div style={{ width: "33.5%", textAlign: "end" }}>
                                <h4 className="mb-0 fw-bolder">
                                  {selectedCurrencySign}&nbsp;
                                  {NumberWithCommas(
                                    salesData?.total_adjustment_fees || "0"
                                  )}
                                </h4>
                              </div>
                              <div style={{ width: "34%", textAlign: "end" }}>
                                <h4 className="mb-0 fw-bolder">-</h4>
                              </div>
                            </div>
                          </>
                        }
                        key="4"
                        // extra={
                        //   <>
                        //     <h4 className="mb-0 fw-bolder">
                        //       {selectedCurrencySign}&nbsp;
                        //       {NumberWithCommas(
                        //         salesData?.total_adjustment_fees || "0"
                        //       )}
                        //     </h4>
                        //   </>
                        // }
                      >
                        <div className="separator separator-dashed my-3" />

                        <div className="d-flex justify-content-between">
                          <label
                            className="panel_text"
                            style={{
                              width: "33%",
                            }}
                          >
                            Reversal Reimbursement
                          </label>
                          <h4
                            className="mb-0 "
                            style={{
                              width: "33%",
                              textAlign: "end",
                              fontWeight: "400",
                            }}
                          >
                            {selectedCurrencySign}&nbsp;
                            {NumberWithCommas(
                              salesData?.adjustment_fees
                                ?.reversal_reimbursement || "0"
                            )}
                          </h4>
                          <h4
                            className="mb-0 "
                            style={{
                              width: "33%",
                              textAlign: "end",
                              fontWeight: "400",
                            }}
                          >
                            {/* {selectedCurrencySign}&nbsp;
                      {NumberWithCommas(
                        salesData?.adjustment_fees?.reversal_reimbursement ||
                          "0"
                      )} */}
                            -
                          </h4>
                        </div>
                        <div className="separator separator-dashed my-3" />

                        <div className="d-flex justify-content-between">
                          <label
                            className="panel_text"
                            style={{
                              width: "33%",
                            }}
                          >
                            Free Replacement Refund Items
                          </label>
                          <h4
                            className="mb-0 "
                            style={{
                              width: "33%",
                              textAlign: "end",
                              fontWeight: "400",
                            }}
                          >
                            {selectedCurrencySign}&nbsp;
                            {NumberWithCommas(
                              salesData?.adjustment_fees
                                ?.free_replacement_refund_items || "0"
                            )}
                          </h4>
                          <h4
                            className="mb-0 "
                            style={{
                              width: "33%",
                              textAlign: "end",
                              fontWeight: "400",
                            }}
                          >
                            {/* {selectedCurrencySign}&nbsp;
                      {NumberWithCommas(
                        salesData?.adjustment_fees
                          ?.free_replacement_refund_items || "0"
                      )} */}
                            -
                          </h4>
                        </div>

                        <div className="separator separator-dashed my-3" />

                        <div className="d-flex justify-content-between">
                          <label
                            className="panel_text"
                            style={{
                              width: "33%",
                            }}
                          >
                            Reserve Debit
                          </label>
                          <h4
                            className="mb-0 "
                            style={{
                              width: "33%",
                              textAlign: "end",
                              fontWeight: "400",
                            }}
                          >
                            {selectedCurrencySign}&nbsp;
                            {NumberWithCommas(
                              salesData?.adjustment_fees?.reserve_debit || "0"
                            )}
                          </h4>
                          <h4
                            className="mb-0 "
                            style={{
                              width: "33%",
                              textAlign: "end",
                              fontWeight: "400",
                            }}
                          >
                            {/* {selectedCurrencySign}&nbsp;
                      {NumberWithCommas(
                        salesData?.adjustment_fees?.reserve_debit || "0"
                      )} */}
                            -
                          </h4>
                        </div>
                        <div className="separator separator-dashed my-3" />
                        <div className="d-flex justify-content-between">
                          <label
                            className="panel_text"
                            style={{
                              width: "33%",
                            }}
                          >
                            Reserve Credit
                          </label>
                          <h4
                            className="mb-0 "
                            style={{
                              width: "33%",
                              textAlign: "end",
                              fontWeight: "400",
                            }}
                          >
                            {selectedCurrencySign}&nbsp;
                            {NumberWithCommas(
                              salesData?.adjustment_fees?.reserve_credit || "0"
                            )}
                          </h4>
                          <h4
                            className="mb-0 "
                            style={{
                              width: "33%",
                              textAlign: "end",
                              fontWeight: "400",
                            }}
                          >
                            {/* {selectedCurrencySign}&nbsp;
                      {NumberWithCommas(
                        salesData?.adjustment_fees?.reserve_credit || "0"
                      )} */}
                            -
                          </h4>
                        </div>
                        <div className="separator separator-dashed my-3" />
                        <div className="d-flex justify-content-between">
                          <label
                            className="panel_text"
                            style={{
                              width: "33%",
                            }}
                          >
                            Compensated clawback
                          </label>
                          <h4
                            className="mb-0 "
                            style={{
                              width: "33%",
                              textAlign: "end",
                              fontWeight: "400",
                            }}
                          >
                            {selectedCurrencySign}&nbsp;
                            {NumberWithCommas(
                              salesData?.adjustment_fees
                                ?.compensated_clawback || "0"
                            )}
                          </h4>
                          <h4
                            className="mb-0 "
                            style={{
                              width: "33%",
                              textAlign: "end",
                              fontWeight: "400",
                            }}
                          >
                            {/* {selectedCurrencySign}&nbsp;
                      {NumberWithCommas(
                        salesData?.adjustment_fees?.compensated_clawback || "0"
                      )} */}
                            -
                          </h4>
                        </div>

                        <div className="separator separator-dashed my-3" />
                        <div className="d-flex justify-content-between">
                          <label
                            className="panel_text"
                            style={{
                              width: "33%",
                            }}
                          >
                            Warehouse Damage
                          </label>
                          <h4
                            className="mb-0 "
                            style={{
                              width: "33%",
                              textAlign: "end",
                              fontWeight: "400",
                            }}
                          >
                            {selectedCurrencySign}&nbsp;
                            {NumberWithCommas(
                              salesData?.adjustment_fees?.warehouse_damage ||
                                "0"
                            )}
                          </h4>
                          <h4
                            className="mb-0 "
                            style={{
                              width: "33%",
                              textAlign: "end",
                              fontWeight: "400",
                            }}
                          >
                            {/* {selectedCurrencySign}&nbsp;
                      {NumberWithCommas(
                        salesData?.adjustment_fees?.warehouse_damage || "0"
                      )} */}
                            -
                          </h4>
                        </div>
                        <div className="separator separator-dashed my-3" />
                        <div className="d-flex justify-content-between">
                          <label
                            className="panel_text"
                            style={{
                              width: "33%",
                            }}
                          >
                            Free Adjustment
                          </label>
                          <h4
                            className="mb-0 "
                            style={{
                              width: "33%",
                              textAlign: "end",
                              fontWeight: "400",
                            }}
                          >
                            {selectedCurrencySign}&nbsp;
                            {NumberWithCommas(
                              salesData?.adjustment_fees?.fee_adjustment || "0"
                            )}
                          </h4>
                          <h4
                            className="mb-0 "
                            style={{
                              width: "33%",
                              textAlign: "end",
                              fontWeight: "400",
                            }}
                          >
                            {/* {selectedCurrencySign}&nbsp;
                      {NumberWithCommas(
                        salesData?.adjustment_fees?.fee_adjustment || "0"
                      )} */}
                            -
                          </h4>
                        </div>
                      </Panel>
                    </Collapse>

                    {/* <div className="border border-gray-300 bg-gray-100 border-dashed rounded p-2 px-5 mb-2 ">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    <div className="w-5px h-5px rounded-circle bg-dark" />
                    <h5 className="mb-0 text-dark fw-semibold ms-3">
                      Shipping
                    </h5>
                  </div>
                  <div>
                    <h4 className="mb-0 fw-bolder fs-6">
                      {selectedCurrencySign}&nbsp;{NumberWithCommas(salesData?.shipping || "0")}
                    </h4>
                  </div>
                </div>
              </div>
              <div className="border border-gray-300 bg-gray-100 border-dashed rounded p-2 px-5 mb-2 ">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    <div className="w-5px h-5px rounded-circle bg-dark" />
                    <h5 className="mb-0 text-dark fw-semibold ms-3">Refunds</h5>
                  </div>
                  <div>
                    <h4 className="mb-0 fw-bolder fs-6">
                      {selectedCurrencySign}&nbsp;
                      {NumberWithCommas(salesData?.refund_revenue || "0")}
                    </h4>
                  </div>
                </div>
              </div>
              <div className="border border-gray-300 bg-gray-100 border-dashed rounded p-2 px-5 mb-2 ">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    <div className="w-5px h-5px rounded-circle bg-dark" />
                    <h5 className="mb-0 text-dark fw-semibold ms-3">
                      Promo Value
                    </h5>
                  </div>
                  <div>
                    <h4 className="mb-0 fw-bolder fs-6">
                      {selectedCurrencySign}&nbsp;{NumberWithCommas(salesData?.promo_value || "0")}
                    </h4>
                  </div>
                </div>
              </div> */}
                  </Panel>
                </Collapse>
                <Collapse
                  activeKey={[subCollapseKey]}
                  accordion={true}
                  onChange={(key) => {
                    onSubCollapseChange(key);
                  }}
                  className="mb-2 border border-gray-300 bg-gray-100 border-dashed rounded commonCollapseDiv"
                >
                  <Panel
                    header={
                      <>
                        <div className="d-flex align-items-center">
                          <div
                            className="d-flex align-items-center"
                            style={{ width: "32%" }}
                          >
                            {/* <div className="w-7px h-7px rounded-circle bg-warning" /> */}
                            <h5 className="mb-0 text-dark fw-semibold ms-1 ">
                              Total Tax
                            </h5>
                          </div>
                          <div style={{ width: "33%", textAlign: "end" }}>
                            <h4 className="mb-0 fw-bolder">
                              {selectedCurrencySign}&nbsp;
                              {NumberWithCommas(
                                salesData?.taxData?.total_tax || "0"
                              )}
                            </h4>
                          </div>
                          <div style={{ width: "33%", textAlign: "end" }}>
                            <h4 className="mb-0 fw-bolder">
                              {selectedCurrencySign}&nbsp;
                              {NumberWithCommas(
                                salesData?.refunds?.total_refund_tax || "0"
                              )}
                            </h4>
                          </div>
                        </div>

                        {/* <h5 className="mb-0 text-dark fw-semibold ms-1">
                      Total Tax
                    </h5> */}
                      </>
                    }
                    key="3"
                    // extra={
                    //   <>
                    //     <h4 className="mb-0 fw-bolder">
                    //       {selectedCurrencySign}&nbsp;
                    //       {NumberWithCommas(salesData?.taxData?.total_tax || "0")}
                    //     </h4>
                    //   </>
                    // }
                  >
                    {/* <div className="d-flex justify-content-between">
                  <label
                    className="panel_text "
                    style={{
                      width: "33%",
                    }}
                  >
                    Total Tax
                  </label>
                  <h4
                    className="mb-0 fw-bolder"
                    style={{
                      width: "33%",
                      textAlign: "end",
                      fontWeight: "400",
                    }}
                  >
                    {selectedCurrencySign}&nbsp;
                    {NumberWithCommas(salesData?.taxData?.total_tax || "0")}
                  </h4>
                  <h4
                    className="mb-0 fw-bolder"
                    style={{
                      width: "33%",
                      textAlign: "end",
                      fontWeight: "400",
                    }}
                  >
                    {selectedCurrencySign}&nbsp;
                    {NumberWithCommas(
                      salesData?.refunds?.total_refund_tax || "0"
                    )}
                  </h4>
                </div>
                <div className="separator separator-dashed my-3" /> */}
                    <div className="separator separator-dashed my-3" />
                    <div className="d-flex justify-content-between mx-5">
                      <label
                        className="panel_text "
                        style={{
                          width: "33%",
                        }}
                      >
                        Tax Collected
                      </label>
                      <h4
                        className="mb-0"
                        style={{
                          width: "33%",
                          textAlign: "end",
                          fontWeight: "400",
                        }}
                      >
                        {selectedCurrencySign}&nbsp;
                        {NumberWithCommas(
                          salesData?.taxData?.tax_collected || "0"
                        )}
                      </h4>
                      <h4
                        className="mb-0"
                        style={{
                          width: "33%",
                          textAlign: "end",
                          fontWeight: "400",
                        }}
                      >
                        {selectedCurrencySign}&nbsp;
                        {NumberWithCommas(
                          "-" + salesData?.refunds?.refund_tax || "0"
                        )}
                      </h4>
                    </div>
                    <div className="separator separator-dashed my-3" />
                    <div className="d-flex justify-content-between mx-5">
                      <label
                        className="panel_text "
                        style={{
                          width: "33%",
                        }}
                      >
                        Tax Withheld
                      </label>
                      <h4
                        className="mb-0 "
                        style={{
                          width: "33%",
                          textAlign: "end",
                          fontWeight: "400",
                        }}
                      >
                        {selectedCurrencySign}&nbsp;
                        {NumberWithCommas(
                          "-" + salesData?.taxData?.tax_withheld || "0"
                        )}
                      </h4>
                      <h4
                        className="mb-0 "
                        style={{
                          width: "33%",
                          textAlign: "end",
                          fontWeight: "400",
                        }}
                      >
                        {selectedCurrencySign}&nbsp;
                        {NumberWithCommas(
                          salesData?.refunds?.refund_marketplace_withheld_tax ||
                            "0"
                        )}
                      </h4>
                    </div>
                  </Panel>
                </Collapse>
                {/* <div className="my-2" style={{ border: "1px dashed #bbbbbb" }} /> */}
                <Collapse
                  activeKey={[subCollapseKey]}
                  accordion={true}
                  onChange={(key) => {
                    onSubCollapseChange(key);
                  }}
                  className="border border-gray-300 bg-gray-100 border-dashed rounded mb-2 commonCollapseDiv"
                >
                  <Panel
                    header={
                      <>
                        <div className="d-flex align-items-center">
                          <div
                            className="d-flex align-items-center"
                            style={{ width: "32%" }}
                          >
                            {/* <div className="w-7px h-7px rounded-circle bg-warning" /> */}
                            <h5 className="mb-0 text-dark fw-semibold ms-1 ">
                              PPC Costs
                            </h5>
                          </div>
                          <div style={{ width: "33%", textAlign: "end" }}>
                            <h4 className="mb-0 fw-bolder">
                              {selectedCurrencySign}&nbsp;
                              {NumberWithCommas(
                                salesData?.ppc_cost?.total_cost || "0"
                              )}
                            </h4>
                          </div>
                          <div style={{ width: "33%", textAlign: "end" }}>
                            <h4 className="mb-0 fw-bolder">-</h4>
                          </div>
                        </div>
                      </>
                    }
                    key="4"
                    // extra={
                    //   <>
                    //     <h4 className="mb-0 fw-bolder">
                    //       {selectedCurrencySign}&nbsp;
                    //       {NumberWithCommas(salesData?.ppc_cost?.total_cost || "0")}
                    //     </h4>
                    //   </>
                    // }
                  >
                    <div className="separator separator-dashed my-3" />
                    <div className="d-flex justify-content-between mx-5">
                      <label
                        className="panel_text "
                        style={{
                          width: "33%",
                        }}
                      >
                        Sponsored Products
                      </label>
                      <h4
                        className="mb-0 "
                        style={{
                          width: "33%",
                          textAlign: "end",
                          fontWeight: "400",
                        }}
                      >
                        {selectedCurrencySign}&nbsp;
                        {NumberWithCommas(salesData?.ppc_cost?.sp_cost || "0")}
                      </h4>
                      <h4
                        className="mb-0 "
                        style={{
                          width: "33%",
                          textAlign: "end",
                          fontWeight: "400",
                        }}
                      >
                        {/* {selectedCurrencySign}&nbsp;
                  {NumberWithCommas(salesData?.ppc_cost?.sp_cost || "0")} */}
                        -
                      </h4>
                    </div>

                    <div className="separator separator-dashed my-3" />

                    <div className="d-flex justify-content-between mx-5">
                      <label
                        className="panel_text "
                        style={{
                          width: "33%",
                        }}
                      >
                        Sponsored Brands
                      </label>
                      <h4
                        className="mb-0"
                        style={{
                          width: "33%",
                          textAlign: "end",
                          fontWeight: "400",
                        }}
                      >
                        {selectedCurrencySign}&nbsp;
                        {NumberWithCommas(salesData?.ppc_cost?.sb_cost || "0")}
                      </h4>
                      <h4
                        className="mb-0"
                        style={{
                          width: "33%",
                          textAlign: "end",
                          fontWeight: "400",
                        }}
                      >
                        {/* {selectedCurrencySign}&nbsp;
                  {NumberWithCommas(salesData?.ppc_cost?.sb_cost || "0")} */}
                        -
                      </h4>
                    </div>

                    <div className="separator separator-dashed my-3" />

                    <div className="d-flex justify-content-between mx-5">
                      <label
                        className="panel_text "
                        style={{
                          width: "33%",
                        }}
                      >
                        Sponsored Displays
                      </label>
                      <h4
                        className="mb-0"
                        style={{
                          width: "33%",
                          textAlign: "end",
                          fontWeight: "400",
                        }}
                      >
                        {selectedCurrencySign}&nbsp;
                        {NumberWithCommas(salesData?.ppc_cost?.sd_cost || "0")}
                      </h4>
                      <h4
                        className="mb-0"
                        style={{
                          width: "33%",
                          textAlign: "end",
                          fontWeight: "400",
                        }}
                      >
                        {/* {selectedCurrencySign}&nbsp;
                  {NumberWithCommas(salesData?.ppc_cost?.sd_cost || "0")} */}
                        -
                      </h4>
                    </div>
                  </Panel>
                </Collapse>
                {/* <div className="border border-gray-300 bg-gray-100 border-dashed rounded p-2 px-5 mb-2">
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  <h5 className="mb-0 text-gray-800 fw-semibold ms-3">
                    Reimbursements
                  </h5>
                </div>
                <div>
                  <h4 className="mb-0 fw-bolder">
                    {selectedCurrencySign}&nbsp;
                    {NumberWithCommas(salesData?.reimbursement || "0")}
                  </h4>
                </div>
              </div>
            </div> */}
                {/* <div className="border border-gray-300 bg-gray-100 border-dashed rounded p-2 px-5 mb-2">
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                // <div className="w-7px h-7px rounded-circle bg-warning" />
                <h5 className="mb-0 text-gray-800 fw-semibold ms-3">
                  Estimated Payout
                </h5>
              </div>
              <div>
                <h4 className="mb-0 fw-bolder">
                  {selectedCurrencySign}&nbsp;
                  {NumberWithCommas(salesData?.estimate_payout || "0")}
                </h4>
              </div>
            </div>
          </div> */}
              </>
            )}
          </div>
        </div>
        {/*end::Body*/}
        <div className="card-footer p-3 px-5">
          <div className="mb-5 mt-3" style={{ border: "1px dashed #bbbbbb" }} />
          {salesLoading ? (
            <>
              <Skeleton.Button active block className="mb-1" />
              <Skeleton.Button active block className="mb-1" />
              <Skeleton.Button active block className="mb-1" />
            </>
          ) : (
            <>
              <div
                className="bg-gray-100 rounded p-2 px-5 mb-2"
                style={{
                  borderStyle: "dashed",
                  borderColor: "#ac9c06",
                  borderWidth: "1px",
                  backgroundColor: "transparent",
                }}
              >
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    {/* <div className="w-7px h-7px rounded-circle bg-gren" /> */}
                    <h5 className="mb-0 text-gren fw-semibold ms-3">
                      Profit Margin
                    </h5>
                  </div>
                  <div>
                    <h4 className="mb-0 fw-bolder">
                      {salesData?.profit_margin || "0"}%
                    </h4>
                  </div>
                </div>
              </div>
              <div
                className="bg-gray-100 rounded p-2 px-5 mb-2"
                style={{
                  borderStyle: "dashed",
                  borderColor: "#8f7be9",
                  borderWidth: "1px",
                  backgroundColor: "transparent",
                }}
              >
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    {/* <div className="w-7px h-7px rounded-circle bg-gren" /> */}
                    <h5 className="mb-0 text-gren fw-semibold ms-3">ROI</h5>
                  </div>
                  <div>
                    <h4 className="mb-0 fw-bolder">{salesData?.roi || "0"}%</h4>
                  </div>
                </div>
              </div>
              <div
                className=" rounded p-2 px-5 mb-2"
                style={{
                  borderStyle: "solid",
                  borderColor: "#63CE94",
                  borderWidth: "1px",
                  backgroundColor: "transparent",
                }}
              >
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    <i className="ki-outline ki-chart-simple-2 fs-1 text-green" />
                    <h5 className="mb-0 text-green fw-semibold ms-3 fs-4">
                      Total Sales
                    </h5>
                  </div>
                  <div>
                    <h4 className="mb-0 fw-bolder text-green fs-3">
                      {selectedCurrencySign}&nbsp;
                      {NumberWithCommas(salesData?.total_sales)}
                    </h4>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </SalesBreakDownWrapper>
  );
};

export default SalesBreakdown;
