import { Skeleton, Tooltip, message } from "antd";
import moment from "moment";
import React, { useRef } from "react";
import Chart from "react-apexcharts";
import { useReactToPrint } from "react-to-print";
import { FilePdfOutlined, FileExcelOutlined } from "@ant-design/icons";
import writeXlsxFile from "write-excel-file";
import { savePDF } from "@progress/kendo-react-pdf";
import NoData from "../../../../component/no-data";
export default function (props) {
  const {
    orderChartDate,
    orderChartSeries,
    orderOverviewLoading,
    selectedDateRangeType,
    exportOrderGraphArray,
  } = props;

  const options = {
    chart: {
      type: "bar",
      height: 300,
      toolbar: {
        show: false,
        offsetY: -72,
        tools: {
          download: '<img src="/assets/media/downloads.png" width="20"/>',
        },
        export: {
          // Set the filename
          filename: "Order Overview Graph",
          // Include only PNG
          formats: ["png"],
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        // endingShape: "rounded",
        columnWidth: "50%",
        borderRadius: 3,
      },
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    dataLabels: {
      enabled: false,
    },
    yaxis: {
      title: {
        // text: '$ (thousands)'
      },
    },
    xaxis: {
      tickPlacement: "between",
      tickAmount: window.innerWidth >= 992 ? 8 : 5,
      categories:
        orderChartDate?.map((d) =>
          selectedDateRangeType === "last52_week" ||
          selectedDateRangeType === "year_to_date"
            ? d
            : moment(new Date(d)).format("DD MMM YYYY")
        ) || [],
      labels: {
        rotate: -50,
      },
    },
    noData: {
      text: "No Data",
      align: "center",
      verticalAlign: "middle",
      style: {
        fontSize: "20px",
      },
    },
    colors: ["#5058ce"],
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val;
        },
      },
    },
  };

  const chartRef = useRef(null);
  const handleExportPDF = () => {
    savePDF(chartRef.current, { paperSize: "auto", fileName: "chart.pdf" });
  };
  // const handleExportPDF = useReactToPrint({
  //   content: () => chartRef.current,
  //   documentTitle: "Order Overview Chart",
  //   onAfterPrint: () => {
  //     // message.destroy();
  //     // message.success("PDF Downloaded");
  //     // // Callback after printing, you can use it to trigger further actions.
  //     // console.log("Printed successfully!");
  //   },
  //   pageStyle: `
  //     @page {
  //       size: A4 landscape;
  //       margin: 0;
  //     }
  //     body {
  //       margin: 0;
  //       display: flex;
  //       align-items: center;
  //       justify-content: center;
  //       height: 100vh;
  //     }
  //   `,
  // });

  const exportHeaderAmazon = [
    { value: "Date", type: String },
    { value: "Orders", type: String },
  ];

  const downloadXlAmazon = async () => {
    let dataRow = [];
    await exportOrderGraphArray.map((d) => {
      const newList = [d?.date, d?.order_count];
      dataRow = [
        ...dataRow,
        newList.map((d) => {
          return { value: d };
        }),
      ];
    });
    const data = [exportHeaderAmazon, ...dataRow];
    await writeXlsxFile(data, {
      fileName: "Order Overview.xlsx",
    });
    message.destroy();
  };

  return (
    <>
      <div className="card h-md-100 mb-5 mb-xl-10">
        <div className="card-header pb-0  border-bottom-0">
          <div className="card-title align-items-start flex-column">
            <span className="card-label fs-2 fw-bold text-dark">
              Order Overview
            </span>
          </div>
          <div className="card-toolbar">
            <span
              className="d-flex align-items-center cursor-pointer me-5"
              onClick={() => {
                message.destroy();
                {
                  orderChartDate?.length != 0
                    ? handleExportPDF()
                    : message.info("No Data found");
                }
              }}
            >
              <Tooltip title="Download PDF" placement="bottom">
                <FilePdfOutlined className="fs-1" style={{ color: "red" }} />
              </Tooltip>
            </span>
            <span
              className="d-flex align-items-center cursor-pointer"
              onClick={() => {
                // handleExportPDF();
                message.destroy();
                {
                  orderChartDate?.length != 0
                    ? downloadXlAmazon()
                    : message.info("No Data found");
                }
              }}
            >
              <Tooltip title="Download Excel" placement="bottom">
                <FileExcelOutlined className="fs-1" style={{ color: "blue" }} />
              </Tooltip>
            </span>
          </div>
        </div>
        <div
          className="card-body"
          style={{ filter: orderOverviewLoading ? "blur(6px)" : "blur(0px)" }}
          ref={chartRef}
        >
          {orderChartDate?.length != 0 ? (
            <Chart
              options={options}
              series={orderChartSeries}
              type="bar"
              height={350}
            />
          ) : (
            <NoData height="400px" />
          )}
        </div>
      </div>
    </>
  );
}
