import { Skeleton, Tooltip, message } from "antd";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import Chart from "react-apexcharts";
import { NumberWithCommas } from "../../../../config";
import { savePDF } from "@progress/kendo-react-pdf";
import { FilePdfOutlined, FileExcelOutlined } from "@ant-design/icons";
import writeXlsxFile from "write-excel-file";
export default function (props) {
  const {
    salesChartSeries,
    salesChartDate,
    salesOverviewLoading,
    selectedDateRangeType,
    exportGraphArray,
  } = props;
  const chartRef = useRef(null);
  const options = {
    chart: {
      type: "bar",
      toolbar: {
        show: false,
        offsetY: -60,
        tools: {
          download: '<img src="/assets/media/downloads.png" width="20"/>',
        },
        export: {
          // Set the filename
          filename: "Sales Overview Graph",
          // Include only PNG
          formats: ["png"],
        },
      },
      selection: {
        enabled: true,
      },
      dropShadow: {
        enabled: !0,
        top: 12,
        left: 0,
        bottom: 0,
        right: 0,
        blur: 2,
        color: "rgba(132, 145, 183, 0.4)",
        opacity: 0.45,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        // endingShape: "rounded",
        columnWidth: "50%",
        borderRadius: 3,
      },
    },
    noData: {
      text: "No Data",
      align: "center",
      verticalAlign: "middle",
      style: {
        fontSize: "20px",
      },
    },
    tooltip: {
      marker: {
        show: true,
      },
      x: {
        show: true,
      },
      y: {
        formatter: function (
          value,
          { series, seriesIndex, dataPointIndex, w }
        ) {
          const withTitle_ = w?.config?.series?.[seriesIndex];
          // console.log(withTitle_, "withTitle_");
          return withTitle_?.name === "Unit Sold"
            ? value || 0
            : "$" + NumberWithCommas(value);
        },
      },
    },
    // colors: ["#4318ff", "#63CE94"],
    colors: ["#8992d4", "#373fb5", "#98cabb", "#097980"],

    // stroke: {
    //   curve: "smooth",
    //   lineCap: "butt",
    //   show: !0,
    //   width: 2,
    //   colors: ["#000", "#63CE94"],
    // },
    yaxis: [
      {
        seriesName: "Total Sales",
        title: {
          text: "Total Sales",
        },
        labels: {
          formatter: function (_) {
            return NumberWithCommas(_);
          },
        },
      },
      {
        seriesName: "Unit Sold",
        opposite: true,
        title: {
          text: "Unit Sold",
        },
        labels: {
          formatter: function (_) {
            return NumberWithCommas(_);
          },
        },
      },
    ],
    // yaxis: {
    //   labels: {
    //     formatter: function (_) {
    //       return NumberWithCommas(_);
    //     },
    //     offsetX: -12,
    //     offsetY: 0,
    //   },
    // },
    dataLabels: {
      enabled: false, // Set this to true if you want to display data labels initially
    },
    markers: {
      size: 5,
    },
    xaxis: {
      tickPlacement: "between",
      tickAmount: window.innerWidth >= 992 ? 15 : 5,
      categories:
        salesChartDate?.map((d) =>
          selectedDateRangeType === "last52_week" ||
          selectedDateRangeType === "year_to_date"
            ? d
            : moment(new Date(d)).format("DD MMM YYYY")
        ) || [],
      labels: {
        rotate: -50,
      },
    },
    grid: {
      strokeDashArray: 3,
      xaxis: { lines: { show: !0 } },
      yaxis: { lines: { show: !1 } },
    },
    legend: {
      show: true,
    },

    // fill: {
    //   type: "gradient",
    //   gradient: {
    //     type: "vertical",
    //     shadeIntensity: 1,
    //     inverseColors: !1,
    //     opacityFrom: 0.05,
    //     opacityTo: 0.05,
    //     stops: [45, 100],
    //   },
    // },
  };

  const handleExportPDF = () => {
    savePDF(chartRef.current, { paperSize: "auto", fileName: "chart.pdf" });
  };

  const exportHeaderAmazon = [
    { value: "Date", type: String },
    { value: "Total Sales", type: String },
    { value: "Unit Sold", type: String },
  ];

  const downloadXlAmazon = async () => {
    let dataRow = [];
    await exportGraphArray.map((d) => {
      const newList = [
        d?.date,
        d?.order_sales,
        d?.unit_sold,
        d?.ad_spend,
        d?.TACoS,
      ];
      dataRow = [
        ...dataRow,
        newList.map((d) => {
          return { value: d };
        }),
      ];
    });
    const data = [exportHeaderAmazon, ...dataRow];
    await writeXlsxFile(data, {
      fileName: "Sales Overview.xlsx",
    });
    message.destroy();
  };

  return (
    <>
      <div className="card h-md-100 mb-5 mb-xl-10">
        <div className="card-header pb-0  border-bottom-0">
          <div className="card-title align-items-start flex-column">
            <span className="card-label fs-2 fw-bold text-dark">
              Sales Overview
            </span>
          </div>
          <div className="card-toolbar">
            {salesOverviewLoading ? (
              <div className="d-flex">
                <Skeleton.Button block active className="me-2" />{" "}
                <Skeleton.Button block active />
              </div>
            ) : (
              <>
                <span
                  className="d-flex align-items-center cursor-pointer me-5"
                  onClick={() => {
                    handleExportPDF();
                  }}
                >
                  <Tooltip title="Download PDF" placement="bottom">
                    <FilePdfOutlined
                      className="fs-1"
                      style={{ color: "red" }}
                    />
                  </Tooltip>
                </span>
                <span
                  className="d-flex align-items-center cursor-pointer"
                  onClick={() => {
                    // handleExportPDF();
                    message.destroy();
                    {
                      salesChartDate?.length != 0
                        ? downloadXlAmazon()
                        : message.info("No Data found");
                    }
                  }}
                >
                  <Tooltip title="Download Excel" placement="bottom">
                    <FileExcelOutlined
                      className="fs-1"
                      style={{ color: "blue" }}
                    />
                  </Tooltip>
                </span>
              </>
            )}
          </div>
        </div>
        <div className="card-body py-0" ref={chartRef}>
          {salesOverviewLoading ? (
            <div className="d-flex justify-content-center">
              <Skeleton.Node active block />
            </div>
          ) : (
            <Chart
              options={options}
              series={salesChartSeries}
              type="bar"
              height={400}
            />
          )}
        </div>
      </div>
    </>
  );
}
