import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { EditReplenishmentWrapper } from "./style";
import ForecastTab from "./forecast-tab";

export default function (props) {
  const {
    show,
    onHide,
    selectedRowAsinSku,
    marketplace_id,
    GetReplenishmentForecastAction,
    fakeActionInventoryManagement,
  } = props;

  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          onHide();
        }}
        size="lg"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Forecast Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <EditReplenishmentWrapper>
              <ForecastTab {...props} />
            </EditReplenishmentWrapper>
          </>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <button
              className="btn btn-light-danger me-2"
              onClick={() => {
                onHide();
              }}
            >
              Cancel
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
