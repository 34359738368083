import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import WalmartOrders from "../../../modules/pages/order-management/order";

import {
  GetSalesChannelList,
  fakeActionSalesAnalytics,
} from "../../../redux/modules/sales-analytics/sales-analytics.action";
import {
  GetWalmartOrdersAction,
  GetWalmartOrdersActionWithAllProduct,
  GetDirectWalmartOrdersAction,
  GetOrderDetails,
  WalmartShipItemAction,
  WalmartCancelItemAction,
  WalmartAcknowledgeOrderAction,
  fakeActionWalmartOrders,
} from "../../../redux/modules/order-management/orders/orders.action";

const mapStateToProps = (state) => ({
  GetWalmartOrdersResponse: state.WalmartOrders.GetWalmartOrdersResponse,
  GetWalmartOrdersWithAllProductResponse:
    state.WalmartOrders.GetWalmartOrdersWithAllProductResponse,
  GetDirectWalmartOrdersResponse:
    state.WalmartOrders.GetDirectWalmartOrdersResponse,
  GetSalesChannelListResponse: state.SalesAnalytics.GetSalesChannelListResponse,
  GetWalmartOrdersDetailsResponse:
    state.WalmartOrders.GetWalmartOrdersDetailsResponse,
  WalmartShipmentItemsResponse:
    state.WalmartOrders.WalmartShipmentItemsResponse,
  WalmartCancelItemsResponse: state.WalmartOrders.WalmartCancelItemsResponse,
  WalmartAcknowledgeOrderResponse:
    state.WalmartOrders.WalmartAcknowledgeOrderResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      GetWalmartOrdersAction,
      GetWalmartOrdersActionWithAllProduct,
      GetDirectWalmartOrdersAction,
      GetOrderDetails,
      WalmartShipItemAction,
      WalmartCancelItemAction,
      WalmartAcknowledgeOrderAction,
      fakeActionWalmartOrders,

      GetSalesChannelList,
      fakeActionSalesAnalytics,
    },
    dispatch
  );

const WalmartOrdersContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(WalmartOrders);

export default WalmartOrdersContainer;
