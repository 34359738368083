import { Input, Popconfirm, Upload, message } from "antd";
import React, { useEffect, useState } from "react";
import {
  DeleteOutlined,
  PlusCircleOutlined,
  EditOutlined,
  CloudDownloadOutlined,
  CloudUploadOutlined,
} from "@ant-design/icons";
import Loading from "../../../../../component/loading";
import NoData from "../../../../../component/no-data";
import { useSelector } from "react-redux";
import { WarehouseSampleTemplate } from "../../../../../config";

export default function (props) {
  const {
    selectedRowAsinSku,
    marketplace_id,
    GetReplenishmentWarehouseListAction,
    AddEditWarehouseDetailsAction,
    DeleteWarehouseAction,
    ImportWarehouseStock,
    fakeActionInventoryManagement,
  } = props;

  const GetReplenishmentWarehouseListRes = useSelector(
    (state) =>
      state.InventoryManagement.GetReplenishmentWarehouseListResponse || {}
  );

  const AddEditReplenishmentWarehouseDetailsRes = useSelector(
    (state) =>
      state.InventoryManagement.AddEditReplenishmentWarehouseDetailsResponse ||
      {}
  );

  const DeleteReplenishmentWarehouseRes = useSelector(
    (state) =>
      state.InventoryManagement.DeleteReplenishmentWarehouseResponse || {}
  );

  const ImportWarehouseStockDataRes = useSelector(
    (state) => state.InventoryManagement.ImportWarehouseStockDataResponse || {}
  );

  const [warehouseList, setWarehouseList] = useState([]);
  const [warehouseIndex, setWarehouseIndex] = useState("");
  const [warehouseLoading, setWarehouseLoading] = useState(false);
  const [singleWarehouseObj, setSingleWarehouseObj] = useState({
    name: "",
    stock: 0,
  });

  const [userId, setUserId] = useState(
    JSON.parse(localStorage.getItem("user"))?.id.toString()
  );

  useEffect(() => {
    // setWarehouseLoading(true);
    const Obj = {
      sku_data_id: selectedRowAsinSku?.rowId,
    };
    GetReplenishmentWarehouseListAction(Obj);
  }, []);

  useEffect(() => {
    if (GetReplenishmentWarehouseListRes?.status === true) {
      message.destroy();
      message.success(GetReplenishmentWarehouseListRes?.message);
      setWarehouseList(GetReplenishmentWarehouseListRes?.data);
      setWarehouseLoading(false);
      fakeActionInventoryManagement("GetReplenishmentWarehouseListResponse");
    } else if (GetReplenishmentWarehouseListRes?.status === false) {
      message.destroy();
      message.error(GetReplenishmentWarehouseListRes.message);
      fakeActionInventoryManagement("GetReplenishmentWarehouseListResponse");
    }
  }, [GetReplenishmentWarehouseListRes]);

  useEffect(() => {
    if (AddEditReplenishmentWarehouseDetailsRes?.status === true) {
      message.destroy();
      message.success(AddEditReplenishmentWarehouseDetailsRes?.message);
      setWarehouseList(AddEditReplenishmentWarehouseDetailsRes?.data);
      fakeActionInventoryManagement(
        "AddEditReplenishmentWarehouseDetailsResponse"
      );
    } else if (AddEditReplenishmentWarehouseDetailsRes?.status === false) {
      message.destroy();
      message.error(AddEditReplenishmentWarehouseDetailsRes.message);
      fakeActionInventoryManagement(
        "AddEditReplenishmentWarehouseDetailsResponse"
      );
    }
  }, [AddEditReplenishmentWarehouseDetailsRes]);

  useEffect(() => {
    if (DeleteReplenishmentWarehouseRes?.status === true) {
      message.destroy();
      message.success(DeleteReplenishmentWarehouseRes?.message);
      setWarehouseList(DeleteReplenishmentWarehouseRes?.data);
      fakeActionInventoryManagement("DeleteReplenishmentWarehouseResponse");
    } else if (DeleteReplenishmentWarehouseRes?.status === false) {
      message.destroy();
      message.error(DeleteReplenishmentWarehouseRes.message);
      fakeActionInventoryManagement("DeleteReplenishmentWarehouseResponse");
    }
  }, [DeleteReplenishmentWarehouseRes]);

  useEffect(() => {
    if (ImportWarehouseStockDataRes?.status === true) {
      message.destroy();
      message.success(ImportWarehouseStockDataRes?.message);
      setWarehouseLoading(true);
      const Obj = {
        sku_data_id: selectedRowAsinSku?.rowId,
      };
      GetReplenishmentWarehouseListAction(Obj);
      fakeActionInventoryManagement("ImportWarehouseStockDataResponse");
    } else if (ImportWarehouseStockDataRes?.status === false) {
      message.destroy();
      message.error(ImportWarehouseStockDataRes.message);
      fakeActionInventoryManagement("ImportWarehouseStockDataResponse");
    }
  }, [ImportWarehouseStockDataRes]);

  const propsUpload = {
    beforeUpload: (file) => {
      const isValidFile =
        file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      if (!isValidFile) {
        message.error(`${file.name} is not a .xlsx file`);
      }
      return isValidFile || Upload.LIST_IGNORE;
    },
    onChange(info) {
      if (info?.file?.status !== "uploading") {
        message.destroy();
        console.log(info, "info");
        const isCsvOrXlsx =
          info?.file?.type === "text/csv" ||
          info?.file?.type ===
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        if (isCsvOrXlsx) {
          message.loading("File Uploading...", 0);
          setTimeout(() => {
            ImportWarehouseStock({
              warehouse_stock_file: info?.file?.originFileObj,
              sku: selectedRowAsinSku?.sku,
              asin: selectedRowAsinSku?.asin,
              marketplace_id: marketplace_id,
            });
          }, 1600);
        } else {
          message.error("You can only upload CSV/XLSX file!");
        }
      }
    },
    // onChange: (info) => {
    //   setFiles(info.fileList);
    // },
  };

  function validateBlobFile(blob) {
    if (
      blob.type !==
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      return false;
    }
    return true;
  }
  const handleBlobError = async (blob) => {
    try {
      console.log(blob, "blob");
      const newBlob = await blob.text();
      message.destroy();
      message.error(newBlob.split(".")?.[0]);
    } catch (error) {
      return "Invalid File";
      // Handle the error accordingly
    }
  };

  function downloadFile(url) {
    fetch(url)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        console.log(blob, "-----------------");
        if (validateBlobFile(blob)) {
          // Create a temporary URL for the blob
          const fileURL = URL.createObjectURL(blob);
          console.log(fileURL, "blob");

          // Create a link element
          const link = document.createElement("a");
          link.href = fileURL;
          link.download = `Warehouse Stock.xlsx`; // Specify the desired filename

          // Simulate a click on the link to initiate the download
          message.destroy();
          link.click();

          // Clean up the temporary URL
          URL.revokeObjectURL(fileURL);
        } else {
          message.destroy();
          handleBlobError(blob);
        }
      })
      .catch((error) => {
        message.destroy();
        message.error("Error in file");
      });
  }

  return (
    <>
      <div className="row d-flex justify-content-end align-items-center">
        <div className="col-auto">
          <Upload {...propsUpload} maxCount={1}>
            <button className="btn fs-7 fw-bolder import-button me-2">
              <CloudDownloadOutlined /> Import
            </button>
          </Upload>
        </div>
        <div className="col-auto">
          <button
            className="btn export-button"
            onClick={() => {
              const Id = btoa(userId);
              const url = `https://api.ivcsr.com/inventory/warehouse-stock-export?id=${Id}&sku=${selectedRowAsinSku?.sku}&asin=${selectedRowAsinSku?.asin}&marketplace_id=${marketplace_id}`;
              message.destroy();
              message.loading("Loading...", 0);
              downloadFile(url);
            }}
          >
            <CloudUploadOutlined />
            Export
          </button>
        </div>
        <div className="col-auto">
          <span
            className="text-primary fw-bold "
            style={{
              cursor: "pointer",
              fontSize: "1.2rem",
              textDecoration: "underline",
            }}
            onClick={() => {
              WarehouseSampleTemplate();
            }}
          >
            Download Sample Template
          </span>
        </div>
      </div>
      <div className="separator separator-dashed my-5" />
      <div className="row mt-5">
        <div className="col-4">
          <Input
            className="ant_common_input me-5"
            size="large"
            placeholder="Enter Warehouse Name"
            value={singleWarehouseObj.name}
            onChange={(e) => {
              let single_ = { ...singleWarehouseObj };
              single_.name = e.target.value;
              console.log(single_, "single_");
              setSingleWarehouseObj(single_);
            }}
          />
        </div>
        <div className="col-4">
          <Input
            className="ant_common_input me-5"
            size="large"
            placeholder="Enter Warehouse Stock"
            value={singleWarehouseObj.stock}
            onChange={(e) => {
              let single_ = { ...singleWarehouseObj };
              if (!e.target.value) {
                single_.stock = e.target.value;
                console.log(single_, "single_");
                setSingleWarehouseObj(single_);
              }
              const newVal = e.target.value.split(".").join("");
              const validation = /^[0-9]+$/.test(newVal);
              if (validation) {
                single_.stock = e.target.value;
                console.log(single_, "single_");
                setSingleWarehouseObj(single_);
              }
            }}
          />
        </div>
        <div className="col-3 d-flex align-items-center justify-content-evenly">
          <button
            className="btn btn-primary"
            onClick={() => {
              if (
                singleWarehouseObj?.name == "" ||
                singleWarehouseObj.stock == ""
              ) {
                message.destroy();
                message.warning("Please Enter Warehouse Details");
              } else {
                const Obj = {
                  id: warehouseIndex || 0,
                  sku_data_id: selectedRowAsinSku?.rowId || 0,
                  warehouse_name: singleWarehouseObj?.name,
                  stock: singleWarehouseObj?.stock,
                  sku: selectedRowAsinSku?.sku,
                  asin: selectedRowAsinSku?.asin,
                  marketplace_id: marketplace_id,
                };
                message.destroy(0);
                message.loading("Loading...", 0);
                AddEditWarehouseDetailsAction(Obj);
                setWarehouseIndex("");
                setSingleWarehouseObj({
                  name: "",
                  stock: 0,
                });
              }
            }}
          >
            {warehouseIndex !== "" ? "Save" : "Add"}
          </button>
          {warehouseIndex !== "" ? (
            <button
              className="btn btn-light-danger ms-2"
              onClick={() => {
                // setWarehouseIndex("");
                // setSingleWarehouseObj({
                //   name: "",
                //   stock: 0,
                // });
              }}
            >
              Cancel
            </button>
          ) : (
            ""
          )}
        </div>
      </div>
      {console.log(warehouseList, "warehouseList")}
      <div className="separator separator-dashed my-5" />
      <div className="row">
        <div className="row">
          <div className="col-1">
            <label className="fw-bolder fs-6">Sr.No</label>
          </div>
          <div className="col-4">
            <label className="fw-bolder fs-6">Warehouse Name</label>
          </div>
          <div className="col-4">
            <label className="fw-bolder fs-6">Warehouse Stock</label>
          </div>
          <div className="col-1">
            <label className="fw-bolder fs-6">Action</label>
          </div>
        </div>
        <div className="separator separator-dashed my-5" />
        <div style={{ height: "250px", overflowY: "auto" }}>
          {warehouseLoading ? (
            <Loading style={{ height: "335px" }} />
          ) : warehouseList?.length != 0 ? (
            warehouseList?.map((d, i) => {
              return (
                <>
                  <div className="row">
                    <div className="col-1">
                      <label className="fw-bolder fs-6">{i + 1}</label>
                    </div>
                    <div className="col-4">
                      <label>{d?.warehouse_name}</label>
                    </div>
                    <div className="col-4">
                      <label>{d?.stock}</label>
                    </div>
                    <div className="col-1">
                      <div
                        className="d-flex"
                        onClick={() => {
                          setWarehouseIndex(d?.id);
                          let single_ = { ...singleWarehouseObj };
                          single_.stock = d?.stock;
                          single_.name = d?.warehouse_name;
                          setSingleWarehouseObj(single_);
                        }}
                      >
                        <EditOutlined
                          style={{ color: "#3b973b", fontSize: "22px" }}
                        />
                      </div>
                    </div>
                    <div className="col-1">
                      <Popconfirm
                        title="Are you sure to delete this warehouse?"
                        placement="left"
                        cancelText="No"
                        okText="Yes"
                        getPopupContainer={(triggerNode) =>
                          triggerNode.parentNode
                        }
                        onConfirm={() => {
                          message.destroy();
                          message.loading("Deleting...", 0);
                          DeleteWarehouseAction(d?.id);
                        }}
                      >
                        <DeleteOutlined
                          style={{ color: "red", fontSize: "20px" }}
                        />
                      </Popconfirm>
                    </div>
                  </div>
                  <div className="separator separator-dashed my-5" />
                </>
              );
            })
          ) : (
            <NoData height={"200px"} />
          )}
        </div>
      </div>
    </>
  );
}
