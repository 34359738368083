import { Dropdown, Input, Popover, Select, Tag, message } from "antd";
import moment from "moment";
import React, { useEffect, useState, useContext } from "react";
import { useSelector } from "react-redux";
import IvcsrTable from "../../../../component/ivcsr-table";
import Loading from "../../../../component/loading";
import NoData from "../../../../component/no-data";
import Pagination from "../../../../component/pagination";
import { SearchOutlined } from "@ant-design/icons";
import { DefaultPerPage } from "../../../../config";

export default function (props) {
  const {
    GetWalmartItemFeed,
    fakeActionSkuData,
    GetSalesChannelList,
    fakeActionSalesAnalytics,
  } = props;

  const GetWalmartItemFeedRes = useSelector(
    (state) => state.SKUData.GetWalmartItemFeedResponse || {}
  );

  const GetSalesChannelListRes = useSelector(
    (state) => state.SalesAnalytics.GetSalesChannelListResponse || {}
  );

  const [filter, setFilter] = useState(null);
  const [salesChannelList, setsalesChannelList] = useState([]);
  const [selectedSalesChannel, setSelectedSalesChannel] = useState([]);
  const [selectedSalesChannelId, setSelectedSalesChannelId] = useState([]);
  const [salesChannelLoading, setSalesChannelLoading] = useState(true);
  const [selectedCurrencySign, setSelectedCurrencySign] = useState("");

  const [walmartList, setWalmartList] = useState([]);
  const [walmartLoading, setWalmartLoading] = useState(true);
  const [walmartPage, setWalmartPage] = useState(1);
  const [walmartTotalPage, setWalmartTotalPage] = useState(1);
  const [walmartPageSize, setWalmartPageSize] = useState(DefaultPerPage);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isOpenCreateModal, setIsOpenCreateModal] = useState(false);
  const [isOpenImportModal, setIsOpenImportModal] = useState(false);
  // const [isOpenImportModalParshv, setIsOpenImportModalParshv] = useState(false);
  const [isOpenBulkUploadModal, setIsOpenBulkUploadModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [feedType, setFeedType] = useState(null);
  const [feedStatus, setFeedStatus] = useState(null);

  useEffect(() => {
    GetSalesChannelList();
    return () => {};
  }, []);

  useEffect(() => {
    if (GetSalesChannelListRes?.status === true) {
      setSalesChannelLoading(false);
      setsalesChannelList(GetSalesChannelListRes?.data?.sales_channel);
      setSelectedSalesChannel("Amazon.com");
      setSelectedSalesChannelId("ATVPDKIKX0DER");
      setSelectedCurrencySign("$");

      const apiObj = {
        page: 1,
        perPage: walmartPageSize,
        sales_channel: "Amazon.com",
      };
      setWalmartLoading(true);
      GetWalmartItemFeed(apiObj);

      fakeActionSalesAnalytics("GetSalesChannelListResponse");
    } else if (GetSalesChannelListRes?.status === false) {
      fakeActionSalesAnalytics("GetSalesChannelListResponse");
    }
  }, [GetSalesChannelListRes]);

  useEffect(() => {
    if (GetWalmartItemFeedRes.status === true) {
      setWalmartList(GetWalmartItemFeedRes?.data?.records);
      console.log(GetWalmartItemFeedRes, "GetWalmartItemFeedRes");
      setWalmartLoading(false);
      setWalmartTotalPage(GetWalmartItemFeedRes?.data?.pagination?.totalCount);
      fakeActionSkuData("GetWalmartItemFeedResponse");
    } else if (GetWalmartItemFeedRes.status === false) {
      setWalmartLoading(false);
      fakeActionSkuData("GetWalmartItemFeedResponse");
    }
  }, [GetWalmartItemFeedRes]);

  const walmartColumns = [
    {
      title: "#",
      ellipsis: true,
      align: "left",
      render: (text) => {
        return (
          <span>{(walmartPage - 1) * walmartPageSize + 1 + text.key}</span>
        );
      },
    },
    // {
    //   title: "Feed Type",
    //   render: (text, record) => (
    //     <img
    //       src={record.primary_image_url}
    //       width={75}
    //       height={75}
    //       style={{ borderRadius: "5px" }}
    //       alt="Product Image"
    //     />
    //   ),
    // },
    {
      title: "Feed Type",
      dataIndex: "feed_type",
      key: "feed_type",
      render: (text) => <span className="fw-bold">{text || "-"}</span>,
    },
    {
      title: "Feed Id",
      dataIndex: "feed_id",
      key: "feed_id",
      render: (text) => <span>{text || "-"}</span>,
    },

    {
      title: "Feed Status",
      dataIndex: "feed_status",
      key: "feed_status",
      render: (text) => {
        return (
          <Tag
            color={
              text === "ERROR"
                ? "error"
                : text === "PROCESSED"
                ? "geekblue"
                : text === "RECEIVED"
                ? "green"
                : text === "INPROGRESS"
                ? "warning"
                : "purple"
            }
            className="fw-bold my-1"
          >
            {text}
          </Tag>
        );
      },
    },
    {
      title: "Item Data Error Count",
      dataIndex: "item_data_error_count",
      key: "item_data_error_count",
      render: (text) => <span>{text || "0"}</span>,
    },
    {
      title: "Items Failed",
      dataIndex: "items_failed",
      key: "items_failed",
      render: (text) => <span>{text || "0"}</span>,
    },
    {
      title: "Items In Review Count",
      dataIndex: "items_in_review_count",
      key: "items_in_review_count",
      render: (text) => <span>{text || "0"}</span>,
    },
    {
      title: "Items Processing",
      dataIndex: "items_processing",
      key: "items_processing",
      render: (text) => <span>{text || "0"}</span>,
    },
    {
      title: "Items Received",
      dataIndex: "items_received",
      key: "items_received",
      render: (text) => <span>{text || "0"}</span>,
    },
    {
      title: "Items Succeeded",
      dataIndex: "items_succeeded",
      key: "items_succeeded",
      render: (text) => <span>{text || "0"}</span>,
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => (
        <span>{moment(new Date(text * 1000)).format("MMM DD, YYYY")}</span>
      ),
    },
    {
      title: "Updated At",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (text) => (
        <span>{moment(new Date(text * 1000)).format("MMM DD, YYYY")}</span>
      ),
    },
    // {
    //   title: "Marketplace",
    //   dataIndex: "marketplace",
    //   key: "marketplace",
    //   render: (text) => <span>{text || "-"}</span>,
    // },
    // {
    //   title: "GTIN",
    //   dataIndex: "gtin",
    //   key: "gtin",
    //   render: (text) => <span>{text || "-"}</span>,
    // },
    // {
    //   title: "UPC",
    //   dataIndex: "upc",
    //   key: "upc",
    //   render: (text) => <span>{text || "-"}</span>,
    // },
    // {
    //   title: "Marketplace Id",
    //   dataIndex: "marketplace_id",
    //   key: "marketplace_id",
    //   render: (text) => <span>{text || "-"}</span>,
    // },
  ];

  const onWalmartPageNo = (e) => {
    setWalmartLoading(true);
    const apiObj = {
      page: e,
      perPage: walmartPageSize,
      sales_channel: selectedSalesChannel,
      search: searchTerm,
      feedType: feedType,
      feedStatus: feedStatus,
    };
    GetWalmartItemFeed(apiObj);
    setWalmartPage(e);
  };

  const onWalmartPerPage = (e) => {
    setWalmartPage(1);
    setWalmartPageSize(e);
    setWalmartLoading(true);
    const apiObj = {
      page: 1,
      perPage: e,
      sales_channel: selectedSalesChannel,
      search: searchTerm,
      feedType: feedType,
      feedStatus: feedStatus,
    };
    GetWalmartItemFeed(apiObj);
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  // const items = [
  //   {
  //     label: (
  //       <span
  //         onClick={() => {
  //           setIsOpenImportModal(true);
  //         }}
  //       >
  //         Import File
  //       </span>
  //     ),
  //     key: "1",
  //   },
  //   {
  //     label: (
  //       <span
  //         onClick={() => {
  //           setIsOpenCreateModal(true);
  //         }}
  //       >
  //         Add Manually
  //       </span>
  //     ),
  //     key: "2",
  //   },
  // ];

  const items = [
    {
      label: (
        <div
          onClick={() => {
            message.destroy();
            if (selectedRowKeys?.length == 0) {
              return message.info("Please select feed...");
            }
            // else {
            //   message.success("Done");
            // }
          }}
        >
          Price
        </div>
      ),
      key: "1",
    },
    {
      label: (
        <div
          onClick={() => {
            message.destroy();
            if (selectedRowKeys?.length == 0) {
              return message.info("Please select feed...");
            }
            // else {
            //   message.success("Done");
            // }
          }}
        >
          Retired Item
        </div>
      ),
      key: "2",
    },
    {
      label: (
        <div
          onClick={() => {
            message.destroy();
            if (selectedRowKeys?.length == 0) {
              return message.info("Please select feed...");
            }
            // else {
            //   message.success("Done");
            // }
          }}
        >
          Varient Item
        </div>
      ),
      key: "3",
    },
    // {
    //   label: (
    //     <span
    //     // onClick={() => {
    //     //   setIsOpenImportModal(true);
    //     // }}
    //     >
    //       Update Items
    //     </span>
    //   ),
    //   key: "2",
    // },
    // {
    //   label: (
    //     <span
    //       onClick={() => {
    //         setIsOpenImportModalParshv(true);
    //       }}
    //     >
    //       Test Parshv
    //     </span>
    //   ),
    //   key: "2",
    // },
  ];

  return (
    <div className="fadeInRight">
      <>
        <div className="d-flex flex-column flex-column-fluid">
          <div id="kt_app_content" className="app-content flex-column-fluid">
            <div
              id="kt_app_content_container"
              className="app-container container-fluid"
            >
              {/*begin::Row*/}

              <div className="row gy-5 g-xl-5">
                {/*begin::Col*/}
                <div className="col-xxl-12">
                  {/*begin::Tables Widget 9*/}
                  <div className="card card-xxl-stretch mb-5 mb-xl-8">
                    {/*begin::Header*/}
                    <div className="card-header border-bottom-dashed">
                      {/*end::Title*/}
                      {/*begin::Title*/}
                      <h3 className="card-title ">
                        {/* <span className="card-label fw-bold text-gray-900"></span> */}
                        <Input
                          type="text"
                          className="text-dark w-250px fs-7 fw-bold ant_common_input"
                          name="all_search"
                          placeholder="Search by Feed Id..."
                          value={searchTerm}
                          size="large"
                          onChange={(e) => {
                            setSearchTerm(e.target.value);
                          }}
                          onPressEnter={(e) => {
                            setWalmartPage(1);
                            const apiObj = {
                              page: 1,
                              perPage: walmartPageSize,
                              sales_channel: "Amazon.com",
                              search: e.target.value,
                              feedType: feedType,
                              feedStatus: feedStatus,
                            };
                            setWalmartLoading(true);
                            GetWalmartItemFeed(apiObj);
                          }}
                          prefix={
                            <SearchOutlined
                              style={{ fontSize: "19px", color: "grey" }}
                            />
                          }
                        />
                      </h3>
                      {/*end::Title*/}
                      <div className="card-toolbar gap-2">
                        <div className="position-relative">
                          <Select
                            options={[
                              {
                                label: "MP_ITEM",
                                value: "MP_ITEM",
                              },
                              {
                                label: "MP_INVENTORY",
                                value: "MP_INVENTORY",
                              },
                              {
                                label: "MP_ITEM_MATCH",
                                value: "MP_ITEM_MATCH",
                              },
                              {
                                label: "RETURNS_OVERRIDES",
                                value: "RETURNS_OVERRIDES",
                              },
                            ]}
                            placeholder="Select Feed Type"
                            size="large"
                            className="w-200px"
                            allowClear
                            value={feedType}
                            onChange={(e) => {
                              setFeedType(e);
                              setWalmartPage(1);
                              const apiObj = {
                                page: 1,
                                perPage: walmartPageSize,
                                sales_channel: "Amazon.com",
                                search: searchTerm,
                                feedType: e,
                                feedStatus: feedStatus,
                              };
                              setWalmartLoading(true);
                              GetWalmartItemFeed(apiObj);
                            }}
                          />
                        </div>
                        <div className="position-relative">
                          <Select
                            options={[
                              {
                                label: "RECEIVED",
                                value: "RECEIVED",
                              },
                              {
                                label: "PROCESSED",
                                value: "PROCESSED",
                              },
                              {
                                label: "INPROGRESS",
                                value: "INPROGRESS",
                              },
                              {
                                label: "ERROR",
                                value: "ERROR",
                              },
                            ]}
                            placeholder="Select Feed Status"
                            size="large"
                            className="w-200px"
                            allowClear
                            value={feedStatus}
                            onChange={(e) => {
                              setFeedStatus(e);
                              setWalmartPage(1);
                              const apiObj = {
                                page: 1,
                                perPage: walmartPageSize,
                                sales_channel: "Amazon.com",
                                search: searchTerm,
                                feedType: feedType,
                                feedStatus: e,
                              };
                              setWalmartLoading(true);
                              GetWalmartItemFeed(apiObj);
                            }}
                          />
                        </div>
                        {/* <div className="position-relative">
                          <Select
                            className="w-200px"
                            size="large"
                            placeholder="Select Sales Channel"
                            loading={salesChannelLoading}
                            value={selectedSalesChannelId || null}
                            onChange={(e, _) => {
                              setSelectedSalesChannelId(e);
                              setSelectedSalesChannel(_?.sales_channel);
                              setWalmartPage(1);
                              setWalmartPageSize(DefaultPerPage);
                              setWalmartLoading(true);
                              const apiObj = {
                                page: 1,
                                perPage: walmartPageSize,
                                filter: filter,
                                sales_channel: _?.sales_channel,
                              };
                              GetWalmartItemFeed(apiObj);
                            }}
                            options={SalesChannelFlag(salesChannelList)}
                          />
                        </div> */}
                      </div>
                    </div>
                    {/*end::Header*/}
                    {/*begin::Body*/}
                    <div className="card-body pb-5 pt-0">
                      {/*begin::Table container*/}
                      <div className="table-responsive mt-5">
                        {walmartLoading ? (
                          <Loading />
                        ) : walmartList?.length !== 0 ? (
                          <>
                            <IvcsrTable
                              columns={walmartColumns?.map((d) => ({
                                ...d,
                                width:
                                  d?.title === "Feed Id"
                                    ? 300
                                    : d?.title?.length * 13,
                              }))}
                              sticky={{
                                offsetHeader: "10px",
                              }}
                              scroll={{
                                x: "max-content",
                                y: "calc(100vh - 200px)",
                              }}
                              fixed="top"
                              dataSource={walmartList}
                              rowKey="key"
                              loading={walmartLoading}
                              // isCheckBoxRow={true}
                              // rowSelection={rowSelection}
                              pagination={false}
                            />
                          </>
                        ) : (
                          <NoData type="new" />
                        )}
                      </div>
                      <Pagination
                        loading={walmartLoading || walmartList?.length === 0}
                        pageSize={walmartPageSize}
                        page={walmartPage}
                        totalPage={walmartTotalPage}
                        onPerPage={onWalmartPerPage}
                        onPageNo={onWalmartPageNo}
                      />
                    </div>
                    {/*begin::Body*/}
                  </div>
                  {/*end::Tables Widget 9*/}
                </div>
                {/*end::Col*/}
              </div>

              {/*end::Row*/}
            </div>
          </div>
        </div>
      </>
      {/* {isOpenCreateModal && (
        <CreateItemModal
          show={isOpenCreateModal}
          onHide={() => {
            setIsOpenCreateModal(false);
          }}
          {...props}
        />
      )} */}

      {/* {isOpenImportModal && (
        <ImportItemModal
          show={isOpenImportModal}
          onHide={() => {
            setIsOpenImportModal(false);
          }}
        />
      )} */}
      {/* {isOpenBulkUploadModal && (
        <BulkUploadModal
          show={isOpenBulkUploadModal}
          onHide={() => {
            setIsOpenBulkUploadModal(false);
          }}
        />
      )} */}

      {/* {isOpenImportModalParshv && (
        <ImportItemModal_parshv
          show={isOpenImportModalParshv}
          onHide={() => {
            setIsOpenImportModalParshv(false);
          }}
        />
      )} */}
    </div>
  );
}
