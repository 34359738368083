import { Checkbox, Input, message } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Error from "../../../../../component/error";
import NoData from "../../../../../component/no-data";
import ReturnConfig from "./lib/return-config";
import OrderConfig from "./lib/order-config";

export default function (props) {
  const { SaveUserProfile, fakeActionuserProfile } = props;
  const [selectedTab, setSelectedTab] = useState("order");
  const [isOptionChecked, setIsOptionsChecked] = useState({
    initiate: true,
    delivered: true,
  });
  return (
    <div className="d-flex flex-column flex-column-fluid">
      <div id="kt_app_content" className="app-content flex-column-fluid">
        <div
          id="kt_app_content_container"
          className="app-container container-fluid"
        >
          <div className="row">
            <div className="col-md-12 cus-tab">
              <ul className="nav nav-pills mb-5 fs-6">
                <li className="nav-item">
                  <span
                    className={`nav-link ${
                      selectedTab === "returns" ? "active" : ""
                    } fs-7 fw-bold py-3 px-7 me-2 bg-white`}
                    onClick={() => {
                      setSelectedTab("returns");
                    }}
                  >
                    Return Center Address Details
                  </span>
                </li>
                <li className="nav-item">
                  <span
                    className={`nav-link ${
                      selectedTab === "order" ? "active" : ""
                    } fs-7 fw-bold py-3 px-7 me-2 bg-white`}
                    onClick={() => {
                      setSelectedTab("order");
                    }}
                  >
                    Order Refund Details
                  </span>
                </li>
              </ul>
            </div>
          </div>

          <div className="tab-content" id="myTabContent">
            <div
              className={`tab-pane ${
                selectedTab === "returns" ? " show active" : ""
              } fade`}
              role="tabpanel"
            >
              <div className="row gy-5 g-xl-5 fadeInRight">
                <div className="col-xxl-12">
                  <ReturnConfig {...props} />
                </div>
              </div>
            </div>

            <div
              className={`tab-pane ${
                selectedTab === "order" ? " show active" : ""
              } fade`}
              role="tabpanel"
            >
              <div className="row gy-5 g-xl-5 fadeInRight">
                <div className="col-xxl-12">
                  <OrderConfig {...props} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
