import React from "react";
import { NumberWithCommas } from "../../../../config";

export default function (props) {
  const { salesAnalyticsCardData, cardLoading } = props;
  return (
    <>
      <div
        className="row g-5 g-xl-7 mb-5 mb-xl-10"
        style={{ filter: cardLoading ? "blur(6px)" : "blur(0px)" }}
      >
        {/*begin::Col*/}
        <div className="col-xl-3">
          {/*begin::Card widget 3*/}
          <div
            className="card card-flush bgi-no-repeat bgi-size-cover bgi-position-x-end h-xl-100"
            style={{
              backgroundColor: "#f1577d",
              backgroundImage:
                'url("assets/media/svg/shapes/wave-bg-light.svg")',
            }}
          >
            {/*begin::Card body*/}
            <div className="card-body py-4">
              <div className=" d-flex align-items-center justify-content-between">
                {/*begin::Icon*/}
                <div
                  className="d-flex flex-center rounded-circle h-60px w-60px"
                  style={{
                    border: "1px dashed rgba(255, 255, 255, 0.5)",
                    backgroundColor: "#ffffff2e",
                  }}
                >
                  <i className="ki-duotone ki-chart-simple-3 text-white fs-3x lh-0">
                    <span className="path1" />
                    <span className="path2" />
                    <span className="path3" />
                    <span className="path4" />
                    <span className="path5" />
                    <span className="path6" />
                    <span className="path7" />
                  </i>
                </div>
                {/*end::Icon*/}
                <div className=" text-end">
                  <span className="fs-1-5 text-white fw-bold">
                    <small className="fs-2 me-1">$</small>
                    {NumberWithCommas(salesAnalyticsCardData?.total_sales || 0)}
                  </span>
                  <div className="fw-bold fs-6 text-white ">
                    <span className="d-block">Total Sales</span>
                  </div>
                </div>
              </div>

              {/* <div class="d-flex flex-column w-100 me-2">
														<div class="d-flex flex-stack mb-2">
															<span class="text-muted me-2 fs-7 fw-bold">
																70%
															</span>
														</div>
														
														<div class="progress h-6px w-100">
															<div class="progress-bar bg-danger" role="progressbar" style="width: 70%" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100"></div>
														</div>
													</div> */}
            </div>
            {/*end::Card body*/}
            {/*begin::Card footer*/}
            <div
              className="card-footer py-2"
              style={{
                borderTop: "1px solid rgba(255, 255, 255, 0.3)",
                background: "rgba(255, 255, 255, 0.15)",
              }}
            >
              {/*begin::Progress*/}
              {/* <p className="mb-0 text-white d-flex align-items-center">
                <i className="ki-outline ki-arrow-up fs-3 text-light " />
                <span className="fs-5 fw-bolder ms-1 me-2">+5.2% </span>
                <span className="opacity-75">Since last month</span>
              </p> */}
              {/*end::Progress*/}
            </div>
            {/*end::Card footer*/}
          </div>
          {/*end::Card widget 3*/}
        </div>
        {/*end::Col*/}
        {/*begin::Col*/}
        <div className="col-xl-3">
          {/*begin::Card widget 3*/}
          <div
            className="card card-flush bgi-no-repeat bgi-size-cover bgi-position-x-end h-xl-100"
            style={{
              backgroundColor: "#7f68ff",
              backgroundImage:
                'url("assets/media/svg/shapes/wave-bg-light.svg")',
            }}
          >
            {/*begin::Card body*/}
            <div className="card-body py-4">
              <div className=" d-flex align-items-center justify-content-between">
                {/*begin::Icon*/}
                <div
                  className="d-flex flex-center rounded-circle h-60px w-60px"
                  style={{
                    border: "1px dashed rgba(255, 255, 255, 0.5)",
                    backgroundColor: "#ffffff2e",
                  }}
                >
                  <i className="ki-duotone ki-tag text-white fs-3x lh-0">
                    <span className="path1" />
                    <span className="path2" />
                    <span className="path3" />
                  </i>
                </div>
                {/*end::Icon*/}
                <div className=" text-end">
                  <span className="fs-1-5 text-white fw-bold">
                    <small className="fs-2 me-1">$</small>{" "}
                    {NumberWithCommas(salesAnalyticsCardData?.net_profit || 0)}
                  </span>
                  <div className="fw-bold fs-6 text-white ">
                    <span className="d-block">Net Profit</span>
                  </div>
                </div>
              </div>
            </div>
            {/*end::Card body*/}
            {/*begin::Card footer*/}
            <div
              className="card-footer py-2"
              style={{
                borderTop: "1px solid rgba(255, 255, 255, 0.3)",
                background: "rgba(0, 0, 0, 0.15)",
              }}
            >
              {/*begin::Progress*/}
              {/* <p className="mb-0 text-white d-flex align-items-center">
                <i className="ki-outline ki-arrow-up fs-3 text-light " />
                <span className="fs-5 fw-bolder ms-1 me-2">+5.2% </span>
                <span className="opacity-75">Since last month</span>
              </p> */}
              {/*end::Progress*/}
            </div>
            {/*end::Card footer*/}
          </div>
          {/*end::Card widget 3*/}
        </div>
        {/*end::Col*/}
        {/*begin::Col*/}
        <div className="col-xl-3">
          {/*begin::Card widget 3*/}
          <div
            className="card card-flush bgi-no-repeat bgi-size-cover bgi-position-x-end h-xl-100"
            style={{
              backgroundColor: "#4caf50",
              backgroundImage:
                'url("assets/media/svg/shapes/wave-bg-light.svg")',
            }}
          >
            {/*begin::Card body*/}
            <div className="card-body py-4">
              <div className=" d-flex align-items-center justify-content-between">
                {/*begin::Icon*/}
                <div
                  className="d-flex flex-center rounded-circle h-60px w-60px"
                  style={{
                    border: "1px dashed rgba(255, 255, 255, 0.5)",
                    backgroundColor: "#ffffff2e",
                  }}
                >
                  <i className="ki-duotone ki-percentage text-white fs-3x lh-0">
                    <span className="path1" />
                    <span className="path2" />
                  </i>
                </div>
                {/*end::Icon*/}
                <div className=" text-end">
                  <span className="fs-1-5 text-white fw-bold">
                    {NumberWithCommas(salesAnalyticsCardData?.profit_margin) ||
                      0}
                    %
                  </span>
                  <div className="fw-bold fs-6 text-white ">
                    <span className="d-block">Profit Margin</span>
                  </div>
                </div>
              </div>
            </div>
            {/*end::Card body*/}
            {/*begin::Card footer*/}
            <div
              className="card-footer py-2"
              style={{
                borderTop: "1px solid rgba(255, 255, 255, 0.3)",
                background: "rgba(0, 0, 0, 0.15)",
              }}
            >
              {/*begin::Progress*/}
              {/* <p className="mb-0 text-white d-flex align-items-center">
                <i className="ki-outline ki-arrow-up fs-3 text-light " />
                <span className="fs-5 fw-bolder ms-1 me-2">+5.2% </span>
                <span className="opacity-75">Since last month</span>
              </p> */}
              {/*end::Progress*/}
            </div>
            {/*end::Card footer*/}
          </div>
          {/*end::Card widget 3*/}
        </div>
        {/*end::Col*/}
        {/*begin::Col*/}
        <div className="col-xl-3">
          {/*begin::Card widget 3*/}
          <div
            className="card card-flush bgi-no-repeat bgi-size-cover bgi-position-x-end h-xl-100"
            style={{
              backgroundColor: "#c89851",
              backgroundImage:
                'url("assets/media/svg/shapes/wave-bg-light.svg")',
            }}
          >
            {/*begin::Card body*/}
            <div className="card-body py-4">
              <div className=" d-flex align-items-center justify-content-between">
                {/*begin::Icon*/}
                <div
                  className="d-flex flex-center rounded-circle h-60px w-60px"
                  style={{
                    border: "1px dashed rgba(255, 255, 255, 0.5)",
                    backgroundColor: "#ffffff2e",
                  }}
                >
                  <i className="ki-duotone ki-delivery-3 text-white fs-3x lh-0">
                    <span className="path1" />
                    <span className="path2" />
                    <span className="path3" />
                  </i>
                </div>
                {/*end::Icon*/}
                <div className=" text-end">
                  <span className="fs-1-5 text-white fw-bold">
                    {NumberWithCommas(salesAnalyticsCardData?.total_units || 0)}
                  </span>
                  <div className="fw-bold fs-6 text-white ">
                    <span className="d-block">Total Units Sold</span>
                  </div>
                </div>
              </div>
            </div>
            {/*end::Card body*/}
            {/*begin::Card footer*/}
            <div
              className="card-footer py-2"
              style={{
                borderTop: "1px solid rgba(255, 255, 255, 0.3)",
                background: "rgba(0, 0, 0, 0.15)",
              }}
            >
              {/*begin::Progress*/}
              {/* <p className="mb-0 text-white d-flex align-items-center">
                <i className="ki-outline ki-arrow-up fs-3 text-light " />
                <span className="fs-5 fw-bolder ms-1 me-2">+5.2% </span>
                <span className="opacity-75">Since last month</span>
              </p> */}
              {/*end::Progress*/}
            </div>
            {/*end::Card footer*/}
          </div>
          {/*end::Card widget 3*/}
        </div>
        {/*end::Col*/}
      </div>
    </>
  );
}
