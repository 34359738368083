import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import WamlartReturnsRefunds from "../../../modules/pages/order-management/returns-refunds";

import {
  GetSalesChannelList,
  fakeActionSalesAnalytics,
} from "../../../redux/modules/sales-analytics/sales-analytics.action";
import {
  GetReturnsAndRefundsOrder,
  WalmartReturnRefundOrder,
  GetDirectWalmartRefundAction,
  fakeActionReturnsRefunds,
} from "../../../redux/modules/order-management/returns-refunds/returns-refunds.action";

const mapStateToProps = (state) => ({
  GetReturnsAndRefundsOrderResponse:
    state.WalmartReturnsRefunds.GetReturnsAndRefundsOrderResponse,
  GetDirectWalmartRefundsResponse:
    state.WalmartReturnsRefunds.GetDirectWalmartRefundsResponse,
  WalmartReturnsAndRefundsOrderResponse:
    state.WalmartReturnsRefunds.WalmartReturnsAndRefundsOrderResponse,

  GetSalesChannelListResponse: state.SalesAnalytics.GetSalesChannelListResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      GetReturnsAndRefundsOrder,
      WalmartReturnRefundOrder,
      GetDirectWalmartRefundAction,
      fakeActionReturnsRefunds,

      GetSalesChannelList,
      fakeActionSalesAnalytics,
    },
    dispatch
  );

const WamlartReturnsRefundsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(WamlartReturnsRefunds);

export default WamlartReturnsRefundsContainer;
