import { Alert, Checkbox, Input, Select, message } from "antd";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { SalesChannelFlag } from "../../../../../config";
import { Link } from "react-router-dom";
export default function (props) {
  const {
    show,
    onHide,
    StoreWMMarketplaceAction,
    fakeActionMarketplaceCred,
    onSuccess,
  } = props;
  const [seller_name, setSeller_name] = useState(
    JSON.parse(localStorage.getItem("user"))?.seller_name
  );
  const [client_id, setClient_id] = useState("");
  const [client_secret, setClient_secret] = useState("");
  const [client_marketplace, setClient_marketplace] = useState(null);

  const StoreWalMartMarketplaceRes = useSelector(
    (state) => state.MarketplaceCredential.StoreWalMartMarketplaceResponse || {}
  );

  useEffect(() => {
    if (StoreWalMartMarketplaceRes.status === true) {
      message.destroy();
      message.success(StoreWalMartMarketplaceRes?.message);
      blankAllInput();
      onSuccess();
      fakeActionMarketplaceCred("StoreWalMartMarketplaceResponse");
    } else if (StoreWalMartMarketplaceRes.status === false) {
      message.destroy();
      message.error(StoreWalMartMarketplaceRes?.message);
      fakeActionMarketplaceCred("StoreWalMartMarketplaceResponse");
    }
  }, [StoreWalMartMarketplaceRes]);

  const blankAllInput = () => {
    setClient_id("");
    setClient_secret("");
    setClient_marketplace(null);
  };
  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          onHide();
        }}
        size="md"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Walmart Marketplace Credential</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <div className="row">
              <div className="col-12">
                <div>
                  <label className="fw-bold fs-6">Seller Name</label>
                </div>
                <div>
                  <Input
                    value={seller_name}
                    onChange={(e) => {
                      setSeller_name(e.target.value);
                    }}
                    size="large"
                    className="ant_common_input"
                  />
                </div>
              </div>
              <div className="col-12  mt-5">
                <div>
                  <label className="fw-bold fs-6">Client Id</label>
                </div>
                <div>
                  <Input
                    value={client_id}
                    onChange={(e) => {
                      setClient_id(e.target.value);
                    }}
                    size="large"
                    className="ant_common_input"
                  />
                </div>
              </div>

              <div className="col-12 mt-5">
                <div>
                  <label className="fw-bold fs-6">Secret Id</label>
                </div>
                <div>
                  <Input
                    value={client_secret}
                    onChange={(e) => {
                      setClient_secret(e.target.value);
                    }}
                    size="large"
                    className="ant_common_input"
                  />
                </div>
              </div>

              <div className="col-12 mt-5">
                <div>
                  <label className="fw-bold fs-6">Marketplace</label>
                </div>
                <div>
                  <Select
                    size="large"
                    style={{ width: "100%" }}
                    placeholder="Select Marketplace"
                    options={[
                      { label: "Canada", value: "A2EUQ1WTGCTBG2" },
                      { label: "USA", value: "ATVPDKIKX0DER" },
                      { label: "Mexico", value: "A1AM78C64UM0Y8" },
                    ]}
                    onChange={(e) => {
                      setClient_marketplace(e);
                    }}
                    value={client_marketplace}
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  />
                </div>
              </div>
            </div>
          </>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-light-danger"
            onClick={() => {
              blankAllInput();
              onHide();
            }}
          >
            Close
          </button>

          <button
            type="button"
            className="btn btn-primary"
            onClick={() => {
              // onHide();
              if (
                !seller_name ||
                !client_id ||
                !client_secret ||
                !client_marketplace
              ) {
                message.destroy();
                message.warning("Please Enter Value");
              } else {
                const Obj = {
                  client_id: client_id,
                  client_secret: client_secret,
                  marketplace_id: client_marketplace,
                  seller_name: seller_name,
                };
                message.loading("Loading...", 0);
                StoreWMMarketplaceAction(Obj);
              }
            }}
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
