import axiosCall from "../../../configurations/network-services/axiosCall";

// const prefix = "dashboard";

export const GetFeedItemsLogList = (data) => {
  const path = `wm/feed-items?page=${data?.page || 1}&per-page=${
    data?.perPage || 10
  }&search_key=${data?.search_by || ""}`;
  const responseType = "GET_FEED_ITEMS_LOG_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const fakeActionFeesItemsLog = (data) => async (dispatch) => {
  dispatch({ type: "FAKE_ACTION_FEED_ITEMS_LOG", state: data });
};
