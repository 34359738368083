import { Progress } from "antd";
import React from "react";

const CustomProgress = (props) => {
  const { data } = props;

  const findColors = {
    DONE: "#3fb73f",
    NOT_FOUND: "#7288e0",
    FAILED: "#e56161",
    PENDING: "#ffd501",
  };

  const resultObject = Object.fromEntries(
    Object.entries(data).map(([key, value]) => [
      `${value.score_pect}%`,
      findColors[key],
    ])
  );

  const sort = Object.entries(resultObject).sort(
    ([keyA], [keyB]) => parseInt(keyA) - parseInt(keyB)
  );
  const newObject = Object.fromEntries(
    sort?.map(([key, value], i, arr) => [
      `${Math.min(parseInt(key) + 1, 100)}%`,
      arr[i + 1]?.[1] || value,
    ])
  );
  const minColor = sort?.[0]?.[1];
  return (
    <>
      <Progress
        percent={100}
        strokeColor={{ ["0%"]: minColor, ...resultObject, ...newObject }}
      />
    </>
  );
};

export default CustomProgress;
