import { Alert, DatePicker, Input, Select, message } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import RangePickerIVCSR from "../../../../../component/range-picker";
import { DateFormat } from "../../../../../config";
import { Link } from "react-router-dom";
export default function ({ show, onHide, selectedReport }) {
  const [filters, setFilters] = useState({
    start_date: dayjs().add(-7, "d"),
    end_date: dayjs(),
  });

  return (
    <>
      <Modal show={show} onHide={onHide} size="lg" centered backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>View {selectedReport}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            {/*begin::Scroll*/}
            {/*end::Input group*/}
            <div className="row g-9 mb-5 align-items-center">
              <div className="col-md-4 fv-row">
                {/* <label class=" fs-6 fw-bold mb-2">Select Daterange</label> */}
              </div>
              <div className="col-md-8 fv-row">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    defaultValue=""
                    id="flexCheckDefault"
                  />
                  <label
                    className="form-check-label fs-6 text-dark fw-bold"
                    htmlFor="flexCheckDefault"
                  >
                    Send report over an email
                  </label>
                </div>
              </div>
            </div>
            {/*end::Input group*/}
            {/*end::Input group*/}
            <div className="row g-9 mb-5 align-items-center">
              <div className="col-md-4 fv-row">
                <label className=" fs-6 fw-bold mb-2">Recipients</label>
              </div>
              <div className="col-md-8 fv-row">
                <div className=" mt-3">
                  <Input
                    size="large"
                    className="ant_common_input"
                    placeholder="Enter Email"
                    id=""
                  />
                </div>
              </div>
            </div>
            {/*end::Input group*/}
            {/*end::Input group*/}
            {/* <div
              className="row g-9 mb-5 align-items-center"
              data-select2-id="select2-data-7-j8gg"
            >
              <div className="col-md-4 fv-row">
                <label className=" fs-6 fw-bold mb-2">Request Time</label>
              </div>
              <div
                className="col-md-8 fv-row"
                data-select2-id="select2-data-6-9vel"
              >
                <Select
                  size="large"
                  style={{ width: "100%" }}
                  options={[
                    { label: "Now", value: "Now" },
                    { label: "Daily", value: "Daily" },
                    { label: "Weekly", value: "Weekly" },
                    { label: "Monthly", value: "Monthly" },
                  ]}
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  placeholder="Select"
                />

                <div className=" mt-3">
                  <DatePicker
                    onChange={() => {}}
                    showTime
                    style={{ width: "100%" }}
                    size="large"
                    className="ant_common_input"
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  />
                </div>
              </div>
            </div>

            <div className="row g-9 mb-5 align-items-center">
              <div className="col-md-4 fv-row">
                <label className=" fs-6 fw-bold mb-2">End Date</label>
              </div>
              <div className="col-md-8 fv-row">
                <DatePicker
                  onChange={() => {}}
                  style={{ width: "100%" }}
                  size="large"
                  className="ant_common_input"
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                />
              </div>
            </div> */}
          </>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-light-danger"
            onClick={onHide}
          >
            Close
          </button>

          <button type="button" className="btn btn-primary" onClick={onHide}>
            Download & Send
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
