import { Checkbox, Input, message } from "antd";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
export default function (props) {
  const {
    show,
    onHide,
    selectedCheckbox,
    marketplace_id,
    onSuccess,
    SaveBulkInventoryAction,
    fakeActionInventoryManagement,
  } = props;

  const [bulkObject, setBulkObject] = useState({
    manufacturing_time_days: 0,
    i_use_prep_center: 0,
    shipping_prep_center_days: 0,
    shipping_fba_days: 0,
  });

  const SaveBulkInventoryRes = useSelector(
    (state) => state.InventoryManagement.SaveBulkInventoryResponse || {}
  );

  useEffect(() => {
    if (SaveBulkInventoryRes?.status === true) {
      message.destroy();
      message.success(SaveBulkInventoryRes?.message);
      onSuccess();
      fakeActionInventoryManagement("SaveBulkInventoryResponse");
    } else if (SaveBulkInventoryRes?.status === false) {
      message.destroy();
      message.error(SaveBulkInventoryRes.message);
      fakeActionInventoryManagement("SaveBulkInventoryResponse");
    }
  }, [SaveBulkInventoryRes]);
  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          onHide();
        }}
        size="lg"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Lead Time</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <div>
              <div className="mb-5 d-flex justify-content-between">
                <label className="fw-bold fs-6">
                  Manufacturing time (days)
                </label>
                <Input
                  className="ant_common_input me-5"
                  placeholder="Manufacturing time (days)"
                  size="large"
                  style={{
                    width: "230px",
                  }}
                  value={bulkObject?.manufacturing_time_days}
                  onChange={(e) => {
                    if (!e.target.value) {
                      const newObj = { ...bulkObject };
                      newObj.manufacturing_time_days = e.target.value;
                      setBulkObject(newObj);
                    }
                    const newVal = e.target.value.split(".").join("");
                    const validation = /^[0-9]+$/.test(newVal);
                    if (validation) {
                      const newObj = { ...bulkObject };
                      newObj.manufacturing_time_days = e.target.value;
                      setBulkObject(newObj);
                    }
                  }}
                />
              </div>
              <div className="mb-10 d-flex justify-content-between">
                <label className="fw-bold fs-6">I use a Prep Center</label>
                <div
                  style={{
                    width: "245px",
                  }}
                >
                  <Checkbox
                    checked={bulkObject?.i_use_prep_center == 1 ? true : false}
                    onChange={(e) => {
                      const newObj = { ...bulkObject };
                      newObj.i_use_prep_center = e.target.checked ? 1 : 0;
                      newObj.shipping_prep_center_days = 0;
                      setBulkObject(newObj);
                    }}
                  ></Checkbox>
                </div>
              </div>

              <div className="mb-5 d-flex justify-content-between">
                <label className="fw-bold fs-6">
                  Shipping to Prep Center (days)
                </label>
                <Input
                  className="ant_common_input me-5"
                  placeholder="Shipping to Prep Center (days)"
                  size="large"
                  style={{
                    width: "230px",
                  }}
                  value={bulkObject?.shipping_prep_center_days}
                  onChange={(e) => {
                    if (!e.target.value) {
                      const newObj = { ...bulkObject };
                      newObj.shipping_prep_center_days = e.target.value;
                      setBulkObject(newObj);
                    }
                    const newVal = e.target.value.split(".").join("");
                    const validation = /^[0-9]+$/.test(newVal);
                    if (validation) {
                      const newObj = { ...bulkObject };
                      newObj.shipping_prep_center_days = e.target.value;
                      setBulkObject(newObj);
                    }
                  }}
                  onBlur={(e) => {
                    if (!e.target.value) {
                      const newObj = { ...bulkObject };
                      newObj.shipping_prep_center_days = 0;
                      setBulkObject(newObj);
                    }
                  }}
                  disabled={bulkObject?.i_use_prep_center == 0}
                />
              </div>
              <div className="mb-5 d-flex justify-content-between">
                <label className="fw-bold fs-6">Shipping to FBA (days)</label>
                <Input
                  className="ant_common_input me-5"
                  placeholder="Shipping to FBA (days)"
                  size="large"
                  style={{
                    width: "230px",
                  }}
                  value={bulkObject?.shipping_fba_days}
                  onChange={(e) => {
                    if (!e.target.value) {
                      const newObj = { ...bulkObject };
                      newObj.shipping_fba_days = e.target.value;
                      setBulkObject(newObj);
                    }
                    const newVal = e.target.value.split(".").join("");
                    const validation = /^[0-9]+$/.test(newVal);
                    if (validation) {
                      const newObj = { ...bulkObject };
                      newObj.shipping_fba_days = e.target.value;
                      setBulkObject(newObj);
                    }
                  }}
                />
              </div>
            </div>
          </>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <button
              className="btn btn-light-danger me-2"
              onClick={() => {
                onHide();
              }}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary"
              onClick={() => {
                message.loading("Loading...", 0);
                const Obj = {
                  skus: selectedCheckbox.join(","),
                  i_use_prep_center: bulkObject?.i_use_prep_center,
                  manufacturing_time_days: bulkObject?.manufacturing_time_days,
                  shipping_prep_center_days:
                    bulkObject?.shipping_prep_center_days,
                  shipping_fba_days: bulkObject?.shipping_fba_days,
                  marketplace_id: marketplace_id,
                };

                SaveBulkInventoryAction(Obj);
              }}
            >
              Save
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
