import React, { useContext, useEffect } from "react";
import { GlobalContext } from "../../../commonContext";
import AmazonProductListng from "./amazon-product-listing";
import UnderConstruction from "../../../component/under-construction/under-construction";
import WalmartProductListing from "./walmart-product-listing";
export default function (props) {
  const contextVar = useContext(GlobalContext);

  return contextVar?.data?.marketplaceType === "amazon" ? (
    <AmazonProductListng {...props} />
  ) : (
    <WalmartProductListing {...props} />
  );
}
