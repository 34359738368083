export default (state, action) => {
  if (typeof state === "undefined") {
    return [];
  }
  switch (action.type) {
    case "GET_RETURN_CENTER_ADDRESS_SUCCESS":
    case "GET_RETURN_CENTER_ADDRESS_ERROR":
      return {
        ...state,
        GetReturnCenterAddrResponse: action.updatePayload,
      };

    case "DELETE_RETURN_CENTER_ADDRESS_SUCCESS":
    case "DELETE_RETURN_CENTER_ADDRESS_ERROR":
      return {
        ...state,
        DeleteReturnCenterAddrResponse: action.updatePayload,
      };

    case "SAVE_RETURN_CENTER_ADDR_SUCCESS":
    case "SAVE_RETURN_CENTER_ADDR_ERROR":
      return {
        ...state,
        SaveReturnCenterAddrResponse: action.updatePayload,
      };

    case "FAKE_ACTION_CONFIGURATION":
      return { ...state, [action?.state]: {} };
    default:
      return state;
  }
};
