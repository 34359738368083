import { Input, message } from "antd";
import React, { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
export default function (props) {
  const {
    show,
    onHide,
    selectedCheckbox,
    marketplace_id,
    onSuccess,
    SaveBulkInventoryAction,
    fakeActionInventoryManagement,
  } = props;

  const [loading, setLoading] = useState(false);
  const [bulkObject, setBulkObject] = useState({
    target_stock_days: 0,
    fba_buffer_days: 0,
  });

  const SaveBulkInventoryRes = useSelector(
    (state) => state.InventoryManagement.SaveBulkInventoryResponse || {}
  );

  useEffect(() => {
    if (SaveBulkInventoryRes?.status === true) {
      message.destroy();
      message.success(SaveBulkInventoryRes?.message);
      onSuccess();
      fakeActionInventoryManagement("SaveBulkInventoryResponse");
    } else if (SaveBulkInventoryRes?.status === false) {
      message.destroy();
      message.error(SaveBulkInventoryRes.message);
      fakeActionInventoryManagement("SaveBulkInventoryResponse");
    }
  }, [SaveBulkInventoryRes]);

  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          onHide();
        }}
        size="lg"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Days Of Stock</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <div className="px-20">
              <div className="mb-5 d-flex justify-content-between">
                <label className="fw-bold fs-6">
                  Target stock range after new order(days)
                </label>
                <Input
                  className="ant_common_input me-5"
                  placeholder="Enter Target Days of stock"
                  size="large"
                  style={{
                    width: "230px",
                  }}
                  value={bulkObject?.target_stock_days}
                  onChange={(e) => {
                    if (!e.target.value) {
                      const newObj = { ...bulkObject };
                      newObj.target_stock_days = e.target.value;
                      setBulkObject(newObj);
                    }
                    const newVal = e.target.value.split(".").join("");
                    const validation = /^[0-9]+$/.test(newVal);
                    if (validation) {
                      const newObj = { ...bulkObject };
                      newObj.target_stock_days = e.target.value;
                      setBulkObject(newObj);
                    }
                  }}
                />
              </div>
              <div className="mb-5 d-flex justify-content-between">
                <label className="fw-bold fs-6">FBA buffer (days)</label>
                <Input
                  className="ant_common_input me-5"
                  placeholder="Enter FBA Buffer Days"
                  size="large"
                  style={{
                    width: "230px",
                  }}
                  value={bulkObject?.fba_buffer_days}
                  onChange={(e) => {
                    if (!e.target.value) {
                      const newObj = { ...bulkObject };
                      newObj.fba_buffer_days = e.target.value;
                      setBulkObject(newObj);
                    }
                    const newVal = e.target.value.split(".").join("");
                    const validation = /^[0-9]+$/.test(newVal);
                    if (validation) {
                      const newObj = { ...bulkObject };
                      newObj.fba_buffer_days = e.target.value;
                      setBulkObject(newObj);
                    }
                  }}
                />
              </div>
            </div>
          </>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-light-danger"
            onClick={onHide}
          >
            Close
          </button>
          <button
            onClick={() => {
              // SaveBulkSupplier();
              message.destroy();
              message.loading("Loading...", 0);
              const Obj = {
                skus: selectedCheckbox.join(","),
                target_stock_days: bulkObject?.target_stock_days,
                fba_buffer_days: bulkObject?.fba_buffer_days,
                marketplace_id: marketplace_id,
              };
              setLoading(false);
              SaveBulkInventoryAction(Obj);
            }}
            disabled={
              Object.values(bulkObject)?.filter((d) => d === "")?.length !==
                0 || loading
            }
            type="button"
            className="btn btn-primary px-5"
          >
            Submit
            {loading && (
              <Spinner
                animation="border"
                style={{ marginLeft: "10px" }}
                size="sm"
                className="ml-1"
              />
            )}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
