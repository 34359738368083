import React, { useEffect, useState } from "react";
import Loading from "../../../../component/loading";
import IvcsrTable from "../../../../component/ivcsr-table";
import NoData from "../../../../component/no-data";
import Pagination from "../../../../component/pagination";
import { DefaultPerPage } from "../../../../config";
import {
  Checkbox,
  Image,
  Input,
  Popconfirm,
  Popover,
  Select,
  Spin,
  Switch,
  Tag,
  Tooltip,
  message,
} from "antd";
import {
  LoadingOutlined,
  ExclamationCircleOutlined,
  CopyTwoTone,
  DeleteTwoTone,
  EditTwoTone,
  HistoryOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { useSelector } from "react-redux";
const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
    }}
    spin
  />
);
export default function (props) {
  const { GetRepricerProductListing, fakeActionRepricerListing } = props;
  const GetRepricingProductListingRes = useSelector(
    (state) =>
      state.RepricerProductListing.GetRepricingProductListingResponse || {}
  );
  const [isOpenFilters, setIsOpenFilters] = useState(false);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageSize, setPageSize] = useState(DefaultPerPage);
  const [ruleWithProduct, setRuleWithProduct] = useState({});
  const [statusId, setStatusId] = useState("");
  const [modalType, setModalType] = useState("add");
  const [addNewRepricer, setAddNewRepricer] = useState(false);
  const [selectedRule, setSelectedRule] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [isUpdateMinPrice, setIsUpdateMinPrice] = useState(false);
  const [isUpdateMaxPrice, setIsUpdateMaxPrice] = useState(false);
  const [ruleList, setRuleList] = useState([]);
  const [rulesLoading, setRulesLoading] = useState(false);

  useEffect(() => {
    const obj = { page: page, perPage: pageSize, searchTerm: searchTerm };
    GetRepricerProductListing(obj);
  }, []);

  useEffect(() => {
    if (GetRepricingProductListingRes.status === true) {
      setList(GetRepricingProductListingRes?.data?.records || []);
      setLoading(false);
      setTotalPage(GetRepricingProductListingRes?.data?.pagination?.totalCount);
      fakeActionRepricerListing("GetRepricingProductListingResponse");
    } else if (GetRepricingProductListingRes.status === false) {
      setLoading(false);
      fakeActionRepricerListing("GetRepricingProductListingResponse");
    }
  }, [GetRepricingProductListingRes]);

  const onPageNo = (e) => {
    setLoading(true);
    const obj = { page: e, perPage: pageSize, searchTerm: searchTerm };
    GetRepricerProductListing(obj);
    setPage(e);
  };

  const onPerPage = (e) => {
    setPage(1);
    setPageSize(e);
    setLoading(true);
    const obj = { page: 1, perPage: e, searchTerm: searchTerm };
    GetRepricerProductListing(obj);
  };

  const productColumns = [
    {
      title: "Sr.No.",
      width: 60,
      ellipsis: true,
      align: "left",
      render: (_, __, i) => {
        return <span>{(page - 1) * pageSize + 1 + i}</span>;
      },
    },
    {
      title: "Product Title",
      width: 120,
      align: "left",
      render: (text) => {
        return (
          <Popover
            content={
              <div
                style={{
                  height: "max-content",
                  width: "300px",
                }}
              >
                {text?.product_title}
              </div>
            }
            placement="bottom"
          >
            <div
              className="text-primary mb-1 fw-bolder text-one fs-6 popoverActionIconSingleLine"
              style={{ minWidth: "100px" }}
            >
              {text?.product_title}
            </div>
          </Popover>
        );
      },
    },
    {
      title: "Image",
      width: 100,
      align: "center",
      render: (text) => {
        return (
          <div style={{ height: "80px", width: "80px" }}>
            <Image src={text?.image_url} />
          </div>
        );
      },
    },
    {
      title: "SKU",
      width: 140,
      align: "left",
      render: (text) => {
        return (
          <Tooltip rule title={text?.sku}>
            <Tag color="purple">
              <b>{text?.sku || "N/A"}</b>
            </Tag>
          </Tooltip>
        );
      },
    },
    {
      title: "ASIN",
      width: 120,
      align: "left",
      render: (text) => {
        return (
          <Tag color="purple">
            <b>{text?.asin || "-"}</b>
          </Tag>
        );
      },
    },
    {
      title: "Available Units",
      width: 90,
      align: "left",
      render: (text) => {
        return (
          <span className="fw-bolder fs-6 d-flex align-items-center">
            {text?.available_units || "0"}
          </span>
        );
      },
    },
    {
      title: "Buy Box Price",
      width: 90,
      align: "left",
      render: (text) => {
        return (
          <span className="fw-bold fs-6">
            {"$"}&nbsp;
            {text?.buy_box_price}
          </span>
        );
      },
    },
    {
      title: "Min Price",
      width: 120,
      align: "left",
      render: (text) => {
        return (
          <Input
            value={text?.min_price}
            // onPressEnter={(e) => {
            //   if (parseInt(e.target.value) === 0) {
            //     message.destroy();
            //     message.info("Enter min value");
            //     return;
            //   }
            //   const reg = /^-?\d*(\.\d*)?$/;
            //   if (reg.test(e.target.value) || e.target.value === "") {
            //     if (isUpdateMinPrice) {
            //       if (text?.min_price <= text?.max_price) {
            //         message.destroy();
            //         message.loading("Loading...", 0);
            //         RepricingProductUpdateAction(text.id, {
            //           min_price: e.target.value,
            //         });
            //       } else {
            //         message.destroy();
            //         message.info("Min price must be less than max price");
            //         return;
            //       }
            //     }
            //   }
            // }}
            // onBlur={(e) => {
            //   if (parseInt(e.target.value) === 0) {
            //     message.destroy();
            //     message.info("Enter min value");
            //     return;
            //   }
            //   const reg = /^-?\d*(\.\d*)?$/;
            //   if (reg.test(e.target.value) || e.target.value === "") {
            //     if (isUpdateMinPrice) {
            //       if (text?.min_price <= text?.max_price) {
            //         message.destroy();
            //         message.loading("Loading...", 0);
            //         RepricingProductUpdateAction(text.id, {
            //           min_price: e.target.value,
            //         });
            //       } else {
            //         message.destroy();
            //         message.info("Min price must be less than max price");
            //         return;
            //       }
            //     }
            //   }
            // }}
            // onChange={(e) => {
            //   if (parseInt(e.target.value) === 0) {
            //     message.destroy();
            //     message.info("Enter valid value");
            //     return;
            //   }
            //   const reg = /^-?\d*(\.\d*)?$/;
            //   if (
            //     reg.test(e.target.value) ||
            //     e.target.value === "" ||
            //     e.target.value === "-"
            //   ) {
            //     if (text?.min_price !== e.target.value) {
            //       setIsUpdateMinPrice(true);
            //     }
            //     setList(
            //       list?.map((d) => {
            //         if (d.id === text.id) {
            //           return { ...d, min_price: e.target.value };
            //         }
            //         return { ...d };
            //       })
            //     );
            //   }
            // }}
          />
        );
      },
    },
    {
      title: "Current Price",
      align: "left",
      width: 100,
      render: (text) => {
        if (text?.is_current_price_change == 1) {
          return (
            <Tooltip title="New Repriced Price">
              <Tag bordered={false} color="green" className="fs-6">
                {"$"}&nbsp;
                {text?.current_price}
              </Tag>
            </Tooltip>
          );
        }

        return (
          <span>
            <Tag color="blue" className="fs-6">
              {"$"}&nbsp;
              {text?.current_price}
            </Tag>
          </span>
        );
      },
    },

    {
      title: "Max Price",
      width: 120,
      align: "left",
      render: (text) => {
        return (
          <Input
            value={text?.max_price}
            // onPressEnter={(e) => {
            //   if (parseInt(e.target.value) === 0) {
            //     message.destroy();
            //     message.info("Enter max value");
            //     return;
            //   }
            //   const reg = /^-?\d*(\.\d*)?$/;
            //   if (reg.test(e.target.value) || e.target.value === "") {
            //     if (isUpdateMaxPrice) {
            //       if (text?.max_price >= text?.min_price) {
            //         message.destroy();
            //         message.loading("Loading...", 0);
            //         RepricingProductUpdateAction(text.id, {
            //           max_price: e.target.value,
            //         });
            //       } else {
            //         message.destroy();
            //         message.info("Max price must be grather than min price");
            //         return;
            //       }
            //     }
            //   }
            // }}
            // onBlur={(e) => {
            //   if (parseInt(e.target.value) === 0) {
            //     message.destroy();
            //     message.info("Enter max value");
            //     return;
            //   }
            //   const reg = /^-?\d*(\.\d*)?$/;
            //   if (reg.test(e.target.value) || e.target.value === "") {
            //     if (isUpdateMaxPrice) {
            //       if (text?.max_price >= text?.min_price) {
            //         message.destroy();
            //         message.loading("Loading...", 0);
            //         RepricingProductUpdateAction(text.id, {
            //           max_price: e.target.value,
            //         });
            //       } else {
            //         message.destroy();
            //         message.info("Max price must be grather than min price");
            //         return;
            //       }
            //     }
            //   }
            // }}
            // onChange={(e) => {
            //   if (parseInt(e.target.value) === 0) {
            //     message.destroy();
            //     message.info("Enter valid value");
            //     return;
            //   }
            //   const reg = /^-?\d*(\.\d*)?$/;
            //   if (
            //     reg.test(e.target.value) ||
            //     e.target.value === "" ||
            //     e.target.value === "-"
            //   ) {
            //     if (reg.test(e.target.value)) {
            //       if (text?.max_price !== e.target.value) {
            //         setIsUpdateMaxPrice(true);
            //       }
            //       setList(
            //         list?.map((d) => {
            //           if (d.id === text.id) {
            //             return { ...d, max_price: e.target.value };
            //           }
            //           return { ...d };
            //         })
            //       );
            //     }
            //   }
            // }}
          />
        );
      },
    },
    // B00PZ0ZHEO
    {
      title: "Repricing Rule",
      width: 190,
      align: "left",
      render: (text) => {
        return (
          <Select
            placeholder="Repricing Rule"
            // getPopupContainer={(triggerNode) =>
            //   document.getElementById().parentNode
            // }
            allowClear
            value={null}
            style={{ width: "100%" }}
            size="medium"
            // loading={rulesLoading}
            // onChange={(e) => {
            //   showConfirm(text, e);
            // }}
            // options={ruleList?.map((d) => {
            //   return {
            //     label: d?.rule_name,
            //     value: parseInt(d?.id),
            //   };
            // })}
          />
        );
      },
    },
    {
      title: "Availability Status",
      width: 120,
      align: "left",
      render: (text) => {
        if (parseInt(text?.stock_availability) === 0) {
          return (
            <Tag color="red">
              <span className="fw-bolder fs-7 d-flex align-items-center">
                <div className=" bg-danger rounded-circle border border-3 border-white h-15px w-15px" />
                Out of Stock
              </span>
            </Tag>
          );
        }
        return (
          <Tag color="green">
            <span className="fw-bolder fs-7 d-flex align-items-center">
              <div
                className=" rounded-circle border border-3 border-white h-15px w-15px"
                style={{ background: "green" }}
              />
              In Stock
            </span>
          </Tag>
        );
      },
    },
    {
      title: "No. Of Offers",
      width: 80,
      align: "left",
      render: (text) => {
        return (
          <span>
            <Tag color="orange">{text?.offer_count || 0}</Tag>
          </span>
        );
      },
    },
    {
      title: "Last Sale",
      width: 120,
      align: "left",
      render: (text) => {
        // console.log(new Date(text.last_sales), "new Date(text.last_sales)");
        return (
          <div>
            <span className="fw-bold fs-6">
              {text.last_sales
                ? moment(text?.last_sales).format("MMM DD, YYYY")
                : "-"}
            </span>
          </div>
        );
      },
    },
    {
      title: "History",
      width: 80,
      align: "center",
      render: (text) => {
        return (
          <Tooltip title="Price change history">
            <Tag
              // onClick={() => {
              //   setSelectedASIN(text?.asin);
              //   const Obj = {
              //     page: 1,
              //     perPage: DefaultPerPage,
              //     asin: text?.asin,
              //   };
              //   GetPriceChangeHistoryAction(Obj);
              // }}
              style={{ padding: "6px 6px 2.5px 6px", cursor: "pointer" }}
              icon={<HistoryOutlined />}
              color="warning"
            />
          </Tooltip>
        );
      },
    },
  ];

  const staticData = [
    {
      id: 1,
      repricing_rule_id: null,
      sku: "BTD21LSA-1612-B",
      asin: "B09LP9TJ1Z",
      product_title:
        "Night Owl 16 Channel Bluetooth Video Home Security Camera System with (12) Wired 1080p HD Indoor/Outdoor Spotlight Cameras with Audio and 1TB Hard Drive (Expandable up to 16 Cameras)",
      image_url: "https://m.media-amazon.com/images/I/41wAcntepzL._SL75_.jpg",
      buy_box_price: 0,
      current_price: 0,
      min_price: 0,
      max_price: 0,
      last_sales: "2023-11-08T17:28:44+00:00",
      view_offers: null,
      offer_count: 0,
      stock_availability: 0,
      repricing_status: 0,
      available_units: 0,
      is_buy_box_winner: 0,
      no_buy_box_winner: 0,
      marketplace: "USA",
      marketplace_id: "ATVPDKIKX0DER",
      created_at: 1709719221,
      updated_at: 1716271283,
    },
    {
      id: 2,
      repricing_rule_id: null,
      sku: "BTD21LSA-86-B",
      asin: "B09LP96NFC",
      product_title:
        "Night Owl Sp, Llc 8 Channel Video Home Security Camera System with (6) Wired 1080p HD Indoor/Outdoor Spotlight Cameras Audio and 1TB Hard Drive (Expandable up to Cameras), BTD21LSA-86-B, White",
      image_url: "https://m.media-amazon.com/images/I/41pUpfqjwpL._SL75_.jpg",
      buy_box_price: 0,
      current_price: 0,
      min_price: 0,
      max_price: 0,
      last_sales: "2024-04-30T19:43:39+00:00",
      view_offers: null,
      offer_count: 0,
      stock_availability: 0,
      repricing_status: 0,
      available_units: 0,
      is_buy_box_winner: 0,
      no_buy_box_winner: 0,
      marketplace: "USA",
      marketplace_id: "ATVPDKIKX0DER",
      created_at: 1709719222,
      updated_at: 1716271283,
    },
    {
      id: 3,
      repricing_rule_id: null,
      sku: "BTD8-41-4L",
      asin: "B09LRJSH8F",
      product_title:
        "Night Owl Sp 4 Channel Bluetooth Video Home Security Camera System with (4) Wired 4K UHD Indoor/Outdoor Spotlight Cameras and 1TB Hard Drive, White, (BTD8-41-4L)",
      image_url: "https://m.media-amazon.com/images/I/31I8Jbb6JIL._SL75_.jpg",
      buy_box_price: 0,
      current_price: 0,
      min_price: 0,
      max_price: 0,
      last_sales: "2024-04-05T14:20:14+00:00",
      view_offers: null,
      offer_count: 0,
      stock_availability: 0,
      repricing_status: 0,
      available_units: 0,
      is_buy_box_winner: 0,
      no_buy_box_winner: 0,
      marketplace: "USA",
      marketplace_id: "ATVPDKIKX0DER",
      created_at: 1709719223,
      updated_at: 1716271283,
    },
    {
      id: 4,
      repricing_rule_id: null,
      sku: "BTD82LSA-1612-B",
      asin: "B0B44Q593G",
      product_title:
        "Night Owl 16 Channel Bluetooth Video Home Security Camera System with (12) Wired 4K UHD Indoor/Outdoor Spotlight Cameras with Audio and 2TB Hard Drive (Expandable up to 16 Cameras)",
      image_url: "https://m.media-amazon.com/images/I/41eO0j16y6L._SL75_.jpg",
      buy_box_price: 0,
      current_price: 0,
      min_price: 0,
      max_price: 0,
      last_sales: "2024-02-03T18:44:11+00:00",
      view_offers: null,
      offer_count: 0,
      stock_availability: 0,
      repricing_status: 0,
      available_units: 0,
      is_buy_box_winner: 0,
      no_buy_box_winner: 0,
      marketplace: "USA",
      marketplace_id: "ATVPDKIKX0DER",
      created_at: 1709719223,
      updated_at: 1716271283,
    },
    {
      id: 5,
      repricing_rule_id: null,
      sku: "BTN8-82-6L",
      asin: "B0B44C7DMJ",
      product_title:
        "Night Owl 8 Channel Bluetooth Video Home Security Camera System with (6) Wired IP 4K HD Indoor/Outdoor Spotlight Cameras with Audio and 2TB Hard Drive (Expandable up to 12 Cameras)",
      image_url: "https://m.media-amazon.com/images/I/51rVLMeb1cL._SL75_.jpg",
      buy_box_price: 0,
      current_price: 0,
      min_price: 0,
      max_price: 0,
      last_sales: "2024-05-20T11:37:16+00:00",
      view_offers: null,
      offer_count: 0,
      stock_availability: 1,
      repricing_status: 0,
      available_units: 193,
      is_buy_box_winner: 0,
      no_buy_box_winner: 0,
      marketplace: "USA",
      marketplace_id: "ATVPDKIKX0DER",
      created_at: 1709719224,
      updated_at: 1716271283,
    },
    {
      id: 6,
      repricing_rule_id: null,
      sku: "BTN82L-84-B",
      asin: "B09XG2NB6L",
      product_title:
        "Night Owl 8 Channel Bluetooth Video Home Security Camera System with (4) Wired IP 4K HD Indoor/Outdoor Spotlight Cameras with Audio and 2TB Hard Drive (Expandable up to 12 Cameras)",
      image_url: "https://m.media-amazon.com/images/I/41aKsYRm11L._SL75_.jpg",
      buy_box_price: 0,
      current_price: 0,
      min_price: 0,
      max_price: 0,
      last_sales: "2024-05-18T03:19:32+00:00",
      view_offers: null,
      offer_count: 0,
      stock_availability: 1,
      repricing_status: 0,
      available_units: 143,
      is_buy_box_winner: 0,
      no_buy_box_winner: 0,
      marketplace: "USA",
      marketplace_id: "ATVPDKIKX0DER",
      created_at: 1709719224,
      updated_at: 1716271283,
    },
    {
      id: 7,
      repricing_rule_id: null,
      sku: "BTN82L-88-B",
      asin: "B09XFMY7HS",
      product_title:
        "Night Owl 8 Channel Bluetooth Video Home Security Camera System with (8) Wired IP 4K HD Indoor/Outdoor Spotlight Cameras with Audio and 2TB Hard Drive (Expandable up to 12 Cameras)",
      image_url: "https://m.media-amazon.com/images/I/51onXybh0tL._SL75_.jpg",
      buy_box_price: 0,
      current_price: 0,
      min_price: 0,
      max_price: 0,
      last_sales: "2024-05-20T16:37:15+00:00",
      view_offers: null,
      offer_count: 0,
      stock_availability: 1,
      repricing_status: 0,
      available_units: 117,
      is_buy_box_winner: 0,
      no_buy_box_winner: 0,
      marketplace: "USA",
      marketplace_id: "ATVPDKIKX0DER",
      created_at: 1709719225,
      updated_at: 1716271283,
    },
    {
      id: 8,
      repricing_rule_id: null,
      sku: "BTWN8-4L1",
      asin: "B09LRJV7S4",
      product_title:
        "Night Owl Sp, Llc Night Owl 10 Channel Bluetooth Video Home Security Camera System with (4) Wi-Fi IP 4K HD Indoor/Outdoor Spotlight Cameras 2-Way Audio and 1TB Hard Drive, BTWN8-4L1",
      image_url: "https://m.media-amazon.com/images/I/41BU7deqnoL._SL75_.jpg",
      buy_box_price: 0,
      current_price: 0,
      min_price: 0,
      max_price: 0,
      last_sales: "2024-05-20T20:41:49+00:00",
      view_offers: null,
      offer_count: 0,
      stock_availability: 1,
      repricing_status: 0,
      available_units: 43,
      is_buy_box_winner: 0,
      no_buy_box_winner: 0,
      marketplace: "USA",
      marketplace_id: "ATVPDKIKX0DER",
      created_at: 1709719225,
      updated_at: 1716271283,
    },
    {
      id: 9,
      repricing_rule_id: null,
      sku: "BTWN81-F4-4L",
      asin: "B0CGT9TJZD",
      product_title:
        "Night Owl 10 Channel Bluetooth Video Home Security Camera System with (4) Plug-in Wi-Fi 2K HD Indoor/Outdoor Spotlight Cameras with 2-Way Audio and 1TB Hard Drive (Expandable up to 10 Cameras)",
      image_url: "https://m.media-amazon.com/images/I/41Kj-XxMRaL._SL75_.jpg",
      buy_box_price: 0,
      current_price: 0,
      min_price: 0,
      max_price: 0,
      last_sales: "2024-05-04T02:32:48+00:00",
      view_offers: null,
      offer_count: 0,
      stock_availability: 1,
      repricing_status: 0,
      available_units: 78,
      is_buy_box_winner: 0,
      no_buy_box_winner: 0,
      marketplace: "USA",
      marketplace_id: "ATVPDKIKX0DER",
      created_at: 1709719226,
      updated_at: 1716271283,
    },
    {
      id: 10,
      repricing_rule_id: null,
      sku: "BTWN81-F4-8L",
      asin: "B0CGTGJ56K",
      product_title:
        "Night Owl 10 Channel Bluetooth Video Home Security Camera System with (8) Plug-in Wi-Fi 2K HD Indoor/Outdoor Spotlight Cameras with 2-Way Audio and 1TB Hard Drive (Expandable up to 10 Cameras)",
      image_url: "https://m.media-amazon.com/images/I/515KE9+UolL._SL75_.jpg",
      buy_box_price: 0,
      current_price: 0,
      min_price: 0,
      max_price: 0,
      last_sales: "2024-05-17T19:19:33+00:00",
      view_offers: null,
      offer_count: 0,
      stock_availability: 1,
      repricing_status: 0,
      available_units: 63,
      is_buy_box_winner: 0,
      no_buy_box_winner: 0,
      marketplace: "USA",
      marketplace_id: "ATVPDKIKX0DER",
      created_at: 1709719226,
      updated_at: 1716271283,
    },
    {
      id: 11,
      repricing_rule_id: null,
      sku: "BTWN81L-2-3-B",
      asin: "B09LRKBZ1G",
      product_title:
        "Night Owl 10 Channel Bluetooth Video Home Security Camera System with (3) Wi-Fi IP 4K HD Indoor/Outdoor Spotlight Cameras 2-Way Audio & 1TB Hard Drive (Expandable up to Cameras), BTWN81L-2-3-B",
      image_url: "https://m.media-amazon.com/images/I/31r5Y9g0tVL._SL75_.jpg",
      buy_box_price: 0,
      current_price: 0,
      min_price: 0,
      max_price: 0,
      last_sales: "2024-02-22T00:00:20+00:00",
      view_offers: null,
      offer_count: 0,
      stock_availability: 1,
      repricing_status: 0,
      available_units: 27,
      is_buy_box_winner: 0,
      no_buy_box_winner: 0,
      marketplace: "USA",
      marketplace_id: "ATVPDKIKX0DER",
      created_at: 1709719227,
      updated_at: 1716271283,
    },
    {
      id: 12,
      repricing_rule_id: null,
      sku: "CAB-1004KV1-B",
      asin: "B0CDHQMT8V",
      product_title:
        "Night Owl 100 ft. in-Wall Rated Video/Power Camera Extension Cable with Extension Adapter",
      image_url: "https://m.media-amazon.com/images/I/51-1cyaBJkL._SL75_.jpg",
      buy_box_price: 0,
      current_price: 0,
      min_price: 0,
      max_price: 0,
      last_sales: "2024-05-18T12:31:54+00:00",
      view_offers: null,
      offer_count: 0,
      stock_availability: 1,
      repricing_status: 0,
      available_units: 269,
      is_buy_box_winner: 0,
      no_buy_box_winner: 0,
      marketplace: "USA",
      marketplace_id: "ATVPDKIKX0DER",
      created_at: 1709719227,
      updated_at: 1716271283,
    },
    {
      id: 13,
      repricing_rule_id: null,
      sku: "CAB-2PK1004KV1",
      asin: "B07NDYNQKH",
      product_title:
        "Night Owl 100 ft. in-Wall Rated Video/Power Camera Extension Cable with Extension Adapter (2-Pack)",
      image_url: "https://m.media-amazon.com/images/I/41BKK84F9YL._SL75_.jpg",
      buy_box_price: 0,
      current_price: 0,
      min_price: 0,
      max_price: 0,
      last_sales: "2024-05-21T00:55:30+00:00",
      view_offers: null,
      offer_count: 0,
      stock_availability: 1,
      repricing_status: 0,
      available_units: 292,
      is_buy_box_winner: 0,
      no_buy_box_winner: 0,
      marketplace: "USA",
      marketplace_id: "ATVPDKIKX0DER",
      created_at: 1709719228,
      updated_at: 1716271283,
    },
    {
      id: 14,
      repricing_rule_id: null,
      sku: "CAM-2PK-DP2LSA",
      asin: "B09LPN6TW1",
      product_title:
        "Night Owl Wired 1080p HD Indoor/Outdoor Add On Spotlight Cameras with Preset Voice Alerts and Built-in Camera Siren (Requires Compatible BTD2 or BTD8 Series DVR - Sold Separately)",
      image_url: "https://m.media-amazon.com/images/I/31CRI75HPxL._SL75_.jpg",
      buy_box_price: 0,
      current_price: 0,
      min_price: 0,
      max_price: 0,
      last_sales: "2024-05-21T01:36:34+00:00",
      view_offers: null,
      offer_count: 0,
      stock_availability: 1,
      repricing_status: 0,
      available_units: 12,
      is_buy_box_winner: 0,
      no_buy_box_winner: 0,
      marketplace: "USA",
      marketplace_id: "ATVPDKIKX0DER",
      created_at: 1709719228,
      updated_at: 1716271283,
    },
    {
      id: 15,
      repricing_rule_id: null,
      sku: "CAM-2PK-DP8LSA",
      asin: "B09LRCVHKQ",
      product_title:
        "Night Owl Wired 4K UHD Indoor/Outdoor Add On Spotlight Cameras with Preset Voice Alerts and Built-in Camera Siren (Requires Compatible BTD8 Series DVR - Sold Separately)",
      image_url: "https://m.media-amazon.com/images/I/31EfVGmjwCL._SL75_.jpg",
      buy_box_price: 0,
      current_price: 0,
      min_price: 0,
      max_price: 0,
      last_sales: "2024-05-15T01:44:36+00:00",
      view_offers: null,
      offer_count: 0,
      stock_availability: 1,
      repricing_status: 0,
      available_units: 149,
      is_buy_box_winner: 0,
      no_buy_box_winner: 0,
      marketplace: "USA",
      marketplace_id: "ATVPDKIKX0DER",
      created_at: 1709719229,
      updated_at: 1716271283,
    },
    {
      id: 16,
      repricing_rule_id: null,
      sku: "CAM-2PK-FTD4",
      asin: "B0CBQSJS71",
      product_title:
        "Night Owl SP Wired 2K Deterrence Add On Cameras with 2-Way Audio - 2 Pack - White",
      image_url: "https://m.media-amazon.com/images/I/41gYl5emkSL._SL75_.jpg",
      buy_box_price: 0,
      current_price: 0,
      min_price: 0,
      max_price: 0,
      last_sales: "2024-05-20T03:53:54+00:00",
      view_offers: null,
      offer_count: 0,
      stock_availability: 1,
      repricing_status: 0,
      available_units: 235,
      is_buy_box_winner: 0,
      no_buy_box_winner: 0,
      marketplace: "USA",
      marketplace_id: "ATVPDKIKX0DER",
      created_at: 1709719229,
      updated_at: 1716271283,
    },
    {
      id: 17,
      repricing_rule_id: null,
      sku: "CAM-2PK-FWIP4L",
      asin: "B0CF6PQ7VY",
      product_title:
        "Night Owl Plug-in Wireless 2K HD Indoor/Outdoor Deterrence Camera with 2-Way Audio (2 Camera)",
      image_url: "https://m.media-amazon.com/images/I/41+kGLjDU7L._SL75_.jpg",
      buy_box_price: 0,
      current_price: 0,
      min_price: 0,
      max_price: 0,
      last_sales: "2024-05-20T23:57:39+00:00",
      view_offers: null,
      offer_count: 0,
      stock_availability: 1,
      repricing_status: 0,
      available_units: 55,
      is_buy_box_winner: 0,
      no_buy_box_winner: 0,
      marketplace: "USA",
      marketplace_id: "ATVPDKIKX0DER",
      created_at: 1709719230,
      updated_at: 1716271283,
    },
    {
      id: 18,
      repricing_rule_id: null,
      sku: "CAM-2PK-IP8LBU-B",
      asin: "B09XBS7YGB",
      product_title:
        "Night Owl Wired IP 4K UHD Indoor/Outdoor Add On Spotlight Cameras with Preset Voice Alerts and Built-in Camera Siren (Requires Compatible BTN8 Series NVR or BTD8 Series DVR - Sold Separately)",
      image_url: "https://m.media-amazon.com/images/I/31wmKqcRwyL._SL75_.jpg",
      buy_box_price: 0,
      current_price: 0,
      min_price: 0,
      max_price: 0,
      last_sales: "2024-05-20T17:32:13+00:00",
      view_offers: null,
      offer_count: 0,
      stock_availability: 1,
      repricing_status: 0,
      available_units: 100,
      is_buy_box_winner: 0,
      no_buy_box_winner: 0,
      marketplace: "USA",
      marketplace_id: "ATVPDKIKX0DER",
      created_at: 1709719230,
      updated_at: 1716271283,
    },
    {
      id: 19,
      repricing_rule_id: null,
      sku: "CAM-2PK-WNIP2LBU",
      asin: "B08D23HQYY",
      product_title:
        "Night Owl AC Powered 1080p HD Wi-Fi IP Indoor/Outdoor Camera with 100 ft. of Night Vision, 2-Way Audio, Built-in Spotlight, Wide Viewing Angle and Free Remote Viewing App, 2-Pack",
      image_url: "https://m.media-amazon.com/images/I/31mXVuAcIiL._SL75_.jpg",
      buy_box_price: 0,
      current_price: 0,
      min_price: 0,
      max_price: 0,
      last_sales: "2024-05-20T21:39:32+00:00",
      view_offers: null,
      offer_count: 0,
      stock_availability: 1,
      repricing_status: 0,
      available_units: 135,
      is_buy_box_winner: 0,
      no_buy_box_winner: 0,
      marketplace: "USA",
      marketplace_id: "ATVPDKIKX0DER",
      created_at: 1709719231,
      updated_at: 1716271283,
    },
    {
      id: 20,
      repricing_rule_id: null,
      sku: "CAM-2PK-WNIP8LBU",
      asin: "B09LRHR2MK",
      product_title:
        "Night Owl Sp, Llc Night Owl Wi-Fi IP 4K HD Indoor/Outdoor Dual Spotlight Cameras with 2-Way Audio, Preset Voice Alerts and Built-in Camera Siren",
      image_url: "https://m.media-amazon.com/images/I/31l3IqgEN-L._SL75_.jpg",
      buy_box_price: 0,
      current_price: 0,
      min_price: 0,
      max_price: 0,
      last_sales: "2024-05-15T13:54:55+00:00",
      view_offers: null,
      offer_count: 0,
      stock_availability: 1,
      repricing_status: 0,
      available_units: 153,
      is_buy_box_winner: 0,
      no_buy_box_winner: 0,
      marketplace: "USA",
      marketplace_id: "ATVPDKIKX0DER",
      created_at: 1709719231,
      updated_at: 1716271283,
    },
    {
      id: 21,
      repricing_rule_id: null,
      sku: "CAM-FWIP4L",
      asin: "B0CF6YDX7C",
      product_title:
        "Night Owl Plug-in Wireless 2K HD Indoor/Outdoor Deterrence Camera with 2-Way Audio (1 Camera)",
      image_url: "https://m.media-amazon.com/images/I/41xHjz7vecL._SL75_.jpg",
      buy_box_price: 0,
      current_price: 0,
      min_price: 0,
      max_price: 0,
      last_sales: "2024-05-14T20:12:16+00:00",
      view_offers: null,
      offer_count: 0,
      stock_availability: 1,
      repricing_status: 0,
      available_units: 116,
      is_buy_box_winner: 0,
      no_buy_box_winner: 0,
      marketplace: "USA",
      marketplace_id: "ATVPDKIKX0DER",
      created_at: 1709719232,
      updated_at: 1716271283,
    },
    {
      id: 22,
      repricing_rule_id: null,
      sku: "CAM-FWIP8L",
      asin: "B0CGTPYTF2",
      product_title:
        "Night Owl Plug-in AC Powered Wi-Fi 4K HD Indoor/Outdoor Deterrence Camera with 2-Way Audio (1 Camera)",
      image_url: "https://m.media-amazon.com/images/I/41FLpbrcJNL._SL75_.jpg",
      buy_box_price: 0,
      current_price: 0,
      min_price: 0,
      max_price: 0,
      last_sales: "2024-05-16T15:39:57+00:00",
      view_offers: null,
      offer_count: 0,
      stock_availability: 1,
      repricing_status: 0,
      available_units: 126,
      is_buy_box_winner: 0,
      no_buy_box_winner: 0,
      marketplace: "USA",
      marketplace_id: "ATVPDKIKX0DER",
      created_at: 1709719232,
      updated_at: 1716271283,
    },
    {
      id: 23,
      repricing_rule_id: null,
      sku: "CAM-WNIP2LWA",
      asin: "B08Q796C87",
      product_title:
        "Night Owl 1080p AC Powered Panoramic Wi-Fi IP Indoor/Outdoor Camera with a 140° Ultra Wide Angle View, 2-Way Audio, Built-in Spotlight, Motion Detection, and Remote Viewing Mobile App",
      image_url: "https://m.media-amazon.com/images/I/315gTwsV-xL._SL75_.jpg",
      buy_box_price: 0,
      current_price: 0,
      min_price: 0,
      max_price: 0,
      last_sales: "2024-05-20T01:29:05+00:00",
      view_offers: null,
      offer_count: 0,
      stock_availability: 1,
      repricing_status: 0,
      available_units: 73,
      is_buy_box_winner: 0,
      no_buy_box_winner: 0,
      marketplace: "USA",
      marketplace_id: "ATVPDKIKX0DER",
      created_at: 1709719233,
      updated_at: 1716271283,
    },
    {
      id: 24,
      repricing_rule_id: null,
      sku: "CAM-WNIP8LBU",
      asin: "B09LRKTQKT",
      product_title:
        "Night Owl Wi-Fi IP 4K HD Indoor/Outdoor Dual Spotlight Camera with 2-Way Audio, Preset Voice Alerts & Built-in Camera Siren, B09LRKTQKT",
      image_url: "https://m.media-amazon.com/images/I/219edcyGPAL._SL75_.jpg",
      buy_box_price: 0,
      current_price: 0,
      min_price: 0,
      max_price: 0,
      last_sales: "2024-05-21T01:16:00+00:00",
      view_offers: null,
      offer_count: 0,
      stock_availability: 1,
      repricing_status: 0,
      available_units: 76,
      is_buy_box_winner: 0,
      no_buy_box_winner: 0,
      marketplace: "USA",
      marketplace_id: "ATVPDKIKX0DER",
      created_at: 1709719233,
      updated_at: 1716271283,
    },
    {
      id: 25,
      repricing_rule_id: null,
      sku: "CL-BT8D-4-14LSA",
      asin: "B09HZ8X5S2",
      product_title:
        "Night Owl 4 Channel Bluetooth Video Home Security Camera System with (4) Wired 4K UHD Indoor/Outdoor Spotlight Cameras with Audio and 1TB Hard Drive",
      image_url: "https://m.media-amazon.com/images/I/31ZC3o3ZnNL._SL75_.jpg",
      buy_box_price: 0,
      current_price: 0,
      min_price: 0,
      max_price: 0,
      last_sales: "2024-03-27T21:06:00+00:00",
      view_offers: null,
      offer_count: 0,
      stock_availability: 0,
      repricing_status: 0,
      available_units: 0,
      is_buy_box_winner: 0,
      no_buy_box_winner: 0,
      marketplace: "USA",
      marketplace_id: "ATVPDKIKX0DER",
      created_at: 1709719234,
      updated_at: 1716271283,
    },
  ];

  return (
    <>
      <div className="d-flex flex-column flex-column-fluid fadeInRight">
        <div id="kt_app_content" className="app-content flex-column-fluid">
          <div
            id="kt_app_content_container"
            className="app-container container-fluid"
          >
            <div className="row gy-5 g-xl-5">
              <div className="col-xxl-12">
                <div className="card card-xxl-stretch mb-5 mb-xl-8">
                  <div className="card-header border-bottom-dashed">
                    <h3 className="card-title ">
                      <div className="position-relative">
                        <Input
                          type="text"
                          className="text-dark w-250px fs-7 fw-bold ant_common_input"
                          name="all_search"
                          placeholder="Search by ASIN, SKU..."
                          value={searchTerm}
                          size="large"
                          onChange={(e) => {
                            setSearchTerm(e.target.value);
                          }}
                          onPressEnter={(e) => {
                            setLoading(true);
                            setPage(1);
                            GetRepricerProductListing({
                              page: 1,
                              perPage: pageSize,
                              searchTerm: e.target.value,
                              // marketplace_id: selectedSalesChannelId,
                            });
                          }}
                          prefix={
                            <SearchOutlined
                              style={{ fontSize: "19px", color: "grey" }}
                            />
                          }
                        />
                      </div>
                    </h3>

                    <div className="card-toolbar gap-2">
                      <div className="position-relative me-2">
                        <button
                          className="btn btn-primary"
                          onClick={() => {
                            setIsOpenFilters(!isOpenFilters);
                          }}
                        >
                          Filters
                        </button>
                        {/* <span
                          onClick={() => {
                            setAddNewRepricer(true);
                            setModalType("add");
                          }}
                          className="btn btn-primary fw-bolder"
                        >
                          Add Rule
                        </span> */}
                      </div>
                      <div className="position-relative">
                        <button className="btn btn-primary">Import</button>
                        {/* <span
                          onClick={() => {
                            setAddNewRepricer(true);
                            setModalType("add");
                          }}
                          className="btn btn-primary fw-bolder"
                        >
                          Add Rule
                        </span> */}
                      </div>
                    </div>
                  </div>

                  <div className="card-body pb-5 pt-0">
                    {isOpenFilters && (
                      <>
                        <div className="d-flex justify-content-start mt-5 fade-in">
                          <div className="me-4">
                            <label className="fw-bold fs-6">
                              Repricing Status
                            </label>
                            <br />
                            <Select
                              placeholder="Repricing Status"
                              size="large"
                              style={{ width: "200px" }}
                            />
                          </div>
                          <div className="me-4">
                            <label className="fw-bold fs-6">
                              Buy Box Status
                            </label>
                            <br />
                            <Select
                              placeholder="Buy Box Status"
                              size="large"
                              style={{ width: "200px" }}
                            />
                          </div>
                          {/* <div className="me-4">
                            <label className="fw-bold fs-6">
                              Repricing Rule
                            </label>
                            <br />
                            <Select
                              placeholder="Repricing Rule"
                              size="large"
                              style={{ width: "200px" }}
                            />
                          </div> */}
                          {/* <div className="position-relative me-4">
                        <div>
                          <span className="fw-bold fs-6">Price Range</span>
                        </div>
                        <div className="d-flex align-items-center">
                         
                          <Input
                            placeholder="min"
                            size="large"
                            style={{ width: "100px" }}
                          />
                          -
                          <Input
                            placeholder="max"
                            size="large"
                            style={{ width: "100px" }}
                          />
                        </div>
                      </div> */}
                          <div className="position-relative me-4">
                            <label className="fw-bold fs-6">
                              Availability Status
                            </label>
                            <div
                              className="d-flex align-items-center position-relative"
                              style={{ top: "11px" }}
                            >
                              <Checkbox />
                              <label className="fw-bold mx-2 ">
                                <Tag color="green" className="fs-6">
                                  In Stock
                                </Tag>
                              </label>
                              <Checkbox />
                              <label className="fw-bold mx-2 ">
                                <Tag color="red" className="fs-6">
                                  Out of Stock
                                </Tag>
                              </label>
                              <Checkbox />
                              <label className="fw-bold mx-2 ">
                                <Tag color="geekblue" className="fs-6">
                                  All
                                </Tag>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div
                          className="separator separator-dashed my-5"
                          style={{
                            borderColor: "#00000069",
                            boxShadow: "0px 0px 10px 1px #b7b5f1",
                          }}
                        />
                      </>
                    )}

                    <div className="card-body py-4" id="repricing_products">
                      <div className="table-responsive">
                        {/* {loading ? (
                          <Loading />
                        ) : list?.length > 0 ? (
                          list?.map((l, j) => {
                            const isImage = productColumns?.filter(
                              (d) => d?.title === "Image"
                            );
                            return (
                              <>
                                <div className="row d-flex align-items-center mx-0">
                                  {isImage?.length != 0 && (
                                    <div className="col-1">
                                      {isImage[0]?.render(
                                        { ...l, key: j },
                                        list,
                                        j
                                      )}
                                    </div>
                                  )}

                                  <div
                                    className={`${
                                      isImage?.length != 0 ? "col-11" : "col-12"
                                    }`}
                                  >
                                    <div className="row mx-0">
                                      {productColumns?.map((d, i) => {
                                        if (d?.title === "Image") {
                                          return;
                                        }
                                        if (d?.title === "extraRow") {
                                          return (
                                            <div
                                              className="col-12 d-flex align-items-center my-2"
                                              style={{
                                                borderRight:
                                                  "1px solid #80808033",
                                              }}
                                            >
                                              {d?.render(
                                                { ...l, key: j },
                                                list,
                                                j
                                              )}
                                            </div>
                                          );
                                        }
                                        return (
                                          <div
                                            className="col-12 col-md-12 col-lg-4 d-flex align-items-center my-2"
                                            style={{
                                              borderRight:
                                                "1px solid #80808033",
                                            }}
                                          >
                                            <div style={{ width: "50%" }}>
                                              <label
                                                className="fs-5"
                                                style={{ fontWeight: "600" }}
                                              >
                                                {d?.title} :
                                              </label>
                                            </div>
                                            <div
                                              style={{ width: "50%" }}
                                              className="commonTableColumnAlign"
                                            >
                                              {d?.title == "Sr.No." ? (
                                                <Tag color="#7472ee">
                                                  {d?.render(
                                                    { ...l, key: j },
                                                    list,
                                                    j
                                                  )}
                                                </Tag>
                                              ) : (
                                                d?.render(
                                                  { ...l, key: j },
                                                  list,
                                                  j
                                                )
                                              )}
                                            </div>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="separator separator-dashed my-5"
                                  style={{
                                    borderColor: "#00000069",
                                    boxShadow: "0px 0px 10px 1px #b7b5f1",
                                  }}
                                />
                              </>
                            );
                          })
                        ) : (
                          <NoData />
                        )} */}
                        {staticData?.map((text, i) => {
                          return (
                            <>
                              <div className="row mx-0" key={i}>
                                <div
                                  className="col-12 mx-0 p-3"
                                  style={{
                                    backgroundColor: "#f2efff",
                                    border: "1px solid #cfc7ff",
                                    borderRadius: "5px",
                                  }}
                                >
                                  <div className="row mx-0">
                                    <div className="col-2 col-md-1 d-flex justify-content-center">
                                      <img
                                        src={text?.image_url}
                                        style={{
                                          height: "80px",
                                          width: "80px",
                                          objectFit: "contain",
                                          borderRadius: "5px",
                                        }}
                                      />
                                    </div>
                                    <div className="col-10 col-md-11">
                                      <div className="row mx-0">
                                        <div className="col-12 mx-0">
                                          <label className="fs-4 fw-bold">
                                            <Tag
                                              color="magenta"
                                              className="mr-3"
                                            >
                                              Sr. No.
                                              {(page - 1) * pageSize + 1 + i}
                                            </Tag>

                                            {text?.product_title}
                                          </label>
                                        </div>
                                        <div className="col-12 mx-0 mt-3">
                                          <Tag
                                            color="processing"
                                            className="fw-bold fs-6 me-10"
                                          >
                                            SKU: {text?.sku}
                                          </Tag>
                                          <Tag
                                            color="gold"
                                            className="fw-bold fs-6 me-10"
                                          >
                                            ASIN: {text?.asin}
                                          </Tag>
                                          <Tag
                                            color="pink"
                                            style={{
                                              cursor: "pointer",
                                              textDecoration: "underline",
                                            }}
                                            className="fw-bold fs-6 me-10 "
                                            onClick={() => {
                                              // setSelectedASIN(text?.asin);
                                              // message.destroy();
                                              // message.loading(
                                              //   "Loading...",
                                              //   0
                                              // );
                                              // const Obj = {
                                              //   page: 1,
                                              //   perPage: DefaultPerPage,
                                              //   asin: text?.asin,
                                              // };
                                              // GetPriceChangeHistoryAction(
                                              //   Obj
                                              // );
                                            }}
                                          >
                                            History
                                          </Tag>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-12 px-0">
                                  <div className="row mx-0">
                                    <div className="col-12 col-md-4 align-items-center justify-content-between d-flex py-1 px-15">
                                      <label
                                        className="fs-6"
                                        style={{ fontWeight: "500" }}
                                      >
                                        Current Price :
                                      </label>
                                      {text?.is_current_price_change == 1 ? (
                                        <Tooltip title="New Repriced Price">
                                          <Tag bordered={false} color="green">
                                            {/* {
                                                        signList[
                                                          marketPlaceSelected
                                                        ]
                                                      } */}
                                            $ &nbsp;
                                            {text?.current_price}
                                          </Tag>
                                        </Tooltip>
                                      ) : (
                                        <label
                                          className="fs-5"
                                          style={{ fontWeight: "600" }}
                                        >
                                          {/* {
                                                      signList[
                                                        marketPlaceSelected
                                                      ]
                                                    } */}
                                          ${text?.current_price || "0"}
                                        </label>
                                      )}
                                    </div>
                                    <div className="col-12 col-md-4 align-items-center justify-content-between d-flex py-1 px-15">
                                      <label
                                        className="fs-6"
                                        style={{ fontWeight: "500" }}
                                      >
                                        Current Stock :
                                      </label>

                                      <label
                                        className="fs-5"
                                        style={{ fontWeight: "600" }}
                                      >
                                        {text?.available_units || "0"}
                                      </label>
                                    </div>
                                    <div className="col-12 col-md-4 align-items-center justify-content-between d-flex py-1 px-15">
                                      <label
                                        className="fs-6"
                                        style={{ fontWeight: "500" }}
                                      >
                                        Min Price :
                                      </label>

                                      <label
                                        className="fs-5"
                                        style={{ fontWeight: "600" }}
                                      >
                                        <Input
                                          value={text?.min_price}
                                          onPressEnter={(e) => {
                                            if (
                                              parseInt(e.target.value) === 0
                                            ) {
                                              message.destroy();
                                              message.info("Enter min value");
                                              return;
                                            }
                                            const reg = /^-?\d*(\.\d*)?$/;
                                            if (
                                              reg.test(e.target.value) ||
                                              e.target.value === ""
                                            ) {
                                              if (isUpdateMinPrice) {
                                                if (
                                                  text?.min_price <=
                                                  text?.max_price
                                                ) {
                                                  // message.destroy();
                                                  // message.loading(
                                                  //   "Loading...",
                                                  //   0
                                                  // );
                                                  // RepricingProductUpdateAction(
                                                  //   text.id,
                                                  //   {
                                                  //     min_price:
                                                  //       e.target
                                                  //         .value,
                                                  //   }
                                                  // );
                                                } else {
                                                  message.destroy();
                                                  message.info(
                                                    "Min price must be less than max price"
                                                  );
                                                  return;
                                                }
                                              }
                                            }
                                          }}
                                          onBlur={(e) => {
                                            if (
                                              parseInt(e.target.value) === 0
                                            ) {
                                              message.destroy();
                                              message.info("Enter min value");
                                              return;
                                            }
                                            const reg = /^-?\d*(\.\d*)?$/;
                                            if (
                                              reg.test(e.target.value) ||
                                              e.target.value === ""
                                            ) {
                                              if (isUpdateMinPrice) {
                                                if (
                                                  text?.min_price <=
                                                  text?.max_price
                                                ) {
                                                  // message.destroy();
                                                  // message.loading(
                                                  //   "Loading...",
                                                  //   0
                                                  // );
                                                  // RepricingProductUpdateAction(
                                                  //   text.id,
                                                  //   {
                                                  //     min_price:
                                                  //       e.target
                                                  //         .value,
                                                  //   }
                                                  // );
                                                } else {
                                                  message.destroy();
                                                  message.info(
                                                    "Min price must be less than max price"
                                                  );
                                                  return;
                                                }
                                              }
                                            }
                                          }}
                                          onChange={(e) => {
                                            if (
                                              parseInt(e.target.value) === 0
                                            ) {
                                              message.destroy();
                                              message.info("Enter valid value");
                                              return;
                                            }
                                            const reg = /^-?\d*(\.\d*)?$/;
                                            if (
                                              reg.test(e.target.value) ||
                                              e.target.value === "" ||
                                              e.target.value === "-"
                                            ) {
                                              if (
                                                text?.min_price !==
                                                e.target.value
                                              ) {
                                                setIsUpdateMinPrice(true);
                                              }
                                              setList(
                                                list?.map((d) => {
                                                  if (d.id === text.id) {
                                                    return {
                                                      ...d,

                                                      min_price: e.target.value,
                                                    };
                                                  }
                                                  return { ...d };
                                                })
                                              );
                                            }
                                          }}
                                        />
                                      </label>
                                    </div>
                                    <div className="col-12 col-md-4 align-items-center justify-content-between d-flex py-1 px-15">
                                      <label
                                        className="fs-6"
                                        style={{ fontWeight: "500" }}
                                      >
                                        COGS :
                                      </label>

                                      <label
                                        className="fs-5"
                                        style={{ fontWeight: "600" }}
                                      >
                                        ${text?.cogs || "0"}
                                      </label>
                                    </div>
                                    <div className="col-12 col-md-4 align-items-center justify-content-between d-flex py-1 px-15">
                                      <label
                                        className="fs-6"
                                        style={{ fontWeight: "500" }}
                                      >
                                        Sales Velocity :
                                      </label>

                                      <label
                                        className="fs-5"
                                        style={{ fontWeight: "600" }}
                                      >
                                        {text?.sales_velocity || "0"}/day
                                      </label>
                                    </div>

                                    <div className="col-12 col-md-4 align-items-center justify-content-between d-flex py-1 px-15">
                                      <label
                                        className="fs-6"
                                        style={{ fontWeight: "500" }}
                                      >
                                        Max Price :
                                      </label>

                                      <label
                                        className="fs-5"
                                        style={{ fontWeight: "600" }}
                                      >
                                        <Input
                                          value={text?.max_price}
                                          onPressEnter={(e) => {
                                            if (
                                              parseInt(e.target.value) === 0
                                            ) {
                                              message.destroy();
                                              message.info("Enter max value");
                                              return;
                                            }
                                            const reg = /^-?\d*(\.\d*)?$/;
                                            if (
                                              reg.test(e.target.value) ||
                                              e.target.value === ""
                                            ) {
                                              if (isUpdateMaxPrice) {
                                                if (
                                                  text?.max_price >=
                                                  text?.min_price
                                                ) {
                                                  // message.destroy();
                                                  // message.loading(
                                                  //   "Loading...",
                                                  //   0
                                                  // );
                                                  // RepricingProductUpdateAction(
                                                  //   text.id,
                                                  //   {
                                                  //     max_price:
                                                  //       e.target
                                                  //         .value,
                                                  //   }
                                                  // );
                                                } else {
                                                  message.destroy();

                                                  message.info(
                                                    "Max price must be grather than min price"
                                                  );
                                                  return;
                                                }
                                              }
                                            }
                                          }}
                                          onBlur={(e) => {
                                            if (
                                              parseInt(e.target.value) === 0
                                            ) {
                                              message.destroy();
                                              message.info("Enter max value");
                                              return;
                                            }
                                            const reg = /^-?\d*(\.\d*)?$/;
                                            if (
                                              reg.test(e.target.value) ||
                                              e.target.value === ""
                                            ) {
                                              if (isUpdateMaxPrice) {
                                                if (
                                                  text?.max_price >=
                                                  text?.min_price
                                                ) {
                                                  // message.destroy();
                                                  // message.loading(
                                                  //   "Loading...",
                                                  //   0
                                                  // );
                                                  // RepricingProductUpdateAction(
                                                  //   text.id,
                                                  //   {
                                                  //     max_price:
                                                  //       e.target
                                                  //         .value,
                                                  //   }
                                                  // );
                                                } else {
                                                  message.destroy();
                                                  message.info(
                                                    "Max price must be grather than min price"
                                                  );
                                                  return;
                                                }
                                              }
                                            }
                                          }}
                                          onChange={(e) => {
                                            if (
                                              parseInt(e.target.value) === 0
                                            ) {
                                              message.destroy();
                                              message.info("Enter valid value");
                                              return;
                                            }
                                            const reg = /^-?\d*(\.\d*)?$/;
                                            if (
                                              reg.test(e.target.value) ||
                                              e.target.value === "" ||
                                              e.target.value === "-"
                                            ) {
                                              if (reg.test(e.target.value)) {
                                                if (
                                                  text?.max_price !==
                                                  e.target.value
                                                ) {
                                                  setIsUpdateMaxPrice(true);
                                                }
                                                setList(
                                                  list?.map((d) => {
                                                    if (d.id === text.id) {
                                                      return {
                                                        ...d,

                                                        max_price:
                                                          e.target.value,
                                                      };
                                                    }
                                                    return { ...d };
                                                  })
                                                );
                                              }
                                            }
                                          }}
                                        />
                                      </label>
                                    </div>
                                    <div className="col-12 col-md-4 align-items-center justify-content-between d-flex py-1 px-15">
                                      <label
                                        className="fs-6"
                                        style={{ fontWeight: "500" }}
                                      >
                                        Buy Box :
                                      </label>

                                      <label
                                        className="fs-5"
                                        style={{ fontWeight: "600" }}
                                      >
                                        ${text?.buy_box_price || "0"}
                                      </label>
                                    </div>

                                    <div className="col-12 col-md-4 align-items-center justify-content-between d-flex py-1 px-15">
                                      <label
                                        className="fs-6"
                                        style={{ fontWeight: "500" }}
                                      >
                                        Sales Velocity :
                                      </label>

                                      <label
                                        className="fs-5"
                                        style={{ fontWeight: "600" }}
                                      >
                                        {text?.sales_velocity || "0"}/day
                                      </label>
                                    </div>

                                    <div className="col-12 col-md-4 align-items-center justify-content-between d-flex py-1 px-15">
                                      <label
                                        className="fs-6"
                                        style={{ fontWeight: "500" }}
                                      >
                                        Repricer Rule :
                                      </label>

                                      <label
                                        className="fs-5"
                                        style={{ fontWeight: "600" }}
                                      >
                                        <Select
                                          placeholder="Repricing Rule"
                                          getPopupContainer={(triggerNode) =>
                                            document.getElementById(
                                              "repricing_products"
                                            ).parentNode
                                          }
                                          allowClear
                                          value={
                                            ruleList?.length === 0
                                              ? null
                                              : text?.repricing_rule_id
                                              ? parseInt(
                                                  text?.repricing_rule_id
                                                )
                                              : null
                                          }
                                          style={{ width: "100%" }}
                                          loading={rulesLoading}
                                          onChange={(e, _) => {
                                            // showConfirm(text, e, _);
                                          }}
                                          options={ruleList?.map((d) => {
                                            return {
                                              label: d?.rule_name,
                                              value: parseInt(d?.id),
                                              ...d,
                                            };
                                          })}
                                        />
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="separator separator-dashed my-7"
                                  style={{
                                    boxShadow: "0px 0px 7px 1px #e1e0f1",
                                  }}
                                ></div>
                              </div>
                            </>
                          );
                        })}
                      </div>
                      <Pagination
                        loading={loading || list?.length === 0}
                        pageSize={pageSize}
                        page={page}
                        totalPage={totalPage}
                        onPerPage={onPerPage}
                        onPageNo={onPageNo}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
