import { Skeleton, Tag, Tooltip } from "antd";
import React, { useState } from "react";
import { NumberWithCommas } from "../../../../../config";
import OrderStatusModal from "../order-status-modal";

const TabItemDetails = (props) => {
  const { itemDetails, loading } = props;
  const loadingArr = [1, 2, 3, 4, 5, 6];
  const loadingArr2 = [1, 2, 3, 4];
  const [isOpenOrderStatusModal, setIsOpenOrderStatusModal] = useState(false);
  const [allStatusData, setAllStatusData] = useState([]);
  return (
    <>
      <div className="row gy-5 g-xl-5 ">
        {/*begin::Col*/}
        <div className="col-xxl-12">
          {/*begin::Tables Widget 9*/}
          <div className="card card-xxl-stretch mb-5 mb-xl-8">
            {/*begin::Header*/}
            <div className="card-header border-bottom-dashed">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bolder text-dark">
                  Items Details
                </span>
                {/* <span class="text-gray-400 pt-1 fw-semibold fs-6">Sales analytics by SKU</span> */}
              </h3>
              <div className="card-toolbar"></div>
            </div>
            {/*end::Header*/}
            {/*begin::Body*/}
            <div className="card-body p-9">
              <div className="row g-5 mb-0">
                <div className="col-md-6 ">
                  {loading &&
                    loadingArr?.map((d) => {
                      return (
                        <div className="row mb-2">
                          <div className="col-lg-4 fw-normal text-gray-700 fs-6">
                            <Skeleton.Button active block size="medium" />
                          </div>
                          <div className="col-lg-8">
                            <span className="fw-bold fs-6 text-gray-800 text-two">
                              <Skeleton.Button active block size="medium" />
                            </span>
                          </div>
                        </div>
                      );
                    })}
                </div>

                <div className="col-md-5 mx-auto">
                  {loading &&
                    loadingArr2?.map((d) => {
                      return (
                        <>
                          <div className=" bg-light p-7 px-7 rounded-1">
                            <div className="row mb-7">
                              <label className="col-lg-4 fw-bold text-dark fs-6">
                                <Skeleton.Button active block size="medium" />
                              </label>
                              <div className="col-lg-8 fv-row">
                                <span className="fw-bold text-gray-800 fs-6">
                                  <Skeleton.Button active block size="medium" />
                                </span>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                </div>
              </div>
              {!loading &&
                itemDetails?.map((d) => {
                  const newStatus = JSON.parse(d?.order_line_status_details);
                  const myNewStatus = newStatus?.map((ns) => {
                    return {
                      status: ns?.status,
                      quantity: ns?.statusQuantity?.amount,
                    };
                  });
                  const statusArr = newStatus?.map((ns) => {
                    return ns?.status;
                  });
                  const isAcknowledge = statusArr?.includes("Acknowledged");
                  console.log(
                    myNewStatus,
                    statusArr,
                    newStatus?.[0]?.statusQuantity?.unitOfMeasurement,
                    "-myNewStatus"
                  );

                  let statusQuantities = {};

                  // Loop through the orders array
                  myNewStatus?.forEach((order) => {
                    // Check if the status exists in the statusQuantities object
                    if (statusQuantities.hasOwnProperty(order.status)) {
                      // If it exists, add the quantity to the existing value
                      statusQuantities[order.status] += parseInt(
                        order.quantity
                      );
                    } else {
                      // If it doesn't exist, create a new property and set its value to the quantity
                      statusQuantities[order.status] = parseInt(order.quantity);
                    }
                  });
                  console.log(statusQuantities, "-statusQuantities");
                  return (
                    <>
                      <div className="row g-5 mb-0">
                        <div className="col-md-7 ">
                          {/*begin::Row*/}
                          <div className="row mb-7">
                            <div className="col-lg-4 fw-normal text-gray-700 fs-6"></div>
                            <div className="col-lg-8">
                              {/* <Tag className="fw-bold  fs-6" color="blue">
                                Orderline {d?.order_line_number || ""}
                              </Tag> */}
                            </div>
                          </div>
                          <div className="row mb-7">
                            <div className="col-lg-4 fw-normal text-gray-700 fs-6">
                              <img
                                src={d?.product_image}
                                style={{ width: "70px" }}
                              />
                            </div>
                            <div className="col-lg-8">
                              <span className="fw-bold fs-6 text-primary text-two">
                                {d?.product_name || "-"}
                              </span>
                            </div>
                          </div>
                          {/*end::Row*/}
                          {/*begin::row*/}
                          <div className="row mb-7">
                            <label className="col-lg-4 fw-normal text-gray-700 fs-6">
                              SKU:
                            </label>
                            <div className="col-lg-8 fv-row">
                              <span className="fw-bold text-gray-800 fs-6">
                                {d?.sku || ""}
                              </span>
                            </div>
                          </div>
                          {/*end::row*/}
                          {/*begin::row*/}
                          <div className="row mb-7">
                            <label className="col-lg-4 fw-normal text-gray-700 fs-6">
                              Fulfilled by:
                            </label>
                            <div className="col-lg-8 fv-row">
                              <span className="fw-bold text-gray-800 fs-6">
                                <div className="d-flex align-items-center">
                                  {/* <img
                                src="assets/media/walmart.jpg"
                                className="h-25px rounded-circle w-25px me-2"
                                alt=""
                              /> */}
                                  <span>{d?.ship_node_type || "-"}</span>
                                </div>
                              </span>
                            </div>
                          </div>
                          {/*end::row*/}
                          {/*begin::row*/}
                          <div className="row mb-7">
                            <label className="col-lg-4 fw-normal text-gray-700 fs-6">
                              Total Order Quantity
                            </label>
                            <div className="col-lg-8 fv-row d-flex align-items-center">
                              <span className="fw-bold text-gray-800 fs-6">
                                {d?.product_units || "0"}
                              </span>
                            </div>
                          </div>
                          <div className="row mb-7">
                            <label className="col-lg-4 fw-normal text-gray-700 fs-6">
                              Remaining Order Quantity
                            </label>
                            <div className="col-lg-8 fv-row d-flex align-items-center">
                              <span
                                className={`fw-bold ${
                                  d?.acknowledge_line_quantity == 0
                                    ? "text-danger"
                                    : "text-green"
                                } fs-4`}
                              >
                                {d?.acknowledge_line_quantity || "0"}
                              </span>
                            </div>
                          </div>
                          {/*end::row*/}
                          {/*begin::row*/}
                          <div className="row mb-7">
                            <label className="col-lg-4 fw-normal text-gray-700 fs-6">
                              Status :
                            </label>
                            {/* <div className="col-lg-8 fv-row">
                              <div className="row mt-5">
                                <div className="col-3 text-center">
                                  <div
                                    style={{
                                      border: "1px solid #1d39c4",
                                    }}
                                  >
                                    <div
                                      className="p-3 fw-bold fs-6 me-0"
                                      style={{
                                        background: "#f0f5ff",
                                        color: "#1d39c4",
                                        borderBottom: "1px solid #1d39c4",
                                      }}
                                    >
                                      Shipped
                                    </div>
                                    <div className="p-3 fw-bold fs-4">
                                      {statusQuantities?.Shipped || 0}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-3 text-center">
                                  <div
                                    style={{
                                      border: "1px solid #ff4d4f",
                                    }}
                                  >
                                    <div
                                      className="p-3 fw-bold fs-6 me-0"
                                      style={{
                                        background: "#fff2f0",
                                        color: "#ff4d4f",
                                        borderBottom: "1px solid #ff4d4f",
                                      }}
                                    >
                                      Cancelled
                                    </div>
                                    <div className="p-3 fw-bold fs-4">
                                      {statusQuantities?.Cancelled || 0}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-3 text-center">
                                  <div
                                    style={{
                                      border: "1px solid #d46b08",
                                    }}
                                  >
                                    <div
                                      className="p-3 fw-bold fs-6 me-0"
                                      style={{
                                        background: "#fff7e6",
                                        color: "#d46b08",
                                        borderBottom: "1px solid #d46b08",
                                      }}
                                    >
                                      Acknowledged
                                    </div>
                                    <div className="p-3 fw-bold fs-4">
                                      {statusQuantities?.Acknowledged || 0}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-3 text-center">
                                  <div
                                    style={{
                                      border: "1px solid #52c41a",
                                    }}
                                  >
                                    <div
                                      className="p-3 fw-bold fs-6 me-0"
                                      style={{
                                        background: "#f6ffed",
                                        color: "#52c41a",
                                        borderBottom: "1px solid #52c41a",
                                      }}
                                    >
                                      Total Quantity
                                    </div>
                                    <div className="p-3 fw-bold fs-4">
                                      {d?.product_units || 0}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div> */}
                            <div className="col-lg-8 fv-row">
                              {/* <div
                                className="d-flex mt-5 p-5"
                                style={{
                                  border: "1px dashed #d4ccff",
                                  borderRadius: "11px",
                                }}
                              >
                                <div
                                  className="text-center"
                                  style={{
                                    width: "25%",
                                    borderRight: "1px dashed #d4ccff",
                                  }}
                                >
                                  <div>
                                    <Tag
                                      className="fw-bold fs-6 me-0"
                                      color="geekblue"
                                    >
                                      Shipped
                                    </Tag>
                                  </div>
                                  <div className="fw-bold fs-6 mt-3">
                                    {statusQuantities?.Shipped || 0}
                                  </div>
                                </div>
                                <div
                                  className="text-center"
                                  style={{
                                    width: "25%",
                                    borderRight: "1px dashed #d4ccff",
                                  }}
                                >
                                  <div>
                                    <Tag
                                      className="fw-bold fs-6 me-0"
                                      color="error"
                                    >
                                      Cancelled
                                    </Tag>
                                  </div>
                                  <div className="fw-bold fs-6 mt-3">
                                    {statusQuantities?.Cancelled || 0}
                                  </div>
                                </div>
                                <div
                                  className="text-center"
                                  style={{
                                    width: "25%",
                                    borderRight: "1px dashed #d4ccff",
                                  }}
                                >
                                  <div>
                                    <Tag
                                      className="fw-bold fs-6 me-0"
                                      color="orange"
                                    >
                                      Acknowledged
                                    </Tag>
                                  </div>
                                  <div className="fw-bold fs-6 mt-3">
                                    {statusQuantities?.Acknowledged || 0}
                                  </div>
                                </div>
                                <div
                                  className="text-center"
                                  style={{
                                    width: "25%",
                                  }}
                                >
                                  <div>
                                    <Tag
                                      className="fw-bold fs-6 me-0"
                                      color="success"
                                    >
                                      Total Items
                                    </Tag>
                                  </div>
                                  <div className="fw-bold fs-6 mt-3">
                                    {d?.product_units || 0}
                                  </div>
                                </div>
                              </div> */}
                              {Object?.entries(statusQuantities)?.map(
                                (ms, msi) => {
                                  return (
                                    <span
                                      className="fw-bold text-gray-800 fs-6"
                                      key={msi}
                                    >
                                      <div className="d-flex align-items-center">
                                        <Tooltip
                                          title="View orderline status details"
                                          placement="bottom"
                                        >
                                          {" "}
                                          <Tag
                                            color={
                                              ms?.[0] === "Cancelled"
                                                ? "error"
                                                : ms?.[0] === "Shipped"
                                                ? "geekblue"
                                                : ms?.[0] === "Delivered"
                                                ? "green"
                                                : ms?.[0] === "Acknowledged"
                                                ? "orange"
                                                : "purple"
                                            }
                                            style={{
                                              textDecoration: "underline",
                                            }}
                                            className="fw-bold my-1  cursor-pointer"
                                            onClick={() => {
                                              setIsOpenOrderStatusModal(true);
                                              setAllStatusData(newStatus);
                                            }}
                                          >
                                            {ms?.[0]} : {ms?.[1]}
                                          </Tag>
                                        </Tooltip>
                                      </div>
                                    </span>
                                  );
                                }
                              )}
                            </div>
                          </div>
                          {/*end::row*/}
                        </div>
                        <div className="col-md-5 mx-auto">
                          <div className=" bg-light p-7 px-7 rounded-1">
                            {/*begin::row*/}
                            <div className="row mb-7">
                              <label className="col-lg-6 fw-bold text-dark fs-6">
                                Subtotal (1 Item)
                              </label>
                              <div className="col-lg-6 fv-row">
                                <span className="fw-bold text-gray-800 fs-6">
                                  ${NumberWithCommas(d?.product_amount || 0)}
                                </span>
                              </div>
                            </div>
                            {/*end::row*/}
                            {/*begin::row*/}
                            <div className="row mb-7">
                              <label className="col-lg-6 fw-normal text-gray-700 fs-6">
                                Shipping Fee:
                              </label>
                              <div className="col-lg-6 fv-row">
                                <span className="fw-bold text-gray-800 fs-6">
                                  $
                                  {NumberWithCommas(
                                    d?.product_shipping_charge || 0
                                  )}
                                </span>
                              </div>
                            </div>
                            {/*end::row*/}
                            {/*begin::row*/}
                            <div className="row mb-7">
                              <label className="col-lg-6 fw-normal text-gray-700 fs-6">
                                Taxes:
                              </label>
                              <div className="col-lg-6 fv-row">
                                <span className="fw-bold text-gray-800 fs-6">
                                  ${NumberWithCommas(d?.product_tax || 0)}
                                </span>
                              </div>
                            </div>
                            {/*end::row*/}
                            {/*begin::row*/}
                            <div className="separator separator-dashed borde-gray-300 my-4 w-75" />
                            <div className="row mb-7">
                              <label className="col-lg-6 fw-bold text-dark fs-6">
                                Total:
                              </label>
                              <div className="col-lg-6 fv-row">
                                <span className="fw-bold text-green fs-6">
                                  $
                                  {NumberWithCommas(
                                    d?.total_product_amount || 0
                                  )}
                                </span>
                              </div>
                            </div>
                            {/*end::row*/}
                          </div>
                        </div>
                      </div>
                      <div className="separator separator-dashed my-5" />
                    </>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
      {isOpenOrderStatusModal && (
        <OrderStatusModal
          show={isOpenOrderStatusModal}
          onHide={() => {
            setIsOpenOrderStatusModal(false);
            setAllStatusData([]);
          }}
          data={allStatusData}
        />
      )}
    </>
  );
};

export default TabItemDetails;
