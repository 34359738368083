import {
  Alert,
  Checkbox,
  DatePicker,
  Input,
  Popover,
  Select,
  Tag,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import lodashObj from "lodash";
import { DateFormat, getUserId } from "../../../../../config";
import dayjs from "dayjs";
import { AllCarrierName } from "../../../../../config/static-select-option";
import { OrderWrapper } from "./style";
export default function (props) {
  const {
    show,
    onHide,
    processOrderOperation,
    orderData,
    setOrderData,
    selectedPoId,
    WalmartShipItemAction,
    WalmartCancelItemAction,
    fakeActionWalmartOrders,
    onSuccess,
  } = props;
  // const [list, setList] = useState(orderData);
  const [selectedCheckbox, setSelectedCheckbox] = useState([]);

  const [shipDate, setShipDate] = useState(dayjs());
  const [carrierInfo, setCarrierInfo] = useState({
    shipDateTime: Date.parse(dayjs()),
    carrierName: null,
    methodCode: null,
    trackingNumber: 0,
    trackingURL: "",
  });
  const [returnAddr, setReturnAddr] = useState({
    address1: "",
    city: "",
    state: "",
    postalCode: "",
    country: "",
    name: "",
    dayPhone: "",
    emailId: "",
  });

  const WalmartShipmentItemsRes = useSelector(
    (state) => state.WalmartOrders.WalmartShipmentItemsResponse || {}
  );
  const WalmartCancelItemsRes = useSelector(
    (state) => state.WalmartOrders.WalmartCancelItemsResponse || {}
  );

  useEffect(() => {
    if (orderData?.length > 0) {
      orderData?.map((d) => {
        setSelectedCheckbox((prev) => {
          return [...prev, d];
        });
      });
    }
  }, []);

  useEffect(() => {
    if (WalmartShipmentItemsRes.status === true) {
      message.destroy();
      message.success("Shipment - Request submit to walmart");
      onSuccess();
      onHide();
      fakeActionWalmartOrders("WalmartShipmentItemsResponse");
    } else if (WalmartShipmentItemsRes.status === false) {
      message.destroy();
      message.error("Request failed");
      fakeActionWalmartOrders("WalmartShipmentItemsResponse");
    }
  }, [WalmartShipmentItemsRes]);

  useEffect(() => {
    if (WalmartCancelItemsRes.status === true) {
      message.destroy();
      message.success("Cancel - Request submit to walmart");
      onSuccess();
      onHide();
      fakeActionWalmartOrders("WalmartCancelItemsResponse");
    } else if (WalmartCancelItemsRes.status === false) {
      message.destroy();
      message.error("Failed");
      fakeActionWalmartOrders("WalmartCancelItemsResponse");
    }
  }, [WalmartCancelItemsRes]);

  const ShipWalmartItems = () => {
    const finalJson = selectedCheckbox?.map((d) => {
      return {
        lineNumber: d?.order_line_number,
        intentToCancelOverride: false,
        sellerOrderId: d?.sub_seller_id,
        // sellerOrderId: d?.customer_order_id,
        orderLineStatuses: {
          orderLineStatus: [
            {
              status: "Shipped",
              statusQuantity: {
                unitOfMeasurement: d?.order_line_status_unit,
                amount: d?.order_line_status_quantity,
              },
              trackingInfo: {
                shipDateTime: carrierInfo?.shipDateTime,
                carrierName: {
                  carrier: carrierInfo?.carrierName,
                },
                methodCode: carrierInfo?.methodCode,
                trackingNumber: carrierInfo?.trackingNumber,
                trackingURL: carrierInfo?.trackingURL,
              },
              returnCenterAddress: d?.return_center_address
                ? JSON.parse(d?.return_center_address)
                : returnAddr,
            },
          ],
        },
      };
    });
    const newJson = {
      orderShipment: {
        orderLines: {
          orderLine: finalJson,
        },
      },
    };

    if (processOrderOperation === "ship") {
      message.destroy();
      message.loading("Loading...", 0);
      WalmartShipItemAction(selectedPoId, newJson);
    }
  };

  const CancelWalmartItems = () => {
    const isValid = selectedCheckbox?.filter((d) => !d?.cancellation_reason);

    if (isValid?.length > 0) {
      message.destroy();
      message.warning("Please select reason");
      return;
    } else {
      const finalJson = selectedCheckbox?.map((d) => {
        return {
          lineNumber: d?.order_line_number,
          orderLineStatuses: {
            orderLineStatus: [
              {
                status: "Cancelled",
                cancellationReason: d?.cancellation_reason,
                statusQuantity: {
                  unitOfMeasurement: d?.order_line_status_unit,
                  amount: d?.order_line_status_quantity,
                },
              },
            ],
          },
        };
      });
      const newJson = {
        orderCancellation: {
          orderLines: {
            orderLine: finalJson,
          },
        },
      };

      if (processOrderOperation === "cancel") {
        message.destroy();
        message.loading("Loading...", 0);
        WalmartCancelItemAction(selectedPoId, newJson);
      }
    }
  };

  const setCarrierInfoValue = (key, value) => {
    const newObj = { ...carrierInfo };
    newObj[key] = value;
    setCarrierInfo({ ...newObj });
  };
  const setReturnAddressValue = (key, value) => {
    const newObj = { ...returnAddr };
    newObj[key] = value;
    setReturnAddr({ ...newObj });
  };

  // const addrJson = [
  //   {
  //     shipNode: "99351656957153281",
  //     nodeType: "3PL",
  //     shipNodeName: "MP_Deliver",
  //     status: "INACTIVE",
  //     timeZone: "EST",
  //     distributorSupportedServices: ["TWO_DAY_DELIVERY"],
  //     addressLine1: "110 Sutter St. Fl 9",
  //     city: "San Francisco",
  //     state: "CA",
  //     country: "USA",
  //     postalCode: "94104",
  //     shippingDetails: [
  //       {
  //         twoDayShipping: [
  //           {
  //             carrierMethodName: "3PL_CARRIER",
  //             carrierMethodType: "GROUND",
  //           },
  //         ],
  //       },
  //     ],
  //   },
  //   {
  //     shipNode: "99351656957153281",
  //     nodeType: "3PL",
  //     shipNodeName: "MP_Deliverr",
  //     status: "INACTIVE",
  //     timeZone: "EST",
  //     distributorSupportedServices: ["TWO_DAY_DELIVERY"],
  //     addressLine1: "110 Sutter St. Fl 9",
  //     city: "San Francisco",
  //     state: "CA",
  //     country: "USA",
  //     postalCode: "94104",
  //     shippingDetails: [
  //       {
  //         twoDayShipping: [
  //           {
  //             carrierMethodName: "3PL_CARRIER",
  //             carrierMethodType: "GROUND",
  //           },
  //         ],
  //       },
  //     ],
  //   },
  // ];

  return (
    <>
      <Modal show={show} onHide={onHide} size="xl" centered backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>
            {processOrderOperation === "ship" ? "Ship Items" : "Cancel Items"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <OrderWrapper>
            <div style={{ height: "600px", overflow: "auto" }}>
              {processOrderOperation === "ship" && (
                <div className="row mx-0 px-0">
                  <div className="col-12 my-1">
                    <label className="fw-bold fs-5 text-primary ">
                      Carrier Info
                    </label>{" "}
                    {/* <div className="separator separator-solid my-3" /> */}
                  </div>{" "}
                  <div className="col-3 my-1">
                    <label className="fw-bold required">Ship Date</label>
                    <DatePicker
                      onChange={(date, dateString) => {
                        setCarrierInfoValue(
                          "shipDateTime",
                          Date.parse(dateString)
                        );
                        setShipDate(date);
                      }}
                      value={shipDate}
                      style={{ width: "100%" }}
                      size="large"
                      className="ant_common_input"
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentNode
                      }
                      format={DateFormat}
                      needConfirm={false}
                    />
                  </div>
                  <div className="col-3 my-1">
                    <label className="fw-bold required">Carrier Name</label>
                    <Select
                      options={AllCarrierName?.map((d) => {
                        return { label: d, value: d };
                      })}
                      value={carrierInfo?.carrierName}
                      onChange={(e) => {
                        setCarrierInfoValue("carrierName", e);
                      }}
                      placeholder="Carrier Name"
                      size="large"
                      style={{ width: "100%" }}
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentNode
                      }
                    />
                  </div>
                  <div className="col-3 my-1">
                    <label className="fw-bold required">Method Code</label>
                    <Select
                      options={[
                        {
                          label: "Standard",
                          value: "Standard",
                        },
                        {
                          label: "Express",
                          value: "Express",
                        },
                      ]}
                      value={carrierInfo?.methodCode}
                      onChange={(e) => {
                        setCarrierInfoValue("methodCode", e);
                      }}
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentNode
                      }
                      style={{ width: "100%" }}
                      placeholder="Method Code"
                      size="large"
                    />
                    {/* <Input
                      size="large"
                      className="ant_common_input"
                      value={carrierInfo?.methodCode}
                      onChange={(e) => {
                        const newObj = { ...carrierInfo };
                        newObj.methodCode = e.target.value;
                        setCarrierInfo({ ...newObj });
                      }}
                      placeholder="Method Code"
                    /> */}
                  </div>
                  <div className="col-3 my-1">
                    <label className="fw-bold required">Tracking Number</label>
                    <Input
                      size="large"
                      className="ant_common_input"
                      defaultValue={0}
                      value={carrierInfo?.trackingNumber}
                      onBlur={(e) => {
                        if (!e.target.value) {
                          setCarrierInfoValue("trackingNumber", 0);
                        }
                      }}
                      onChange={(e) => {
                        let value = e.target.value;
                        value = value.replace(/^0+/, "");
                        if (carrierInfo?.trackingNumber == 0 && value != "0") {
                          value = value.replace("0", "");
                        }
                        const isNumeric = /^[0-9]*$/.test(value);
                        if (isNumeric || value === "") {
                          setCarrierInfoValue("trackingNumber", value);
                        }
                      }}
                      placeholder="Tracking Number"
                    />
                  </div>{" "}
                  <div className="col-3 my-1">
                    <label className="fw-bold required">Tracking URL</label>
                    <Input
                      size="large"
                      className="ant_common_input"
                      value={carrierInfo?.trackingURL}
                      onChange={(e) => {
                        setCarrierInfoValue("trackingURL", e.target.value);
                      }}
                      placeholder="Tracking URL"
                    />
                  </div>
                  <div className="separator separator-dashed my-3" />
                  <div className="col-12 mb-1">
                    <label className="fw-bold text-primary fs-5">
                      Return Center Address
                    </label>{" "}
                    {/* <div className="separator separator-solid my-3" /> */}
                  </div>
                  <div className="col-3 my-1">
                    <label className="fw-bold required">Address 1</label>
                    <Input
                      size="large"
                      className="ant_common_input"
                      value={carrierInfo?.address1}
                      onChange={(e) => {
                        setReturnAddressValue("address1", e.target.value);
                      }}
                      placeholder="Address 1"
                    />
                  </div>
                  <div className="col-3 my-1">
                    <label className="fw-bold required">City</label>
                    <Input
                      size="large"
                      className="ant_common_input"
                      value={carrierInfo?.city}
                      onChange={(e) => {
                        setReturnAddressValue("city", e.target.value);
                      }}
                      placeholder="City"
                    />
                  </div>
                  <div className="col-3 my-1">
                    <label className="fw-bold required">State</label>
                    <Input
                      size="large"
                      className="ant_common_input"
                      value={carrierInfo?.state}
                      onChange={(e) => {
                        setReturnAddressValue("state", e.target.value);
                      }}
                      placeholder="State"
                    />
                  </div>
                  <div className="col-3 my-1">
                    <label className="fw-bold required">Country</label>
                    <Input
                      size="large"
                      className="ant_common_input"
                      value={carrierInfo?.country}
                      onChange={(e) => {
                        setReturnAddressValue("country", e.target.value);
                      }}
                      placeholder="Country"
                    />
                  </div>
                  <div className="col-3 my-1">
                    <label className="fw-bold required">Postal Code</label>
                    <Input
                      size="large"
                      className="ant_common_input"
                      value={carrierInfo?.postalCode}
                      onChange={(e) => {
                        setReturnAddressValue("postalCode", e.target.value);
                      }}
                      placeholder="Postal Code"
                    />
                  </div>
                  {/* <div className="col-6 my-1">
                    <label className="fw-bold required">Address</label>
                    <Select
                      placeholder="Select Address"
                      options={addrJson?.map((d, i) => {
                        console.log(d, "addrd-----------");
                        return {
                          label: (
                            <div>
                              <label className="fw-bolder text-primary">
                                {d?.shipNodeName}-
                              </label>
                              <label>{d?.nodeType}</label>
                              <br />
                              <span>
                                {d?.addressLine1},{d?.city},{d?.state},
                                {d?.country},{d?.postalCode}
                              </span>
                            </div>
                          ),
                          value: d?.shipNodeName,
                          ...d,
                        };
                      })}
                      onChange={(e, _) => {
                        const newObj = { ...returnAddr };
                        newObj.name = _.name;
                        newObj.address1 = _.addressLine1;
                        newObj.city = _.city;
                        newObj.state = _.state;
                        newObj.postalCode = _.postalCode;
                        newObj.country = _.country;
                        newObj.dayPhone = _.dayPhone;
                        newObj.emailId = _.email;
                        setReturnAddr({ ...newObj });
                      }}
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentNode
                      }
                      style={{ width: "100%" }}
                      size="large"
                    />
                  </div> */}
                  <div className="col-12 mt-7">
                    <label className="fw-bold fs-5">Item List</label>{" "}
                    <div className="separator separator-solid my-3" />
                  </div>{" "}
                </div>
              )}

              {orderData?.map((d) => {
                return (
                  <>
                    <div className="row my-4 px-0 mx-0">
                      <div className="col-1 d-flex justify-content-center">
                        <Checkbox
                          checked={
                            selectedCheckbox.filter(
                              (a) => a.order_line_number == d.order_line_number
                            )?.length == 1
                              ? true
                              : false
                          }
                          onChange={(e) => {
                            const checkboxId = d.order_line_number;
                            setSelectedCheckbox((prev) => {
                              if (e?.target?.checked) {
                                return [...prev, d];
                              } else {
                                return prev.filter(
                                  (b) => b.order_line_number !== checkboxId
                                );
                              }
                            });
                          }}
                        />
                        <div className="symbol symbol-50px ms-3">
                          {/* <img src={`${d?.product_image}`} alt="" /> */}
                        </div>
                      </div>
                      <div className="col-11">
                        <div className="row mb-2">
                          <div className="col-12">
                            <Popover
                              getPopupContainer={(triggerNode) =>
                                triggerNode.parentNode
                              }
                              content={
                                <div
                                  style={{
                                    height: "max-content",
                                    width: "300px",
                                  }}
                                >
                                  {d?.product_name}
                                </div>
                              }
                              placement="bottomLeft"
                            >
                              <div
                                className="text-primary mb-1 fw-bold text-one fs-5 popoverActionIconSingleLine"
                                style={{ minWidth: "100px" }}
                              >
                                {d?.product_name}
                              </div>
                            </Popover>
                          </div>
                        </div>
                        <div className="d-flex align-items-center">
                          <Tag color="magenta">SKU - {d?.sku}</Tag>

                          <Tag color="geekblue">
                            Orderline - {d?.order_line_number}
                          </Tag>
                        </div>
                        <div className="row mt-5">
                          {processOrderOperation === "cancel" && (
                            <div className="position-relative">
                              <Select
                                style={{ width: "350px" }}
                                getPopupContainer={(triggerNode) =>
                                  triggerNode.parentNode
                                }
                                placeholder="Select Reason"
                                size="medium"
                                value={d?.cancellation_reason || null}
                                onChange={(e) => {
                                  setOrderData((prev) =>
                                    prev?.map((l) => {
                                      if (
                                        l?.order_line_number ===
                                        d?.order_line_number
                                      ) {
                                        return { ...l, cancellation_reason: e };
                                      }
                                      return { ...l };
                                    })
                                  );
                                  setSelectedCheckbox((prev) =>
                                    prev?.map((l) => {
                                      if (
                                        l?.order_line_number ===
                                        d?.order_line_number
                                      ) {
                                        return { ...l, cancellation_reason: e };
                                      }
                                      return { ...l };
                                    })
                                  );
                                }}
                                options={[
                                  {
                                    label: "SELLER_CANCEL_PRICING_ERROR",
                                    value: "SELLER_CANCEL_PRICING_ERROR",
                                  },
                                  {
                                    label: "SELLER_CANCEL_OUT_OF_STOCK",
                                    value: "SELLER_CANCEL_OUT_OF_STOCK",
                                  },
                                  {
                                    label: "SELLER_CANCEL_FRAUD_STOP_SHIPMENT",
                                    value: "SELLER_CANCEL_FRAUD_STOP_SHIPMENT",
                                  },
                                  {
                                    label:
                                      "SELLER_CANCEL_ADDRESS_NOT_SERVICEABLE",
                                    value:
                                      "SELLER_CANCEL_ADDRESS_NOT_SERVICEABLE",
                                  },
                                ]}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="separator separator-dashed mb-4" />
                  </>
                );
              })}
            </div>
          </OrderWrapper>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-light-danger"
            onClick={onHide}
          >
            Close
          </button>
          {/* {getUserId() == 8 && ( */}
          <button
            type="submit"
            className="btn btn-primary"
            disabled={
              processOrderOperation === "ship"
                ? Object.entries(carrierInfo)?.filter((d) => !d?.[1])?.length >
                    0 ||
                  Object.entries(returnAddr)?.filter(
                    (d) =>
                      d?.[0] !== "name" &&
                      d?.[0] !== "dayPhone" &&
                      d?.[0] !== "emailId" &&
                      !d?.[1]
                  )?.length > 0 ||
                  selectedCheckbox?.length == 0
                : selectedCheckbox?.length == 0
            }
            onClick={() => {
              if (selectedCheckbox?.length > 0) {
                if (processOrderOperation === "ship") {
                  ShipWalmartItems();
                } else if (processOrderOperation === "cancel") {
                  CancelWalmartItems();
                }
              } else {
                message.destroy();
                message.info("Please select items");
              }
            }}
          >
            {processOrderOperation === "ship" ? "Ship Items" : "Cancel Items"}
          </button>
          {/* )} */}
        </Modal.Footer>
      </Modal>
    </>
  );
}
