import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import {
  Checkbox,
  Dropdown,
  Popover,
  Select,
  Skeleton,
  Table,
  Tag,
  theme,
} from "antd";
import dayjs from "dayjs";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import IvcsrTable from "../../../../component/ivcsr-table";
import Loading from "../../../../component/loading";
import NoData from "../../../../component/no-data";
import Pagination from "../../../../component/pagination";
import RangePickerIVCSR from "../../../../component/range-picker";
import {
  DateFormat,
  DefaultPerPage,
  NumberWithCommas,
} from "../../../../config";
import ItemRecommModal from "./walmart-keyword/item-recomm-modal";
import KeywordRecommModal from "./walmart-keyword/keyword-recomm-modal";
const { useToken } = theme;
const WalmartKeyword = (props) => {
  const { token } = useToken();
  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
    minWidth: "350px",
  };
  const {
    GetWalmartBidRuleAdGroupList,
    GetWalmartBidRuleCampaignList,
    fakeActionWalmartBidManagement,
    GetWMKeywordSummeryData,
    GetWMKeywordChartData,
    GetWMKeywordListData,
    GetWmSearchTrendsData,
    GetWmItemRecommendation,
    GetWmKeywordRecommendation,
    fakeActionWalmartKeyword,
  } = props;
  const [swiper, setSwiper] = useState(null);
  const [search_trends_list, set_search_trends_list] = useState([]);
  const [search_list_loading, set_search_list_loading] = useState(true);
  const [manage_keyword_loading, set_manage_keyword_loading] = useState(true);
  const [performanceSummary, setPerformanceSummary] = useState({});
  const [selectedFilterTab, setSelectedFilterTab] = useState("All");
  const [perform_summary_loading, set_perform_summary_loading] = useState(true);
  const [wm_chart_loading, set_wm_chart_loading] = useState(true);
  const [campaign_list, set_campaign_list] = useState([]);
  const [ad_group_list, set_ad_group_list] = useState([]);
  const [keyword_list, set_keyword_list] = useState([]);
  const [chartSeries, setChartSeries] = useState([]);
  const [chartDate, setChartDate] = useState();
  const [isOpenItemRecommModal, setIsOpenItemRecommModal] = useState(false);
  const [isOpenKeywordRecommModal, setIsOpenKeywordRecommModal] =
    useState(false);
  const [all_filters, set_all_filters] = useState({
    start_date: dayjs().add(-30, "d"),
    end_date: dayjs(),
    campaign: null,
    ad_group: null,
    attribute: "attributed3days",
  });
  const [item_recomm_filter, set_item_recomm_filter] = useState({
    page: 1,
    perPage: DefaultPerPage,
  });
  const [keyword_recomm_filter, set_keyword_recomm_filter] = useState({
    page: 1,
    perPage: DefaultPerPage,
  });
  const [filterList, setFilterList] = useState({
    performance: {
      impressions: "Impressions",
      clicks: "Clicks",
      ctr: "CTR",
      ad_spend: "Ad Spend",
    },
    conversion: {
      unit_sold: "Unit Sold",
      orders: "Orders",
      cr_unit_sold: "Conversion Rate (Unit Sold Based)",
      cr_order_sold: "Conversion Rate (Orders Sold Based)",
      sales_revenue: "Total Attr. Sales Revenue",
      advertised_sku_sales: "Advertised SKU Sales",
      other_sku_sales: "Other SKU Sales",
      roas: "RoAS",
      new_to_brand_order: "New-to-Brand Orders",
    },
  });
  const [selectedFilters, setSelectedFilters] = useState([
    "impression",
    "ad_clicks",
    "CTR",
    "ad_spend",
    "units",
    "orders",
    "CRUnitSold",
    "otherSkuSales",
    "CROrderSold",
    "sales_revenue",
    "new_to_brand_order",
    "ROAS",
    "advertisedSkuSales",
    "in_store_advertised_sales",
    "in_store_attributed_sales",
    "in_store_orders",
    "in_store_other_sales",
    "in_store_units_sold",
  ]);
  const [search_trends_filter, set_search_trends_filter] = useState({
    page: 1,
    perPage: DefaultPerPage,
    totalPage: 0,
  });
  const [item_recommendation_list, set_item_recommendation_list] = useState([]);
  const [
    item_recommendation_total_record,
    set_item_recommendation_total_record,
  ] = useState([]);
  const [keyword_recommendation_list, set_keyword_recommendation_list] =
    useState([]);
  const [
    keyword_recommendation_total_record,
    set_keyword_recommendation_total_record,
  ] = useState([]);

  const GetWalmartBidRuleCampaignListRes = useSelector(
    (state) =>
      state.WalmartBidManagement.GetWalmartBidRuleCampaignListResponse || {}
  );
  const GetWalmartBidRuleAgGroupListRes = useSelector(
    (state) =>
      state.WalmartBidManagement.GetWalmartBidRuleAgGroupListResponse || {}
  );
  const GetWmKeywordSummeryDataRes = useSelector(
    (state) =>
      state.WalmartKeywordManagement.GetWmKeywordSummeryDataResponse || {}
  );
  const GetWmKeywordChartDataRes = useSelector(
    (state) =>
      state.WalmartKeywordManagement.GetWmKeywordChartDataResponse || {}
  );
  const GetWmKeywordListDataRes = useSelector(
    (state) => state.WalmartKeywordManagement.GetWmKeywordListDataResponse || {}
  );
  const GetWmSearchTrendsDataRes = useSelector(
    (state) =>
      state.WalmartKeywordManagement.GetWmSearchTrendsDataResponse || {}
  );
  const GetWmItemRecommendationRes = useSelector(
    (state) =>
      state.WalmartKeywordManagement.GetWmItemRecommendationResponse || {}
  );
  const GetWmKeywordRecommendationRes = useSelector(
    (state) =>
      state.WalmartKeywordManagement.GetWmKeywordRecommendationResponse || {}
  );

  useEffect(() => {
    const obj = {
      ...all_filters,
      start_date: moment(all_filters?.start_date?.$d).format("YYYY-MM-DD"),
      end_date: moment(all_filters?.end_date?.$d).format("YYYY-MM-DD"),
    };
    GetWalmartBidRuleAdGroupList();
    GetWalmartBidRuleCampaignList();
    GetWMKeywordSummeryData(obj);
    GetWMKeywordChartData(obj);
    GetWMKeywordListData(obj);
    GetWmSearchTrendsData(obj);
    GetWmItemRecommendation(item_recomm_filter);
    GetWmKeywordRecommendation(keyword_recomm_filter);
  }, []);

  useEffect(() => {
    if (GetWalmartBidRuleCampaignListRes?.status === true) {
      set_campaign_list(GetWalmartBidRuleCampaignListRes?.data?.records || {});
      fakeActionWalmartBidManagement("GetWalmartBidRuleCampaignListResponse");
    } else if (GetWalmartBidRuleCampaignListRes?.status === false) {
      fakeActionWalmartBidManagement("GetWalmartBidRuleCampaignListResponse");
    }
  }, [GetWalmartBidRuleCampaignListRes]);

  useEffect(() => {
    if (GetWalmartBidRuleAgGroupListRes?.status === true) {
      set_ad_group_list(GetWalmartBidRuleAgGroupListRes?.data?.records || {});
      fakeActionWalmartBidManagement("GetWalmartBidRuleAgGroupListResponse");
    } else if (GetWalmartBidRuleAgGroupListRes?.status === false) {
      fakeActionWalmartBidManagement("GetWalmartBidRuleAgGroupListResponse");
    }
  }, [GetWalmartBidRuleAgGroupListRes]);

  useEffect(() => {
    if (GetWmKeywordSummeryDataRes?.status === true) {
      set_perform_summary_loading(false);
      setPerformanceSummary(GetWmKeywordSummeryDataRes?.data?.data);
      fakeActionWalmartKeyword("GetWmKeywordSummeryDataResponse");
    } else if (GetWmKeywordSummeryDataRes?.status === false) {
      set_perform_summary_loading(false);
      setPerformanceSummary([]);
      fakeActionWalmartKeyword("GetWmKeywordSummeryDataResponse");
    }
  }, [GetWmKeywordSummeryDataRes]);

  useEffect(() => {
    if (GetWmKeywordChartDataRes?.status === true) {
      set_wm_chart_loading(false);
      const finalSeries = Object.entries(
        GetWmKeywordChartDataRes?.data?.data?.chartData
      )?.map((d) => {
        // console.log(d, "-------cahrt d");
        return {
          name: d?.[0]?.replace("_", " "),
          data: Object?.entries(d?.[1])?.map((d) => d?.[1]),
        };
      });
      setChartSeries(finalSeries);
      setChartDate(GetWmKeywordChartDataRes?.data?.data?.chartDate);

      fakeActionWalmartKeyword("GetWmKeywordChartDataResponse");
    } else if (GetWmKeywordChartDataRes?.status === false) {
      set_wm_chart_loading(false);
      setChartSeries([]);
      setChartDate([]);
      fakeActionWalmartKeyword("GetWmKeywordChartDataResponse");
    }
  }, [GetWmKeywordChartDataRes]);

  useEffect(() => {
    if (GetWmKeywordListDataRes?.status === true) {
      set_manage_keyword_loading(false);
      set_keyword_list(GetWmKeywordListDataRes?.data?.data || {});
      fakeActionWalmartKeyword("GetWmKeywordListDataResponse");
    } else if (GetWmKeywordListDataRes?.status === false) {
      set_manage_keyword_loading(false);
      fakeActionWalmartKeyword("GetWmKeywordListDataResponse");
    }
  }, [GetWmKeywordListDataRes]);

  useEffect(() => {
    if (GetWmSearchTrendsDataRes?.status === true) {
      set_search_list_loading(false);
      const itemsLoopArr = [1, 2, 3];
      const test = GetWmSearchTrendsDataRes?.data?.records?.map((d) => {
        const all_items = itemsLoopArr?.map((f) => {
          return {
            item_id: d?.[`item${f}_id`],
            item_name: d?.[`item${f}_name`],
            item_brand_name: d?.[`item${f}_brand_name`],
            item_click_share: d?.[`item${f}_click_share`],
            item_conversion_share: d?.[`item${f}_conversion_share`],
          };
        });

        return {
          items: all_items,
          advertiser_id: d?.advertiser_id,
          created_at: d?.created_at,
          id: d?.id,
          marketplace: d?.marketplace,
          marketplace_id: d?.marketplace_id,
          report_date: d?.report_date,
          search_keyword: d?.search_keyword,
          search_keyword_frequency_rank: d?.search_keyword_frequency_rank,
          system_event_process_id: d?.system_event_process_id,
        };
      });
      set_search_trends_filter({
        ...search_trends_filter,
        totalPage: GetWmSearchTrendsDataRes?.data?.pagination?.totalCount,
      });
      set_search_trends_list(test);
      fakeActionWalmartKeyword("GetWmSearchTrendsDataResponse");
    } else if (GetWmSearchTrendsDataRes?.status === false) {
      set_manage_keyword_loading(false);
      fakeActionWalmartKeyword("GetWmSearchTrendsDataResponse");
    }
  }, [GetWmSearchTrendsDataRes]);

  useEffect(() => {
    if (GetWmItemRecommendationRes?.status === true) {
      set_item_recommendation_total_record(
        GetWmItemRecommendationRes?.data?.pagination?.totalCount
      );
      set_item_recommendation_list(GetWmItemRecommendationRes?.data?.records);
      fakeActionWalmartKeyword("GetWmItemRecommendationResponse");
    } else if (GetWmItemRecommendationRes?.status === false) {
      fakeActionWalmartKeyword("GetWmItemRecommendationResponse");
    }
  }, [GetWmItemRecommendationRes]);

  useEffect(() => {
    if (GetWmKeywordRecommendationRes?.status === true) {
      set_keyword_recommendation_total_record(
        GetWmKeywordRecommendationRes?.data?.pagination?.totalCount
      );
      set_keyword_recommendation_list(
        GetWmKeywordRecommendationRes?.data?.records
      );
      fakeActionWalmartKeyword("GetWmKeywordRecommendationResponse");
    } else if (GetWmKeywordRecommendationRes?.status === false) {
      fakeActionWalmartKeyword("GetWmKeywordRecommendationResponse");
    }
  }, [GetWmKeywordRecommendationRes]);

  const handleNext = () => {
    if (swiper !== null) {
      swiper.slideNext();
    }
  };
  const handlePrev = () => {
    if (swiper !== null) {
      swiper.slidePrev();
    }
  };
  const chartOptions = {
    chart: {
      height: 350,
      type: "area",
      toolbar: { show: false },
      zoom: {
        enabled: false,
      },
    },
    colors: ["#4318FF", "#000", "#367d19"],
    dataLabels: { enabled: !1 },
    stroke: {
      show: !0,
      curve: "smooth",
      width: [2, 2, 2],
      dashArray: [0, 0],
      lineCap: "round",
    },

    xaxis: {
      tickPlacement: "between",
      tickAmount: window.innerWidth >= 992 ? 15 : 5,
      categories:
        chartDate?.map((d) => moment(new Date(d)).format("DD MMM YYYY")) || [],
      labels: {
        rotate: -50,
      },
    },
    //    yaxis: {
    // 	 labels: {
    // 	   formatter: function (_) {
    // 		 return _ + "";
    // 	   },
    // 	   offsetX: -12,
    // 	   offsetY: 0,
    // 	 },
    //    },
    yaxis: [
      {
        axisTicks: {
          show: true,
        },

        labels: {
          formatter: function (_) {
            return _ + "";
          },
          offsetX: -12,
          offsetY: 0,
        },
      },
      {
        opposite: true,
        axisTicks: {
          show: true,
        },

        labels: {
          formatter: function (_) {
            return _ + "";
          },
          offsetX: -12,
          offsetY: 0,
        },
      },
    ],
    grid: {
      strokeDashArray: 3,
      xaxis: { lines: { show: !0 } },
      yaxis: { lines: { show: !1 } },
    },
    legend: { show: true },
    fill: {
      type: "gradient",
      gradient: {
        type: "vertical",
        shadeIntensity: 1,
        inverseColors: !1,
        opacityFrom: 0.41,
        opacityTo: 0.2,
        stops: [45, 100],
      },
    },
    tooltip: {
      marker: {
        show: true,
      },
      x: {
        show: true,
      },
      y: {
        formatter: function (
          value,
          { series, seriesIndex, dataPointIndex, w }
        ) {
          const withTitle_ = w?.config?.series?.[seriesIndex];
          console.log(withTitle_, "withTitle_");
          return withTitle_?.name !== "ad spend"
            ? value || 0
            : "$" + NumberWithCommas(value);
        },
      },
    },
  };

  const search_treands_columns = [
    // {
    //   title: "ID",
    //   dataIndex: "id",
    //   key: "id",
    //   width: 200,
    // },
    // {
    //   title: "Advertiser ID",
    //   dataIndex: "advertiser_id",
    //   key: "advertiser_id",
    // },
    // {
    //   title: "Created At",
    //   dataIndex: "created_at",
    //   key: "created_at",
    // },
    // {
    //   title: "Marketplace",
    //   dataIndex: "marketplace",
    //   key: "marketplace",
    // },
    // {
    //   title: "Marketplace ID",
    //   dataIndex: "marketplace_id",
    //   key: "marketplace_id",
    // },
    {
      title: "Report Date",
      dataIndex: "report_date",
      key: "report_date",
    },
    {
      title: "Search Keyword",
      dataIndex: "search_keyword",
      key: "search_keyword",
    },
    {
      title: "Search Keyword Frequency Rank",
      dataIndex: "search_keyword_frequency_rank",
      key: "search_keyword_frequency_rank",
    },
    // {
    //   title: "System Event Process ID",
    //   dataIndex: "system_event_process_id",
    //   key: "system_event_process_id",
    // },
  ];
  // Static Searc hTrends Data and Logic

  // useEffect(() => {
  //   const dataSource = [
  //     {
  //       id: "1",
  //       system_event_process_id: "SEP001",
  //       report_date: "2023-07-01",
  //       search_keyword: "example keyword",
  //       search_keyword_frequency_rank: "1",
  //       item1_id: "I001",
  //       item1_name: "Item 1",
  //       item1_brand_name: "Brand 1",
  //       item1_click_share: "10%",
  //       item1_conversion_share: "5%",
  //       item2_id: "I002",
  //       item2_name: "Item 2",
  //       item2_brand_name: "Brand 2",
  //       item2_click_share: "20%",
  //       item2_conversion_share: "10%",
  //       item3_id: "I003",
  //       item3_name: "Item 3",
  //       item3_brand_name: "Brand 3",
  //       item3_click_share: "30%",
  //       item3_conversion_share: "15%",
  //       marketplace_id: "M001",
  //       marketplace: "Marketplace 1",
  //       advertiser_id: "A001",
  //       created_at: "2023-07-01",
  //       updated_at: "2023-07-01",
  //     },
  //     {
  //       id: "1",
  //       system_event_process_id: "SEP001",
  //       report_date: "2023-07-01",
  //       search_keyword: "example keyword",
  //       search_keyword_frequency_rank: "1",
  //       item1_id: "I001",
  //       item1_name: "Item 1",
  //       item1_brand_name: "Brand 1",
  //       item1_click_share: "10%",
  //       item1_conversion_share: "5%",
  //       item2_id: "I002",
  //       item2_name: "Item 2",
  //       item2_brand_name: "Brand 2",
  //       item2_click_share: "20%",
  //       item2_conversion_share: "10%",
  //       item3_id: "I003",
  //       item3_name: "Item 3",
  //       item3_brand_name: "Brand 3",
  //       item3_click_share: "30%",
  //       item3_conversion_share: "15%",
  //       marketplace_id: "M001",
  //       marketplace: "Marketplace 1",
  //       advertiser_id: "A001",
  //       created_at: "2023-07-01",
  //       updated_at: "2023-07-01",
  //     },
  //   ];

  //   console.log(dataSource, "-dataSource");
  //   const itemsLoopArr = [1, 2, 3];
  //   const test = dataSource?.map((d) => {
  //     const all_items = itemsLoopArr?.map((f) => {
  //       return {
  //         item_id: d?.[`item${f}_id`],
  //         item_name: d?.[`item${f}_name`],
  //         item_brand_name: d?.[`item${f}_brand_name`],
  //         item_click_share: d?.[`item${f}_click_share`],
  //         item_conversion_share: d?.[`item${f}_conversion_share`],
  //       };
  //     });

  //     return {
  //       items: all_items,
  //       advertiser_id: d?.advertiser_id,
  //       created_at: d?.created_at,
  //       id: d?.id,
  //       marketplace: d?.marketplace,
  //       marketplace_id: d?.marketplace_id,
  //       report_date: d?.report_date,
  //       search_keyword: d?.search_keyword,
  //       search_keyword_frequency_rank: d?.search_keyword_frequency_rank,
  //       system_event_process_id: d?.system_event_process_id,
  //     };
  //   });
  //   set_search_trends_list(test);
  // }, []);

  const ExpandedRow = ({ record }) => {
    const itemColumns = [
      {
        title: "Item ID",
        dataIndex: "item_id",
        key: "item_id",
      },
      {
        title: "Item Name",
        dataIndex: "item_name",
        key: "item_name",
      },
      {
        title: "Item Brand Name",
        dataIndex: "item_brand_name",
        key: "item_brand_name",
      },
      {
        title: "Item Brand Name",
        dataIndex: "item_click_share",
        key: "item_click_share",
      },
      {
        title: "Item Conversion Share",
        dataIndex: "item_conversion_share",
        key: "item_conversion_share",
      },
    ];

    return (
      <Table
        columns={itemColumns}
        dataSource={record?.items}
        pagination={false}
      />
    );
  };

  const keywordColumns = [
    // {
    //   title: "#",
    //   dataIndex: "checkbox",
    //   key: "checkbox",
    //   width: 50,
    //   render: () => (
    //     <div className="form-check form-check-sm form-check-custom">
    //       <Checkbox />
    //     </div>
    //   ),
    // },
    {
      title: "#",
      width: 50,
      render: (text, i) => <div className="fw-bolder">{text?.key + 1}</div>,
    },
    {
      title: "Keyword Id",
      dataIndex: "keyword_id",
      key: "keyword_id",
      width: 100,
    },
    {
      title: "Searched Keyword",
      width: 130,
      render: (text) => (
        <div className="d-flex align-items-center">
          {/* <Switch /> */}
          <label
            className="ms-2 fw-bolder text-primary"
            style={{ textTransform: "capitalize" }}
          >
            {text?.searched_keyword}
          </label>
        </div>
      ),
    },
    {
      title: "Match Type",
      dataIndex: "match_type",
      key: "match_type",
      width: 100,
    },
    {
      title: "Suggested Bid",
      dataIndex: "suggested_bid",
      key: "suggested_bid",
      width: 100,
    },
    {
      title: "Bid",
      dataIndex: "bid",
      key: "bid",
      width: 50,
    },
    {
      title: "Campaign Name",
      dataIndex: "campaign_name",
      key: "campaign_name",
      width: 120,
    },
    {
      title: "Campaign Id",
      dataIndex: "campaign_id",
      key: "campaign_id",
      width: 100,
    },
    // {
    //   title: "Campaign Status",
    //   dataIndex: "campaign_status",
    //   key: "campaign_status",
    //   width: 120,
    // },
    {
      title: "Ad Group Id",
      dataIndex: "ad_group_id",
      key: "ad_group_id",
      width: 100,
    },
    {
      title: "Ad Group Name",
      dataIndex: "ad_group_name",
      key: "ad_group_name",
      width: 100,
    },
    // {
    //   title: "Ad Group Status",
    //   dataIndex: "adGroupStatus",
    //   key: "adGroupStatus",
    //   width: 100,
    // },
    {
      title: "Total Attributed Sales",
      dataIndex: "directAttributedSales",
      key: "directAttributedSales",
      width: 120,
      render: (text) => NumberWithCommas(text || 0),
    },
    {
      title: "RoAS",
      dataIndex: "ROAS",
      key: "ROAS",
      width: 100,
      render: (text) => NumberWithCommas(text || 0),
    },
    {
      title: "Ad Spend",
      dataIndex: "ad_spend",
      key: "ad_spend",
      width: 100,
      render: (text) => NumberWithCommas(text || 0),
    },
    {
      title: "Average CPC",
      dataIndex: "averageCPC",
      key: "averageCPC",
      width: 100,
      render: (text) => NumberWithCommas(text || 0),
    },
    {
      title: "Impressions",
      dataIndex: "impression",
      key: "impression",
      width: 100,
      render: (text) => NumberWithCommas(text || 0),
    },
    {
      title: "Clicks",
      dataIndex: "ad_clicks",
      key: "ad_clicks",
      width: 100,
      render: (text) => NumberWithCommas(text || 0),
    },
    {
      title: "CTR",
      dataIndex: "CTR",
      key: "CTR",
      width: 100,
      render: (text) => NumberWithCommas(text || 0),
    },
    {
      title: "CR (Orders Based)",
      dataIndex: "CROrderSold",
      key: "CROrderSold",
      width: 100,
      render: (text) => NumberWithCommas(text || 0),
    },
    {
      title: "Orders",
      dataIndex: "orders",
      key: "orders",
      width: 100,
      render: (text) => NumberWithCommas(text || 0),
    },
    {
      title: "Units",
      dataIndex: "units",
      key: "units",
      width: 100,
      render: (text) => NumberWithCommas(text || 0),
    },
  ];
  const onPageNo = (e) => {
    set_search_list_loading(true);
    const apiObj = {
      page: e,
      perPage: search_trends_filter?.perPage,
      start_date: moment(all_filters?.start_date?.$d).format("YYYY-MM-DD"),
      end_date: moment(all_filters?.end_date?.$d).format("YYYY-MM-DD"),
    };
    GetWmSearchTrendsData(apiObj);
    set_search_trends_filter({ ...search_trends_filter, page: e });
  };

  const onPerPage = (e) => {
    set_search_trends_filter({ ...search_trends_filter, page: 1, perPage: e });
    set_search_list_loading(true);
    const apiObj = {
      page: 1,
      perPage: e,
      start_date: moment(all_filters?.start_date?.$d).format("YYYY-MM-DD"),
      end_date: moment(all_filters?.end_date?.$d).format("YYYY-MM-DD"),
    };
    GetWmSearchTrendsData(apiObj);
  };
  return (
    <div className="d-flex flex-column flex-column-fluid fadeInRight">
      <div id="kt_app_content" className="app-content flex-column-fluid">
        <div
          id="kt_app_content_container"
          className="app-container container-fluid"
        >
          <div className="row g-5 g-xl-5 mb-0 ">
            <div className="col-12 my-3 d-flex flex-wrap">
              <div className="me-3">
                <RangePickerIVCSR
                  className="ant_common_input"
                  id="ivcsr_date_picker"
                  size="large"
                  allowClear={false}
                  style={{ width: "290px" }}
                  format={DateFormat}
                  value={[all_filters?.start_date, all_filters?.end_date]}
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  onChange={(e) => {
                    const filters_ = all_filters;
                    filters_.start_date = e?.[0];
                    filters_.end_date = e?.[1];
                    set_all_filters({ ...filters_ });
                    const obj = {
                      ...all_filters,
                      start_date: moment(e?.[0]?.$d).format("YYYY-MM-DD"),
                      end_date: moment(e?.[1]?.$d).format("YYYY-MM-DD"),
                    };
                    GetWMKeywordSummeryData(obj);
                    GetWMKeywordChartData(obj);
                    GetWmSearchTrendsData(obj);
                    GetWMKeywordListData(obj);
                  }}
                />
              </div>
              <div className="me-3">
                <Select
                  size="large"
                  style={{ width: "250px" }}
                  value={all_filters?.ad_group}
                  placeholder="Select Ad Group"
                  allowClear
                  onChange={(e) => {
                    set_all_filters({ ...all_filters, ad_group: e });
                    const obj = {
                      ...all_filters,
                      ad_group: e,
                      start_date: moment(all_filters?.start_date?.$d).format(
                        "YYYY-MM-DD"
                      ),
                      end_date: moment(all_filters?.end_date?.$d).format(
                        "YYYY-MM-DD"
                      ),
                    };
                    set_perform_summary_loading(true);
                    set_wm_chart_loading(true);
                    GetWMKeywordSummeryData(obj);
                    GetWMKeywordChartData(obj);
                    GetWMKeywordListData(obj);
                  }}
                  options={ad_group_list?.map((d) => {
                    return {
                      label: d?.ad_group_name,
                      value: d?.ad_group_id,
                    };
                  })}
                />
              </div>
              <div className="me-3">
                <Select
                  size="large"
                  style={{ width: "250px" }}
                  value={all_filters?.campaign}
                  placeholder="Select Campaign"
                  allowClear
                  onChange={(e) => {
                    set_all_filters({ ...all_filters, campaign: e });
                    const obj = {
                      ...all_filters,
                      campaign: e,
                      start_date: moment(all_filters?.start_date?.$d).format(
                        "YYYY-MM-DD"
                      ),
                      end_date: moment(all_filters?.end_date?.$d).format(
                        "YYYY-MM-DD"
                      ),
                    };
                    set_perform_summary_loading(true);
                    set_wm_chart_loading(true);
                    GetWMKeywordSummeryData(obj);
                    GetWMKeywordChartData(obj);
                    GetWMKeywordListData(obj);
                  }}
                  options={campaign_list?.map((d) => {
                    return {
                      label: d?.campaign_name,
                      value: d?.campaign_id,
                    };
                  })}
                />
              </div>
              <div className="me-3">
                <Select
                  size="large"
                  style={{ width: "250px" }}
                  value={all_filters?.attribute}
                  placeholder="Select Attribute"
                  onChange={(e) => {
                    set_all_filters({ ...all_filters, attribute: e });
                    const obj = {
                      ...all_filters,
                      attribute: e,
                      start_date: moment(all_filters?.start_date?.$d).format(
                        "YYYY-MM-DD"
                      ),
                      end_date: moment(all_filters?.end_date?.$d).format(
                        "YYYY-MM-DD"
                      ),
                    };
                    set_perform_summary_loading(true);
                    set_wm_chart_loading(true);
                    GetWMKeywordSummeryData(obj);
                    GetWMKeywordChartData(obj);
                    GetWMKeywordListData(obj);
                  }}
                  options={[
                    {
                      label: "3 DaysAttribution",
                      value: "attributed3days",
                    },
                    {
                      label: "14 DaysAttribution",
                      value: "attributed14days",
                    },
                    {
                      label: "30 DaysAttribution",
                      value: "attributed30days",
                    },
                  ]}
                />
              </div>
            </div>
          </div>

          <div className="row g-5 g-xl-5 ">
            <div className="col-12  mb-5 mb-xl-5 ">
              <div className="card card-flush h-md-100">
                <div className="card-header  min-h-50px">
                  <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bolder fs-2 text-dark">
                      All Campaigns Performance Summary
                    </span>
                  </h3>

                  <div className="card-toolbar">
                    <div>
                      {perform_summary_loading ? (
                        <Skeleton.Button size="large" active />
                      ) : (
                        <Dropdown
                          disabled={filterList?.length === 0}
                          trigger={["click"]}
                          dropdownRender={() => (
                            <div style={contentStyle}>
                              <div>
                                <div className="separator border-gray-200" />
                                <div
                                  style={{
                                    minHeight: "400px",
                                    minWidth: "50px",
                                    border: "1px solid #4419ff8a",
                                    borderRadius: "7px",
                                  }}
                                >
                                  <div
                                    className="d-flex flex-column bgi-no-repeat"
                                    style={{
                                      borderRadius: "7px",
                                      backgroundImage:
                                        'url("/assets/media/white-waves.png")',
                                    }}
                                  >
                                    <h3 className="text-dark fw-semibold px-9 mt-10 mb-6">
                                      Metrics
                                      <span className="fs-8 opacity-75 ps-3" />
                                    </h3>

                                    <ul
                                      className="nav nav-line-tabs nav-line-tabs-2x nav-stretch fw-semibold px-9"
                                      role="tablist"
                                    >
                                      <li
                                        className="nav-item cursor-pointer"
                                        role="presentation"
                                      >
                                        <span
                                          className={`${
                                            selectedFilterTab === "All"
                                              ? "active"
                                              : ""
                                          } nav-link text-dark opacity-75 opacity-state-100 pb-4 `}
                                          onClick={() => {
                                            setSelectedFilterTab("All");
                                          }}
                                        >
                                          All Metrics
                                        </span>
                                      </li>
                                      <li
                                        className="nav-item  cursor-pointer"
                                        role="presentation"
                                      >
                                        <span
                                          className={`${
                                            selectedFilterTab === "Performance"
                                              ? "active"
                                              : ""
                                          } nav-link text-dark opacity-75 opacity-state-100 pb-4 `}
                                          onClick={() => {
                                            setSelectedFilterTab("Performance");
                                          }}
                                        >
                                          Performance
                                        </span>
                                      </li>
                                      <li
                                        className="nav-item  cursor-pointer"
                                        role="presentation"
                                      >
                                        <span
                                          className={`${
                                            selectedFilterTab === "Conversion"
                                              ? "active"
                                              : ""
                                          } nav-link text-dark opacity-75 opacity-state-100 pb-4 `}
                                          onClick={() => {
                                            setSelectedFilterTab("Conversion");
                                          }}
                                        >
                                          Conversion
                                        </span>
                                      </li>
                                    </ul>
                                  </div>
                                  <div className="tab-content">
                                    <div
                                      className={`${
                                        selectedFilterTab === "All"
                                          ? "show active"
                                          : ""
                                      } tab-pane fade `}
                                    >
                                      <div className="scroll-y mh-250px  my-5 px-8 mt-3">
                                        {Object?.entries(
                                          filterList?.performance
                                        )?.map((d) => {
                                          console.log(d, "------dddd");
                                          return (
                                            <div className="d-flex flex-stack py-2 ps-0 form-check form-check-custom form-check-solid form-check-sm mb-0">
                                              <label
                                                className="form-check-label fs-6 text-gray-800 text-hover-primary fw-bold ms-0"
                                                htmlFor="flexCheckDefault"
                                              >
                                                {d[1]}
                                              </label>
                                              <Checkbox
                                                checked={
                                                  selectedFilters.filter(
                                                    (a) => a === d[0]
                                                  )?.length === 1
                                                    ? true
                                                    : false
                                                }
                                                onChange={(e) => {
                                                  const checkboxId = d[0];
                                                  setSelectedFilters((prev) => {
                                                    if (e?.target?.checked) {
                                                      return [
                                                        ...prev,
                                                        checkboxId,
                                                      ];
                                                    } else {
                                                      return prev.filter(
                                                        (a) => a !== checkboxId
                                                      );
                                                    }
                                                  });
                                                }}
                                              />
                                            </div>
                                          );
                                        })}
                                        {Object?.entries(
                                          filterList?.conversion
                                        )?.map((d) => {
                                          return (
                                            <div className="d-flex flex-stack py-2 ps-0 form-check form-check-custom form-check-solid form-check-sm mb-0">
                                              <label
                                                className="form-check-label fs-6 text-gray-800 text-hover-primary fw-bold ms-0"
                                                htmlFor="flexCheckDefault"
                                              >
                                                {d[1]}
                                              </label>
                                              <Checkbox
                                                checked={
                                                  selectedFilters.filter(
                                                    (a) => a === d[0]
                                                  )?.length === 1
                                                    ? true
                                                    : false
                                                }
                                                onChange={(e) => {
                                                  const checkboxId = d[0];
                                                  setSelectedFilters((prev) => {
                                                    if (e?.target?.checked) {
                                                      return [
                                                        ...prev,
                                                        checkboxId,
                                                      ];
                                                    } else {
                                                      return prev.filter(
                                                        (a) => a !== checkboxId
                                                      );
                                                    }
                                                  });
                                                }}
                                              />
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </div>

                                    <div
                                      className={`${
                                        selectedFilterTab === "Performance"
                                          ? "show active"
                                          : ""
                                      } tab-pane fade `}
                                    >
                                      <div className="scroll-y mh-250px  my-5 px-8">
                                        {Object?.entries(
                                          filterList?.performance
                                        )?.map((d) => {
                                          console.log(d, "------dddd");
                                          return (
                                            <div className="d-flex flex-stack py-2 ps-0 form-check form-check-custom form-check-solid form-check-sm mb-0">
                                              <label
                                                className="form-check-label fs-6 text-gray-800 text-hover-primary fw-bold ms-0"
                                                htmlFor="flexCheckDefault"
                                              >
                                                {d[1]}
                                              </label>
                                              <Checkbox
                                                checked={
                                                  selectedFilters.filter(
                                                    (a) => a === d[0]
                                                  )?.length === 1
                                                    ? true
                                                    : false
                                                }
                                                onChange={(e) => {
                                                  const checkboxId = d[0];
                                                  setSelectedFilters((prev) => {
                                                    if (e?.target?.checked) {
                                                      return [
                                                        ...prev,
                                                        checkboxId,
                                                      ];
                                                    } else {
                                                      return prev.filter(
                                                        (a) => a !== checkboxId
                                                      );
                                                    }
                                                  });
                                                }}
                                              />
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </div>

                                    <div
                                      className={`${
                                        selectedFilterTab === "Conversion"
                                          ? "show active"
                                          : ""
                                      } tab-pane fade `}
                                    >
                                      <div className="scroll-y mh-250px  my-5 px-8 mt-3">
                                        {Object?.entries(
                                          filterList?.conversion
                                        )?.map((d) => {
                                          return (
                                            <div className="d-flex flex-stack py-2 ps-0 form-check form-check-custom form-check-solid form-check-sm mb-0">
                                              <label
                                                className="form-check-label fs-6 text-gray-800 text-hover-primary fw-bold ms-0"
                                                htmlFor="flexCheckDefault"
                                              >
                                                {d[1]}
                                              </label>
                                              <Checkbox
                                                checked={
                                                  selectedFilters.filter(
                                                    (a) => a === d[0]
                                                  )?.length === 1
                                                    ? true
                                                    : false
                                                }
                                                onChange={(e) => {
                                                  const checkboxId = d[0];
                                                  setSelectedFilters((prev) => {
                                                    if (e?.target?.checked) {
                                                      return [
                                                        ...prev,
                                                        checkboxId,
                                                      ];
                                                    } else {
                                                      return prev.filter(
                                                        (a) => a !== checkboxId
                                                      );
                                                    }
                                                  });
                                                }}
                                              />
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        >
                          <button className="btn btn-primary">
                            Select Metrics
                          </button>
                        </Dropdown>
                      )}
                    </div>
                  </div>
                </div>

                <div className="card-body pt-2 pb-0">
                  {perform_summary_loading ? (
                    <div
                      className="d-flex justify-content-between"
                      style={{ width: "100%" }}
                    >
                      <Skeleton.Button
                        active
                        style={{ height: "50px", width: "110px" }}
                      />
                      <Skeleton.Button
                        active
                        style={{ height: "50px", width: "110px" }}
                      />
                      <Skeleton.Button
                        active
                        style={{ height: "50px", width: "110px" }}
                      />
                      <Skeleton.Button
                        active
                        style={{ height: "50px", width: "110px" }}
                      />
                      <Skeleton.Button
                        active
                        style={{ height: "50px", width: "110px" }}
                      />
                      <Skeleton.Button
                        active
                        style={{ height: "50px", width: "110px" }}
                      />
                    </div>
                  ) : (
                    <div className="d-flex">
                      <button
                        onClick={handlePrev}
                        className="btn btn-light-info me-4"
                        style={{ padding: "5px", fontSize: "25px" }}
                      >
                        <LeftOutlined />
                      </button>

                      <Swiper
                        style={{ height: "100px" }}
                        spaceBetween={10}
                        className="mySwiper"
                        navigation={true}
                        onSwiper={setSwiper}
                        pagination={{
                          type: "fraction",
                        }}
                        slidesPerView={
                          window.innerWidth <= 992
                            ? 4
                            : window.innerWidth >= 992 &&
                              window.innerWidth <= 1100
                            ? 5
                            : window.innerWidth >= 1100 &&
                              window.innerWidth <= 1250
                            ? 6
                            : window.innerWidth >= 1250 &&
                              window.innerWidth <= 1350
                            ? 8
                            : 9
                        }
                      >
                        {selectedFilters?.map((d) => {
                          return (
                            <SwiperSlide>
                              <span
                                className="parent-hover cursor-pointer d-flex align-items-center flex-md-row-fluid py-lg-2 px-0 px-4  me-4"
                                style={{
                                  borderRadius: "5px",
                                  // borderTop: "2px solid #4318FF",
                                  background: "#efedff",
                                  height: "100%",
                                }}
                              >
                                {console.log(
                                  performanceSummary,
                                  "performanceSummary"
                                )}
                                <div className="d-flex justify-content-center flex-column">
                                  <span className="text-gray-700 fw-semibold d-block fs-6">
                                    {d === "ad_spend"
                                      ? "Ad Spend"
                                      : d === "advertisedSkuSales"
                                      ? "Advertised SKU Sales"
                                      : d === "ad_clicks"
                                      ? "Clicks"
                                      : d === "CROrderSold"
                                      ? "Conversion Rate (Orders Sold Based)"
                                      : d === "CRUnitSold"
                                      ? "Conversion Rate (Unit Sold Based)"
                                      : d === "CTR"
                                      ? "CTR"
                                      : d === "impression"
                                      ? "Impression"
                                      : d === "new_to_brand_order"
                                      ? "New-to-Brand Orders"
                                      : d === "orders"
                                      ? "Orders"
                                      : d === "otherSkuSales"
                                      ? "Other SKU Sales"
                                      : d === "ROAS"
                                      ? "RoAS"
                                      : d === "sales_revenue"
                                      ? "Total Attr. Sales Revenue"
                                      : d === "units"
                                      ? "Unit Sold"
                                      : d === "in_store_advertised_sales"
                                      ? "In Store Advertised Sales"
                                      : d === "in_store_attributed_sales"
                                      ? "In Store Attributed Sales"
                                      : d === "in_store_orders"
                                      ? "In Store Orders"
                                      : d === "in_store_other_sales"
                                      ? "In Store Other Sales"
                                      : "In Store Unit Sold"}
                                  </span>

                                  <span className="fw-bold text-gray-800 parent-hover-primary fs-5 mt-1">
                                    {d === "ad_spend"
                                      ? "$"
                                      : d === "advertisedSkuSales"
                                      ? "$"
                                      : d === "otherSkuSales"
                                      ? "$"
                                      : d === "ROAS"
                                      ? "$"
                                      : d === "sales_revenue"
                                      ? "$"
                                      : ""}
                                    {NumberWithCommas(
                                      performanceSummary[d] || 0
                                    )}
                                    {d === "CROrderSold"
                                      ? "%"
                                      : d === "CRUnitSold"
                                      ? "%"
                                      : d === "CTR"
                                      ? "%"
                                      : d === "new_to_brand_order"
                                      ? "%"
                                      : ""}
                                  </span>
                                </div>
                              </span>
                            </SwiperSlide>
                          );
                        })}
                      </Swiper>

                      <button
                        onClick={handleNext}
                        className="btn btn-light-info"
                        style={{ padding: "5px", fontSize: "25px" }}
                      >
                        <RightOutlined />
                      </button>
                    </div>
                  )}

                  <div className="d-flex justify-content-end mt-5">
                    <div className="d-flex">
                      {/* {perform_summary_loading ? (
                        <Skeleton.Button active className="me-2" />
                      ) : (
                        <span
                          className="d-flex align-items-center cursor-pointer me-5"
                          onClick={() => {
                            handleExportPDF();
                          }}
                        >
                          <Tooltip title="Download PDF" placement="bottom">
                            <FilePdfOutlined
                              className="fs-1"
                              style={{ color: "red" }}
                            />
                          </Tooltip>
                        </span>
                      )}
                      {perform_summary_loading ? (
                        <Skeleton.Button active className="me-2" />
                      ) : (
                        <span
                          className="d-flex align-items-center cursor-pointer"
                          onClick={() => {
                            // handleExportPDF();
                            downloadXlAmazon();
                          }}
                        >
                          <Tooltip title="Download Excel" placement="bottom">
                            <FileExcelOutlined
                              className="fs-1"
                              style={{ color: "blue" }}
                            />
                          </Tooltip>
                        </span>
                      )} */}
                    </div>
                  </div>
                  {wm_chart_loading ? (
                    <div className="d-flex justify-content-center my-5">
                      <Skeleton.Node
                        active
                        style={{
                          height: "200px",
                          width: "200px",
                          margin: "50px",
                        }}
                      />
                    </div>
                  ) : (
                    <div id="chartZoom" className="w-100 mt-0">
                      <Chart
                        options={chartOptions}
                        series={chartSeries}
                        type="area"
                        height={"400px"}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row g-5 g-xl-5">
            <div className="col-12 mb-5 mb-xl-5 ">
              <div className="card card-flush h-md-100">
                <div className="card-header  min-h-50px">
                  <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bolder fs-2 text-dark">
                      Recommendation
                    </span>
                  </h3>

                  <div className="card-toolbar"></div>
                </div>

                <div className="card-body pt-2 pb-0">
                  <div className="row">
                    <div className="col-12 col-md-6 col-lg-4 col-lx-3 my-2">
                      <div
                        onClick={() => {
                          setIsOpenItemRecommModal(true);
                        }}
                        className="card cursor-pointer"
                        style={{
                          borderRadius: "10px",
                          backgroundImage:
                            "linear-gradient(to right, #eae1fc, #959cc6)",
                          borderLeft: "4px solid #5724c0",
                          clipPath: "polygon(0 0, 100% 2%, 96% 100%, 0% 100%)",
                        }}
                      >
                        <div className="card-body">
                          <div className="d-flex align-items-center">
                            <div className>
                              <p
                                className="mb-1 fs-3 "
                                style={{
                                  color: "#5724c0",
                                  textDecoration: "underline",
                                }}
                              >
                                Total Item Recommendation
                              </p>
                              <span
                                className="mb-0 fw-bolder fs-1"
                                style={{ color: "#5724c0" }}
                              >
                                {item_recommendation_total_record || 0}
                              </span>
                            </div>
                            <div
                              className="ms-auto text-white"
                              style={{
                                width: "48px",
                                height: "48px",
                                backgroundColor: "#5724c0",
                                borderRadius: "50%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                cursor: " pointer",
                              }}
                            >
                              <i
                                class="ki-outline ki-tablet-text-up fs-1 "
                                style={{ color: "#fff" }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4 col-lx-3 my-2">
                      <div
                        onClick={() => {
                          setIsOpenKeywordRecommModal(true);
                        }}
                        className="card"
                        style={{
                          borderRadius: "10px",
                          backgroundImage:
                            "linear-gradient(90deg, #e0f4e7, #a1c9a1)",
                          borderLeft: "4px solid #1b9548",
                          clipPath: "polygon(0 0, 100% 2%, 96% 100%, 0% 100%)",
                        }}
                      >
                        <div className="card-body">
                          <div className="d-flex align-items-center">
                            <div className>
                              <p
                                className="mb-1 fs-3"
                                style={{
                                  color: "#1b9548",
                                  textDecoration: "underline",
                                }}
                              >
                                Total Keyword Recommendation
                              </p>
                              <span
                                className="mb-0 fw-bolder fs-1"
                                style={{ color: "#1b9548" }}
                              >
                                {keyword_recommendation_total_record || 0}
                              </span>
                            </div>
                            <div
                              className="ms-auto text-white"
                              style={{
                                width: "48px",
                                height: "48px",
                                backgroundColor: "#1b9548",
                                borderRadius: "50%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                cursor: " pointer",
                              }}
                            >
                              <i
                                class="ki-outline ki-tablet-text-up fs-1 "
                                style={{ color: "#fff" }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-20"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="row g-5 g-xl-5 ">
            <div className="col-xxl-12 mb-5 mb-xl-5 ">
              <div className="card card-flush h-xl-100">
                <div className="card-header">
                  <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bolder fs-2 text-dark">
                      Search Trends Keyword
                    </span>
                  </h3>

                  <div className="card-toolbar"></div>
                </div>

                <div className="card-body pt-2">
                  <div className="table-responsive ">
                    {search_list_loading ? (
                      <Loading />
                    ) : search_trends_list?.length > 0 ? (
                      <IvcsrTable
                        columns={search_treands_columns?.map((d) => ({
                          ...d,
                          width:
                            d?.title === "Product"
                              ? 400
                              : d?.title?.length * 13,
                        }))}
                        sticky={{
                          offsetHeader: "10px",
                        }}
                        scroll={{
                          x: "max-content",
                          y: 300,
                        }}
                        fixed={true}
                        dataSource={search_trends_list}
                        rowKey="key"
                        loading={search_list_loading}
                        pagination={false}
                        expandable={{
                          expandedRowRender: (record) => {
                            return (
                              <div>
                                <ExpandedRow record={record} />
                              </div>
                            );
                          },
                          expandIcon: ({ expanded, onExpand, record }) =>
                            expanded ? (
                              <i
                                className="ki-outline ki-down fs-1 customIcon"
                                onClick={(e) => onExpand(record, e)}
                              />
                            ) : (
                              <i
                                className="ki-outline ki-right fs-1 customIcon"
                                onClick={(e) => onExpand(record, e)}
                              />
                            ),
                        }}
                      />
                    ) : (
                      <NoData type="new" />
                    )}
                  </div>
                  <Pagination
                    loading={
                      search_list_loading || search_trends_list?.length === 0
                    }
                    pageSize={search_trends_filter?.perPage}
                    page={search_trends_filter?.page}
                    totalPage={search_trends_filter?.totalPage}
                    onPerPage={onPerPage}
                    onPageNo={onPageNo}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* <div className="row g-5 g-xl-5 ">
            <div className="col-xxl-12 mb-5 mb-xl-5 ">
              <div className="card card-flush h-xl-100">
                <div className="card-header">
                  <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bolder fs-2 text-dark">
                      Item recommendation
                    </span>
                  </h3>

                  <div className="card-toolbar">
                    <div className="position-relative">
                      <label
                        className="text-primary fw-bold fs-4 cursor-pointer"
                        style={{ textDecoration: "underline" }}
                      >
                        View More : {item_recommendation_total_record}
                      </label>
                    </div>
                  </div>
                </div>

                <div className="card-body pt-2">
                  <div className="row">
                    {item_recommendation_list?.length > 0 ? (
                      item_recommendation_list?.map((d, i) => {
                        return (
                          i <= 5 && (
                            <div
                              className="col-xl-2 col-lg-6 col-md-6 my-2"
                              key={i}
                            >
                              <div className="card  overlay overflow-hidden bdr-box">
                                <div className="card-body p-0">
                                  <div className="overlay-wrapper  min-h-100px p-5">
                                    <div className="ribbon ribbon-triangle ribbon-top-start border-info">
                                      <div className="ribbon-label ">
                                        {i + 1}
                                      </div>
                                    </div>

                                    <div className="text-center">
                                      <div className="d-flex flex-column">
                                        <Popover
                                          content={
                                            <div
                                              style={{
                                                height: "auto",
                                                width: "400px",
                                              }}
                                            >
                                              {d?.item_name}
                                            </div>
                                          }
                                          placement="bottom"
                                        >
                                          <span className="text-gray-800 fs-6 text-hover-info mb-1 fw-bolder text-two ">
                                            {d?.item_name}
                                          </span>
                                        </Popover>

                                        <span>
                                          <>
                                            <Tag
                                              color="geekblue"
                                              className="mt-1 fs-6"
                                              bordered={false}
                                            >
                                              <b className="me-2">Brand:</b>
                                              {d?.brand_name}
                                            </Tag>
                                          </>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        );
                      })
                    ) : (
                      <NoData type="new" />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <div className="row g-5 g-xl-5 ">
            <div className="col-xxl-12 mb-5 mb-xl-5 ">
              <div className="card card-flush h-xl-100">
                <div className="card-header">
                  <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bolder fs-2 text-dark">
                      Manage your keywords
                    </span>
                  </h3>
                  <div className="card-toolbar"></div>
                </div>

                <div className="card-body pt-2 pb-0">
                  <div className="table-responsive ">
                    {manage_keyword_loading ? (
                      <Loading />
                    ) : keyword_list?.length > 0 ? (
                      <IvcsrTable
                        columns={keywordColumns}
                        sticky={{
                          offsetHeader: "10px",
                        }}
                        scroll={{
                          x: 3500,
                          y: 500,
                        }}
                        fixed={true}
                        dataSource={keyword_list}
                        rowKey="key"
                        loading={false}
                        pagination={true}
                      />
                    ) : (
                      <NoData type="new" />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isOpenItemRecommModal && (
        <ItemRecommModal
          show={isOpenItemRecommModal}
          onHide={() => setIsOpenItemRecommModal(false)}
          GetWmItemRecommendation={GetWmItemRecommendation}
          item_recomm_filter={item_recomm_filter}
          set_item_recomm_filter={set_item_recomm_filter}
          item_recommendation_list={item_recommendation_list}
          item_recommendation_total_record={item_recommendation_total_record}
        />
      )}
      {isOpenKeywordRecommModal && (
        <KeywordRecommModal
          show={isOpenKeywordRecommModal}
          onHide={() => setIsOpenKeywordRecommModal(false)}
          GetWmKeywordRecommendation={GetWmKeywordRecommendation}
          keyword_recomm_filter={keyword_recomm_filter}
          set_keyword_recomm_filter={set_keyword_recomm_filter}
          keyword_recommendation_list={keyword_recommendation_list}
          keyword_recommendation_total_record={
            keyword_recommendation_total_record
          }
        />
      )}
    </div>
  );
};

export default WalmartKeyword;
