import styled from "styled-components";

export const SubCardDetails = styled.div`
  .subCardDesign {
    padding: 10px 13px;
    text-align: center;
    border-radius: 6px;
    // background: #f1f1f1;
    // box-shadow: 0px 2px 4px #1d0d6a7a;
  }
`;

export const TopProductWrapper = styled.div`
  .ant-segmented-item-selected {
    background-image: linear-gradient(45deg, #6542ff, #9992b1);
    color: #ffffff;
    font-weight: 600;
    letter-spacing: 0.5px;
  }
  .ant-segmented-item,
  .ant-segmented {
    :where(.css-dev-only-do-not-override-i56vxb).ant-segmented
      .ant-segmented-item {
      transition: color 0s cubic-bezier(0, 0, 0, 0);
    }
  }
  .ant-segmented-item-label {
    line-height: 37px !important;
    min-height: 35px !important;
    letter-spacing: 0.5px;
    font-weight: 600;
  }
`;
