import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import IvcsrTable from "../../../../component/ivcsr-table";
import Loading from "../../../../component/loading";
import writeXlsxFile from "write-excel-file";
import { Popover, Tag, message } from "antd";
import { NumberWithCommas } from "../../../../config";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";
const InshightSnapshot = (props) => {
  const { GetWalmartInsightsReportList, fakeActionWalmartAdvanceReports } =
    props;

  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);

  const GetWalmartInsightReportListRes = useSelector(
    (state) => state.AdvanceReport.GetWalmartInsightReportListResponse || {}
  );

  useEffect(() => {
    GetWalmartInsightsReportList();
  }, []);

  useEffect(() => {
    if (GetWalmartInsightReportListRes.status === true) {
      setList(GetWalmartInsightReportListRes?.data?.records);
      console.log(
        GetWalmartInsightReportListRes,
        "GetWalmartInsightReportListRes"
      );
      setLoading(false);

      fakeActionWalmartAdvanceReports("GetWalmartInsightReportListResponse");
    } else if (GetWalmartInsightReportListRes.status === false) {
      setLoading(false);
      fakeActionWalmartAdvanceReports("GetWalmartInsightReportListResponse");
    }
  }, [GetWalmartInsightReportListRes]);

  const columns = [
    {
      title: "Product",
      width: 400,
      render: (text) => {
        return (
          <div className="d-flex align-items-center border-start border-2 border-info p-3 rounded">
            <div className="symbol symbol-50px  me-3">
              <img src={`${text?.item_image_url}`} alt="" />
            </div>
            {/*end::Avatar*/}
            {/*begin::User details*/}
            <div className="d-flex flex-column">
              <Popover
                content={
                  <div
                    style={{
                      height: "max-content",
                      maxWidth: "400px",
                    }}
                  >
                    {text?.catalog_item_name}
                  </div>
                }
                placement="bottomLeft"
              >
                <div
                  className="text-primary mb-1 fw-bolder text-one fs-6 popoverActionIconSingleLine"
                  style={{ minWidth: "100px" }}
                >
                  {text?.catalog_item_name}
                </div>
              </Popover>

              <div>
                <span className="fs-6">
                  {/* <Link target="blank" to={{ pathname: text?.item_page_url }}> */}{" "}
                  <Tag color="orange" style={{ textDecoration: "underline" }}>
                    <b>UPC:</b> {text?.upc}
                  </Tag>
                  {/* </Link> */}
                </span>
                <br />
                <span className="fs-6 ">
                  <Tag color="purple" className="mt-1">
                    <b>Brand:</b> {text?.brand_name}
                  </Tag>
                </span>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      width: 200,
      render: (text) => {
        return (
          <Popover
            content={
              <div
                style={{
                  height: "max-content",
                  maxWidth: "400px",
                }}
              >
                {text}
              </div>
            }
            placement="bottomLeft"
          >
            <div
              className="mb-1 fw-bold text-one fs-6 popoverActionIconSingleLine"
              style={{ minWidth: "100px" }}
            >
              {text}
            </div>
          </Popover>
        );
      },
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (text, _) => {
        return <label>${NumberWithCommas(text || 0)}</label>;
      },
    },
    {
      title: "Walmart Product Id",
      dataIndex: "walmart_product_id",
      key: "walmart_product_id",
      render: (text, _) => {
        return (
          <>
            <div>
              <Link
                to={{ pathname: `${_?.item_page_url}` }}
                target="blank"
                style={{ textDecoration: "underline" }}
                className="text-primary"
              >
                {text}
              </Link>
            </div>
            <div className="mt-2">
              <Tag
                color={_?.published_status == 1 ? "green" : "red"}
                className="fw-bold "
              >
                {" "}
                {_?.published_status == 1 ? "PUBLISHED" : "UNPUBLISHED"}
              </Tag>
            </div>
          </>
        );
      },
    },
    {
      title: "Walmart Item Number",
      dataIndex: "walmart_item_number",
      key: "walmart_item_number",
    },
    // {
    //   title: "Catalog Item Id",
    //   dataIndex: "catalog_item_id",
    //   key: "catalog_item_id",
    // },
    {
      title: "Base Item Id",
      dataIndex: "base_item_id",
      key: "base_item_id",
    },
    {
      title: "Buy Box Winner Price",
      dataIndex: "buy_box_winner_price",
      key: "buy_box_winner_price",
      render: (text, _) => {
        return <label>${NumberWithCommas(text || 0)}</label>;
      },
    },

    {
      title: "GTIN",
      dataIndex: "gtin",
      key: "gtin",
    },
    {
      title: "Inventory Count",
      dataIndex: "inventory_count",
      key: "inventory_count",
      render: (text, _) => {
        return (
          <Tag color={text > 0 ? "success" : "error"} className="fs-5 fw-bold">
            {text}
          </Tag>
        );
      },
    },
    {
      title: "Item Creation Date",
      dataIndex: "item_creation_date",
      key: "item_creation_date",
      render: (text, _) => {
        return <span>{moment(text)?.format("MMM DD, YYYY")}</span>;
      },
    },
    {
      title: "Marketplace",
      dataIndex: "marketplace",
      key: "marketplace",
    },

    {
      title: "Page View Based Availability Rate",
      dataIndex: "page_view_based_availability_rate",
      key: "page_view_based_availability_rate",
    },
    {
      title: "Page View Based Buybox Win Rate",
      dataIndex: "page_view_based_buy_box_win_rate",
      key: "page_view_based_buy_box_win_rate",
    },

    // {
    //   title: "Published Status",
    //   dataIndex: "published_status",
    //   key: "published_status",
    //   render: (text, _) => {
    //     return (
    //       <span
    //         style={{ color: text?.published_status == 1 ? "green" : "red" }}
    //         className="fw-bold"
    //       >
    //         {text == 1 ? "PUBLISHED" : "UNPUBLISHED"}
    //       </span>
    //     );
    //   },
    // },
    {
      title: "Replenishment Status",
      dataIndex: "replenishment_status",
      key: "replenishment_status",
    },
    {
      title: "Review Count",
      dataIndex: "review_count",
      key: "review_count",
    },
  ];

  const downloadXl = async (header, dataRow, name) => {
    const data = [header, ...dataRow];
    await writeXlsxFile(data, {
      fileName: name,
    });
    message.destroy();
  };
  return (
    <>
      <div className="card-header border-bottom-dashed">
        <h3 className="card-title ">
          <span className="card-label fw-bold text-gray-900">
            Insight Snapshot
          </span>
        </h3>

        <div className="card-toolbar">
          <div className="position-relative">
            <button
              className="btn btn-light-info fw-bolder fs-6 ms-2"
              onClick={async () => {
                const header = [
                  {
                    value: "Page View Based Availability Rate",
                    type: String,
                  },
                  {
                    value: "Page View Based Buybox Win Rate",
                    type: String,
                  },
                  { value: "Price", type: String },
                  { value: "Published Status", type: String },
                  { value: "Replenishment Status", type: String },
                  {
                    value: "Review Count",
                    type: String,
                  },
                  { value: "UPC", type: String },
                  {
                    value: "Walmart Item Number",
                    type: String,
                  },
                  {
                    value: "Walmart ProductId",
                    type: String,
                  },
                ];

                let dataRow = [];
                await list.map((d) => {
                  const newList = [
                    d?.page_view_based_availability_rate,
                    d?.page_view_based_buy_box_win_rate,
                    d?.price,
                    d?.published_status,
                    d?.replenishment_status,
                    d?.review_count,
                    d?.upc,
                    d?.walmart_item_number,
                    d?.walmart_product_id,
                  ];
                  dataRow = [
                    ...dataRow,
                    newList.map((d) => {
                      return { value: d };
                    }),
                  ];
                });
                downloadXl(header, dataRow, "Insight-snapshot.xlsx");
              }}
            >
              Export
            </button>
          </div>
        </div>
      </div>
      <div className="card-body pb-5 pt-0">
        <div className="mt-5">
          {loading ? (
            <Loading />
          ) : (
            <IvcsrTable
              dataSource={list}
              columns={columns}
              scroll={{ x: "max-content" }}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default InshightSnapshot;
