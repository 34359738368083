import React, { useContext, useEffect, useState } from "react";
import { LogoutWrapper, Wrapper } from "./style";
import { useLocation, useHistory, Link } from "react-router-dom";
import { Select, Tooltip, message } from "antd";
import { useSelector } from "react-redux";
import Icons from "../../../component/icons";
import { GlobalContext } from "../../../commonContext";
export default function (props) {
  const {
    userType,
    isCollapsed,
    setIsCollapsed,
    isBackToAdmin,
    setIsBackToAdmin,
    fakeSwitchBack,
    setMobileStatus,
  } = props;
  const contextVar = useContext(GlobalContext);
  const contextPlatformType = contextVar?.data?.marketplaceType;
  const history = useHistory();
  const location = useLocation();
  const [locationPath, setLocationPath] = useState([]);
  // const [contextPlatformType, setPlatformType] = useState(contextPlatformType);

  // console.log(contextVar?.data , 'commonGlobalVal')

  useEffect(() => {
    // console.log(location, "newLoca-----------");
    // const newLoca = location.pathname.split("/")?.[1]?.split("-")?.join(" ");
    const fullPath = location.pathname.split("/");
    setLocationPath(fullPath);
  }, [location]);

  const BackToAdmin = () => {
    localStorage.setItem("userType", 1);
    history.replace("/manage-user");
    // let adminDataLocal = localStorage.getItem("adminData");
    // if (adminDataLocal) {
    //   adminDataLocal = JSON.parse(adminDataLocal);
    //   localStorage.setItem("user", adminDataLocal.data);
    //   localStorage.setItem("userType", JSON.parse(adminDataLocal?.data)?.type);
    //   localStorage.setItem("token", adminDataLocal.token);
    //   history.replace("/manage-user");
    // }
  };

  return (
    <Wrapper>
      <div
        id="kt_app_header"
        className="app-header my-3 my-md-0"
        style={{ borderBottom: "none" }}
      >
        {/*begin::Header container*/}
        <div
          className="app-container container-fluid d-flex align-items-stretch flex-stack"
          id="kt_app_header_container"
        >
          {/*begin::Sidebar toggle*/}
          <div
            className="d-flex align-items-center d-block d-lg-none ms-n3"
            title="Show sidebar menu"
          >
            <div
              className="btn btn-icon btn-active-color-primary w-35px h-35px me-2"
              id="kt_app_sidebar_mobile_toggle"
              onClick={() => {
                setMobileStatus(true);
              }}
            >
              <i className="ki-outline ki-abstract-14 fs-2" />
            </div>
            {/*begin::Logo image*/}
            <img
              alt="Logo"
              src="/assets/media/all-images/ivcsr-logo-new.png  "
              className="h-30px d-none d-sm-inline app-sidebar-logo-default theme-light-show shimmer"
            />
            {/*end::Logo image*/}
          </div>
          {/*end::Sidebar toggle*/}
          {/*begin::Navbar*/}
          <div
            className="app-navbar flex-lg-grow-1 w-100 d-flex justify-content-between align-items-center"
            id="kt_app_header_navbar"
          >
            <div className="app-navbar-item">
              <div
                className="page-title gap-4 me-3 mb-5 mb-lg-0"
                data-kt-swapper={1}
                data-kt-swapper-mode="{default: 'prepend', lg: 'prepend'}"
                data-kt-swapper-parent="{default: '#kt_app_content_container', lg: '#kt_app_header_wrapper'}"
              >
                {/*begin::Breadcrumb*/}
                <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 mb-2">
                  {/*begin::Item*/}
                  <li className="breadcrumb-item text-gray-600 fw-bold lh-1">
                    <span
                      className="text-gray-700 text-hover-primary me-1 cursor-pointer"
                      onClick={() => {
                        history.replace("/dashboard");
                      }}
                    >
                      <i className="ki-outline ki-home text-gray-700 fs-6" />{" "}
                    </span>
                  </li>
                  {/*end::Item*/}
                  {/*begin::Item*/}
                  <li className="breadcrumb-item">
                    <i className="ki-outline ki-right fs-7 text-gray-700 mx-n1" />
                  </li>
                  {/*end::Item*/}
                  {/*begin::Item*/}
                  <li
                    className="breadcrumb-item text-gray-600 fw-bold lh-1"
                    style={{ textTransform: "capitalize" }}
                  >
                    {locationPath?.[1] === "callbackads" ||
                    locationPath?.[1] === "spapi-callback"
                      ? "Marketplace Credential"
                      : locationPath?.[1]?.split("-")?.join(" ")}
                  </li>
                  {/*end::Item*/}
                  {/*begin::Item*/}
                  {locationPath?.length >= 3 && (
                    <>
                      {" "}
                      <li className="breadcrumb-item">
                        <i className="ki-outline ki-right fs-7 text-gray-700 mx-n1" />
                      </li>
                      {/*end::Item*/}
                      {/*begin::Item*/}
                      <li
                        className="breadcrumb-item text-gray-500"
                        style={{ textTransform: "capitalize" }}
                      >
                        {locationPath?.[2]?.split("-")?.join(" ")}
                      </li>
                    </>
                  )}
                  {/*end::Item*/}
                </ul>
                {/*end::Breadcrumb*/}
                {/*begin::Title*/}
                <h1
                  className="text-gray-900 fw-bolder m-0 fs-2x"
                  style={{ textTransform: "capitalize" }}
                >
                  {locationPath?.length >= 3
                    ? locationPath?.[2]?.split("-")?.join(" ")
                    : locationPath?.[1] === "callbackads"
                    ? "Advertising Credentials"
                    : locationPath?.[1] === "spapi-callback"
                    ? "SP API Credentials"
                    : locationPath?.[1]?.split("-")?.join(" ")}
                </h1>
                {/*end::Title*/}
              </div>
            </div>
            {userType != 1 ? (
              <div
                className="app-navbar-item fadeInTop"
                style={{
                  background: `${
                    contextPlatformType === "amazon" ? "#fea419" : "#0071dc"
                  }`,
                  padding: "0 8px",
                  height: "55px",
                  borderRadius: "50px",
                  boxShadow: "0px 0px 11px -4px #8f7be9",
                }}
              >
                <div className="d-flex justify-content-center align-items-center">
                  {localStorage.getItem("connected_by") === "both" ? (
                    <Select
                      style={{ width: "120px" }}
                      placeholder="--select--"
                      className="selectHeaderDD"
                      size="large"
                      // #febd69
                      defaultValue={"amazon"}
                      value={contextPlatformType}
                      onChange={(e) => {
                        message.destroy();
                        message.loading(`Switching to ${e}...`, 0);
                        setTimeout(() => {
                          localStorage.setItem("platformType", e);
                          // setPlatformType(e);
                          contextVar?.updateCommonGlobalVal({
                            marketplaceType: e,
                          });
                          message.destroy();
                          message.success(`Succesfully Switch`);
                        }, "2000");
                      }}
                      options={[
                        {
                          value: "amazon",
                          label: (
                            <>
                              Amazon
                              {/* <img
                            style={{ width: "70px" }}
                            src="/assets/media/all-images/amazon.png"
                          /> */}
                            </>
                          ),
                        },
                        {
                          value: "walmart",
                          label: (
                            <>
                              Walmart
                              {/* <img
                            style={{ width: "80px" }}
                            src="/assets/media/all-images/Walmart_logo.png"
                          /> */}
                            </>
                          ),
                        },
                      ]}
                    />
                  ) : (
                    <div
                      style={{
                        width: "120px",
                        width: "120px",
                        padding: "11px",
                        borderRadius: "23px",
                        background: "#f5f3ff",
                        fontSize: "14px",
                        fontWeight: "600",
                        color: "#6c6b71",
                        textAlign: "center",
                      }}
                    >
                      <label style={{ textTransform: "capitalize" }}>
                        {localStorage.getItem("platformType")}
                      </label>
                    </div>
                  )}

                  <div
                    className="ms-5"
                    style={{
                      padding: "6px",
                      borderRadius: "27px",
                      border: "1px dashed #8f7be9",
                      background: "#f7f9fb",
                      // boxShadow: "0px 0px 6px #8f7be9",
                    }}
                  >
                    {contextPlatformType === "amazon" ? (
                      <img
                        src="/assets/media/all-images/amazon-small.png"
                        style={{ width: "30px" }}
                      />
                    ) : (
                      <img
                        src="/assets/media/all-images/walmart-small.png"
                        style={{ width: "30px" }}
                      />
                    )}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            {/* <div
              className=" d-flex ms-auto justify-content-end "
              style={{
                background: "#cfc9f56b",
                padding: "0 10px",
                height: "63px",
                borderRadius: "50px",
                marginTop: "18px",
                boxShadow: "rgb(143 123 233 / 57%) 0px 0px 15px",
              }}
            >
              <div className="app-navbar-item">
                <Select
                  style={{ width: "150px" }}
                  placeholder="--select--"
                  className="selectHeaderDD"
                  size="large"
                  defaultValue={"amazon"}
                  options={[
                    {
                      value: "amazon",
                      label: (
                        <>
                          <img
                            style={{ width: "70px" }}
                            src="/assets/media/all-images/amazon.png"
                          />
                        </>
                      ),
                    },
                    {
                      value: "walmart",
                      label: (
                        <>
                          <img
                            style={{ width: "80px" }}
                            src="/assets/media/all-images/Walmart_logo.png"
                          />
                        </>
                      ),
                    },
                  ]}
                />
              </div>
            </div> */}
          </div>
          {/*end::Navbar*/}
          {/*begin::Separator*/}
          <div className="app-navbar-separator separator d-none d-lg-flex" />
          {/*end::Separator*/}
        </div>
        {/*end::Header container*/}
      </div>
    </Wrapper>
  );
}
