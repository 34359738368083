import { getUserId } from "../../../../config";
import axiosCall from "../../../configurations/network-services/axiosCall";
import walmartAxiosCall from "../../../configurations/network-services/walmartAxoisCall";

export const GetDirectWalmartOrdersAction = (data) => {
  // for direct walmart api
  const path = `all/order`;
  const responseType = "GET_DIRECT_WALMART_ORDERS";
  return walmartAxiosCall("get", path, responseType, data, {
    AUTHORIZATION: `Bearer ${localStorage.getItem("token")}`,
    "MARKETPLACE-ID": "ATVPDKIKX0DER",
    "USER-ID": getUserId(),
    "EVENT-TYPE": "Walmart Report",
  });
};

export const GetWalmartOrdersAction = (data) => {
  const path = `wm/orders?page=${data?.page || 1}&per-page=${
    data?.perPage || 20
  }&ship_node_type=${data?.ship_node_type || ""}&order_line_status=${
    data?.order_line_status || ""
  }&date_type=${data?.date_type || ""}&start_date=${
    data?.start_date || ""
  }&end_date=${data?.end_date || ""}&search_key=${
    data?.search || ""
  }&customer_name=${data?.customer_name || ""}`;
  const responseType = "GET_WALMART_ORDERS";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetWalmartOrdersActionWithAllProduct = (data) => {
  const path = `wm/orders-with-products?page=${data?.page || 1}&per-page=${
    data?.perPage || 20
  }&ship_node_type=${data?.ship_node_type || ""}&order_line_status=${
    data?.order_line_status || ""
  }&date_type=${data?.date_type || ""}&start_date=${
    data?.start_date || ""
  }&end_date=${data?.end_date || ""}&search_key=${
    data?.search || ""
  }&customer_name=${data?.customer_name || ""}`;
  const responseType = "GET_WALMART_ORDERS_WITH_ALL_PRODUCTS";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetOrderDetails = (data) => {
  const path = `wm/order-details?purchase_order_id=${data?.id || ""}&is_all=${
    data?.is_all || 0
  }`;
  const responseType = "GET_WALMART_ORDERS_DETAILS";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const WalmartShipItemAction = (poId, data) => {
  const path = `orders/ship?purchase_order_id=${poId}`;
  const responseType = "WALMART_SHIPMENT_ITEMS";
  return walmartAxiosCall("post", path, responseType, data, {
    AUTHORIZATION: `Bearer ${localStorage.getItem("token")}`,
    "MARKETPLACE-ID": "ATVPDKIKX0DER",
    // user_id: 8,
    // user_id: JSON.parse(localStorage.getItem("user"))?.id,
    "USER-ID": getUserId(),
    "EVENT-TYPE": "Walmart Report",
  });
};

export const WalmartCancelItemAction = (poId, data) => {
  const path = `orders/cancel?purchase_order_id=${poId}`;
  const responseType = "WALMART_CANCEL_ITEMS";
  return walmartAxiosCall("post", path, responseType, data, {
    AUTHORIZATION: `Bearer ${localStorage.getItem("token")}`,
    "MARKETPLACE-ID": "ATVPDKIKX0DER",
    // user_id: 8,
    // user_id: JSON.parse(localStorage.getItem("user"))?.id,
    "USER-ID": getUserId(),
    "EVENT-TYPE": "Walmart Report",
  });
};

export const WalmartAcknowledgeOrderAction = (poId) => {
  const path = `orders/acknowledge?purchase_order_id=${poId}`;
  const responseType = "WALMART_ACKNOWLEDGE_ORDER";
  return walmartAxiosCall("post", path, responseType, null, {
    AUTHORIZATION: `Bearer ${localStorage.getItem("token")}`,
    "MARKETPLACE-ID": "ATVPDKIKX0DER",
    // user_id: 8,
    // user_id: JSON.parse(localStorage.getItem("user"))?.id,
    "USER-ID": getUserId(),
  });
};

export const fakeActionWalmartOrders = (data) => async (dispatch) => {
  dispatch({ type: "FAKE_ACTION_WALMART_ORDERS", state: data });
};
