import {
  Input,
  Popconfirm,
  Popover,
  Select,
  Skeleton,
  Tag,
  Tooltip,
  message,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { urlDecode } from "../../../../../config";
export default function (props) {
  const { GetReturnsAndRefundsOrderDetails, fakeActionReturnsRefunds } = props;

  const GetReturnsAndRefundsOrderDetailsRes = useSelector(
    (state) =>
      state.WalmartReturnsRefunds.GetReturnsAndRefundsOrderDetailsResponse || {}
  );

  const [orderData, setOrderData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [total_charge, set_total_charge] = useState([]);
  const location = useLocation();

  useEffect(() => {
    const newUrl = urlDecode(location);
    GetReturnsAndRefundsOrderDetails(
      newUrl?.return_order_id,
      newUrl?.purchase_order_line_number
    );
  }, [location]);

  useEffect(() => {
    if (GetReturnsAndRefundsOrderDetailsRes.status === true) {
      setOrderData(GetReturnsAndRefundsOrderDetailsRes?.data);
      GetReturnsAndRefundsOrderDetailsRes?.data?.refundData?.charge_totals &&
        set_total_charge(
          JSON.parse(
            GetReturnsAndRefundsOrderDetailsRes?.data?.refundData?.charge_totals
          )
        );
      setLoading(false);
      fakeActionReturnsRefunds("GetReturnsAndRefundsOrderDetailsResponse");
    } else if (GetReturnsAndRefundsOrderDetailsRes.status === false) {
      setLoading(false);
      fakeActionReturnsRefunds("GetReturnsAndRefundsOrderDetailsResponse");
    }
  }, [GetReturnsAndRefundsOrderDetailsRes]);

  return (
    <div className="fadeInRight">
      <>
        <div className="d-flex flex-column flex-column-fluid">
          <div id="kt_app_content" className="app-content flex-column-fluid">
            <div
              id="kt_app_content_container"
              className="app-container container-fluid"
            >
              <div className="row gy-5 g-xl-5 mb-20">
                <div className="col-xxl-7  ">
                  <div className="card  mb-5 mb-xl-8">
                    <div className="card-header border-bottom-dashed">
                      <h3 className="card-title align-items-start flex-row align-items-center">
                        <Link
                          id={Math.random()}
                          to={{
                            pathname: "/order-management/returns-refunds",
                          }}
                        >
                          <span className="fs-6 d-flex align-items-center me-10">
                            <i className="ki-outline ki-arrow-left fs-2 text-primary me-1" />{" "}
                            Back
                          </span>
                        </Link>
                        <span className="card-label fw-bolder text-dark">
                          {" "}
                          Return Details
                        </span>
                      </h3>
                      <div className="card-toolbar"></div>
                    </div>

                    <div className="card-body p-9">
                      <div className="row align-items-center mb-3">
                        <div className="col-2">
                          {loading ? (
                            <Skeleton.Node />
                          ) : (
                            <img
                              src={`${orderData?.itemDetails?.image_url}`}
                              style={{ width: "50px" }}
                            />
                          )}
                        </div>
                        <div className="col-10">
                          {loading ? (
                            <Skeleton.Button active block />
                          ) : (
                            <label className="fw-bold fs-5 text-primary">
                              {orderData?.itemDetails?.product_name}
                            </label>
                          )}
                        </div>
                      </div>
                      <div className="">
                        {/* <div className="row mb-7 ">
                          <label className="col-lg-6  fw-normal text-gray-700 fs-6">
                            Tracking #:
                          </label>
                          <div className="col-lg-6 ">
                            <span className="fw-bold fs-6 text-gray-800">
                              {orderData?.refundData?.tracking_id || "-"}
                            </span>
                          </div>
                        </div> */}

                        <div className="row mb-7">
                          <label className="col-lg-6 fw-normal text-gray-700 fs-6">
                            {loading ? (
                              <Skeleton.Button
                                active
                                style={{ width: "200px" }}
                              />
                            ) : (
                              "Customer Order #:"
                            )}
                          </label>
                          <div className="col-lg-6 fv-row">
                            {loading ? (
                              <Skeleton.Button
                                active
                                style={{ width: "300px" }}
                              />
                            ) : (
                              <span className="fw-bold text-gray-800 fs-6">
                                {orderData?.refundData?.customer_order_id ||
                                  "-"}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="row mb-7">
                          <label className="col-lg-6 fw-normal text-gray-700 fs-6">
                            {loading ? (
                              <Skeleton.Button
                                active
                                style={{ width: "200px" }}
                              />
                            ) : (
                              "Purchase Order Id #:"
                            )}
                          </label>
                          <div className="col-lg-6 fv-row">
                            {loading ? (
                              <Skeleton.Button
                                active
                                style={{ width: "300px" }}
                              />
                            ) : (
                              <span className="fw-bold text-gray-800 fs-6">
                                {orderData?.refundData?.purchase_order_id ||
                                  "-"}
                              </span>
                            )}
                          </div>
                        </div>

                        {console.log(
                          orderData?.return_tracking_detail,
                          "return_tracking_detail"
                        )}
                        <div className="row mb-7">
                          <label className="col-lg-6 fw-normal text-gray-700 fs-6">
                            {loading ? (
                              <Skeleton.Button
                                active
                                style={{ width: "200px" }}
                              />
                            ) : (
                              "Tracking Status:"
                            )}
                          </label>
                          <div className="col-lg-6 fv-row">
                            {loading ? (
                              <Skeleton.Button
                                active
                                style={{ width: "300px" }}
                              />
                            ) : (
                              <span className="fw-bold text-primary fs-6">
                                {orderData?.refundData
                                  ?.current_tracking_statuses
                                  ? JSON.parse(
                                      orderData?.refundData
                                        ?.current_tracking_statuses
                                    )?.[0]?.status
                                  : "-"}
                              </span>
                            )}
                          </div>
                        </div>

                        {/* <div className="row mb-7">
                            <label className="col-lg-6 fw-normal text-gray-700 fs-6">
                              Request Date:
                            </label>
                            <div className="col-lg-6 fv-row">
                              <span className="fw-bold text-gray-800 fs-6">
                                {orderData?.refundData?.request_date || "-"}
                              </span>
                            </div>
                          </div> */}

                        <div className="row mb-7">
                          <label className="col-lg-6 fw-normal text-gray-700 fs-6">
                            {loading ? (
                              <Skeleton.Button
                                active
                                style={{ width: "200px" }}
                              />
                            ) : (
                              "Refund Status:"
                            )}
                          </label>
                          <div className="col-lg-6 fv-row">
                            {loading ? (
                              <Skeleton.Button
                                active
                                style={{ width: "300px" }}
                              />
                            ) : (
                              <span className="fw-bold text-danger fs-6">
                                {orderData?.refundData?.current_refund_status ||
                                  "-"}
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="row mb-7">
                          <label className="col-lg-6 fw-normal text-gray-700 fs-6">
                            {loading ? (
                              <Skeleton.Button
                                active
                                style={{ width: "200px" }}
                              />
                            ) : (
                              "Return Line:"
                            )}
                          </label>
                          <div className="col-lg-6 fv-row">
                            {loading ? (
                              <Skeleton.Button
                                active
                                style={{ width: "300px" }}
                              />
                            ) : (
                              <span className="fw-bold text-gray-800 fs-6">
                                {orderData?.refundData?.return_lines}
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="row mb-0">
                          <label className="col-lg-6 fw-normal text-gray-700 fs-6">
                            {" "}
                            {loading ? (
                              <Skeleton.Button
                                active
                                style={{ width: "200px" }}
                              />
                            ) : (
                              "Channel:"
                            )}
                          </label>
                          <div className="col-lg-6 fv-row">
                            {" "}
                            {loading ? (
                              <Skeleton.Button
                                active
                                style={{ width: "300px" }}
                              />
                            ) : (
                              <span className="fw-bold text-gray-800 fs-6">
                                {orderData?.refundData?.return_channel_name ||
                                  "-"}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card  mb-5 mb-xl-8">
                    <div className="card-header border-bottom-dashed">
                      <h3 className="card-title align-items-start flex-column">
                        <span className="card-label fw-bolder text-dark">
                          Order Details
                        </span>
                      </h3>
                      <div className="card-toolbar"></div>
                    </div>

                    <div className="card-body p-9">
                      <div className="">
                        <div className="row mb-7 ">
                          <label className="col-lg-6 fw-normal text-gray-700 fs-6">
                            {loading ? (
                              <Skeleton.Button
                                active
                                style={{ width: "200px" }}
                              />
                            ) : (
                              "Item Id #:"
                            )}
                          </label>
                          <div className="col-lg-6">
                            {loading ? (
                              <Skeleton.Button
                                active
                                style={{ width: "300px" }}
                              />
                            ) : (
                              <span className="fw-bold fs-6 text-gray-800">
                                {orderData?.itemDetails?.item_id || "-"}
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="row mb-7 ">
                          <label className="col-lg-6 fw-normal text-gray-700 fs-6">
                            {loading ? (
                              <Skeleton.Button
                                active
                                style={{ width: "200px" }}
                              />
                            ) : (
                              "Carrier Name : "
                            )}
                          </label>
                          <div className="col-lg-6">
                            {" "}
                            {loading ? (
                              <Skeleton.Button
                                active
                                style={{ width: "300px" }}
                              />
                            ) : (
                              <span className="fw-bold fs-6 text-primary">
                                {orderData?.orderDetails?.carrier_name || "-"}
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="row mb-7 ">
                          <label className="col-lg-6 fw-normal text-gray-700 fs-6">
                            {" "}
                            {loading ? (
                              <Skeleton.Button
                                active
                                style={{ width: "200px" }}
                              />
                            ) : (
                              "PO Line #:"
                            )}
                          </label>
                          <div className="col-lg-6">
                            {" "}
                            {loading ? (
                              <Skeleton.Button
                                active
                                style={{ width: "300px" }}
                              />
                            ) : (
                              <span className="fw-bold fs-6 text-gray-800">
                                {orderData?.refundData
                                  ?.purchase_order_line_number || "-"}
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="row mb-0">
                          <label className="col-lg-6 fw-normal text-gray-700 fs-6">
                            {loading ? (
                              <Skeleton.Button
                                active
                                style={{ width: "200px" }}
                              />
                            ) : (
                              "Fulfillment Type:"
                            )}
                          </label>
                          <div className="col-lg-6">
                            {" "}
                            {loading ? (
                              <Skeleton.Button
                                active
                                style={{ width: "300px" }}
                              />
                            ) : (
                              <span className="fw-bold fs-6 text-gray-800">
                                {orderData?.itemDetails?.fulfillment_type ||
                                  "-"}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-xxl-5 mx-auto "
                  style={{ position: "sticky", top: 100 }}
                >
                  <div className="">
                    <div
                      className="card card-xxl-flush  mb-5 mb-xl-8"
                      style={{ position: "sticky", top: 100 }}
                    >
                      <div className="card-header border-bottom-dashed">
                        <h3 className="card-title align-items-start flex-column">
                          <span className="card-label fw-bolder text-dark">
                            Order Balance
                          </span>
                        </h3>
                        <div className="card-toolbar"></div>
                      </div>

                      <div className="card-body p-9 pb-9">
                        {loading && (
                          <>
                            <div className="row mb-5">
                              <label className="col-lg-6 fw-normal text-gray-700 fs-6">
                                <Skeleton.Button
                                  active
                                  style={{ width: "200px" }}
                                />
                              </label>
                              <div className="col-lg-6">
                                <Skeleton.Button
                                  active
                                  style={{ width: "300px" }}
                                />
                              </div>
                            </div>
                            <div className="row mb-5">
                              <label className="col-lg-6 fw-normal text-gray-700 fs-6">
                                <Skeleton.Button
                                  active
                                  style={{ width: "200px" }}
                                />
                              </label>
                              <div className="col-lg-6">
                                <Skeleton.Button
                                  active
                                  style={{ width: "300px" }}
                                />
                              </div>
                            </div>
                            <div className="row mb-5">
                              <label className="col-lg-6 fw-normal text-gray-700 fs-6">
                                <Skeleton.Button
                                  active
                                  style={{ width: "200px" }}
                                />
                              </label>
                              <div className="col-lg-6">
                                <Skeleton.Button
                                  active
                                  style={{ width: "300px" }}
                                />
                              </div>
                            </div>
                          </>
                        )}

                        {!loading &&
                          total_charge?.map((d) => {
                            return d?.name !== "lineProductTaxes" &&
                              d?.name !== "lineSubTotal" ? (
                              <div className="row mb-7 ">
                                <label
                                  className="col-lg-7 fw-normal text-gray-700 fs-6"
                                  style={{ textTransform: "capitalize" }}
                                >
                                  {d?.name?.replace(/([A-Z])/g, " $1")}:
                                </label>
                                <div className="col-lg-5 fv-row text-end ">
                                  <span className="fw-bold text-gray-800 fs-6">
                                    ${d?.value?.currencyAmount || "-"}
                                  </span>
                                </div>
                              </div>
                            ) : (
                              ""
                            );
                          })}

                        {/* <div className="separator separator-dashed borde-gray-300 my-4 " />

                        <div className="row mt-5 ">
                          <label className="col-lg-7 fw-bold text-gray-700 fs-6">
                            Refunded Amount:
                          </label>
                          <div className="col-lg-5 fv-row text-end">
                            <span className="fw-bold text-danger fs-6">
                              $
                              {orderData?.refundData?.total_refund_amount ||
                                "-"}
                            </span>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
}
