export default (state, action) => {
  if (typeof state === "undefined") {
    return [];
  }
  switch (action.type) {
    case "GET_MARKETPLACE_CRED_LIST_SUCCESS":
    case "GET_MARKETPLACE_CRED_LIST_ERROR":
      return {
        ...state,
        GetMarketplaceCredListResponse: action.updatePayload,
      };

    case "GET_REGION_LIST_SUCCESS":
    case "GET_REGION_LIST_ERROR":
      return {
        ...state,
        GetRegionListResponse: action.updatePayload,
      };

    case "GET_REGION_WISE_MARKETPLACE_LIST_SUCCESS":
    case "GET_REGION_WISE_MARKETPLACE_LIST_ERROR":
      return {
        ...state,
        GetRegionWiseMarketplaceListResponse: action.updatePayload,
      };

    case "SP_GENERATE_REFRESH_SUCCESS":
    case "SP_GENERATE_REFRESH_ERROR":
      return {
        ...state,
        SPGenerateRefreshResponse: action.updatePayload,
      };

    case "SP_GENERATE_ADV_REFRESH_SUCCESS":
    case "SP_GENERATE_ADV_REFRESH_ERROR":
      return {
        ...state,
        ADVGenerateRefreshResponse: action.updatePayload,
      };

    case "SAVE_AMAZON_ADV_CREDENTIAL_SUCCESS":
    case "SAVE_AMAZON_ADV_CREDENTIAL_ERROR":
      return {
        ...state,
        SaveAmazonAdvCredentialResponse: action.updatePayload,
      };

    case "GET_WM_CREDENTIAL_SUCCESS":
    case "GET_WM_CREDENTIAL_ERROR":
      return {
        ...state,
        GetWalmartCredentialResponse: action.updatePayload,
      };

    case "STORE_WM_MARKETPLACE_SUCCESS":
    case "STORE_WM_MARKETPLACE_ERROR":
      return {
        ...state,
        StoreWalMartMarketplaceResponse: action.updatePayload,
      };

    case "STORE_WM_CONNECT_SUCCESS":
    case "STORE_WM_CONNECT_ERROR":
      return {
        ...state,
        StoreWalMartConnectResponse: action.updatePayload,
      };

    case "FAKE_ACTION_MARKETPLACE_CRED":
      return { ...state, [action?.state]: {} };
    default:
      return state;
  }
};
