import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import Chart from "react-apexcharts";
import { NumberWithCommas } from "../../../../config";
import {
  CloudDownloadOutlined,
  FilePdfOutlined,
  FileExcelOutlined,
} from "@ant-design/icons";
import { Skeleton, Tooltip, message } from "antd";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import html2pdf from "html2pdf.js";
import { useReactToPrint } from "react-to-print";
import writeXlsxFile from "write-excel-file";
import Highcharts from "highcharts";
import { savePDF } from "@progress/kendo-react-pdf";
import NoData from "../../../../component/no-data";

export default function (props) {
  const {
    unitSoldChartDate,
    unitSoldChartSeries,
    unitSoldChartLoading,
    selectedDateRangeType,
    exportGraphArray,
    selectedViewBy,
  } = props;

  const options = {
    chart: {
      toolbar: {
        show: false,
        offsetY: -58,
        tools: {
          download: '<img src="/assets/media/downloads.png" width="20"/>',
        },
        export: {
          // Set the filename
          filename: "Unit Sold Graph",
          // Include only PNG
          formats: ["png"],
        },
      },
      selection: {
        enabled: true,
      },
      dropShadow: {
        enabled: !0,
        top: 12,
        left: 0,
        bottom: 0,
        right: 0,
        blur: 2,
        color: "rgba(132, 145, 183, 0.4)",
        opacity: 0.45,
      },
    },
    stroke: {
      width: [0, 0, 2, 2],
      curve: "smooth",
    },
    plotOptions: {
      bar: {
        horizontal: false,
        // endingShape: "rounded",
        columnWidth: "50%",
        borderRadius: 3,
      },
    },
    noData: {
      text: "No Data",
      align: "center",
      verticalAlign: "middle",
      style: {
        fontSize: "20px",
      },
    },
    tooltip: {
      marker: {
        show: true,
      },
      x: {
        show: true,
      },
      y: {
        formatter: function (
          value,
          { series, seriesIndex, dataPointIndex, w }
        ) {
          const withTitle_ = w?.config?.series?.[seriesIndex];
          console.log(withTitle_, "withTitle_");
          return withTitle_?.name === "Unit Sold"
            ? value || 0
            : "$" + NumberWithCommas(value);
        },
      },
    },
    colors: [
      "#e76f03", //orange
      "#6048d3", //blue
      "#f0d854", //yellow
      "#12c81a", //green
    ],
    yaxis: [
      {
        seriesName: "Total Sales",
        title: {
          text: "Total Sales",
        },
        labels: {
          formatter: function (_) {
            return NumberWithCommas(_);
          },
        },
      },
      {
        seriesName: "Unit Sold",
        opposite: true,
        title: {
          text: "Unit Sold",
        },
        labels: {
          formatter: function (_) {
            return NumberWithCommas(_);
          },
        },
      },

      // {
      //   seriesName: "Ad Spend",
      //   opposite: false,
      //   title: {
      //     text: "Ad Spend",
      //   },
      //   labels: {
      //     formatter: function (_) {
      //       return NumberWithCommas(_);
      //     },
      //   },
      // },
      // {
      //   seriesName: "TACos",
      //   opposite: true,
      //   title: {
      //     text: "TACos",
      //   },
      //   labels: {
      //     formatter: function (_) {
      //       return NumberWithCommas(_);
      //     },
      //   },
      // },
    ],
    // yaxis: {
    //   labels: {
    //     formatter: function (_) {
    //       return NumberWithCommas(_);
    //     },
    //     offsetX: -12,
    //     offsetY: 0,
    //   },
    // },
    dataLabels: {
      enabled: false, // Set this to true if you want to display data labels initially
    },
    markers: {
      size: 5,
    },
    xaxis: {
      tickPlacement: "between",
      tickAmount: window.innerWidth >= 992 ? 15 : 5,
      categories:
        unitSoldChartDate?.map((d) =>
          selectedDateRangeType === "last52_week" ||
          selectedDateRangeType === "year_to_date"
            ? d
            : selectedViewBy === "month"
            ? moment(new Date(d)).format("MMM YYYY")
            : moment(new Date(d)).format("DD MMM YYYY")
        ) || [],
      labels: {
        rotate: -50,
      },
    },
    grid: {
      strokeDashArray: 3,
      xaxis: { lines: { show: !0 } },
      yaxis: { lines: { show: !1 } },
    },
    legend: {
      show: true,
    },

    // fill: {
    //   type: "gradient",
    //   gradient: {
    //     type: "vertical",
    //     shadeIntensity: 1,
    //     inverseColors: !1,
    //     opacityFrom: 0.05,
    //     opacityTo: 0.05,
    //     stops: [45, 100],
    //   },
    // },
  };
  const chartRef = useRef(null);

  // const handleExportPDF = useReactToPrint({
  //   content: () => chartRef.current,
  //   documentTitle: "Unit Sold Chart",
  //   onAfterPrint: () => {
  //     // message.destroy();
  //     // message.success("PDF Downloaded");
  //     // // Callback after printing, you can use it to trigger further actions.
  //     // console.log("Printed successfully!");
  //   },
  //   pageStyle: `
  //     @page {
  //       size: A4 landscape;
  //       margin: 0;
  //     }
  //     body {
  //       margin: 0;
  //       display: flex;
  //       align-items: center;
  //       justify-content: center;
  //       height: 100vh;
  //     }
  //   `,
  // });

  const handleExportPDF = () => {
    savePDF(chartRef.current, { paperSize: "auto", fileName: "chart.pdf" });
  };

  const exportHeaderAmazon = [
    { value: "Date", type: String },
    { value: "Total Sales", type: String },
    { value: "Unit Sold", type: String },
    { value: "Ad Spend", type: String },
    { value: "TACoS", type: String },
  ];

  const downloadXlAmazon = async () => {
    let dataRow = [];
    await exportGraphArray.map((d) => {
      const newList = [
        d?.date,
        d?.order_sales,
        d?.unit_sold,
        d?.ad_spend,
        d?.TACoS,
      ];
      dataRow = [
        ...dataRow,
        newList.map((d) => {
          return { value: d };
        }),
      ];
    });
    const data = [exportHeaderAmazon, ...dataRow];
    await writeXlsxFile(data, {
      fileName: "Unit Sold Graph.xlsx",
    });
    message.destroy();
  };

  const sign = {
    TACoS: {
      valueSuffix: "%",
      valuePrefix: "",
    },
    "Unit Sold": {
      valueSuffix: "",
      valuePrefix: "",
    },
    "Total Sales": {
      valueSuffix: "",
      valuePrefix: "$",
    },
    "Ad Spend": {
      valueSuffix: "",
      valuePrefix: "$",
    },
  };

  // const colors = [
  //   "#70ad47", //green
  //   "#e76f03", //orange
  //   "#6048d3", //blue
  //   "#fec30d", //yellow
  // ];

  const colors = ["#8992d4", "#373fb5", "#98cabb", "#097980"];
  // const colors = ["#cbccff", "#be81ff", "#7c07fe", "#fe80ff"];
  // const colors = ["#1ebbb4", "#18789f", "#c11577", "#031262"];
  // const colors = ["#989bca", "#0a4980", "#7bded2", "#c11577"];
  // const colors = ["#98cabb", "#097980", "#ddc820", "#c11577"];
  useEffect(() => {
    if (unitSoldChartDate?.length === 0 || unitSoldChartSeries?.length === 0)
      return;
    Highcharts.chart("line-chart", {
      chart: {
        style: {
          fontFamily: 'Inter, Helvetica, "sans-serif"',
        },
        renderTo: "sales-by-week-graph",
        height: "430px",
        style: {
          fontFamily: "Oswald,sans-serif",
        },
      },
      type: "column",
      title: "",
      xAxis: {
        title: {
          text: "Date",
        },
        categories: unitSoldChartDate,
      },
      // plotOptions: {
      //   series: {
      //     borderWidth: 0,
      //     dataLabels: {
      //       enabled: true,
      //       format: "{point.y:.2f}",
      //     },
      //   },
      // },
      yAxis: [
        {
          title: {
            text: "",
          },
          stackLabels: {
            enabled: true,
            formatter: function () {
              return this.stack;
            },
          },
          labels: {
            formatter: function () {
              return this.value?.toLocaleString();
            },
          },
        },
        {
          title: {
            text: `<label class="fw-bolder">Unit Sold</label>`,
            // text: "",
          },
          opposite: true,
          stackLabels: {
            enabled: true,
            // formatter: function () {
            //   return this.stack;
            // },
          },
          labels: {
            formatter: function () {
              return this.value?.toLocaleString();
            },
          },
        },
        {
          title: {
            text: `<label class="fw-bolder">TACoS</label>`,
            // text: "",
          },
          opposite: true,
          stackLabels: {
            enabled: true,
            // formatter: function () {
            //   return this.stack;
            // },
          },
          labels: {
            formatter: function () {
              return this.value?.toLocaleString() + "%";
            },
          },
        },
      ],
      series: unitSoldChartSeries?.map((d, i) => ({
        name: d?.name,
        type: i == 3 || i == 2 ? "line" : "column",
        // tooltip: sign?.[d?.name],
        color: colors?.[i] || "#A52A2A",
        data: d?.data,
        // yAxis: 0,
        yAxis:
          d?.name === "Total Sales"
            ? 0
            : d?.name === "Unit Sold"
            ? 1
            : d?.name === "Ad Spend"
            ? 0
            : 2,
        // yAxis: i == 1 ? 1 : 2,
      })),
      tooltip: {
        useHTML: true,
        shared: true,
        style: {
          fontSize: "14px", // Change font size
          padding: "10px", // Change padding
          // You can add more properties to modify the tooltip style
        },
        pointFormatter: function () {
          return (
            '<span style="color:' +
            this.color +
            '">\u25CF</span> ' +
            '<span style="text-transform: capitalize">' +
            this.series.name +
            "</span>" +
            ": <b>" +
            sign?.[this.series.name]?.valuePrefix +
            this.y?.toLocaleString() +
            sign?.[this.series.name]?.valueSuffix +
            "</b><br/>"
          );
        },
      },
    });
  }, [unitSoldChartSeries]);
  return (
    <>
      <div
        className="card card-flush"
        style={
          {
            // filter: unitSoldChartLoading ? "blur(6px)" : "blur(0px)",
            // backgroundImage: "url(/assets/media/crossword.png)",
            // boxShadow: "#8f7be963 0px 0px 18px -1px",
          }
        }
      >
        {/*begin::Header*/}
        <div className="card-header pt-7">
          {/*begin::Title*/}
          <h3 className="card-title align-items-start flex-column">
            {/* <h3 className="card-title align-items-enter d-flex"> */}

            <span className="card-label fw-bold text-dark">Unit Sold</span>
            <span className="text-gray-400 pt-2 fw-semibold fs-6">
              Unit Sold Overview
            </span>

            {/* <button
              className="btn btn-primary"
              onClick={() => {
                message.destroy();
                message.loading("Loading...", 0);

                // Replace 'elementToCapture' with the ID or ref of the DOM element you want to capture
                const elementToCapture = document.getElementById("chartZoom");

                html2canvas(elementToCapture).then((canvas) => {
                  const imgData = canvas.toDataURL("image/png");

                  const pdf = new jsPDF();

                  const text = "Unit Sold Graph";
                  pdf.text(text, 10, 15);

                  const desiredWidth = 300;
                  // Calculate the height to maintain the original aspect ratio
                  const aspectRatio = canvas.width / canvas.height;
                  const desiredHeight = desiredWidth / aspectRatio;

                  pdf.addImage(
                    imgData,
                    "PNG",
                    0,
                    30,
                    desiredWidth,
                    desiredHeight
                  );

                  // pdf.addImage(imgData, "PNG", 25, 25);

                  // Replace 'output.pdf' with the desired file name
                  pdf.save("output.pdf");
                  message.destroy();
                });
              }}
            >
              Download PDF
            </button> */}
          </h3>
          {/*end::Title*/}
          {/*begin::Toolbar*/}
          <div className="card-toolbar">
            <div className="d-flex">
              {unitSoldChartLoading ? (
                <Skeleton.Button active className="me-2" />
              ) : (
                <span
                  className="d-flex align-items-center cursor-pointer me-5"
                  onClick={() => {
                    handleExportPDF();
                  }}
                  // onClick={() => {
                  //   message.destroy();
                  //   message.loading("Loading...", 0);

                  //   // Replace 'elementToCapture' with the ID or ref of the DOM element you want to capture
                  //   const elementToCapture = document.getElementById("chartZoom");

                  //   html2canvas(elementToCapture).then((canvas) => {
                  //     const imgData = canvas.toDataURL("image/png", 1.0);
                  //     var pdf = new jsPDF();

                  //     const desiredWidth = 300;

                  //     const aspectRatio = canvas.width / canvas.height;
                  //     const desiredHeight = desiredWidth / aspectRatio;

                  //     pdf.addImage(imgData, "PNG", 0, 0);

                  //     pdf.save("output.pdf");
                  //     message.destroy();
                  //   });
                  // }}
                >
                  <Tooltip title="Download PDF" placement="bottom">
                    <FilePdfOutlined
                      className="fs-1"
                      style={{ color: "red" }}
                    />
                  </Tooltip>
                </span>
              )}
              {unitSoldChartLoading ? (
                <Skeleton.Button active />
              ) : (
                <span
                  className="d-flex align-items-center cursor-pointer"
                  onClick={() => {
                    // handleExportPDF();
                    downloadXlAmazon();
                  }}
                >
                  <Tooltip title="Download Excel" placement="bottom">
                    <FileExcelOutlined
                      className="fs-1"
                      style={{ color: "blue" }}
                    />
                  </Tooltip>
                </span>
              )}
            </div>
          </div>
          {/*end::Toolbar*/}
        </div>
        {/*end::Header*/}
        {/*begin::Body*/}
        <div className="card-body pb-0">
          {/*begin::Map container*/}
          {/* <div
            id="chartZoom"
            className="w-100 position-relative"
            style={{ minHeight: "395px" }}
          >
            <Chart
              options={options}
              series={unitSoldChartSeries}
              type="area"
              height={432}
            />
          </div> */}

          <div className="w-100" style={{ minHeight: "430px" }} id="chartZoom">
            {unitSoldChartLoading ? (
              <div className="d-flex justify-content-center align-items-center">
                <Skeleton.Button block active style={{ height: "300px" }} />
              </div>
            ) : unitSoldChartDate?.length != 0 ? (
              <div id="line-chart" ref={chartRef}></div>
            ) : (
              <NoData height="400px" />
            )}

            {/* <Chart
              options={options}
              series={unitSoldChartSeries}
              height={413}
              ref={chartRef}
            /> */}
          </div>

          {/*end::Map container*/}
        </div>
        {/*end::Body*/}
      </div>
    </>
  );
}
