import React, { useEffect, useState } from "react";
import Pagination from "../../../../../component/pagination";
import { DefaultPerPage, SalesChannelFlag } from "../../../../../config";
import Loading from "../../../../../component/loading";
import IvcsrTable from "../../../../../component/ivcsr-table";
import NoData from "../../../../../component/no-data";
import { useSelector } from "react-redux";
import { Input, Popover, Select, message } from "antd";
import writeXlsxFile from "write-excel-file";
import { SearchOutlined } from "@ant-design/icons";
import { WalmartMarketplaceList } from "../../../../../config/static-select-option";

export default function (props) {
  const {
    GetWalmartInventoryDetailsList,
    fakeActionInventoryDetails,

    GetSalesChannelList,
    fakeActionSalesAnalytics,
  } = props;
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageSize, setPageSize] = useState(DefaultPerPage);
  const [isExportFalg, setIsExportFlag] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const [salesChannelList, setsalesChannelList] = useState([]);
  const [selectedSalesChannel, setSelectedSalesChannel] = useState([]);
  const [salesChannelLoading, setSalesChannelLoading] = useState(true);
  const [selectedCurrencySign, setSelectedCurrencySign] = useState("");

  const GetWalmartInventoryDetailsListRes = useSelector(
    (state) =>
      state.InventoryDetails.GetWalmartInventoryDetailsListResponse || {}
  );
  const GetSalesChannelListRes = useSelector(
    (state) => state.SalesAnalytics.GetSalesChannelListResponse || {}
  );
  useEffect(() => {
    GetSalesChannelList();
  }, []);

  useEffect(() => {
    if (GetSalesChannelListRes?.status === true) {
      setSalesChannelLoading(false);
      setsalesChannelList(GetSalesChannelListRes?.data?.sales_channel);
      setSelectedSalesChannel("ATVPDKIKX0DER");
      setSelectedCurrencySign("$");
      const Obj = {
        page: 1,
        perPage: pageSize,
        marketplaceId: "ATVPDKIKX0DER",
        isExport: 0,
        search: searchTerm,
      };
      GetWalmartInventoryDetailsList(Obj);
      fakeActionSalesAnalytics("GetSalesChannelListResponse");
    } else if (GetSalesChannelListRes?.status === false) {
      fakeActionSalesAnalytics("GetSalesChannelListResponse");
    }
  }, [GetSalesChannelListRes]);

  useEffect(() => {
    if (GetWalmartInventoryDetailsListRes?.status === true) {
      if (isExportFalg) {
        downloadXl(GetWalmartInventoryDetailsListRes?.data?.records);
        setIsExportFlag(false);
      } else {
        console.log(
          GetWalmartInventoryDetailsListRes,
          "GetWalmartInventoryDetailsListRes"
        );
        setTotalPage(
          GetWalmartInventoryDetailsListRes?.data?.pagination?.totalCount
        );

        setList(GetWalmartInventoryDetailsListRes?.data?.records);
        setLoading(false);
      }

      fakeActionInventoryDetails("GetWalmartInventoryDetailsListResponse");
    } else if (GetWalmartInventoryDetailsListRes?.status === false) {
      fakeActionInventoryDetails("GetWalmartInventoryDetailsListResponse");
    }
  }, [GetWalmartInventoryDetailsListRes]);

  const exportHeader = [
    { value: "Product Title", type: String },
    { value: "SKU", type: String },
    { value: "Input Quantity", type: String },
    { value: "Sell Quantity", type: String },
    { value: "Item Id", type: String },
  ];

  const downloadXl = async (productData) => {
    let dataRow = [];
    await productData.map((d) => {
      const newList = [
        d?.product_name,
        d?.sku,
        d?.input_quantity,
        d?.avail_to_sell_quantity,
        d?.item_id,
      ];
      dataRow = [
        ...dataRow,
        newList.map((d) => {
          return { value: d };
        }),
      ];
    });
    const data = [exportHeader, ...dataRow];
    await writeXlsxFile(data, {
      fileName: "Walmart Products.xlsx",
    });
    message.destroy();
  };

  const walmartColumns = [
    {
      title: "#",
      ellipsis: true,
      align: "left",
      render: (text) => {
        return <span>{(page - 1) * pageSize + 1 + text.key}</span>;
      },
    },
    {
      title: "SKU",
      dataIndex: "sku",
      key: "sku",
      render: (text) => <span>{text || "-"}</span>,
    },
    {
      title: "Product Title",
      dataIndex: "product_name",
      key: "product_name",
      render: (text) => {
        return (
          <Popover
            content={
              <div
                style={{
                  height: "max-content",
                  maxWidth: "300px",
                }}
              >
                {text}
              </div>
            }
            placement="bottom"
          >
            <div className="popoverActionIcon">{text}</div>
          </Popover>
        );
      },
    },
    {
      title: "Input Quantity",
      dataIndex: "input_quantity",
      key: "input_quantity",
      sorter: (a, b) => a?.input_quantity - b?.input_quantity,
      render: (text) => <span>{text || "-"}</span>,
    },
    {
      title: "Sell Quantity",
      dataIndex: "avail_to_sell_quantity",
      key: "avail_to_sell_quantity",
      sorter: (a, b) => a?.avail_to_sell_quantity - b?.avail_to_sell_quantity,
      render: (text) => <span>{text || "-"}</span>,
    },
    {
      title: "Item Id",
      dataIndex: "item_id",
      key: "item_id",
      render: (text) => <span>{text || "-"}</span>,
    },
  ];

  const onPageNo = (e) => {
    setLoading(true);
    const apiObj = {
      page: e,
      perPage: pageSize,
      marketplaceId: selectedSalesChannel,
      isExport: 0,
      search: searchTerm,
    };
    GetWalmartInventoryDetailsList(apiObj);
    setPage(e);
  };

  const onPerPage = (e) => {
    setPage(1);
    setPageSize(e);
    setLoading(true);
    const apiObj = {
      page: 1,
      perPage: e,
      marketplaceId: selectedSalesChannel,
      isExport: 0,
      search: searchTerm,
    };
    GetWalmartInventoryDetailsList(apiObj);
  };
  return (
    <>
      {" "}
      <div className="fadeInRight">
        <div className="d-flex flex-column flex-column-fluid">
          <div id="kt_app_content" className="app-content flex-column-fluid">
            <div
              id="kt_app_content_container"
              className="app-container container-fluid"
            >
              <div>
                <div className="row gy-5 g-xl-5">
                  <div className="col-xxl-12">
                    <div className="card card-xxl-stretch mb-5 mb-xl-8">
                      <div className="card-header border-bottom-dashed">
                        <h3 className="card-title ">
                          <span className="card-label fw-bold text-gray-900">
                            <div className="position-relative">
                              <Input
                                type="text"
                                className="text-dark w-250px fs-7 fw-bold ant_common_input"
                                name="all_search"
                                placeholder="Search by SKU..."
                                value={searchTerm}
                                onChange={(e) => {
                                  setSearchTerm(e.target.value);
                                }}
                                onPressEnter={(e) => {
                                  setLoading(true);
                                  const Obj = {
                                    page: 1,
                                    perPage: pageSize,
                                    marketplaceId: selectedSalesChannel,
                                    isExport: 0,
                                    search: searchTerm,
                                  };
                                  GetWalmartInventoryDetailsList(Obj);
                                }}
                                prefix={
                                  <SearchOutlined
                                    style={{ fontSize: "19px", color: "grey" }}
                                  />
                                }
                                size="large"
                              />
                            </div>
                          </span>
                        </h3>

                        <div className="card-toolbar gap-2">
                          <div className="position-relative">
                            {/* <Select
              className="w-200px"
              size="large"
              placeholder="Select Sales Channel"
              loading={salesChannelLoading}
              value={selectedSalesChannel || null}
              onChange={(e, _) => {
                setSelectedSalesChannel(e);
                setPage(1);
                setPageSize(DefaultPerPage);
              }}
              options={SalesChannelFlag(salesChannelList)}
            /> */}
                          </div>
                          <div className="position-relative">
                            <button
                              className="btn btn-light-info fw-bolder fs-7"
                              onClick={() => {
                                setIsExportFlag(true);
                                message.destroy();
                                message.loading("Loading...", 0);
                                const Obj = {
                                  page: 1,
                                  perPage: pageSize,
                                  marketplaceId: selectedSalesChannel,
                                  isExport: 1,
                                  search: searchTerm,
                                };
                                GetWalmartInventoryDetailsList(Obj);
                              }}
                            >
                              Export
                            </button>
                          </div>
                          <div className="position-relative">
                            <Select
                              className="w-250px"
                              size="large"
                              placeholder="Select Sales Channel"
                              loading={salesChannelLoading}
                              value={selectedSalesChannel || null}
                              onChange={(e, _) => {
                                setSelectedSalesChannel(e);
                                setPage(1);
                                setPageSize(DefaultPerPage);
                              }}
                              // options={SalesChannelFlag(salesChannelList)}
                              options={
                                salesChannelList?.length > 0
                                  ? SalesChannelFlag(salesChannelList)
                                  : SalesChannelFlag(WalmartMarketplaceList)
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="card-body pb-5 pt-0">
                        <div className="table-responsive mt-5">
                          {loading ? (
                            <Loading />
                          ) : list?.length != 0 ? (
                            <>
                              <IvcsrTable
                                columns={walmartColumns?.map((d) => ({
                                  ...d,
                                  width:
                                    d?.title === "Product Title"
                                      ? 300
                                      : d?.title?.length * 13,
                                }))}
                                sticky={{
                                  offsetHeader: "10px",
                                }}
                                scroll={{
                                  x: "max-content",
                                  y: "calc(100vh - 200px)",
                                }}
                                fixed="top"
                                dataSource={list}
                                rowKey="key"
                                loading={loading}
                                pagination={false}
                              />
                            </>
                          ) : (
                            <NoData />
                          )}
                        </div>
                        <Pagination
                          loading={loading || list?.length === 0}
                          pageSize={pageSize}
                          page={page}
                          totalPage={totalPage}
                          onPerPage={onPerPage}
                          onPageNo={onPageNo}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
