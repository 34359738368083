import { message } from "antd";
import dayjs from "dayjs";
import moment from "moment";

export const ENDPOINT = "https://api.ivcsr.com";
export const API_URL = `${ENDPOINT}/api/v1/`;
export const WALMART_ENDPOINT = "https://service.ivcsr.com";
export const WALMART_API_URL = `${WALMART_ENDPOINT}/api/v1/`;
export const pageDropdown = [25, 50, 100];
export const DefaultPerPage = 25;
export const DateFormat = "MMM DD, YYYY";
export const getUserId = () => {
  return JSON.parse(localStorage.getItem("user"))?.id;
};
export const timeSince = (date) => {
  const units = [
    { label: "year", seconds: 31536000 },
    { label: "month", seconds: 2592000 },
    { label: "day", seconds: 86400 },
    { label: "hour", seconds: 3600 },
    { label: "minute", seconds: 60 },
    { label: "second", seconds: 1 },
  ];

  const seconds = Math.floor((new Date() - date * 1000) / 1000);

  for (let i = 0; i < units.length; i++) {
    const interval = seconds / units[i].seconds;
    if (interval > 1) {
      return (
        Math.floor(interval) +
        " " +
        units[i].label +
        (interval > 1 ? "s" : "") +
        " ago"
      );
    }
  }

  return "just now";
};

export const urlDecode = (url) => {
  return (url?.search?.split("?")[1]?.split("&") || []).reduce((newObj, e) => {
    const obj = e.split("=");
    return { ...newObj, [obj[0]]: obj[1] };
  }, {});
};

export const SalesChannelFlag = (salesChannelList) =>
  salesChannelList.map((d, i) => {
    return {
      value: d?.marketplace_id || d?.sales_channel,
      label: (
        <>
          <img
            src={`/assets//media/domainImage/${
              d?.marketplace_id || d?.sales_channel
            }.png`}
            style={{ height: "20px" }}
            loading="lazy"
            className="me-3"
            onError={(e) => {
              e.target.src = "/assets/media/domainImage/red-flag.png";
            }}
          />
          {d?.marketplace} {d?.sales_channel && `(${d?.sales_channel})`}
        </>
      ),
      ...d,
    };
  });

export const NumberWithCommas = (n) => {
  const num_ = parseFloat(n || 0);
  if (num_) {
    if (Number(num_) === num_ && num_ % 1 !== 0) {
      return parseFloat(num_)
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return parseInt(num_)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return num_;
};

export const convertNumberToKMB = (number) => {
  const newValue = NumberWithCommas(number);
  if (number < 1000) {
    return number; // Less than a thousand, so no conversion needed.
  } else if (number < 1000000) {
    return (number / 1000).toFixed(2) + "K"; // Convert to thousands (K).
  } else if (number < 1000000000) {
    return (number / 1000000).toFixed(2) + "M"; // Convert to millions (M).
  } else {
    return (number / 1000000000).toFixed(2) + "B"; // Convert to billions (B).
  }
};

const downloadFile = (href, name) => {
  const link = document.createElement("a");
  link.href = `${href}${name}`;
  link.setAttribute("download", name);
  link.click();
  link.remove();
  setTimeout(() => {
    message.destroy();
  }, 2000);
};

export const WarehouseSampleTemplate = () => {
  return downloadFile(
    "/assets/media/downloadTemplate/",
    "warehouse_stock_sample.xlsx"
  );
};

export const ItemActivityFeedSampleTemplate = () => {
  return downloadFile(
    "/assets/media/downloadTemplate/",
    "Create_Item_Sample_File.xlsx"
  );
};

export const ItemActivityBulkUploadSampleTemplate = (
  selectedOption,
  visibleToOption
) => {
  const fileName =
    selectedOption === "MP_ITEM"
      ? `Create Item Sample File - ${visibleToOption}.xlsx`
      : selectedOption === "price"
      ? "Update_Bulk_Price_Sample_File.xlsx"
      : selectedOption === "inventory"
      ? "Update_Bulk_Inventory_Sample_File.xlsx"
      : selectedOption === "RETURNS_OVERRIDES"
      ? "Bulk_Return Overrides.xlsx"
      : selectedOption === "MP_ITEM_MATCH"
      ? "Create_Item_By_match_Sample_File.xlsx"
      : "Create_Item_Sample_File.xlsx";
  return downloadFile("/assets/media/downloadTemplate/", fileName);
};

const presetRanges = [
  {
    label: "Today",
    value: [dayjs(), dayjs()],
    filterKey: "today",
  },
  {
    label: "Yesterday",
    value: [dayjs().add(-1, "d"), dayjs().add(-1, "d")],
    filterKey: "yesterday",
  },
  {
    label: "LW",
    value: [
      dayjs().subtract(1, "week").startOf("week"),
      dayjs().subtract(1, "week").endOf("week"),
    ],
    filterKey: "last_week",
  },
  {
    label: "L4W",
    value: [dayjs().add(-28, "d"), dayjs()],
    filterKey: "last4_week",
  },
  {
    label: "L13W",
    value: [dayjs().add(-91, "d"), dayjs()],
    filterKey: "last13_week",
  },
  {
    label: "YTD",
    value: [dayjs().startOf("year"), dayjs()],
    filterKey: "year_to_date",
  },
  {
    label: "L52W",
    value: [dayjs().add(-365, "d"), dayjs()],
    filterKey: "last52_week",
  },
  {
    label: "YOY L4W",
    value: [
      dayjs().subtract(4, "weeks").subtract(1, "year"),
      dayjs().subtract(1, "year"),
    ],
    filterKey: "yoy_last4_week",
  },
  {
    label: "Custom Range",
    value: [0, 0],
    filterKey: "custom",
  },
];

export const handleDateRangeChange = (value, dateString) => {
  console.log(dateString, "-------------");
  const selectedPresetRange = presetRanges.filter((range) => {
    console.log(range, "-range");
    return (
      dayjs(range?.value?.[0]).format("YYYY-MMM-DD") ===
        dateString?.[0]?.format("YYYY-MMM-DD") &&
      dayjs(range?.value?.[1]).format("YYYY-MMM-DD") ===
        dateString?.[1]?.format("YYYY-MMM-DD")
    );
  });

  return selectedPresetRange?.length !== 0
    ? selectedPresetRange?.[0]?.filterKey
    : "custom";
};

export const isNot2000SeriesYear = (date) => {
  const year = new Date(date).getFullYear();
  return year < 2000 || year > 2099;
};

export const bidManagementValidKeyName = (value) => {
  const keyNameObj = {
    rule_name: "rule name",
    action_type: "action type",
    notification_type: "notification type",
    apply_rule_type: "apply the rule to type",
    apply_rule_attribute: "attribute",
    campaign_list: "campaigns",
    notifications_email: "notifications email",
  };
  return keyNameObj[value];
};

const isBlank = (value) => {
  return value == undefined || value == null || value == "";
};

export const returnBlankKeyFromObj = (newObj) => {
  const key = Object.entries(newObj).find(([key, value]) => {
    if (isBlank(value)) {
      return key; // Found a blank value, immediately return true to stop iteration
    }
  });
  return key;
};

// export const handleDateRangeChange = (value, dateString) => {
//   const selectedPresetRange = presetRanges.filter((range) => {
//     return (
//       dayjs(range?.value?.[0]).format("YYYY-MMM-DD") === dateString?.[0] &&
//       dayjs(range?.value?.[1]).format("YYYY-MMM-DD") === dateString?.[1]
//     );
//   });

//   const date1 = new Date(dateString?.[0]);
//   const date2 = new Date(dateString?.[1]);

//   // Calculate the difference in milliseconds
//   const differenceInMilliseconds = date2 - date1;

//   // Convert milliseconds to days
//   const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);

//   setSelectedRange(
//     selectedPresetRange?.length !== 0
//       ? selectedPresetRange?.[0]?.label
//       : "custom"
//   );
//   return selectedPresetRange?.length !== 0
//     ? selectedPresetRange?.[0]?.label
//     : "custom";
// };
