import React, { useEffect, useState } from "react";
// import { Wrapper, NewLogin } from "./style";
import { Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { Checkbox, Input, Radio, message } from "antd";
import { Link } from "react-router-dom";

export default function (props) {
  const { SignUpAction, fakeActionAuth } = props;

  const [redirectPage, setRedirectPage] = useState(false);
  const [username, setUserName] = useState("");
  const [company_name, set_company_name] = useState("");
  const [email, set_email] = useState("");
  const [phone_number, set_phone_number] = useState("");
  const [password, set_password] = useState("");
  const [confirm_password, set_confirm_password] = useState("");
  const [connected_by, set_connected_by] = useState("");
  const [sending, set_sending] = useState(false);

  const SignUpRes = useSelector((state) => state.Auth.SignUpResponse || {});

  const onSignUp = () => {
    const isValidEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
      email
    );
    if (isValidEmail) {
      if (password === confirm_password) {
        set_sending(true);
        const Obj = {
          name: username,
          email: email,
          password: password,
          contact_no: phone_number,
          seller_name: company_name,
          connected_by: connected_by,
        };
        SignUpAction(Obj);
      } else {
        message.destroy();
        message.info("Password and confirm password not matched");
      }
    } else {
      message.destroy();
      message.info("Invalid email");
    }
  };

  useEffect(() => {
    if (SignUpRes?.status) {
      message.destroy();
      message.success("User Created");
      set_sending(false);
      setRedirectPage(true);
      fakeActionAuth("SignUpResponse");
    } else if (Object.keys(SignUpRes).length !== 0) {
      set_sending(false);
      message.destroy();
      message.error(SignUpRes?.message);
      fakeActionAuth("SignUpResponse");
    }
  }, [SignUpRes]);

  if (redirectPage) {
    return <Redirect to={"/login"} />;
  }

  return (
    <>
      <div
        className="d-flex flex-column flex-column-fluid flex-lg-row"
        style={{
          height: "100%",
          backgroundImage: "url(/assets/media/loginBgSvg.svg)",
          backgroundSize: "cover",
        }}
      >
        <div className="d-flex flex-center w-lg-50 pt-15 pt-lg-0 px-10">
          <div className="d-flex flex-center flex-lg-start flex-column">
            <span
              className="mb-7 d-flex"
              style={{
                display: window.innerWidth > 992 ? "" : "flex",
                justifyContent: window.innerWidth > 992 ? "" : "center",
              }}
            >
              <img
                alt="Logo"
                src="/assets/media/all-images/ivcsr-logo-new.png"
                className={`shimmer ${window.innerWidth > 992 ? "h-80px" : ""}`}
                style={{ width: window.innerWidth > 992 ? "" : "80%" }}
              />
            </span>
            <h2 className="text-dark fw-light m-0 fs-1x">
              Working for <b>your Growth</b>
            </h2>
          </div>
        </div>

        <div className="d-flex flex-center w-lg-50 p-10">
          <div
            className="card rounded-3 w-md-650px"
            style={{
              borderTop: "3px solid #4318ff",
              boxShadow:
                "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px",
              //  background: "#e4e6fe7a"
            }}
          >
            <div className="card-body d-flex flex-column p-10 p-lg-20 pb-lg-10">
              <div className="d-flex flex-center flex-column-fluid pb-15 pb-lg-20">
                <span className="form w-100">
                  <div className="text-center mb-11">
                    <h1 className="text-dark fw-bolder mb-3">Sign Up</h1>

                    <div className="text-gray-500 fw-semibold fs-6">
                      Your Social Campaigns
                    </div>
                  </div>
                  <div className="row mb-10">
                    <div className="col-12 col-md-6 my-2">
                      <Input
                        type="text"
                        placeholder="Name"
                        name="name"
                        autoComplete="name"
                        className="bg-transparent"
                        size="large"
                        style={{ borderColor: "#9182fe" }}
                        onChange={(e) => {
                          setUserName(e.target.value);
                        }}
                      />
                    </div>
                    <div className="col-12 col-md-6 my-2">
                      <Input
                        type="text"
                        placeholder="Company Name"
                        name="sellername"
                        autoComplete="comp-name"
                        className="bg-transparent"
                        size="large"
                        style={{ borderColor: "#9182fe" }}
                        onChange={(e) => {
                          set_company_name(e.target.value);
                        }}
                      />
                    </div>
                    <div className="col-12 col-md-6 my-2">
                      <Input
                        type="text"
                        placeholder="Email"
                        name="email"
                        autoComplete="email"
                        className="bg-transparent"
                        size="large"
                        style={{ borderColor: "#9182fe" }}
                        onChange={(e) => {
                          set_email(e.target.value);
                        }}
                      />
                    </div>
                    <div className="col-12 col-md-6 my-2">
                      <Input
                        type="text"
                        placeholder="Phone Number"
                        name="phonenumber"
                        autoComplete="phone-number"
                        className="bg-transparent"
                        size="large"
                        style={{ borderColor: "#9182fe" }}
                        onChange={(e) => {
                          set_phone_number(e.target.value);
                        }}
                      />
                    </div>
                    <div className="col-12 col-md-6 my-2">
                      <Input.Password
                        placeholder="Password"
                        name="password"
                        autoComplete="new-password"
                        className="bg-transparent"
                        size="large"
                        style={{ borderColor: "#9182fe" }}
                        onChange={(e) => {
                          set_password(e.target.value);
                        }}
                        onPressEnter={(e) => {
                          // onLogin(e);
                        }}
                      />
                    </div>
                    <div className="col-12 col-md-6 my-2">
                      <Input.Password
                        placeholder="Confirm Password"
                        name="password"
                        autoComplete="confirm-password"
                        className="bg-transparent"
                        size="large"
                        style={{ borderColor: "#9182fe" }}
                        onChange={(e) => {
                          set_confirm_password(e.target.value);
                        }}
                      />
                    </div>
                    <div className="col-12 my-2">
                      <Radio.Group
                        onChange={(e) => {
                          set_connected_by(e.target.value);
                        }}
                        value={connected_by}
                      >
                        <Radio value={"walmart"} className="fw-bold">
                          Walmart
                        </Radio>
                        <Radio value={"amazon"} className="fw-bold">
                          Amazon
                        </Radio>
                        <Radio value={"both"} className="fw-bold">
                          Both
                        </Radio>
                      </Radio.Group>
                    </div>
                    <div className="col-12 my-2 d-flex">
                      <Checkbox />
                      <label className="fw-bold fs-5 ms-3">
                        I Agree to the IVCSR Terms and conditions to use the
                        services provided by the application.
                      </label>
                    </div>
                  </div>

                  <div className="d-grid mb-10">
                    <button
                      onClick={() => {
                        if (
                          username &&
                          company_name &&
                          email &&
                          phone_number &&
                          password &&
                          connected_by &&
                          confirm_password
                        ) {
                          onSignUp();
                        } else {
                          message.destroy();
                          message.info("Please enter all details");
                        }
                      }}
                      disabled={sending}
                      className="btn btn-primary h-50px"
                    >
                      {!sending && <span>Sign Up</span>}
                      <span
                        className="indicator-progress fs-3"
                        style={{ display: sending ? "block" : "none" }}
                      >
                        Please wait...
                        <span className="spinner-border spinner-border-sm align-middle ms-2" />
                      </span>
                    </button>
                  </div>

                  <div className="text-gray-500 text-center fw-semibold fs-6">
                    Already have an account?
                    <Link
                      id={Math.random()}
                      to="/login"
                      className=" fw-bolder ms-2"
                      style={{ color: "#007c00" }}
                    >
                      Sign in here
                    </Link>
                  </div>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
