import React, { Suspense, useState } from "react";
import { Route, Switch } from "react-router-dom";
import Error from "./component/error";
import ManageUser from "./containers/admin/manage-user";
import Dashboard from "./containers/dashboard";
import SystemEventLogContainer from "./containers/application-log/system-event-log";
import CentralLogContainer from "./containers/application-log/central-log-system";
import SalesAnalyticsContainer from "./containers/sales-analytics";
import DataSchedulerContainer from "./containers/application-log/data-scheduler";
import InventoryManagementContainer from "./containers/inventory/inventory-management";
import InventoryDetailsContainer from "./containers/inventory/inventory-details";
import SkuDataContainer from "./containers/master-table/sku-data";
import SupplierContainer from "./containers/master-table/supplier";
import MarketplaceCredentialContainer from "./containers/setting/marketplace-credential";
import ProfileContainer from "./containers/setting/profile";
import AdvDashboardContainer from "./containers/advertisement/adv-dshboard";
import AdvCampaignAnalyticsContainer from "./containers/advertisement/campaign-analytics";
import ListingOptimizerContainer from "./containers/listing-optimizer";
import CriteriaAnalyticsContainer from "./containers/criteria-analytics";
import ListingOptimizerProductDetailsContainer from "./containers/listing-optimizer/lib/product-details";
import BidManagementContainer from "./containers/advertisement/rule-management";
import AdvCampaignManagementContainer from "./containers/advertisement/campaign-management";
import ProductOperationContainer from "./containers/product-operation";
import WalmartApiLogContainer from "./containers/application-log/api-log";
import ItemBulkOperationContainer from "./containers/item-bulk-operation";
import WalmartOrdersContainer from "./containers/order-management/order";
import WamlartReturnsRefundsContainer from "./containers/order-management/return-refund";
import ManageReportsContainer from "./containers/manage-reports";
import ViewReportListContainer from "./containers/manage-reports/lib/view-report-list";
import RepricerProductListingContainer from "./containers/repricer-product-listing";
import ReportScheduleContainer from "./containers/admin/report-schedule";
import ActiveKeywordPageContainer from "./containers/advertisement/adv-dshboard/lib/active-keyword-page";
import RepricingStrategiesContainer from "./containers/repricer_strategies";
import RepricingBuyBoxDetailsContainer from "./containers/repricing-buy-box-details";
import AdvanceReportContainer from "./containers/advance-report";
import OrderDetailsContainer from "./containers/order-management/order/lib/order-details";
import ProcessOrderContainer from "./containers/order-management/order/lib/process-order";
import BidRuleDetailsContainer from "./containers/advertisement/rule-management/lib/walmart-rule";
import WalmartReturnRefundDetailsContainer from "./containers/order-management/return-refund/walmart-lib/details";
import ConfigurationContainer from "./containers/setting/configuration";
import FeedItemsLogContainer from "./containers/master-table/feed-items-log";
import KeywordManagementContainer from "./containers/advertisement/keyword-management";
function PageRouter(props) {
  const { userType } = props;
  return (
    <Suspense>
      {userType == 1 ? (
        <>
          <Switch>
            <Route path={"/manage-user"} component={ManageUser} exact />
            <Route
              path={"/criteria-analytics"}
              component={CriteriaAnalyticsContainer}
              exact
            />
            <Route
              path={"/report-schedule"}
              component={ReportScheduleContainer}
              exact
            />
            <Route path={"/"} component={ManageUser} exact />
            <Route path="*" render={() => <Error status={1} />} />
          </Switch>
        </>
      ) : (
        <>
          <Switch>
            {/* <Route path={"/dashboard"} component={Dashboard} exact /> */}
            <Route path={"/dashboard"} component={Dashboard} exact />
            <Route path="/" component={Dashboard} exact />
            <Route
              path={"/application-log/system-event-log"}
              component={SystemEventLogContainer}
              exact
            />
            <Route
              path={"/application-log/central-log"}
              component={CentralLogContainer}
              exact
            />
            <Route
              path={"/application-log/data-scheduler"}
              component={DataSchedulerContainer}
              exact
            />
            <Route
              path={"/application-log/api-log"}
              component={WalmartApiLogContainer}
              exact
            />
            <Route
              path={"/sales-analytics"}
              component={SalesAnalyticsContainer}
              exact
            />
            <Route
              path={"/inventory/inventory-replenishment"}
              component={InventoryManagementContainer}
              exact
            />
            <Route
              path={"/inventory/inventory-details"}
              component={InventoryDetailsContainer}
              exact
            />
            <Route
              path={"/master-table/product-details"}
              component={SkuDataContainer}
              exact
            />
            <Route
              path={"/master-table/supplier"}
              component={SupplierContainer}
              exact
            />
            <Route
              path={"/master-table/item-activity-feed"}
              component={ProductOperationContainer}
              exact
            />
            <Route
              path={"/master-table/item-bulk-operation"}
              component={ItemBulkOperationContainer}
              exact
            />
            <Route
              path={"/master-table/feed-items"}
              component={FeedItemsLogContainer}
              exact
            />
            <Route
              path={"/listing-optimizer"}
              component={ListingOptimizerContainer}
              exact
            />
            <Route
              path={"/listing-optimizer/details"}
              component={ListingOptimizerProductDetailsContainer}
              exact
            />
            <Route
              path={"/spapi-callback"}
              component={MarketplaceCredentialContainer}
              exact
            />
            <Route
              path={"/callbackads"}
              component={MarketplaceCredentialContainer}
              exact
            />
            <Route
              path={"/setting/marketplace-credential"}
              component={MarketplaceCredentialContainer}
              exact
            />
            <Route
              path={"/setting/my-profile"}
              component={ProfileContainer}
              exact
            />
            <Route
              path={"/setting/configuration"}
              component={ConfigurationContainer}
              exact
            />
            <Route
              path={"/advertisement/advertisement-dashboard"}
              component={AdvDashboardContainer}
              exact
            />
            <Route
              path={"/advertisement/campaigns-analytics"}
              component={AdvCampaignAnalyticsContainer}
              exact
            />
            <Route
              path={"/advertisement/rule-management"}
              component={BidManagementContainer}
              exact
            />
            <Route
              path={"/advertisement/rule-management/rule"}
              component={BidRuleDetailsContainer}
              exact
            />
            <Route
              path={"/advertisement/campaign-management"}
              component={AdvCampaignManagementContainer}
              exact
            />
            <Route
              path={"/advertisement/advance-report"}
              component={AdvanceReportContainer}
              exact
            />
            <Route
              path={"/order-management/orders"}
              component={WalmartOrdersContainer}
              exact
            />
            <Route
              path={"/order-management/orders/details"}
              component={OrderDetailsContainer}
              exact
            />
            <Route
              path={"/order-management/orders/process-order"}
              component={ProcessOrderContainer}
              exact
            />
            <Route
              path={"/order-management/returns-refunds"}
              component={WamlartReturnsRefundsContainer}
              exact
            />
            <Route
              path={"/order-management/returns-refunds/details"}
              component={WalmartReturnRefundDetailsContainer}
              exact
            />
            <Route
              path={"/manage-reports"}
              component={ManageReportsContainer}
              exact
            />
            <Route
              path={"/manage-reports/view-report"}
              component={ViewReportListContainer}
              exact
            />
            <Route
              path={"/repricing/product-listing"}
              component={RepricerProductListingContainer}
              exact
            />
            <Route
              path={"/repricing/repricing-strategies"}
              component={RepricingStrategiesContainer}
              exact
            />
            <Route
              path={"/repricing/buy-box-details"}
              component={RepricingBuyBoxDetailsContainer}
              exact
            />
            <Route
              path={"/advertisement/advertisement-dashboard/active-keywords"}
              component={ActiveKeywordPageContainer}
              exact
            />
            <Route
              path={"/advertisement/keyword-management"}
              component={KeywordManagementContainer}
              exact
            />

            <Route path="*" render={() => <Error status={1} />} />
          </Switch>
        </>
      )}
    </Suspense>
  );
}

export default PageRouter;
