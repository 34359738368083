import styled from "styled-components";
export const OrderWrapper = styled.div`
  .ant-select-selection-item div span {
    display: none !important;
  }
  .ant-checkbox-inner {
    width: 22px !important;
    height: 22px !important;
  }
`;
