import React, { useEffect, useState } from "react";
import Loading from "../../../component/loading";
import NoData from "../../../component/no-data";
import IvcsrTable from "../../../component/ivcsr-table";
import { DefaultPerPage, SalesChannelFlag } from "../../../config";
import { Link } from "react-router-dom";
import {
  Checkbox,
  Image,
  Input,
  Popover,
  Progress,
  Select,
  Tag,
  message,
} from "antd";
import { SearchOutlined, LoadingOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import Pagination from "../../../component/pagination";
import moment from "moment";
import PreviewImage from "../../../component/preview-image";
import ListingOptimizerTable from "./lib/listing-optimizer-table";

export default function (props) {
  const {
    GetListingOptimizerProducts,
    StartProductAnalysis,
    fakeActionListingOptimizer,
    GetSalesChannelList,
    fakeActionSalesAnalytics,
  } = props;
  const conicColors = {
    "0%": "#c62b4d",
    "50%": "#d8ff00",
    "100%": "green",
  };

  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageSize, setPageSize] = useState(DefaultPerPage);
  const [startAnalytics, setStartAnalytics] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [lastAnalysed, setLastAnalysed] = useState({});
  const [allProductStatus, setAllProductStatus] = useState({});
  const [allProductAnalysisScore, setAllProductAnalysisScore] = useState({});
  const [salesChannelList, setsalesChannelList] = useState([]);
  const [selectedSalesChannel, setSelectedSalesChannel] = useState([]);
  const [selectedSalesChannelId, setSelectedSalesChannelId] = useState([]);
  const [salesChannelLoading, setSalesChannelLoading] = useState(true);
  const [selectedCurrencySign, setSelectedCurrencySign] = useState("");
  const [selectedCheckbox, setSelectedCheckbox] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(null);

  const GetSalesChannelListRes = useSelector(
    (state) => state.SalesAnalytics.GetSalesChannelListResponse || {}
  );
  const GetListingOptimizerProductsRes = useSelector(
    (state) => state.ListingOptimizer.GetListingOptimizerProductsResponse || {}
  );
  const StartProductAnalysisRes = useSelector(
    (state) => state.ListingOptimizer.StartProductAnalysisResponse || {}
  );

  useEffect(() => {
    GetSalesChannelList();
    return () => {};
  }, []);

  useEffect(() => {
    if (GetSalesChannelListRes?.status === true) {
      setSalesChannelLoading(false);
      setsalesChannelList(GetSalesChannelListRes?.data?.sales_channel);
      setSelectedSalesChannel("Amazon.com");
      setSelectedSalesChannelId("ATVPDKIKX0DER");
      setSelectedCurrencySign("$");
      GetListingOptimizerProducts({
        page: page,
        perPage: pageSize,
        search: searchTerm,
        marketplace_id: "ATVPDKIKX0DER",
        status: selectedStatus,
      });
      setLoading(true);
      fakeActionSalesAnalytics("GetSalesChannelListResponse");
    } else if (GetSalesChannelListRes?.status === false) {
      fakeActionSalesAnalytics("GetSalesChannelListResponse");
    }
  }, [GetSalesChannelListRes]);

  useEffect(() => {
    if (GetListingOptimizerProductsRes?.status === true) {
      console.log(
        GetListingOptimizerProductsRes,
        "GetListingOptimizerProductsRes"
      );
      setTotalPage(
        GetListingOptimizerProductsRes?.data?.pagination?.totalCount
      );
      setLoading(false);
      setList(GetListingOptimizerProductsRes?.data?.records);
      setLastAnalysed(GetListingOptimizerProductsRes?.data?.productCriteria);
      // setAllProductStatus(GetListingOptimizerProductsRes?.data?.productStatus);
      setAllProductStatus(GetListingOptimizerProductsRes?.data?.catalogItems);
      setAllProductAnalysisScore(
        GetListingOptimizerProductsRes?.data?.productAnalysisScore
      );
      fakeActionListingOptimizer("GetListingOptimizerProductsResponse");
    } else if (GetListingOptimizerProductsRes?.status === false) {
      setLoading(false);
      fakeActionListingOptimizer("GetListingOptimizerProductsResponse");
    }
  }, [GetListingOptimizerProductsRes]);

  useEffect(() => {
    if (StartProductAnalysisRes?.status === true) {
      console.log(StartProductAnalysisRes, "StartProductAnalysisRes");
      setStartAnalytics(false);
      message.destroy();
      message.success(StartProductAnalysisRes?.message);
      GetListingOptimizerProducts({
        page: page,
        perPage: pageSize,
        search: searchTerm,
        marketplace_id: selectedSalesChannelId,
        status: selectedStatus,
      });
      setLoading(true);
      fakeActionListingOptimizer("StartProductAnalysisResponse");
    } else if (StartProductAnalysisRes?.status === false) {
      setStartAnalytics(false);
      message.destroy();
      message.success(StartProductAnalysisRes?.message);
      fakeActionListingOptimizer("StartProductAnalysisResponse");
    }
  }, [StartProductAnalysisRes]);

  const onPageNo = (e) => {
    setLoading(true);
    GetListingOptimizerProducts({
      page: e,
      perPage: pageSize,
      search: searchTerm,
      marketplace_id: selectedSalesChannelId,
      status: selectedStatus,
    });
    setPage(e);
  };

  const onPerPage = (e) => {
    setPage(1);
    setPageSize(e);
    setLoading(true);
    GetListingOptimizerProducts({
      page: 1,
      perPage: e,
      search: searchTerm,
      marketplace_id: selectedSalesChannelId,
      status: selectedStatus,
    });
  };

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const columns = [
    {
      title: "Sr. No.",
      fixed: window.innerWidth > 992 ? "left" : false,
      render: (text) => {
        return <span>{(page - 1) * pageSize + 1 + text.key}</span>;
      },
    },
    {
      title: "Product",
      // width: 500,
      render: (text) => {
        {
          // console.log(JSON.parse(text?.images)?.[0]?.link, "images");
        }
        return (
          <div className="d-flex align-items-center border-start border-2 border-info p-3 rounded">
            <div className="symbol symbol-50px  me-3">
              {/* <PreviewImage imageUrl={JSON.parse(text?.images)?.[0]?.link} /> */}
            </div>
            {/*end::Avatar*/}
            {/*begin::User details*/}
            <div className="d-flex flex-column">
              <Popover
                content={
                  <div
                    style={{
                      height: "max-content",
                      width: "300px",
                    }}
                  >
                    {text?.item_name}
                  </div>
                }
                placement="bottom"
              >
                <div
                  className="text-primary mb-1 fw-bolder text-one fs-5 popoverActionIconSingleLine"
                  style={{ minWidth: "100px" }}
                >
                  {text?.item_name}
                </div>
              </Popover>

              <div className="mt-2">
                {/* <span className="fs-6">
                  <Tag color="purple">
                    <b>ASIN:</b> {text?.asin}
                  </Tag>
                </span> */}
                <div className="fs-6">
                  <Tag color="orange">
                    <b>Brand:</b> {text?.brand}
                  </Tag>
                </div>

                <div className="fs-6 mt-2">
                  <Tag color="green">
                    <b>ASIN:</b> {text?.asin}
                  </Tag>
                </div>

                <div className="mt-2">
                  {/* <Link
                    id={Math.random()}
                    to={{
                      pathname: "/listing-optimizer/details",
                      search: `?id=${text?.id}`,
                    }}
                    target="_blank"
                  >
                    <Tag color="#826bfb">
                      <b style={{ textDecoration: "underline" }}>
                        View Product
                      </b>
                    </Tag>
                  </Link> */}
                </div>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Action",
      align: "center",
      render: (text) => {
        return (
          <>
            <Link
              id={Math.random()}
              to={{
                pathname: "/listing-optimizer/details",
                search: `?id=${text?.id}`,
              }}
              target="_blank"
            >
              <button className="btn btn-light-info btn-sm fs-6">
                View Product
              </button>
              {/* <Tag color="#826bfb">
                <b style={{ textDecoration: "underline" }}>View Product</b>
              </Tag> */}
            </Link>
          </>
        );
      },
    },
    {
      title: "Score",
      align: "center",
      render: (text) => {
        return (
          <>
            <Progress
              percent={text?.status}
              size="small"
              type="circle"
              strokeColor={text < 50 ? "red" : text < 75 ? "orange" : "green"}
            />
          </>
        );
      },
    },
    {
      title: "Status",
      align: "left",
      render: (text) => {
        // const newStatus = Object.keys(allProductStatus || {})?.filter(
        //   (d) => d === text?.asin
        // );
        // console.log(newStatus, "newStatus");
        return "s";
        // <Tag
        //   color={
        //     allProductStatus[newStatus?.[0]] === "Active" ? "green" : "red"
        //   }
        // >
        //   {allProductStatus[newStatus?.[0]]}
        // </Tag>
      },
    },
    {
      title: "Category Name",
      dataIndex: "category_name",
      key: "category_name",
      align: "left",
    },
    // {
    //   title: "Product Type",
    //   dataIndex: "product_types",
    //   key: "product_types",
    //   align: "left",
    // },
    {
      title: "Marketplace",
      dataIndex: "marketplace",
      key: "marketplace",
      align: "left",
    },
    {
      title: "Marketplace Id",
      dataIndex: "marketplace_id",
      key: "marketplace_id",
      align: "left",
    },

    {
      title: "Last Analysed",
      align: "center",
      render: (text) => {
        return "aa";
        // const lastDate = Object.keys(lastAnalysed)?.filter(
        //   (d) => d === text?.asin
        // );
        // console.log(lastAnalysed[lastDate?.[0]], "lastDatelastDatelastDate");
        // return lastAnalysed[lastDate?.[0]]
        //   ? moment(new Date(lastAnalysed[lastDate?.[0]] * 1000)).format(
        //       "MMM DD, YYYY"
        //     )
        //   : "-";
      },
    },
    // {
    //   title: "Action",
    //   align: "center",
    //   render: (text) => {
    //     return (
    //       <Link
    //         id={Math.random()}
    //         to={{
    //           pathname: "/listing-optimizer/details",
    //           search: `?id=${text?.id}`,
    //         }}
    //         target="_blank"
    //       >
    //         <button className="btn btn-light-info fs-7">View Product</button>
    //       </Link>
    //     );
    //   },
    // },
  ];
  return (
    <div classname="d-flex flex-column flex-column-fluid ">
      <div
        id="kt_app_content"
        className="app-content flex-column-fluid fadeInRight"
      >
        {/*begin::Content container*/}
        <div
          id="kt_app_content_container"
          className="app-container container-fluid"
        >
          {/*begin::Row*/}
          <div className="row gy-5 g-xl-5">
            {/*begin::Col*/}
            <div className="col-xxl-12">
              {/*begin::Tables Widget 9*/}
              <div className="card card-xxl-stretch mb-5 mb-xl-8">
                {/*begin::Header*/}
                <div className="card-header border-bottom-dashed">
                  <div className="d-flex flex-stack flex-wrap">
                    <div className="position-relative">
                      <Input
                        type="text"
                        className="text-dark w-250px fs-7 fw-bold ant_common_input"
                        name="all_search"
                        placeholder="Search by ASIN"
                        value={searchTerm}
                        size="large"
                        onChange={(e) => {
                          setSearchTerm(e.target.value);
                        }}
                        onPressEnter={(e) => {
                          setLoading(true);
                          setPage(1);
                          GetListingOptimizerProducts({
                            page: 1,
                            perPage: pageSize,
                            search: e.target.value,
                            marketplace_id: selectedSalesChannelId,
                            status: selectedStatus,
                          });
                        }}
                        prefix={
                          <SearchOutlined
                            style={{ fontSize: "19px", color: "grey" }}
                          />
                        }
                      />
                    </div>
                    {/* <div className="ms-3 ">
                        <Select
                          options={[
                            { label: "Test Brand 1", value: "Test Brand 1" },
                            { label: "Test Brand 2", value: "Test Brand 2" },
                            { label: "Test Brand 3", value: "Test Brand 3" },
                          ]}
                          size="large"
                          style={{ width: "150px" }}
                        />
                      </div> */}
                  </div>
                  <div className="card-toolbar ">
                    <div className="position-relative me-3 d-flex">
                      <div className="d-flex me-5 align-items-center">
                        <div
                          style={{
                            background: "#d1d408",
                            height: "12px",
                            width: "12px",
                            borderRadius: "6px",
                          }}
                          className="me-2"
                        ></div>
                        <label className="fw-bold">PENDING</label>
                      </div>
                      <div className="d-flex me-5 align-items-center">
                        <div
                          style={{
                            background: "#4ebc4e",
                            height: "12px",
                            width: "12px",
                            borderRadius: "6px",
                          }}
                          className="me-2"
                        ></div>
                        <label className="fw-bold">DONE</label>
                      </div>
                      <div className="d-flex me-5 align-items-center">
                        <div
                          style={{
                            background: "#d54141",
                            height: "12px",
                            width: "12px",
                            borderRadius: "6px",
                          }}
                          className="me-2"
                        ></div>
                        <label className="fw-bold">FAILED</label>
                      </div>
                      <div className="d-flex me-5 align-items-center">
                        <div
                          style={{
                            background: "#4318ff",
                            height: "12px",
                            width: "12px",
                            borderRadius: "6px",
                          }}
                          className="me-2"
                        ></div>
                        <label className="fw-bold">NOT FOUND</label>
                      </div>
                    </div>
                    <div className="position-relative me-3">
                      <button
                        className="btn btn-primary"
                        onClick={() => {
                          const analysisObj = {
                            asins: selectedCheckbox,
                            marketplace_id: selectedSalesChannelId,
                          };
                          if (selectedCheckbox?.length > 0) {
                            setStartAnalytics(true);
                            StartProductAnalysis(analysisObj);
                          } else {
                            message.destroy();
                            message.info("Please select product...");
                          }
                        }}
                      >
                        {startAnalytics && <LoadingOutlined />} Start Analysis
                      </button>
                    </div>
                    <div className="position-relative me-3">
                      {" "}
                      <Select
                        className="w-200px"
                        size="large"
                        placeholder="Select Status"
                        value={selectedStatus || null}
                        onChange={(e) => {
                          setSelectedStatus(e);
                          setPage(1);
                          setPageSize(DefaultPerPage);
                          setLoading(true);
                          GetListingOptimizerProducts({
                            page: 1,
                            perPage: pageSize,
                            search: searchTerm,
                            marketplace_id: selectedSalesChannelId,
                            status: e,
                          });
                        }}
                        options={[
                          { label: "Active", value: "Active" },
                          { label: "Inactive", value: "Inactive" },
                        ]}
                      />
                    </div>
                    <div className="position-relative">
                      <Select
                        className="w-250px"
                        size="large"
                        placeholder="Select Sales Channel"
                        loading={salesChannelLoading}
                        value={selectedSalesChannelId || null}
                        onChange={(e, _) => {
                          setSelectedSalesChannelId(e);
                          setSelectedSalesChannel(_?.sales_channel);
                          setPage(1);
                          setPageSize(DefaultPerPage);
                          setLoading(true);
                          GetListingOptimizerProducts({
                            page: 1,
                            perPage: pageSize,
                            search: searchTerm,
                            marketplace_id: e,
                            status: selectedStatus,
                          });
                        }}
                        options={SalesChannelFlag(salesChannelList)}
                      />
                    </div>
                  </div>
                </div>
                {/*end::Header*/}
                {/*begin::Body*/}
                <div className="card-body py-2">
                  <div
                    className="table-responsive tbl-sales"
                    style={{ height: "800px" }}
                  >
                    {/*begin::Table*/}
                    {loading ? (
                      <Loading />
                    ) : list?.length != 0 ? (
                      <>
                        {/* <IvcsrTable
                            columns={columns?.map((d) => ({
                              ...d,
                              // ...PropsFilter(d?.dataIndex),
                              width:
                                d?.title === "Product"
                                  ? 400
                                  : d?.title?.length * 13,
                            }))}
                            dataSource={list}
                            fixed={true}
                            sticky={{
                              offsetHeader: "10px",
                            }}
                            rowKey="key"
                            loading={loading}
                            isCheckBoxRow={true}
                            rowSelection={rowSelection}
                            pagination={false}
                            scroll={{ x: "max-content", y: 770 }}
                          /> */}
                        <ListingOptimizerTable
                          list={list}
                          allProductStatus={allProductStatus}
                          lastAnalysed={lastAnalysed}
                          page={page}
                          pageSize={pageSize}
                          selectedCheckbox={selectedCheckbox}
                          setSelectedCheckbox={setSelectedCheckbox}
                          allProductAnalysisScore={allProductAnalysisScore}
                        />
                      </>
                    ) : (
                      <NoData />
                    )}
                  </div>
                  <Pagination
                    loading={loading || list?.length === 0}
                    pageSize={pageSize}
                    page={page}
                    totalPage={totalPage}
                    onPerPage={onPerPage}
                    onPageNo={onPageNo}
                  />
                </div>
                {/*begin::Body*/}
              </div>
              {/*end::Tables Widget 9*/}
            </div>
            {/*end::Col*/}
          </div>
          {/*end::Row*/}
        </div>
        {/*end::Content container*/}
      </div>
    </div>
  );
}
