export default (state, action) => {
  if (typeof state === "undefined") {
    return [];
  }
  switch (action.type) {
    case "GET_SKU_DATA_LIST_SUCCESS":
    case "GET_SKU_DATA_LIST_ERROR":
      return {
        ...state,
        GetSkuDataListResponse: action.updatePayload,
      };

    case "GET_WALMART_PRODUCT_LIST_SUCCESS":
    case "GET_WALMART_PRODUCT_LIST_ERROR":
      return {
        ...state,
        GetWalmartProductListResponse: action.updatePayload,
      };

    case "GET_WALMART_ITEM_FEED_SUCCESS":
    case "GET_WALMART_ITEM_FEED_ERROR":
      return {
        ...state,
        GetWalmartItemFeedResponse: action.updatePayload,
      };

    case "SAVE_WALMART_ITEM_FEED_LIST_SUCCESS":
    case "SAVE_WALMART_ITEM_FEED_LIST_ERROR":
      return {
        ...state,
        SaveWalmartItemFeedListResponse: action.updatePayload,
      };

    case "GET_GLOBALSEARCH_PRODUCT_LIST_SUCCESS":
    case "GET_GLOBALSEARCH_PRODUCT_LIST_ERROR":
      return {
        ...state,
        GetGlobalSearchProductListResponse: action.updatePayload,
      };

    case "GET_WALMART_PRODUCT_SEARCH_LIST_SUCCESS":
    case "GET_WALMART_PRODUCT_SEARCH_LIST_ERROR":
      return {
        ...state,
        GetWalmartProductSearchListResponse: action.updatePayload,
      };

    case "FAKE_ACTION_SKU_DATA":
      return { ...state, [action?.state]: {} };
    default:
      return state;
  }
};
