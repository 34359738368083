import styled from "styled-components";

export const OrderProcessWrapper = styled.div`
  .ant-checkbox .ant-checkbox-inner {
    height: 24px !important;
    width: 24px !important;
    border: 1px solid blue !important;
  }
  .ant-checkbox .ant-checkbox-inner:after {
    width: 7.714286px !important;
    height: 12.142857px !important;
  }
`;
