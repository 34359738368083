import { Skeleton, Tooltip, message } from "antd";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import Chart from "react-apexcharts";
import { NumberWithCommas } from "../../../../config";
import { useReactToPrint } from "react-to-print";
import { FilePdfOutlined, FileExcelOutlined } from "@ant-design/icons";
import writeXlsxFile from "write-excel-file";
import Highcharts from "highcharts";
import { savePDF } from "@progress/kendo-react-pdf";
import NoData from "../../../../component/no-data";

export default function (props) {
  const {
    salesChartSeries,
    salesChartDate,
    salesOverviewLoading,
    selectedDateRangeType,
    exportGraphArray,
  } = props;

  // const options = {
  //   chart: {
  //     toolbar: {
  //       show: false,
  //       offsetY: -50,
  //       tools: {
  //         download: '<img src="/assets/media/downloads.png" width="20"/>',
  //       },
  //       export: {
  //         // Set the filename
  //         filename: "Sales Overview Graph",
  //         // Include only PNG
  //         formats: ["png"],
  //       },
  //     },
  //     selection: {
  //       enabled: true,
  //     },
  //     dropShadow: {
  //       enabled: !0,
  //       top: 12,
  //       left: 0,
  //       bottom: 0,
  //       right: 0,
  //       blur: 2,
  //       color: "rgba(132, 145, 183, 0.4)",
  //       opacity: 0.45,
  //     },
  //   },
  //   plotOptions: {
  //     bar: {
  //       horizontal: false,
  //       // endingShape: "rounded",
  //       columnWidth: "50%",
  //       borderRadius: 3,
  //     },
  //   },
  //   noData: {
  //     text: "No Data",
  //     align: "center",
  //     verticalAlign: "middle",
  //     style: {
  //       fontSize: "20px",
  //     },
  //   },
  //   tooltip: {
  //     marker: {
  //       show: true,
  //     },
  //     x: {
  //       show: true,
  //     },
  //     y: {
  //       formatter: function (
  //         value,
  //         { series, seriesIndex, dataPointIndex, w }
  //       ) {
  //         const withTitle_ = w?.config?.series?.[seriesIndex];
  //         console.log(withTitle_, "withTitle_");
  //         return withTitle_?.name === "Unit Sold"
  //           ? value || 0
  //           : "$" + NumberWithCommas(value);
  //       },
  //     },
  //   },
  //   // colors: ["#6048d3", "#f0a154", "#08950e"],
  //   colors: [
  //     "#e76f03", //orange
  //     "#6048d3", //blue
  //     "#b29b1b", //yellow
  //     "#12c81a", //green
  //   ],
  //   stroke: {
  //     width: [0, 0, 2, 2],
  //     curve: "smooth",
  //   },
  //   yaxis: [
  //     {
  //       seriesName: "Total Sales",
  //       title: {
  //         text: "Total Sales",
  //       },
  //       labels: {
  //         formatter: function (_) {
  //           return NumberWithCommas(_);
  //         },
  //       },
  //     },
  //     {
  //       seriesName: "Unit Sold",
  //       opposite: true,
  //       title: {
  //         text: "Unit Sold",
  //       },
  //       labels: {
  //         formatter: function (_) {
  //           return NumberWithCommas(_);
  //         },
  //       },
  //     },
  //   ],
  //   // yaxis: {
  //   //   labels: {
  //   //     formatter: function (_) {
  //   //       return NumberWithCommas(_);
  //   //     },
  //   //     offsetX: -12,
  //   //     offsetY: 0,
  //   //   },
  //   // },
  //   dataLabels: {
  //     enabled: false, // Set this to true if you want to display data labels initially
  //   },
  //   markers: {
  //     size: 5,
  //   },
  //   xaxis: {
  //     tickPlacement: "between",
  //     tickAmount: window.innerWidth >= 992 ? 15 : 5,
  //     categories:
  //       salesChartDate?.map((d) =>
  //         selectedDateRangeType === "last52_week" ||
  //         selectedDateRangeType === "year_to_date"
  //           ? d
  //           : moment(new Date(d)).format("DD MMM YYYY")
  //       ) || [],
  //     labels: {
  //       rotate: -50,
  //     },
  //   },
  //   grid: {
  //     strokeDashArray: 3,
  //     xaxis: { lines: { show: !0 } },
  //     yaxis: { lines: { show: !1 } },
  //   },
  //   legend: {
  //     show: true,
  //   },

  //   // fill: {
  //   //   type: "gradient",
  //   //   gradient: {
  //   //     type: "vertical",
  //   //     shadeIntensity: 1,
  //   //     inverseColors: !1,
  //   //     opacityFrom: 0.05,
  //   //     opacityTo: 0.05,
  //   //     stops: [45, 100],
  //   //   },
  //   // },
  // };

  const sign = {
    TACoS: {
      valueSuffix: "%",
      valuePrefix: "",
    },
    "Unit Sold": {
      valueSuffix: "",
      valuePrefix: "",
    },
    "Total Sales": {
      valueSuffix: "",
      valuePrefix: "$",
    },
    "Ad Spend": {
      valueSuffix: "",
      valuePrefix: "$",
    },
  };

  const colors = ["#8992d4", "#373fb5", "#98cabb", "#097980"];
  useEffect(() => {
    if (salesChartDate?.length === 0 || salesChartSeries?.length === 0) return;
    Highcharts.chart("line-chart", {
      chart: {
        renderTo: "sales-by-week-graph",
        height: "430px",
        style: {
          fontFamily: "Oswald,sans-serif",
        },
      },
      type: "column",
      title: "",
      xAxis: {
        title: {
          text: "Date",
        },
        categories: salesChartDate,
      },
      yAxis: [
        {
          title: {
            text: "",
          },
          labels: {
            formatter: function () {
              return this.value?.toLocaleString();
            },
          },
        },
        {
          title: {
            text: `<label class="fw-bolder">Unit Sold</label>`,
          },
          opposite: true,
          labels: {
            formatter: function () {
              return this.value?.toLocaleString();
            },
          },
        },
        {
          title: {
            text: `<label class="fw-bolder">TACoS</label>`,
          },
          opposite: true,
          labels: {
            formatter: function () {
              return this.value?.toLocaleString() + "%";
            },
          },
        },
      ],
      series: salesChartSeries?.map((d, i) => ({
        name: d?.name,
        type: i == 3 || i == 2 ? "line" : "column",
        // tooltip: sign?.[d?.name],
        color: colors?.[i] || "#A52A2A",
        data: d?.data,
        // yAxis: 0,
        yAxis:
          d?.name === "Total Sales"
            ? 0
            : d?.name === "Unit Sold"
            ? 1
            : d?.name === "Ad Spend"
            ? 0
            : 2,
        // yAxis: i == 1 ? 1 : 2,
      })),
      tooltip: {
        useHTML: true,
        shared: true,
        style: {
          fontSize: "14px", // Change font size
          padding: "10px", // Change padding
          // You can add more properties to modify the tooltip style
        },
        pointFormatter: function () {
          return (
            '<span style="color:' +
            this.color +
            '">\u25CF</span> ' +
            '<span style="text-transform: capitalize">' +
            this.series.name +
            "</span>" +
            ": <b>" +
            sign?.[this.series.name]?.valuePrefix +
            this.y?.toLocaleString() +
            sign?.[this.series.name]?.valueSuffix +
            "</b><br/>"
          );
        },
      },
    });
  }, [salesChartSeries]);

  const chartRef = useRef(null);
  const handleExportPDF = () => {
    savePDF(chartRef.current, { paperSize: "auto", fileName: "chart.pdf" });
  };
  // const handleExportPDF = useReactToPrint({
  //   content: () => chartRef.current,
  //   documentTitle: "Sales Overview Chart",
  //   onAfterPrint: () => {
  //     // message.destroy();
  //     // message.success("PDF Downloaded");
  //     // // Callback after printing, you can use it to trigger further actions.
  //     // console.log("Printed successfully!");
  //   },
  //   pageStyle: `
  //     @page {
  //       size: A4 landscape;
  //       margin: 0;
  //     }
  //     body {
  //       margin: 0;
  //       display: flex;
  //       align-items: center;
  //       justify-content: center;
  //       height: 100vh;
  //     }
  //   `,
  // });

  const exportHeaderAmazon = [
    { value: "Date", type: String },
    { value: "Total Sales", type: String },
    { value: "Unit Sold", type: String },
    { value: "Ad Spend", type: String },
    { value: "TACoS", type: String },
  ];

  const downloadXlAmazon = async () => {
    let dataRow = [];
    await exportGraphArray.map((d) => {
      const newList = [
        d?.date,
        d?.order_sales,
        d?.unit_sold,
        d?.ad_spend,
        d?.TACoS,
      ];
      dataRow = [
        ...dataRow,
        newList.map((d) => {
          return { value: d };
        }),
      ];
    });
    const data = [exportHeaderAmazon, ...dataRow];
    await writeXlsxFile(data, {
      fileName: "Sales Overview.xlsx",
    });
    message.destroy();
  };

  return (
    <>
      <div className="card h-md-100 mb-5 mb-xl-10">
        <div className="card-header pb-0  border-bottom-0">
          <div className="card-title align-items-start flex-column">
            <span className="card-label fs-2 fw-bold text-dark">
              Sales Overview
            </span>
          </div>
          <div className="card-toolbar">
            <button
              className="d-flex align-items-center cursor-pointer me-5"
              onClick={() => {
                message.destroy();
                salesChartDate?.length != 0
                  ? handleExportPDF()
                  : message.info("No Data found");
              }}
              style={{ backgroundColor: "transparent", border: "none" }}
            >
              <Tooltip title="Download PDF" placement="bottom">
                <FilePdfOutlined className="fs-1" style={{ color: "red" }} />
              </Tooltip>
            </button>
            <button
              className="d-flex align-items-center cursor-pointer"
              onClick={() => {
                message.destroy();
                salesChartDate?.length != 0
                  ? downloadXlAmazon()
                  : message.info("No Data found");
              }}
              style={{ backgroundColor: "transparent", border: "none" }}
            >
              <Tooltip title="Download Excel" placement="bottom">
                <FileExcelOutlined className="fs-1" style={{ color: "blue" }} />
              </Tooltip>
            </button>
          </div>
        </div>
        <div
          className="card-body py-0"
          style={{ filter: salesOverviewLoading ? "blur(6px)" : "blur(0px)" }}
        >
          {salesChartDate?.length != 0 ? (
            <div id="line-chart" ref={chartRef}></div>
          ) : (
            <NoData height="400px" />
          )}

          {/* <Chart
            options={options}
            series={salesChartSeries}
            height={400}
            ref={chartRef}
          /> */}
        </div>
      </div>
    </>
  );
}
