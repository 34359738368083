import { Popover } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import IvcsrTable from "../../../../component/ivcsr-table";
import Loading from "../../../../component/loading";
import NoData from "../../../../component/no-data";
import Pagination from "../../../../component/pagination";
import { DefaultPerPage } from "../../../../config";

export default function (props) {
  const { GetSystemEventLogList, fakeActionSystemEventLog } = props;
  const GetSystemEventLogListRes = useSelector(
    (state) => state.SystemEventLog.GetSystemEventLogListResponse || {}
  );

  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageSize, setPageSize] = useState(DefaultPerPage);

  useEffect(() => {
    const apiObj = {
      page: 1,
      perPage: pageSize,
    };
    GetSystemEventLogList(apiObj);
    return () => {};
  }, []);

  useEffect(() => {
    if (GetSystemEventLogListRes.status === true) {
      setList(GetSystemEventLogListRes?.data?.records);
      console.log(GetSystemEventLogListRes, "GetSystemEventLogListRes");
      setLoading(false);
      setTotalPage(GetSystemEventLogListRes?.data?.pagination?.totalCount);
      fakeActionSystemEventLog("GetSystemEventLogListResponse");
    } else if (GetSystemEventLogListRes.status === false) {
      setLoading(false);
      fakeActionSystemEventLog("GetSystemEventLogListResponse");
    }
  }, [GetSystemEventLogListRes]);

  const columns = [
    {
      title: "#",
      ellipsis: true,
      align: "left",
      render: (text) => {
        return <span>{(page - 1) * pageSize + 1 + text.key}</span>;
      },
    },

    {
      title: "Log Created at",
      align: "left",
      render: (item) => {
        return (
          <div>
            <span>
              {moment(new Date(item?.created_at * 1000)).format(
                "MMM DD, YYYY hh:mm A"
              )}
            </span>
          </div>
        );
      },
    },
    {
      title: "Event Name",
      align: "left",
      width: 200,
      render: (item) => {
        return (
          <Popover
            content={
              <div
                style={{
                  maxHeight: "200px",
                  maxWidth: "400px",
                  overflow: "scroll",
                }}
              >
                {item?.event_name}
              </div>
            }
            className="popoverActionIcon "
            placement="bottom"
          >
            {item?.event_name || "-"}
          </Popover>
        );
      },
    },
    {
      title: "Event Type",
      align: "left",
      render: (item) => {
        return (
          <div>
            <span>{item?.event_type || "-"}</span>
          </div>
        );
      },
    },
    {
      title: "Error Message",
      width: 300,
      align: "left",
      render: (item) => {
        return (
          <Popover
            content={
              <div
                style={{
                  height: "max-content",
                  maxWidth: "400px",
                }}
              >
                {item?.error_message}
              </div>
            }
            className="popoverActionIcon "
            placement="bottom"
          >
            {item?.error_message || "-"}
          </Popover>
        );
      },
    },
    {
      title: "Error Data",
      align: "left",
      render: (item) => {
        return (
          <Popover
            content={
              <div
                style={{
                  height: "max-content",
                  maxWidth: "400px",
                }}
              >
                {item?.error_data}
              </div>
            }
            className="popoverActionIcon "
            placement="bottom"
          >
            {item?.error_data || "-"}
          </Popover>
        );
      },
    },
  ];

  const onPageNo = (e) => {
    setLoading(true);
    const apiObj = {
      page: e,
      perPage: pageSize,
    };
    GetSystemEventLogList(apiObj);
    setPage(e);
  };

  const onPerPage = (e) => {
    setPage(1);
    setPageSize(e);
    setLoading(true);
    const apiObj = {
      page: 1,
      perPage: e,
    };
    GetSystemEventLogList(apiObj);
  };

  return (
    <div className="fadeInRight">
      <>
        <div className="d-flex flex-column flex-column-fluid">
          <div id="kt_app_content" className="app-content flex-column-fluid">
            <div
              id="kt_app_content_container"
              className="app-container container-fluid"
            >
              {/*begin::Row*/}
              <div className="row gy-5 g-xl-5">
                {/*begin::Col*/}
                <div className="col-xxl-12">
                  {/*begin::Tables Widget 9*/}
                  <div className="card card-xxl-stretch mb-5 mb-xl-8">
                    {/*begin::Header*/}
                    <div className="card-header border-bottom-dashed">
                      {/*end::Title*/}
                      {/*begin::Title*/}
                      <h3 className="card-title ">
                        <span className="card-label fw-bold text-gray-900">
                          System Event Log
                        </span>
                      </h3>
                      {/*end::Title*/}
                      <div className="card-toolbar">
                        <div className="position-relative"></div>
                      </div>
                    </div>
                    {/*end::Header*/}
                    {/*begin::Body*/}
                    <div className="card-body pb-5 pt-0">
                      {/*begin::Table container*/}
                      <div className="table-responsive mt-5">
                        {loading ? (
                          <Loading />
                        ) : list?.length != 0 ? (
                          <>
                            <IvcsrTable
                              columns={columns?.map((d) => ({
                                ...d,
                                width:
                                  d?.title === "Error Data"
                                    ? 500
                                    : d?.title === "Log Created at"
                                    ? 200
                                    : d?.title === "Event Name"
                                    ? 200
                                    : d?.title === "Event Message"
                                    ? 400
                                    : d?.title?.length * 13,
                              }))}
                              fixed={"top"}
                              sticky={{
                                offsetHeader: "10px",
                              }}
                              scroll={{
                                x: "max-content",
                                y: "calc(100vh - 200px)",
                              }}
                              dataSource={list}
                              rowKey="key"
                              loading={loading}
                              pagination={false}
                            />
                          </>
                        ) : (
                          <NoData type="new" />
                        )}
                      </div>
                      <Pagination
                        loading={loading || list?.length === 0}
                        pageSize={pageSize}
                        page={page}
                        totalPage={totalPage}
                        onPerPage={onPerPage}
                        onPageNo={onPageNo}
                      />
                    </div>
                    {/*begin::Body*/}
                  </div>
                  {/*end::Tables Widget 9*/}
                </div>
                {/*end::Col*/}
              </div>
              {/*end::Row*/}
            </div>
          </div>
        </div>
      </>
    </div>
  );
}
