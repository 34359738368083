import {
  Input,
  Popconfirm,
  Popover,
  Radio,
  Select,
  Table,
  Tag,
  Tooltip,
  message,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import IvcsrTable from "../../../../component/ivcsr-table";
import Loading from "../../../../component/loading";
import NoData from "../../../../component/no-data";
import Pagination from "../../../../component/pagination";
import {
  DateFormat,
  DefaultPerPage,
  NumberWithCommas,
  getUserId,
  timeSince,
} from "../../../../config";
import {
  SearchOutlined,
  RightOutlined,
  DownOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import RangePickerIVCSR from "../../../../component/range-picker";
import OperationModal from "./lib/operation-modal";
import { Link } from "react-router-dom";
import OrderStatusModal from "./order-status-modal";
export default function (props) {
  const {
    GetWalmartOrdersActionWithAllProduct,
    GetDirectWalmartOrdersAction,
    fakeActionWalmartOrders,
    GetOrderDetails,
    WalmartAcknowledgeOrderAction,
  } = props;

  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageSize, setPageSize] = useState(DefaultPerPage);
  const [filterByDate, setFilterByWidth] = useState("order_by");
  const [processOrderStatus, setProcessOrderStatus] = useState(false);
  const [processOrderOperation, setProcessOrderOperation] = useState("");
  const [orderData, setOrderData] = useState([]);
  const [selectedPoId, setSelectedPoId] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [searchCustomerName, setSearchCustomerName] = useState("");
  const [isOpenOrderStatusModal, setIsOpenOrderStatusModal] = useState(false);
  const [allStatusData, setAllStatusData] = useState([]);

  const [filters, setFilters] = useState({
    start_date: dayjs().add(-30, "d"),
    end_date: dayjs(),
  });

  const [allFilters, setAllFilters] = useState({
    order_line_status: null,
    ship_node_type: null,
    date_type: "order",
  });

  const GetWalmartOrdersWithAllProductRes = useSelector(
    (state) => state.WalmartOrders.GetWalmartOrdersWithAllProductResponse || {}
  );
  const GetDirectWalmartOrdersRes = useSelector(
    (state) => state.WalmartOrders.GetDirectWalmartOrdersResponse || {}
  );
  const GetWalmartOrdersDetailsRes = useSelector(
    (state) => state.WalmartOrders.GetWalmartOrdersDetailsResponse || {}
  );
  const WalmartAcknowledgeOrderRes = useSelector(
    (state) => state.WalmartOrders.WalmartAcknowledgeOrderResponse || {}
  );

  useEffect(() => {
    const obj = {
      page: 1,
      perPage: pageSize,
      start_date: moment(filters?.start_date?.$d).format("YYYY-MM-DD"),
      end_date: moment(filters?.end_date?.$d).format("YYYY-MM-DD"),
      search: searchTerm,
      customer_name: searchCustomerName,
      ...allFilters,
    };
    GetWalmartOrdersActionWithAllProduct(obj);
    return () => {};
  }, []);

  useEffect(() => {
    if (GetWalmartOrdersWithAllProductRes.status === true) {
      const finalList = GetWalmartOrdersWithAllProductRes?.data?.records?.map(
        (d) => {
          let totalQty = 0;
          d?.map((qty) => {
            totalQty = totalQty + parseInt(qty?.order_quantity);
          });
          // ------------------------------------------------------------------------------ Calculate Total order Quantity
          let totalOrderAmt = 0;
          d?.map((amt) => {
            totalOrderAmt = totalOrderAmt + parseFloat(amt?.order_total);
          });
          // ------------------------------------------------------------------------------ Calculate Total order Amount
          const localStatus = d?.map((d_status) => {
            return JSON.parse(d_status?.order_line_status_details)?.map(
              (os) => {
                return os?.status;
              }
            );
          });
          const uniqueArr = localStatus?.flat();
          let commonStatus = [...new Set(uniqueArr)];
          // ------------------------------------------------------------------------------ Calculate Order Common Status
          console.log(
            totalQty,
            commonStatus,
            NumberWithCommas(totalOrderAmt),
            "totalQty"
          );
          return {
            list: d,
            purchase_order_id: d?.[0]?.purchase_order_id,
            customer_order_id: d?.[0]?.customer_order_id,
            customer: d?.[0]?.customer,
            ship_node_type: d?.[0]?.ship_node_type,
            order_date: d?.[0]?.order_date,
            total_quantity: totalQty,
            order_total: totalOrderAmt,
            order_status: commonStatus,
          };
        }
      );
      console.log(finalList, "-finalList");
      setList(finalList);
      setLoading(false);
      setTotalPage(
        GetWalmartOrdersWithAllProductRes?.data?.pagination?.totalCount
      );
      fakeActionWalmartOrders("GetWalmartOrdersWithAllProductResponse");
    } else if (GetWalmartOrdersWithAllProductRes.status === false) {
      setLoading(false);
      fakeActionWalmartOrders("GetWalmartOrdersWithAllProductResponse");
    }
  }, [GetWalmartOrdersWithAllProductRes]);

  // useEffect(() => {
  //   if (GetWalmartOrdersWithAllProductRes.status === true) {
  //     const newData = GetWalmartOrdersWithAllProductRes?.data?.records?.map(
  //       (d) => {
  //         const inData = Object.entries(d)?.map((a) => {
  //           const otherInnerData = a?.[1]?.map((b) => {
  //             const myLocalStatus = JSON.parse(
  //               b?.order_line_status_details
  //             )?.map((os) => {
  //               return {
  //                 status: os?.status,
  //                 quantity: os?.statusQuantity?.amount,
  //               };
  //             });
  //             const commonStatus = JSON.parse(
  //               b?.order_line_status_details
  //             )?.map((os) => {
  //               return os?.status;
  //             });
  //             const newData = {
  //               ...b,
  //               all_order_line_status: myLocalStatus,
  //               common_status: commonStatus,
  //             };
  //             return newData;
  //           });
  //           // console.log(otherInnerData, "-otherInnerData");
  //           return otherInnerData;
  //         });

  //         return { list: inData };
  //       }
  //     );
  //     // console.log(newData, "newData");
  //     const flattenedList = newData.map((item) => {
  //       const myd = item.list.flat();
  //       return {
  //         list: myd,
  //       };
  //     });
  //     // console.log(flattenedList, "flattenedList");
  //     const finalData = flattenedList?.map((d) => {
  //       // console.log(d, "-----flattenedList - d");

  //       let totalQty = 0;
  //       d?.list?.map((q) => {
  //         totalQty = totalQty + parseInt(q?.order_quantity);
  //       });

  //       let totalOrderAmt = 0;
  //       d?.list?.map((q) => {
  //         totalOrderAmt = totalOrderAmt + parseFloat(q?.order_total);
  //       });
  //       const order_status = d?.list?.map((s) => {
  //         return s?.common_status;
  //       });
  //       const flatArr = order_status?.flat();

  //       const data = d?.list?.[0];
  //       return {
  //         ...d,
  //         purchase_order_id: data?.purchase_order_id,
  //         customer_order_id: data?.customer_order_id,
  //         customer: data?.customer,
  //         ship_node_type: data?.ship_node_type,
  //         order_date: data?.order_date,
  //         total_quantity: totalQty,
  //         order_total: totalOrderAmt,
  //         order_status: flatArr,
  //       };
  //     });
  //     console.log(finalData, "finalData");
  //     setList(finalData);
  //     setLoading(false);
  //     setTotalPage(
  //       GetWalmartOrdersWithAllProductRes?.data?.pagination?.totalCount
  //     );
  //     fakeActionWalmartOrders("GetWalmartOrdersWithAllProductResponse");
  //   } else if (GetWalmartOrdersWithAllProductRes.status === false) {
  //     setLoading(false);
  //     fakeActionWalmartOrders("GetWalmartOrdersWithAllProductResponse");
  //   }
  // }, [GetWalmartOrdersWithAllProductRes]);

  useEffect(() => {
    if (GetDirectWalmartOrdersRes.status === true) {
      setLoading(true);
      setPage(1);
      const obj = {
        page: 1,
        perPage: pageSize,
        start_date: moment(filters?.start_date?.$d).format("YYYY-MM-DD"),
        end_date: moment(filters?.end_date?.$d).format("YYYY-MM-DD"),
        search: searchTerm,
        customer_name: searchCustomerName,
        ...allFilters,
      };
      GetWalmartOrdersActionWithAllProduct(obj);
      fakeActionWalmartOrders("GetDirectWalmartOrdersResponse");
    } else if (GetDirectWalmartOrdersRes.status === false) {
      setLoading(true);
      setPage(1);
      const obj = {
        page: 1,
        perPage: pageSize,
        start_date: moment(filters?.start_date?.$d).format("YYYY-MM-DD"),
        end_date: moment(filters?.end_date?.$d).format("YYYY-MM-DD"),
        search: searchTerm,
        customer_name: searchCustomerName,
        ...allFilters,
      };
      GetWalmartOrdersActionWithAllProduct(obj);
      fakeActionWalmartOrders("GetDirectWalmartOrdersResponse");
    }
  }, [GetDirectWalmartOrdersRes]);

  useEffect(() => {
    if (GetWalmartOrdersDetailsRes.status === true) {
      setOrderData(GetWalmartOrdersDetailsRes?.data);
      setProcessOrderStatus(true);
      message.destroy();
      fakeActionWalmartOrders("GetWalmartOrdersDetailsResponse");
    } else if (GetWalmartOrdersDetailsRes.status === false) {
      message.destroy();
      message.error(GetWalmartOrdersDetailsRes?.message);
      fakeActionWalmartOrders("GetWalmartOrdersDetailsResponse");
    }
  }, [GetWalmartOrdersDetailsRes]);

  useEffect(() => {
    if (WalmartAcknowledgeOrderRes.status === true) {
      message.destroy();
      message.success("Order Acknowledged");
      setLoading(true);
      const obj = {
        page: 1,
        perPage: pageSize,
        start_date: moment(filters?.start_date?.$d).format("YYYY-MM-DD"),
        end_date: moment(filters?.end_date?.$d).format("YYYY-MM-DD"),
        search: searchTerm,
        customer_name: searchCustomerName,
        ...allFilters,
      };
      GetWalmartOrdersActionWithAllProduct(obj);
      fakeActionWalmartOrders("WalmartAcknowledgeOrderResponse");
    } else if (WalmartAcknowledgeOrderRes.status === false) {
      message.destroy();
      message.error(WalmartAcknowledgeOrderRes?.message || "Request Failed");
      fakeActionWalmartOrders("WalmartAcknowledgeOrderResponse");
    }
  }, [WalmartAcknowledgeOrderRes]);

  const columns = [
    {
      title: "Sr. No.",
      ellipsis: true,
      align: "left",
      render: (text) => {
        return <span>{(page - 1) * pageSize + 1 + text.key}</span>;
      },
    },
    {
      title: "Action",
      width: 300,
      align: "center",
      render: (text) => {
        const newStr = text?.order_status;
        const isAcknowledge = newStr.includes("Acknowledged");
        const isCreated = newStr.includes("Created");
        return (
          <div className="">
            <div className="d-flex align-items-center justify-content-between">
              <div className="p-1">
                <Tooltip title="View Order" placement="bottom">
                  <Link
                    id={Math.random()}
                    to={{
                      pathname: "orders/details",
                      search: `?poid=${text?.purchase_order_id}`,
                    }}
                    target="_blank"
                  >
                    <button className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                      <img
                        src="/assets/media/visible.png"
                        style={{ width: "23px" }}
                      />
                    </button>
                  </Link>
                </Tooltip>
              </div>
              {isAcknowledge && (
                <>
                  <div className="p-1">
                    <Tooltip title="Process Order" placement="bottom">
                      <Link
                        id={Math.random()}
                        to={{
                          pathname: "orders/process-order",
                          search: `?poid=${text?.purchase_order_id}`,
                        }}
                        target="_blank"
                      >
                        <button className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                          <img
                            src="/assets/media/process-order.png"
                            style={{ width: "25px" }}
                          />
                        </button>
                      </Link>
                    </Tooltip>
                  </div>
                  {/* <div
                    className="p-1"
                    onClick={() => {
                      // setProcessOrderStatus(true);
                      setProcessOrderOperation("ship");
                      message.destroy();
                      message.loading("Loading...", 0);
                      GetOrderDetails({
                        id: text?.purchase_order_id,
                        is_all: 1,
                      });
                      setSelectedPoId(text?.purchase_order_id);
                    }}
                  >
                    <Tooltip title="Ship Item" placement="bottom">
                      <button className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                        <img
                          src="/assets/media/transport.png"
                          style={{ width: "25px" }}
                        />
                      </button>
                    </Tooltip>
                  </div> */}
                  {/* <div
                    className="p-1"
                    onClick={() => {
                      setProcessOrderOperation("cancel");
                      message.destroy();
                      message.loading("Loading...", 0);
                      GetOrderDetails({
                        id: text?.purchase_order_id,
                        is_all: 1,
                      });
                      setSelectedPoId(text?.purchase_order_id);
                    }}
                  >
                    <Tooltip title="Cancel Item" placement="bottom">
                      <button className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                        <i className="ki-outline ki-trash fs-2 text-danger" />
                      </button>
                    </Tooltip>
                  </div> */}
                </>
              )}

              {isCreated && (
                <div className="p-1">
                  <Tooltip title="Acknowledge Order" placement="bottom">
                    <button
                      className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
                      onClick={() => {
                        message.destroy();
                        message.loading("Loading...", 0);
                        WalmartAcknowledgeOrderAction(text?.purchase_order_id);
                      }}
                    >
                      <img
                        src="/assets/media/shopping-cart.png"
                        style={{ width: "25px" }}
                      />
                    </button>
                  </Tooltip>
                </div>
              )}
              {/* {text?.order_status === "Acknowledged" && (
                <div
                  className="p-1"
                  onClick={() => {
                    // setProcessOrderStatus(true);
                    setProcessOrderOperation("ship");
                    message.destroy();
                    message.loading("Loading...", 0);
                    GetOrderDetails({ id: text?.purchase_order_id, is_all: 1 });
                    setSelectedPoId(text?.purchase_order_id);
                  }}
                >
                  <Tooltip title="Ship Item" placement="bottom">
                    <button className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                      <img
                        src="/assets/media/transport.png"
                        style={{ width: "25px" }}
                      />
                    </button>
                  </Tooltip>
                </div>
              )}
              {text?.order_status === "Acknowledged" ||
              text?.order_status === "Created" ? (
                <div
                  className="p-1"
                  onClick={() => {
                    setProcessOrderOperation("cancel");
                    message.destroy();
                    message.loading("Loading...", 0);
                    GetOrderDetails({ id: text?.purchase_order_id, is_all: 1 });
                    setSelectedPoId(text?.purchase_order_id);
                  }}
                >
                  <Tooltip title="Cancel Item" placement="bottom">
                    <button className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                      <i className="ki-outline ki-trash fs-2 text-danger" />
                    </button>
                  </Tooltip>
                </div>
              ) : (
                ""
              )} */}
            </div>
          </div>
        );
      },
    },
    {
      title: "Status",
      align: "center",
      render: (text) => {
        const uniqueValues = [...new Set(text?.order_status)];
        return (
          <div className="">
            {uniqueValues?.map((d) => {
              return (
                <Tag
                  color={
                    d === "Cancelled"
                      ? "error"
                      : d === "Shipped"
                      ? "geekblue"
                      : d === "Delivered"
                      ? "green"
                      : d === "Acknowledged"
                      ? "orange"
                      : "purple"
                  }
                  className="fw-bold my-1"
                >
                  {d}
                </Tag>
              );
            })}
          </div>
        );
      },
    },
    {
      title: "Purchase Order Id",
      dataIndex: "purchase_order_id",
      key: "purchase_order_id",
      render: (text) => {
        return <span className="">{text || "-"}</span>;
      },
    },
    {
      title: "Customer Order Id",
      dataIndex: "customer_order_id",
      key: "customer_order_id",
      render: (text) => {
        return <span className="">{text || "-"}</span>;
      },
    },
    {
      title: "Order Date",
      dataIndex: "order_date",
      key: "order_date",
      render: (text) => <span>{moment(text).format("MMM DD, YYYY")}</span>,
    },
    {
      title: "Total Amount",
      dataIndex: "order_total",
      key: "order_total",
      render: (text) => (
        <span className="fw-bolder text-primary">
          ${NumberWithCommas(text || 0)}
        </span>
      ),
    },
    {
      title: "Quantity",
      dataIndex: "total_quantity",
      key: "total_quantity",
      render: (text) => <span>{text || "-"}</span>,
    },

    {
      title: "Customer",
      dataIndex: "customer",
      key: "customer",
      render: (text) => {
        return (
          <Popover
            content={
              <div
                style={{
                  height: "max-content",
                  width: "max-content",
                }}
              >
                {text}
              </div>
            }
            placement="bottomRight"
          >
            <div
              className="mb-1 text-one fs-5 popoverActionIconSingleLine"
              style={{ minWidth: "100px" }}
            >
              {text}
            </div>
          </Popover>
        );
        // return text?.map((d) => {
        //   console.log(d, "ddddd");
        //   return (
        //     <div>
        //       <Popover
        //         content={
        //           <div
        //             style={{
        //               height: "max-content",
        //               width: "max-content",
        //             }}
        //           >
        //             {d}
        //           </div>
        //         }
        //         placement="bottom"
        //       >
        //         <div
        //           className="text-primary mb-1 fw-bolder text-one fs-5 popoverActionIconSingleLine"
        //           style={{ minWidth: "100px" }}
        //         >
        //           {d}
        //         </div>
        //       </Popover>
        //     </div>
        //   );
        // });
      },
    },
    {
      title: "Fulfilled by",
      dataIndex: "ship_node_type",
      key: "ship_node_type",
      render: (text) => <span>{text}</span>,
    },
    // {
    //   title: "Ship method",
    //   dataIndex: "ship_method",
    //   key: "ship_method",
    //   render: (text) => <span>{text}</span>,
    // },

    // {
    //   title: "Created At",
    //   dataIndex: "created_at",
    //   key: "created_at",
    //   render: (text) => (
    //     <span>
    //       {moment(new Date(text * 1000)).format(  "YYYY-MM-DD"hh:mm A")}
    //     </span>
    //   ),
    // },
  ];

  const columnsSub = [
    {
      title: "#",
      width: 58,
      render: (text) => {
        return <span>{text?.key || 1}</span>;
      },
    },
    {
      title: "Product",
      width: 400,
      render: (text) => {
        return (
          <div className="d-flex align-items-center p-3 rounded">
            <div className="symbol symbol-50px  me-3">
              <img src={`${text?.image_url}`} alt="" />
            </div>
            {/*end::Avatar*/}
            {/*begin::User details*/}
            <div className="d-flex flex-column">
              <Popover
                content={
                  <div
                    style={{
                      height: "max-content",
                      maxWidth: "400px",
                    }}
                  >
                    {text?.product_name}
                  </div>
                }
                placement="bottom"
              >
                <div
                  className="text-primary mb-1 fw-bolder text-one fs-5 popoverActionIconSingleLine"
                  style={{ minWidth: "100px" }}
                >
                  {text?.product_name}
                </div>
              </Popover>

              <div>
                <span className="fs-6">
                  <Tag color="orange">
                    <b>SKU:</b> {text?.sku}
                  </Tag>
                </span>
                <br />
                <Tag className="fw-bold mt-1 fs-6" color="green">
                  Orderline {text?.order_line_number || ""}
                </Tag>
                {/*<span className="fs-6 ">
                  <Tag color="purple" className="mt-1">
                    <b>Order:</b> {text?.customer_order_id}
                  </Tag>
                </span> */}
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Status",
      width: 280,
      render: (text) => {
        const status = JSON.parse(text?.order_line_status_details);
        console.log(status, " -status");
        return status?.map((d) => {
          return (
            <Tooltip title="View orderline status details" placement="bottom">
              <Tag
                color={
                  d?.status === "Cancelled"
                    ? "error"
                    : d?.status === "Shipped"
                    ? "geekblue"
                    : d?.status === "Delivered"
                    ? "green"
                    : d?.status === "Acknowledged"
                    ? "orange"
                    : "purple"
                }
                className="fw-bold my-1 cursor-pointer"
                style={{ textDecoration: "underline" }}
                onClick={() => {
                  setAllStatusData(
                    JSON.parse(text?.order_line_status_details) || {}
                  );
                  setIsOpenOrderStatusModal(true);
                }}
              >
                {d?.status} : {d?.statusQuantity?.amount}
              </Tag>
            </Tooltip>
          );
        });
      },
      // render: (text) => (
      //   <Tag
      //     color={
      //       ext?.order_status === "Cancelled"
      //         ? "error"
      //         : text?.order_status === "Shipped"
      //         ? "geekblue"
      //         : text?.order_status === "Delivered"
      //         ? "green"
      //         : text?.order_status === "Acknowledged"
      //         ? "orange"
      //         : "purple"
      //     }
      //     className="fw-bold my-1"
      //   >
      //     {text?.order_status}
      //   </Tag>
      // ),
    },
    // {
    //   title: "Ship Date",
    //   dataIndex: "ship_by",
    //   key: "ship_by",
    //   render: (text) => {
    //     return (
    //       <span>
    //         {text === "1970-01-01" ? "-" : moment(text).format("MMM DD, YYYY")}
    //       </span>
    //     );
    //   },
    // },
    {
      title: "Amount",
      width: 280,
      render: (text) => (
        <span className="fw-bold text-green">
          ${NumberWithCommas(text?.order_total || "-")}
        </span>
      ),
    },
    {
      title: "Quantity",
      width: 280,
      render: (text) => (
        <span className=" fw-bolder text-dark">
          {text?.order_quantity || "-"}
        </span>
      ),
    },
    {
      title: "Ship Method",
      width: 280,
      render: (text) => <span>{text?.ship_method || "-"}</span>,
    },
  ];

  const onPageNo = (e) => {
    setLoading(true);
    const apiObj = {
      page: e,
      perPage: pageSize,
      start_date: moment(filters?.start_date?.$d).format("YYYY-MM-DD"),
      end_date: moment(filters?.end_date?.$d).format("YYYY-MM-DD"),
      search: searchTerm,
      customer_name: searchCustomerName,
      ...allFilters,
    };
    GetWalmartOrdersActionWithAllProduct(apiObj);
    setPage(e);
  };

  const onPerPage = (e) => {
    setPage(1);
    setPageSize(e);
    setLoading(true);
    const apiObj = {
      page: 1,
      perPage: e,
      start_date: moment(filters?.start_date?.$d).format("YYYY-MM-DD"),
      end_date: moment(filters?.end_date?.$d).format("YYYY-MM-DD"),
      search: searchTerm,
      customer_name: searchCustomerName,
      ...allFilters,
    };
    GetWalmartOrdersActionWithAllProduct(apiObj);
  };

  return (
    <div className="fadeInRight">
      <>
        <div className="d-flex flex-column flex-column-fluid">
          <div id="kt_app_content" className="app-content flex-column-fluid">
            <div
              id="kt_app_content_container"
              className="app-container container-fluid"
            >
              <div className="row gy-5 g-xl-5">
                <div className="col-xxl-12">
                  <div className="card card-xxl-stretch mb-5 mb-xl-8">
                    <div className="card-header border-bottom-dashed">
                      <h3 className="card-title ">
                        {/* <span className="card-label fw-bold text-gray-900">
                          Orders
                        </span> */}
                      </h3>

                      <div className="card-toolbar gap-2">
                        <div className="position-relative">
                          <Input
                            type="text"
                            className="text-dark w-250px fs-7 fw-bold ant_common_input "
                            name="all_search"
                            placeholder="by PO Id, Customer Order Id"
                            value={searchTerm}
                            size="large"
                            onChange={(e) => {
                              setSearchTerm(e.target.value);
                            }}
                            onPressEnter={(e) => {
                              setPage(1);
                              const obj = {
                                page: 1,
                                perPage: pageSize,
                                start_date: moment(
                                  filters?.start_date?.$d
                                ).format("YYYY-MM-DD"),
                                end_date: moment(filters?.end_date?.$d).format(
                                  "YYYY-MM-DD"
                                ),
                                search: e.target.value,
                                customer_name: searchCustomerName,
                                ...allFilters,
                              };
                              setLoading(true);
                              GetWalmartOrdersActionWithAllProduct(obj);
                            }}
                            prefix={
                              <SearchOutlined
                                style={{ fontSize: "19px", color: "grey" }}
                              />
                            }
                          />
                        </div>
                        <div className="position-relative mr-4">
                          <Select
                            options={[
                              {
                                label: <label>Created</label>,
                                value: "Created",
                              },
                              {
                                label: <label>Acknowledged</label>,
                                value: "Acknowledged",
                              },
                              {
                                label: <label>Shipped </label>,
                                value: "Shipped",
                              },
                              {
                                label: <label>Unshipped </label>,
                                value: "Unshipped",
                              },
                              {
                                label: <label>Cancelled </label>,
                                value: "Cancelled",
                              },
                              {
                                label: <label>Delivered </label>,
                                value: "Delivered",
                              },
                            ]}
                            allowClear
                            onChange={(e) => {
                              const allFilt = { ...allFilters };
                              allFilt.order_line_status = e;
                              setAllFilters(allFilt);
                              setLoading(true);
                              const obj = {
                                page: 1,
                                perPage: pageSize,
                                start_date: moment(
                                  filters?.start_date?.$d
                                ).format("YYYY-MM-DD"),
                                end_date: moment(filters?.end_date?.$d).format(
                                  "YYYY-MM-DD"
                                ),
                                search: searchTerm,
                                customer_name: searchCustomerName,
                                ...allFilt,
                              };
                              GetWalmartOrdersActionWithAllProduct(obj);
                            }}
                            value={allFilters?.order_line_status}
                            size="large"
                            className="w-200px"
                            placeholder="Select Select"
                            defaultValue={"all"}
                          />
                        </div>
                        <div className="position-relative mr-4">
                          <Select
                            options={[
                              {
                                label: <label>Seller Fulfilled</label>,
                                value: "SellerFulfilled",
                              },
                              {
                                label: <label>WFS</label>,
                                value: "wfs",
                              },
                              {
                                label: <label>Partner Fulfilled</label>,
                                value: "PartnerFulfilled",
                              },
                            ]}
                            allowClear
                            onChange={(e) => {
                              const allFilt = { ...allFilters };
                              allFilt.ship_node_type = e;
                              setAllFilters(allFilt);
                              setLoading(true);
                              const obj = {
                                page: 1,
                                perPage: pageSize,
                                start_date: moment(
                                  filters?.start_date?.$d
                                ).format("YYYY-MM-DD"),
                                end_date: moment(filters?.end_date?.$d).format(
                                  "YYYY-MM-DD"
                                ),
                                search: searchTerm,
                                customer_name: searchCustomerName,
                                ...allFilt,
                              };
                              GetWalmartOrdersActionWithAllProduct(obj);
                            }}
                            value={allFilters?.ship_node_type}
                            size="large"
                            className="w-200px"
                            placeholder="Fulfilled by"
                          />
                        </div>
                        <div className="position-relative">
                          <Input
                            type="text"
                            className="text-dark w-250px fs-7 fw-bold ant_common_input"
                            name="all_search"
                            placeholder="Search by customer name"
                            value={searchCustomerName}
                            size="large"
                            onChange={(e) => {
                              setSearchCustomerName(e.target.value);
                            }}
                            onPressEnter={(e) => {
                              setPage(1);
                              const obj = {
                                page: 1,
                                perPage: pageSize,
                                start_date: moment(
                                  filters?.start_date?.$d
                                ).format("YYYY-MM-DD"),
                                end_date: moment(filters?.end_date?.$d).format(
                                  "YYYY-MM-DD"
                                ),
                                search: searchTerm,
                                customer_name: e.target.value,
                                ...allFilters,
                              };
                              setLoading(true);
                              GetWalmartOrdersActionWithAllProduct(obj);
                            }}
                            prefix={
                              <SearchOutlined
                                style={{ fontSize: "19px", color: "grey" }}
                              />
                            }
                          />
                        </div>{" "}
                        <div className="position-relative mr-4 d-flex">
                          <div className="d-flex align-items-center">
                            <Radio.Group
                              onChange={(e) => {
                                const allFilt = { ...allFilters };
                                allFilt.date_type = e.target.value;
                                setAllFilters(allFilt);
                                setLoading(true);
                                const obj = {
                                  page: 1,
                                  perPage: pageSize,
                                  start_date: moment(
                                    filters?.start_date?.$d
                                  ).format("YYYY-MM-DD"),
                                  end_date: moment(
                                    filters?.end_date?.$d
                                  ).format("YYYY-MM-DD"),
                                  search: searchTerm,
                                  customer_name: searchCustomerName,
                                  ...allFilt,
                                };
                                GetWalmartOrdersActionWithAllProduct(obj);
                              }}
                              value={allFilters?.date_type}
                            >
                              <Radio value={"order"}>
                                <label className="fw-bold">Order By</label>
                              </Radio>
                              <Radio value={"ship"}>
                                <label className="fw-bold">Ship By</label>
                              </Radio>
                            </Radio.Group>
                          </div>
                        </div>
                        <div className="position-relative mr-4">
                          <RangePickerIVCSR
                            disabled={!filterByDate}
                            className="ant_common_input"
                            id="ivcsr_date_picker"
                            size="large"
                            allowClear={false}
                            style={{ width: "250px" }}
                            format={DateFormat}
                            value={[filters?.start_date, filters?.end_date]}
                            getPopupContainer={(triggerNode) =>
                              triggerNode.parentNode
                            }
                            onChange={(e) => {
                              const filters_ = filters;
                              filters_.start_date = e?.[0];
                              filters_.end_date = e?.[1];
                              setFilters({ ...filters_ });
                              setLoading(true);
                              const obj = {
                                page: 1,
                                perPage: pageSize,
                                start_date: moment(e?.[0]?.$d).format(
                                  "YYYY-MM-DD"
                                ),
                                end_date: moment(e?.[1]?.$d).format(
                                  "YYYY-MM-DD"
                                ),
                                search: searchTerm,
                                customer_name: searchCustomerName,
                                ...allFilters,
                              };
                              GetWalmartOrdersActionWithAllProduct(obj);
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="card-body pb-5 pt-0">
                      <div className="mt-2">
                        <div className="position-relative">
                          <Tooltip title="Refresh Order" placement="right">
                            <button
                              className="btn btn-icon btn-info"
                              onClick={() => {
                                setLoading(true);
                                GetDirectWalmartOrdersAction();
                              }}
                              disabled={loading}
                            >
                              <i className="ki-outline ki-arrows-circle fs-1 customIcon" />
                              {/* <img
                                className="ms-2"
                                src="/assets/media/refresh_.png"
                                style={{ width: "18px", objectFit: "contain" }}
                              /> */}
                            </button>
                          </Tooltip>
                        </div>
                      </div>
                      <div className="table-responsive">
                        {loading ? (
                          <Loading />
                        ) : list?.length !== 0 ? (
                          <>
                            <IvcsrTable
                              columns={columns?.map((d) => ({
                                ...d,
                                width:
                                  d?.title === "Product"
                                    ? 400
                                    : d?.title?.length * 13,
                              }))}
                              fixed={true}
                              sticky={{
                                offsetHeader: "10px",
                              }}
                              dataSource={list}
                              rowKey="key"
                              loading={loading}
                              pagination={false}
                              scroll={{
                                x: "max-content",
                                y: "calc(100vh - 300px)",
                              }}
                              expandable={{
                                expandedRowRender: (record) => {
                                  return (
                                    <div>
                                      <Table
                                        dataSource={record?.list?.map(
                                          (d, i) => ({
                                            ...d,
                                            key: i + 1,
                                          })
                                        )}
                                        columns={columnsSub?.map((d) => ({
                                          ...d,
                                          width:
                                            d?.title === "Product"
                                              ? 200
                                              : d?.title?.length * 13,
                                        }))}
                                        rowKey="key"
                                        pagination={false}
                                        scroll={{ x: 900 }}
                                      />
                                    </div>
                                  );
                                },
                                expandIcon: ({ expanded, onExpand, record }) =>
                                  expanded ? (
                                    <i
                                      className="ki-outline ki-down fs-1 customIcon"
                                      onClick={(e) => onExpand(record, e)}
                                    />
                                  ) : (
                                    <i
                                      className="ki-outline ki-right fs-1 customIcon"
                                      onClick={(e) => onExpand(record, e)}
                                    />
                                  ),
                              }}
                            />
                          </>
                        ) : (
                          <NoData type="new" />
                        )}
                      </div>
                      <Pagination
                        loading={loading || list?.length === 0}
                        pageSize={pageSize}
                        page={page}
                        totalPage={totalPage}
                        onPerPage={onPerPage}
                        onPageNo={onPageNo}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
      {isOpenOrderStatusModal && (
        <OrderStatusModal
          show={isOpenOrderStatusModal}
          onHide={() => {
            setIsOpenOrderStatusModal(false);
            setAllStatusData([]);
          }}
          data={allStatusData}
        />
      )}
      {processOrderStatus && (
        <OperationModal
          show={processOrderStatus}
          onHide={() => {
            setProcessOrderStatus(false);
            setOrderData([]);
            setProcessOrderOperation("");
            setSelectedPoId("");
          }}
          onSuccess={() => {
            const obj = {
              page: 1,
              perPage: pageSize,
              start_date: moment(filters?.start_date?.$d).format("YYYY-MM-DD"),
              end_date: moment(filters?.end_date?.$d).format("YYYY-MM-DD"),
              search: searchTerm,
              customer_name: searchCustomerName,
              ...allFilters,
            };
            setPage(1);
            setPageSize(DefaultPerPage);
            setLoading(true);
            GetWalmartOrdersActionWithAllProduct(obj);
          }}
          processOrderOperation={processOrderOperation}
          orderData={orderData}
          setOrderData={setOrderData}
          selectedPoId={selectedPoId}
          {...props}
        />
      )}
    </div>
  );
}
