import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SystemEventLog from "../../../modules/pages/application-log/system-event-log";
import {
  GetSystemEventLogList,
  fakeActionSystemEventLog,
} from "../../../redux/modules/application-log/system-event-log/system-event-log.action";
const mapStateToProps = (state) => ({
  GetSystemEventLogListResponse:
    state.SystemEventLog.GetSystemEventLogListResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      GetSystemEventLogList,
      fakeActionSystemEventLog,
    },
    dispatch
  );

const SystemEventLogContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SystemEventLog);

export default SystemEventLogContainer;
