import React from "react";
import { NumberWithCommas } from "../../../../../../config";
import { Skeleton } from "antd";

export default function (props) {
  const { cardData, loading } = props;
  return (
    <div className="col-xxl-12">
      {" "}
      <div className="row ">
        <div className="col-12">
          {/*begin::Mixed Widget 14*/}
          <div className="card theme-dark-bg-body">
            {/*begin::Body*/}
            <div className="card-body d-flex flex-column">
              {/*begin::Wrapper*/}
              <div className="d-flex flex-column mb-7">
                {/*begin::Title*/}
                <a
                  href="#"
                  className="text-gray-900 text-hover-primary fw-bold fs-3"
                >
                  Performance Metrics
                </a>
                {/*end::Title*/}
              </div>
              {/*end::Wrapper*/}
              {/*begin::Row*/}
              {loading ? (
                <div className="row">
                  <div className="col-3">
                    <Skeleton.Button block size="large" active />
                  </div>
                  <div className="col-3">
                    <Skeleton.Button block size="large" active />
                  </div>
                  <div className="col-3">
                    <Skeleton.Button block size="large" active />
                  </div>
                  <div className="col-3">
                    <Skeleton.Button block size="large" active />
                  </div>
                </div>
              ) : (
                <div className="row g-5 gy-7 d-flex justify-content-between">
                  {/*begin::Col*/}
                  <div className="" style={{ width: "20%" }}>
                    {/*begin::Item*/}
                    <div
                      className="d-flex flex-stack "
                      style={{ borderLeft: "2px solid #4318ff" }}
                    >
                      <div className="symbol symbol-40px me-4">
                        <span className="symbol-label">
                          <i className="ki-duotone ki-dollar text-gray-600 fs-1">
                            <span className="path1" />
                            <span className="path2" />
                            <span className="path3" />
                          </i>
                        </span>
                      </div>
                      {/*begin::Section*/}
                      <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                        <div className="me-5">
                          <span className="text-gray-800 fw-bolder fs-4 me-3 d-block">
                            ${NumberWithCommas(cardData?.spend?.current || 0)}
                          </span>
                          <a href="#" className="fw-bold text-primary fs-6">
                            Ad Spend
                          </a>
                          <div className="d-flex align-items-center">
                            {/* <span className="text-gray-600 fw-semibold fs-7 d-block text-start ps-0">
                            559.5K
                          </span> */}

                            {cardData?.spend?.change_percentage > 0 ? (
                              <span className="badge badge-light-success fs-8">
                                <i className="ki-outline ki-arrow-up fs-5 text-green ms-n1" />
                                {NumberWithCommas(
                                  cardData?.spend?.change_percentage || 0
                                )}
                                %
                              </span>
                            ) : (
                              <span className="badge badge-light-danger fs-8">
                                <i className="ki-outline ki-arrow-down fs-5 text-danger ms-n1" />
                                {NumberWithCommas(
                                  cardData?.spend?.change_percentage || 0
                                )}
                                %
                              </span>
                            )}
                          </div>
                        </div>
                        {/*end::Content*/}
                      </div>
                      {/*end::Section*/}
                    </div>
                    {/*end::Item*/}
                  </div>
                  {/*begin::Col*/}

                  <div className="" style={{ width: "20%" }}>
                    {/*begin::Item*/}
                    <div
                      className="d-flex flex-stack "
                      style={{ borderLeft: "2px solid #4318ff" }}
                    >
                      <div className="symbol symbol-40px me-4">
                        <span className="symbol-label">
                          <i className="ki-duotone ki-chart-pie-3  text-gray-600 fs-1">
                            <span className="path1" />
                            <span className="path2" />
                            <span className="path3" />
                          </i>
                        </span>
                      </div>
                      {/*begin::Section*/}
                      <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                        <div className="me-5">
                          <span className="text-gray-800 fw-bolder fs-4 me-3 d-block">
                            ${NumberWithCommas(cardData?.sales?.current || 0)}
                          </span>
                          <a href="#" className="fw-bold text-primary fs-6">
                            Ad Sales
                          </a>
                          <div className="d-flex align-items-center">
                            {/* <span className="text-gray-600 fw-semibold fs-7 d-block text-start ps-0">
                            559.5K
                          </span> */}

                            {cardData?.sales?.change_percentage > 0 ? (
                              <span className="badge badge-light-success fs-8">
                                <i className="ki-outline ki-arrow-up fs-5 text-green ms-n1" />
                                {NumberWithCommas(
                                  cardData?.sales?.change_percentage || 0
                                )}
                                %
                              </span>
                            ) : (
                              <span className="badge badge-light-danger fs-8">
                                <i className="ki-outline ki-arrow-down fs-5 text-danger ms-n1" />
                                {NumberWithCommas(
                                  cardData?.sales?.change_percentage || 0
                                )}
                                %
                              </span>
                            )}
                          </div>
                        </div>
                        {/*end::Content*/}
                      </div>
                      {/*end::Section*/}
                    </div>
                    {/*end::Item*/}
                  </div>

                  {/*end::Col*/}
                  {/*begin::Col*/}
                  <div className="" style={{ width: "20%" }}>
                    {/*begin::Item*/}
                    <div
                      className="d-flex flex-stack "
                      style={{ borderLeft: "2px solid #4318ff" }}
                    >
                      <div className="symbol symbol-40px me-4">
                        <span className="symbol-label">
                          <i className="ki-duotone ki-chart-pie-3  text-gray-600 fs-1">
                            <span className="path1" />
                            <span className="path2" />
                            <span className="path3" />
                          </i>
                        </span>
                      </div>
                      {/*begin::Section*/}
                      <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                        <div className="me-5">
                          <span className="text-gray-800 fw-bolder fs-4 me-3 d-block">
                            $
                            {NumberWithCommas(
                              cardData?.total_sales?.current || 0
                            )}
                          </span>
                          <a href="#" className="fw-bold text-primary fs-6">
                            Total Sales
                          </a>
                          <div className="d-flex align-items-center">
                            {/* <span className="text-gray-600 fw-semibold fs-7 d-block text-start ps-0">
                            559.5K
                          </span> */}
                            {cardData?.total_sales?.change_percentage > 0 ? (
                              <span className="badge badge-light-success fs-8">
                                <i className="ki-outline ki-arrow-up fs-5 text-green ms-n1" />
                                {NumberWithCommas(
                                  cardData?.total_sales?.change_percentage || 0
                                )}
                                %
                              </span>
                            ) : (
                              <span className="badge badge-light-danger fs-8">
                                <i className="ki-outline ki-arrow-down fs-5 text-danger ms-n1" />
                                {NumberWithCommas(
                                  cardData?.total_sales?.change_percentage || 0
                                )}
                                %
                              </span>
                            )}
                          </div>
                        </div>
                        {/*end::Content*/}
                      </div>
                      {/*end::Section*/}
                    </div>
                    {/*end::Item*/}
                  </div>

                  {/*end::Col*/}
                  {/*begin::Col*/}

                  <div className="" style={{ width: "20%" }}>
                    {/*begin::Item*/}
                    <div
                      className="d-flex flex-stack "
                      style={{ borderLeft: "2px solid #4318ff" }}
                    >
                      <div className="symbol symbol-40px me-4">
                        <span className="symbol-label">
                          <i className="ki-duotone ki-graph-up text-gray-600 fs-1">
                            <span className="path1" />
                            <span className="path2" />
                            <span className="path3" />
                            <span className="path4" />
                            <span className="path5" />
                            <span className="path6" />
                          </i>
                        </span>
                      </div>
                      {/*begin::Section*/}
                      <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                        <div className="me-5">
                          <span className="text-gray-800 fw-bolder fs-4 me-3 d-block">
                            {NumberWithCommas(cardData?.acos?.current || 0)}%{" "}
                          </span>
                          <a href="#" className="fw-bold text-primary fs-6">
                            ACOS
                          </a>
                          <div className="d-flex align-items-center">
                            {/* <span className="text-gray-600 fw-semibold fs-7 d-block text-start ps-0">
                            559.5K
                          </span> */}
                            {cardData?.acos?.change_percentage > 0 ? (
                              <span className="badge badge-light-success fs-8">
                                <i className="ki-outline ki-arrow-up fs-5 text-green ms-n1" />
                                {NumberWithCommas(
                                  cardData?.acos?.change_percentage || 0
                                )}
                                %
                              </span>
                            ) : (
                              <span className="badge badge-light-danger fs-8">
                                <i className="ki-outline ki-arrow-down fs-5 text-danger ms-n1" />
                                {NumberWithCommas(
                                  cardData?.acos?.change_percentage || 0
                                )}
                                %
                              </span>
                            )}
                          </div>
                        </div>
                        {/*end::Content*/}
                      </div>
                      {/*end::Section*/}
                    </div>
                    {/*end::Item*/}
                  </div>

                  {/*end::Col*/}
                  {/*begin::Col*/}

                  <div className="" style={{ width: "20%" }}>
                    {/*begin::Item*/}
                    <div
                      className="d-flex flex-stack "
                      style={{ borderLeft: "2px solid #4318ff" }}
                    >
                      <div className="symbol symbol-40px me-4">
                        <span className="symbol-label">
                          <i className="ki-duotone ki-graph-up text-gray-600 fs-1">
                            <span className="path1" />
                            <span className="path2" />
                            <span className="path3" />
                            <span className="path4" />
                            <span className="path5" />
                            <span className="path6" />
                          </i>
                        </span>
                      </div>
                      {/*begin::Section*/}
                      <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                        <div className="me-5">
                          <span className="text-gray-800 fw-bolder fs-4 me-3 d-block">
                            {NumberWithCommas(cardData?.tacos?.current || 0)}%
                          </span>
                          <a href="#" className="fw-bold text-primary fs-6">
                            TACoS
                          </a>
                          <div className="d-flex align-items-center">
                            {/* <span className="text-gray-600 fw-semibold fs-7 d-block text-start ps-0">
                            559.5K
                          </span> */}
                            {cardData?.tacos?.change_percentage > 0 ? (
                              <span className="badge badge-light-success fs-8">
                                <i className="ki-outline ki-arrow-up fs-5 text-green ms-n1" />
                                {NumberWithCommas(
                                  cardData?.tacos?.change_percentage || 0
                                )}
                                %
                              </span>
                            ) : (
                              <span className="badge badge-light-danger fs-8">
                                <i className="ki-outline ki-arrow-down fs-5 text-danger ms-n1" />
                                {NumberWithCommas(
                                  cardData?.tacos?.change_percentage || 0
                                )}
                                %
                              </span>
                            )}
                          </div>
                        </div>
                        {/*end::Content*/}
                      </div>
                      {/*end::Section*/}
                    </div>
                    {/*end::Item*/}
                  </div>

                  {/*end::Col*/}
                </div>
              )}

              {/*end::Row*/}
            </div>
          </div>
          {/*end::Mixed Widget 14*/}
        </div>
      </div>
    </div>
  );
}
