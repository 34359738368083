import {
  Alert,
  Checkbox,
  DatePicker,
  Input,
  Popover,
  Select,
  Tag,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import lodashObj from "lodash";
import { DateFormat, getUserId } from "../../../../../config";
import dayjs from "dayjs";
import { AllCarrierName } from "../../../../../config/static-select-option";
import { OrderWrapper } from "../lib/style";
export default function (props) {
  const {
    show,
    onHide,
    processOrderOperation,
    selectedPoId,
    WalmartShipItemAction,
    WalmartCancelItemAction,
    fakeActionWalmartOrders,
    selectedCheckbox,
    onSuccess,
    addressList,
    SaveReturnCenterAddr,
    fakeActionuserConfiguration,
  } = props;

  const [shipDate, setShipDate] = useState(dayjs());
  const [isAddReturnAddr, setIsAddReturnAddr] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [isAddNewAddress, setIsAddNewAddress] = useState(false);
  const [isAddAddrInSettings, setIsAddAddrInSettings] = useState(false);
  const [carrierInfo, setCarrierInfo] = useState({
    shipDateTime: Date.parse(dayjs()),
    carrierName: null,
    methodCode: null,
    trackingNumber: 0,
    trackingURL: "",
  });
  const [returnAddr, setReturnAddr] = useState({
    address1: "",
    city: "",
    state: "",
    postalCode: "",
    country: "",
    name: "",
    dayPhone: "",
    emailId: "",
  });

  const WalmartShipmentItemsRes = useSelector(
    (state) => state.WalmartOrders.WalmartShipmentItemsResponse || {}
  );
  const SaveReturnCenterAddrRes = useSelector(
    (state) => state.Configuration.SaveReturnCenterAddrResponse || {}
  );

  useEffect(() => {
    if (WalmartShipmentItemsRes.status === true) {
      message.destroy();
      message.success("Shipment - Request submit to walmart");
      onSuccess();
      onHide();
      fakeActionWalmartOrders("WalmartShipmentItemsResponse");
    } else if (WalmartShipmentItemsRes.status === false) {
      message.destroy();
      message.error("Request failed");
      fakeActionWalmartOrders("WalmartShipmentItemsResponse");
    }
  }, [WalmartShipmentItemsRes]);

  useEffect(() => {
    if (SaveReturnCenterAddrRes.status === true) {
      fakeActionuserConfiguration("SaveReturnCenterAddrResponse");
    } else if (SaveReturnCenterAddrRes.status === false) {
      fakeActionuserConfiguration("SaveReturnCenterAddrResponse");
    }
  }, [SaveReturnCenterAddrRes]);

  const ShipWalmartItems = () => {
    const finalJson = selectedCheckbox?.map((d) => {
      // isAddReturnAddr
      const returnCenterAddress_ = isAddReturnAddr
        ? {
            returnCenterAddress: d?.return_center_address
              ? JSON.parse(d?.return_center_address)
              : returnAddr,
          }
        : {};
      return {
        lineNumber: d?.order_line_number,
        intentToCancelOverride: false,
        sellerOrderId: d?.sub_seller_id,
        orderLineStatuses: {
          orderLineStatus: [
            {
              status: "Shipped",
              statusQuantity: {
                unitOfMeasurement: d?.new_unit_of_measurement,
                amount: d?.qty_to_ship?.toString(),
              },
              trackingInfo: {
                shipDateTime: carrierInfo?.shipDateTime,
                carrierName: {
                  carrier: carrierInfo?.carrierName,
                },
                methodCode: carrierInfo?.methodCode,
                trackingNumber: carrierInfo?.trackingNumber,
                trackingURL: carrierInfo?.trackingURL,
              },
              ...returnCenterAddress_,
            },
          ],
        },
      };
    });
    const newJson = {
      orderShipment: {
        orderLines: {
          orderLine: finalJson,
        },
      },
    };
    console.log(newJson, "-newJson");
    if (processOrderOperation === "ship") {
      message.destroy();
      message.loading("Loading...", 0);
      WalmartShipItemAction(selectedPoId, newJson);
    }
  };

  const setCarrierInfoValue = (key, value) => {
    const newObj = { ...carrierInfo };
    newObj[key] = value;
    setCarrierInfo({ ...newObj });
  };
  const setReturnAddressValue = (key, value) => {
    const newObj = { ...returnAddr };
    newObj[key] = value;
    setReturnAddr({ ...newObj });
  };

  return (
    <>
      <Modal show={show} onHide={onHide} size="xl" centered backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>
            {processOrderOperation === "ship" ? "Ship Items" : "Cancel Items"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <OrderWrapper>
            <div style={{ height: "525px", overflow: "auto" }}>
              {processOrderOperation === "ship" && (
                <div className="row mx-0 px-0">
                  <div className="col-12 my-1">
                    <label className="fw-bolder fs-4 text-danger ">
                      Note :
                    </label>
                    <label className="fw-bold text-danger fs-5 ms-2">
                      All selected SKUs will ship with the following carrier
                      information
                    </label>
                  </div>
                  <div className="separator separator-dashed my-3" />
                  <div className="col-12 my-1">
                    <label className="fw-bold fs-5 text-primary ">
                      Carrier Info
                    </label>
                  </div>
                  <div className="col-3 my-1">
                    <label className="fw-bold required">Ship Date</label>
                    <DatePicker
                      onChange={(date, dateString) => {
                        setCarrierInfoValue(
                          "shipDateTime",
                          Date.parse(dateString)
                        );
                        setShipDate(date);
                      }}
                      value={shipDate}
                      style={{ width: "100%" }}
                      size="large"
                      className="ant_common_input"
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentNode
                      }
                      format={DateFormat}
                      needConfirm={false}
                    />
                  </div>
                  <div className="col-3 my-1">
                    <label className="fw-bold required">Carrier Name</label>
                    <Select
                      options={AllCarrierName?.map((d) => {
                        return { label: d, value: d };
                      })}
                      value={carrierInfo?.carrierName}
                      onChange={(e) => {
                        setCarrierInfoValue("carrierName", e);
                      }}
                      placeholder="Carrier Name"
                      size="large"
                      style={{ width: "100%" }}
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentNode
                      }
                    />
                  </div>
                  <div className="col-3 my-1">
                    <label className="fw-bold required">Method Code</label>
                    <Select
                      options={[
                        {
                          label: "Standard",
                          value: "Standard",
                        },
                        {
                          label: "Express",
                          value: "Express",
                        },
                      ]}
                      value={carrierInfo?.methodCode}
                      onChange={(e) => {
                        setCarrierInfoValue("methodCode", e);
                      }}
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentNode
                      }
                      style={{ width: "100%" }}
                      placeholder="Method Code"
                      size="large"
                    />
                  </div>
                  <div className="col-3 my-1">
                    <label className="fw-bold required">Tracking Number</label>
                    <Input
                      size="large"
                      className="ant_common_input"
                      defaultValue={0}
                      value={carrierInfo?.trackingNumber}
                      onBlur={(e) => {
                        if (!e.target.value) {
                          setCarrierInfoValue("trackingNumber", 0);
                        }
                      }}
                      onChange={(e) => {
                        let value = e.target.value;
                        // value = value.replace(/^0+/, "");
                        // if (carrierInfo?.trackingNumber == 0 && value != "0") {
                        //   value = value.replace("0", "");
                        // }
                        // const isNumeric = /^[0-9]*$/.test(value);
                        // if (isNumeric || value === "") {
                        setCarrierInfoValue("trackingNumber", value);
                        // }
                      }}
                      placeholder="Tracking Number"
                    />
                  </div>
                  <div className="col-6 my-1">
                    <label className="fw-bold required">Tracking URL</label>
                    <Input
                      size="large"
                      className="ant_common_input"
                      value={carrierInfo?.trackingURL}
                      onChange={(e) => {
                        setCarrierInfoValue("trackingURL", e.target.value);
                      }}
                      onBlur={(e) => {
                        const isUrl =
                          /^(https?|ftp):\/\/[^\s\/$.?#].[^\s]*$/.test(
                            e.target.value
                          );
                        if (isUrl) {
                          setCarrierInfoValue("trackingURL", e.target.value);
                        } else {
                          message.destroy();
                          message.error(
                            !e.target.value
                              ? "Please enter tracking url"
                              : "Please enter valid tracking url"
                          );
                          setCarrierInfoValue("trackingURL", "");
                        }
                      }}
                      placeholder="Tracking URL"
                    />
                  </div>
                  <div className="col-3 my-1 d-flex align-items-center">
                    <Checkbox
                      checked={isAddReturnAddr}
                      onChange={(e) => {
                        setIsAddReturnAddr(e.target.checked);
                      }}
                    />
                    <label className="fw-bold ms-3">
                      Add Return Center Address
                    </label>
                  </div>
                  {isAddReturnAddr && (
                    <>
                      <div className="separator separator-dashed my-3" />
                      <div className="col-12 mb-1">
                        <label className="fw-bold text-primary fs-5">
                          Return Center Address
                        </label>
                        <span className="text-muted ms-2">
                          (Select existing address or add new address)
                        </span>
                      </div>
                      <div className="col-11 col-lg-8 col-xl-7">
                        <label className="fw-bold required">
                          Select Address
                        </label>
                        <Select
                          size="large"
                          placeholder="Select Address"
                          style={{ width: "100%" }}
                          getPopupContainer={(triggerNode) =>
                            triggerNode.parentNode
                          }
                          value={selectedAddress}
                          onChange={(e, _) => {
                            console.log(_, "-------_");
                            setSelectedAddress(e);
                            const newObj = { ...returnAddr };
                            newObj.address1 = _?.address_line1;
                            newObj.city = _?.city;
                            newObj.state = _?.state;
                            newObj.country = _?.country;
                            newObj.postalCode = _?.postal_code;
                            setReturnAddr({ ...newObj });
                          }}
                          options={addressList?.map((d) => {
                            return {
                              label: (
                                <span>
                                  {d?.address_line1}, {d?.city}, {d?.state},
                                  {d?.country}, {d?.postal_code}
                                </span>
                              ),
                              value: d?.id,
                              ...d,
                            };
                          })}
                          disabled={isAddNewAddress}
                        />
                      </div>
                      <div className="col-1 d-flex align-items-end">
                        <i
                          onClick={() => {
                            setIsAddNewAddress(!isAddNewAddress);
                            setSelectedAddress(null);
                            const newObj = { ...returnAddr };
                            newObj.address1 = "";
                            newObj.city = "";
                            newObj.state = "";
                            newObj.postalCode = "";
                            newObj.country = "";
                            newObj.name = "";
                            newObj.dayPhone = "";
                            newObj.emailId = "";
                            setReturnAddr({ ...newObj });
                          }}
                          className={`ki-outline ki-plus
                          `}
                          style={{
                            fontSize: "25px",
                            color: "#fff",
                            background: "#4318ff",
                            borderRadius: "7px",
                            padding: "5px",
                          }}
                        />
                      </div>
                      {isAddNewAddress ? (
                        <>
                          {" "}
                          <div className="col-12 mt-10"></div>
                          <div className="col-3 my-1">
                            <label className="fw-bold required">Name</label>
                            <Input
                              size="large"
                              className="ant_common_input"
                              value={carrierInfo?.name}
                              onChange={(e) => {
                                setReturnAddressValue("name", e.target.value);
                              }}
                              placeholder="Name"
                            />
                          </div>
                          <div className="col-3 my-1">
                            <label className="fw-bold required">
                              Address 1
                            </label>
                            <Input
                              size="large"
                              className="ant_common_input"
                              value={carrierInfo?.address1}
                              onChange={(e) => {
                                setReturnAddressValue(
                                  "address1",
                                  e.target.value
                                );
                              }}
                              placeholder="Address 1"
                            />
                          </div>
                          <div className="col-3 my-1">
                            <label className="fw-bold required">City</label>
                            <Input
                              size="large"
                              className="ant_common_input"
                              value={carrierInfo?.city}
                              onChange={(e) => {
                                setReturnAddressValue("city", e.target.value);
                              }}
                              placeholder="City"
                            />
                          </div>
                          <div className="col-3 my-1">
                            <label className="fw-bold required">State</label>
                            <Input
                              size="large"
                              className="ant_common_input"
                              value={carrierInfo?.state}
                              onChange={(e) => {
                                setReturnAddressValue("state", e.target.value);
                              }}
                              placeholder="State"
                            />
                          </div>
                          <div className="col-3 my-1">
                            <label className="fw-bold required">Country</label>
                            <Input
                              size="large"
                              className="ant_common_input"
                              value={carrierInfo?.country}
                              onChange={(e) => {
                                setReturnAddressValue(
                                  "country",
                                  e.target.value
                                );
                              }}
                              placeholder="Country"
                            />
                          </div>
                          <div className="col-3 my-1">
                            <label className="fw-bold required">
                              Postal Code
                            </label>
                            <Input
                              size="large"
                              className="ant_common_input"
                              value={carrierInfo?.postalCode}
                              onChange={(e) => {
                                setReturnAddressValue(
                                  "postalCode",
                                  e.target.value
                                );
                              }}
                              placeholder="Postal Code"
                            />
                          </div>
                          <div className="col-6 my-1 d-flex align-items-center">
                            <Checkbox
                              checked={isAddAddrInSettings}
                              onChange={(e) => {
                                setIsAddAddrInSettings(e.target.checked);
                              }}
                            />
                            <label className="fw-bold ms-3">
                              Add this retrun address to your main configuration
                              to use further
                            </label>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  )}
                </div>
              )}
            </div>
          </OrderWrapper>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-light-danger"
            onClick={onHide}
          >
            Close
          </button>
          <button
            type="submit"
            className="btn btn-primary"
            disabled={
              Object.entries(carrierInfo)?.filter((d) => !d?.[1])?.length > 0 ||
              (isAddReturnAddr &&
                Object.entries(returnAddr)?.filter(
                  (d) =>
                    d?.[0] !== "dayPhone" && d?.[0] !== "emailId" && !d?.[1]
                )?.length > 0)
            }
            onClick={() => {
              if (isAddAddrInSettings) {
                const addrJson = {
                  display_name: returnAddr?.address1,
                  address_line1: returnAddr?.address1,
                  address_line2: "",
                  city: returnAddr?.city,
                  state: returnAddr?.state,
                  country: returnAddr?.country,
                  postal_code: returnAddr?.postalCode,
                };
                SaveReturnCenterAddr(addrJson, "");
              }
              ShipWalmartItems();
            }}
          >
            {processOrderOperation === "ship" ? "Ship Items" : "Cancel Items"}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
