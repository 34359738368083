import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ActiveKeywordPage from "../../../../modules/pages/advertisement/adv-dashboard/lib/active-keyword-page";

import {
  GetAmazonActiveKeywords,
  fakeActionAdvDashboard,
} from "../../../../redux/modules/advertisement/dashboard/index.action";

const mapStateToProps = (state) => ({
  GetAmazonActiveKeywordsResponse:
    state.AdvDashboard.GetAmazonActiveKeywordsResponse,
  //   GetDashboardCardGraphDataResponse:
  //     state.AdvDashboard.GetDashboardCardGraphDataResponse,
  //   GetDashboardSubCardDataResponse:
  //     state.AdvDashboard.GetDashboardSubCardDataResponse,
  //   GetActiveCampaignSubCardDataResponse:
  //     state.AdvDashboard.GetActiveCampaignSubCardDataResponse,
  //   GetTargetingSubCardDataResponse:
  //     state.AdvDashboard.GetTargetingSubCardDataResponse,
  //   GetDailyPerformanceTrendDataResponse:
  //     state.AdvDashboard.GetDailyPerformanceTrendDataResponse,
  //   GetTopSearchTermDataResponse: state.AdvDashboard.GetTopSearchTermDataResponse,
  //   GetWalmartAdvFilterResponse: state.AdvDashboard.GetWalmartAdvFilterResponse,
  //   GetWalmartAdvPerformanceChartResponse:
  //     state.AdvDashboard.GetWalmartAdvPerformanceChartResponse,
  //   GetWalmartAdvPerformanceSummaryResponse:
  //     state.AdvDashboard.GetWalmartAdvPerformanceSummaryResponse,
  //   GetWalmartCampaignAdSpendResponse:
  //     state.AdvDashboard.GetWalmartCampaignAdSpendResponse,
  //   GetWalmartCampaignOutOfBudgetResponse:
  //     state.AdvDashboard.GetWalmartCampaignOutOfBudgetResponse,
  //   GetSalesChannelListResponse: state.SalesAnalytics.GetSalesChannelListResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      GetAmazonActiveKeywords,
      fakeActionAdvDashboard,
    },
    dispatch
  );

const ActiveKeywordPageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ActiveKeywordPage);

export default ActiveKeywordPageContainer;
