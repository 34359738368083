import axiosCall from "../../../configurations/network-services/axiosCall";

const prefix = "analytics";

export const GetInventoryDetailsList = (data) => {
  const path = `inventory/get-details?marketplace_id=ATVPDKIKX0DER&search&type=${
    data?.type || ""
  }&page=${data?.page || 1}&per-page=${data?.perPage || 10}&isExport=${
    data?.isExport || 0
  }&sort=${data?.sort || ""}`;
  const responseType = "GET_INVENTORY_DETAILS_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetWalmartInventoryDetailsList = (data) => {
  const path = `wm/inventory?marketplace_id=${data?.marketplaceId || ""}&page=${
    data?.page || 1
  }&per-page=${data?.perPage || 10}&isExport=${data?.isExport || 0}&sku=${
    data?.search || ""
  }`;
  const responseType = "GET_WALMART_INVENTORY_DETAILS_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const fakeActionInventoryDetails = (data) => async (dispatch) => {
  dispatch({ type: "FAKE_ACTION_INVENTORY_DETAILS", state: data });
};
