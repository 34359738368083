import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import IvcsrTable from "../../../../../component/ivcsr-table";
import Loading from "../../../../../component/loading";
import NoData from "../../../../../component/no-data";
import { useLocation } from "react-router-dom";
import { urlDecode } from "../../../../../config";

export default function (props) {
  const { GetAmazonActiveKeywords, fakeActionAdvDashboard } = props;
  const location = useLocation();
  const [urlState, setUrlState] = useState({});
  useEffect(() => {
    GetAmazonActiveKeywords();
  }, []);

  const GetAmazonActiveKeywordsRes = useSelector(
    (state) => state.AdvDashboard.GetAmazonActiveKeywordsResponse || {}
  );
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    console.log(urlDecode(location), "location");
    const newUrl = urlDecode(location);
    setUrlState(newUrl);
  }, [location]);

  useEffect(() => {
    if (GetAmazonActiveKeywordsRes?.status === true) {
      console.log(GetAmazonActiveKeywordsRes, "GetAmazonActiveKeywordsRes");
      setList(GetAmazonActiveKeywordsRes?.data?.data || []);
      setLoading(false);
      fakeActionAdvDashboard("GetAmazonActiveKeywordsResponse");
    } else if (GetAmazonActiveKeywordsRes?.status === false) {
      setLoading(false);
      fakeActionAdvDashboard("GetAmazonActiveKeywordsResponse");
    }
  }, [GetAmazonActiveKeywordsRes]);

  const columns = [
    {
      title: "Keyword",
      dataIndex: "keyword",
      key: "keyword",
      render: (text) => {
        return (
          <span className="fw-bold" style={{ textTransform: "capitalize" }}>
            {text?.toLowerCase()?.replaceAll("-", " ") || "-"}
          </span>
        );
      },
    },
    {
      title: "Clicks",
      dataIndex: "clicks",
      key: "clicks",
    },
    {
      title: "Impressions",
      dataIndex: "impressions",
      key: "impressions",
    },
    {
      title: "Sales",
      dataIndex: "sales",
      key: "sales",
      render: (text) => {
        return <span>${text || "-"}</span>;
      },
    },
    {
      title: "Spend",
      dataIndex: "spend",
      key: "spend",
      render: (text) => {
        return <span>${text || "-"}</span>;
      },
    },
    {
      title: "ACoS",
      dataIndex: "ACoS",
      key: "ACoS",
      render: (text) => {
        return <span>${text || "-"}</span>;
      },
    },
    {
      title: "ACoS Percentage",
      dataIndex: "ACoS_percentage",
      key: "ACoS_percentage",
    },
    {
      title: "CPC",
      dataIndex: "CPC",
      key: "CPC",
      render: (text) => {
        return <span>${text || "-"}</span>;
      },
    },
    {
      title: "CPO",
      dataIndex: "CPO",
      key: "CPO",
    },
    {
      title: "CTR",
      dataIndex: "CTR",
      key: "CTR",
      render: (text) => {
        return <span>{text || "-"}%</span>;
      },
    },
    {
      title: "CVR",
      dataIndex: "CVR",
      key: "CVR",
    },
    {
      title: "ROAS",
      dataIndex: "ROAS",
      key: "ROAS",
      render: (text) => {
        return <span>${text || "-"}</span>;
      },
    },
    {
      title: "Ad Keyword Status",
      dataIndex: "ad_keyword_status",
      key: "ad_keyword_status",
    },
    {
      title: "Keyword ID",
      dataIndex: "keyword_id",
      key: "keyword_id",
    },
    {
      title: "Keyword Type",
      dataIndex: "keyword_type",
      key: "keyword_type",
    },
    {
      title: "Match Type",
      dataIndex: "match_type",
      key: "match_type",
    },
    {
      title: "Targeting",
      dataIndex: "targeting",
      key: "targeting",
    },
    {
      title: "Unit Ordered",
      dataIndex: "unit_ordered",
      key: "unit_ordered",
    },
  ];
  return (
    <>
      <div className="d-flex flex-column flex-column-fluid">
        <div id="kt_app_content" className="app-content flex-column-fluid">
          <div
            id="kt_app_content_container"
            className="app-container container-fluid"
          >
            <div className="card card-xxl-stretch mb-5 mb-xl-5">
              <div className="card-header pt-5 border-bottom-0">
                <label className="card-title align-items-start flex-column">
                  <span className="fw-bold text-dark fs-2">
                    Active Keywords
                  </span>
                </label>
                <div className="card-toolbar">
                  <div className="me-4  fs-4">
                    Start Date :
                    <label className="fw-bold ms-1">
                      {urlState?.start_date?.replaceAll("%", " ")}
                    </label>
                  </div>
                  &
                  <div className="ms-4 fs-4">
                    End Date :
                    <label className="fw-bold ms-1">
                      {urlState?.end_date?.replaceAll("%", " ")}
                    </label>
                  </div>
                </div>
              </div>

              <div className="card-body py-3">
                <div className="table-responsive mt-5">
                  {loading ? (
                    <Loading />
                  ) : list?.length !== 0 ? (
                    <>
                      <IvcsrTable
                        columns={columns}
                        fixed="top"
                        dataSource={list}
                        rowKey="key"
                        loading={loading}
                        pagination={true}
                        scroll={{ x: "max-content" }}
                      />
                    </>
                  ) : (
                    <NoData type="new" />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
