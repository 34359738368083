import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import MarketplaceCredential from "../../../modules/pages/setting/marketplace-credential";

import {
  GetMarketplaceCred,
  GetRegionList,
  GetRegionWiseMarketplaceList,
  fakeActionMarketplaceCred,
  SPGenerateRefreshTokenAction,
  AdvGenerateRefreshTokenAction,
  SaveAmazonAdvCredential,
  StoreWMMarketplaceAction,
  GetWalmartCredentialAction,
  StoreWMConnectAction,
} from "../../../redux/modules/setting/marketplace-credential/marketplace-credential.action";

const mapStateToProps = (state) => ({
  GetMarketplaceCredListResponse:
    state.MarketplaceCredential.GetMarketplaceCredListResponse,
  GetRegionListResponse: state.MarketplaceCredential.GetRegionListResponse,
  GetRegionWiseMarketplaceListResponse:
    state.MarketplaceCredential.GetRegionWiseMarketplaceListResponse,
  SPGenerateRefreshResponse:
    state.MarketplaceCredential.SPGenerateRefreshResponse,
  ADVGenerateRefreshResponse:
    state.MarketplaceCredential.ADVGenerateRefreshResponse,
  SaveAmazonAdvCredentialResponse:
    state.MarketplaceCredential.SaveAmazonAdvCredentialResponse,
  GetWalmartCredentialResponse:
    state.MarketplaceCredential.GetWalmartCredentialResponse,
  StoreWalMartMarketplaceResponse:
    state.MarketplaceCredential.StoreWalMartMarketplaceResponse,
  StoreWalMartConnectResponse:
    state.MarketplaceCredential.StoreWalMartConnectResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      GetMarketplaceCred,
      GetRegionList,
      GetRegionWiseMarketplaceList,
      fakeActionMarketplaceCred,
      SPGenerateRefreshTokenAction,
      AdvGenerateRefreshTokenAction,
      SaveAmazonAdvCredential,
      StoreWMMarketplaceAction,
      GetWalmartCredentialAction,
      StoreWMConnectAction,
    },
    dispatch
  );

const MarketplaceCredentialContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MarketplaceCredential);

export default MarketplaceCredentialContainer;
