import { Alert, Checkbox, Col, Input, Row, Select, message } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function (props) {
  return (
    <>
      <div>
        <div className="d-flex justify-content-center">
          <img
            src="/assets/media/Under construction-amico.svg"
            style={{
              height: "600px",
              width: "600px",
              userDrag: "none",
              userSelect: "none",
            }}
          />
        </div>

        <div className="d-flex justify-content-center">
          <label className="fs-1 fw-bold">Under Construction</label>
        </div>
        <div className="d-flex justify-content-center my-10">
          <Link to="/dashboard">
            <button className="btn btn-primary">Go to Dashboard</button>
          </Link>
        </div>
      </div>
    </>
  );
}
