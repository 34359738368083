import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Dashboard from "../../modules/pages/dashboard";

import {
  GetTopProductsList,
  GetDashboardUnitSoldChart,
  GetDashboardSalesData,
  fakeActionDashboard,
} from "../../redux/modules/dashboard/dashboard.action";

import {
  GetSalesChannelList,
  fakeActionSalesAnalytics,
} from "../../redux/modules/sales-analytics/sales-analytics.action";

const mapStateToProps = (state) => ({
  GetDashboardTopProductsListResponse:
    state.Dashboard.GetDashboardTopProductsListResponse,
  GetDashboardUnitSoldChartResponse:
    state.Dashboard.GetDashboardUnitSoldChartResponse,
  GetDashboardSalesDataResponse: state.Dashboard.GetDashboardSalesDataResponse,
  GetSalesChannelListResponse: state.SalesAnalytics.GetSalesChannelListResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      GetTopProductsList,
      GetDashboardUnitSoldChart,
      GetDashboardSalesData,
      fakeActionDashboard,
      GetSalesChannelList,
      fakeActionSalesAnalytics,
    },
    dispatch
  );

const DashboardContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard);

export default DashboardContainer;
