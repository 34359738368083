import { Alert, Input, Radio, message } from "antd";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
export default function ({
  show,
  onHide,
  onSuccess,
  data,
  AddUserAction,
  fakeActionManageUser,
}) {
  const [sending, setSending] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [contact, setContact] = useState("");
  const [sellerName, setSellerName] = useState("");
  const [userId, serUserId] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidContact, setIsValidContact] = useState(true);
  const [connected_by, set_connected_by] = useState("");

  const AddUserRes = useSelector(
    (state) => state.ManageUser.AddUserResponse || {}
  );

  useEffect(() => {
    if (data.id) {
      serUserId(data.id);
      setName(data.name);
      setSellerName(data.seller_name);
      setEmail(data.email);
      setContact(data.contact_no);
      set_connected_by(data?.connected_by);
      setPassword("");
    } else {
      setName("");
      setSellerName("");
      setEmail("");
      setPassword("");
      setContact("");
      set_connected_by("");
    }
  }, [data]);

  const onSubmit = () => {
    const isValidEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
      email
    );
    setIsValidEmail(isValidEmail);

    const isValidContact = /^[0-9]*$/.test(contact);
    setIsValidContact(isValidContact);
    if (isValidEmail && isValidContact && connected_by) {
      const body = {
        name: name,
        email: email,
        password: password,
        contact_no: contact,
        seller_name: sellerName,
        password: password,
        connected_by: connected_by,
      };
      if (data.id) {
        delete body.password;
      }
      setSending(true);
      // console.log(body, "body");
      message.destroy();
      message.loading("Loading", 0);
      AddUserAction(data.id, body);
    }
  };

  useEffect(() => {
    if (AddUserRes.status) {
      message.destroy();
      message.success(AddUserRes?.message);
      onSuccess();
      setSending(false);
      onSuccess();
      fakeActionManageUser("AddUserResponse");
    } else if (AddUserRes.status === false) {
      setSending(false);
      message.destroy();
      message.error(AddUserRes?.message);
      fakeActionManageUser("AddUserResponse");
    }
  }, [AddUserRes]);

  return (
    <>
      <Modal show={show} onHide={onHide} size="md" centered backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Manage User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <div>
              <div className="col-md-12 form-group mb-5">
                <label className="fw-bold fs-7">Name</label>
                <Input
                  className="ant_common_input"
                  size="large"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
              </div>
              <div className="col-md-12 form-group mb-5">
                <label className="fw-bold fs-7">Seller Name</label>
                <Input
                  className="ant_common_input"
                  size="large"
                  value={sellerName}
                  onChange={(e) => {
                    setSellerName(e.target.value);
                  }}
                />
              </div>
              <div className="col-md-12 form-group mb-5">
                <label className="fw-bold fs-7">Email</label>
                <Input
                  className="ant_common_input"
                  size="large"
                  value={email}
                  type="email"
                  autocomplete="false"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  onBlur={(e) => {
                    const isValidEmail =
                      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                        e.target.value
                      );
                    setIsValidEmail(isValidEmail);
                  }}
                />
                {!isValidEmail && (
                  <Alert
                    className="mt-2"
                    message="Invalid Email"
                    type="error"
                    showIcon
                    closable
                  />
                )}
              </div>
              <div className="col-md-12 form-group mb-5">
                <label className="fw-bold fs-7">Phone Number</label>
                <Input
                  className="ant_common_input"
                  size="large"
                  value={contact}
                  onChange={(e) => {
                    setContact(e.target.value);
                  }}
                  onBlur={(e) => {
                    const isValidContact = /^[0-9]*$/.test(e.target.value);
                    setIsValidContact(isValidContact);
                  }}
                />
                {!isValidContact && (
                  <Alert
                    className="mt-2"
                    message="Invalid Phone Number"
                    type="error"
                    showIcon
                    closable
                  />
                )}
              </div>

              <div className="col-12 my-3">
                <label className="fw-bold fs-7 mb-2">Platforms</label>
                <br />
                <Radio.Group
                  onChange={(e) => {
                    set_connected_by(e.target.value);
                  }}
                  value={connected_by}
                >
                  <Radio value={"walmart"} className="fw-bold">
                    Walmart
                  </Radio>
                  <Radio value={"amazon"} className="fw-bold">
                    Amazon
                  </Radio>
                  <Radio value={"both"} className="fw-bold">
                    Both
                  </Radio>
                </Radio.Group>
              </div>

              {!data.id && (
                <>
                  <div className="col-md-12 form-group mb-5">
                    <label className="fw-bold fs-7">Password</label>
                    <Input
                      className="ant_common_input"
                      size="large"
                      value={password}
                      autocomplete="new-password"
                      type="password"
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                    />
                  </div>
                </>
              )}
            </div>
          </>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-light-danger"
            onClick={onHide}
          >
            Close
          </button>
          <button
            type="submit"
            className="btn btn-primary"
            onClick={onSubmit}
            disabled={
              name && email && sellerName && contact && connected_by && !sending
                ? false
                : true
            }
          >
            Submit
            {sending && (
              <span className="spinner-border spinner-border-sm align-middle ms-2" />
            )}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
