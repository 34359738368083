import { Popover, Table, Tag, Tooltip } from "antd";
import React, { useState } from "react";
import IvcsrTable from "../../../../component/ivcsr-table";
import { NumberWithCommas } from "../../../../config";

export default function (props) {
  const {
    data,
    page,
    pageSize,
    selectedCurrencySign,
    columns,
    handleOnChangeColumn,
  } = props;
  // console.log(data, columns, "-------------------data");
  return (
    <>
      <IvcsrTable
        dataSource={data}
        columns={columns}
        onChange={handleOnChangeColumn}
        scroll={{
          x: 2000,
          y: 500,
        }}
        pagination={false}
      />
    </>
  );
}
