export default (state, action) => {
  if (typeof state === "undefined") {
    return [];
  }
  switch (action.type) {
    case "GET_DASHBOARD_TOP_PRODUCTS_SUCCESS":
    case "GET_DASHBOARD_TOP_PRODUCTS_ERROR":
      return {
        ...state,
        GetDashboardTopProductsListResponse: action.updatePayload,
      };
    case "GET_DASHBOARD_UNIT_SOLD_CHART_SUCCESS":
    case "GET_DASHBOARD_UNIT_SOLD_CHART_ERROR":
      return {
        ...state,
        GetDashboardUnitSoldChartResponse: action.updatePayload,
      };
    case "GET_DASHBOARD_SALES_DATA_SUCCESS":
    case "GET_DASHBOARD_SALES_DATA_ERROR":
      return {
        ...state,
        GetDashboardSalesDataResponse: action.updatePayload,
      };
    // case "GET_SALES_TABLE_DATA_SUCCESS":
    // case "GET_SALES_TABLE_DATA_ERROR":
    //   return {
    //     ...state,
    //     GetSalesAnalyticsTableDataResponse: action.updatePayload,
    //   };
    // case "GET_SALES_CHANNEL_LIST_DATA_SUCCESS":
    // case "GET_SALES_CHANNEL_LIST_DATA_ERROR":
    //   return {
    //     ...state,
    //     GetSalesChannelListResponse: action.updatePayload,
    //   };

    case "FAKE_ACTION_DASHBOARD":
      return { ...state, [action?.state]: {} };
    default:
      return state;
  }
};
