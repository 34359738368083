import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SalesAnalytics from "../../modules/pages/sales-analytics";

import {
  GetSalesAnalyticsCardData,
  GetSalesOverviewChart,
  GetOrderOverviewChart,
  GetSalesAnalyticsTableData,
  GetSalesChannelList,
  fakeActionSalesAnalytics,
  GetWalmartSalesAnalyticsCardData,
  GetWalmartSalesOverviewChart,
  GetWalmartOrderOverviewChart,
  GetWalmartSalesAnalyticsTableData,
} from "../../redux/modules/sales-analytics/sales-analytics.action";

import {
  GetDashboardUnitSoldChart,
  fakeActionDashboard,
} from "../../redux/modules/dashboard/dashboard.action";

const mapStateToProps = (state) => ({
  GetSalesAnalyticsCardDataResponse:
    state.SalesAnalytics.GetSalesAnalyticsCardDataResponse,
  GetSalesAnalyticsSalesOverviewChartResponse:
    state.SalesAnalytics.GetSalesAnalyticsSalesOverviewChartResponse,
  GetSalesAnalyticsOrderOverviewChartResponse:
    state.SalesAnalytics.GetSalesAnalyticsOrderOverviewChartResponse,
  GetSalesAnalyticsTableDataResponse:
    state.SalesAnalytics.GetSalesAnalyticsTableDataResponse,
  GetSalesChannelListResponse: state.SalesAnalytics.GetSalesChannelListResponse,

  GetWalmartSalesAnalyticsCardDataResponse:
    state.SalesAnalytics.GetWalmartSalesAnalyticsCardDataResponse,
  GetWalmartSalesAnalyticsSalesOverviewChartResponse:
    state.SalesAnalytics.GetWalmartSalesAnalyticsSalesOverviewChartResponse,
  GetWalmartSalesAnalyticsOrderOverviewChartResponse:
    state.SalesAnalytics.GetWalmartSalesAnalyticsOrderOverviewChartResponse,
  GetWalmartSalesAnalyticsTableDataResponse:
    state.SalesAnalytics.GetWalmartSalesAnalyticsTableDataResponse,

  GetDashboardUnitSoldChartResponse:
    state.Dashboard.GetDashboardUnitSoldChartResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      GetSalesAnalyticsCardData,
      GetSalesOverviewChart,
      GetOrderOverviewChart,
      GetSalesAnalyticsTableData,
      GetSalesChannelList,
      fakeActionSalesAnalytics,

      GetDashboardUnitSoldChart,
      fakeActionDashboard,

      GetWalmartSalesAnalyticsCardData,
      GetWalmartSalesOverviewChart,
      GetWalmartOrderOverviewChart,
      GetWalmartSalesAnalyticsTableData,
    },
    dispatch
  );

const SalesAnalyticsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SalesAnalytics);

export default SalesAnalyticsContainer;
