import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ReportSchedule from "../../../modules/pages/admin/report-schedule";
// import {
//   GetUserList,
//   SwitchUserAction,
//   AddUserAction,
//   DeleteUserAction,
//   fakeActionManageUser,
// } from "../../../redux/modules/admin/manage-user/manage-user.action";

const mapStateToProps = (state) => ({
  //   GetUserListResponse: state.Auth.GetUserListResponse,
  //   SwitchUserResponse: state.Auth.SwitchUserResponse,
  //   AddUserResponse: state.Auth.AddUserResponse,
  //   DeleteUserResponse: state.Auth.DeleteUserResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      //   GetUserList,
      //   SwitchUserAction,
      //   AddUserAction,
      //   DeleteUserAction,
      //   fakeActionManageUser,
    },
    dispatch
  );

const ReportScheduleContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportSchedule);

export default ReportScheduleContainer;
