import { SearchOutlined } from "@ant-design/icons";
import { Input, Popover, Radio, message } from "antd";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import Loading from "../../../../../component/loading";
import NoData from "../../../../../component/no-data";
import { NumberWithCommas } from "../../../../../config";
import { Link } from "react-router-dom/cjs/react-router-dom";

export default function (props) {
  const { show, onHide, GetGlobalSearchProducts, fakeActionSkuData } = props;

  const GetGlobalSearchProductListRes = useSelector(
    (state) => state.SKUData.GetGlobalSearchProductListResponse || {}
  );

  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [productList, setProductList] = useState([]);
  const [searchType, setSearchType] = useState("query");
  const onChangeSearchType = (e) => {
    setSearchType(e.target.value);
  };

  useEffect(() => {
    if (GetGlobalSearchProductListRes.status === true) {
      console.log(
        "GetGlobalSearchProductListRes",
        GetGlobalSearchProductListRes
      );
      setLoading(false);
      const finalArray = GetGlobalSearchProductListRes?.data?.items?.map(
        (d) => ({
          ...d,
          main_category: d?.properties?.categories?.[0],
        })
      );
      const getMainCategory = finalArray?.map((d) => d?.main_category);
      const uniqueMainCategory = [...new Set(getMainCategory)];
      const makeNewArrayUsingFinalCategory = uniqueMainCategory?.map((d) => {
        const catLabel = d;
        const catarray = finalArray?.filter(
          (d) => d?.main_category === catLabel
        );
        return {
          cat_label: catLabel,
          all_prod: catarray,
        };
      });
      setProductList(makeNewArrayUsingFinalCategory);
      // setProductList(GetGlobalSearchProductListRes?.data?.items);
      fakeActionSkuData("GetGlobalSearchProductListResponse");
    } else if (GetGlobalSearchProductListRes.status === false) {
      message.destroy();
      message.error(
        GetGlobalSearchProductListRes?.data?.error?.[0]?.description
      );
      setLoading(false);
      fakeActionSkuData("GetGlobalSearchProductListResponse");
    }
  }, [GetGlobalSearchProductListRes]);

  const columns = [
    {
      title: "Item ID",
      dataIndex: "itemId",
      key: "itemId",
      width: 100,
    },
    {
      title: "Image",
      dataIndex: "images",
      key: "images",
      width: 60,
      render: (images) => (
        <img src={images?.[0]?.url} alt="Product" width={50} />
      ),
    },
    {
      title: "Categories",
      dataIndex: ["properties", "categories"],
      key: "categories",
      width: 150,
      render: (categories) => {
        return categories?.join("  /  ");
      },
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      width: 60,
      render: (price) => `${price?.amount || 0}`,
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: 150,
      render: (item) => {
        return (
          <Popover
            content={
              <div
                style={{
                  height: "max-content",
                  width: "auto",
                }}
              >
                {item}
              </div>
            }
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
            placement="bottom"
          >
            <div
              className="text-primary mb-1 fw-bolder text-one fs-5 popoverActionIconSingleLine"
              style={{ minWidth: "100px" }}
            >
              {item}
            </div>
          </Popover>
        );
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: 200,
      render: (description) => (
        <div dangerouslySetInnerHTML={{ __html: description }} />
      ),
    },
    {
      title: "Brand",
      dataIndex: "brand",
      key: "brand",
      width: 100,
    },
    {
      title: "Product Type",
      dataIndex: "productType",
      key: "productType",
      width: 100,
    },
    {
      title: "Next Day Eligible",
      dataIndex: ["properties", "next_day_eligible"],
      key: "next_day_eligible",
      width: 100,
      render: (nextDayEligible) => (nextDayEligible ? "Yes" : "No"),
    },
  ];

  return (
    <Modal show={show} centered onHide={onHide} backdrop="static" size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Global Search</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div className="row">
            <div className="col-12 d-flex">
              <div className="me-4">
                <label className="fw-bolder fs-5">Search By : </label>
              </div>
              <div>
                <Radio.Group onChange={onChangeSearchType} value={searchType}>
                  <Radio value={"gtin"} className="fw-bold">
                    GTIN
                  </Radio>
                  <Radio value={"upc"} className="fw-bold">
                    UPC
                  </Radio>
                  <Radio value={"query"} className="fw-bold">
                    Product Name / Brand Name
                  </Radio>
                </Radio.Group>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-7 d-flex mt-4">
              <Input
                type="text"
                className="ant_common_input"
                size="large"
                placeholder={`Search by ${
                  searchType === "query"
                    ? "Product Name"
                    : searchType === "upc"
                    ? "UPC"
                    : "GTIN"
                }`}
                value={searchValue}
                onChange={(e) => {
                  setSearchValue(e.target.value);
                }}
                onPressEnter={(e) => {
                  if (e.target.value) {
                    setLoading(true);
                    GetGlobalSearchProducts({
                      search: searchValue,
                      type: searchType,
                    });
                  } else {
                    message.destroy();
                    message.warning(
                      `Please enter ${
                        searchType === "query"
                          ? "Product Name"
                          : searchType === "upc"
                          ? "UPC"
                          : "GTIN"
                      }`
                    );
                  }
                }}
                prefix={
                  <SearchOutlined style={{ fontSize: "19px", color: "grey" }} />
                }
              />
            </div>
            <div className="col-5 d-flex mt-4">
              <button
                className="btn btn-primary"
                onClick={() => {
                  setLoading(true);
                  GetGlobalSearchProducts({
                    search: searchValue,
                    type: searchType,
                  });
                }}
                disabled={!searchValue}
              >
                Search
              </button>
            </div>
          </div>
          <div
            className="row mt-5"
            style={{ height: "600px ", overflowY: "scroll" }}
          >
            {loading ? (
              <Loading style={{ height: "600px" }} />
            ) : productList?.length !== 0 ? (
              productList?.map((d) => {
                return (
                  <div>
                    <div className="row mb-6">
                      <div className="col-12">
                        <label className="fw-bolder fs-1">
                          {d?.cat_label}{" "}
                          <span
                            className="fw-normal"
                            style={{ fontSize: "17px", color: "#74767c" }}
                          >{`(${d?.all_prod?.length})`}</span>
                        </label>
                      </div>
                    </div>
                    <div className="row px-0 mx-0">
                      <div
                        className="col-12 d-flex pb-5"
                        style={{ overflowX: "scroll" }}
                      >
                        {d?.all_prod?.map((ap) => {
                          return (
                            <div
                              className="mx-4 cursor-pointer"
                              style={{ width: "250px" }}
                              onClick={() => {
                                window.open(
                                  `https://www.walmart.com/search?q=${ap?.itemId}`
                                );
                              }}
                            >
                              <div>
                                <img
                                  src={ap?.images?.[0]?.url}
                                  alt="Product"
                                  style={{
                                    height: "100px",
                                    width: "100px",
                                    objectFit: "contain",
                                  }}
                                />
                              </div>
                              <div className="mt-2">
                                <label>
                                  <label className="fw-bold fs-5">$</label>
                                  <label className="fw-bold fs-3">
                                    {NumberWithCommas(ap?.price?.amount || 0)}
                                  </label>
                                </label>
                              </div>
                              <div className="mt-2">
                                <Popover
                                  content={
                                    <div
                                      style={{
                                        height: "max-content",
                                        width: "300px",
                                      }}
                                    >
                                      {ap?.title}
                                    </div>
                                  }
                                  getPopupContainer={(triggerNode) =>
                                    triggerNode.parentNode
                                  }
                                  placement="right"
                                >
                                  <div
                                    className="mb-1 text-one fs-5 popoverActionIcon"
                                    style={{
                                      minWidth: "250px",
                                      color: "#666666",
                                      minHeight: "50px",
                                    }}
                                  >
                                    {ap?.title}
                                  </div>
                                </Popover>
                              </div>
                              <div className="mt-2 fw-bold">
                                Brand : {ap?.brand}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div className="separator separator-dashed my-10" />
                  </div>
                );
              })
            ) : (
              <NoData type="new" />
            )}

            {/* {loading ? (
              <Loading style={{ height: "300px" }} />
            ) : productList?.length !== 0 ? (
              <IvcsrTable
                columns={columns}
                dataSource={productList}
                pagination={true}
                scroll={{ x: 1800, y: 500 }}
              />
            ) : (
              <NoData type="new" />
            )} */}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-light-danger" onClick={onHide}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
}
