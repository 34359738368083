import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import BidRuleDetails from "../../../../modules/pages/advertisement/rule-management/walmart-rule-lib/rule-details";

import {
  GetWalmartBidRuleDetails,
  CreateWalmartRuleAction,
  GetWalmartCreateRuleAttribute,
  GetWalmartBidRuleCampaignList,
  GetWalmartBidRuleAdGroupList,
  fakeActionWalmartBidManagement,
} from "../../../../redux/modules/advertisement/rule-management/walmart-bid/walmart-rule-action";

const mapStateToProps = (state) => ({
  GetWalmartBidRuleDetailsResponse:
    state.WalmartBidManagement.GetWalmartBidRuleDetailsResponse,
  GetWalmartCreateRuleAttributeResponse:
    state.WalmartBidManagement.GetWalmartCreateRuleAttributeResponse,
  GetWalmartBidRuleCampaignListResponse:
    state.WalmartBidManagement.GetWalmartBidRuleCampaignListResponse,
  GetWalmartBidRuleAgGroupListResponse:
    state.WalmartBidManagement.GetWalmartBidRuleAgGroupListResponse,
  CreateWalmartRuleActionResponse:
    state.WalmartBidManagement.CreateWalmartRuleActionResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      GetWalmartBidRuleDetails,
      CreateWalmartRuleAction,
      GetWalmartCreateRuleAttribute,
      GetWalmartBidRuleCampaignList,
      GetWalmartBidRuleAdGroupList,
      fakeActionWalmartBidManagement,
    },
    dispatch
  );

const BidRuleDetailsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(BidRuleDetails);

export default BidRuleDetailsContainer;
