import { Menu } from "antd";
import React, { useEffect, useState, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  AppstoreOutlined,
  ContainerOutlined,
  DesktopOutlined,
  MailOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  PieChartOutlined,
} from "@ant-design/icons";
import { BackToAdminWrapper, SidebarWrapper } from "./style";
import { convertSidebarLinks, nameObject } from "./lib/sidebar-config";
import { LogoutOutlined } from "@ant-design/icons";
import { GlobalContext } from "../../../commonContext";

export default function (props) {
  const { userType, isCollapsed, setIsCollapsed, isBackToAdmin, mobileStatus } =
    props;

  const [openKeys, setOpenKeys] = useState([]);
  const history = useHistory();
  const location = useLocation();

  const contextVar = useContext(GlobalContext);

  const [locationPath, setLocationPath] = useState("");
  const [selectedKeys, setSelectedKeys] = useState(["dashboard"]);

  // useEffect(() => {
  //   const newLoca = location.pathname.split("/");
  //   setLocationPath(newLoca[1]);
  // }, [location]);

  useEffect(() => {
    if (location?.pathname) {
      // console.log(location?.pathname === "/", "location?.pathname");
      if (location?.pathname === "/") {
        return setSelectedKeys(["dashboard"]);
      }
      setIsCollapsed(false);
      const currantMenu = location?.pathname?.split("/");
      // console.log(location?.pathname, currantMenu, "currantMenu-------------");
      // console.log(
      //   nameObject?.[location?.pathname]?.isSubMenu,
      //   "nameObject?.[location?.pathname]?.isSubMenu----------------"
      // );
      if (nameObject?.[location?.pathname]?.isSubMenu === 1) {
        setSelectedKeys([currantMenu?.[currantMenu?.length - 1]]);
      } else {
        setSelectedKeys([currantMenu?.[1]]);
      }
    }
    return () => {};
  }, [location?.pathname]);

  const defaultSubMenuSelected = () => {
    if (isCollapsed) {
      return [];
    }
    let subMenuPath = window.location.pathname.split("/");
    if (subMenuPath.length == 3) {
      return [subMenuPath?.[1]];
    }
    return [];
  };

  const backToAdmin = () => {
    let adminDataLocal = localStorage.getItem("adminData");
    if (adminDataLocal) {
      adminDataLocal = JSON.parse(adminDataLocal);
      localStorage.setItem("user", adminDataLocal.data);
      localStorage.setItem(
        "userType",
        JSON.parse(adminDataLocal?.data)?.user_type
      );
      localStorage.setItem("token", adminDataLocal.token);
      localStorage.removeItem("adminData");
      contextVar?.updateCommonGlobalVal({
        user_photo: "",
        user_data: JSON.parse(adminDataLocal.data),
      });
    }
  };

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    console.log(latestOpenKey, "latestOpenKey");
    setOpenKeys([latestOpenKey]);
    // if (latestOpenKey && rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
    //   setOpenKeys(keys);
    // } else {
    //   setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    // }
  };
  return (
    <SidebarWrapper>
      <div
        id="kt_app_sidebar"
        className={`app-sidebar ${
          mobileStatus && "drawer"
        } flex-column fadeInLeft`}
        // ${userType == 1 && "fadeInBottom"}
        // ${userType != 1 && "fadeInLeft"}`}
        data-kt-drawer="true"
        data-kt-drawer-name="app-sidebar"
        data-kt-drawer-activate="{default: true, lg: false}"
        data-kt-drawer-overlay="true"
        data-kt-drawer-width="200px"
        data-kt-drawer-direction="start"
        data-kt-drawer-toggle="#kt_app_sidebar_mobile_toggle"
      >
        <div
          className="app-sidebar-header d-flex flex-stack d-none d-lg-flex pt-6 pb-7 justify-content-center border-bottom border-bottom-dashed border-gray-300"
          id="kt_app_sidebar_header"
        >
          {/*begin::Logo*/}
          <span
            className="app-sidebar-logo"
            onClick={() => {
              history.replace("/dashboard");
            }}
          >
            <img
              alt="Logo"
              src="/assets/media/all-images/ivcsr-logo-new.png"
              className="h-35px d-none d-sm-inline app-sidebar-logo-default theme-light-show shimmer"
            />
            {/* <img alt="Logo" src="/assets/media/logos/demo38-dark.svg" class="h-20px h-lg-25px theme-dark-show" /> */}
          </span>
          {/*end::Logo*/}
          {/*begin::Sidebar toggle*/}
          {/* <div id="kt_app_sidebar_toggle" class="app-sidebar-toggle btn btn-sm btn-icon bg-light btn-color-gray-700 btn-active-color-primary d-none d-lg-flex rotate" data-kt-toggle="true" data-kt-toggle-state="active" data-kt-toggle-target="body" data-kt-toggle-name="app-sidebar-minimize">
            <i class="ki-outline ki-text-align-right rotate-180 fs-1"></i>
          </div> */}
          {/*end::Sidebar toggle*/}
        </div>
        {/*begin::Navs*/}
        <div
          className="app-sidebar-navs flex-column-fluid py-6"
          id="kt_app_sidebar_navs"
        >
          <div className="text-center">
            <div>
              <div
                className="cursor-pointer symbol symbol-circle symbol-45px symbol-md-75px"
                style={{
                  border: "1px solid var(--bs-primary)",
                  padding: "5px",
                }}
              >
                {contextVar?.data?.user_photo ? (
                  <img
                    src={contextVar?.data?.user_photo}
                    style={{ height: "60px", width: "60px" }}
                  />
                ) : (
                  <div
                    style={{
                      height: "60px",
                      width: "60px",
                      background: "#5058ce",
                      borderRadius: "30px",
                      color: "rgb(255, 255, 255)",
                      fontWeight: "400",
                      fontSize: "38px",
                      boxShadow: "rgb(137 146 212) 0px -50px 36px -28px inset",
                    }}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <span
                      style={{
                        textTransform: "uppercase",
                      }}
                    >
                      {userType == 1 ? (
                        <img
                          src="/assets/media/admin-user.png"
                          style={{ width: "43px" }}
                        />
                      ) : (
                        JSON.parse(localStorage.getItem("user"))?.name?.charAt(
                          0
                        )
                      )}
                    </span>
                  </div>
                )}

                {/* <span class="svg-icon svg-icon-muted svg-icon-2hx">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.28548 15.0861C7.34369 13.1814 9.35142 12 11.5304 12H12.4696C14.6486 12 16.6563 13.1814 17.7145 15.0861L19.3493 18.0287C20.0899 19.3618 19.1259 21 17.601 21H6.39903C4.87406 21 3.91012 19.3618 4.65071 18.0287L6.28548 15.0861Z"
                      fill="currentColor"
                    />
                    <rect
                      opacity="0.3"
                      x="8"
                      y="3"
                      width="8"
                      height="8"
                      rx="4"
                      fill="currentColor"
                    />
                  </svg>
                </span> */}
              </div>
            </div>
            <div>
              <div className="d-flex flex-column mt-3">
                <div className="fw-bold d-flex align-items-center fs-5 justify-content-center">
                  {/* {JSON.parse(localStorage.getItem("user"))?.name} */}
                  {contextVar?.data?.user_data?.name}
                </div>
                <span className="fw-semibold text-muted text-hover-primary fs-7">
                  {contextVar?.data?.user_data?.email}
                </span>
              </div>
            </div>
            {localStorage.getItem("adminData") && (
              <div className="mt-3">
                <BackToAdminWrapper className="d-flex justify-content-center">
                  <button
                    class="btn"
                    onClick={() => {
                      backToAdmin();
                      history.replace("/manage-user");
                    }}
                  >
                    <div>Back To Admin</div>
                  </button>
                </BackToAdminWrapper>
              </div>
            )}
          </div>
          {/* <div class="separator separator-dashed" ></div> */}
          <div
            id="kt_app_sidebar_navs_wrappers"
            className="app-sidebar-wrapper hover-scroll-y my-2"
            data-kt-scroll="true"
            data-kt-scroll-activate="true"
            data-kt-scroll-height="auto"
            data-kt-scroll-dependencies="#kt_app_sidebar_header"
            data-kt-scroll-wrappers="#kt_app_sidebar_navs"
            data-kt-scroll-offset="5px"
            style={{ height: `calc(100vh - (160px + 100px))` }}
          >
            {/*begin::Sidebar menu*/}
            <div
              id="#kt_app_sidebar_menu"
              data-kt-menu="true"
              data-kt-menu-expand="false"
              className="app-sidebar-menu-primary menu menu-column menu-rounded menu-sub-indention menu-state-bullet-primary mt-5"
            >
              {/*begin::Heading*/}
              <Menu
                selectedKeys={selectedKeys}
                mode="inline"
                openKeys={openKeys}
                onOpenChange={onOpenChange}
                inlineCollapsed={isCollapsed}
                items={convertSidebarLinks(
                  userType == 1 ? "admin" : "user",
                  contextVar
                )}
                onClick={(e) => {
                  setSelectedKeys(e?.key);
                }}
                defaultOpenKeys={defaultSubMenuSelected()}
              />
              {/*end::Heading*/}
            </div>
            {/*end::Sidebar menu*/}
          </div>
          <div className="d-flex justify-content-center align-items-center">
            <button
              class="btnLogout"
              onClick={() => {
                localStorage.clear();
                // history.replace("/login");
                window.location.href = "/login";
              }}
            >
              <span class="iconLogout">
                <LogoutOutlined />
              </span>
              <span class="textLogout">Logout</span>
            </button>
            {/* <button
              className="btn customLogoutBtn"
              onClick={() => {
                localStorage.clear();
                // history.replace("/login");
                window.location.href = "/login";
              }}
              style={{
                // boxShadow:
                //   "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset",
                backgroundColor: "#4318ffc7",
                borderRadius: "8px",
                fontSize: "16px",
                color: "#ffffff",
                padding: "8px 41px",
                boxShadow: "0px 4px 22px -3px #7154ff",
              }}
            >
              <LogoutOutlined className="me-3" /> Logout
            </button> */}
          </div>
        </div>
        {/*end::Navs*/}
      </div>
    </SidebarWrapper>
  );
}
