import {
  ContactsOutlined,
  EnvironmentOutlined,
  MailOutlined,
  PhoneOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Input, message } from "antd";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";

export default function (props) {
  const {
    show,
    onHide,
    SaveSupplierAction,
    selectedSupplier,
    onSuccess,
    fakeActionSupplier,
  } = props;

  const SaveSupplierRes = useSelector(
    (state) => state.Supplier.SaveSupplierResponse || {}
  );

  const [supplierId, setSupplierId] = useState("");
  const [isUpdate, setIsUpdate] = useState(true);
  const [isValidate, setIsValidate] = useState(true);
  const [supplierDetails, setSupplierDetails] = useState({
    supplier_name: "",
    contact_name: "",
    email: "",
    phone_number: "",
    address: "",
  });

  useEffect(() => {
    console.log(selectedSupplier, "selectedSupplier");
    const newObj = { ...supplierDetails };
    newObj.supplier_name = selectedSupplier?.supplier_name || "";
    newObj.contact_name = selectedSupplier?.contact_name || "";
    newObj.email = selectedSupplier?.email || "";
    newObj.phone_number = selectedSupplier?.phone_number || "";
    newObj.address = selectedSupplier?.address || "";

    setSupplierDetails(newObj);
    setSupplierId(selectedSupplier?.id);
  }, [selectedSupplier]);

  useEffect(() => {
    if (SaveSupplierRes.status === true) {
      message.destroy();
      message.success(SaveSupplierRes.message);
      onSuccess();
      fakeActionSupplier("SaveSupplierResponse");
    } else if (SaveSupplierRes.status === false) {
      message.destroy();
      message.error(SaveSupplierRes.message);
      fakeActionSupplier("CreateSu pplierResponse");
    }
  }, [SaveSupplierRes]);

  const onChangeInput = (key, value) => {
    setIsUpdate(false);
    console.log(key, value);
    setSupplierDetails({ ...supplierDetails, [key]: value });
  };

  const submit = () => {
    if (isValidate) {
      message.destroy();
      message.loading("Loading...", 0);
      console.log(supplierDetails, "supplierDetails-----------------------");
      SaveSupplierAction(supplierId, supplierDetails);
    } else {
      message.destroy();
      message.error("Enter Valid Email");
      return;
    }
  };

  return (
    <Modal show={show} centered onHide={onHide} backdrop="static" size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Supplier Setup</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-12 col-md-6 my-4">
            <label className="fw-bold fs-5 mb-2">Supplier Name</label>
            <Input
              value={supplierDetails?.supplier_name}
              onChange={(e) => {
                onChangeInput("supplier_name", e.target.value);
              }}
              size="large"
              className="ant_common_input"
              placeholder="Enter Supplier Name"
              prefix={
                <UserOutlined
                  style={{
                    color: "#e76dc3",
                    marginRight: "5px",
                    fontSize: "18px",
                  }}
                />
              }
            />
          </div>{" "}
          <div className="col-12 col-md-6 my-4">
            <label className="fw-bold fs-5 mb-2">Supplier Contact Name</label>
            <Input
              value={supplierDetails?.contact_name}
              onChange={(e) => {
                if (!e.target.value) {
                  onChangeInput("contact_name", e.target.value);
                }
                const validation = /^[a-zA-Z ]+$/.test(e.target.value);
                if (validation) {
                  onChangeInput("contact_name", e.target.value);
                }
              }}
              size="large"
              className="ant_common_input"
              placeholder="Enter Contact Name"
              prefix={
                <ContactsOutlined
                  style={{
                    color: "#e76dc3",
                    marginRight: "5px",
                    fontSize: "18px",
                  }}
                />
              }
            />
          </div>
          <div className="col-12 col-md-6 my-4">
            <label className="fw-bold fs-5 mb-2">Supplier Email</label>
            <Input
              value={supplierDetails?.email}
              onChange={(e) => {
                onChangeInput("email", e.target.value);
              }}
              onBlur={(e) => {
                const validation = /^[\w-\\.]+@([\w-]+\.)+[a-zA-Z]{2,4}$/.test(
                  e.target.value
                );
                if (!validation) {
                  setIsValidate(false);
                } else {
                  setIsValidate(true);
                }
              }}
              size="large"
              className="ant_common_input"
              placeholder="Enter Email"
              prefix={
                <MailOutlined
                  style={{
                    color: "#e76dc3",
                    marginRight: "5px",
                    fontSize: "18px",
                  }}
                />
              }
            />
          </div>
          <div className="col-12 col-md-6 my-4">
            <label className="fw-bold fs-5 mb-2">Phone Number</label>
            <Input
              value={supplierDetails?.phone_number}
              onChange={(e) => {
                if (!e.target.value) {
                  onChangeInput("phone_number", e.target.value);
                }
                const validation = /^[0-9]+$/.test(e.target.value);
                if (validation) {
                  onChangeInput("phone_number", e.target.value);
                }
              }}
              size="large"
              className="ant_common_input"
              placeholder="Enter Phone Number"
              prefix={
                <PhoneOutlined
                  style={{
                    color: "#e76dc3",
                    marginRight: "5px",
                    fontSize: "18px",
                    transform: "rotate(85deg)",
                  }}
                />
              }
            />
          </div>
          <div className="col-12  my-4">
            <label className="fw-bold fs-5 mb-2">Supplier Address</label>
            <Input
              value={supplierDetails?.address}
              onChange={(e) => {
                onChangeInput("address", e.target.value);
                //   setData({ ...data, name: e.target.value });
              }}
              size="large"
              className="ant_common_input"
              placeholder="Enter Address"
              prefix={
                <EnvironmentOutlined
                  style={{
                    color: "#e76dc3",
                    marginRight: "5px",
                    fontSize: "18px",
                  }}
                />
              }
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-light-danger" onClick={onHide}>
          Close
        </button>

        <button
          onClick={() => {
            submit();
          }}
          type="button"
          className="btn btn-primary px-5"
          disabled={
            Object.values(supplierDetails)?.filter((d) => d === "")?.length !==
              0 || isUpdate
          }
        >
          Submit
          {/* {loading && (
            <Spinner
              animation="border"
              style={{ marginLeft: "10px" }}
              size="sm"
              className="ml-1"
            />
          )} */}
        </button>
      </Modal.Footer>
    </Modal>
  );
}
