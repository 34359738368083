import React, { useEffect, useState } from "react";
import Loading from "../../../../component/loading";
import IvcsrTable from "../../../../component/ivcsr-table";
import NoData from "../../../../component/no-data";
import Pagination from "../../../../component/pagination";
import { DefaultPerPage } from "../../../../config";
import { Popconfirm, Popover, Spin, Switch, Tag, Tooltip, message } from "antd";
import {
  LoadingOutlined,
  ExclamationCircleOutlined,
  CopyTwoTone,
  DeleteTwoTone,
  EditTwoTone,
} from "@ant-design/icons";
import { useSelector } from "react-redux";
const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
    }}
    spin
  />
);
export default function (props) {
  const { GetRepricerStrategies, fakeActionRepricerListing } = props;

  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageSize, setPageSize] = useState(DefaultPerPage);
  const [ruleWithProduct, setRuleWithProduct] = useState({});
  const [statusId, setStatusId] = useState("");
  const [modalType, setModalType] = useState("add");
  const [addNewRepricer, setAddNewRepricer] = useState(false);
  const [selectedRule, setSelectedRule] = useState({});

  const GetRepricingStrategiesRes = useSelector(
    (state) => state.RepricerProductListing.GetRepricingStrategiesResponse || {}
  );

  useEffect(() => {
    GetRepricerStrategies();
  }, []);

  useEffect(() => {
    if (GetRepricingStrategiesRes.status === true) {
      // setList(GetRepricingStrategiesRes?.data?.records || []);
      setList([
        {
          repricerStrategy: "Strategy 1",
          strategyCollectionId: "Collection 123",
          enabled: "Yes",
          assignedCount: 5,
          enableRepricerForPromotion: "Yes",
          strategyType: "Type A",
          adjustmentType: "Percentage",
          adjustmentValue: 10,
        },
        {
          repricerStrategy: "Strategy 2",
          strategyCollectionId: "Collection 456",
          enabled: "No",
          assignedCount: 3,
          enableRepricerForPromotion: "No",
          strategyType: "Type B",
          adjustmentType: "Fixed",
          adjustmentValue: 5,
        },
      ]);
      setLoading(false);
      setTotalPage(GetRepricingStrategiesRes?.data?.pagination?.totalCount);
      fakeActionRepricerListing("GetRepricingStrategiesResponse");
    } else if (GetRepricingStrategiesRes.status === false) {
      setLoading(false);
      fakeActionRepricerListing("GetRepricingStrategiesResponse");
    }
  }, [GetRepricingStrategiesRes]);

  const onPageNo = (e) => {
    // setLoading(true);
    // const apiObj = {
    //   page: e,
    //   perPage: pageSize,
    //   searchKey: searchTerm,
    //   marketplace_id: selectedSalesChannel,
    //   supplier_id: selectedSupplier,
    //   forecast_days: noOfDaysToReplenish,
    //   isExport: 0,
    // };
    // GetInventoryManagementList(apiObj);
    setPage(e);
  };

  const onPerPage = (e) => {
    setPage(1);
    setPageSize(e);
    // setLoading(true);
    // const apiObj = {
    //   page: 1,
    //   perPage: e,
    //   searchKey: searchTerm,
    //   marketplace_id: selectedSalesChannel,
    //   supplier_id: selectedSupplier,
    //   forecast_days: noOfDaysToReplenish,
    //   isExport: 0,
    // };
    // GetInventoryManagementList(apiObj);
  };

  const columns = [
    {
      title: "Repricer Strategy",
      dataIndex: "repricerStrategy",
      key: "repricerStrategy",
    },
    {
      title: "Strategy Collection ID",
      dataIndex: "strategyCollectionId",
      key: "strategyCollectionId",
    },
    {
      title: "Enabled",
      dataIndex: "enabled",
      key: "enabled",
    },
    {
      title: "Assigned Count",
      dataIndex: "assignedCount",
      key: "assignedCount",
    },
    {
      title: "Enable Repricer For Promotion",
      dataIndex: "enableRepricerForPromotion",
      key: "enableRepricerForPromotion",
    },
    {
      title: "Strategy Type",
      dataIndex: "strategyType",
      key: "strategyType",
    },
    {
      title: "Adjustment Type",
      dataIndex: "adjustmentType",
      key: "adjustmentType",
    },
    {
      title: "Adjustment Value",
      dataIndex: "adjustmentValue",
      key: "adjustmentValue",
    },
  ];

  return (
    <>
      <div className="d-flex flex-column flex-column-fluid fadeInRight">
        <div id="kt_app_content" className="app-content flex-column-fluid">
          <div
            id="kt_app_content_container"
            className="app-container container-fluid"
          >
            <div className="row gy-5 g-xl-5">
              <div className="col-xxl-12">
                <div className="card card-xxl-stretch mb-5 mb-xl-8">
                  <div className="card-header border-bottom-dashed">
                    <h3 className="card-title ">
                      <span className="card-label fw-bold text-gray-900">
                        Repricer
                      </span>
                    </h3>

                    <div className="card-toolbar gap-2">
                      <div className="position-relative">
                        <span
                          onClick={() => {
                            setAddNewRepricer(true);
                            setModalType("add");
                          }}
                          className="btn btn-primary fw-bolder"
                        >
                          Add Rule
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="card-body pb-5 pt-0">
                    <div className="table-responsive mt-5">
                      {loading ? (
                        <Loading />
                      ) : list?.length !== 0 ? (
                        <>
                          <IvcsrTable
                            columns={columns}
                            fixed="top"
                            dataSource={list}
                            rowKey="key"
                            loading={loading}
                            pagination={false}
                            scroll={{ x: "max-content" }}
                          />
                        </>
                      ) : (
                        <NoData type="new" />
                      )}
                    </div>
                    <Pagination
                      loading={loading || list?.length === 0}
                      pageSize={pageSize}
                      page={page}
                      totalPage={totalPage}
                      onPerPage={onPerPage}
                      onPageNo={onPageNo}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
