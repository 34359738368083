export const adminCentralLogEventStatus = [
  {
    label: "PENDING",
    value: "PENDING",
  },
  {
    label: "IN_PROGRESS",
    value: "IN_PROGRESS",
  },
  {
    label: "DONE",
    value: "DONE",
  },
  {
    label: "FAILED",
    value: "FAILED",
  },
  {
    label: "SCHEDULED",
    value: "SCHEDULED",
  },
  {
    label: "FATAL",
    value: "FATAL",
  },
  {
    label: "CANCELLED",
    value: "CANCELLED",
  },
  {
    label: "NOT_FOUND",
    value: "NOT_FOUND",
  },
  {
    label: "NO DATA",
    value: "NO DATA",
  },
];

export const skuDataSelectOption = [
  { value: "ca", label: "COGS added" },
  { value: "cna", label: "COGS not added" },
];

export const weekList = [
  {
    label: "Sunday",
    value: "Sunday",
    day_index: 0,
  },
  {
    label: "Monday",
    value: "Monday",
    day_index: 1,
  },
  {
    label: "Tuesday",
    value: "Tuesday",
    day_index: 2,
  },
  {
    label: "Wednesday",
    value: "Wednesday",
    day_index: 3,
  },
  {
    label: "Thursday",
    value: "Thursday",
    day_index: 4,
  },
  {
    label: "Friday",
    value: "Friday",
    day_index: 5,
  },
  {
    label: "Saturday",
    value: "Saturday",
    day_index: 6,
  },
];

export const daysList = [
  { label: "1", value: "01" },
  { label: "2", value: "02" },
  { label: "3", value: "03" },
  { label: "4", value: "04" },
  { label: "5", value: "05" },
  { label: "6", value: "06" },
  { label: "7", value: "07" },
  { label: "8", value: "08" },
  { label: "9", value: "09" },
  { label: "10", value: "10" },
  { label: "11", value: "11" },
  { label: "12", value: "12" },
  { label: "13", value: "13" },
  { label: "14", value: "14" },
  { label: "15", value: "15" },
  { label: "16", value: "16" },
  { label: "17", value: "17" },
  { label: "18", value: "18" },
  { label: "19", value: "19" },
  { label: "20", value: "20" },
  { label: "21", value: "21" },
  { label: "22", value: "22" },
  { label: "23", value: "23" },
  { label: "24", value: "24" },
  { label: "25", value: "25" },
  { label: "26", value: "26" },
  { label: "27", value: "27" },
  { label: "28", value: "28" },
  { label: "29", value: "29" },
  { label: "30", value: "30" },
  { label: "31", value: "31" },
];

export const monthsList = [
  { label: "January", value: "January" },
  { label: "February", value: "February" },
  { label: "March", value: "March" },
  { label: "April", value: "April" },
  { label: "May", value: "May" },
  { label: "June", value: "June" },
  { label: "July", value: "July" },
  { label: "August", value: "August" },
  { label: "September", value: "September" },
  { label: "October", value: "October" },
  { label: "November", value: "November" },
  { label: "December", value: "December" },
];
export const lookbackInto = [
  { label: "1", value: "1" },
  { label: "2", value: "2" },
  { label: "3", value: "3" },
  { label: "4", value: "4" },
  { label: "5", value: "5" },
  { label: "6", value: "6" },
  { label: "7", value: "7" },
  { label: "8", value: "8" },
  { label: "9", value: "9" },
  { label: "10", value: "10" },
  { label: "11", value: "11" },
  { label: "12", value: "12" },
  { label: "13", value: "13" },
  { label: "14", value: "14" },
  { label: "15", value: "15" },
  { label: "16", value: "16" },
  { label: "17", value: "17" },
  { label: "18", value: "18" },
  { label: "19", value: "19" },
  { label: "20", value: "20" },
  { label: "21", value: "21" },
  { label: "22", value: "22" },
  { label: "23", value: "23" },
  { label: "24", value: "24" },
  { label: "25", value: "25" },
  { label: "26", value: "26" },
  { label: "27", value: "27" },
  { label: "28", value: "28" },
  { label: "29", value: "29" },
  { label: "30", value: "30" },
  { label: "31", value: "31" },
  { label: "32", value: "32" },
  { label: "33", value: "33" },
  { label: "34", value: "34" },
  { label: "35", value: "35" },
  { label: "36", value: "36" },
  { label: "37", value: "37" },
  { label: "38", value: "38" },
  { label: "39", value: "39" },
  { label: "40", value: "40" },
  { label: "41", value: "41" },
  { label: "42", value: "42" },
  { label: "43", value: "43" },
  { label: "44", value: "44" },
  { label: "45", value: "45" },
  { label: "46", value: "46" },
  { label: "47", value: "47" },
  { label: "48", value: "48" },
  { label: "49", value: "49" },
  { label: "50", value: "50" },
  { label: "51", value: "51" },
  { label: "52", value: "52" },
  { label: "53", value: "53" },
  { label: "54", value: "54" },
  { label: "55", value: "55" },
  { label: "56", value: "56" },
  { label: "57", value: "57" },
  { label: "58", value: "58" },
  { label: "59", value: "59" },
  { label: "60", value: "60" },
];

export const criteriaIfDd = [
  {
    value: "Budget",
    label: "Budget",
  },
  {
    value: "ROAS",
    label: "RoAS",
  },
  {
    value: "Sales/Budget",
    label: "Sales/Budget",
  },
  {
    value: "Budget-Spent",
    label: "Budget-Spent",
  },
  {
    value: "Campaign Status",
    label: "Campaign Status",
  },
  {
    value: "Spend/Budget",
    label: "Spend/Budget",
  },
  {
    value: "Status",
    label: "Status",
  },
  {
    value: "Hour of the day",
    label: "Hour of the day",
  },
  {
    value: "Weekday",
    label: "Weekday",
  },
];

export const categoriesList = [
  { label: "Cases and Bags", value: "cases_and_bags" },
  { label: "Building Supply", value: "building_supply" },
  { label: "Tires", value: "tires" },
  { label: "Computer Components", value: "computer_components" },
  {
    label: "Health and Beauty Electronics",
    value: "health_and_beauty_electronics",
  },
  { label: "Furniture Other", value: "furniture_other" },
  { label: "Decorations and Favors", value: "decorations_and_favors" },
  { label: "Hardware", value: "hardware" },
  { label: "Child Car Seats", value: "child_car_seats" },
  { label: "Food and Beverage Other", value: "food_and_beverage_other" },
  { label: "Electronics Other", value: "electronics_other" },
  { label: "Electronics Cables", value: "electronics_cables" },
  { label: "Plumbing and HVAC", value: "plumbing_and_hvac" },
  { label: "Video Games", value: "video_games" },
  { label: "Other Other", value: "other_other" },
  { label: "Safety and Emergency", value: "safety_and_emergency" },
  { label: "Jewelry Other", value: "jewelry_other" },
  { label: "Books and Magazines", value: "books_and_magazines" },
  { label: "Tools", value: "tools" },
  { label: "Sport and Recreation Other", value: "sport_and_recreation_other" },
  {
    label: "Carriers and Accessories Other",
    value: "carriers_and_accessories_other",
  },
  { label: "Animal Food", value: "animal_food" },
  { label: "Baby Toys", value: "baby_toys" },
  { label: "Cleaning and Chemical", value: "cleaning_and_chemical" },
  {
    label: "Ceremonial Clothing and Accessories",
    value: "ceremonial_clothing_and_accessories",
  },
  { label: "Music Cases and Bags", value: "music_cases_and_bags" },
  { label: "Computers", value: "computers" },
  { label: "Grills and Outdoor Cooking", value: "grills_and_outdoor_cooking" },
  { label: "Personal Care", value: "personal_care" },
  { label: "Bedding", value: "bedding" },
  { label: "Storage", value: "storage" },
  { label: "Animal Accessories", value: "animal_accessories" },
  { label: "Baby Food", value: "baby_food" },
  { label: "Electrical", value: "electrical" },
  { label: "Medical Aids", value: "medical_aids" },
  { label: "Music", value: "music" },
  { label: "Art and Craft Other", value: "art_and_craft_other" },
  { label: "Medicine and Supplements", value: "medicine_and_supplements" },
  { label: "Toys Other", value: "toys_other" },
  {
    label: "Wheels and Wheel Components",
    value: "wheels_and_wheel_components",
  },
  { label: "Footwear Other", value: "footwear_other" },
  { label: "TV Shows", value: "tv_shows" },
  { label: "Animal Health and Grooming", value: "animal_health_and_grooming" },
  { label: "Video Projectors", value: "video_projectors" },
  { label: "Cameras and Lenses", value: "cameras_and_lenses" },
  { label: "Sound and Recording", value: "sound_and_recording" },
  { label: "Watercraft", value: "watercraft" },
  { label: "Funeral", value: "funeral" },
  { label: "Watches Other", value: "watches_other" },
  { label: "Large Appliances", value: "large_appliances" },
  { label: "Baby Furniture", value: "baby_furniture" },
  { label: "Costumes", value: "costumes" },
  { label: "Instrument Accessories", value: "instrument_accessories" },
  { label: "Optical", value: "optical" },
  { label: "Home Other", value: "home_other" },
  { label: "Cycling", value: "cycling" },
  { label: "Gift Supply and Awards", value: "gift_supply_and_awards" },
  { label: "Fuels and Lubricants", value: "fuels_and_lubricants" },
  { label: "Baby Other", value: "baby_other" },
  { label: "Vehicle Other", value: "vehicle_other" },
  { label: "Animal Other", value: "animal_other" },
  { label: "Optics", value: "optics" },
  { label: "Garden and Patio Other", value: "garden_and_patio_other" },
  { label: "Cell Phones", value: "cell_phones" },
  { label: "Musical Instruments", value: "musical_instruments" },
  {
    label: "Printers, Scanners, and Imaging",
    value: "printers_scanners_and_imaging",
  },
  { label: "Movies", value: "movies" },
  { label: "Office Other", value: "office_other" },
  { label: "TVs and Video Displays", value: "tvs_and_video_displays" },
  { label: "Tools and Hardware Other", value: "tools_and_hardware_other" },
  { label: "Electronics Accessories", value: "electronics_accessories" },
  {
    label: "Vehicle Parts and Accessories",
    value: "vehicle_parts_and_accessories",
  },
  { label: "Land Vehicles", value: "land_vehicles" },
  { label: "Clothing Other", value: "clothing_other" },
  { label: "Photo Accessories", value: "photo_accessories" },
  { label: "Software", value: "software" },
];

export const ActivatiyFeesIsVisibleToList = [
  { label: "Animal Accessories", value: "Animal Accessories" },
  // { label: "Animal Food", value: "animal_food" },
  // { label: "Animal Health & Grooming", value: "animal_health_and_grooming" },
  // { label: "Animal Other", value: "animal_other" },
  // { label: "Art & Craft", value: "art_and_craft" },
  // {
  //   label: "Baby Diapering, Care, & Other",
  //   value: "baby_diapering_care_and_other",
  // },
  // { label: "Baby Food", value: "baby_food" },
  // { label: "Baby Furniture", value: "baby_furniture" },
  // { label: "Baby Toys", value: "baby_toys" },
  // { label: "Baby Transport", value: "baby_transport" },
  // {
  //   label: "Beauty, Personal Care, & Hygiene",
  //   value: "beauty_personal_care_and_hygiene",
  // },
  // { label: "Bedding", value: "bedding" },
  // { label: "Books & Magazines", value: "books_and_magazines" },
  // { label: "Building Supply", value: "building_supply" },
  // { label: "Cameras & Lenses", value: "cameras_and_lenses" },
  // { label: "Carriers & Accessories", value: "carriers_and_accessories" },
  // { label: "Cases & Bags", value: "cases_and_bags" },
  // { label: "Cell Phones", value: "cell_phones" },
  // {
  //   label: "Ceremonial Clothing & Accessories",
  //   value: "ceremonial_clothing_and_accessories",
  // },
  // { label: "Clothing", value: "clothing" },
  // { label: "Computer Components", value: "computer_components" },
  // { label: "Computers", value: "computers" },
  // { label: "Costumes", value: "costumes" },
  // { label: "Cycling", value: "cycling" },
  // { label: "Decorations & Favors", value: "decorations_and_favors" },
  // { label: "Electrical", value: "electrical" },
  // { label: "Electronics Accessories", value: "electronics_accessories" },
  // { label: "Electronics Cables", value: "electronics_cables" },
  { label: "Electronics Other", value: "electronics_other" },
  // { label: "Food & Beverage", value: "food_and_beverage" },
  // { label: "Footwear", value: "footwear" },
  // { label: "Fuels & Lubricants", value: "fuels_and_lubricants" },
  // { label: "Funeral", value: "funeral" },
  // { label: "Furniture", value: "furniture" },
  // { label: "Garden & Patio", value: "garden_and_patio" },
  // { label: "Gift Supply & Awards", value: "gift_supply_and_awards" },
  // { label: "Grills & Outdoor Cooking", value: "grills_and_outdoor_cooking" },
  // { label: "Hardware", value: "hardware" },
  // {
  //   label: "Health & Beauty Electronics",
  //   value: "health_and_beauty_electronics",
  // },
  {
    label: "Home Decor, Kitchen, & Other",
    value: "Home Decor, Kitchen, & Other",
  },
  // {
  //   label: "Household Cleaning Products & Supplies",
  //   value: "household_cleaning_products_and_supplies",
  // },
  // { label: "Instrument Accessories", value: "instrument_accessories" },
  // { label: "Jewelry", value: "jewelry" },
  // { label: "Land Vehicles", value: "land_vehicles" },
  // { label: "Large Appliances", value: "large_appliances" },
  // { label: "Medical Aids & Equipment", value: "medical_aids_and_equipment" },
  // { label: "Medicine & Supplements", value: "medicine_and_supplements" },
  // { label: "Movies", value: "movies" },
  // { label: "Music Cases & Bags", value: "music_cases_and_bags" },
  // { label: "Music", value: "music" },
  // { label: "Musical Instruments", value: "musical_instruments" },
  // { label: "Office", value: "office" },
  // { label: "Optical", value: "optical" },
  // { label: "Optics", value: "optics" },
  // { label: "Other", value: "other" },
  // { label: "Photo Accessories", value: "photo_accessories" },
  // { label: "Plumbing & HVAC", value: "plumbing_and_hvac" },
  // {
  //   label: "Printers, Scanners, & Imaging",
  //   value: "printers_scanners_and_imaging",
  // },
  // { label: "Safety & Emergency", value: "safety_and_emergency" },
  // { label: "Software", value: "software" },
  // { label: "Sound & Recording", value: "sound_and_recording" },
  // { label: "Sport & Recreation Other", value: "sport_and_recreation_other" },
  // { label: "Storage", value: "storage" },
  // { label: "TV Shows", value: "tv_shows" },
  // { label: "TVs & Video Displays", value: "tvs_and_video_displays" },
  // { label: "Tires", value: "tires" },
  // { label: "Tools & Hardware Other", value: "tools_and_hardware_other" },
  // { label: "Tools", value: "tools" },
  // { label: "Toys", value: "toys" },
  // { label: "Vehicle Other", value: "vehicle_other" },
  // {
  //   label: "Vehicle Parts & Accessories",
  //   value: "vehicle_parts_and_accessories",
  // },
  // { label: "Video Games", value: "video_games" },
  // { label: "Video Projectors", value: "video_projectors" },
  // { label: "Watches", value: "watches" },
  // { label: "Watercraft", value: "watercraft" },
  // { label: "Wheels & Wheel Components", value: "wheels_and_wheel_components" },
];

export const createRepricingRule = [
  { label: "Above", value: "above" },
  { label: "Below", value: "below" },
  { label: "Match", value: "match" },
];

export const repricingAmtPer = [
  { label: "Amount", value: "amount" },
  { label: "Percent", value: "percent" },
];

export const productBuyBoxStatus = [
  {
    value: "ownbuybox",
    label: (
      //   <Checkbox checked={selectedBuyBoxStatus.includes("1")}>
      <>
        <i className="fas fa-shield-alt me-2 fs-5 text-success" />
        You own the Buy Box
      </>
      //   </Checkbox>
    ),
  },
  {
    value: "ownedbyamazon",
    label: (
      // <Checkbox checked={selectedBuyBoxStatus.includes("2")}>
      <>
        <i className="fab fa-amazon me-2 fs-5 text-dark" />
        Buy Box is owned by Amazon
      </>
      // </Checkbox>
    ),
  },
  {
    value: "nobuybox",
    label: (
      // <Checkbox checked={selectedBuyBoxStatus.includes("3")}>
      <>
        <i className="fas fa-minus-circle me-2 fs-5 text-warning" />
        No Buy Box available
      </>
      // </Checkbox>
    ),
  },
  {
    value: "ownedbycompetitor",
    label: (
      // <Checkbox checked={selectedBuyBoxStatus.includes("5")}>
      <>
        <i className="fas fa-archive ms-1 me-2 fs-5 text-danger" />
        Buy Box is owned by a competitor
      </>
      // </Checkbox>
    ),
  },
  // {
  //   value: "4",
  //   label: (
  //     // <Checkbox checked={selectedBuyBoxStatus.includes("4")}>
  //     <>
  //       {" "}
  //       <i className="fas fa-archive me-2 fs-5 text-danger" />
  //       Lost Buy Box in last 24 hours
  //     </>
  //     // </Checkbox>
  //   ),
  // },
];

export const productRepricingStatus = [
  { label: "All", value: "all" },
  {
    label: "Product is repricing",
    value: "pr",
  },
  {
    label: "Product is not repricing",
    value: "pnr",
  },
];

export const AllCarrierName = [
  "UPS",
  "USPS",
  "FedEx",
  "Airborne",
  "OnTrac",
  "DHL Ecommerce - US",
  "DHL",
  "LS",
  "UDS",
  "UPSMI",
  "FDX",
  "PILOT",
  "ESTES",
  "SAIA",
  "FDS Express",
  "Seko Worldwide",
  "HIT Delivery",
  "FEDEXSP",
  "RL Carriers",
  "Metropolitan Warehouse & Delivery",
  "China Post",
  "YunExpress",
  "Yellow Freight Sys",
  "AIT Worldwide Logistics",
  "Chukou1",
  "Sendle",
  "Landmark Global",
  "Sunyou",
  "Yanwen",
  "4PX",
  "GLS",
  "OSM Worldwide",
  "FIRST MILE",
  "AM Trucking",
  "CEVA",
  "India Post",
  "SF Express",
  "CNE",
  "TForce Freight",
  "AxleHire",
  "LSO",
  "Royal Mail",
  "ABF Freight System",
  "WanB",
  "Roadrunner Freight",
  "Meyer Distribution",
  "AAA Cooper",
  "Canada Post",
  "Southeastern Freight Lines",
  "Japan Post",
  "Correos de Mexico",
  "XPO Logistics",
  "JD Logistics",
  "YDH",
  "JCEX",
  "Flyt",
  "Deutsche Post",
  "Better Trucks",
  "Asendia",
  "SFC",
  "UBI",
  "ePost Global",
  "YF Logistics",
  "RXO",
  "Estes Express",
  "Shypmax",
  "WIN.IT America",
  "PITT OHIO",
  "PostNord Sweden",
  "Equick",
  "Whistl",
  "Tusou",
  "Shiprocket",
  "DTDC",
  "PTS",
];

export const staticKeyword = [
  {
    score: 177,
    query: "security camera",
    suggestedBid: 0.92,
    suggestedBidExact: 0.92,
    suggestedBidPhrase: 0.82,
    suggestedBidBroad: 1,
  },
  {
    score: 176,
    query: "night owl security camera",
    suggestedBid: 1.04,
    suggestedBidExact: 1.04,
    suggestedBidPhrase: 1.04,
    suggestedBidBroad: 1.04,
  },
  {
    score: 175,
    query: "camera",
    suggestedBid: 2.4,
    suggestedBidExact: 2.4,
    suggestedBidPhrase: 0.99,
    suggestedBidBroad: 0.56,
  },
  {
    score: 174,
    query: "security camera wireless wifi",
    suggestedBid: 0.66,
    suggestedBidExact: 0.66,
    suggestedBidPhrase: 0.61,
    suggestedBidBroad: 0.86,
  },
  {
    score: 173,
    query: "security camera system",
    suggestedBid: 1.26,
    suggestedBidExact: 1.26,
    suggestedBidPhrase: 10.98,
    suggestedBidBroad: 1.15,
  },
  {
    score: 172,
    query: "night owl",
    suggestedBid: 0.88,
    suggestedBidExact: 0.88,
    suggestedBidPhrase: 0.88,
    suggestedBidBroad: 0.88,
  },
  {
    score: 171,
    query: "outdoor camera",
    suggestedBid: 1.15,
    suggestedBidExact: 1.15,
    suggestedBidPhrase: 0.86,
    suggestedBidBroad: 0.82,
  },
  {
    score: 170,
    query: "door bell camera",
    suggestedBid: 0.66,
    suggestedBidExact: 0.66,
    suggestedBidPhrase: 1.01,
    suggestedBidBroad: 0.34,
  },
  {
    score: 169,
    query: "indoor camera",
    suggestedBid: 0.88,
    suggestedBidExact: 0.88,
    suggestedBidPhrase: 1.46,
    suggestedBidBroad: 1,
  },
  {
    score: 168,
    query: "home security camera system",
    suggestedBid: 0.75,
    suggestedBidExact: 0.75,
    suggestedBidPhrase: 1.94,
    suggestedBidBroad: 0.97,
  },
  {
    score: 167,
    query: "wifi camera",
    suggestedBid: 2.2,
    suggestedBidExact: 2.2,
    suggestedBidPhrase: 1.13,
    suggestedBidBroad: 0.89,
  },
];

export const WalmartMarketplaceList = [
  {
    id: 1,
    sales_channel: "Walmart.com",
    marketplace: "USA",
    marketplace_id: "ATVPDKIKX0DER",
    status: 1,
    region: null,
    default_marketplace: 0,
    created_at: 1698206446,
    updated_at: 1710302462,
    currency_sign: "$",
  },
];
