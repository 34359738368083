import React, { useContext, useEffect } from "react";
import { GlobalContext } from "../../../../commonContext";
import { useHistory } from "react-router-dom";
import WalmartConfig from "./walmart/walmart-config";

export default function (props) {
  const history = useHistory();
  const contextVar = useContext(GlobalContext);

  return contextVar?.data?.marketplaceType === "amazon" ? (
    history.replace("/dashboard")
  ) : (
    // <WalmartOrders {...props} />
    <WalmartConfig {...props} />
  );
}
