import {
  Checkbox,
  Input,
  Popconfirm,
  Popover,
  Switch,
  Tag,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { DefaultPerPage } from "../../../../../../config";
import { SearchOutlined, LoadingOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import IvcsrTable from "../../../../../../component/ivcsr-table";
import Loading from "../../../../../../component/loading";
import NoData from "../../../../../../component/no-data";
import Pagination from "../../../../../../component/pagination";
const AddList = (props) => {
  const {
    GetWalmartProductList,
    selectedCheckbox,
    setSelectedCheckbox,
    fakeActionSkuData,
    campaignId,
    adGroupId,
    WalmartCreateAdItemsCampaign,
    walmartList,
    setWalmartList,
    itemOperationLoading,
    setItemOperationLoading,
    itemId,
    setItemId,
  } = props;
  const [walmartPage, setWalmartPage] = useState(1);
  const [walmartTotalPage, setWalmartTotalPage] = useState(1);
  const [walmartPageSize, setWalmartPageSize] = useState(DefaultPerPage);
  const [searchTerm, setSearchTerm] = useState("");
  const [walmartLoading, setWalmartLoading] = useState(false);

  const GetWalmartProductListRes = useSelector(
    (state) => state.SKUData.GetWalmartProductListResponse || {}
  );

  useEffect(() => {
    setWalmartLoading(true);
    const apiObj = {
      page: 1,
      perPage: walmartPageSize,
      sales_channel: "Amazon.com",
      search_key: searchTerm,
      isExport: 0,
    };
    GetWalmartProductList(apiObj);
  }, []);

  const onWalmartPageNo = (e) => {
    setWalmartLoading(true);
    const apiObj = {
      page: e,
      perPage: walmartPageSize,
      sales_channel: "ATVPDKIKX0DER",
      search_key: searchTerm,
      isExport: 0,
    };
    GetWalmartProductList(apiObj);
    setWalmartPage(e);
  };

  const onWalmartPerPage = (e) => {
    setWalmartPage(1);
    setWalmartPageSize(e);
    setWalmartLoading(true);
    const apiObj = {
      page: 1,
      perPage: e,
      sales_channel: "ATVPDKIKX0DER",
      search_key: searchTerm,
      isExport: 0,
    };
    GetWalmartProductList(apiObj);
  };

  useEffect(() => {
    if (GetWalmartProductListRes.status === true) {
      message.destroy();
      setWalmartList(GetWalmartProductListRes?.data?.records);
      setWalmartLoading(false);
      setWalmartTotalPage(
        GetWalmartProductListRes?.data?.pagination?.totalCount
      );
      fakeActionSkuData("GetWalmartProductListResponse");
    } else if (GetWalmartProductListRes.status === false) {
      setWalmartLoading(false);
      fakeActionSkuData("GetWalmartProductListResponse");
    }
  }, [GetWalmartProductListRes]);

  const columns = [
    {
      title: "Item Name",
      width: 400,
      render: (text) => {
        return (
          <div className="d-flex align-items-center">
            <Checkbox
              checked={
                selectedCheckbox.filter((a) => a.sku == text.sku)?.length == 1
                  ? true
                  : false
              }
              onChange={(e) => {
                const checkboxId = text.sku;
                const newJson = {
                  campaignId: campaignId,
                  adGroupId: adGroupId,
                  itemId: text?.item_id || text?.itemId,
                  bid: 0.6,
                  status: "enabled",
                };
                WalmartCreateAdItemsCampaign([newJson], "POST", campaignId);
                // setSelectedCheckbox((prev) => {
                //   if (e?.target?.checked) {
                //     return [...prev, text];
                //   } else {
                //     return prev.filter((b) => b.sku !== checkboxId);
                //   }
                // });
              }}
              className="me-5"
            />
            <div className="d-flex align-items-center border-start border-2 border-info p-3 rounded">
              <div className="symbol symbol-50px  me-3">
                <img src={`${text?.primary_image_url}`} alt="" />
              </div>
              <div className="d-flex flex-column">
                <Popover
                  content={
                    <div
                      style={{
                        height: "max-content",
                        maxWidth: "400px",
                      }}
                    >
                      {text?.product_name}
                    </div>
                  }
                  placement="bottom"
                >
                  <div
                    className="text-primary mb-1 fw-bolder text-one fs-6 popoverActionIconSingleLine"
                    style={{ minWidth: "100px" }}
                  >
                    {text?.product_name}
                  </div>
                </Popover>
                <div>
                  <span className="fs-6">
                    <Tag color="orange">
                      <b>SKU:</b> {text?.sku}
                    </Tag>
                  </span>
                  <br />
                  <span className="fs-6 ">
                    <Tag color="purple" className="mt-1">
                      <b>Brand:</b> {text?.brand}
                    </Tag>
                  </span>
                </div>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Item Id",
      dataIndex: "item_id",
      key: "item_id",
    },
    // {
    //   title: "Sugg. Bid",
    //   dataIndex: "suggestedBid",
    //   key: "suggestedBid",
    // },
  ];

  const Addcolumns = [
    {
      title: "Item Name",
      width: 400,
      render: (text) => {
        return (
          <div className="d-flex align-items-center border-start border-2 border-info p-3 rounded">
            <div className="symbol symbol-50px  me-3">
              <img
                src={`${text?.primary_image_url || text?.itemImageUrl}`}
                alt=""
              />
            </div>
            {/*end::Avatar*/}
            {/*begin::User details*/}
            <div className="d-flex flex-column">
              <Popover
                content={
                  <div
                    style={{
                      height: "max-content",
                      width: "300px",
                    }}
                  >
                    {text?.product_name || text?.name}
                  </div>
                }
                placement="bottom"
              >
                <div
                  className="text-primary mb-1 fw-bolder text-one fs-6 popoverActionIconSingleLine"
                  style={{ minWidth: "100px" }}
                >
                  {text?.product_name || text?.name}
                </div>
              </Popover>
              <div>
                <span className="fs-6">
                  <Tag color="orange">
                    <b>SKU:</b> {text?.sku}
                  </Tag>
                </span>
                <br />
                <span className="fs-6 ">
                  <Tag color="purple" className="mt-1">
                    <b>Brand:</b> {text?.brand}
                  </Tag>
                </span>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Item Id",
      render: (text) => {
        return <label>{text?.itemId}</label>;
      },
    },
    {
      title: "Status",
      align: "center",
      render: (text) => {
        return text?.itemId === itemId && itemOperationLoading === "status" ? (
          <LoadingOutlined style={{ fontSize: "23px" }} />
        ) : (
          <div className="d-flex justify-content-between align-items-center">
            {" "}
            <Switch
              checked={text?.status === "enabled" ? true : false}
              onChange={(e) => {
                setItemId(text?.itemId);
                setItemOperationLoading("status");
                const newObj = {
                  campaignId: campaignId,
                  adGroupId: adGroupId,
                  itemId: text?.itemId,
                  bid: text?.bid,
                  status: e ? "enabled" : "disabled",
                };
                WalmartCreateAdItemsCampaign([newObj], "PUT", campaignId);
              }}
            />
            {/* <Popconfirm
              title="Are you sure to delete this campaign ?"
              onConfirm={() => {
                message.destroy();
                message.loading("Deleting...", 0);
                setItemId(text?.itemId);
                setItemOperationLoading("status");
                const newObj = {
                  campaignId: campaignId,
                  adGroupId: adGroupId,
                  itemId: text?.itemId,
                  bid: text?.bid,
                  status: "deleted",
                };
                WalmartCreateAdItemsCampaign([newObj], "PUT", campaignId);
              }}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <div className="mx-1">
                <button class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                  <i class="ki-outline ki-trash fs-3 text-danger"></i>
                </button>
              </div>
            </Popconfirm> */}
          </div>
        );
      },
    },
    // {
    //   title: "Action",
    //   align: "center",
    //   render: (text) => {
    //     return text?.itemId === itemId && itemOperationLoading === "delete" ? (
    //       <LoadingOutlined style={{ fontSize: "23px" }} />
    //     ) : (
    //       <Popconfirm
    //         title="Are you sure to delete this item ?"
    //         onConfirm={() => {
    //           setItemId(text?.itemId);
    //           setItemOperationLoading("delete");
    //           const newObj = {
    //             campaignId: campaignId,
    //             adGroupId: adGroupId,
    //             itemId: text?.itemId,
    //             bid: text?.bid,
    //             status: "deleted",
    //           };
    //           WalmartCreateAdItemsCampaign([newObj], "PUT", campaignId);
    //         }}
    //         onCancel={() => {}}
    //         okText="Yes"
    //         cancelText="No"
    //       >
    //         <div>
    //           <button class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
    //             <i class="ki-outline ki-trash fs-2 text-danger"></i>
    //           </button>
    //         </div>
    //       </Popconfirm>
    //     );
    //   },
    // },
  ];

  return (
    <>
      <div className="row fadeOutBottom">
        {/*begin::Col*/}
        <div className="col-xl-12">
          {/*begin::Basic info*/}
          <div className="card card-flush h-lg-100 mb-5 mb-xl-5">
            {/*begin::Card header*/}
            <div className="card-header border-0 cursor-pointer">
              {/*begin::Card title*/}
              <div className="card-title m-0">
                <h3 className="fw-bold m-0">Item List</h3>
              </div>
              {/*end::Card title*/}
              <div className="card-toolbar">
                {/* <button className="btn btn-light-info fs-7 fw-bold">
                  Create New Ad Group
                </button> */}
              </div>
            </div>
            {/*begin::Card header*/}
            {/*begin::Card body*/}
            <div className="card-body border-top border-top-dashed pb-2 pt-6">
              {/*begin::Input group*/}
              <div className="row mb-0 g-5">
                <div className="col-md-12">
                  <div className="d-flex justify-content-between align-items-center mb-5">
                    <div className="mb-3 fw-bold fs-5">
                      Select Items to Advertise
                    </div>
                    <div className=" d-flex flex-wrap align-items-center">
                      <div className="position-relative ">
                        <i className="ki-outline ki-magnifier text-gray-800 fs-2 position-absolute top-50 translate-middle-y ms-4" />
                        <Input
                          type="text"
                          className="text-dark w-250px fs-7 fw-bold ant_common_input"
                          name="all_search"
                          placeholder="Search by Brand or SKU..."
                          value={searchTerm}
                          onChange={(e) => {
                            setSearchTerm(e.target.value);
                          }}
                          onPressEnter={(e) => {
                            setWalmartLoading(true);
                            const apiObj = {
                              page: 1,
                              perPage: walmartPageSize,
                              sales_channel: "Amazon.com",
                              search_key: e.target.value,
                              isExport: 0,
                            };
                            GetWalmartProductList(apiObj);
                          }}
                          prefix={
                            <SearchOutlined
                              style={{ fontSize: "19px", color: "grey" }}
                            />
                          }
                          size="large"
                        />
                      </div>
                      {/*begin::Menu*/}
                      <button
                        className="btn btn-icon btn-color-gray-500 btn-active-color-primary justify-content-end"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                        data-kt-menu-overflow="true"
                      >
                        <i className="bi bi-file-earmark-arrow-down fs-1 text-gray-700 ms-3" />
                      </button>
                      {/*begin::Menu 2*/}
                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px"
                        data-kt-menu="true"
                      >
                        {/*begin::Menu item*/}
                        <div className="menu-item px-3">
                          <div className="menu-content fs-6 text-gray-900 fw-bold px-3 py-4">
                            Download CSV for
                          </div>
                        </div>
                        {/*end::Menu item*/}
                        {/*begin::Menu separator*/}
                        <div className="separator mb-3 opacity-75" />
                        {/*end::Menu separator*/}
                        {/*begin::Menu item*/}
                        <div className="menu-item px-3 mb-4">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultValue
                              id="flexCheckDefault"
                            />
                            <label
                              className="form-check-label fs-6 text-dark"
                              htmlFor="flexCheckDefault"
                            >
                              Items
                            </label>
                          </div>
                        </div>
                        {/*end::Menu item*/}
                        {/*begin::Menu item*/}
                        <div className="menu-item px-3">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultValue
                              id="flexCheckDefault"
                            />
                            <label
                              className="form-check-label fs-6 text-dark"
                              htmlFor="flexCheckDefault"
                            >
                              Template
                            </label>
                          </div>
                        </div>
                        {/*end::Menu item*/}
                        <div className="separator mt-3 opacity-75" />
                        {/*begin::Menu item*/}
                        <div className="menu-item px-3">
                          <div className="menu-content px-3 py-3">
                            <a
                              className="btn btn-primary  btn-sm px-4"
                              href="#"
                            >
                              Download CSV
                            </a>
                          </div>
                        </div>
                        {/*end::Menu item*/}
                      </div>
                      {/*end::Menu 2*/}
                      {/*end::Menu*/}
                    </div>
                  </div>
                  <div className="row gy-5 g-xl-5">
                    {/*begin::Col*/}
                    <div className="col-xxl-6">
                      {/*begin::Tables Widget 9*/}
                      <div
                        className="card card-xxl-stretch mb-5 mb-xl-8"
                        style={{ boxShadow: "none" }}
                      >
                        {/*begin::Header*/}
                        <div className="card-header border-bottom-dashed">
                          <h3 className="card-title fw-bold">
                            All Items ({walmartTotalPage})
                          </h3>
                          <div className="card-toolbar"></div>
                        </div>
                        {/*end::Header*/}
                        {/*begin::Body*/}
                        <div className="card-body py-2 px-7">
                          {/*begin::Table container*/}

                          {/*begin::Table*/}
                          <div className="table-responsive mt-5">
                            {walmartLoading ? (
                              <Loading />
                            ) : walmartList?.length !== 0 ? (
                              <>
                                <IvcsrTable
                                  columns={columns?.map((d) => ({
                                    ...d,
                                    // ...PropsFilter(d?.dataIndex),
                                    width:
                                      d?.title === "Item Name"
                                        ? 400
                                        : d?.title?.length * 13,
                                  }))}
                                  fixed={true}
                                  sticky={{
                                    offsetHeader: "10px",
                                  }}
                                  dataSource={walmartList}
                                  rowKey="key"
                                  loading={walmartLoading}
                                  pagination={false}
                                  scroll={{ x: "max-content", y: 500 }}
                                />
                              </>
                            ) : (
                              <NoData type="new" />
                            )}
                          </div>
                          <Pagination
                            loading={
                              walmartLoading || walmartList?.length === 0
                            }
                            pageSize={walmartPageSize}
                            page={walmartPage}
                            totalPage={walmartTotalPage}
                            onPerPage={onWalmartPerPage}
                            onPageNo={onWalmartPageNo}
                          />
                          {/*end::Table*/}

                          {/*end::Table container*/}
                        </div>
                        {/*begin::Body*/}
                      </div>
                      {/*end::Tables Widget 9*/}
                    </div>
                    {/*end::Col*/}
                    {/*begin::Col*/}
                    <div className="col-xxl-6">
                      {/*begin::Tables Widget 9*/}
                      <div
                        className="card card-xxl-stretch mb-5 mb-xl-8"
                        style={{ boxShadow: "none" }}
                      >
                        {/*begin::Header*/}
                        <div className="card-header border-bottom-dashed">
                          <h3 className="card-title fw-bold">Added Items</h3>
                          <div className="card-toolbar"></div>
                        </div>
                        {/*end::Header*/}
                        {/*begin::Body*/}
                        <div className="card-body py-2 px-7">
                          {/*begin::Table container*/}
                          <div className="table-responsive">
                            {/*begin::Table*/}
                            <div className="table-responsive mt-5">
                              {selectedCheckbox?.length !== 0 ? (
                                <>
                                  <IvcsrTable
                                    columns={Addcolumns?.map((d) => ({
                                      ...d,
                                      // ...PropsFilter(d?.dataIndex),
                                      width:
                                        d?.title === "Item Name"
                                          ? 400
                                          : d?.title?.length * 13,
                                    }))}
                                    fixed={true}
                                    sticky={{
                                      offsetHeader: "10px",
                                    }}
                                    dataSource={selectedCheckbox}
                                    rowKey="key"
                                    loading={false}
                                    pagination={false}
                                    scroll={{ x: "max-content", y: 580 }}
                                  />
                                </>
                              ) : (
                                <NoData type="new" />
                              )}
                            </div>
                            {/*end::Table*/}
                          </div>
                          {/*end::Table container*/}
                        </div>
                        {/*begin::Body*/}
                      </div>
                      {/*end::Tables Widget 9*/}
                    </div>
                    {/*end::Col*/}
                  </div>
                </div>
              </div>
              {/*end::Input group*/}
            </div>
            {/*end::Card body*/}
          </div>
          {/*end::Basic info*/}
        </div>
        {/*end::Col*/}
      </div>
    </>
  );
};

export default AddList;
