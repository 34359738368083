import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import HeaderCard from "./amazon_lib/header-card";
import SalesOverview from "./amazon_lib/sales-overview";
import OrderOverview from "./amazon_lib/order-overview";
import SalesAnalyticsTable from "./amazon_lib/sales-analytics-table";
import {
  Checkbox,
  Dropdown,
  Input,
  Popover,
  Radio,
  Select,
  Skeleton,
  Tag,
  message,
  theme,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Pagination from "../../../component/pagination";
import {
  DateFormat,
  DefaultPerPage,
  NumberWithCommas,
  SalesChannelFlag,
  handleDateRangeChange,
} from "../../../config";
import Loading from "../../../component/loading";
import NoData from "../../../component/no-data";
import RangePickerIVCSR from "../../../component/range-picker";
import dayjs from "dayjs";
import moment from "moment";
import { CheckboxWrapper } from "./style";
import writeXlsxFile from "write-excel-file";
const { useToken } = theme;

export default function (props) {
  const {
    GetSalesAnalyticsCardData,
    GetSalesOverviewChart,
    GetOrderOverviewChart,
    GetSalesAnalyticsTableData,
    GetSalesChannelList,
    fakeActionSalesAnalytics,
    GetDashboardUnitSoldChart,
    fakeActionDashboard,
  } = props;

  const { token } = useToken();
  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
    minWidth: "150px",
  };

  const [searchTerm, setSearchTerm] = useState("");
  const [list, setList] = useState([]);
  const [isExportList, setIsExportList] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageSize, setPageSize] = useState(DefaultPerPage);
  const [salesChartDate, setSalesChartDate] = useState([]);
  const [salesChartSeries, setSalesChartSeries] = useState([]);
  const [exportGraphArray, setExportGraphArray] = useState([]);
  const [orderChartDate, setOrderChartDate] = useState([]);
  const [orderChartSeries, setOrderChartSeries] = useState([]);
  const [exportOrderGraphArray, setExportOrderGraphArray] = useState([]);
  const [salesAnalyticsCardData, setSalesAnalyticsCardData] = useState([]);

  const [salesChannelList, setsalesChannelList] = useState([]);
  const [selectedSalesChannel, setSelectedSalesChannel] = useState([]);
  const [selectedSalesChannelId, setSelectedSalesChannelId] = useState([]);
  const [salesChannelLoading, setSalesChannelLoading] = useState(true);
  const [selectedCurrencySign, setSelectedCurrencySign] = useState("");

  const [filter, setFilter] = useState({
    date_period: [dayjs().add(-30, "d"), dayjs()],
  });

  const [cardLoading, setCardLoading] = useState(true);
  const [salesOverviewLoading, setSalesOverviewLoading] = useState(true);
  const [orderOverviewLoading, setOrderOverviewLoading] = useState(true);
  const [salesTableLoading, setSalesTableLoading] = useState(true);
  const [selectedDateRangeType, setSelectedDateRangeType] = useState("custom");
  const [isExportFlag, setIsExportFlag] = useState(0);
  const [selectedViewBy, setSelectedViewBy] = useState("week");

  const GetSalesAnalyticsCardDataRes = useSelector(
    (state) => state.SalesAnalytics.GetSalesAnalyticsCardDataResponse || {}
  );

  // const GetSalesAnalyticsSalesOverviewChartRes = useSelector(
  //   (state) =>
  //     state.SalesAnalytics.GetSalesAnalyticsSalesOverviewChartResponse || {}
  // );

  const GetSalesAnalyticsOrderOverviewChartRes = useSelector(
    (state) =>
      state.SalesAnalytics.GetSalesAnalyticsOrderOverviewChartResponse || {}
  );

  const GetSalesAnalyticsTableDataRes = useSelector(
    (state) => state.SalesAnalytics.GetSalesAnalyticsTableDataResponse || {}
  );

  const GetSalesChannelListRes = useSelector(
    (state) => state.SalesAnalytics.GetSalesChannelListResponse || {}
  );

  const GetDashboardUnitSoldChartRes = useSelector(
    (state) => state.Dashboard.GetDashboardUnitSoldChartResponse || {}
  );

  useEffect(() => {
    GetSalesChannelList();
  }, []);

  useEffect(() => {
    if (GetSalesChannelListRes?.status === true) {
      setSalesChannelLoading(false);
      setsalesChannelList(GetSalesChannelListRes?.data?.sales_channel);
      setSelectedSalesChannel("Amazon.com");
      setSelectedSalesChannelId("ATVPDKIKX0DER");
      setSelectedCurrencySign("$");

      const apiObj = {
        page: 1,
        perPage: pageSize,
        searchTerm: searchTerm,
        sales_channel: "Amazon.com",
        filter_date:
          moment(filter?.date_period?.[0].$d).format("MMM DD, YYYY") +
          " - " +
          moment(filter?.date_period?.[1].$d).format("MMM DD, YYYY"),
        filterKey: selectedDateRangeType,
        isExport: 0,
        viewBy: selectedViewBy,
      };
      GetSalesAnalyticsTableData(apiObj);
      GetDashboardUnitSoldChart(apiObj);
      GetOrderOverviewChart(apiObj);
      GetSalesAnalyticsCardData(apiObj);
      fakeActionSalesAnalytics("GetSalesChannelListResponse");
    } else if (GetSalesChannelListRes?.status === false) {
      fakeActionSalesAnalytics("GetSalesChannelListResponse");
    }
  }, [GetSalesChannelListRes]);

  useEffect(() => {
    if (GetSalesAnalyticsTableDataRes?.status === true) {
      if (isExportFlag === 0) {
        console.log(
          GetSalesAnalyticsTableDataRes,
          "GetSalesAnalyticsTableDataRes"
        );
        setTotalPage(
          GetSalesAnalyticsTableDataRes?.data?.pagination?.totalCount
        );

        setList(GetSalesAnalyticsTableDataRes?.data?.records);
        setSalesTableLoading(false);
      } else {
        setIsExportList(GetSalesAnalyticsTableDataRes?.data?.records);
        downloadXl(GetSalesAnalyticsTableDataRes?.data?.records);
        setIsExportFlag(0);
      }
      fakeActionSalesAnalytics("GetSalesAnalyticsTableDataResponse");
    } else if (GetSalesAnalyticsTableDataRes?.status === false) {
      setSalesTableLoading(false);
      fakeActionSalesAnalytics("GetSalesAnalyticsTableDataResponse");
    }
  }, [GetSalesAnalyticsTableDataRes]);

  useEffect(() => {
    if (GetDashboardUnitSoldChartRes?.status === true) {
      console.log(GetDashboardUnitSoldChartRes, "GetDashboardUnitSoldChartRes");
      const newSeries =
        Object.entries(GetDashboardUnitSoldChartRes?.data?.chartData)?.map(
          (d) => {
            console.log(d, "d----------");
            const name =
              d?.[0] === "order_sales"
                ? "Total Sales"
                : d?.[0] === "unit_sold"
                ? "Unit Sold"
                : d?.[0] === "TACoS"
                ? "TACoS"
                : "Ad Spend";
            const data = Object.entries(d?.[1])?.map((a) => {
              return parseFloat(a?.[1]);
            });
            return {
              name: name,
              data: data,
              type:
                d?.[0] === "ad_spend" || d?.[0] === "TACoS" ? "line" : "column",
            };
          }
        ) || [];

      const finalData = Object.entries(
        GetDashboardUnitSoldChartRes?.data?.chartDate
      )?.map((d) => {
        const newdata = Object.entries(
          GetDashboardUnitSoldChartRes?.data?.chartData
        )?.map((a) => {
          console.log({ [a[0]]: a?.[1]?.[d?.[1]] }, "aa");
          return { [a[0]]: a?.[1]?.[d?.[1]] };
        });
        console.log(d, "dd");
        console.log(newdata, "newdata");
        const myArr = { date: d?.[1], other: newdata };

        const transformedData = myArr.other.reduce((result, obj) => {
          const key = Object.keys(obj)[0];
          result[key] = obj[key];
          return result;
        }, {});

        const finalResult = {
          date: myArr.date,
          ...transformedData,
        };

        console.log(finalResult, "finalResult");
        return finalResult;
      });
      setExportGraphArray(finalData);

      setSalesChartSeries(newSeries);
      setSalesChartDate(GetDashboardUnitSoldChartRes?.data?.chartDate);
      setSalesOverviewLoading(false);
      fakeActionDashboard("GetDashboardUnitSoldChartResponse");
    } else if (GetDashboardUnitSoldChartRes?.status === false) {
      setSalesOverviewLoading(false);
      setSalesChartSeries([]);
      setSalesChartDate([]);
      message.destroy();
      message.error(GetDashboardUnitSoldChartRes?.message);
      fakeActionDashboard("GetDashboardUnitSoldChartResponse");
    }
  }, [GetDashboardUnitSoldChartRes]);

  useEffect(() => {
    if (GetSalesAnalyticsOrderOverviewChartRes?.status === true) {
      console.log(
        GetSalesAnalyticsOrderOverviewChartRes,
        "GetSalesAnalyticsOrderOverviewChartRes"
      );
      const newSeries =
        Object.entries(
          GetSalesAnalyticsOrderOverviewChartRes?.data?.chartData
        )?.map((d) => {
          console.log(d, "d----------");
          const name = d?.[0] === "order_count" ? "Orders" : "";
          const data = Object.entries(d?.[1])?.map((a) => {
            return a?.[1];
          });
          return {
            name: name,
            data: data,
          };
        }) || [];
      console.log(newSeries, "newSeries");

      const finalData = Object.entries(
        GetSalesAnalyticsOrderOverviewChartRes?.data?.chartDate
      )?.map((d) => {
        const newdata = Object.entries(
          GetSalesAnalyticsOrderOverviewChartRes?.data?.chartData
        )?.map((a) => {
          console.log({ [a[0]]: a?.[1]?.[d?.[1]] }, "aa");
          return { [a[0]]: a?.[1]?.[d?.[1]] };
        });
        console.log(d, "dd");
        console.log(newdata, "newdata");
        const myArr = { date: d?.[1], other: newdata };

        const transformedData = myArr.other.reduce((result, obj) => {
          const key = Object.keys(obj)[0];
          result[key] = obj[key];
          return result;
        }, {});

        const finalResult = {
          date: myArr.date,
          ...transformedData,
        };

        return finalResult;
      });
      // setExportGraphArray(finalData);
      console.log(finalData, "finalOrderResult");
      setExportOrderGraphArray(finalData);

      setOrderChartSeries(newSeries);
      setOrderChartDate(
        GetSalesAnalyticsOrderOverviewChartRes?.data?.chartDate
      );
      setOrderOverviewLoading(false);
      fakeActionSalesAnalytics("GetSalesAnalyticsOrderOverviewChartResponse");
    } else if (GetSalesAnalyticsOrderOverviewChartRes?.status === false) {
      setOrderOverviewLoading(false);
      fakeActionSalesAnalytics("GetSalesAnalyticsOrderOverviewChartResponse");
    }
  }, [GetSalesAnalyticsOrderOverviewChartRes]);

  useEffect(() => {
    if (GetSalesAnalyticsCardDataRes?.status === true) {
      console.log(GetSalesAnalyticsCardDataRes, "GetSalesAnalyticsCardDataRes");
      setSalesAnalyticsCardData(GetSalesAnalyticsCardDataRes?.data || []);
      setCardLoading(false);
      fakeActionSalesAnalytics("GetSalesAnalyticsCardDataResponse");
    } else if (GetSalesAnalyticsCardDataRes?.status === false) {
      setCardLoading(false);
      fakeActionSalesAnalytics("GetSalesAnalyticsCardDataResponse");
    }
  }, [GetSalesAnalyticsCardDataRes]);

  const onPageNo = (e) => {
    setSalesTableLoading(true);
    const apiObj = {
      page: e,
      perPage: pageSize,
      searchTerm: searchTerm,
      sales_channel: selectedSalesChannel,
      filter_date:
        moment(filter?.date_period?.[0].$d).format("MMM DD, YYYY") +
        " - " +
        moment(filter?.date_period?.[1].$d).format("MMM DD, YYYY"),
      isExport: 0,
      sort:
        sortFilters?.type === "ascend"
          ? sortFilters?.key
          : `-${sortFilters?.key}`,
    };
    GetSalesAnalyticsTableData(apiObj);
    setPage(e);
  };

  const onPerPage = (e) => {
    setPage(1);
    setPageSize(e);
    setSalesTableLoading(true);
    const apiObj = {
      page: 1,
      perPage: e,
      searchTerm: searchTerm,
      sales_channel: selectedSalesChannel,
      filter_date:
        moment(filter?.date_period?.[0].$d).format("MMM DD, YYYY") +
        " - " +
        moment(filter?.date_period?.[1].$d).format("MMM DD, YYYY"),
      isExport: 0,
      sort:
        sortFilters?.type === "ascend"
          ? sortFilters?.key
          : `-${sortFilters?.key}`,
    };
    GetSalesAnalyticsTableData(apiObj);
  };

  const onRangeChange = (_, dateStrings, filterKey) => {
    console.log(_, dateStrings, "_, dateStrings");
    const filter_ = { ...filter };
    if (_ === null) {
      const currDate = [dayjs().add(-30, "d"), dayjs()];
      filter_.date_period = currDate;
      //   dateStrings[0] = `${currDate[0]?.format("MMM DD, YYYY")}`;
      //   dateStrings[1] = `${currDate[1]?.format("MMM DD, YYYY")}`;
    } else {
      filter_.date_period = _;
    }
    setFilter(filter_);

    const apiObj = {
      page: 1,
      perPage: pageSize,
      searchTerm: searchTerm,
      sales_channel: selectedSalesChannel,
      filter_date:
        moment(_[0].$d).format("MMM DD, YYYY") +
        " - " +
        moment(_[1].$d).format("MMM DD, YYYY"),
      filterKey: filterKey,
      isExport: 0,
      viewBy: selectedViewBy,
      sort:
        sortFilters?.type === "ascend"
          ? sortFilters?.key
          : `-${sortFilters?.key}`,
    };

    setCardLoading(true);
    setSalesOverviewLoading(true);
    setOrderOverviewLoading(true);
    setSalesTableLoading(true);
    GetSalesAnalyticsTableData(apiObj);
    GetDashboardUnitSoldChart(apiObj);
    GetOrderOverviewChart(apiObj);
    GetSalesAnalyticsCardData(apiObj);
  };

  const defaultColumns = [
    {
      title: "Sr. No",
      fixed: window.innerWidth > 992 ? "left" : false,
      fixed: "left",
      width: 40,
      render: (item) => {
        return (
          <div>
            <span>{(page - 1) * pageSize + 1 + item.key}</span>
          </div>
        );
      },
    },
    {
      title: "Product",
      dataIndex: "product_title",
      key: "product_title",
      fixed: "left",
      width: 110,
      render: (item, row) => {
        return (
          <div className="d-flex align-items-center border-start border-2 border-info p-3 rounded">
            <div className="symbol symbol-50px  me-3">
              <img src={`${row?.image_url}`} alt="" />
            </div>
            {/*end::Avatar*/}
            {/*begin::User details*/}
            <div className="d-flex flex-column">
              <Popover
                content={
                  <div
                    style={{
                      height: "max-content",
                      width: "300px",
                    }}
                  >
                    {item}
                  </div>
                }
                placement="bottom"
              >
                <div
                  className="text-primary mb-1 fw-bolder text-one fs-5 popoverActionIconSingleLine"
                  style={{ minWidth: "100px" }}
                >
                  {item}
                </div>
              </Popover>

              <div>
                <span className="fs-6">
                  <Tag color="orange">
                    <b>SKU:</b> {row?.sku}
                  </Tag>
                </span>
                <br />
                <span className="fs-6 ">
                  <Tag color="purple" className="mt-1">
                    <b>ASIN:</b> {row?.asin}
                  </Tag>
                </span>
              </div>
            </div>
          </div>
        );
      },
    },
  ];
  const salesAnalyticsColumns = [
    {
      title: "Orders",
      dataIndex: "total_orders",
      key: "total_orders",
      isFilterKey: "total_orders",
      width: 50,
      // sorter: (a, b) => a?.total_orders - b?.total_orders,
      render: (item) => {
        return (
          <div>
            <span>{NumberWithCommas(item)}</span>
          </div>
        );
      },
    },
    {
      title: "Units Sold",
      isFilterKey: "total_units",
      width: 50,
      // sorter: (a, b) => a?.total_units - b?.total_units,
      render: (item) => {
        return (
          <div className="d-flex align-items-center">
            <span>{NumberWithCommas(item?.total_units)}</span>
            <Popover
              placement="bottom"
              content={
                <>
                  <div className="d-flex justify-content-between">
                    <label>Orders :&nbsp;</label>
                    <label className="fw-bold">
                      {NumberWithCommas(item?.order_units)}
                    </label>
                  </div>
                  <div className="d-flex justify-content-between">
                    <label>Refunds :&nbsp;</label>
                    <label className="fw-bold">
                      {NumberWithCommas(item?.refund_units)}
                    </label>
                  </div>
                </>
              }
              trigger="hover"
              className="me-4"
            >
              <i
                style={{ fontSize: "17px", color: "#827dcb" }}
                className="bi bi-info-square mx-2 cursor-pointer"
              ></i>
            </Popover>
          </div>
        );
      },
    },

    // {
    //   title: "Total Sales",
    //   dataIndex: "total_sales",
    //   key: "total_sales",
    //   width: 90,
    //   render: (item) => {
    //     return (
    //       <div>
    //         <span>
    //           {selectedCurrencySign} {NumberWithCommas(item)}
    //         </span>
    //       </div>
    //     );
    //   },
    // },
    // {
    //   title: "Refund Sales",
    //   dataIndex: "refund_gross_revenue",
    //   key: "refund_gross_revenue",
    //   width: 90,
    //   render: (item) => {
    //     return (
    //       <div>
    //         <span>
    //           {selectedCurrencySign} {NumberWithCommas(item)}
    //         </span>
    //       </div>
    //     );
    //   },
    // },
    {
      title: "Item Price",
      isFilterKey: "item_price",
      width: 50,
      // sorter: (a, b) => a?.item_price - b?.item_price,
      render: (item) => {
        return (
          <div>
            <span>
              {selectedCurrencySign} {NumberWithCommas(item?.item_price)}
            </span>
          </div>
        );
      },
    },
    {
      title: "Gross Revenue",
      width: 60,
      isFilterKey: "gross_revenue",
      render: (item) => {
        return (
          <div className="d-flex align-items-center">
            <span>
              {selectedCurrencySign}
              {NumberWithCommas(item?.gross_revenue)}
            </span>
            <Popover
              placement="bottom"
              content={
                <>
                  <div className="d-flex justify-content-between">
                    <label>Orders :&nbsp;</label>
                    <label className="fw-bold">
                      {selectedCurrencySign}
                      {NumberWithCommas(item?.gross_revenue)}
                    </label>
                  </div>
                  <div className="d-flex justify-content-between">
                    <label>Refunds :&nbsp;</label>
                    <label className="fw-bold">
                      {selectedCurrencySign}
                      {NumberWithCommas("-" + item?.refund_gross_revenue) || 0}
                    </label>
                  </div>
                </>
              }
              trigger="hover"
              className="me-4"
            >
              <i
                style={{ fontSize: "17px", color: "#827dcb" }}
                className="bi bi-info-square mx-2 cursor-pointer"
              ></i>
            </Popover>
          </div>
        );
      },
    },
    // {
    //   title: "Refund Gross Revenue",
    //   width: 80,
    //   render: (item) => {
    //     return (
    //       <div>
    //         <span>
    //           {selectedCurrencySign}{" "}
    //           {NumberWithCommas(item?.refund_gross_revenue)}
    //         </span>
    //       </div>
    //     );
    //   },
    // },
    {
      title: "Total Fees",
      isFilterKey: "total_fees",
      width: 50,
      render: (item) => {
        return (
          <div className="d-flex align-items-center">
            <span>
              {selectedCurrencySign}
              {NumberWithCommas(item?.total_fees || 0)}
              {/* {NumberWithCommas(
                item?.total_amazon_fees -
                  item?.refunds?.total_refund_amazon_fees
              ) != 0}
              {NumberWithCommas(
                item?.total_amazon_fees -
                  item?.refunds?.total_refund_amazon_fees
              )} */}
            </span>
            <Popover
              placement="bottom"
              content={
                <>
                  {/* <div className="d-flex justify-content-between">
                    <label>Referral Fees :&nbsp;</label>
                    <label className="fw-bold">
                      {selectedCurrencySign}
                      {NumberWithCommas(item?.referral_fees || 0)}
                    </label>
                  </div> */}
                  <div className="d-flex justify-content-between">
                    <label>FBA Fees :&nbsp;</label>
                    <label className="fw-bold">
                      {selectedCurrencySign}
                      {NumberWithCommas(item?.amazon_fees?.order_fba_fees || 0)}
                    </label>
                  </div>
                  <div className="d-flex justify-content-between">
                    <label>Other Fees :&nbsp;</label>
                    <label className="fw-bold">
                      {selectedCurrencySign}
                      {NumberWithCommas(
                        item?.total_fees - item?.amazon_fees?.order_fba_fees ||
                          0
                      )}
                    </label>
                  </div>
                </>
              }
              trigger="hover"
              className="me-4"
            >
              <i
                style={{ fontSize: "17px", color: "#827dcb" }}
                className="bi bi-info-square mx-2 cursor-pointer"
              ></i>
            </Popover>
          </div>
        );
      },
    },
    // {
    //   title: "Refund Fees",
    //   width: 50,
    //   render: (item) => {
    //     return (
    //       <div>
    //         <span>
    //           {selectedCurrencySign}{" "}
    //           {NumberWithCommas(item?.refunds?.total_refund_amazon_fees)}
    //         </span>
    //       </div>
    //     );
    //   },
    // },
    // {
    //   title: "Total Tax",
    //   width: 40,
    //   render: (item) => {
    //     return (
    //       <div className="d-flex align-items-center">
    //         <span>
    //           {selectedCurrencySign}
    //           {NumberWithCommas(item?.tax_data?.total_tax)}
    //         </span>
    //         <Popover
    //           placement="bottom"
    //           content={
    //             <>
    //               <div className="d-flex justify-content-between">
    //                 <label>Orders :&nbsp;</label>
    //                 <label className="fw-bold">
    //                   {selectedCurrencySign}
    //                   {item?.refund_gross_revenue}
    //                 </label>
    //               </div>
    //               <div className="d-flex justify-content-between">
    //                 <label>Refunds :&nbsp;</label>
    //                 <label className="fw-bold">
    //                   {selectedCurrencySign}
    //                   {NumberWithCommas(item?.refunds?.refund_tax)}
    //                 </label>
    //               </div>
    //             </>
    //           }
    //           trigger="hover"
    //           className="me-4"
    //         >
    //           <i
    //             style={{ fontSize: "17px", color: "#827dcb" }}
    //             className="bi bi-info-square mx-2 cursor-pointer"
    //           ></i>
    //         </Popover>
    //       </div>
    //     );
    //   },
    // },
    // {
    //   title: "Refund Tax",
    //   width: 50,
    //   render: (item) => {
    //     return (
    //       <div>
    //         <span>
    //           {selectedCurrencySign}{" "}
    //           {NumberWithCommas(item?.refunds?.refund_tax)}
    //         </span>
    //       </div>
    //     );
    //   },
    // },

    {
      title: "Total Sales",
      dataIndex: "total_sales",
      key: "total_sales",
      isFilterKey: "total_sales",
      // sorter: (a, b) => a?.total_sales - b?.total_sales,
      width: 50,
      render: (item) => {
        return (
          <div>
            <span>${NumberWithCommas(item) || 0}</span>
          </div>
        );
      },
    },
    {
      title: "Inventory Total",
      dataIndex: "inventory_total",
      key: "inventory_total",
      isFilterKey: "inventory_total",
      // sorter: (a, b) => a?.inventory_total - b?.inventory_total,
      width: 70,
      render: (item) => {
        return (
          <div>
            <span>{NumberWithCommas(item) || 0}</span>
          </div>
        );
      },
    },
    {
      title: "Ad Sales",
      dataIndex: "ad_sales",
      key: "ad_sales",
      isFilterKey: "ad_sales",
      // sorter: (a, b) => a?.total_sales - b?.total_sales,
      width: 50,
      render: (item) => {
        return (
          <div>
            <span>${NumberWithCommas(item) || 0}</span>
          </div>
        );
      },
    },
    {
      title: "Ad Spend",
      dataIndex: "ad_spend",
      key: "ad_spend",
      isFilterKey: "ad_spend",
      // sorter: (a, b) => a?.total_sales - b?.total_sales,
      width: 50,
      render: (item) => {
        return (
          <div>
            <span>${NumberWithCommas(item) || 0}</span>
          </div>
        );
      },
    },
    // {
    //   title: "Net Profit",
    //   dataIndex: "net_profit",
    //   key: "net_profit",
    //   width: 50,
    //   render: (item) => {
    //     return (
    //       <div>
    //         <span>
    //           {selectedCurrencySign} {NumberWithCommas(item)}
    //         </span>
    //       </div>
    //     );
    //   },
    // },
    {
      title: "Profit Margin",
      dataIndex: "profit_margin",
      key: "profit_margin",
      isFilterKey: "profit_margin",
      width: 50,
      render: (item) => {
        return (
          <div>
            <span>{item}%</span>
          </div>
        );
      },
    },
    {
      title: "ROI",
      dataIndex: "roi",
      key: "roi",
      isFilterKey: "roi",
      // sorter: (a, b) => a?.roi - b?.roi,
      width: 50,
      render: (item) => {
        return (
          <div>
            <span>{item || 0}%</span>
          </div>
        );
      },
    },
  ];

  const [selectedFilterCheckbox, setSelectedFilterCheckbox] = useState([
    "total_orders",
    "total_units",
    "item_price",
    "gross_revenue",
    "total_fees",
    "total_sales",
    "inventory_total",
    "ad_sales",
    "ad_spend",
    "profit_margin",
    "roi",
  ]);
  console.log(selectedFilterCheckbox, "selectedFilterCheckbox");
  const checkoxOptions = salesAnalyticsColumns?.map((d) => ({
    label: d?.title,
    value: d?.isFilterKey,
  }));

  const exportHeader = [
    { value: "Product", type: String },
    { value: "ASIN", type: String },
    { value: "SKU", type: String },
    { value: "Orders", type: String },
    { value: "Units sold", type: String },
    { value: "Item Price", type: String },
    { value: "Gross Revenue", type: String },
    { value: "Total Fees", type: String },
    { value: "Total Sales", type: String },
    { value: "Inventory Total", type: String },
    { value: "Ad Sales", type: String },
    { value: "Ad Spend", type: String },
    { value: "Profit Margin", type: String },
    { value: "ROI", type: String },
  ];

  const downloadXl = async (exportData) => {
    let dataRow = [];
    await exportData.map((d) => {
      const grossRevenue = d?.gross_revenue - d?.refund_gross_revenue;
      const totalFees =
        d?.total_amazon_fees - d?.refunds?.total_refund_amazon_fees;
      const newList = [
        d?.product_title,
        d?.asin,
        d?.sku,
        d?.total_orders,
        d?.total_units,
        d?.item_price,
        grossRevenue,
        totalFees,
        d?.total_sales,
        d?.inventory_total,
        d?.ad_sales,
        d?.ad_spend,
        d?.profit_margin,
        d?.roi,
      ];
      dataRow = [
        ...dataRow,
        newList.map((d) => {
          return { value: d };
        }),
      ];
    });
    const data = [exportHeader, ...dataRow];
    await writeXlsxFile(data, {
      fileName: "Sale-Analytics.xlsx",
    });
    message.destroy();
  };
  const [sortFilters, setSortFilters] = useState({
    key: "",
    type: "",
  });
  console.log(sortFilters, "filters");
  const handleOnChangeColumn = (_, __, sorter) => {
    setSortFilters({
      key: sorter?.order ? sorter?.column?.isFilterKey : "",
      type: sorter?.order || "",
    });
    // console.clear();
    console.log(sorter, "sorter");
    const apiObj = {
      page: 1,
      perPage: pageSize,
      searchTerm: searchTerm,
      sales_channel: "Amazon.com",
      filter_date:
        moment(filter?.date_period?.[0].$d).format("MMM DD, YYYY") +
        " - " +
        moment(filter?.date_period?.[1].$d).format("MMM DD, YYYY"),
      filterKey: selectedDateRangeType,
      isExport: 0,
      viewBy: selectedViewBy,
      sort:
        sorter?.order === "ascend"
          ? sorter?.column?.isFilterKey
          : `-${sorter?.column?.isFilterKey}`,
    };

    setSalesTableLoading(true);
    GetSalesAnalyticsTableData(apiObj);
  };
  const extraProps = (type) => {
    if (type) {
      return {
        defaultSortOrder: type === sortFilters?.key ? sortFilters.type : [],
        sorter: () => {},
      };
    }
  };
  return (
    <div className="d-flex flex-column flex-column-fluid fadeInRight">
      <div id="kt_app_content" className="app-content flex-column-fluid">
        <div
          id="kt_app_content_container"
          className="app-container container-fluid"
        >
          <div className="row g-5 g-xl-5 mb-5">
            <div className="col-md-12">
              <div className="d-lg-flex gap-2 justify-content-between">
                <div className="d-flex flex-wrap gap-2 mb-1">
                  <div className="min-w-150px">
                    <Input
                      type="text"
                      className="text-dark w-250px fs-7 fw-bold ant_common_input"
                      name="all_search"
                      placeholder="Search by ASIN or SKU..."
                      value={searchTerm}
                      size="large"
                      onChange={(e) => {
                        setSearchTerm(e.target.value);
                      }}
                      onPressEnter={(e) => {
                        setPage(1);
                        const apiObj = {
                          page: 1,
                          perPage: DefaultPerPage,
                          searchTerm: searchTerm,
                          sales_channel: selectedSalesChannel,
                          filter_date:
                            moment(filter?.date_period?.[0].$d).format(
                              "MMM DD, YYYY"
                            ) +
                            " - " +
                            moment(filter?.date_period?.[1].$d).format(
                              "MMM DD, YYYY"
                            ),
                          filterKey: selectedDateRangeType,
                          isExport: 0,
                          viewBy: selectedViewBy,
                          sort:
                            sortFilters?.type === "ascend"
                              ? sortFilters?.key
                              : `-${sortFilters?.key}`,
                        };
                        setCardLoading(true);
                        setSalesOverviewLoading(true);
                        setOrderOverviewLoading(true);
                        setSalesTableLoading(true);
                        GetSalesAnalyticsTableData(apiObj);
                        GetDashboardUnitSoldChart(apiObj);
                        GetOrderOverviewChart(apiObj);
                        GetSalesAnalyticsCardData(apiObj);
                      }}
                      prefix={
                        <SearchOutlined
                          style={{ fontSize: "19px", color: "grey" }}
                        />
                      }
                    />
                  </div>
                  <div>
                    <RangePickerIVCSR
                      className="ant_common_input"
                      id="ivcsr_date_picker"
                      size="large"
                      allowClear={false}
                      style={{ width: "290px" }}
                      format={DateFormat}
                      value={filter?.date_period}
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentNode
                      }
                      // disabled={chartLoading}
                      onChange={(_, e) => {
                        const filterKey = handleDateRangeChange(e, _);
                        setSelectedDateRangeType(filterKey);
                        console.log(filterKey, "filterKey");
                        onRangeChange(_, e, filterKey);
                      }}
                    />
                  </div>
                  <div
                    className="d-flex align-items-center ps-3"
                    style={{
                      border: "1px solid #d3cdff",
                      borderRadius: "5px",
                      background: "#f5f3ff",
                      height: "44px",
                    }}
                  >
                    <div className="d-flex align-items-center">
                      <label className="fw-bolder fs-5 me-5">View By : </label>{" "}
                      <Radio.Group
                        onChange={(e) => {
                          setSelectedViewBy(e.target.value);
                          const apiObj = {
                            page: 1,
                            perPage: pageSize,
                            searchTerm: searchTerm,
                            sales_channel: "Amazon.com",
                            filter_date:
                              moment(filter?.date_period?.[0].$d).format(
                                "MMM DD, YYYY"
                              ) +
                              " - " +
                              moment(filter?.date_period?.[1].$d).format(
                                "MMM DD, YYYY"
                              ),
                            filterKey: "custom",
                            isExport: 0,
                            viewBy: e.target.value,
                          };
                          setSalesOverviewLoading(true);
                          GetDashboardUnitSoldChart(apiObj);
                          setOrderOverviewLoading(true);
                          GetOrderOverviewChart(apiObj);
                        }}
                        value={selectedViewBy}
                      >
                        <Radio value={"day"}>Day</Radio>
                        <Radio value={"week"}>Week</Radio>
                        <Radio value={"month"}>Month</Radio>
                      </Radio.Group>
                    </div>
                  </div>
                </div>

                <div className="d-flex">
                  <Select
                    className="w-250px"
                    size="large"
                    placeholder="Select Sales Channel"
                    loading={salesChannelLoading}
                    value={selectedSalesChannelId || null}
                    onChange={(e, _) => {
                      setSelectedSalesChannelId(e);
                      setSelectedSalesChannel(_?.sales_channel);
                      setPage(1);
                      setPageSize(DefaultPerPage);
                      setCardLoading(true);
                      setSalesOverviewLoading(true);
                      setOrderOverviewLoading(true);
                      setSalesTableLoading(true);
                      const apiObj = {
                        page: 1,
                        perPage: DefaultPerPage,
                        searchTerm: searchTerm,
                        sales_channel: _?.sales_channel,
                        filter_date:
                          moment(filter?.date_period?.[0].$d).format(
                            "MMM DD, YYYY"
                          ) +
                          " - " +
                          moment(filter?.date_period?.[1].$d).format(
                            "MMM DD, YYYY"
                          ),
                        filterKey: selectedDateRangeType,
                        isExport: 0,
                        sort:
                          sortFilters?.type === "ascend"
                            ? sortFilters?.key
                            : `-${sortFilters?.key}`,
                      };
                      GetSalesAnalyticsTableData(apiObj);
                      GetDashboardUnitSoldChart(apiObj);
                      GetOrderOverviewChart(apiObj);
                      GetSalesAnalyticsCardData(apiObj);
                    }}
                    options={SalesChannelFlag(salesChannelList)}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* ------------------------------------------------------  Filter End  -----------------------------------------*/}
          {/* ------------------------------------------------------  Header Start  -----------------------------------------*/}

          <HeaderCard
            salesAnalyticsCardData={salesAnalyticsCardData}
            cardLoading={cardLoading}
          />

          {/* ------------------------------------------------------  Header End  -----------------------------------------*/}
          {/* ------------------------------------------------------  Graph Start  -----------------------------------------*/}
          <div className="row gy-5 g-xl-5 mb-5">
            <div className="col-xxl-7">
              <SalesOverview
                salesChartDate={salesChartDate}
                salesChartSeries={salesChartSeries}
                salesOverviewLoading={salesOverviewLoading}
                selectedDateRangeType={selectedDateRangeType}
                exportGraphArray={exportGraphArray}
                {...props}
              />
            </div>
            <div className="col-xxl-5">
              <OrderOverview
                orderChartDate={orderChartDate}
                orderChartSeries={orderChartSeries}
                orderOverviewLoading={orderOverviewLoading}
                selectedDateRangeType={selectedDateRangeType}
                exportOrderGraphArray={exportOrderGraphArray}
                {...props}
              />
            </div>
          </div>

          {/* ------------------------------------------------------  Graph End  -----------------------------------------*/}
          {/* ------------------------------------------------------  Table Start  -----------------------------------------*/}

          <div className="row gy-5 g-xl-5">
            <div className="col-xxl-12">
              <div className="card card-xxl-stretch mb-5 mb-xl-8">
                <div className="card-header border-bottom-dashed">
                  <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bolder text-dark">
                      Sales Overview
                    </span>
                    <span className="text-gray-500 pt-1 fw-normal fs-6">
                      Sales analytics by SKU
                    </span>
                  </h3>
                  <div className="card-toolbar">
                    <div>
                      <Dropdown
                        disabled={list === 0}
                        trigger={["click"]}
                        dropdownRender={() => (
                          <div style={contentStyle}>
                            <div>
                              <div className="separator border-gray-200" />
                              <div
                                style={{
                                  minHeight: "230px",
                                  minWidth: "150px",
                                  border: "1px solid #4419ff8a",
                                  borderRadius: "7px",
                                  overflow: "auto",
                                }}
                              >
                                <CheckboxWrapper>
                                  <Checkbox.Group
                                    value={selectedFilterCheckbox}
                                    options={checkoxOptions}
                                    onChange={(value) => {
                                      setSelectedFilterCheckbox(value);
                                    }}
                                    style={{
                                      display: "grid",
                                      justifyContent: "center",
                                    }}
                                  />
                                </CheckboxWrapper>
                              </div>
                            </div>
                          </div>
                        )}
                      >
                        <button className="btn btn-primary">
                          Select Filter
                        </button>
                      </Dropdown>
                    </div>
                    <div></div>
                    <button
                      className="btn btn-light-info fw-bolder fs-6 ms-2"
                      onClick={() => {
                        setIsExportFlag(1);
                        message.destroy();
                        message.loading("Loading...", 0);
                        const apiObj = {
                          page: 1,
                          perPage: "",
                          searchTerm: searchTerm,
                          sales_channel: selectedSalesChannel,
                          filter_date:
                            moment(filter?.date_period?.[0].$d).format(
                              "MMM DD, YYYY"
                            ) +
                            " - " +
                            moment(filter?.date_period?.[1].$d).format(
                              "MMM DD, YYYY"
                            ),
                          isExport: 1,
                          sort:
                            sortFilters?.type === "ascend"
                              ? sortFilters?.key
                              : `-${sortFilters?.key}`,
                        };
                        GetSalesAnalyticsTableData(apiObj);
                      }}
                    >
                      Export
                    </button>
                  </div>
                </div>

                <div className="card-body py-2">
                  <div className="table-responsive tbl-sales">
                    {salesTableLoading ? (
                      <Loading />
                    ) : list?.length != 0 ? (
                      <>
                        <SalesAnalyticsTable
                          data={list}
                          page={page}
                          pageSize={pageSize}
                          selectedCurrencySign={selectedCurrencySign}
                          columns={[
                            ...defaultColumns,
                            ...salesAnalyticsColumns?.filter((d) =>
                              selectedFilterCheckbox?.includes(d?.isFilterKey)
                            ),
                          ]?.map((d) => ({
                            ...d,
                            ...extraProps(d?.isFilterKey),
                          }))}
                          handleOnChangeColumn={handleOnChangeColumn}
                        />
                      </>
                    ) : (
                      <NoData height="300px" />
                    )}
                  </div>
                  <Pagination
                    loading={salesTableLoading || list?.length === 0}
                    pageSize={pageSize}
                    page={page}
                    totalPage={totalPage}
                    onPerPage={onPerPage}
                    onPageNo={onPageNo}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* ------------------------------------------------------  Table End  -----------------------------------------*/}
        </div>
      </div>
    </div>
  );
}
