export default (state, action) => {
  if (typeof state === "undefined") {
    return [];
  }
  switch (action.type) {
    case "GET_RETURNS_AND_REFUNDS_ORDERS_SUCCESS":
    case "GET_RETURNS_AND_REFUNDS_ORDERS_ERROR":
      return {
        ...state,
        GetReturnsAndRefundsOrderResponse: action.updatePayload,
      };
    case "GET_RETURNS_AND_REFUNDS_ORDERS_DETAILS_SUCCESS":
    case "GET_RETURNS_AND_REFUNDS_ORDERS_DETAILS_ERROR":
      return {
        ...state,
        GetReturnsAndRefundsOrderDetailsResponse: action.updatePayload,
      };
    case "WALMART_RETURNS_AND_REFUNDS_ORDERS_SUCCESS":
    case "WALMART_RETURNS_AND_REFUNDS_ORDERS_ERROR":
      return {
        ...state,
        WalmartReturnsAndRefundsOrderResponse: action.updatePayload,
      };
    case "GET_DIRECT_WALMART_REFUND_SUCCESS":
    case "GET_DIRECT_WALMART_REFUND_ERROR":
      return {
        ...state,
        GetDirectWalmartRefundsResponse: action.updatePayload,
      };

    case "FAKE_ACTION_RETURNS_REFUNDS":
      return { ...state, [action?.state]: {} };

    default:
      return state;
  }
};
