import styled from "styled-components";
export const Wrapper = styled.div`
    .antd_selected_presets_date {
        color: #fff !important;
        background: #4016f5 !important;
            &:hover {
            color: #fff !important;
            }
        }
    }
    // .ant-picker-dropdown 
    // .ant-picker-cell-in-view
    // .ant-picker-cell-selected 
    // .ant-picker-cell-inner 
    // .ant-picker-cell-in-view
    // .ant-picker-cell-range-start 
    // .ant-picker-cell-range-end {
    //     color: #000 !important;
    //     background: red !important;    
    // }

    // .ant-picker-cell-range-start > .ant-picker-cell-inner {
    //     color: #fff !important;
    //     background: #4016f5 !important;    
    // }

    // .ant-picker-cell-range-end > .ant-picker-cell-inner {
    //     color: #fff !important;
    //     background: #4016f5 !important;    
    // }

    .ant-picker:hover,.ant-picker-focused{    
        // border-color: #fff !important;
        box-shadow: none !important;
    }
    .ant-picker-dropdown .ant-picker-panel-container .ant-picker-presets ul li{
        border-radius: 0px !important;
        padding-inline: 9px !important;
        padding-block: 2px !important;
    }
    .ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before{
        border: 1px solid #4016f5 !important;
    }
    .ant-picker-presets ul li{
        padding : 15px 2px !important;
        &:hover {
            color: #fff !important;
            background: #4016f5 !important;
        }
    }
    .ant-picker-active-bar{
        background: #4016f5 !important;
    }
    .ant-picker-input input{
        color: #00000075 !important;
        font-weight : bold;
        font-size: 1rem !important;
    }
    .ant-picker-large{
        padding : 10px 10px !important;
    }
    .ant-picker-cell-in-range::before{
        background: #f3f0fd !important
    }


    .ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
    .ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, 
    .ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
       color: #fff !important;
        background: #4016f5 !important;    
    }
`;
