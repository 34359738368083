export default (state, action) => {
  if (typeof state === "undefined") {
    return [];
  }
  switch (action.type) {
    case "GET_SUPPLIER_DATA_LIST_SUCCESS":
    case "GET_SUPPLIER_DATA_LIST_ERROR":
      return {
        ...state,
        GetSupplierDataListResponse: action.updatePayload,
      };
    case "SAVE_SUPPLIER_DATA_SUCCESS":
    case "SAVE_SUPPLIER_DATA_ERROR":
      return {
        ...state,
        SaveSupplierResponse: action.updatePayload,
      };
    case "DELETE_SUPPLIER_SUCCESS":
    case "DELETE_SUPPLIER_ERROR":
      return {
        ...state,
        DeleteSupplierResponse: action.updatePayload,
      };

    case "FAKE_ACTION_SUPPLIER":
      return { ...state, [action?.state]: {} };
    default:
      return state;
  }
};
