import React, { useState } from "react";
import Loading from "../../../../../component/loading";
import NoData from "../../../../../component/no-data";
import IvcsrTable from "../../../../../component/ivcsr-table";
import { NumberWithCommas } from "../../../../../config";
import writeXlsxFile from "write-excel-file";
import { Segmented, message } from "antd";
import { TopProductWrapper } from "../style";
export default function (props) {
  const { tableData } = props;
  // console.log("cardData", cardData);
  const [selectedTab, setSelectedTab] = useState("Search Term");
  const columns = [
    {
      title: "ID",
      fixed: "left",
      render: (_, __, i) => {
        return <span>{i + 1}</span>;
      },
    },
    {
      title: "Search Terms",
      fixed: "left",
      render: (text) => {
        return (
          <span className="fw-bolder" style={{ textTransform: "capitalize" }}>
            {text?.search_term || ""}
          </span>
        );
      },
    },
    {
      title: "Keywords",
      fixed: "left",
      render: (text) => {
        return (
          <span className="fw-bolder" style={{ textTransform: "capitalize" }}>
            {text?.keyword || ""}
          </span>
        );
      },
    },
    {
      title: "Spend",
      sorter: (a, b) => a?.spend - b?.spend,
      render: (text) => {
        return <span>${NumberWithCommas(text?.spend || 0)}</span>;
      },
    },
    {
      title: "Ad Sales",
      sorter: (a, b) => a?.sales - b?.sales,
      render: (text) => {
        return <span>${NumberWithCommas(text?.sales || 0)}</span>;
      },
    },
    {
      title: "ROAS",
      sorter: (a, b) => a?.ROAS - b?.ROAS,
      render: (text) => {
        return <span>${NumberWithCommas(text?.ROAS || 0)}</span>;
      },
    },
    // {
    //   title: "Total Sales",
    // sorter: (a, b) => a?.spend - b?.spend,
    //   render: (text) => {
    //     return <span>${NumberWithCommas(text?.sales || 0)}</span>;
    //   },
    // },
    {
      title: "CPC",
      sorter: (a, b) => a?.CPC - b?.CPC,
      render: (text) => {
        return <span>${NumberWithCommas(text?.CPC || 0)}</span>;
      },
    },
    {
      title: "ACoS",
      sorter: (a, b) => a?.ACoS - b?.ACoS,
      render: (text) => {
        return <span>{NumberWithCommas(text?.ACoS || 0)}%</span>;
      },
    },
    {
      title: "CTR",
      sorter: (a, b) => a?.CTR - b?.CTR,
      render: (text) => {
        return <span>{NumberWithCommas(text?.CTR || 0)}%</span>;
      },
    },
    {
      title: "Impressions",
      sorter: (a, b) => a?.impressions - b?.impressions,
      render: (text) => {
        return <span>{NumberWithCommas(text?.impressions || 0)}</span>;
      },
    },
    {
      title: "Orders",
      sorter: (a, b) => a?.unit_ordered - b?.unit_ordered,
      render: (text) => {
        return <span>{NumberWithCommas(text?.unit_ordered || 0)}</span>;
      },
    },
  ];

  const exportHeaderAmazon = [
    { value: "Search Terms", type: String },
    { value: "Keywords", type: String },
    { value: "Spend", type: String },
    { value: "Ad Sales", type: String },
    { value: "ROAS", type: String },
    { value: "CPC", type: String },
    { value: "ACoS", type: String },
    { value: "CTR", type: String },
    { value: "Impressions", type: String },
    { value: "Orders", type: String },
  ];

  const downloadXl = async () => {
    let dataRow = [];
    await tableData.map((d) => {
      const newList = [
        d?.search_term,
        d?.keyword,
        d?.spend,
        d?.sales,
        d?.ROAS,
        d?.CPC,
        d?.ACoS,
        d?.CTR,
        d?.impressions,
        d?.unit_ordered,
      ];
      dataRow = [
        ...dataRow,
        newList.map((d) => {
          return { value: d };
        }),
      ];
    });
    const data = [exportHeaderAmazon, ...dataRow];
    await writeXlsxFile(data, {
      fileName: "Top 10 searchterm.xlsx",
    });
    message.destroy();
  };

  return (
    <TopProductWrapper>
      <div className="card card-xxl-stretch mb-5 mb-xl-8">
        {/*begin::Header*/}
        <div className="card-header pt-5 border-bottom-0">
          {/*begin::Title*/}
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bold text-dark">
              Top 10 Search Term
            </span>
          </h3>
          {/*end::Title*/}
          {/*begin::Toolbar*/}
          <div className="card-toolbar">
            {" "}
            <button
              className="btn btn-light-info fs-7 fw-bolder me-2"
              disabled={tableData?.length === 0}
              onClick={() => {
                downloadXl();
              }}
            >
              Export
            </button>
            {/* <div>
              <Segmented
                options={["Search Term", "ASIN"]}
                value={selectedTab}
                onChange={(e) => {
                  setSelectedTab(e);
                }}
              />
            </div> */}
          </div>
          {/*end::Toolbar*/}
        </div>
        {/*end::Header*/}
        {/*begin::Body*/}
        <div className="card-body py-3">
          <div>
            {tableData?.length != 0 ? (
              <IvcsrTable
                dataSource={tableData}
                columns={columns}
                rowKey="key"
                scroll={{
                  x: "max-content",
                }}
                pagination={false}
              />
            ) : (
              <NoData type="new" />
            )}
          </div>
        </div>
        {/*begin::Body*/}
      </div>
    </TopProductWrapper>
  );
}
