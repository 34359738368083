import React, { useEffect, useState } from "react";
import Loading from "../../../../component/loading";
import IvcsrTable from "../../../../component/ivcsr-table";
import NoData from "../../../../component/no-data";
import Pagination from "../../../../component/pagination";
import { DefaultPerPage } from "../../../../config";
import { Popconfirm, Popover, Spin, Switch, Tag, Tooltip, message } from "antd";
import {
  LoadingOutlined,
  ExclamationCircleOutlined,
  CopyTwoTone,
  DeleteTwoTone,
  EditTwoTone,
} from "@ant-design/icons";
import AddNewRepricer from "./lib/add-new-repricer";
const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
    }}
    spin
  />
);
export default function (props) {
  const {} = props;

  const [list, setList] = useState([
    {
      id: 4,
      type: null,
      rule_name: "Rule Base 1",
      rule_type: "above",
      amount: 10,
      amount_type: "amount",
      rule_status: 0,
      min_value_per: 0,
      max_value_per: 0,
      profit_floor: 0,
      fixed_price: 0,
      amount_of_fixed_price: 0,
      action_on_fixed_price: null,
      additional_info:
        '{"exclusions":{"exclude_non_buy_box_eligible_sellers":"","exclude_out_of_bounds_sellers":"","exclude_amazon":"","exclude_specific_merchants":""},"scenarios":{"if_buy_box_is_suppressed":"","if_no_competitors":"","if_competition_is_below_min_price":"","if_competition_is_match_min_price":"","buy_box_chaser":"","sleep_mode":""}}',
      created_at: 1704173419,
      updated_at: 1704173419,
    },
  ]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageSize, setPageSize] = useState(DefaultPerPage);
  const [ruleWithProduct, setRuleWithProduct] = useState({});
  const [statusId, setStatusId] = useState("");
  const [modalType, setModalType] = useState("add");
  const [addNewRepricer, setAddNewRepricer] = useState(false);
  const [selectedRule, setSelectedRule] = useState({});

  const onPageNo = (e) => {
    // setLoading(true);
    // const apiObj = {
    //   page: e,
    //   perPage: pageSize,
    //   searchKey: searchTerm,
    //   marketplace_id: selectedSalesChannel,
    //   supplier_id: selectedSupplier,
    //   forecast_days: noOfDaysToReplenish,
    //   isExport: 0,
    // };
    // GetInventoryManagementList(apiObj);
    setPage(e);
  };

  const onPerPage = (e) => {
    setPage(1);
    setPageSize(e);
    // setLoading(true);
    // const apiObj = {
    //   page: 1,
    //   perPage: e,
    //   searchKey: searchTerm,
    //   marketplace_id: selectedSalesChannel,
    //   supplier_id: selectedSupplier,
    //   forecast_days: noOfDaysToReplenish,
    //   isExport: 0,
    // };
    // GetInventoryManagementList(apiObj);
  };

  const productColumns = [
    {
      title: "Name",
      width: 400,
      align: "left",
      ellipsis: true,
      render: (text) => {
        return (
          <Tooltip rule title={text?.rule_name}>
            <span className="fw-bolder">{text?.rule_name || "N/A"}</span>
          </Tooltip>
        );
      },
    },
    {
      title: "Pricing Rule",
      width: 400,
      align: "left",
      render: (text) => {
        return (
          <Tooltip rule>
            <>
              Price&nbsp;
              <b>
                <span style={{ textTransform: "uppercase" }}>
                  {text?.rule_type || "N/A"}
                </span>
              </b>
              &nbsp;the competition by&nbsp;<b>{text?.amount || 0}</b>&nbsp;
              <span style={{ textTransform: "uppercase" }}>
                {text?.amount_type === "amount" ? "Amount" : "Percent"}
              </span>
              &nbsp;(
              <b>
                <i
                  style={{ fontSize: "18px", margin: "0px 5px" }}
                  className={`${
                    text?.amount_type === "amount"
                      ? "bi bi-cash"
                      : "bi bi-percent"
                  }`}
                ></i>
              </b>
              )
            </>
          </Tooltip>
        );
      },
    },
    {
      title: "Products",
      width: 130,
      align: "left",
      render: (text) => {
        return (
          <span className="text-primary cursor-pointer">
            <b>{ruleWithProduct?.[text?.id] || 0}</b>
          </span>
        );
      },
    },
    {
      title: "Active",
      width: 100,
      align: "left",
      render: (text) => {
        return (
          <Switch
            loading={statusId === text?.id ? true : false}
            checked={text?.rule_status}
            onChange={(e) => {
              setStatusId(text?.id);
              // UpdateRepricingRuleListAction(text?.id, {
              //   rule_name: text?.rule_name,
              //   rule_type: text?.rule_type,
              //   amount: text?.amount,
              //   amount_type: text?.amount_type,
              //   rule_status: text?.rule_status === 1 ? 0 : 1,
              // });
            }}
          />
        );
      },
    },
    {
      title: "Action",
      width: 150,
      align: "center",
      render: (text) => {
        return (
          <>
            <div className="d-flex w-100px">
              {/* <div
                className="mx-2 d-flex align-items-center"
                onClick={() => {
                  setOpenDrawer(true);
                }}
              >
                <i
                  class="bi bi-info-circle-fill"
                  style={{ fontSize: "20px", color: "#7e78ef" }}
                ></i>
              </div> */}

              <div
                onClick={() => {
                  setModalType("edit");
                  setAddNewRepricer(true);
                  setSelectedRule(text);
                }}
                className="mx-2"
              >
                <Tooltip placement="bottom" title="Edit">
                  <button className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2">
                    <i className="ki-outline ki-pencil fs-2 text-info" />
                  </button>
                </Tooltip>
              </div>
              <div
                onClick={() => {
                  setModalType("clone");
                  setSelectedRule(text);
                  setAddNewRepricer(true);
                }}
                className="mx-2"
              >
                <Tooltip title={"Clone Repricer"}>
                  <button className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                    <CopyTwoTone className="fs-2 text-danger" />
                  </button>
                </Tooltip>
              </div>
              {selectedRule?.id === text?.id && !addNewRepricer ? (
                <Spin indicator={antIcon} />
              ) : (
                <div className="mx-2">
                  <Popconfirm
                    title={
                      <div>
                        {`Are you sure, you want to delete `}
                        <b>{text?.rule_name}</b> rule ?
                      </div>
                    }
                    placement="left"
                    cancelText="No"
                    okText="Yes"
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    onConfirm={() => {
                      message?.destroy();
                      // message.loading("Loading...", 0);
                      setSelectedRule(text);
                      // DeleteRepricingRuleAction(text?.id);
                    }}
                    onCancel={() => {
                      setSelectedRule({});
                    }}
                  >
                    <Tooltip title="Delete" placement="bottom">
                      <button className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                        <i className="ki-outline ki-trash fs-2 text-danger" />
                      </button>
                    </Tooltip>
                  </Popconfirm>
                </div>
              )}
            </div>
          </>
        );
      },
    },
  ];

  // const columns = [
  //   {
  //     title: "Sr. No",
  //     fixed: "left",
  //     render: (item) => {
  //       return (
  //         <div>
  //           <span>{(page - 1) * pageSize + 1 + item.key}</span>
  //         </div>
  //       );
  //     },
  //   },
  //   {
  //     title: "Product",
  //     dataIndex: "product_title",
  //     key: "product_title",
  //     fixed: "left",
  //     isFilterKey: "notAdd",
  //     width: 500,
  //     render: (item, row) => {
  //       return (
  //         <div className="d-flex align-items-center border-start border-2 border-info p-3 rounded">
  //           <div className="symbol symbol-50px  me-3">
  //             <img src={`${row?.image_url}`} alt="" />
  //           </div>
  //           {/*end::Avatar*/}
  //           {/*begin::User details*/}
  //           <div className="d-flex flex-column">
  //             <Popover
  //               content={
  //                 <div
  //                   style={{
  //                     height: "max-content",
  //                     width: "300px",
  //                   }}
  //                 >
  //                   {item}
  //                 </div>
  //               }
  //               placement="bottom"
  //             >
  //               <div
  //                 className="text-primary mb-1 fw-bolder text-one fs-5 popoverActionIconSingleLine"
  //                 style={{ minWidth: "100px" }}
  //               >
  //                 {item}
  //               </div>
  //             </Popover>

  //             <div>
  //               <span className="fs-6">
  //                 <Tag color="orange">
  //                   <b>SKU:</b> {row?.sku}
  //                 </Tag>
  //               </span>
  //               <br />
  //               <span className="fs-6 ">
  //                 <Tag color="purple" className="mt-1">
  //                   <b>ASIN:</b> {row?.asin}
  //                 </Tag>
  //               </span>
  //             </div>
  //           </div>
  //         </div>
  //       );
  //     },
  //   },
  //   {
  //     title: "Last 30 days Sales",
  //     dataIndex: "buyBoxPrice",
  //     key: "buyBoxPrice",
  //   },
  //   {
  //     title: "Buy Box Price",
  //     dataIndex: "buyBoxPrice",
  //     key: "buyBoxPrice",
  //   },
  //   {
  //     title: "Buy Box %",
  //     dataIndex: "buyBoxPercent",
  //     key: "buyBoxPercent",
  //   },
  //   {
  //     title: "Loss of Buy Box",
  //     dataIndex: "lossOfBuyBox",
  //     key: "lossOfBuyBox",
  //   },
  //   {
  //     title: "Lost Revenue",
  //     dataIndex: "lossOfBuyBox",
  //     key: "lossOfBuyBox",
  //   },
  //   {
  //     title: "Buy Box Winner",
  //     dataIndex: "buyBoxWinner",
  //     key: "buyBoxWinner",
  //   },
  // ];

  return (
    <>
      <div className="d-flex flex-column flex-column-fluid fadeInRight">
        <div id="kt_app_content" className="app-content flex-column-fluid">
          <div
            id="kt_app_content_container"
            className="app-container container-fluid"
          >
            <div className="row gy-5 g-xl-5">
              <div className="col-xxl-12">
                <div className="card card-xxl-stretch mb-5 mb-xl-8">
                  <div className="card-header border-bottom-dashed">
                    <h3 className="card-title ">
                      <span className="card-label fw-bold text-gray-900">
                        Repricer
                      </span>
                    </h3>

                    <div className="card-toolbar gap-2">
                      <div className="position-relative">
                        <span
                          onClick={() => {
                            setAddNewRepricer(true);
                            setModalType("add");
                          }}
                          className="btn btn-primary fw-bolder"
                        >
                          Add Rule
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="card-body pb-5 pt-0">
                    <div className="table-responsive mt-5">
                      {loading ? (
                        <Loading />
                      ) : list?.length !== 0 ? (
                        <>
                          <IvcsrTable
                            columns={productColumns}
                            fixed="top"
                            dataSource={list}
                            rowKey="key"
                            loading={loading}
                            pagination={false}
                            scroll={{ x: "max-content" }}
                          />
                        </>
                      ) : (
                        <NoData type="new" />
                      )}
                    </div>
                    <Pagination
                      loading={loading || list?.length === 0}
                      pageSize={pageSize}
                      page={page}
                      totalPage={totalPage}
                      onPerPage={onPerPage}
                      onPageNo={onPageNo}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {addNewRepricer && (
        <AddNewRepricer
          onHide={() => {
            setAddNewRepricer(false);
            setSelectedRule({});
          }}
          show={addNewRepricer}
          Type={modalType}
          selectedRule={selectedRule}
          getList={() => {
            setPage(1);
            setSelectedRule({});
            setPageSize(DefaultPerPage);
            setLoading(true);
            // GetRepricingRuleListAction({
            //   page: 1,
            //   perPage: DefaultPerPage,
            // });
          }}
          {...props}
        />
      )}
    </>
  );
}
