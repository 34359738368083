import styled from "styled-components";

const SalesBreakDownWrapper = styled.div`
  .ant-collapse-header {
    align-items: center !important;
    border: none !important;
    padding: 5px 5px !important;
  }
  .ant-collapse-content,
  .ant-collapse-content-active {
    border-top: none !important;
    margin-bottom: 20px;
    // background-color: #fff5f8;
  }
  .ant-collapse-item {
    border-bottom: none !important;
  }
  .ant-collapse > .ant-collapse-item:last-child {
    border-radius: 0.475rem !important;
  }
  .ant-collapse,
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0px 0px !important;
  }

  .expenseDiv > .ant-collapse-content {
    background: transparent !important;
    padding: 3px 12px !important;
    margin-bottom: 0px !important;
  }

  .expenseDiv
    > .ant-collapse-content
    > .ant-collapse-content-box
    > .ant-collapse
    > .ant-collapse-item
    > .ant-collapse-content {
    padding: 3px 7px !important;
  }

  .commonCollapseDiv > .ant-collapse-item > .ant-collapse-content {
    padding: 3px 7px !important;
  }
  .ant-collapse-header-text {
    width: 33% !important;
  }
  .ant-collapse-expand-icon {
    padding-inline-end: 4px !important;
  }
`;

export default SalesBreakDownWrapper;

export const WalmartSalesbreakdownWrapper = styled.div`
  .ant-collapse > .ant-collapse-item {
    border-bottom: none !important;
  }
`;
