import { Input, Radio, Select, Upload, message } from "antd";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import {
  ActivatiyFeesIsVisibleToList,
  categoriesList,
} from "../../../../../config/static-select-option";
import { InboxOutlined, LoadingOutlined } from "@ant-design/icons";
import {
  ItemActivityFeedSampleTemplate,
  NumberWithCommas,
  getUserId,
} from "../../../../../config";
import { useSelector } from "react-redux";
import { ModalWrapper } from "../../../product-operation/lib/style";

export default function (props) {
  const { show, onHide, SaveItemFeedList, rowData, fakeActionSkuData } = props;
  const { Dragger } = Upload;
  const [isUploading, setIsUploading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const SaveWalmartItemFeedListRes = useSelector(
    (state) => state.SKUData.SaveWalmartItemFeedListResponse || {}
  );

  useEffect(() => {
    if (SaveWalmartItemFeedListRes.status === true) {
      message.destroy();
      message.success(
        "Thank you.  Your item has been submitted to Walmart Catalog.  Please note that it can take up to 48 hours for items to be reflect to walmart catalog."
      );
      setIsLoading(false);
      console.log(SaveWalmartItemFeedListRes, "SaveWalmartItemFeedListRes");
      onHide();
      fakeActionSkuData("SaveWalmartItemFeedListResponse");
    } else if (SaveWalmartItemFeedListRes.status === false) {
      message.destroy();
      message.error(
        SaveWalmartItemFeedListRes?.message || "Please check in Api log"
      );
      setIsLoading(false);
      fakeActionSkuData("SaveWalmartItemFeedListResponse");
    }
  }, [SaveWalmartItemFeedListRes]);

  const [modalData, setModalData] = useState([
    {
      sub_category: null,
      visible_to: null,
      sku: "",
      product_id_type: null,
      product_id: "",
      product_name: "",
      brand: "",
      price: "",
      shipping_weight: 0,
      must_ship_alone: "No",
      short_description: "",
      main_image_url: "",
    },
  ]);

  // useEffect(() => {
  //   console.log(rowData, "rowData");
  //   const newData = {
  //     sub_category: rowData?.product_category,
  //     visible_to: null,
  //     sku: rowData?.sku,
  //     product_id_type: rowData?.gtin
  //       ? "GTIN"
  //       : rowData?.upc
  //       ? "UPC"
  //       : rowData?.ean
  //       ? "EAN"
  //       : "ISBN",
  //     product_id: rowData?.gtin
  //       ? rowData?.gtin
  //       : rowData?.upc
  //       ? rowData?.upc
  //       : rowData?.ean
  //       ? rowData?.ean
  //       : rowData?.isbn,
  //     product_name: rowData?.product_name,
  //     brand: rowData?.brand,
  //     price: rowData?.price,
  //     shipping_weight: rowData?.shipping_weight,
  //     must_ship_alone: rowData?.must_ship_alone || "No",
  //     short_description: "",
  //     main_image_url: rowData?.primary_image_url,
  //   };
  //   if (Object.entries(rowData)?.length > 0) {
  //     setModalData([newData]);
  //   }
  // }, [rowData]);

  const onChangeCommonFunction = (key, value, index) => {
    setModalData((prev) =>
      prev.map((r, j) => ({
        ...r,
        [key]:
          j === index
            ? key === "price" || key === "shipping_weight"
              ? // ? parseFloat(value)
                value
              : value
            : r?.[key],
      }))
    );
  };

  const customUpload = async (props) => {
    console.log(props, "props------");
    const { file, onSuccess, onError } = props;
    try {
      setIsUploading(true);
      const formData = new FormData();
      formData.append("file", file);

      const response = await fetch("https://service.ivcsr.com/api/v1/items", {
        method: "POST",
        headers: {
          AUTHORIZATION: `Bearer ${localStorage.getItem("token")}`,
          "MARKETPLACE-ID": "ATVPDKIKX0DER",
          "USER-ID": getUserId(),
          FEEDTYPE: "MP_ITEM",
          MODE: "FILE",
          FILENAME: file.name,
        },
        body: formData,
      });

      if (response.ok) {
        // Handle successful upload
        onSuccess();
        message.destroy();

        // Log or print the response
        const responseData = await response.json();

        // console.log("Upload successful. Response:", responseData);
        const finalJson = JSON.stringify(responseData);
        message.info(finalJson);
        console.log("Upload successful. Response:", finalJson);

        setIsUploading(false);
        if (responseData?.code == 201) {
          message.destroy();
          message.success(
            "Thank you.  Your item has been submitted to Walmart Catalog.  Please note that it can take up to 48 hours for items to be reflect to walmart catalog."
          );
        } else {
          message.destroy();
          message.success(
            responseData?.message ||
              "Error while add item. Please check api log"
          );
        }
        onHide();
        // Display success message
      } else {
        // Handle upload error
        const errorData = await response.json();
        onError(new Error(errorData.message));

        // Display error message
        message.destroy();
        message.error("File upload failed");
      }
    } catch (error) {
      message.destroy();
      message.error("Error");
      // Handle other errors
      // onError(error);
    }
  };

  // const isEditing = (record) => record.key === editingKey;

  // const columns = [
  //   {
  //     title: "Sr. No",
  //     dataIndex: "serialNumber",
  //     width: "5%",
  //     editable: false,
  //   },
  //   {
  //     title: "Category",
  //     dataIndex: "category",
  //     width: "10%",
  //     editable: true,
  //   },
  //   {
  //     title: "Visible To",
  //     dataIndex: "visibleTo",
  //     width: "10%",
  //     editable: true,
  //   },
  //   {
  //     title: "Product Id Type",
  //     dataIndex: "productIdType",
  //     width: "10%",
  //     editable: true,
  //   },
  //   {
  //     title: "Product Id",
  //     dataIndex: "productId",
  //     width: "10%",
  //     editable: true,
  //   },
  //   {
  //     title: "Product Name",
  //     dataIndex: "productName",
  //     width: "15%",
  //     editable: true,
  //   },
  //   {
  //     title: "Brand",
  //     dataIndex: "brand",
  //     width: "10%",
  //     editable: true,
  //   },
  //   {
  //     title: "SKU",
  //     dataIndex: "sku",
  //     width: "10%",
  //     editable: true,
  //   },
  //   {
  //     title: "Price",
  //     dataIndex: "price",
  //     width: "10%",
  //     editable: true,
  //   },
  //   {
  //     title: "Shipping Weight",
  //     dataIndex: "shippingWeight",
  //     width: "10%",
  //     editable: true,
  //   },
  //   {
  //     title: "Must Ship Alone",
  //     dataIndex: "mustShipAlone",
  //     width: "10%",
  //     editable: true,
  //   },
  //   {
  //     title: "Short Description",
  //     dataIndex: "shortDescription",
  //     width: "15%",
  //     editable: true,
  //   },
  //   {
  //     title: "Main Image Url",
  //     dataIndex: "mainImageUrl",
  //     width: "15%",
  //     editable: true,
  //   },
  //   {
  //     title: "Action",
  //     dataIndex: "action",
  //     editable: false,
  //     render: (_, record) => {
  //       <span>Save</span>;
  //     },
  //   },
  // ];

  // const mergedColumns = columns.map((col) => {
  //   if (!col.editable) {
  //     return col;
  //   }

  //   return {
  //     ...col,
  //     onCell: (record) => ({
  //       record,
  //       inputType: col.dataIndex === "serialNumber" ? "number" : "text",
  //       dataIndex: col.dataIndex,
  //       title: col.title,
  //       editing: isEditing(record),
  //     }),
  //   };
  // });

  return (
    <Modal show={show} centered onHide={onHide} backdrop="static" size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Add Items</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ModalWrapper>
          {/* <div className="">
            <div className="d-flex justify-content-between mb-3">
              <label className="fs-4 fw-bolder">Bulk Upload</label>
              <label
                className="text-primary fs-6 fw-bold"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  ItemActivityFeedSampleTemplate();
                }}
              >
                Download Sample Template
              </label>
            </div>
            <div>
              <Dragger
                style={{
                  borderColor: isUploading ? "#008000" : "#4318ff",
                  background: isUploading ? "#ebf5eb" : "#f2f0ff",
                }}
                customRequest={customUpload}
              >
                <div className="d-flex align-items-center justify-content-between">
                  <div>
                    <p className="ant-upload-text">
                      Click or drag file to this area to upload
                    </p>
                  </div>
                  {isUploading ? (
                    <LoadingOutlined
                      style={{ color: "#008000", fontSize: "28px" }}
                    />
                  ) : (
                    <i className="bi bi-file-earmark-arrow-up fs-3x" />
                  )}
                </div>
              </Dragger>
            </div>
          </div> */}
          {/* <div className="d-flex align-items-center justify-content-around my-5">
            <div style={{ width: "45%" }}>
              <div style={{ border: "1px dashed #8397c8" }}></div>
            </div>
            <div>
              <label className="fw-bolder fs-4">OR</label>
            </div>
            <div style={{ width: "45%" }}>
              <div style={{ border: "1px dashed #8397c8" }}></div>
            </div>
          </div> */}

          <div className="table-responsive" style={{ height: "500px" }}>
            {/*begin::Table*/}
            <table
              className="table align-middle table-row-dashed  table-row-gray-300 gy-4 gx-4 border-top-d"
              id=""
            >
              <thead>
                <tr className="fw-bolder text-dark bg-light-info">
                  <th className="min-w-65px">Sr. No</th>
                  <th className="min-w-175px">Category</th>
                  <th className="min-w-150px">Visible To</th>
                  <th className="min-w-150px">Product Id Type</th>
                  <th className="min-w-100px">Product Id</th>
                  <th className="min-w-150px">Product Name</th>
                  <th className="min-w-150px">Brand</th>
                  <th className="min-w-150px">SKU</th>
                  <th className="min-w-100px">Price</th>
                  <th className="min-w-150px">Shipping Weight</th>
                  <th className="min-w-175px">Must Ship Alone</th>
                  <th className="min-w-150px">Short Description</th>
                  <th className="min-w-150px">Main Image Url</th>
                  <th className="min-w-100px">Action</th>
                  {/* {Object?.entries(rowData)?.length == 0 ? (
                    <th className="min-w-100px">Action</th>
                  ) : (
                    ""
                  )} */}
                </tr>
              </thead>
              <tbody className="text-gray-700 fw-bold fs-7">
                {modalData?.map((d, i) => {
                  return (
                    <tr className="" key={i}>
                      <td>{i + 1}</td>
                      <td>
                        <Select
                          size="medium"
                          options={categoriesList?.map((d, i) => {
                            return { label: d?.label, value: d?.value };
                          })}
                          showSearch
                          style={{ width: "175px" }}
                          getPopupContainer={(triggerNode) =>
                            triggerNode.parentNode
                          }
                          value={d?.sub_category}
                          onChange={(e) => {
                            onChangeCommonFunction("sub_category", e, i);
                          }}
                          placeholder="Select"
                        />
                      </td>
                      <td>
                        <Select
                          size="medium"
                          options={ActivatiyFeesIsVisibleToList?.map((d, i) => {
                            return { label: d?.label, value: d?.value };
                          })}
                          style={{ width: "175px" }}
                          getPopupContainer={(triggerNode) =>
                            triggerNode.parentNode
                          }
                          value={d?.visible_to}
                          onChange={(e) => {
                            onChangeCommonFunction("visible_to", e, i);
                          }}
                          placeholder="Select"
                        />
                      </td>
                      <td>
                        <Select
                          size="medium"
                          options={[
                            { label: "GTIN", value: "GTIN" },
                            { label: "UPC", value: "UPC" },
                            { label: "EAN", value: "EAN" },
                            { label: "ISBN", value: "ISBN" },
                          ]}
                          style={{ width: "100%" }}
                          getPopupContainer={(triggerNode) =>
                            triggerNode.parentNode
                          }
                          value={d?.product_id_type}
                          onChange={(e) => {
                            onChangeCommonFunction("product_id_type", e, i);
                          }}
                          placeholder="Select"
                        />
                      </td>
                      <td>
                        <Input
                          size="medium"
                          className="ant_common_input"
                          value={d?.product_id}
                          onChange={(e) => {
                            onChangeCommonFunction(
                              "product_id",
                              e.target.value,
                              i
                            );
                          }}
                        />
                      </td>
                      <td>
                        <Input
                          size="medium"
                          className="ant_common_input"
                          value={d?.product_name}
                          onChange={(e) => {
                            onChangeCommonFunction(
                              "product_name",
                              e.target.value,
                              i
                            );
                          }}
                        />
                      </td>
                      <td>
                        <Input
                          size="medium"
                          className="ant_common_input"
                          value={d?.brand}
                          onChange={(e) => {
                            onChangeCommonFunction("brand", e.target.value, i);
                          }}
                        />
                      </td>
                      <td>
                        <Input
                          size="medium"
                          className="ant_common_input"
                          value={d?.sku}
                          onChange={(e) => {
                            onChangeCommonFunction("sku", e.target.value, i);
                          }}
                        />
                      </td>
                      <td>
                        <Input
                          size="medium"
                          className="ant_common_input"
                          value={d?.price}
                          onChange={(e) => {
                            const newVal = e.target.value.split(".").join("");
                            const validation = /^[0-9]+$/.test(newVal);
                            if (validation || !newVal) {
                              onChangeCommonFunction(
                                "price",
                                e.target.value,
                                i
                              );
                            }
                          }}
                        />
                      </td>
                      <td>
                        <Input
                          size="medium"
                          className="ant_common_input"
                          value={d?.shipping_weight}
                          onChange={(e) => {
                            // const newVal = e.target.value.split(".").join("");
                            const validation = /^[0-9]+$/.test(e.target.value);
                            if (validation || !e.target.value) {
                              onChangeCommonFunction(
                                "shipping_weight",
                                parseInt(e.target.value || 0),
                                i
                              );
                            }
                          }}
                        />
                      </td>
                      <td>
                        <Radio.Group
                          name="radiogroup"
                          defaultValue={"No"}
                          disabled
                        >
                          <Radio value={"Yes"}>Yes &nbsp; /</Radio>
                          <Radio value={"No"}>No</Radio>
                        </Radio.Group>
                        {/* <Input
                          size="medium"
                          className="ant_common_input"  
                          value={d?.must_ship_alone}
                          onChange={(e) => {
                            onChangeCommonFunction(
                              "must_ship_alone",
                              e.target.value,
                              i
                            );
                          }}
                        /> */}
                      </td>
                      <td>
                        <Input
                          size="medium"
                          className="ant_common_input"
                          value={d?.short_description}
                          onChange={(e) => {
                            onChangeCommonFunction(
                              "short_description",
                              e.target.value,
                              i
                            );
                          }}
                        />
                      </td>
                      <td>
                        <Input
                          size="medium"
                          className="ant_common_input"
                          value={d?.main_image_url}
                          onChange={(e) => {
                            onChangeCommonFunction(
                              "main_image_url",
                              e.target.value,
                              i
                            );
                          }}
                        />
                      </td>
                      <td>
                        <div className="d-flex">
                          {modalData?.length > 1 && (
                            <span
                              className="btn btn-light-danger btn-icon me-3"
                              onClick={() => {
                                const newLen = modalData?.filter(
                                  (d, j) => j != i
                                );
                                setModalData(newLen);
                              }}
                            >
                              <i className="fa fa-minus" />
                            </span>
                          )}
                          {modalData?.length - 1 === i && (
                            <span
                              className="btn btn-light-info btn-icon "
                              onClick={() => {
                                if (modalData?.length >= 7) {
                                  message.destroy();
                                  message.info(
                                    "You can add maximum 7 product at a time..."
                                  );
                                } else {
                                  console.log("ModalData", modalData);
                                  const newArray = [
                                    ...modalData,
                                    {
                                      sub_category: null,
                                      visible_to: null,
                                      sku: "",
                                      product_id_type: null,
                                      product_id: "",
                                      product_name: "",
                                      brand: "",
                                      price: "",
                                      shipping_weight: 0,
                                      must_ship_alone: "No",
                                      short_description: "",
                                      main_image_url: "",
                                    },
                                  ];
                                  setModalData(newArray);
                                }
                              }}
                            >
                              <i className="fa fa-plus" />
                            </span>
                          )}
                        </div>
                      </td>
                      {/* {Object?.entries(rowData)?.length == 0 ? (
                        <td>
                          <div className="d-flex">
                            {modalData?.length > 1 && (
                              <span
                                className="btn btn-light-danger btn-icon me-3"
                                onClick={() => {
                                  const newLen = modalData?.filter(
                                    (d, j) => j != i
                                  );
                                  setModalData(newLen);
                                }}
                              >
                                <i className="fa fa-minus" />
                              </span>
                            )}
                            {modalData?.length - 1 === i && (
                              <span
                                className="btn btn-light-info btn-icon "
                                onClick={() => {
                                  if (modalData?.length >= 7) {
                                    message.destroy();
                                    message.info(
                                      "You can add maximum 7 product at a time..."
                                    );
                                  } else {
                                    console.log("ModalData", modalData);
                                    const newArray = [
                                      ...modalData,
                                      {
                                        sub_category: null,
                                        visible_to: null,
                                        sku: "",
                                        product_id_type: null,
                                        product_id: "",
                                        product_name: "",
                                        brand: "",
                                        price: "",
                                        shipping_weight: "",
                                        must_ship_alone: "No",
                                        short_description: "",
                                        main_image_url: "",
                                      },
                                    ];
                                    setModalData(newArray);
                                  }
                                }}
                              >
                                <i className="fa fa-plus" />
                              </span>
                            )}
                          </div>
                        </td>
                      ) : (
                        ""
                      )} */}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {/*end::Table*/}
          </div>
        </ModalWrapper>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-light-danger" onClick={onHide}>
          Close
        </button>
        <button
          className="btn btn-primary px-5"
          disabled={isLoading}
          onClick={() => {
            message.destroy();
            message.loading("Loading...", 0);
            setIsLoading(true);
            SaveItemFeedList(modalData);
          }}
        >
          Submit
        </button>
      </Modal.Footer>
    </Modal>
  );
}
