import styled from "styled-components";

const Wrapper = styled.div`
  .dashboard-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .dashboard-card {
    flex: 1 0 auto;
    max-width: 200px;
    min-width: 150px;
    height: 120px;
    border-radius: 8px;
    //   padding: 16px;
    color: #000;
    background: #fff;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 35px;
    transform: translateY(0);
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    cursor: pointer;

    border-radius: 12px;
    background-image: linear-gradient(
      135deg,
      rgba(255, 255, 255, 0.1) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.1) 50%,
      rgba(255, 255, 255, 0.1) 75%,
      transparent 75%,
      transparent
    );
  }

  .dashboard-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  }

  .card-key {
    font-size: 16px;
    font-weight: bold;
  }

  .card-value {
    font-size: 24px;
  }

  @media (min-width: 576px) {
    .dashboard-card {
      max-width: calc(50% - 10px);
      min-width: calc(50% - 10px);
    }
  }

  @media (min-width: 768px) {
    .dashboard-card {
      max-width: calc(33.33% - 10px);
      min-width: calc(33.33% - 10px);
    }
  }

  @media (min-width: 992px) {
    .dashboard-card {
      max-width: calc(25% - 10px);
      min-width: calc(25% - 10px);
    }
  }

  @media (min-width: 1200px) {
    .dashboard-card {
      max-width: calc(20% - 10px);
      min-width: calc(20% - 10px);
    }
  }

  .chart-container {
    position: relative;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }

  .chart-container::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      135deg,
      rgba(0, 0, 0, 0.1) 0%,
      rgba(0, 0, 0, 0.3) 100%
    );
    pointer-events: none;
  }

  .card-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 10px;
  }
`;

export default Wrapper;
