import styled from "styled-components";
export const BackToAdminWrapper = styled.div`
  .btn {
    --color: #4318ff;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.5s;
    border: none;
    background-color: transparent;
  }

  .btn div {
    letter-spacing: 2px;
    background: var(--color);
    border-radius: 20px;
    color: white;
    padding: 0.5rem 1rem;
  }

  .btn::before {
    content: "";
    z-index: -1;
    // background-color: var(--color);
    background-color: #dcd8f4;
    border: 2px solid white;
    border-radius: 20px;
    width: 80%;
    height: 84%;
    position: absolute;
    transform: rotate(5deg);
    transition: 0.5s;
    // opacity: 0.2;
  }

  .btn:hover {
    cursor: pointer;
    filter: brightness(1);
    transform: scale(1);
  }

  .btn:hover::before {
    transform: rotate(0deg);
    opacity: 1;
    height: 62%;
  }

  .btn svg {
    transform: translateX(-200%);
    transition: 0.5s;
    width: 0;
    opacity: 0;
  }

  .btn:active {
    filter: brightness(1);
  }
`;
export const SidebarWrapper = styled.div`
  .ant-menu {
    border-inline-end: none !important;
  }
  .ant-menu-light .ant-menu-item-selected {
    background-color: transparent !important;
  }
  .ant-menu-item-selected {
    padding-left: 10px !important;
    background-image: linear-gradient(
      91deg,
      #d3cbff,
      transparent
    ) !important;
    box-shadow: none !important;
    border-right: 3px solid var(--bs-primary) !important;
    border-radius: 5px 0px 0px 5px !important;
    animation-duration: 0.7s;
    animation-fill-mode: both;
    animation-name: fadeInLeftMenu;
  }
  @keyframes fadeInLeftMenu {
    from {
      opacity: 0;
      transform: translateX(-3%);
    }
    to {
      opacity: 1;
    }
  }
  .ant-menu-item-icon {
    color:  var(--bs-primary) !important;
  }
  .ant-menu-item {
    padding-left: 6px !important;
    margin:10px 0px;
  }
  .ant-menu-submenu {
    padding-left: 0px !important;
  }
  .ant-menu-submenu-title {
    margin-left: 0;
    padding-left: 7px !important;
    border-right: 3px solid transparent !important;
    border-radius: 5px 0px 0px 5px !important;
  }
  .ant-menu-light:not(.ant-menu-horizontal)
    .ant-menu-item:not(.ant-menu-item-selected):hover {
      background-color: transparent !important;
      background-image: linear-gradient(
        91deg,
        var(--bs-gray-200),
        transparent,
        transparent
      ) !important;
      box-shadow: none !important;
      border-right: 3px solid #071437 !important;
      border-radius: 5px 0px 0px 5px !important;
    }
  }

  .ant-menu-light:not(.ant-menu-horizontal) .ant-menu-submenu-title:hover {

    background-color: transparent !important;
    background-image: linear-gradient(
      91deg,
      var(--bs-gray-200),
      transparent,
      transparent
    ) !important;
    box-shadow: none !important;
    border-right: 3px solid #071437 !important;
    border-radius: 5px 0px 0px 5px !important;
  }

  .ant-menu-title-content {
    font-size: 1.15rem !important;
    font-weight: 500 !important;
    color: #5e5f63;
  }

  .ant-menu-sub,
  .ant-menu-inline {
    background: transparent !important;
  }
  .ant-menu-sub {
    padding-left: 16px !important;
  }
  .ant-menu-sub {
    .ant-menu-item {
      &:hover {
        background-color: transparent !important;
        color: #000 !important;
        .ant-menu-title-content {
          color: #000;
        }
      }
    }
  }
  .ant-menu .ant-menu-submenu-arrow {
    color: #ababab;
  }
  .fadeInLeftSidebar {
    animation-duration: 0.7s;
    animation-fill-mode: both;
    animation-name: fadeInLeftSidebar;
  }

  @keyframes fadeInLeftSidebar {
    from {
      opacity: 0;
      transform: translateX(-100%);
    }
    to {
      opacity: 1;
    }
  }

  .fadeInLeftSwitchUser {
    animation-duration: 0.7s;
    animation-fill-mode: both;
    animation-name: fadeInLeftSwitchUser;
  }

  @keyframes fadeInLeftSwitchUser {
    from {
      opacity: 0;
      transform: translateX(-100%);
    }
    to {
      opacity: 1;
    }
  }

  .btnLogout {
    width: 150px;
    height: 40px;
    border-radius: 0.475rem;
    border: none;
    transition: all 0.5s ease-in-out;
    font-size: 18px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    // font-weight: 600;
    display: flex;
    align-items: center;
    background: #4318ff;
    color: #f5f5f5;
  }
  
  .btnLogout:hover {
    box-shadow: 0 0 20px 0px #2e2e2e3a;
  }
  
  .btnLogout .iconLogout {
    position: absolute;
    height: 40px;
    width: 58px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.5s;
  }
  
  .btnLogout .textLogout {
    transform: translateX(50px);
  }
  
  .btnLogout:hover .iconLogout {
    width: 140px;
  }
  
  .btnLogout:hover .textLogout {
    transition: all 0.5s;
    opacity: 0;
  }
  
  .btnLogout:focus {
    outline: none;
  }
  
  .btnLogout:active .iconLogout {
    transform: scale(0.85);
  }
`;
