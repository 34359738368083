import { Input, Popover, Tag, message } from "antd";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import IvcsrTable from "../../../../component/ivcsr-table";
import Loading from "../../../../component/loading";
import NoData from "../../../../component/no-data";
import Pagination from "../../../../component/pagination";
import { DefaultPerPage, urlDecode } from "../../../../config";
import ErrorLogModal from "./lib/error-log-modal";
import { GlobalContext } from "../../../../commonContext";
import { useHistory } from "react-router-dom";
import ReactJson from "react-json-view";
import { SearchOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";

export default function (props) {
  const { GetWalmartApiLogList, fakeActionWalmartApiLog } = props;
  const GetWalmartApiLogListRes = useSelector(
    (state) => state.WalmartApiLog.GetWalmartApiLogListResponse || {}
  );
  const location = useLocation();
  const history = useHistory();
  const contextVar = useContext(GlobalContext);

  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageSize, setPageSize] = useState(DefaultPerPage);
  const [isOpenErrorModal, setIsOpenErrorModal] = useState(false);
  const [wmRequestedData, setWmRequestedData] = useState([]);
  const [wmResponseData, setWmResponseData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    console.log(urlDecode(location), "location");
    const newUrl = urlDecode(location);
    setSearchTerm(newUrl?.logId);
    const apiObj = {
      page: 1,
      perPage: pageSize,
      searchTerm: newUrl?.logId,
    };
    GetWalmartApiLogList(apiObj);
    return () => {};
  }, [location]);

  // useEffect(() => {
  //   const apiObj = {
  //     page: 1,
  //     perPage: pageSize,
  //     searchTerm: searchTerm,
  //   };
  //   GetWalmartApiLogList(apiObj);
  //   return () => {};
  // }, [contextVar?.data?.marketplaceType === "walmart"]);

  useEffect(() => {
    if (GetWalmartApiLogListRes.status === true) {
      setList(GetWalmartApiLogListRes?.data?.records);
      console.log(GetWalmartApiLogListRes, "GetWalmartApiLogListRes");
      setLoading(false);
      setTotalPage(GetWalmartApiLogListRes?.data?.pagination?.totalCount);
      fakeActionWalmartApiLog("GetWalmartApiLogListResponse");
    } else if (GetWalmartApiLogListRes.status === false) {
      setLoading(false);
      fakeActionWalmartApiLog("GetWalmartApiLogListResponse");
    }
  }, [GetWalmartApiLogListRes]);

  const convertJSON = (data) => {
    try {
      return JSON.parse(data || {});
    } catch (error) {
      return { value: data, key: "string" };
    }
  };

  const columns = [
    {
      title: "#",
      ellipsis: true,
      align: "left",
      width: 100,
      render: (text) => {
        return <span>{(page - 1) * pageSize + 1 + text.key}</span>;
      },
    },

    {
      title: "API Log Id",
      align: "left",
      render: (item) => {
        return (
          <div>
            <span>{item?.id}</span>
          </div>
        );
      },
    },
    {
      title: "Log Created at",
      align: "left",
      width: 300,
      render: (item) => {
        return (
          <div>
            <span>
              {moment(new Date(item?.created_at * 1000)).format(
                "MMM DD, YYYY hh:mm A"
              )}
            </span>
          </div>
        );
      },
    },
    {
      title: "Event Name",
      align: "left",
      width: 300,
      render: (item) => {
        return <span>{item?.event_name || "-"}</span>;
      },
    },
    {
      title: "Status",
      align: "left",
      width: 300,
      render: (item) => {
        return (
          <div>
            <Tag
              color={item?.status == 0 ? "error" : "success"}
              style={{ textDecoration: "underline" }}
              onClick={() => {
                item?.wm_request_payload &&
                  setWmRequestedData(JSON.parse(item?.wm_request_payload));
                item?.wm_response_payload &&
                  setWmResponseData(JSON.parse(item?.wm_response_payload));
                if (item?.wm_request_payload || item?.wm_response_payload) {
                  setIsOpenErrorModal(true);
                } else {
                  message.destroy();
                  message.info("Payload not found...");
                }
              }}
            >
              {item?.status == 0 ? "ERROR" : "SUCCESS"}
            </Tag>
          </div>
        );
      },
    },
    {
      title: "Error Details",
      align: "left",
      width: 400,
      render: (item) => {
        console.log("====================================");
        console.log(convertJSON(item?.error_details || {}));
        console.log("====================================");
        return item?.error_details ? (
          <Popover
            content={
              <div
                style={{
                  height: "max-content",
                  width: "500px",
                }}
              >
                {convertJSON(item?.error_details || {})?.key === "string" ? (
                  convertJSON(item?.error_details || {})?.value
                ) : (
                  <ReactJson
                    src={convertJSON(item?.error_details || {})}
                    theme="rjv-default"
                    displayDataTypes={false}
                    collapsed={false}
                    style={{
                      overflow: "auto",
                      height: "auto",
                      width: "100%",
                      padding: "10px",
                    }}
                  />
                )}
              </div>
            }
            placement="bottom"
          >
            <div
              className="text-danger mb-1 fw-bolder text-one fs-6 popoverActionIconSingleLine"
              style={{ minWidth: "100px" }}
            >
              <div>
                <span>{item?.error_details || "-"}</span>
              </div>
            </div>
          </Popover>
        ) : (
          "-"
        );
      },
    },
  ];

  const onPageNo = (e) => {
    setLoading(true);
    const apiObj = {
      page: e,
      perPage: pageSize,
      searchTerm: searchTerm,
    };
    GetWalmartApiLogList(apiObj);
    setPage(e);
  };

  const onPerPage = (e) => {
    setPage(1);
    setPageSize(e);
    setLoading(true);
    const apiObj = {
      page: 1,
      perPage: e,
      searchTerm: searchTerm,
    };
    GetWalmartApiLogList(apiObj);
  };
  if (contextVar?.data?.marketplaceType === "walmart") {
    return (
      <div className="fadeInRight">
        <>
          <div className="d-flex flex-column flex-column-fluid">
            <div id="kt_app_content" className="app-content flex-column-fluid">
              <div
                id="kt_app_content_container"
                className="app-container container-fluid"
              >
                {/*begin::Row*/}
                <div className="row gy-5 g-xl-5">
                  {/*begin::Col*/}
                  <div className="col-xxl-12">
                    {/*begin::Tables Widget 9*/}
                    <div className="card card-xxl-stretch mb-5 mb-xl-8">
                      {/*begin::Header*/}
                      <div className="card-header border-bottom-dashed">
                        {/*end::Title*/}
                        {/*begin::Title*/}
                        <h3 className="card-title ">
                          <Input
                            type="text"
                            className="text-dark w-250px fs-7 fw-bold ant_common_input"
                            name="all_search"
                            placeholder="Search by API Log Id..."
                            value={searchTerm}
                            size="large"
                            onChange={(e) => {
                              setSearchTerm(e.target.value);
                            }}
                            onPressEnter={(e) => {
                              setPage(1);
                              const apiObj = {
                                page: 1,
                                perPage: pageSize,
                                searchTerm: e.target.value,
                              };
                              setLoading(true);
                              GetWalmartApiLogList(apiObj);
                            }}
                            prefix={
                              <SearchOutlined
                                style={{ fontSize: "19px", color: "grey" }}
                              />
                            }
                          />
                        </h3>
                        {/*end::Title*/}
                        <div className="card-toolbar">
                          <div className="position-relative"></div>
                        </div>
                      </div>
                      {/*end::Header*/}
                      {/*begin::Body*/}
                      <div className="card-body pb-5 pt-0">
                        {/*begin::Table container*/}
                        <div className="table-responsive mt-5">
                          {loading ? (
                            <Loading />
                          ) : list?.length != 0 ? (
                            <>
                              <IvcsrTable
                                columns={columns?.map((d) => ({
                                  ...d,
                                  width: d?.title?.length * 13,
                                }))}
                                fixed={"top"}
                                sticky={{
                                  offsetHeader: "10px",
                                }}
                                scroll={{
                                  x: "max-content",
                                  y: "calc(100vh - 200px)",
                                }}
                                dataSource={list}
                                rowKey="key"
                                loading={loading}
                                pagination={false}
                              />
                            </>
                          ) : (
                            <NoData type="new" />
                          )}
                        </div>
                        <Pagination
                          loading={loading || list?.length === 0}
                          pageSize={pageSize}
                          page={page}
                          totalPage={totalPage}
                          onPerPage={onPerPage}
                          onPageNo={onPageNo}
                        />
                      </div>
                      {/*begin::Body*/}
                    </div>
                    {/*end::Tables Widget 9*/}
                  </div>
                  {/*end::Col*/}
                </div>
                {/*end::Row*/}
              </div>
            </div>
          </div>
        </>
        {isOpenErrorModal && (
          <ErrorLogModal
            show={isOpenErrorModal}
            onHide={() => {
              setIsOpenErrorModal(false);
              setWmRequestedData([]);
              setWmResponseData([]);
            }}
            requestedJson={wmRequestedData}
            responseJson={wmResponseData}
          />
        )}
      </div>
    );
  } else {
    history.replace("/dashboard");
  }
}
