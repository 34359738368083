import { Skeleton, message } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { NumberWithCommas } from "../../../../../config";
import { useSelector } from "react-redux";

const TabOrderDetails = (props) => {
  const { GetOrderDetails, fakeActionWalmartOrders, poUrl } = props;
  const loadingArr = [1, 2, 3, 4, 5, 6];
  const loadingArr2 = [1, 2, 3, 4];

  const [loading, setLoading] = useState(true);
  const [orderDetails, setOrderDetails] = useState({});
  const [customer, setCustomerDetails] = useState({});

  useEffect(() => {
    if (Object.keys(poUrl).length !== 0) {
      GetOrderDetails({ id: poUrl?.poid, is_all: 0 });
    }
  }, [poUrl]);

  const GetWalmartOrdersDetailsRes = useSelector(
    (state) => state.WalmartOrders.GetWalmartOrdersDetailsResponse || {}
  );

  useEffect(() => {
    if (GetWalmartOrdersDetailsRes.status === true) {
      setOrderDetails(GetWalmartOrdersDetailsRes?.data?.orderDetails);
      setCustomerDetails(GetWalmartOrdersDetailsRes?.data?.shippingInfo);
      setLoading(false);
      fakeActionWalmartOrders("GetWalmartOrdersDetailsResponse");
    } else if (GetWalmartOrdersDetailsRes.status === false) {
      setLoading(false);
      fakeActionWalmartOrders("GetWalmartOrdersDetailsResponse");
    }
  }, [GetWalmartOrdersDetailsRes]);

  return (
    <>
      <div className="row gy-5 g-xl-5">
        {/*begin::Col*/}
        <div className="col-xxl-12">
          {/*begin::Tables Widget 9*/}
          <div className="card card-xxl-stretch mb-5 mb-xl-8">
            {/*begin::Header*/}
            <div className="card-header border-bottom-dashed">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bolder text-dark">
                  Order Details
                </span>
                {/* <span class="text-gray-400 pt-1 fw-semibold fs-6">Sales analytics by SKU</span> */}
              </h3>
              {/* <div className="card-toolbar">
                {loading ? (
                  <Skeleton.Button active size="large" />
                ) : orderDetails?.tracking_url ? (
                  <Link
                    to={{
                      pathname: orderDetails?.tracking_url || "",
                    }}
                    target="_blank"
                  >
                    <button className="btn btn-primary">View Timeline</button>
                  </Link>
                ) : (
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      message.destroy();
                      message.info("Tracking url not found");
                    }}
                  >
                    View Timeline
                  </button>
                )}
              </div> */}
            </div>
            {/*end::Header*/}
            {/*begin::Body*/}
            <div className="card-body p-9">
              <div className="row g-5 mb-0">
                <div className="col-md-6 ">
                  {/*begin::Row*/}
                  {loading &&
                    loadingArr?.map((d) => {
                      return (
                        <div className="row mb-7">
                          <label className="col-lg-4 fw-normal text-gray-700 fs-6">
                            <Skeleton.Button active size="small" block />
                          </label>
                          <div className="col-lg-8">
                            <Skeleton.Button active size="small" block />
                          </div>
                        </div>
                      );
                    })}
                  {!loading && (
                    <>
                      <div className="row mb-7">
                        <label className="col-lg-4 fw-normal text-gray-700 fs-6">
                          Order #:
                        </label>

                        <div className="col-lg-8">
                          <span className="fw-bold fs-6 text-gray-800">
                            {orderDetails?.purchase_order_id || "-"}
                          </span>
                        </div>
                      </div>
                      {/*end::Row*/}
                      {/*begin::row*/}
                      <div className="row mb-7">
                        <label className="col-lg-4 fw-normal text-gray-700 fs-6">
                          Ship Method:
                        </label>

                        <div className="col-lg-8">
                          <span className="fw-bold fs-6 text-gray-800">
                            {orderDetails?.ship_method || "-"}
                          </span>
                        </div>
                      </div>
                      {/*end::row*/}
                      {/*begin::row*/}
                      <div className="row mb-7">
                        <label className="col-lg-4 fw-normal text-gray-700 fs-6">
                          Ordered:
                        </label>
                        <div className="col-lg-8 fv-row">
                          <span className="fw-bold text-gray-800 fs-6">
                            {moment(orderDetails?.order_date).format(
                              "MMM DD, YYYY"
                            ) || "-"}
                          </span>
                        </div>
                      </div>
                      {/*end::row*/}
                      {/*begin::row*/}
                      {/* <div className="row mb-7">
                        <label className="col-lg-4 fw-normal text-gray-700 fs-6">
                          Ship by:
                        </label>
                        <div className="col-lg-8 fv-row">
                          <span className="fw-bold text-gray-800 fs-6">
                            {orderDetails?.ship_date
                              ? moment(orderDetails?.ship_date).format(
                                  "MMM DD, YYYY"
                                )
                              : "-"}
                          </span>
                        </div>
                      </div> */}
                      {/*end::row*/}
                      {/*begin::row*/}
                      {/* <div className="row mb-7">
                        <label className="col-lg-4 fw-normal text-gray-700 fs-6">
                          Pickup by:
                        </label>
                        <div className="col-lg-8 fv-row">
                          <span className="fw-bold text-gray-800 fs-6">
                            {orderDetails?.pick_up_date
                              ? moment(orderDetails?.pick_up_date).format(
                                  "MMM DD, YYYY"
                                )
                              : "-"}
                          </span>
                        </div>
                      </div> */}
                      {/*end::row*/}
                      {/*begin::row*/}
                      <div className="row mb-7">
                        <label className="col-lg-4 fw-normal text-gray-700 fs-6">
                          Fulfilled by:
                        </label>
                        <div className="col-lg-8 fv-row">
                          <span className="fw-bold text-gray-800 fs-6">
                            <div className="d-flex align-items-center">
                              {/* <img
                            src="assets/media/walmart.jpg"
                            className="h-25px rounded-circle w-25px me-2"
                            alt=""
                          /> */}
                              <span>{orderDetails?.ship_node_type || "-"}</span>
                            </div>
                          </span>
                        </div>
                      </div>
                      {/*end::row*/}
                    </>
                  )}
                </div>
                <div className="col-md-5 mx-auto">
                  <div className=" bg-light p-7 px-7 rounded-1">
                    {/*begin::row*/}
                    {loading &&
                      loadingArr2?.map((d) => {
                        return (
                          <div className="row mb-7">
                            <label className="col-lg-4 fw-bold text-dark fs-6">
                              <Skeleton.Button active size="small" block />
                            </label>
                            <div className="col-lg-8 fv-row">
                              <span className="fw-bold text-gray-800 fs-6">
                                <Skeleton.Button active size="small" block />
                              </span>
                            </div>
                          </div>
                        );
                      })}
                    {!loading && (
                      <>
                        <div className="row mb-7">
                          <label className="col-lg-6 fw-bold text-dark fs-6">
                            Subtotal ({orderDetails?.order_units} Item)
                          </label>
                          <div className="col-lg-6 fv-row">
                            <span className="fw-bold text-gray-800 fs-6">
                              $
                              {NumberWithCommas(
                                orderDetails?.order_sub_total || 0
                              )}
                            </span>
                          </div>
                        </div>
                        {/*end::row*/}
                        {/*begin::row*/}
                        <div className="row mb-7">
                          <label className="col-lg-6 fw-normal text-gray-700 fs-6">
                            Shipping Fee:
                          </label>
                          <div className="col-lg-6 fv-row">
                            <span className="fw-bold text-gray-800 fs-6">
                              $
                              {NumberWithCommas(
                                orderDetails?.order_shipping || 0
                              )}
                            </span>
                          </div>
                        </div>
                        {/*end::row*/}
                        {/*begin::row*/}
                        <div className="row mb-7">
                          <label className="col-lg-6 fw-normal text-gray-700 fs-6">
                            Taxes:
                          </label>
                          <div className="col-lg-6 fv-row">
                            <span className="fw-bold text-gray-800 fs-6">
                              ${NumberWithCommas(orderDetails?.order_tax || 0)}
                            </span>
                          </div>
                        </div>
                        {/*end::row*/}
                        <div className="separator separator-dashed borde-gray-300 my-4 w-75" />
                        {/*begin::row*/}
                        <div className="row mb-7">
                          <label className="col-lg-6 fw-bold text-dark fs-6">
                            Total:
                          </label>
                          <div className="col-lg-6 fv-row">
                            <span className="fw-bold text-green fs-6">
                              $
                              {NumberWithCommas(orderDetails?.order_total || 0)}
                            </span>
                          </div>
                        </div>
                        {/*end::row*/}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row gy-5 g-xl-5 ">
        {/*begin::Col*/}
        <div className="col-xxl-12">
          {/*begin::Tables Widget 9*/}
          <div className="card card-xxl-stretch mb-5 mb-xl-8">
            {/*begin::Header*/}
            <div className="card-header border-bottom-dashed">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bolder text-dark">
                  Customer Details
                </span>
                {/* <span class="text-gray-400 pt-1 fw-semibold fs-6">Sales analytics by SKU</span> */}
              </h3>
              <div className="card-toolbar"></div>
            </div>
            {/*end::Header*/}
            {/*begin::Body*/}
            <div className="card-body p-9">
              <div className="row g-5 mb-0">
                <div className="col-md-6 ">
                  {/*begin::Row*/}
                  {loading &&
                    loadingArr2?.map((d) => {
                      return (
                        <div className="row mb-7">
                          <label className="col-lg-4 fw-bold text-dark fs-6">
                            <Skeleton.Button active size="small" block />
                          </label>
                          <div className="col-lg-8 fv-row">
                            <span className="fw-bold text-gray-800 fs-6">
                              <Skeleton.Button active size="small" block />
                            </span>
                          </div>
                        </div>
                      );
                    })}
                  {!loading && (
                    <>
                      <div className="row mb-7">
                        <label className="col-lg-4 fw-normal text-gray-700 fs-6">
                          Name:
                        </label>
                        <div className="col-lg-8">
                          <span className="fw-bold fs-6 text-gray-800 ">
                            {customer?.postalAddress?.name || "-"}
                          </span>
                        </div>
                      </div>
                      {/*end::Row*/}
                      {/*begin::row*/}
                      <div className="row mb-7">
                        <label className="col-lg-4 fw-normal text-gray-700 fs-6">
                          Address:
                        </label>
                        <div className="col-lg-8 fv-row">
                          <span className="fw-bold text-gray-800 fs-6">
                            {customer?.postalAddress?.address1}&nbsp;
                            {customer?.postalAddress?.address2}&nbsp;
                            {customer?.postalAddress?.city}&nbsp;
                            {customer?.postalAddress?.state}&nbsp;
                            {customer?.postalAddress?.country}&nbsp;,
                            {customer?.postalAddress?.postalCode}&nbsp;
                          </span>
                        </div>
                      </div>
                      {/*end::row*/}
                      {/*begin::row*/}
                      <div className="row mb-7">
                        <label className="col-lg-4 fw-normal text-gray-700 fs-6">
                          Contact Number:
                        </label>
                        <div className="col-lg-8 fv-row">
                          <span className="fw-bold text-gray-800 fs-6">
                            {customer?.phone || "-"}
                          </span>
                        </div>
                      </div>
                      {/*end::row*/}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TabOrderDetails;
