import { Popconfirm, Popover, Select, Tag, message } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import IvcsrTable from "../../../../component/ivcsr-table";
import Loading from "../../../../component/loading";
import NoData from "../../../../component/no-data";
import Pagination from "../../../../component/pagination";
import { DefaultPerPage, timeSince } from "../../../../config";
import SupplierModal from "./lib/supplier-modal";
export default function (props) {
  const { GetSupplierDataList, DeleteSupplierAction, fakeActionSupplier } =
    props;

  const GetSupplierDataListRes = useSelector(
    (state) => state.Supplier.GetSupplierDataListResponse || {}
  );
  const DeleteSupplierRes = useSelector(
    (state) => state.Supplier.DeleteSupplierResponse || {}
  );

  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageSize, setPageSize] = useState(DefaultPerPage);

  const [showSupplierSetup, setShowSupplierSetup] = useState(false);
  const [selectedSupplier, setSelectedSupplier] = useState([]);

  useEffect(() => {
    const obj = { page: 1, pageSize: pageSize };
    GetSupplierDataList(obj);
    return () => {};
  }, []);

  useEffect(() => {
    if (GetSupplierDataListRes.status === true) {
      setList(GetSupplierDataListRes?.data?.records);
      console.log(GetSupplierDataListRes, "GetSupplierDataListRes");
      setLoading(false);
      setTotalPage(GetSupplierDataListRes?.data?.pagination?.totalCount);
      fakeActionSupplier("GetSupplierDataListResponse");
    } else if (GetSupplierDataListRes.status === false) {
      setLoading(false);
      fakeActionSupplier("GetSupplierDataListResponse");
    }
  }, [GetSupplierDataListRes]);

  useEffect(() => {
    if (DeleteSupplierRes.status === true) {
      message.destroy();
      setPage(1);
      setPageSize(DefaultPerPage);
      const obj = { page: 1, pageSize: pageSize };
      setLoading(true);
      GetSupplierDataList(obj);
      fakeActionSupplier("DeleteSupplierResponse");
    } else if (DeleteSupplierRes.status === false) {
      message.destroy();
      fakeActionSupplier("DeleteSupplierResponse");
    }
  }, [DeleteSupplierRes]);

  const columns = [
    {
      title: "Sr. No.",
      ellipsis: true,
      align: "left",
      render: (text) => {
        return <span>{(page - 1) * pageSize + 1 + text.key}</span>;
      },
    },

    {
      title: "Supplier Name",
      align: "left",
      render: (item) => {
        return (
          <div>
            <span>{item?.supplier_name}</span>
          </div>
        );
      },
    },
    {
      title: "Contact Name",
      align: "left",
      render: (item) => {
        return (
          <div>
            <span>{item?.contact_name}</span>
          </div>
        );
      },
    },
    {
      title: "Email",
      align: "left",
      render: (item) => {
        return (
          <div>
            <span>{item?.email}</span>
          </div>
        );
      },
    },
    {
      title: "Phone Number",
      align: "left",
      render: (item) => {
        return (
          <div>
            <span>{item?.phone_number}</span>
          </div>
        );
      },
    },
    {
      title: "Address",
      align: "left",
      render: (item) => {
        return (
          <div>
            <span>{item?.address}</span>
          </div>
        );
      },
    },
    {
      title: "Created At",
      align: "left",
      render: (item) => {
        return (
          <div>
            <span>
              {moment(new Date(item?.created_at * 1000)).format(
                "MMM DD, YYYY hh:mm A"
              )}
            </span>
            <br />
            <span className="timeStampColor">
              ({timeSince(item?.created_at)})
            </span>
          </div>
        );
      },
    },
    {
      title: "Action",
      align: "center",
      render: (item) => {
        return (
          <div>
            <button
              onClick={() => {
                setShowSupplierSetup(true);
                setSelectedSupplier(item);
              }}
              className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2"
            >
              <i className="ki-outline ki-pencil fs-2 text-info" />
            </button>
            <Popconfirm
              title="Are you sure to delete this Supplier?"
              placement="left"
              cancelText="No"
              okText="Yes"
              onConfirm={() => {
                message.loading("Deleting...", 0);
                console.log(item?.id);
                DeleteSupplierAction(item?.id);
              }}
            >
              <button className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                <i className="ki-outline ki-trash fs-2 text-danger" />
              </button>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  const onPageNo = (e) => {
    setLoading(true);
    const apiObj = {
      page: e,
      perPage: pageSize,
    };
    GetSupplierDataList(apiObj);
    setPage(e);
  };

  const onPerPage = (e) => {
    setPage(1);
    setPageSize(e);
    setLoading(true);
    const apiObj = {
      page: 1,
      perPage: e,
    };
    GetSupplierDataList(apiObj);
  };

  return (
    <div className="fadeInRight">
      <>
        <div className="d-flex flex-column flex-column-fluid">
          <div id="kt_app_content" className="app-content flex-column-fluid">
            <div
              id="kt_app_content_container"
              className="app-container container-fluid"
            >
              {/*begin::Row*/}
              <div className="row gy-5 g-xl-5">
                {/*begin::Col*/}
                <div className="col-xxl-12">
                  {/*begin::Tables Widget 9*/}
                  <div className="card card-xxl-stretch mb-5 mb-xl-8">
                    {/*begin::Header*/}
                    <div className="card-header border-bottom-dashed">
                      {/*end::Title*/}
                      {/*begin::Title*/}
                      <h3 className="card-title ">
                        <span className="card-label fw-bold text-gray-900">
                          Supplier
                        </span>
                      </h3>
                      {/*end::Title*/}
                      <div className="card-toolbar">
                        <div className="position-relative mr-4"></div>
                        <div className="position-relative ms-3">
                          {" "}
                          <button
                            className="btn btn-primary "
                            onClick={() => {
                              setShowSupplierSetup(true);
                            }}
                          >
                            Add Supplier
                          </button>
                        </div>
                      </div>
                    </div>
                    {/*end::Header*/}
                    {/*begin::Body*/}
                    <div className="card-body pb-5 pt-0">
                      {/*begin::Table container*/}
                      <div className="table-responsive mt-5">
                        {loading ? (
                          <Loading />
                        ) : list?.length !== 0 ? (
                          <>
                            <IvcsrTable
                              columns={columns}
                              fixed="top"
                              dataSource={list}
                              rowKey="key"
                              loading={loading}
                              pagination={false}
                              scroll={{ x: "max-content" }}
                            />
                          </>
                        ) : (
                          <NoData type="new" />
                        )}
                      </div>
                      <Pagination
                        loading={loading || list?.length === 0}
                        pageSize={pageSize}
                        page={page}
                        totalPage={totalPage}
                        onPerPage={onPerPage}
                        onPageNo={onPageNo}
                      />
                    </div>
                    {/*begin::Body*/}
                  </div>
                  {/*end::Tables Widget 9*/}
                </div>
                {/*end::Col*/}
              </div>
              {/*end::Row*/}
            </div>
          </div>
        </div>
      </>
      <SupplierModal
        show={showSupplierSetup}
        selectedSupplier={selectedSupplier}
        onHide={() => {
          setShowSupplierSetup(false);
          setSelectedSupplier([]);
        }}
        onSuccess={() => {
          setShowSupplierSetup(false);
          setSelectedSupplier([]);
          setLoading(true);
          const obj = { page: 1, pageSize: pageSize };
          GetSupplierDataList(obj);
        }}
        {...props}
      />
    </div>
  );
}
