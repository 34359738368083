import React, { useEffect, useRef, useState } from "react";
import AdvDahboardCard from "./lib/adv-dahboard-card";
import AdvDetailCard from "./lib/adv-detail-card";
import AdvSalesOverviewChart from "./lib/adv-sales-overview-chart";
import AdvAudience from "./lib/adv-audience";
import RangePickerIVCSR from "../../../../component/range-picker";
import { DateFormat, SalesChannelFlag } from "../../../../config";
import dayjs from "dayjs";
import { Select, Tooltip } from "antd";
import { useSelector } from "react-redux";
import moment from "moment";
import AdvTopSearchTerm from "./lib/adv-top-search-term";
import Loading from "../../../../component/loading";
import { useReactToPrint } from "react-to-print";
import { FilePdfOutlined, FileExcelOutlined } from "@ant-design/icons";

export default function (props) {
  const {
    // GetSalesChannelList,
    fakeActionSalesAnalytics,
    GetAdvFilters,
    GetAdvDashboardGraphCardData,
    GetAdvDashboardSubCardData,
    GetActiveCampaignSubCardData,
    GetTargetingSubCardData,
    GetDailyPerformanceTrend,
    GetTopSearchTermData,
    fakeActionAdvDashboard,
  } = props;

  // const GetSalesChannelListRes = useSelector(
  //   (state) => state.SalesAnalytics.GetSalesChannelListResponse || {}
  // );
  const GetFiltersListRes = useSelector(
    (state) => state.AdvDashboard.GetFiltersListResponse || {}
  );
  const GetDashboardCardGraphDataRes = useSelector(
    (state) => state.AdvDashboard.GetDashboardCardGraphDataResponse || {}
  );
  const GetDashboardSubCardDataRes = useSelector(
    (state) => state.AdvDashboard.GetDashboardSubCardDataResponse || {}
  );
  const GetActiveCampaignSubCardDataRes = useSelector(
    (state) => state.AdvDashboard.GetActiveCampaignSubCardDataResponse || {}
  );
  const GetTargetingSubCardDataRes = useSelector(
    (state) => state.AdvDashboard.GetTargetingSubCardDataResponse || {}
  );
  const GetDailyPerformanceTrendDataRes = useSelector(
    (state) => state.AdvDashboard.GetDailyPerformanceTrendDataResponse || {}
  );
  const GetTopSearchTermDataRes = useSelector(
    (state) => state.AdvDashboard.GetTopSearchTermDataResponse || {}
  );

  // const [salesChannelList, setsalesChannelList] = useState([]);
  // const [selectedSalesChannel, setSelectedSalesChannel] = useState([]);
  // const [selectedSalesChannelId, setSelectedSalesChannelId] = useState();
  // const [salesChannelLoading, setSalesChannelLoading] = useState(true);
  // const [selectedCurrencySign, setSelectedCurrencySign] = useState("");

  const [matchTypeDataFilters, setMatchTypeDataFilters] = useState([]);
  const [advSalesChannelData, setAdvSalesChannelData] = useState([]);
  const [selectedadvSalesChannel, setSelectedadvSalesChannel] = useState([]);
  const [advDailyTrendData, setAdvDailyTrendData] = useState([]);
  const [selectedMatchFileter, setSelectedMatchFilter] = useState([
    "EXACT",
    "PHRASE",
    "BROAD",
    "TARGETING_EXPRESSION_PREDEFINED",
    "TARGETING_EXPRESSION",
  ]);

  const [advTypeDataFilters, setAdvTypeDataFilters] = useState([]);
  const [selectedAdvTypeFileter, setSelectedAdvTypeFilter] = useState([]);

  const [allAdsPerformanceData, setAllAdsPerformanceData] = useState([]);
  const [subCardDataList, setSubCardDataList] = useState([]);
  const [activeCampaignSubCardData, setActiveCampaignSubCardData] = useState(
    []
  );
  const [targetingSubCardData, setTargetingSubCardData] = useState([]);
  const [topSearchTermTableData, setTopSearchTermTableData] = useState([]);

  const [selectedTrendChartFilter, setSelectedTrendChartFilter] = useState([
    "roas",
    "spend",
    "sales",
    "acos",
  ]);

  const [chartXAxis, setchartXAxis] = useState([]);
  const [chartSeries, setchartSeries] = useState([]);
  const [exportPerformanceTrendGraph, setExportPerformanceTrendGraph] =
    useState([]);

  const [dashboardMainCardLoading, setDashboardMainCardLoading] =
    useState(true);

  const [dashboardSubCardLoading, setDashboardSubCardLoading] = useState(true);
  const [activeCampaignCardLoading, setActiveCampaignCardLoading] =
    useState(true);
  const [targetingCardLoading, setTargetingCardLoading] = useState(true);
  const [dailyTrendChartLoading, setDailyTrendChartLoading] = useState(true);
  const [topSearchTermLoading, setTopSearchTermLoading] = useState(true);
  // const [chartSeries, setchartSeries] = useState([]);
  // const [chartSeries, setchartSeries] = useState([]);
  // const [chartSeries, setchartSeries] = useState([]);

  const [filters, setFilters] = useState({
    start_date: dayjs().add(-7, "d"),
    end_date: dayjs(),
  });

  useEffect(() => {
    // GetSalesChannelList();
    GetAdvFilters();
  }, []);

  // useEffect(() => {
  //   if (GetSalesChannelListRes?.status === true) {
  //     setSalesChannelLoading(false);
  //     setsalesChannelList(GetSalesChannelListRes?.data?.sales_channel);
  //     setSelectedSalesChannelId("ATVPDKIKX0DER");
  //     setSelectedSalesChannel("Amazon.com");
  //     setSelectedCurrencySign("$");
  //     fakeActionSalesAnalytics("GetSalesChannelListResponse");
  //   } else if (GetSalesChannelListRes?.status === false) {
  //     fakeActionSalesAnalytics("GetSalesChannelListResponse");
  //   }
  // }, [GetSalesChannelListRes]);

  useEffect(() => {
    if (GetFiltersListRes?.status === true) {
      const newMatchTypeData = Object?.entries(
        GetFiltersListRes?.data?.match_type_data
      )?.map((d) => {
        return { label: d?.[1], value: d?.[0] };
      });

      const newAdvTypeData = Object?.entries(
        GetFiltersListRes?.data?.advertising_type_data
      )?.map((d) => {
        return { label: d?.[1], value: d?.[0] };
      });

      const newAdvDailyTrendData = Object?.entries(
        GetFiltersListRes?.data?.metrics_data
      )?.map((d) => {
        return { label: d?.[1], value: d?.[0] };
      });
      // console.log(newAdvDailyTrendData, "newAdvDailyTrendData");
      const newAdvSalesChannelList = Object?.entries(
        GetFiltersListRes?.data?.profile_data
      )?.map((d, i) => {
        return {
          marketplace: d[1]?.[0]?.marketplace,
          marketplace_id: d[1]?.[0]?.marketplace_id,
        };
      });

      setSelectedadvSalesChannel(newAdvSalesChannelList?.[2]?.marketplace_id);
      setAdvTypeDataFilters(newAdvTypeData);
      setMatchTypeDataFilters(newMatchTypeData);
      setAdvDailyTrendData(newAdvDailyTrendData);
      setAdvSalesChannelData(newAdvSalesChannelList);

      const GraphCardpAiObj = {
        marketplace_id: newAdvSalesChannelList?.[2]?.marketplace_id,
        match_type: selectedMatchFileter,
        request_type: "Current",
        start_date: moment(filters?.start_date?.$d).format("YYYY-MM-DD"),
        end_date: moment(filters?.end_date?.$d).format("YYYY-MM-DD"),
      };
      setDashboardMainCardLoading(true);
      GetAdvDashboardGraphCardData(GraphCardpAiObj);

      const subDashboardApiObj = {
        marketplace_id: newAdvSalesChannelList?.[2]?.marketplace_id,
        request_type: "Current",
        metrics_type: selectedAdvTypeFileter,
        start_date: moment(filters?.start_date?.$d).format("YYYY-MM-DD"),
        end_date: moment(filters?.end_date?.$d).format("YYYY-MM-DD"),
      };
      setDashboardSubCardLoading(true);
      GetAdvDashboardSubCardData(subDashboardApiObj);

      setActiveCampaignCardLoading(true);
      GetActiveCampaignSubCardData(subDashboardApiObj);

      setTargetingCardLoading(true);
      GetTargetingSubCardData(subDashboardApiObj);

      const dailyTrendObj = {
        marketplace_id: newAdvSalesChannelList?.[2]?.marketplace_id,
        request_type: "Current",
        metrics_type: selectedAdvTypeFileter,
        trend_type: selectedTrendChartFilter,
        start_date: moment(filters?.start_date?.$d).format("YYYY-MM-DD"),
        end_date: moment(filters?.end_date?.$d).format("YYYY-MM-DD"),
      };
      GetDailyPerformanceTrend(dailyTrendObj);

      const topSearchTerm = {
        marketplace_id: newAdvSalesChannelList?.[2]?.marketplace_id,
        start_date: moment(filters?.start_date?.$d).format("YYYY-MM-DD"),
        end_date: moment(filters?.end_date?.$d).format("YYYY-MM-DD"),
      };
      GetTopSearchTermData(topSearchTerm);

      fakeActionAdvDashboard("GetFiltersListResponse");
    } else if (GetFiltersListRes?.status === false) {
      fakeActionAdvDashboard("GetFiltersListResponse");
    }
  }, [GetFiltersListRes]);

  useEffect(() => {
    if (GetDashboardCardGraphDataRes?.status === true) {
      setAllAdsPerformanceData(
        GetDashboardCardGraphDataRes?.data?.all_ads_performance_data
      );
      setDashboardMainCardLoading(false);
      fakeActionAdvDashboard("GetDashboardCardGraphDataResponse");
    } else if (GetDashboardCardGraphDataRes?.status === false) {
      fakeActionAdvDashboard("GetDashboardCardGraphDataResponse");
    }
  }, [GetDashboardCardGraphDataRes]);

  useEffect(() => {
    if (GetDashboardSubCardDataRes?.status === true) {
      setDashboardSubCardLoading(false);
      setSubCardDataList(GetDashboardSubCardDataRes?.data);
      fakeActionAdvDashboard("GetDashboardSubCardDataResponse");
    } else if (GetDashboardSubCardDataRes?.status === false) {
      fakeActionAdvDashboard("GetDashboardSubCardDataResponse");
    }
  }, [GetDashboardSubCardDataRes]);

  useEffect(() => {
    if (GetActiveCampaignSubCardDataRes?.status === true) {
      setActiveCampaignCardLoading(false);
      setActiveCampaignSubCardData(GetActiveCampaignSubCardDataRes?.data);
      fakeActionAdvDashboard("GetActiveCampaignSubCardDataResponse");
    } else if (GetActiveCampaignSubCardDataRes?.status === false) {
      fakeActionAdvDashboard("GetActiveCampaignSubCardDataResponse");
    }
  }, [GetActiveCampaignSubCardDataRes]);

  useEffect(() => {
    if (GetTargetingSubCardDataRes?.status === true) {
      setTargetingCardLoading(false);
      setTargetingSubCardData(GetTargetingSubCardDataRes?.data?.targeting_data);
      fakeActionAdvDashboard("GetTargetingSubCardDataResponse");
    } else if (GetTargetingSubCardDataRes?.status === false) {
      fakeActionAdvDashboard("GetTargetingSubCardDataResponse");
    }
  }, [GetTargetingSubCardDataRes]);

  useEffect(() => {
    if (GetTopSearchTermDataRes?.status === true) {
      setTopSearchTermLoading(false);
      setTopSearchTermTableData(GetTopSearchTermDataRes?.data?.data);
      fakeActionAdvDashboard("GetTopSearchTermDataResponse");
    } else if (GetTopSearchTermDataRes?.status === false) {
      fakeActionAdvDashboard("GetTopSearchTermDataResponse");
    }
  }, [GetTopSearchTermDataRes]);

  useEffect(() => {
    if (GetDailyPerformanceTrendDataRes?.status === true) {
      // console.log(GetDailyPerformanceTrendDataRes?.data?.data);
      const OriginalData = GetDailyPerformanceTrendDataRes?.data?.data;
      const chartxAxis = OriginalData?.map((d, i) => {
        return d?.report_date;
      });

      const seriesData = selectedTrendChartFilter?.map((a, j) => {
        // console.log(a, "-a");

        const localData = OriginalData?.map((d, i) => {
          return parseFloat(d[a]);
        });
        return { name: a, data: localData };
      });
      setDailyTrendChartLoading(false);
      setchartSeries(seriesData);
      setchartXAxis(chartxAxis);
      setExportPerformanceTrendGraph(
        GetDailyPerformanceTrendDataRes?.data?.data
      );

      fakeActionAdvDashboard("GetDailyPerformanceTrendDataResponse");
    } else if (GetDailyPerformanceTrendDataRes?.status === false) {
      fakeActionAdvDashboard("GetDailyPerformanceTrendDataResponse");
    }
  }, [GetDailyPerformanceTrendDataRes]);

  const chartRef = useRef(null);

  const handleExportPDF = useReactToPrint({
    content: () => chartRef.current,
    documentTitle: "Targeting Chart",
    onAfterPrint: () => {
      // message.destroy();
      // message.success("PDF Downloaded");
      // // Callback after printing, you can use it to trigger further actions.
      // console.log("Printed successfully!");
    },
    pageStyle: `
      @page {
        size: A4 landspace;
        margin: 0;
      }
      body {
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100vh;
      }
    `,
  });
  return (
    <div className="d-flex flex-column flex-column-fluid fadeInRight">
      <div id="kt_app_content" className="app-content flex-column-fluid">
        <div
          id="kt_app_content_container"
          className="app-container container-fluid"
        >
          <div className="row g-5 g-xl-5">
            <div className="col-12 my-3 d-flex">
              <div className="me-3">
                <RangePickerIVCSR
                  className="ant_common_input"
                  id="ivcsr_date_picker"
                  size="large"
                  allowClear={false}
                  style={{ width: "290px" }}
                  format={DateFormat}
                  value={[filters?.start_date, filters?.end_date]}
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  onChange={(e) => {
                    const filters_ = filters;
                    filters_.start_date = e?.[0];
                    filters_.end_date = e?.[1];
                    setFilters({ ...filters_ });

                    const GraphCardpAiObj = {
                      marketplace_id: selectedadvSalesChannel,
                      match_type: selectedMatchFileter,
                      request_type: "Current",
                      // metrics_type: selectedAdvTypeFileter,
                      // start_date: "2023-10-26",
                      // end_date: "2023-11-25",
                      start_date: moment(e?.[0]?.$d).format("YYYY-MM-DD"),
                      end_date: moment(e?.[1]?.$d).format("YYYY-MM-DD"),
                    };
                    setDashboardMainCardLoading(true);
                    GetAdvDashboardGraphCardData(GraphCardpAiObj);

                    const subDashboardApiObj = {
                      marketplace_id: selectedadvSalesChannel,
                      request_type: "Current",
                      metrics_type: selectedAdvTypeFileter,
                      start_date: moment(e?.[0]?.$d).format("YYYY-MM-DD"),
                      end_date: moment(e?.[1]?.$d).format("YYYY-MM-DD"),
                    };
                    GetAdvDashboardSubCardData(subDashboardApiObj);
                    GetActiveCampaignSubCardData(subDashboardApiObj);
                    GetTargetingSubCardData(subDashboardApiObj);

                    const dailyTrendObj = {
                      marketplace_id: selectedadvSalesChannel,
                      request_type: "Current",
                      metrics_type: selectedAdvTypeFileter,
                      trend_type: selectedTrendChartFilter,
                      start_date: moment(e?.[0]?.$d).format("YYYY-MM-DD"),
                      end_date: moment(e?.[1]?.$d).format("YYYY-MM-DD"),
                    };
                    GetDailyPerformanceTrend(dailyTrendObj);

                    setTopSearchTermLoading(true);
                    const topSearchTerm = {
                      marketplace_id: selectedadvSalesChannel,
                      start_date: moment(e?.[0]?.$d).format("YYYY-MM-DD"),
                      end_date: moment(e?.[1]?.$d).format("YYYY-MM-DD"),
                    };
                    GetTopSearchTermData(topSearchTerm);
                  }}
                />
              </div>
              <div className="me-3">
                <Select
                  className="w-250px"
                  size="large"
                  placeholder="Select Sales Channel"
                  options={advSalesChannelData?.map((d, i) => {
                    return {
                      label: (
                        <>
                          <img
                            src={`/assets//media/domainImage/${d?.marketplace_id}.png`}
                            style={{ height: "20px" }}
                            loading="lazy"
                            className="me-3"
                            onError={(e) => {
                              e.target.src =
                                "/assets/media/domainImage/red-flag.png";
                            }}
                          />
                          {d?.marketplace}
                        </>
                      ),
                      value: d?.marketplace_id,
                    };
                  })}
                  value={selectedadvSalesChannel}
                  onChange={(e) => {
                    setSelectedadvSalesChannel(e);
                    const GraphCardpAiObj = {
                      marketplace_id: e,
                      match_type: selectedMatchFileter,
                      request_type: "Current",
                      metrics_type: selectedAdvTypeFileter,
                      start_date: moment(filters?.start_date?.$d).format(
                        "YYYY-MM-DD"
                      ),
                      end_date: moment(filters?.end_date?.$d).format(
                        "YYYY-MM-DD"
                      ),
                    };
                    setDashboardMainCardLoading(true);
                    GetAdvDashboardGraphCardData(GraphCardpAiObj);

                    setTopSearchTermLoading(true);
                    const topSearchTerm = {
                      marketplace_id: e,
                      start_date: moment(filters?.start_date?.$d).format(
                        "YYYY-MM-DD"
                      ),
                      end_date: moment(filters?.end_date?.$d).format(
                        "YYYY-MM-DD"
                      ),
                    };
                    GetTopSearchTermData(topSearchTerm);
                  }}
                />

                {/* <Select
                  className="w-200px"
                  size="large"
                  placeholder="Select Sales Channel"
                  loading={salesChannelLoading}
                  value={selectedSalesChannelId || null}
                  onChange={(e, _) => {
                    setSelectedSalesChannel(_?.sales_channel);
                    setSelectedSalesChannelId(e);
                    const GraphCardpAiObj = {
                      marketplace_id: e,
                      match_type: selectedMatchFileter,
                      request_type: "Current",
                      metrics_type: selectedAdvTypeFileter,
                      // start_date: "2023-10-26",
                      // end_date: "2023-11-25",
                      start_date: moment(filters?.start_date?.$d).format(
                        "YYYY-MM-DD"
                      ),
                      end_date: moment(filters?.end_date?.$d).format(
                        "YYYY-MM-DD"
                      ),
                    };
                    setDashboardMainCardLoading(true);
                    GetAdvDashboardGraphCardData(GraphCardpAiObj);
                    GetAdvDashboardSubCardData();
                    GetActiveCampaignSubCardData();
                    GetTargetingSubCardData();
                    GetDailyPerformanceTrend();
                  }}
                  options={SalesChannelFlag(salesChannelList)}
                /> */}
              </div>
              <div className="me-3">
                <Select
                  className="w-200px"
                  size="large"
                  placeholder="Select"
                  mode="multiple"
                  onChange={(e) => {
                    setSelectedMatchFilter(e);
                    const GraphCardpAiObj = {
                      marketplace_id: selectedadvSalesChannel,
                      match_type: e,
                      request_type: "Current",
                      metrics_type: selectedAdvTypeFileter,
                      start_date: moment(filters?.start_date?.$d).format(
                        "YYYY-MM-DD"
                      ),
                      end_date: moment(filters?.end_date?.$d).format(
                        "YYYY-MM-DD"
                      ),
                    };
                    setDashboardMainCardLoading(true);
                    GetAdvDashboardGraphCardData(GraphCardpAiObj);
                  }}
                  maxTagCount={"responsive"}
                  value={selectedMatchFileter}
                  options={matchTypeDataFilters?.map((d) => {
                    return { label: d?.label, value: d?.value };
                  })}
                />
              </div>
              <div className="me-3">
                <Select
                  className="w-200px"
                  size="large"
                  placeholder="Select"
                  mode="multiple"
                  onChange={(e) => {
                    setSelectedAdvTypeFilter(e);

                    const subDashboardApiObj = {
                      marketplace_id: selectedadvSalesChannel,
                      request_type: "Current",
                      metrics_type: e,
                      start_date: moment(filters?.start_date?.$d).format(
                        "YYYY-MM-DD"
                      ),
                      end_date: moment(filters?.end_date?.$d).format(
                        "YYYY-MM-DD"
                      ),
                    };
                    setDashboardSubCardLoading(true);
                    setActiveCampaignCardLoading(true);
                    setTargetingCardLoading(true);

                    GetAdvDashboardSubCardData(subDashboardApiObj);
                    GetActiveCampaignSubCardData(subDashboardApiObj);
                    GetTargetingSubCardData(subDashboardApiObj);

                    const dailyTrendObj = {
                      marketplace_id: selectedadvSalesChannel,
                      request_type: "Current",
                      metrics_type: e,
                      trend_type: selectedTrendChartFilter,
                      start_date: moment(filters?.start_date?.$d).format(
                        "YYYY-MM-DD"
                      ),
                      end_date: moment(filters?.end_date?.$d).format(
                        "YYYY-MM-DD"
                      ),
                    };
                    setDailyTrendChartLoading(true);
                    GetDailyPerformanceTrend(dailyTrendObj);
                  }}
                  maxTagCount={"responsive"}
                  value={selectedAdvTypeFileter}
                  options={advTypeDataFilters?.map((d) => {
                    return { label: d?.label, value: d?.value };
                  })}
                />
              </div>
              {/* <div className="d-md-flex">
                    <div className="position-relative me-3 mb-4 mb-md-0"></div>
                    <div className="min-w-150px mb-4 mb-md-0"></div>
                    <div className=" ms-3"></div>
                  </div> */}
            </div>
          </div>

          <div className="card card-xxl-stretch mb-5 mb-xl-5">
            <div className="card-header pt-5 border-bottom-0">
              <label className="card-title align-items-start flex-column">
                <span className="fw-bold text-dark fs-2">Targeting</span>
              </label>

              <div className="card-toolbar">
                <span
                  className="d-flex align-items-center cursor-pointer me-5"
                  onClick={() => {
                    handleExportPDF();
                  }}
                >
                  <Tooltip title="Download PDF" placement="bottom">
                    <FilePdfOutlined
                      className="fs-1"
                      style={{ color: "red" }}
                    />
                  </Tooltip>
                </span>
              </div>
            </div>

            <div className="card-body py-3">
              <AdvDahboardCard
                allAdsPerformanceData={allAdsPerformanceData}
                dashboardMainCardLoading={dashboardMainCardLoading}
                setDashboardMainCardLoading={setDashboardMainCardLoading}
                targetingSubCardData={targetingSubCardData}
                chartRef={chartRef}
                start_date={moment(filters?.start_date?.$d).format(
                  "MMM DD, YYYY"
                )}
                end_date={moment(filters?.end_date?.$d).format("MMM DD, YYYY")}
              />
            </div>
          </div>
          <div className="separator separator-dashed my-1" />

          <div className="card card-xxl-stretch mb-5 mb-xl-5 mt-5">
            <div className="card-header pt-5 border-bottom-0">
              <label className="card-title align-items-start flex-column">
                <span className="fw-bold text-dark fs-2">Campaign</span>
              </label>

              <div className="card-toolbar"></div>
            </div>

            <div className="card-body py-3">
              <div className="row mb-5">
                <AdvDetailCard
                  cardData={subCardDataList}
                  activeCampaignSubCardData={activeCampaignSubCardData}
                  targetingSubCardData={targetingSubCardData}
                  dashboardSubCardLoading={dashboardSubCardLoading}
                  setDashboardSubCardLoading={setDashboardSubCardLoading}
                  activeCampaignCardLoading={activeCampaignCardLoading}
                  targetingCardLoading={targetingCardLoading}
                />
              </div>
            </div>
          </div>

          <div className="separator separator-dashed my-1" />

          {/* <div className="row gy-5 g-xl-5">
            <div className="col-xxl-7">
              <AdvSalesOverviewChart />
            </div>

            <div className="col-xxl-5">
              <AdvAudience />
            </div>
          </div> */}

          <div className="row mt-5">
            <div className="col-xxl-12">
              <AdvSalesOverviewChart
                selectedFilter={selectedTrendChartFilter}
                setSelectedFilter={setSelectedTrendChartFilter}
                chartSeries={chartSeries}
                chartXAxis={chartXAxis}
                advDailyTrendData={advDailyTrendData}
                dailyTrendChartLoading={dailyTrendChartLoading}
                exportPerformanceTrendGraph={exportPerformanceTrendGraph}
                onChangeMatricsFilter={(e) => {
                  const dailyTrendObj = {
                    marketplace_id: selectedadvSalesChannel,
                    request_type: "Current",
                    metrics_type: selectedAdvTypeFileter,
                    trend_type: e,
                    start_date: moment(filters?.start_date?.$d).format(
                      "YYYY-MM-DD"
                    ),
                    end_date: moment(filters?.end_date?.$d).format(
                      "YYYY-MM-DD"
                    ),
                  };
                  setDailyTrendChartLoading(true);
                  GetDailyPerformanceTrend(dailyTrendObj);
                }}
                {...props}
              />
            </div>
          </div>

          <div className="row gy-5 g-xl-5">
            <div className="col-xxl-12">
              {topSearchTermLoading ? (
                <Loading />
              ) : (
                <AdvTopSearchTerm
                  tableData={topSearchTermTableData}
                  {...props}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
