import React, { useContext, useEffect } from "react";
import { GlobalContext } from "../../../../commonContext";
import { useHistory } from "react-router-dom";
import Walmart from "./walmart";

import Amazon from "./amazon-inventory";
export default function (props) {
  const history = useHistory();
  const contextVar = useContext(GlobalContext);

  return contextVar?.data?.marketplaceType === "amazon" ? (
    <Amazon {...props} />
  ) : (
    <Walmart {...props} />
  );
}
