import axiosCall from "../../../configurations/network-services/axiosCall";

// const prefix = "dashboard";

export const GetDataSchedulerList = (data) => {
  const path = `user-scheduler?page=${data?.page || 1}&per-page=${
    data?.perPage || 10
  }&marketplace_id=${data?.marketplaceId || ""}&marketplace_type=${data?.marketplaceType || ''}`;
  const responseType = "GET_DATA_SCHEDULER_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const fakeActionDataScheduler = (data) => async (dispatch) => {
  dispatch({ type: "FAKE_ACTION_DATA_SCHEDULER", state: data });
};
