import { Alert, Input, Popover, message } from "antd";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import ReactJson from "react-json-view";
import { useSelector } from "react-redux";
import IvcsrTable from "../../../../component/ivcsr-table";
export default function ({ show, onHide, errorModalMessage }) {
  const columns = [
    {
      title: "SKU",
      dataIndex: "sku",
      key: "sku",
    },
    {
      title: "Row Number",
      dataIndex: "row",
      key: "row",
    },
    {
      title: "Field",
      dataIndex: "field",
      key: "field",
    },
    {
      title: "Message",
      dataIndex: "message",
      key: "message",
      width: 400,
      render: (text) => {
        return (
          <Popover
            content={
              <div
                style={{
                  height: "max-content",
                  width: "600px",
                }}
              >
                {text}
              </div>
            }
            placement="bottom"
            getPopupContainer={(triggerNode) =>
              document.getElementById("test").parentNode
            }
          >
            <div
              className="text-danger mb-1 fw-bolder text-one fs-6 popoverActionIconSingleLine"
              style={{ minWidth: "100px" }}
            >
              {text}
            </div>
          </Popover>
        );
      },
    },
    {
      title: "Incorrect Data",
      dataIndex: "value",
      key: "value",
    },
  ];
  return (
    <>
      <Modal show={show} onHide={onHide} size="xl" centered backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Erorr Log</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <div className="">
              <div id="test" style={{ height: "500px", overflow: "auto" }}>
                <div>
                  <IvcsrTable
                    columns={columns?.map((d) => ({
                      ...d,
                      width:
                        d?.title === "Message"
                          ? 300
                          : d?.title === "SKU"
                          ? 200
                          : d?.title?.length * 13,
                    }))}
                    fixed={true}
                    sticky={{
                      offsetHeader: "0px",
                    }}
                    dataSource={errorModalMessage}
                    rowKey="key"
                    loading={false}
                    pagination={false}
                    scroll={{ x: "max-content", y: 700 }}
                  />
                </div>
              </div>
            </div>
          </>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-light-danger"
            onClick={onHide}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
