import axiosCall from "../../../configurations/network-services/axiosCall";

// const prefix = "dashboard";

export const GetMarketplaceCred = (data) => {
  const path = `user-credentials`;
  const responseType = "GET_MARKETPLACE_CRED_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetRegionList = (data) => {
  const path = `get-selling-region`;
  const responseType = "GET_REGION_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetRegionWiseMarketplaceList = (data) => {
  const path = `marketplaces/get-region-by-marketplace/${data}`;
  const responseType = "GET_REGION_WISE_MARKETPLACE_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const SPGenerateRefreshTokenAction = (data) => {
  const path = `user-credential/generate-refresh-token`;
  const responseType = "SP_GENERATE_REFRESH";
  return axiosCall("post", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const AdvGenerateRefreshTokenAction = (data) => {
  const path = `user-credential/generate-refresh-ad-token`;
  const responseType = "SP_GENERATE_ADV_REFRESH";
  return axiosCall("post", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const SaveAmazonAdvCredential = (data) => {
  const path = `user-credential/save-ad-profile`;
  const responseType = "SAVE_AMAZON_ADV_CREDENTIAL";
  return axiosCall("post", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetWalmartCredentialAction = (data) => {
  const path = `wm/credential?app_type=Walmart`;
  const responseType = "GET_WM_CREDENTIAL";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const StoreWMMarketplaceAction = (data) => {
  const path = `wm/credential/marketplace`;
  const responseType = "STORE_WM_MARKETPLACE";
  return axiosCall("post", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const StoreWMConnectAction = (data) => {
  const path = `wm/credential/connect`;
  const responseType = "STORE_WM_CONNECT";
  return axiosCall("post", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const fakeActionMarketplaceCred = (data) => async (dispatch) => {
  dispatch({ type: "FAKE_ACTION_MARKETPLACE_CRED", state: data });
};
