import React, { useEffect, useRef, useState } from "react";
// import AdvSalesOverviewTable from "./lib/adv-sales-overview-table";
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import {
  FileExcelOutlined,
  FilePdfOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { savePDF } from "@progress/kendo-react-pdf";
import { Checkbox, Dropdown, Skeleton, Tooltip, message, theme } from "antd";
import "daterangepicker";
import "daterangepicker/daterangepicker.css";
import dayjs from "dayjs";
import $ from "jquery";
import moment from "moment";
import Chart from "react-apexcharts";
import { useSelector } from "react-redux";
import "swiper/css"; // Import Swiper styles
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import writeXlsxFile from "write-excel-file";
import RangePickerIVCSR from "../../../../component/range-picker";
import { DateFormat, NumberWithCommas } from "../../../../config";
import WalmartCampaignsTable from "./lib/walmart-campaigns-table";
import WalmartExactBoardGraph from "./lib/walmart-exact-board-graph";
import WalmartPerformanceConversion from "./lib/walmart-performance-conversion";
const { useToken } = theme;

export default function (props) {
  const {
    GetSalesChannelList,
    fakeActionSalesAnalytics,
    GetWalmartAdvFilter,
    GetWalmartAdvPerformanceChart,
    GetWalmartPerformanceSummary,
    GetWalmartCampaignByAdSpend,
    GetWalmartCampaignOutOfBudget,
    GetWalmartDashboardPerformanceMetrics,
    fakeActionAdvDashboard,
  } = props;

  const GetSalesChannelListRes = useSelector(
    (state) => state.SalesAnalytics.GetSalesChannelListResponse || {}
  );

  const GetWalmartAdvFilterRes = useSelector(
    (state) => state.AdvDashboard.GetWalmartAdvFilterResponse || {}
  );

  const GetWalmartAdvPerformanceChartRes = useSelector(
    (state) => state.AdvDashboard.GetWalmartAdvPerformanceChartResponse || {}
  );

  const GetWalmartAdvPerformanceSummaryRes = useSelector(
    (state) => state.AdvDashboard.GetWalmartAdvPerformanceSummaryResponse || {}
  );

  const GetWalmartCampaignAdSpendRes = useSelector(
    (state) => state.AdvDashboard.GetWalmartCampaignAdSpendResponse || {}
  );

  const GetWalmartCampaignOutOfBudgetRes = useSelector(
    (state) => state.AdvDashboard.GetWalmartCampaignOutOfBudgetResponse || {}
  );

  const GetWalmartDashboardPerformanceMetricsRes = useSelector(
    (state) =>
      state.AdvDashboard.GetWalmartDashboardPerformanceMetricsResponse || {}
  );

  const [exportGraphArray, setExportGraphArray] = useState([]);
  const swiperNextPrev = useSwiper();
  const [salesChannelList, setsalesChannelList] = useState([]);
  const [selectedSalesChannel, setSelectedSalesChannel] = useState([]);
  const [selectedSalesChannelId, setSelectedSalesChannelId] = useState();
  const [salesChannelLoading, setSalesChannelLoading] = useState(true);
  const [selectedCurrencySign, setSelectedCurrencySign] = useState("");
  const [selectedFilterTab, setSelectedFilterTab] = useState("All");

  const [selectedChartArr, setSelectedChartArr] = useState([
    "impressions",
    "clicks",
    "ad_spend",
  ]);
  const [filterList, setFilterList] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([
    "impressions",
    "clicks",
    "ctr",
    "ad_spend",
    "unit_sold",
    "orders",
    "cr_unit_sold",
    "other_sku_sales",
    "cr_order_sold",
    "sales_revenue",
    "new_to_brand_order",
    "roas",
    "advertised_sku_sales",
    "in_store_advertised_sales",
    "in_store_attributed_sales",
    "in_store_orders",
    "in_store_other_sales",
    "in_store_units_sold",
  ]);
  const [filters, setFilters] = useState({
    start_date: dayjs().add(-30, "d"),
    end_date: dayjs(),
  });
  const [chartSeries, setChartSeries] = useState([]);
  const [chartDate, setChartDate] = useState([]);
  const [performanceSummary, setPerformanceSummary] = useState({});
  const [performanceMetrics, setPerformanceMetrics] = useState({});
  const [campaignByAdSpendData, setCampaignByAdSpendData] = useState([]);
  const [campaignOutOfBudget, setCampaignOutOfBudget] = useState([]);
  const chartRefFirstGraph = useRef(null);
  const { token } = useToken();
  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
    minWidth: "350px",
  };
  const dateRangePickerRef = useRef();
  const [performSummaryLoading, setPerformSummaryLoading] = useState(true);
  const [performanceMetricsLoading, setPerformanceMetricsLoading] =
    useState(true);
  const [campaignByTableLoading, setCampaignByTableLoading] = useState(true);
  const [outOfCampaignTableLoading, setOutOfCampaignTableLoading] =
    useState(true);

  useEffect(() => {
    // Initialize the date range picker
    $(dateRangePickerRef.current).daterangepicker({
      // Options for the date range picker
      autoApply: false,
      showDropdowns: false,
      ranges: {
        Today: [moment(), moment()],
        Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
        "Last 7 Days": [moment().subtract(6, "days"), moment()],
        "Last 30 Days": [moment().subtract(29, "days"), moment()],
        "This Month": [moment().startOf("month"), moment().endOf("month")],
        "Last Month": [
          moment().subtract(1, "month").startOf("month"),
          moment().subtract(1, "month").endOf("month"),
        ],
      },
      locale: {
        format: "MMM-DD,YYYY",
        separator: " - ",
        applyLabel: "Apply",
        cancelLabel: "Cancel",
        fromLabel: "From",
        toLabel: "To",
        customRangeLabel: "Custom",
        weekLabel: "W",
        daysOfWeek: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
        monthNames: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ],
        firstDay: 6,
      },
      alwaysShowCalendars: true,
      startDate: moment().subtract(6, "days"),
      endDate: moment(),
      opens: "right",
      // ... other options as needed
    });

    // You can also listen to events, e.g., when the user selects a new date range
    $(dateRangePickerRef.current).on(
      "apply.daterangepicker",
      (event, picker) => {
        console.log(
          "New date range selected:",
          picker.startDate.format("MM/DD/YYYY"),
          "to",
          picker.endDate.format("MM/DD/YYYY")
        );
      }
    );

    // Cleanup when the component is unmounted
    return () => {
      // $(dateRangePickerRef.current).data("daterangepicker").remove();
    };
  }, []);

  useEffect(() => {
    GetSalesChannelList();
    GetWalmartAdvFilter();
  }, []);

  useEffect(() => {
    if (GetSalesChannelListRes?.status === true) {
      setSalesChannelLoading(false);
      setsalesChannelList(GetSalesChannelListRes?.data?.sales_channel);
      setSelectedSalesChannelId("ATVPDKIKX0DER");
      setSelectedSalesChannel("Amazon.com");
      setSelectedCurrencySign("$");
      fakeActionSalesAnalytics("GetSalesChannelListResponse");
    } else if (GetSalesChannelListRes?.status === false) {
      fakeActionSalesAnalytics("GetSalesChannelListResponse");
    }
  }, [GetSalesChannelListRes]);

  useEffect(() => {
    if (GetWalmartAdvFilterRes?.status === true) {
      // console.log("GetWalmartAdvFilterRes", GetWalmartAdvFilterRes);
      setFilterList(GetWalmartAdvFilterRes?.data);
      const Obj = {
        start_date: moment(filters?.start_date?.$d).format("YYYY-MM-DD"),
        end_date: moment(filters?.end_date?.$d).format("YYYY-MM-DD"),
        metrics: selectedChartArr?.join(","),
      };
      GetWalmartAdvPerformanceChart(Obj);

      const SummaryObj = {
        start_date: moment(filters?.start_date?.$d).format("YYYY-MM-DD"),
        end_date: moment(filters?.end_date?.$d).format("YYYY-MM-DD"),
      };
      GetWalmartPerformanceSummary(SummaryObj);

      const byAddSpendObj = {
        start_date: moment(filters?.start_date?.$d).format("YYYY-MM-DD"),
        end_date: moment(filters?.end_date?.$d).format("YYYY-MM-DD"),
      };
      GetWalmartCampaignByAdSpend(byAddSpendObj);

      const OutOfBudgetObj = {
        start_date: moment(filters?.start_date?.$d).format("YYYY-MM-DD"),
        end_date: moment(filters?.end_date?.$d).format("YYYY-MM-DD"),
      };
      GetWalmartCampaignOutOfBudget(OutOfBudgetObj);

      const perforMetricsObj = {
        start_date: moment(filters?.start_date?.$d).format("YYYY-MM-DD"),
        end_date: moment(filters?.end_date?.$d).format("YYYY-MM-DD"),
      };
      GetWalmartDashboardPerformanceMetrics(perforMetricsObj);
      fakeActionAdvDashboard("GetWalmartAdvFilterResponse");
    } else if (GetWalmartAdvFilterRes?.status === false) {
      fakeActionAdvDashboard("GetWalmartAdvFilterResponse");
    }
  }, [GetWalmartAdvFilterRes]);

  useEffect(() => {
    if (GetWalmartAdvPerformanceChartRes?.status === true) {
      // console.log(
      //   "GetWalmartAdvPerformanceChartRes",
      //   GetWalmartAdvPerformanceChartRes
      // );
      setPerformSummaryLoading(false);
      const finalSeries = Object.entries(
        GetWalmartAdvPerformanceChartRes?.data?.chartData
      )?.map((d) => {
        // console.log(d, "-------cahrt d");
        return {
          name: d?.[0]?.replace("_", " "),
          data: Object?.entries(d?.[1])?.map((d) => d?.[1]),
        };
      });
      setChartSeries(finalSeries);
      setChartDate(GetWalmartAdvPerformanceChartRes?.data?.chartDate);

      const finalData = Object.entries(
        GetWalmartAdvPerformanceChartRes?.data?.chartDate
      )?.map((d) => {
        const newdata = Object.entries(
          GetWalmartAdvPerformanceChartRes?.data?.chartData
        )?.map((a) => {
          console.log({ [a[0]]: a?.[1]?.[d?.[1]] }, "aa");
          return { [a[0]]: a?.[1]?.[d?.[1]] };
        });
        const myArr = { date: d?.[1], other: newdata };

        const transformedData = myArr.other.reduce((result, obj) => {
          const key = Object.keys(obj)[0];
          result[key] = obj[key];
          return result;
        }, {});

        const finalResult = {
          date: myArr.date,
          ...transformedData,
        };

        console.log(finalResult, "finalResult");
        return finalResult;
      });
      setExportGraphArray(finalData);
      console.log(finalSeries, "finalSeries");
      fakeActionAdvDashboard("GetWalmartAdvPerformanceChartReseponse");
    } else if (GetWalmartAdvPerformanceChartRes?.status === false) {
      setPerformSummaryLoading(false);
      message.destroy();
      message.error(GetWalmartAdvPerformanceChartRes?.message);
      fakeActionAdvDashboard("GetWalmartAdvPerformanceChartReseponse");
    }
  }, [GetWalmartAdvPerformanceChartRes]);

  useEffect(() => {
    if (GetWalmartAdvPerformanceSummaryRes?.status === true) {
      console.log(
        "GetWalmartAdvPerformanceSummaryRes",
        GetWalmartAdvPerformanceSummaryRes
      );
      setPerformanceSummary(GetWalmartAdvPerformanceSummaryRes?.data);
      fakeActionAdvDashboard("GetWalmartAdvPerformanceSummaryResponse");
    } else if (GetWalmartAdvPerformanceSummaryRes?.status === false) {
      fakeActionAdvDashboard("GetWalmartAdvPerformanceSummaryResponse");
    }
  }, [GetWalmartAdvPerformanceSummaryRes]);

  useEffect(() => {
    if (GetWalmartCampaignAdSpendRes?.status === true) {
      console.log("GetWalmartCampaignAdSpendRes", GetWalmartCampaignAdSpendRes);
      setCampaignByTableLoading(false);
      setCampaignByAdSpendData(GetWalmartCampaignAdSpendRes?.data);
      fakeActionAdvDashboard("GetWalmartCampaignAdSpendResponse");
    } else if (GetWalmartCampaignAdSpendRes?.status === false) {
      setCampaignByTableLoading(false);
      message.destroy();
      message.error(GetWalmartCampaignAdSpendRes?.message);
      fakeActionAdvDashboard("GetWalmartCampaignAdSpendResponse");
    }
  }, [GetWalmartCampaignAdSpendRes]);

  useEffect(() => {
    if (GetWalmartCampaignOutOfBudgetRes?.status === true) {
      console.log(
        "GetWalmartCampaignOutOfBudgetRes",
        GetWalmartCampaignOutOfBudgetRes
      );
      setOutOfCampaignTableLoading(false);
      setCampaignOutOfBudget(GetWalmartCampaignOutOfBudgetRes?.data);
      fakeActionAdvDashboard("GetWalmartCampaignOutOfBudgetResponse");
    } else if (GetWalmartCampaignOutOfBudgetRes?.status === false) {
      setOutOfCampaignTableLoading(false);
      message.destroy();
      message.error(GetWalmartCampaignAdSpendRes?.message);
      fakeActionAdvDashboard("GetWalmartCampaignOutOfBudgetResponse");
    }
  }, [GetWalmartCampaignOutOfBudgetRes]);

  useEffect(() => {
    if (GetWalmartDashboardPerformanceMetricsRes?.status === true) {
      setPerformanceMetrics(GetWalmartDashboardPerformanceMetricsRes?.data);
      setPerformanceMetricsLoading(false);
      fakeActionAdvDashboard("GetWalmartDashboardPerformanceMetricsResponse");
    } else if (GetWalmartDashboardPerformanceMetricsRes?.status === false) {
      message.destroy();
      message.error(GetWalmartCampaignAdSpendRes?.message);
      setPerformanceMetricsLoading(false);
      fakeActionAdvDashboard("GetWalmartDashboardPerformanceMetricsResponse");
    }
  }, [GetWalmartDashboardPerformanceMetricsRes]);

  const options = {
    chart: {
      height: 350,
      type: "area",
      toolbar: { show: false },
      zoom: {
        enabled: false,
      },
    },
    colors: ["#4318FF", "#000", "#367d19"],
    dataLabels: { enabled: !1 },
    stroke: {
      show: !0,
      curve: "smooth",
      width: [2, 2, 2],
      dashArray: [0, 0],
      lineCap: "round",
    },

    xaxis: {
      tickPlacement: "between",
      tickAmount: window.innerWidth >= 992 ? 15 : 5,
      categories:
        chartDate?.map((d) => moment(new Date(d)).format("DD MMM YYYY")) || [],
      labels: {
        rotate: -50,
      },
    },
    //    yaxis: {
    // 	 labels: {
    // 	   formatter: function (_) {
    // 		 return _ + "";
    // 	   },
    // 	   offsetX: -12,
    // 	   offsetY: 0,
    // 	 },
    //    },
    yaxis: [
      {
        axisTicks: {
          show: true,
        },

        labels: {
          formatter: function (_) {
            return _ + "";
          },
          offsetX: -12,
          offsetY: 0,
        },
      },
      {
        opposite: true,
        axisTicks: {
          show: true,
        },

        labels: {
          formatter: function (_) {
            return _ + "";
          },
          offsetX: -12,
          offsetY: 0,
        },
      },
    ],
    grid: {
      strokeDashArray: 3,
      xaxis: { lines: { show: !0 } },
      yaxis: { lines: { show: !1 } },
    },
    legend: { show: true },
    fill: {
      type: "gradient",
      gradient: {
        type: "vertical",
        shadeIntensity: 1,
        inverseColors: !1,
        opacityFrom: 0.41,
        opacityTo: 0.2,
        stops: [45, 100],
      },
    },
    tooltip: {
      marker: {
        show: true,
      },
      x: {
        show: true,
      },
      y: {
        formatter: function (
          value,
          { series, seriesIndex, dataPointIndex, w }
        ) {
          const withTitle_ = w?.config?.series?.[seriesIndex];
          console.log(withTitle_, "withTitle_");
          return withTitle_?.name !== "ad spend"
            ? value || 0
            : "$" + NumberWithCommas(value);
        },
      },
    },
  };
  useEffect(() => {
    const chartdivData = {
      exact: "0",
      board: "0",
      phrase: "0",
      auto: "0",
    };
    const chartdivData1 = {
      exact: "0",
      board: "0",
      phrase: "0",
      auto: "0",
    };
    const chartdivData2 = {
      exact: "0",
      board: "0",
      phrase: "0",
      auto: "0",
    };
    const chartdivData3 = {
      exact: "0",
      board: "0",
      phrase: "0",
      auto: "0",
    };
    getAm5Chart("chartdiv", chartdivData, "$");
    getAm5Chart("chartdiv2", chartdivData1, "$");
    getAm5Chart("chartdiv3", chartdivData2, "%");
    getAm5Chart("chartdiv4", chartdivData3, "$");
  }, []);
  const getAm5Chart = (id, data, sign) => {
    am5.ready(function () {
      var root = am5.Root.new(id);
      // Set themes
      root.setThemes([am5themes_Animated.new(root)]);

      // Create chart
      var chart = root.container.children.push(
        am5percent.PieChart.new(root, {
          startAngle: 180,
          endAngle: 360,
          layout: root.verticalLayout,
          innerRadius: am5.percent(50),
        })
      );

      var series = chart.series.push(
        am5percent.PieSeries.new(root, {
          startAngle: 180,
          endAngle: 360,
          name: "Series",
          categoryField: "category",
          valueField: "value",
          text: "{category}",
          alignLabels: false,
          legendLabelText: "[]{category}[/]",
          legendValueText: `[bold]${sign === "$" ? "$" : ""}{value}${
            sign === "%" ? "%" : ""
          }[/]`,
        })
      );

      // Disabling labels and ticks
      series.labels.template.set("visible", false);
      series.ticks.template.set("visible", false);

      // Set data with a property to disable certain values
      series.data.setAll([
        { value: data?.exact, category: "Exact" },
        { value: data?.board, category: "Broad" },
        { value: data?.phrase, category: "Phrase" },
        { value: data?.auto, category: "Auto" },
      ]);

      // Create legend
      var legend = chart.children.push(
        am5.Legend.new(root, {
          centerX: am5.percent(50),
          x: am5.percent(50),
          marginTop: -30,
          marginBottom: 0,
        })
      );
      series.states.create("hidden", {
        startAngle: 180,
        endAngle: 180,
      });

      series.slices.template.setAll({
        cornerRadius: 5,
      });

      series.ticks.template.setAll({
        forceHidden: true,
      });
      // Customize legend labels to show values
      legend.labels.template.set("text", "{category}: {value}");
      series.slices.template.set("tooltipText", "{category}: [bold]{value}[/]");

      legend.data.setAll(series.dataItems);

      // Play initial series animation
      series.appear(0, 0);
      root._logo.dispose();
    });
  };
  const handleExportPDF = () => {
    savePDF(chartRefFirstGraph.current, {
      paperSize: "auto",
      fileName: "chart.pdf",
    });
  };
  const exportHeaderAmazon = [
    { value: "Date", type: String },
    { value: "Impressions", type: String },
    { value: "Clicks", type: String },
    { value: "Ad Spend", type: String },
  ];
  const downloadXlAmazon = async () => {
    let dataRow = [];
    await exportGraphArray.map((d) => {
      const newList = [d?.date, d?.impressions, d?.clicks, d?.ad_spend];
      dataRow = [
        ...dataRow,
        newList.map((d) => {
          return { value: d };
        }),
      ];
    });
    const data = [exportHeaderAmazon, ...dataRow];
    await writeXlsxFile(data, {
      fileName: "Performance Summary Graph.xlsx",
    });
    message.destroy();
  };
  const [swiper, setSwiper] = useState(null);
  const handleNext = () => {
    if (swiper !== null) {
      swiper.slideNext();
    }
  };
  const handlePrev = () => {
    if (swiper !== null) {
      swiper.slidePrev();
    }
  };
  return (
    <div className="d-flex flex-column flex-column-fluid fadeInRight">
      <div id="kt_app_content" className="app-content flex-column-fluid">
        <div
          id="kt_app_content_container"
          className="app-container container-fluid"
        >
          <div className="row g-5 g-xl-5 mb-0 ">
            <div className="col-12 my-3 d-flex">
              <div className="me-3">
                <RangePickerIVCSR
                  className="ant_common_input"
                  id="ivcsr_date_picker"
                  size="large"
                  allowClear={false}
                  style={{ width: "290px" }}
                  format={DateFormat}
                  value={[filters?.start_date, filters?.end_date]}
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  onChange={(e) => {
                    const filters_ = filters;
                    filters_.start_date = e?.[0];
                    filters_.end_date = e?.[1];
                    setFilters({ ...filters_ });

                    setPerformSummaryLoading(false);
                    setCampaignByTableLoading(false);
                    setOutOfCampaignTableLoading(false);

                    const Obj = {
                      start_date: moment(e?.[0]?.$d).format("YYYY-MM-DD"),
                      end_date: moment(e?.[1]?.$d).format("YYYY-MM-DD"),
                      metrics: selectedChartArr?.join(","),
                    };
                    GetWalmartAdvPerformanceChart(Obj);

                    const SummaryObj = {
                      start_date: moment(e?.[0]?.$d).format("YYYY-MM-DD"),
                      end_date: moment(e?.[1]?.$d).format("YYYY-MM-DD"),
                    };
                    GetWalmartPerformanceSummary(SummaryObj);

                    const byAddSpendObj = {
                      start_date: moment(e?.[0]?.$d).format("YYYY-MM-DD"),
                      end_date: moment(e?.[1]?.$d).format("YYYY-MM-DD"),
                    };
                    GetWalmartCampaignByAdSpend(byAddSpendObj);

                    const OutOfBudgetObj = {
                      start_date: moment(e?.[0]?.$d).format("YYYY-MM-DD"),
                      end_date: moment(e?.[1]?.$d).format("YYYY-MM-DD"),
                    };
                    GetWalmartCampaignOutOfBudget(OutOfBudgetObj);
                  }}
                />
              </div>
              {/* <div className="me-3">
                <Select
                  className="w-200px"
                  size="large"
                  placeholder="Select Sales Channel"
                  loading={salesChannelLoading}
                  value={selectedSalesChannelId || null}
                  onChange={(e, _) => {
                    setSelectedSalesChannel(_?.sales_channel);
                    setSelectedSalesChannelId(e);
                  }}
                  options={SalesChannelFlag(salesChannelList)}
                />
              </div> */}
            </div>
          </div>
          <div className="row g-5 g-xl-5 ">
            <div className="col-xxl-12 mb-5 mb-xl-5 ">
              <div className="card card-flush h-md-100">
                <div className="card-header  min-h-50px">
                  <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bold text-dark">
                      All Campaigns Performance Summary
                    </span>
                  </h3>

                  <div className="card-toolbar">
                    <div>
                      {performSummaryLoading ? (
                        <Skeleton.Button size="large" active />
                      ) : (
                        <Dropdown
                          disabled={filterList?.length === 0}
                          trigger={["click"]}
                          dropdownRender={() => (
                            <div style={contentStyle}>
                              <div>
                                <div className="separator border-gray-200" />
                                <div
                                  style={{
                                    minHeight: "400px",
                                    minWidth: "50px",
                                    border: "1px solid #4419ff8a",
                                    borderRadius: "7px",
                                  }}
                                >
                                  <div
                                    className="d-flex flex-column bgi-no-repeat"
                                    style={{
                                      borderRadius: "7px",
                                      backgroundImage:
                                        'url("/assets/media/white-waves.png")',
                                    }}
                                  >
                                    <h3 className="text-dark fw-semibold px-9 mt-10 mb-6">
                                      Metrics
                                      <span className="fs-8 opacity-75 ps-3" />
                                    </h3>

                                    <ul
                                      className="nav nav-line-tabs nav-line-tabs-2x nav-stretch fw-semibold px-9"
                                      role="tablist"
                                    >
                                      <li
                                        className="nav-item cursor-pointer"
                                        role="presentation"
                                      >
                                        <span
                                          className={`${
                                            selectedFilterTab === "All"
                                              ? "active"
                                              : ""
                                          } nav-link text-dark opacity-75 opacity-state-100 pb-4 `}
                                          onClick={() => {
                                            setSelectedFilterTab("All");
                                          }}
                                        >
                                          All Metrics
                                        </span>
                                      </li>
                                      <li
                                        className="nav-item  cursor-pointer"
                                        role="presentation"
                                      >
                                        <span
                                          className={`${
                                            selectedFilterTab === "Performance"
                                              ? "active"
                                              : ""
                                          } nav-link text-dark opacity-75 opacity-state-100 pb-4 `}
                                          onClick={() => {
                                            setSelectedFilterTab("Performance");
                                          }}
                                        >
                                          Performance
                                        </span>
                                      </li>
                                      <li
                                        className="nav-item  cursor-pointer"
                                        role="presentation"
                                      >
                                        <span
                                          className={`${
                                            selectedFilterTab === "Conversion"
                                              ? "active"
                                              : ""
                                          } nav-link text-dark opacity-75 opacity-state-100 pb-4 `}
                                          onClick={() => {
                                            setSelectedFilterTab("Conversion");
                                          }}
                                        >
                                          Conversion
                                        </span>
                                      </li>
                                    </ul>
                                  </div>
                                  <div className="tab-content">
                                    <div
                                      className={`${
                                        selectedFilterTab === "All"
                                          ? "show active"
                                          : ""
                                      } tab-pane fade `}
                                    >
                                      <div className="scroll-y mh-250px  my-5 px-8 mt-3">
                                        {Object?.entries(
                                          filterList?.performance
                                        )?.map((d) => {
                                          console.log(d, "------dddd");
                                          return (
                                            <div className="d-flex flex-stack py-2 ps-0 form-check form-check-custom form-check-solid form-check-sm mb-0">
                                              <label
                                                className="form-check-label fs-6 text-gray-800 text-hover-primary fw-bold ms-0"
                                                htmlFor="flexCheckDefault"
                                              >
                                                {d[1]}
                                              </label>
                                              <Checkbox
                                                checked={
                                                  selectedFilters.filter(
                                                    (a) => a === d[0]
                                                  )?.length === 1
                                                    ? true
                                                    : false
                                                }
                                                onChange={(e) => {
                                                  const checkboxId = d[0];
                                                  setSelectedFilters((prev) => {
                                                    if (e?.target?.checked) {
                                                      return [
                                                        ...prev,
                                                        checkboxId,
                                                      ];
                                                    } else {
                                                      return prev.filter(
                                                        (a) => a !== checkboxId
                                                      );
                                                    }
                                                  });
                                                }}
                                              />
                                            </div>
                                          );
                                        })}
                                        {Object?.entries(
                                          filterList?.conversion
                                        )?.map((d) => {
                                          return (
                                            <div className="d-flex flex-stack py-2 ps-0 form-check form-check-custom form-check-solid form-check-sm mb-0">
                                              <label
                                                className="form-check-label fs-6 text-gray-800 text-hover-primary fw-bold ms-0"
                                                htmlFor="flexCheckDefault"
                                              >
                                                {d[1]}
                                              </label>
                                              <Checkbox
                                                checked={
                                                  selectedFilters.filter(
                                                    (a) => a === d[0]
                                                  )?.length === 1
                                                    ? true
                                                    : false
                                                }
                                                onChange={(e) => {
                                                  const checkboxId = d[0];
                                                  setSelectedFilters((prev) => {
                                                    if (e?.target?.checked) {
                                                      return [
                                                        ...prev,
                                                        checkboxId,
                                                      ];
                                                    } else {
                                                      return prev.filter(
                                                        (a) => a !== checkboxId
                                                      );
                                                    }
                                                  });
                                                }}
                                              />
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </div>

                                    <div
                                      className={`${
                                        selectedFilterTab === "Performance"
                                          ? "show active"
                                          : ""
                                      } tab-pane fade `}
                                    >
                                      <div className="scroll-y mh-250px  my-5 px-8">
                                        {Object?.entries(
                                          filterList?.performance
                                        )?.map((d) => {
                                          console.log(d, "------dddd");
                                          return (
                                            <div className="d-flex flex-stack py-2 ps-0 form-check form-check-custom form-check-solid form-check-sm mb-0">
                                              <label
                                                className="form-check-label fs-6 text-gray-800 text-hover-primary fw-bold ms-0"
                                                htmlFor="flexCheckDefault"
                                              >
                                                {d[1]}
                                              </label>
                                              <Checkbox
                                                checked={
                                                  selectedFilters.filter(
                                                    (a) => a === d[0]
                                                  )?.length === 1
                                                    ? true
                                                    : false
                                                }
                                                onChange={(e) => {
                                                  const checkboxId = d[0];
                                                  setSelectedFilters((prev) => {
                                                    if (e?.target?.checked) {
                                                      return [
                                                        ...prev,
                                                        checkboxId,
                                                      ];
                                                    } else {
                                                      return prev.filter(
                                                        (a) => a !== checkboxId
                                                      );
                                                    }
                                                  });
                                                }}
                                              />
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </div>

                                    <div
                                      className={`${
                                        selectedFilterTab === "Conversion"
                                          ? "show active"
                                          : ""
                                      } tab-pane fade `}
                                    >
                                      <div className="scroll-y mh-250px  my-5 px-8 mt-3">
                                        {Object?.entries(
                                          filterList?.conversion
                                        )?.map((d) => {
                                          return (
                                            <div className="d-flex flex-stack py-2 ps-0 form-check form-check-custom form-check-solid form-check-sm mb-0">
                                              <label
                                                className="form-check-label fs-6 text-gray-800 text-hover-primary fw-bold ms-0"
                                                htmlFor="flexCheckDefault"
                                              >
                                                {d[1]}
                                              </label>
                                              <Checkbox
                                                checked={
                                                  selectedFilters.filter(
                                                    (a) => a === d[0]
                                                  )?.length === 1
                                                    ? true
                                                    : false
                                                }
                                                onChange={(e) => {
                                                  const checkboxId = d[0];
                                                  setSelectedFilters((prev) => {
                                                    if (e?.target?.checked) {
                                                      return [
                                                        ...prev,
                                                        checkboxId,
                                                      ];
                                                    } else {
                                                      return prev.filter(
                                                        (a) => a !== checkboxId
                                                      );
                                                    }
                                                  });
                                                }}
                                              />
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        >
                          <button className="btn btn-primary">
                            Select Metrics
                          </button>
                        </Dropdown>
                      )}
                    </div>
                  </div>
                </div>

                <div
                  className="card-body pt-2 pb-0"
                  // style={{
                  //   filter: performSummaryLoading ? "blur(6px)" : "blur(0px)",
                  // }}
                >
                  {performSummaryLoading ? (
                    <div
                      className="d-flex justify-content-between"
                      style={{ width: "100%" }}
                    >
                      <Skeleton.Button
                        active
                        style={{ height: "50px", width: "110px" }}
                      />
                      <Skeleton.Button
                        active
                        style={{ height: "50px", width: "110px" }}
                      />
                      <Skeleton.Button
                        active
                        style={{ height: "50px", width: "110px" }}
                      />
                      <Skeleton.Button
                        active
                        style={{ height: "50px", width: "110px" }}
                      />
                      <Skeleton.Button
                        active
                        style={{ height: "50px", width: "110px" }}
                      />
                      <Skeleton.Button
                        active
                        style={{ height: "50px", width: "110px" }}
                      />
                    </div>
                  ) : (
                    <div className="d-flex">
                      <button
                        onClick={handlePrev}
                        className="btn btn-light-info me-4"
                        style={{ padding: "5px", fontSize: "25px" }}
                      >
                        <LeftOutlined />
                      </button>

                      <Swiper
                        style={{ height: "100px" }}
                        spaceBetween={10}
                        className="mySwiper"
                        navigation={true}
                        onSwiper={setSwiper}
                        pagination={{
                          type: "fraction",
                        }}
                        slidesPerView={
                          window.innerWidth <= 992
                            ? 4
                            : window.innerWidth >= 992 &&
                              window.innerWidth <= 1100
                            ? 5
                            : window.innerWidth >= 1100 &&
                              window.innerWidth <= 1250
                            ? 6
                            : window.innerWidth >= 1250 &&
                              window.innerWidth <= 1350
                            ? 8
                            : 9
                        }
                      >
                        {selectedFilters?.map((d) => {
                          console.log(d, performanceSummary[d]);

                          return (
                            <SwiperSlide>
                              <span
                                className="parent-hover cursor-pointer d-flex align-items-center flex-md-row-fluid py-lg-2 px-0 px-4 me-4"
                                style={{
                                  borderRadius: "5px",
                                  // borderTop: `2px solid ${
                                  //   selectedChartArr.includes(d)
                                  //     ? "#4318FF"
                                  //     : "transparent"
                                  // }`,
                                  background: "#efedff",
                                  height: "100%",
                                }}
                                // onClick={() => {
                                //   setSelectedChartArr([...selectedChartArr, d]);
                                //   const Obj = {
                                //     start_date: moment(
                                //       filters?.start_date?.$d
                                //     ).format("YYYY-MM-DD"),
                                //     end_date: moment(
                                //       filters?.end_date?.$d
                                //     ).format("YYYY-MM-DD"),
                                //     metrics: [...selectedChartArr, d]?.join(
                                //       ","
                                //     ),
                                //   };
                                //   GetWalmartAdvPerformanceChart(Obj);
                                // }}
                              >
                                <div className="d-flex justify-content-center flex-column">
                                  <span className="text-gray-700 fw-semibold d-block fs-6">
                                    {d === "ad_spend"
                                      ? "Ad Spend"
                                      : d === "advertised_sku_sales"
                                      ? "Advertised SKU Sales"
                                      : d === "clicks"
                                      ? "Clicks"
                                      : d === "cr_order_sold"
                                      ? "Conversion Rate (Orders Sold Based)"
                                      : d === "cr_unit_sold"
                                      ? "Conversion Rate (Unit Sold Based)"
                                      : d === "ctr"
                                      ? "CTR"
                                      : d === "impressions"
                                      ? "Impressions"
                                      : d === "new_to_brand_order"
                                      ? "New-to-Brand Orders"
                                      : d === "orders"
                                      ? "Orders"
                                      : d === "other_sku_sales"
                                      ? "Other SKU Sales"
                                      : d === "roas"
                                      ? "RoAS"
                                      : d === "sales_revenue"
                                      ? "Total Attr. Sales Revenue"
                                      : d === "units"
                                      ? "Unit Sold"
                                      : d === "in_store_advertised_sales"
                                      ? "In Store Advertised Sales"
                                      : d === "in_store_attributed_sales"
                                      ? "In Store Attributed Sales"
                                      : d === "in_store_orders"
                                      ? "In Store Orders"
                                      : d === "in_store_other_sales"
                                      ? "In Store Other Sales"
                                      : "In Store Unit Sold"}
                                  </span>
                                  <span className="fw-bold text-gray-800 parent-hover-primary fs-5 mt-1">
                                    {d === "ad_spend"
                                      ? "$"
                                      : d === "advertised_sku_sales"
                                      ? "$"
                                      : d === "other_sku_sales"
                                      ? "$"
                                      : d === "roas"
                                      ? "$"
                                      : d === "sales_revenue"
                                      ? "$"
                                      : ""}
                                    {NumberWithCommas(
                                      performanceSummary[d] || 0
                                    )}
                                    {d === "cr_order_sold"
                                      ? "%"
                                      : d === "cr_unit_sold"
                                      ? "%"
                                      : d === "ctr"
                                      ? "%"
                                      : d === "new_to_brand_order"
                                      ? "%"
                                      : ""}
                                  </span>
                                </div>
                              </span>
                            </SwiperSlide>
                          );
                        })}
                      </Swiper>

                      <button
                        onClick={handleNext}
                        className="btn btn-light-info"
                        style={{ padding: "5px", fontSize: "25px" }}
                      >
                        <RightOutlined />
                      </button>
                    </div>
                  )}

                  <div className="d-flex justify-content-end mt-5">
                    <div className="d-flex">
                      {performSummaryLoading ? (
                        <Skeleton.Button active className="me-2" />
                      ) : (
                        <span
                          className="d-flex align-items-center cursor-pointer me-5"
                          onClick={() => {
                            handleExportPDF();
                          }}
                        >
                          <Tooltip title="Download PDF" placement="bottom">
                            <FilePdfOutlined
                              className="fs-1"
                              style={{ color: "red" }}
                            />
                          </Tooltip>
                        </span>
                      )}
                      {performSummaryLoading ? (
                        <Skeleton.Button active className="me-2" />
                      ) : (
                        <span
                          className="d-flex align-items-center cursor-pointer"
                          onClick={() => {
                            // handleExportPDF();
                            downloadXlAmazon();
                          }}
                        >
                          <Tooltip title="Download Excel" placement="bottom">
                            <FileExcelOutlined
                              className="fs-1"
                              style={{ color: "blue" }}
                            />
                          </Tooltip>
                        </span>
                      )}
                    </div>
                  </div>
                  {performSummaryLoading ? (
                    <div className="d-flex justify-content-center my-5">
                      <Skeleton.Node
                        active
                        style={{
                          height: "200px",
                          width: "200px",
                          margin: "50px",
                        }}
                      />
                    </div>
                  ) : (
                    <div
                      id="chartZoom"
                      className="w-100 mt-0"
                      ref={chartRefFirstGraph}
                    >
                      <Chart
                        options={options}
                        series={chartSeries}
                        type="area"
                        height={"400px"}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <WalmartExactBoardGraph {...props} />

          <WalmartPerformanceConversion
            {...props}
            performanceMetrics={performanceMetrics}
            performLoading={performanceMetricsLoading}
          />

          <WalmartCampaignsTable
            campaignByAdSpendData={campaignByAdSpendData}
            campaignOutOfBudget={campaignOutOfBudget}
            campaignByTableLoading={campaignByTableLoading}
            outOfCampaignTableLoading={outOfCampaignTableLoading}
          />
        </div>
      </div>
    </div>
  );
}
