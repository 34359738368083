import React, { useContext, useEffect } from "react";
import { GlobalContext } from "../../../../commonContext";
import AmazonIndex from "./lib/amazon-index";
import WalmartIndex from "./lib/walmart-index";
export default function (props) {
  const contextVar = useContext(GlobalContext);

  return contextVar?.data?.marketplaceType === "amazon" ? (
    <AmazonIndex {...props} />
  ) : (
    <WalmartIndex {...props} />
  );
}
