export default (state, action) => {
  if (typeof state === "undefined") {
    return [];
  }
  switch (action.type) {
    case "GET_WALMART_INSIGHTS_REPORT_SUCCESS":
    case "GET_WALMART_INSIGHTS_REPORT_ERROR":
      return {
        ...state,
        GetWalmartInsightReportListResponse: action.updatePayload,
      };
    case "GET_WALMART_PERFORMANCE_DATA_SUCCESS":
    case "GET_WALMART_PERFORMANCE_DATA_ERROR":
      return {
        ...state,
        GetWalmartPerformanceDataResponse: action.updatePayload,
      };
    case "GET_WALMART_ADVANCE_REPORT_SUMMARY_DATA_SUCCESS":
    case "GET_WALMART_ADVANCE_REPORT_SUMMARY_DATA_ERROR":
      return {
        ...state,
        GetAdvanceReportSummaryDataResponse: action.updatePayload,
      };
    case "GET_WALMART_ADVANCE_REPORT_CHART_DATA_SUCCESS":
    case "GET_WALMART_ADVANCE_REPORT_CHART_DATA_ERROR":
      return {
        ...state,
        GetAdvanceReportChartDataResponse: action.updatePayload,
      };

    case "FAKE_ACTION_WALMART_ADVANCE_REPORT":
      return { ...state, [action?.state]: {} };
    default:
      return state;
  }
};
