// import axiosCall from "../../configurations/network-services/axiosCall";

import { getUserId } from "../../../config";
import axiosCall from "../../configurations/network-services/axiosCall";
import walmartAxiosCall from "../../configurations/network-services/walmartAxoisCall";

export const GetItemBulkOperation = (data) => {
  const path = `wm/bulk-upload?page=${data?.page || 1}&per-page=${
    data?.perPage || 25
  }&feed_type=${data?.feed_type || ""}`;
  const responseType = "GET_ITEM_BULK_OPERATION";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const DownloadItemBulkFile = (data) => {
  const path = `download_file`;
  const responseType = "DOWNLOAD_ITEM_BULK_OPERATION";
  return walmartAxiosCall("post", path, responseType, data, {
    AUTHORIZATION: `Bearer ${localStorage.getItem("token")}`,
    "MARKETPLACE-ID": "ATVPDKIKX0DER",
    "USER-ID": JSON.parse(localStorage?.getItem("user")).id,
    "CONTENT-TYPE": "application/json",
  });
};
export const GetUploadFileBulkError = (data) => {
  const path = `download_file`;
  const responseType = "GET_UPLOAD_FILE_BULK_ERROR";
  return walmartAxiosCall("post", path, responseType, data, {
    AUTHORIZATION: `Bearer ${localStorage.getItem("token")}`,
    "MARKETPLACE-ID": "ATVPDKIKX0DER",
    "USER-ID": JSON.parse(localStorage?.getItem("user")).id,
    "CONTENT-TYPE": "application/json",
  });
};

export const ItemBulkOperationUpload = (data) => {
  let FormData_ = new FormData();
  FormData_.append("file", data?.file || "");
  const path = `items`;
  const responseType = "UPLOAD_ITEM_BULK_OPERATION_FILE";
  return walmartAxiosCall("post", path, responseType, FormData_, {
    headers: {
      AUTHORIZATION: `Bearer ${localStorage.getItem("token")}`,
      "MARKETPLACE-ID": "ATVPDKIKX0DER",
      // user_id: 8,
      // user_id: JSON.parse(localStorage.getItem("user"))?.id,
      "USER-ID": getUserId(),
      FEEDTYPE: "MP_ITEM",
      MODE: "FILE",
      FILENAME: data?.file.name,
    },
  });
};

export const GetWalmartApiLogDetails = (id) => {
  const path = `wm/log?id=${id}`;
  const responseType = "GET_API_LOG_DETAILS";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const fakeActionItemBulkOperation = (data) => async (dispatch) => {
  dispatch({ type: "FAKE_ACTION_ITEM_BULK_OPERATION", state: data });
};
