import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SKUData from "../../../modules/pages/master-table/sku-data";

import {
  GetSkuDataList,
  GetWalmartProductList,
  SaveItemFeedList,
  GetGlobalSearchProducts,
  GetWalmartProductsSearch,
  fakeActionSkuData,
} from "../../../redux/modules/master-table/sku-data/sku-data.action";
import {
  GetSalesChannelList,
  fakeActionSalesAnalytics,
} from "../../../redux/modules/sales-analytics/sales-analytics.action";
const mapStateToProps = (state) => ({
  GetSkuDataListResponse: state.SKUData.GetSkuDataListResponse,
  GetWalmartProductListResponse: state.SKUData.GetWalmartProductListResponse,
  SaveWalmartItemFeedListResponse:
    state.SKUData.SaveWalmartItemFeedListResponse,
  GetGlobalSearchProductListResponse:
    state.SKUData.GetGlobalSearchProductListResponse,
  GetWalmartProductSearchListResponse:
    state.SKUData.GetWalmartProductSearchListResponse,

  GetSalesChannelListResponse: state.SalesAnalytics.GetSalesChannelListResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      GetSkuDataList,
      GetWalmartProductList,
      SaveItemFeedList,
      GetGlobalSearchProducts,
      GetWalmartProductsSearch,
      fakeActionSkuData,

      GetSalesChannelList,
      fakeActionSalesAnalytics,
    },
    dispatch
  );

const SkuDataContainer = connect(mapStateToProps, mapDispatchToProps)(SKUData);

export default SkuDataContainer;
