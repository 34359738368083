import { Collapse, Skeleton } from "antd";
import React, { useState } from "react";
import { WalmartSalesbreakdownWrapper } from "./style";
import { NumberWithCommas } from "../../../../config";

const SalesBreakdown = (props) => {
  const { salesData, salesLoading, selectedCurrencySign } = props;
  const [collapseKey, setCollapseKey] = useState("");
  const [subCollapseKey, setSubCollapseKey] = useState("");
  const { Panel } = Collapse;
  const onCollapseChange = (key) => {
    setCollapseKey(key);
  };
  const onSubCollapseChange = (key) => {
    setSubCollapseKey(key);
  };
  return (
    <WalmartSalesbreakdownWrapper>
      <div
        className="card card-flush h-md-100"
        // style={{ filter: salesLoading ? "blur(6px)" : "blur(0px)" }}
      >
        <div className="card-header pt-0">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bold text-dark">
              Sales Breakdown
            </span>
          </h3>

          <div className="card-toolbar"></div>
        </div>

        <div
          className="card-body pt-0 px-5 pb-0"
          style={{ overflowY: "scroll", height: "364px" }}
        >
          {salesLoading ? (
            <>
              <Skeleton.Button active block className="mb-2" />
              <Skeleton.Button active block className="mb-2" />
              <Skeleton.Button active block className="mb-2" />
              <Skeleton.Button active block className="mb-2" />
              <Skeleton.Button active block className="mb-2" />
            </>
          ) : (
            <>
              {" "}
              <div className="border border-gray-300 bg-gray-100 border-dashed rounded p-4 px-5 mb-3">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    <h5 className="mb-0  fw-semibold ms-3">Total Orders</h5>
                  </div>
                  <div>
                    <h4 className="mb-0 fw-bolder">
                      {NumberWithCommas(salesData?.total_orders || "0")}
                    </h4>
                  </div>
                </div>
              </div>
              <div className="border border-gray-300 bg-gray-100 border-dashed rounded p-4 px-5 mb-3">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    <h5 className="mb-0  fw-semibold ms-3">Total Units Sold</h5>
                  </div>
                  <div>
                    <h4 className="mb-0 fw-bolder">
                      {NumberWithCommas(salesData?.order_units || "0")}
                    </h4>
                  </div>
                </div>
              </div>
              {/* <div className="border border-gray-300 bg-gray-100 border-dashed rounded p-4 px-5 mb-3">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    <h5 className="mb-0  fw-semibold ms-3">Total Sales</h5>
                  </div>
                  <div>
                    <h4 className="mb-0 fw-bolder">
                      $&nbsp;{NumberWithCommas(salesData?.total_sales || "0")}
                    </h4>
                  </div>
                </div>
              </div> */}
              <Collapse
                activeKey={[subCollapseKey]}
                accordion={true}
                onChange={(key) => {
                  onSubCollapseChange(key);
                }}
                className="border border-gray-300 bg-gray-100 border-dashed rounded mb-3"
              >
                <Panel
                  header={
                    <>
                      <h5 className="mb-0 text-dark fw-semibold ms-3">
                        Gross Revenue
                      </h5>
                    </>
                  }
                  key="3"
                  extra={
                    <>
                      <h4 className="mb-0 fw-bolder">
                        $&nbsp;
                        {NumberWithCommas(salesData?.gross_revenue || 0)}
                      </h4>
                    </>
                  }
                >
                  <div className="d-flex justify-content-between">
                    <label className="panel_text">Total Sales</label>
                    <h4 className="mb-0 fw-bolder">
                      $&nbsp;
                      {NumberWithCommas(salesData?.total_sales || "0")}
                    </h4>
                  </div>
                  <div className="separator separator-dashed my-3" />
                  <div className="d-flex justify-content-between">
                    <label className="panel_text">Shipping</label>
                    <h4 className="mb-0 fw-bolder">
                      $&nbsp;{NumberWithCommas(salesData?.shipping || "0")}
                    </h4>
                  </div>
                  <div className="separator separator-dashed my-3" />
                  <div className="d-flex justify-content-between">
                    <label className="panel_text">Promotions</label>
                    <h4 className="mb-0 fw-bolder">
                      $&nbsp;
                      {NumberWithCommas(salesData?.promotion_amount || "0")}
                    </h4>
                  </div>
                </Panel>
              </Collapse>{" "}
              <Collapse
                activeKey={[subCollapseKey]}
                accordion={true}
                onChange={(key) => {
                  onSubCollapseChange(key);
                }}
                className="border border-gray-300 bg-gray-100 border-dashed rounded mb-3"
              >
                <Panel
                  header={
                    <>
                      <h5 className="mb-0 text-dark fw-semibold ms-3">
                        Refunds
                      </h5>
                    </>
                  }
                  key="1"
                  extra={
                    <>
                      <h4 className="mb-0 fw-bolder">
                        $&nbsp;
                        {NumberWithCommas(
                          "-" + salesData?.refund_revenue || "0"
                        )}
                      </h4>
                    </>
                  }
                >
                  <div className="d-flex justify-content-between">
                    <label className="panel_text">Total Units</label>
                    <h4 className="mb-0 fw-bolder">
                      {NumberWithCommas(
                        salesData?.refunds?.refund_units || "0"
                      )}
                    </h4>
                  </div>
                  <div className="separator separator-dashed my-3" />{" "}
                  <div className="d-flex justify-content-between">
                    <label className="panel_text">Unit Price</label>
                    <h4 className="mb-0 fw-bolder">
                      $&nbsp;
                      {NumberWithCommas(
                        salesData?.refunds?.refund_unit_price || "0"
                      )}
                    </h4>
                  </div>
                  <div className="separator separator-dashed my-3" />
                  <div className="d-flex justify-content-between">
                    <label className="panel_text">Shipping</label>
                    <h4 className="mb-0 fw-bolder">
                      $&nbsp;
                      {NumberWithCommas(
                        salesData?.refunds?.refund_shipping_charge || "0"
                      )}
                    </h4>
                  </div>
                  <div className="separator separator-dashed my-3" />
                  <div className="d-flex justify-content-between">
                    <label className="panel_text">Total Tax</label>
                    <h4 className="mb-0 fw-bolder">
                      $&nbsp;
                      {NumberWithCommas(
                        salesData?.refunds?.total_refund_tax || "0"
                      )}
                    </h4>
                  </div>
                </Panel>
              </Collapse>
              <div className="border border-gray-300 bg-gray-100 border-dashed rounded p-4 px-5 mb-3">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    <h5 className="mb-0  fw-semibold ms-3">COGS</h5>
                  </div>
                  <div>
                    <h4 className="mb-0 fw-bolder">
                      $&nbsp;{NumberWithCommas(salesData?.cogs || "0")}
                    </h4>
                  </div>
                </div>
              </div>
              <Collapse
                activeKey={[subCollapseKey]}
                accordion={true}
                onChange={(key) => {
                  onSubCollapseChange(key);
                }}
                className="border border-gray-300 bg-gray-100 border-dashed rounded mb-3"
              >
                <Panel
                  header={
                    <>
                      <h5 className="mb-0 text-dark fw-semibold ms-3">Taxes</h5>
                    </>
                  }
                  key="2"
                  extra={
                    <>
                      <h4 className="mb-0 fw-bolder">
                        $&nbsp;
                        {NumberWithCommas(
                          parseFloat(salesData?.shipping_tax) +
                            parseFloat(salesData?.product_tax)
                        )}
                      </h4>
                    </>
                  }
                >
                  <div className="d-flex justify-content-between">
                    <label className="panel_text">Shipping Tax</label>
                    <h4 className="mb-0 fw-bolder">
                      $&nbsp;
                      {NumberWithCommas(salesData?.shipping_tax || "0")}
                    </h4>
                  </div>
                  <div className="separator separator-dashed my-3" />
                  <div className="d-flex justify-content-between">
                    <label className="panel_text">Product Tax</label>
                    <h4 className="mb-0 fw-bolder">
                      $&nbsp;{NumberWithCommas(salesData?.product_tax || "0")}
                    </h4>
                  </div>
                </Panel>
              </Collapse>
              {/* <div className="border border-gray-300 bg-gray-100 border-dashed rounded p-4 px-5 mb-3">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    <h5 className="mb-0  fw-semibold ms-3">PPC Cost</h5>
                  </div>
                  <div>
                    <h4 className="mb-0 fw-bolder">
                      $&nbsp;{NumberWithCommas(salesData?.ppc_cost || "0")}
                    </h4>
                  </div>
                </div>
              </div> */}
            </>
          )}

          {/* <div className="border border-gray-300 bg-gray-100 border-dashed rounded p-4 px-5 mb-3">
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                // <div className="w-7px h-7px rounded-circle bg-info" />
                <h5 className="mb-0 text-gray-800 fw-semibold ms-3">
                  Reimbursements
                </h5>
              </div>
              <div>
                <h4 className="mb-0 fw-bolder">
                  $&nbsp;{NumberWithCommas(salesData?.reimbursement || "0")}
                </h4>
              </div>
            </div>
          </div> */}
        </div>
        {/*end::Body*/}
        <div className="card-footer p-3 px-5">
          <div className="mb-5 mt-3" style={{ border: "1px dashed #bbbbbb" }} />
          {salesLoading ? (
            <>
              <Skeleton.Button active block className="mb-1" />
              <Skeleton.Button active block className="mb-1" />
              <Skeleton.Button active block className="mb-1" />
            </>
          ) : (
            <>
              {" "}
              <div
                className="bg-gray-100 rounded p-2 px-5 mb-2"
                style={{
                  borderStyle: "dashed",
                  borderColor: "#ac9c06",
                  borderWidth: "1px",
                  backgroundColor: "transparent",
                }}
              >
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    {/* <div className="w-7px h-7px rounded-circle bg-gren" /> */}
                    <h5 className="mb-0 text-gren fw-semibold ms-3">
                      Profit Margin
                    </h5>
                  </div>
                  <div>
                    <h4 className="mb-0 fw-bolder">
                      {salesData?.profit_margin || "0"}%
                    </h4>
                  </div>
                </div>
              </div>
              <div
                className="bg-gray-100 rounded p-2 px-5 mb-2"
                style={{
                  borderStyle: "dashed",
                  borderColor: "#8f7be9",
                  borderWidth: "1px",
                  backgroundColor: "transparent",
                }}
              >
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    {/* <div className="w-7px h-7px rounded-circle bg-gren" /> */}
                    <h5 className="mb-0 text-gren fw-semibold ms-3">ROI</h5>
                  </div>
                  <div>
                    <h4 className="mb-0 fw-bolder">{salesData?.roi || "0"}%</h4>
                  </div>
                </div>
              </div>
              <div
                className=" rounded p-2 px-5 mb-2"
                style={{
                  borderStyle: "solid",
                  borderColor: "#63CE94",
                  borderWidth: "1px",
                  backgroundColor: "transparent",
                }}
              >
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    <i className="ki-outline ki-chart-simple-2 fs-1 text-green" />
                    <h5 className="mb-0 text-green fw-semibold ms-3 fs-4">
                      Total Sales
                    </h5>
                  </div>
                  <div>
                    <h4 className="mb-0 fw-bolder text-green fs-3">
                      $&nbsp;{NumberWithCommas(salesData?.total_sales)}
                    </h4>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </WalmartSalesbreakdownWrapper>
  );
};

export default SalesBreakdown;
