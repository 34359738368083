import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AdvCampaignAnalytics from "../../../modules/pages/advertisement/campaign-analytics";
import {
  GetSalesChannelList,
  fakeActionSalesAnalytics,
} from "../../../redux/modules/sales-analytics/sales-analytics.action";
// import {
//   GetTopProductsList,
//   GetDashboardUnitSoldChart,
//   GetDashboardSalesData,
//   fakeActionDashboard,
// } from "../../redux/modules/dashboard/dashboard.action"

const mapStateToProps = (state) => ({
  //   GetDashboardTopProductsListResponse:
  //     state.Dashboard.GetDashboardTopProductsListResponse,
  //   GetDashboardUnitSoldChartResponse:
  //     state.Dashboard.GetDashboardUnitSoldChartResponse,
  //   GetDashboardSalesDataResponse: state.Dashboard.GetDashboardSalesDataResponse,
  GetSalesChannelListResponse: state.SalesAnalytics.GetSalesChannelListResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      GetSalesChannelList,
      fakeActionSalesAnalytics,
      //   GetTopProductsList,
      //   GetDashboardUnitSoldChart,
      //   GetDashboardSalesData,
      //   fakeActionDashboard,
      //   GetSalesChannelList,
      //   fakeActionSalesAnalytics,
    },
    dispatch
  );

const AdvCampaignAnalyticsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AdvCampaignAnalytics);

export default AdvCampaignAnalyticsContainer;
