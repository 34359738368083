export default (state, action) => {
  if (typeof state === "undefined") {
    return [];
  }
  switch (action.type) {
    case "GET_ITEM_BULK_OPERATION_SUCCESS":
    case "GET_ITEM_BULK_OPERATION_ERROR":
      return {
        ...state,
        GetItemBulkOperationResponse: action.updatePayload,
      };

    case "DOWNLOAD_ITEM_BULK_OPERATION_SUCCESS":
    case "DOWNLOAD_ITEM_BULK_OPERATION_ERROR":
      return {
        ...state,
        GetDownloadBulkOperationResponse: action.updatePayload,
      };

    case "GET_UPLOAD_FILE_BULK_ERROR_SUCCESS":
    case "GET_UPLOAD_FILE_BULK_ERROR_ERROR":
      return {
        ...state,
        GetUploadFIleBulkErrorResponse: action.updatePayload,
      };

    case "UPLOAD_ITEM_BULK_OPERATION_FILE_SUCCESS":
    case "UPLOAD_ITEM_BULK_OPERATION_FILE_ERROR":
      return {
        ...state,
        GetUploadItemBulkOperationFileResponse: action.updatePayload,
      };

    case "GET_API_LOG_DETAILS_SUCCESS":
    case "GET_API_LOG_DETAILS_ERROR":
      return {
        ...state,
        GetWalmartApiLogDetailsResponse: action.updatePayload,
      };

    case "FAKE_ACTION_ITEM_BULK_OPERATION":
      return { ...state, [action?.state]: {} };
    default:
      return state;
  }
};
