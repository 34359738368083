import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ProductOperation from "../../modules/pages/product-operation";

// import {
//   GetTopProductsList,
//   GetDashboardUnitSoldChart,
//   GetDashboardSalesData,
//   fakeActionDashboard,
// } from "../../redux/modules/dashboard/dashboard.action";

import {
  GetSalesChannelList,
  fakeActionSalesAnalytics,
} from "../../redux/modules/sales-analytics/sales-analytics.action";

import {
  GetWalmartItemFeed,
  SaveItemFeedList,
  fakeActionSkuData,
} from "../../redux/modules/master-table/sku-data/sku-data.action";

const mapStateToProps = (state) => ({
  GetWalmartItemFeedResponse: state.SKUData.GetWalmartItemFeedResponse,
  SaveWalmartItemFeedListResponse:
    state.SKUData.SaveWalmartItemFeedListResponse,

  GetSalesChannelListResponse: state.SalesAnalytics.GetSalesChannelListResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      GetWalmartItemFeed,
      SaveItemFeedList,
      fakeActionSkuData,

      GetSalesChannelList,
      fakeActionSalesAnalytics,
    },
    dispatch
  );

const ProductOperationContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductOperation);

export default ProductOperationContainer;
