import React, { useEffect, useState } from "react";
import Loading from "../../../../component/loading";
import IvcsrTable from "../../../../component/ivcsr-table";
import NoData from "../../../../component/no-data";
import Pagination from "../../../../component/pagination";
import { DefaultPerPage } from "../../../../config";
import {
  Checkbox,
  Image,
  Input,
  Popconfirm,
  Popover,
  Select,
  Spin,
  Switch,
  Tag,
  Tooltip,
  message,
} from "antd";
import {
  LoadingOutlined,
  ExclamationCircleOutlined,
  CopyTwoTone,
  DeleteTwoTone,
  EditTwoTone,
  HistoryOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import AddNewRepricer from "./lib/add-new-repricer";
import moment from "moment";
import { useSelector } from "react-redux";
const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
    }}
    spin
  />
);
export default function (props) {
  const { GetRepricerProductListing, fakeActionRepricerListing } = props;
  const GetRepricingProductListingRes = useSelector(
    (state) =>
      state.RepricerProductListing.GetRepricingProductListingResponse || {}
  );
  const [isOpenFilters, setIsOpenFilters] = useState(false);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageSize, setPageSize] = useState(DefaultPerPage);
  const [ruleWithProduct, setRuleWithProduct] = useState({});
  const [statusId, setStatusId] = useState("");
  const [modalType, setModalType] = useState("add");
  const [addNewRepricer, setAddNewRepricer] = useState(false);
  const [selectedRule, setSelectedRule] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [isUpdateMinPrice, setIsUpdateMinPrice] = useState(false);
  const [isUpdateMaxPrice, setIsUpdateMaxPrice] = useState(false);
  const [ruleList, setRuleList] = useState([]);
  const [rulesLoading, setRulesLoading] = useState(false);

  useEffect(() => {
    const obj = { page: page, perPage: pageSize, searchTerm: searchTerm };
    GetRepricerProductListing(obj);
  }, []);

  useEffect(() => {
    if (GetRepricingProductListingRes.status === true) {
      setList(GetRepricingProductListingRes?.data?.records || []);
      setLoading(false);
      setTotalPage(GetRepricingProductListingRes?.data?.pagination?.totalCount);
      fakeActionRepricerListing("GetRepricingProductListingResponse");
    } else if (GetRepricingProductListingRes.status === false) {
      setLoading(false);
      fakeActionRepricerListing("GetRepricingProductListingResponse");
    }
  }, [GetRepricingProductListingRes]);

  const onPageNo = (e) => {
    setLoading(true);
    const obj = { page: e, perPage: pageSize, searchTerm: searchTerm };
    GetRepricerProductListing(obj);
    setPage(e);
  };

  const onPerPage = (e) => {
    setPage(1);
    setPageSize(e);
    setLoading(true);
    const obj = { page: 1, perPage: e, searchTerm: searchTerm };
    GetRepricerProductListing(obj);
  };

  const productColumns = [
    {
      title: "Sr.No.",
      width: 60,
      ellipsis: true,
      align: "left",
      render: (_, __, i) => {
        return <span>{(page - 1) * pageSize + 1 + i}</span>;
      },
    },
    {
      title: "Product Title",
      width: 120,
      align: "left",
      render: (text) => {
        return (
          <Popover
            content={
              <div
                style={{
                  height: "max-content",
                  width: "300px",
                }}
              >
                {text?.product_title}
              </div>
            }
            placement="bottom"
          >
            <div
              className="text-primary mb-1 fw-bolder text-one fs-6 popoverActionIconSingleLine"
              style={{ minWidth: "100px" }}
            >
              {text?.product_title}
            </div>
          </Popover>
        );
      },
    },
    {
      title: "Image",
      width: 100,
      align: "center",
      render: (text) => {
        return (
          <div style={{ height: "80px", width: "80px" }}>
            <Image src={text?.image_url} />
          </div>
        );
      },
    },
    {
      title: "SKU",
      width: 140,
      align: "left",
      render: (text) => {
        return (
          <Tooltip rule title={text?.sku}>
            <Tag color="purple">
              {" "}
              <b>{text?.sku || "N/A"}</b>
            </Tag>
          </Tooltip>
        );
      },
    },
    {
      title: "ASIN",
      width: 120,
      align: "left",
      render: (text) => {
        return (
          <Tag color="purple">
            <b>{text?.asin || "-"}</b>
          </Tag>
        );
      },
    },
    {
      title: "Available Units",
      width: 90,
      align: "left",
      render: (text) => {
        return (
          <span className="fw-bolder fs-6 d-flex align-items-center">
            {text?.available_units || "0"}
          </span>
        );
      },
    },
    {
      title: "Buy Box Price",
      width: 90,
      align: "left",
      render: (text) => {
        return (
          <span className="fw-bold fs-6">
            {"$"}&nbsp;
            {text?.buy_box_price}
          </span>
        );
      },
    },
    {
      title: "Min Price",
      width: 120,
      align: "left",
      render: (text) => {
        return (
          <Input
            value={text?.min_price}
            // onPressEnter={(e) => {
            //   if (parseInt(e.target.value) === 0) {
            //     message.destroy();
            //     message.info("Enter min value");
            //     return;
            //   }
            //   const reg = /^-?\d*(\.\d*)?$/;
            //   if (reg.test(e.target.value) || e.target.value === "") {
            //     if (isUpdateMinPrice) {
            //       if (text?.min_price <= text?.max_price) {
            //         message.destroy();
            //         message.loading("Loading...", 0);
            //         RepricingProductUpdateAction(text.id, {
            //           min_price: e.target.value,
            //         });
            //       } else {
            //         message.destroy();
            //         message.info("Min price must be less than max price");
            //         return;
            //       }
            //     }
            //   }
            // }}
            // onBlur={(e) => {
            //   if (parseInt(e.target.value) === 0) {
            //     message.destroy();
            //     message.info("Enter min value");
            //     return;
            //   }
            //   const reg = /^-?\d*(\.\d*)?$/;
            //   if (reg.test(e.target.value) || e.target.value === "") {
            //     if (isUpdateMinPrice) {
            //       if (text?.min_price <= text?.max_price) {
            //         message.destroy();
            //         message.loading("Loading...", 0);
            //         RepricingProductUpdateAction(text.id, {
            //           min_price: e.target.value,
            //         });
            //       } else {
            //         message.destroy();
            //         message.info("Min price must be less than max price");
            //         return;
            //       }
            //     }
            //   }
            // }}
            // onChange={(e) => {
            //   if (parseInt(e.target.value) === 0) {
            //     message.destroy();
            //     message.info("Enter valid value");
            //     return;
            //   }
            //   const reg = /^-?\d*(\.\d*)?$/;
            //   if (
            //     reg.test(e.target.value) ||
            //     e.target.value === "" ||
            //     e.target.value === "-"
            //   ) {
            //     if (text?.min_price !== e.target.value) {
            //       setIsUpdateMinPrice(true);
            //     }
            //     setList(
            //       list?.map((d) => {
            //         if (d.id === text.id) {
            //           return { ...d, min_price: e.target.value };
            //         }
            //         return { ...d };
            //       })
            //     );
            //   }
            // }}
          />
        );
      },
    },
    {
      title: "Current Price",
      align: "left",
      width: 100,
      render: (text) => {
        if (text?.is_current_price_change == 1) {
          return (
            <Tooltip title="New Repriced Price">
              <Tag bordered={false} color="green" className="fs-6">
                {"$"}&nbsp;
                {text?.current_price}
              </Tag>
            </Tooltip>
          );
        }

        return (
          <span>
            <Tag color="blue" className="fs-6">
              {"$"}&nbsp;
              {text?.current_price}
            </Tag>
          </span>
        );
      },
    },

    {
      title: "Max Price",
      width: 120,
      align: "left",
      render: (text) => {
        return (
          <Input
            value={text?.max_price}
            // onPressEnter={(e) => {
            //   if (parseInt(e.target.value) === 0) {
            //     message.destroy();
            //     message.info("Enter max value");
            //     return;
            //   }
            //   const reg = /^-?\d*(\.\d*)?$/;
            //   if (reg.test(e.target.value) || e.target.value === "") {
            //     if (isUpdateMaxPrice) {
            //       if (text?.max_price >= text?.min_price) {
            //         message.destroy();
            //         message.loading("Loading...", 0);
            //         RepricingProductUpdateAction(text.id, {
            //           max_price: e.target.value,
            //         });
            //       } else {
            //         message.destroy();
            //         message.info("Max price must be grather than min price");
            //         return;
            //       }
            //     }
            //   }
            // }}
            // onBlur={(e) => {
            //   if (parseInt(e.target.value) === 0) {
            //     message.destroy();
            //     message.info("Enter max value");
            //     return;
            //   }
            //   const reg = /^-?\d*(\.\d*)?$/;
            //   if (reg.test(e.target.value) || e.target.value === "") {
            //     if (isUpdateMaxPrice) {
            //       if (text?.max_price >= text?.min_price) {
            //         message.destroy();
            //         message.loading("Loading...", 0);
            //         RepricingProductUpdateAction(text.id, {
            //           max_price: e.target.value,
            //         });
            //       } else {
            //         message.destroy();
            //         message.info("Max price must be grather than min price");
            //         return;
            //       }
            //     }
            //   }
            // }}
            // onChange={(e) => {
            //   if (parseInt(e.target.value) === 0) {
            //     message.destroy();
            //     message.info("Enter valid value");
            //     return;
            //   }
            //   const reg = /^-?\d*(\.\d*)?$/;
            //   if (
            //     reg.test(e.target.value) ||
            //     e.target.value === "" ||
            //     e.target.value === "-"
            //   ) {
            //     if (reg.test(e.target.value)) {
            //       if (text?.max_price !== e.target.value) {
            //         setIsUpdateMaxPrice(true);
            //       }
            //       setList(
            //         list?.map((d) => {
            //           if (d.id === text.id) {
            //             return { ...d, max_price: e.target.value };
            //           }
            //           return { ...d };
            //         })
            //       );
            //     }
            //   }
            // }}
          />
        );
      },
    },
    // B00PZ0ZHEO
    {
      title: "Repricing Rule",
      width: 190,
      align: "left",
      render: (text) => {
        return (
          <Select
            placeholder="Repricing Rule"
            // getPopupContainer={(triggerNode) =>
            //   document.getElementById().parentNode
            // }
            allowClear
            value={null}
            style={{ width: "100%" }}
            size="medium"
            // loading={rulesLoading}
            // onChange={(e) => {
            //   showConfirm(text, e);
            // }}
            // options={ruleList?.map((d) => {
            //   return {
            //     label: d?.rule_name,
            //     value: parseInt(d?.id),
            //   };
            // })}
          />
        );
      },
    },
    {
      title: "Availability Status",
      width: 120,
      align: "left",
      render: (text) => {
        if (parseInt(text?.stock_availability) === 0) {
          return (
            <Tag color="red">
              {" "}
              <span className="fw-bolder fs-7 d-flex align-items-center">
                <div className=" bg-danger rounded-circle border border-3 border-white h-15px w-15px" />{" "}
                Out of Stock
              </span>
            </Tag>
          );
        }
        return (
          <Tag color="green">
            {" "}
            <span className="fw-bolder fs-7 d-flex align-items-center">
              <div
                className=" rounded-circle border border-3 border-white h-15px w-15px"
                style={{ background: "green" }}
              />{" "}
              In Stock
            </span>
          </Tag>
        );
      },
    },
    {
      title: "No. Of Offers",
      width: 80,
      align: "left",
      render: (text) => {
        return (
          <span>
            <Tag color="orange">{text?.offer_count || 0}</Tag>
          </span>
        );
      },
    },
    {
      title: "Last Sale",
      width: 120,
      align: "left",
      render: (text) => {
        // console.log(new Date(text.last_sales), "new Date(text.last_sales)");
        return (
          <div>
            <span className="fw-bold fs-6">
              {text.last_sales
                ? moment(text?.last_sales).format("MMM DD, YYYY")
                : "-"}
            </span>
          </div>
        );
      },
    },
    {
      title: "History",
      width: 80,
      align: "center",
      render: (text) => {
        return (
          <Tooltip title="Price change history">
            <Tag
              // onClick={() => {
              //   setSelectedASIN(text?.asin);
              //   const Obj = {
              //     page: 1,
              //     perPage: DefaultPerPage,
              //     asin: text?.asin,
              //   };
              //   GetPriceChangeHistoryAction(Obj);
              // }}
              style={{ padding: "6px 6px 2.5px 6px", cursor: "pointer" }}
              icon={<HistoryOutlined />}
              color="warning"
            />
          </Tooltip>
        );
      },
    },
  ];

  return (
    <>
      <div className="d-flex flex-column flex-column-fluid fadeInRight">
        <div id="kt_app_content" className="app-content flex-column-fluid">
          <div
            id="kt_app_content_container"
            className="app-container container-fluid"
          >
            <div className="row gy-5 g-xl-5">
              <div className="col-xxl-12">
                <div className="card card-xxl-stretch mb-5 mb-xl-8">
                  <div className="card-header border-bottom-dashed">
                    <h3 className="card-title ">
                      <div className="position-relative">
                        <Input
                          type="text"
                          className="text-dark w-250px fs-7 fw-bold ant_common_input"
                          name="all_search"
                          placeholder="Search by ASIN, SKU..."
                          value={searchTerm}
                          size="large"
                          onChange={(e) => {
                            setSearchTerm(e.target.value);
                          }}
                          onPressEnter={(e) => {
                            setLoading(true);
                            setPage(1);
                            GetRepricerProductListing({
                              page: 1,
                              perPage: pageSize,
                              searchTerm: e.target.value,
                              // marketplace_id: selectedSalesChannelId,
                            });
                          }}
                          prefix={
                            <SearchOutlined
                              style={{ fontSize: "19px", color: "grey" }}
                            />
                          }
                        />
                      </div>
                    </h3>

                    <div className="card-toolbar gap-2">
                      <div className="position-relative me-2">
                        <button
                          className="btn btn-primary"
                          onClick={() => {
                            setIsOpenFilters(!isOpenFilters);
                          }}
                        >
                          Filters
                        </button>
                        {/* <span
                          onClick={() => {
                            setAddNewRepricer(true);
                            setModalType("add");
                          }}
                          className="btn btn-primary fw-bolder"
                        >
                          Add Rule
                        </span> */}
                      </div>
                      <div className="position-relative">
                        <button className="btn btn-primary">Import</button>
                        {/* <span
                          onClick={() => {
                            setAddNewRepricer(true);
                            setModalType("add");
                          }}
                          className="btn btn-primary fw-bolder"
                        >
                          Add Rule
                        </span> */}
                      </div>
                    </div>
                  </div>

                  <div className="card-body pb-5 pt-0">
                    {isOpenFilters && (
                      <>
                        <div className="d-flex justify-content-start mt-5 fade-in">
                          <div className="me-4">
                            <label className="fw-bold fs-6">
                              Repricing Status
                            </label>
                            <br />
                            <Select
                              placeholder="Repricing Status"
                              size="large"
                              style={{ width: "200px" }}
                            />
                          </div>
                          <div className="me-4">
                            <label className="fw-bold fs-6">
                              Buy Box Status
                            </label>
                            <br />
                            <Select
                              placeholder="Buy Box Status"
                              size="large"
                              style={{ width: "200px" }}
                            />
                          </div>
                          {/* <div className="me-4">
                            <label className="fw-bold fs-6">
                              Repricing Rule
                            </label>
                            <br />
                            <Select
                              placeholder="Repricing Rule"
                              size="large"
                              style={{ width: "200px" }}
                            />
                          </div> */}
                          {/* <div className="position-relative me-4">
                        <div>
                          <span className="fw-bold fs-6">Price Range</span>
                        </div>
                        <div className="d-flex align-items-center">
                          {" "}
                          <Input
                            placeholder="min"
                            size="large"
                            style={{ width: "100px" }}
                          />{" "}
                          -{" "}
                          <Input
                            placeholder="max"
                            size="large"
                            style={{ width: "100px" }}
                          />
                        </div>
                      </div> */}
                          <div className="position-relative me-4">
                            <label className="fw-bold fs-6">
                              Availability Status
                            </label>
                            <div
                              className="d-flex align-items-center position-relative"
                              style={{ top: "11px" }}
                            >
                              <Checkbox />
                              <label className="fw-bold mx-2 ">
                                {" "}
                                <Tag color="green" className="fs-6">
                                  In Stock{" "}
                                </Tag>
                              </label>
                              <Checkbox />
                              <label className="fw-bold mx-2 ">
                                <Tag color="red" className="fs-6">
                                  Out of Stock
                                </Tag>
                              </label>{" "}
                              <Checkbox />
                              <label className="fw-bold mx-2 ">
                                {" "}
                                <Tag color="geekblue" className="fs-6">
                                  All
                                </Tag>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div
                          className="separator separator-dashed my-5"
                          style={{
                            borderColor: "#00000069",
                            boxShadow: "0px 0px 10px 1px #b7b5f1",
                          }}
                        />
                      </>
                    )}

                    <div className="card-body py-4" id="repricing_products">
                      <div className="table-responsive">
                        {/* {loading ? (
                          <Loading />
                        ) : list?.length > 0 ? (
                          list?.map((l, j) => {
                            const isImage = productColumns?.filter(
                              (d) => d?.title === "Image"
                            );
                            return (
                              <>
                                <div className="row d-flex align-items-center mx-0">
                                  {isImage?.length != 0 && (
                                    <div className="col-1">
                                      {isImage[0]?.render(
                                        { ...l, key: j },
                                        list,
                                        j
                                      )}
                                    </div>
                                  )}

                                  <div
                                    className={`${
                                      isImage?.length != 0 ? "col-11" : "col-12"
                                    }`}
                                  >
                                    <div className="row mx-0">
                                      {productColumns?.map((d, i) => {
                                        if (d?.title === "Image") {
                                          return;
                                        }
                                        if (d?.title === "extraRow") {
                                          return (
                                            <div
                                              className="col-12 d-flex align-items-center my-2"
                                              style={{
                                                borderRight:
                                                  "1px solid #80808033",
                                              }}
                                            >
                                              {d?.render(
                                                { ...l, key: j },
                                                list,
                                                j
                                              )}
                                            </div>
                                          );
                                        }
                                        return (
                                          <div
                                            className="col-12 col-md-12 col-lg-4 d-flex align-items-center my-2"
                                            style={{
                                              borderRight:
                                                "1px solid #80808033",
                                            }}
                                          >
                                            <div style={{ width: "50%" }}>
                                              <label
                                                className="fs-5"
                                                style={{ fontWeight: "600" }}
                                              >
                                                {d?.title} :
                                              </label>
                                            </div>
                                            <div
                                              style={{ width: "50%" }}
                                              className="commonTableColumnAlign"
                                            >
                                              {d?.title == "Sr.No." ? (
                                                <Tag color="#7472ee">
                                                  {d?.render(
                                                    { ...l, key: j },
                                                    list,
                                                    j
                                                  )}
                                                </Tag>
                                              ) : (
                                                d?.render(
                                                  { ...l, key: j },
                                                  list,
                                                  j
                                                )
                                              )}
                                            </div>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="separator separator-dashed my-5"
                                  style={{
                                    borderColor: "#00000069",
                                    boxShadow: "0px 0px 10px 1px #b7b5f1",
                                  }}
                                />
                              </>
                            );
                          })
                        ) : (
                          <NoData />
                        )} */}
                        {loading ? (
                          <Loading />
                        ) : list?.length != 0 ? (
                          list?.map((text, i) => {
                            return (
                              <>
                                {/* <div className="row mb-5">
                                  <div className="col-12 d-flex justify-content-center">
                                    <Tag color="purple">
                                      <label className="fw-bold fs-4">
                                        Repricing Score : 78/100
                                      </label>
                                    </Tag>
                                  </div>
                                </div> */}

                                <div className="row" key={i}>
                                  <div className="col-12">
                                    <div className="row">
                                      <div
                                        className="col-1 d-flex justify-content-evenly align-items-center"
                                        style={{ flexDirection: "column" }}
                                      >
                                        <div>
                                          <img
                                            src={text?.image_url}
                                            style={{
                                              height: "100px",
                                              width: "100px",
                                              objectFit: "contain",
                                            }}
                                          />
                                        </div>
                                      </div>

                                      <div className="col-11">
                                        <div className="row mb-5">
                                          <div className="col-12 mb-2">
                                            <label className="fs-4 fw-bold">
                                              <Tag
                                                color="magenta"
                                                className="mr-3"
                                              >
                                                Sr. No.
                                                {(page - 1) * pageSize + 1 + i}
                                              </Tag>

                                              {text?.product_title}
                                            </label>
                                          </div>
                                          <div className="col-12">
                                            <div className="d-flex justify-content-between">
                                              <div>
                                                <Tag
                                                  color="processing"
                                                  className="fw-bold fs-6 me-10"
                                                >
                                                  SKU: {text?.sku}
                                                </Tag>
                                                <Tag
                                                  color="gold"
                                                  className="fw-bold fs-6 me-10"
                                                >
                                                  ASIN: {text?.asin}
                                                </Tag>
                                                <Tag
                                                  color="pink"
                                                  style={{
                                                    cursor: "pointer",
                                                    textDecoration: "underline",
                                                  }}
                                                  className="fw-bold fs-6 me-10 "
                                                  onClick={() => {
                                                    // setSelectedASIN(text?.asin);
                                                    // message.destroy();
                                                    // message.loading(
                                                    //   "Loading...",
                                                    //   0
                                                    // );
                                                    // const Obj = {
                                                    //   page: 1,
                                                    //   perPage: DefaultPerPage,
                                                    //   asin: text?.asin,
                                                    // };
                                                    // GetPriceChangeHistoryAction(
                                                    //   Obj
                                                    // );
                                                  }}
                                                >
                                                  History
                                                </Tag>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <div className="row">
                                          <div className="col-4 px-3">
                                            <div className="d-flex mb-3">
                                              <div style={{ width: "50%" }}>
                                                <label
                                                  className="fs-5"
                                                  style={{ fontWeight: "600" }}
                                                >
                                                  Current Price :
                                                </label>
                                              </div>

                                              <div
                                                style={{ width: "50%" }}
                                                className="fs-5"
                                              >
                                                {text?.is_current_price_change ==
                                                1 ? (
                                                  <Tooltip title="New Repriced Price">
                                                    <Tag
                                                      bordered={false}
                                                      color="green"
                                                    >
                                                      {/* {
                                                        signList[
                                                          marketPlaceSelected
                                                        ]
                                                      } */}
                                                      $ &nbsp;
                                                      {text?.current_price}
                                                    </Tag>
                                                  </Tooltip>
                                                ) : (
                                                  <label>
                                                    {/* {
                                                      signList[
                                                        marketPlaceSelected
                                                      ]
                                                    } */}
                                                    $
                                                    {text?.current_price || "0"}
                                                  </label>
                                                )}
                                              </div>
                                            </div>
                                            <div className="d-flex mb-3">
                                              <div style={{ width: "50%" }}>
                                                <label
                                                  className="fs-5"
                                                  style={{ fontWeight: "600" }}
                                                >
                                                  COGS :
                                                </label>
                                              </div>

                                              <div
                                                style={{ width: "50%" }}
                                                className="fs-5"
                                              >
                                                {/* {signList[marketPlaceSelected]} */}
                                                ${text?.cogs || "0"}
                                              </div>
                                            </div>
                                            <div className="d-flex mb-3">
                                              <div style={{ width: "50%" }}>
                                                <label
                                                  className="fs-5"
                                                  style={{ fontWeight: "600" }}
                                                >
                                                  Buy Box :
                                                </label>
                                              </div>

                                              <div
                                                style={{ width: "50%" }}
                                                className="fs-5"
                                              >
                                                {/* {signList[marketPlaceSelected]} */}
                                                ${text?.buy_box_price || "0"}
                                              </div>
                                            </div>
                                            {/* <div className="d-flex mb-3">
                                              <div style={{ width: "50%" }}>
                                                <label
                                                  className="fs-5"
                                                  style={{ fontWeight: "600" }}
                                                >
                                                  Suggestion :
                                                </label>
                                              </div>

                                              <div
                                                style={{ width: "50%" }}
                                                className="fs-5"
                                              >
                                                <span
                                                  style={{
                                                    wordBreak: "break-word",
                                                  }}
                                                >
                                                  <Popover
                                                    content={
                                                      <div
                                                        style={{
                                                          height: "max-content",
                                                          maxWidth: "400px",
                                                        }}
                                                      >
                                                        Use Wiser AI, who will
                                                        suggest you how to
                                                        increase sales by 1.5x
                                                      </div>
                                                    }
                                                    placement="bottom"
                                                  >
                                                    <div
                                                      className="mb-1 fw-bolder text-two fs-6 popoverActionIconSingleLine"
                                                      style={{
                                                        minWidth: "100px",
                                                      }}
                                                    >
                                                      Use Wiser AI, who will
                                                      suggest you how to
                                                      increase sales by 1.5x
                                                    </div>
                                                  </Popover>
                                                </span>
                                              </div>
                                            </div> */}
                                          </div>

                                          <div
                                            className="col-4 px-3"
                                            style={{
                                              borderLeft:
                                                "1px solid rgba(128, 128, 128, 0.2)",
                                              borderRight:
                                                "1px solid rgba(128, 128, 128, 0.2)",
                                            }}
                                          >
                                            <div className="d-flex mb-3">
                                              <div style={{ width: "50%" }}>
                                                <label
                                                  className="fs-5"
                                                  style={{ fontWeight: "600" }}
                                                >
                                                  Current Stock :
                                                </label>
                                              </div>

                                              <div
                                                style={{ width: "50%" }}
                                                className="fs-5"
                                              >
                                                {text?.available_units || "0"}
                                              </div>
                                            </div>
                                            <div className="d-flex mb-3">
                                              <div style={{ width: "50%" }}>
                                                <label
                                                  className="fs-5"
                                                  style={{ fontWeight: "600" }}
                                                >
                                                  Sales Velocity :
                                                </label>
                                              </div>

                                              <div
                                                style={{ width: "50%" }}
                                                className="fs-5"
                                              >
                                                {text?.sales_velocity || "0"} /
                                                day
                                              </div>
                                            </div>
                                            <div className="d-flex mb-3">
                                              <div style={{ width: "50%" }}>
                                                <label
                                                  className="fs-5"
                                                  style={{ fontWeight: "600" }}
                                                >
                                                  Total Sales Velocity(all
                                                  seller) :
                                                </label>
                                              </div>

                                              <div
                                                style={{ width: "50%" }}
                                                className="fs-5"
                                              >
                                                {text?.total_sales_velocity ||
                                                  "0"}
                                                / day
                                              </div>
                                            </div>
                                            {/* <div className="d-flex mb-3">
                                              <div style={{ width: "50%" }}>
                                                <label
                                                  className="fs-5"
                                                  style={{ fontWeight: "600" }}
                                                >
                                                  Competitor Stock :
                                                </label>
                                              </div>

                                              <div
                                                style={{ width: "50%" }}
                                                className="fs-5"
                                              >
                                                {text?.available_unitsss || "0"}
                                              </div>
                                            </div>
                                            <div className="d-flex align-items-center mb-3">
                                              <div style={{ width: "50%" }}>
                                                <label
                                                  className="fs-5"
                                                  style={{ fontWeight: "600" }}
                                                >
                                                  Competitor Offers
                                                </label>
                                              </div>

                                              <div
                                                style={{ width: "50%" }}
                                                className="d-flex align-items-center"
                                              >
                                                <label
                                                  className="fs-4 fw-bolder cursor-pointer"
                                                  style={{
                                                    textDecoration: "underline",
                                                    color: "#c41d7f",
                                                  }}
                                                  onClick={() => {
                                                    // setIsOpenOfferModal(true);
                                                    // const offer = JSON.parse(
                                                    //   text?.view_offers
                                                    // );
                                                    // setOfferRowData(offer);
                                                    // setSelectedRowForOffer({
                                                    //   asin: text?.asin,
                                                    //   sku: text?.sku,
                                                    // });
                                                  }}
                                                >
                                                  {text?.offer_count || "0"}
                                                </label>
                                              </div>
                                            </div> */}
                                          </div>

                                          <div className="col-4 px-3">
                                            <div className="d-flex mb-2">
                                              <div style={{ width: "50%" }}>
                                                <label
                                                  className="fs-5"
                                                  style={{ fontWeight: "600" }}
                                                >
                                                  Min Price :
                                                </label>
                                              </div>

                                              <div
                                                style={{ width: "50%" }}
                                                className="fs-5"
                                              >
                                                <span className="fw-bolder fs-6 d-flex align-items-center">
                                                  <Input
                                                    value={text?.min_price}
                                                    onPressEnter={(e) => {
                                                      if (
                                                        parseInt(
                                                          e.target.value
                                                        ) === 0
                                                      ) {
                                                        message.destroy();
                                                        message.info(
                                                          "Enter min value"
                                                        );
                                                        return;
                                                      }
                                                      const reg =
                                                        /^-?\d*(\.\d*)?$/;
                                                      if (
                                                        reg.test(
                                                          e.target.value
                                                        ) ||
                                                        e.target.value === ""
                                                      ) {
                                                        if (isUpdateMinPrice) {
                                                          if (
                                                            text?.min_price <=
                                                            text?.max_price
                                                          ) {
                                                            // message.destroy();
                                                            // message.loading(
                                                            //   "Loading...",
                                                            //   0
                                                            // );
                                                            // RepricingProductUpdateAction(
                                                            //   text.id,
                                                            //   {
                                                            //     min_price:
                                                            //       e.target
                                                            //         .value,
                                                            //   }
                                                            // );
                                                          } else {
                                                            message.destroy();
                                                            message.info(
                                                              "Min price must be less than max price"
                                                            );
                                                            return;
                                                          }
                                                        }
                                                      }
                                                    }}
                                                    onBlur={(e) => {
                                                      if (
                                                        parseInt(
                                                          e.target.value
                                                        ) === 0
                                                      ) {
                                                        message.destroy();
                                                        message.info(
                                                          "Enter min value"
                                                        );
                                                        return;
                                                      }
                                                      const reg =
                                                        /^-?\d*(\.\d*)?$/;
                                                      if (
                                                        reg.test(
                                                          e.target.value
                                                        ) ||
                                                        e.target.value === ""
                                                      ) {
                                                        if (isUpdateMinPrice) {
                                                          if (
                                                            text?.min_price <=
                                                            text?.max_price
                                                          ) {
                                                            // message.destroy();
                                                            // message.loading(
                                                            //   "Loading...",
                                                            //   0
                                                            // );
                                                            // RepricingProductUpdateAction(
                                                            //   text.id,
                                                            //   {
                                                            //     min_price:
                                                            //       e.target
                                                            //         .value,
                                                            //   }
                                                            // );
                                                          } else {
                                                            message.destroy();
                                                            message.info(
                                                              "Min price must be less than max price"
                                                            );
                                                            return;
                                                          }
                                                        }
                                                      }
                                                    }}
                                                    onChange={(e) => {
                                                      if (
                                                        parseInt(
                                                          e.target.value
                                                        ) === 0
                                                      ) {
                                                        message.destroy();
                                                        message.info(
                                                          "Enter valid value"
                                                        );
                                                        return;
                                                      }
                                                      const reg =
                                                        /^-?\d*(\.\d*)?$/;
                                                      if (
                                                        reg.test(
                                                          e.target.value
                                                        ) ||
                                                        e.target.value === "" ||
                                                        e.target.value === "-"
                                                      ) {
                                                        if (
                                                          text?.min_price !==
                                                          e.target.value
                                                        ) {
                                                          setIsUpdateMinPrice(
                                                            true
                                                          );
                                                        }
                                                        setList(
                                                          list?.map((d) => {
                                                            if (
                                                              d.id === text.id
                                                            ) {
                                                              return {
                                                                ...d,

                                                                min_price:
                                                                  e.target
                                                                    .value,
                                                              };
                                                            }
                                                            return { ...d };
                                                          })
                                                        );
                                                      }
                                                    }}
                                                  />
                                                </span>
                                              </div>
                                            </div>
                                            <div className="d-flex mb-2">
                                              <div style={{ width: "50%" }}>
                                                <label
                                                  className="fs-5"
                                                  style={{ fontWeight: "600" }}
                                                >
                                                  Max Price :
                                                </label>
                                              </div>
                                              <div
                                                style={{ width: "50%" }}
                                                className="fs-5"
                                              >
                                                <span className="fw-bolder fs-6 d-flex align-items-center">
                                                  <Input
                                                    value={text?.max_price}
                                                    onPressEnter={(e) => {
                                                      if (
                                                        parseInt(
                                                          e.target.value
                                                        ) === 0
                                                      ) {
                                                        message.destroy();
                                                        message.info(
                                                          "Enter max value"
                                                        );
                                                        return;
                                                      }
                                                      const reg =
                                                        /^-?\d*(\.\d*)?$/;
                                                      if (
                                                        reg.test(
                                                          e.target.value
                                                        ) ||
                                                        e.target.value === ""
                                                      ) {
                                                        if (isUpdateMaxPrice) {
                                                          if (
                                                            text?.max_price >=
                                                            text?.min_price
                                                          ) {
                                                            // message.destroy();
                                                            // message.loading(
                                                            //   "Loading...",
                                                            //   0
                                                            // );
                                                            // RepricingProductUpdateAction(
                                                            //   text.id,
                                                            //   {
                                                            //     max_price:
                                                            //       e.target
                                                            //         .value,
                                                            //   }
                                                            // );
                                                          } else {
                                                            message.destroy();

                                                            message.info(
                                                              "Max price must be grather than min price"
                                                            );
                                                            return;
                                                          }
                                                        }
                                                      }
                                                    }}
                                                    onBlur={(e) => {
                                                      if (
                                                        parseInt(
                                                          e.target.value
                                                        ) === 0
                                                      ) {
                                                        message.destroy();
                                                        message.info(
                                                          "Enter max value"
                                                        );
                                                        return;
                                                      }
                                                      const reg =
                                                        /^-?\d*(\.\d*)?$/;
                                                      if (
                                                        reg.test(
                                                          e.target.value
                                                        ) ||
                                                        e.target.value === ""
                                                      ) {
                                                        if (isUpdateMaxPrice) {
                                                          if (
                                                            text?.max_price >=
                                                            text?.min_price
                                                          ) {
                                                            // message.destroy();
                                                            // message.loading(
                                                            //   "Loading...",
                                                            //   0
                                                            // );
                                                            // RepricingProductUpdateAction(
                                                            //   text.id,
                                                            //   {
                                                            //     max_price:
                                                            //       e.target
                                                            //         .value,
                                                            //   }
                                                            // );
                                                          } else {
                                                            message.destroy();
                                                            message.info(
                                                              "Max price must be grather than min price"
                                                            );
                                                            return;
                                                          }
                                                        }
                                                      }
                                                    }}
                                                    onChange={(e) => {
                                                      if (
                                                        parseInt(
                                                          e.target.value
                                                        ) === 0
                                                      ) {
                                                        message.destroy();
                                                        message.info(
                                                          "Enter valid value"
                                                        );
                                                        return;
                                                      }
                                                      const reg =
                                                        /^-?\d*(\.\d*)?$/;
                                                      if (
                                                        reg.test(
                                                          e.target.value
                                                        ) ||
                                                        e.target.value === "" ||
                                                        e.target.value === "-"
                                                      ) {
                                                        if (
                                                          reg.test(
                                                            e.target.value
                                                          )
                                                        ) {
                                                          if (
                                                            text?.max_price !==
                                                            e.target.value
                                                          ) {
                                                            setIsUpdateMaxPrice(
                                                              true
                                                            );
                                                          }
                                                          setList(
                                                            list?.map((d) => {
                                                              if (
                                                                d.id === text.id
                                                              ) {
                                                                return {
                                                                  ...d,

                                                                  max_price:
                                                                    e.target
                                                                      .value,
                                                                };
                                                              }
                                                              return { ...d };
                                                            })
                                                          );
                                                        }
                                                      }
                                                    }}
                                                  />
                                                </span>
                                              </div>
                                            </div>
                                            <div className="d-flex mb-2">
                                              <div style={{ width: "50%" }}>
                                                <label
                                                  className="fs-5"
                                                  style={{ fontWeight: "600" }}
                                                >
                                                  Repricer Rule :
                                                </label>
                                              </div>
                                              <div
                                                style={{ width: "50%" }}
                                                className="fs-5"
                                              >
                                                <Select
                                                  placeholder="Repricing Rule"
                                                  getPopupContainer={(
                                                    triggerNode
                                                  ) =>
                                                    document.getElementById(
                                                      "repricing_products"
                                                    ).parentNode
                                                  }
                                                  allowClear
                                                  value={
                                                    ruleList?.length === 0
                                                      ? null
                                                      : text?.repricing_rule_id
                                                      ? parseInt(
                                                          text?.repricing_rule_id
                                                        )
                                                      : null
                                                  }
                                                  style={{ width: "100%" }}
                                                  size="large"
                                                  loading={rulesLoading}
                                                  onChange={(e, _) => {
                                                    // showConfirm(text, e, _);
                                                  }}
                                                  options={ruleList?.map(
                                                    (d) => {
                                                      return {
                                                        label: d?.rule_name,
                                                        value: parseInt(d?.id),
                                                        ...d,
                                                      };
                                                    }
                                                  )}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className="separator separator-dashed my-7"
                                    style={{
                                      boxShadow: "0px 0px 7px 1px #e1e0f1",
                                    }}
                                  ></div>
                                </div>
                              </>
                            );
                          })
                        ) : (
                          <NoData height="500px" />
                        )}
                      </div>
                      <Pagination
                        loading={loading || list?.length === 0}
                        pageSize={pageSize}
                        page={page}
                        totalPage={totalPage}
                        onPerPage={onPerPage}
                        onPageNo={onPageNo}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
