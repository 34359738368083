import { Alert, Checkbox, Input, Select, message } from "antd";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { SalesChannelFlag } from "../../../../../config";
import { Link } from "react-router-dom";
export default function (props) {
  const {
    show,
    onHide,
    regionList,
    GetRegionWiseMarketplaceList,
    fakeActionMarketplaceCred,
  } = props;
  const AppId = JSON.parse(
    localStorage.getItem("userCredential")
  )?.lwa_application_id;
  const Email = JSON.parse(localStorage.getItem("user"))?.email;

  const [sellerName, setSellerName] = useState(
    JSON.parse(localStorage.getItem("user"))?.seller_name
  );
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [marketplaceList, setMarketplaceList] = useState([]);
  const [selectedMakretplace, setSelectedMarketplace] = useState(null);
  const [checkMarketplaceList, setCheckMarketplaceList] = useState([]);

  const GetRegionWiseMarketplaceListRes = useSelector(
    (state) =>
      state.MarketplaceCredential.GetRegionWiseMarketplaceListResponse || {}
  );

  useEffect(() => {
    if (GetRegionWiseMarketplaceListRes.status === true) {
      const newObj = Object.entries(GetRegionWiseMarketplaceListRes?.data)?.map(
        (d) => {
          return { marketplace: d?.[1], marketplace_id: d?.[0] };
        }
      );
      setMarketplaceList(newObj);
      fakeActionMarketplaceCred("GetRegionWiseMarketplaceListResponse");
    } else if (GetRegionWiseMarketplaceListRes.status === false) {
      fakeActionMarketplaceCred("GetRegionWiseMarketplaceListResponse");
    }
  }, [GetRegionWiseMarketplaceListRes]);

  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          setSelectedMarketplace(null);
          setCheckMarketplaceList([]);
          setMarketplaceList([]);
          setSelectedRegion(null);
          onHide();
        }}
        size="md"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>SP API Credential</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <div className="row">
              <div className="col-12">
                <div>
                  <label className="fw-bold fs-6">Seller Name</label>
                </div>
                <div>
                  <Input
                    value={sellerName}
                    onChange={(e) => {
                      setSellerName(e.target.value);
                    }}
                    size="medium"
                    className="ant_common_input"
                  />
                </div>
              </div>
              <div className="col-12 mt-5">
                <div>
                  <label className="fw-bold fs-6">Region</label>
                </div>
                <div>
                  <Select
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    style={{ width: "100%", color: "#707070" }}
                    placeholder="Select Region"
                    size="large"
                    onChange={(e, _) => {
                      setSelectedRegion(_);
                      setSelectedMarketplace(null);
                      GetRegionWiseMarketplaceList(_?.value);
                    }}
                    value={selectedRegion?.value}
                    options={regionList}
                  />
                </div>
              </div>
              <div className="col-12 mt-5">
                <div>
                  <label className="fw-bold fs-6">Primary Marketplace</label>
                </div>
                <div>
                  <Select
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    style={{ width: "100%", color: "#707070" }}
                    placeholder="Select Marketplace"
                    size="large"
                    onChange={(e) => {
                      setSelectedMarketplace(e);
                      setCheckMarketplaceList([e]);
                    }}
                    value={selectedMakretplace}
                    options={marketplaceList.map((d, i) => {
                      // console.log(d, "-------select");
                      return {
                        value: d?.marketplace_id,
                        label: (
                          <>
                            <img
                              src={`/assets//media/domainImage/${d?.marketplace_id}.png`}
                              style={{ height: "20px" }}
                              loading="lazy"
                              className="me-3"
                              onError={(e) => {
                                e.target.src =
                                  "/assets/media/domainImage/red-flag.png";
                              }}
                            />
                            {d?.marketplace}
                          </>
                        ),
                        ...d,
                      };
                    })}
                  />
                </div>
              </div>

              <div className="col-12 mt-5">
                {marketplaceList?.length !== 0 ? (
                  <>
                    <Checkbox.Group
                      value={checkMarketplaceList}
                      onChange={(e) => {
                        setCheckMarketplaceList(e);
                      }}
                    >
                      <div className="row">
                        {marketplaceList?.map((d, i) => (
                          <div className="col-4 my-2">
                            <Checkbox
                              disabled={
                                d?.marketplace_id == selectedMakretplace
                              }
                              value={d?.marketplace_id}
                            >
                              <img
                                src={`/assets/media/domainImage/${d?.marketplace_id}.png`}
                                style={{ height: "20px" }}
                                loading="lazy"
                                className="me-3"
                                onError={(e) => {
                                  e.target.src =
                                    "/assets//media/domainImage/red-flag.png";
                                }}
                              />
                              {d?.marketplace}
                            </Checkbox>
                          </div>
                        ))}
                      </div>
                    </Checkbox.Group>
                  </>
                ) : (
                  <label className="fw-bold fs-6">
                    Marketplace not found...
                  </label>
                )}
              </div>
            </div>
          </>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-light-danger"
            onClick={() => {
              setSelectedMarketplace(null);
              setCheckMarketplaceList([]);
              setMarketplaceList([]);
              setSelectedRegion(null);
              onHide();
            }}
          >
            Close
          </button>
          {!selectedMakretplace || !selectedRegion || !sellerName ? (
            <span
              className="btn btn-light-info"
              style={{ pointerEvents: "none" }}
            >
              Login With Amazon
            </span>
          ) : (
            <Link
              className="btn btn-primary"
              target="_blank"
              onClick={() => {
                if (
                  checkMarketplaceList?.length !== 0 &&
                  Object.values(selectedMakretplace)?.filter((d) => !d)
                    ?.length === 0
                ) {
                  localStorage.setItem(
                    "other_marketplace",
                    JSON.stringify(
                      checkMarketplaceList?.filter(
                        (d) => d !== selectedMakretplace
                      )
                    )
                  );
                }
              }}
              to={{
                pathname: `${selectedRegion?.url}/apps/authorize/consent?application_id=${AppId}&state=${Email}!!${sellerName}!!${selectedRegion?.value}!!${selectedMakretplace}&version=beta`,
              }}
            >
              Login With Amazon
            </Link>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}
