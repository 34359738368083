import React, { useEffect, useState, useContext } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  DefaultPerPage,
  SalesChannelFlag,
  timeSince,
} from "../../../../config";
import Pagination from "../../../../component/pagination";
import Loading from "../../../../component/loading";
import moment from "moment";
import { Input, Select, Tag, message } from "antd";
import NoData from "../../../../component/no-data";
import IvcsrTable from "../../../../component/ivcsr-table";
import {
  SearchOutlined,
  ClockCircleOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { GlobalContext } from "../../../../commonContext";
import { WalmartMarketplaceList } from "../../../../config/static-select-option";

export default function (props) {
  const {
    GetDataSchedulerList,
    fakeActionDataScheduler,
    GetMarketplaceList,
    fakeActionSalesAnalytics,
  } = props;

  const GetDataSchedulerListRes = useSelector(
    (state) => state.DataScheduler.GetDataSchedulerListResponse || {}
  );

  const GetMarketplaceListRes = useSelector(
    (state) => state.SalesAnalytics.GetMarketplaceListResponse || {}
  );

  const contextVar = useContext(GlobalContext);
  const platformType = contextVar?.data?.marketplaceType;

  const [salesChannelList, setsalesChannelList] = useState([]);
  const [selectedSalesChannel, setSelectedSalesChannel] = useState([]);
  const [selectedSalesChannelId, setSelectedSalesChannelId] = useState([]);
  const [salesChannelLoading, setSalesChannelLoading] = useState(true);
  const [selectedCurrencySign, setSelectedCurrencySign] = useState("");

  const [list, setList] = useState([]);
  const [eventNameList, setEventNameList] = useState({});
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageSize, setPageSize] = useState(DefaultPerPage);
  const [filter, setFilter] = useState({
    event_status: "",
    event_name: "",
    event_type: "",
    priority: "",
  });

  useEffect(() => {
    setLoading(true);
    GetMarketplaceList();

    return () => {};
  }, [contextVar?.data?.marketplaceType]);

  useEffect(() => {
    if (GetMarketplaceListRes?.status === true) {
      setSalesChannelLoading(false);
      setsalesChannelList(GetMarketplaceListRes?.data?.marketplace_list);
      setSelectedSalesChannel("Amazon.com");
      setSelectedSalesChannelId("ATVPDKIKX0DER");
      setSelectedCurrencySign("$");

      const apiObj = {
        page: 1,
        perPage: pageSize,
        marketplaceId: "ATVPDKIKX0DER",
        marketplaceType: contextVar?.data?.marketplaceType,
      };
      GetDataSchedulerList(apiObj);

      fakeActionSalesAnalytics("GetMarketplaceListResponse");
    } else if (GetMarketplaceListRes?.status === false) {
      fakeActionSalesAnalytics("GetMarketplaceListResponse");
    }
  }, [GetMarketplaceListRes]);

  useEffect(() => {
    if (GetDataSchedulerListRes.status === true) {
      setList(GetDataSchedulerListRes?.data?.records);
      console.log(GetDataSchedulerListRes, "GetDataSchedulerListResponse");
      setLoading(false);
      setTotalPage(GetDataSchedulerListRes?.data?.pagination?.totalCount);
      fakeActionDataScheduler("GetDataSchedulerListResponse");
    } else if (GetDataSchedulerListRes.status === false) {
      setLoading(false);
      fakeActionDataScheduler("GetDataSchedulerListResponse");
    }
  }, [GetDataSchedulerListRes]);

  const columns = [
    {
      title: "#",
      ellipsis: true,
      align: "left",
      render: (item) => {
        return <span>{(page - 1) * pageSize + 1 + item.key}</span>;
      },
    },
    {
      title: "Created At",
      render: (item) => {
        return (
          <div>
            <span>
              {moment(new Date(item?.created_at * 1000)).format("MMM DD, YYYY")}
            </span>
          </div>
        );
      },
    },
    {
      title: "Event Process Type",
      dataIndex: "event_process_type",
      key: "event_process_type",
    },
    {
      title: "Event Type",
      dataIndex: "event_type",
      key: "event_type",
    },
    {
      title: "Event Name",
      dataIndex: "event_name",
      key: "event_name",
    },
    {
      title: "Daily Frequency",
      dataIndex: "daily_frequency",
      key: "daily_frequency",
    },
    {
      title: "Frequency Status",
      dataIndex: "frequency_status",
      key: "frequency_status",
      render: (item) => {
        return (
          <span>
            <Tag
              color={`${item === "PENDING" ? "warning" : "success"}`}
              icon={
                item === "PENDING" ? (
                  <ClockCircleOutlined />
                ) : item === "DONE" ? (
                  <CheckCircleOutlined />
                ) : (
                  <CloseCircleOutlined />
                )
              }
            >
              {item || "-"}
            </Tag>
          </span>
        );
      },
    },
    {
      title: "Previous Days",
      dataIndex: "previous_days",
      key: "previous_days",
    },
    {
      title: "Previous Status",
      dataIndex: "previous_status",
      key: "previous_status",
      render: (item) => {
        return (
          <span>
            <Tag
              color={`${item === "PENDING" ? "warning" : "success"}`}
              icon={
                item === "PENDING" ? (
                  <ClockCircleOutlined />
                ) : item === "DONE" ? (
                  <CheckCircleOutlined />
                ) : (
                  <CloseCircleOutlined />
                )
              }
            >
              {item || "-"}
            </Tag>
          </span>
        );
      },
    },
    {
      title: "Update Daily Days",
      dataIndex: "update_daily_days",
      key: "update_daily_days",
    },
    {
      title: "Update Daily Status",
      dataIndex: "update_daily_status",
      key: "update_daily_status",
      render: (item) => {
        return (
          <span>
            <Tag
              color={`${item === "PENDING" ? "warning" : "success"}`}
              icon={
                item === "PENDING" ? (
                  <ClockCircleOutlined />
                ) : item === "DONE" ? (
                  <CheckCircleOutlined />
                ) : (
                  <CloseCircleOutlined />
                )
              }
            >
              {item || "-"}
            </Tag>
          </span>
        );
      },
    },
    {
      title: "Marketplace",
      dataIndex: "marketplace",
      key: "marketplace",
    },
    {
      title: "Updated At",
      render: (item) => {
        return (
          <div>
            <span>
              {moment(new Date(item?.created_at * 1000)).format(
                "MMM DD, YYYY hh:mm A"
              )}
            </span>
          </div>
        );
      },
    },
  ];

  const onPageNo = (e) => {
    setLoading(true);
    const apiObj = {
      page: e,
      perPage: pageSize,
      marketplaceId: selectedSalesChannelId,
      marketplaceType: contextVar?.data?.marketplaceType,
    };
    GetDataSchedulerList(apiObj);
    setPage(e);
  };

  const onPerPage = (e) => {
    setPage(1);
    setPageSize(e);
    setLoading(true);
    const apiObj = {
      page: 1,
      perPage: e,
      marketplaceId: selectedSalesChannelId,
      marketplaceType: contextVar?.data?.marketplaceType,
    };
    GetDataSchedulerList(apiObj);
  };

  return (
    <div className="fadeInRight">
      <div className="d-flex flex-column flex-column-fluid">
        <div id="kt_app_content" className="app-content flex-column-fluid">
          <div
            id="kt_app_content_container"
            className="app-container container-fluid"
          >
            <div>
              {/*begin::Row*/}
              <div className="row gy-5 g-xl-5">
                {/*begin::Col*/}
                <div className="col-xxl-12">
                  {/*begin::Tables Widget 9*/}
                  <div className="card card-xxl-stretch mb-5 mb-xl-8">
                    {/*begin::Header*/}
                    <div className="card-header border-bottom-dashed">
                      {/*end::Title*/}
                      {/*begin::Title*/}
                      <h3 className="card-title ">
                        <span className="card-label fw-bold text-gray-900">
                          Data Scheduler
                        </span>
                      </h3>
                      {/*end::Title*/}
                      <div className="card-toolbar">
                        <div className="position-relative">
                          <Select
                            className="w-250px"
                            size="large"
                            placeholder="Select Sales Channel"
                            loading={salesChannelLoading}
                            value={selectedSalesChannelId || null}
                            onChange={(e, _) => {
                              setSelectedSalesChannelId(e);
                              setSelectedSalesChannel(_?.sales_channel);
                              setPage(1);
                              setPageSize(DefaultPerPage);
                              setLoading(true);
                              const apiObj = {
                                page: 1,
                                perPage: DefaultPerPage,
                                marketplaceId: e,
                                marketplaceType:
                                  contextVar?.data?.marketplaceType,
                              };
                              GetDataSchedulerList(apiObj);
                            }}
                            options={
                              platformType === "amazon"
                                ? SalesChannelFlag(salesChannelList)
                                : SalesChannelFlag(WalmartMarketplaceList)
                            }
                          />
                        </div>
                        {/* <div className="position-relative me-3">
                          <Select
                            className="w-150px"
                            size="large"
                            placeholder="Event Type"
                            allowClear
                            value={filter?.event_type || null}
                            onChange={(e) => {
                              const filter_ = { ...filter };
                              filter_.event_type = e || "";
                              setFilter(filter_);
                              setLoading(true);
                              setPage(1);
                              const apiObj = {
                                page: 1,
                                perPage: pageSize,
                                ...filter_,
                              };
                              GetDataSchedulerList(apiObj);
                            }}
                            getPopupContainer={(triggerNode) =>
                              triggerNode.parentNode
                            }
                            options={[
                              {
                                value: "Advertising Report",
                                label: "Advertising Report",
                              },
                            ]}
                          />
                        </div>
                        <div className="position-relative me-3">
                          {" "}
                          <Select
                            className="w-150px"
                            size="large"
                            placeholder="Event Name"
                            allowClear
                            value={filter?.event_name || null}
                            onChange={(e) => {
                              const filter_ = { ...filter };
                              filter_.event_name = e || "";
                              setFilter(filter_);
                              setLoading(true);
                              setPage(1);
                              const apiObj = {
                                page: 1,
                                perPage: pageSize,
                                ...filter_,
                              };
                              GetDataSchedulerList(apiObj);
                            }}
                            getPopupContainer={(triggerNode) =>
                              triggerNode.parentNode
                            }
                            options={Object.keys(eventNameList)?.map((d) => {
                              return {
                                label: d,
                                value: d,
                              };
                            })}
                          />
                        </div>
                        <div className="position-relative me-3">
                          <Select
                            className="w-150px"
                            size="large"
                            placeholder="Event Status"
                            allowClear
                            value={filter?.event_status || null}
                            onChange={(e) => {
                              const filter_ = { ...filter };
                              filter_.event_status = e || "";
                              setFilter(filter_);
                              setLoading(true);
                              setPage(1);
                              const apiObj = {
                                page: 1,
                                perPage: pageSize,
                                ...filter_,
                              };
                              GetDataSchedulerList(apiObj);
                            }}
                            getPopupContainer={(triggerNode) =>
                              triggerNode.parentNode
                            }
                            options={adminCentralLogEventStatus.map((d) => {
                              return {
                                label: d.label,
                                value: d.value,
                              };
                            })}
                          />
                        </div>
                        <div className="position-relative ms-3">
                          {" "}
                          <Select
                            className="w-150px"
                            size="large"
                            placeholder="Priority"
                            allowClear
                            value={filter?.priority || null}
                            onChange={(e) => {
                              const filter_ = { ...filter };
                              filter_.priority = e || "";
                              setFilter(filter_);
                              setLoading(true);
                              setPage(1);
                              const apiObj = {
                                page: 1,
                                perPage: pageSize,
                                ...filter_,
                              };
                              GetDataSchedulerList(apiObj);
                            }}
                            getPopupContainer={(triggerNode) =>
                              triggerNode.parentNode
                            }
                            options={[
                              {
                                value: "1",
                                label: "High",
                              },
                              {
                                value: "2",
                                label: "Medium",
                              },
                              {
                                value: "3",
                                label: "Low",
                              },
                            ]}
                          />
                        </div> */}
                      </div>
                    </div>
                    {/*end::Header*/}
                    {/*begin::Body*/}
                    <div className="card-body pb-5 pt-0">
                      <div className="table-responsive mt-5">
                        {/*begin::Table container*/}
                        {loading ? (
                          <Loading />
                        ) : list?.length != 0 ? (
                          <>
                            <IvcsrTable
                              columns={columns?.map((d) => ({
                                ...d,
                                width: d?.title?.length * 13,
                              }))}
                              fixed={"top"}
                              sticky={{
                                offsetHeader: "10px",
                              }}
                              scroll={{
                                x: "max-content",
                                y: "calc(100vh - 200px)",
                              }}
                              dataSource={list}
                              rowKey="key"
                              loading={loading}
                              pagination={false}
                            />
                          </>
                        ) : (
                          <NoData />
                        )}
                      </div>
                      <Pagination
                        loading={loading || list?.length === 0}
                        pageSize={pageSize}
                        page={page}
                        totalPage={totalPage}
                        onPerPage={onPerPage}
                        onPageNo={onPageNo}
                      />
                    </div>
                    {/*begin::Body*/}
                  </div>
                  {/*end::Tables Widget 9*/}
                </div>
                {/*end::Col*/}
              </div>
              {/*end::Row*/}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
