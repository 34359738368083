import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import InventoryManagement from "../../../modules/pages/inventory/inventory-management";

import {
  GetInventoryManagementList,
  GetSupplierList,
  GetReplenishmentModalData,
  SaveReplenishmentModalData,
  GetReplenishmentWarehouseListAction,
  AddEditWarehouseDetailsAction,
  GetReplenishmentForecastAction,
  DeleteWarehouseAction,
  ImportWarehouseStock,
  fakeActionInventoryManagement,
  SaveBulkInventoryAction,
} from "../../../redux/modules/inventory/inventory-management/inventory-management.action";

import {
  GetSalesChannelList,
  fakeActionSalesAnalytics,
} from "../../../redux/modules/sales-analytics/sales-analytics.action";

const mapStateToProps = (state) => ({
  GetInventoryManagementListResponse:
    state.InventoryManagement.GetInventoryManagementListResponse,
  GetSupplierListResponse: state.InventoryManagement.GetSupplierListResponse,
  GetReplenishmentModalDataResponse:
    state.InventoryManagement.GetReplenishmentModalDataResponse,
  SaveReplenishmentModalDataResponse:
    state.InventoryManagement.SaveReplenishmentModalDataResponse,
  GetReplenishmentWarehouseListResponse:
    state.InventoryManagement.GetReplenishmentWarehouseListResponse,
  AddEditReplenishmentWarehouseDetailsResponse:
    state.InventoryManagement.AddEditReplenishmentWarehouseDetailsResponse,
  DeleteReplenishmentWarehouseResponse:
    state.InventoryManagement.DeleteReplenishmentWarehouseResponse,
  GetReplenishmentForecastDataResponse:
    state.InventoryManagement.GetReplenishmentForecastDataResponse,
  ImportWarehouseStockDataResponse:
    state.InventoryManagement.ImportWarehouseStockDataResponse,
  SaveBulkInventoryResponse:
    state.InventoryManagement.SaveBulkInventoryResponse,

  GetSalesChannelListResponse: state.SalesAnalytics.GetSalesChannelListResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      GetInventoryManagementList,
      GetSupplierList,
      GetReplenishmentModalData,
      SaveReplenishmentModalData,
      GetReplenishmentWarehouseListAction,
      AddEditWarehouseDetailsAction,
      DeleteWarehouseAction,
      ImportWarehouseStock,
      GetReplenishmentForecastAction,
      SaveBulkInventoryAction,
      fakeActionInventoryManagement,
      GetSalesChannelList,
      fakeActionSalesAnalytics,
    },
    dispatch
  );

const InventoryManagementContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(InventoryManagement);

export default InventoryManagementContainer;
