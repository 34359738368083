import React, { useContext, useEffect } from "react";
import { GlobalContext } from "../../../commonContext";
import AmazonSalesAnalytics from "./amazon-sales-analytics";
import WamlartSalesAlaytics from "./wamlart-sales-alaytics";
import UnderConstruction from "../../../component/under-construction/under-construction";

export default function (props) {
  const contextVar = useContext(GlobalContext);

  return contextVar?.data?.marketplaceType === "amazon" ? (
    <AmazonSalesAnalytics {...props} />
  ) : (
    // <WamlartSalesAlaytics {...props} />
    <WamlartSalesAlaytics {...props} />
  );
}
