import axiosCall from "../../../../configurations/network-services/axiosCall";

// export const GetAdvFilters = () => {
//   const path = `advertising/dashboard/filters`;
//   const responseType = "GET_FILTERS_LIST";
//   return axiosCall("get", path, responseType, null, {
//     Authorization: `Bearer ${localStorage.getItem("token")}`,
//   });
// };

export const GetWMKeywordSummeryData = (data) => {
  const path = `wm/keyword/summary-data?start_date=${
    data?.start_date || ""
  }&end_date=${data?.end_date || ""}&campaign_id=${
    data?.campaign || ""
  }&attributed_days=${data?.attribute || ""}&ad_group_id=${
    data?.ad_group || ""
  }`;
  const responseType = "GET_WM_KEYWORD_SUMMERY_DATA";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetWMKeywordChartData = (data) => {
  const path = `wm/keyword/chart-data?start_date=${
    data?.start_date || ""
  }&end_date=${data?.end_date || ""}&campaign_id=${
    data?.campaign || ""
  }&attributed_days=${data?.attribute || ""}&ad_group_id=${
    data?.ad_group || ""
  }`;
  const responseType = "GET_WM_KEYWORD_CHART_DATA";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetWMKeywordListData = (data) => {
  const path = `wm/keyword/list?start_date=${data?.start_date || ""}&end_date=${
    data?.end_date || ""
  }&campaign_id=${data?.campaign || ""}&attributed_days=${
    data?.attribute || ""
  }&ad_group_id=${data?.ad_group || ""}`;
  const responseType = "GET_WM_KEYWORD_LIST_DATA";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const GetWmSearchTrendsData = (data) => {
  const path = `wm/keyword/search-trends-report?page=${
    data?.page || 1
  }&per-page=${data?.perPage || 25}&start_date=${
    data?.start_date || ""
  }&end_date=${data?.end_date || ""}`;
  const responseType = "GET_WM_SEARCH_TRENDS_DATA";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const GetWmItemRecommendation = (data) => {
  const path = `wm/keyword/item-recommendation?page=${
    data?.page || 1
  }&per-page=${data?.perPage || 25}`;
  const responseType = "GET_WM_ITEM_RECOMMENDATION";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const GetWmKeywordRecommendation = (data) => {
  const path = `wm/keyword/keyword-recommendation?page=${
    data?.page || 1
  }&per-page=${data?.perPage || 25}`;
  const responseType = "GET_WM_KEYWORD_RECOMMENDATION";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const fakeActionWalmartKeyword = (data) => async (dispatch) => {
  dispatch({ type: "FAKE_ACTION_WALMART_KEYWORD", state: data });
};
