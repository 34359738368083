import { Alert, Checkbox, Input, Select, Tag, message } from "antd";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { NumberWithCommas, SalesChannelFlag } from "../../../../../config";
import { Link } from "react-router-dom";
import IvcsrTable from "../../../../../component/ivcsr-table";
export default function (props) {
  const {
    show,
    onHide,
    advProfileData,
    SaveAmazonAdvCredential,
    fakeActionMarketplaceCred,
    profileIds,
    setProfileIds,
  } = props;

  const SaveAmazonAdvCredentialRes = useSelector(
    (state) => state.MarketplaceCredential.SaveAmazonAdvCredentialResponse || {}
  );

  // console.log(advProfileData, "advProfileData");
  // const [profileIds, setProfileIds] = useState([]);

  const marketplaceObj = {
    A2Q3Y263D00KWC: "Amazon.com.br",
    A2EUQ1WTGCTBG2: "Amazon.ca",
    A1AM78C64UM0Y8: "Amazon.com.mx",
    ATVPDKIKX0DER: "Amazon.com",
    A2VIGQ35RCS4UG: "Amazon.ae",
    A1PA6795UKMFR9: "Amazon.de",
    A1RKKUPIHCS9HS: "Amazon.es",
    A13V1IB3VIYZZH: "Amazon.fr",
    A1F83G8C2ARO7P: "Amazon.uk",
    A21TJRUUN4KGV: "Amazon.in",
    APJ6JRA9NG5V4: "Amazon.it",
    A1805IZSGTT6HS: "Amazon.nl",
    A17E79C6D8DWNP: "Amazon.sa",
    A33AVAJ2PDY3EV: "Amazon.tr",
    A19VAU5U5O7RUS: "Amazon.sg",
    A1VC38T7YXB528: "Amazon.jp",
    AAHKV2X7AFYLW: "Amazon.cn",
  };

  useEffect(() => {
    if (SaveAmazonAdvCredentialRes.status === true) {
      message.destroy();
      message.success(SaveAmazonAdvCredentialRes?.message);
      onHide();
      fakeActionMarketplaceCred("SaveAmazonAdvCredentialResponse");
    } else if (SaveAmazonAdvCredentialRes.status === false) {
      message.destroy();
      onHide();
      message.error(SaveAmazonAdvCredentialRes?.message);
      fakeActionMarketplaceCred("SaveAmazonAdvCredentialResponse");
    }
  }, [SaveAmazonAdvCredentialRes]);

  const columns = [
    {
      title: "Action",
      render: (text) => {
        return (
          <Checkbox
            checked={
              profileIds.filter((a) => a == text.profileId)?.length == 1
                ? true
                : false
            }
            onChange={(e) => {
              const checkboxId = text.profileId;
              setProfileIds((prev) => {
                if (e?.target?.checked) {
                  return [...prev, checkboxId];
                } else {
                  return prev.filter((profileId) => profileId !== checkboxId);
                }
              });
            }}
          />
        );
      },
    },
    {
      title: "Ad Profile Id",
      dataIndex: "profileId",
    },
    {
      title: "Name",
      render: (text) => {
        return (
          <span className="fw-bold">{text?.accountInfo?.name || "-"}</span>
        );
      },
    },
    {
      title: "Type",
      render: (text) => {
        return (
          <Tag
            color={text?.accountInfo?.type === "vendor" ? "blue" : "success"}
            style={{ textTransform: "capitalize" }}
            className="fw-bold"
          >
            {text?.accountInfo?.type || "-"}
          </Tag>
        );
      },
    },
    {
      title: "Marketplace",
      render: (text) => {
        return (
          <span className="fw-bold">
            {marketplaceObj[text?.accountInfo?.marketplaceStringId] || "-"}
          </span>
        );
      },
    },
    {
      title: "Marketplace Id",
      render: (text) => {
        return <span>{text?.accountInfo?.marketplaceStringId || "-"}</span>;
      },
    },
    {
      title: "Daily Budget",
      render: (text) => {
        return <span>{NumberWithCommas(text?.dailyBudget || 0)}</span>;
      },
    },
    {
      title: "Country Code",
      dataIndex: "countryCode",
    },
    {
      title: "Currency Code",
      dataIndex: "currencyCode",
    },
    {
      title: "Seller Id",
      render: (text) => {
        return <span>{text?.accountInfo?.sellerStringId || "-"}</span>;
      },
    },
    {
      title: "Brand Entity Id",
      render: (text) => {
        return <span>{text?.accountInfo?.brandEntityId || "-"}</span>;
      },
    },
    {
      title: "brand Name",
      render: (text) => {
        return <span>{text?.accountInfo?.brandName || "-"}</span>;
      },
    },
    {
      title: "Time Zone",
      dataIndex: "timezone",
    },
  ];

  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          onHide();
        }}
        size="xl"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Advertisement Credential</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <div style={{ height: "500px", overflow: "auto" }}>
              <IvcsrTable
                columns={columns}
                dataSource={advProfileData}
                pagination={false}
                scroll={{ x: "max-content" }}
              />
            </div>
          </>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-light-danger"
            onClick={() => {
              onHide();
            }}
          >
            Close
          </button>
          <button
            className="btn btn-primary"
            onClick={() => {
              const Obj = {
                ad_profile_ids: profileIds,
              };
              // console.log(Obj, "Obj");
              message.destroy();
              message.loading("Loading...", 0);
              SaveAmazonAdvCredential(Obj);
            }}
          >
            Save
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
