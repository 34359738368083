import React, { useContext, useEffect } from "react";
import { GlobalContext } from "../../../commonContext";
import { useHistory } from "react-router-dom";
import WalmartReport from "./walmart-report";
import UnderConstruction from "../../../component/under-construction/under-construction";

export default function (props) {
  const history = useHistory();
  const contextVar = useContext(GlobalContext);

  return contextVar?.data?.marketplaceType === "amazon" ? (
    history.replace("/dashboard")
  ) : (
    <WalmartReport {...props} />
    // <UnderConstruction />
  );
}
