import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AdvanceReport from "../../modules/pages/advance-report";

import {
  GetWalmartInsightsReportList,
  GetWalmartPerformanceData,
  GetWalmartAdvanceReportSummaryData,
  GetWalmartAdvanceReportChartData,
  fakeActionWalmartAdvanceReports,
} from "../../redux/modules/advance-report/advance-report.action";

const mapStateToProps = (state) => ({
  GetWalmartInsightReportListResponse:
    state.AdvanceReport.GetWalmartInsightReportListResponse,
  GetWalmartPerformanceDataResponse:
    state.AdvanceReport.GetWalmartPerformanceDataResponse,
  GetAdvanceReportSummaryDataResponse:
    state.AdvanceReport.GetAdvanceReportSummaryDataResponse,
  GetAdvanceReportChartDataResponse:
    state.AdvanceReport.GetAdvanceReportChartDataResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      GetWalmartInsightsReportList,
      GetWalmartPerformanceData,
      GetWalmartAdvanceReportSummaryData,
      GetWalmartAdvanceReportChartData,
      fakeActionWalmartAdvanceReports,
    },
    dispatch
  );

const AdvanceReportContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AdvanceReport);

export default AdvanceReportContainer;
