import React, { useContext, useEffect, useState } from "react";
// import { Wrapper, NewLogin } from "./style";
import { Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { Input, message } from "antd";
import { Link } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { GlobalContext } from "../../../../commonContext";

export default function (props) {
  const { loginAction, fakeActionAuth } = props;

  const [redirectPage, setRedirectPage] = useState(false);
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [sending, setSending] = useState(false);
  const contextVar = useContext(GlobalContext);
  const LoginRes = useSelector((state) => state.Auth.LoginResponse || {});

  const onLogin = (e) => {
    e.preventDefault();
    if (username && password) {
      setSending(true);
      const Obj = {
        username: username,
        password: password,
      };
      loginAction(Obj);
      // localStorage.setItem("token", "token");
      // localStorage.setItem("userType", 1);
      // setRedirectPage(true);
    }
  };

  useEffect(() => {
    if (LoginRes?.status) {
      setSending(false);
      message.destroy();
      // message.success("Login Successfully");
      localStorage.setItem("user", JSON.stringify(LoginRes?.data?.user_data));
      localStorage.setItem("userType", LoginRes?.data?.user_data?.user_type);
      localStorage.setItem("token", LoginRes?.data?.auth_token);
      localStorage.setItem(
        "userCredential",
        JSON.stringify(LoginRes?.data?.credentials)
      );
      localStorage.setItem(
        "platformType",
        LoginRes?.data?.user_data?.connected_by === "both"
          ? "amazon"
          : LoginRes?.data?.user_data?.connected_by
      );
      localStorage.setItem(
        "connected_by",
        LoginRes?.data?.user_data?.connected_by
      );
      // localStorage.setItem("platformType", "amazon");
      // setPlatformType(e);
      contextVar?.updateCommonGlobalVal({
        marketplaceType:
          LoginRes?.data?.user_data?.connected_by === "both"
            ? "amazon"
            : LoginRes?.data?.user_data?.connected_by,
        user_data: LoginRes?.data?.user_data,
      });
      // setPlatformType(e);
      setRedirectPage(true);
      fakeActionAuth("LoginResponse");
    } else if (Object.keys(LoginRes).length !== 0) {
      setSending(false);
      message.destroy();
      message.error(LoginRes?.message);
      fakeActionAuth("LoginResponse");
    }
  }, [LoginRes]);

  if (localStorage.getItem("userType") || redirectPage) {
    return (
      <Redirect
        to={
          localStorage.getItem("userType") == 1 ? `/manage-user` : "/dashboard"
        }
      />
    );
  }

  return (
    <>
      <div
        className="d-flex flex-column flex-column-fluid flex-lg-row"
        style={{
          height: "100%",
          backgroundImage: "url(/assets/media/loginBgSvg.svg)",
          backgroundSize: "cover",
        }}
      >
        <div className="d-flex flex-center w-lg-50 pt-15 pt-lg-0 px-10">
          <div className="d-flex flex-center flex-lg-start flex-column">
            <span
              className="mb-7 d-flex"
              style={{
                display: window.innerWidth > 992 ? "" : "flex",
                justifyContent: window.innerWidth > 992 ? "" : "center",
              }}
            >
              <img
                alt="Logo"
                src="/assets/media/all-images/ivcsr-logo-new.png"
                className={`shimmer ${window.innerWidth > 992 ? "h-80px" : ""}`}
                style={{ width: window.innerWidth > 992 ? "" : "80%" }}
              />
            </span>
            <h2 className="text-dark fw-light m-0 fs-1x">
              Working for <b>your Growth</b>
            </h2>
          </div>
        </div>

        <div className="d-flex flex-center w-lg-50 p-10">
          <div
            className="card rounded-3 w-md-550px "
            style={{
              borderTop: "3px solid #4318ff",
              boxShadow:
                "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px",
              //  background: "#e4e6fe7a"
            }}
          >
            <div className="card-body d-flex flex-column p-10 p-lg-20 pb-lg-10">
              <div className="d-flex flex-center flex-column-fluid pb-15 pb-lg-20">
                <span className="form w-100">
                  <div className="text-center mb-11">
                    <h1 className="text-dark fw-bolder mb-3">Sign In</h1>

                    <div className="text-gray-500 fw-semibold fs-6">
                      Your Social Campaigns
                    </div>
                  </div>

                  <div className="fv-row mb-8">
                    <Input
                      type="text"
                      placeholder="Email"
                      name="email"
                      autoComplete="off"
                      className="form-control h-60px bg-transparent"
                      style={{ borderColor: "#9182fe" }}
                      onChange={(e) => {
                        setUserName(e.target.value);
                      }}
                    />
                  </div>

                  <div className="fv-row mb-3">
                    <Input
                      type="password"
                      placeholder="Password"
                      name="password"
                      autoComplete="off"
                      className="form-control h-60px bg-transparent"
                      style={{ borderColor: "#9182fe" }}
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                      onPressEnter={(e) => {
                        onLogin(e);
                      }}
                    />
                  </div>

                  <div className="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8">
                    <div />

                    <a href="" className="fw-bolder text-info">
                      Forgot Password ?
                    </a>
                  </div>

                  <div className="d-grid mb-10">
                    <button
                      onClick={onLogin}
                      className="btn btn-primary h-50px"
                      disabled={sending}
                    >
                      {sending ? (
                        <span className="fw-bold">
                          Please Wait...
                          <LoadingOutlined className="ms-3" />
                        </span>
                      ) : (
                        <span>Sign In</span>
                      )}
                    </button>
                  </div>

                  <div className="text-gray-500 text-center fw-semibold fs-6">
                    Not a Member yet?
                    <Link
                      to="/create-account"
                      className=" fw-bolder ms-2"
                      style={{ color: "#007c00" }}
                    >
                      Sign up
                    </Link>
                  </div>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
