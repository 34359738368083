import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { DefaultPerPage, timeSince } from "../../../../config";
import IvcsrTable from "../../../../component/ivcsr-table";
import { Popconfirm, Segmented, Tooltip, message } from "antd";
import moment from "moment/moment";
import Loading from "../../../../component/loading";
import NoData from "../../../../component/no-data";
import Pagination from "../../../../component/pagination";
import UserModal from "./lib/report-modal";
import ReportModal from "./lib/report-modal";

export default function (props) {
  const {
    // GetUserList,
    // SwitchUserAction,
    // DeleteUserAction,
    // fakeActionManageUser,
  } = props;
  // const GetUserListRes = useSelector(
  //   (state) => state.ManageUser.GetUserListResponse || {}
  // );
  // const SwitchUserRes = useSelector(
  //   (state) => state.ManageUser.SwitchUserResponse || {}
  // );
  // const DeleteUserRes = useSelector(
  //   (state) => state.ManageUser.DeleteUserResponse || {}
  // );

  const history = useHistory();

  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageSize, setPageSize] = useState(DefaultPerPage);
  const [openModal, setOpenModal] = useState(true);
  const [selectedRow, setSelectedRow] = useState([]);
  const [selectedSegment, setSelectedSegment] = useState(1);

  // useEffect(() => {
  //   GetUserList();
  // }, []);

  // useEffect(() => {
  //   if (GetUserListRes.status === true) {
  //     setList(GetUserListRes?.data?.records);
  //     console.log(GetUserListRes, "GetUserListRes");
  //     setLoading(false);
  //     setTotalPage(GetUserListRes?.data?.pagination?.totalCount);
  //     fakeActionManageUser("GetUserListResponse");
  //   } else if (GetUserListRes.status === false) {
  //     setLoading(false);
  //     // message.error(SwitchUserRes?.message);
  //     fakeActionManageUser("GetUserListResponse");
  //   }
  // }, [GetUserListRes]);

  // useEffect(() => {
  //   if (SwitchUserRes.status === true) {
  //     message.destroy();
  //     message.success(SwitchUserRes?.message);
  //     setTimeout(() => {
  //       let adminToken = localStorage.getItem("token");
  //       let adminData = localStorage.getItem("user");
  //       const data = {
  //         token: adminToken,
  //         data: adminData,
  //       };

  //       localStorage.setItem("adminData", JSON.stringify(data));

  //       localStorage.setItem(
  //         "user",
  //         JSON.stringify(SwitchUserRes?.data?.user_data)
  //       );
  //       localStorage.setItem(
  //         "userType",
  //         SwitchUserRes?.data?.user_data?.user_type
  //       );
  //       localStorage.setItem(
  //         "userCredential",
  //         JSON.stringify(SwitchUserRes?.data?.credentials)
  //       );
  //       localStorage.setItem("token", SwitchUserRes?.data?.auth_token);
  //       history.replace("/dashboard");
  //       fakeActionManageUser("SwitchUserResponse");
  //     }, 500);
  //   } else if (SwitchUserRes.status === false) {
  //     message.destroy();
  //     message.error(SwitchUserRes?.message);
  //     fakeActionManageUser("SwitchUserResponse");
  //   }
  // }, [SwitchUserRes]);

  // useEffect(() => {
  //   if (DeleteUserRes.status === true) {
  //     message.destroy();
  //     message.success(DeleteUserRes?.message);
  //     setPage(1);
  //     setPageSize(DefaultPerPage);
  //     setLoading(true);
  //     GetUserList({
  //       page: 1,
  //       perPage: DefaultPerPage,
  //     });
  //     fakeActionManageUser("DeleteUserResponse");
  //   } else if (DeleteUserRes.status === false) {
  //     message.destroy();
  //     message.error(DeleteUserRes?.message);
  //     fakeActionManageUser("DeleteUserResponse");
  //   }
  // }, [DeleteUserRes]);

  // const columns = [
  //   {
  //     title: "Sr. No.",
  //     ellipsis: true,
  //     width: 100,
  //     align: "left",
  //     render: (_, __, i) => {
  //       return <span>{(page - 1) * pageSize + 1 + i}</span>;
  //     },
  //   },
  //   {
  //     title: "Name",
  //     width: 150,
  //     align: "left",
  //     render: (text) => {
  //       return <span className="fw-bolder">{text?.name || "-"}</span>;
  //     },
  //   },
  //   {
  //     title: "Email",
  //     width: 200,
  //     align: "left",
  //     render: (text) => {
  //       return <span>{text?.email || "-"}</span>;
  //     },
  //   },
  //   {
  //     title: "Contact No",
  //     width: 150,
  //     align: "left",
  //     render: (text) => {
  //       return <span>{text?.contact_no || "-"}</span>;
  //     },
  //   },

  //   {
  //     title: "Switch User",
  //     width: 150,
  //     align: "center",
  //     render: (text) => {
  //       return (
  //         <Tooltip title="Switch user">
  //           <span
  //             onClick={() => {
  //               message.destroy();
  //               message.loading("Switching...", 0);
  //               // SwitchUserAction(text?.id);
  //             }}
  //             style={{ cursor: "pointer" }}
  //           >
  //             <i class="ki-outline ki-arrow-right-left fs-1 text-danger me-3" />
  //           </span>
  //         </Tooltip>
  //       );
  //     },
  //   },
  //   {
  //     title: "Created At",
  //     width: 200,
  //     align: "left",
  //     render: (text) => {
  //       return (
  //         <div>
  //           <span>
  //             {moment(new Date(text.created_at * 1000)).format(
  //               "MMM DD, YYYY hh:mm A"
  //             )}
  //           </span>
  //           <br />
  //           <span className="timeStampColor">
  //             ({timeSince(text.created_at)})
  //           </span>
  //         </div>
  //       );
  //     },
  //   },

  //   {
  //     title: "Updated At",
  //     width: 200,
  //     align: "left",
  //     render: (text) => {
  //       return (
  //         <div>
  //           <span>
  //             {text?.updated_at !== 1
  //               ? moment(new Date(text.updated_at * 1000)).format(
  //                   "MMM DD, YYYY hh:mm A"
  //                 )
  //               : moment(new Date()).format("MMM DD, YYYY hh:mm A")}
  //           </span>
  //           <br />
  //           <span className="timeStampColor">
  //             {text?.updated_at !== 1
  //               ? timeSince(text?.updated_at)
  //               : timeSince(Date.parse(new Date()) / 1000)}
  //           </span>
  //         </div>
  //       );
  //     },
  //   },
  //   {
  //     title: "Action",
  //     width: 100,
  //     align: "center",
  //     render: (text) => {
  //       return (
  //         <div className="d-flex flex-shrink-0">
  //           <span
  //             className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2"
  //             onClick={() => {
  //               setSelectedRow(text);
  //               setOpenModal(true);
  //             }}
  //           >
  //             <i className="ki-outline ki-pencil fs-2 text-primary" />
  //           </span>

  //           <Popconfirm
  //             placement="left"
  //             title="Delete"
  //             description="Are you sure to delete this User ?"
  //             onConfirm={() => {
  //               message.destroy();
  //               message.loading("Loading", 0);
  //               DeleteUserAction(text?.id);
  //             }}
  //             onCancel={() => {}}
  //             okText="Yes"
  //             cancelText="No"
  //             okButtonProps={{
  //               style: { backgroundColor: "green" },
  //             }}
  //           >
  //             <span className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
  //               <i className="ki-outline ki-trash fs-2 text-danger" />
  //             </span>
  //           </Popconfirm>
  //         </div>
  //       );
  //     },
  //   },
  // ];

  // const onPageNo = (e) => {
  //   setLoading(true);
  //   const apiObj = {
  //     page: e,
  //     perPage: pageSize,
  //   };
  //   GetUserList(apiObj);
  //   setPage(e);
  // };

  // const onPerPage = (e) => {
  //   setPage(1);
  //   setPageSize(e);
  //   setLoading(true);
  //   const apiObj = {
  //     page: 1,
  //     perPage: e,
  //   };
  //   GetUserList(apiObj);
  // };

  const columns = [];
  return (
    <div className="fadeInRight">
      <>
        <div className="d-flex flex-column flex-column-fluid">
          <div id="kt_app_content" className="app-content flex-column-fluid">
            <div
              id="kt_app_content_container"
              className="app-container container-fluid"
            >
              {/*begin::Row*/}
              <div className="row gy-5 g-xl-5">
                {/*begin::Col*/}
                <div className="col-xxl-12">
                  {/*begin::Tables Widget 9*/}
                  <div className="card card-xxl-stretch mb-5 mb-xl-8">
                    {/*begin::Header*/}
                    <div className="card-header border-bottom-dashed">
                      {/*end::Title*/}
                      {/*begin::Title*/}
                      <h3 className="card-title ">
                        <span className="card-label fw-bold text-gray-900"></span>
                      </h3>
                      {/*end::Title*/}
                      <div className="card-toolbar">
                        <div className="position-relative mr-4"></div>
                        <div className="position-relative ms-3">
                          {" "}
                          <button
                            className="btn btn-primary "
                            onClick={() => {
                              setOpenModal(true);
                            }}
                          >
                            Schedule Report
                          </button>
                        </div>
                      </div>
                    </div>
                    {/*end::Header*/}
                    {/*begin::Body*/}
                    <div className="card-body pb-5 pt-0">
                      {/*begin::Table container*/}
                      <div className="table-responsive mt-5">
                        {loading ? (
                          <Loading />
                        ) : list?.length !== 0 ? (
                          <>
                            <IvcsrTable
                              columns={columns}
                              fixed="top"
                              dataSource={list}
                              rowKey="key"
                              loading={loading}
                              pagination={false}
                              scroll={{ x: "max-content" }}
                            />
                          </>
                        ) : (
                          <NoData type="new" />
                        )}
                      </div>
                      {/* <Pagination
                        loading={loading || list?.length === 0}
                        pageSize={pageSize}
                        page={page}
                        totalPage={totalPage}
                        onPerPage={onPerPage}
                        onPageNo={onPageNo}
                      /> */}
                    </div>
                    {/*begin::Body*/}
                  </div>
                  {/*end::Tables Widget 9*/}
                </div>
                {/*end::Col*/}
              </div>
              {/*end::Row*/}
            </div>
          </div>
        </div>
      </>
      {openModal && (
        <ReportModal
          show={openModal}
          onHide={() => {
            setOpenModal(false);
            setSelectedRow([]);
          }}
          data={selectedRow}
          onSuccess={() => {
            setOpenModal(false);
            setList([]);
            setSelectedRow([]);
            setPage(1);
            setPageSize(DefaultPerPage);
            setLoading(true);
          }}
          {...props}
        />
      )}
    </div>
  );
}
