import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ProcessOrder from "../../../../modules/pages/order-management/order/process-order/process-order";
import {
  GetOrderDetails,
  WalmartShipItemAction,
  WalmartCancelItemAction,
  fakeActionWalmartOrders,
} from "../../../../redux/modules/order-management/orders/orders.action";

import {
  GetReturnCenterAddress,
  SaveReturnCenterAddr,
  fakeActionuserConfiguration,
} from "../../../../redux/modules/setting/configuration/configuration.action";

const mapStateToProps = (state) => ({
  GetWalmartOrdersDetailsResponse:
    state.WalmartOrders.GetWalmartOrdersDetailsResponse,
  WalmartShipmentItemsResponse:
    state.WalmartOrders.WalmartShipmentItemsResponse,
  WalmartCancelItemsResponse: state.WalmartOrders.WalmartCancelItemsResponse,

  GetReturnCenterAddrResponse: state.Configuration.GetReturnCenterAddrResponse,
  SaveReturnCenterAddrResponse:
    state.Configuration.SaveReturnCenterAddrResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      GetOrderDetails,
      WalmartShipItemAction,
      WalmartCancelItemAction,
      fakeActionWalmartOrders,

      GetReturnCenterAddress,
      SaveReturnCenterAddr,
      fakeActionuserConfiguration,
    },
    dispatch
  );

const ProcessOrderContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProcessOrder);

export default ProcessOrderContainer;
