import { Alert, Input, Popover, message } from "antd";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import ReactJson from "react-json-view";
import { useSelector } from "react-redux";
import { CloseOutlined } from "@ant-design/icons";
import IvcsrTable from "../../../../component/ivcsr-table";
export default function ({
  show,
  onHide,
  requestedJson,
  responseJson,
  errorList,
  walmartStatus,
}) {
  const [isUpdateViewStatus, setIsUpdateViewStatus] = useState(false);
  const columns = [
    {
      title: "Feed Id",
      dataIndex: "feedId",
      key: "feedId",
      render: (text) => {
        return (
          <Popover
            content={
              <div
                style={{
                  height: "max-content",
                  width: "max-content",
                }}
              >
                {text}
              </div>
            }
            placement="bottom"
            getPopupContainer={(triggerNode) =>
              document.getElementById("test").parentNode
            }
          >
            <div
              className="text-primary mb-1 fw-bolder text-one fs-6 popoverActionIconSingleLine"
              style={{ minWidth: "100px" }}
            >
              {text}
            </div>
          </Popover>
        );
      },
    },
    {
      title: "SKU",
      dataIndex: "sku",
      key: "sku",
    },
    {
      title: "Feed Status",
      dataIndex: "feedStatus",
      key: "feedStatus",
    },
    {
      title: "Field",
      dataIndex: "field",
      key: "field",
    },
    {
      title: "Message",
      dataIndex: "description",
      key: "description",
      render: (text) => {
        return (
          <Popover
            content={
              <div
                style={{
                  height: "max-content",
                  width: "500px",
                }}
              >
                {text}
              </div>
            }
            placement="bottom"
            getPopupContainer={(triggerNode) =>
              document.getElementById("test").parentNode
            }
          >
            <div
              className="text-danger mb-1 fw-bolder text-one fs-6 popoverActionIconSingleLine"
              style={{ minWidth: "100px" }}
            >
              {text}
            </div>
          </Popover>
        );
      },
    },
  ];

  const successCol = [
    {
      title: "Feed Id",
      dataIndex: "feedId",
      key: "feedId",
      render: (text) => {
        return (
          <Popover
            content={
              <div
                style={{
                  height: "max-content",
                  width: "max-content",
                }}
              >
                {text}
              </div>
            }
            placement="bottom"
            getPopupContainer={(triggerNode) =>
              document.getElementById("test").parentNode
            }
          >
            <div
              className="text-primary mb-1 fw-bolder text-one fs-6 popoverActionIconSingleLine"
              style={{ minWidth: "100px" }}
            >
              {text}
            </div>
          </Popover>
        );
      },
    },
    {
      title: "Feed Status",
      dataIndex: "feedStatus",
      key: "feedStatus",
    },
  ];

  return (
    <>
      <Modal show={show} onHide={onHide} size="xl" centered backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Erorr Log</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <div id="test" style={{ height: "600px", overflow: "auto" }}>
              <div>
                {walmartStatus === "SUCCESS" ? (
                  <IvcsrTable
                    columns={successCol?.map((d) => ({
                      ...d,
                    }))}
                    fixed={true}
                    sticky={{
                      offsetHeader: "0px",
                    }}
                    dataSource={errorList}
                    rowKey="key"
                    loading={false}
                    pagination={false}
                    scroll={{ x: "max-content", y: 700 }}
                  />
                ) : (
                  <IvcsrTable
                    columns={columns?.map((d) => ({
                      ...d,
                      width:
                        d?.title === "Feed Id"
                          ? 200
                          : d?.title === "Message"
                          ? 300
                          : d?.title === "SKU"
                          ? 200
                          : d?.title?.length * 13,
                    }))}
                    fixed={true}
                    sticky={{
                      offsetHeader: "0px",
                    }}
                    dataSource={errorList}
                    rowKey="key"
                    loading={false}
                    pagination={false}
                    scroll={{ x: "max-content", y: 700 }}
                  />
                )}
              </div>
              <div className="fs-4 text-primary fw-bold d-flex justify-content-between mt-10 mb-5 cursor-pointer px-5">
                <div
                  onClick={() => {
                    setIsUpdateViewStatus(true);
                  }}
                  style={{ textDecoration: "underline" }}
                >
                  View Walmart Response Details
                </div>
                <div
                  className="text-danger"
                  onClick={() => {
                    setIsUpdateViewStatus(false);
                  }}
                >
                  {isUpdateViewStatus && <CloseOutlined />}
                </div>
              </div>
              {isUpdateViewStatus && (
                <div className="">
                  <ReactJson
                    src={responseJson}
                    theme="rjv-default"
                    displayDataTypes={false}
                    collapsed={false}
                    style={{
                      overflow: "auto",
                      height: "100%",
                      border: "1px solid #5142a0b0",
                      borderRadius: "4px",
                      padding: "10px",
                    }}
                  />
                </div>
              )}
            </div>
          </>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-light-danger"
            onClick={onHide}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
