import { Skeleton, Tag } from "antd";
import PreviewImage from "../../../../component/preview-image";
import moment from "moment";

export default function (props) {
  const { productData, allLoading } = props;
  return (
    <>
      {" "}
      <div
        className="p-5 mb-7 rounded border border-gray-400 border-dashed"
        style={{ backgroundColor: "#f2efff" }}
      >
        <div className="d-flex flex-wrap flex-sm-nowrap">
          {allLoading?.mainHeaderLoading ? (
            <Skeleton
              avatar
              paragraph={{
                rows: 3,
              }}
            />
          ) : (
            <>
              <div className="me-10 mb-0">
                <div className="symbol symbol-125px  symbol-fixed position-relative">
                  <PreviewImage
                    imageUrl={
                      productData
                        ? JSON.parse(productData?.images || "[]")?.[0]?.link
                        : ""
                    }
                  />
                </div>
              </div>
              <div className="flex-grow-1">
                <div className="d-flex justify-content-between align-items-start flex-wrap mb-3">
                  <div className="" style={{ width: "100%" }}>
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="d-flex align-items-center mb-2 ">
                        <div className="d-flex">
                          <div
                            className="d-flex flex-wrap fw-bold fs-3 text-primary"
                            style={{ cursor: "pointer" }}
                          >
                            {productData?.item_name || "-"}
                          </div>
                          <span href="#" className="ms-2">
                            <i className="ki-duotone ki-verify fs-1 text-primary">
                              <span className="path1" />
                              <span className="path2" />
                            </i>
                          </span>
                        </div>
                      </div>
                      <div></div>
                    </div>
                    <div className="d-flex align-items-center">
                      <div>
                        <span className="me-2">Product Types:</span>
                        <span href="#" className="text-dark fs-6 fw-bold me-1">
                          {productData?.product_types || "-"}
                        </span>
                      </div>

                      <div className="ms-3">
                        <span className="me-2">ASIN:</span>
                        <span href="#" className="text-dark fs-6 fw-bold me-1">
                          {productData?.asin || "-"}
                        </span>
                      </div>
                      <div className="ms-3">
                        <label className="ms-2">
                          <Tag color="#108ee9" className="fw-bolder fs-5">
                            Product Score :{productData?.product_score || "0"}
                          </Tag>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-wrap flex-stack justify-content-end border-top border-top-dashed border-gray-400">
                  <div className="d-flex flex-column flex-grow-1 pe-8 mt-4">
                    <div className="d-flex flex-wrap justify-content-start">
                      <div className="pb-0 fs-6 d-flex justify-content-between ">
                        <div className="min-w-150px px-3 ">
                          <div className="fw-semibold fs-7">Brand</div>
                          <div className="text-primary fw-bold">
                            {productData?.brand || "-"}
                          </div>
                        </div>

                        <div className="min-w-150px px-3 ">
                          <div className="fw-semibold fs-7">Last sync</div>
                          <div className="text-primary fw-bold">
                            {moment(
                              new Date(productData?.updated_at * 1000)
                            ).format("MMM DD, YYYY")}
                          </div>
                        </div>

                        <div className="min-w-150px px-3 ">
                          <div className="fw-semibold fs-7">Keywords</div>
                          <div className="text-primary fw-bold">
                            {productData?.item_type_keyword || "-"}
                          </div>
                        </div>

                        <div className="min-w-175px px-3 ">
                          <div className="fw-semibold fs-7">Category Name</div>
                          <div className="text-primary fw-bold">
                            {productData?.category_name || "-"}
                            <small className="text-dark">
                              ({productData?.category_id || "-"})
                            </small>
                          </div>
                        </div>

                        <div className="min-w-150px px-3 ">
                          <div className="fw-semibold fs-7">
                            Lowest Category
                          </div>
                          <div className="text-primary fw-bold">
                            {productData?.lowest_category_name || "-"}
                            <small className="text-dark">
                              ({productData?.lowest_category_id || "-"})
                            </small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
