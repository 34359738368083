import React from "react";

const Icons = (icon) => {
  const { type, ...rest } = icon;
  const getIcon = () => {
    switch (type) {
      case "dashboard":
        return <i className="ki-outline ki-notification-status fs-1" />;
      case "graph-testing":
        return <i className="ki-outline ki-notification-status fs-1" />;
      case "campaign-analytics":
        return <i className="ki-outline ki-graph-3 fs-1" />;
      case "campaign-management":
        return <i className="ki-outline ki-data fs-1" />;
      case "brand-management":
        return <i className="ki-outline ki-abstract-26 fs-1" />;
      case "application-logs":
        return <i className="ki-outline ki-chart-simple-2 fs-1" />;
      case "manage-user":
        return <i class="ki-outline ki-profile-user fs-1" />;
      case "setting":
        return <i class="ki-outline ki-gear fs-1" />;
      case "advertisement-report":
        return <i class="ki-outline ki-tablet-text-up fs-1" />;

      case "noData":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="42"
            height="39"
            viewBox="0 0 42 39"
          >
            <path
              fill="#363A3E"
              fillRule="evenodd"
              d="M1.917 22.254l3.879-1.066.223.8 3.924-1.065.446 1.555 9.897-2.666v7.062l-7.266 11.15 1.515.976 6.599-10.217L27.733 39l1.515-.977-7.222-11.149v-7.551l9.586-2.577.268 1.065L42 16.612 37.451 0l-9.317 4.086.268 1.066-21.223 5.73.446 1.556L3.7 13.503l.223.8L0 15.368l1.917 6.886zM36.293 2.443l3.433 12.615-6.51.8-2.942-10.75 6.019-2.665zM28.891 6.84l2.23 8.172-19.53 5.33-2.228-8.172L28.89 6.84zm-20.82 7.33l1.382 5.062-2.229.622-1.383-5.063 2.23-.622zM4.37 16.034l.937 3.465-2.14.578-.981-3.466 2.184-.577z"
              opacity=".507"
            />
          </svg>
        );
      case "amazonLogo":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 48 48"
            width="24px"
            height="24px"
          >
            <path
              fill="#FFB300"
              d="M39.6,39c-4.2,3.1-10.5,5-15.6,5c-7.3,0-13.8-2.9-18.8-7.4c-0.4-0.4,0-0.8,0.4-0.6c5.4,3.1,11.5,4.9,18.3,4.9c4.6,0,10.4-1,15.1-3C39.7,37.7,40.3,38.5,39.6,39z M41.1,36.9c-0.5-0.7-3.5-0.3-4.8-0.2c-0.4,0-0.5-0.3-0.1-0.6c2.3-1.7,6.2-1.2,6.6-0.6c0.4,0.6-0.1,4.5-2.3,6.3c-0.3,0.3-0.7,0.1-0.5-0.2C40.5,40.4,41.6,37.6,41.1,36.9z"
            />
            <path
              fill="#37474F"
              d="M36.9,29.8c-1-1.3-2-2.4-2-4.9v-8.3c0-3.5,0-6.6-2.5-9c-2-1.9-5.3-2.6-7.9-2.6C19,5,14.2,7.2,13,13.4c-0.1,0.7,0.4,1,0.8,1.1l5.1,0.6c0.5,0,0.8-0.5,0.9-1c0.4-2.1,2.1-3.1,4.1-3.1c1.1,0,3.2,0.6,3.2,3v3c-3.2,0-6.6,0-9.4,1.2c-3.3,1.4-5.6,4.3-5.6,8.6c0,5.5,3.4,8.2,7.8,8.2c3.7,0,5.9-0.9,8.8-3.8c0.9,1.4,1.3,2.2,3,3.7c0.4,0.2,0.9,0.2,1.2-0.1l0,0c1-0.9,2.9-2.6,4-3.5C37.4,30.9,37.3,30.3,36.9,29.8z M27,22.1L27,22.1c0,2-0.1,6.9-5,6.9c-3,0-3-3-3-3c0-4.5,4.2-5,8-5V22.1z"
            />
          </svg>
        );
      default:
        return <span>NA</span>;
    }
  };
  return <span {...rest}>{getIcon()}</span>;
};

export default Icons;
