import styled from "styled-components";

export const EditReplenishmentWrapper = styled.div`
  .ant-segmented-item-selected {
    background-image: linear-gradient(45deg, #6542ff, #9992b1);
    color: #ffffff;
    font-weight: 600;
    letter-spacing: 0.5px;
  }
  .ant-segmented-item,
  .ant-segmented {
    :where(.css-dev-only-do-not-override-i56vxb).ant-segmented
      .ant-segmented-item {
      transition: color 0s cubic-bezier(0, 0, 0, 0);
    }
  }
  .ant-segmented-item-label {
    line-height: 37px !important;
    min-height: 35px !important;
    letter-spacing: 0.5px;
    font-weight: 600;
  }
  .ant-checkbox .ant-checkbox-inner {
    width: 21px !important;
    height: 23px !important;
  }
`;
